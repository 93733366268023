import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { ViewLayout } from './view-layout';

export function ListHomologuerPointLivraison() {
    const renderContent = () => (
        <ViewLayout>
            <h2>Liste Homologuation</h2>
            <ListGenericForm
                identifier="id"
                reduxPropertyName="pointsLivraison"
                functionName={URL_CONST.GET_LIST_POINT_LIVRAISON_MARCHAND}
                nodeName={DB_NODE.POINT_LIVRAISON}
                mapper={[
                    {
                        field: 'id',
                        displayName: 'id',
                        position: 1
                    },
                    {
                        field: 'agent',
                        displayName: 'Agent',
                        position: 2
                    },
                    {
                        field: 'adresse.adresseUne',
                        displayName: 'Adresse',
                        position: 3
                    },
                    {
                        field: 'adresse.reference',
                        displayName: 'Reference',
                        position: 4
                    },
                    {
                        field: 'etat',
                        displayName: 'Etat',
                        position: 5
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
