import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_CONST, formInitialState } from '@napp-inc/jnapp-util';
import {
    fetchElement,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { REDUX_NODE_NAME } from '../../../redux';
import { SimpleCard } from '../../../components';
import { URL_CONST } from '../../../util';

const defaultFields = {};

export function StockPhysiqueCard({
    idStock,
    numero,
    designation,
    icon,
    typeNumber
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, stockPhysique } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        stockPhysique: reduxState.stocksPhysique?.find(
            (item) => item?.id === idStock
        )
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['stockPhysique']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { elements } = formState;
    const onSuccess = (data) => {
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItem(
                { id: idStock, ...data?.element },
                REDUX_NODE_NAME.STOCK_PHYSIQUE
            )
        );
    };
    const fetchReport = () => {
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'stockPhysique',
                payload: {
                    idStock
                },
                functionName: URL_CONST.GET_STOCK_PHYSIQUE,
                etat: elements?.stockPhysique?.status,
                formDispatcher,
                onSuccess
            })
        );
    };
    return (
        <SimpleCard
            text={designation}
            value={Math.abs(stockPhysique?.quantite) || 0}
            textAlternatif={numero}
            icon={icon}
            typeNumber={typeNumber}
            isCompact={false}
            nombreApresVirgule={0}
            refetchFunction={fetchReport}
            isDisabledRefetch={
                elements?.stockPhysique?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
        />
    );
}
