/**
 * Ce composant sert à rendre conditionnellement ces enfants si la condition pour être rendu est vérifié
 * @param {Object} param0 props react
 * @param {Object} param0.children props children react
 * @param {Boolean} param0.isShouldBeRendered booléen définissant si les enfants doivent être rendu ou pas
 * @returns {React.Component}
 */

export function ConditionalRenderingWrapper({
    children,
    isShouldBeRendered = true
}) {
    return isShouldBeRendered ? children : null;
}
