import { HiOutlineCreditCard } from 'react-icons/hi';

/**
 * @desc Mon compte rel paths
 */
export const MonCompteViewLayoutRelPath = {
    name: 'Mon compte',
    path: 'my-account',
    icon: <HiOutlineCreditCard className="icon-custom" />
};
