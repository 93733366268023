import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetOrganisationDetail,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { MARCHAND_ORGANISATION_PROFESIONNELLE } from '@napp-inc/jnapp-util/src/constante/marchand';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { TableMangerSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    selectedPointLivraison: {},
    table: '',
    list: []
};

/**
 * @description defaultDeps de notre state
 */
const defaultDependencies = { fields: ['pointLivraison', 'agences'] };

export function CreationSalleMangerForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        pointsLivraison,
        etatPointLivraison,
        agences,
        etatAgence,
        organisation
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        pointsLivraison: reduxState.pointsLivraison.map((item) => ({
            ...item,
            designation: `${item?.adresse?.adresseUne}, Ref: ${item?.adresse?.reference}`,
            agent: `${item.agentLivreur.nom} ${item.agentLivreur.prenom}`
        })),
        etatPointLivraison: reduxState.etat.pointsLivraison.etat,
        agences: reduxState.agences,
        etatAgence: reduxState.etat.agences.etat,
        organisation: reduxState.organisation
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { typeOrganisation } = useGetOrganisationDetail({
        organisation
    });
    const { fields, form } = formState;

    /**
     * @description récupérer les produits marchand
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_POINT_LIVRAISON_MARCHAND,
                nodeName: DB_NODE.POINT_LIVRAISON,
                etat: etatPointLivraison
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_AGENCE,
                nodeName: DB_NODE.AGENCE,
                etat: etatAgence
            }
        ]
    });

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const list = fields.list.map((item) => item.code);
        const payload = {
            newSalleMangerTable: {
                typeProprietaire:
                    typeOrganisation === MARCHAND_ORGANISATION_PROFESIONNELLE
                        ? 'Agence'
                        : 'PointLivraison',
                valeurTypeProprietaire: {
                    reference: fields.selectedPointLivraison.id
                }
            },
            list,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_SALLE_MANGER,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };

    const onAddNewTable = () => {
        if (!fields.table || !fields.table.length) return;
        const data = {
            code: fields.table,
            designation: fields.table
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'code',
                field: 'list',
                data
            }
        });
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'pointLivraison',
                    etat: etatPointLivraison
                },
                {
                    dependency: 'agences',
                    etat: etatAgence
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    idProperty="id"
                    options={
                        typeOrganisation ===
                        MARCHAND_ORGANISATION_PROFESIONNELLE
                            ? agences
                            : pointsLivraison
                    }
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption={`Selectionner ${
                        typeOrganisation ===
                        MARCHAND_ORGANISATION_PROFESIONNELLE
                            ? "l'agence"
                            : 'le point de livraison'
                    }`}
                    label={`Selectionner ${
                        typeOrganisation ===
                        MARCHAND_ORGANISATION_PROFESIONNELLE
                            ? 'Agence*'
                            : 'Point de livraison*'
                    }`}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedPointLivraison"
                    id="selectedPointLivraisonId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Tables*" className="col-sm-2" />
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    divClassName="col-sm-9"
                    name="table"
                    placeholder="Designation de la table"
                    id="creationSalleMangerFormTableId"
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={onAddNewTable}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.list.length}>
                <SimpleTable
                    formDispatcher={formDispatcher}
                    identifier="code"
                    listSelectedItemName="list"
                    data={fields.list}
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            field: 'designation',
                            displayName: 'Designation de la table'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer salle à manger"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
