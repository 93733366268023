import * as yup from 'yup';

/**
 * @description : Schema Réévaluation Charge variable création form
 */
export const ReevaluationChargeVariableSchema = yup.object().shape({
    selectedCharge: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    datePaiement: yup.string().required(),
    chargeReevaluation: yup
        .array()
        .of(
            yup
                .object({
                    id: yup.string().required(),
                    code: yup.string().required(),
                    datePaiement: yup.number().min(1),
                    devise: yup.string()
                })
                .shape({})
        )
        .min(1, 'Ajoutez au moins un élément à la liste')
});
