import { useContext, useEffect, useState } from 'react';
import * as aiIcons from 'react-icons/ai';
import SettingHeader from './setting-header';
import { SettingMenu } from './setting-menu';
import SettingContent from './setting-content';
import { StateContext } from '../../context/state-context';

/**
 * Le layout setting
 * @returns {React.Component}
 */
export function SettingLayout() {
    const { screenSize, activeMenu } = useContext(StateContext);
    const [mainContentStyle, setMainContentStyle] = useState(
        `col-md-12 col-lg-10 col-sm-12`
    );

    useEffect(() => {
        if (screenSize > 900 && activeMenu) {
            setMainContentStyle(` col-md-12 col-lg-10 col-sm-12`);
        } else if (screenSize > 900 && !activeMenu) {
            setMainContentStyle(` col-md-12 col-lg-10 col-sm-12`);
        }
    }, [screenSize, activeMenu, setMainContentStyle]);

    return (
        <div className="container-fluid">
            {/* header setting: a row with two things */}

            <div className="row">
                <SettingHeader
                    icon={<aiIcons.AiFillSetting />}
                    title="Paramètres"
                />
                <hr />
            </div>
            <div className="row py-1">
                {/* <Col md={12} lg={2} sm={12} xs={12}> */}
                <div className="col-md-12 col-lg-2 col-sm-12">
                    <SettingMenu className="wrapper-flex" />
                </div>
                <div className={`${mainContentStyle} shadow`}>
                    <SettingContent />
                </div>
            </div>
        </div>
    );
}
