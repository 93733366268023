import { submitPutAction, useFetchAndListening } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { DynamicMonoSelectAndInputSecond } from '../../../components/special-components/dynamic-mono-select-and-input-second';
import { useGetDetailForm } from '../../../util';
import { percuAndRetournedMontantDevise } from '../../stock/appro-sim/util';
import { schema } from './detail-fields';

const defaultFields = {
    actionEncours: '',
    deviseRetourSelect: {},
    deviseRetourInput: 0,
    devisePercuSelect: {},
    devisePercuInput: 0,
    montantPercu: [],
    montantRetour: []
};

const objectKeysPercu = {
    devise: 'devisePercuSelect.code',
    montant: 'devisePercuInput'
};

const objectKeysRetour = {
    devise: 'deviseRetourSelect.code',
    montant: 'deviseRetourInput'
};

export function DetailCommandeFlashForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'commandesFlash', defaultFields });
    const { monnaiesLocale, etatMonnaieLocale } = useSelector((reduxState) => ({
        monnaiesLocale: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            code: item?.code || item?.devise
        })),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat
    }));

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            }
        ]
    });
    const { fields, form } = formState;
    const recouvrement = () => {
        const payload = {
            typeEntite: 'CommandeFlash',
            valeurTypeEntite: entity?.id,
            montantsDevise: percuAndRetournedMontantDevise(
                fields.montantPercu,
                fields.montantRetour
            )
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.POST_REMBOURSEMENT_COMMANDE,
                payload,
                fields,
                reduxNodeName: DB_NODE.COMMANDE_FLASH,
                reduxDispatcher
            })
        );
    };
    const demanderAnnulation = () => {
        const payload = {
            typeEntite: 'CommandeFlash',
            valeurTypeEntite: entity?.id,
            montantsDevise: percuAndRetournedMontantDevise(
                fields.montantPercu,
                fields.montantRetour
            )
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.POST_DEMANDE_ANNULATION,
                payload,
                fields,
                reduxNodeName: DB_NODE.COMMANDE_FLASH,
                reduxDispatcher
            })
        );
    };
    const updateEtat = (etat) => {
        const actionEncours = etat;
        const payload = {
            etat,
            idCommandeFlash: entity?.id,
            montantsDevise: percuAndRetournedMontantDevise(
                fields.montantPercu,
                fields.montantRetour
            )
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_COMMANDE_FLASH,
                payload,
                fields,
                reduxNodeName: DB_NODE.COMMANDE_FLASH,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_COMMANDE_FLASH_BY_ID}
            nodeName={DB_NODE.COMMANDE_FLASH}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Commandes Flash"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.VENTE_ORGANISATION.MISE_A_JOUR_COMMANDE_FLASH,
                    CODE_SERVICE.VENTE_ORGANISATION.INITIER_COMMANDE_FLASH,
                    CODE_SERVICE.VENTE_ORGANISATION
                        .INITIER_COMMANDE_FLASH_MODE_RECOUVREMENT
                ]}
            >
                {(!entity?.estRecouvert &&
                    (entity?.etat === ETAT.ETAT_ENCOURS ||
                        (entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION &&
                            !entity?.demandeAnnulation))) ||
                (entity?.estRecouvert && entity?.etat !== ETAT.ETAT_ANNULER) ? (
                    <CustomRow>
                        <CustomCol>
                            <div className="shadow card">
                                <div className="card-header">
                                    <p className="fw-normal mb-0">
                                        Montant perçu
                                    </p>
                                </div>
                                <div className="card-body">
                                    <CustomRow>
                                        <DynamicMonoSelectAndInputSecond
                                            options={monnaiesLocale}
                                            id="deviseMontantPercu"
                                            name="devisePercu"
                                            defaultOption="Selectionner devise"
                                            formState={formState}
                                            formDispatcher={formDispatcher}
                                            type="number"
                                            identifier="devise"
                                            placeholder="montant"
                                            field="montantPercu"
                                            objectKeys={objectKeysPercu}
                                            // uiValidator={uiValidator}
                                        />
                                    </CustomRow>
                                </div>
                            </div>
                        </CustomCol>
                        <CustomCol>
                            <div className="shadow card">
                                <div className="card-header">
                                    <p className="fw-normal mb-0">
                                        Montant retourné
                                    </p>
                                </div>
                                <div className="card-body">
                                    <CustomRow>
                                        <DynamicMonoSelectAndInputSecond
                                            options={monnaiesLocale}
                                            id="deviseMontantRetour"
                                            name="deviseRetour"
                                            defaultOption="Selectionner devise"
                                            formState={formState}
                                            formDispatcher={formDispatcher}
                                            type="number"
                                            identifier="devise"
                                            placeholder="montant"
                                            field="montantRetour"
                                            objectKeys={objectKeysRetour}
                                            // uiValidator={uiValidator}
                                        />
                                    </CustomRow>
                                </div>
                            </div>
                        </CustomCol>
                    </CustomRow>
                ) : null}
                <CustomRow>
                    {entity?.etat === ETAT.ETAT_PRE_ENCOURS ||
                    entity?.etat === ETAT.ETAT_REJETER ? (
                        <CustomCol className="offset-md-6 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                    ) : null}
                    {entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION &&
                    !entity?.demandeAnnulation ? (
                        <CustomCol className="col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Demander annulation"
                                className="btn-secondary"
                                confirmOnClick={() => demanderAnnulation()}
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS &&
                                    fields.actionEncours !== ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                    ) : null}
                    {entity?.estRecouvert &&
                    entity?.etat !== ETAT.ETAT_ANNULER ? (
                        <CustomCol className="col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Recouvrement"
                                className="btn-secondary"
                                confirmOnClick={() => recouvrement()}
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS &&
                                    fields.actionEncours !== ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation recouvrement"
                            />
                        </CustomCol>
                    ) : null}
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
