import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    MOYEN_PAIEMENT,
    BUDGET_ELABORATION,
    CHARGE,
    TYPE_BUDGET_PREVISIONNEL,
    fromTimestampToString,
    TYPE_BUDGET_ADDITIONNEL,
    BUDGET_AMENDEMENT
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomLabel,
    CustomCollapse,
    CustomAddRemoveButton,
    SimpleTable,
    FormWrapper,
    CustomSwitchButton,
    ConditionalRenderingWrapper,
    CustomToast
} from '../../../../../components';
import { URL_CONST } from '../../../../../util';
import { REDUX_NODE_NAME } from '../../../../../redux';
import { ElaborationChargeVariableFormSchema as uiValidator } from '../validation';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedDirection: {},
    selectedSecteur: {},
    selectedCharge: {},
    selectedCaisseSectorielle: {},
    modePaiement: {},
    tauxCroissance: 0,
    list: [],
    estPrevisionnel: false
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: [
        'directions',
        'secteurs',
        'charges',
        'caisses',
        'exercicesFinancier'
    ]
};

/**
 * Principale vue des vues pour les charges variables
 * @param {Object} param0 : prends en compte toutes les props possibles
 * @param {String} param0.typeBudget: qui sera du type 'Elaboration | Completion | Ammendement'
 * @param {String} param0.isAdditionnel: qui sera du type true | False, pour savoir si c'est un prévisionnel ou un additionnel
 * @returns
 */
export function ChargesVariables({
    isAdditionnel = false,
    isCompletion = false,
    isAmendement = false,
    idEntity,
    payloadUrl = {}
}) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        chargesVariable,
        etatCharge,
        etatSecteurs,
        etatDirections,
        caisses,
        etatCaisse,
        exerciceFinancier,
        etatExerciceFinancier
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        chargesVariable: reduxState.chargesVariable,
        etatCharge: reduxState.etat.chargesVariable.etat,
        etatSecteurs: reduxState.etat.secteurs.etat,
        secteursNapp: reduxState.secteurs.filter(
            (item) => item?.direction?.estNapp
        ),
        secteursKinDB: reduxState.secteurs.filter(
            (item) => !item?.direction?.estNapp
        ),
        directions: reduxState.directions,
        etatDirections: reduxState.etat.directions.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item.valeurTypeEntite.designation}-${item.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat,
        exerciceFinancier: reduxState.exerciceFinancier.map((item) => ({
            ...item,
            designation: `${fromTimestampToString(
                item.dateDebut
            )} - ${fromTimestampToString(item.dateFin)}`
        })),
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const chargeVariableTargeted = chargesVariable.find(
        (item) => item.code === fields.selectedCharge.code
    );
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_VARIABLE,
                nodeName: REDUX_NODE_NAME.CHARGE_VARIABLE,
                etat: etatCharge
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_DIRECTION, // Toutes les directions
                nodeName: REDUX_NODE_NAME.DIRECTION,
                etat: etatDirections
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
            }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedExercice, list } = fields;
        const targetedExercice = exerciceFinancier.find(
            (item) =>
                item.id === selectedExercice.id ||
                (item.dateDebut === payloadUrl?.start &&
                    item.dateFin === payloadUrl?.end)
        );
        const lastList = list.map((item) => {
            const model = {
                element: {
                    code: item.element.code
                },
                typeMoyenPaiement: item.typeMoyenPaiement
            };
            if (item.tauxCroissance) {
                model.tauxCroissance = item.tauxCroissance;
            }
            if (item.idCaisse) {
                model.idCaisse = item.idCaisse;
            }
            return model;
        });
        const payloadToAdd = {};
        const payloadToAddOut = {};
        if (isAdditionnel) {
            payloadToAdd.estPrevisionnel = fields.estPrevisionnel;
        }
        if (isCompletion || isAmendement) {
            payloadToAddOut.idElaborationAmendement = idEntity;
        }
        const payload = {
            periode: {
                dateDebut: targetedExercice.dateDebut || payloadUrl?.start,
                dateFin: targetedExercice.dateFin || payloadUrl?.end
            },
            newElaborationAmendement: {
                typeEntite: isAmendement
                    ? BUDGET_AMENDEMENT
                    : BUDGET_ELABORATION,
                typeProprietaire: CHARGE.code,
                estVariable: true,
                type: isAdditionnel
                    ? TYPE_BUDGET_ADDITIONNEL.code
                    : TYPE_BUDGET_PREVISIONNEL.code,
                ...payloadToAdd
            },
            creator,
            list: lastList,
            ...payloadToAddOut
        };
        // console.log(JSON.stringify(payload));
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    /**
     * @description action pour rajouter le partenaires et le montant à payer
     */
    const addBeneficiaire = () => {
        if (!isCompletion) {
            if (!fields.modePaiement.code || !fields.selectedCharge.code) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `Vérifiez Selectionner les éléments requis pour continuer la complétion`,
                        status: FORM_CONST.CREATION_ECHEC
                    }
                });
                return;
            }
            const propertyToAdd = {};
            if (fields.modePaiement.code === 'Cash') {
                propertyToAdd.idCaisse = fields.selectedCaisseSectorielle.id;
            }

            if (chargeVariableTargeted?.type === 'Retribution') {
                propertyToAdd.tauxCroissance = 0;
            }
            if (chargeVariableTargeted?.type !== 'Retribution') {
                propertyToAdd.tauxCroissance = fields.tauxCroissance;
            }
            const data = {
                id: fields.selectedCharge.code,
                typeMoyenPaiement: fields.modePaiement.code,
                element: {
                    designation: fields.selectedCharge.designation,
                    code: fields.selectedCharge.code
                },
                ...propertyToAdd
            };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'id',
                    field: 'list',
                    data
                }
            });
        } else {
            if (!fields.selectedCharge.code) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `Vérifiez Selectionner une charge pour continuer la complétion`,
                        status: FORM_CONST.CREATION_ECHEC
                    }
                });
                return;
            }
            const propertyToAdd = {};

            if (chargeVariableTargeted?.type === 'Retribution') {
                propertyToAdd.tauxCroissance = 0;
            }
            if (chargeVariableTargeted?.type !== 'Retribution') {
                propertyToAdd.tauxCroissance = fields.tauxCroissance;
            }
            const data = {
                id: fields.selectedCharge.code,
                element: {
                    designation: fields.selectedCharge.designation,
                    code: fields.selectedCharge.code
                },
                ...propertyToAdd
            };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'id',
                    field: 'list',
                    data
                }
            });
        }
    };

    const renderContent = () => (
        <FormWrapper
            isRedirect={!isCompletion && !isAmendement}
            onSubmit={handleSubmit}
            formState={formState}
            isStepBack={isCompletion || isAmendement}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'directions',
                    etat: etatDirections
                },
                {
                    dependency: 'exercicesFinancier',
                    etat: etatExerciceFinancier
                },
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'charges',
                    etat: etatCharge
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisse
                }
            ]}
        >
            <ConditionalRenderingWrapper
                isShouldBeRendered={isCompletion || isAmendement}
            >
                <div className="alert alert-warning" role="alert">
                    Cette opération apportera des ajouts au présent budget!
                    <hr />
                    <div>
                        <span className="">Exercice :</span>{' '}
                        <span>
                            Du {fromTimestampToString(payloadUrl.start)} au{' '}
                            {fromTimestampToString(payloadUrl.end)}
                        </span>
                    </div>
                </div>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!isCompletion && !isAmendement}
            >
                {form && form.status === FORM_CONST.MODIFICATION_SUCCESS && (
                    <CustomToast
                        successMessage="Modification Effectuée"
                        status={form.status}
                    />
                )}
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!isCompletion && !isAmendement}
            >
                <CustomRow>
                    <CustomDropdown
                        options={exerciceFinancier}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner un exercice"
                        label="Exercice*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedExercice"
                        id="selectedExerciceIElaborationChargeVariableFormd"
                        idProperty="id"
                        uiValidator={uiValidator}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper isShouldBeRendered={isAdditionnel}>
                <CustomSwitchButton
                    id="selectionElaborationAdditionnelSwitchId"
                    name="estPrevisionnel"
                    text="Agir sur le budget prévisionnel?"
                    value={fields.estPrevisionnel}
                    formState={formState}
                    formDispatcher={formDispatcher}
                    divClassName="col-sm-3 mb-3"
                />
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomDropdown
                    options={MOYEN_PAIEMENT.ALL_MOYEN_PAIEMENT}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le mode de paiement"
                    label="Mode de paiement*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="modePaiement"
                    id="modePaiementId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomCollapse isIn={fields.modePaiement.code === 'Cash'}>
                <CustomRow>
                    <CustomDropdown
                        idProperty="id"
                        options={caisses}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner la caisse"
                        label="Caisse Sectorielle*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedCaisseSectorielle"
                        id="selectedCaisseSectorielleId"
                        uiValidator={uiValidator}
                    />
                </CustomRow>
            </CustomCollapse>
            <CustomRow>
                <CustomLabel text="Charge*" className="col-sm-2" />
                <CustomDropdown
                    options={chargesVariable}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la charge"
                    divClassName="col-12 col-sm-4"
                    name="selectedCharge"
                    id="selectedChargeId"
                    uiValidator={uiValidator}
                />
                <CustomInput
                    isInputGroup={false}
                    divClassName="col-12 col-sm-4"
                    placeholder="Saisissez le taux de croissance..."
                    formDispatcher={formDispatcher}
                    name="tauxCroissance"
                    type="number"
                    id="tauxCroissanceId"
                    disabled={chargeVariableTargeted?.type === 'Retribution'}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-2"
                    callback={addBeneficiaire}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.list.length}>
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.list}
                    isShowDropButton
                    listSelectedItemName="list"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            displayName: 'Charge',
                            field: 'element.designation'
                        },
                        {
                            position: 3,
                            displayName: 'Taux de croissance',
                            field: 'tauxCroissance'
                        }
                    ]}
                />
            </CustomCollapse>
            <div className="mt-3">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Soumettre elaboration"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </div>
        </FormWrapper>
    );
    return renderContent();
}
