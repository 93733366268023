import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { useLocation } from 'react-router-dom';
import { ListApproCashForm } from './list-appro-cash';
import { ShowNewComponent } from '../../../components';
import { FinancePath } from '../../../routes/registration';

export function ApproCashViewLayout() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Appro Cash</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${FinancePath.ApproCashListPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                        .CODE_SERVICE_MODULE_FINANCE
                                        .INITIER_APPRO_CASH,
                                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                        .CODE_SERVICE_MODULE_FINANCE
                                        .INITIER_APPRO_CASH_POUR_AUTRE
                                ]}
                                destination={
                                    FinancePath.ApproCashCreationPath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListApproCashForm />
        </>
    );
}
