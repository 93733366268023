import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { ViewLayout } from './view-layout';

export function ListMoyenRetracage() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                reduxPropertyName="traitementsModeRetracage"
                functionName={URL_CONST.GET_LIST_TRAITEMENT_MODE_RETRACAGE}
                nodeName={DB_NODE.TRAITEMENT_MODE_RETRACAGE}
                identifier="id"
                mapper={[
                    {
                        field: 'valeurTypeEntite.code',
                        displayName: 'Code',
                        position: 2
                    },
                    {
                        field: 'valeurTypeEntite.designation',
                        displayName: 'Désignation',
                        position: 3
                    },
                    {
                        field: 'modeRetracage',
                        displayName: 'Mode Retracage',
                        position: 4
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 5
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
