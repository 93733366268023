import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TIME,
    TODAY
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListRecouvrementEmoneyForm() {
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { uid } = useGetUserDetail({
        firebaseUser
    });
    return (
        <ListGenericForm
            listTitle="Comptes de Créance"
            reduxPropertyName="compteCreance"
            functionName={URL_CONST.GET_LIST_COMPTE_CREANCE}
            nodeName={REDUX_NODE_NAME.COMPTE_CREANCE}
            mapper={[
                {
                    field: 'valeurTypeProprietaire.designation',
                    displayName: 'Client'
                },
                {
                    field: 'valeurTypeOrganisation.designation',
                    displayName: 'Organisation'
                },
                {
                    field: 'solde',
                    displayName: 'solde'
                },
                {
                    field: 'dateCreation',
                    displayName: 'dateCreation'
                }
            ]}
            path={DB_NODE.COMPTE_CREANCE}
            listenerFilters={[
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'typeEntite',
                    value: 'Creance',
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'gestionnaire.reference',
                    value: uid,
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'date',
                    value: TIME.utc000000(),
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.ORDER_BY,
                    property: 'dateDerniereModification',
                    value: 'asc'
                },
                {
                    type: FILTER_TYPE.START_AT,
                    value: TODAY.nowUtc(),
                    isTimestampNowValue: true
                }
            ]}
        />
    );
}
