import * as yup from 'yup';

/**
 * @description : Schema de MonnaieFormSchema form
 */
export const MonnaieFormSchema = yup.object().shape({
    selectedMonnaie: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
