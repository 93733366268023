import {
    fetchElement,
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { AbonnementMarchandSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    searchInput: ''
};

/**
 * @description defaultElements de notre state
 */
const defaultElements = ['marchands'];

export function CreationAbonnementMarchandForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, marchands } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        marchands: reduxState.marchands
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    /**
     * @description fonction pour fetcher les marchands et les agences
     */
    const onSearchMarchand = () => {
        const { status: etat } = elements.marchands;
        if (!fields.searchInput) return;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_MARCHAND,
                elementPropertyName: 'marchands',
                etat,
                payload: {
                    tillNumber: fields.searchInput
                },
                reduxNodeName: DB_NODE.MARCHAND,
                reduxDispatcher
            })
        );
    };

    /**
     * Fonction pour poster le parametre de creer un abonnement marchand
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            creator,
            codeMarchand: marchands[0]?.code
        };
        if (!marchands[0].code) return;
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_ABONNEMENT,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    id="creationAbonnementMarchandTillNumberId"
                    name="searchInput"
                    isInputGroup={false}
                    label="TillNumber*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-8"
                    placeholder="TillNumber Marchand"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomButtonLoader
                    className="col-sm-2 btn-primary"
                    onClick={onSearchMarchand}
                    type="button"
                    text="Search"
                    disabled={
                        elements?.marchands?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                    isActionEncours={
                        elements?.marchands?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
            </CustomRow>
            {marchands.length ? (
                <CustomRow>
                    <CustomDropdown
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        name="selectedMarchand"
                        id="creationAbonnementMarchandSelectedMarchandId"
                        options={marchands}
                        selectionClassName="form-select-md"
                        label="Marchand*"
                        defaultOption={marchands[0]?.designation}
                        disabled
                        labelClassName="col-sm-2"
                        divClassName="col-sm-8"
                    />
                </CustomRow>
            ) : null}
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="S'abonner"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
