import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

export function CheckLogout({ children }) {
    const location = useLocation();
    const firebaseUser = useSelector((reduxState) => reduxState.firebaseUser);
    if (firebaseUser && firebaseUser.uid) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
}
