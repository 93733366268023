import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../releve/generic';
import { URL_CONST } from '../../../util';
import {
    MouvementCardsComponent,
    ReportCommandeFlashSelector
} from '../../../components';
import { commandeMapper } from './mapper';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedSuperDealer: {},
    selectedTypeBusiness: {},
    selectedEtat: {}
};

export function RapportCommandeFlashForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const displayTypeBussiness = (type) => {
        if (type === 'TraditionnelBusiness') return 'Traditionnel';
        if (type === 'BusinessModel') return 'Classique';
        return 'Sim';
    };
    const { fields, elements } = formState;
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedSuperDealer, selectedEtat } =
            fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payloadToAdd = {};
        if (selectedEtat.code) {
            payloadToAdd.etat = selectedEtat.code;
        }
        if (selectedSuperDealer.code) {
            payloadToAdd.codeSuperDealer = selectedSuperDealer.code;
        }
        const payload = {
            dateDebut,
            dateFin,
            ...payloadToAdd,
            property: 'dateCreation',
            estNonSendeur: true
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_COMMANDE_FLASH_BY_ETAT,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };
    return (
        <>
            <MainReportComponentLarge
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={commandeMapper}
                dataList={
                    elements.mouvements?.value?.map((item) => ({
                        ...item,
                        business: displayTypeBussiness(item.typeBusiness)
                    })) || []
                }
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                topOfChart={MouvementCardsComponent}
                isCommandeLitigieuse
            >
                <ReportCommandeFlashSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
