import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    URL_CONST,
    FORM_CONST,
    CHARGE,
    RESSOURCE,
    RETRACAGE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomLabel,
    CustomRadioButton,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { MoyenRetracageSchema as uiValidator } from './validation';

/**
 * @description defaultFields
 */
const defaultFields = {
    selectedType: CHARGE.code,
    selectedElement: {},
    selectedModeRetracage: {}
};

/**
 * @description Dependences
 */
const defaultDependencies = { fields: ['charges', 'ressources'] };
/**
 * Mode retracage
 * @returns {Array}
 */
const MODE_RETRACAGE = [
    { code: RETRACAGE.INSTANTANEE, designation: RETRACAGE.INSTANTANEE },
    { code: RETRACAGE.QUALIFICATION, designation: RETRACAGE.QUALIFICATION }
];
export function CreationMoyenRetracageForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, ressources, etatRessource, charges, etatCharge } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            ressources: reduxState.ressources,
            etatRessource: reduxState.etat.ressources.etat,
            charges: reduxState.chargesVariable,
            etatCharge: reduxState.etat.chargesVariable.etat
        }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_RESSOURCE,
                nodeName: DB_NODE.RESSOURCE,
                etat: etatRessource
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_VARIABLE,
                nodeName: REDUX_NODE_NAME.CHARGE_VARIABLE,
                etat: etatCharge
            }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            creator,
            newTraitementModeRetracage: {
                typeEntite: fields.selectedType,
                valeurTypeEntite: {
                    code: fields.selectedElement.code
                },
                modeRetracage: fields.selectedModeRetracage.code
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_TRAITEMENT_MODE_RETRACAGE,
                payload,
                fields,
                reduxNodeName: DB_NODE.TRAITEMENT_MODE_RETRACAGE,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'charges',
                    etat: etatCharge
                },
                {
                    dependency: 'ressources',
                    etat: etatRessource
                }
            ]}
        >
            <CustomRow>
                <CustomLabel text="Type*" className="col-12 col-md-2" />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3"
                    label="Charges"
                    id="typeExecutableCreationCharge"
                    name="selectedType"
                    value={CHARGE.code}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3"
                    label="Ressources"
                    id="typeNonExecutableCreationCharge"
                    name="selectedType"
                    value={RESSOURCE.code}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            {/* Recupérer  */}
            <CustomRow>
                <CustomDropdown
                    options={
                        fields.selectedType === RESSOURCE.code
                            ? ressources
                            : charges
                    }
                    formDispatcher={formDispatcher}
                    formState={formState}
                    label={`${
                        fields.selectedType === RESSOURCE.code
                            ? 'Ressources'
                            : 'Charges'
                    }*`}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedElement"
                    id="moyenRetracageId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={MODE_RETRACAGE}
                    idProperty="code"
                    propertyToDisplay="designation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    label="Moyen de retracage"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedModeRetracage"
                    id="selectedModeRetracageId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        isActionEncours={
                            form.status === FORM_CONST.CREATION_EN_COURS
                        }
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
