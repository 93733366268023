import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import { schema } from './detail-fields';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetDetailForm } from '../../../util';

export function DetailGestionRevendeurForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'statusRevendeur' });
    const { form, fields } = formState;
    const updateEtat = () => {
        const payload = {
            idStatusRevendeur: entity?.id,
            etat: ETAT.ETAT_VALIDER
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_STATUS_REVENDEUR,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.STATUS_REVENDEUR,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_STATUS_REVENDEUR_BY_ID}
            nodeName={REDUX_NODE_NAME.STATUS_REVENDEUR}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Status revendeur detail"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .CHANGEMENT_STATUT_REVENDEUR
                        .VALIDER_CHANGEMENT_STATUT_REVENDEUR
                ]}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={updateEtat}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
