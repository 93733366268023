import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { ArticleColisSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    selectedArticle: {},
    articles: []
};

/**
 * @description defaultDeps de notre state
 */
const defaultDependencies = { fields: ['article'] };

export function CreationArticleColisForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * @description récupérer les produits marchand
    //  */
    // useFetchAndListening({
    //     idToken,
    //     reduxDispatcher,
    //     list: [
    //         {
    //             isOnlyFetch: true,
    //             functionName: URL_CONST.GET_LIST_INGREDIENT_MARCHAND,
    //             nodeName: REDUX_NODE_NAME.INGREDIENTS,
    //             etat: etatIngredient
    //         }
    //     ]
    // });

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {};
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_PRODUIT_MATERIEL,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };

    /**
     * L'action qui sera déclenchée pour l'ajout des Ingredients
     * @returns
     */
    const onAddArticle = () => {
        const newState = {
            ...initialState,
            fields: {
                ...initialState.fields,
                articles: fields.articles
            }
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
            payload: {
                newState,
                field: 'articles',
                data: fields.selectedArticle,
                identifier: 'code'
            }
        });
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomDropdown
                    options={[]}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedArticle"
                    id="creationArticleProduitMaterielSelectedArticleId"
                    defaultOption="Selectionner l'article"
                    label="Article*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-9"
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={onAddArticle}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.articles.length}>
                <SimpleTable
                    formDispatcher={formDispatcher}
                    data={fields.articles}
                    listSelectedItemName="articles"
                    identifier="code"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            field: 'code',
                            displayName: 'Code'
                        },
                        {
                            position: 2,
                            field: 'quantite',
                            displayName: 'Quantité'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer l'article"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
