export const schema = [
    {
        title: 'Agent',
        description: 'valeurTypeOrganisation.designation',
        className: 'border-bottom'
    },
    {
        title: 'Durée(H)',
        description: 'nombreHeure',
        className: 'border-bottom'
    },
    {
        title: 'Motif',
        description: 'motif',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
