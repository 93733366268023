import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, DB_NODE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';

// FIXME: LES ACTIONS (ETAT) DOIVENT TENIR COMPTE DES SERVICES

export function DetailEvolutionStatutRevendeur() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({ nodeName: 'notificationsZone' });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = {
            etat,
            creator,
            idNotificationZone: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_NOTIFICATION_ZONE,
                payload,
                reduxNodeName: DB_NODE.NOTIFICATION_ZONE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_NOTIFICATION_ZONE_BY_ID}
            nodeName={DB_NODE.NOTIFICATION_ZONE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Paiement compte exécution charge"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .EVOLUTION_STATUT_REVENDEUR
                        .INITIER_NOTIFICATION_EVOLUTION_STATUT_REVENDEUR,
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .EVOLUTION_STATUT_REVENDEUR
                        .LECTURE_NOTIFICATION_EVOLUTION_STATUT_REVENDEUR,
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .EVOLUTION_STATUT_REVENDEUR
                        .VALIDER_NOTIFICATION_EVOLUTION_STATUT_REVENDEUR
                ]}
            >
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
