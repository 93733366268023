import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../../releve/generic';
import { URL_CONST } from '../../../../util';
import {
    MarchandSelector,
    MouvementCardsComponent
} from '../../../../components';
import { commandeMapper } from './mapper';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedMarchand: {}
};

export function RapportCommandeFlashSansCorrespondanceForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const displayTypeBussiness = (type) => {
        if (type === 'TraditionnelBusiness') return 'Traditionnel';
        if (type === 'BusinessModel') return 'Classique';
        return 'Sim';
    };
    const { fields, elements } = formState;
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedMarchand } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payloadToAdd = {};
        if (selectedMarchand.code) {
            payloadToAdd.codeMarchand = selectedMarchand.code;
        }
        const payload = {
            dateDebut,
            dateFin,
            ...payloadToAdd
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_REFERENCE_NON_IDENTIFIEE,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher,
                formater: (data) =>
                    data.map((item) => {
                        const correspondances = [];
                        if (item?.commandes?.length) {
                            let toAdd = '';
                            item.commandes.forEach((elm) => {
                                toAdd = `${elm?.client?.designation || ''} | ${
                                    elm?.client?.numeroContact || ''
                                } | ${elm?.user?.prenom || ''} ${
                                    elm?.user?.nom || ''
                                }`;
                            });
                            correspondances.push(toAdd);
                        }
                        return {
                            ...item,
                            quantite: item?.montant,
                            correspondance: correspondances.join('; \n ')
                        };
                    })
            })
        );
    };
    return (
        <>
            <MainReportComponentLarge
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={commandeMapper}
                dataList={
                    elements.mouvements?.value?.map((item) => ({
                        ...item,
                        business: displayTypeBussiness(item.typeBusiness)
                    })) || []
                }
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                topOfChart={MouvementCardsComponent}
            >
                <MarchandSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
