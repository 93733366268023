import React from 'react';
import { ListCompteCautionOrganisationForm } from './list-balance';

export function CompteCautionOrganisationViewLayout() {
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Caution organisation</h1>
            </div>
            <ListCompteCautionOrganisationForm />
        </div>
    );
}
