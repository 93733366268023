import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponent } from '../releve/generic';
import {
    URL_CONST,
    agregationMouvement,
    traitementMouvement
} from '../../../util';
import { ReleveEmoneySelector } from '../../../components';
import { getTypeEntite } from './util';

const defaultFields = {
    debut: '',
    fin: '',
    previewValue: '',
    configuration: 'SuperDealer',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isDisplayChart: false,
    selectedCompte: {},
    selectedSuperDealer: {},
    selectedCashCollecteur: {},
    selectedSuperDealerCompte: {},
    isFetching: false,
    aggregated: {},
    devise: '',
    chartTitle: '',
    yAxisTittle: ''
};

const mapper = [
    {
        dataExtractor: (item) => getTypeEntite(item),
        displayName: 'Type'
    },
    {
        field: 'soldePrecedent',
        displayName: 'solde ouverture'
    },
    {
        field: 'entree',
        displayName: 'entree'
    },
    {
        field: 'sortie',
        displayName: 'sortie'
    },
    {
        field: 'soldeActuel',
        displayName: 'solde fermeture'
    },
    {
        field: 'dateCreation',
        displayName: 'date création'
    }
];

export function ReleveEmoneyForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements', 'comptes']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const fetchOnSuccess = ({ element }) => {
        const agregees = agregationMouvement(element || []);
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'aggregated',
                data: agregees
            }
        });
    };

    const formatDataOnReturnSuccess = (mvts) => {
        const compte = formState?.elements?.comptes?.value?.find(
            (cpt) => cpt.id === fields.selectedSuperDealerCompte?.code
        );
        const newListMvts = compte
            ? mvts.map((mvt) => ({
                  ...mvt,
                  devise: compte?.devise,
                  numero: compte?.numero,
                  institutionFinanciere: compte?.institutionFinanciere
              }))
            : mvts;
        return traitementMouvement(newListMvts).sort(
            (a, b) => b.dateCreation - a.dateCreation
        );
    };
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedCompte } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const devise = selectedCompte?.designation?.split(' ').reverse()?.[0];
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'devise',
                data: devise
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'chartTitle',
                data: `Compte : ${selectedCompte?.designation || ''}`
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'yAxisTittle',
                data: `Montant (${devise || ''})`
            }
        });

        // FIXME: use idLimiCredit instead of fixed id
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload: {
                    valeurTypeProprietaire:
                        fields?.selectedSuperDealerCompte?.code,
                    // valeurTypeProprietaire: selectedCompte?.idLimitCredit,
                    typeProprietaire: DB_NODE.CAISSE,
                    dateDebut,
                    dateFin
                },
                functionName: URL_CONST.GET_LIST_MOUVEMENT_PLAGE,
                etat: elements.mouvements.status,
                formDispatcher,
                formater: formatDataOnReturnSuccess,
                // formater: mouvementsFormater,
                onSuccess: fetchOnSuccess,
                reduxDispatcher
            })
        );
    };
    return (
        <MainReportComponent
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={mapper}
            dataList={
                elements.mouvements?.value ||
                [
                    // {
                    //     creator: '05gXaYB0QHelq0RGGsJOeiv43mP2',
                    //     dateCreation: 1684856165976,
                    //     soldePrecedent: 12,
                    //     typeOperation: 'SORTIE',
                    //     valeurTypeProprietaire: 'hNtD44YawdZRXt3G0R4P',
                    //     soldeActuel: 9,
                    //     montant: 10,
                    //     estRetarder: false,
                    //     dateDerniereModification: 1684856165976,
                    //     typeEntite: 'ApprovisionnementMonnaie',
                    //     estBudget: false,
                    //     typeProprietaire: 'Compte',
                    //     dateDerniereModificationId:
                    //         '1684856165976Ki5uu67bGKR6rk5TxeUc',
                    //     dateCreationId: '1684856165976Ki5uu67bGKR6rk5TxeUc',
                    //     valeurTypeEntite: 'bR3QCaVLh0hjQwhmNdBZ',
                    //     id: 'Ki5uu67bGKR6rk5TxeUc'
                    // }
                ]
            }
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
            // topOfChart={MouvementCardsComponent}
        >
            <ReleveEmoneySelector
                isReleve
                formState={formState}
                formDispatcher={formDispatcher}
            />
        </MainReportComponent>
    );
}
