import { buildYupErrorObject, FORM_CONST } from '@napp-inc/jnapp-util';
import React from 'react';
import { BsSearch } from 'react-icons/bs';
import { TiArrowSortedDown } from 'react-icons/ti';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomCollapse } from '../container';
import { InputFeedback } from './input-feedback';

export function InputSearchGroup({
    placeholder = 'Rechercher',
    id,
    type = 'text',
    name,
    disabled = false,
    label = 'Search',
    formDispatcher,
    labelClassName = 'col-2 col-sm-2',
    divClassName = 'col-10 col-sm-10',
    readOnly = false,
    formState = {},
    isInputGroup = true,
    inputClassName,
    uiValidator,
    buttonDisabled = false,
    data = [],
    hasMore = false,
    next = () => {},
    callback = () => {},
    displayMapper = [],
    isVisibleProperty = 'isVisible'
}) {
    const { error, fields, form } = formState;
    const handleVisisbility = () => {
        formDispatcher({
            type: FORM_CONST.TEXT_FIELD_CHANGE,
            payload: {
                field: [isVisibleProperty],
                data: !fields[isVisibleProperty]
            }
        });
    };
    const handleChange = async (event) => {
        const { value } = event.target;
        formDispatcher({
            type: FORM_CONST.TEXT_FIELD_CHANGE,
            payload: { field: name, data: value }
        });
        if (uiValidator && form.isSubmitted) {
            uiValidator
                .validate(
                    { ...fields, [name]: value },
                    {
                        abortEarly: false
                    }
                )
                .then(() => {
                    formDispatcher({
                        type: FORM_CONST.VALIDATION_SUCCESS
                    });
                })
                .catch((erreur) => {
                    if (erreur.name === 'ValidationError') {
                        const err = buildYupErrorObject(erreur);
                        formDispatcher({
                            type: FORM_CONST.SET_FIELDS_ERROR,
                            payload: {
                                message: { ...err },
                                status: FORM_CONST.CREATION_ECHEC
                            }
                        });
                    }
                });
        }
    };
    return (
        <>
            <div className="row">
                {label ? (
                    <label
                        htmlFor={id}
                        className={`${
                            isInputGroup ? 'form-label' : 'col-form-label'
                        } ${labelClassName}`}
                    >
                        {label}
                    </label>
                ) : null}
                <div className={divClassName}>
                    <div className="input-group mb-3 has-validation">
                        <input
                            type={type}
                            id={id}
                            name={name}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={handleChange}
                            className={`form-control ${
                                error && error[name] ? `is-invalid` : null
                            } ${inputClassName}`}
                            placeholder={placeholder}
                            aria-label="Recipient's search"
                            aria-describedby="basic-addon2"
                        />
                        {buttonDisabled ? (
                            <div className="input-group-text" id="basic-addon2">
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <button
                                type="button"
                                className="input-group-text pointer"
                                id="basic-addon2"
                                onClick={callback}
                                disabled={buttonDisabled}
                            >
                                <BsSearch />
                            </button>
                        )}
                        {data.length ? (
                            <span
                                className="input-group-text pointer"
                                id="basic-addon2"
                            >
                                <TiArrowSortedDown
                                    onClick={handleVisisbility}
                                />
                            </span>
                        ) : null}
                        <InputFeedback>{error && error[name]}</InputFeedback>
                    </div>
                </div>
            </div>
            <CustomCollapse isIn={fields[isVisibleProperty]}>
                <div
                    className="col-sm-auto overflow-auto"
                    style={{ height: 240 }}
                >
                    <InfiniteScroll
                        dataLength={data.length}
                        next={next}
                        hasMore={hasMore}
                        loader={<span>Loading...</span>}
                    >
                        <ul className="list-group">
                            {data.map((item) => (
                                <li key={item.id} className="list-group-item">
                                    {displayMapper.map((prop) => (
                                        <span key={prop}>{item[prop]}</span>
                                    ))}
                                </li>
                            ))}
                        </ul>
                    </InfiniteScroll>
                </div>
            </CustomCollapse>
        </>
    );
}
