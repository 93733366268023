import { ref, uploadBytesResumable } from '@firebase/storage';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    ADMINISTRATION_CONST,
    CONTRATS,
    DB_NODE,
    DEVISE,
    formInitialState,
    FORM_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    CustomSwitchButton,
    DynamicFormMounted,
    FormWrapper
    // CustomToast
} from '../../../components';
import { imgPieceIdentite } from '../../../firebase';
import { REDUX_NODE_NAME } from '../../../redux';
import {
    asyncGenerator,
    ConfirmCreateUserMarchandSchema as uiValidator,
    uploadTaskPromise,
    URL_CONST
} from '../../../util';
import { mapper, pieceIdentiteMapper } from './form-fields';

const defaultFields = {
    newPersonnePrenom: '',
    newPersonneNom: '',
    newPersonnePostnom: '',
    newPersonneSexe: {},
    newPersonneTypeCarteIdentite: {},
    newPersonneNumeroCarteIdentite: '',
    newPersonneNomImage: {},
    newPersonneEmailPersonnelle: '', // Only for napp user and KinDB users : if exists, don't send email
    newPersonneEmail: '', // for all organisation
    newPersonneNumeroTel: '', // Le numéro de téléphone passe en priorité ce qui fait que s'il est renseigné, il devient un moyen de connexion comme l'email, le SDK ne le prend pas encore en charge
    valeurTypeProprietaire: {},
    profilUserDedier: {}, // for all organisation
    typeContrat: {}, // Only for Napp User
    codeFonction: {}, // Only for Napp User
    newAdresseAdresseUne: '',
    selectedInstitutionFinanciere: {},
    selectedDevise: {},
    numeroCompte: '',
    limit: 0,
    selectedCaisse: {},
    listCaisses: [],
    estBanque: true,
    file: {},
    logo: '',
    logoTraiter: '',
    logoStatus: ''
};

/**
 * Dependencies for loading agence of the organisation
 */
// const defaultDependencies = {
//     fields: [
//         // 'secteurs',
//         'banques',
//         'profilUserDedier',
//         // 'fonctionsDisponible',
//         'agencesMarchand'
//         // 'agences'
//     ]
// };

// FIXME: Change Alert to Toast and See HTTP response
// TODO: Fonction pour rajouter les caisses disponibles à affecter à un utilisateur
export function DroitAccessCreationUtilisateur() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        institutionsFinanciere,
        etatInstitutionsFinanciere,
        profilUserDedier,
        etatProfilUserDedier,
        fonctionDisponible,
        etatFonctionDisponible,
        etatSecteurs,
        secteurs,
        agences,
        etatAgences
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        etatSecteurs: reduxState.etat.secteurs.etat,
        secteurs: reduxState.secteurs,
        agencesMarchand: reduxState.agencesMarchand,
        etatAgencesMarchand: reduxState.etat.agencesMarchand.etat,
        agences: reduxState.agences,
        etatAgences: reduxState.etat.agences.etat,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionsFinanciere: reduxState.etat.institutionsFinanciere.etat,
        profilUserDedier: reduxState.profilUserDedier,
        etatProfilUserDedier: reduxState.etat.profilUserDedier.etat,
        fonctionDisponible: reduxState.fonctionDisponible,
        etatFonctionDisponible: reduxState.etat.fonctionDisponible.etat
    }));
    const { idToken, typeEntite, creator } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const defaultDependencies = {};
    if (
        typeEntite === TYPE_ORGANISATION.SUPER_DEALER ||
        typeEntite === TYPE_ORGANISATION.MARCHAND ||
        typeEntite === TYPE_ORGANISATION.CASH_COLLECTEUR ||
        typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
    ) {
        defaultDependencies.fields = ['banques', 'profilUserDedier', 'agences'];
    }
    if (typeEntite === ADMINISTRATION_CONST.NAPP) {
        defaultDependencies.fields = [
            'banques',
            'profilUserDedier',
            'secteurs',
            'fonctionsDisponible'
        ];
    }
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        files: ['logo']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_AGENCE,
                nodeName: DB_NODE.AGENCE,
                etat: etatAgences,
                isCondition:
                    typeEntite === TYPE_ORGANISATION.SUPER_DEALER ||
                    typeEntite === TYPE_ORGANISATION.MARCHAND ||
                    typeEntite === TYPE_ORGANISATION.CASH_COLLECTEUR ||
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE,
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs,
                isCondition: typeEntite === ADMINISTRATION_CONST.NAPP
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionsFinanciere
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PROFIL_USER_TYPE_ENTREPRISE,
                nodeName: DB_NODE.PROFIL_USER_DEDIE,
                etat: etatProfilUserDedier
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_FONCTION_DISPONIBLE,
                nodeName: REDUX_NODE_NAME.FONCTION,
                etat: etatFonctionDisponible,
                isCondition: typeEntite === ADMINISTRATION_CONST.NAPP
            }
        ]
    });

    const checkPropertyToAdd = () => {
        const props = { estLimit: false, estCaisse: false };
        const targetedFonction = fonctionDisponible.find(
            (element) => element.code === fields.codeFonction.code
        );
        const targetedProfil = profilUserDedier.find(
            (element) => element.code === fields.profilUserDedier.code
        );
        if (
            (targetedFonction && targetedFonction.typeEntite === 'Caisse') ||
            targetedProfil?.typeEntite === 'Caisse'
        )
            props.estCaisse = true;
        if (
            (targetedFonction && targetedFonction.typeEntite === 'Limit') ||
            targetedProfil?.typeEntite === 'Limit'
        )
            props.estLimit = true;
        return props;
    };
    const addUserCaisse = () => {
        if (!fields.selectedCaisse.id) return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listCaisses',
                identifier: 'id',
                data: {
                    id: fields.selectedCaisse.id, // to complete with the right reference
                    designation: fields.selectedCaisse.id // to complete with the right reference
                }
            }
        });
    };
    const properties = checkPropertyToAdd();
    const getResults = async ({ tab = [{ file: fields.file }] }) => {
        const imgTab = [];
        const promises = tab.map((item) =>
            asyncGenerator({
                imgRef: imgPieceIdentite,
                element: { file: fields.file } || item,
                formDispatcher
            })
        );
        const values = await Promise.all(promises);
        // eslint-disable-next-line no-restricted-syntax
        for (const promise of values) {
            if (promise) {
                const uploadTask = uploadBytesResumable(
                    ref(imgPieceIdentite, promise.metadata.name),
                    fields.file
                );
                // eslint-disable-next-line no-await-in-loop
                const downloadURL = await uploadTaskPromise(uploadTask);
                imgTab.push({
                    imgUrl: downloadURL,
                    img: promise.metadata.name
                });
            }
        }
        return imgTab;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let payload = {};
        const propertyToAdd = {};
        // if (!fields.newPersonneEmail || !fields.newPersonneNumeroTel) {
        //     formDispatcher({
        //         type: FORM_CONST.SET_FORM_ERROR,
        //         payload: {
        //             message: 'Email ou Numéro Téléphone manquant',
        //             status: FORM_CONST.MODIFICATION_ECHEC
        //         }
        //     });
        //     return;
        // }
        if (
            !fields.logo &&
            (typeEntite === ADMINISTRATION_CONST.NAPP ||
                typeEntite === ADMINISTRATION_CONST.KIN_DB)
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Image Obligatoire',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        if (fields.estBanque && fields.selectedInstitutionFinanciere.code) {
            propertyToAdd.compte = {
                institutionFinanciere: {
                    code: fields.selectedInstitutionFinanciere.code,
                    designation:
                        fields.selectedInstitutionFinanciere.designation
                },
                numero: fields.numeroCompte,
                devise: fields.selectedDevise.code
            };
        }
        if (properties.estCaisse) {
            propertyToAdd.caisses = fields.listCaisses;
        }
        if (properties.estLimit) {
            propertyToAdd.limitCredit = fields.limit;
        }
        const payloadToAddIfCashCollector = {};
        const payloadToAddIfNotCashCollector = {};
        if (typeEntite === TYPE_ORGANISATION.CASH_COLLECTEUR) {
            payloadToAddIfCashCollector.profilUser = {
                code: fields.profilUserDedier.code,
                designation: fields.profilUserDedier.designation
            };
        }
        if (
            typeEntite === ADMINISTRATION_CONST.NAPP ||
            typeEntite === ADMINISTRATION_CONST.KIN_DB
        ) {
            payloadToAddIfNotCashCollector.nomImage = fields.file?.name;
            payloadToAddIfNotCashCollector.typeCarteIdentite =
                fields.newPersonneTypeCarteIdentite.code;
            payloadToAddIfNotCashCollector.numeroCarteIdentite =
                fields.newPersonneNumeroCarteIdentite;
        }
        if (
            typeEntite === ADMINISTRATION_CONST.NAPP ||
            typeEntite === ADMINISTRATION_CONST.KIN_DB ||
            typeEntite === TYPE_ORGANISATION.CASH_COLLECTEUR ||
            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
        ) {
            payload = {
                newPersonne: {
                    email: fields.newPersonneEmail,
                    emailPersonnelle: fields.newPersonneEmailPersonnelle,
                    nom: fields.newPersonneNom,
                    postnom: fields.newPersonnePostnom,
                    prenom: fields.newPersonnePrenom,
                    sexe: fields.newPersonneSexe.code,
                    // typeCarteIdentite: fields.newPersonneTypeCarteIdentite.code,
                    // numeroCarteIdentite: fields.newPersonneNumeroCarteIdentite,
                    // nomImage: fields.file?.name,
                    ...payloadToAddIfNotCashCollector,
                    numeroTel: fields.newPersonneNumeroTel // Le numero de tel ne peut pas exister en meme temps que la propriété email, sinon l'utilisateur se connectera via numero de tel, sdk pas encore pris en charge
                },
                typeEntite,
                valeurTypeProprietaire: {
                    code: fields.valeurTypeProprietaire.code,
                    designation: fields.valeurTypeProprietaire.designation
                },
                creator,
                typeContrat: fields.typeContrat.code,
                codeFonction: fields.codeFonction.code,
                ...payloadToAddIfCashCollector,
                newAdresse: {
                    adresseUne: fields.newAdresseAdresseUne
                },
                ...propertyToAdd
            };
        }
        if (
            typeEntite !== ADMINISTRATION_CONST.NAPP &&
            typeEntite !== ADMINISTRATION_CONST.KIN_DB &&
            typeEntite !== TYPE_ORGANISATION.CASH_COLLECTEUR
        ) {
            payload = {
                newPersonne: {
                    email: fields.newPersonneEmail,
                    nom: fields.newPersonneNom,
                    postnom: fields.newPersonnePostnom,
                    prenom: fields.newPersonnePrenom,
                    sexe: fields.newPersonneSexe.code,
                    typeCarteIdentite: fields.newPersonneTypeCarteIdentite.code,
                    numeroCarteIdentite: fields.newPersonneNumeroCarteIdentite,
                    // nomImage: fields.newPersonneNomImage?.name, // Pour le moment on enleve la contrainte
                    numeroTel: fields.newPersonneNumeroTel // Le numero de tel ne peut pas exister en meme temps que la propriété email, sinon l'utilisateur se connectera via numero de tel, sdk pas encore pris en charge
                },
                typeEntite,
                valeurTypeProprietaire: {
                    code: fields.valeurTypeProprietaire.code,
                    designation: fields.valeurTypeProprietaire.designation
                },
                creator,
                profilUser: {
                    code: fields.profilUserDedier.code,
                    designation: fields.profilUserDedier.designation
                },
                newAdresse: {
                    adresseUne: fields.newAdresseAdresseUne
                },
                ...propertyToAdd
            };
        }
        // if (
        //     typeEntite !== ADMINISTRATION_CONST.NAPP &&
        //     typeEntite !== ADMINISTRATION_CONST.KIN_DB
        // ) {
        //     payloadToAdd.profilUserDedier.code = fields.profilUserDedier.code;
        //     payloadToAdd.profilUserDedier.designation = fields.profilUserDedier.designation;
        // }
        // if (
        //     typeEntite === ADMINISTRATION_CONST.NAPP ||
        //     typeEntite === ADMINISTRATION_CONST.KIN_DB
        // ) {
        //     payloadToAdd.newPersonne.emailPersonnel =
        //         fields.newPersonneEmailPersonnelle;
        //     payloadToAdd.fonction.codeFonction = fields.codeFonction.code;
        //     payloadToAdd.compte = {
        //         institutionFinanciere: fields.selectedInstitutionFinanciere,
        //         numero: fields.numeroCompte,
        //         devise: fields.selectedDevise
        //     };
        // }
        // payload = {
        //     newPersonne: {
        //         ...payloadToAdd.newPersonne,
        //         nom: fields.newPersonneNom,
        //         postnom: fields.newPersonnePostnom,
        //         prenom: fields.newPersonnePrenom,
        //         sexe: fields.newPersonneSexe.code,
        //         typeCarteIdentite: fields.newPersonneTypeCarteIdentite.code,
        //         numeroCarteIdentite: fields.newPersonneNumeroCarteIdentite,
        //         nomImage: fields.newPersonneNomImage?.name,
        //         numeroTel: fields.newPersonneNumeroTel
        //     },
        //     typeEntite,
        //     valeurTypeProprietaire: {
        //         code: fields.valeurTypeProprietaire.code,
        //         designation: fields.valeurTypeProprietaire.designation
        //     },
        //     creator,
        //     typeContrat: fields.typeContrat.code,
        //     ...payloadToAdd.fonction, // Only for User Napp
        //     newAdresse: {
        //         adresseUne: fields.newAdresseAdresseUne
        //     }
        // };

        if (fields.file.name) {
            const element = { file: { ...fields.file } };
            formDispatcher({
                type: FORM_CONST.CREATION_EN_COURS
            });
            const image = await getResults({ tab: [element] });
            if (image.length) {
                const theUserImg = image[0];
                if (theUserImg.imgUrl) {
                    formDispatcher(
                        submitPostAction({
                            idToken,
                            fields,
                            functionName: URL_CONST.POST_EMPLOYE,
                            reduxDispatcher,
                            reduxNodeName: DB_NODE.USER,
                            payload: {
                                ...payload,
                                newPersonne: {
                                    ...payload.newPersonne,
                                    userImg: theUserImg.imgUrl
                                }
                            },
                            uiValidator
                        })
                    );
                }
            }
        } else {
            formDispatcher(
                submitPostAction({
                    idToken,
                    fields,
                    functionName: URL_CONST.POST_EMPLOYE,
                    reduxDispatcher,
                    reduxNodeName: DB_NODE.USER,
                    payload,
                    uiValidator
                })
            );
        }
    };
    /**
     * Function des vues à rendre
     * @returns {JSX.Element}
     */
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs,
                    isCondition: typeEntite === ADMINISTRATION_CONST.NAPP
                },
                {
                    dependency: 'banques',
                    etat: etatInstitutionsFinanciere
                },
                {
                    dependency: 'profilUserDedier',
                    etat: etatProfilUserDedier
                },
                {
                    dependency: 'fonctionsDisponible',
                    etat: etatFonctionDisponible,
                    isCondition: typeEntite === ADMINISTRATION_CONST.NAPP
                },
                {
                    dependency: 'agences',
                    etat: etatAgences,
                    isCondition:
                        typeEntite === TYPE_ORGANISATION.SUPER_DEALER ||
                        typeEntite === TYPE_ORGANISATION.MARCHAND ||
                        typeEntite === TYPE_ORGANISATION.CASH_COLLECTEUR ||
                        typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                }
            ]}
        >
            <h5 className="Subhead-heading-label">Création utilisateur</h5>
            <p className="separator-right">Informations sur l&apos;identité</p>
            <DynamicFormMounted
                formDispatcher={formDispatcher}
                formState={formState}
                uiValidator={uiValidator}
                fieldsMapper={mapper}
            />
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    typeEntite !== TYPE_ORGANISATION.CASH_COLLECTEUR &&
                    typeEntite !== TYPE_ORGANISATION.DISTRIBUTEUR
                }
            >
                <p className="separator-right">
                    Informations sur la pièce d&apos;identité
                </p>
                <DynamicFormMounted
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    fieldsMapper={pieceIdentiteMapper}
                />
            </ConditionalRenderingWrapper>
            <p className="separator-right">Informations de contacts</p>
            <CustomRow>
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    name="newPersonneNumeroTel"
                    id="newPersonneNumeroTel"
                    placeholder="Numéro de téléphone"
                    label="Numéro de téléphone*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    isInputGroup={false}
                />
            </CustomRow>
            {/* Il faudra rajouter une condition en fonction de la valeur type Entite :"KinDB" et non entité: "SuperDealer" */}
            <CustomRow
                isShouldBeRendered={typeEntite === ADMINISTRATION_CONST.NAPP}
            >
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    type="email"
                    name="newPersonneEmailPersonnelle"
                    id="newPersonneEmailPersonnelle"
                    placeholder="Adresse Email"
                    label="Email*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    isInputGroup={false}
                />
            </CustomRow>
            <p className="separator-right">
                Informations liées à l&apos;entreprise
            </p>
            <CustomRow>
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    type="email"
                    name="newPersonneEmail"
                    id="newPersonneEmailID"
                    placeholder="Email de l'utilisateur"
                    label="Email*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    isInputGroup={false}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={typeEntite === ADMINISTRATION_CONST.NAPP}
            >
                <CustomDropdown
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    name="valeurTypeProprietaire"
                    id="valeurTypeProprietaire"
                    options={secteurs}
                    selectionClassName="form-select-md"
                    defaultOption="Selectionnez un secteur administratif"
                    label="Secteur Administratif*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    typeEntite === TYPE_ORGANISATION.SUPER_DEALER ||
                    typeEntite === TYPE_ORGANISATION.MARCHAND ||
                    typeEntite === TYPE_ORGANISATION.CASH_COLLECTEUR ||
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                }
            >
                <CustomDropdown
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    name="valeurTypeProprietaire"
                    id="valeurTypeProprietaire"
                    options={agences}
                    selectionClassName="form-select-md"
                    defaultOption="Agence ou Succursale"
                    label="Agence*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                />
            </CustomRow>
            {typeEntite === ADMINISTRATION_CONST.NAPP ? (
                <>
                    <CustomRow>
                        <CustomDropdown
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            name="typeContrat"
                            id="typeContrat"
                            options={CONTRATS.TYPES_CONTRATS}
                            selectionClassName="form-select-md"
                            defaultOption="Type de contrat"
                            label="Contrat*"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            name="codeFonction"
                            id="codeFonctionId"
                            options={fonctionDisponible}
                            selectionClassName="form-select-md"
                            defaultOption="Fonction"
                            label="Fonction*"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                        />
                    </CustomRow>
                    <CustomRow isShouldBeRendered={!!properties.estLimit}>
                        <CustomInput
                            type="number"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            name="limit"
                            id="creationUtilisateurLimitId"
                            placeholder="Définir une limite"
                            label="Limite*"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                            isInputGroup={false}
                        />
                    </CustomRow>
                    <CustomRow isShouldBeRendered={!!properties.estCaisse}>
                        <CustomDropdown
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            name="selectedCaisse"
                            id="selectedCaisseId"
                            options={fonctionDisponible}
                            selectionClassName="form-select-md"
                            defaultOption="Caisses"
                            label="Caisses*"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                        />
                        <CustomAddRemoveButton
                            className="btn-success col-sm-2"
                            onClick={addUserCaisse}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomSwitchButton
                            formDispatcher={formDispatcher}
                            formState={formState}
                            id="estBanqueId"
                            text="Renseigner les informations bancaires?"
                            value={fields.estBanque}
                            name="estBanque"
                            divClassName="ms-2 col-md-8"
                        />
                    </CustomRow>
                    <CustomRow isShouldBeRendered={!!fields.estBanque}>
                        <CustomCol className="col-2">
                            <CustomLabel>Info Bancaire*</CustomLabel>
                        </CustomCol>
                        <CustomCol className="col-10">
                            <CustomRow>
                                <CustomDropdown
                                    idProperty="code"
                                    propertyToDisplay="designation"
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    uiValidator={uiValidator}
                                    name="selectedInstitutionFinanciere"
                                    id="creationUtilisateurNumeroCompteInstitutFinanciereId"
                                    options={institutionsFinanciere}
                                    selectionClassName="form-select-md"
                                    defaultOption="Institution Financière"
                                    divClassName="col-sm-12"
                                />
                            </CustomRow>
                            <CustomRow>
                                <CustomInput
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    uiValidator={uiValidator}
                                    name="numeroCompte"
                                    id="creationUtilisateurNumeroCompteInstitutFinanciereId"
                                    placeholder="Numéro compte"
                                    divClassName="col-sm-12"
                                    isInputGroup={false}
                                />
                            </CustomRow>
                            <CustomRow>
                                <CustomDropdown
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    uiValidator={uiValidator}
                                    name="selectedDevise"
                                    id="creationUtilisateurSelectedDeviseInstitutionFinanciereId"
                                    options={DEVISE.MONNAIES}
                                    selectionClassName="form-select-md"
                                    defaultOption="Devise"
                                    divClassName="col-sm-12"
                                />
                            </CustomRow>
                        </CustomCol>
                    </CustomRow>
                </>
            ) : null}
            <CustomRow
                isShouldBeRendered={
                    typeEntite !== ADMINISTRATION_CONST.NAPP &&
                    typeEntite !== ADMINISTRATION_CONST.KIN_DB
                }
            >
                <CustomDropdown
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    name="profilUserDedier"
                    id="profilUserDedier"
                    options={profilUserDedier}
                    selectionClassName="form-select-md"
                    defaultOption="Profile"
                    label="Profil*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                />
            </CustomRow>

            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                }
            >
                <CustomRow isShouldBeRendered={!!properties.estLimit}>
                    <CustomInput
                        type="number"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        name="limit"
                        id="creationUtilisateurLimitId"
                        placeholder="Définir une limite"
                        label="Limite*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        isInputGroup={false}
                    />
                </CustomRow>
                <CustomRow isShouldBeRendered={!!properties.estCaisse}>
                    <CustomDropdown
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        name="selectedCaisse"
                        id="selectedCaisseId"
                        options={fonctionDisponible}
                        selectionClassName="form-select-md"
                        defaultOption="Caisses"
                        label="Caisses*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                    />
                    <CustomAddRemoveButton
                        className="btn-success col-sm-2"
                        onClick={addUserCaisse}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    name="newAdresseAdresseUne"
                    id="newAdresseAdresseUne"
                    placeholder="Adresse Physique"
                    label="Adresse physique*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    isInputGroup={false}
                />
            </CustomRow>
            <CustomButtonLoader
                className="btn mt-3 w-25"
                text="Créer Utilisateur"
                disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                isActionEncours={form.status === FORM_CONST.CREATION_EN_COURS}
            />
        </FormWrapper>
    );
    return renderContent();
}
