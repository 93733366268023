import { useSelector } from 'react-redux';
import { CustomRow } from '../container';
import { InputDeviseComponent } from './input-devise';
import { useFormatPeruRetourMontantDeviseList } from '../../util';

export function SaisiMontantForm({
    formDispatcher,
    formState,
    percuRetourName,
    isPercu = true
}) {
    const { monnaiesLocale } = useSelector((reduxState) => ({
        monnaiesLocale: reduxState.monnaiesLocale,
        taux: reduxState.taux
    }));
    useFormatPeruRetourMontantDeviseList({
        monnaiesLocale,
        formDispatcher,
        percuRetourName
    });
    return (
        <>
            <CustomRow>
                <h1 className="lead">{`Saisissez ${
                    isPercu ? 'le montant perçu' : 'le retour'
                }`}</h1>
            </CustomRow>
            {formState.fields?.[percuRetourName].map((item) => (
                <CustomRow key={item.id}>
                    <InputDeviseComponent
                        percuRetourName={percuRetourName}
                        devise={item}
                        formDispatcher={formDispatcher}
                    />
                </CustomRow>
            ))}
        </>
    );
}
