import {
    submitPutAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetOrganisationDetail,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CATEGORIE_MARCHAND,
    DB_NODE,
    formInitialState,
    FORM_CONST,
    NATURE_PRODUIT
} from '@napp-inc/jnapp-util';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { BsCheckCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCollapse,
    CustomConfirmButton,
    CustomDropdown,
    CustomInput,
    CustomRadioButton,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    SimpleTable
} from '../../../../components';
import { logoProduitRef } from '../../../../firebase';
import {
    asyncGenerator,
    ChoixCategorieSchema as uiValidator,
    uploadTaskPromise,
    URL_CONST
} from '../../../../util';
import { ProduitAirtime } from './produit-airtime';
import { ProduitEmoney } from './produit-emoney';
import { ProduitMateriel } from './produit-materiel';

const defaultFields = {
    categorie: {
        code: '',
        designation: ''
    },
    nature: {
        code: '',
        designation: ''
    },
    monnaieDesignation: '',
    monnaieCode: '',
    typeRadio: '',
    sousTypeRadio: '',
    selectedDevise: {},
    devises: [],
    recrutementProduitDesignation: '',
    recrutementProduitCode: '',
    recrutementProduitTillNumber: '',
    recrutementProduitDigit: 0,
    primaireProduitDesignation: '',
    primaireProduitCode: '',
    primaireProduitPrixSuperDealer: 0.0,
    primaireProduitPrixAchat: 0.0,
    primaireProduitPrixVente: 0.0,
    recrutementProduitPrixDeVente: 0.0,
    deriveProduitDesignation: '',
    syntaxeProduitPrimaire: '',
    syntaxeProduitDerive: '',
    deriveProduitCode: '',
    deriveProduitQuantiteADebiter: 0,
    deriveProduitPrixVente: 0,
    airtimeProduits: [],
    logo: '',
    file: {},
    logoTraiter: '',
    logoStatus: '',
    logoDetails: [],
    prefixAirtime: '',
    prefixesAirtime: [],
    syntaxe: '',
    isSms: false,
    syntaxeSmsDestinaire: '',
    estTypeEmoney: false
};

const defaultDependencies = { fields: ['devises'] };

const defaultFiles = ['logo'];
export function ChoixCategorieMarchand() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser, organisation, devises, etatDevise } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation,
            devises: reduxState.devises,
            etatDevise: reduxState.etat.devises.etat
        }));
    const { idToken } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        files: defaultFiles,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const {
        codeMarchand,
        codeCategorieMarchand,
        designationCategorieMarchand
    } = useGetOrganisationDetail({
        organisation
    });
    const { fields, form } = formState;
    const { categorie } = fields;
    const { status } = form;
    const typeCategories =
        CATEGORIE_MARCHAND.DIFFERENTS_CATEGORIE_AND_TYPE_MARCHAND.find(
            ({ code }) => code === categorie.code
        )?.typeMarchand;

    /**
     * @description récupérer les devises
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_ALL_DEVISE,
                nodeName: DB_NODE.DEVISE,
                etat: etatDevise
            }
        ]
    });

    /**
     * @description action pour rajouter les produits
     */
    const addProduct = () => {
        const data = {};
        if (
            fields.typeRadio === CATEGORIE_MARCHAND.AIRTIME?.code &&
            categorie.code ===
                CATEGORIE_MARCHAND.MARCHAND_PRODUIT_ELECTRONIQUE.code
        ) {
            const logoData = {
                logo: fields.logo,
                file: fields.file,
                logoTraiter: fields.logoTraiter,
                name: fields.file.name
            };
            if (
                fields.nature.code === NATURE_PRODUIT.NATURE_PRODUIT_RECRUTEMENT
            ) {
                data.nature = NATURE_PRODUIT.NATURE_PRODUIT_RECRUTEMENT;
                data.code = fields.recrutementProduitCode;
                data.designation = fields.recrutementProduitDesignation;
                data.tillNumber = fields.recrutementProduitTillNumber;
                data.nombreMaxDigit = fields.recrutementProduitDigit;
                data.prixVente = fields.recrutementProduitPrixDeVente;
                data.img = fields.file.name;
            }
            if (fields.nature.code === NATURE_PRODUIT.NATURE_PRODUIT_PRIMAIRE) {
                data.nature = NATURE_PRODUIT.NATURE_PRODUIT_PRIMAIRE;
                data.code = fields.primaireProduitCode;
                data.designation = fields.primaireProduitDesignation;
                data.prixAchatSuperDealer =
                    fields.primaireProduitPrixSuperDealer;
                data.prixAchat = fields.primaireProduitPrixAchat;
                data.prixVente = fields.primaireProduitPrixVente;
                data.img = fields.file.name;
            }
            if (fields.nature.code === NATURE_PRODUIT.NATURE_PRODUIT_DERIVE) {
                data.nature = NATURE_PRODUIT.NATURE_PRODUIT_DERIVE;
                data.code = fields.deriveProduitCode;
                data.designation = fields.deriveProduitDesignation;
                data.quantiteADebiter = fields.deriveProduitQuantiteADebiter;
                data.prixVente = fields.deriveProduitPrixVente;
                data.img = fields.file.name;
            }
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'code',
                    field: 'airtimeProduits',
                    data
                }
            });
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'name',
                    field: 'logoDetails',
                    data: logoData
                }
            });
        }
        if (
            fields.typeRadio === CATEGORIE_MARCHAND.MOBILE_MONEY?.code &&
            categorie.code ===
                CATEGORIE_MARCHAND.MARCHAND_PRODUIT_ELECTRONIQUE.code
        ) {
            data.code = fields.selectedDevise.code;
            data.designation = fields.selectedDevise.designation;
            if (
                !fields.selectedDevise.code ||
                !fields.selectedDevise.designation
            ) {
                return;
            }
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'code',
                    field: 'devises',
                    data
                }
            });
        }
    };
    /**
     * @description ajoute les préfix dans le tableau des préfix
     * @param {*} e
     * @returns
     */

    const addPrefixAirtime = () => {
        if (fields.prefixAirtime && fields.prefixAirtime.length) {
            const data = {
                id: fields.prefixAirtime,
                code: fields.prefixAirtime
            };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'id',
                    field: 'prefixesAirtime',
                    data
                }
            });
        }
    };

    const getResults = async ({ tab }) => {
        const produits = [];
        const promises = tab.map((item) =>
            asyncGenerator({
                imgRef: logoProduitRef,
                element: item,
                formDispatcher
            })
        );
        const values = await Promise.all(promises);
        // eslint-disable-next-line no-restricted-syntax
        for (const promise of values) {
            // if (promise) {
            const listProduit = fields.airtimeProduits.filter(
                (item) => item.img === promise.metadata.name
            );
            if (listProduit.length) {
                const newFile = fields.logoDetails.find(
                    (element) => element.file.name === promise.metadata.name
                );
                const uploadTask = uploadBytesResumable(
                    ref(logoProduitRef, promise.metadata.name),
                    newFile.file
                );
                // eslint-disable-next-line no-await-in-loop
                const downloadURL = await uploadTaskPromise(uploadTask);
                // eslint-disable-next-line no-restricted-syntax
                for (const elem of listProduit) {
                    produits.push({
                        ...elem,
                        imgUrl: downloadURL
                    });
                }
            }

            // }
        }
        return produits;
    };
    const checkSyntaxe = (stx) => {
        const keyWords = ['[NUMBER]', '[Qt]', '[PIN]'];
        return (
            stx.includes(keyWords[0]) &&
            stx.includes(keyWords[1]) &&
            stx.includes(keyWords[2]) &&
            stx.includes(keyWords[2])
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!codeMarchand) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `codeMarchand non renseigné`,
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        let payload = {
            codeCategorieMarchand: fields.typeRadio,
            codeMarchand
        };
        const payloadToAdd = {};
        if (fields.syntaxe) {
            const isValid = checkSyntaxe(fields.syntaxe);
            if (isValid) {
                payloadToAdd.syntaxe = fields.syntaxe;
                payloadToAdd.syntaxeEstSms = fields.isSms;
                // Rajout de la condition pour que la syntaweSmsDestinataire ne soit envoyé que dans le cas de isSms
                if (fields.isSms) {
                    payloadToAdd.syntaxeSmsDestinataire =
                        fields.syntaxeSmsDestinaire;
                }
            } else {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `Vérifiez le format de la syntaxe`,
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
                return;
            }
        }
        if (fields.typeRadio === CATEGORIE_MARCHAND.AIRTIME.code) {
            if (!fields.prefixesAirtime.length) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `Vérifiez renseigner les préfix`,
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
                return;
            }
            payloadToAdd.prefix = fields.prefixesAirtime.map(
                (item) => item.code
            );
            // payload = {
            //     ...payload,
            //     // produits: fields.airtimeProduits,
            //     elementUpdate: {
            //         ...payloadToAdd
            //     }
            // };

            const produits = await getResults({ tab: fields.logoDetails });
            if (!produits.length) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `produits non renseigné`,
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
                return;
            }

            payload = {
                ...payload,
                elementUpdate: {
                    ...payloadToAdd
                },
                produits
            };
        }
        if (fields.typeRadio === CATEGORIE_MARCHAND.MOBILE_MONEY.code) {
            if (
                !fields.monnaieDesignation ||
                !fields.monnaieCode ||
                !fields.devises.length
            ) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `Vérifiez les informations de la monnaie`,
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
                return;
            }
            payload = {
                ...payload,
                newMonnaie: {
                    designation: fields.monnaieDesignation,
                    code: fields.monnaieCode,
                    devises: fields.devises
                },
                elementUpdate: {
                    estTypeEmoney: fields.estTypeEmoney
                }
            };
        }
        if (
            fields.typeRadio !== CATEGORIE_MARCHAND.AIRTIME.code &&
            fields.typeRadio !== CATEGORIE_MARCHAND.MOBILE_MONEY.code &&
            categorie.code === CATEGORIE_MARCHAND.MARCHAND_PRODUIT_MATERIEL.code
        ) {
            payload = {
                ...payload,
                typeOrganisation: fields.sousTypeRadio
            };
        }
        // const newArrayLogoDetail = fields.logoDetails.filter(
        //     (item) => item.logo !== ''
        // );
        /**
         *  section to comment
         */
        // const produits = await getResults({ tab: fields.logoDetails });
        // if (!produits.length) {
        //     formDispatcher({
        //         type: FORM_CONST.SET_FORM_ERROR,
        //         payload: {
        //             message: `produits non renseigné`,
        //             status: FORM_CONST.MODIFICATION_ECHEC
        //         }
        //     });
        //     return;
        // }
        // if (produits.length) {
        // payload = {
        //     ...payload,
        //     produits
        // };
        formDispatcher(
            submitPutAction({
                idToken,
                fields,
                functionName: URL_CONST.PUT_MARCHAND_CATEGORIE,
                payload,
                uiValidator,
                reduxDispatcher
            })
        );
        // }
    };

    const renderContent = () => {
        if (codeCategorieMarchand)
            return (
                <CustomAlert>
                    {designationCategorieMarchand}{' '}
                    <BsCheckCircle className="icon-custom text-success" />
                </CustomAlert>
            );
        return (
            <FormWrapper
                className="py-3 px-2"
                onSubmit={handleSubmit}
                formState={formState}
                formDispatcher={formDispatcher}
                isRedirect={false}
                listDependencies={[
                    {
                        dependency: 'devises',
                        etat: etatDevise
                    }
                ]}
            >
                <CustomRow>
                    <CustomDropdown
                        label="Catégorie*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        defaultOption="Choisir une catégorie"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        name="categorie"
                        id="categorieChoice"
                        options={
                            CATEGORIE_MARCHAND.DIFFERENTS_CATEGORIE_MARCHAND
                        }
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    {categorie.code !== '' && typeCategories !== undefined && (
                        <>
                            {typeCategories.map(({ code, designation }) => (
                                <CustomRadioButton
                                    key={code}
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    name="typeRadio"
                                    divClassName="col-sm-4 mt-2"
                                    id={code}
                                    value={code}
                                    labelClassName="blockquote-footer"
                                    label={designation}
                                    uiValidator={uiValidator}
                                />
                            ))}
                        </>
                    )}
                </CustomRow>
                <CustomRow>
                    <CustomCollapse
                        isIn={
                            fields.typeRadio ===
                                CATEGORIE_MARCHAND.AIRTIME?.code &&
                            categorie.code ===
                                CATEGORIE_MARCHAND.MARCHAND_PRODUIT_ELECTRONIQUE
                                    .code
                        }
                    >
                        <CustomRow>
                            <CustomInput
                                isInputGroup={false}
                                label="Syntaxe*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-8"
                                placeholder="ex: *xxx*[NUMBER]*[Qt]*[PIN]#"
                                formDispatcher={formDispatcher}
                                formState={formState}
                                name="syntaxe"
                                id="syntaxeMarchandAirtimeId"
                                uiValidator={uiValidator}
                            />
                            <CustomSwitchButton
                                inputTitle="SMS ?"
                                id="creationAchatCreditExclureId"
                                name="isSms"
                                text="SMS ?"
                                value={fields.isSms}
                                formState={formState}
                                formDispatcher={formDispatcher}
                                divClassName="col-sm-2 pt-2"
                            />
                        </CustomRow>
                        {fields.isSms ? (
                            <CustomRow>
                                <CustomInput
                                    isInputGroup={false}
                                    label="Destinataire*"
                                    labelClassName="col-sm-2"
                                    divClassName="col-sm-10"
                                    placeholder="Numéro Déstinataire"
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    name="syntaxeSmsDestinaire"
                                    id="syntaxeSmsDestinaireMarchandAirtimeId"
                                    uiValidator={uiValidator}
                                />
                            </CustomRow>
                        ) : null}
                        <CustomRow>
                            <CustomInput
                                isInputGroup={false}
                                label="Catégorie*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-8"
                                placeholder="Saisissez le préfix"
                                formDispatcher={formDispatcher}
                                formState={formState}
                                name="prefixAirtime"
                                id="prefixMarchandAirtimeId"
                                uiValidator={uiValidator}
                            />
                            <CustomButtonLoader
                                type="button"
                                className="col-sm-2"
                                text="Add"
                                onClick={addPrefixAirtime}
                            />
                        </CustomRow>
                        <CustomCollapse isIn={!!fields.prefixesAirtime.length}>
                            <CustomRow>
                                <SimpleTable
                                    listSelectedItemName="prefixesAirtime"
                                    identifier="id"
                                    formDispatcher={formDispatcher}
                                    data={fields.prefixesAirtime}
                                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                    filter={false}
                                    bottomPagination={false}
                                    isLinkToDetail={false}
                                    mapper={[
                                        {
                                            position: 1,
                                            field: 'code',
                                            displayName: 'Code'
                                        }
                                    ]}
                                />
                            </CustomRow>
                        </CustomCollapse>
                        <ProduitAirtime
                            formState={formState}
                            formDispatcher={formDispatcher}
                            uiValidator={uiValidator}
                            adder={addProduct}
                            idToken={idToken}
                        />
                    </CustomCollapse>
                    <CustomCollapse
                        isIn={
                            fields.typeRadio ===
                                CATEGORIE_MARCHAND.MOBILE_MONEY?.code &&
                            categorie.code ===
                                CATEGORIE_MARCHAND.MARCHAND_PRODUIT_ELECTRONIQUE
                                    .code
                        }
                    >
                        <CustomRow>
                            <CustomSwitchButton
                                inputTitle="estTypeEmoney ?"
                                id="estTypeEmoneyId"
                                name="estTypeEmoney"
                                text="estTypeEmoney ?"
                                value={fields.estTypeEmoney}
                                formState={formState}
                                formDispatcher={formDispatcher}
                                divClassName="col-sm-2 pt-2"
                            />
                        </CustomRow>
                        <ProduitEmoney
                            formState={formState}
                            formDispatcher={formDispatcher}
                            uiValidator={uiValidator}
                            handleProduct={addProduct}
                            devises={devises}
                        />
                    </CustomCollapse>
                    <CustomCollapse
                        isIn={
                            (fields.typeRadio ===
                                CATEGORIE_MARCHAND.RESTAURANT.code ||
                                fields.typeRadio ===
                                    CATEGORIE_MARCHAND.COMMERCE_GENERAL.code) &&
                            categorie.code ===
                                CATEGORIE_MARCHAND.MARCHAND_PRODUIT_MATERIEL
                                    .code
                        }
                    >
                        <ProduitMateriel
                            formState={formState}
                            formDispatcher={formDispatcher}
                            uiValidator={uiValidator}
                        />
                    </CustomCollapse>
                </CustomRow>
                <CustomRow className="row">
                    <CustomConfirmButton
                        type="button"
                        backdrop="static"
                        text="Soumettre"
                        className="btn-primary btn mt-3 ms-2 w-25"
                        confirmOnClick={handleSubmit}
                        disabled={
                            (fields.typeRadio ===
                                CATEGORIE_MARCHAND.AIRTIME.code &&
                                !!fields.logoStatus &&
                                status === FORM_CONST.STATUT.ETAT_INITIAL) ||
                            (status === FORM_CONST.MODIFICATION_EN_COURS &&
                                !!fields.logoStatus &&
                                fields.typeRadio ===
                                    CATEGORIE_MARCHAND.AIRTIME.code) ||
                            (status === FORM_CONST.MODIFICATION_EN_COURS &&
                                fields.typeRadio !==
                                    CATEGORIE_MARCHAND.AIRTIME.code)
                        }
                        isActionEncours={
                            (fields.typeRadio ===
                                CATEGORIE_MARCHAND.AIRTIME.code &&
                                !!fields.logoStatus &&
                                status === FORM_CONST.STATUT.ETAT_INITIAL) ||
                            (status === FORM_CONST.MODIFICATION_EN_COURS &&
                                !!fields.logoStatus &&
                                fields.typeRadio ===
                                    CATEGORIE_MARCHAND.AIRTIME.code) ||
                            (status === FORM_CONST.MODIFICATION_EN_COURS &&
                                fields.typeRadio !==
                                    CATEGORIE_MARCHAND.AIRTIME.code)
                        }
                    />
                </CustomRow>
            </FormWrapper>
        );
    };
    return renderContent();
}
