import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import {
    BUDGET_AMENDEMENT,
    CODE_SERVICE,
    formInitialState,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import {
    CustomButton,
    CustomCol,
    CustomRow,
    UserServiceWrapper
} from '../../../components';
import {
    AmendementElaborationChargeRessourceBudgetAddition,
    DetailElaborationBudgetPrevisionnelForm
} from './amendement';

const defaultFields = {
    selectedDirection: {},
    selectedSecteur: {},
    selectedCharge: {},
    selectedCaisseSectorielle: {},
    prixCharge: 0,
    selectedMonth: {},
    beneficiaireAndMontant: [],
    selectedBeneficiaire: {},
    selectedTypeBeneficiaire: {},
    datePaiement: '',
    modePaiement: {},
    quantiteParBeneficiaire: 0,
    montantParBeneficiaire: 0,
    chargeElaboration: [],
    searchInput: '',
    typeOperation: BUDGET_AMENDEMENT,
    actionEncours: '',
    isShow: false
};
/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: ['directions', 'secteurs', 'charges', 'caisses'],
    entity: true
};

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['beneficiaires'];

export function DetailElaborationBudgetAdditionnel() {
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields } = formState;
    const onShowAmend = () => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'isShow',
                data: !fields.isShow
            }
        });
    };
    return (
        <>
            {!fields.isShow ? (
                <DetailElaborationBudgetPrevisionnelForm
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            ) : (
                <AmendementElaborationChargeRessourceBudgetAddition
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            )}
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .VALIDER_ELABORATION_AMENDEMENT,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ADOPTER_ELABORATION_AMENDEMENT,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ANNULER_ELABORATION_AMENDEMENT
                ]}
            >
                <CustomRow>
                    <CustomCol className="col-3 col-sm-4">
                        <CustomButton
                            onClick={onShowAmend}
                            className="btn-danger"
                        >
                            {fields.isShow
                                ? 'Revenir sur la vue de détails'
                                : 'Amender cette élaboration...'}
                        </CustomButton>
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </>
    );
}
