import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { submitPostAction } from '@napp-inc/jnapp-hook';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomDropdown,
    CustomRow,
    CustomSwitchButton,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { crediterCompteDetailSchemaValidator as uiValidator } from './validation';

export function DetailCrediterCompteForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        reduxProps
    } = useGetDetailForm({
        nodeName: 'creditersCompte',
        defaultFields: {
            actionEncours: '',
            isChange: false,
            selectedDeviseSollicite: {}
        },
        defaultDependencies: { entity: true, fields: ['monnaiesLocale'] },
        listNode: [
            {
                name: 'monnaiesLocale',
                format: (list) =>
                    list?.map((item) => ({
                        ...item,
                        code: item?.code || item?.devise
                    }))
            }
        ],
        listFunctions: [
            {
                id: 'monnaiesLocale',
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE
            }
        ]
    });
    const { form, fields } = formState;
    const updateEtat = () => {
        const payloadToAdd = {};
        if (fields.isChange && fields.selectedDeviseSollicite?.code) {
            if (fields.selectedDeviseSollicite?.code === entity?.devise) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `Vous ne pouvez pas sollicité la même devise que l'opération`,
                        status: FORM_CONST.CREATION_ECHEC
                    }
                });
                return;
            }
            payloadToAdd.deviseSollicite = fields.selectedDeviseSollicite.code;
        }
        const payload = {
            idCrediterCompte: entity?.id,
            ...payloadToAdd
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CREDITER_COMPTE,
                payload,
                fields,
                reduxNodeName: DB_NODE.CREDITER_COMPTE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_CREDITER_COMPTE_BY_ID}
            nodeName={DB_NODE.CREDITER_COMPTE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Crediter Compte"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
            listDependencies={[
                {
                    dependency: 'monnaiesLocale',
                    etat: reduxProps?.etatMonnaiesLocale
                }
            ]}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_CREDITER_COMPTE
                ]}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        entity?.etat === ETAT.ETAT_VALIDER_DECAISSEMENT
                    }
                >
                    <CustomSwitchButton
                        formDispatcher={formDispatcher}
                        formState={formState}
                        text="Activer l'opération de Change"
                        name="isChange"
                        divClassName="col-md-8 mb-4"
                        value={fields.isChange}
                    />
                    <CustomRow isShouldBeRendered={fields.isChange}>
                        <CustomDropdown
                            options={reduxProps?.monnaiesLocale}
                            label="Devise Sollicité*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            name="selectedDeviseSollicite"
                            id="selectedDeviseSolliciteCreationCrediterCompte"
                            formState={formState}
                            defaultOption="Selectionner Devise Sollicitée"
                            idProperty="code"
                            propertyToDisplay="devise"
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={updateEtat}
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                            isActionEncours
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
