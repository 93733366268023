import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { ApproStockProduitMaterielSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    selectedProduit: {},
    selectedVariete: { code: '', designation: 'Selectionner la variété' },
    metaInfoVariete: '',
    varietes: [],
    produitWithoutVariety: [],
    produitWithVariety: [],
    quantite: 0
};

/**
 * @description defaultDeps de notre state
 */
const defaultDependencies = { fields: ['produits'] };

export function CreationApproStockMarchandProduitMaterielForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, produits, etatProduit } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            produits: reduxState.produits,
            etatProduit: reduxState.etat.produits.etat
        })
    );
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * @description récupérer les produits marchand
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PRODUIT_MARCHAND,
                nodeName: DB_NODE.PRODUIT,
                etat: etatProduit
            }
        ]
    });

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const propertyToAdd = {};
        const listWithoutVariety = fields?.produitWithoutVariety.map(
            (item) => ({
                codeProduit: item?.codeProduit,
                quantite: item?.quantite
            })
        );
        const listWithVariety = fields?.produitWithVariety.map((item) => ({
            codeProduit: item?.codeProduit,
            varietes: item?.varietes.map((subItem) => ({
                typeEntite: subItem?.typeEntite,
                valeurTypeEntite: subItem?.valeurTypeEntite,
                quantite: subItem?.quantite
            }))
        }));
        if (fields.produitWithVariety.length)
            propertyToAdd.list = listWithVariety;
        else propertyToAdd.list = listWithoutVariety;
        const payload = {
            creator,
            ...propertyToAdd
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_APPRO_STOCK_PRODUIT_MATERIEL,
                payload,
                fields,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    /**
     * L'action qui sera déclenchée pour l'ajout des
     * @returns
     */
    const addVarieteQuantite = () => {
        if (!fields.selectedVariete.code || !fields.metaInfoVariete) {
            const data = {
                id: `${fields.selectedProduit.code}`,
                codeProduit: fields.selectedProduit.code,
                quantite: fields.quantite
            };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    field: 'produitWithoutVariety',
                    data,
                    identifier: 'id'
                }
            });
        }
        if (fields.selectedVariete.code && fields.metaInfoVariete) {
            const data = {
                id: `${fields.selectedVariete.code}${fields.metaInfoVariete}`,
                typeEntite: fields.selectedVariete.code,
                designationVariete: fields.selectedVariete.designation,
                valeurTypeEntite: fields.metaInfoVariete,
                quantite: fields.quantite //
            };
            const newState = {
                ...initialState,
                fields: {
                    ...initialState.fields,
                    selectedProduit: fields.selectedProduit,
                    selectedVariete: fields.selectedVariete,
                    varietes: fields.varietes
                }
            };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
                payload: {
                    newState,
                    field: 'varietes',
                    data,
                    identifier: 'id'
                }
            });
        }
    };

    /**
     * L'action qui sera déclenchée pour l'ajout des users qui bénéficient de la fonctionnalité
     * @returns
     */
    const onAddEntireObjectAction = () => {
        const newState = {
            ...initialState,
            fields: {
                ...initialState.fields,
                produitWithVariety: fields.produitWithVariety
            }
        };
        const data = {
            id: `${fields.selectedProduit.code}`,
            codeProduit: fields.selectedProduit.code,
            designationProduit: fields.selectedProduit.designation,
            varietes: fields.varietes
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
            payload: {
                field: 'produitWithVariety',
                data,
                identifier: 'id',
                newState
            }
        });
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'produits',
                    etat: etatProduit
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={produits}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le produit"
                    divClassName="col-12 col-sm-10"
                    labelClassName="col-12 col-sm-2"
                    label="Produit*"
                    name="selectedProduit"
                    id="selectedProduitId"
                    resetOnChangeState
                    newState={{
                        ...initialState.fields
                    }}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        {
                            code: 'Poids',
                            designation: 'Poids'
                        },
                        {
                            code: 'Taille',
                            designation: 'Taille'
                        },
                        {
                            code: 'Couleur',
                            designation: 'Couleur'
                        }
                    ]}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la variété"
                    divClassName="col-12 col-sm-10"
                    labelClassName="col-12 col-sm-2"
                    label="Variété"
                    name="selectedVariete"
                    id="selectedVarieteId"
                    resetOnChangeState
                    newState={{
                        ...initialState.fields,
                        selectedProduit: fields.selectedProduit
                    }}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomCollapse isIn={fields.selectedVariete.code !== ''}>
                <CustomRow>
                    <CustomInput
                        id="approStockFormMetaInfoVarieteId"
                        name="metaInfoVariete"
                        isInputGroup={false}
                        divClassName="col-sm-10"
                        labelClassName="col-sm-2"
                        label="Description*"
                        placeholder="Description"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomRow>
            </CustomCollapse>
            <CustomRow>
                <CustomInput
                    id="approStockFormQuantiteId"
                    name="quantite"
                    type="number"
                    min={0}
                    isInputGroup={false}
                    divClassName="col-sm-9"
                    labelClassName="col-sm-2"
                    label="Quantité*"
                    placeholder="Quantité"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={addVarieteQuantite}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.varietes.length}>
                <SimpleTable
                    formDispatcher={formDispatcher}
                    data={fields.varietes}
                    listSelectedItemName="varietes"
                    identifier="id"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            field: 'designationVariete',
                            displayName: 'Designation'
                        },
                        {
                            position: 2,
                            field: 'valeurTypeEntite',
                            displayName: 'MetaInfo'
                        },
                        {
                            position: 3,
                            field: 'quantite',
                            displayName: 'Quantité'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomCollapse isIn={fields.produitWithoutVariety.length > 0}>
                <SimpleTable
                    formDispatcher={formDispatcher}
                    data={fields.produitWithoutVariety}
                    listSelectedItemName="produitWithoutVariety"
                    identifier="id"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            field: 'codeProduit',
                            displayName: 'Produit'
                        },
                        {
                            position: 2,
                            field: 'quantite',
                            displayName: 'Quantité'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        type="button"
                        className="btn-primary"
                        onClick={onAddEntireObjectAction}
                        text="Ajouter..."
                    />
                </CustomCol>
            </CustomRow>
            {/* La palge des Sims ne sera disponibles que pour les marchands airtime dont les produits SIM est disponible */}
            {/* Voir la creation appro sim */}
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
