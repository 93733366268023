import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListDepotEmoneyForm() {
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { uid } = useGetUserDetail({ firebaseUser });
    return (
        <ListGenericForm
            path={DB_NODE.COMMANDE_EMONEY}
            fetchType={FETCH_LISTENING}
            reduxPropertyName="depotEmoney"
            functionName={URL_CONST.GET_LIST_DEPOT_EMONEY_USER}
            nodeName={REDUX_NODE_NAME.DEPOT_EMONEY}
            listenerFilters={[
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'user.uid',
                    value: uid,
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'estRetrait',
                    value: false
                },
                {
                    type: FILTER_TYPE.ORDER_BY,
                    property: 'dateDerniereModification',
                    value: 'asc'
                },
                {
                    type: FILTER_TYPE.START_AT,
                    value: TODAY.nowUtc(),
                    isTimestampNowValue: true
                }
            ]}
            mapper={[
                {
                    field: 'client.designation',
                    displayName: 'Client'
                },
                {
                    field: 'valeurTypePartenariat.designation',
                    displayName: 'Organisation'
                },
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand'
                },
                {
                    field: 'montant',
                    displayName: 'montant'
                },
                {
                    field: 'devise',
                    displayName: 'devise'
                },
                {
                    field: 'etat',
                    displayName: 'etat'
                },
                {
                    field: 'dateCreation',
                    displayName: 'dateCreation'
                }
            ]}
        />
    );
}
