import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { ref, uploadBytes } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomInputFile,
    CustomRow,
    FormWrapper
} from '../../../components';
import { fichierPresence } from '../../../firebase';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

// releveBancaire

/**
 * @desc defaultFields
 */
const defaultFields = {
    file: '',
    fileStatus: ''
};

/**
 * @desc defaultFiles
 */
const defaultFiles = ['documentPresentation'];

export function CreationUploadFichierPresenceForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        files: defaultFiles
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'fileStatus',
                data: 'UPLOAD_PENDING'
            }
        });
        uploadBytes(ref(fichierPresence, fields.file.name), fields.file)
            .then((response) => {
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'fileStatus',
                        data: 'UPLOAD_FINISHED'
                    }
                });
                const payload = {
                    fileName: response.metadata.name,
                    creator
                };
                if (response.metadata.name) {
                    formDispatcher(
                        submitPostAction({
                            idToken,
                            functionName: URL_CONST.POST_TRAITEMENT_PRESENCE,
                            payload,
                            fields,
                            reduxNodeName: REDUX_NODE_NAME.FICHIER_PRESENCE,
                            reduxDispatcher
                        })
                    );
                }
            })
            .catch((error) => {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: error,
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
            });
    };
    const renderContent = () => (
        <FormWrapper formState={formState} formDispatcher={formDispatcher}>
            <CustomRow>
                <CustomInputFile
                    id="creationUploadFichierEmoneyInputFichier"
                    name="file"
                    label="Fichier"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    // uiValidator={CreationArticleSchema}
                    accept=".doc, .docx, .pdf, .txt, .xlsx, .csv"
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={
                            (form.status === FORM_CONST.CREATION_EN_COURS ||
                                fields.fileStatus === 'UPLOAD_PENDING') &&
                            fields.fileStatus !== ''
                        }
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
