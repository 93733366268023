import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import * as yup from 'yup';
import {
    CODE_SERVICE,
    formInitialState,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { TransfertCaisse } from './creation-form';
import { useHasService } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

/**
 * @description defaultFields for our state
 */
const defaultFields = {
    selectedCaisseSource: {},
    selectedCaisseDestination: {},
    montant: 0,
    numeroReference: ''
};

/**
 * @description defaultUiValidator
 */
const defaultUiValidator = yup.object().shape({
    selectedCaisseSource: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedCaisseDestination: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    montant: yup.number().min(1),
    numeroReference: yup.string()
});
const setDefaultProperties = ({ type, service }) => {
    /**
     * @description defaultDependencies for our state
     */
    const defaultDependencies = { fields: ['caisses', 'caissesOrganisation'] };

    const payload = {
        caisses: { estOrganisation: false },
        caissesOrganisation: { estOrganisation: true },
        caisseNodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
        caisseReduxProperty: 'caissesActiveUser',
        caisseOrganisationNodeName: REDUX_NODE_NAME.ALL_CAISSE_ORGANISATION,
        caisseOrganisationReduxProperty: 'allCaissesOrganisation'
    };
    // const condition = {};
    if (
        type === TYPE_ORGANISATION.NAPP &&
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.INITIER_TRANSFERT_CAISSE
                .code
        ] &&
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.VALIDER_TRANSFERT_CAISSE
                .code
        ]
    ) {
        payload.caisses = { estOrganisation: false };
    }
    if (
        type === TYPE_ORGANISATION.NAPP &&
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.INITIER_TRANSFERT_CAISSE
                .code
        ] &&
        !service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.VALIDER_TRANSFERT_CAISSE
                .code
        ]
    ) {
        payload.caisses = { estOrganisation: false };
    }
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: defaultUiValidator,
            cfPayload: payload
            // cfPayload: {
            //     estOrganisation: true,
            //     estOperation: true
            // }
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: defaultUiValidator,
            cfPayload: payload
            // cfPayload: {
            //     estOrganisation: true
            // }
        };
    }
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: defaultUiValidator,
            cfPayload: payload
            // cfPayload: {
            //     estOrganisation: true
            // }
        };
    }
    if (type === TYPE_ORGANISATION.REVENDEUR) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: defaultUiValidator,
            cfPayload: payload
            // cfPayload: {
            //     estOrganisation: true
            // }
        };
    }
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: defaultUiValidator,
            cfPayload: payload
            // cfPayload: {
            //     estOrganisation: true
            // }
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({}),
        cfPayload: {}
    };
};

export function CreationTransfertForm() {
    /**
     * Get data on redux store
     */
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.INITIER_TRANSFERT_CAISSE,
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.VALIDER_TRANSFERT_CAISSE
        ]
    });

    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const { fields, dependencies, elements, uiValidator, cfPayload } =
        setDefaultProperties({
            type: typeEntite,
            service: hasService
        });

    /**
     * initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });

    const renderContent = () =>
        initialState?.dependencies ? (
            <TransfertCaisse
                idToken={idToken}
                typeEntite={typeEntite}
                uiValidator={uiValidator}
                initialState={initialState}
                cfPayload={cfPayload}
            />
        ) : null;
    return renderContent();
}
