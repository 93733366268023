import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListLitigieuxEmoneyForm() {
    return (
        <ListGenericForm
            reduxPropertyName="litigieuxEmoney"
            functionName={URL_CONST.GET_LIST_COMMANDE_LITIGIEUX_EMONEY}
            nodeName={REDUX_NODE_NAME.LITIGIEUX_EMONEY}
            mapper={[
                {
                    displayName: 'Marchand',
                    field: 'marchand.designation'
                },
                {
                    displayName: 'User',
                    dataExtractor: (item) =>
                        `${item?.user?.prenom || ''} ${item?.user?.nom || ''}`
                },
                {
                    displayName: 'Revendeur',
                    field: 'valeurTypeOrganisation.designation'
                },
                {
                    displayName: 'Type business',
                    field: 'typeBusiness'
                },
                {
                    displayName: 'Type',
                    dataExtractor: (item) =>
                        item?.estRetrait ? 'Retrait' : 'Dépôt'
                },
                {
                    displayName: 'Client',
                    field: 'client.designation',
                    dataExtractor: (item) =>
                        item?.client?.designation ||
                        `${item?.client?.prenom || ''} ${
                            item?.client?.nom || ''
                        }`
                },
                {
                    displayName: 'Numéro',
                    field: 'client.numero'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Devise',
                    field: 'devise'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Motif',
                    field: 'motif'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                },
                {
                    displayName: 'Dernière modification',
                    field: 'dateDerniereModification'
                }
            ]}
        />
    );
}
