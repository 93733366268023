import { useGetColorAndDesignationEtat } from '@napp-inc/jnapp-hook';

export function EtatDisplayer({ etat, className, style = {} }) {
    const { color, state } = useGetColorAndDesignationEtat({ etat });
    return (
        <span
            className={`badge ${className}`}
            style={{ ...style, backgroundColor: color }}
        >
            {state}
        </span>
    );
}
