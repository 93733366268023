export const commandeMapper = [
    {
        displayName: 'Marchand',
        field: 'marchand.designation'
    },
    {
        displayName: 'Numéro',
        field: 'tillNumber'
    },
    {
        displayName: 'Quantite',
        field: 'quantite',
        isNumber: true
    },
    {
        displayName: 'Référence',
        field: 'numeroReference'
    },
    {
        displayName: 'Type',
        field: 'type'
    },
    {
        displayName: 'Correspondance',
        field: 'correspondance'
    },
    {
        field: 'dateCreation',
        displayName: 'Création'
    },
    {
        field: 'date',
        displayName: 'Date (partenaire)'
    }
];
