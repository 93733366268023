import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { SignInRelPath } from '../routes/registration/gestion-user/connexion-rel-path';

export function CheckLogin({ children }) {
    const location = useLocation();
    const firebaseUser = useSelector((reduxState) => reduxState.firebaseUser);
    if (!(firebaseUser && firebaseUser.uid)) {
        return (
            <Navigate
                to={`/${SignInRelPath.path}`}
                state={{ from: location }}
                replace
            />
        );
    }
    return children;
}
