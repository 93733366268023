export const schema = [
    {
        title: 'Type',
        description: 'typeCompteExterne',
        className: 'border-bottom'
    },
    {
        title: 'Designation',
        description: 'libelle',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Banques',
        description: 'institutionFinanciere.designation',
        className: 'border-bottom'
    },
    {
        title: 'Devise',
        description: 'devise',
        className: 'border-bottom'
    },
    {
        title: 'Numero',
        description: 'numero',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
