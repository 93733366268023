import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce, URL_CONST } from '../../../util';
import {
    CustomConfirmButton,
    CustomFormGroup,
    CustomInput,
    CustomRow,
    FormWrapper,
    SelectZone
} from '../../../components';
import { CreationAgenceSchema as uiValidator } from './validation';

const defaultFields = {
    code: '',
    designation: '',
    newAdresseAdresseUne: '',
    selectedZone: {},
    selectedZoneDropDown: {},
    preview: '',
    zones: []
};
const defaultElements = ['zones'];

export function CreationCreationAgenceForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    /**
     * Common form processes
     */
    const { idToken, creator, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    const { status } = form;
    useDebounce({
        name: 'selectedZone',
        preview: 'preview',
        isObject: true,
        formDispatcher,
        formState,
        delay: 2000,
        idToken,
        functionName: URL_CONST.GET_LIST_COORDONNES_DISPONIBLE_POUR_AGENCE,
        elementPropertyName: 'zones',
        creator,
        reduxNodeName: DB_NODE.ENTITE_ADMINISTRATIVE,
        reduxDispatcher
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = {};
        if (typeEntite === TYPE_ORGANISATION.MARCHAND) {
            payloadToAdd.zones = fields.zones;
        }
        const payload = {
            newAgence: {
                code: fields.code,
                designation: fields.designation,
                estSysteme: false
            },
            newAdresse: {
                adresseUne: fields.newAdresseAdresseUne
            },
            ...payloadToAdd,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                fields,
                functionName: URL_CONST.POST_AGENCE,
                reduxDispatcher,
                reduxNodeName: DB_NODE.AGENCE,
                payload: {
                    ...payload
                },
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper formState={formState} formDispatcher={formDispatcher}>
            <CustomRow>
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    id="newCodeAgence"
                    name="code"
                    placeholder="Code agence (ex: NAPP_AG)"
                    label="Code de l'agence*"
                    labelClassName="col-sm-3"
                    divClassName="col-sm-9"
                    isInputGroup={false}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    id="newDesignationAgence"
                    label="Desigantion de l'agence*"
                    labelClassName="col-sm-3"
                    divClassName="col-sm-9"
                    isInputGroup={false}
                    name="designation"
                    placeholder="Desigantion agence (ex: ATHENAS GROUP)"
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    id="newAdresseAdresseUneId"
                    label="Adresse*"
                    labelClassName="col-sm-3"
                    divClassName="col-sm-9"
                    isInputGroup={false}
                    name="newAdresseAdresseUne"
                    placeholder="Adresse physique de l'agence"
                />
            </CustomRow>
            {typeEntite === TYPE_ORGANISATION.MARCHAND ? (
                <CustomRow>
                    <CustomFormGroup className="col-md-12">
                        <SelectZone
                            zoneFound={
                                elements?.zones?.value?.length
                                    ? elements.zones.value
                                    : []
                            }
                            entitesAdministrative={
                                elements?.zones?.value?.length
                                    ? elements.zones.value
                                    : []
                            }
                            // callback={processChanges}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomFormGroup>
                </CustomRow>
            ) : null}
            <CustomRow className="row">
                <CustomConfirmButton
                    type="button"
                    backdrop="static"
                    text="Créer agence"
                    className="btn-success btn mt-3 w-25"
                    confirmOnClick={handleSubmit}
                    disabled={status === FORM_CONST.CREATION_EN_COURS}
                    isActionEncours={status === FORM_CONST.CREATION_EN_COURS}
                />
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
