import * as yup from 'yup';

/**
 * @description : Schema de elaboration charge fixe
 */
export const ElaborationChargeFixeFormSchema = yup.object().shape({
    selectedExercice: yup.object().shape({
        id: yup.string(),
        designation: yup.string()
    }),
    selectedDirection: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedSecteur: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedCharge: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedMonth: yup.object().shape({
        code: yup.number(),
        designation: yup.number()
    }),
    selectedCaisseSectorielle: yup.object().shape({
        id: yup.string(),
        designation: yup.string()
    }),
    prixCharge: yup.number().min(0),
    beneficiaireAndMontant: [],
    selectedBeneficiaire: yup.object().shape({
        id: yup.string(),
        designation: yup.string()
    }),
    selectedTypeBeneficiaire: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    datePaiement: yup.string(),
    searchInput: yup.string(),
    modePaiement: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    quantiteParBeneficiaire: yup.number().min(0),
    montantParBeneficiaire: yup.number().min(0),
    isPrevisionnel: yup.boolean()
});
