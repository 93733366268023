import * as yup from 'yup';

/**
 * @description : Schema de Produit Materiel création form
 */
export const ProduitMaterielSchema = yup.object().shape({
    codeProduit: yup.string().required(),
    designationProduit: yup.string().required(),
    designationCourteProduit: yup.string(),
    selectedUnite: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    descriptionProduit: yup.string(),
    temperatureProduit: yup.string().required(),
    selectedEtatProduit: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    prixVente: yup.number().min(1),
    estInflammable: yup.boolean(),
    estFragile: yup.boolean(),
    delaiConsignation: yup.number().min(1),
    longueur: yup.number().min(1),
    largeur: yup.number().min(1),
    hauteur: yup.number().min(1),
    poids: yup.number().min(1),
    selectedIngredient: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    ingredients: yup.array().of(
        yup
            .object({
                code: yup.string().required(),
                quantite: yup.number().min(1)
            })
            .shape({})
    ),
    // .required()
    // .min(1, 'Ajoutez au moins un élément à la liste'),
    quantite: yup.number().min(1),

    actionEncours: yup.string().required(),
    selectedPointLivraison: yup.string().required(),
    selectedAgentLivreur: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    })
});
