import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    actionEncours: ''
};

export function DetailCasLitigieuxForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'casLitigieux', defaultFields });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const actionEncours = etat;
        const payload = {
            etat,
            idCommandeFlash: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_COMMANDE_FLASH,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.CAS_LITIGIEUX,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_COMMANDE_FLASH_BY_ID}
            nodeName={REDUX_NODE_NAME.CAS_LITIGIEUX}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Commande litigieuse"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_SENDER
                        .MISE_A_JOUR_COMMANDE_LITIGIEUX
                ]}
            >
                {entity?.etat === ETAT.ETAT_ENCOURS ||
                entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION ? (
                    <CustomRow>
                        <CustomCol className="offset-md-6 col-md-1">
                            <CustomConfirmButton
                                type="button"
                                text="Mettre Encours"
                                className="btn-primary"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ENCOURS)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ENCOURS
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                        <CustomCol className="col-md-1">
                            <CustomConfirmButton
                                type="button"
                                text="Livrer"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_LIVRER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_LIVRER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation livraison"
                            />
                        </CustomCol>
                        <CustomCol className="col-md-1">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                    </CustomRow>
                ) : null}
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
