import { DB_NODE, ONLY_FETCH } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { ViewLayout } from './view-layout';

export function ListElaborationBudgetAdditionnel() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                fetchType={ONLY_FETCH}
                reduxPropertyName="elaborations"
                functionName={
                    URL_CONST.GET_LIST_ELABORATION_AMENDEMENT_INITIALS
                }
                nodeName={DB_NODE.ELABORATION_AMENDEMENT}
                mapper={[
                    {
                        field: 'type',
                        displayName: 'Elaboration',
                        position: 1
                    },
                    {
                        field: 'estPrevisionnel',
                        displayName: 'Prévisionnel',
                        position: 2
                    },
                    {
                        field: 'typeEntite',
                        displayName: 'Type',
                        position: 3
                    },
                    {
                        field: 'typeProprietaire',
                        displayName: 'Element',
                        position: 4
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 6
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 7
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
