import {
    fetchElement,
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomFormGroup,
    CustomInput,
    CustomRow,
    FormWrapper,
    SelectZone
} from '../../../components';
import { useDebounce, URL_CONST } from '../../../util';
import { DerogationSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    codeRevendeur: '',
    selectedZone: {},
    selectedZoneDropDown: {},
    preview: '',
    zones: []
};
const defaultElements = ['zones', 'revendeur'];

export function CreationDerogationForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    useDebounce({
        name: 'selectedZone',
        preview: 'preview',
        isObject: true,
        formDispatcher,
        formState,
        delay: 2000,
        idToken,
        functionName: URL_CONST.GET_LIST_ZONE_SECTEUR_BY_TEXT_DESIGNATION,
        elementPropertyName: 'zones',
        creator,
        reduxNodeName: DB_NODE.ENTITE_ADMINISTRATIVE,
        reduxDispatcher
    });

    const onSearchRevendeur = () => {
        const { status: etat } = elements.revendeur;
        const { codeRevendeur } = fields;
        if (
            codeRevendeur &&
            codeRevendeur !== elements?.revendeur?.value?.tillNumber
        ) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'revendeur',
                    payload: { tillNumber: codeRevendeur },
                    functionName: URL_CONST.GET_REVENDEUR_BY_TILL_NUMBER,
                    etat,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            // codeRevendeur: elements?.revendeur?.value?.code,
            // zones,
            creator
        };
        // if (!fields.zones.length || !fields.codeRevendeur) return;
        if (!elements?.revendeur?.value?.code) return;
        // const zones = fields.zones.map((item) => Number(item.osmId));
        if (elements?.revendeur?.value?.code) {
            payload.codeRevendeur = elements?.revendeur?.value?.code;
        }
        if (fields.zones.length) {
            payload.zones = fields.zones.map((item) => Number(item.osmId));
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_DEROGATION,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Revendeur*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-8"
                    placeholder="Saisissez la reférence du revendeur"
                    formDispatcher={formDispatcher}
                    name="codeRevendeur"
                    id="derogationCodeRevendeurId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomButtonLoader
                    className="col-2"
                    type="button"
                    disabled={
                        elements?.revendeur?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                    isActionEncours={
                        elements?.revendeur?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                    onClick={onSearchRevendeur}
                    text="Rechercher"
                />
            </CustomRow>
            {elements.revendeur.status === FORM_CONST.FETCH_ELEMENT_FAILED && (
                <CustomRow>
                    <CustomCol className="offset-md-2 col-12 col-md-10">
                        <CustomAlert
                            error={`${elements?.revendeur?.error || ''}`}
                        />
                    </CustomCol>
                </CustomRow>
            )}
            {elements.revendeur.status === FORM_CONST.FETCH_ELEMENT_SUCCESS && (
                <CustomRow>
                    <CustomCol className="offset-md-2 col-12 col-md-10">
                        <CustomAlert
                            successMessage={`${
                                elements?.revendeur?.value?.tillNumber || ''
                            } - ${
                                elements?.revendeur?.value?.designation || ''
                            }`}
                        />
                    </CustomCol>
                </CustomRow>
            )}
            {/* Pour la creation des colonnes */}
            <CustomRow>
                <CustomFormGroup className="col-md-12">
                    <SelectZone
                        idProperty="osmId"
                        zoneFound={
                            elements?.zones?.value?.length
                                ? elements.zones.value
                                : []
                        }
                        entitesAdministrative={
                            elements?.zones?.value?.length
                                ? elements.zones.value
                                : []
                        }
                        // callback={processChanges}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomFormGroup>
            </CustomRow>
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer Dérogation"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
