import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    COUPURE,
    DB_NODE,
    ETAT,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import {
    CustomAddButton,
    CustomCol,
    CustomConfirmButton,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';
import { DetailDispatchingPaiementCompteExecutionSchema as uiValidator } from './validation';

/**
 * @desc defaultFields
 */
const defaultFields = {
    actionEncours: '',
    selectedCoupureName: {},
    nombreCoupureName: 0,
    listBilletageName: []
};

// FIXME: LES ACTIONS (ETAT) DOIVENT TENIR COMPTE DES SERVICES

export function DetailDispatchingPaiementExecutionCharge() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({ nodeName: 'dispatchingPaiement', defaultFields });
    const { fields, form } = formState;

    const isCaisseExecution = true;

    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payloadToAdd = {};
        if (!isCaisseExecution) {
            payloadToAdd.numeroReference = fields.numeroReference;
        }
        if (isCaisseExecution) {
            const coupures = fields.listBilletageName.map((item) => ({
                valeur: Number(item.coupure.code),
                nombre: item.nombre
            }));
            payloadToAdd.coupures = coupures;
        }
        const payload = {
            etat,
            creator,
            idMoyenPaiementDate: entity?.id,
            ...payloadToAdd
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_PAIEMENT_CAISSE_SECTORIELLE,
                payload,
                reduxNodeName: DB_NODE.MOYEN_PAIEMENT_DATE,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const ajouterCoupure = () => {
        const devise = { code: 'USD' };
        const coupure = formState.fields.selectedCoupureName;
        const nombre = formState.fields.nombreCoupureName;
        if (!nombre || !devise || !devise.code || !coupure || !coupure.code)
            return;
        const data = {
            nombre,
            devise,
            coupure,
            id: `${coupure.code}${devise.code}`
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: { field: 'listBilletageName', data }
        });
    };

    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_MOYEN_PAIEMENT_DATE_BY_ID}
            nodeName={DB_NODE.MOYEN_PAIEMENT_DATE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Dispatch Paiement exécution charge"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .VALIDER_DISPATCHING_CAISSE_EXECUTION_CHARGE
                ]}
            >
                <CustomRow>
                    <CustomLabel className="col-3">Billetage*</CustomLabel>
                    <CustomCol className="col-4">
                        <CustomDropdown
                            options={COUPURE.USD || []}
                            formDispatcher={formDispatcher}
                            name="selectedCoupureName"
                            id="selectedCoupureCreationBilletage"
                            formState={formState}
                            propertyToDisplay="code"
                            uiValidator={uiValidator}
                        />
                    </CustomCol>
                    <CustomCol className="col-4">
                        <CustomInput
                            type="number"
                            divClassName="col-12 col-sm-10"
                            isInputGroup={false}
                            placeholder="Nombre coupure"
                            formDispatcher={formDispatcher}
                            name="nombreCoupureName"
                            id="nombreCoupureCreationBilletage"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomCol>
                    <CustomCol className="col-1">
                        <CustomAddButton callback={ajouterCoupure} />
                    </CustomCol>
                </CustomRow>
                {formState?.fields?.listBilletageName.length > 0 && (
                    <CustomRow>
                        <CustomCol className="offset-md-2 col-md-10 col-12">
                            <SimpleTable
                                formDispatcher={formDispatcher}
                                listSelectedItemName="listBilletageName"
                                identifier="id"
                                data={formState.fields.listBilletageName}
                                tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                filter={false}
                                bottomPagination={false}
                                isLinkToDetail={false}
                                mapper={[
                                    {
                                        field: 'devise.code',
                                        displayName: 'devise',
                                        position: 1
                                    },
                                    {
                                        field: 'coupure.code',
                                        displayName: 'coupure',
                                        position: 2
                                    },
                                    {
                                        field: 'nombre',
                                        displayName: 'nombre',
                                        position: 3
                                    }
                                ]}
                            />
                        </CustomCol>
                    </CustomRow>
                )}
                <CustomRow>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Vailder Decaissement"
                            className="btn-success"
                            confirmOnClick={() =>
                                updateEtat(ETAT.ETAT_VALIDER_DECAISSEMENT)
                            }
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours ===
                                ETAT.ETAT_VALIDER_DECAISSEMENT
                            }
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Vailder Encaissement"
                            className="btn-success"
                            confirmOnClick={() =>
                                updateEtat(ETAT.ETAT_VALIDER_ENCAISSEMENT)
                            }
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours ===
                                ETAT.ETAT_VALIDER_ENCAISSEMENT
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
