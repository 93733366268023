import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AmendementElaborationCharge } from './amendement-charge';
import { AmendementChargeVariable } from './amendement-charge-variable';
import { CreationAmendementRessource } from './amendement-ressource';

export function AmendementElaborationChargeRessourceBudgetAddition({
    formState,
    formDispatcher
}) {
    const { id } = useParams();
    const { entity } = useSelector((reduxState) => ({
        entity: reduxState.elaborations.find((element) => element.id === id)
    }));

    const renderContent = () => {
        if (entity?.typeProprietaire === 'Charge' && entity?.estVariable) {
            return <AmendementChargeVariable />;
        }
        if (entity?.typeProprietaire === 'Charge' && !entity?.estVariable) {
            return (
                <AmendementElaborationCharge
                    formDispatcher={formDispatcher}
                    formState={formState}
                />
            );
        }
        return (
            <CreationAmendementRessource
                formDispatcher={formDispatcher}
                formState={formState}
            />
        );
    };
    return renderContent();
}
