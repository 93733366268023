import React from 'react';
import { Outlet } from 'react-router-dom';

/**
 * Le main content du layout setting
 * @returns {React.Component}
 */
export default function SettingContent() {
    return <Outlet />;
}
