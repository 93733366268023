import { HiOutlineCreditCard } from 'react-icons/hi';

/**
 * @desc Approvisionnement sim rel paths
 */
export const ApprovisionnementSimViewLayoutRelPath = {
    name: 'Approvisionnement sim',
    path: 'approvisionnement-sim',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const ApprovisionnementSimCreateRelPath = {
    name: '',
    path: 'create'
};

export const ApprovisionnementSimDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Transfert sim rel paths
 */
export const TransfertSimViewLayoutRelPath = {
    name: 'Transfert sim',
    path: 'transfert-sim',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const TransfertSimCreateRelPath = {
    name: '',
    path: 'create'
};

export const TransfertSimDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Vente sim rel paths
 */
export const VenteSimViewLayoutRelPath = {
    name: 'Vente sim',
    path: 'vente-sim',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const VenteSimCreateRelPath = {
    name: '',
    path: 'create'
};

export const VenteSimDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Facturation stock rel paths
 */
export const FacturationStockViewLayoutRelPath = {
    name: 'Facturation',
    path: 'facturation',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const FacturationStockCreateRelPath = {
    name: '',
    path: 'create'
};

export const FacturationStockDetailRelPath = {
    name: '',
    path: ':id'
};
