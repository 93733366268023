import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { ListLitigieuxForm } from './list-litigieux';
import { RapportCommandeFlashDoublonForm } from './doublons';
import { RapportCommandeFlashSansCorrespondanceForm } from './sans-correspondance';
import { RapportRequeteApiForm } from './api-request';
import { ReleveCommandeFlashMultipleForm } from './envoi-multiple';
import { UserServiceWrapper } from '../../../components';

export function CasLitigieuxViewLayout() {
    const [key, setKey] = useState('litigieux');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Commande litigieuse</h1>
            </div>
            <Tabs
                defaultActiveKey="litigieux"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="litigieux" title="Commande litigieuse">
                    {key === 'litigieux' && <ListLitigieuxForm />}
                </Tab>
                <Tab eventKey="doublon" title="Doublons">
                    {key === 'doublon' && <RapportCommandeFlashDoublonForm />}
                </Tab>
                <Tab eventKey="nullReference" title="Références fantômes">
                    {key === 'nullReference' && (
                        <RapportCommandeFlashSansCorrespondanceForm />
                    )}
                </Tab>
                <Tab eventKey="api" title="Api request">
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                                .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
                                .LECTURE_REQUETE_API_PARTENAIRE
                        ]}
                    >
                        {key === 'api' && <RapportRequeteApiForm />}
                    </UserServiceWrapper>
                </Tab>
                <Tab eventKey="multiple" title="Multiple">
                    {key === 'multiple' && <ReleveCommandeFlashMultipleForm />}
                </Tab>
            </Tabs>
        </div>
    );
}
