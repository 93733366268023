export const mapper = [
    {
        field: 'institutionFinanciere.designation',
        displayName: 'Banque'
    },
    {
        field: 'numeroCompte',
        displayName: 'Compte'
    },
    {
        field: 'typeOperation',
        displayName: 'Opération'
    },
    {
        field: 'typeMouvement',
        displayName: 'Type'
    },
    {
        field: 'valeurTypeEntite.designation',
        displayName: 'Superdealer'
    },
    {
        field: 'montant',
        displayName: 'Montant'
    },
    {
        field: 'devise',
        displayName: 'Devise'
    },
    {
        field: 'etat',
        displayName: 'Etat'
    }
];
