import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { FORM_CONST, formInitialState, URL_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    CustomSwitchButton
} from '../../../components';

const defaultFields = {
    isViderCompteFermeture: true
};

export function ViderCompteFermetureForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (event) => {
        const payload = {
            creator: '0oDzddBRCoUnpa2ncVm35AS4gdS2',
            estVideFermeture: fields.isViderCompteFermeture
        };
        event.preventDefault();
        formDispatcher(
            submitPutAction({
                reduxDispatcher,
                functionName: URL_CONST.PUT_VIDER_COMPTE_AFTER_FERMETURE,
                fields,
                idToken,
                payload
            })
        );
    };
    const renderContent = () => (
        <CustomRow className="">
            <CustomAlert
                status={
                    form.status === FORM_CONST.MODIFICATION_SUCCESS
                        ? FORM_CONST.MODIFICATION_SUCCESS
                        : ''
                }
            />
            <CustomAlert error={form.error} />
            <CustomRow>
                <CustomCol>
                    <CustomSwitchButton
                        id="isViderCompteFermeture"
                        text="Vider compte à la fermeture"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        value={fields.isViderCompteFermeture}
                        name="isViderCompteFermeture"
                        divClassName="col-md-8"
                    />
                    <div className="w-100" />
                    <fieldset>
                        <legend>
                            <p className="note">
                                En acceptant ce paramètre vous autorisez que
                                votre compte JNAPP soit vidé automatiquement à
                                la fermeture.
                            </p>
                        </legend>
                    </fieldset>
                    {/* <CustomCol className="col-md-4"> */}
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                    {/* </CustomCol> */}
                </CustomCol>
                {/* <CustomCol>
                    <p>Description</p>
                </CustomCol> */}
            </CustomRow>
        </CustomRow>
    );
    return renderContent();
}
