import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';

export function TokenRefresherComponent() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, tokenRefresher } = useSelector((reduxState) => ({
        firebaseUser: reduxState?.firebaseUser,
        tokenRefresher: reduxState?.tokenRefresher
    }));
    useEffect(() => {
        if (tokenRefresher?.value === GENERIC_ACTIONS_TYPE.REFRESH_TOKEN) {
            reduxDispatcher(GENERIC_ITEM_ACTIONS_CREATOR.doNotRefreshToken());
            firebaseUser.getIdToken(firebaseUser, true);
        }
    }, [firebaseUser, reduxDispatcher, tokenRefresher?.value]);

    return null;
}
