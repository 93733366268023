import { Link, useLocation } from 'react-router-dom';
import { CreationArticleForm } from './creation';
import { ListArticleForm } from './list-article';
import { CreationArticleFlashForm } from './creation-article-flash';

export function MarchandArticleSetting() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <CreationArticleForm />
            <hr />
            <CreationArticleFlashForm />
            <hr />
            <ListArticleForm />
            <hr />
            <Link to={`${pathname}/detailarticle/45`}>Detail</Link>
        </div>
    );
}
