import * as yup from 'yup';

export const ApproSimVenteSchema = yup.object().shape({
    tillNumberAcheteur: yup.string().required(),
    quantiteSim: yup.number().min(1),
    montantPercu: yup.array().min(1)
});

export const ApproSimGetByReferenceSchema = yup.object().shape({
    reference: yup.string().required()
});
