export const commandeMapper = [
    {
        field: 'numero',
        displayName: 'Numéro'
    },
    {
        field: 'quantite',
        displayName: 'quantite'
    },
    {
        field: 'item',
        displayName: 'Request'
    }
];
