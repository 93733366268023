import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../releve/generic';
import { URL_CONST } from '../../../util';
import { mapper } from './mapper';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isDisplayChart: true,
    isFetching: false,
    aggregated: {},
    devise: '',
    chartTitle: '',
    yAxisTittle: ''
};

export function RapportOperationBancaireForm() {
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const mapKeysValue = [
        { code: 'CrediterCaution', designation: 'Créditer Caution' },
        { code: 'RemboursementCaution', designation: 'Remboursement Caution' },
        { code: 'TransfertFonds', designation: 'Transfert de fonds' },
        { code: 'OperationVirement', designation: 'Virement' }
    ];
    const formater = (data) => {
        if (data === null) return [];
        const finalResult = [];
        const keys = Object.keys(data);
        if (keys?.length) {
            keys.forEach((key) => {
                const dataToFormat = data[key];
                if (dataToFormat?.length) {
                    dataToFormat.forEach((dtf) => {
                        const finder = finalResult.find(
                            (item) => item.id === dtf
                        );
                        if (!finder) {
                            const dataToPush = {
                                ...dtf,
                                typeOperation: mapKeysValue.find(
                                    (item) => item.code === key
                                )?.designation,
                                typeMouvement: dtf?.type || '',
                                numeroCompte:
                                    dtf?.numeroCompteExterne ||
                                    dtf?.numero ||
                                    dtf?.compte?.numero ||
                                    '',
                                devise:
                                    dtf?.devise || dtf?.compte?.devise || '',
                                etat: dtf?.etat || ''
                            };
                            finalResult.push(dataToPush);
                        }
                    });
                }
            });
        }
        return finalResult.sort((a, b) => a.dateCreation - b.dateCreation);
    };
    const fetchReport = () => {
        const { dateFin, dateDebut } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }

        const payload = {
            dateDebut,
            dateFin
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName:
                    URL_CONST.GET_LIST_OPERATION_INSTITUTION_FINANCIERE,
                etat: elements.mouvements.status,
                formDispatcher
            })
        );
    };
    return (
        <MainReportComponentLarge
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            isDetaille={false}
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={mapper}
            dataList={formater(elements.mouvements?.value) || []}
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
        />
    );
}
