import { useEtatChargementDependencies } from '@napp-inc/jnapp-hook';
import { DetailForm } from './detail-form';
import { LoadCompleteWrapper } from './load-complete-wrapper';
import { PrintWrapper } from './print-component';
/**
 * Componsant pour enrober le formulaire qui doivent charger des depedances avant d'être disponible pour le user
 * @param {Object} param0 objet contenant formDispatcher, dependencies, listDependencies et children
 * @param {Function} param0.formDispatcher formDispatcher
 * @param {Object} param0.dependencies objet pouvant contenir entity et/ou fields comme propriété(s)
 * @param {Array} param0.listDependencies tableau contenant des objets { dependency, etatChargement },
 * avec dependecy comme nom de la propriété (depedance) que l'on doit chargé et etatChargement representant son etat de chargement dans le store redux
 * @param {Boolean} param0.isPrintAllowed définit s'il faut ou pas afficher le button d'impression
 * @param {Objet} param0.children propriété children de React
 * @returns {React.Component}
 */
export function EtatChargementWrapper({
    formDispatcher,
    dependencies,
    listDependencies,
    entity,
    children,
    isDetail,
    schema,
    subtitle,
    isDownloadFile,
    isExportable,
    downloadFileFunction,
    exportDetailFunction,
    isPrintAllowed = true,
    optionalDependencies,
    isShowDetail,
    detailCallback
}) {
    useEtatChargementDependencies({
        formDispatcher,
        dependencies,
        list: listDependencies
    });

    return (
        <LoadCompleteWrapper
            optionalDependencies={optionalDependencies}
            dependencies={dependencies}
            entity={entity}
        >
            {isDetail ? (
                <>
                    {isPrintAllowed && (
                        <PrintWrapper>
                            <DetailForm
                                entity={entity}
                                schema={schema}
                                subtitle={subtitle}
                                isShowDetail={isShowDetail}
                                detailCallback={detailCallback}
                            />
                        </PrintWrapper>
                    )}
                    <DetailForm
                        entity={entity}
                        schema={schema}
                        subtitle={subtitle}
                        isDownloadFile={isDownloadFile}
                        isExportable={isExportable}
                        downloadFileFunction={downloadFileFunction}
                        exportDetailFunction={exportDetailFunction}
                        isShowDetail={isShowDetail}
                        detailCallback={detailCallback}
                    />
                    {children}
                </>
            ) : (
                children
            )}
        </LoadCompleteWrapper>
    );
}
