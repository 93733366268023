export const schema = [
    {
        title: 'Devise',
        description: 'devise',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Modifié le-',
        description: 'dateDerniereModification'
    }
];
