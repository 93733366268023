export const schema = [
    {
        title: 'Banques',
        description: 'institutionFinanciere.designation',
        className: 'border-bottom'
    },
    {
        title: 'Numero',
        description: 'numero',
        className: 'border-bottom'
    },
    {
        title: 'Devise',
        description: 'devise',
        className: 'border-bottom'
    },
    {
        title: 'Compte de transfert',
        description: 'estCompteTransfert',
        isBoolean: true
    },
    {
        title: 'Date création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
