import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { TYPE_ORGANISATION, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

const setDefaultProperties = ({ type }) => {
    const defaultMapper = [
        {
            field: 'institutionFinanciere.designation',
            displayName: 'Banque',
            isKeepFormat: true
        },
        {
            field: 'numero',
            displayName: 'Numero de compte',
            isKeepFormat: true
        },
        {
            field: 'devise',
            displayName: 'Devise',
            isKeepFormat: true
        },
        {
            field: 'estCompteTransfert',
            displayName: 'Compte de transfert',
            isBoolean: true
        },
        {
            field: 'dateCreation',
            displayName: 'Création',
            position: 5
        }
    ];
    // DefaultListerners
    const filters = [];

    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    return { mapper: [], listerners: [] };
};

export function ListCompteBancaireOrganisationForm() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));

    /**
     * Common form processes
     */
    const { typeEntite } = useGetUserDetail({
        nappUser
    });
    const { mapper } = setDefaultProperties({
        type: typeEntite
    });
    return (
        <ListGenericForm
            reduxPropertyName="comptesBancaireOrganisation"
            functionName={URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION}
            nodeName={REDUX_NODE_NAME.COMPTE_BANCAIRE_ORGANISATION}
            mapper={mapper}
        />
    );
}
