import { URL_CONST, useGetReportDetail } from '../../../../util';
import { StandardReportTemplate } from '../../template';
import { GenericSelector } from '../../../../components';

const formater = (donnees) =>
    donnees?.[0].map((item) => ({
        ...item,
        recruteur: `${item?.recrutement?.agent?.prenom || ''} ${
            item?.recrutement?.agent?.nom || ''
        }`
    }));

export function RapportListRevendeurRecruteur() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedRecruteur: {}
        }
    });
    return (
        <StandardReportTemplate
            listTitle={formState?.fields?.selectedRecruteur?.designation}
            functionName={URL_CONST.GET_LIST_REVENDEUR_BY_RECRUTEUR}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            mapper={[
                {
                    displayName: 'Désignation',
                    field: 'designation'
                },
                {
                    displayName: 'Till number',
                    field: 'tillNumber'
                },
                {
                    displayName: 'Recruteur',
                    field: 'recruteur'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
            isAddTotalTr
            payload={
                {
                    // dateDebut: TIME.utc000000(TODAY.at000000()),
                    // dateFin: TIME.utc235959(TODAY.at235959()),
                    // typeEntite: 'Journalier',
                    // estAllRevendeur: true
                }
            }
            defaultPayloadMapperValues={
                {
                    // typeEntite: 'Journalier',
                    // estAllRevendeur: true
                }
            }
            payloadMapper={[
                // {
                //     name: 'dateDebut',
                //     property: 'dateDebut',
                //     isRequired: true,
                //     displayName: 'Date début'
                // },
                // {
                //     name: 'dateFin',
                //     property: 'dateFin',
                //     isRequired: true,
                //     displayName: 'Date fin'
                // },
                {
                    name: 'selectedRecruteur',
                    isRequired: true,
                    displayName: 'Recruteur',
                    callback: (item) => {
                        if (!item?.id || item?.id === 'all') return false;
                        return { referenceAgentRecrutement: item?.id };
                    }
                }
            ]}
            isShouldShowDateRange={false}
        >
            <GenericSelector
                isDynamicNode
                dynamicNodeName="recruteursRevendeur"
                functionName={URL_CONST.GET_LIST_RECRUTEUR}
                formDispatcher={formDispatcher}
                formState={formState}
                label="Sélectionner un recruteur"
                propertyName="selectedRecruteur"
                listFormater={(list) =>
                    list.map((item) => ({
                        id: item?.reference,
                        code: item?.reference,
                        designation: `${item?.prenom || ''} ${item?.nom || ''}`
                    }))
                }
            />
        </StandardReportTemplate>
    );
}
