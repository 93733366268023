import {
    CODE_SERVICE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useHasService } from '../../../util';

const setDefaultProperties = ({ type, service, uid, oid }) => {
    let filters = [];
    if (
        (service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_TRANSFERT_CAISSE_KINDB?.code
        ] &&
            !service[
                CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                    .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB
                    ?.code
            ]) ||
        (service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_TRANSFERT_CAISSE_KINDB?.code
        ] &&
            service[
                CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                    .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB
                    ?.code
            ])
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.ARRAY_CONTAINS,
                property: 'users',
                value: uid,
                isRequired: true
            }
        ]);
    } else if (
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB?.code
        ] &&
        !service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_TRANSFERT_CAISSE_KINDB?.code
        ]
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.ARRAY_CONTAINS,
                property: 'organisations',
                value: oid,
                isRequired: true
            }
        ]);
    }

    if (
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_TRANSFERT_CAISSE_KINDB?.code
        ] ||
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB?.code
        ]
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.ORDER_BY,
                property: 'dateDerniereModification',
                value: 'asc'
            },
            {
                type: FILTER_TYPE.START_AT,
                value: TODAY.nowUtc(),
                isTimestampNowValue: true
            }
        ]);
    }
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        return {
            mapper: [
                {
                    displayName: 'Initiateur',
                    field: 'valeurTypeOrganisation.designation'
                },
                {
                    displayName: 'Caisse Source',
                    field: 'caisseA.designation'
                },
                {
                    displayName: 'Type Caisse Source',
                    tdExtractor: (item) =>
                        item?.caisseA?.estEmoney ? 'Emoney' : 'Cash'
                },
                {
                    displayName: 'Beneficiaire',
                    field: 'valeurTypeOrganisationB.designation'
                },
                {
                    displayName: 'Caisse Bénéficiaire',
                    field: 'caisseB.designation'
                },
                {
                    displayName: 'Type Caisse Bénéficiaire',
                    tdExtractor: (item) =>
                        item?.caisseB?.estEmoney ? 'Emoney' : 'Cash'
                },
                {
                    displayName: 'Reference Dépôt',
                    field: 'references.depot'
                },
                {
                    displayName: 'Reference Retrait',
                    field: 'references.retrait'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Devise',
                    field: 'devise'
                },
                {
                    displayName: 'Devise Sollicité',
                    field: 'deviseSollicite'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ],
            listerners: filters
        };
    }
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR) {
        return {
            mapper: [
                {
                    displayName: 'Initiateur',
                    field: 'valeurTypeOrganisation.designation'
                },
                {
                    displayName: 'Caisse Source',
                    field: 'caisseA.designation'
                },
                {
                    displayName: 'Beneficiaire',
                    field: 'valeurTypeOrganisationB.designation'
                },
                {
                    displayName: 'Caisse Bénéficiaire',
                    field: 'caisseB.designation'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Devise',
                    field: 'devise'
                },
                {
                    displayName: 'Devise Sollicité',
                    field: 'deviseSollicite'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ],
            listerners: filters
        };
    }
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            mapper: [
                {
                    displayName: 'Initiateur',
                    field: 'valeurTypeOrganisation.designation'
                },
                {
                    displayName: 'Caisse Source',
                    field: 'caisseA.designation'
                },
                {
                    displayName: 'Type Caisse Source',
                    tdExtractor: (item) =>
                        item?.caisseA?.estEmoney ? 'Emoney' : 'Cash'
                },
                {
                    displayName: 'Beneficiaire',
                    field: 'valeurTypeOrganisationB.designation'
                },
                {
                    displayName: 'Caisse Bénéficiaire',
                    field: 'caisseB.designation'
                },
                {
                    displayName: 'Type Caisse Bénéficiaire',
                    tdExtractor: (item) =>
                        item?.caisseB?.estEmoney ? 'Emoney' : 'Cash'
                },
                {
                    displayName: 'Reference Dépôt',
                    field: 'references.depot'
                },
                {
                    displayName: 'Reference Retrait',
                    field: 'references.retrait'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Devise',
                    field: 'devise'
                },
                {
                    displayName: 'Devise Sollicité',
                    field: 'deviseSollicite'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ],
            listerners: filters
        };
    }

    return { mapper: [], listerners: [] };
};

export function ListTransfertInterInstitutionnelForm() {
    /**
     * Get data on redux store
     */
    const { firebaseUser, organisation, nappUser } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            organisation: reduxState.organisation,
            nappUser: reduxState.nappUser
        })
    );
    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_TRANSFERT_CAISSE_KINDB,
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB
        ]
    });

    /**
     * Common form processes
     */
    const { uid, oid, typeEntite } = useGetUserDetail({
        firebaseUser,
        organisation,
        nappUser
    });
    const { mapper, listerners } = setDefaultProperties({
        type: typeEntite,
        service: hasService,
        uid,
        oid
    });
    return mapper.length ? (
        <ListGenericForm
            fetchType={FETCH_LISTENING}
            path={DB_NODE.TRANSFERT_KINDB}
            reduxPropertyName="transfertKinDb"
            functionName={URL_CONST.GET_LIST_TRANSFERT_CAISSE_KINDB}
            nodeName={REDUX_NODE_NAME.TRANSFERT_KINDB}
            mapper={mapper}
            listenerFilters={listerners}
        />
    ) : null;
}
