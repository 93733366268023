import * as yup from 'yup';

/**
 * @description : Schema de validation CrediterCompteViaInstitutionFin form
 */
export const CrediterCompteViaInstitutionFin = yup.object().shape({
    numeroCompteExterne: yup.string().required(),
    dateOperation: yup.string().required(),
    montant: yup.number().min(1),
    selectedInstitutionFinanciere: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
