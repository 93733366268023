import { ref, uploadBytesResumable } from '@firebase/storage';
import { asyncGenerator } from './asyncGenerator';
import { uploadTaskPromise } from './uploadTask';

/**
 * Fonction getResults
 * @param {Object} param0 : Objet contenant les parametres de notre fonction
 * @param {Array} param0.tab : Tableau des elements à uploader
 * @param {Fonction} param0.formDispatcher : Fonction de dispatch dans le formState
 * @param {FirebaseStorage} param0.refStorage : reférence du storage
 * @param {Object} param0.fieldData : fields du state avec toutes ses données
 * @param {String} param0.errorType : type d'erreur du formulaire
 * @param {String} param0.errorMessage : Message d'erreur en cas d'echec
 * @returns {Promise}
 */

export const getResultFilesToUpload = async ({
    tab = [],
    formDispatcher,
    refStorage,
    fieldData,
    errorType,
    errorMessage
}) => {
    const imgTab = [];
    const promises = tab.map((item) =>
        asyncGenerator({
            imgRef: refStorage,
            element: { file: fieldData } || item,
            formDispatcher,
            errorType,
            errorMessage
        })
    );
    const values = await Promise.all(promises);
    // eslint-disable-next-line no-restricted-syntax
    for (const promise of values) {
        if (promise) {
            const adder = Date.now();
            const uploadTask = uploadBytesResumable(
                ref(refStorage, `${adder}-${promise.metadata.name}`),
                fieldData
            );
            // eslint-disable-next-line no-await-in-loop
            const downloadURL = await uploadTaskPromise(uploadTask);
            imgTab.push({
                logoUrl: downloadURL,
                logo: `${adder}-${promise.metadata.name}`
            });
        }
    }
    return imgTab;
};
