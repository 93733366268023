import React from 'react';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import { ConfigurationPath } from '../../../routes/registration';
import { ListShutDownAndUpApiPartnerForm } from './list-shutdown-and-up-api-partner';

export function ShutDownAndUpApiPartnerViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Liste Api Marchand</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ConfigurationPath.ShutDownAndUpApiPartnerViewLayoutPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE
                                        .CODE_SERVICE_MODULE_ADMINISTRATION
                                        .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
                                        .ACTIVER_DESACTIVER_API_PARTENAIRE
                                ]}
                                destination={
                                    ConfigurationPath
                                        .ShutDownAndUpApiPartnerCreatePath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListShutDownAndUpApiPartnerForm />
        </div>
    );
}
