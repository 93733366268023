import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Stock } from '../../../module';
import {
    ApprovisionnementSimCreateRelPath,
    ApprovisionnementSimDetailRelPath,
    ApprovisionnementSimViewLayoutRelPath,
    FacturationStockCreateRelPath,
    FacturationStockDetailRelPath,
    FacturationStockViewLayoutRelPath,
    TransfertSimCreateRelPath,
    TransfertSimDetailRelPath,
    TransfertSimViewLayoutRelPath,
    VenteSimCreateRelPath,
    VenteSimDetailRelPath,
    VenteSimViewLayoutRelPath
} from './stock-rel-path';

/**
 * @desc Approvisionnement sim
 */

export const ApprovisionnementSimViewLayoutPath = {
    ...ApprovisionnementSimViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Stock.ApprovisionnementSimViewLayout,
    element: <Stock.ApprovisionnementSimViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .INITIER_APPRO_SIM_ACHAT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .VALIDER_APPRO_SIM_ACHAT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .ADOPTER_APPRO_SIM_ACHAT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .ANNULER_APPRO_SIM_ACHAT
    ]
};

export const ApprovisionnementSimCreatePath = {
    ...ApprovisionnementSimCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Stock.CreationApprovisionnementSimForm,
    element: <Stock.CreationApprovisionnementSimForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .INITIER_APPRO_SIM_ACHAT
    ]
};

export const ApprovisionnementSimDetailPath = {
    ...ApprovisionnementSimDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Stock.DetailApprovisionnementSimForm,
    element: <Stock.DetailApprovisionnementSimForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .INITIER_APPRO_SIM_ACHAT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .VALIDER_APPRO_SIM_ACHAT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .ADOPTER_APPRO_SIM_ACHAT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .ANNULER_APPRO_SIM_ACHAT
    ]
};

/**
 * @desc Transfert sim
 */

export const TransfertSimViewLayoutPath = {
    ...TransfertSimViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Stock.TransfertSimViewLayout,
    element: <Stock.TransfertSimViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .INITIER_DISPATCHING_SIM,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .VALIDER_DISPATCHING_SIM,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .ANNULER_DISPATCHING_SIM
    ]
};

export const TransfertSimCreatePath = {
    ...TransfertSimCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Stock.CreationTransfertSimForm,
    element: <Stock.CreationTransfertSimForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .INITIER_DISPATCHING_SIM
    ]
};

export const TransfertSimDetailPath = {
    ...TransfertSimDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Stock.DetailTransfertSimForm,
    element: <Stock.DetailTransfertSimForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .INITIER_DISPATCHING_SIM,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .VALIDER_DISPATCHING_SIM,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .ANNULER_DISPATCHING_SIM
    ]
};
/**
 * @desc Vente sim
 */

export const VenteSimViewLayoutPath = {
    ...VenteSimViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Stock.VenteSimViewLayout,
    element: <Stock.VenteSimViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .INITIER_APPRO_SIM_VENTE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .VALIDER_APPRO_SIM_VENTE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .LECTURE_APPRO_SIM_VENTE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .ANNULER_APPRO_SIM_VENTE
    ]
};

export const VenteSimCreatePath = {
    ...VenteSimCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Stock.CreationVenteSimForm,
    element: <Stock.CreationVenteSimForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .INITIER_APPRO_SIM_VENTE
    ]
};

export const VenteSimDetailPath = {
    ...VenteSimDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Stock.DetailVenteSimForm,
    element: <Stock.DetailVenteSimForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .INITIER_APPRO_SIM_VENTE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .VALIDER_APPRO_SIM_VENTE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .LECTURE_APPRO_SIM_VENTE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .ANNULER_APPRO_SIM_VENTE
    ]
};

/**
 * @desc Facturation Stock
 */

export const FacturationStockViewLayoutPath = {
    ...FacturationStockViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Stock.FacturationStockViewLayout,
    element: <Stock.FacturationStockViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.FACTURATION_STOCK.INITIER_CREATION_FACTURATION_STOCK,
        CODE_SERVICE.STOCK.FACTURATION_STOCK.LECTURE_FACTURATION_STOCK,
        CODE_SERVICE.STOCK.FACTURATION_STOCK.DECAISSER_FACTURATION_STOCK,
        CODE_SERVICE.STOCK.FACTURATION_STOCK.VALIDER_FACTURATION_STOCK,
        CODE_SERVICE.STOCK.FACTURATION_STOCK.CONFIRMER_FACTURATION_STOCK
    ]
};

export const FacturationStockCreatePath = {
    ...FacturationStockCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Stock.CreateFacturationStockForm,
    element: <Stock.CreateFacturationStockForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.FACTURATION_STOCK.INITIER_CREATION_FACTURATION_STOCK
    ]
};

export const FacturationStockDetailPath = {
    ...FacturationStockDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Stock.DetailFacturationStockForm,
    element: <Stock.DetailFacturationStockForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.FACTURATION_STOCK.INITIER_CREATION_FACTURATION_STOCK,
        CODE_SERVICE.STOCK.FACTURATION_STOCK.LECTURE_FACTURATION_STOCK,
        CODE_SERVICE.STOCK.FACTURATION_STOCK.DECAISSER_FACTURATION_STOCK,
        CODE_SERVICE.STOCK.FACTURATION_STOCK.VALIDER_FACTURATION_STOCK,
        CODE_SERVICE.STOCK.FACTURATION_STOCK.CONFIRMER_FACTURATION_STOCK
    ]
};
