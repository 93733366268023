import { DetailWrapper } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';

export function DetailCompteImputationForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'comptesImputation' });
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_COMPTE_BY_ID}
            nodeName={REDUX_NODE_NAME.COMPTE_IMPUTATION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Compte Imputation"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        />
    );
    return renderContent();
}
