import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    ETAT,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import {
    ConditionalRenderingWrapper,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailReevaluationCharge() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'reevaluations' });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = {
            etat,
            idReevaluation: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_REEVALUATION,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.REEVALUATION,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_REEVALUATION_REEVALUATION_BY_ID}
            nodeName={REDUX_NODE_NAME.REEVALUATION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Réévaluation"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ATTENTE_VALIDATION_REEVALUATION_CHARGE_FIXE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .VALIDER_REEVALUATION_CHARGE_FIXE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ADOPTER_REEVALUATION_CHARGE_FIXE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ATTENTE_VALIDATION_REEVALUATION_CHARGE_VARIABLE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .VALIDER_REEVALUATION_CHARGE_VARIABLE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ADOPTER_REEVALUATION_CHARGE_VARIABLE
                ]}
            >
                <CustomRow>
                    <div className="d-flex justify-content-end">
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat !== ETAT.ETAT_VALIDER
                            }
                        >
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger me-3"
                                confirmText="Oui"
                                cancelText="Non"
                                confirmClassName="w-25 btn-primary"
                                cancelClassName="w-25 btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                header="Confirmation Annulation"
                            />
                        </ConditionalRenderingWrapper>
                        <UserServiceWrapper
                            services={[
                                { code: 'test' },
                                CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                    .ATTENTE_VALIDATION_REEVALUATION_CHARGE_FIXE,
                                CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                    .ATTENTE_VALIDATION_REEVALUATION_CHARGE_VARIABLE
                            ]}
                        >
                            <ConditionalRenderingWrapper
                                isShouldBeRendered={
                                    entity?.etat === ETAT.ETAT_ENCOURS
                                }
                            >
                                <CustomConfirmButton
                                    type="button"
                                    text="Mettre en attente"
                                    className="btn-success"
                                    confirmText="Oui"
                                    cancelText="Non"
                                    confirmClassName="w-25 btn-primary"
                                    cancelClassName="w-25 btn-danger"
                                    confirmOnClick={() =>
                                        updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                    }
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ATTENTE_VALIDATION
                                    }
                                    header="Confirmation"
                                />
                            </ConditionalRenderingWrapper>
                        </UserServiceWrapper>
                        <UserServiceWrapper
                            services={[
                                { code: 'test' },
                                CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                    .VALIDER_REEVALUATION_CHARGE_FIXE,
                                CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                    .ADOPTER_REEVALUATION_CHARGE_FIXE,
                                CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                    .VALIDER_REEVALUATION_CHARGE_VARIABLE,
                                CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                    .ADOPTER_REEVALUATION_CHARGE_VARIABLE
                            ]}
                        >
                            <ConditionalRenderingWrapper
                                isShouldBeRendered={
                                    entity?.etat ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            >
                                <CustomConfirmButton
                                    type="button"
                                    text="Valider"
                                    className="btn-success"
                                    confirmText="Oui"
                                    cancelText="Non"
                                    confirmClassName="w-25 btn-primary"
                                    cancelClassName="w-25 btn-danger"
                                    confirmOnClick={() =>
                                        updateEtat(ETAT.ETAT_VALIDER)
                                    }
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                    header="Confirmation"
                                />
                            </ConditionalRenderingWrapper>
                        </UserServiceWrapper>
                    </div>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
