import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { FORM_CONST, formInitialState, URL_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    CustomSwitchButton
} from '../../../components';

const defaultFields = {
    isAccepterReference: true
};

export function AccepterReferenceForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (event) => {
        const payload = {
            creator: '0oDzddBRCoUnpa2ncVm35AS4gdS2',
            estPaiementByNumeroReference: fields.isAccepterReference
        };
        event.preventDefault();
        formDispatcher(
            submitPutAction({
                reduxDispatcher,
                functionName: URL_CONST.PUT_ACCEPTER_NUMERO_REFERENCE,
                fields,
                idToken,
                payload
            })
        );
    };
    const renderContent = () => (
        <CustomRow>
            <CustomAlert
                status={
                    form.status === FORM_CONST.MODIFICATION_SUCCESS
                        ? FORM_CONST.MODIFICATION_SUCCESS
                        : ''
                }
            />
            <CustomAlert error={form.error} />
            <CustomCol>
                <CustomSwitchButton
                    id="accepterReferenceConfig"
                    text="Accepter référence"
                    formDispatcher={formDispatcher}
                    value={fields.isAccepterReference}
                    name="isAccepterReference"
                    divClassName="col-md-8"
                    formState={formState}
                />
                <fieldset>
                    <legend>
                        <p className="note">
                            En acceptant la référence, vous acceptez que les
                            clients puissent effectuer leurs paiements par leur
                            numéro de référence.
                            <br />
                            Sinon, seul le scan s&apos;un code QR sera accepté.
                        </p>
                    </legend>
                </fieldset>
                {/* <CustomCol className="col-md-4"> */}
                <CustomButtonLoader
                    className="btn-success mt-3"
                    onClick={handleSubmit}
                    text="Sauvegarder"
                    disabled={form.status === FORM_CONST.MODIFICATION_EN_COURS}
                />
                {/* </CustomCol> */}
            </CustomCol>
            {/* <CustomCol>
                <p>Description</p>
            </CustomCol> */}
        </CustomRow>
    );
    return renderContent();
}
