import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { TypeStockFormSchema as uiValidator } from './validation';

const defaultFields = {
    code: '',
    designation: '',
    selectedCategorieMarchand: {},
    listCategorieMarchand: []
};

export function CreationTypeStockForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, categoriesMarchand, etatCategorieMarchand } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            categoriesMarchand: reduxState.categoriesMarchand.filter(
                (item) => item.typeEntite === 'Categorie'
            ),
            etatCategorieMarchand: reduxState.etat.categoriesMarchand.etat
        }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CATEGORIE_MARCHAND,
                nodeName: DB_NODE.CATEGORIE_MARCHAND,
                etat: etatCategorieMarchand
            }
        ]
    });
    const { fields, form } = formState;
    const addCategorieMarchand = () => {
        const { selectedCategorieMarchand, listCategorieMarchand } = fields;
        if (!selectedCategorieMarchand?.code) return;
        if (
            listCategorieMarchand.find(
                (item) => item?.code === selectedCategorieMarchand.code
            )
        )
            return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listCategorieMarchand',
                identifier: 'code',
                data: {
                    ...selectedCategorieMarchand
                }
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { code, designation, listCategorieMarchand } = fields;
        const payload = {
            newTypeStock: {
                code,
                designation,
                estSysteme: false
            },
            categoriesMarchand: listCategorieMarchand.map((item) => item.code),
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_TYPE_STOCK,
                payload,
                fields,
                reduxNodeName: DB_NODE.TYPE_STOCK,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Code*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le code"
                    formDispatcher={formDispatcher}
                    name="code"
                    id="codeCreationTypeStock"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Designation*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le nom"
                    formDispatcher={formDispatcher}
                    name="designation"
                    id="designationCreationTypeStock"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={categoriesMarchand}
                    label="Catégorie marchand*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-sm-8"
                    formDispatcher={formDispatcher}
                    name="selectedCategorieMarchand"
                    id="selectedCategorieMarchandCreationTypeStock"
                    formState={formState}
                    uiValidator={uiValidator}
                    idProperty="code"
                />
                <CustomCol className="col-sm-2">
                    <CustomAddButton
                        size="25em"
                        callback={addCategorieMarchand}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    <SimpleTable
                        identifier="code"
                        listSelectedItemName="listCategorieMarchand"
                        data={fields.listCategorieMarchand}
                        formDispatcher={formDispatcher}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 1,
                                field: 'designation',
                                displayName: 'Désignation'
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
