import {
    // useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { Str, TIME, TODAY, formInitialState } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Retourne un objet contenant la plupart des éléments requis sur un formulaire des détails
 * @param {Object} param Objet passé en paramètre avec les propriétés suivantes
 * @returns {Object}
 */
export function useGetReportDetail({
    // nodeName,
    defaultFields = {},
    defaultElements = [],
    // defaultFiles,
    // etatPath = 'etat',
    // listEtatFinaux = [],
    listNode = [] // [{name: 'reduxNameProperty', format: (item) => {return someFormatter(item)}}]
    // listFunctions = []
}) {
    const reduxDispatcher = useDispatch();
    const reduxProps = useSelector((reduxState) => {
        const reduxProperties = {};
        listNode.forEach((node) => {
            reduxProperties[node.name] = node.format
                ? node.format(reduxState[node.name])
                : reduxState[node.name];
            reduxProperties[`etat${Str.ucFirst(node.name)}`] =
                reduxState.etat[node.name]?.etat;
        });
        return {
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation,
            ...reduxProperties
        };
    });
    const { idToken, creator, profilUser, typeEntite, isNapp, oid, uid } =
        useGetUserDetail({
            firebaseUser: reduxProps.firebaseUser,
            nappUser: reduxProps.nappUser,
            organisation: reduxProps.organisation
        });
    // const { isShouldBeRendered } = useShouldBeRenderktkted({
    //     entity: reduxProps.entity,
    //     list: listEtatFinaux,
    //     etat: etatPath
    // });
    const initialState = formInitialState({
        fields: {
            debut: '',
            fin: '',
            dateDebut: TIME.utc000000(TODAY.at000000()),
            dateFin: TIME.utc235959(TODAY.at000000()),
            selectedDateRangeReport: {
                code: 'TODAY',
                designation: "Aujourd'hui"
            },
            isFetching: false,
            ...defaultFields
        },
        elements: ['mouvements', ...defaultElements]
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    // const list = listFunctions.map((item) => {
    //     if (Object.hasOwn(reduxProps, item.id)) {
    //         return { ...item, etat: reduxProps[`etat${Str.ucFirst(item.id)}`] };
    //     }
    //     return { ...item };
    // });
    // useFetchAndListening({
    //     idToken,
    //     reduxDispatcher,
    //     list
    // });
    // if (!nodeName) return "Une erreur s'est produite";
    return {
        reduxDispatcher,
        idToken,
        creator,
        formState,
        formDispatcher,
        profilUser,
        typeEntite,
        reduxProps,
        isNapp,
        oid,
        uid
    };
}
