import { Add, Div, floatParser } from '@napp-inc/jnapp-util';
import { useEffect, useState } from 'react';

export function useCalculTotalInput({
    listPercuRetour = [],
    deviseReference,
    taux = []
}) {
    const [totalInput, setTotal] = useState(0);
    const [message, setMessage] = useState('');
    useEffect(() => {
        if (listPercuRetour && listPercuRetour.length) {
            let totalTemp = 0;
            let errorMessage = '';
            listPercuRetour.forEach((item) => {
                if (typeof item.montant === 'number') {
                    if (item.devise === deviseReference) {
                        totalTemp = Add(totalTemp, item.montant);
                    } else {
                        const tauxToUse = taux.find(
                            (tx) => tx.devise === item.devise
                        );
                        if (tauxToUse && typeof tauxToUse.valeur === 'number') {
                            totalTemp += floatParser(
                                Div(item.montant, tauxToUse.valeur)
                            );
                        } else {
                            errorMessage += `Le taux de change de ${item.devise} n'est pas défini\n`;
                        }
                    }
                }
            });
            setTotal(totalTemp);
            setMessage(errorMessage);
        }
    }, [taux, deviseReference, listPercuRetour]);

    return { totalInput, message };
}
