export const schema = [
    {
        description: 'typeEntite',
        title: 'Type organisation',
        className: 'border-bottom'
    },
    {
        description: 'valeurTypeEntite.designation',
        title: 'Organisation',
        className: 'border-bottom'
    },
    {
        title: 'Devise',
        description: 'devise',
        className: 'border-bottom'
    },
    {
        title: 'Valeur',
        description: 'valeur',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
