/**
 * @desc Creation agence
 */

import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Configuration } from '../../../module';
import {
    CreationAgenceCreateRelPath,
    CreationAgenceDetailRelPath,
    CreationAgenceViewLayoutRelPath,
    CrediterCompteViaInstitutFinCreateRelPath,
    CrediterCompteViaInstitutFinDetailRelPath,
    CrediterCompteViaInstitutFinViewLayoutRelPath,
    HeureSupplementaireCreateRelPath,
    HeureSupplementaireDetailRelPath,
    HeureSupplementaireViewLayoutRelPath
} from './shared-config-rel-path';

export const CreationAgenceViewLayoutPath = {
    ...CreationAgenceViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationAgenceViewLayout,
    element: <Configuration.CreationAgenceViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.AGENCE_SERVICES.LECTURE_AGENCE_ORGANISATION,
        CODE_SERVICE.AGENCE_SERVICES.CREATION_AGENCE_ORGANISATION
    ]
};

export const CreationAgenceCreatePath = {
    ...CreationAgenceCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCreationAgenceForm,
    element: <Configuration.CreationCreationAgenceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.AGENCE_SERVICES.CREATION_AGENCE_ORGANISATION
    ]
};

export const CreationAgenceDetailPath = {
    ...CreationAgenceDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCreationAgenceForm,
    element: <Configuration.DetailCreationAgenceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.AGENCE_SERVICES.LECTURE_AGENCE_ORGANISATION,
        CODE_SERVICE.AGENCE_SERVICES.CREATION_AGENCE_ORGANISATION
    ]
};

/**
 * @desc Crediter compte via institut fin
 */

export const CrediterCompteViaInstitutFinViewLayoutPath = {
    ...CrediterCompteViaInstitutFinViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CrediterCompteViaInstitutFinViewLayout,
    element: <Configuration.CrediterCompteViaInstitutFinViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .CASH_COLLECTION_CREDITER_COMPTE_ORGANISATION
    ]
};

export const CrediterCompteViaInstitutFinCreatePath = {
    ...CrediterCompteViaInstitutFinCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCrediterCompteViaInstitutFinForm,
    element: <Configuration.CreationCrediterCompteViaInstitutFinForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .CASH_COLLECTION_CREDITER_COMPTE_ORGANISATION
    ]
};

export const CrediterCompteViaInstitutFinDetailPath = {
    ...CrediterCompteViaInstitutFinDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCrediterCompteViaInstitutFinForm,
    element: <Configuration.DetailCrediterCompteViaInstitutFinForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .CASH_COLLECTION_CREDITER_COMPTE_ORGANISATION
    ]
};

/**
 * @desc Heure supplementaire
 */

export const HeureSupplementaireViewLayoutPath = {
    ...HeureSupplementaireViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.HeureSupplementaireViewLayout,
    element: <Configuration.HeureSupplementaireViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.INITIER_HEURE_SUPPLEMENTAIRE,
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.VALIDER_HEURE_SUPPLEMENTAIRE,
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.LECTURE_HEURE_SUPPLEMENTAIRE
    ]
};

export const HeureSupplementaireCreatePath = {
    ...HeureSupplementaireCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationHeureSupplementaireForm,
    element: <Configuration.CreationHeureSupplementaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.INITIER_HEURE_SUPPLEMENTAIRE
    ]
};

export const HeureSupplementaireDetailPath = {
    ...HeureSupplementaireDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailHeureSupplementaireForm,
    element: <Configuration.DetailHeureSupplementaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.INITIER_HEURE_SUPPLEMENTAIRE,
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.VALIDER_HEURE_SUPPLEMENTAIRE,
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.LECTURE_HEURE_SUPPLEMENTAIRE
    ]
};
