import { BsFillKanbanFill, BsSim, BsLink45Deg, BsFront } from 'react-icons/bs';
import {
    GiShadowFollower,
    GiHeavyCollar,
    GiReceiveMoney
} from 'react-icons/gi';
import { AiFillApi, AiOutlinePicRight } from 'react-icons/ai';
import {
    RiSlideshowFill,
    RiFileTransferLine,
    RiProductHuntLine
} from 'react-icons/ri';
import { BiCubeAlt, BiFridge } from 'react-icons/bi';
import { SiDiagramsdotnet } from 'react-icons/si';
import { GoDatabase } from 'react-icons/go';
import { MdSystemUpdateAlt, MdPeopleOutline } from 'react-icons/md';
import { CgShutterstock } from 'react-icons/cg';

export const MarchandGeneralSettingRelPath = {
    name: 'Général',
    path: 'cmgensetting',
    icon: <BsFillKanbanFill className="icon-custom" />
};

// Show room
export const MarchandShowRoomSettingRelPath = {
    name: 'Show Room',
    path: 'cmshowroomsetting',
    icon: <RiSlideshowFill className="icon-custom" />
};

export const MarchandShowRoomCreateSettingRelPath = {
    name: '',
    path: 'create'
};

export const MarchandShowRoomDetailSettingRelPath = {
    name: '',
    path: ':id'
};

export const MarchandDetailRayonRelPath = {
    name: '',
    path: 'detailrayon/:id'
};

export const MarchandArticleRelPath = {
    name: 'Article',
    path: 'cmarticle',
    icon: <BiFridge className="icon-custom" />
};

// versement
export const MarchandVersementListRelPath = {
    name: 'Versement',
    path: 'versements',
    icon: <GiReceiveMoney className="icon-custom" />
};

export const MarchandVersementCreationRelPath = {
    name: 'Versement',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const MarchandVersementDetailRelPath = {
    name: 'Versement',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};

// affiliation
export const AffiliationAgentLivreurViewLayoutRelPath = {
    name: 'Affiliation agent livreur',
    path: 'affiliations',
    icon: <BsLink45Deg className="icon-custom" />
};

export const AffiliationAgentLivreurCreationRelPath = {
    name: '',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const AffiliationAgentLivreurDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};

// regularisation stock
export const RegularisationStockViewLayoutRelPath = {
    name: 'Régularisation stock',
    path: 'regul-stock',
    icon: <MdSystemUpdateAlt className="icon-custom" />
};

export const RegularisationStockCreationRelPath = {
    name: '',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const RegularisationStockDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};
// type stock
export const TypeStockViewLayoutRelPath = {
    name: 'Type stock',
    path: 'type-stock',
    icon: <CgShutterstock className="icon-custom" />
};

export const TypeStockCreationRelPath = {
    name: '',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const TypeStockDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};
// imputation stock
export const ImputationStockViewLayoutRelPath = {
    name: 'Imputation stock',
    path: 'imputations',
    icon: <AiOutlinePicRight className="icon-custom" />
};

export const ImputationStockCreationRelPath = {
    name: '',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const ImputationStockDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};
// imputation caisse
export const ImputationCaisseViewLayoutRelPath = {
    name: 'Imputation',
    path: 'imp-caisse',
    icon: <AiOutlinePicRight className="icon-custom" />
};

export const ImputationCaisseCreationRelPath = {
    name: '',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const ImputationCaisseDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};
// transfert stock
export const TransfertStockViewLayoutRelPath = {
    name: 'Transfert stock',
    path: 'transfert-stock',
    icon: <RiFileTransferLine className="icon-custom" />
};

export const TransfertStockCreationRelPath = {
    name: '',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const TransfertStockDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};
// prensence
export const PresenceViewLayoutRelPath = {
    name: 'Presence',
    path: 'presences',
    icon: <MdPeopleOutline className="icon-custom" />
};

export const PresenceCreationRelPath = {
    name: '',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const PresenceDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};
// tarif ressource
export const TarifRessourceViewLayoutRelPath = {
    name: 'Tarif ressource',
    path: 'tarif-ressource',
    icon: <BsFront className="icon-custom" />
};

export const TarifRessourceCreationRelPath = {
    name: '',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const TarifRessourceDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};
// tarif charge
export const TarifChargeViewLayoutRelPath = {
    name: 'Tarif charge',
    path: 'tarif-charge',
    icon: <GiHeavyCollar className="icon-custom" />
};

export const TarifChargeCreationRelPath = {
    name: '',
    path: 'create',
    icon: <BiCubeAlt className="icon-custom" />
};

export const TarifChargeDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};
// produit
export const ProduitViewLayoutRelPath = {
    name: 'Produit airtime',
    path: 'produits',
    icon: <RiProductHuntLine className="icon-custom" />
};

export const ProduitDetailRelPath = {
    name: '',
    path: ':id',
    icon: <BiCubeAlt className="icon-custom" />
};

export const ModifierColonneProduitAirtimeRelPath = {
    name: '',
    path: 'update-column',
    icon: <RiProductHuntLine className="icon-custom" />
};

// article

export const MarchandDetailArticleRelPath = {
    name: '',
    path: 'detailarticle/:id'
};

export const MarchandAbonnementSettingRelPath = {
    name: 'Abonnement',
    path: 'cmsubscribes',
    icon: <GiShadowFollower className="icon-custom" />
};

export const MarchandStockSettingRelPath = {
    name: 'Stock',
    path: 'cmstock',
    icon: <GoDatabase className="icon-custom" />
};

export const MarchandConfigSimSettingRelPath = {
    name: 'Sim',
    path: 'cmssim',
    icon: <BsSim className="icon-custom" />
};

export const AmendementElaborationSettingRelPath = {
    name: 'amendement',
    path: 'amend/:id'
};

export const AmendementElaborationAdditionnelSettingRelPath = {
    name: 'amendement-ad',
    path: 'amend-ad/:id'
};

// Dérogation

export const DerogationSettingRelPath = {
    name: 'Derogation Zone',
    path: 'derogation',
    icon: <SiDiagramsdotnet className="icon-custom" />
};

export const DerogationSettingCreateRelPath = {
    name: '',
    path: 'create'
};

export const DerogationSettingDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Mise ajour produit electronique rel paths
 */
export const MiseAjourProduitElectroniqueViewLayoutRelPath = {
    name: 'Update Prod Electronique',
    path: 'mise-ajour-produit-electronique',
    icon: <SiDiagramsdotnet className="icon-custom" />
};

export const MiseAjourProduitElectroniqueCreateRelPath = {
    name: '',
    path: 'create'
};

export const MiseAjourProduitElectroniqueDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Appointage Super dealer rel paths
 */
export const SuperDealerViewLayoutRelPath = {
    name: 'Appointage',
    path: 'appointsd',
    icon: <AiFillApi className="icon-custom" />
};

export const SuperDealerCreateRelPath = {
    name: '',
    path: 'create'
};

export const SuperDealerDetailRelPath = {
    name: '',
    path: ':id'
};
