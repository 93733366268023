import * as yup from 'yup';

/**
 * @description : Schema de validation pour la creation des comptes distributeurs
 */
export const CompteExterneDistributeurSchema = yup.object().shape({
    intituleCompte: yup.string().required(),
    numeroCompte: yup.string().required(),
    selectedSecteur: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    nom: yup.string(),
    postnom: yup.string(),
    prenom: yup.string().required(),
    email: yup.string().email(),
    numeroTel: yup.string()
});
