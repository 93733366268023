import { FORM_CONST } from '@napp-inc/jnapp-util';
import { ref, uploadBytes } from 'firebase/storage';

/**
 * Fonction asyncGenerator
 * @param {Object} param0 : Objet contenant les parametres de notre fonction
 * @param {FirebaseStorage} param0.imgRef : reférence du storage
 * @param {Object} param0.element : fields du state avec toutes ses données
 * @param {Fonction} param0.formDispatcher : Fonction de dispatch dans le formState
 * @param {String} param0.errorType : type d'erreur du formulaire
 * @param {String} param0.errorMessage : Message d'erreur en cas d'echec
 * @returns {Promise}
 */
export const asyncGenerator = async ({
    imgRef,
    element,
    formDispatcher,
    errorType = FORM_CONST.MODIFICATION_ECHEC,
    errorMessage = 'Erreur: Impossible de créer un image'
    // eslint-disable-next-line consistent-return
}) => {
    formDispatcher({
        type: FORM_CONST.FIELD_CHANGE,
        payload: {
            field: 'logoStatus',
            data: 'UPLOAD_PENDING'
        }
    });
    try {
        const response = await uploadBytes(
            ref(imgRef, element.file.name),
            element.file
        );
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'logoStatus',
                data: 'UPLOAD_SUCCESS'
            }
        });
        return response;
    } catch (error) {
        formDispatcher({
            type: FORM_CONST.SET_FORM_ERROR,
            payload: {
                message: errorMessage,
                status: errorType
            }
        });
    }
};
