import { DB_NODE, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

const setDefaultProperties = (type) => {
    const defaultMapper = [
        {
            field: 'devise',
            displayName: 'devise'
        },
        {
            field: 'etat',
            displayName: 'Etat'
        },
        {
            field: 'dateCreation',
            displayName: 'Création'
        },
        {
            field: 'dateDerniereModification',
            displayName: 'Modifié le-'
        }
    ];
    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: [
                {
                    field: 'typeEntite',
                    displayName: 'Type'
                },
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'Agence'
                }
            ].concat(defaultMapper),
            listerners: []
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: [
                {
                    field: 'designation',
                    displayName: 'Designation'
                }
            ].concat(defaultMapper),
            listerners: []
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: [
                {
                    field: 'designation',
                    displayName: 'Designation'
                }
            ].concat(defaultMapper),
            listerners: []
        };
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR)
        return {
            mapper: [
                {
                    field: 'designation',
                    displayName: 'Designation'
                }
            ].concat(defaultMapper),
            listerners: []
        };
    return { mapper: [], listerners: [] };
};

export function ListCaisseForm() {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { typeEntite } = useGetUserDetail({
        nappUser
    });
    const { mapper /* , listerners */ } = setDefaultProperties(typeEntite);
    return (
        <ListGenericForm
            reduxPropertyName="caisses"
            functionName={URL_CONST.GET_LIST_CAISSE}
            nodeName={DB_NODE.CAISSE}
            mapper={mapper}
        />
    );
}
