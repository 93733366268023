import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListRessourceForm() {
    return (
        <ListGenericForm
            reduxPropertyName="ressources"
            functionName={URL_CONST.GET_LIST_RESSOURCE}
            nodeName={DB_NODE.RESSOURCE}
            mapper={[
                {
                    field: 'code',
                    displayName: 'code',
                    position: 1
                },
                {
                    field: 'designation',
                    displayName: 'designation',
                    position: 2
                }
            ]}
        />
    );
}
