export const commandeMapper = [
    {
        field: 'numero',
        displayName: 'Numéro'
    },
    {
        field: 'quantite',
        displayName: 'quantite'
    },
    {
        field: 'dateCreation',
        displayName: 'Création'
    },
    {
        field: 'dateDerniereModification',
        displayName: 'Dernière modification'
    }
];
