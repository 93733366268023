import { Offcanvas } from 'react-bootstrap';
import { ConditionalRenderingWrapper, CustomCol } from '../container';
import { ReportDateRange } from '../calendrier';
import { CustomButtonLoader } from '../button';
import { CustomAlert } from '../alert';

/**
 * Composant pour afficher le offcanvas, composant principalement utilisé pour les filtres
 * @param {Object} param0 objet avec les paramètres suivants
 * @param {Object} param0.formState état du formulaire
 * @param {Object} param0.formDispatcher dispatcher du formulaire
 * @param {Function} param0.fetchReport fonction pour rechercher le rapport
 * @param {Boolean} param0.isFetching s'il faut afficher le loader
 * @param {Boolean} param0.show s'il faut afficher le offcanvas
 * @param {Function} param0.handleClose fonction pour fermer le offcanvas
 * @param {Boolean} param0.isShouldShowDateRange s'il faut afficher la date range picker
 * @param {String} param0.debutName nom du champ date début en chaine
 * @param {String} param0.finName nom du champ date fin en chaine
 * @param {String} param0.dateDebutName nom du champ date début
 * @param {String} param0.dateFinName nom du champ date fin
 * @param {String} param0.selectedDateRangeReportName nom du champ date range
 * @param {String} param0.searchButtonText texte du bouton de recherche
 * @param {String} param0.offCanvasTitle titre du offcanvas
 * @param {React.Component} param0.children composant enfant
 * @returns React.Component
 */

export function CustomOffCanvas({
    formState,
    formDispatcher,
    fetchReport,
    isFetching,
    show,
    handleClose,
    isShouldShowDateRange = true, // as default value on reportDateRange component
    debutName, // as default value on reportDateRange component
    finName, // as default value on reportDateRange component
    dateDebutName, // as default value on reportDateRange component
    dateFinName, // as default value on reportDateRange component
    selectedDateRangeReportName, // as default value on reportDateRange component
    searchButtonText = 'Rechercher',
    offCanvasTitle = 'Filtres',
    elementPropertyName,
    children
}) {
    const offcanvasOptions = {
        name: 'Enable backdrop (default)',
        scroll: true,
        backdrop: true,
        placement: 'end'
    };
    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...offcanvasOptions}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{offCanvasTitle}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-3">
                <ConditionalRenderingWrapper
                    isShouldBeRendered={isShouldShowDateRange}
                >
                    <CustomAlert
                        error={
                            formState?.elements?.[elementPropertyName]?.error
                        }
                    />
                    <CustomCol className="col-12">
                        <ReportDateRange
                            formState={formState}
                            formDispatcher={formDispatcher}
                            debutName={debutName}
                            finName={finName}
                            dateDebutName={dateDebutName}
                            dateFinName={dateFinName}
                            selectedDateRangeReportName={
                                selectedDateRangeReportName
                            }
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
                <CustomCol className="col-12">{children}</CustomCol>
                <CustomCol className="col-12 mt-2">
                    <CustomButtonLoader
                        onClick={fetchReport}
                        disabled={isFetching}
                        isActionEncours={isFetching}
                        text={searchButtonText}
                    />
                </CustomCol>
            </Offcanvas.Body>
        </Offcanvas>
    );
}
