import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST, useHasService } from '../../../util';

const setDefaultProperties = ({ type, service, uid, oid }) => {
    const defaultMapper = [
        {
            displayName: 'Compte',
            field: 'valeurTypeEntite.numeroCompteExterne',
            dataExtractor: (item) =>
                item?.estSim
                    ? item?.valeurTypeEntite?.designation
                    : item?.valeurTypeEntite?.numeroCompteExterne
        },
        {
            displayName: 'Organisation',
            field: 'superDealer.designation',
            dataExtractor: (item) =>
                item?.superDealer?.designation
                    ? item?.superDealer?.designation
                    : 'NAPP'
        },
        {
            displayName: 'Stock',
            field: 'numero'
        },
        {
            displayName: 'Paiement',
            field: 'typeEntite'
        },
        {
            displayName: 'Facturation',
            dataExtractor: (item) => (item?.estSim ? 'SIM' : 'FLASH')
        },
        {
            displayName: 'Devise',
            field: 'valeurTypeEntite.devise'
        },
        {
            displayName: 'Quantité',
            field: 'quantite'
        },
        {
            displayName: 'Montant',
            field: 'montant',
            isKeepFormat: true
        },
        {
            displayName: 'Etat',
            field: 'etat'
        },
        {
            displayName: 'Creation',
            field: 'dateCreation'
        }
    ];
    // DefaultListerners
    let filters = [];
    if (
        service[
            CODE_SERVICE.STOCK.FACTURATION_STOCK.DECAISSER_FACTURATION_STOCK
                ?.code
        ]
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'userDecaissement',
                value: uid
            }
        ]);
    }

    if (
        service[
            CODE_SERVICE.STOCK.FACTURATION_STOCK.VALIDER_FACTURATION_STOCK?.code
        ] ||
        service[
            CODE_SERVICE.STOCK.FACTURATION_STOCK
                .INITIER_CREATION_FACTURATION_STOCK?.code
        ]
    ) {
        filters = filters.concat([
            {
                property: 'organisations',
                value: [oid],
                operator: FILTER_OPERATOR.ARRAY_CONTAINS_ANY,
                type: FILTER_TYPE.WHERE
            }
        ]);
    }
    if (
        service[
            CODE_SERVICE.STOCK.FACTURATION_STOCK.VALIDER_FACTURATION_STOCK?.code
        ] ||
        service[
            CODE_SERVICE.STOCK.FACTURATION_STOCK
                .INITIER_CREATION_FACTURATION_STOCK?.code
        ] ||
        service[
            CODE_SERVICE.STOCK.FACTURATION_STOCK.DECAISSER_FACTURATION_STOCK
                ?.code
        ] ||
        service[
            CODE_SERVICE.STOCK.FACTURATION_STOCK.CONFIRMER_FACTURATION_STOCK
                ?.code
        ]
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.ORDER_BY,
                property: 'dateDerniereModification',
                value: 'asc'
            },
            {
                type: FILTER_TYPE.START_AT,
                value: TODAY.nowUtc(),
                isTimestampNowValue: true
            }
        ]);
    }

    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: [
                {
                    displayName: 'Compte',
                    field: 'valeurTypeEntite.numeroCompteExterne'
                },
                {
                    displayName: 'Stock',
                    field: 'numero'
                },
                {
                    displayName: 'Type Facturation',
                    field: 'typeEntite'
                },
                {
                    displayName: 'Devise',
                    field: 'valeurTypeEntite.devise'
                },
                {
                    displayName: 'Quantité',
                    field: 'quantite'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Creation',
                    field: 'dateCreation'
                }
            ],
            listerners: filters
        };
    return { mapper: [], listerners: [] };
};

export function ListFacturationStockForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, firebaseUser, organisation } = useSelector(
        (reduxState) => ({
            nappUser: reduxState.nappUser,
            firebaseUser: reduxState.firebaseUser,
            organisation: reduxState.organisation
        })
    );

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.STOCK.FACTURATION_STOCK
                .INITIER_CREATION_FACTURATION_STOCK,
            CODE_SERVICE.STOCK.FACTURATION_STOCK.VALIDER_FACTURATION_STOCK
        ]
    });

    /**
     * Common form processes
     */
    const { typeEntite, uid, oid } = useGetUserDetail({
        nappUser,
        firebaseUser,
        organisation
    });
    const { mapper, listerners } = setDefaultProperties({
        type: typeEntite,
        service: hasService,
        uid,
        oid
    });
    return mapper.length ? (
        <ListGenericForm
            reduxPropertyName="approvisionnementsStock"
            functionName={URL_CONST.GET_LIST_APPRO_STOCK_SUPER_DEALER}
            nodeName={DB_NODE.APPROVISIONNEMENT_STOCK}
            path={DB_NODE.APPROVISIONNEMENT_STOCK}
            fetchType={FETCH_LISTENING}
            mapper={mapper}
            listenerFilters={listerners}
        />
    ) : null;
}
