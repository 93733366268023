import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST /* , DB_NODE */
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    BilletageComponent,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    selectedCaisseSource: {},
    selectedCaisseDestination: {},
    selectedCoupureBilletage: {},
    nombreCoupureBilletage: 0,
    listBilletage: [],
    selectedCoupureBilletageDestination: {},
    nombreCoupureBilletageDestination: 0,
    listBilletageDestination: [],
    taux: 0,
    tauxB: 0,
    isTaux: false,
    isTauxB: false
};

/**
 * @description defaultDependencies for our state
 */
const defaultDependencies = { fields: ['caisses' /* , 'taux' */] };

export function CreationChangeForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        caisses,
        etatCaisses,
        // etatTaux,
        allCoupures,
        deviseReference
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        caisses: reduxState.caissesActiveUser.map((item) => ({
            ...item,
            designation: `${item?.designation || ''}-${item?.devise || ''}`
        })),
        etatCaisses: reduxState.etat.caissesActiveUser.etat,
        taux: reduxState.taux,
        etatTaux: reduxState.etat.taux.etat,
        allCoupures: reduxState.allCoupures,
        deviseReference: reduxState.deviseReference
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                payload: { estOrganisation: true },
                nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
                etat: etatCaisses
            }
            // {
            //     isOnlyFetch: true,
            //     nodeName: DB_NODE.TAUX,
            //     functionName: URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION,
            //     etat: etatTaux
            // }
        ]
    });

    const getCaisseDevise = (id) => {
        const findedDevise = caisses.find(
            (caisse) => id === caisse?.id
        )?.devise;

        return findedDevise;
    };
    const getCaisseCoupure = (id) =>
        allCoupures.find((coupure) => coupure.devise === getCaisseDevise(id))
            ?.coupures || [];

    // const matchDevise = getCaisseDevise();

    const getDestinationCaisses = () => {
        const caissesDestination = caisses.filter(
            (caisse) =>
                fields.selectedCaisseSource.id !== caisse?.id &&
                caisses.find(
                    (item) => fields.selectedCaisseSource?.id === item?.id
                )?.devise !== caisse?.devise
        );
        return {
            caissesDestination,
            tailleCaisses: caissesDestination.length
        };
    };
    const { caissesDestination, tailleCaisses } = getDestinationCaisses();

    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = { devises: [] };
        if (fields.listBilletage.length) {
            payloadToAdd.coupures = fields.listBilletage.map((billet) => ({
                nombre: billet.nombre,
                valeur: parseInt(billet.coupure.code, 10)
            }));
        }
        if (fields.listBilletageDestination.length) {
            payloadToAdd.coupuresSortie = fields.listBilletageDestination.map(
                (billet) => ({
                    nombre: billet.nombre,
                    valeur: parseInt(billet.coupure.code, 10)
                })
            );
        }
        if (
            fields.isTaux &&
            getCaisseDevise(fields.selectedCaisseSource.id) !==
                deviseReference?.devise
        ) {
            payloadToAdd.devises.push({
                devise: getCaisseDevise(fields.selectedCaisseSource.id),
                valeur: fields.taux
            });
        }
        if (
            fields.isTauxB &&
            getCaisseDevise(fields.selectedCaisseDestination.id) !==
                deviseReference?.devise
        ) {
            payloadToAdd.devises.push({
                devise: getCaisseDevise(fields.selectedCaisseDestination.id),
                valeur: fields.tauxB
            });
        }
        if (!payloadToAdd.devises.length) {
            delete payloadToAdd.devises;
        }
        const payload = {
            newChangeEspece: {
                typeEntite: 'Caisse',
                estTauxSollicite: !!payloadToAdd.devises?.length,
                valeurTypeEntite: {
                    reference: fields.selectedCaisseSource.id
                },
                provenance: {
                    reference: fields.selectedCaisseDestination.id
                }
            },
            ...payloadToAdd
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CHANGE_ESPECE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.OPERATION_CHANGE,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'caisses',
                    etat: etatCaisses
                }
                // {
                //     dependency: 'taux',
                //     etat: etatTaux
                // }
            ]}
        >
            {/* <StateShowerComponent state={formState} /> */}
            <CustomRow>
                <CustomDropdown
                    options={caisses}
                    label="Caisse à créditer*"
                    defaultOption="Sélectionner caisse"
                    // disabled={![].length}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCaisseSource"
                    id="selectedCaisseSourceCreationVersementAgentVendeur"
                    formState={formState}
                    idProperty="id"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!!fields.selectedCaisseSource.id}>
                <BilletageComponent
                    formState={formState}
                    formDispatcher={formDispatcher}
                    selectedCoupureName="selectedCoupureBilletage"
                    nombreCoupureName="nombreCoupureBilletage"
                    // deviseCaisse={caisses?.find(item => item.id === fields.selectedCaisseSource.id)?.devise}
                    deviseCaisse={getCaisseDevise(
                        fields.selectedCaisseSource.id
                    )}
                    coupures={getCaisseCoupure(fields.selectedCaisseSource.id)}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!fields.selectedCaisseSource.id &&
                    getCaisseDevise(fields.selectedCaisseSource.id) !==
                        deviseReference?.devise
                }
            >
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="isTauxCreationChangeId"
                    text="Renseigner un taux?"
                    value={fields.isTaux}
                    name="isTaux"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.isTaux &&
                    getCaisseDevise(fields.selectedCaisseSource.id) !==
                        deviseReference?.devise
                }
            >
                <CustomInput
                    type="number"
                    label="Taux*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Taux"
                    formDispatcher={formDispatcher}
                    name="taux"
                    id="creationencaissementDecaissementTauxId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={caissesDestination}
                    disabled={!tailleCaisses}
                    label="Caisse à Débiter*"
                    defaultOption="Sélectionner Caisse"
                    // disabled={![].length}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCaisseDestination"
                    id="selectedCaisseDestinationCreationVersementAgentVendeur"
                    formState={formState}
                    // uiValidator={uiValidator}
                    idProperty="id"
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={!!fields.selectedCaisseDestination.id}
            >
                <BilletageComponent
                    formState={formState}
                    formDispatcher={formDispatcher}
                    selectedCoupureName="selectedCoupureBilletageDestination"
                    nombreCoupureName="nombreCoupureBilletageDestination"
                    listBilletageName="listBilletageDestination"
                    // deviseCaisse={caisses?.find(item => item.id === fields.selectedCaisseSource.id)?.devise}
                    deviseCaisse={getCaisseDevise(
                        fields.selectedCaisseDestination.id
                    )}
                    coupures={getCaisseCoupure(
                        fields.selectedCaisseDestination.id
                    )}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!fields.selectedCaisseDestination.id &&
                    getCaisseDevise(fields.selectedCaisseDestination.id) !==
                        deviseReference?.devise
                }
            >
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="isTauxCreationChangeId"
                    text="Renseigner un taux?"
                    value={fields.isTauxB}
                    name="isTauxB"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.isTauxB &&
                    getCaisseDevise(fields.selectedCaisseDestination.id) !==
                        deviseReference?.devise
                }
            >
                <CustomInput
                    type="number"
                    label="Taux*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Taux"
                    formDispatcher={formDispatcher}
                    name="tauxB"
                    id="creationencaissementDecaissementTauxBId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
