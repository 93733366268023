import { useGetEntity } from '@napp-inc/jnapp-hook';
import { Navigate } from 'react-router-dom';
import { FormWrapper } from './form-wrapper';

/**
 * Composant pour enrober le formulaire de création/détail
 * IL inclut les composants de redirection, de retour à la page précédente,
 * d'affichage de message d'erreur, du formulaire,
 * du composant loader qui traque l'évolution du chargement des dependances
 * @param {Object} param0 Objet avec les propriétés children, formState, fomrDispatcher, listDependencies, entity
 * @param {Object} param0.formState state du formulaire
 * @param {Function} param0.formDispatcher formDispatcher
 * @param {Array} param0.listDependencies tableau contenant des objets { dependency, etatChargement },
 * avec dependecy comme nom de la propriété (depedance) que l'on doit chargé et etatChargement representant son etat de chargement dans le store redux
 *@param {Object} param0.entity objet entity du formulaire détail
 * @param {Objet} param0.children propriété children de React
 * @param {Function} param0.onSubmit function qui se déclenche à la soumission du formulaire
 * @returns {React.Component}
 */
export function DetailWrapper({
    children,
    functionName,
    nodeName,
    subtitle,
    formState,
    formDispatcher,
    schema,
    isDetail = true,
    onSubmit,
    reduxDispatcher,
    entity,
    id,
    idToken,
    isDownloadFile,
    isExportable,
    downloadFileFunction,
    exportDetailFunction,
    listDependencies,
    isShowDetail,
    detailCallback,
    actionButtons,
    formater
}) {
    const { dependencies } = formState || {};
    useGetEntity({
        entity,
        etat: dependencies?.entity?.status,
        formDispatcher,
        functionName,
        id,
        idToken,
        nodeName,
        reduxDispatcher,
        isShowDetail,
        detailCallback,
        formater
    });
    const renderContent = () => {
        if (id?.includes('create')) return <Navigate to="/not-found" replace />;
        return (
            <FormWrapper
                isDetail={isDetail}
                subtitle={subtitle}
                schema={schema}
                formState={formState}
                formDispatcher={formDispatcher}
                entity={entity}
                onSubmit={onSubmit}
                isDownloadFile={isDownloadFile}
                isExportable={isExportable}
                downloadFileFunction={downloadFileFunction}
                exportDetailFunction={exportDetailFunction}
                listDependencies={listDependencies}
                isShowDetail={isShowDetail}
                detailCallback={detailCallback}
                actionButtons={actionButtons}
            >
                {children}
            </FormWrapper>
        );
    };
    return renderContent();
}
