import { DB_NODE, ONLY_FETCH, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../components';
import { ViewLayout } from './view-layout';

export function ListPaiementForm() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                fetchType={ONLY_FETCH}
                reduxPropertyName="preuvePaiements"
                functionName={URL_CONST.GET_LIST_PREUVE_PAIEMENT}
                nodeName={DB_NODE.PREUVE_PAIEMENT}
                mapper={[
                    {
                        field: 'numeroReference',
                        displayName: 'Reference',
                        position: 1
                    },
                    {
                        field: 'agentTagueur.nom',
                        displayName: 'Agent',
                        position: 2
                    },
                    {
                        field: 'agentTagueur.tillNumber',
                        displayName: 'TillNumber',
                        position: 3
                    },
                    {
                        field: 'montant',
                        displayName: 'Montant',
                        position: 4
                    },
                    {
                        field: 'etat',
                        displayName: 'Etat',
                        position: 5
                    },
                    {
                        field: 'devise',
                        displayName: 'Devise',
                        position: 6
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 7
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
