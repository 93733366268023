import { Str } from '@napp-inc/jnapp-util';
import React from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { DateDisplayer, EtatDisplayer } from '../special-components';

export function DetailListItem({
    title,
    description,
    field,
    className,
    dateFormat,
    isDate,
    isEtat,
    isBoolean,
    concat
}) {
    const toDisplay = () => {
        if (field === 'etat' || isEtat)
            return <EtatDisplayer etat={description} />;
        if (
            field === 'dateCreation' ||
            field === 'dateDerniereModification' ||
            isDate
        )
            return <DateDisplayer date={description} dateFormat={dateFormat} />;
        if (typeof description === 'boolean' || isBoolean) {
            if (description) return <BsCheckCircle className="text-success" />;
            if (!description)
                return <MdRemoveCircleOutline className="text-danger" />;
        }
        return description;
    };
    return (
        <>
            <dt className={`col-sm-3 py-2 px-2 ${className}`}>
                {Str.ucFirst(title)}
            </dt>
            <dd className={`col-sm-9 py-2 px-2 ${className}`}>
                {toDisplay()}
                {concat ? ` ${concat}` : ''}
            </dd>
        </>
    );
}
