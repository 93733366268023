import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    RegularisationStockViewLayoutRelPath,
    RegularisationStockCreationRelPath
} from '../../../routes/registration/configuration/config-marchand-rel-path';
import { ListRegularisationStockForm } from './list-regul-stock';

export function RegularisationStockViewLayout() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Régularisation Stock</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${RegularisationStockViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[{ code: 'test' }]}
                                destination={
                                    RegularisationStockCreationRelPath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListRegularisationStockForm />
        </>
    );
}
