import { DB_NODE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListChargeForm() {
    return (
        <ListGenericForm
            reduxPropertyName="charges"
            functionName={URL_CONST.GET_ALL_CHARGE}
            nodeName={DB_NODE.CHARGE}
            mapper={[
                {
                    field: 'designation',
                    displayName: 'Designation',
                    position: 2
                },
                {
                    field: 'classe',
                    displayName: 'classe',
                    position: 3
                },
                {
                    field: 'compteImputation.designation',
                    displayName: 'compte imputation',
                    position: 4
                },
                {
                    field: 'devise',
                    displayName: 'Devise',
                    position: 5
                },
                {
                    field: 'typeEntite',
                    displayName: 'TypeEntité',
                    position: 6
                }
            ]}
        />
    );
}
