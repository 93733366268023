import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListPresenceAgentForm() {
    return (
        <ListGenericForm
            payload={{
                date: 1658188800000
            }}
            reduxPropertyName="presencesAgent"
            functionName={URL_CONST.GET_LIST_PRESENCE_AGENT}
            nodeName={DB_NODE.PRESENCE_AGENT}
            mapper={[
                {
                    field: 'id',
                    displayName: 'id',
                    position: 1
                },
                {
                    field: 'agent.nom',
                    displayName: 'nom',
                    position: 2
                },
                {
                    field: 'agent.prenom',
                    displayName: 'prenom',
                    position: 3
                }
            ]}
        />
    );
}
