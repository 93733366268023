export const schema = [
    {
        title: 'Marchand',
        description: 'marchand.designation',
        className: 'border-bottom'
    },
    {
        title: 'User',
        callback: (item) =>
            `${item?.user?.prenom || ''} ${item?.user?.nom || ''}`,
        className: 'border-bottom'
    },
    {
        title: 'Type business',
        description: 'typeBusiness',
        className: 'border-bottom'
    },
    {
        title: 'Revendeur',
        description: 'valeurTypeEntite.designation',
        className: 'border-bottom'
    },
    {
        title: 'Client',
        description: 'client.designation',
        className: 'border-bottom'
    },
    {
        title: 'Numéro',
        description: 'numero',
        className: 'border-bottom'
    },
    {
        title: 'Produit',
        description: 'produit.designation',
        className: 'border-bottom'
    },
    {
        title: 'Quantité',
        description: 'quantite',
        className: 'border-bottom'
    },
    {
        title: 'Prix total',
        description: 'prixTotal',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Demande annulation',
        description: 'demandeAnnulation',
        isBoolean: true,
        className: 'border-bottom',
        dataExtractor: (item) => !!item?.demandeAnnulation || false
    },
    {
        title: 'Motif',
        description: 'motif',
        className: 'border-bottom'
    },
    {
        title: 'Message partenaire',
        description: 'commentaires',
        callback: (item) => JSON.stringify(item?.commentaires || ''),
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Dernière modification',
        description: 'dateDerniereModification',
        className: 'border-bottom'
    }
];
