export const mapper = [
    {
        position: 0,
        type: 'text',
        id: 'codeCreationDirection',
        name: 'code',
        placeholder: "Désignation de l'organisation",
        label: 'Code*',
        isInputGroup: false
    },
    {
        position: 1,
        type: 'text',
        id: 'designationCreationDirection',
        name: 'designation',
        placeholder: "Code de l'organisation",
        label: 'Désignation*',
        isInputGroup: false
    },
    {
        position: 2,
        type: 'checkbox',
        id: 'estNappCheckButton',
        name: 'estNapp',
        label: 'Direction Napp ?',
        labelClassName: 'col-sm-2',
        inputClassName: 'col-sm-10 ms-1'
    }
];
