import * as yup from 'yup';

/**
 * @description : Schema Tarif ressource
 */
export const TarifRessourceFormSchema = yup.object().shape({
    estTarifPreferentiel: yup.boolean(),
    tillNumber: yup.string(),
    selectedType: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedCategorie: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedRessource: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    pourcentage: yup.number().min(0)
});
