import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListHeureSupplementaireForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, organisation } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation
    }));
    /**
     * Common form processes
     */
    const { oid, typeEntite } = useGetUserDetail({
        nappUser,
        organisation
    });
    return (
        <ListGenericForm
            fetchType={FETCH_LISTENING}
            path={DB_NODE.HEURE_SUPPLEMENTAIRE}
            listenerFilters={[
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'typeOrganisation',
                    value: typeEntite,
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'valeurTypeOrganisation.reference',
                    value: oid,
                    isRequired: true
                },
                {
                    property: 'dateDerniereModification',
                    value: 'asc',
                    type: FILTER_TYPE.ORDER_BY
                },
                {
                    type: FILTER_TYPE.START_AT,
                    value: TODAY.nowUtc(),
                    isTimestampNowValue: true
                }
            ]}
            reduxPropertyName="heuresSupplementaire"
            functionName={URL_CONST.GET_LIST_HEURE_SUPPLEMENTAIRE_INITIALS}
            nodeName={DB_NODE.HEURE_SUPPLEMENTAIRE}
            payload={{ estOrganisation: true }}
            mapper={[
                {
                    displayName: 'Agent',
                    field: 'valeurTypeOrganisation.designation'
                },
                {
                    displayName: 'Durée(H)',
                    field: 'nombreHeure'
                },
                {
                    displayName: 'Motif',
                    field: 'motif'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
