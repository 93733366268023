import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListBanqueForm() {
    return (
        <ListGenericForm
            reduxPropertyName="institutionsFinanciereToApprove"
            functionName={URL_CONST.GET_LIST_BANQUE_INITIALS}
            nodeName={REDUX_NODE_NAME.INSTITUTION_FINANCIERE_TO_APPROVE}
            mapper={[
                {
                    field: 'code',
                    displayName: 'Code',
                    position: 1
                },
                {
                    field: 'designation',
                    displayName: 'Désignation',
                    position: 2
                },
                {
                    field: 'etat',
                    displayName: 'Etat',
                    position: 3
                }
            ]}
        />
    );
}
