export const schema = [
    {
        title: 'Montant',
        description: 'montant',
        className: 'border-bottom'
    },
    {
        title: 'Devise',
        description: 'devise',
        className: 'border-bottom'
    },
    {
        title: 'Devise Sollicité',
        description: 'deviseSollicite',
        className: 'border-bottom'
    },
    {
        title: 'Revendeur',
        description: 'valeurTypeProprietaire.designation',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
