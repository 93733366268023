export const schema = [
    {
        description: 'institutionFinanciere.designation',
        title: 'Banque'
    },
    {
        description: 'numeroCompteExterne',
        title: 'Compte Externe'
    },
    {
        description: 'montant',
        title: 'Montant Opération'
    },
    {
        description: 'devise',
        title: 'Devise'
    },
    {
        description: 'dateCreation',
        title: 'Création'
    }
];
