import { Button } from 'react-bootstrap';
import { flattenObject, exportToExcel } from '@napp-inc/jnapp-util';
import { BsCloudArrowDown } from 'react-icons/bs';

/**
 * Button export à inclure au niveau des listes
 * @param {Object} param0 objet avec les propriétés suivantes
 * @param {Array} param0.dataList liste des éléments à exporter
 * @param {Array} param0.mapper mapper des éléments de la liste
 * @param {String} param0.fileName nom du fichier à exporter
 * @param {String} param0.sheetName nom de la feuille dans le fichier exporté
 * @param {String} param0.format format du fichier exporté (xlsx par défaut)
 * @returns {React.Component}
 */
export function ExcelExporter({
    dataList,
    mapper,
    fileName,
    sheetName,
    format,
    transformExportDataFunction
}) {
    const dataWithTransformation = transformExportDataFunction
        ? transformExportDataFunction(dataList)
        : {};
    return (
        <Button
            title="Exporter"
            size="sm"
            onClick={() =>
                exportToExcel({
                    data: transformExportDataFunction
                        ? flattenObject(
                              dataWithTransformation?.dataList,
                              dataWithTransformation?.mapper
                          )
                        : flattenObject(dataList, mapper),
                    fileName,
                    sheetName,
                    format
                })
            }
        >
            <BsCloudArrowDown /> Export
        </Button>
    );
}
