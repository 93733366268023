import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { DetailWrapper } from '../../../components';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailServiceGenerateurForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'servicesGenerateur' });
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_SERVICE_GENERATEUR_BY_ID}
            nodeName={DB_NODE.SERVICE_GENERATEUR}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Service Générateur"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        />
    );
    return renderContent();
}
