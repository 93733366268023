import * as yup from 'yup';

/**
 * @description : Schema tranfert Stock
 */
export const PaiementVendorsFormSchema = yup.object().shape({
    nombreMois: yup.number().min(1).max(3),
    selectedConfiguration: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    dateDebut: yup.string().required()
});
