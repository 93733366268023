import {
    downloadFileByUrlAction,
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { ref, getDownloadURL } from 'firebase/storage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomInput,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { instructionRef } from '../../../firebase';
import { extractTXTFileInfo } from '../../../util';
import { schema } from './detail-fields';
import { DetailInstructionSchema as uiValidator } from './validation';

/**
 * @desc defaultFields
 */
const defaultFields = {
    actionEncours: '',
    numeroReference: ''
};

/**
 * @desc defaultFiles
 */
const defaultFiles = ['instruction'];

// FIXME: LES ACTIONS (ETAT) DOIVENT TENIR COMPTE DES SERVICES

export function DetailInstruction() {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    const { firebaseUser, entity } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        entity: reduxState.instructions.find((element) => element.id === id)
    }));
    const { idToken } = useGetUserDetail({
        firebaseUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: { entity: true },
        files: defaultFiles
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, files, form } = formState;

    const nameOfFile = entity?.fileNameText
        ? entity?.fileNameText
        : entity?.fileName;

    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;

        const payloadToAdd = {};
        if (fields.numeroReference) {
            payloadToAdd.numeroReference = fields.numeroReference;
        }
        const payload = {
            etat,
            idInstruction: entity?.id,
            ...payloadToAdd
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_VALIDATION_INSTRUCTION,
                payload,
                fields,
                reduxNodeName: DB_NODE.INSTRUCTION,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const downloadFunction = () => {
        formDispatcher(
            downloadFileByUrlAction({
                getDownloadURL,
                fileRef: ref(instructionRef, nameOfFile),
                propertyName: 'instruction',
                isBlob: true
            })
        );
    };
    useEffect(() => {
        if (
            files.instruction?.status === FORM_CONST.FILE_DOWNLOADED &&
            !!entity?.fileNameText
        ) {
            extractTXTFileInfo({
                props: files.instruction,
                fileName: nameOfFile
            });
        } else if (
            files.instruction?.status === FORM_CONST.FILE_DOWNLOADED &&
            !!entity?.fileName
        ) {
            const link = document.createElement('a');
            link.href = files.instruction?.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        return () => {};
    }, [
        nameOfFile,
        files.instruction,
        files.instruction?.status,
        entity?.fileNameText,
        entity?.fileName
    ]);

    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_INSTRUCTION_BY_ID}
            nodeName={DB_NODE.INSTRUCTION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Instructions"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
            downloadFileFunction={downloadFunction}
            isDownloadFile={!!entity?.fileName || !!entity?.fileNameText}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION
                        .CONFIRMER_INSTRUCTION,
                    CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION
                        .VALIDER_FEEDBACK_INSTRUCTION,
                    CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION
                        .ADOPTER_INSTRUCTION,
                    CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION
                        .VALIDER_INSTRUCTION
                ]}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                    }
                >
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Référence*"
                            labelClassName="col-12 col-md-2"
                            divClassName="col-12 col-md-10"
                            placeholder="Numéro de référence"
                            formDispatcher={formDispatcher}
                            name="numeroReference"
                            id="numeroReferenceDetailInstructionId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Approuver"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER_FEED_BACK)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_VALIDER_FEED_BACK
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                </ConditionalRenderingWrapper>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
