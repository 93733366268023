import { FORM_CONST } from '@napp-inc/jnapp-util';
import { useEffect, useState } from 'react';

export function useFormatPeruRetourMontantDeviseList({
    formDispatcher,
    monnaiesLocale,
    percuRetourName
}) {
    const [listPercuRetour, setListPercuRetour] = useState([]);
    useEffect(() => {
        if (monnaiesLocale && monnaiesLocale.length) {
            const tempList = [];
            monnaiesLocale.forEach((item) => {
                tempList.push({
                    id: item.code || item.devise,
                    devise: item.code || item.devise,
                    montant: 0
                });
            });
            setListPercuRetour(tempList);
            if (formDispatcher && percuRetourName) {
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: percuRetourName,
                        data: tempList
                    }
                });
            }
        }
    }, [monnaiesLocale, formDispatcher, percuRetourName]);
    return { listPercuRetour };
}
