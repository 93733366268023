import { Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { RapportCommandeFlashForm } from './rapport-commande-flash';
import { ListTotauxLivreesForm } from './list-totaux-livrees';
import { UserServiceWrapper } from '../../../components';
import { ReleveStockForm } from './releve';

export function ReleveCommandeFlashViewLayout() {
    const [key, setKey] = useState('report');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Rapport Airtime</h1>
            </div>

            <Tabs
                defaultActiveKey="report"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="report" title="Rapport airtime">
                    {key === 'report' && <RapportCommandeFlashForm />}
                </Tab>
                <Tab eventKey="totaux" title="Totaux livrées">
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.STOCK.REGULARISATION_STOCK
                                .INITIER_CREATION_REGULARISATION_STOCK,
                            CODE_SERVICE.STOCK.REGULARISATION_STOCK
                                .VALIDER_REGULARISATION_STOCK
                        ]}
                    >
                        {key === 'totaux' && <ListTotauxLivreesForm />}
                    </UserServiceWrapper>
                </Tab>
                <Tab eventKey="releve" title="Relevé">
                    {key === 'releve' && <ReleveStockForm />}
                </Tab>
            </Tabs>
        </div>
    );
}
