import React, { useCallback } from 'react';
import { FORM_CONST, TIME, TODAY } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { ReduxReportTemplate } from '../template';
import {
    CustomDropdown,
    CustomRow,
    SuperDealerSelector
} from '../../../components';
import { URL_CONST, useGetReportDetail } from '../../../util';

export function ListReleveActiviteDepotRetrait() {
    const { superDealerKinDB } = useSelector((reduxState) => ({
        superDealerKinDB: reduxState.allSuperDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        }))
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedSuperDealer: {},
            selectedOperation: {}
        }
    });
    const { fields } = formState;
    const codeMarchand = useCallback(
        () =>
            superDealerKinDB?.find(
                (item) => item.code === fields.selectedSuperDealer?.code
            )?.marchand?.code,
        [fields.selectedSuperDealer?.code, superDealerKinDB]
    );
    const cfunction =
        fields?.selectedOperation?.code === 'RETRAIT'
            ? URL_CONST.GET_LIST_RETRAIT_USER
            : URL_CONST.GET_LIST_DEPOT_EMONEY_USER;
    const mapper =
        fields?.selectedOperation?.code === 'RETRAIT'
            ? [
                  {
                      field: 'valeurTypePartenariat.designation',
                      displayName: 'Organisation'
                  },
                  {
                      field: 'client.designation',
                      displayName: 'Client'
                  },
                  {
                      field: 'numeroReference',
                      displayName: 'Reference'
                  },
                  {
                      field: 'montant',
                      displayName: 'montant'
                  },
                  {
                      field: 'devise',
                      displayName: 'devise'
                  },
                  {
                      field: 'etat',
                      displayName: 'etat'
                  },
                  {
                      field: 'dateCreation',
                      displayName: 'Création'
                  },
                  {
                      field: 'dateDerniereModification',
                      displayName: 'Modifié le-'
                  }
              ]
            : [
                  {
                      displayName: 'Marchand',
                      field: 'marchand.designation'
                  },
                  {
                      displayName: 'Client',
                      field: 'client.designation'
                  },
                  {
                      field: 'client.numero',
                      displayName: 'Numéro'
                  },
                  {
                      field: 'devise',
                      displayName: 'devise'
                  },
                  {
                      displayName: 'montant',
                      field: 'montant',
                      isNumber: true
                  },
                  {
                      field: 'etat',
                      displayName: 'Etat'
                  },
                  {
                      field: 'dateCreation',
                      displayName: 'Création'
                  },
                  {
                      field: 'dateDerniereModification',
                      displayName: 'Dernière modification'
                  }
              ];
    const payload = {
        dateDebut: TIME.utc000000(TODAY.at000000()),
        dateFin: TIME.utc235959(TODAY.at235959()),
        codeMarchand: codeMarchand(),
        estOrganisation: false,
        estMax: true
    };
    const listFormater = (donnes) => (donnes?.length ? donnes?.[0] : donnes);
    const resetTheEntireState = (data, fieldsReseted) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'selectedOperation',
                data
            }
        });
        formDispatcher({
            type: FORM_CONST.REINITIALISER,
            payload: {
                ...fieldsReseted,
                initialState: {
                    ...fieldsReseted.initialState,
                    fields: {
                        ...fieldsReseted.initialState.fields,
                        selectedOperation: data
                    }
                }
            }
        });
    };
    return (
        <ReduxReportTemplate
            formState={formState}
            formDispatcher={formDispatcher}
            functionName={cfunction}
            listFormater={listFormater}
            mapper={mapper}
            isAddTotalTr
            defaultPayloadMapperValues={{
                ...payload
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={[
                        { code: 'RETRAIT', designation: 'Retrait' },
                        { code: 'DEPOT', designation: 'Dépôt' }
                    ]}
                    defaultOption="Selectionner Opération"
                    label="Type Opération: "
                    labelClassName="col-12"
                    divClassName="col-12"
                    formDispatcher={formDispatcher}
                    name="selectedOperation"
                    id="selectedOperationCreationApproCompteEmoney"
                    // uiValidator={CreationApproCompteEmoneySchema}
                    formState={formState}
                    idProperty="code"
                    callback={resetTheEntireState}
                    fieldsReseted={{
                        initialState: {
                            ...formState,
                            elements: {
                                ...formState.elements,
                                mouvements: {
                                    error: null,
                                    status: FORM_CONST.STATUT.ETAT_INITIAL,
                                    value: []
                                }
                            },
                            fields: {
                                ...formState.fields
                            }
                        }
                    }}
                />
            </CustomRow>
            <SuperDealerSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                estNonAirtime
            />
        </ReduxReportTemplate>
    );
}
