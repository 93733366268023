import { Navigate, useLocation } from 'react-router-dom';
import { useShouldRedirectOnState, useWhereToGo } from '../../util';

/**
 * Ce composant est utilisé pour faire de la redirection
 * Utile pour le formulaire qui effectuent des MAJ dans la BD
 * @param {Object} param0.form  form of the form state
 * @returns {React.Component}
 */
export function RedirectionComponent({ form }) {
    const { pathname } = useLocation();
    const { destination } = useWhereToGo(pathname);
    const isShouldRedirect = useShouldRedirectOnState(form?.status);
    return isShouldRedirect ? <Navigate to={destination} replace /> : '';
}
