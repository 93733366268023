import React, { useEffect, useMemo } from 'react';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useSelector, useDispatch } from 'react-redux';
import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { BsCameraVideoOff, BsCameraVideo } from 'react-icons/bs';
import { useSound } from 'use-sound';
import { Scanner } from '../../components/qr-scanner';
import {
    CustomButton,
    CustomButtonLoader,
    CustomCollapse,
    CustomInput,
    CustomSwitchButton,
    FormWrapper
} from '../../components';
import { isVideoDeviceAvailable } from '../../util/devices';
import successSound from '../../assets/sounds/one.wav';
import failedSound from '../../assets/sounds/two.wav';
import failedSound3 from '../../assets/sounds/three.wav';

const defaultFields = {
    numeroReference: '',
    scannedNumeroReference: '',
    isSaisirReference: true,
    isShowScanner: false,
    isVideoAvailable: false,
    frontCamera: true
};

export function CheckPaiementForm() {
    const reduxDispatcher = useDispatch();
    const abortController = useMemo(() => new AbortController(), []);

    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const [playSuccess] = useSound(successSound, { volume: 0.01 });
    const [playFailed] = useSound(failedSound, { volume: 0.01 });
    const [playFailed3] = useSound(failedSound3, { volume: 0.01 });
    const { fields, form } = formState;
    const paiement = useSelector(({ preuvePaiements }) => {
        const ref = fields.isShowScanner
            ? fields.scannedNumeroReference
            : fields.numeroReference;
        return preuvePaiements.find((p) => p.numeroReference === ref);
    });
    useEffect(() => {
        isVideoDeviceAvailable().then((isAvailable) => {
            formDispatcher({
                type: FORM_CONST.TEXT_FIELD_CHANGE,
                payload: {
                    field: 'isVideoAvailable',
                    data: isAvailable
                }
            });
        });
        return () => abortController.abort();
    }, [formDispatcher, abortController]);
    function soundCallback(type) {
        if (type === 'success') {
            playSuccess();
        } else if (type === 'failedExiste') {
            playFailed();
        } else if (type === 'notfound') {
            playFailed3();
        }
    }
    const recherche = (referenceSent) => {
        const { numeroReference } = fields;
        const reference = referenceSent || numeroReference;
        if (!reference) return;
        const payload = {
            creator,
            numeroReference: reference
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: 'putpreuvePaiements',
                payload,
                reduxDispatcher,
                reduxNodeName: DB_NODE.PREUVE_PAIEMENT,
                soundCallback
            })
        );
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        recherche();
    };

    const renderContent = () => {
        function FrontCamera() {
            return (
                <Scanner
                    formDispatcher={formDispatcher}
                    name="scannedNumeroReference"
                    callback={recherche}
                />
            );
        }
        function BackCamera() {
            return (
                <Scanner
                    formDispatcher={formDispatcher}
                    name="scannedNumeroReference"
                    callback={recherche}
                    backCamera
                />
            );
        }
        return (
            <>
                <FormWrapper
                    onSubmit={handleSubmit}
                    formState={formState}
                    formDispatcher={formDispatcher}
                >
                    <h1>Checker paiement</h1>
                    <hr />
                    <CustomSwitchButton
                        text="Saisir référence"
                        formDispatcher={formDispatcher}
                        id="scannerpreuvePaiements"
                        defaultChecked={fields.isSaisirReference}
                        value={fields.isSaisirReference}
                        disabled={!fields.isVideoAvailable}
                        name="isSaisirReference"
                    />
                    <CustomCollapse
                        isIn={!fields.isSaisirReference}
                        className="row"
                    >
                        <div
                            className="col-md-1 mt-4 mr-4"
                            style={{ marginRight: '1em' }}
                        >
                            <CustomButton
                                type="button"
                                onClick={() =>
                                    formDispatcher({
                                        type: FORM_CONST.TEXT_FIELD_CHANGE,
                                        payload: {
                                            field: 'isShowScanner',
                                            data: !fields.isShowScanner
                                        }
                                    })
                                }
                                className={
                                    fields.isShowScanner
                                        ? 'btn btn-danger'
                                        : 'btn btn-dark'
                                }
                            >
                                {fields.isShowScanner ? (
                                    <span className="">
                                        <BsCameraVideoOff size="1.5em" />
                                    </span>
                                ) : (
                                    <span className="">
                                        <BsCameraVideo size="1.5em" />
                                    </span>
                                )}
                            </CustomButton>
                            <CustomSwitchButton
                                id="selectCameraChecking"
                                text="Avant"
                                offText="Arrière"
                                formDispatcher={formDispatcher}
                                value={fields.frontCamera}
                                disabled={!fields.isVideoAvailable}
                                name="frontCamera"
                                formState={formState}
                            />
                        </div>
                        {!fields.isSaisirReference &&
                        fields.isShowScanner &&
                        fields.frontCamera ? (
                            <div className="col-md-4 ml-2">{FrontCamera()}</div>
                        ) : undefined}
                        {!fields.isSaisirReference &&
                        fields.isShowScanner &&
                        !fields.frontCamera ? (
                            <div className="col-md-4 ml-2">{BackCamera()}</div>
                        ) : undefined}
                        <div
                            hidden={
                                form.status !== FORM_CONST.MODIFICATION_EN_COURS
                            }
                            className="spinner-border  text-primary"
                            role="status"
                        />
                    </CustomCollapse>
                    <CustomCollapse isIn={!!fields.isSaisirReference}>
                        <CustomInput
                            name="numeroReference"
                            className=""
                            type="text"
                            placeholder="text"
                            htmlFor="TxtField"
                            value={formState.fields.numeroReference}
                            formDispatcher={formDispatcher}
                        />
                        <CustomButtonLoader
                            className="btn-success mt-2"
                            onClick={handleSubmit}
                            text="Rechercher"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                        />
                    </CustomCollapse>
                </FormWrapper>
                {paiement && (
                    <div className="border m-2 p-4">
                        <div className="row">
                            Référence : {` ${paiement?.numeroReference}`}
                        </div>
                        <div className="row">
                            Till nuumber (client) : c&apos;est lequel
                        </div>
                        <div className="row">
                            Montant :
                            {` ${paiement?.montant} ${paiement?.devise}`}
                        </div>
                    </div>
                )}
            </>
        );
    };
    return renderContent();
}
