import {
    TIME,
    TODAY,
    TYPE_ORGANISATION,
    floatParser
} from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { CashCollecteurSelector } from '../../../../components';

const formater = (donnees) => {
    const result = [];
    (donnees?.[0]?.listReturn || []).forEach((item) => {
        const existing = result.find(
            (elm) =>
                elm?.superDealer?.code === item?.detail?.valeurTypeEntite?.code
        );
        if (existing) {
            if (
                Object.prototype.hasOwnProperty.call(
                    existing?.montant,
                    item?.detail?.devise
                )
            ) {
                const montant = {
                    cash: 0,
                    emoney: 0,
                    ...existing.montant[item?.detail?.devise]
                };
                if (item?.detail?.estEmoney)
                    montant.emoney += floatParser(item?.soldeFermeture || 0);
                else montant.cash += floatParser(item?.soldeFermeture || 0);

                existing.montant[item?.detail?.devise] = montant;
            } else {
                const montant = {
                    cash: 0,
                    emoney: 0
                };
                if (item?.detail?.estEmoney)
                    montant.emoney = floatParser(item?.soldeFermeture || 0);
                else montant.cash = floatParser(item?.soldeFermeture || 0);

                existing.montant[item?.detail?.devise] = montant;
            }
        } else {
            const montant = {
                cash: 0,
                emoney: 0
            };
            if (item?.detail?.estEmoney)
                montant.emoney = floatParser(item?.soldeFermeture || 0);
            else montant.cash = floatParser(item?.soldeFermeture || 0);
            result.push({
                id: item?.detail?.code,
                superDealer: {
                    code: item?.detail?.valeurTypeEntite?.code,
                    designation: item?.detail?.valeurTypeEntite?.designation
                },
                montant: {
                    [item?.detail?.devise]: montant
                }
            });
        }
    });
    return result;
};

// const dynamicColumnFormater = (dynamicColumn) => {
//     const result = [];
//     Object.keys(dynamicColumn || {}).forEach((key) => {
//         result.push(
//             {
//                 displayName: `Cash-${key}`,
//                 field: `montant.${key}.cash`,
//                 isNumber: true
//             },
//             {
//                 displayName: `Emoney-${key}`,
//                 field: `montant.${key}.emoney`,
//                 isNumber: true
//             }
//         );
//     });
//     return result;
// };
export function RapportCaisseCashCollecteurForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCashCollecteur: {}
        }
    });
    return (
        <ReduxReportTemplate
            dynamicNodeName={`reportsCaisseCashCollecteur-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_CAISSE_ACTIVE_USER}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            mapper={[
                {
                    displayName: 'Super dealer',
                    field: 'superDealer.designation'
                },
                {
                    displayName: 'CDF',
                    field: `montant.CDF.cash`,
                    isTotal: true
                },
                {
                    displayName: 'USD',
                    field: `montant.USD.cash`,
                    isTotal: true
                }
            ]}
            isAddTotalTr
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                typeEntite: 'Journalier',
                typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR,
                estOrganisation: true,
                estTotal: true,
                estAll: true,
                estDevise: true
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier',
                typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR,
                estOrganisation: true,
                estTotal: true,
                estDevise: true
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedCashCollecteur',
                    isRequired: false,
                    displayName: 'Cash collecteur',
                    callback: (value) => {
                        if (!value?.code || value?.code === 'all') return {};
                        return { codeValeurTypeProprietaire: value?.code };
                    }
                }
            ]}
        >
            <CashCollecteurSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                isAddAditionalOptions
            />
        </ReduxReportTemplate>
    );
}
