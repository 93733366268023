import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { CashCollecteurSelector } from '../../../../components';

const formater = (data) => {
    const result = [];
    (data?.[0]?.listReturn || []).forEach((item) => {
        result.push({
            id: item?.valeurTypeProprietaire,
            solde: item?.soldeFermeture,
            designation: `${item?.partenaire?.designation}`
        });
    });
    return result;
};

export function RapportPartenaireCashCollecteurForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCashCollecteur: {}
        }
    });
    return (
        <ReduxReportTemplate
            dynamicNodeName={`reportsPartenaireCashCollecteur-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_COMPTE_BANCAIRE_PARTENAIRE}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            mapper={[
                {
                    displayName: 'Partenaire',
                    field: 'designation'
                },
                {
                    displayName: 'Solde',
                    field: 'solde',
                    isTotal: true
                }
            ]}
            isAddTotalTr
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                typeEntite: 'Journalier',
                typeProprietaire: 'CashCollecteur',
                estOrganisation: true,
                estBalance: true,
                estAll: true
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier',
                typeProprietaire: 'CashCollecteur',
                estOrganisation: true,
                estBalance: true,
                estAll: true
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedCashCollecteur',
                    isRequired: false,
                    displayName: 'Cash collecteur',
                    callback: (value) => {
                        if (!value?.code || value?.code === 'all') return {};
                        return { codeValeurTypeProprietaire: value?.code };
                    }
                }
            ]}
        >
            <CashCollecteurSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                isAddAditionalOptions
            />
        </ReduxReportTemplate>
    );
}
