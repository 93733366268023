import { FORM_CONST } from '@napp-inc/jnapp-util';
import { BaseReportTemplateForm } from './base-report-template';
import { SimpleTable } from '../../../components';

export function StandardReportTemplate({
    formState,
    formDispatcher,
    mapper,
    functionName,
    payloadMapper,
    defaultPayloadMapperValues,
    isAddDateOnPayloadMapper,
    divContentClassName,
    formater,
    elementPropertyName = 'mouvements',
    CustomOffCanvasButtonText,
    isShouldShowDateRange,
    debutName,
    finName,
    dateDebutName,
    dateFinName,
    selectedDateRangeReportName,
    searchButtonText,
    offCanvasTitle,
    identifier = 'id',
    bottomPagination = false,
    isAddTotalTr,
    isExportAllowed = true,
    isLinkToDetail = false,
    isDisplaySearch = true,
    filter = true,
    onSuccess,
    listFormater,
    listTitle,
    children,
    dynamicColumnFormater,
    isCommandeLitigieuse,
    isCommandeLitigieuseEmoney
}) {
    const renderTable = () => {
        const data =
            listFormater && typeof listFormater === 'function'
                ? listFormater(
                      formState?.elements?.[elementPropertyName]?.value
                  )
                : formState?.elements?.[elementPropertyName]?.value;
        const dynamicColumns = [];
        if (
            dynamicColumnFormater &&
            typeof dynamicColumnFormater === 'function'
        ) {
            dynamicColumns.push(
                ...dynamicColumnFormater(
                    formState?.elements?.[elementPropertyName]?.value?.[0]
                        ?.devises || {}
                )
            );
            dynamicColumns.push(
                ...dynamicColumnFormater(
                    formState?.elements?.[elementPropertyName]?.value?.[0]
                        ?.dynamicColonne || {}
                )
            );
        }
        return (
            <SimpleTable
                data={data || []}
                isDsiplayEmptyTableNotification={
                    formState?.elements?.[elementPropertyName]?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDisplaySearch={isDisplaySearch}
                tableClassName=""
                isShowDropButton={false}
                identifier={identifier}
                mapper={mapper.concat(dynamicColumns)}
                filter={filter}
                isLinkToDetail={isLinkToDetail}
                isExportAllowed={isExportAllowed}
                bottomPagination={bottomPagination}
                isRefetchAllowed={false}
                listTitle={`${listTitle || ''} ${
                    data?.length ? `(#${data?.length})` : ''
                }`}
                isAddTotalTr={isAddTotalTr}
                isCommandeLitigieuse={isCommandeLitigieuse}
                isCommandeLitigieuseEmoney={isCommandeLitigieuseEmoney}
                formDispatcher={formDispatcher}
                formState={formState}
            />
        );
    };
    return (
        <BaseReportTemplateForm
            contents={{
                1: renderTable
            }}
            isFetching={
                formState?.elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
            formState={formState}
            formDispatcher={formDispatcher}
            defaultPayloadMapperValues={defaultPayloadMapperValues}
            payloadMapper={payloadMapper}
            functionName={functionName}
            isAddDateOnPayloadMapper={isAddDateOnPayloadMapper}
            divContentClassName={divContentClassName}
            formater={formater}
            elementPropertyName={elementPropertyName}
            CustomOffCanvasButtonText={CustomOffCanvasButtonText}
            isShouldShowDateRange={isShouldShowDateRange}
            debutName={debutName}
            finName={finName}
            dateDebutName={dateDebutName}
            dateFinName={dateFinName}
            selectedDateRangeReportName={selectedDateRangeReportName}
            searchButtonText={searchButtonText}
            offCanvasTitle={offCanvasTitle}
            onSuccess={onSuccess}
        >
            {children}
        </BaseReportTemplateForm>
    );
}
