import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomFormGroup,
    CustomInput,
    CustomRow,
    FormWrapper,
    SelectZone
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useDebounce } from '../../../util';
import { CreationCashCollecteurFormSchema as uiValidator } from './validation';

/**
 * @description state du formulaire
 */
const defaultFields = {
    selectedZone: {},
    selectedZoneDropDown: {},
    email: '',
    numeroTel: '',
    preview: '',
    searchInput: '',
    zones: []
};

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['zones'];

export function CreationCashCollectionForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    useDebounce({
        name: 'selectedZone',
        preview: 'preview',
        isObject: true,
        formDispatcher,
        formState,
        delay: 2000,
        idToken,
        functionName: URL_CONST.GET_LIST_COORDONNES_DISPONIBLE_POUR_AGENCE,
        elementPropertyName: 'zones',
        creator,
        reduxNodeName: DB_NODE.ENTITE_ADMINISTRATIVE,
        reduxDispatcher
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const { email, numeroTel, zones } = fields;
        if (!email || !numeroTel || !zones.length) return;
        const newZones = zones.map((item) => item.reference);
        const payload = {
            email,
            numeroTel,
            creator,
            zones: newZones
        };
        // console.log(payload);
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CASH_COLLECTEUR,
                payload,
                fields,
                uiValidator,
                reduxNodeName: REDUX_NODE_NAME.CASH_COLLECTION_ORGANISATION,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            onSubmit={handleSubmit}
        >
            <CustomRow>
                <CustomInput
                    type="email"
                    label="Email*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="example@domaine.com"
                    formDispatcher={formDispatcher}
                    name="email"
                    id="emailCashCollectionOrganisationId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Numéro*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="243 xxx xxx xxx"
                    formDispatcher={formDispatcher}
                    name="numeroTel"
                    id="numeroTelCashCollectionOrganisationId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomFormGroup className="col-md-12">
                    <SelectZone
                        zoneFound={
                            elements?.zones?.value?.length
                                ? elements.zones.value
                                : []
                        }
                        entitesAdministrative={
                            elements?.zones?.value?.length
                                ? elements.zones.value
                                : []
                        }
                        // callback={processChanges}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomFormGroup>
            </CustomRow>

            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
