import { AiFillMobile } from 'react-icons/ai';
import { GrDocumentConfig } from 'react-icons/gr';
import { HiOutlineCreditCard } from 'react-icons/hi';
import { MdMobileScreenShare } from 'react-icons/md';

export const SystServiceViewLayoutRelPath = {
    name: 'Sys Services',
    path: 'admservices',
    icon: <GrDocumentConfig className="icon-custom" />
};

export const SystServiceDetailRelPath = {
    name: '',
    path: ':id'
};

export const ServiceCreateRelPath = {
    name: 'servicesCreate',
    path: 'create'
};

/**
 * @desc Droit acces rel paths
 */
export const DroitAccesViewLayoutRelPath = {
    name: 'Droit accès',
    path: 'droit-acces',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const DroitAccesCreateRelPath = {
    name: 'droit-acces-create',
    path: 'create'
};

export const DroitAccesDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Droit acces organisation rel paths
 */
export const DroitAccesOrganisationViewLayoutRelPath = {
    name: 'Droit accès organisation',
    path: 'droit-acces-org',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const DroitAccesOrganisationDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Version application rel paths
 */
export const VersionApplicationViewLayoutRelPath = {
    name: 'Version application',
    path: 'vers-app',
    icon: <MdMobileScreenShare className="icon-custom" />
};

export const VersionApplicationCreateRelPath = {
    name: '',
    path: 'create'
};

export const VersionApplicationDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Application mobile rel paths
 */
export const ApplicationMobileViewLayoutRelPath = {
    name: 'Application mobile',
    path: 'app-mobile',
    icon: <AiFillMobile className="icon-custom" />
};

export const ApplicationMobileCreateRelPath = {
    name: '',
    path: 'create'
};

/**
 * @desc Notification Push rel paths
 */
export const NotificationPushViewLayoutRelPath = {
    name: 'Notification Push',
    path: 'notification-push',
    icon: <AiFillMobile className="icon-custom" />
};

export const NotificationPushCreateRelPath = {
    name: '',
    path: 'create'
};

/**
 * @desc Operation Service client rel paths
 */
export const OperationServiceClientViewLayoutRelPath = {
    name: 'Opérations',
    path: 'op-sc',
    icon: <AiFillMobile className="icon-custom" />
};

/**
 * @desc Service client Formulaire rel paths
 */
export const ServiceClientFormulaireViewLayoutRelPath = {
    name: 'Service client Formulaire',
    path: 'service-client-formulaire',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const ServiceClientFormulaireCreateRelPath = {
    name: '',
    path: 'create'
};

export const ServiceClientFormulaireDetailRelPath = {
    name: '',
    path: ':id'
};
