import * as yup from 'yup';

/**
 * @description : Schema de COnfirmation SOlde compte form
 */

export const ConfirmationSoldeCompteFormSchema = yup.object().shape({
    selectedTache: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
