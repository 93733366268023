import { submitPutAction, useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import { FORM_CONST, formInitialState } from '@napp-inc/jnapp-util';
import { Col } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    CustomCheckButton,
    CustomButtonLoader,
    CustomRow,
    CustomFormGroup,
    FormWrapper,
    DynamicFormMounted
} from '../../../components';
import { SignInRelPath } from '../../../routes/registration/gestion-user/connexion-rel-path';
import { URL_CONST } from '../../../util';
import {
    AddresseEMailMapper,
    CommercialInfoMapper,
    OrganisationMapper,
    OwnerMapper
} from './form-fields';
import { ValidationCashCollecteurFormSchema as uiValidator } from './validation';

const defaultFields = {
    codeValidation: '',
    email: '',
    code: '',
    designation: '',
    rccm: '',
    adresseUne: '',
    nom: '',
    prenom: '',
    numeroTel: '',
    userEmail: '',
    userAdresseUne: '',
    aggree: false
};

export function ValiderCashCollectionOrganisation() {
    const reduxDispatcher = useDispatch();
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        { initialState }
    );
    const { fields, form } = formState;
    const { status } = form;
    if (status === FORM_CONST.MODIFICATION_SUCCESS) {
        return <Navigate to={`/${SignInRelPath.path}`} replace />;
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            codeValidation: fields.codeValidation,
            email: fields.email,
            elemUpdate: {
                code: fields.code,
                designation: fields.designation,
                rccm: fields.rccm
            },
            newAdresse: {
                adresseUne: fields.adresseUne
            },
            newPersonne: {
                nom: fields.nom,
                prenom: fields.prenom,
                numeroTel: fields.numeroTel,
                email: fields.userEmail
            },
            newAdressePersonne: {
                adresseUne: fields.userAdresseUne
            }
        };
        formDispatcher(
            submitPutAction({
                idToken: '',
                functionName: URL_CONST.PUT_VALIDATE_CASH_COLLECTEUR,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };
    return (
        // <div className="container-fluid vh-100 vw-100 justify-content-center align-items-center">
        <CustomRow className="vh-100 vw-100 justify-content-md-center">
            <Col className="bg-primary d-flex flex-column justify-content-center align-items-center">
                <p className="fs-3 mb-3 fw-bold text-light">
                    Créez votre Organisation!
                </p>
                <p>
                    <Link
                        className="text-decoration-none text-black"
                        to={`/${SignInRelPath.path}`}
                    >
                        Se connecter!
                    </Link>
                </p>
            </Col>
            <Col className="col-md-8 col-xl-8 col-lg-8 pb-5 mt-5 napp-sign-up">
                <FormWrapper
                    onSubmit={handleSubmit}
                    formState={formState}
                    formDispatcher={formDispatcher}
                    isRedirect={false}
                >
                    <h5 className="h5 mt-4 mb-2 fw-bolder">
                        Infos de confirmation
                    </h5>
                    <hr className="mb-4" />
                    <DynamicFormMounted
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        fieldsMapper={AddresseEMailMapper}
                    />
                    <h5 className="h5 mb-2 fw-bolder">Organisation</h5>
                    <hr className="mb-4" />
                    <DynamicFormMounted
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        fieldsMapper={OrganisationMapper}
                    />
                    <h5 className="h5 mb-2 fw-bolder">
                        Informations Commerciales
                    </h5>
                    <hr className="mb-4" />
                    <DynamicFormMounted
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        fieldsMapper={CommercialInfoMapper}
                    />
                    <h5 className="h5 mb-2 fw-bolder">
                        Informations sur le(s) propriétaire(s)
                    </h5>
                    <hr className="mb-4" />
                    <DynamicFormMounted
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        fieldsMapper={OwnerMapper}
                    />
                    <CustomFormGroup className="mb-3">
                        <CustomCheckButton
                            uiValidator={uiValidator}
                            formDispatcher={formDispatcher}
                            name="aggree"
                            id="OwnerAggreement"
                            label="J'ai lu et j'accepte les termes de confidentialité"
                            formState={formState}
                            divClassName="ms-1"
                            labelClassName="col-sm-10 fs-6 text-decoration-underline"
                            inputClassName="col-sm-2"
                        />
                    </CustomFormGroup>
                    <CustomButtonLoader
                        className="px-4 w-25 btn-lg"
                        text="Inscription"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </FormWrapper>
            </Col>
        </CustomRow>
        // </div>
    );
}
