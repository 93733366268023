import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailShutDownAndUpApiPartnerForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'traitementConfigurationMarchandApi'
        });
    const { fields, form } = formState;
    const onSuccess = (data) => {
        if (data?.element) {
            reduxDispatcher(
                GENERIC_ITEM_ACTIONS_CREATOR.ajouterItem(
                    data.element,
                    REDUX_NODE_NAME.TRAITEMENT_CONFIGURATION_MARCHAND_API
                )
            );
            reduxDispatcher(
                GENERIC_ITEM_ACTIONS_CREATOR.ajouterItem(
                    {
                        ...data.element,
                        id: data.element.idConfigurationMarchandApi
                    },
                    REDUX_NODE_NAME.CONFIGURATION_API_PARTENAIRE
                )
            );
        }
    };
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        const payload = {
            idTraitementConfigurationMarchandApi: entity?.id,
            // creator: 'qlxrcWKltYhDhrIG90oSyeU0eVN2',
            etat
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName:
                    URL_CONST.PUT_TRAITEMENT_CONFIGURATION_MARCHAND_API,
                payload,
                reduxNodeName:
                    REDUX_NODE_NAME.TRAITEMENT_CONFIGURATION_MARCHAND_API,
                reduxDispatcher,
                onSuccess
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={
                URL_CONST.GET_TRAITEMENT_CONFIGURATION_MARCHAND_API_BY_ID
            }
            nodeName={REDUX_NODE_NAME.TRAITEMENT_CONFIGURATION_MARCHAND_API}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Configuration"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
                        .ATTENTE_VALIDATION_ACTIVER_DESACTIVER_API_PARTENAIRE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
                        .VALIDER_ACTIVER_DESACTIVER_API_PARTENAIRE
                ]}
            >
                <CustomRow
                    isShouldBeRendered={entity?.etat !== ETAT.ETAT_VALIDER}
                >
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    {/* <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Mettre en attente"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper> */}
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
