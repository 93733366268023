import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListLigneCreditForm() {
    return (
        <ListGenericForm
            reduxPropertyName="lignesCredit"
            functionName={URL_CONST.GET_LIST_LIGNE_CREDIT_INTITIALS}
            nodeName={DB_NODE.LIGNE_CREDIT}
            mapper={[
                {
                    displayName: 'Revendeur',
                    field: 'revendeur.designation',
                    position: 1
                },
                {
                    displayName: 'Code revendeur',
                    field: 'revendeur.code',
                    position: 2
                },
                {
                    field: 'etat',
                    displayName: 'Etat',
                    position: 3
                },
                {
                    field: 'dateCreation',
                    displayName: 'Date',
                    position: 4
                }
            ]}
        />
    );
}
