import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ViewLayout } from './view-layout';

export function ListDirection() {
    return (
        <ViewLayout>
            <ListGenericForm
                identifier="id"
                reduxPropertyName="directions"
                functionName={URL_CONST.GET_LIST_DIRECTION}
                nodeName={REDUX_NODE_NAME.DIRECTION}
                isLinkToDetail={false}
                mapper={[
                    {
                        field: 'code',
                        displayName: 'Code',
                        position: 1
                    },
                    {
                        field: 'designation',
                        displayName: 'Designation',
                        position: 2
                    }
                ]}
            />
        </ViewLayout>
    );
}
