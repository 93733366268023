import { ViewLayout } from './view-layout';

export function ListDepot() {
    const renderContent = () => (
        <ViewLayout>
            <h2>Liste Depot</h2>
        </ViewLayout>
    );
    return renderContent();
}
