import {
    CODE_SERVICE,
    FORM_CONST,
    GENERIC_ACTIONS_TYPE
} from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST, useDebounce, useHasService } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { ConditionalRenderingWrapper, CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner les comptes
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @param {Boolean} param0.isReleve form state du parent
 * @param {String} param0.label Label du dropdown, par défaut "Caisse : "
 * @param {String} param0.propertyName propriété où la valeur de la caisse selectionnée est enregistrée dans le form state, par defaut "selectedCaisse"
 * @param {String} param0.labelClassName className du label
 * @param {String} param0.divClassName className du div
 * @param {String} param0.propertyToDisplay nom de la propriété à afficher dans le dropdown, par défaut : "designation"
 * @param {String} param0.idProperty propriété id à utiliser, par défaut : "id"
 * @returns {React.Component}
 */
export function ReleveEmoneySelector({
    formDispatcher,
    formState,
    isReleve = false
}) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        superDealerKinDB,
        etatSuperDealerKinDB,
        allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        superDealerKinDB: reduxState.allSuperDealerKinDB
            .filter((item) => item.estEmoney)
            .map((item) => ({
                ...item,
                code: item.entreprise.code,
                designation: item.entreprise.designation
            })),
        etatSuperDealerKinDB: reduxState.etat.allSuperDealerKinDB.etat,
        allCashCollecteurKinDB: reduxState.allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB: reduxState.etat.allCashCollecteurKinDB.etat
    }));

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
                .LECTURE_ALL_SUPERDEALER_AND_CASHCOLLECTEUR_CAISSE
        ]
    });

    const formater = (tableau = []) =>
        tableau.map((item) => ({
            ...item,
            code: item.id,
            designation: `${item?.designation || ''} - ${item?.devise || ''}`
        }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { estAll: true, typeEntite: nappUser?.typeEntite },
                isCondition:
                    !!hasService[
                        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                            .SERIVCE_CODE_RAPPORT
                            .LECTURE_ALL_SUPERDEALER_AND_CASHCOLLECTEUR_CAISSE
                            ?.code
                    ]
            },
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_CASH_COLLECTEUR_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_CASH_COLLECTEUR_KIN_DB,
                etat: etatAllCashCollecteurKinDB,
                isCondition:
                    !!hasService[
                        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                            .SERIVCE_CODE_RAPPORT
                            .LECTURE_ALL_SUPERDEALER_AND_CASHCOLLECTEUR_CAISSE
                            ?.code
                    ]
            }
        ]
    });
    const { elements, fields } = formState;

    /**
     * Debounce
     */
    useDebounce({
        name: 'selectedSuperDealer',
        preview: 'previewValue',
        propertyInName: 'code',
        isObject: true,
        formDispatcher,
        formState,
        delay: 1500,
        idToken,
        functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
        elementPropertyName: 'comptes',
        payloadToAdd: {
            typeProprietaire: fields.configuration,
            estOrganisation: true,
            codeValeurTypeProprietaire: fields.selectedSuperDealer.code,
            estEmoney: true
        },
        dataLength: 0
    });

    // const onFetchSuperDealerAccount = () => {
    //     const { status: etat } = elements.comptes;
    //     formDispatcher(
    //         fetchElement({
    //             idToken,
    //             functionName:
    //                 URL_CONST.GET_LIST_CAISSE_ACTIVE_USER /** @desc : A changer */,
    //             elementPropertyName: 'comptes',
    //             etat,
    //             payload: {
    //                 typeProprietaire: fields.configuration,
    //                 estOrganisation: true,
    //                 codeValeurTypeProprietaire:
    //                     fields.configuration === 'SuperDealer'
    //                         ? fields.selectedSuperDealer.code
    //                         : fields.selectedCashCollecteur.code
    //             },
    //             reduxDispatcher
    //         })
    //     );
    // };
    const renderContent = () => {
        if (
            etatSuperDealerKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS ||
            etatAllCashCollecteurKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            !!hasService[
                                CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                                    .SERIVCE_CODE_RAPPORT
                                    .LECTURE_ALL_SUPERDEALER_AND_CASHCOLLECTEUR_CAISSE
                                    ?.code
                            ]
                        }
                    >
                        {/* <div className="d-flex flex-row mb-3">
                            <CustomRadioButton
                                divClassName="col-6 col-sm-6 ms-6"
                                label="Super Dealer"
                                id="superDealerReleveEmoneySelectorId"
                                name="configuration"
                                value="SuperDealer"
                                formDispatcher={formDispatcher}
                                // uiValidator={uiValidator}
                                formState={formState}
                            />
                            <CustomRadioButton
                                divClassName="col-6 col-sm-6 ms-6"
                                label="Cash Collecteur"
                                id="cashCollecteurReleveEmoneySelectorId"
                                name="configuration"
                                value="CashCollecteur"
                                formDispatcher={formDispatcher}
                                // uiValidator={uiValidator}
                                formState={formState}
                            />
                        </div> */}
                        <CustomRow
                            isShouldBeRendered={
                                fields.configuration === 'SuperDealer'
                            }
                        >
                            <CustomDropdown
                                options={superDealerKinDB}
                                defaultOption="Selectionner SuperDealer"
                                label="Super Dealer: "
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedSuperDealer"
                                id="selectedSuperDealerCreationApproCompteEmoney"
                                // uiValidator={CreationApproCompteEmoneySchema}
                                formState={formState}
                            />
                        </CustomRow>
                        <CustomRow
                            isShouldBeRendered={
                                fields.configuration === 'CashCollecteur'
                            }
                        >
                            <CustomDropdown
                                options={allCashCollecteurKinDB}
                                defaultOption="Selectionner Cash Collecteur"
                                label="Cash Collecteur: "
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedCashCollecteur"
                                id="selectedCashCollecteurCreationApproCompteEmoney"
                                // uiValidator={CreationApproCompteEmoneySchema}
                                formState={formState}
                            />
                        </CustomRow>

                        {/* <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                !!isReleve &&
                                !!hasService[
                                    CODE_SERVICE
                                        .CODE_SERVICE_MODULE_ADMINISTRATION
                                        .SERIVCE_CODE_RAPPORT
                                        .LECTURE_ALL_KINDB_COMPTE_BANCAIRE?.code
                                ]
                            }
                        >
                            <CustomButtonLoader
                                text="Voir Caisse"
                                className="col-5"
                                type="button"
                                onClick={onFetchSuperDealerAccount}
                                disabled={
                                    elements?.comptes?.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                                isActionEncours={
                                    elements?.comptes?.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                            />
                        </ConditionalRenderingWrapper> */}
                    </ConditionalRenderingWrapper>
                    {/* <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            !!isReleve &&
                            !hasService[
                                CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                                    .SERIVCE_CODE_RAPPORT
                                    .LECTURE_ALL_KINDB_COMPTE_BANCAIRE?.code
                            ]
                        }
                    >
                        <CustomRow>
                            <CustomDropdown
                                options={comptes}
                                defaultOption="Selectionner Compte"
                                label="Compte"
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedSuperDealerCompte"
                                id="selectedSuperDealerCompteRapportCompteId"
                                formState={formState}
                            />
                        </CustomRow>
                    </ConditionalRenderingWrapper> */}
                    {elements?.comptes?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                        <div className="placeholder-glow">
                            <span className="placeholder col-5" />
                            <span className="placeholder col-12" />
                        </div>
                    ) : null}
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            !!isReleve && !!elements?.comptes?.value?.length
                        }
                    >
                        <CustomRow className="mt-3">
                            <CustomDropdown
                                options={formater(
                                    elements?.comptes?.value || []
                                )}
                                defaultOption="Selectionner Compte"
                                label="Caisse :"
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedSuperDealerCompte"
                                id="selectedSuperDealerCompteRapportCompteId"
                                formState={formState}
                            />
                        </CustomRow>
                    </ConditionalRenderingWrapper>
                    {/* <ConditionalRenderingWrapper isShouldBeRendered={!isReleve}>
                        <CustomRow>
                            <CustomDropdown
                                isSort={false}
                                options={[
                                    { code: 'ALL', designation: 'Tous' },
                                    {
                                        code: 'CommandeFlash ',
                                        designation: 'Commandes Flash'
                                    },
                                    {
                                        code: 'CommandeEmoney ',
                                        designation: 'Commandes Emoney'
                                    },
                                    {
                                        code: 'ApprovisionnementSim ',
                                        designation: 'Appro Sim'
                                    }
                                ]}
                                defaultOption="Choisir"
                                label="Opération: "
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedTypeOperation"
                                id="selectedTypeOperationCreationApproCompteEmoney"
                                // uiValidator={CreationApproCompteEmoneySchema}
                                formState={formState}
                            />
                        </CustomRow>
                    </ConditionalRenderingWrapper> */}
                </>
            );
        }
        if (
            etatSuperDealerKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC ||
            etatAllCashCollecteurKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
