import { Add, TIME, TODAY, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { ReduxReportTemplate } from '../../module/report/template';
import { URL_CONST } from '../../util';

const formater = (data) => {
    const result = [];
    (data?.[0]?.listReturn || []).forEach((item) => {
        result.push({
            id: item?.id,
            limit: item?.limit,
            solde: item?.solde,
            disponible: Add(item?.limit, item?.solde),
            nomComplet: `${item?.valeurTypeProprietaire?.prenom || ''} ${
                item?.valeurTypeProprietaire?.nom || ''
            }`,
            organisation: item?.valeurTypeOrganisation?.designation || ''
        });
    });
    return result;
};

/**
 * Composant pour le rapport de limite de crédit d'un organisme : revendeur, super dealer, cash collecteur
 * @param {Object} param0 Object avec les paramètres suivants :
 * @param {String} param0.typeOrganisation Type d'organisation
 * @param {Array} param0.mapper Mapper
 * @param {Object} param0.formState Etat du formulaire
 * @param {Object} param0.formDispatcher Dispatcher du formulaire
 * @param {String} param0.name Nom de la propriété
 * @param {String} param0.idProperty Propriété id
 * @param {String} param0.displayName Nom à afficher
 * @param {React.Component} param0.children Composant enfant
 * @returns {React.Component}
 *
 * */
export function RapportLimiteCreditOrganisme({
    typeOrganisation = TYPE_ORGANISATION.SUPER_DEALER,
    mapper = [
        {
            displayName: 'Super dealer',
            field: 'organisation'
        },
        {
            displayName: 'Agent',
            field: 'nomComplet'
        },
        {
            displayName: 'Autorisé',
            field: 'limit',
            isTotal: true
        },
        {
            displayName: 'Disponible',
            field: 'disponible',
            isTotal: true
        },
        {
            displayName: 'Utilisé',
            field: 'solde',
            isTotal: true
        }
    ],
    formState,
    formDispatcher,
    name = 'selectedSuperDealer',
    idProperty = 'code',
    displayName = 'Super dealer',
    payload,
    children
}) {
    return (
        <ReduxReportTemplate
            listTitle="Valeur exprimée en USD"
            dynamicNodeName={`rapportLimitCredit-${typeOrganisation}-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_LIMIT_CREDIT_ORGANISATION}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            mapper={mapper}
            isAddTotalTr
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                estListReturn: true,
                estAll: true,
                typeProprietaire: typeOrganisation,
                ...payload
            }}
            defaultPayloadMapperValues={{
                estListReturn: true,
                estAll: true,
                typeProprietaire: typeOrganisation,
                ...payload
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: `${name}`,
                    property: 'codeValeurTypeProprietaire',
                    isRequired: false,
                    displayName,
                    callback: (value) => {
                        if (
                            !value?.[idProperty] ||
                            value?.[idProperty] === 'all'
                        )
                            return {};
                        return {
                            codeValeurTypeProprietaire: value?.[idProperty]
                        };
                    }
                }
            ]}
            bottomPagination
        >
            {children}
        </ReduxReportTemplate>
    );
}
