export const schema = [
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Till number',
        description: 'revendeur.tillNumber',
        className: 'border-bottom'
    },
    {
        title: 'Désignation',
        description: 'revendeur.designation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    },
    {
        title: 'Type réconciliation',
        description: 'typeReconciliation',
        className: 'border-bottom'
    },
    {
        title: 'Limit',
        description: 'limit',
        className: 'border-bottom'
    },
    {
        title: 'Début',
        description: 'periode.dateDebut',
        className: 'border-bottom',
        isDate: true
    },
    {
        title: 'Fin',
        description: 'periode.dateFin',
        className: 'border-bottom',
        isDate: true
    }
];
