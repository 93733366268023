import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    Str,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../../releve/generic';
import { URL_CONST } from '../../../../util';
import {
    CustomDropdown,
    MouvementCardsComponent
} from '../../../../components';
import { commandeMapper } from './mapper';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedCollection: {}
};

const collections = [
    { code: 'AppelFlashVodacomProd', designation: 'Vodacom' },
    { code: 'AppelFlashOrangeProd', designation: 'Orange' },
    { code: 'AppelFlashAfricellProd', designation: 'Africell' },
    { code: 'AppelFlashAirtelProd', designation: 'Airtel' }
];

function CollectionSelector({ formDispatcher, formState }) {
    return (
        <CustomDropdown
            options={collections}
            defaultOption="Selectionner un partenaire"
            label="Collection: "
            labelClassName="col-12"
            divClassName="col-12"
            formDispatcher={formDispatcher}
            name="selectedCollection"
            id="selectedCollectionCollectionSelector"
            // uiValidator={CreationApproCompteEmoneySchema}
            formState={formState}
        />
    );
}

export function RapportCommandeFlashDoublonForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const displayTypeBussiness = (type) => {
        if (type === 'TraditionnelBusiness') return 'Traditionnel';
        if (type === 'BusinessModel') return 'Classique';
        return 'Sim';
    };
    const { fields, elements } = formState;
    const formatingReturn = (data) => {
        const { selectedCollection } = fields;
        return data.map((item) => {
            let numero = '';
            let quantite = '';
            if (selectedCollection?.code === 'AppelFlashVodacomProd') {
                if (item?.body) {
                    numero = Str.betweenLast(
                        item?.body,
                        '<req:Identifier>',
                        '</req:Identifier>'
                    );
                    quantite = Str.betweenFirst(
                        item?.body,
                        '<req:Amount>',
                        '</req:Amount>'
                    );
                }
            }
            if (selectedCollection?.code === 'AppelFlashOrangeProd') {
                if (item?.body) {
                    numero = Str.betweenLast(
                        item?.body,
                        '<MSISDN2>',
                        '</MSISDN2>'
                    );
                    quantite = Str.betweenFirst(
                        item?.body,
                        '<AMOUNT>',
                        '</AMOUNT>'
                    );
                }
            }
            if (selectedCollection?.code === 'AppelFlashAirtelProd') {
                if (item?.body) {
                    numero = Str.betweenLast(
                        item?.body,
                        '<MSISDN2>',
                        '</MSISDN2>'
                    );
                    quantite = Str.betweenFirst(item?.body, '<QTY>', '</QTY>');
                }
            }
            return {
                ...item,
                numero,
                quantite
            };
        });
    };
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedCollection } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payloadToAdd = {};
        if (selectedCollection.code) {
            payloadToAdd.collection = selectedCollection.code;
        }
        const payload = {
            dateDebut,
            dateFin,
            ...payloadToAdd
            // property: 'dateCreation'
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_COMMANDE_DOUBLON,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher,
                formater: formatingReturn
            })
        );
    };
    // console.log('formstate : ', formState?.fields);
    return (
        <>
            <MainReportComponentLarge
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={commandeMapper}
                dataList={
                    elements.mouvements?.value?.map((item) => ({
                        ...item,
                        business: displayTypeBussiness(item.typeBusiness)
                    })) || []
                }
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                topOfChart={MouvementCardsComponent}
            >
                <CollectionSelector
                    formDispatcher={formDispatcher}
                    formState={formState}
                />
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
