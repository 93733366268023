import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { DetailWrapper } from '../../../components';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

const defaultFields = {
    actionEncours: '',
    deviseRetourSelect: {},
    deviseRetourInput: 0,
    devisePercuSelect: {},
    devisePercuInput: 0,
    montantPercu: [],
    montantRetour: []
};

export function DetailCommandeFlashSenderForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'commandesFlash', defaultFields });
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_COMMANDE_FLASH_BY_ID}
            nodeName={DB_NODE.COMMANDE_FLASH}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Commandes Flash"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        />
    );
    return renderContent();
}
