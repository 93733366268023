import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    PRODUIT_ELECTRONIQUE,
    URL_CONST,
    Sub,
    Add
} from '@napp-inc/jnapp-util';
import * as Luhn from 'luhn-js';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../../components';

const defaultFields = {
    debut: '',
    fin: '',
    produitSim: {},
    plages: []
};

const defaultDependencies = { fields: ['produitsSuperDealer'] };

export function CreationApprovisionnementSimForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        produitsSuperDealer,
        etatProduitSuperDealer,
        nomberMinDigit
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        produitsSuperDealer: reduxState.produitsSuperDealer.filter(
            (item) =>
                item?.typeEntite.toUpperCase() ===
                PRODUIT_ELECTRONIQUE.SIM.toUpperCase()
        ),
        nomberMinDigit: reduxState.produitsSuperDealer.find(
            (item) =>
                item?.typeEntite.toUpperCase() ===
                PRODUIT_ELECTRONIQUE.SIM.toUpperCase()
        )?.nomberMinDigit,
        etatProduitSuperDealer: reduxState.etat.produitsSuperDealer.etat
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    /**
     * This hook fetches data (super dealer stock, caisses, comptes)
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.PRODUIT_SUPER_DEALER,
                functionName: URL_CONST.GET_LIST_PRODUIT_SUPER_DEALER,
                etat: etatProduitSuperDealer
            }
        ]
    });

    /**
     * Fonction d'ajout de plage sim dans un tableau avant la soumission du formulaire
     * @returns {ReduxAction}
     */
    const addProduit = () => {
        const { debut, fin } = fields;
        const newState = {
            ...initialState,
            fields: {
                ...initialState.fields,
                produitSim: fields.produitSim,
                plages: fields.plages
            }
        };

        if (!Luhn.isValid(debut) || !Luhn.isValid(fin)) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Les numéros de sim doivent ne sont pas valides',
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
        }
        if (debut.length === 0 || !fin.length === 0) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Renseigner le debut et la fin de la plage',
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
        } else if (debut.length !== fin.length) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        "Impossible d'avoir un nombre de caractère different",
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
        } else if (
            parseInt(fin.substring(7), 10) - parseInt(debut.substring(7), 10) <
                0 ||
            debut === fin
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'La fin doit être supérieur au début',
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
        } else {
            let debutValide = debut;
            let finValide = fin;
            if (debut.length > nomberMinDigit && Luhn.isValid(debut)) {
                debutValide = debut.substr(0, debut.length - 1);
            }
            if (fin.length > nomberMinDigit && Luhn.isValid(fin)) {
                finValide = fin.substr(0, fin.length - 1);
            }
            formDispatcher({
                type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
                payload: {
                    newState,
                    field: 'plages',
                    identifier: 'id',
                    data: {
                        id: `${debut}-${fin}`,
                        debut,
                        fin,
                        quantite: Add(
                            Sub(
                                parseInt(finValide.substring(7), 10),
                                parseInt(debutValide.substring(7), 10)
                            ),
                            1
                        )
                    }
                }
            });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { produitSim, plages } = fields;
        if (!produitSim.code) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Veuillez renseigner le code du produit sim',
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
        }
        if (fields.plages.length < 1) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Veuillez renseigner au moins une plage',
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
        }
        const payload = {
            newApprovisionnementSim: {
                produit: {
                    code: produitSim.code
                }
            },
            plages
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_APPRO_SIM,
                payload,
                fields,
                reduxNodeName: DB_NODE.APPROVISIONNEMENT_SIM,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'produitsSuperDealer',
                    etat: etatProduitSuperDealer
                }
            ]}
        >
            <CustomRow>
                <CustomCol className="col-sm-2">
                    <CustomLabel text="Produit" />
                </CustomCol>
                <CustomCol>
                    <CustomDropdown
                        options={produitsSuperDealer}
                        defaultOption="Choisir un produit"
                        formState={formState}
                        formDispatcher={formDispatcher}
                        name="produitSim"
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="col-sm-2">
                    <CustomLabel text="Plages" />
                </CustomCol>
                <CustomCol>
                    <CustomInput
                        placeholder="Début"
                        name="debut"
                        formState={formState}
                        formDispatcher={formDispatcher}
                    />
                </CustomCol>
                <CustomCol>
                    <CustomInput
                        placeholder="Fin"
                        name="fin"
                        formState={formState}
                        formDispatcher={formDispatcher}
                    />
                </CustomCol>
                <CustomCol className="col-sm-1">
                    <CustomAddButton
                        list={fields.plages}
                        className="btn btn-primary"
                        formDispatcher={formDispatcher}
                        field="plages"
                        size="25em"
                        identifier="id"
                        callback={addProduit}
                    />
                </CustomCol>
            </CustomRow>
            <CustomCollapse
                isIn={fields.plages.length > 0}
                className="offset-sm-2 col-sm-10"
            >
                <SimpleTable
                    isShowDropButton
                    identifier="id"
                    data={fields.plages}
                    listSelectedItemName="plages"
                    formDispatcher={formDispatcher}
                    tableClass="table table-hover shadow table-borderless table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            field: 'debut',
                            displayName: 'Début',
                            position: 1
                        },
                        {
                            field: 'fin',
                            displayName: 'Fin',
                            position: 2
                        },
                        {
                            field: 'quantite',
                            displayName: 'Quantité',
                            position: 3
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
