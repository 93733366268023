import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    CaisseCreateRelPath,
    CaisseViewLayoutRelPath
} from '../../../routes/registration/configuration/config-napp-rel-path';
import { ListCaisseForm } from './list-caisse';

export function CaisseViewLayout() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Caisse</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname === `/${CaisseViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                        .CODE_SERVICE_MODULE_ADMINISTRATION
                                        .INITIER_CREATION_CAISSE
                                ]}
                                destination={CaisseCreateRelPath.path}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListCaisseForm />
        </>
    );
}
