import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { ViewLayout } from './view-layout';

export function ListProfil() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                reduxPropertyName="allProfilUsers"
                functionName={URL_CONST.GET_ALL_PROFIL_USER}
                nodeName={DB_NODE.PROFIL_USER}
                mapper={[
                    {
                        field: 'code',
                        displayName: 'Code',
                        position: 1
                    },
                    {
                        field: 'designation',
                        displayName: 'Désignation',
                        position: 2
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
