import * as yup from 'yup';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    CODE_SERVICE,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { REDUX_NODE_NAME } from '../../../redux';
import { useHasService } from '../../../util';
import { CreationTransfertExterneForm } from './creation-form';

const defaultFields = {
    selectedSuperDealerIsAll: {},
    selectedCaisseSource: {},
    selectMonnaieSortie: {},
    selectedTypeOrganisation: {},
    selectedSuperDealer: {},
    selectedCashCollecteur: {},
    estDeviseSollicite: false,
    montant: 0,
    numeroReference: ''
};

let defaultDependencies = {
    fields: ['caisses', 'superDealer', 'cashCollecteur', 'monnaieLocale']
};

const setDefaultProperties = ({ type, service, isKinDB }) => {
    const payload = {
        caisseNodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
        caisseReduxProperty: 'caissesActiveUser',
        superDealerNodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
        superDealerReduxProperty: 'allSuperDealerKinDB'
    };
    const conditions = {};

    /** Caissier */
    if (
        type === TYPE_ORGANISATION.NAPP &&
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_TRANSFERT_CAISSE_KINDB.code
        ] &&
        (service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT.code
        ] ||
            service[
                CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                    .INITIER_DECAISSEMENT.code
            ])
    ) {
        defaultDependencies = {
            fields: [
                'caisses',
                'superDealer',
                'cashCollecteur',
                'monnaieLocale'
            ]
        };
        payload.caisses = { estOrganisation: false };
        payload.caisseNodeName = REDUX_NODE_NAME.ALL_CAISSE_ACTIVE_USER;
        payload.caisseReduxProperty = 'allCaissesActiveUser';
        payload.superDealerNodeName = REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB;
        payload.superDealerReduxProperty = 'allSuperDealerKinDB';
        payload.superDealer = {
            typeEntite: type,
            estAll: true
        };
        conditions.isAll = true;
    }

    /** Autres que le caissier */
    if (
        type === TYPE_ORGANISATION.NAPP &&
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_TRANSFERT_CAISSE_KINDB.code
        ] &&
        !service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT.code
        ] &&
        !service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DECAISSEMENT.code
        ]
    ) {
        defaultDependencies = {
            fields: [
                'caisses',
                'superDealer',
                'cashCollecteur',
                'monnaieLocale'
            ]
        };
        payload.caisses = {
            estOrganisation: true,
            typeProprietaire: 'SuperDealer',
            estAll: true
        };
        payload.caisseNodeName = REDUX_NODE_NAME.ALL_CAISSE_SUPER_DEALER;
        payload.caisseReduxProperty = 'allCaissesSuperDealer';
        payload.superDealerNodeName = REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB;
        payload.superDealerReduxProperty = 'allSuperDealerKinDB';
        payload.superDealer = {
            typeEntite: type,
            estAll: true
        };
        conditions.isAll = true;
        /** Is Global pour la condition des superDealers pour les nons caissiers */
        conditions.isGlobal = true;
    }
    const yupValidation = yup.object().shape({
        selectedSuperDealerIsAll: yup.object().shape({
            code: yup.string(),
            designation: yup.string()
        }),
        estDeviseSollicite: yup.boolean(),
        selectedCaisseSource: yup.object().shape({
            id: yup.string().required(),
            designation: yup.string().required()
        }),
        selectMonnaieSortie: yup.object().when('estDeviseSollicite', {
            is: true,
            then: yup.object().shape({
                id: yup.string().required(),
                devise: yup.string().required()
            }),
            otherwise: yup.object().shape({
                id: yup.string(),
                devise: yup.string()
            })
        }),
        selectedTypeOrganisation: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedSuperDealer: yup.object().when('selectedTypeOrganisation', {
            is: (selectedTypeOrganisation) =>
                selectedTypeOrganisation.code ===
                TYPE_ORGANISATION.SUPER_DEALER,
            then: yup.object().shape({
                code: yup.string().required(),
                designation: yup.string().required()
            }),
            otherwise: yup.object().shape({
                code: yup.string(),
                designation: yup.string()
            })
        }),
        selectedCashCollecteur: yup.object().when('selectedTypeOrganisation', {
            is: (selectedTypeOrganisation) =>
                selectedTypeOrganisation.code ===
                TYPE_ORGANISATION.CASH_COLLECTEUR,
            then: yup.object().shape({
                code: yup.string().required(),
                designation: yup.string().required()
            }),
            otherwise: yup.object().shape({
                code: yup.string(),
                designation: yup.string()
            })
        }),
        montant: yup.number().min(1)
    });
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yupValidation,
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yupValidation,
            cfPayload: payload,
            cfCondition: conditions
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        if (isKinDB) {
            conditions.isAll = true;
            payload.caisses = { estOrganisation: false };
            payload.superDealer = {
                typeEntite: type,
                estAll: true
            };
        }
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yupValidation,
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR) {
        if (isKinDB) {
            conditions.isAll = true;
            payload.caisses = { estOrganisation: false };
            payload.superDealer = {
                typeEntite: type,
                estAll: true
            };
            payload.caisses = { estOrganisation: false };
        }
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedCaisse: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedUser: yupValidation,
                montant: yup.number().min(1)
            }),
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({}),
        cfPayload: {},
        cfCondition: {}
    };
};

export function CreationTransfertInterInstitutionnelForm() {
    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_TRANSFERT_CAISSE_KINDB,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DECAISSEMENT
        ]
    });

    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser,
        organisation
    });

    const {
        fields,
        dependencies,
        elements,
        uiValidator,
        cfPayload,
        cfCondition
    } = setDefaultProperties({
        type: typeEntite,
        service: hasService,
        isKinDB: organisation.estKinDistribution
        // profil: profilUser
    });
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });
    const renderContent = () =>
        initialState.dependencies && cfPayload.caisseNodeName ? (
            <CreationTransfertExterneForm
                idToken={idToken}
                typeEntite={typeEntite}
                uiValidator={uiValidator}
                initialState={initialState}
                hasService={hasService}
                cfPayload={cfPayload}
                cfCondition={cfCondition}
            />
        ) : null;
    return renderContent();
}
