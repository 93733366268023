import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../../components';

export function ListApprovisionnementSimForm() {
    return (
        <ListGenericForm
            reduxPropertyName="approvisionnementsSim"
            functionName={URL_CONST.GET_LIST_APPROVISIONNEMENT_SIM}
            nodeName={DB_NODE.APPROVISIONNEMENT_SIM}
            mapper={[
                {
                    field: 'quantite',
                    displayName: 'Quantité',
                    position: 1
                },
                {
                    displayName: 'Montant',
                    field: 'montant',
                    position: 2
                },
                {
                    displayName: 'Etat',
                    field: 'etat',
                    position: 3
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation',
                    position: 4
                }
            ]}
        />
    );
}
