import { URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListCrediterCompteViaInstitutFinForm() {
    return (
        <ListGenericForm
            reduxPropertyName="crediterCompteViaInstitution"
            functionName={URL_CONST.GET_LIST_CREDITER_COMPTE_INITIALS}
            nodeName={REDUX_NODE_NAME.CREDITER_COMPTE_VIA_INSTITUT_FINANCIERE}
            payload={{
                typeEntite: 'InstitutionFinanciere',
                estOrganisation: true
            }}
            mapper={[
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'Numéro Transaction'
                },
                {
                    field: 'devise',
                    displayName: 'Devise',
                    isKeepFormat: true
                },
                {
                    field: 'montant',
                    displayName: 'Montant'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation',
                    isKeepFormat: true
                }
            ]}
        />
    );
}
