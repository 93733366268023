import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListLitigieuxForm() {
    return (
        <ListGenericForm
            reduxPropertyName="casLitigieux"
            functionName={URL_CONST.GET_LIST_COMMANDE_LITIGIEUX}
            nodeName={REDUX_NODE_NAME.CAS_LITIGIEUX}
            mapper={[
                {
                    displayName: 'Marchand',
                    field: 'marchand.designation'
                },
                {
                    displayName: 'User',
                    dataExtractor: (item) =>
                        `${item?.user?.prenom || ''} ${item?.user?.nom || ''}`
                },
                {
                    displayName: 'Revendeur',
                    field: 'valeurTypeEntite.designation'
                },
                {
                    displayName: 'Client',
                    field: 'client.designation'
                },
                {
                    displayName: 'Numéro',
                    field: 'numero'
                },
                {
                    displayName: 'Type business',
                    field: 'typeBusiness'
                },
                {
                    displayName: 'Prix total',
                    field: 'prixTotal'
                },
                {
                    displayName: 'Quantité',
                    field: 'quantite'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Motif',
                    field: 'motif'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                },
                {
                    displayName: 'Dernière modification',
                    field: 'dateDerniereModification'
                }
            ]}
        />
    );
}
