import { useIsOnline } from '../../util';
import { ConditionalRenderingWrapper } from '../container';

/**
 * Ce composant affiche une barre qui s'affiche en l'absence de connexion internet
 * @returns {React.Component}
 */
export function InternetConnectionStatusBar() {
    const onlineStatus = useIsOnline();
    return (
        <ConditionalRenderingWrapper isShouldBeRendered={!onlineStatus}>
            <div className="row bg-danger danger">
                <div className="col-12 bg-danger danger text-white text-center">
                    <h5>Vérifiez votre connexion Internet...</h5>
                </div>
            </div>
        </ConditionalRenderingWrapper>
    );
}
