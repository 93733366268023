import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    TYPE_RECONCILIATION_SUPERDEALER,
    URL_CONST,
    utc000000,
    utc235959
} from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomInput,
    CustomLabel,
    CustomRadioButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import {
    useGetDetailForm
    // PutDetailsLigneCreditSuperDealerSchema as uiValidator
} from '../../../util';
import { schema } from './detail-fields';

const defaultFields = {
    idLimit: '',
    limit: 0,
    typeReconciliation: TYPE_RECONCILIATION_SUPERDEALER.JOURNALIERE,
    dateDebut: 0,
    dateFin: 0,
    actionEncours: ''
};

export function DetailLigneCreditForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'lignesCredit', defaultFields });

    const { fields, form } = formState;

    const updateEtat = (etat) => {
        const { typeReconciliation, limit, dateFin, dateDebut } = fields;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        let propertyToAdd = {};
        if (etat === ETAT.ETAT_ATTENTE_VALIDATION) {
            propertyToAdd = {
                limit,
                typeReconciliation,
                periode: {
                    dateDebut: utc000000(dateDebut),
                    dateFin: utc235959(dateFin)
                }
            };
        }
        const payload = {
            etat,
            idLigneCredit: entity?.id,
            ...propertyToAdd
        };
        // const propertyToAddForValidator = {};
        // if (etat === ETAT.ETAT_ATTENTE_VALIDATION) {
        //     propertyToAdd.uiValidator = uiValidator;
        //     propertyToAdd.fields = fields;
        // }
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_LIGNE_CREDIT_DEALER,
                payload,
                reduxNodeName: DB_NODE.LIGNE_CREDIT,
                reduxDispatcher
                // ...propertyToAddForValidator
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_LIGNE_CREDIT_BY_ID}
            nodeName={DB_NODE.LIGNE_CREDIT}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Ligne crédit"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.FINANCE_ORGANISATION.METTRE_A_JOUR_LIGNE_CREDIT
                ]}
            >
                {entity?.etat === ETAT.ETAT_ENCOURS ? (
                    <CustomRow>
                        <CustomRow>
                            <CustomInput
                                isInputGroup={false}
                                label="Limite* :"
                                labelClassName="col-sm-3"
                                divClassName="col-sm-8"
                                placeholder="Limite de crédit"
                                formDispatcher={formDispatcher}
                                name="limit"
                                type="number"
                                id="islimitOctroieLigneDeCredit"
                                formState={formState}
                                // uiValidator={uiValidator}
                            />
                        </CustomRow>
                        <CustomRow className="pt-2 px-2">
                            <CustomInput
                                isInputGroup={false}
                                label="Debut* :"
                                labelClassName="col-sm-3"
                                divClassName="col-sm-8"
                                placeholder="Limite de crédit"
                                formDispatcher={formDispatcher}
                                name="dateDebut"
                                type="date"
                                id="isDateDebutOctroieLigneDeCredit"
                                formState={formState}
                                // uiValidator={uiValidator}
                            />
                        </CustomRow>
                        <CustomRow className="pt-2 px-2">
                            <CustomInput
                                isInputGroup={false}
                                label="Fin* :"
                                labelClassName="col-sm-3"
                                divClassName="col-sm-8"
                                placeholder="Limite de crédit"
                                formDispatcher={formDispatcher}
                                name="dateFin"
                                type="date"
                                id="isDateFinOctroieLigneDeCredit"
                                formState={formState}
                                // uiValidator={uiValidator}
                            />
                        </CustomRow>
                        <CustomRow className="pt-2 px-2">
                            <CustomLabel
                                text="Réconciliation* :"
                                className="col-md-3"
                            />
                            <CustomRadioButton
                                divClassName="col-md-3"
                                label="Journalière"
                                name="typeReconciliation"
                                id="isIdCompFiege"
                                // uiValidator={uiValidator}
                                formState={formState}
                                formDispatcher={formDispatcher}
                                value={
                                    TYPE_RECONCILIATION_SUPERDEALER.JOURNALIERE
                                }
                            />
                            <CustomRadioButton
                                divClassName="col-md-6"
                                label=" A l'atteinte de la ligne de crédit"
                                name="typeReconciliation"
                                id="isIdCompRotative"
                                // uiValidator={uiValidator}
                                formState={formState}
                                formDispatcher={formDispatcher}
                                value={
                                    TYPE_RECONCILIATION_SUPERDEALER.ATTEINTE_LIGNE_CREDIT
                                }
                            />
                        </CustomRow>
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                ) : null}
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
