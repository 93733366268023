import * as yup from 'yup';

/**
 * @description : Schema de raison form
 */

export const RaisonFormSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required()
});
