import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Configuration } from '../../../module';
import {
    MarchandGeneralSettingRelPath,
    MarchandShowRoomSettingRelPath,
    MarchandDetailRayonRelPath,
    MarchandArticleRelPath,
    MarchandDetailArticleRelPath,
    MarchandAbonnementSettingRelPath,
    MarchandConfigSimSettingRelPath,
    AmendementElaborationSettingRelPath,
    MarchandVersementListRelPath,
    MarchandVersementCreationRelPath,
    MarchandVersementDetailRelPath,
    AffiliationAgentLivreurCreationRelPath,
    AffiliationAgentLivreurDetailRelPath,
    AffiliationAgentLivreurViewLayoutRelPath,
    RegularisationStockViewLayoutRelPath,
    RegularisationStockCreationRelPath,
    RegularisationStockDetailRelPath,
    TypeStockViewLayoutRelPath,
    TypeStockCreationRelPath,
    TypeStockDetailRelPath,
    ImputationStockViewLayoutRelPath,
    ImputationStockCreationRelPath,
    ImputationStockDetailRelPath,
    TransfertStockViewLayoutRelPath,
    TransfertStockCreationRelPath,
    TransfertStockDetailRelPath,
    PresenceViewLayoutRelPath,
    PresenceCreationRelPath,
    PresenceDetailRelPath,
    TarifRessourceViewLayoutRelPath,
    TarifRessourceCreationRelPath,
    TarifRessourceDetailRelPath,
    TarifChargeViewLayoutRelPath,
    TarifChargeCreationRelPath,
    TarifChargeDetailRelPath,
    ProduitViewLayoutRelPath,
    ProduitDetailRelPath,
    AmendementElaborationAdditionnelSettingRelPath,
    MarchandShowRoomCreateSettingRelPath,
    MarchandShowRoomDetailSettingRelPath,
    DerogationSettingRelPath,
    DerogationSettingCreateRelPath,
    DerogationSettingDetailRelPath,
    SuperDealerViewLayoutRelPath,
    SuperDealerCreateRelPath,
    SuperDealerDetailRelPath,
    ModifierColonneProduitAirtimeRelPath,
    ImputationCaisseViewLayoutRelPath,
    ImputationCaisseCreationRelPath,
    ImputationCaisseDetailRelPath
} from './config-marchand-rel-path';

export const MarchandGeneralSettingPath = {
    ...MarchandGeneralSettingRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.MarchandGeneralSetting,
    element: <Configuration.MarchandGeneralSetting />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
            .DEFINIR_LOGO_ORGANISATION,
        CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
            .PARAMETRER_ACCEPTER_NUMERO_REFERENCE_PAIEMENT,
        CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
            .PARAMETRER_TRANSFERT_FONDS_AUTOMATIQUE,
        CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
            .PARAMETRER_VIDER_COMPTE_FERMETURE,
        CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE.DEFINIR_HORAIRE,
        CODE_SERVICE.MARCHAND.CHOIX_CATEGORIE.MISE_A_JOUR_CATEGORIE_MARCHAND,
        CODE_SERVICE.STOCK.TYPE_COMPLEMENTARITE
            .METTRE_A_JOUR_TYPE_COMPLEMENTARITE
    ]
};

// Show room
export const MarchandShowRoomSettingPath = {
    ...MarchandShowRoomSettingRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListRayonForm,
    element: <Configuration.ListRayonForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND.LECTURE_SHOWROOM,
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND.CREATION_SHOWROOM
    ]
};

export const MarchandShowRoomCreateSettingPath = {
    ...MarchandShowRoomCreateSettingRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationRayonShowRoom,
    element: <Configuration.CreationRayonShowRoom />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND.CREATION_SHOWROOM
    ]
};

export const MarchandShowRoomDetailSettingPath = {
    ...MarchandShowRoomDetailSettingRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailRayonColonneForm,
    element: <Configuration.DetailRayonColonneForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND.LECTURE_SHOWROOM,
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND.CREATION_SHOWROOM
    ]
};

export const MarchandDetailRayonPath = {
    ...MarchandDetailRayonRelPath,
    isVisible: false,
    Component: Configuration.DetailRayonColonneForm,
    element: <Configuration.DetailRayonColonneForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND.LECTURE_SHOWROOM,
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND.CREATION_SHOWROOM
    ]
};

export const MarchandArticlePath = {
    ...MarchandArticleRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.MarchandArticleSetting,
    element: <Configuration.MarchandArticleSetting />,
    code: [{ code: 'test' }]
};

export const MarchandDetailArticlePath = {
    ...MarchandDetailArticleRelPath,
    isVisible: false,
    Component: Configuration.DetailArticleForm,
    element: <Configuration.DetailArticleForm />,
    code: [{ code: 'test' }]
};

export const MarchandAbonnementSettingPath = {
    ...MarchandAbonnementSettingRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.MarchandAbonnementSetting,
    element: <Configuration.MarchandAbonnementSetting />,
    code: [{ code: 'test' }]
};

/**
 * Complementarité stock
 */
export const MarchandConfigSimSettingPath = {
    ...MarchandConfigSimSettingRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.MarchandConfigSimSetting,
    element: <Configuration.MarchandConfigSimSetting />,
    code: [{ code: 'test' }]
};

// versement

export const MarchandVersementListSettingPath = {
    ...MarchandVersementListRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.MarchandVersementSetting,
    element: <Configuration.MarchandVersementSetting />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_VERSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_VERSEMENT_POUR_AUTRE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_VERSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_VERSEMENT
    ]
};
export const MarchandVersementCreationSettingPath = {
    ...MarchandVersementCreationRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationVersementForm,
    element: <Configuration.CreationVersementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_VERSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_VERSEMENT_POUR_AUTRE
    ]
};
export const MarchandVersementDetailSettingPath = {
    ...MarchandVersementDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailVersementForm,
    element: <Configuration.DetailVersementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_VERSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_VERSEMENT_POUR_AUTRE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_VERSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_VERSEMENT
    ]
};

// affiliation agent livreur
export const AffiliationAgentLivreurViewLayoutSettingPath = {
    ...AffiliationAgentLivreurViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.AffiliationAgentLivreurViewLayout,
    element: <Configuration.AffiliationAgentLivreurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.MARCHAND_PRODUIT_MATERIEL
            .INITIER_AFFILIATION_AGENT_LIVREUR,
        CODE_SERVICE.SERVICE_CODE_WEB.MARCHAND_PRODUIT_MATERIEL
            .LECTURE_LIST_AFFILIATION_AGENT_LIVREUR,
        CODE_SERVICE.SERVICE_CODE_WEB.MARCHAND_PRODUIT_MATERIEL
            .LECTURE_LIST_AFFILIATION_AGENT_LIVREUR_BY_ORGANISATION,
        CODE_SERVICE.SERVICE_CODE_WEB.MARCHAND_PRODUIT_MATERIEL
            .MODIFIER_AFFILIATION_AGENT_LIVREUR
    ]
};
export const AffiliationAgentLivreurCreationSettingPath = {
    ...AffiliationAgentLivreurCreationRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationAffiliationAgentLivreurForm,
    element: <Configuration.CreationAffiliationAgentLivreurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.MARCHAND_PRODUIT_MATERIEL
            .INITIER_AFFILIATION_AGENT_LIVREUR
    ]
};
export const AffiliationAgentLivreurDetailSettingPath = {
    ...AffiliationAgentLivreurDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailAffiliationAgentLivreurForm,
    element: <Configuration.DetailAffiliationAgentLivreurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.MARCHAND_PRODUIT_MATERIEL
            .INITIER_AFFILIATION_AGENT_LIVREUR,
        CODE_SERVICE.SERVICE_CODE_WEB.MARCHAND_PRODUIT_MATERIEL
            .LECTURE_LIST_AFFILIATION_AGENT_LIVREUR,
        CODE_SERVICE.SERVICE_CODE_WEB.MARCHAND_PRODUIT_MATERIEL
            .LECTURE_LIST_AFFILIATION_AGENT_LIVREUR_BY_ORGANISATION,
        CODE_SERVICE.SERVICE_CODE_WEB.MARCHAND_PRODUIT_MATERIEL
            .MODIFIER_AFFILIATION_AGENT_LIVREUR
    ]
};

// regularisation stock
export const RegularisationStockViewLayoutSettingPath = {
    ...RegularisationStockViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.RegularisationStockViewLayout,
    element: <Configuration.RegularisationStockViewLayout />,
    code: [{ code: 'test' }]
};
export const RegularisationStockCreationSettingPath = {
    ...RegularisationStockCreationRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationRegularisationStockForm,
    element: <Configuration.CreationRegularisationStockForm />,
    code: [{ code: 'test' }]
};
export const RegularisationStockDetailSettingPath = {
    ...RegularisationStockDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailRegularisationStockForm,
    element: <Configuration.DetailRegularisationStockForm />,
    code: [{ code: 'test' }]
};
// type stock
export const TypeStockViewLayoutPath = {
    ...TypeStockViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.TypeStockViewLayout,
    element: <Configuration.TypeStockViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.TYPE_STOCK.LECTURE_TYPE_STOCK,
        CODE_SERVICE.STOCK.TYPE_STOCK.INITIER_CREATION_TYPE_STOCK
    ]
};
export const TypeStockCreationPath = {
    ...TypeStockCreationRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationTypeStockForm,
    element: <Configuration.CreationTypeStockForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.TYPE_STOCK.INITIER_CREATION_TYPE_STOCK
    ]
};
export const TypeStockDetailPath = {
    ...TypeStockDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailTypeStockForm,
    element: <Configuration.DetailTypeStockForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.TYPE_STOCK.LECTURE_TYPE_STOCK,
        CODE_SERVICE.STOCK.TYPE_STOCK.INITIER_CREATION_TYPE_STOCK
    ]
};
// imputations stock

export const ImputationStockViewLayoutPath = {
    ...ImputationStockViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ImputationStockViewLayout,
    element: <Configuration.ImputationStockViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.IMPUTATION_STOCK.LECTURE_IMPUTATION_STOCK,
        CODE_SERVICE.STOCK.IMPUTATION_STOCK.INITIER_CREATION_IMPUTATION_STOCK
    ]
};
export const ImputationStockCreationPath = {
    ...ImputationStockCreationRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationImputationStockForm,
    element: <Configuration.CreationImputationStockForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.IMPUTATION_STOCK.INITIER_CREATION_IMPUTATION_STOCK
    ]
};
export const ImputationStockDetailPath = {
    ...ImputationStockDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailImputationStockForm,
    element: <Configuration.DetailImputationStockForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.IMPUTATION_STOCK.LECTURE_IMPUTATION_STOCK,
        CODE_SERVICE.STOCK.IMPUTATION_STOCK.INITIER_CREATION_IMPUTATION_STOCK
    ]
};
// imputations caisse

export const ImputationCaisseViewLayoutPath = {
    ...ImputationCaisseViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ImputationCaisseViewLayout,
    element: <Configuration.ImputationCaisseViewLayout />,
    code: [
        { code: 'test' },
        // CODE_SERVICE.STOCK.IMPUTATION_STOCK.LECTURE_IMPUTATION_STOCK,
        // CODE_SERVICE.STOCK.IMPUTATION_STOCK.INITIER_CREATION_IMPUTATION_STOCK,
        CODE_SERVICE.SERVICE_CODE_WEB.IMPUTATION.CREATION_IMPUTATION
    ]
};
export const ImputationCaisseCreationPath = {
    ...ImputationCaisseCreationRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationImputationCaisseForm,
    element: <Configuration.CreationImputationCaisseForm />,
    code: [
        { code: 'test' },
        // CODE_SERVICE.STOCK.IMPUTATION_STOCK.INITIER_CREATION_IMPUTATION_STOCK,
        CODE_SERVICE.SERVICE_CODE_WEB.IMPUTATION.CREATION_IMPUTATION
    ]
};
export const ImputationCaisseDetailPath = {
    ...ImputationCaisseDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailImputationCaisseForm,
    element: <Configuration.DetailImputationCaisseForm />,
    code: [
        { code: 'test' },
        // CODE_SERVICE.STOCK.IMPUTATION_STOCK.LECTURE_IMPUTATION_STOCK,
        // CODE_SERVICE.STOCK.IMPUTATION_STOCK.INITIER_CREATION_IMPUTATION_STOCK,
        CODE_SERVICE.SERVICE_CODE_WEB.IMPUTATION.CREATION_IMPUTATION
    ]
};
// transfert stock

export const TransfertStockViewLayoutPath = {
    ...TransfertStockViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.TransfertStockViewLayout,
    element: <Configuration.TransfertStockViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.TRANSFERT_STOCK.LECTURE_TRANSFERT_STOCK,
        CODE_SERVICE.STOCK.TRANSFERT_STOCK.INITIER_CREATION_TRANSFERT_STOCK,
        CODE_SERVICE.STOCK.TRANSFERT_STOCK.METTRE_A_JOUR_TRANSFERT_STOCK
    ]
};
export const TransfertStockCreationPath = {
    ...TransfertStockCreationRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationTransfertStockForm,
    element: <Configuration.CreationTransfertStockForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.TRANSFERT_STOCK.INITIER_CREATION_TRANSFERT_STOCK
    ]
};
export const TransfertStockDetailPath = {
    ...TransfertStockDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailTransfertStockForm,
    element: <Configuration.DetailTransfertStockForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.TRANSFERT_STOCK.LECTURE_TRANSFERT_STOCK,
        CODE_SERVICE.STOCK.TRANSFERT_STOCK.INITIER_CREATION_TRANSFERT_STOCK,
        CODE_SERVICE.STOCK.TRANSFERT_STOCK.METTRE_A_JOUR_TRANSFERT_STOCK
    ]
};

// presence agents
export const PresenceViewLayoutPath = {
    ...PresenceViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.PresenceViewLayout,
    element: <Configuration.PresenceViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRESENCE.INITIER_ENREGISTREMENT_PRESENCE,
        CODE_SERVICE.SERVICE_CODE_WEB.PRESENCE.LECTURE_PRESENCE
    ]
};
export const PresenceCreationPath = {
    ...PresenceCreationRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationPresenceAgentForm,
    element: <Configuration.CreationPresenceAgentForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRESENCE.INITIER_ENREGISTREMENT_PRESENCE,
        CODE_SERVICE.SERVICE_CODE_WEB.PRESENCE.LECTURE_PRESENCE
    ]
};
export const PresenceDetailPath = {
    ...PresenceDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailPresenceAgentForm,
    element: <Configuration.DetailPresenceAgentForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRESENCE.INITIER_ENREGISTREMENT_PRESENCE,
        CODE_SERVICE.SERVICE_CODE_WEB.PRESENCE.LECTURE_PRESENCE
    ]
};
// tarif ressources
export const TarifRessourceViewLayoutPath = {
    ...TarifRessourceViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.TarifRessourceViewLayout,
    element: <Configuration.TarifRessourceViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_TARIFICATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_TARIFICATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_TARIFICATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_TARIFICATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_TARIFICATION_RESSOURCE
    ]
};
export const TarifRessourceCreationPath = {
    ...TarifRessourceCreationRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationTarifRessourceForm,
    element: <Configuration.CreationTarifRessourceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_TARIFICATION_RESSOURCE
    ]
};
export const TarifRessourceDetailPath = {
    ...TarifRessourceDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailTarifRessourceForm,
    element: <Configuration.DetailTarifRessourceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_TARIFICATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_TARIFICATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_TARIFICATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_TARIFICATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_TARIFICATION_RESSOURCE
    ]
};
// tarif charges
export const TarifChargeViewLayoutPath = {
    ...TarifChargeViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.TarifChargeViewLayout,
    element: <Configuration.TarifChargeViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_TARIFICATION_CHARGE
    ]
};
export const TarifChargeCreationPath = {
    ...TarifChargeCreationRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationTarifChargeForm,
    element: <Configuration.CreationTarifChargeForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_TARIFICATION_CHARGE
    ]
};
export const TarifChargeDetailPath = {
    ...TarifChargeDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailTarifChargeForm,
    element: <Configuration.DetailTarifChargeForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_TARIFICATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_TARIFICATION_CHARGE
    ]
};
// Produit
export const ProduitViewLayoutPath = {
    ...ProduitViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ProduitViewLayout,
    element: <Configuration.ProduitViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND.LECTURE_LISTE_PRODUIT,
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND
            .METTRE_A_JOUR_COLONNE_PRODUIT
    ]
};
export const ProduitDetailPath = {
    ...ProduitDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailProduitMarchandForm,
    element: <Configuration.DetailProduitMarchandForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND.LECTURE_LISTE_PRODUIT,
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND
            .METTRE_A_JOUR_COLONNE_PRODUIT
    ]
};

export const ModifierColonneProduitAirtimePath = {
    ...ModifierColonneProduitAirtimeRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ModifierColonneProduitAirtimeForm,
    element: <Configuration.ModifierColonneProduitAirtimeForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PRODUIT_MARCHAND
            .METTRE_A_JOUR_COLONNE_PRODUIT
    ]
};

// setting

export const AmendementElaborationSettingPath = {
    ...AmendementElaborationSettingRelPath,
    dependencies: ['ConfigMenu'],
    Component: Configuration.AmendementElaborationChargeRessource,
    element: <Configuration.AmendementElaborationChargeRessource />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};

export const AmendementElaborationAdditionnelSettingPath = {
    ...AmendementElaborationAdditionnelSettingRelPath,
    dependencies: ['ConfigMenu'],
    Component: Configuration.AmendementElaborationChargeRessource,
    element: <Configuration.AmendementElaborationChargeRessource />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};

// Dérogationùù

export const DerogationSettingPath = {
    ...DerogationSettingRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListDerogation,
    element: <Configuration.ListDerogation />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.DEROGATION.INITIER_DEROGATION,
        CODE_SERVICE.SERVICE_CODE_WEB.DEROGATION.ATTENTE_VALIDATION_DEROGATION,
        CODE_SERVICE.SERVICE_CODE_WEB.DEROGATION.LECTURE_DEROGATION,
        CODE_SERVICE.SERVICE_CODE_WEB.DEROGATION.VALIDER_DEROGATION
    ]
};

export const DerogationCreateSettingPath = {
    ...DerogationSettingCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationDerogationForm,
    element: <Configuration.CreationDerogationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.DEROGATION.INITIER_DEROGATION
    ]
};

export const DerogationDetailSettingPath = {
    ...DerogationSettingDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailDerogationForm,
    element: <Configuration.DetailDerogationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.DEROGATION.INITIER_DEROGATION,
        CODE_SERVICE.SERVICE_CODE_WEB.DEROGATION.ATTENTE_VALIDATION_DEROGATION,
        CODE_SERVICE.SERVICE_CODE_WEB.DEROGATION.LECTURE_DEROGATION,
        CODE_SERVICE.SERVICE_CODE_WEB.DEROGATION.VALIDER_DEROGATION
    ]
};

/**
 * @desc Appointage Super dealer
 */

export const SuperDealerViewLayoutPath = {
    ...SuperDealerViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.SuperDealerViewLayout,
    element: <Configuration.SuperDealerViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.MARCHAND.APPOINTAGE_SUPER_DEALER
            .LECTURE_APPOINTAGE_SUPER_DEALER,
        CODE_SERVICE.MARCHAND.APPOINTAGE_SUPER_DEALER
            .VALIDER_APPOINTAGE_SUPER_DEALER,
        CODE_SERVICE.MARCHAND.APPOINTAGE_SUPER_DEALER
            .INITIER_APPOINTAGE_SUPER_DEALER
    ]
};

export const SuperDealerCreatePath = {
    ...SuperDealerCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationSuperDealerForm,
    element: <Configuration.CreationSuperDealerForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.MARCHAND.APPOINTAGE_SUPER_DEALER
            .INITIER_APPOINTAGE_SUPER_DEALER
    ]
};

export const SuperDealerDetailPath = {
    ...SuperDealerDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailSuperDealerForm,
    element: <Configuration.DetailSuperDealerForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.MARCHAND.APPOINTAGE_SUPER_DEALER
            .LECTURE_APPOINTAGE_SUPER_DEALER,
        CODE_SERVICE.MARCHAND.APPOINTAGE_SUPER_DEALER
            .VALIDER_APPOINTAGE_SUPER_DEALER,
        CODE_SERVICE.MARCHAND.APPOINTAGE_SUPER_DEALER
            .INITIER_APPOINTAGE_SUPER_DEALER
    ]
};
