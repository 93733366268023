import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListCashCollectionForm() {
    return (
        <ListGenericForm
            reduxPropertyName="cashCollectionOrganisation"
            functionName={URL_CONST.GET_LIST_CASH_COLLECTEUR_INITIALS}
            nodeName={REDUX_NODE_NAME.CASH_COLLECTION_ORGANISATION}
            mapper={[
                {
                    displayName: 'Email',
                    field: 'email',
                    isKeepFormat: true
                },
                {
                    displayName: 'Numero',
                    field: 'numeroTel'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
