import * as yup from 'yup';

/**
 * @description : Schema de validation secteurs
 */
export const SecteurSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required(),
    estCommercial: yup.boolean(),
    typeEntite: yup.string().required(),
    typeProprietaire: yup.string().required()
});
