import { useCallback } from 'react';
import { GENERIC_ACTIONS_TYPE, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useFetchAndListening } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST, formaterCaisses } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { ConditionalRenderingWrapper, CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner les caisses
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @param {String} param0.label Label du dropdown, par défaut "Caisse : "
 * @param {String} param0.propertyName propriété où la valeur de la caisse selectionnée est enregistrée dans le form state, par defaut "selectedCaisse"
 * @param {String} param0.labelClassName className du label
 * @param {String} param0.divClassName className du div
 * @param {String} param0.propertyToDisplay nom de la propriété à afficher dans le dropdown, par défaut : "designation"
 * @param {String} param0.idProperty propriété id à utiliser, par défaut : "id"
 * @returns {React.Component}
 */
export function ReportCaisseOrganisationSelector({
    formDispatcher,
    formState,
    cfPayload = {},
    cfCondition = {},
    idToken,
    typeEntite
}) {
    const reduxDispatcher = useDispatch();
    const {
        superDealerKinDB,
        etatSuperDealerKinDB,
        allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB,
        caisses,
        etatCaisses,
        caissesOrganisation,
        etatCaissesOrganisation
    } = useSelector((reduxState) => ({
        superDealerKinDB:
            reduxState?.[cfPayload?.superDealerReduxProperty]?.map((item) => ({
                ...item,
                code: item?.entreprise?.code,
                designation: item?.entreprise?.designation
            })) || [],
        etatSuperDealerKinDB:
            reduxState?.etat?.[cfPayload?.superDealerReduxProperty]?.etat,
        allCashCollecteurKinDB: reduxState.allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB: reduxState.etat.allCashCollecteurKinDB.etat,
        caisses: formaterCaisses(
            reduxState?.[cfPayload?.caisseReduxProperty] || []
        ),
        etatCaisses: reduxState?.etat?.[cfPayload?.caisseReduxProperty]?.etat,
        caissesOrganisation: formaterCaisses(
            reduxState?.[cfPayload?.caisseOrganisationReduxProperty] || []
        ),
        etatCaissesOrganisation:
            reduxState?.etat?.[cfPayload?.caisseOrganisationReduxProperty]?.etat
    }));

    const { fields } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: cfPayload?.superDealerNodeName,
                etat: etatSuperDealerKinDB,
                payload: cfPayload?.superDealer,
                isCondition: !!cfPayload?.superDealer
            },
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_CASH_COLLECTEUR_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_CASH_COLLECTEUR_KIN_DB,
                etat: etatAllCashCollecteurKinDB,
                isCondition: typeEntite === TYPE_ORGANISATION.NAPP
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: cfPayload?.caisseNodeName,
                payload: cfPayload?.caisses,
                etat: etatCaisses,
                isCondition: !!cfPayload.caisseNodeName
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: cfPayload?.caisseOrganisationNodeName,
                payload: cfPayload?.caisseNapp,
                etat: etatCaissesOrganisation,
                isCondition: !!cfPayload.caisseOrganisationNodeName
            }
        ]
    });

    const getAllOrganisationByTypeOrganisation = useCallback(() => {
        if (
            typeEntite === TYPE_ORGANISATION.NAPP &&
            !!cfCondition?.isCaissier
        ) {
            const sd = superDealerKinDB.filter(
                (item) => item.code !== typeEntite
            );
            const cc = allCashCollecteurKinDB.filter(
                (item) => item.code !== typeEntite
            );
            if (
                fields.selectedTypeOrganisation.code ===
                TYPE_ORGANISATION.SUPER_DEALER
            ) {
                return superDealerKinDB;
            }
            if (
                fields.selectedTypeOrganisation.code ===
                TYPE_ORGANISATION.CASH_COLLECTEUR
            ) {
                return allCashCollecteurKinDB;
            }
            if (
                fields.selectedTypeOrganisation.code === TYPE_ORGANISATION.NAPP
            ) {
                return [
                    {
                        code: TYPE_ORGANISATION.NAPP,
                        designation: TYPE_ORGANISATION.NAPP
                    }
                ];
            }
            if (fields.selectedTypeOrganisation.code === `ALL`)
                return [
                    {
                        code: TYPE_ORGANISATION.NAPP,
                        designation: TYPE_ORGANISATION.NAPP
                    },
                    ...sd,
                    ...cc
                ];
        }
        if (typeEntite === TYPE_ORGANISATION.NAPP && !!cfCondition?.isGlobal) {
            const sd = superDealerKinDB.filter(
                (item) => item.code !== typeEntite
            );
            const cc = allCashCollecteurKinDB.filter(
                (item) => item.code !== typeEntite
            );
            if (fields.selectedTypeOrganisation.code === `ALL`)
                return [
                    {
                        code: TYPE_ORGANISATION.NAPP,
                        designation: TYPE_ORGANISATION.NAPP
                    },
                    ...sd,
                    ...cc
                ];
            if (fields.selectedTypeOrganisation.code === TYPE_ORGANISATION.NAPP)
                return [
                    {
                        code: TYPE_ORGANISATION.NAPP,
                        designation: TYPE_ORGANISATION.NAPP
                    }
                ];
            if (
                fields.selectedTypeOrganisation.code ===
                TYPE_ORGANISATION.CASH_COLLECTEUR
            ) {
                return allCashCollecteurKinDB;
            }
            if (
                fields.selectedTypeOrganisation.code ===
                TYPE_ORGANISATION.SUPER_DEALER
            ) {
                return superDealerKinDB;
            }
        }
        return [];
    }, [
        allCashCollecteurKinDB,
        cfCondition?.isCaissier,
        cfCondition?.isGlobal,
        fields.selectedTypeOrganisation.code,
        superDealerKinDB,
        typeEntite
    ]);

    const filterCaisses = useCallback(() => {
        if (fields.selectedSuperDealerIsAll.code === TYPE_ORGANISATION.NAPP) {
            return caissesOrganisation.filter(
                (item) =>
                    item?.typeOrganisation === TYPE_ORGANISATION.NAPP ||
                    !!item?.estNapp
            );
        }
        if (
            typeEntite === TYPE_ORGANISATION.NAPP &&
            fields.selectedSuperDealerIsAll.code &&
            !!cfCondition?.isCaissier
        ) {
            return caisses.filter(
                (item) =>
                    item?.superDealer?.code ===
                    fields.selectedSuperDealerIsAll.code
            );
        }
        if (
            typeEntite === TYPE_ORGANISATION.NAPP &&
            fields.selectedSuperDealerIsAll.code &&
            !cfCondition?.isCaissier
        ) {
            return [...caisses, ...caissesOrganisation].filter(
                (item) =>
                    item?.superDealer?.code ===
                    fields.selectedSuperDealerIsAll.code
            );
        }
        if (typeEntite !== TYPE_ORGANISATION.NAPP) {
            return caisses;
        }
        return [];
    }, [
        caisses,
        caissesOrganisation,
        cfCondition?.isCaissier,
        fields.selectedSuperDealerIsAll.code,
        typeEntite
    ]);

    const renderContent = () => {
        if (
            etatCaisses === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS &&
            etatSuperDealerKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS &&
            etatAllCashCollecteurKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS &&
            typeEntite === TYPE_ORGANISATION.NAPP
        ) {
            return (
                <>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            (!!cfCondition?.isGlobal ||
                                !!cfCondition?.isCaissier) &&
                            typeEntite === TYPE_ORGANISATION.NAPP
                        }
                    >
                        <CustomRow>
                            <CustomDropdown
                                isSort={false}
                                options={[
                                    {
                                        code: `ALL`,
                                        designation: `Tous`
                                    },
                                    {
                                        code: TYPE_ORGANISATION.NAPP,
                                        designation: TYPE_ORGANISATION.NAPP
                                    },
                                    {
                                        code: TYPE_ORGANISATION.CASH_COLLECTEUR,
                                        designation: 'Cash Collecteur'
                                    },
                                    {
                                        code: TYPE_ORGANISATION.SUPER_DEALER,
                                        designation: 'Super Dealer'
                                    }
                                ]}
                                label="Type Organisation* :"
                                defaultOption="Sélectionner Type Organisation"
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedTypeOrganisation"
                                id="selectedTypeOrganisationCreationTransfertInstitutionnelId"
                                formState={formState}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomDropdown
                                isSort={false}
                                options={getAllOrganisationByTypeOrganisation()}
                                disabled={
                                    !getAllOrganisationByTypeOrganisation()
                                        ?.length
                                }
                                defaultOption="Selectionner organisation..."
                                label="Organisation* : "
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedSuperDealerIsAll"
                                id="selectedSuperDealerIsAllReportCaisseOrganisationSelectorId"
                                formState={formState}
                            />
                        </CustomRow>
                    </ConditionalRenderingWrapper>
                    <CustomRow>
                        <CustomDropdown
                            isSort={false}
                            options={filterCaisses()}
                            label="Caisse* :"
                            defaultOption="Sélectionner caisse"
                            disabled={!filterCaisses()?.length}
                            labelClassName="col-12"
                            divClassName="col-12"
                            formDispatcher={formDispatcher}
                            name="selectedCaisse"
                            id="selectedCaisseReportCaisseOrganisationSelectorId"
                            formState={formState}
                            idProperty="id"
                        />
                    </CustomRow>
                </>
            );
        }
        if (
            etatCaisses === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS &&
            typeEntite !== TYPE_ORGANISATION.NAPP
        ) {
            return (
                <CustomRow>
                    <CustomDropdown
                        isSort={false}
                        options={filterCaisses()}
                        label="Caisse* :"
                        defaultOption="Sélectionner caisse"
                        disabled={!filterCaisses()?.length}
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedCaisse"
                        id="selectedCaisseReportCaisseOrganisationSelectorId"
                        formState={formState}
                        idProperty="id"
                    />
                </CustomRow>
            );
        }
        if (etatCaisses === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des caisses" />
            );
        }
        if (
            etatSuperDealerKinDB === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des organisations superDealers" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
