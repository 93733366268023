import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    TYPE_SERVICE_GENERATUER_CHARGE,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddButton,
    CustomAlert,
    CustomButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { TarifChargeFormSchema as uiValidator } from './validation';

const defaultFields = {
    selectedServiceGenerateur: {},
    selectedCharge: {},
    pourcentage: 0,
    listTarif: [],
    payload: {},
    selectedType: {},
    selectedCategorie: {},
    estTarifPreferentiel: false,
    tillNumber: ''
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: ['servicesGenerateur', 'charges', 'categoriesMarchand']
};

export function CreationTarifChargeForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        charges,
        etatCharge,
        servicesGenerateur,
        etatServiceGenerateur,
        categoriesMarchand,
        typesMarchand,
        etatCategorieMarchand
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        charges: reduxState.chargesVariable,
        etatCharge: reduxState.etat.chargesVariable.etat,
        servicesGenerateur: reduxState.servicesGenerateur.filter(
            (item) => item.type === TYPE_SERVICE_GENERATUER_CHARGE.code
        ),
        etatServiceGenerateur: reduxState.etat.servicesGenerateur.etat,
        categoriesMarchand: reduxState.categoriesMarchand.filter(
            (item) => item.typeEntite === 'Categorie'
        ),
        typesMarchand: reduxState.categoriesMarchand.filter(
            (item) => item.typeEntite === 'Type'
        ),
        etatCategorieMarchand: reduxState.etat.categoriesMarchand.etat
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: ['marchand']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_VARIABLE,
                nodeName: REDUX_NODE_NAME.CHARGE_VARIABLE,
                etat: etatCharge
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SERVICE_GENERATEUR,
                nodeName: DB_NODE.SERVICE_GENERATEUR,
                etat: etatServiceGenerateur
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CATEGORIE_MARCHAND,
                nodeName: DB_NODE.CATEGORIE_MARCHAND,
                etat: etatCategorieMarchand
            }
        ]
    });
    const ajouterCharge = () => {
        const { pourcentage, selectedServiceGenerateur, selectedCharge } =
            fields;
        if (
            !selectedCharge?.code ||
            !selectedServiceGenerateur.code ||
            !pourcentage
        )
            return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listTarif',
                data: {
                    id: `${selectedServiceGenerateur.code}${selectedCharge.code}`,
                    serviceGenerateur: selectedServiceGenerateur,
                    charge: selectedCharge,
                    pourcentage
                }
            }
        });
    };
    useEffect(() => {
        if (fields.estTarifPreferentiel && elements.marchand) {
            if (elements.marchand?.value?.categorieMarchand) {
                const type = {
                    code: elements.marchand.value?.categorieMarchand.code,
                    designation:
                        elements.marchand.value?.categorieMarchand.designation
                };
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'selectedType',
                        data: type
                    }
                });
            } else {
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'selectedType',
                        data: {}
                    }
                });
            }
        }
    }, [fields.estTarifPreferentiel, elements.marchand, formDispatcher]);
    const fetchMarchand = () => {
        const { tillNumber } = fields;
        if (tillNumber && tillNumber !== elements.marchand?.value?.tillNumber) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'marchand',
                    payload: { tillNumber },
                    functionName: URL_CONST.GET_MARCHAND_BY_TILL_NUMBER,
                    etat: elements.marchand.status,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { listTarif, estTarifPreferentiel, selectedType } = fields;
        const { marchand } = elements;
        if (!listTarif.length) return;
        if (estTarifPreferentiel && !marchand?.value?.id) return;
        const list = listTarif.map((tarif) => ({
            taux: tarif.pourcentage,
            code: tarif.charge.code
        }));
        const payload = {};
        if (!estTarifPreferentiel) {
            payload.elements = [
                {
                    codeTypeMarchand: selectedType.code,
                    list
                }
            ];
        } else {
            payload.details = list;
            payload.idMarchand = marchand?.value?.id;
        }
        const functionName = estTarifPreferentiel
            ? URL_CONST.POST_TARIF_MARCHAND
            : URL_CONST.POST_TARIF_CHARGE;
        formDispatcher(
            submitPostAction({
                idToken,
                functionName,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TARIF_CHARGE,
                reduxDispatcher
                // uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'servicesGenerateur',
                    etat: etatServiceGenerateur
                },
                {
                    dependency: 'charges',
                    etat: etatCharge
                },
                {
                    dependency: 'categoriesMarchand',
                    etat: etatCategorieMarchand
                }
            ]}
        >
            <CustomRow>
                <CustomSwitchButton
                    id="estTarifPreferentielCreationTarifRessource"
                    text="Tarif préférentiel"
                    inputTitle="Tarif preferentiel pour un marchand"
                    formDispatcher={formDispatcher}
                    name="estTarifPreferentiel"
                    divClassName="col-md-8"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            {fields.estTarifPreferentiel && (
                <>
                    <CustomRow>
                        <CustomInput
                            type="text"
                            label="Till number*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-10 col-sm-8"
                            isInputGroup={false}
                            placeholder="Saisissez le tillNumber"
                            formDispatcher={formDispatcher}
                            name="tillNumber"
                            id="tillNumberCreationTarifRessource"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                        <CustomCol className="col-2">
                            <CustomButton
                                disabled={
                                    elements.marchand.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                                onClick={fetchMarchand}
                            >
                                Rechercher
                            </CustomButton>
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomCol className="offset-md-2 col-12 col-md-10">
                            {elements.marchand.status ===
                                FORM_CONST.FETCH_ELEMENT_FAILED && (
                                <CustomAlert
                                    error={`${elements.marchand?.error || ''}`}
                                />
                            )}
                            {elements.marchand.status ===
                                FORM_CONST.FETCH_ELEMENT_SUCCESS && (
                                <CustomAlert
                                    successMessage={`${
                                        elements.marchand?.value?.tillNumber ||
                                        ''
                                    } - ${
                                        elements.marchand?.value?.designation ||
                                        ''
                                    }`}
                                />
                            )}
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            options={typesMarchand}
                            label="Type marchand*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            name="selectedType"
                            id="selectedTypeCreationTarifRessource"
                            formState={formState}
                            propertyToDisplay="designation"
                            idProperty="code"
                            disabled
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                </>
            )}
            {!fields.estTarifPreferentiel && (
                <>
                    <CustomRow>
                        <CustomDropdown
                            options={categoriesMarchand}
                            label="Categorie marchand*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            name="selectedCategorie"
                            id="selectedCategorieCreationTarifRessource"
                            formState={formState}
                            propertyToDisplay="designation"
                            idProperty="code"
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            options={typesMarchand.filter(
                                (typeRes) =>
                                    typeRes?.parent?.code ===
                                    fields.selectedCategorie?.code
                            )}
                            label="Type marchand*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            name="selectedType"
                            id="selectedTypeCreationTarifRessource"
                            formState={formState}
                            propertyToDisplay="designation"
                            idProperty="code"
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                </>
            )}
            <CustomRow>
                <CustomDropdown
                    options={servicesGenerateur}
                    label="Service*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedServiceGenerateur"
                    id="selectedServiceGenerateurCreationTarifCharge"
                    formState={formState}
                    propertyToDisplay="designation"
                    idProperty="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={charges.filter(
                        (chg) =>
                            fields.selectedServiceGenerateur?.code ===
                            chg?.serviceGenerateur?.code
                    )}
                    label="Charge*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCharge"
                    id="selectedChargeCreationTarifCharge"
                    formState={formState}
                    propertyToDisplay="designation"
                    idProperty="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    isFloat
                    isInputGroup={false}
                    label="Pourcentage*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-11 col-md-9"
                    min={0}
                    placeholder="Saisissez le pourcentage"
                    formDispatcher={formDispatcher}
                    name="pourcentage"
                    id="pourcentageCreationTarifCharge"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomCol className="col-1">
                    <CustomAddButton callback={ajouterCharge} />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    <SimpleTable
                        formDispatcher={formDispatcher}
                        listSelectedItemName="listTarif"
                        data={fields.listTarif}
                        identifier="id"
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                field: 'serviceGenerateur.designation',
                                displayName: 'service',
                                position: 2
                            },
                            {
                                field: 'charge.designation',
                                displayName: 'charge',
                                position: 3
                            },
                            {
                                field: 'pourcentage',
                                displayName: 'pourcentage',
                                position: 4
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
