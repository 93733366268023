export const commandeMapper = [
    {
        displayName: 'Marchand',
        field: 'marchand.designation'
    },
    {
        field: 'produit.designation',
        displayName: 'produit'
    },
    {
        displayName: 'Revendeur',
        field: 'valeurTypeEntite.designation'
    },
    {
        displayName: 'User',
        dataExtractor: (item) =>
            `${item?.user?.prenom || ''} ${item?.user?.nom || ''}`
    },
    {
        field: 'client.designation',
        displayName: 'Client',
        dataExtractor: (item) => {
            if (item?.client?.designation) return item?.client?.designation;
            return item?.valeurTypeEntite?.designation;
        }
    },
    {
        field: 'numero',
        displayName: 'Numéro'
    },
    {
        field: 'quantite',
        displayName: 'quantite',
        isNumber: true
    },
    {
        field: 'etat',
        displayName: 'Etat'
    },
    {
        field: 'numeroReference',
        displayName: 'Référence'
    },
    {
        field: 'business',
        displayName: 'Type Business'
    },
    {
        field: 'motif',
        displayName: 'Motif'
    },
    {
        field: 'dateCreation',
        displayName: 'Création'
    },
    {
        field: 'dateDerniereModification',
        displayName: 'Dernière modification'
    }
];
