import { GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST, getDesignationStock } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { CustomAlert } from '../alert';

/**
 * Construit une dropdown où l'on peut sélectionner le stock super dealer
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */

export function StockSuperDealerSelector({
    formDispatcher,
    formState,
    idProperty = 'id',
    isAddAditionalOptions = false,
    additionnalOptions = [{ id: 'all', code: 'all', designation: 'Tous' }]
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, stocksSuperDealer, etatStockSuperDealer } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            stocksSuperDealer: reduxState.stocksSuperDealer,
            etatStockSuperDealer: reduxState.etat.stocksSuperDealer.etat
        }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_STOCK_SUPER_DEALER,
                nodeName: REDUX_NODE_NAME.STOCK_SUPER_DEALER,
                etat: etatStockSuperDealer,
                formater: (item) =>
                    item.map((elm) => ({
                        ...elm,
                        code: elm?.valeurTypeOrganisation?.code,
                        designation: getDesignationStock(
                            elm?.marchand?.designation,
                            elm?.typeBusiness
                        )
                    }))
            }
        ]
    });
    const renderContent = () => {
        if (
            etatStockSuperDealer ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <CustomDropdown
                    options={stocksSuperDealer}
                    defaultOption="Selectionner stock"
                    label="Stock: "
                    labelClassName="col-12"
                    divClassName="col-12"
                    formDispatcher={formDispatcher}
                    name="selectedStockSuperDealer"
                    id="selectedStockSuperDealerStockSuperDealerSelector"
                    formState={formState}
                    idProperty={idProperty}
                    keyProperty="id"
                    additionnalOptions={
                        isAddAditionalOptions ? additionnalOptions : []
                    }
                />
            );
        }
        if (
            etatStockSuperDealer === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des stocks" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
