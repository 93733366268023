import { useEffect, useState } from 'react';
/**
 * Ce hook définit si la connection internet est active ou pas
 * @returns {Boolean}
 */
export const useIsOnline = () => {
    const [isOnline, setIsOnline] = useState(true);
    useEffect(() => {
        setInterval(() => {
            if (navigator.onLine) {
                setIsOnline(true);
            } else {
                setIsOnline(false);
            }
        }, 2000);
    }, []);
    return isOnline;
};
