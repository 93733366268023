import * as yup from 'yup';

/**
 * @description : Schema de validation Programmer Pause
 */
export const ProgrammerPauseSchema = yup.object().shape({
    debutPause: yup.string().required(),
    finPause: yup.string().required(),
    selectedUser: yup.object().shape({
        reference: yup.string().required(),
        designation: yup.string().required()
    }),
    users: yup
        .array()
        .of(
            yup
                .object({
                    code: yup.string().required(),
                    designation: yup.string().required()
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins un élément à la liste'),
    duree: yup.number().required()
});
