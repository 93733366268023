import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import {
    buildYupErrorObject,
    extractNetworkErrorMessage,
    FORM_CONST,
    formInitialState
} from '@napp-inc/jnapp-util';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    CustomInput,
    CustomButtonLoader,
    CustomRow,
    CustomForm,
    CustomFormGroup,
    CustomAlert
} from '../../components';
import { auth } from '../../firebase/firebase';
import {
    SignUpRelPath,
    ForgotPasswordRelPath
} from '../../routes/registration/gestion-user/connexion-rel-path';
import { SignInSchema } from '../../util/validation';

const defaultFields = {
    email: '',
    password: ''
};

export function SingIn() {
    const uiValidator = SignInSchema;
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        { initialState }
    );
    const { fields, form } = formState;
    const handleSubmit = async (event, login, pwd) => {
        event.preventDefault();
        try {
            formDispatcher({ type: FORM_CONST.CREATION_EN_COURS });
            if (await uiValidator.validate(fields, { abortEarly: false })) {
                await signInWithEmailAndPassword(
                    auth,
                    login || fields.email,
                    pwd || fields.password
                );
            }
        } catch (erreur) {
            if (erreur.name === 'ValidationError') {
                const err = buildYupErrorObject(erreur);
                formDispatcher({
                    type: FORM_CONST.SET_FIELDS_ERROR,
                    payload: {
                        message: { ...err },
                        status: FORM_CONST.CREATION_ECHEC
                    }
                });
            } else {
                const message = extractNetworkErrorMessage(erreur);
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: { message, status: FORM_CONST.CREATION_ECHEC }
                });
            }
        }
    };
    return (
        <div className="container-fluid -flex justify-content-center align-items-center">
            <CustomRow className="p-5">
                <Col className="col-md-4 col-xl-4 col-lg-4 col-sm-10 offset-sm-1 offset-md-3 col-">
                    <CustomForm className="row g-3" onSubmit={handleSubmit}>
                        <CustomAlert
                            error={
                                formState.form.error
                                    ? 'Vérifiez les informations renseignées'
                                    : ''
                            }
                        />
                        <p className="fs-3 mb-3 fw-bold text-center">
                            Connexion
                        </p>
                        <hr />
                        <CustomFormGroup>
                            <CustomInput
                                uiValidator={uiValidator}
                                label="E-mail"
                                labelClassName="col-12 fs-6 fw-bolder"
                                type="email"
                                id="email"
                                name="email"
                                controlClassName="form-control"
                                placeholder="adresse@jnapp.com"
                                formDispatcher={formDispatcher}
                                formState={formState}
                            />
                        </CustomFormGroup>
                        <br />
                        <CustomFormGroup>
                            <CustomInput
                                uiValidator={uiValidator}
                                label="Mot de passe"
                                labelClassName="col-12 fs-6 fw-bolder"
                                type="password"
                                placeholder="12abF8"
                                id="password"
                                name="password"
                                controlClassName="form-control"
                                formDispatcher={formDispatcher}
                                formState={formState}
                            />
                        </CustomFormGroup>
                        <br />
                        <div className="d-flex justify-content-between">
                            <p className="text-muted fw-light">
                                <Link to={`/${ForgotPasswordRelPath.path}`}>
                                    Mot de passe oublié?
                                </Link>
                            </p>
                        </div>
                        <br />
                        <CustomButtonLoader
                            className="btn btn-lg"
                            text="Connexion"
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                        />
                        <p className="mt-5 mb-3 text-muted text-center">
                            Vous n&apos;avez pas de compte?{' '}
                            <Link to={`/${SignUpRelPath.path}`}>
                                S&apos;inscrire
                            </Link>
                        </p>
                    </CustomForm>
                </Col>
            </CustomRow>
        </div>
    );
}
