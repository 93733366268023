import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { IngredientSchema as uiValidator } from './validation';
/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    ingredient: '',
    codeIngredient: '',
    unite: {},
    selectedEmplacement: {},
    emplacements: []
};

/**
 * @description defaultDependencies de notre state
 */
const defaultDependencies = {
    fields: ['emplacement']
};

export function CreationIngredientForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, emplacementsPhysique, etatEmplacement } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            emplacementsPhysique: reduxState.emplacementsPhysique.map(
                (item) => ({
                    ...item,
                    designation: `${item.rayon}-${item.code}`
                })
            ),
            etatEmplacement: reduxState.etat.emplacementsPhysique.etat
        })
    );
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * @description récupérer les produits marchand
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EMPLACEMENT_PHYSIQUE_DEPOT,
                nodeName: DB_NODE.EMPLACEMENT_PHYSIQUE,
                etat: etatEmplacement
            }
        ]
    });

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const referenceAgence = emplacementsPhysique.find(
            (item) =>
                item.code === fields.selectedEmplacement.code &&
                fields.selectedEmplacement.designation.includes(item.rayon)
        );
        const emplacements = fields.emplacements.map((item) => ({
            code: item.code,
            referenceAgence: referenceAgence.agence.reference
        }));
        const payload = {
            newIngredient: {
                code: fields.codeIngredient,
                designation: fields.ingredient,
                unite: fields.unite.code
            },
            creator,
            emplacements
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_INGREDIENT,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };

    /**
     * L'action qui sera déclenchée pour l'ajout de nouveaux emplacements
     * @returns
     */
    const onAddEmplacement = () => {
        const { selectedEmplacement } = fields;
        if (!fields.selectedEmplacement) return;
        const ObjectFinded = emplacementsPhysique.find(
            (element) => element.id === selectedEmplacement.id
        );
        const data = {
            code: ObjectFinded.code,
            designation: selectedEmplacement.designation
        };
        if (!ObjectFinded) return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'emplacements',
                data,
                identifier: 'code'
            }
        });
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'emplacement',
                    etat: etatEmplacement
                }
            ]}
        >
            <CustomRow>
                <CustomLabel text="Ingredient*" className="col-sm-2" />
                <CustomInput
                    id="creationDepotFormIngredientId"
                    name="ingredient"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Nommer l'ingredient'"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    id="creationDepotFormCodeIngredientId"
                    name="codeIngredient"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Saisissez le code de l'ingredient"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    name="unite"
                    id="creationIngredientFormUniteId"
                    options={[
                        { code: 'Litre', designation: 'Litre' },
                        { code: 'gramme', designation: 'gramme' }
                    ]}
                    defaultOption="Selectionner l'unite"
                    label="Unité*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    name="selectedEmplacement"
                    id="creationIngredientFormIngredientId"
                    options={emplacementsPhysique}
                    defaultOption="Selectionner l'emplacement"
                    label="Emplacement*"
                    labelClassName="col-sm-2"
                    idProperty="id"
                    divClassName="col-sm-9"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={onAddEmplacement}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.emplacements.length}>
                <CustomRow>
                    <SimpleTable
                        formDispatcher={formDispatcher}
                        data={fields.emplacements}
                        listSelectedItemName="emplacements"
                        identifier="code"
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 1,
                                field: 'code',
                                displayName: 'Code'
                            },
                            {
                                position: 2,
                                field: 'designation',
                                displayName: 'Designation'
                            }
                        ]}
                    />
                </CustomRow>
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer l'ingrédient"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
