import { ReleveCompteForm } from './releve-compte';

export function ReleveCompteViewLayout() {
    // const [key, setKey] = useState('releve');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Relevé compte</h1>
            </div>
            {/* 
            <Tabs
                defaultActiveKey="report"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            > */}
            {/* <Tab eventKey="report" title="Report">
                    {key === 'report' && <RapportCompteForm />}
                </Tab> */}
            {/* <Tab eventKey="releve" title="Relevé"> */}
            <ReleveCompteForm />
            {/* </Tab> */}
            {/* </Tabs> */}
        </div>
    );
}
