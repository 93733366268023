import { GestionUser } from '../../../module';
import { MonCompteViewLayoutRelPath } from './gestion-user-rel-path';

/**
 * @desc Mon compte
 */

export const MonCompteViewLayoutPath = {
    ...MonCompteViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: GestionUser.MonCompteViewLayout,
    element: <GestionUser.MonCompteViewLayout />,
    code: [{ code: 'DEFAULT_WEB_USER_SERVICE' }]
};
