import { useIsLoadComplete } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CenteredSpinner,
    CustomAlert,
    CustomCollapse,
    CustomRow
} from '../../../components';

export function ListDetailApproStockProduitMateriel({ formState }) {
    const { id } = useParams();
    const { entity, etatEntity } = useSelector((reduxState) => ({
        entity: reduxState.detailApprovisionnementsStock.find(
            (item) => item?.idApprovisionnementStockProduitMateriel === id
        ),
        etatEntity: reduxState.etat.detailApprovisionnementsStock.etat
    }));
    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        etatChargement: etatEntity
    });

    const { form, fields } = formState;

    const renderContent = () => {
        if (!entity?.id && !isLoadFailed && !isLoadComplete) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return <CustomAlert error={form?.error} />;
        }
        return (
            <CustomCollapse isIn={fields.isUpdateView}>
                <CustomAlert error={form?.error} />
                <CustomRow>
                    <h1 className="lead">Détails</h1>
                </CustomRow>
                <CustomRow>
                    <span>Quantité: {entity?.quantite}</span>
                    <span>Produit: {entity?.produit?.designation}</span>
                </CustomRow>
            </CustomCollapse>
        );
    };
    return renderContent();
}
