import { CATEGORIE_MARCHAND } from '@napp-inc/jnapp-util';
import { CustomInput } from '../input';
import { CustomDropdown } from '../dropdown';
import { CustomAddButton } from '../button';
import { CustomCollapse } from '../container';
import { SimpleTable } from '../table';

/**
 * Select remplace le composant select de react-bootstrap et prends les mêmes attributs que le composant Natif
 * @param {Object} param0 object qui contient les props du composant:
 * @param {Array} param0.options liste des options
 * @param {Array} param0.optionsEmoney liste des options Emoney
 * @param {String} param0.id id de l'élément html
 * @param {Function} param0.formDispatcher fonction qui sera appelée lorsque l'utilisateur selectionnera une option
 * @param {String} param0.defaultOption valeur par défaut
 * @param {String} param0.name nom de la propriéte name du select
 * @param {String} param0.inputName nom de la propriéte name de l'input
 * @param {Object} param0.uiValidator schema de validation
 * @param {Object} param0.formState state du composant
 * @returns {React.Component}
 */
export function DynamicMonoSelectAndInput({
    options = [],
    optionsEmoney = [],
    defaultOption = 'Selectionner la devise',
    id,
    callback,
    name,
    formDispatcher,
    formState,
    uiValidator,
    inputName,
    code
}) {
    const { fields } = formState;

    let secondSelect = null;
    if (code === CATEGORIE_MARCHAND.AIRTIME.code) {
        secondSelect = (
            <div className="col">
                <CustomDropdown
                    name={name}
                    id={`${id}-select`}
                    defaultOption="Selectionner le type business"
                    options={options}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    propertyToDisplay="designation"
                />
            </div>
        );
    }
    if (code === CATEGORIE_MARCHAND.MOBILE_MONEY.code) {
        secondSelect = (
            <>
                <div className="col-2">Devise à appointer*</div>
                <div className="col">
                    <CustomDropdown
                        name={name}
                        id={`${id}-select-devise`}
                        defaultOption={defaultOption}
                        options={optionsEmoney}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        propertyToDisplay="designation"
                    />
                </div>
            </>
        );
    }
    return (
        <div className="row mx-2 g-1">
            {secondSelect}
            <div className="col">
                <CustomInput
                    id={`${id}-numero`}
                    name={inputName}
                    placeholder="Numéro Téléphone"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </div>
            <div className="col-sm-2">
                <CustomAddButton
                    disabled={
                        (code === CATEGORIE_MARCHAND.MOBILE_MONEY.code &&
                            (!fields?.selectedTypeBusiness?.code ||
                                !fields?.selectedTypeBusiness?.designation ||
                                !fields?.selectedTypeBusinessNumero)) ||
                        (code === CATEGORIE_MARCHAND?.AIRTIME?.code &&
                            (!fields?.selectedTypeBusiness?.code ||
                                !fields?.selectedTypeBusiness?.designation ||
                                !fields?.selectedTypeBusinessNumero))
                    }
                    list={options}
                    identifier="code"
                    formDispatcher={formDispatcher}
                    field="typeBusiness"
                    id={fields?.selectedTypeBusiness?.code}
                    size="25em"
                    iconClassName="text-success"
                    callback={callback}
                />
            </div>
            <div />
            <CustomCollapse isIn={!!fields.typeBusiness.length}>
                <SimpleTable
                    data={fields.typeBusiness}
                    identifier="id"
                    className="offset-sm-2 w-50"
                    isShowDropButton
                    listSelectedItemName="typeBusiness"
                    formDispatcher={formDispatcher}
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            displayName: 'Type',
                            field: 'code'
                        },
                        {
                            position: 2,
                            displayName: 'Numero',
                            field: 'numero'
                        }
                    ]}
                />
            </CustomCollapse>
        </div>
    );
}
