import { SimpleCard } from './simple-card';

/**
 * Cards dedicated for entree sorite of mouvements
 * @param {*} param0
 * @returns
 */
export function SimpleCardMouvement({
    entree,
    sortie,
    ouverture,
    fermeture,
    textAlternatif,
    textAlternatifEntree,
    textAlternatifSortie,
    textAlternatifOuverture,
    textAlternatifFermeture,
    devise,
    isCompact = false,
    nombreApresVirgule = 2
}) {
    return (
        <div className="row py-3">
            <div className="col-md-3 pb-1">
                <SimpleCard
                    text="Ouverture"
                    type="warning"
                    value={ouverture}
                    devise={devise}
                    textAlternatif={
                        textAlternatif || textAlternatifOuverture || ''
                    }
                    icon={1}
                    isCompact={isCompact}
                    nombreApresVirgule={nombreApresVirgule}
                />
            </div>
            <div className="col-md-3 pb-1">
                <SimpleCard
                    text="Entrée"
                    type="success"
                    icon={2}
                    value={entree}
                    devise={devise}
                    textAlternatif={
                        textAlternatif || textAlternatifEntree || ''
                    }
                    isCompact={isCompact}
                    nombreApresVirgule={nombreApresVirgule}
                />
            </div>
            <div className="col-md-3 pb-1">
                <SimpleCard
                    text="Sortie"
                    type="danger"
                    icon={3}
                    value={sortie}
                    devise={devise}
                    textAlternatif={
                        textAlternatif || textAlternatifSortie || ''
                    }
                    isCompact={isCompact}
                    nombreApresVirgule={nombreApresVirgule}
                />
            </div>
            <div className="col-md-3 pb-1">
                <SimpleCard
                    text="Fermeture"
                    type="primary"
                    icon={4}
                    value={fermeture}
                    devise={devise}
                    textAlternatif={
                        textAlternatif || textAlternatifFermeture || ''
                    }
                    isCompact={isCompact}
                    nombreApresVirgule={nombreApresVirgule}
                />
            </div>
        </div>
    );
}
