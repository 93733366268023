import { Str } from '@napp-inc/jnapp-util';
import {
    CustomAddButton,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../../components';

export function ProduitEmoney({
    formState,
    formDispatcher,
    uiValidator,
    devises,
    handleProduct
}) {
    const { fields } = formState;
    return (
        <>
            <small className="lead fs-5">Création de la monnaie</small>
            <CustomRow className="mt-3">
                <CustomInput
                    label="Désignation*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    isInputGroup={false}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    type="text"
                    name="monnaieDesignation"
                    placeholder="Designation Monnaie"
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    label="Code*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    isInputGroup={false}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    type="text"
                    name="monnaieCode"
                    placeholder="CODE MONNAIE"
                />
            </CustomRow>
            <small className="lead fs-5">
                Selectionner les devises attachées à la monnaie
            </small>
            <CustomRow className="mt-3">
                <CustomDropdown
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    name="selectedDevise"
                    id="selectedDeviseProduitEmoney"
                    defaultOption="Selectionner devise"
                    options={devises}
                    label="Devise*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-8"
                    onFormatString={Str.titleCase}
                />
                <div className="col-sm-2">
                    <CustomAddButton
                        callback={handleProduct}
                        // list={devises}
                        // identifier="code"
                        // formDispatcher={formDispatcher}
                        // field={stateFieldTargetedByAddOrRemove}
                        // id={fields?.selectedDevise?.code}
                        size="25em"
                    />
                </div>
            </CustomRow>
            <CustomCollapse isIn={!!fields.devises.length}>
                <SimpleTable
                    data={fields.devises}
                    identifier="code"
                    listSelectedItemName="devises"
                    formDispatcher={formDispatcher}
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            displayName: 'Code',
                            field: 'code'
                        },
                        {
                            position: 2,
                            displayName: 'Designation',
                            field: 'designation'
                        }
                    ]}
                />
            </CustomCollapse>
        </>
    );
}
