import * as yup from 'yup';
import {
    TYPE_CAISSE_GUICHET,
    TYPE_CAISSE_SECTORIELLE,
    TYPE_ORGANISATION,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import { CreationCaisse } from './creation-form';

const defaultFieldsKinDistribution = {
    selectedDevise: {},
    selectedAgent: {},
    selectedSuperDealer: {},
    designation: '',
    estEmoney: false
};

const defaultFieldsNapp = {
    selectedTypeEntite: {},
    selectedDevise: {},
    selectedAgent: {},
    selectedAgence: {}
};

const defaultFieldsOtherOrganisation = {
    selectedTypeCaisse: {},
    selectedDevise: {},
    selectedAgent: {},
    designation: ''
};

const setDefaultProperties = ({ type, isKinDB = false }) => {
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFieldsNapp,
            dependencies: { fields: ['monnaie', 'agences', 'users'] },
            elements: [],
            uiValidator: yup.object().shape({
                selectedTypeEntite: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedDevise: yup.object().shape({
                    id: yup.string().required(),
                    devise: yup.string().required()
                }),
                selectedAgence: yup.object().when('selectedTypeEntite', {
                    is: (selectedTypeEntite) =>
                        selectedTypeEntite?.code ===
                            TYPE_CAISSE_SECTORIELLE?.code ||
                        selectedTypeEntite?.code === TYPE_CAISSE_GUICHET?.code,
                    then: yup.object().shape({
                        code: yup.string().required(),
                        designation: yup.string().required()
                    }),
                    otherwise: yup.object().shape({
                        code: yup.string(),
                        designation: yup.string()
                    })
                }),
                selectedAgent: yup.object().shape({
                    uid: yup.string().required(),
                    designation: yup.string().required()
                })
            })
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            fields: defaultFieldsOtherOrganisation,
            dependencies: {
                fields: ['monnaie', 'users', 'typeCaisse']
            },
            elements: [],
            uiValidator: yup.object().shape({
                designation: yup.string().required(),
                selectedTypeCaisse: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedDevise: yup.object().shape({
                    id: yup.string().required(),
                    devise: yup.string().required()
                }),
                selectedAgent: yup.object().shape({
                    uid: yup.string().required(),
                    designation: yup.string().required()
                })
            })
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            fields: isKinDB
                ? defaultFieldsKinDistribution
                : defaultFieldsOtherOrganisation,
            dependencies: {
                fields: ['monnaie', 'users', 'typeCaisse']
            },
            elements: [],
            uiValidator: yup.object().shape({
                designation: yup.string().when('estEmoney', {
                    is: (estEmoney) => estEmoney === false,
                    then: yup.string().required(),
                    otherwise: yup.string()
                }),
                selectedTypeEntite: yup.object().shape({
                    code: yup.string(),
                    designation: yup.string()
                }),
                selectedSuperDealer: yup.object().when('estEmoney', {
                    is: true,
                    then: yup.object().shape({
                        code: yup.string().required(),
                        designation: yup.string().required()
                    }),
                    otherwise: yup.object().shape({
                        code: yup.string(),
                        designation: yup.string()
                    })
                }),
                selectedDevise: yup.object().shape({
                    id: yup.string().required(),
                    devise: yup.string().required()
                }),
                selectedAgence: yup.object().shape({
                    code: yup.string(),
                    designation: yup.string()
                }),
                selectedAgent: yup.object().shape({
                    uid: yup.string().required(),
                    designation: yup.string().required()
                }),
                estEmoney: yup.boolean()
            })
        };
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR)
        return {
            fields: defaultFieldsOtherOrganisation,
            dependencies: { fields: ['monnaie', 'users', 'typeCaisse'] },
            elements: [],
            uiValidator: yup.object().shape({
                designation: yup.string().required(),
                selectedTypeCaisse: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedDevise: yup.object().shape({
                    id: yup.string().required(),
                    devise: yup.string().required()
                }),
                selectedAgent: yup.object().shape({
                    uid: yup.string().required(),
                    designation: yup.string().required()
                })
            })
        };
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({})
    };
};

export function CreationCaisseForm() {
    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });

    const { fields, dependencies, elements, uiValidator } = typeEntite
        ? setDefaultProperties({
              type: typeEntite,
              isKinDB: !!organisation?.estKinDistribution
          })
        : {};

    /**
     * initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies: organisation?.estKinDistribution
            ? {
                  fields: dependencies?.fields
                      ?.filter((item) => item !== 'typeCaisse')
                      .concat('superDealer')
              }
            : dependencies,
        elements
    });

    const renderContent = () =>
        typeEntite ? (
            <CreationCaisse
                idToken={idToken}
                typeEntite={typeEntite}
                isKinDistribution={!!organisation?.estKinDistribution}
                uiValidator={uiValidator}
                initialState={initialState}
            />
        ) : null;
    return renderContent();
}
