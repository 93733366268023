export const schema = [
    {
        description: 'numeroReference',
        title: 'Reference'
    },
    {
        title: 'Revendeur',
        description: 'valeurTypeOrganisationUser.code'
    },
    {
        title: 'T-Number',
        description: 'valeurTypeOrganisationUser.tillNumber'
    },
    {
        title: 'Quantité',
        description: 'quantite'
    },
    {
        title: 'Montant',
        description: 'montant'
    },
    {
        title: 'Etat',
        description: 'etat'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    },
    {
        title: 'Dernière modification',
        description: 'dateDerniereModification'
    }
];
