import * as yup from 'yup';

/**
 * @description : Schema de validation Profil User Dedier
 */
export const ProfilUserDedierSchema = yup.object().shape({
    selectedTypeEntite: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedValeurTypeEntite: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedTypeOrganisation: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedNiveauOrganisation: yup.object().shape({
        code: yup.string(),
        designation: yup.number()
    }),
    selectedProfilUser: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedServiceUser: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    })
});
