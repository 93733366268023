export const schema = [
    {
        title: 'Prenom',
        description: 'valeurTypeProprietaire.prenom',
        className: 'border-bottom'
    },
    {
        title: 'Nom',
        description: 'valeurTypeProprietaire.nom',
        className: 'border-bottom'
    },
    {
        title: 'Limite',
        description: 'limit',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
