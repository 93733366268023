import { submitPostAction } from '@napp-inc/jnapp-hook';
import { ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper
} from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

/**
 * @Description : Si taux sollicité, alors ENCOURS-ATTENTE_VALIDATION-VALIDER
 * Sinon ENCOURS-VALIDER
 */

export function DetailChangeForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'operationChange' });

    const { form, fields } = formState;
    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payload = {
            idChangeEspece: entity.id,
            etat
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CHANGE_ESPECE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.OPERATION_CHANGE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_CHANGE_ESPECE_BY_ID}
            nodeName={REDUX_NODE_NAME.OPERATION_CHANGE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Change detail"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <CustomRow>
                <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                    <CustomConfirmButton
                        type="button"
                        text="Annuler"
                        className="btn-danger"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_ANNULER
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation Annulation"
                    />
                </CustomCol>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Mettre en attente"
                            className="btn-success"
                            confirmOnClick={() =>
                                updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                            }
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours ===
                                ETAT.ETAT_ATTENTE_VALIDATION
                            }
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                    }
                >
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
