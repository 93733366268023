import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    TYPE_COMPTE_EXTERNE,
    PAYS,
    ETAT,
    COMPENSATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomConfirmButton,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { CompteExterneBancaireSchema as uiValidator } from './validation';
import { REDUX_NODE_NAME } from '../../../../redux';

const defaultFields = {
    intituleCompte: '',
    numeroCompte: '',
    selectedType: {},
    selectedPays: {},
    selectedBanque: {},
    selectedDevise: {},
    nom: '',
    postnom: '',
    prenom: '',
    email: '',
    soldeCritique: 0,
    soldeMedian: 0,
    soldeSecurite: 0
};

export function CreationCompteBancaireForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        institutionsFinanciere,
        etatInstitutionFinanciere,
        monnaiesLocale,
        etatMonnaiesLocale
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionFinanciere: reduxState.etat.institutionsFinanciere.etat,
        monnaiesLocale: reduxState.monnaiesLocale.filter(
            (item) =>
                item.etat === ETAT.ETAT_VALIDER || item.estReference === true
        ),
        etatMonnaiesLocale: reduxState.etat.monnaiesLocale.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionFinanciere
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaiesLocale
            }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const {
            nom,
            postnom,
            prenom,
            email,
            selectedBanque,
            numeroCompte,
            intituleCompte,
            selectedDevise,
            selectedType,
            selectedPays
        } = fields;

        const payloadToAdd = {};
        if (fields.selectedType.code === COMPENSATION.code) {
            payloadToAdd.soldeCritique = fields.soldeCritique;
            payloadToAdd.soldeMedian = fields.soldeMedian;
            payloadToAdd.soldeSecurite = fields.soldeSecurite;
        }
        const payload = {
            newCompte: {
                institutionFinanciere: {
                    code: selectedBanque.code,
                    designation: selectedBanque.designation
                },
                libelle: intituleCompte,
                numero: numeroCompte,
                devise: selectedDevise.devise,
                typeCompteExterne: selectedType.code,
                ...payloadToAdd
            },
            pays: {
                code: selectedPays.code,
                designation: selectedPays.designation
            },
            gestionnaire: {
                nom,
                prenom,
                postnom,
                email
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_COMPTE_PARALLELE,
                reduxNodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE,
                payload,
                fields,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Intitulé*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez l'intitulé du compte"
                    formDispatcher={formDispatcher}
                    name="intituleCompte"
                    id="intituleCompteId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={TYPE_COMPTE_EXTERNE}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le type"
                    label="Type*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedType"
                    id="selectedTypeId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Numéro de compte*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez le numero de compte"
                    formDispatcher={formDispatcher}
                    name="numeroCompte"
                    id="numeroCompteId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    idProperty="id"
                    propertyToDisplay="devise"
                    options={monnaiesLocale}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la devise"
                    label="Devise*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedDevise"
                    id="selectedDeviseId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedType.code === COMPENSATION.code
                }
            >
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Solde Critique*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        type="number"
                        placeholder="Saisir solde critique"
                        name="soldeCritique"
                        formDispatcher={formDispatcher}
                        id="idSoldeCritique"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Solde Médian*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        type="number"
                        placeholder="Saisir solde médian"
                        name="soldeMedian"
                        formDispatcher={formDispatcher}
                        id="idSoldeMedian"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Solde Sécurité*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        type="number"
                        placeholder="Saisir solde sécurité"
                        name="soldeSecurite"
                        formDispatcher={formDispatcher}
                        id="idSoldeSecurite"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomDropdown
                    options={PAYS}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le pays"
                    label="Pays*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedPays"
                    id="selectedPaysId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={institutionsFinanciere}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la banque"
                    label="Banque*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedBanque"
                    id="selectedBanqueId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Nom*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Nom du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="nom"
                    id="nomGestionnaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Postnom*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Postnom du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="postnom"
                    id="postnomGestionnaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Prénom*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Prénom du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="prenom"
                    id="prenomGestionnaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Email*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Email du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="email"
                    type="email"
                    id="emailGestionnaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomConfirmButton
                    type="button"
                    backdrop="static"
                    text="Valider"
                    className="btn-primary btn mt-3 ms-2 w-25"
                    confirmOnClick={handleSubmit}
                    disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    isActionEncours={
                        form.status === FORM_CONST.CREATION_EN_COURS
                    }
                />
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
