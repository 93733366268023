import * as yup from 'yup';

/**
 * @description : Schema de validation directions création form
 */
export const DirectionSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required(),
    typeEntite: yup.string().required(),
    estNapp: yup.boolean()
});
