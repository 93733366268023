import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/napp-custom.scss';
import { signOut, onIdTokenChanged } from 'firebase/auth';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ConfigureStore, {
    GENERIC_ITEM_ACTIONS_CREATOR
} from '@napp-inc/jnapp-redux';
import {
    Array,
    DB_NODE,
    FILTER_OPERATOR,
    FILTER_TYPE,
    FORM_CONST,
    UserSchema
} from '@napp-inc/jnapp-util';
import { analytics, setUserId } from '@napp-inc/jnapp-firebase';
import { REDUX_NODE_NAME } from './redux';
import { URL_CONST } from './util';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { auth } from './firebase';
import { NappErrorBoundary } from './components';
import { BaseLayout } from './layout/base-layout';
import { StateContextWrapper } from './context';
import { reducers } from './redux/redux-nodes';
import fakesService from './components/menu/fake-service';
import defaultsUserService from './components/menu/default-user-service';

const { store } = ConfigureStore(reducers);

const rootElement = document.getElementById('root');

function RootComponent() {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <NappErrorBoundary>
                        <StateContextWrapper>
                            <BaseLayout />
                        </StateContextWrapper>
                    </NappErrorBoundary>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );
}
onIdTokenChanged(auth, (user) => {
    if (user) {
        const etatNappUser = store.getState()?.etat?.nappUser?.etat;
        store.dispatch(
            GENERIC_ITEM_ACTIONS_CREATOR.setItem(
                user,
                REDUX_NODE_NAME.FIREBASE_USER
            )
        );
        if (etatNappUser === FORM_CONST.STATUT.ETAT_INITIAL) {
            setUserId(analytics, user.uid);
            store.dispatch(
                GENERIC_ITEM_ACTIONS_CREATOR.setItem(
                    { name: 'light' },
                    REDUX_NODE_NAME.THEME
                )
            );
            user.getIdToken().then((idToken) => {
                store.dispatch(
                    GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                        [...defaultsUserService],
                        REDUX_NODE_NAME.USER_SERVICE
                    )
                );
                if (process.env.NODE_ENV === 'development') {
                    store.dispatch(
                        GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                            [...fakesService],
                            REDUX_NODE_NAME.USER_SERVICE
                        )
                    );
                }
                store.dispatch(
                    GENERIC_ITEM_ACTIONS_CREATOR.getCustomObjetByFunctionName({
                        idToken,
                        functionName: URL_CONST.GET_USER_V2,
                        estListMonnaie: true,
                        // estListCompte: true,
                        nodeList: [
                            {
                                nodeName: REDUX_NODE_NAME.ORAGANISATION,
                                isSet: true,
                                propertyName: 'organisation'
                            },
                            {
                                nodeName: REDUX_NODE_NAME.DEVISE_REFERENCE,
                                isSet: true,
                                propertyName: 'devise'
                            },
                            {
                                nodeName: REDUX_NODE_NAME.NAPP_USER,
                                isSet: true,
                                propertyName: 'nappUser',
                                reduxValidator: UserSchema
                            },
                            {
                                nodeName: REDUX_NODE_NAME.USER_SERVICE,
                                propertyName: 'listSysService'
                            },
                            {
                                nodeName: REDUX_NODE_NAME.COMPTE_LIMITE_CREDIT,
                                propertyName: 'limitCredit',
                                isSet: true
                            },
                            {
                                nodeName: DB_NODE.TAUX,
                                propertyName: 'taux'
                            },
                            {
                                nodeName: REDUX_NODE_NAME.ALL_COUPURE_DEVISE,
                                propertyName: 'coupures',
                                formater: (coupures) => {
                                    if (!coupures) return [];
                                    const grouped = Array.groupByProperty({
                                        array: coupures,
                                        property: 'devise'
                                    });
                                    const formated = [];
                                    // eslint-disable-next-line no-restricted-syntax, guard-for-in
                                    for (const devise in grouped) {
                                        formated.push({
                                            devise,
                                            code: devise,
                                            coupures: Array.tri({
                                                data: grouped[devise],
                                                property: 'valeur',
                                                order: 'asc'
                                            })
                                        });
                                    }
                                    return formated;
                                }
                            }
                        ]
                    })
                );
            });
            store.dispatch(
                GENERIC_ITEM_ACTIONS_CREATOR.startListeningDocument({
                    path: DB_NODE.USER,
                    id: user.uid,
                    nodeName: REDUX_NODE_NAME.NAPP_USER,
                    // reduxValidator: UserSchema
                    onSuccess: (userBack) => {
                        if (userBack.estDesactive) {
                            signOut(auth).then(() => {
                                store.dispatch(
                                    GENERIC_ITEM_ACTIONS_CREATOR.resetApp()
                                );
                            });
                        }
                    }
                })
            );
            store.dispatch(
                GENERIC_ITEM_ACTIONS_CREATOR.startListening({
                    filters: [
                        {
                            type: FILTER_TYPE.WHERE,
                            operator: FILTER_OPERATOR.EQUAL,
                            property: 'owner',
                            value: user.uid
                        },
                        {
                            type: FILTER_TYPE.ORDER_BY,
                            property: 'dateDerniereModification'
                        },
                        {
                            type: FILTER_TYPE.START_AT,
                            isTimestampNowValue: true
                        }
                    ],
                    path: DB_NODE.USER_SYS_SERVICE,
                    nodeName: REDUX_NODE_NAME.USER_SERVICE
                })
            );
        }
    } else {
        store.dispatch(
            GENERIC_ITEM_ACTIONS_CREATOR.resetItem(
                REDUX_NODE_NAME.FIREBASE_USER
            )
        );
        store.dispatch(GENERIC_ITEM_ACTIONS_CREATOR.detachAllListeners());
        store.dispatch(
            GENERIC_ITEM_ACTIONS_CREATOR.setItem(
                { name: 'dark' },
                REDUX_NODE_NAME.THEME
            )
        );
    }
    ReactDOM.render(<RootComponent user={user} />, rootElement);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
