import { useCallback } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    DB_NODE,
    TYPE_ORGANISATION
    // CODE_SERVICE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST, checkTypeCaisse, formaterCaisses } from '../../../../util';

export function CreationTransfertExterneForm({
    idToken,
    initialState,
    uiValidator,
    cfPayload,
    cfCondition,
    typeEntite
}) {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        superDealerKinDB,
        etatSuperDealerKinDB,
        allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB,
        caisses,
        etatCaisses,
        allCoupures,
        monnaiesLocales,
        etatMonnaieLocale
    } = useSelector((reduxState) => ({
        superDealerKinDB: reduxState.allSuperDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.allSuperDealerKinDB.etat,
        allCashCollecteurKinDB: reduxState.allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB: reduxState.etat.allCashCollecteurKinDB.etat,
        caisses:
            formaterCaisses(reduxState?.[cfPayload.caisseReduxProperty]) || [],
        etatCaisses: reduxState?.etat?.[cfPayload.caisseReduxProperty]?.etat,
        allCoupures: reduxState.allCoupures,
        monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            code: item.code || item.devise
        })),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat
    }));

    /**
     * Common form processes
     */
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: cfPayload?.superDealer,
                isCondition: !!cfCondition?.isAll
            },
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_CASH_COLLECTEUR_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_CASH_COLLECTEUR_KIN_DB,
                etat: etatAllCashCollecteurKinDB
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: cfPayload?.caisseNodeName,
                payload: cfPayload?.caisses,
                etat: etatCaisses,
                isCondition: !!cfPayload.caisseNodeName
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            }
        ]
    });

    const { fields, form } = formState;

    const typeCaisse = useCallback(
        () =>
            checkTypeCaisse({
                element: fields.selectedCaisseSource,
                list: caisses
            }),
        [caisses, fields.selectedCaisseSource]
    );

    const getCoupuresMatching = () =>
        allCoupures
            .find(
                (coupure) =>
                    coupure?.devise ===
                    caisses.find(
                        (caisse) =>
                            fields.selectedCaisseSource?.id === caisse?.id
                    )?.devise
            )
            ?.coupures.sort(
                (a, b) => Number(a?.valeur || 0) - Number(b?.valeur || 0)
            ) || [];

    const handleSubmit = (e) => {
        e.preventDefault();
        const [firstElement] = getCoupuresMatching();
        if (form.status === FORM_CONST.CREATION_ECHEC) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    status: FORM_CONST.STATUT.ETAT_INITIAL
                }
            });
        }
        if (!firstElement?.valeur) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Impossible de savoir si vous pouvez utiliser ce montant pour cette caisse`,
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        if (fields.montant % firstElement.valeur !== 0) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Veuillez revoir le montant renseigné`,
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        if (fields.estDeviseSollicite && fields.selectMonnaieSortie?.devise) {
            const findCaisse = caisses.find(
                (item) => item.id === fields.selectedCaisseSource?.id
            );
            if (findCaisse?.devise === fields.selectMonnaieSortie?.devise) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `Vous ne pouvez pas solliciter dans la même dévise`,
                        status: FORM_CONST.CREATION_ECHEC
                    }
                });
                return;
            }
        }
        const payloadToIn = {};
        const payloadToOut = {};
        if (fields.selectedTypeOrganisation?.code) {
            payloadToIn.valeurTypeOrganisationB = {
                code:
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.SUPER_DEALER
                        ? fields.selectedSuperDealer.code
                        : fields.selectedCashCollecteur.code
            };
        }
        if (fields.estDeviseSollicite) {
            payloadToIn.deviseSollicite = fields.selectMonnaieSortie?.devise;
        }
        if (!!typeCaisse()?.estEmoney && !fields.numeroReference) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Veuillez saisir le numéro de référence`,
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        if (!!typeCaisse()?.estEmoney && !!fields.numeroReference) {
            payloadToOut.numeroReference = fields.numeroReference;
        }
        const payload = {
            newTransfert: {
                caisseA: {
                    reference: fields.selectedCaisseSource.id
                },
                typeOrganisationB:
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.SUPER_DEALER
                        ? TYPE_ORGANISATION.SUPER_DEALER
                        : TYPE_ORGANISATION.CASH_COLLECTEUR,
                ...payloadToIn
            },
            montant: fields.montant,
            ...payloadToOut
        };
        if (cfCondition?.isGlobal && fields.selectedSuperDealerIsAll.code) {
            payload.newTransfert.codeSuperDealer =
                fields.selectedSuperDealerIsAll.code;
        }
        if (fields.selectedTypeOrganisation.code === TYPE_ORGANISATION.NAPP) {
            payload.newTransfert.typeOrganisationB = TYPE_ORGANISATION.NAPP;
            payload.newTransfert.valeurTypeOrganisationB = {
                code: TYPE_ORGANISATION.NAPP
            };
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_TRANSFERT_CAISSE_KINDB,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TRANSFERT_KINDB,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const filterCaisses = useCallback(() => {
        if (
            fields.selectedSuperDealerIsAll.code &&
            !!cfCondition?.isGlobal &&
            fields.selectedSuperDealerIsAll.code !== TYPE_ORGANISATION.NAPP
        ) {
            return caisses.filter(
                (item) =>
                    item?.superDealer?.code ===
                    fields.selectedSuperDealerIsAll.code
            );
        }
        if (
            fields.selectedSuperDealerIsAll.code &&
            fields.selectedSuperDealerIsAll.code === TYPE_ORGANISATION.NAPP
        ) {
            return caisses.filter(
                (item) =>
                    item?.typeEntite === fields.selectedSuperDealerIsAll.code
            );
        }
        return caisses;
    }, [caisses, cfCondition.isGlobal, fields.selectedSuperDealerIsAll.code]);

    const getAllOrganisation = useCallback(() => {
        if (typeEntite === TYPE_ORGANISATION.NAPP && !!cfCondition?.isGlobal) {
            const sd = superDealerKinDB.filter(
                (item) => item.code !== typeEntite
            );
            const cc = allCashCollecteurKinDB.filter(
                (item) => item.code !== typeEntite
            );
            return [
                {
                    code: TYPE_ORGANISATION.NAPP,
                    designation: TYPE_ORGANISATION.NAPP
                },
                ...sd,
                ...cc
            ];
        }
        return [];
    }, [
        allCashCollecteurKinDB,
        cfCondition.isGlobal,
        superDealerKinDB,
        typeEntite
    ]);

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'caisses',
                    etat: etatCaisses,
                    isCondition: !!cfPayload.caisseNodeName
                },
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB,
                    isCondition: !!cfCondition?.isAll
                },
                {
                    dependency: 'cashCollecteur',
                    etat: etatAllCashCollecteurKinDB
                },
                {
                    dependency: 'monnaieLocale',
                    etat: etatMonnaieLocale
                }
            ]}
        >
            <CustomRow isShouldBeRendered={!!cfCondition.isGlobal}>
                <CustomDropdown
                    options={getAllOrganisation({
                        superDealer: superDealerKinDB,
                        cashColleteur: allCashCollecteurKinDB
                    })}
                    disabled={
                        !getAllOrganisation({
                            superDealer: superDealerKinDB,
                            cashColleteur: allCashCollecteurKinDB
                        })?.length
                    }
                    defaultOption="Selectionner organisation..."
                    label="Organisation Source* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedSuperDealerIsAll"
                    id="selectedSuperDealerIsAllCreationTransfertInstitutionnelId"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={filterCaisses()}
                    label="Caisse Source* :"
                    defaultOption="Sélectionner caisse Source"
                    disabled={!filterCaisses()?.length}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCaisseSource"
                    id="selectedCaisseSourceCreationTransfertInstitutionnelId"
                    formState={formState}
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!!typeCaisse()?.estEmoney}>
                <CustomInput
                    type="text"
                    label="Numéro Référence* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Numéro Référence"
                    formDispatcher={formDispatcher}
                    name="numeroReference"
                    id="creationNumeroReferenceCreationTransfertInterInstitutionnelId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomSwitchButton
                id="estDeviseSolliciteCreationTransfertInstitutionnelId"
                text="Sollicité Devise"
                formDispatcher={formDispatcher}
                formState={formState}
                value={fields.isTransfertAutomatique}
                name="estDeviseSollicite"
                divClassName="col-md-8 mb-3"
            />
            <CustomRow isShouldBeRendered={fields.estDeviseSollicite}>
                <CustomDropdown
                    options={monnaiesLocales}
                    label="Monnaie Reception* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectMonnaieSortie"
                    id="selectMonnaieSortieCreationTransfertInstitutionnelId"
                    formState={formState}
                    propertyToDisplay="devise"
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        {
                            code: TYPE_ORGANISATION.CASH_COLLECTEUR,
                            designation: 'Cash Collecteur'
                        },
                        {
                            code: TYPE_ORGANISATION.SUPER_DEALER,
                            designation: 'Super Dealer'
                        },
                        {
                            code: TYPE_ORGANISATION.NAPP,
                            designation: TYPE_ORGANISATION.NAPP
                        }
                    ]}
                    label="Organisation Bénéficiaire* :"
                    defaultOption="Sélectionner Type Organisation"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeOrganisation"
                    id="selectedTypeOrganisationCreationTransfertInstitutionnelId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.SUPER_DEALER
                }
            >
                <CustomDropdown
                    options={superDealerKinDB.filter(
                        (item) => item.code !== typeEntite
                    )}
                    disabled={
                        !superDealerKinDB.filter(
                            (item) => item.code !== typeEntite
                        ).length
                    }
                    defaultOption="Selectionner Bénéficiaire"
                    label="Bénéficiaire* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedSuperDealer"
                    id="selectedSuperDealerCreationTransfertInstitutionnelId"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.CASH_COLLECTEUR
                }
            >
                <CustomDropdown
                    options={allCashCollecteurKinDB.filter(
                        (item) => item.code !== typeEntite
                    )}
                    disabled={
                        !allCashCollecteurKinDB.filter(
                            (item) => item.code !== typeEntite
                        )?.length
                    }
                    defaultOption="Selectionner Bénéficiaire"
                    label="Bénéficiaire* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCashCollecteur"
                    id="selectedCashCollecteurCreationTransfertInstitutionnelId"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="creationChangeCompteMontantId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
