import { FORM_CONST } from '@napp-inc/jnapp-util';
import { TiDeleteOutline } from 'react-icons/ti';

/**
 * Button avec une icone de suppression,
 * permet de supprimer un élément dans une liste d'un formulaire
 * @param {Object} param0 object with the following properties:
 * @param {String} param0.id id de l'élément à supprimer
 * @param {String} param0.field nom de la liste dans les champps fields du state du form
 * @param {Function} param0.formDispatcher fonction de dispatch du formulaire
 * @param {String} param0.identifier propriété utilisée pour filtrer dans la liste, par défaut c'est l'id
 * @param {Object} param0.style style du bouton
 * @param {String} param0.className classe du bouton
 * @param {Boolean} param0.disabled si le bouton est clicable ou non
 * @param {String} param0.iconClassName classe de l'icone
 * @returns {React.Component}
 */
export function CustomDropButton({
    id,
    field,
    style = { border: 'none', background: 'none' },
    formDispatcher,
    identifier = 'id',
    className = '',
    disabled = false,
    iconClassName = 'text-danger'
}) {
    const handleDrop = () => {
        formDispatcher({
            type: FORM_CONST.REMOVE_ITEM,
            payload: {
                identifier,
                field,
                data: { [identifier]: id }
            }
        });
    };
    return (
        <button
            disabled={disabled}
            type="button"
            className={className}
            style={style}
            onClick={() => handleDrop()}
            size="md"
        >
            <span hidden={false}>
                <TiDeleteOutline className={`icon-custom ${iconClassName}`} />
            </span>
        </button>
    );
}
