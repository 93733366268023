/* eslint-disable jsx-a11y/label-has-associated-control */
import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    formInitialState,
    ProduitSchema
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomRow,
    CustomInputFile
} from '../../../components';
import { CustomTextAreaInput } from '../../../components/input';
import { CreationArticleSchema, URL_CONST } from '../../../util';

const defaultFields = {
    code: '',
    designation: '',
    rayon: '',
    parent: {},
    type: {},
    emplacementPhysique: {},
    prixUnitaire: 1,
    images: [],
    documentPresentation: '',
    description: '',
    urlVideo: '',
    temperature: '',
    etat: '',
    longueur: '',
    largeur: '',
    hauteur: '',
    poids: '',
    estInflammable: false,
    estFragile: false,
    estEmpilable: false,
    longueurSupplementaire: '',
    largeurSupplementaire: '',
    hauteurSupplementaire: '',
    delaiConsignation: '',
    estLivrerDomicile: false,
    positions: []
};

// FIXME: function à compléter

export function CreationArticleFlashForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        files: ['images', 'documentPresentation']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    // useFetchAndListening produit marchand airtime
    //
    const { fields, form } = formState;
    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            name: fields.designation,
            code: fields.code
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_FAKE,
                payload,
                uiValidator: CreationArticleSchema,
                fields,
                reduxNodeName: DB_NODE.PRODUIT,
                reduxDispatcher,
                reduxValidator: ProduitSchema
            })
        );
    };
    return (
        <>
            <CustomAlert error="Formulaire provisoire pour la creation d'acticles flash" />
            <CustomForm onSubmit={handleSubmit} className="py-2">
                <CustomRow>
                    <CustomInput
                        rows={2}
                        isInputGroup={false}
                        label="Code*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Code du produit"
                        formDispatcher={formDispatcher}
                        name="code"
                        id="codeProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Désignation*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="désignation du produit"
                        formDispatcher={formDispatcher}
                        value={fields.designation}
                        name="designation"
                        id="designationProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { value: 'Parent 1', code: 'parent1' },
                            { value: 'Parent 2', code: 'parent2' },
                            { value: 'Parent 3', code: 'parent3' }
                        ]}
                        propertyToDisplay="value"
                        label="Produit Parent*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Sélectionner un rayon"
                        formDispatcher={formDispatcher}
                        name="parent"
                        id="rayonProduit"
                        uiValidator={ProduitSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { value: 'Rayon 1', code: 'rayon1' },
                            { value: 'Rayon 2', code: 'rayon2' },
                            { value: 'Rayon 3', code: 'rayon3' }
                        ]}
                        propertyToDisplay="value"
                        label="Rayon*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Sélectionner un rayon"
                        formDispatcher={formDispatcher}
                        name="rayon"
                        id="rayonProduit"
                        uiValidator={ProduitSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { value: 'Colonne 1', code: 'colonne1' },
                            { value: 'Colonne 2', code: 'colonne2' },
                            { value: 'Colonne 3', code: 'colonne3' }
                        ]}
                        propertyToDisplay="value"
                        label="Colonne*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Sélectionner une colonne"
                        formDispatcher={formDispatcher}
                        name="type"
                        id="typeProduit"
                        uiValidator={ProduitSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { value: 'Emplacement 1', code: 'EMPLACEMENT1' },
                            { value: 'Emplacement 2', code: 'EMPLACEMENT2' },
                            { value: 'Emplacement 3', code: 'EMPLACEMENT3' }
                        ]}
                        propertyToDisplay="value"
                        label="Emplacement physique*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Sélectionner un emplacement"
                        formDispatcher={formDispatcher}
                        name="emplacementPhysique"
                        id="emplacementPhysiqueProduit"
                        uiValidator={ProduitSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Prix*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Prix unitaire du produit"
                        formDispatcher={formDispatcher}
                        name="prixUnitaire"
                        type="number"
                        id="prixUnitaireProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomTextAreaInput
                        isInputGroup={false}
                        label="Description"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Description du produit"
                        formDispatcher={formDispatcher}
                        name="description"
                        id="descriptionProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                        rows={5}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Url vidéo"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Url video présentation produit"
                        formDispatcher={formDispatcher}
                        name="urlVideo"
                        id="urlVideoProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInputFile
                        id="documentPresentationProduit"
                        name="documentPresentation"
                        label="Document de presentation"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        formState={formState}
                        formDispatcher={formDispatcher}
                        uiValidator={CreationArticleSchema}
                        accept=".doc, .docx, .pdf"
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInputFile
                        id="imagesProduit"
                        name="images"
                        label="Images du produit"
                        divClassName="col-sm-10"
                        labelClassName="col-sm-2"
                        formState={formState}
                        formDispatcher={formDispatcher}
                        uiValidator={CreationArticleSchema}
                        accept=".jpg, .jpeg, .png"
                        multiple
                    />
                </CustomRow>
                <div className="row">
                    <div className="col-3">
                        <CustomButtonLoader
                            className="btn-success"
                            onClick={handleSubmit}
                            text="Sauvegarder"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                        />
                    </div>
                </div>
            </CustomForm>
        </>
    );
}
