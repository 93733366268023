/**
 * Rend le message d'erreur en cas de problème de validation
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {String}  param0.className classe CSS
 * @param {Object} param0.children le children du composant
 * @returns {React.Component}
 */
export function InputFeedback({ children, className }) {
    return <div className={`invalid-feedback ${className}`}>{children}</div>;
}
