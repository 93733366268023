import { LIST_TYPE_SERVICE_GENERATEUR } from '@napp-inc/jnapp-util';

export const mapper = [
    {
        position: 0,
        type: 'text',
        id: 'codeCreationServiceGenerateur',
        name: 'code',
        placeholder: 'Saisissez le code',
        label: 'Code*',
        isInputGroup: false
    },
    {
        position: 1,
        type: 'text',
        id: 'designationCreationServiceGenerateur',
        name: 'designation',
        placeholder: 'Saisissez la designation',
        label: 'Désignation*',
        isInputGroup: false
    },
    {
        position: 2,
        type: 'dropdown',
        id: 'selectedTypeCreationServiceGenerateur',
        name: 'selectedType',
        label: 'Type*',
        options: LIST_TYPE_SERVICE_GENERATEUR,
        defaultOption: 'Sélectionnez un type'
    }
];
