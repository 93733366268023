import { intParser } from '@napp-inc/jnapp-util';
import { useEffect, useState } from 'react';

/**
 * Permet de définir le chemin où se rendre en cas de redirection
 * Son utilisation premmière est pour les formulaires de création ou de détail qui doivent renvoyer sur la liste
 * @param {String} pathname current location
 * @param {Number} stepBack nombre d'éléments à oter de l'url
 * @returns {Objet} retourne un objet avec la propriété destination: string
 */
export const useWhereToGo = (pathname, stepBack = 1) => {
    const [destination, setDestination] = useState('');
    useEffect(() => {
        if (
            pathname &&
            typeof pathname === 'string' &&
            intParser(stepBack) > 0
        ) {
            const splittedPath = pathname.split('/');
            for (let i = 0; i < stepBack; i += 1) {
                splittedPath.pop();
            }
            const joinedPath = splittedPath.join('/');
            setDestination(joinedPath);
        }
    }, [pathname, stepBack]);
    return {
        destination
    };
};
