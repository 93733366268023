export const schema = [
    {
        title: 'Devise',
        description: 'code',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
