import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST, DB_NODE } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomRow,
    DynamicFormMounted,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { fieldsMapper } from './form-fields';
import { ServiceSchema as uiValidator } from './validation';

/**
 * @desc defaultFields
 */
const defaultFields = {
    code: '',
    designation: '',
    list: []
};

export function CreationServiceForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const onAddService = () => {
        if (!fields.code || !fields.designation) return;
        const data = {
            code: fields.code,
            designation: fields.designation
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                data,
                identifier: 'code',
                field: 'list'
            }
        });
        ['code', 'designation'].forEach((field) => {
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field,
                    data: ''
                }
            });
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if ((!fields.code || !fields.designation) && !fields.list.length) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Vérifiez les valeurs renseignées',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        const payload = {
            creator,
            list: fields.list
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_LIST_SYS_SERVICE,
                payload,
                fields,
                reduxDispatcher,
                reduxNodeName: DB_NODE.SYST_SERVICE,
                uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <DynamicFormMounted
                formDispatcher={formDispatcher}
                formState={formState}
                uiValidator={uiValidator}
                fieldsMapper={fieldsMapper}
            />
            <CustomRow className="mx-1">
                <div className="offset-md-11 col-1">
                    <CustomButtonLoader
                        type="button"
                        className="btn-primary"
                        onClick={onAddService}
                        text="Ajouter"
                    />
                </div>
            </CustomRow>
            <CustomCollapse isIn={!!fields.list.length}>
                <SimpleTable
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    listSelectedItemName="list"
                    identifier="code"
                    formDispatcher={formDispatcher}
                    data={fields.list}
                    mapper={[
                        {
                            position: 1,
                            field: 'code',
                            displayName: 'Code'
                        },
                        {
                            position: 2,
                            field: 'designation',
                            displayName: 'Designation'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        text="Enregistrer"
                        isActionEncours={
                            form.status === FORM_CONST.CREATION_EN_COURS
                        }
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
