import { FORM_CONST, ENTITE_ADMINISTRATIVE_NIVEAU } from '@napp-inc/jnapp-util';
import { CustomAlert } from '../alert';
import { CustomAddRemoveButton } from '../button';
import { CustomCollapse, CustomRow } from '../container';
import { CustomDropdown, CustomDropdownEditable } from '../dropdown';
import { SimpleTable } from '../table';

// FIXME : A faire, fetcher user pour appliquer des modifications telles que l'activation et la désativation

/**
 * Composant pour la selection des zones
 * @param {Object} param0 Object containing component's props
 * @param {Object} param0.formState Objet representant le state du formulaire
 * @param {Function} param0.formDispatcher Function qui dispatch une action sur le state du formulaire
 * @param {Object} param0.uiValidator Objet representant le schema de validation du formulaire
 * @param {Function} param0.callback Function qui sera appelee lorsque l'utilisateur arrete de saisir sur le formulaire
 * @param {Array} param0.entitesAdministrative Array contenant les entites administrative
 * @returns {JSX.Element}
 */
export function SelectZone({
    formState,
    formDispatcher,
    uiValidator,
    callback,
    entitesAdministrative,
    stateProperty = 'selectedZoneDropDown',
    idProperty = 'reference',
    propertyToDisplay = 'designation',
    adminLevelProperty = 'niveau',
    targetField = 'zones'
}) {
    const { fields, elements } = formState;
    const addReferenceZone = () => {
        if (
            !fields[stateProperty][idProperty] ||
            !fields[stateProperty][propertyToDisplay]
        ) {
            return;
        }
        const finded = elements?.zones?.value?.find(
            (item) =>
                Number(item[idProperty]) ===
                Number(fields[stateProperty][idProperty])
        );
        const valueToDisplay =
            fields[stateProperty][propertyToDisplay].split(' ');
        const data = {
            [idProperty]: fields[stateProperty][idProperty],
            [propertyToDisplay]: valueToDisplay.length
                ? valueToDisplay[0]
                : fields[stateProperty][propertyToDisplay],
            [adminLevelProperty]: finded[adminLevelProperty]
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: idProperty,
                field: targetField,
                data
            }
        });
    };

    return (
        <>
            <CustomRow className="pt-3">
                <CustomDropdownEditable
                    onKeyUp={callback}
                    label="Zones*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-9"
                    idProperty="reference"
                    displayName="designation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    defaultOption="Selectionner la zone"
                    options={[]}
                    name="selectedZone"
                    id="selectedZone"
                    selectionClassName="form-select-md"
                />
                {elements?.zones?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only"> </span>
                    </div>
                ) : null}
                {elements?.zones?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS ? (
                    <div className="mt-3 ">
                        <CustomAlert
                            successMessage={`Résulat de la recherche pour ${fields.preview}: ${entitesAdministrative.length}`}
                        />
                    </div>
                ) : null}
            </CustomRow>
            {elements?.zones?.value?.length ? (
                <CustomRow>
                    <CustomDropdown
                        options={elements?.zones?.value?.map((item) => ({
                            ...item,
                            [propertyToDisplay]: `${
                                item[propertyToDisplay]
                            } - ${
                                ENTITE_ADMINISTRATIVE_NIVEAU[
                                    item[adminLevelProperty]
                                ]
                                    ? ENTITE_ADMINISTRATIVE_NIVEAU[
                                          item[adminLevelProperty]
                                      ]
                                    : ''
                            }`
                        }))}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner zone"
                        divClassName="col-12 col-sm-10"
                        name={`${stateProperty}`}
                        id={`${stateProperty}Id`}
                        idProperty={idProperty}
                        propertyToDisplay={propertyToDisplay}
                        uiValidator={uiValidator}
                    />
                    <CustomAddRemoveButton
                        className="col-sm-2"
                        callback={addReferenceZone}
                    />
                </CustomRow>
            ) : null}
            <CustomCollapse isIn={!!fields?.zones}>
                <SimpleTable
                    identifier={idProperty}
                    data={fields?.zones.length ? fields?.zones : []}
                    listSelectedItemName={targetField}
                    formDispatcher={formDispatcher}
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            displayName: 'Code',
                            field: idProperty
                        },
                        {
                            position: 2,
                            displayName: 'Designation',
                            field: propertyToDisplay
                        },
                        {
                            position: 3,
                            displayName: 'Niveau',
                            field: adminLevelProperty
                        }
                    ]}
                />
            </CustomCollapse>
        </>
    );
}
