export const schema = [
    {
        title: 'Categorie',
        description: 'categorie'
    },
    {
        title: 'Rubrique',
        description: 'rubrique'
    },
    {
        title: 'Marchand',
        description: 'marchand.designation'
    },
    {
        title: 'Demandeur',
        description: 'valeurTypeOrganisation.designation'
    },
    {
        title: 'Etat',
        description: 'etat'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
