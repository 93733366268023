import { CONFIG_TECHNIQUE } from '@napp-inc/jnapp-util';
import {
    CustomAddRemoveButton,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../components';

export function ObjectTypeConfig({
    formState,
    formDispatcher,
    uiValidator,
    callback
}) {
    const { fields } = formState;
    const renderContent = () => (
        <>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Clé*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-4"
                    isInputGroup={false}
                    placeholder="Valeur de la clé"
                    formDispatcher={formDispatcher}
                    name="key"
                    id="keyId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Valeurs*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-4"
                    isInputGroup={false}
                    placeholder="Valeur de la clé"
                    formDispatcher={formDispatcher}
                    name="objectKey"
                    id="objectKeyId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    type="text"
                    divClassName="col-12 col-sm-4"
                    isInputGroup={false}
                    placeholder="Valeur de la clé"
                    formDispatcher={formDispatcher}
                    name="objectValue"
                    id="objectValueId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                {typeof callback === 'function' &&
                fields.selectedTypeValeur.code ===
                    CONFIG_TECHNIQUE.OBJECT.code ? (
                    <CustomAddRemoveButton
                        className="btn-success col-sm-2"
                        callback={callback}
                    />
                ) : null}
                {typeof callback === 'function' &&
                fields.objectValues.length &&
                fields.selectedTypeValeur.code ===
                    CONFIG_TECHNIQUE.OBJECT.code ? (
                    <CustomRow>
                        <SimpleTable
                            listSelectedItemName="objectValues"
                            identifier="id"
                            formDispatcher={formDispatcher}
                            data={fields.objectValues}
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    field: 'id',
                                    displayName: 'CLE'
                                },
                                {
                                    position: 2,
                                    field: 'value',
                                    displayName: 'VALEUR'
                                }
                            ]}
                        />
                    </CustomRow>
                ) : null}
            </CustomRow>
        </>
    );
    return renderContent();
}
