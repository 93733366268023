import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { RegularisationStockFormSchema as uiValidator } from './validation';

const defaultFields = {
    libelle: '',
    quantite: 0,
    selectedProduit: {},
    selectedImputation: {}
};

export function CreationRegularisationStockForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        imputationsStock,
        etatImputationStock,
        produits,
        etatProduit
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        imputationsStock: reduxState.imputationsStock,
        etatImputationStock: reduxState.etat.imputationsStock.etat,
        produits: reduxState.produits,
        etatProduit: reduxState.etat.produits.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_IMPUTATION_STOCK,
                nodeName: DB_NODE.IMPUTATION_STOCK,
                etat: etatImputationStock
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PRODUIT_MARCHAND_MATERIEL,
                nodeName: DB_NODE.PRODUIT,
                etat: etatProduit
            }
        ]
    });
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const { libelle, selectedImputation, selectedProduit, quantite } =
            fields;
        if (!quantite || !selectedImputation?.code || !selectedProduit?.code)
            return;
        const payload = {
            newRegularisationStock: {
                produit: {
                    code: selectedProduit.code
                },
                imputation: {
                    code: selectedImputation.code
                },
                quantite,
                libelle
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_REGULARISATION_STOCK,
                payload,
                fields,
                reduxNodeName: DB_NODE.REGULARISATION_STOCK,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Libellé*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le libellé"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    name="libelle"
                    id="libelleCreationRegularisationStockMateriel"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={produits}
                    label="Produit*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedProduit"
                    id="selectedProduitCreationRegularisationStock"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={imputationsStock}
                    label="Imputation*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-sm-5"
                    formDispatcher={formDispatcher}
                    name="selectedImputation"
                    id="selectedImputationCreationRegularisationStock"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
                <CustomInput
                    divClassName="col-sm-5"
                    type="number"
                    isInputGroup={false}
                    placeholder="Saisissez la quantité"
                    formDispatcher={formDispatcher}
                    name="quantite"
                    id="quantiteCreationRegularisationStockMateriel"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
