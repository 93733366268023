import * as yup from 'yup';

export const ConfirmCreateSuperDealerSchema = yup.object().shape({
    newAppointageCode: yup.string().required(),
    newAppointageDesignation: yup.string().required(),
    newAppointageEmail: yup.string().email().required(),
    newProprietaireNom: yup.string().required(),
    newProprietairePrenom: yup.string().required(),
    preview: yup.string(),
    newProprietaireNumeroTel: yup.string().required(),
    newProprietaireAdresse: yup.string().required(),
    newAdresseAdresseUne: yup.string().required(),
    selectedZone: yup.object().shape({
        reference: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedProduit: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedTypeBusinessNumero: yup.string().required(),
    selectedTypeBusiness: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    })
});

export const DistributeurCreateSuperDealerSchema = yup.object().shape({
    newAppointageDesignation: yup.string().required(),
    newAppointageEmail: yup.string().email().required(),
    preview: yup.string(),
    selectedProduit: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedTypeBusinessNumero: yup.string(),
    selectedTypeBusiness: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedMarchand: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    typeBusinessWithNumber: yup.array().of(
        yup.object().shape({
            code: yup.string(),
            numero: yup.string()
        })
    ),
    typeBusiness: yup.array().of(
        yup.object().shape({
            code: yup.string(),
            id: yup.string(),
            numero: yup.string()
        })
    )
});
