import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useGetUserDetail,
    useSubmit
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    DB_NODE,
    NAPP,
    FETCH_CUSTOM_OBJECT
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { VirementFormSchema as uiValidator } from './validation';

/**
 * @description defaultFields for our state
 */
const defaultFields = {
    selectedTypeOperation: {},
    selectedCompte: {},
    selectedCompteSuperDealerKinDb: {},
    selectedSuperDealer: {},
    selectedSuperDealerDestinataire: {},
    selectedInstitutionFinanciere: {},
    estCommission: false,
    montant: 0,
    commission: 0,
    taux: 0
};

/**
 * @description defaultDependencies for our state
 */
// const defaultDependencies = {
//     fields: ['comptes', 'banques', 'caisses', 'superDealer']
// };

/**
 * @description defaultElements
 */
const defaultElements = ['comptes'];

export function CreationVirementKinDBForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        nappUser,
        comptes,
        organisation,
        etatCompte,
        // comptesSuperDealerKinDB,
        // etatCompteSuperDealerKinDB,
        institutionsFinanciere,
        etatInstitutionsFinanciere,
        // taux,
        // etatTaux,
        caisses,
        etatCaisses,
        superDealerKinDB,
        etatSuperDealerKinDB
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation,
        comptes: reduxState.comptesBancaireOrganisation,
        etatCompte: reduxState.etat.comptesBancaireOrganisation.etat,
        comptesSuperDealerKinDB: reduxState.comptesBancaireSuperDealerKinDB,
        etatCompteSuperDealerKinDB:
            reduxState.etat.comptesBancaireSuperDealerKinDB.etat,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionsFinanciere: reduxState.etat.institutionsFinanciere.etat,
        // taux: reduxState.tauxSuperDealerKindb,
        // etatTaux: reduxState.etat.tauxSuperDealerKindb.etat,
        caisses: reduxState.caissesActiveUser.map((item) => ({
            ...item,
            designation: `${item?.designation || ''}-${item?.devise || ''}`
        })),
        etatCaisses: reduxState.etat.caissesActiveUser.etat,
        superDealerKinDB: reduxState.superDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat
    }));
    /**
     * Common form processes
     */
    const { idToken, isNapp } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const defaultDependencies = {};
    if (nappUser.typeEntite !== NAPP) {
        defaultDependencies.fields = [
            'comptes',
            'banques',
            'caisses',
            'superDealer'
        ];
    } else {
        defaultDependencies.fields = ['banques', 'superDealer'];
    }

    const { formDispatcher, formState } = useSubmit({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });

    const { fields, form } = formState;

    const formatCaisse = (elements) => elements?.list || [];
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionsFinanciere
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: isNapp
                    ? { estAll: true, typeEntite: nappUser?.typeEntite }
                    : {
                          estAll: true,
                          estExclu: true,
                          typeEntite: nappUser?.typeEntite
                      }
            },
            {
                fetchType: FETCH_CUSTOM_OBJECT,
                listEtatCustomObject: [etatCompte, etatCaisses],
                functionName: URL_CONST.GET_LIST_ELEMENT_VIREMENT_KINDB,
                nodeList: [
                    {
                        nodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE_ORGANISATION,
                        propertyName: 'Compte'
                    },
                    {
                        nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
                        propertyName: 'Caisse',
                        formater: formatCaisse
                    }
                ]
            }
        ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = {};
        if (isNapp) {
            payloadToAdd.codeSuperDealer = fields.selectedSuperDealer?.code;
        }
        const payload = {
            newOperationVirement: {
                type: 'Debit', // fields.selectedTypeOperation?.code,
                caisse: {
                    reference: fields.selectedCaisse.id
                },
                partenaire: {
                    code: fields.selectedSuperDealerDestinataire.code
                },
                compte: { reference: fields.selectedCompte?.id },
                montant: fields.montant
            },
            ...payloadToAdd
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_OPERATION_VIREMENT_KINDB,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.VIREMENTS_KINDB,
                reduxDispatcher
            })
        );
    };

    /**
     * Return taux système en fonction d'un superdealer
     * @param {Object} sd : fields.selectedSuperDealer
     * @param {Array} txs : taux
     * @returns {Integer}
     */
    // const getTauxBySuperDealer = (sd, txs) =>
    //     txs.find((tx) => tx?.valeurTypeEntite?.code === sd?.code)?.valeur;

    /**
     * Return Compte en fonction d'un superdealer
     * @param {Array} cpt : comptes
     * @param {Object} sd : fields.selectedSuperDealer
     * @param {Object} bq : fields.selectedInstitutionFinanciere
     * @returns {Array}
     */
    const getCompteBySuperDealer = (cpt, sd, bq) =>
        cpt
            .filter((item) => item?.valeurTypeProprietaire?.code === sd?.code)
            .filter(
                (element) => element?.institutionFinanciere?.code === bq?.code
            )
            ?.map((item) => ({
                ...item,
                designation: `${item?.numero}-${item?.devise}`
            }));

    /**
     * Return true if taille Compte en fonction d'un superdealer est > 0
     * @param {Array} cpt : comptes
     * @param {Object} sd : fields.selectedSuperDealer
     * @param {Object} bq : fields.selectedInstitutionFinanciere
     * @returns {Boolean}
     */
    const isCompteBySuperDealerExiste = (cpt, sd, bq) => {
        const res = getCompteBySuperDealer(cpt, sd, bq);
        return res?.length;
    };

    /**
     * @description fonction pour fetcher les comptes
     */
    const onSearchCompteSuperDealer = () => {
        const { status: etat } = formState.elements.comptes;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_ELEMENT_VIREMENT_KINDB,
                elementPropertyName: 'comptes',
                etat,
                payload: {
                    codeSuperDealer: fields.selectedSuperDealer?.code
                },
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'banques',
                    etat: etatInstitutionsFinanciere
                },
                {
                    dependency: 'comptes',
                    etat: etatCompte,
                    isCondition: nappUser.typeEntite !== NAPP
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisses,
                    isCondition: nappUser.typeEntite !== NAPP
                },
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB
                }
            ]}
        >
            <CustomRow isShouldBeRendered={!!isNapp}>
                <CustomDropdown
                    options={superDealerKinDB}
                    defaultOption="Selectionner SuperDealer"
                    label="Super Dealer: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-8"
                    formDispatcher={formDispatcher}
                    name="selectedSuperDealer"
                    id="selectedSuperDealerCreationApproCompteEmoney"
                    // uiValidator={CreationApproCompteEmoneySchema}
                    formState={formState}
                />
                <CustomButtonLoader
                    className="col-2 h-25"
                    type="button"
                    text="rechercher"
                    onClick={onSearchCompteSuperDealer}
                    disabled={
                        formState.elements?.comptes?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                    isActionEncours={
                        formState.elements?.comptes?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    !!formState.elements?.comptes?.value?.Caisse?.list
                        ?.length ||
                    !!formState.elements?.comptes?.value?.Compte?.length
                }
            >
                <CustomRow>
                    <CustomDropdown
                        idProperty="code"
                        propertyToDisplay="designation"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        label="Institution Financière* : "
                        name="selectedInstitutionFinanciere"
                        id="creationRemboursementCautionCompteInstitutFinanciereId"
                        options={institutionsFinanciere}
                        selectionClassName="form-select-md"
                        defaultOption="Sélectionner"
                        divClassName="col-sm-10"
                        labelClassName="col-sm-2"
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={formState.elements?.comptes?.value?.Caisse?.list?.map(
                            (item) => ({
                                ...item,
                                designation: `${item?.designation} - ${item?.devise}`
                            })
                        )}
                        label="Caisse*"
                        defaultOption="Sélectionner caisse"
                        // disabled={![].length}
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        formDispatcher={formDispatcher}
                        name="selectedCaisse"
                        id="creationTransfertCaisseKinDBSelectedCaisseId"
                        formState={formState}
                        idProperty="id"
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={getCompteBySuperDealer(
                            formState.elements?.comptes?.value?.Compte || [],
                            organisation.entreprise
                                ? organisation.entreprise
                                : fields.selectedSuperDealer,
                            fields.selectedInstitutionFinanciere,
                            formState.elements?.comptes?.value?.Caisse?.list,
                            fields.selectedCaisse
                        )}
                        label="Compte*: "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        formDispatcher={formDispatcher}
                        uiValidator={uiValidator}
                        defaultOption="Sélectionner"
                        name="selectedCompte"
                        id="selectedCompteCreationVirement"
                        formState={formState}
                        idProperty="id"
                        disabled={
                            !isCompteBySuperDealerExiste(
                                formState.elements?.comptes?.value?.Compte ||
                                    [],
                                organisation.entreprise
                                    ? organisation.entreprise
                                    : fields.selectedSuperDealer,
                                fields.selectedInstitutionFinanciere,
                                formState.elements?.comptes?.value?.Caisse
                                    ?.list,
                                fields.selectedCaisse
                            )
                        }
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={superDealerKinDB}
                        defaultOption="Selectionner SuperDealer"
                        label="Paternaire : "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-8"
                        formDispatcher={formDispatcher}
                        name="selectedSuperDealerDestinataire"
                        id="selectedSuperDealerDestinataireCreationApproCompteEmoney"
                        // uiValidator={CreationApproCompteEmoneySchema}
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper isShouldBeRendered={!isNapp}>
                <CustomRow>
                    <CustomDropdown
                        idProperty="code"
                        propertyToDisplay="designation"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        label="Institution Financière* : "
                        name="selectedInstitutionFinanciere"
                        id="creationRemboursementCautionCompteInstitutFinanciereId"
                        options={institutionsFinanciere}
                        selectionClassName="form-select-md"
                        defaultOption="Sélectionner"
                        divClassName="col-sm-10"
                        labelClassName="col-sm-2"
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={caisses}
                        label="Caisse*"
                        defaultOption="Sélectionner caisse"
                        // disabled={![].length}
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        formDispatcher={formDispatcher}
                        name="selectedCaisse"
                        id="creationTransfertCaisseKinDBSelectedCaisseId"
                        formState={formState}
                        idProperty="id"
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={getCompteBySuperDealer(
                            comptes,
                            organisation.entreprise,
                            fields.selectedInstitutionFinanciere,
                            fields.selectedCaisse
                        )}
                        label="Compte*: "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        formDispatcher={formDispatcher}
                        uiValidator={uiValidator}
                        defaultOption="Sélectionner"
                        name="selectedCompte"
                        id="selectedCompteCreationVirement"
                        formState={formState}
                        idProperty="id"
                        disabled={
                            !isCompteBySuperDealerExiste(
                                comptes,
                                organisation.entreprise,
                                fields.selectedInstitutionFinanciere,
                                fields.selectedCaisse
                            )
                        }
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={superDealerKinDB}
                        defaultOption="Selectionner SuperDealer"
                        label="Paternaire : "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-8"
                        formDispatcher={formDispatcher}
                        name="selectedSuperDealerDestinataire"
                        id="selectedSuperDealerDestinataireCreationApproCompteEmoney"
                        // uiValidator={CreationApproCompteEmoneySchema}
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Saisir"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    name="montant"
                    id="creationMontantId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
