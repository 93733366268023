import * as yup from 'yup';

export const ConfirmRegistrationSchema = yup.object().shape({
    codeValidation: yup
        .string()
        .required()
        .matches(/[0-9]/, 'Le code ne doit comporter que des nombres'),
    email: yup
        .string()
        .email()
        .required('Entrez votre adresse : exemple@domaine.com'),
    estMarchand: yup.boolean(),
    selectedOrganisation: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
