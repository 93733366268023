import { RapportTropPercuCommandeFlashForm } from './rapport-trop-percu';

export function RapportTropPercuViewLayout() {
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Rapport trop perçu</h1>
            </div>
            <RapportTropPercuCommandeFlashForm />
        </div>
    );
}
