import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import {
    CODE_SERVICE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useHasService } from '../../../util';

const setDefaultProperties = ({ type, service, reference, uid }) => {
    // DefaultMapper
    const defaultMapper = [
        {
            field: 'caisse.designation',
            displayName: 'Caisse'
        },
        {
            displayName: 'Agent',
            dataExtractor: (item) =>
                `${item?.agent?.prenom || ''} ${item?.agent?.nom || ''}`
        },
        {
            field: 'devise',
            displayName: 'devise'
        },
        {
            field: 'montant',
            displayName: 'montant'
        },
        {
            field: 'etat',
            displayName: 'etat'
        },
        {
            field: 'dateCreation',
            displayName: 'Création'
        }
    ];

    // DefaultListerners
    let filters = [];
    if (
        service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .VALIDER_APPRO_CASH?.code
        ]
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'typeProprietaire',
                value: type,
                isRequired: true
            },
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'valeurTypeProprietaire.reference',
                value: reference,
                isRequired: true
            }
        ]);
    } else if (
        service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_APPRO_CASH?.code
        ]
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.ARRAY_CONTAINS,
                property: 'users',
                value: uid,
                isRequired: true
            }
        ]);
    }

    if (
        service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .VALIDER_APPRO_CASH?.code
        ] ||
        service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_APPRO_CASH?.code
        ]
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'typeEntite',
                value: 'ApproCash',
                isRequired: true
            },
            {
                type: FILTER_TYPE.ORDER_BY,
                property: 'dateDerniereModification',
                value: 'asc'
            },
            {
                type: FILTER_TYPE.START_AT,
                value: TODAY.nowUtc(),
                isTimestampNowValue: true
            }
        ]);
    }
    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    return { mapper: [], listerners: [] };
};

export function ListApproCashForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, firebaseUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser,
        firebaseUser: reduxState.firebaseUser
    }));
    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.SERVICE_CODE_WEB.CODE_VERSEMENT
                .VALIDER_VERSEMENT_AGENT_VENDEUR,
            CODE_SERVICE.SERVICE_CODE_WEB.CODE_VERSEMENT
                .INITIER_VERSEMENT_AGENT_VENDEUR
        ]
    });

    /**
     * Common form processes
     */
    const { uid, typeEntite } = useGetUserDetail({
        nappUser,
        firebaseUser
    });

    const { mapper, listerners } = setDefaultProperties({
        type: typeEntite,
        uid,
        reference: nappUser?.valeurTypeEntite?.reference,
        service: hasService
    });
    return (
        <ListGenericForm
            fetchType={FETCH_LISTENING}
            reduxPropertyName="approCash"
            functionName={URL_CONST.GET_LIST_APPRO_CASH_VERSEMENT_INITIALS}
            nodeName={REDUX_NODE_NAME.APPRO_CASH}
            path={DB_NODE.APPRO_CASH_VERSEMENT}
            payload={{ typeEntite: 'ApproCash' }}
            mapper={mapper}
            listenerFilters={listerners}
        />
    );
}
