export const schema = [
    {
        title: 'Marchand',
        description: 'marchand.designation',
        position: 1
    },
    {
        title: 'Numero',
        description: 'numero',
        className: 'border-bottom'
    },
    {
        title: 'Est Actif',
        description: 'estActif',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
