export const schema = [
    {
        title: 'Nom',
        description: 'agent.nom',
        className: 'border-bottom'
    },
    {
        title: 'Prénom',
        description: 'agent.prenom'
    }
];
