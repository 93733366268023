export const schema = [
    {
        title: 'Imputation',
        description: 'imputation.designation',
        className: 'border-bottom'
    },
    {
        title: 'Caisse',
        description: 'caisse.designation',
        className: 'border-bottom'
    },
    {
        title: 'Montant',
        description: 'montant',
        className: 'border-bottom'
    },
    {
        title: 'Devise',
        description: 'devise',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
