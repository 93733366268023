import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    BENEFICIAIRE,
    DB_NODE,
    formInitialState,
    FORM_CONST,
    CATEGORIE_MARCHAND
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomDropdown,
    CustomFormGroup,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SelectZone
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useDebounce, URL_CONST } from '../../../util';

const defaultFields = {
    selectedSender: {},
    selectedMarchand: {},
    selectedZone: {},
    selectedZoneDropDown: {},
    searchInput: '',
    preview: '',
    zones: []
};

/**
 * @description dependencies du state du formulaire
 */

const defaultElements = ['zones', 'marchand'];

const defaultDependencies = {
    fields: ['user']
};

export function CreationConfigurationSenderForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser, users, etatUser } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            users: reduxState.senders.map((item) => ({
                ...item,
                designation: `${item?.sendeur?.prenom || item?.prenom} ${
                    item?.sendeur?.nom || item?.nom
                }`
            })),
            etatUser: reduxState.etat.senders.etat
        })
    );
    const { idToken, creator, typeEntite, isNapp } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    const { status } = form;
    useDebounce({
        name: 'selectedZone',
        preview: 'preview',
        isObject: true,
        formDispatcher,
        formState,
        delay: 2000,
        idToken,
        functionName: URL_CONST.GET_LIST_COORDONNES_DISPONIBLE_POUR_AGENCE,
        elementPropertyName: 'zones',
        creator,
        reduxNodeName: DB_NODE.ENTITE_ADMINISTRATIVE,
        reduxDispatcher
    });

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER_A_SELECTIONNER,
                nodeName: REDUX_NODE_NAME.AGENT_SENDERS,
                payload: {
                    typeEntite: 'Sendeur'
                },
                etat: etatUser
            }
        ]
    });

    /**
     * @description fonction pour fetcher les marchands
     */
    const onSearchMarchand = () => {
        const { status: etat } = elements.marchand;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_MARCHAND_BY_TEXT_DESIGNATION,
                elementPropertyName: 'marchand',
                etat,
                payload: {
                    textDesignation: fields.searchInput,
                    codeCategorieParent:
                        CATEGORIE_MARCHAND.MARCHAND_PRODUIT_ELECTRONIQUE.code
                },
                reduxDispatcher
            })
        );
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = {};
        if (typeEntite === BENEFICIAIRE.MARCHAND.code) {
            payloadToAdd.zones = fields.zones;
        }
        if (isNapp) {
            const zones = fields.zones.map((item) => Number(item.reference));
            payloadToAdd.codeMarchand = fields.selectedMarchand.code;
            payloadToAdd.zones = zones;
        }
        const payload = {
            uidSendeur: fields.selectedSender.owner,
            ...payloadToAdd,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                fields,
                functionName: URL_CONST.POST_LIST_ZONE_SENDEUR,
                payload: {
                    ...payload
                },
                reduxDispatcher
                // uiValidator
            })
        );
    };
    return (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'user',
                    etat: etatUser
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    label="Sender*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    idProperty="owner"
                    defaultOption="Choisir l'agent"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedSender"
                    id="selectedSenderId"
                    options={users}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={isNapp}>
                <CustomLabel text="Rechecher Marchand*" className="col-sm-2" />
                <CustomInput
                    isInputGroup={false}
                    divClassName="col-12 col-sm-7"
                    placeholder="Marchand"
                    formDispatcher={formDispatcher}
                    name="searchInput"
                    id="configurationSenderSearchInputId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
                <CustomButtonLoader
                    className="col-2 h-25"
                    type="button"
                    text="rechercher"
                    onClick={onSearchMarchand}
                    disabled={
                        elements?.marchand?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                    isActionEncours={
                        elements?.marchand?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
            </CustomRow>
            {elements.marchand?.value?.length ? (
                <CustomRow>
                    <CustomLabel text="Marchand" className="col-sm-2" />
                    <CustomDropdown
                        options={elements?.marchand?.value}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner le marchand"
                        divClassName="col-12 col-sm-4"
                        name="selectedMarchand"
                        id="selectedMarchandId"
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            ) : null}
            {typeEntite === 'Napp' ? (
                <CustomRow>
                    <CustomFormGroup className="col-md-12">
                        <SelectZone
                            zoneFound={
                                elements?.zones?.value?.length
                                    ? elements.zones.value
                                    : []
                            }
                            entitesAdministrative={
                                elements?.zones?.value?.length
                                    ? elements.zones.value
                                    : []
                            }
                            // callback={processChanges}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            // uiValidator={uiValidator}
                        />
                    </CustomFormGroup>
                </CustomRow>
            ) : null}
            <CustomButtonLoader
                className="btn mt-3 w-25"
                text="Affecter Sender"
                disabled={status === FORM_CONST.CREATION_EN_COURS}
            />
        </FormWrapper>
    );
}
