import { useDispatch, useSelector } from 'react-redux';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListCompteTransfertOrganisationForm() {
    const reduxDispatcher = useDispatch();
    const { institionsFin, devisesFin } = useSelector((reduxState) => ({
        institionsFin:
            reduxState.dynamicNode?.dynamicNodeInstitutionFinanciere || [],
        devisesFin: reduxState.dynamicNode?.dynamicNodeDevises || []
    }));
    const onSuccess = (comptes = []) => {
        const institutionFinanciere = [];
        const devises = [];
        comptes.forEach((compte) => {
            const institutionsKeys = Object.keys(compte?.institions) || [];
            institutionsKeys.forEach((key) => {
                if (
                    !institutionFinanciere?.find(
                        (instition) => instition?.code === key
                    )
                )
                    institutionFinanciere.push({
                        code: key,
                        designation:
                            compte?.institions?.[key]?.institutionFinanciere
                                ?.designation
                    });
                const devisesKeys =
                    Object.keys(compte?.institions?.[key]?.devises) || [];
                devisesKeys.forEach((deviseKey) => {
                    if (!devises?.find((devise) => deviseKey === devise))
                        devises.push(deviseKey);
                });
            });
        });
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                comptes,
                REDUX_NODE_NAME.ALL_BALANCE_ORGANISATION_COMPTE_TRANSFERT_FONDS
            )
        );
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItemsDN(
                institutionFinanciere,
                `dynamicNodeInstitutionFinanciere`
            )
        );
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItemsDN(
                devises,
                `dynamicNodeDevises`
            )
        );
    };
    const dynamicColumn = () => {
        const columns = [
            {
                Header: 'Organisation',
                displayName: 'Organisation',
                accessor: 'organisation.designation',
                field: 'organisation.designation',
                isKeepFormat: true
            }
        ];
        institionsFin.forEach((institionFin) => {
            if (institionFin) {
                const element = {
                    Header: institionFin?.designation,
                    displayName: institionFin?.designation,
                    isKeepFormat: true,
                    columns: []
                };
                devisesFin.forEach((deviseFin) => {
                    if (deviseFin)
                        element.columns.push({
                            Header: deviseFin,
                            displayName: deviseFin,
                            accessor: `institions.${institionFin?.code}.devises.${deviseFin}.montant`,
                            field: `institions.${institionFin?.code}.devises.${deviseFin}.montant`,
                            isKeepFormat: true
                        });
                });
                columns.push(element);
            }
        });

        return columns;
    };
    const mapper = dynamicColumn();
    return (
        <ListGenericForm
            reduxPropertyName="allBalanceOrganisationCompteTransfertFonds"
            functionName={URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION}
            nodeName={
                REDUX_NODE_NAME.ALL_BALANCE_ORGANISATION_COMPTE_TRANSFERT_FONDS
            }
            payload={{
                estOrganisation: true,
                typeProprietaire: 'SuperDealer',
                estChangerFormat: true
            }}
            isLinkToDetail={false}
            onSuccess={onSuccess}
            mapper={mapper}
        />
    );
}
