import { Add, ETAT } from '@napp-inc/jnapp-util';
import { useState, useEffect } from 'react';

/**
 *
 * @param {Array} list list des commandes d'un etat donné
 * @param {string} etat code etat dont on fait le calcul
 * @returns  {Object}
 */
const calcultTotaux = (list, etat) => {
    const objectToReturn = {
        etat,
        montantCDF: 0,
        montantUSD: 0
    };
    list.forEach((item) => {
        objectToReturn.montantCDF =
            item.devise === 'CDF'
                ? Add(objectToReturn.montantCDF + item.montant)
                : Add(objectToReturn.montantCDF + 0);
        objectToReturn.montantUSD =
            item.devise === 'USD'
                ? Add(objectToReturn.montantUSD + item.montant)
                : Add(objectToReturn.montantUSD + 0);
    });
    return objectToReturn;
};

/**
 * Ce hook calcul le total des commandes d'un état, utilisé pour la vue totaux du sender flash
 * @param {Object} param0 objet contenant
 * @param {Array} param0.encours liste des commandes encours
 * @param {Array} param0.livrées liste des commandes livrées
 * @param {Array} param0.annulées liste des commandes annulées
 * @param {Array} param0.attente liste des commandes en attente de validatop,
 *
 * @returns {Object} objet contenant la propriété totaux qui est un tableau
 */
export const useGetTotauxFlashSender = ({
    encours = [],
    attente = [],
    annulees = [],
    livrees = []
}) => {
    const [totaux, setTotaux] = useState([]);
    useEffect(() => {
        const encoursResult = calcultTotaux(encours, ETAT.ETAT_ENCOURS);
        const attenteResult = calcultTotaux(
            attente,
            ETAT.ETAT_ATTENTE_VALIDATION
        );
        const annuleesResult = calcultTotaux(annulees, ETAT.ETAT_ANNULER);
        const livreesResult = calcultTotaux(livrees, ETAT.ETAT_VALIDER);
        setTotaux([
            encoursResult,
            attenteResult,
            annuleesResult,
            livreesResult
        ]);
    }, [encours, attente, annulees, livrees]);
    return { totaux };
};
