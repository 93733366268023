import React, { useMemo, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { StateContext } from './state-context';
import Path from '../routes/path';
import { useMenuBuilder } from '../components/menu/menu-builder';

/**
 * Wrapper du menu context
 * @param {Obejct} param0 Object qui contient les props du composant:
 * @param {string} param0.children Contenu du composant qui recevra le contexte du menu
 * @returns {React.Component}
 */
export function StateContextWrapper({ children }) {
    const [activeMenu, setActiveMenu] = useState(false);
    const [showMenu, setShowMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(0);
    const [screenCoordinates, setScreenCoordinates] = useState({ x: 0, y: 0 });
    const { usersService } = useSelector((reduxState) => ({
        usersService: reduxState.usersService
    }));
    const { PrincipalMenu: menus, ConfigMenu: configMenus } = useMenuBuilder(
        Path,
        usersService
    );
    const stateMemoized = useMemo(
        () => ({
            menus,
            configMenus,
            activeMenu,
            setActiveMenu,
            showMenu,
            setShowMenu,
            screenSize,
            setScreenSize,
            screenCoordinates,
            setScreenCoordinates
        }),
        [
            menus,
            configMenus,
            activeMenu,
            setActiveMenu,
            showMenu,
            setShowMenu,
            screenSize,
            setScreenSize,
            screenCoordinates,
            setScreenCoordinates
        ]
    );
    return (
        <StateContext.Provider value={stateMemoized}>
            {children}
        </StateContext.Provider>
    );
}

export const useStateContext = () => useContext(StateContext);
