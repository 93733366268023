import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    // BilletageComponent,
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import {
    URL_CONST,
    // calculTotalBilletage,
    useGetDetailForm
} from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    isSeulExecutant: true,
    selectedDeviseBilletage: {},
    selectedCoupureBilletage: {},
    nombreCoupureBilletage: 0,
    listBilletage: [],
    actionEncours: ''
};

export function DetailApproCashForm() {
    const {
        id,
        reduxDispatcher,
        creator,
        entity,
        idToken,
        formState,
        formDispatcher
    } = useGetDetailForm({
        nodeName: 'approCash',
        defaultFields,
        listNode: [
            {
                name: 'allCoupures'
            }
        ]
    });

    const { fields, form } = formState;

    const updateEtat = (etat) => {
        const payload = {
            idApproCashVersement: entity?.id,
            etat,
            creator
        };
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_APPRO_CASH_VERSEMENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.APPRO_CASH,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_VERSEMENT_AGENT_VENDEUR_BY_ID}
            nodeName={REDUX_NODE_NAME.APPRO_CASH}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Versements"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_FINANCE.VALIDER_APPRO_CASH
                ]}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomRow>
                        <CustomCol className="offset-4 offset-md-8 col-3 col-md-2">
                            <CustomButtonLoader
                                type="button"
                                className="btn-danger"
                                onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                                text="Annuler"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                            />
                        </CustomCol>
                        <CustomCol className="col-3 col-md-2">
                            <CustomButtonLoader
                                type="button"
                                className="btn-success"
                                onClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                                text="valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                </ConditionalRenderingWrapper>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
