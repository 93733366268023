import { COLOR } from '@napp-inc/jnapp-util';

export function CustomBadge({
    children,
    text,
    className,
    style = {},
    backgroundColor = COLOR.DARK
}) {
    return (
        <span
            className={`badge ${className}`}
            style={{ ...style, backgroundColor }}
        >
            {text}
            {children}
        </span>
    );
}
