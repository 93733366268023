import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListTransfertStockForm() {
    return (
        <ListGenericForm
            reduxPropertyName="transfertsStockMateriel"
            functionName={URL_CONST.GET_LIST_TRANSFERT_STOCK_MATERIEL}
            nodeName={DB_NODE.TRANSFERT_STOCK_MATERIEL}
            mapper={[
                {
                    field: 'id',
                    displayName: 'id',
                    position: 1
                },
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand',
                    position: 2
                },
                {
                    field: 'valeurTypeBenefiaire.designation',
                    displayName: 'Bénéficiaire',
                    position: 3
                },
                {
                    field: 'etat',
                    displayName: 'Etat',
                    position: 4
                }
            ]}
        />
    );
}
