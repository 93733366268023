import { Spinner } from 'react-bootstrap';

export function CenteredSpinner() {
    return (
        <div
            style={{
                display: 'grid',
                justifyContent: 'center',
                alignItems: 'center',
                height: '10vh'
            }}
        >
            <Spinner animation="border" variant="primary" />
        </div>
    );
}
