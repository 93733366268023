import { useState } from 'react';
/**
 * Ce hook permet de lire et écrire une valeur dans la session storage du navigateur
 * @param {String} key Clé à stocké dans session storage
 * @param {String} initialValue Valeur initiale de la clé à stocké
 * @returns {Object} Objet contenant les propriétés componentSwitcher, setComponentSwitcher
 */
export const useSessionStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            return error;
        }
        return 1;
    };

    return [storedValue, setValue];
};

/**
 * Ce hook permet de lire l'information et mettre à jour l'information component switcher stocké dans la session storage
 * Component switcher est un objet dans lequel on renseigne l'état des certaines Ui, pour savoir par exemple si on doit rendre un composant ou un autre
 * @returns {Object} Objet contenant les propriétés componentSwitcher, setComponentSwitcher
 */
export const useComponentSwitcher = () => {
    const [componentSwitcher, setComponentSwitcher] = useSessionStorage(
        'componentSwitcher',
        {}
    );
    return { componentSwitcher, setComponentSwitcher };
};
