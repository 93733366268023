import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { RDC_PREFIX, URL_CONST } from '../../../util';
import { ConfigurationKinDbSchema as uiValidator } from './validation';

const defaultFields = {
    numero: ''
};

export function CreationConfigurationKindistributionForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = {};
        if (fields.numero.startsWith('00')) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Format invalide',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        RDC_PREFIX.forEach((prefix) => {
            if (fields.numero.startsWith(prefix)) {
                payloadToAdd.numero = fields.numero.substring(
                    prefix.length,
                    fields.numero.length
                );
                return true;
            }
            return false;
        });
        const payload = {
            numero: fields.numero,
            ...payloadToAdd
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CONFIGURATION_KIN_DB,
                payload,
                fields,
                reduxNodeName: DB_NODE.CONFIGURATION_KINDB,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper formState={formState} formDispatcher={formDispatcher}>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Numero*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Saisissez le numéro"
                    formDispatcher={formDispatcher}
                    name="numero"
                    id="configurationKinDbNumeroId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
