import { CustomAddButton } from '../button';
import { CustomRow } from '../container';
import { CustomDropdown } from '../dropdown';
import { SimpleTable } from '../table';

export function SelectAddTableComponent({
    id,
    formDispatcher,
    uiValidator,
    formState,
    options = [],
    selectedItemName,
    listSelectedItemName,
    identifier = 'code',
    label,
    dropdownPropertyToDisplay = 'value',
    labelClassName = 'col-sm-2',
    divClassName = 'col-6 col-md-8',
    placeholder,
    divButtonClassName = 'col-2',
    divTableClassName = 'offset-md-2 col-md-10',
    mapper,
    defaultOption
}) {
    return (
        <>
            <CustomRow>
                <CustomDropdown
                    options={options}
                    propertyToDisplay={dropdownPropertyToDisplay}
                    label={label}
                    defaultOption={defaultOption}
                    labelClassName={labelClassName}
                    divClassName={divClassName}
                    placeholder={placeholder}
                    formDispatcher={formDispatcher}
                    name={selectedItemName}
                    id={`${id}selectAddTableDropdown`}
                    uiValidator={uiValidator}
                    formState={formState}
                />
                <div className={divButtonClassName}>
                    <CustomAddButton
                        list={options}
                        identifier={identifier}
                        formDispatcher={formDispatcher}
                        field={listSelectedItemName}
                        id={formState?.fields?.[selectedItemName]?.[identifier]}
                        size="25em"
                    />
                </div>
            </CustomRow>
            {formState?.fields?.[listSelectedItemName]?.length ? (
                <CustomRow>
                    <div className={divTableClassName}>
                        <SimpleTable
                            data={formState?.fields?.[listSelectedItemName]}
                            listSelectedItemName={listSelectedItemName}
                            formDispatcher={formDispatcher}
                            identifier={identifier}
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={mapper}
                        />
                    </div>
                </CustomRow>
            ) : null}
        </>
    );
}
