import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    MONNAIES,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdownEditable,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { MonnaieFormSchema as uiValidator } from './validation';

const defaultFields = {
    selectedMonnaie: {}
};

export function CreateMonnaieLocaleForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedMonnaie } = fields;
        const payload = {
            devise: selectedMonnaie.code,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_MONNAIE_LOCALE,
                payload,
                // reduxValidator: ApprovisionnementMonnaieSchema,
                fields,
                reduxNodeName: REDUX_NODE_NAME.MONNAIE_LOCALE_TO_APPROVE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    return (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomDropdownEditable
                    options={MONNAIES.map((monnaie) => ({
                        code: monnaie?.code,
                        designation: `${monnaie?.designation} - ${monnaie?.code}`
                    }))}
                    label="Monnaie*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedMonnaie"
                    id="selectedMonnaieCreationMonnaieLocale"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
}
