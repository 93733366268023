import { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
    CHART_TYPE,
    buildHighchartCategories,
    buildHighchartSeries
} from '../../util';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomRow
} from '../container';
import { CustomSwitchButton } from '../button';

const mouvementMapperSeries = [
    {
        displayName: 'Ouverture',
        field: 'soldePrecedent'
        // lineWidth: 2
        // color: 'blue',
        // callback: true
    },
    {
        displayName: 'Entree',
        field: 'entree'
    },
    {
        displayName: 'Sortie',
        field: 'sortie'
    },
    {
        displayName: 'Fermeture',
        field: 'soldeActuel'
    }
];

/**
 * Construit un graphique en bar ou line, basé sur highchart
 * @param {Object} param0
 * @param {Array} param0.dataList tableau des données
 * @param {String} param0.title titre du graphique
 * @param {String} param0.subtitle Sous titre du graphique
 * @param {String} param0.yAxisTittle Titre de l'axe des Y
 * @param {String} param0.xAxisDateFormat format de data à utilisé pour les ticks de l'axe des X
 * @param {Number} param0.yAxisTickInterval L'intervalle à définir sur les ticks de l'axe des Y, cette valeur est auto-calculée par défaut
 * @param {String} param0.sortProperty propriété utilisée pour faire un tri ascendant, par défaut c'est la dateCreation
 * @param {Array} param0.seriesMapper mapper utilisé pour constituer le tableau series
 * @param {Function} param0.sortList fonction specialisée pour le tri des éléments
 * @returns {React.Component}
 */
export function CustomChart({
    dataList = [],
    title = '',
    subtitle = '',
    yAxisTittle = '',
    yAxisTickInterval,
    xAxisDateFormat,
    sortProperty = 'dateCreation',
    seriesMapper = mouvementMapperSeries,
    sortList
}) {
    const [chartType, setChartType] = useState(CHART_TYPE.COLUMN);
    const slicedMouvement = [];
    if (sortList && typeof sortList === 'function') {
        slicedMouvement.push(...dataList.slice().sort(sortList));
    } else {
        slicedMouvement.push(
            ...dataList
                .slice()
                .sort((a, b) => a[sortProperty] - b[sortProperty])
        );
    }
    const series = buildHighchartSeries({
        seriesMapper,
        dataList: slicedMouvement
    });
    const categories = buildHighchartCategories({
        dataList: slicedMouvement,
        dateFormat: xAxisDateFormat,
        field: sortProperty
    });
    let xAxisTickInterval = 1;
    // Affiche au maximum 20 tick sur l'axe des X, calcul le decalage en fonction

    if (dataList?.length) {
        xAxisTickInterval = Math.round(dataList.length / 20 + 0.5);
    }
    const options = {
        exporting: {
            enabled: true,
            filename: 'chart',
            type: 'image/png',
            width: 800,
            height: 600
        },
        credits: {
            enabled: false
        },
        chart: {
            renderTo: 'container',
            type: chartType
        },
        accessibility: {
            enabled: false
        },
        title: {
            text: title
        },
        subtitle: {
            text: subtitle
        },
        xAxis: {
            categories,
            tickInterval: xAxisTickInterval
        },
        yAxis: {
            title: {
                text: yAxisTittle
            },
            tickInterval: yAxisTickInterval
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        series
    };
    const switchType = (checked) => {
        const value = checked ? CHART_TYPE.COLUMN : CHART_TYPE.LINE;
        setChartType(value);
    };
    return (
        <ConditionalRenderingWrapper
            isShouldBeRendered={slicedMouvement?.length}
        >
            <CustomRow>
                <CustomCol>
                    <CustomSwitchButton
                        text="Bar"
                        callback={switchType}
                        defaultChecked
                        name="chartType"
                    />
                </CustomCol>
            </CustomRow>
            <div
                className="container-fluid"
                style={{ width: '100%', height: '400px' }}
            >
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </ConditionalRenderingWrapper>
    );
}
