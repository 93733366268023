import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { ReleveInstitutionFinanciereOrganisme } from '../../../../components';

export function ReleveInstitutionFinanciereKinDBForm() {
    return (
        <ReleveInstitutionFinanciereOrganisme
            typeOrganisation={TYPE_ORGANISATION.SUPER_DEALER}
        />
    );
}
