import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ListStockSuperDealerForm } from './list-stock';
import { EvolutionStockSuperDealerForm } from './evolution-stock';
import { StockPhysiqueForm } from './stock-physique';
import { RapportEvolutionStockByDateForm } from './evolution-stock-by-date';
import { ReleveStockForm } from '../../report/commande-flash/releve';

export function ReportStockSuperDealerViewLayout() {
    const [key, setKey] = useState('niveau');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Stock super dealer</h1>
            </div>
            <Tabs
                defaultActiveKey="niveau"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="niveau" title="Niveau stock">
                    {key === 'niveau' && <ListStockSuperDealerForm />}
                </Tab>
                <Tab eventKey="evolution" title="Evolution stock">
                    {key === 'evolution' && <EvolutionStockSuperDealerForm />}
                </Tab>
                <Tab eventKey="physique" title="Stock physique">
                    {key === 'physique' && <StockPhysiqueForm />}
                </Tab>
                <Tab eventKey="evolStockByDate" title="Evolution Stock By Date">
                    {key === 'evolStockByDate' && (
                        <RapportEvolutionStockByDateForm />
                    )}
                </Tab>
                <Tab eventKey="releve" title="Relevé Stock">
                    {key === 'releve' && <ReleveStockForm />}
                </Tab>
            </Tabs>
        </div>
    );
}
