// import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST } from '../../../../util';
import { formatType } from './util';

export function ListServiceClientFormulaireForm() {
    const listFormater = (data = []) =>
        data.map((item) => ({
            ...item,
            designationAgent:
                `${item?.agent?.prenom} ${item?.agent?.nom}` || '',
            designationType: formatType(item?.type)
        }));
    return (
        <ListGenericForm
            reduxPropertyName="serviceClientForm"
            functionName={URL_CONST.GET_LIST_FORMULAIRE_SERVICE_CLIENT_INITIALS}
            nodeName={REDUX_NODE_NAME.SERVICE_CLIENT_FORM}
            formater={listFormater}
            // payload={{
            //     dateDebut: TIME.utc000000(TODAY.at000000()),
            //     dateFin: TIME.utc235959(TODAY.at235959())
            // }}
            mapper={[
                {
                    field: 'designationAgent',
                    displayName: 'Agent'
                },
                {
                    field: 'designationType',
                    displayName: 'Type'
                },
                {
                    field: 'urlImage',
                    displayName: 'Url'
                },
                {
                    field: 'titre',
                    displayName: 'Sujet'
                },
                {
                    field: 'description',
                    displayName: 'Description'
                },
                {
                    field: 'texteTraite',
                    displayName: 'Correction'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
