import * as yup from 'yup';

/**
 * @description : Schema de Banque
 */

export const CreationExerciceSchema = yup.object().shape({
    dateDebut: yup.string().required(),
    dateFin: yup.string().required()
});
