import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomConfirmButton,
    CustomRow,
    DynamicFormMounted,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { CreationBanqueSchema as uiValidator } from './validation';

const defaultFields = {
    designation: '',
    code: '',
    adresse: '',
    nationalite: '',
    email: '',
    numeroTel: '',
    codeBcc: ''
};

export function CreationBanqueForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    const { idToken, creator, isNapp } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const fieldsMapper = [
        {
            position: 0,
            type: 'text',
            id: 'codeCreationBanque',
            name: 'code',
            placeholder: 'Saisissez le code',
            label: 'Code*',
            isInputGroup: false
        },
        {
            position: 1,
            type: 'text',
            id: 'designationCreationBanque',
            name: 'designation',
            placeholder: 'Saisissez le nom',
            label: 'Désignation*',
            isInputGroup: false
        },
        {
            position: 2,
            type: 'text',
            id: 'adresseCreationBanque',
            name: 'adresse',
            placeholder: "Saisissez l'adresse",
            label: 'Adresse*',
            isInputGroup: false
        },
        {
            position: 3,
            type: 'text',
            id: 'nationaliteCreationBanque',
            name: 'nationalite',
            placeholder: 'Saisissez la nationalité',
            label: 'Nationlité*',
            isInputGroup: false
        },
        {
            position: 4,
            type: 'text',
            id: 'emailCreationBanque',
            name: 'email',
            placeholder: "Saisissez l'adresse e-mail",
            label: 'E-mail*',
            isInputGroup: false
        },
        {
            position: 5,
            type: 'text',
            id: 'numeroTelCreationBanque',
            name: 'numeroTel',
            placeholder: 'Saisissez le numéro de contact',
            label: 'Numero contact*',
            isInputGroup: false
        },
        {
            position: 6,
            type: 'text',
            id: 'idCodeBCCCreationBanque',
            name: 'codeBcc',
            placeholder: 'Saisissez le CODE BCC',
            label: 'Code BCC*',
            isInputGroup: false,
            isShouldBeRendered: isNapp
        }
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        const { code, designation, adresse, nationalite, email, numeroTel } =
            fields;

        const payloadToAdd = {};
        if (isNapp) {
            payloadToAdd.codeBcc = fields.codeBcc;
        }

        const payload = {
            newBanque: {
                code,
                designation,
                nationalite,
                email,
                numeroTel,
                ...payloadToAdd
            },
            newAdresse: {
                adresseUne: adresse
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_BANQUE,
                payload,
                fields,
                reduxNodeName:
                    REDUX_NODE_NAME.INSTITUTION_FINANCIERE_TO_APPROVE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <DynamicFormMounted
                formDispatcher={formDispatcher}
                formState={formState}
                uiValidator={uiValidator}
                fieldsMapper={fieldsMapper}
            />
            <CustomRow className="row">
                <CustomConfirmButton
                    type="button"
                    backdrop="static"
                    text="Valider"
                    className="btn-primary btn mt-3 ms-2 w-25"
                    confirmOnClick={handleSubmit}
                    disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    isActionEncours={
                        form.status === FORM_CONST.CREATION_EN_COURS
                    }
                />
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
