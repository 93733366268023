import { TIME, TODAY } from '@napp-inc/jnapp-util';
// import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetReportDetail } from '../../../util';
import { ReduxReportTemplate } from '../template';

const groupementCaisse = (data) => {
    let tab = [];
    if (Array.isArray(data)) {
        tab = data[0]?.listReturn;
    } else {
        tab = data?.listReturn || [];
    }
    return tab?.map((item) => ({
        ...item,
        id: `${item?.detail?.idCaisse || ''}`,
        designation: `${item?.detail?.designation || ''} ${
            item?.detail?.devise || ''
        }`
    }));
};

export function EvolutionCaisseSuperDealerForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {}
    });
    const getConclusion = (item) => {
        const conclusion = item?.detail?.conclusion || 'Pas de conclusion';
        if (conclusion === 'FULL')
            return <span className="badge bg-success">{conclusion}</span>;
        if (conclusion === 'MOYEN')
            return (
                <span className="badge bg-warning text-dark">{conclusion}</span>
            );
        if (conclusion === 'CRITIQUE')
            return <span className="badge bg-danger">{conclusion}</span>;
        return <span className="badge bg-secondary">Pas de conclusion</span>;
    };
    return (
        <ReduxReportTemplate
            dynamicNodeName={`evolutionsCaisseSuperDealer-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            nodeName={REDUX_NODE_NAME.EVOLUTION_CAISSE_SUPER_DEALER}
            functionName={URL_CONST.GET_LIST_CAISSE_ACTIVE_USER}
            formState={formState}
            formDispatcher={formDispatcher}
            listFormater={groupementCaisse}
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                typeEntite: 'Journalier',
                typeProprietaire: 'SuperDealer',
                typeRapport: 'EvolutionCaisse',
                estNotDate: true,
                estOrganisation: true,
                estEmoney: true,
                estAll: false
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier',
                typeProprietaire: 'SuperDealer',
                typeRapport: 'EvolutionCaisse',
                estNotDate: true,
                estOrganisation: true,
                estEmoney: true,
                estAll: false
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                }
            ]}
            mapper={[
                {
                    displayName: 'SuperDealer',
                    field: 'designation'
                },
                {
                    displayName: 'Initial',
                    field: 'soldeOuverture'
                },
                {
                    displayName: 'Moyenne',
                    field: 'detail.moyenne'
                },
                {
                    displayName: 'Conclusion',
                    tdExtractor: (item) => getConclusion(item)
                },
                {
                    displayName: 'Depot Validés',
                    field: 'operation.DepotsEmoneyValidees'
                },
                {
                    displayName: 'Depot En attente',
                    field: 'operation.DepotsEmoneyAttenteValidation'
                },
                {
                    displayName: 'Retraits',
                    field: 'operation.RetraitsEmoney'
                },
                {
                    displayName: 'Transfert',
                    field: 'operation.Transfert'
                },
                {
                    displayName: 'Depots Banque',
                    field: 'operation.DepotOperationBancaire'
                },
                {
                    displayName: 'Retraits Banque',
                    field: 'operation.RetraitOperationBancaire'
                },
                // {
                //     displayName: 'Régul Sortie',
                //     field: 'operation.RegularisationSortie.montant'
                // },
                {
                    displayName: 'Fermeture',
                    field: 'soldeFermeture'
                }
            ]}
        />
    );
}
