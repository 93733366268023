export const schema = [
    {
        title: 'Marchand',
        description: 'marchand.designation',
        className: 'border-bottom'
    },
    {
        title: 'Beneficiaire',
        description: 'valeurTypeBenefiaire.designation',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation'
    }
];
