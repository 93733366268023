import { resolvePathPropertyOfObject } from '@napp-inc/jnapp-util';
/**
 * Construit les series utilisées par Highchart
 * @param {Object} param0 Objet contenant les propriétés seriesMapper et dataList
 * @param {Array} param0.dataList liste des données pour constituer les séries
 * @param {Array} param0.seriesMapper tableau d'objet contennant les propriétés { displayName, field, lineWidth, color, callback }
 * - Displayname : legend afficher sur le bas du graphique
 * - Field : champs à inclure dans le tableau data pour la série highcahrt
 * - callback : function pour récuperer d'une facon particulière la valeur du champ à inclure dans le tableau data de la série highchart
 * - lineWidth : épaisseur de la ligne affichée sur le graphique, par défaut 2
 * - color : couleur de la série à utiliser dans le graphique
 * -
 * @returns {Array}
 */
export const buildHighchartSeries = ({ seriesMapper = [], dataList }) => {
    const series = [];
    if (seriesMapper?.length) {
        seriesMapper?.forEach((mapperItem) => {
            const currentSerie = {};
            currentSerie.name = mapperItem?.displayName || '';
            currentSerie.color = mapperItem?.color || '';
            currentSerie.lineWidth = mapperItem?.lineWidth || 2;
            currentSerie.data = dataList?.map((dataListItem) => {
                if (typeof mapperItem?.callback === 'function') {
                    return mapperItem.callback(dataListItem);
                }
                if (mapperItem?.field) {
                    return resolvePathPropertyOfObject(
                        mapperItem?.field,
                        dataListItem
                    );
                }
                return 0;
            });
            series.push(currentSerie);
        });
    }
    return series;
};
