import { useSelector } from 'react-redux';
import { useIsFeatureAllowed } from '../../util';

export function UserServiceWrapper({ services, children }) {
    const { usersService } = useSelector((reduxState) => ({
        usersService: reduxState.usersService
    }));
    const isAllowed = useIsFeatureAllowed(usersService, services);
    return isAllowed ? children : null;
}
