export const schema = [
    {
        description: 'agence.designation',
        title: 'Agence',
        className: 'border-bottom'
    },
    {
        description: 'parent.code',
        title: 'Code rayon',
        className: 'border-bottom'
    },
    {
        description: 'parent.designation',
        title: 'Désignation rayon',
        className: 'border-bottom'
    },
    {
        description: 'code',
        title: 'Code colonne',
        className: 'border-bottom'
    },
    {
        description: 'designation',
        title: 'Désignation colonne',
        className: 'border-bottom'
    }
];
