import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST, DB_NODE } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    selectedCompteSource: {},
    selectedCompteDestination: {},
    montant: 0,
    taux: 0,
    tauxB: 0,
    isTaux: false,
    isTauxB: false
};

/**
 * @description defaultDependencies for our state
 */
const defaultDependencies = { fields: ['compte'] };

export function CreationChangeCompteForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, comptes, etatCompte, deviseReference } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            comptes: reduxState.comptes,
            etatCompte: reduxState.etat.comptes.etat,
            deviseReference: reduxState.deviseReference
        })
    );
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const formater = (tableau = []) =>
        tableau.map((item) => ({
            ...item,
            code: item.id,
            designation: `${item.institutionFinanciere?.designation} - ${item.numero} - ${item.devise}`
        }));
    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.COMPTE,
                functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
                etat: etatCompte,
                formater
            }
        ]
    });

    const getComptesDevise = (code) =>
        comptes.find((compte) => code === compte?.code)?.devise;

    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = { devises: [] };
        if (
            fields.isTaux &&
            getComptesDevise(fields.selectedCompteSource.code) !==
                deviseReference?.devise
        ) {
            payloadToAdd.devises.push({
                devise: getComptesDevise(fields.selectedCompteSource.code),
                valeur: fields.taux
            });
        }
        if (
            fields.isTauxB &&
            getComptesDevise(fields.selectedCompteDestination.code) !==
                deviseReference?.devise
        ) {
            payloadToAdd.devises.push({
                devise: getComptesDevise(fields.selectedCompteDestination.code),
                valeur: fields.tauxB
            });
        }
        if (!payloadToAdd.devises.length) {
            delete payloadToAdd.devises;
        }
        const payload = {
            newChangeEspece: {
                typeEntite: 'Compte',
                estTauxSollicite: !!payloadToAdd.devises?.length,
                valeurTypeEntite: {
                    reference: fields.selectedCompteSource.code
                },
                provenance: {
                    reference: fields.selectedCompteDestination.code
                },
                montant: fields.montant
            },
            ...payloadToAdd
        };
        // console.log('pay', payload);
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CHANGE_ESPECE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.OPERATION_CHANGE,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'compte',
                    etat: etatCompte
                }
            ]}
        >
            {/* <StateShowerComponent state={formState} /> */}
            <CustomRow>
                <CustomDropdown
                    options={comptes}
                    label="Compte à créditer*"
                    defaultOption="Sélectionner compte Source"
                    // disabled={![].length}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCompteSource"
                    id="selectedCompteSourcecreationChangeCompteId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="creationChangeCompteMontantId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!fields.selectedCompteSource.code &&
                    getComptesDevise(fields.selectedCompteSource.code) !==
                        deviseReference?.devise
                }
            >
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="iscreationChangeCompteId"
                    text="Renseigner un taux?"
                    value={fields.isTauxB}
                    name="isTaux"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.isTaux &&
                    getComptesDevise(fields.selectedCompteSource.code) !==
                        deviseReference?.devise
                }
            >
                <CustomInput
                    type="number"
                    label="Taux*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Taux"
                    formDispatcher={formDispatcher}
                    name="taux"
                    id="creationChangeComptTauxId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={comptes.filter(
                        (compte) =>
                            compte.code !== fields.selectedCompteSource.code &&
                            compte.devise !==
                                comptes.find(
                                    (item) =>
                                        item.code ===
                                        fields.selectedCompteSource.code
                                )?.devise
                    )}
                    label="Compte à débiter*"
                    defaultOption="Sélectionner Compte Destination"
                    // disabled={![].length}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCompteDestination"
                    id="selectedCompteDestinationcreationChangeCompteId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!fields.selectedCompteDestination.code &&
                    getComptesDevise(fields.selectedCompteDestination.code) !==
                        deviseReference?.devise
                }
            >
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="isTauxBcreationChangeCompteId"
                    text="Renseigner un taux?"
                    value={fields.isTauxB}
                    name="isTauxB"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.isTauxB &&
                    getComptesDevise(fields.selectedCompteDestination.code) !==
                        deviseReference?.devise
                }
            >
                <CustomInput
                    type="number"
                    label="Taux*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Taux"
                    formDispatcher={formDispatcher}
                    name="tauxB"
                    id="creationChangeCompteTauxBId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
