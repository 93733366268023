import React, { useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { FORM_CONST } from '@napp-inc/jnapp-util';
import { CustomCenteredModal, CustomCol, CustomRow } from '../container';
import { CustomButton } from '../button';

export function ImageInput({ formState, formDispatcher }) {
    const logoRef = useRef();
    const { fields, form } = formState;
    const getImage = () => {
        if (logoRef) {
            const canvasScaled = logoRef.current.getImage();
            const canvasScaledData = canvasScaled.toDataURL();
            formDispatcher({
                type: FORM_CONST.TEXT_FIELD_CHANGE,
                payload: {
                    field: 'logoTraiter',
                    data: canvasScaledData
                }
            });
        }
    };
    return (
        <CustomRow className="">
            <CustomCenteredModal
                header="Edit logo"
                show={fields.showModal}
                onHide={() =>
                    formDispatcher({
                        type: FORM_CONST.TEXT_FIELD_CHANGE,
                        payload: {
                            field: 'showModal',
                            data: false
                        }
                    })
                }
            >
                <CustomCol className="col-md-4">
                    <AvatarEditor
                        ref={logoRef}
                        image={fields.logo}
                        width={250}
                        height={250}
                        border={50}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={1.2}
                        rotate={0}
                        id="canvasProfile"
                    />
                </CustomCol>
                <CustomCol className="col-md-2">
                    <CustomButton
                        onClick={() => {
                            getImage();
                            formDispatcher({
                                type: FORM_CONST.TEXT_FIELD_CHANGE,
                                payload: {
                                    field: 'showModal',
                                    data: false
                                }
                            });
                        }}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    >
                        Enregistrer
                    </CustomButton>
                </CustomCol>
            </CustomCenteredModal>
            <CustomCol>
                <div
                    style={{
                        width: '210px',
                        height: '210px',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    <img
                        src={fields.logoTraiter || formState?.files?.logo?.url}
                        // src={formState.files?.logo?.url}
                        alt=""
                        className="float-start company-logo"
                    />
                    <div className="w-100" />
                    {/* <label className="" htmlFor="updateLogoMarchand"> */}
                    <input
                        defaultChecked
                        style={{
                            overflow: 'hidden',
                            position: 'absolute',
                            top: '160px',
                            left: '0',
                            opacity: '1',
                            width: '86px'
                        }}
                        className=""
                        type="file"
                        accept="image/jpg, image/png, image/jpeg"
                        id="updateLogoMarchand"
                        onChange={(e) => {
                            formDispatcher({
                                type: FORM_CONST.TEXT_FIELD_CHANGE,
                                payload: {
                                    field: 'logo',
                                    // data: e.target.files[0]
                                    data: URL.createObjectURL(e.target.files[0])
                                }
                            });
                            formDispatcher({
                                type: FORM_CONST.TEXT_FIELD_CHANGE,
                                payload: {
                                    field: 'file',
                                    data: e.target.files[0]
                                }
                            });
                            formDispatcher({
                                type: FORM_CONST.TEXT_FIELD_CHANGE,
                                payload: {
                                    field: 'showModal',
                                    data: true
                                }
                            });
                        }}
                    />
                </div>
            </CustomCol>
        </CustomRow>
    );
}
