import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, formatNumber } from '../../../util';

const groupementCompteByOrganisation = (comptes = []) => {
    const group = {};
    comptes.forEach((compte) => {
        const codeGroup = compte?.valeurTypeProprietaire?.code;
        if (Object.prototype.hasOwnProperty.call(group, codeGroup)) {
            group?.[codeGroup].balances.push(compte);
        } else {
            group[codeGroup] = {
                designation: compte?.valeurTypeProprietaire?.designation,
                code: codeGroup,
                balances: [compte]
            };
        }
    });
    const result = [];
    Object.getOwnPropertyNames(group).forEach((item) => {
        result.push({
            id: item,
            designation: group[item]?.designation,
            balances: group[item]?.balances
        });
    });
    return result;
};

export function ListCompteCautionOrganisationForm() {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const tdExtractor = (item) =>
        item?.balances?.map((bl) => (
            <td key={`${bl?.id}`}>
                {formatNumber({
                    value: bl?.solde,
                    devise: bl?.devise
                })}
            </td>
        ));
    return (
        <ListGenericForm
            reduxPropertyName="allCompteCautionOrganisation"
            functionName={URL_CONST.GET_LIST_COMPTE_USER}
            nodeName={REDUX_NODE_NAME.ALL_COMPTE_CAUTION_ORGANISATION}
            payload={{
                estCaution: true,
                estOrganisation: true,
                typeProprietaire: 'SuperDealer',
                typeOrganisation: nappUser?.typeEntite
            }}
            isLinkToDetail={false}
            formater={groupementCompteByOrganisation}
            mapper={[
                {
                    displayName: 'Caution',
                    field: 'designation',
                    position: 1
                },
                {
                    displayName: '',
                    field: 'balances',
                    tdExtractor
                }
            ]}
        />
    );
}
