import * as yup from 'yup';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    // PROFIL_SYSTEM,
    // CODE_SERVICE,
    TYPE_ORGANISATION,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
// import { useHasService } from '../../../util';
import { OperationBancaireForm } from './creation-form';
import { useHasService } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    montant: 0,
    selectedCaisse: {},
    selectedCompte: {},
    selectedOperation: {},
    selectedSuperDealer: {}
};

let defaultDependencies = { fields: ['caisses', 'comptes', 'banques'] };

const setDefaultProperties = ({ type, service }) => {
    const payload = {
        caisseNodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
        caisseReduxProperty: 'caissesActiveUser',
        superDealerNodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
        superDealerReduxProperty: 'allSuperDealerKinDB'
    };
    const conditions = {};
    if (
        type === TYPE_ORGANISATION.NAPP &&
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.DECAISSEMENT_OPERATION_BANCAIRE
                .code
        ] &&
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.INITIER_OPERATION_BANCAIRE.code
        ] &&
        (service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT?.code
        ] ||
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DECAISSEMENT?.code)
    ) {
        defaultDependencies = {
            fields: ['caisses', 'comptes', 'banques', 'superDealer']
        };
        payload.caisses = { estOrganisation: false };
        payload.caisseNodeName = REDUX_NODE_NAME.ALL_CAISSE_ACTIVE_USER;
        payload.caisseReduxProperty = 'allCaissesActiveUser';
        payload.superDealerNodeName = REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB;
        payload.superDealerReduxProperty = 'allSuperDealerKinDB';
        payload.superDealer = {
            typeEntite: type,
            estAll: true
        };
        conditions.isAll = true;
    }
    if (
        type === TYPE_ORGANISATION.NAPP &&
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.INITIER_OPERATION_BANCAIRE.code
        ] &&
        !service[
            CODE_SERVICE.FINANCE_ORGANISATION.DECAISSEMENT_OPERATION_BANCAIRE
                .code
        ]
    ) {
        defaultDependencies = {
            fields: ['caisses', 'comptes', 'banques', 'superDealer']
        };
        payload.caisses = {
            estOrganisation: true,
            typeProprietaire: 'SuperDealer',
            estAll: true
        };
        payload.caisseNodeName = REDUX_NODE_NAME.ALL_CAISSE_SUPER_DEALER;
        payload.caisseReduxProperty = 'allCaissesSuperDealer';
        payload.superDealerNodeName = REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB;
        payload.superDealerReduxProperty = 'allSuperDealerKinDB';
        payload.superDealer = {
            typeEntite: type,
            estAll: true
        };
        conditions.isAll = true;
    }
    const yupValidation = yup.object().shape({
        montant: yup.number().min(1),
        selectedCaisse: yup.object().shape({
            id: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedInstitutionFinanciere: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedSuperDealer: yup.object().shape({
            code: yup.string(),
            designation: yup.string()
        }),
        selectedCompte: yup.object().shape({
            id: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedOperation: yup.object().shape({
            code: yup.string().required()
        })
    });
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yupValidation,
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yupValidation,
            cfPayload: payload,
            cfCondition: conditions
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        payload.caisses = { estOrganisation: false };
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yupValidation,
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR) {
        payload.caisses = { estOrganisation: false };
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedCaisse: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedUser: yupValidation,
                montant: yup.number().min(1)
            }),
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({}),
        cfPayload: {},
        cfCondition: {}
    };
};

export function CreationOperationBancaireForm() {
    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.FINANCE_ORGANISATION.INITIER_OPERATION_BANCAIRE,
            CODE_SERVICE.FINANCE_ORGANISATION.DECAISSEMENT_OPERATION_BANCAIRE,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DECAISSEMENT
        ]
    });

    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser,
        organisation
    });

    const {
        fields,
        dependencies,
        elements,
        uiValidator,
        cfPayload,
        cfCondition
    } = setDefaultProperties({
        type: typeEntite,
        service: hasService
        // profil: profilUser
    });
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });
    const renderContent = () =>
        initialState.dependencies && cfPayload.caisseNodeName ? (
            <OperationBancaireForm
                idToken={idToken}
                typeEntite={typeEntite}
                uiValidator={uiValidator}
                initialState={initialState}
                hasService={hasService}
                cfPayload={cfPayload}
                cfCondition={cfCondition}
            />
        ) : null;
    return renderContent();
}
