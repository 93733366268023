import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListVersionApplicationForm() {
    return (
        <ListGenericForm
            reduxPropertyName="versionApplication"
            functionName={URL_CONST.GET_LIST_VERSION_APPLICATION_INITIALS}
            nodeName={REDUX_NODE_NAME.VERSION_APPLICATION}
            mapper={[
                {
                    displayName: 'Application',
                    field: 'application.designation'
                },
                {
                    displayName: 'Version',
                    field: 'version'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Date Fin',
                    field: 'dateObsolete',
                    isDate: true
                },
                {
                    displayName: 'Est Actif',
                    field: 'estActif',
                    isBoolean: true
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
