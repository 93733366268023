import { FORM_CONST } from '@napp-inc/jnapp-util';
import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

/**
 * Lecteur de code QR
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {Function} param0.formDispatcher Dispatcher du formulaire
 * @param {string} param0.name Nom du champ correspondant au QR code
 * @param {Function} param0.callback Callback appelé lorsque le QR code est lu
 * @param {string} param0.backCamera valeur définissant laquelle des caméra est utilisé pour la lecture du QR code (front ou back)
 * @returns {React.Component}
 */
export function Scanner({ formDispatcher, name, callback, backCamera }) {
    const [data, setData] = useState('No result');
    const constraint = {
        facingMode: { exact: backCamera ? 'environment' : 'user' }
    };
    return (
        <>
            <QrReader
                constraints={constraint}
                onResult={(result) => {
                    if (result) {
                        setData(result?.text);
                        formDispatcher({
                            type: FORM_CONST.TEXT_FIELD_CHANGE,
                            payload: {
                                field: name,
                                data: result?.text
                            }
                        });
                        callback(result?.text);
                    }
                }}
                style={{ width: '100%' }}
            />
            <p>{data}</p>
        </>
    );
}
