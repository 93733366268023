import * as yup from 'yup';

/**
 * @description : Schema de Detail du Point de Livraison création form
 */
export const DetailPaiementCompteExecutionSchema = yup.object().shape({
    actionEncours: yup.string(),
    numeroReference: yup.string(),
    nombreCoupureName: yup.number().min(1),
    selectedCoupureName: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    listBilletageName: yup.array().of(
        yup
            .object({
                id: yup.string(),
                devise: yup.string(),
                coupure: yup.number().min(1),
                nombre: yup.number().min(1)
            })
            .shape({})
    )
});
