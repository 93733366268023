import {
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { Str, formInitialState } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useShouldBeRendered } from './use-should-be-rendered';

/**
 * Retourne un objet contenant la plupart des éléments requis sur un formulaire des détails
 * @param {Object} param Objet passé en paramètre avec les propriétés suivantes
 * @param {String} param.nodeName nom du noeud dans le store redux
 * @param {Object} param.defaultFields Objet contenant les propriétés à a ajouter dans le state du formulaire
 * @param {Object} param.defaultDependencies Objet contenant les dependances à a ajouter dans le state du formulaire
 * @param {Object} param.defaultElements Objet contenant les éléments à a ajouter dans le state du formulaire
 * @param {Object} param.defaultFiles Objet contenant les fichiers à a ajouter dans le state du formulaire
 * @param {String} param.etatPath chemin de la propriété état dans l'objet, par défaut ce chemin vaut : "etat"
 * @param {Array} param.listEtatFinaux liste des états finaux de l'entity
 * @param {Array} param.listNode liste des noms des propriétés redux et de leur fonction de formatage
 * @param {Array} param.listFunctions liste des objects qui seront envoyés au hook useFetchAndListening
 * @returns {Object}
 */
export function useGetDetailForm({
    nodeName,
    defaultFields = { actionEncours: '' },
    defaultDependencies = { entity: true },
    defaultElements,
    defaultFiles,
    etatPath = 'etat',
    listEtatFinaux = [],
    listNode = [], // [{name: 'reduxNameProperty', format: (item) => {return someFormatter(item)}}]
    listFunctions = []
}) {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    // const { firebaseUser, entity, nappUser } = useSelector((reduxState) => {
    const reduxProps = useSelector((reduxState) => {
        const reduxProperties = {};
        listNode.forEach((node) => {
            reduxProperties[node.name] = node.format
                ? node.format(reduxState[node.name])
                : reduxState[node.name];
            reduxProperties[`etat${Str.ucFirst(node.name)}`] =
                reduxState.etat[node.name]?.etat;
        });
        return {
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation,
            entity: reduxState[nodeName].find((element) => element.id === id),
            ...reduxProperties
        };
    });
    const { idToken, creator, profilUser, typeEntite, isNapp, oid, uid } =
        useGetUserDetail({
            firebaseUser: reduxProps.firebaseUser,
            nappUser: reduxProps.nappUser,
            organisation: reduxProps.organisation
        });
    const { isShouldBeRendered } = useShouldBeRendered({
        entity: reduxProps.entity,
        list: listEtatFinaux,
        etat: etatPath
    });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements,
        files: defaultFiles
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const list = listFunctions.map((item) => {
        if (Object.hasOwn(reduxProps, item.id)) {
            return { ...item, etat: reduxProps[`etat${Str.ucFirst(item.id)}`] };
        }
        return { ...item };
    });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list
    });
    if (!nodeName) return "Une erreur s'est produite";
    return {
        id,
        reduxDispatcher,
        entity: reduxProps.entity,
        idToken,
        creator,
        formState,
        formDispatcher,
        profilUser,
        typeEntite,
        isShouldBeRendered,
        reduxProps,
        isNapp,
        oid,
        uid
    };
}
