import { GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { CustomAlert } from '../alert';

/**
 * Construit une dropdown où l'on peut sélectionner un agent du super dealer
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */

export function AgentSuperDealerSelector({
    formDispatcher,
    formState,
    idProperty = 'id',
    name = 'selectedAgentSuperDealer',
    functionName = URL_CONST.GET_LIST_LIMIT_CREDIT_ORGANISATION,
    nodeName = REDUX_NODE_NAME.AGENT_SUPER_DEALER_KIN_DISTRIBUTION,
    payload = {
        typeProprietaire: 'SuperDealer'
    },
    reduxNodeName = 'agentsSuperDealerKinDB'
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, agentsSuperDealerKinDB, etatAgentSuperDealerKinDB } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            agentsSuperDealerKinDB: reduxState[reduxNodeName].map((item) => ({
                ...item,
                designation: `${item?.valeurTypeProprietaire?.prenom || ''} ${
                    item?.valeurTypeProprietaire?.nom || ''
                }`
            })),
            etatAgentSuperDealerKinDB: reduxState.etat[reduxNodeName].etat
        }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName,
                nodeName,
                etat: etatAgentSuperDealerKinDB,
                payload
            }
        ]
    });
    const renderContent = () => {
        if (
            etatAgentSuperDealerKinDB ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <CustomDropdown
                    options={agentsSuperDealerKinDB}
                    defaultOption="Selectionner agent"
                    label="Stock: "
                    labelClassName="col-12"
                    divClassName="col-12"
                    formDispatcher={formDispatcher}
                    name={name}
                    id="selectedAgentSuperDealerStockSuperDealerSelector"
                    formState={formState}
                    idProperty={idProperty}
                    keyProperty="id"
                />
            );
        }
        if (
            etatAgentSuperDealerKinDB ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des agents du super dealer" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
