import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponent } from '../releve/generic';
import { URL_CONST } from '../../../util';
import { UserAndDeviseSelector } from '../../../components';

const defaultFields = {
    debut: '',
    fin: '',
    previewValue: '',
    configuration: 'SuperDealer',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isDisplayChart: false,
    selectedDevise: {},
    selectedAgent: {},
    isFetching: false,
    aggregated: {},
    devise: '',
    chartTitle: '',
    yAxisTittle: ''
};

const mapper = [
    {
        dataExtractor: (item) => `${item?.agent?.prenom} ${item?.agent?.nom}`,
        displayName: 'Agent'
    },
    {
        field: `typeEntite`,
        displayName: 'Type'
    },
    {
        field: 'entree',
        displayName: 'entree'
    },
    {
        field: 'sortie',
        displayName: 'sortie'
    },
    {
        field: 'devise',
        displayName: 'Devise'
    },
    {
        field: 'dateCreation',
        displayName: 'date création'
    }
];

export function RapportComptabilisationCompteForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements', 'comptes']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const fetchOnSuccess = ({ element }) => {
        const agregees = element || [];
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'aggregated',
                data: agregees
            }
        });
    };

    const fetchReport = () => {
        const { dateFin, dateDebut } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }

        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload: {
                    referenceAgent: fields.selectedAgent.uid,
                    estOrganisation: false,
                    dateDebut,
                    dateFin,
                    devise: fields.selectedDevise.devise
                },
                functionName:
                    URL_CONST.GET_LIST_COMPTABILISATION_COMPTE_ORGANISATION,
                etat: elements.mouvements.status,
                formDispatcher,
                onSuccess: fetchOnSuccess,
                reduxDispatcher
            })
        );
    };
    return (
        <MainReportComponent
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={mapper}
            dataList={elements.mouvements?.value || []}
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
            // topOfChart={MouvementCardsComponent}
        >
            <UserAndDeviseSelector
                formState={formState}
                formDispatcher={formDispatcher}
            />
        </MainReportComponent>
    );
}
