/* eslint-disable react/no-array-index-key */
import { useCallback, useState } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    TIME,
    formInitialState,
    BUDGET_ELABORATION,
    TYPE_BUDGET_ADDITIONNEL,
    TYPE_BUDGET_PREVISIONNEL,
    CHARGE,
    BUDGET_AMENDEMENT,
    MOYEN_PAIEMENT,
    getPeriodesOfYear,
    fromStringToTimestamp,
    BENEFICIAIRE,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillMinusCircle } from 'react-icons/ai';
import { IoIosAddCircleOutline, IoMdAdd } from 'react-icons/io';
// import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { URL_CONST } from '../../../../../util';
import { REDUX_NODE_NAME } from '../../../../../redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    // CustomCenteredModal,
    CustomCol,
    CustomDropdown,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../../../components';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedDirection: {},
    estPrevisionnel: false
};

/**
 * @description dependencies du state du formulaire
 */
const defaultDependencies = {
    fields: [
        'directions',
        'secteurs',
        'charges',
        'caisses',
        'exercicesFinancier',
        'employes',
        'users',
        'partenaires'
    ]
};

/**
 * Principale vue des vues pour les charges fixes
 * @param {Object} param0 : prends en compte toutes les props possibles
 * @param {String} param0.typeBudget: qui sera du type 'Elaboration | Completion | Ammendement'
 * @param {String} param0.isAdditionnel: qui sera du type true | False, pour savoir si c'est un prévisionnel ou un additionnel
 * @param {String} param0.isCompletion: qui sera du type true | False, pour savoir si on fait la completion d'une élaboration existante
 * @returns
 */
export function ChargesFixes({
    isAdditionnel = false,
    isCompletion = false,
    isAmendement = false,
    idEntity,
    payloadUrl
}) {
    const reduxDispatcher = useDispatch();
    // const [show, setShow] = useState(false);
    // const onHide = () => setShow(false);
    // const onShow = () => setShow(true);
    // Begin Region
    const [formFields, setFormFields] = useState([
        {
            element: {
                code: '',
                designation: '' /** Property to add for displaying purpose */
            },
            typeMoyenPaiement: '',
            idCaisse: '',
            caisseDesignation: '' /** Property to add for displaying purpose */,
            estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
            estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
            prix: 0,
            details: [
                {
                    direction: '',
                    directionDesignation:
                        '' /** Property to add for displaying purpose */,
                    secteur: {
                        code: '',
                        designation: ''
                    },
                    periodes: [
                        {
                            dateDebut: 0,
                            dateFin: 0,
                            datePaiement: 0,
                            mois: '' /** Property to add for displaying month */,
                            beneficiaires: [
                                {
                                    typeEntite: '',
                                    typeEntiteDesignation:
                                        '' /** Property to add for displaying purpose */,
                                    valeurTypeEntite: {
                                        reference: '',
                                        designation:
                                            '' /** Property to add for displaying purpose */
                                    },
                                    quantite: 0,
                                    montant: 0
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]);
    // End Region

    const {
        firebaseUser,
        charges,
        etatCharge,
        secteurs,
        etatSecteurs,
        directions,
        etatDirections,
        caisses,
        etatCaisse,
        exerciceFinancier,
        etatExerciceFinancier,
        users,
        etatUsers,
        employesOrganisation,
        etatEmployesOrganisation,
        partenaire,
        etatPartenaire
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        charges: reduxState.chargesFixe,
        etatCharge: reduxState.etat.chargesFixe.etat,
        secteurs: reduxState.secteurs,
        etatSecteurs: reduxState.etat.secteurs.etat,
        directions: reduxState.directions,
        etatDirections: reduxState.etat.directions.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeEntite?.designation}-${item?.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat,
        exerciceFinancier: reduxState.exerciceFinancier,
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat,

        users: reduxState.users.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.USER.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatUsers: reduxState.etat.users.etat,
        employesOrganisation: reduxState.employesOrganisation
            .filter((item) => !item?.idUser)
            .map((item) => ({
                ...item,
                typeBeneficiaire: BENEFICIAIRE.EMPLOYE.code,
                designation: `${item?.personne?.prenom || ' '} ${
                    item?.personne?.nom || ' '
                }`
            })),
        etatEmployesOrganisation: reduxState.etat.employesOrganisation.etat,
        partenaire: reduxState.beneficiaires.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.PARTENAIRE.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatPartenaire: reduxState.etat.beneficiaires.etat
    }));

    /**
     * @description: exercice financier format
     */
    const exercices = exerciceFinancier.map((item) => ({
        ...item,
        designation: `${TIME.fromUtcTimestampToLocalString(
            item.dateDebut
        )} - ${TIME.fromUtcTimestampToLocalString(item.dateFin)}`
    }));

    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form /** elements */ } = formState;

    const exerciceCible = exercices.find(
        (item) => item.id === fields?.selectedExercice.id
    );
    const periodes = useCallback(
        () =>
            getPeriodesOfYear({
                startOf: exerciceCible?.dateDebut || payloadUrl?.start,
                endOf: exerciceCible?.dateFin || payloadUrl?.end
            })?.map((item) => ({
                code: item.dateDebut,
                designation: item.mois,
                ...item
            })) || [],
        [
            exerciceCible?.dateDebut,
            exerciceCible?.dateFin,
            payloadUrl?.end,
            payloadUrl?.start
        ]
    )();

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_FIXE,
                nodeName: REDUX_NODE_NAME.CHARGE_FIXE,
                etat: etatCharge
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_DIRECTION, // Toutes les directions
                nodeName: REDUX_NODE_NAME.DIRECTION,
                etat: etatDirections
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUsers
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EMPLOYE_ORGANISATION,
                nodeName: DB_NODE.EMPLOYE,
                etat: etatEmployesOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PARTENAIRE,
                nodeName: REDUX_NODE_NAME.BENEFICIAIRE,
                etat: etatPartenaire
            }
        ]
    });

    /**
     * Start Region
     */

    /**
     * @description: Cette fonction recoit le type de la donnée et la donnée
     * pour retourner une valeur dans un format précis
     * @param {Object} param0 : Tout le paramètre
     * @param {string} param0.type : paramètre type
     * @param {Any} param0.data : paramètre data
     * @returns {Any}: data
     */
    const castTypeOfData = ({ type, data }) => {
        if (type === 'date') return fromStringToTimestamp(data);
        if (type === 'number') return Number(data);
        return data;
    };

    // For input
    /**
     * @description: Logique handleChange pour les inputs
     * @param {Object} param0 : Tout le paramètre
     * @param {Number} param0.index : Index de l'element au plus haut niveau de l'arborescence, voir le state
     * @param {Event} param0.event : Event de l'object input de HTML
     * @param {Number} param0.indexAtSecondLevel : Index de l'element au second niveau de l'arborescence, voir le state
     * @param {Number} param0.indexAtThirdLevel : Index de l'element au troisième niveau de l'arborescence, voir le state
     * @param {Number} param0.id : Index de l'element au quatrième niveau de l'arborescence, voir le state
     */
    const handleChange = ({
        index,
        event,
        indexAtSecondLevel,
        indexAtThirdLevel,
        id
    }) => {
        const values = [...formFields];
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au premier niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            values[index][event.target.name] = castTypeOfData({
                type: event.target.type,
                data: event.target.value
            });
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au deuxième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            values[index].details[indexAtSecondLevel][event.target.name] =
                castTypeOfData({
                    type: event.target.type,
                    data: event.target.value
                });
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au troisième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id !== 'number'
        ) {
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ][event.target.name] = castTypeOfData({
                type: event.target.type,
                data: event.target.value
            });
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au quatrième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id === 'number'
        ) {
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].beneficiaires[id][event.target.name] = castTypeOfData({
                type: event.target.type,
                data: event.target.value
            });
        }
        setFormFields(values);
    };

    /**
     *
     * @param {Object} param0 : Tout le paramètre
     * @param {String} param0.name : name de l'input du formulaire qui peut être de différent format
     * @returns {Array | String} ['partOne', 'partTwo'] or 'name'
     */
    const getNames = ({ name }) => {
        if (name.includes('|')) return name.split('|');
        if (name.includes('.')) return name.split('.');
        return name;
    };

    // For Dropdown
    /**
     * @description: Logique handleChange pour les select input
     * @param {Object} param0 : Tout le paramètre
     * @param {Number} param0.index : Index de l'element au plus haut niveau de l'arborescence, voir le state
     * @param {Event} param0.event : Event de l'object input de HTML
     * @param {Number} param0.indexAtSecondLevel : Index de l'element au second niveau de l'arborescence, voir le state
     * @param {Number} param0.indexAtThirdLevel : Index de l'element au troisième niveau de l'arborescence, voir le state
     * @param {Number} param0.id : Index de l'element au quatrième niveau de l'arborescence, voir le state
     */
    const handleSelectChange = ({
        index,
        event,
        indexAtSecondLevel,
        indexAtThirdLevel,
        id
    }) => {
        const values = [...formFields];
        const names = getNames({ name: event.target.name });
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au premier niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            /** Cette condition permet de savoir si la charge est de type remplie automatiquement
             * dans le select input nous avons rajouter une propriété customtypecharge pour le test fait ici
             */
            if (
                event.target.attributes?.customtypecharge?.nodeValue ===
                'estRempliAutomatique'
            ) {
                const findedElement = charges.find(
                    (element) => element.code === event.target.value
                );
                values[index][
                    event.target.attributes.customtypecharge.nodeValue
                ] =
                    !!findedElement?.[
                        event.target.attributes.customtypecharge.nodeValue
                    ];
            }
            /** Cette condition permet de savoir si la charge est de type estQuantite
             * dans le select input nous avons rajouter une propriété customproperty pour le test fait ici
             */
            if (
                event.target.attributes?.customproperty?.nodeValue ===
                'estQuantite'
            ) {
                const findedElement = charges.find(
                    (element) => element.code === event.target.value
                );
                values[index][
                    event.target.attributes.customproperty.nodeValue
                ] =
                    !!findedElement?.[
                        event.target.attributes.customproperty.nodeValue
                    ];
            }
            /**
             * Pour le dropdown moyen paiement, On vérifie si c'est différent
             * de cash pour vider la propriété idCaisse et caisseDesignation
             */
            if (
                event.target.attributes?.customproperty?.nodeValue ===
                'estMoyenPaiement'
            ) {
                const targetedPropsIdToChange = 'idCaisse';
                const targetedPropsNameToChange = 'caisseDesignation';
                values[index][targetedPropsIdToChange] =
                    event.target.value !== 'Cash'
                        ? ''
                        : values[index][targetedPropsIdToChange];
                values[index][targetedPropsNameToChange] =
                    event.target.value !== 'Cash'
                        ? ''
                        : values[index][targetedPropsNameToChange];
            }
            /**
             * @desc: serie de tests, pour traiter les select input avec des name de type
             * (property),
             * (property|property),
             * (object.property),
             * (object.property1|object.property2)
             */
            if (names.length > 1 && event.target.name.includes('|')) {
                const [f, s] = names;
                if (f.includes('.')) {
                    const [newFf, newFS] = getNames({ name: f });
                    values[index][newFf][newFS] = event.target.value;
                }
                if (s.includes('.')) {
                    const [newSf, newSs] = getNames({ name: s });
                    values[index][newSf][newSs] =
                        typeof event.target.value === 'string'
                            ? event.target.selectedOptions['0'].innerText
                            : event.target.value + 9999;
                }
                if (!f.includes('.') && !s.includes('.')) {
                    values[index][f] = event.target.value;
                    values[index][s] =
                        typeof event.target.value === 'string'
                            ? event.target.selectedOptions['0'].innerText
                            : event.target.value + 9999;
                }
            } else if (names.length > 1 && event.target.name.includes('.')) {
                const [f, s] = names;
                values[index][f][s] = event.target.value;
            } else {
                values[index][event.target.name] = event.target.value;
            }
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au deuxième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            if (names.length > 1 && event.target.name.includes('|')) {
                const [f, s] = names;
                if (f.includes('.')) {
                    const [newFf, newFS] = getNames({ name: f });
                    values[index].details[indexAtSecondLevel][newFf][newFS] =
                        event.target.value;
                }
                if (s.includes('.')) {
                    const [newSf, newSs] = getNames({ name: s });
                    values[index].details[indexAtSecondLevel][newSf][newSs] =
                        typeof event.target.value === 'string'
                            ? event.target.selectedOptions['0'].innerText
                            : event.target.value + 9999;
                }
                if (!f.includes('.') && !s.includes('.')) {
                    values[index].details[indexAtSecondLevel][f] =
                        event.target.value;
                    values[index].details[indexAtSecondLevel][s] =
                        typeof event.target.value === 'string'
                            ? event.target.selectedOptions['0'].innerText
                            : event.target.value + 9999;
                }
            } else if (names.length > 1 && event.target.name.includes('.')) {
                const [f, s] = names;
                values[index].details[indexAtSecondLevel][f][s] =
                    event.target.value;
            } else {
                values[index].details[indexAtSecondLevel][event.target.name] =
                    event.target.value;
            }
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au troisième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id !== 'number'
        ) {
            const targetedMonth = periodes.length
                ? periodes.find(
                      (item) => String(item.code) === event.target.value
                  )
                : {};
            if (names.length > 1 && event.target.name.includes('|')) {
                const [f, s] = names;
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ][f] = targetedMonth?.dateDebut || event.target.value;
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ][s] = targetedMonth?.dateFin || event.target.value;
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ].mois = targetedMonth?.mois?.toUpperCase() || '';
            } else if (names.length > 1 && event.target.name.includes('.')) {
                const [f, s] = names;
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ][f][s] = event.target.value;
            } else {
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ][event.target.name] = event.target.value;
            }
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au quatrième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id === 'number'
        ) {
            if (names.length > 1 && event.target.name.includes('|')) {
                const [f, s] = names;

                // Old Logic
                // values[index].details[indexAtSecondLevel].periodes[
                //     indexAtThirdLevel
                // ].beneficiaires[id][f] = event.target.value;
                // values[index].details[indexAtSecondLevel].periodes[
                //     indexAtThirdLevel
                // ].beneficiaires[id][s] =
                //     typeof event.target.value === 'string'
                //         ? event.target.selectedOptions['0'].innerText
                //         : event.target.value + 9999;
                // New logic
                if (f.includes('.')) {
                    const [newFf, newFS] = getNames({ name: f });
                    values[index].details[indexAtSecondLevel].periodes[
                        indexAtThirdLevel
                    ].beneficiaires[id][newFf][newFS] = event.target.value;
                }
                if (s.includes('.')) {
                    const [newSf, newSs] = getNames({ name: s });
                    values[index].details[indexAtSecondLevel].periodes[
                        indexAtThirdLevel
                    ].beneficiaires[id][newSf][newSs] =
                        typeof event.target.value === 'string'
                            ? event.target.selectedOptions['0'].innerText
                            : event.target.value + 9999;
                }
                if (!f.includes('.') && !s.includes('.')) {
                    values[index].details[indexAtSecondLevel].periodes[
                        indexAtThirdLevel
                    ].beneficiaires[id][f] = event.target.value;
                    values[index].details[indexAtSecondLevel].periodes[
                        indexAtThirdLevel
                    ].beneficiaires[id][s] =
                        typeof event.target.value === 'string'
                            ? event.target.selectedOptions['0'].innerText
                            : event.target.value + 9999;
                }
            } else if (names.length > 1 && event.target.name.includes('.')) {
                const [f, s] = names;
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ].beneficiaires[id][f][s] = event.target.value;
            } else {
                values[index].details[indexAtSecondLevel].periodes[
                    indexAtThirdLevel
                ].beneficiaires[id][event.target.name] = event.target.value;
            }
        }

        setFormFields(values);
    };

    // For Adding Element
    const handleAddHighLevel = (
        type,
        ancestorId,
        indexAtSecondLevel,
        indexAtThirdLevel
    ) => {
        if (type === 'Charge') {
            // console.log('Adding Index');
            setFormFields([
                ...formFields,
                {
                    element: {
                        code: '',
                        designation:
                            '' /** Property to add for displaying purpose */
                    },
                    typeMoyenPaiement: '',
                    idCaisse: '',
                    caisseDesignation:
                        '' /** Property to add for displaying purpose */,
                    estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
                    estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
                    prix: 0,
                    details: [
                        {
                            direction: '',
                            directionDesignation:
                                '' /** Property to add for displaying purpose */,
                            secteur: {
                                code: '',
                                designation: ''
                            },
                            periodes: [
                                {
                                    dateDebut: 0,
                                    dateFin: 0,
                                    datePaiement: 0,
                                    mois: '' /** Property to add for displaying month */,
                                    beneficiaires: [
                                        {
                                            typeEntite: '',
                                            typeEntiteDesignation:
                                                '' /** Property to add for displaying purpose */,
                                            valeurTypeEntite: {
                                                reference: '',
                                                designation:
                                                    '' /** Property to add for displaying purpose */
                                            },
                                            quantite: 0,
                                            montant: 0
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]);
        }
        if (type === 'Secteur' && typeof ancestorId === 'number') {
            const values = [...formFields];
            values[ancestorId].details.push({
                direction: '',
                directionDesignation:
                    '' /** Property to add for displaying purpose */,
                secteur: {
                    code: '',
                    designation: ''
                },
                periodes: [
                    {
                        dateDebut: 0,
                        dateFin: 0,
                        datePaiement: 0,
                        mois: '' /** Property to add for displaying month */,
                        beneficiaires: [
                            {
                                typeEntite: '',
                                typeEntiteDesignation:
                                    '' /** Property to add for displaying purpose */,
                                valeurTypeEntite: {
                                    reference: '',
                                    designation:
                                        '' /** Property to add for displaying purpose */
                                },
                                quantite: 0,
                                montant: 0
                            }
                        ]
                    }
                ]
            });
            setFormFields(values);
        }
        if (
            type === 'Periodes' &&
            typeof ancestorId === 'number' &&
            typeof indexAtSecondLevel === 'number'
        ) {
            const values = [...formFields];
            values[ancestorId].details[indexAtSecondLevel].periodes.push({
                dateDebut: 0,
                dateFin: 0,
                datePaiement: 0,
                mois: '' /** Property to add for displaying month */,
                beneficiaires: [
                    {
                        typeEntite: '',
                        typeEntiteDesignation:
                            '' /** Property to add for displaying purpose */,
                        valeurTypeEntite: {
                            reference: '',
                            designation:
                                '' /** Property to add for displaying purpose */
                        },
                        quantite: 0,
                        montant: 0
                    }
                ]
            });
            setFormFields(values);
        }
        if (
            type === 'Beneficiaires' &&
            typeof ancestorId === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number'
        ) {
            const values = [...formFields];
            values[ancestorId].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].beneficiaires.push({
                typeEntite: '',
                typeEntiteDesignation:
                    '' /** Property to add for displaying purpose */,
                valeurTypeEntite: {
                    reference: '',
                    designation:
                        '' /** Property to add for displaying purpose */
                },
                quantite: 0,
                montant: 0
            });
            setFormFields(values);
        }
    };

    // For Removing Element
    const handleRemove = (index, indexAtSecondLevel, indexAtThirdLevel, id) => {
        const values = [...formFields];
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number' &&
            values.length > 1
        ) {
            values.splice(index, 1);
        }
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number' &&
            values[index].details.length > 1
        ) {
            values[index].details.splice(indexAtSecondLevel, 1);
        }
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id !== 'number' &&
            values[index].details[indexAtSecondLevel].periodes.length > 1
        ) {
            values[index].details[indexAtSecondLevel].periodes.splice(
                indexAtThirdLevel,
                1
            );
        }
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id === 'number' &&
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].beneficiaires.length > 1
        ) {
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].beneficiaires.splice(id, 1);
        }
        setFormFields(values);
    };

    // For Getting Beneficiaire
    const getTypeBeneficiaire = useCallback(
        ({ id }) => {
            if (id === BENEFICIAIRE?.EMPLOYE?.code) return employesOrganisation;
            if (id === BENEFICIAIRE?.USER?.code) return users;
            if (id === BENEFICIAIRE?.PARTENAIRE?.code) return partenaire;
            return [];
        },
        [employesOrganisation, partenaire, users]
    );

    /**
     * End Region
     */

    const handleSubmit = (e) => {
        e.preventDefault();
        /**
         * @description : Payload
         */
        const payloadToAdd = {};
        const payloadToAddOut = {};
        if (isAdditionnel) {
            payloadToAdd.estPrevisionnel = fields.estPrevisionnel;
        }
        if (isCompletion || isAmendement) {
            payloadToAddOut.idElaborationAmendement = idEntity;
        }
        const payload = {
            periode: {
                dateDebut: exerciceCible.dateDebut || payloadUrl?.start,
                dateFin: exerciceCible.dateFin || payloadUrl?.end
            },
            newElaborationAmendement: {
                typeEntite: isAmendement
                    ? BUDGET_AMENDEMENT
                    : BUDGET_ELABORATION,
                typeProprietaire: CHARGE.code,
                estVariable: false,
                type: isAdditionnel
                    ? TYPE_BUDGET_ADDITIONNEL.code
                    : TYPE_BUDGET_PREVISIONNEL.code,
                ...payloadToAdd
            },
            creator,
            list: [],
            ...payloadToAddOut
        };
        if (formFields.length) {
            payload.list = formFields.map((item) => {
                if (item.estRempliAutomatique)
                    return {
                        element: item.element,
                        typeMoyenPaiement: item.typeMoyenPaiement
                    };
                return item;
            });
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    // const options = {
    //     // default is `save`
    //     method: 'open',
    //     // default is Resolution.MEDIUM = 3, which should be enough, higher values
    //     // increases the image quality but also the size of the PDF, so be careful
    //     // using values higher than 10 when having multiple pages generated, it
    //     // might cause the page to crash or hang.
    //     resolution: Resolution.HIGH,
    //     page: {
    //         // margin is in MM, default is Margin.NONE = 0
    //         margin: Margin.SMALL,
    //         // default is 'A4'
    //         format: 'letter',
    //         // default is 'portrait'
    //         orientation: 'landscape'
    //     },
    //     canvas: {
    //         // default is 'image/jpeg' for better size performance
    //         mimeType: 'image/png',
    //         qualityRatio: 1
    //     },
    //     // Customize any value passed to the jsPDF instance and html2canvas
    //     // function. You probably will not need this and things can break,
    //     // so use with caution.
    //     overrides: {
    //         // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    //         pdf: {
    //             compress: true
    //         },
    //         // see https://html2canvas.hertzen.com/configuration for more options
    //         canvas: {
    //             useCORS: true
    //         }
    //     }
    // };

    // you can use a function to return the target element besides using React refs
    // const getTargetElement = () => document.getElementById('content-id');

    const renderContent = () => (
        <FormWrapper
            isRedirect={!isCompletion && !isAmendement}
            onSubmit={handleSubmit}
            formState={formState}
            isStepBack={isCompletion || isAmendement}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'directions',
                    etat: etatDirections
                },
                {
                    dependency: 'exercicesFinancier',
                    etat: etatExerciceFinancier
                },
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'charges',
                    etat: etatCharge
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisse
                },
                {
                    dependency: 'employes',
                    etat: etatEmployesOrganisation
                },
                {
                    dependency: 'users',
                    etat: etatUsers
                },
                {
                    dependency: 'partenaires',
                    etat: etatPartenaire
                }
            ]}
        >
            {/* <CustomCenteredModal
                show={show}
                onHide={onHide}
                header="Budget"
                fullscreen
                // size="lg"
                // backdrop={backdrop}
            >
                <button
                    type="button"
                    onClick={() => generatePDF(getTargetElement, options)}
                >
                    Generate PDF
                </button>
                <div id="content-id">
                    <table className="table table-bordered">
                        <tr>
                            <th>Charges</th>
                            <th>Mode Paiement</th>
                            <th>Caisse</th>
                            <th>Prix (Unité)</th>
                            <th>Secteur</th>
                            <th>Période</th>
                            <th>Bénéficiaire</th>
                            <th>Quantite ou Montant</th>
                        </tr>
                        <tr>
                            <td>January</td>
                            <td>$100</td>
                        </tr>
                        <tr>
                            <td>February</td>
                            <td>$50</td>
                        </tr>
                    </table>
                </div>
            </CustomCenteredModal> */}
            <ConditionalRenderingWrapper isShouldBeRendered={!idEntity}>
                <CustomRow>
                    <CustomDropdown
                        options={exercices}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner un exercice"
                        label="Exercice*"
                        selectionClassName="form-select-sm"
                        labelClassName="col-12"
                        divClassName="col-12 col-sm-5"
                        name="selectedExercice"
                        id="selectedExerciceId"
                        idProperty="id"
                        // uiValidator={uiValidator}
                        // callback={onChangeAfterSelect}
                        // resetOnChangeState
                        // fieldsReseted={{
                        //     ...initialState.fields,
                        //     selectedExercice: fields.selectedExercice,
                        //     chargeElaboration: fields.chargeElaboration
                        // }}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!!fields.selectedExercice.id}
            >
                <ConditionalRenderingWrapper isShouldBeRendered={isAdditionnel}>
                    <CustomSwitchButton
                        id="selectionElaborationAdditionnelSwitchId"
                        name="estPrevisionnel"
                        text="Agir sur le budget prévisionnel?"
                        value={fields.estPrevisionnel}
                        formState={formState}
                        formDispatcher={formDispatcher}
                        divClassName="col-sm-3"
                    />
                </ConditionalRenderingWrapper>
                {/* <hr /> */}
                {/* <BsPlusCircleDotted/> */}
                {formFields.map((field, index) => (
                    <div key={index} className="my-3">
                        <div className="row d-flex align-items-center">
                            <div className="col-3">
                                <span
                                    style={{
                                        fontSize: '13px',
                                        paddingBottom: '8px',
                                        lineHeight: '20px',
                                        minHeight: '20px',
                                        display: 'block',
                                        color: 'rgba(0,0,0,0.5)'
                                    }}
                                >
                                    Charge
                                </span>
                                <select
                                    onChange={(event) =>
                                        handleSelectChange({ index, event })
                                    }
                                    className="form-select form-select-sm col-3"
                                    aria-label="Default select example"
                                    name="element.code|element.designation"
                                    customproperty="estQuantite"
                                    customtypecharge="estRempliAutomatique"
                                    value={field?.element?.code}
                                >
                                    <option hidden>
                                        Selectionnez une charge
                                    </option>
                                    {charges
                                        .sort((a, b) =>
                                            a?.designation?.localeCompare(
                                                b?.designation
                                            )
                                        )
                                        .map((element) => (
                                            <option
                                                value={element.code}
                                                key={element.code}
                                            >
                                                {element.designation}
                                            </option>
                                        ))}
                                </select>
                                <div style={{ height: '16px' }}>
                                    <div
                                        style={{
                                            height: 0
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2">
                                <span
                                    style={{
                                        fontSize: '13px',
                                        paddingBottom: '8px',
                                        lineHeight: '20px',
                                        minHeight: '20px',
                                        display: 'block',
                                        color: 'rgba(0,0,0,0.5)'
                                    }}
                                >
                                    Mode de Paiement
                                </span>
                                <select
                                    onChange={(event) =>
                                        handleSelectChange({ index, event })
                                    }
                                    className="form-select form-select-sm col-3"
                                    aria-label="Default select example"
                                    name="typeMoyenPaiement"
                                    customproperty="estMoyenPaiement"
                                    value={field?.typeMoyenPaiement}
                                >
                                    <option hidden>Mode de Paiement</option>
                                    {MOYEN_PAIEMENT.MOYEN_PAIEMENT_CHARGE_FIXE.map(
                                        (element) => (
                                            <option
                                                value={element.code}
                                                key={element.code}
                                            >
                                                {element.designation}
                                            </option>
                                        )
                                    )}
                                </select>
                                <div style={{ height: '16px' }}>
                                    <div
                                        style={{
                                            height: 0
                                        }}
                                    />
                                </div>
                            </div>
                            {field?.typeMoyenPaiement ===
                            MOYEN_PAIEMENT.CASH ? (
                                <div className="col-2">
                                    <span
                                        style={{
                                            fontSize: '13px',
                                            paddingBottom: '8px',
                                            lineHeight: '20px',
                                            minHeight: '20px',
                                            display: 'block',
                                            color: 'rgba(0,0,0,0.5)'
                                        }}
                                    >
                                        Caisse
                                    </span>
                                    <select
                                        onChange={(event) =>
                                            handleSelectChange({ index, event })
                                        }
                                        className="form-select form-select-sm col-3"
                                        aria-label="Default select example"
                                        name="idCaisse|caisseDesignation"
                                        value={field?.idCaisse}
                                    >
                                        <option hidden>
                                            Selectionnez la caisse
                                        </option>
                                        {caisses
                                            .filter(
                                                (caisse) =>
                                                    caisse.devise ===
                                                    charges.find(
                                                        (charge) =>
                                                            charge.code ===
                                                            field?.element?.code
                                                    )?.devise
                                            )
                                            .map((element) => (
                                                <option
                                                    value={element.id}
                                                    key={element.id}
                                                >
                                                    {element.designation}
                                                </option>
                                            ))}
                                    </select>
                                    <div style={{ height: '16px' }}>
                                        <div
                                            style={{
                                                height: 0
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {charges.find(
                                (charge) => charge.code === field.element.code
                            )?.estQuantite ? (
                                <div className="col-2">
                                    <span
                                        style={{
                                            fontSize: '13px',
                                            paddingBottom: '8px',
                                            lineHeight: '20px',
                                            minHeight: '20px',
                                            display: 'block',
                                            color: 'rgba(0,0,0,0.5)'
                                        }}
                                    >
                                        Prix
                                    </span>
                                    <input
                                        type="number"
                                        name="prix"
                                        placeholder="Enter value"
                                        className="form-control form-control-sm col-2"
                                        value={field.prix}
                                        onChange={(event) =>
                                            handleChange({ index, event })
                                        }
                                    />
                                    <div style={{ height: '16px' }}>
                                        <div
                                            style={{
                                                height: 0
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <div className="col-auto pt-2">
                                <span
                                    role="button"
                                    title="Supprimer toutes les informations liées à cette charge"
                                    className="text-secondary"
                                    tabIndex={0}
                                    onClick={() => handleRemove(index)}
                                    onKeyDown={() => handleRemove(index)}
                                >
                                    <AiFillMinusCircle className="fs-4" />
                                </span>
                            </div>
                        </div>
                        {!charges.find(
                            (charge) => charge.code === field.element.code
                        )?.estRempliAutomatique ? (
                            <div className="d-flex flex-column ms-3">
                                {formFields[index].details.map(
                                    (detail, detailIndex) => (
                                        <div
                                            key={detailIndex}
                                            className="border-start"
                                        >
                                            <div className="d-flex flex-row align-items-center">
                                                <div
                                                    style={{
                                                        height: '46px',
                                                        width: '16px'
                                                    }}
                                                >
                                                    <p />
                                                </div>
                                                <div className="col-3 me-3">
                                                    <span
                                                        style={{
                                                            fontSize: '13px',
                                                            paddingBottom:
                                                                '8px',
                                                            lineHeight: '20px',
                                                            minHeight: '20px',
                                                            display: 'block',
                                                            color: 'rgba(0,0,0,0.5)'
                                                        }}
                                                    >
                                                        Direction
                                                    </span>
                                                    <select
                                                        className="form-select form-select-sm col-3"
                                                        aria-label="Default select example"
                                                        name="direction|directionDesignation"
                                                        // customproperty="directionDesignation"
                                                        value={
                                                            detail?.direction
                                                        }
                                                        onChange={(event) =>
                                                            handleSelectChange({
                                                                index,
                                                                event,
                                                                indexAtSecondLevel:
                                                                    detailIndex
                                                            })
                                                        }
                                                    >
                                                        <option hidden>
                                                            Selectionnez une
                                                            direction
                                                        </option>
                                                        {directions.map(
                                                            (direction) => (
                                                                <option
                                                                    value={
                                                                        direction.code
                                                                    }
                                                                    key={
                                                                        direction.code
                                                                    }
                                                                >
                                                                    {
                                                                        direction.designation
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    <div
                                                        style={{
                                                            height: '16px'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 0
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-3 me-3">
                                                    <span
                                                        style={{
                                                            fontSize: '13px',
                                                            paddingBottom:
                                                                '8px',
                                                            lineHeight: '20px',
                                                            minHeight: '20px',
                                                            display: 'block',
                                                            color: 'rgba(0,0,0,0.5)'
                                                        }}
                                                    >
                                                        Secteur
                                                    </span>
                                                    <select
                                                        className="form-select form-select-sm col-3"
                                                        aria-label="Default select example"
                                                        name="secteur.code|secteur.designation"
                                                        value={
                                                            detail?.secteur
                                                                ?.code
                                                        }
                                                        onChange={(event) =>
                                                            handleSelectChange({
                                                                index,
                                                                event,
                                                                indexAtSecondLevel:
                                                                    detailIndex
                                                            })
                                                        }
                                                        disabled={
                                                            charges.find(
                                                                (charge) =>
                                                                    charge.code ===
                                                                    field
                                                                        .element
                                                                        .code
                                                            )
                                                                ?.estRempliAutomatique
                                                        }
                                                    >
                                                        <option hidden>
                                                            Selectionnez un
                                                            secteur
                                                        </option>
                                                        {secteurs
                                                            .filter(
                                                                (secteur) => {
                                                                    if (
                                                                        formFields[
                                                                            index
                                                                        ]
                                                                            .details[
                                                                            detailIndex
                                                                        ]
                                                                            .direction ===
                                                                        'OKAPI_DIRECT_NAPP'
                                                                    )
                                                                        return secteur
                                                                            ?.direction
                                                                            ?.estNapp;
                                                                    return !secteur
                                                                        ?.direction
                                                                        ?.estNapp;
                                                                }
                                                            )
                                                            .map((secteur) => (
                                                                <option
                                                                    value={
                                                                        secteur.code
                                                                    }
                                                                    key={
                                                                        secteur.code
                                                                    }
                                                                >
                                                                    {
                                                                        secteur.designation
                                                                    }
                                                                </option>
                                                            ))}
                                                    </select>
                                                    <div
                                                        style={{
                                                            height: '16px'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 0
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-auto pt-2 me-3">
                                                    <span
                                                        role="button"
                                                        title="Supprimer Informations du secteur"
                                                        className="text-secondary"
                                                        tabIndex={0}
                                                        onClick={() =>
                                                            handleRemove(
                                                                index,
                                                                detailIndex
                                                            )
                                                        }
                                                        onKeyDown={() =>
                                                            handleRemove(
                                                                index,
                                                                detailIndex
                                                            )
                                                        }
                                                    >
                                                        <AiFillMinusCircle className="fs-4" />
                                                    </span>
                                                </div>
                                                <div className="col-auto pt-2">
                                                    <span
                                                        role="button"
                                                        title="Ajouter Secteur"
                                                        className="text-primary"
                                                        tabIndex={0}
                                                        onClick={() =>
                                                            handleAddHighLevel(
                                                                'Secteur',
                                                                index
                                                            )
                                                        }
                                                        onKeyDown={() =>
                                                            handleAddHighLevel(
                                                                'Secteur',
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <IoMdAdd className="fs-4" />
                                                    </span>
                                                </div>
                                            </div>

                                            {formFields[index].details[
                                                detailIndex
                                            ].periodes.map(
                                                (periodeItem, periodeIndex) => (
                                                    <div
                                                        key={periodeIndex}
                                                        className="border-start"
                                                        style={{
                                                            marginLeft: '30px'
                                                        }}
                                                    >
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div
                                                                style={{
                                                                    height: '46px',
                                                                    width: '16px'
                                                                }}
                                                            >
                                                                <p />
                                                            </div>
                                                            <div className="col-3 me-3">
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '13px',
                                                                        paddingBottom:
                                                                            '8px',
                                                                        lineHeight:
                                                                            '20px',
                                                                        minHeight:
                                                                            '20px',
                                                                        display:
                                                                            'block',
                                                                        color: 'rgba(0,0,0,0.5)'
                                                                    }}
                                                                >
                                                                    Période
                                                                </span>
                                                                <select
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        handleSelectChange(
                                                                            {
                                                                                index,
                                                                                event,
                                                                                indexAtSecondLevel:
                                                                                    detailIndex,
                                                                                indexAtThirdLevel:
                                                                                    periodeIndex
                                                                            }
                                                                        )
                                                                    }
                                                                    className="form-select form-select-sm col-3"
                                                                    aria-label="Default select example"
                                                                    name="dateDebut|dateFin"
                                                                    customproperty="periodes"
                                                                    value={
                                                                        periodeItem?.dateDebut
                                                                    }
                                                                >
                                                                    <option
                                                                        hidden
                                                                    >
                                                                        Selectionnez
                                                                        un
                                                                        période
                                                                    </option>
                                                                    {periodes.map(
                                                                        (
                                                                            periode
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    periode.code
                                                                                }
                                                                                key={
                                                                                    periode.code
                                                                                }
                                                                            >
                                                                                {
                                                                                    periode.designation
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                                <div
                                                                    style={{
                                                                        height: '16px'
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: 0
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-3 me-3">
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '13px',
                                                                        paddingBottom:
                                                                            '8px',
                                                                        lineHeight:
                                                                            '20px',
                                                                        minHeight:
                                                                            '20px',
                                                                        display:
                                                                            'block',
                                                                        color: 'rgba(0,0,0,0.5)'
                                                                    }}
                                                                >
                                                                    Date de
                                                                    paiement
                                                                </span>
                                                                <input
                                                                    className="form-control form-control-sm col-3"
                                                                    name="datePaiement"
                                                                    type="date"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        handleChange(
                                                                            {
                                                                                index,
                                                                                event,
                                                                                indexAtSecondLevel:
                                                                                    detailIndex,
                                                                                indexAtThirdLevel:
                                                                                    periodeIndex
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                                <div
                                                                    style={{
                                                                        height: '16px'
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: 0
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-auto pt-2 me-3">
                                                                <span
                                                                    role="button"
                                                                    title="Supprimer Informations de la Période"
                                                                    className="text-secondary"
                                                                    tabIndex={0}
                                                                    onClick={() =>
                                                                        handleRemove(
                                                                            index,
                                                                            detailIndex,
                                                                            periodeIndex
                                                                        )
                                                                    }
                                                                    onKeyDown={() =>
                                                                        handleRemove(
                                                                            index,
                                                                            detailIndex,
                                                                            periodeIndex
                                                                        )
                                                                    }
                                                                >
                                                                    <AiFillMinusCircle className="fs-4" />
                                                                </span>
                                                            </div>
                                                            <div className="col-auto pt-2">
                                                                <span
                                                                    role="button"
                                                                    title="Ajouter Période"
                                                                    className="text-primary"
                                                                    tabIndex={0}
                                                                    onClick={() =>
                                                                        handleAddHighLevel(
                                                                            'Periodes',
                                                                            index,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                    onKeyDown={() =>
                                                                        handleAddHighLevel(
                                                                            'Periodes',
                                                                            index,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                >
                                                                    <IoMdAdd className="fs-4" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {formFields[
                                                            index
                                                        ].details[
                                                            detailIndex
                                                        ].periodes[
                                                            periodeIndex
                                                        ].beneficiaires.map(
                                                            (
                                                                beneficiaireItem,
                                                                beneficiaireIndex
                                                            ) => (
                                                                // eslint-disable-next-line react/no-array-index-key
                                                                <div
                                                                    key={
                                                                        beneficiaireIndex
                                                                    }
                                                                    className="d-flex flex-row align-items-center ms-4"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            height: '46px',
                                                                            width: '16px'
                                                                        }}
                                                                    >
                                                                        <p />
                                                                    </div>
                                                                    <div className="col-2 me-3">
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    '13px',
                                                                                paddingBottom:
                                                                                    '8px',
                                                                                lineHeight:
                                                                                    '20px',
                                                                                minHeight:
                                                                                    '20px',
                                                                                display:
                                                                                    'block',
                                                                                color: 'rgba(0,0,0,0.5)'
                                                                            }}
                                                                        >
                                                                            Type
                                                                            Bénéficiaire
                                                                        </span>
                                                                        <select
                                                                            className="form-select form-select-sm col-3"
                                                                            aria-label="Default select typeEntite"
                                                                            name="typeEntite"
                                                                            value={
                                                                                beneficiaireItem?.typeEntite
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleSelectChange(
                                                                                    {
                                                                                        index,
                                                                                        event,
                                                                                        indexAtSecondLevel:
                                                                                            detailIndex,
                                                                                        indexAtThirdLevel:
                                                                                            periodeIndex,
                                                                                        id: beneficiaireIndex
                                                                                    }
                                                                                )
                                                                            }
                                                                        >
                                                                            <option
                                                                                hidden
                                                                            >
                                                                                Selectionnez
                                                                                un
                                                                                type
                                                                                bénéficiaire
                                                                            </option>
                                                                            {BENEFICIAIRE.CHARGE_FIXE.map(
                                                                                (
                                                                                    benefCharge
                                                                                ) => (
                                                                                    <option
                                                                                        value={
                                                                                            benefCharge.code
                                                                                        }
                                                                                        key={
                                                                                            benefCharge.code
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            benefCharge.designation
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                        <div
                                                                            style={{
                                                                                height: '16px'
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    height: 0
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2 me-3">
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    '13px',
                                                                                paddingBottom:
                                                                                    '8px',
                                                                                lineHeight:
                                                                                    '20px',
                                                                                minHeight:
                                                                                    '20px',
                                                                                display:
                                                                                    'block',
                                                                                color: 'rgba(0,0,0,0.5)'
                                                                            }}
                                                                        >
                                                                            Bénéficiaire
                                                                        </span>
                                                                        <select
                                                                            className="form-select form-select-sm col-3"
                                                                            aria-label="Default select example"
                                                                            name="valeurTypeEntite.reference|valeurTypeEntite.designation"
                                                                            value={
                                                                                beneficiaireItem
                                                                                    ?.valeurTypeEntite
                                                                                    ?.reference
                                                                            }
                                                                            customproperty="beneficiaire"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleSelectChange(
                                                                                    {
                                                                                        index,
                                                                                        event,
                                                                                        indexAtSecondLevel:
                                                                                            detailIndex,
                                                                                        indexAtThirdLevel:
                                                                                            periodeIndex,
                                                                                        id: beneficiaireIndex
                                                                                    }
                                                                                )
                                                                            }
                                                                        >
                                                                            <option
                                                                                hidden
                                                                            >
                                                                                Selectionnez
                                                                                Bénéficiaire
                                                                            </option>
                                                                            {getTypeBeneficiaire(
                                                                                {
                                                                                    id: formFields[
                                                                                        index
                                                                                    ]
                                                                                        ?.details[
                                                                                        detailIndex
                                                                                    ]
                                                                                        ?.periodes[
                                                                                        periodeIndex
                                                                                    ]
                                                                                        ?.beneficiaires[
                                                                                        beneficiaireIndex
                                                                                    ]
                                                                                        ?.typeEntite
                                                                                }
                                                                            ).map(
                                                                                (
                                                                                    user
                                                                                ) => (
                                                                                    <option
                                                                                        value={
                                                                                            user.id
                                                                                        }
                                                                                        key={
                                                                                            user.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            user.designation
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                        <div
                                                                            style={{
                                                                                height: '16px'
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    height: 0
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2 me-3">
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    '13px',
                                                                                paddingBottom:
                                                                                    '8px',
                                                                                lineHeight:
                                                                                    '20px',
                                                                                minHeight:
                                                                                    '20px',
                                                                                display:
                                                                                    'block',
                                                                                color: 'rgba(0,0,0,0.5)'
                                                                            }}
                                                                        >
                                                                            {`${
                                                                                charges.find(
                                                                                    (
                                                                                        charge
                                                                                    ) =>
                                                                                        charge.code ===
                                                                                        formFields[
                                                                                            index
                                                                                        ]
                                                                                            .element
                                                                                            .code
                                                                                )
                                                                                    ?.estQuantite
                                                                                    ? 'Quantite'
                                                                                    : 'Montant'
                                                                            }`}
                                                                        </span>
                                                                        <input
                                                                            type="number"
                                                                            name={`${
                                                                                charges.find(
                                                                                    (
                                                                                        charge
                                                                                    ) =>
                                                                                        charge.code ===
                                                                                        formFields[
                                                                                            index
                                                                                        ]
                                                                                            .element
                                                                                            .code
                                                                                )
                                                                                    ?.estQuantite
                                                                                    ? 'quantite'
                                                                                    : 'montant'
                                                                            }`}
                                                                            placeholder="Saississez un nombre"
                                                                            className="form-control form-control-sm col-2"
                                                                            value={
                                                                                charges.find(
                                                                                    (
                                                                                        charge
                                                                                    ) =>
                                                                                        charge.code ===
                                                                                        formFields[
                                                                                            index
                                                                                        ]
                                                                                            .element
                                                                                            .code
                                                                                )
                                                                                    ?.estQuantite
                                                                                    ? beneficiaireItem.quantite
                                                                                    : beneficiaireItem.montant
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleChange(
                                                                                    {
                                                                                        index,
                                                                                        event,
                                                                                        indexAtSecondLevel:
                                                                                            detailIndex,
                                                                                        indexAtThirdLevel:
                                                                                            periodeIndex,
                                                                                        id: beneficiaireIndex
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                height: '16px'
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    height: 0
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-auto me-3">
                                                                        <span
                                                                            role="button"
                                                                            title="Supprimer Bénéficiaire"
                                                                            className="text-secondary"
                                                                            tabIndex={
                                                                                0
                                                                            }
                                                                            onClick={() =>
                                                                                handleRemove(
                                                                                    index,
                                                                                    detailIndex,
                                                                                    periodeIndex,
                                                                                    beneficiaireIndex
                                                                                )
                                                                            }
                                                                            onKeyDown={() =>
                                                                                handleRemove(
                                                                                    index,
                                                                                    detailIndex,
                                                                                    periodeIndex,
                                                                                    beneficiaireIndex
                                                                                )
                                                                            }
                                                                        >
                                                                            <AiFillMinusCircle className="fs-4" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <span
                                                                            role="button"
                                                                            title="Ajouter Bénéficiaire"
                                                                            className="text-primary"
                                                                            tabIndex={
                                                                                0
                                                                            }
                                                                            onClick={() =>
                                                                                handleAddHighLevel(
                                                                                    'Beneficiaires',
                                                                                    index,
                                                                                    detailIndex,
                                                                                    periodeIndex
                                                                                )
                                                                            }
                                                                            onKeyDown={() =>
                                                                                handleAddHighLevel(
                                                                                    'Beneficiaires',
                                                                                    index,
                                                                                    detailIndex,
                                                                                    periodeIndex
                                                                                )
                                                                            }
                                                                        >
                                                                            <IoMdAdd className="fs-4" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )
                                )}
                                <span
                                    style={{ marginLeft: '18px' }}
                                    role="button"
                                    title="Ajouter Secteur"
                                    className="text-secondary"
                                    tabIndex={0}
                                    onClick={() =>
                                        handleAddHighLevel('Secteur', index)
                                    }
                                    onKeyDown={() =>
                                        handleAddHighLevel('Secteur', index)
                                    }
                                >
                                    <IoIosAddCircleOutline className="fs-4" />
                                </span>
                            </div>
                        ) : null}
                    </div>
                ))}
                <span
                    style={{ marginLeft: '5px' }}
                    role="button"
                    title="Ajouter Charge"
                    className="text-secondary"
                    tabIndex={0}
                    onClick={() => handleAddHighLevel('Charge')}
                    onKeyDown={() => handleAddHighLevel('Charge')}
                >
                    <IoIosAddCircleOutline className="fs-4" />
                </span>
                {/* <div>
                    <span
                        role="button"
                        tabIndex={0}
                        title="Vérifier reférence"
                        onClick={onShow}
                        onKeyDown={onShow}
                        className="btn btn-outline-dark"
                    >
                        Afficher <AiOutlineFileSearch />
                    </span>
                </div> */}
                {/* <div>
                    <StateShowerComponent state={formFields} />
                </div> */}
                <CustomRow className="mt-3">
                    <CustomCol className="col-3">
                        <CustomButtonLoader
                            className="btn-success"
                            onClick={handleSubmit}
                            text="Soumettre elaboration"
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </ConditionalRenderingWrapper>
        </FormWrapper>
    );
    return renderContent();
}
