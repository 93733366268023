import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import {
    CheckingPaiementRelPath,
    ListCheckingPaiementRelPath,
    PaiementDetailRelPath
} from './checking-rel-path';
import { Paiement } from '../../../module';

export const CreateCheckingPaiementPath = {
    ...CheckingPaiementRelPath,
    Component: Paiement.CheckPaiementForm,
    element: <Paiement.CheckPaiementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PAIEMENT_SERVICE.LECTURE_LISTE_PAIEMENT,
        CODE_SERVICE.SERVICE_CODE_WEB.PAIEMENT_SERVICE
            .VALIDER_REFERENCE_PAIEMENT
    ]
};

export const ListCheckingPaiementPath = {
    ...ListCheckingPaiementRelPath,
    Component: Paiement.ListPaiementForm,
    element: <Paiement.ListPaiementForm />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PAIEMENT_SERVICE.LECTURE_LISTE_PAIEMENT,
        CODE_SERVICE.SERVICE_CODE_WEB.PAIEMENT_SERVICE
            .VALIDER_REFERENCE_PAIEMENT
    ]
};

export const PaiementDetailPath = {
    ...PaiementDetailRelPath,
    isVisible: false,
    Component: Paiement.DetailPaiementForm,
    element: <Paiement.DetailPaiementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.PAIEMENT_SERVICE.LECTURE_LISTE_PAIEMENT,
        CODE_SERVICE.SERVICE_CODE_WEB.PAIEMENT_SERVICE
            .VALIDER_REFERENCE_PAIEMENT
    ]
};
