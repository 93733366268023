import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ViewLayout } from './view-layout';

export function ListDeclarationRessource() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                reduxPropertyName="declarationsRessource"
                functionName={URL_CONST.GET_LIST_DECLARATION_RESSOURCE_INITIALS}
                nodeName={REDUX_NODE_NAME.DECLARATION_RESSOURCE}
                identifier="id"
                mapper={[
                    {
                        field: 'ressource.designation',
                        displayName: 'Ressource',
                        position: 1
                    },
                    {
                        field: 'secteur.designation',
                        displayName: 'Secteur',
                        position: 2
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 3
                    },
                    {
                        field: 'montant',
                        displayName: 'Montant',
                        position: 4
                    },
                    {
                        field: 'numeroReference',
                        displayName: 'Numero de Reference',
                        position: 5
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 6
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
