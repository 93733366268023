import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListServiceGenerateurForm() {
    return (
        <ListGenericForm
            reduxPropertyName="servicesGenerateur"
            functionName={URL_CONST.GET_LIST_SERVICE_GENERATEUR}
            nodeName={DB_NODE.SERVICE_GENERATEUR}
            mapper={[
                {
                    field: 'code',
                    displayName: 'code',
                    position: 1
                },
                {
                    field: 'designation',
                    displayName: 'designation',
                    position: 2
                },
                {
                    field: 'type',
                    displayName: 'type',
                    position: 3
                }
            ]}
        />
    );
}
