import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { RapportAgentKinDBForm } from './rapport-agent-kindb';
import { ReleveAgentForm } from './releve';

export function RapportAgentKinDBViewLayout() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    /**
     * Common form processes
     */
    const { typeEntite } = useGetUserDetail({
        nappUser
    });
    const [key, setKey] = useState('report');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Limite de crédit</h1>
            </div>
            <Tabs
                defaultActiveKey="report"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="report" title="Rapport">
                    {key === 'report' && (
                        <RapportAgentKinDBForm typeOrganisation={typeEntite} />
                    )}
                </Tab>
                <Tab eventKey="releve" title="Relevé">
                    {key === 'releve' && (
                        <ReleveAgentForm typeOrganisation={typeEntite} />
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}
