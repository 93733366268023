import { useDispatch, useSelector } from 'react-redux';
import {
    useFetchAndListening,
    useGetUserDetail,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { StockPhysiqueCard } from './stock-physique-card';
import { CenteredSpinner, CustomAlert } from '../../../components';

export function StockPhysiqueForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        etatStockSuperDealerDIntegrer,
        stocksSuperDealerKinDBIntegrer
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        stocksSuperDealerKinDBIntegrer:
            reduxState.stocksSuperDealerKinDBIntegrer,
        etatStockSuperDealerDIntegrer:
            reduxState.etat.stocksSuperDealerKinDBIntegrer.etat
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_STOCK_SUPER_DEALER,
                nodeName: REDUX_NODE_NAME.STOCK_SUPER_DEALER_INTEGRER,
                etat: etatStockSuperDealerDIntegrer,
                payload: { estIntegrer: true }
            }
        ]
    });
    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        etatChargement: etatStockSuperDealerDIntegrer
    });
    const renderContent = () => {
        if (!isLoadComplete && !isLoadFailed) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return (
                <CustomAlert error="Une erreur est survenur lors du chargement des stocks" />
            );
        }
        return (
            <div className="row py-3">
                {stocksSuperDealerKinDBIntegrer.map((item, index) => (
                    <div className="col-md-3 pb-1" key={item?.id}>
                        <StockPhysiqueCard
                            designation={
                                item?.produit?.designation ||
                                item?.marchand?.designation
                            }
                            idStock={item?.id}
                            numero={`Physique : ${item?.numero || ''}`}
                            icon={index + 1}
                            typeNumber={index + 1}
                        />
                    </div>
                ))}
            </div>
        );
    };
    return renderContent();
}
