import { CLASSE_CHARGE_VARIABLE } from '@napp-inc/jnapp-util';
import * as yup from 'yup';

/**
 * @description : Schema de charge
 */
export const ChargeSchema = yup.object().shape({
    selectedClasse: yup.string().required(),
    code: yup.string().required(),
    designation: yup.string().required(),
    selectedCompteImputation: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedDevise: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedTypeChargeVariable: yup.object().when('selectedClasse', {
        is: (selectedClasse) => selectedClasse === CLASSE_CHARGE_VARIABLE.code,
        then: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        otherwise: yup.object().shape({
            code: yup.string(),
            designation: yup.string()
        })
    }),
    selectedServiceGenerateur: yup.object().when('selectedClasse', {
        is: (selectedClasse) => selectedClasse === CLASSE_CHARGE_VARIABLE.code,
        then: yup.object().shape({
            id: yup.string().required(),
            designation: yup.string().required()
        }),
        otherwise: yup.object().shape({
            id: yup.string(),
            designation: yup.string()
        })
    }),
    estSous: yup.boolean()
});
