import * as yup from 'yup';

/**
 * @description : Schema de validation Homologuer point de livraison
 */
export const HomologuerPointLivraisonSchema = yup.object().shape({
    selectedTypeBeneficiaire: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedPays: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    searchInput: yup.string(),
    adresse: yup.string().required(),
    referenceLivraison: yup.string().required()
});
