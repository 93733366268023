export const schema = [
    {
        title: 'Montant',
        description: 'montant',
        className: 'border-bottom'
    },
    {
        title: 'Date Execution',
        description: 'dateExecution',
        className: 'border-bottom',
        isDate: true
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom',
        isDate: true
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
