import { buildYupErrorObject, FORM_CONST } from '@napp-inc/jnapp-util';
import { InputFeedback } from '../input/input-feedback';

/**
 * Select remplace le composant select de react-bootstrap et prends les mêmes attributs que le composant Natif
 * @param {Object} param0 object qui contient les props du composant:
 * @param {Array} param0.options liste des options
 * @param {String} param0.labelClassName classe CSS du label
 * @param {String} param0.divClassName classe CSS de la div
 * @param {String} param0.label label du datalist
 * @param {Function} param0.onChange fonction qui sera appelée lorsque l'utilisateur selectionnera une option
 * @param {String} param0.id id de l'élément html
 * @param {String} param0.placeholder placeholder du select
 * @param {String} param0.defaultOption valeur par défaut
 * @param {String} param0.name nom de la propriéte name du select
 * @param {String} param0.propertyToDisplay propriété à inclure dans l'objet data
 * @param {String} param0.idProperty propriété à inclure dans l'objet data comme identifiant
 * @param {Object} param0.formState State du composant
 * @param {Function} param0.formDispatcher Fonction qui permet d'envoyer des actions au store du composant
 * @param {Object} param0.uiValidator Schema de validation du composant
 * @param {Boolean} param0.disabled Permet de savoir si le composant est actif ou non
 * @param {Boolean} param0.resetOnChangeState Permet de savoir si le composant devra réinitialiser une partie du state
 * @param {Object} param0.newState Schema du nouveau state qui définira la portion du state à réinitialiser
 * @param {Object} param0.fieldsReseted Schema du nouveau state qui définira la portion du state à ne pas réinitialiser
 * @param {Boolean} param0.isShouldBeRendered defini à quelle condition le composant sera visible
 * @param {Boolean} param0.isSort defini à quelle condition le composant trie les entrées
 * @param {Function} param0.callback fonction de callback
 * @returns {React.Component}
 */

// FIXME: Controlled or Uncontrolled component ?

export function CustomDropdown({
    options = [],
    labelClassName = 'col-sm-2',
    divClassName = 'col-sm-10',
    label,
    selectionClassName,
    defaultOption,
    id,
    keyProperty,
    name,
    formDispatcher,
    formState,
    uiValidator,
    propertyToDisplay = 'designation',
    idProperty = 'code',
    disabled = false,
    resetOnChangeState = false,
    onFormatString,
    newState,
    fieldsReseted,
    isShouldBeRendered = true,
    isSort = true,
    callback,
    additionnalOptions = []
    // refValue,
}) {
    const { fields, form, error } = formState;
    // eslint-disable-next-line consistent-return
    const handleChange = (event) => {
        const { value: currentValue } = event.target;
        const { property } =
            event.target.options[event.target.selectedIndex].dataset;
        const data = {
            [idProperty]: currentValue,
            [propertyToDisplay]: property
        };
        if (!resetOnChangeState) {
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field: name,
                    data
                }
            });
        }
        if (callback) {
            return callback(data, fieldsReseted);
        }
        if (resetOnChangeState && newState) {
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE_AND_SET_NEW_STATE,
                payload: {
                    field: name,
                    data,
                    newState
                }
            });
        }
        if (uiValidator && form.isSubmitted) {
            uiValidator
                .validate({ ...fields, [name]: data }, { abortEarly: false })
                .then(() => {
                    formDispatcher({
                        type: FORM_CONST.VALIDATION_SUCCESS
                    });
                })
                .catch((erreur) => {
                    if (erreur.name === 'ValidationError') {
                        const err = buildYupErrorObject(erreur);
                        formDispatcher({
                            type: FORM_CONST.SET_FIELDS_ERROR,
                            payload: {
                                message: { ...err },
                                status: FORM_CONST.CREATION_ECHEC
                            }
                        });
                    }
                });
        }
    };

    const sortedOptions = isSort
        ? options.sort((a, b) =>
              a?.[propertyToDisplay]?.localeCompare(b?.[propertyToDisplay])
          )
        : options;
    const contentToRender = () =>
        isShouldBeRendered ? (
            <>
                {label ? (
                    <label
                        htmlFor={id}
                        className={`form-label ${labelClassName}`}
                    >
                        {label}
                    </label>
                ) : null}
                <div className={`has-validation ${divClassName}`}>
                    <select
                        // ref={refValue}
                        name={name}
                        id={id}
                        onChange={handleChange}
                        className={`form-select ${
                            error && error[name] ? `is-invalid` : null
                        } ${selectionClassName}`}
                        value={formState?.fields?.[name]?.[idProperty]}
                        disabled={disabled}
                    >
                        <option hidden>{defaultOption}</option>
                        {additionnalOptions
                            .concat(sortedOptions)
                            .map((element) => (
                                <option
                                    data-property={element[propertyToDisplay]}
                                    key={
                                        keyProperty
                                            ? element[keyProperty]
                                            : element[idProperty]
                                    }
                                    value={element[idProperty]}
                                >
                                    {typeof onFormatString === 'function'
                                        ? onFormatString(
                                              element[propertyToDisplay]
                                          )
                                        : element[propertyToDisplay]}
                                </option>
                            ))}
                    </select>
                    <InputFeedback>
                        {error && error[name] && error[name][idProperty]}
                    </InputFeedback>
                </div>
            </>
        ) : null;

    return <>{contentToRender()}</>;
}
