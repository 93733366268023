import { useSelector } from 'react-redux';
import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { StaticSelector, SuperDealerSelector } from '../../../../components';

export function RapportRetraitNonDeclareKinDBForm() {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedSuperDealer: {},
            selectedStatic: { id: 'USD', designation: 'USD' }
        }
    });

    const formater = (data = []) => {
        const result = [];
        (data?.[0]?.listReturn || []).forEach((item) => {
            if (
                item?.detail?.devise === formState?.fields?.selectedStatic?.id
            ) {
                result.push({
                    designation:
                        item?.detail?.valeurTypeProprietaire?.designation,
                    initial: item?.soldeOuverture,
                    solde: item?.soldeFermeture,
                    ajout: item?.entree,
                    declare: item?.sortie,
                    resultat: item?.total
                });
            }
        });
        return result;
    };
    const cutomTableHeader = () => (
        <>
            <tr className="border">
                <th className="border" rowSpan={2}>
                    #
                </th>
                <th className="border" rowSpan={2}>
                    Désignation
                </th>
                <th className="border" colSpan={3}>
                    Non déclaré
                </th>
                <th className="border" rowSpan={2}>
                    déclaré
                </th>
                <th className="border" rowSpan={2}>
                    Résultat
                </th>
            </tr>
            <tr className="border">
                <th className="border">Ouverture</th>
                <th className="border">Ajout</th>
                <th className="border">Fermeture</th>
            </tr>
        </>
    );
    return (
        <ReduxReportTemplate
            cutomTableHeader={cutomTableHeader}
            listTitle={`Retrait en ${formState?.fields?.selectedStatic?.id}`}
            dynamicNodeName={`retraitNonDeclareKinDistribution-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_COMPTE_USER}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            // dynamicColumnFormater={dynamicColumnFormater}
            mapper={[
                {
                    displayName: 'Super dealer',
                    field: 'designation'
                },
                {
                    displayName: 'Initial',
                    field: 'initial',
                    isTotal: true
                },
                {
                    displayName: 'Ajout',
                    field: 'ajout',
                    isTotal: true
                },
                {
                    displayName: 'Solde',
                    field: 'solde',
                    isTotal: true
                },
                {
                    displayName: 'Décalré',
                    field: 'declare',
                    isTotal: true
                },
                {
                    displayName: 'Résultat',
                    field: 'resultat',
                    isTotal: true
                }
            ]}
            isAddTotalTr
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                typeEntite: 'Journalier',
                type: 'NonDeclarerEmoney',
                typeProprietaire: 'SuperDealer',
                estOrganisation: true,
                estDevise: true,
                typeOrganisation: nappUser?.typeEntite
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier',
                type: 'NonDeclarerEmoney',
                typeProprietaire: 'SuperDealer',
                estOrganisation: true,
                estDevise: true,
                typeOrganisation: nappUser?.typeEntite
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedSuperDealer.code',
                    property: 'codeValeurTypeProprietaire',
                    isRequired: false,
                    displayName: 'Super dealer'
                }
            ]}
        >
            <StaticSelector
                formState={formState}
                formDispatcher={formDispatcher}
                options={[
                    { id: 'USD', designation: 'USD' },
                    { id: 'CDF', designation: 'CDF' }
                ]}
            />
            <SuperDealerSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                estNonAirtime
            />
        </ReduxReportTemplate>
    );
}
