import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    DynamicFormMounted,
    FormWrapper
} from '../../../components';
import { ServiceGenerateurSchema as uiValidator } from './validation';
import { mapper as fieldsMapper } from './form-fields';

const defaultFields = {
    designation: '',
    code: '',
    selectedType: {}
};

export function CreationServiceGenerateurForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const { code, designation, selectedType } = fields;
        const payload = {
            newServiceGenerateur: {
                code,
                designation,
                type: selectedType.code
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_SERVICE_GENERATEUR,
                payload,
                fields,
                reduxNodeName: DB_NODE.SERVICE_GENERATEUR,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <DynamicFormMounted
                formDispatcher={formDispatcher}
                formState={formState}
                uiValidator={uiValidator}
                fieldsMapper={fieldsMapper}
            />
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
