export const fieldsMapper = [
    {
        position: 0,
        type: 'text',
        id: 'creationSuperDealerDesignationInput',
        name: 'newAppointageDesignation',
        placeholder: "Désignation de l'organisation",
        label: 'Désignation*',
        isInputGroup: false
    },
    // {
    //     position: 1,
    //     type: 'text',
    //     id: 'creationSuperDealerCodeInput',
    //     name: 'newAppointageCode',
    //     placeholder: "Code de l'organisation",
    //     label: 'Code*',
    //     isInputGroup: false
    // },
    {
        position: 2,
        type: 'email',
        id: 'creationSuperDealerEmailInput',
        name: 'newAppointageEmail',
        placeholder: "Adresse Email de l'organisation",
        label: 'Adresse Email*',
        isInputGroup: false
    },
    {
        position: 3,
        type: 'text',
        id: 'creationSuperDealerAdressePhysiqueInput',
        isInputGroup: false,
        name: 'newAdresseAdresseUne',
        placeholder: 'Adresse Physique',
        label: 'Adresse Physique*'
    },
    {
        position: 4,
        type: 'text',
        id: 'creationSuperDealerNomProprietaireInput',
        name: 'newProprietaireNom',
        placeholder: 'Nom du propiétaire',
        label: 'Nom*',
        isInputGroup: false
    },
    {
        position: 5,
        type: 'text',
        id: 'creationSuperDealerPrenomProprietaireInput',
        name: 'newProprietairePrenom',
        placeholder: 'Prénom du propiétaire',
        label: 'Prénom*',
        isInputGroup: false
    },
    {
        position: 6,
        type: 'text',
        id: 'creationSuperDealerNumeroTelProprietaireInput',
        name: 'newProprietaireNumeroTel',
        placeholder: 'Numéro de téléphone du propiétaire',
        label: 'Numéro de téléphone*',
        isInputGroup: false
    },
    {
        position: 7,
        type: 'text',
        id: 'creationSuperDealerEMailProprietaireInput',
        name: 'newProprietaireEmail',
        placeholder: 'Adresse mail du propiétaire',
        label: 'Email*',
        isInputGroup: false
    },
    {
        position: 8,
        type: 'text',
        id: 'creationSuperDealerAdressePhysiqueProprietaireInput',
        name: 'newProprietaireAdresse',
        placeholder: 'Adresse Physique du propriétaire',
        label: 'Adresse Physique*',
        isInputGroup: false
    }
];

export const fieldsDistributeurMapper = [
    {
        position: 0,
        type: 'text',
        id: 'creationSuperDealerDesignationInput',
        name: 'newAppointageDesignation',
        placeholder: "Désignation de l'organisation",
        label: 'Désignation*',
        isInputGroup: false
    },
    {
        position: 2,
        type: 'email',
        id: 'creationSuperDealerEmailInput',
        name: 'newAppointageEmail',
        placeholder: "Adresse Email de l'organisation",
        label: 'Adresse Email*',
        isInputGroup: false
    }
];
