export const schema = [
    {
        description: 'client.designation',
        title: 'Client',
        className: 'border-bottom'
    },
    {
        description: 'valeurTypePartenariat.designation',
        title: 'Organisation',
        className: 'border-bottom'
    },
    {
        description: 'marchand.designation',
        title: 'Marchand',
        className: 'border-bottom'
    },
    {
        description: 'montant',
        title: 'montant',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'etat',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'dateCreation',
        className: 'border-bottom'
    }
];
