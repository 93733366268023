import { Outlet } from 'react-router-dom';
import { CheckLogout } from './check-logout';

export function SignInLayout() {
    return (
        <CheckLogout>
            <Outlet />
        </CheckLogout>
    );
}
