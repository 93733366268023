import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListRaisonForm() {
    return (
        <ListGenericForm
            reduxPropertyName="raisons"
            functionName={URL_CONST.GET_LIST_RAISON}
            nodeName={REDUX_NODE_NAME.RAISONS}
            mapper={[
                {
                    field: 'code',
                    displayName: 'Code'
                },
                {
                    field: 'designation',
                    displayName: 'Désignation'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
