import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { ReleveBalanceOrganisme } from '../../../../components';

export function ReleveBalanceRevendeurForm() {
    return (
        <ReleveBalanceOrganisme
            typeOrganisation={TYPE_ORGANISATION.REVENDEUR}
        />
    );
}
