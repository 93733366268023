import { FORM_CONST } from '@napp-inc/jnapp-util';
import { useState } from 'react';
import { RiArrowRightSFill, RiArrowDownSFill } from 'react-icons/ri';
import { fuzzySearch } from '../../util';
import { CustomConfirmButton } from '../button';
import { CustomCollapse } from '../container';
import { CustomInput } from '../input';

export function UserServiceSelectionComponent({
    formDispatcher,
    formState,
    selectedItemListName,
    title = 'actifs',
    dataList = [],
    buttonType = 'none',
    isOrganisation = false,
    onUpdate,
    inputName = 'name',
    keys = ['designation', 'code']
}) {
    const [show, setShow] = useState(true);
    const { form, fields } = formState;

    const resultat = fuzzySearch({
        array: dataList,
        keys,
        word: fields[inputName]
    });

    /**
     * @description modifier l'etat qui va devoir s'afficher
     */
    const handleShow = () => {
        setShow((e) => !e);
    };

    const handleChange = (e) => {
        const { id } = e.target;
        const existe = fields?.[selectedItemListName].find(
            (item) => item === id
        );
        if (existe) {
            formDispatcher({
                type: FORM_CONST.REMOVE_PRIMITIVE_ITEM,
                payload: {
                    field: selectedItemListName,
                    data: id
                }
            });
        } else {
            formDispatcher({
                type: FORM_CONST.ADD_PRIMITIVE_ITEM,
                payload: {
                    field: selectedItemListName,
                    data: id
                }
            });
        }
    };
    return (
        <div className="form-group row">
            {/* <div className="col-xs-12 col-md-2 col-form-label "> */}
            <div className="row mb-4">
                <span
                    role="button"
                    tabIndex={0}
                    onClick={handleShow}
                    onKeyDown={handleShow}
                    title="Etendre..."
                >
                    {!show ? <RiArrowRightSFill /> : <RiArrowDownSFill />}{' '}
                    Services {title} :
                </span>
            </div>
            <CustomCollapse isIn={show}>
                <div>
                    <div className="row">
                        <div className="mb-3 col-sm-5">
                            <CustomInput
                                inputClassName="form-control-sm"
                                placeholder="Recherche..."
                                formDispatcher={formDispatcher}
                                formState={formState}
                                name={inputName}
                            />
                        </div>
                        <div className="col-sm-5">
                            Résultat :{' '}
                            <span className="badge bg-primary">
                                {resultat?.length || 0}
                            </span>
                        </div>
                    </div>
                    <div
                        className="ml-3 col-xs-12 col-md-12"
                        style={{
                            maxHeight: '10em',
                            overflow: 'auto'
                        }}
                    >
                        <ul className="list-group">
                            {resultat
                                .filter(({ estActif }) => estActif)
                                .map(({ code, designation }) => (
                                    <li key={code} className="list-group-item">
                                        <input
                                            checked={
                                                !!fields?.[
                                                    selectedItemListName
                                                ].find((item) => item === code)
                                            }
                                            id={code}
                                            type="checkbox"
                                            className="form-check-input me-1"
                                            name={code}
                                            // defaultChecked={title === 'actifs'}
                                            onChange={handleChange}
                                        />
                                        {designation}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="row mt-3">
                        <div className="col align-self-end">
                            {!isOrganisation && buttonType === 'desactiver' && (
                                <CustomConfirmButton
                                    type="button"
                                    backdrop="static"
                                    text="Désactiver"
                                    className="btn-danger"
                                    confirmOnClick={() => onUpdate('activer')}
                                    disabled={
                                        form.status ===
                                            FORM_CONST.MODIFICATION_EN_COURS ||
                                        form.status ===
                                            FORM_CONST.CREATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours === 'activer'
                                    }
                                    confirmText="Poursuivre"
                                    cancelText="Abandonner"
                                    header="Confirmation Annulation"
                                />
                            )}
                            {buttonType === 'activer' && (
                                <CustomConfirmButton
                                    type="button"
                                    backdrop="static"
                                    text="Activer"
                                    className="btn-success"
                                    confirmOnClick={() =>
                                        onUpdate('desactiver')
                                    }
                                    disabled={
                                        form.status ===
                                            FORM_CONST.MODIFICATION_EN_COURS ||
                                        form.status ===
                                            FORM_CONST.CREATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours === 'desactiver'
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </CustomCollapse>
        </div>
    );
}
