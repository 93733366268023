import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    OperationBancaireCreateRelPath,
    DepotRelPath
} from '../../../routes/registration/configuration/config-napp-rel-path';

export function ViewLayout({ children }) {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Depot</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname === `/${DepotRelPath.path}` && (
                            <ShowNewComponent
                                services={{ code: 'test' }}
                                destination={
                                    OperationBancaireCreateRelPath.path
                                }
                            />
                        )}
                        <button
                            disabled={
                                pathname ===
                                `/${DepotRelPath.path}/${OperationBancaireCreateRelPath.path}`
                            }
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                        >
                            Export
                        </button>
                    </div>
                </div>
            </div>
            {children}
        </>
    );
}
