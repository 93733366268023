export const DenominationMapper = [
    {
        position: 0,
        type: 'text',
        name: 'designation',
        id: 'societyDesignation',
        placeholder: 'Désignation Entreprise',
        label: 'Désigantion de la société*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 1,
        type: 'text',
        name: 'code',
        id: 'societyAbr',
        placeholder: 'Code Entreprise',
        label: 'Sigle de la société*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 2,
        type: 'text',
        name: 'entrepriseNumero',
        id: 'entrepriseTelNum',
        placeholder: '243 xxx xxx xxx',
        label: 'Téléphone*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 3,
        type: 'email',
        name: 'email',
        id: 'societyOwnerEmail',
        placeholder: 'exemple@domaine.com',
        label: 'Adresse Email*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    }
];

export const AddressPhysiqueMapper = [
    {
        position: 0,
        type: 'text',
        name: 'adresseUne',
        id: 'societyAdr',
        placeholder: 'N°, Avenue, Quartier, Commune, Ville, Pays',
        label: 'Adresse du siège social*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    }
];

export const InfoCommercialMapper = [
    {
        position: 0,
        type: 'text',
        name: 'rccm',
        id: 'societyRccm',
        placeholder: 'ex: ORD-N-182/78/IG',
        label: 'RCCM ou Patente*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    }
];

export const ProprietaireInfoMapper = [
    {
        position: 0,
        type: 'text',
        name: 'prenom',
        id: 'societyOwnerFirstName',
        placeholder: 'Prénom',
        label: 'Prénom*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 1,
        type: 'text',
        name: 'nom',
        id: 'societyOwnerName',
        placeholder: 'Nom',
        label: 'Nom*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 2,
        type: 'text',
        name: 'postnom',
        id: 'societyOwnerLastName',
        placeholder: 'Postnom',
        label: 'Postnom*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 3,
        type: 'email',
        name: 'emailProprietaire',
        id: 'societyEmail',
        placeholder: 'exemple@domaine.com',
        label: 'Adresse Email du Propriétaire*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 4,
        type: 'text',
        name: 'numeroTel',
        id: 'societyOwnerPhone',
        placeholder: '243 xxx xxx xxx',
        label: 'Téléphone*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 5,
        type: 'text',
        name: 'adresseProprietaire',
        id: 'adresseProprietaireId',
        placeholder: '26, ByPass, Ngaliema; Kinshasa/RDC',
        label: 'Adresse*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    }
];
