import { URL_CONST, formatNumber } from '../../../util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

const groupementCompteByOrganisation = (caisses = []) => {
    const group = {};
    caisses.forEach((caisse) => {
        const codeGroup = caisse?.code;
        if (Object.prototype.hasOwnProperty.call(group, codeGroup)) {
            group?.[codeGroup].balances.push(caisse);
        } else {
            group[codeGroup] = {
                designation: caisse?.designation,
                code: codeGroup,
                estEmoney: caisse?.estEmoney,
                balances: [caisse]
            };
        }
    });
    const result = [];
    Object.getOwnPropertyNames(group).forEach((item) => {
        result.push({
            id: item,
            designation: group[item]?.designation,
            estEmoney: group[item]?.estEmoney,
            balances: group[item]?.balances
        });
    });
    return result;
};
export function ListCaisseEmoneyAllSuperDealerForm() {
    const tdExtractor = (item) =>
        item?.balances?.map((bl) => (
            <td key={`${bl?.id}`}>
                {formatNumber({
                    value: bl?.solde,
                    devise: bl?.devise
                })}
            </td>
        ));
    return (
        <ListGenericForm
            reduxPropertyName="soldeCaissesEmoney"
            functionName={URL_CONST.GET_LIST_CAISSE_ACTIVE_USER}
            nodeName={REDUX_NODE_NAME.SOLDE_CAISSE_EMONEY}
            payload={{ estOrganisation: true, typeProprietaire: 'SuperDealer' }}
            isLinkToDetail={false}
            formater={groupementCompteByOrganisation}
            mapper={[
                {
                    displayName: 'Organistion',
                    field: 'designation',
                    position: 1
                },
                {
                    displayName: 'Est Emoney',
                    field: 'estEmoney',
                    isBoolean: true,
                    position: 2
                },
                {
                    displayName: '',
                    field: 'balances',
                    tdExtractor
                }
            ]}
        />
    );
}
