import { ConditionalRenderingWrapper } from './conditional-rendering-wrapper';

/**
 * CustomRow remplace le composant Row de react-bootstrap et prends les mêmes attributs que le composant Natif
 * @param {Object} param0  Object qui contient les props du composant:
 * @param {string} param0.className Classe CSS
 * @param {Object} param0.style Style CSS
 * @param {React.Component} param0.children Contenu du composant
 * @param {Boolean} param0.isShouldBeRendered condition pour rendre le composant
 * @returns {React.Component}
 */
export function CustomRow({
    className,
    children,
    style,
    isShouldBeRendered = true
}) {
    return (
        <ConditionalRenderingWrapper isShouldBeRendered={isShouldBeRendered}>
            <div style={style} className={`row mb-3 ${className}`}>
                {children}
            </div>
        </ConditionalRenderingWrapper>
    );
}
