import { NATURE_PRODUIT } from '@napp-inc/jnapp-util';
import {
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomRow,
    ImageInput,
    SimpleTable
} from '../../../../components';

/**
 *
 * @param {*} param0 Object containing the following properties:
 * @param {Function} param0.formDispatcher Function to dispatch the form actions
 * @returns {JSX.Element}
 */
export function ProduitAirtime({
    formDispatcher,
    formState,
    uiValidator,
    adder,
    idToken
}) {
    const { fields } = formState;
    return (
        <>
            <small className="lead fs-5">Details Business</small>
            <ImageInput
                formDispatcher={formDispatcher}
                formState={formState}
                idToken={idToken}
            />
            <CustomRow>
                <div className="pt-3">
                    <CustomRow>
                        <CustomDropdown
                            label="Nature*"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                            defaultOption="Choisir une catégorie"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            name="nature"
                            id="choixCategorieNatureProduitId"
                            options={NATURE_PRODUIT.ALL}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomCollapse
                        isIn={
                            fields?.nature?.code ===
                            NATURE_PRODUIT.NATURE_PRODUIT_RECRUTEMENT
                        }
                    >
                        <CustomRow>
                            <CustomInput
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="recrutementProduitDesignation"
                                id="recrutementProduitDesignation"
                                placeholder="Désignation du produit Recrutement"
                                label="Designation*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="recrutementProduitCode"
                                id="recrutementProduitCode"
                                placeholder="Code du produit Recrutement"
                                label="Code*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="recrutementProduitTillNumber"
                                id="recrutementProduitTillNumber"
                                placeholder="Till number du produit Recrutement"
                                label="TillNumber*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="recrutementProduitDigit"
                                id="recrutementProduitDigit"
                                placeholder="Nombre de digit du produit Recrutement"
                                label="Digit*"
                                type="number"
                                min={0}
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="recrutementProduitPrixDeVente"
                                id="recrutementProduitPrixDeVenteId"
                                label="Prix de Vente"
                                type="number"
                                isFloat
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                                numberAfertComma={8}
                            />
                        </CustomRow>
                    </CustomCollapse>
                    <CustomCollapse
                        isIn={
                            fields?.nature?.code ===
                            NATURE_PRODUIT.NATURE_PRODUIT_PRIMAIRE
                        }
                    >
                        <CustomRow>
                            <CustomInput
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="primaireProduitDesignation"
                                id="primaireProduitDesignation"
                                placeholder="Désignation du produit primaire"
                                label="Designation*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="primaireProduitCode"
                                id="primaireProduitCode"
                                placeholder="Code du produit primaire"
                                label="Code*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                type="number"
                                isFloat
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="primaireProduitPrixSuperDealer"
                                id="primaireProduitPrixSuperDealer"
                                placeholder="Prix Super Dealer"
                                label="Prix D'achat*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                                numberAfertComma={8}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                type="number"
                                isFloat
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="primaireProduitPrixVente"
                                id="primaireProduitPrixVente"
                                placeholder="Prix de Vente du produit primaire"
                                label="Prix Vente Transfert*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                                numberAfertComma={8}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                type="number"
                                isFloat
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="primaireProduitPrixAchat"
                                id="primaireProduitPrixAchat"
                                placeholder="Prix d'achat du produit primaire"
                                label="Prix de vente recharge*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                                numberAfertComma={8}
                            />
                        </CustomRow>
                        {/* <CustomRow>
                            <CustomTextAreaInput
                                label="Syntaxe*"
                                rows={5}
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                formDispatcher={formDispatcher}
                                formState={formState}
                                isInputGroup={false}
                                placeholder="Saisir Syntaxe"
                                name="syntaxeProduitPrimaire"
                                id="syntaxeProduitPrimaireId"
                                uiValidator={uiValidator}
                            />
                        </CustomRow> */}
                    </CustomCollapse>
                    <CustomCollapse
                        isIn={
                            fields?.nature?.code ===
                            NATURE_PRODUIT.NATURE_PRODUIT_DERIVE
                        }
                    >
                        <CustomRow>
                            <CustomInput
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="deriveProduitDesignation"
                                id="deriveProduitDesignation"
                                placeholder="Désignation du produit dérivé"
                                label="Designation*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="deriveProduitCode"
                                id="deriveProduitCode"
                                placeholder="Code du produit dérivé"
                                label="Code*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                type="number"
                                isFloat
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="deriveProduitQuantiteADebiter"
                                id="deriveProduitQuantiteADebiter"
                                placeholder="Quantite à débiter"
                                label="Quantite à Débiter*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                                numberAfertComma={8}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomInput
                                type="number"
                                isFloat
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                name="deriveProduitPrixVente"
                                id="deriveProduitPrixVente"
                                placeholder="Prix de Vente du produit dérivé"
                                label="Prix Vente*"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                isInputGroup={false}
                                numberAfertComma={8}
                            />
                        </CustomRow>
                        {/* <CustomRow>
                            <CustomTextAreaInput
                                label="Syntaxe*"
                                rows={5}
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                formDispatcher={formDispatcher}
                                formState={formState}
                                isInputGroup={false}
                                placeholder="Saisir Syntaxe"
                                name="syntaxeProduitDerive"
                                id="syntaxeProduitDeriveId"
                                uiValidator={uiValidator}
                            />
                        </CustomRow> */}
                    </CustomCollapse>
                    <CustomRow className="row">
                        <CustomCol className="offset-9 col-3">
                            <CustomButtonLoader
                                type="button"
                                className="btn-primary"
                                onClick={adder}
                                text="Ajouter Element"
                            />
                        </CustomCol>
                    </CustomRow>
                </div>
                {fields.airtimeProduits.length ? (
                    <SimpleTable
                        identifier="code"
                        formDispatcher={formDispatcher}
                        data={fields.airtimeProduits}
                        isShowDropButton
                        listSelectedItemName="airtimeProduits"
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 1,
                                displayName: 'Code',
                                field: 'code'
                            },
                            {
                                position: 2,
                                displayName: 'Designation',
                                field: 'designation'
                            },
                            {
                                position: 3,
                                displayName: 'Prix SuperDealer',
                                field: 'prixAchatSuperDealer'
                            },
                            {
                                position: 4,
                                displayName: 'P. Achat',
                                field: 'prixAchat'
                            },
                            {
                                position: 5,
                                displayName: 'P. Vente',
                                field: 'prixVente'
                            },
                            {
                                position: 6,
                                displayName: 'Qty à débiter',
                                field: 'quantiteADebiter'
                            },
                            {
                                position: 7,
                                displayName: 'T-Number',
                                field: 'tillNumber'
                            },
                            {
                                position: 8,
                                displayName: 'Nbre Max Digit',
                                field: 'nombreMaxDigit'
                            },
                            {
                                position: 9,
                                displayName: 'Image',
                                field: 'img'
                            },
                            {
                                position: 10,
                                displayName: 'Nature',
                                field: 'nature'
                            }
                        ]}
                    />
                ) : null}
            </CustomRow>
        </>
    );
}
