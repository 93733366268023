import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST, traitementMouvement } from '../../../../util';
import { MainReportComponentLarge } from '../../releve/generic';
import {
    MouvementCardsComponent,
    StockSuperDealerSelector
} from '../../../../components';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedStockSuperDealer: {},
    objectChecker: {}
};

export function ReleveCommandeFlashMultipleForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements', 'commandes']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const displayTypeBussiness = (type) => {
        if (type === 'TraditionnelBusiness') return 'Traditionnel';
        if (type === 'BusinessModel') return 'Classique';
        return 'Sim';
    };
    const { fields, elements } = formState;
    // s'il y a une sortie, il faut supprimer l'élément du tableau et supprimer une entrée dans le tableau
    const cleaning = (data) => {
        const cleanedTable = data?.filter((item) => item?.entree === 0);
        const lengthToReduce = data.length - cleanedTable.length;
        if (cleanedTable.length !== data.length) {
            cleanedTable.splice(-lengthToReduce);
        }
        return cleanedTable;
    };
    const onSuccess = ({ element }) => {
        const ObjectToReturn = {};
        element.forEach((item) => {
            if (!ObjectToReturn[item?.valeurTypeEntite]) {
                const multiple = element?.filter(
                    (elm) => elm?.valeurTypeEntite === item?.valeurTypeEntite
                );
                if (multiple?.length > 1) {
                    const newTable = cleaning(
                        traitementMouvement(multiple)
                    ).map((elm) => ({ ...elm, isRed: false, isBlue: false }));
                    if (newTable.length > 1) {
                        ObjectToReturn[item?.valeurTypeEntite] = {
                            list: newTable,
                            entree: newTable.reduce(
                                (acc, curr) => curr.entree + acc,
                                0
                            ),
                            sortie: newTable.reduce(
                                (acc, curr) => curr.sortie + acc,
                                0
                            )
                        };
                    }
                }
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'objectChecker',
                data: ObjectToReturn
            }
        });
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'commandes',
                payload: { list: Object.keys(ObjectToReturn) },
                functionName: URL_CONST.GET_LIST_COMMANDE_FLASH_BY_LIST,
                etat: elements.commandes.status,
                formDispatcher
            })
        );
    };
    const mouvementFormater = (mouvements) =>
        mouvements.map((item) => {
            const multiple =
                mouvements.filter(
                    (mv) => mv.valeurTypeEntite === item.valeurTypeEntite
                ).length > 1;
            return {
                ...item,
                isRed: multiple
            };
        });

    const fetchReport = () => {
        const { dateFin, dateDebut, selectedStockSuperDealer } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payload = {
            dateDebut,
            dateFin,
            valeurTypeProprietaire: selectedStockSuperDealer?.id,
            typeProprietaire: DB_NODE.STOCK
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'objectChecker',
                data: {}
            }
        });
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_MOUVEMENT_PLAGE,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher,
                formater: mouvementFormater,
                onSuccess
            })
        );
    };
    return (
        <>
            <MainReportComponentLarge
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={[
                    {
                        displayName: 'Super dealer',
                        field: 'valeurTypeOrganisation.designation'
                    },
                    {
                        field: 'typeOperation',
                        displayName: 'Type Opération'
                    },
                    {
                        field: 'montant',
                        displayName: 'Quantité'
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création'
                    },
                    {
                        field: 'dateDerniereModification',
                        displayName: 'Dernière modification'
                    }
                ]}
                dataList={
                    elements.mouvements?.value?.map((item) => ({
                        ...item,
                        business: displayTypeBussiness(item.typeBusiness)
                    })) || []
                }
                objectChecker={fields.objectChecker}
                commandes={elements?.commandes?.value || []}
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                topOfChart={MouvementCardsComponent}
            >
                <StockSuperDealerSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
