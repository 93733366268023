export const schema = [
    {
        title: 'Produit',
        description: 'produit.designation',
        className: 'border-bottom'
    },
    {
        title: 'Quantité',
        description: 'quantite',
        className: 'border-bottom'
    },
    {
        title: 'Prix total',
        description: 'prixTotal',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    },
    {
        title: 'Non recouvert',
        description: 'estRecouvert'
    },
    {
        title: 'Demande annulation',
        description: 'demandeAnnulation',
        isBoolean: true,
        dataExtractor: (item) => !!item?.demandeAnnulation || false
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Dernière modification',
        description: 'dateDerniereModification',
        className: 'border-bottom'
    }
];
