import { useCallback } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    // REMBOURSEMENT_PARTIEL,
    // TYPE_REMBOURSEMENT_CAUTION,
    DB_NODE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    // CustomSwitchButton,
    FormWrapper
} from '../../../components';
import { RemboursementFormSchema as uiValidator } from './validation';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useDebounce } from '../../../util';

const defaultFields = {
    selectedSuperDealer: {},
    selectedCompte: {},
    selectedInstitutionFinanciere: {},
    previewValue: '',
    montant: 0,
    isCompte: false
};

// const defaultDependencies = { fields: ['devises'] };

// FIXME: integrer les fonctions
// https requests

export function CreationRemboursementCautionForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        organisation,
        comptes,
        etatCompte,
        superDealerKinDB,
        etatSuperDealerKinDB,
        institutionsFinanciere,
        etatInstitutionsFinanciere,
        allBalanceOrganisation,
        etatAllBalanceOrganisation
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation,
        comptes: reduxState.comptes,
        etatCompte: reduxState.etat.comptes.etat,
        superDealerKinDB: reduxState.superDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionsFinanciere: reduxState.etat.institutionsFinanciere.etat,
        allBalanceOrganisation: reduxState.allBalanceOrganisationTransfertFonds,
        etatAllBalanceOrganisation:
            reduxState.etat.allBalanceOrganisationTransfertFonds.etat
    }));
    const { idToken, isNapp } = useGetUserDetail({ firebaseUser, nappUser });

    const defaultDependencies = {};
    if (isNapp) {
        defaultDependencies.fields = [
            'superDealerKinDB',
            'comptes',
            'banques',
            'balances'
        ];
    } else {
        // defaultDependencies.fields = ['devises', 'comptes'];
        defaultDependencies.fields = ['comptes', 'banques'];
    }

    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: ['traceur']
    });

    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    const formater = (tableau = []) =>
        tableau.map((item) => ({
            ...item,
            code: item.id,
            designation: `${item.numero} - ${item.devise}`
        }));

    /**
     * Recupération des données nécessaires
     */
    const payloadFetchCompteOrganistion = {};
    if (isNapp) {
        payloadFetchCompteOrganistion.estCaution = true;
        payloadFetchCompteOrganistion.typeProprietaire = 'SuperDealer';
    }

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            // {
            //     isOnlyFetch: true,
            //     functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
            //     nodeName: DB_NODE.MONNAIE_LOCALE,
            //     etat: etatMonnaieLocale
            // },
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.COMPTE,
                functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
                etat: etatCompte,
                formater,
                payload: payloadFetchCompteOrganistion
                // isCondition: !isNapp
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                payload: { estAll: true, typeEntite: nappUser?.typeEntite },
                etat: etatSuperDealerKinDB,
                isCondition: isNapp
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionsFinanciere
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_COMPTE_USER,
                nodeName:
                    REDUX_NODE_NAME.ALL_BALANCE_ORGANISATION_TRANSFERT_FONDS,
                payload: {
                    estOrganisation: true,
                    typeProprietaire: 'SuperDealer',
                    typeOrganisation: nappUser?.typeEntite
                },
                etat: etatAllBalanceOrganisation
            }
        ]
    });

    const getIdBalance = useCallback(
        (sd, cpt, allBal) => {
            if (!sd || !cpt || !allBal) return '';
            const ctn = allBal.filter((bal) => bal?.typeEntite === 'Caution');
            const dev = comptes.find((item) => item?.id === cpt?.id)?.devise;
            const finder = ctn.find(
                (item) =>
                    item?.valeurTypeProprietaire?.code === sd?.code &&
                    item?.devise === dev
            );
            return finder?.id || '';
        },
        [comptes]
    );
    const idBalance = getIdBalance(
        organisation.entreprise
            ? organisation.entreprise
            : fields.selectedSuperDealer,
        fields.selectedCompte,
        allBalanceOrganisation
    );
    /**
     * Debounce
     */
    useDebounce({
        name: 'selectedCompte',
        preview: 'previewValue',
        propertyInName: 'id',
        isObject: true,
        formDispatcher,
        formState,
        delay: 1500,
        idToken,
        functionName: URL_CONST.GET_MONTANT_TOTAL_BY_BALANCE,
        elementPropertyName: 'traceur',
        payloadToAdd: {
            idBalance,
            codeInstitutionFinanciere: fields.selectedInstitutionFinanciere.code
        },
        dataLength: 0
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const institutionFinanciere = {};
        if (fields.selectedInstitutionFinanciere.code) {
            institutionFinanciere.institutionFinanciere = {
                code: fields.selectedInstitutionFinanciere.code
            };
        }
        const generalPayloadToAdd = {};
        if (isNapp) {
            generalPayloadToAdd.codeSuperDealer =
                fields.selectedSuperDealer.code;
        }
        const payloadToAdd = {};
        if (fields.selectedCompte.id) {
            payloadToAdd.idCompte = fields.selectedCompte.id;
        }

        const payload = {
            montant: fields.montant,
            ...payloadToAdd,
            ...generalPayloadToAdd,
            ...institutionFinanciere
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_REMBOURSEMENT_CAUTION,
                reduxNodeName:
                    REDUX_NODE_NAME.REMBOURSEMENT_CAUTION_BENEFICIAIRE,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };
    const getCompteBySuperDealer = (cpt, sd, bq) =>
        cpt
            .filter((item) => item?.valeurTypeProprietaire?.code === sd?.code)
            .filter(
                (element) => element?.institutionFinanciere?.code === bq?.code
            );
    const isCompteBySuperDealerExiste = (cpt, sd, bq) => {
        const res = getCompteBySuperDealer(cpt, sd, bq);
        return res.length;
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'comptes',
                    etat: etatCompte
                },
                {
                    dependency: 'banques',
                    etat: etatInstitutionsFinanciere
                },
                {
                    dependency: 'superDealerKinDB',
                    etat: etatSuperDealerKinDB,
                    isCondition: isNapp
                },
                {
                    dependency: 'balances',
                    etat: etatAllBalanceOrganisation,
                    isCondition: isNapp
                }
            ]}
        >
            <ConditionalRenderingWrapper isShouldBeRendered={isNapp}>
                <CustomRow>
                    <CustomDropdown
                        options={superDealerKinDB}
                        defaultOption="Selectionner SuperDealer"
                        label="Super Dealer: "
                        labelClassName="col-2"
                        divClassName="col-10"
                        formDispatcher={formDispatcher}
                        name="selectedSuperDealer"
                        id="selectedSuperDealerCreationApproCompteEmoney"
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomDropdown
                    idProperty="code"
                    propertyToDisplay="designation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    label="Institution Financière: "
                    name="selectedInstitutionFinanciere"
                    id="creationRemboursementCautionCompteInstitutFinanciereId"
                    options={institutionsFinanciere}
                    selectionClassName="form-select-md"
                    defaultOption="Selectionner Institution Financière"
                    divClassName="col-sm-10"
                    labelClassName="col-sm-2"
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={getCompteBySuperDealer(
                        comptes,
                        organisation.entreprise
                            ? organisation.entreprise
                            : fields.selectedSuperDealer,
                        fields.selectedInstitutionFinanciere
                    )}
                    defaultOption="Selectionner Compte"
                    label="Compte"
                    labelClassName="col-2"
                    divClassName="col-10"
                    formDispatcher={formDispatcher}
                    name="selectedCompte"
                    id="selectedCompteRapportCompteId"
                    idProperty="id"
                    uiValidator={uiValidator}
                    formState={formState}
                    disabled={
                        !isCompteBySuperDealerExiste(
                            comptes,
                            organisation.entreprise
                                ? organisation.entreprise
                                : fields.selectedSuperDealer,
                            fields.selectedInstitutionFinanciere
                        )
                    }
                />
                {elements?.traceur?.status ===
                FORM_CONST.FETCH_ELEMENT_FAILED ? (
                    <div className="d-flex justify-content-end mt-2">
                        <span className="badge bg-danger">
                            Une erreur est survenue
                        </span>
                    </div>
                ) : null}
                {elements?.traceur?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS ? (
                    <div className="d-flex justify-content-end mt-2">
                        <span className="badge bg-primary">
                            Total: {elements?.traceur?.value?.total || '0'}
                        </span>
                    </div>
                ) : null}
                {elements?.traceur?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                    <p className="d-flex justify-content-end mt-2 placeholder-glow">
                        <span className="placeholder col-1" />
                    </p>
                ) : null}
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Montant*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez le numero de compte"
                    formDispatcher={formDispatcher}
                    name="montant"
                    type="number"
                    min={0}
                    id="numeroCompteId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
