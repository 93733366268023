import React, { useState } from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { ListRetraitCashForm } from './list-retrait';
import { CustomCenteredModal } from '../../../components';
import { RechercheRetraitEmoneyByNumeroReferenceForm } from './recherche-by-num-ref';

export function RetraitCashViewLayout() {
    const [show, setShow] = useState(false);
    const onHide = () => setShow(false);
    const onShow = () => setShow(true);
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Retrait cash</h1>
                <div className="btn-toolbar mb-2 mb-md-0" />
                <span
                    role="button"
                    tabIndex={0}
                    title="Vérifier reférence"
                    onClick={onShow}
                    onKeyDown={onShow}
                    className="btn btn-outline-dark"
                >
                    Recherche <AiOutlineFileSearch />
                </span>
            </div>
            <CustomCenteredModal
                show={show}
                onHide={onHide}
                header="Rechercher Retrait"
                // size={modalSize}
                // backdrop={backdrop}
            >
                <RechercheRetraitEmoneyByNumeroReferenceForm />
            </CustomCenteredModal>
            <ListRetraitCashForm />
        </div>
    );
}
