import { formatNumber } from '../../util';

/**
 * Carte utilisée pour afficher un text et une valeur, par exemple un solde de produit flash
 * @param {Object} param0
 * @param {String} param0.text text à afficher
 * @param {Number} param0.value valeur à afficher
 * @param {String} param0.className
 * @param {String} param0.devise devise de la valeur à afficher
 * @param {Boolean} param0.isPercent si la valeur est en pourcentage
 * @param {Boolean} param0.isLongCompactDisplay s'il faut utiliser le format long compact
 * @param {Boolean} param0.isForceParse s'il faut forcer le parse de la value
 * @param {Number} param0.nombreApresVirgule nombre de rang à considérer après la virgule
 * @returns {React.Component}
 */
export function CustomCard({
    text,
    value,
    devise,
    className,
    isPercent,
    isCompact = true,
    isLongCompactDisplay,
    isForceParse,
    nombreApresVirgule,
    textAfterValue = ''
}) {
    return (
        <div className={`rounded text-white card mb-2 ${className || ''}`}>
            <div className={`card-body  ${className || ''}`}>
                <h5 className="card-title">
                    {formatNumber({
                        devise,
                        value,
                        isCompact,
                        isPercent,
                        isLongCompactDisplay,
                        isForceParse,
                        nombreApresVirgule
                    })}{' '}
                    {textAfterValue || ''}
                </h5>
                <p className="card-text">{text}</p>
            </div>
        </div>
    );
}
