import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    TransfertStockCreationRelPath,
    TransfertStockViewLayoutRelPath
} from '../../../routes/registration/configuration/config-marchand-rel-path';
import { ListTransfertStockForm } from './list-transfert';

export function TransfertStockViewLayout() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Transfert stock</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${TransfertStockViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.STOCK.TRANSFERT_STOCK
                                        .INITIER_CREATION_TRANSFERT_STOCK
                                ]}
                                destination={TransfertStockCreationRelPath.path}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListTransfertStockForm />
        </>
    );
}
