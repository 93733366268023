import { ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    BilletageComponent,
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper
} from '../../../components';
import {
    URL_CONST,
    calculTotalBilletage,
    useGetDetailForm
} from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';
import {
    DecaissementDetailFormSchema as uiValidator,
    DecaissementDetailFormRequiredSchema as uiValidatorRequired
} from './validation';

// FIXME: il faut deja que dans l'information de encaissement et decaissement qu'il y ait la devise deja

const defaultFields = {
    actionEncours: '',
    selectedCoupureBilletage: {},
    nombreCoupureBilletage: 0,
    listBilletage: []
};

/**
 * @description defaultDependencies for our state
 */
const defaultDependencies = { entity: true, fields: ['devise'] };

export function DetailDecaissementForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        reduxProps
    } = useGetDetailForm({
        nodeName: 'decaissements',
        defaultFields,
        defaultDependencies,
        listNode: [
            {
                name: 'allCoupures'
            }
        ]
    });

    const { form, fields } = formState;

    const updateEtat = (etat) => {
        const total = calculTotalBilletage({
            listBilletage: fields.listBilletage
        });
        const checkTotal = total[0]?.total === entity?.montant;
        if (!checkTotal && fields.listBilletage.length) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        'Montant renseigné différent du total des coupures renseigné',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payloadToAdd = {};
        if (fields.listBilletage.length) {
            payloadToAdd.coupures = fields.listBilletage.map((billet) => ({
                nombre: billet.nombre,
                valeur: parseInt(billet.coupure.code, 10)
            }));
        }
        const payload = {
            idEncaissementDecaissement: entity?.id,
            etat,
            ...payloadToAdd
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_ENCAISSEMENT_DECAISSEMENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.DECAISSEMENTS,
                reduxDispatcher,
                uiValidator:
                    entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                        ? uiValidatorRequired
                        : uiValidator
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_ENCAISSEMENT_DECAISSEMENT_BY_ID}
            nodeName={REDUX_NODE_NAME.DECAISSEMENTS}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail operation caisse"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                }
            >
                <CustomRow>
                    <BilletageComponent
                        formState={formState}
                        uiValidator={uiValidatorRequired}
                        formDispatcher={formDispatcher}
                        selectedDeviseName="selectedDeviseBilletage"
                        selectedCoupureName="selectedCoupureBilletage"
                        nombreCoupureName="nombreCoupureBilletage"
                        deviseCaisse={entity?.caisse?.devise}
                        coupures={
                            reduxProps?.allCoupures.find(
                                (item) => item.devise === entity?.caisse?.devise
                            )?.coupures || []
                        }
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                    <CustomConfirmButton
                        type="button"
                        text="Annuler"
                        className="btn-danger"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_ANNULER
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation Annulation"
                    />
                </CustomCol>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Mettre en attente"
                            className="btn-success"
                            confirmOnClick={() =>
                                updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                            }
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours ===
                                ETAT.ETAT_ATTENTE_VALIDATION
                            }
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                    }
                >
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
