import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { TYPE_ORGANISATION, formInitialState } from '@napp-inc/jnapp-util';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { CompteBancaireOrganisation } from './creation-form';

const defaultFields = {
    numeroCompte: '',
    selectedBanque: {},
    selectedDevise: {},
    estCompteTransfert: true
};

const defaultDependencies = {
    fields: ['banques', 'monnaies']
};

const setDefaultProperties = ({ type }) => {
    const CommonUiValidator = yup.object().shape({
        numeroCompte: yup.string().required(),
        selectedBanque: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedDevise: yup.object().shape({
            id: yup.string().required(),
            devise: yup.string().required()
        }),
        estCompteTransfert: yup.boolean().default(false)
    });

    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: CommonUiValidator
        };
    }
    if (type === TYPE_ORGANISATION.MARCHAND) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: CommonUiValidator
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: CommonUiValidator
        };
    }
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: CommonUiValidator
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({})
    };
};

export function CreationCompteBancaireOrganisationForm() {
    /**
     * Get data on redux store
     */
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const { fields, dependencies, elements, uiValidator } =
        setDefaultProperties({ type: typeEntite });
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });
    const renderContent = () =>
        initialState?.dependencies ? (
            <CompteBancaireOrganisation
                idToken={idToken}
                typeEntite={typeEntite}
                initialState={initialState}
                uiValidator={uiValidator}
            />
        ) : null;
    return renderContent();
}
