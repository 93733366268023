import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailCrediterBalanceCautionForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'transfertBalanceCaution' });
    const { form, fields } = formState;
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = {
            etat,
            idTransfertBalance: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_TRANSFERT_BALANCE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TRANSFERT_BALANCE_CAUTION,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TRANSFERT_BALANCE_BY_ID}
            nodeName={REDUX_NODE_NAME.TRANSFERT_BALANCE_CAUTION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail transfert"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_TRANSFERT_BALANCE
                ]}
            >
                <CustomRow
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomCol className="offset-4 offset-md-8 col-3 col-md-2">
                        <CustomButtonLoader
                            type="button"
                            className="btn-danger"
                            onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            text="Annuler"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomButtonLoader
                            type="button"
                            className="btn-success"
                            onClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            text="valider"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
