import { Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';
import { RapportFondRoulementKinDBForm } from './rapport-fonds-roulement';
import { RapportFondRoulementChargeRessourceKinDBForm } from './rapport-fonds-roulement-charge-ressource';
// import { RapportFondRoulementKinDBBySDForm } from './rapport-fonds-roulement-by-sd';

export function FondsRoulementKinDBViewLayout() {
    const [key, setKey] = useState('fonds');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Business Evolution</h1>
            </div>

            <Tabs
                defaultActiveKey="fonds"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="fonds" title="Fonds de roulement">
                    {key === 'fonds' && <RapportFondRoulementKinDBForm />}
                </Tab>
                {/* <Tab eventKey="sd" title="Super Dealer">
                    {key === 'sd' && <RapportFondRoulementKinDBBySDForm />}
                </Tab> */}
                <Tab eventKey="chgress" title="Ressource & charge">
                    {key === 'chgress' && (
                        <RapportFondRoulementChargeRessourceKinDBForm />
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}
