import { ConditionalRenderingWrapper } from './conditional-rendering-wrapper';

/**
 * Colonne personnalisée de Bootstrap
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {string} param0.className Classe CSS
 * @param {string} param0.children Contenu du composant
 * @param {Boolean} param0.isShouldBeRendered condition pour rendre le composant
 * @returns {React.Component}
 */
export function CustomCol({ className, children, isShouldBeRendered = true }) {
    return (
        <ConditionalRenderingWrapper isShouldBeRendered={isShouldBeRendered}>
            <div className={`col ${className}`}>{children}</div>
        </ConditionalRenderingWrapper>
    );
}
