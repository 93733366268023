import * as yup from 'yup';

/**
 * @description : Schema de validation Rayon création form
 */
export const RayonSchema = yup.object().shape({
    rayon: yup.string(),
    codeRayon: yup.string(),
    colonne: yup.string(),
    codeColonne: yup.string(),
    emplacement: yup.number(),
    colonnes: yup
        .array()
        .of(
            yup
                .object({
                    code: yup.string().required(),
                    designation: yup.string().required(),
                    details: yup
                        .array()
                        .of(
                            yup
                                .object({
                                    code: yup.string().required(),
                                    designation: yup.string().required(),
                                    nombreEmplacementPhysique: yup
                                        .number()
                                        .required()
                                })
                                .shape({})
                        )
                        .required()
                        .min(1, 'Ajoutez au moins un élément')
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins un élément à la liste')
});
