import Button from 'react-bootstrap/Button';
// For button based on Bootstrap CSS

/*
 * className :
 * btn-regular === sans bord arrondi
 * btn-regular-rounded === avec bord arrondi => utilisation:  btn-regular btn-regular-rounded
 * btn-rounded-corner === bouton rond => utilisation: btn-regular btn-rounded-corner
 * btn-icon-button === avec icon
 * btn--rounded === pour les boutons ronds avec icon
 */

/**
 * Bouton sans effet lors de son déclenchement
 * @param {Object} param0 object contenant les props du composant:
 * @param {string} param0.children Children du composant
 * @param {string} param0.className Classe CSS voir commentaire dans le code
 * @param {string} param0.size Taille du composant basée sur Bootstrap
 * @param {boolean} param0.disabled Désactivé ou non
 * @param {string} param0.type Type de composant
 * @param {string} param0.variant Variant du composant qui est une couleur basée sur bootstrap
 * @param {string} param0.text the button text
 * @param {Function} param0.onClick Fonction qui permet d'envoyer des actions au store du composant ou de déclencher une action du composant
 * @returns {React.Component}
 */

export function CustomButton({
    children,
    variant,
    className,
    onClick,
    size = 'md',
    type = 'button',
    disabled = false,
    title = '',
    text = ''
}) {
    return (
        <Button
            title={title}
            type={type}
            variant={variant}
            size={size}
            className={className}
            disabled={disabled}
            onClick={onClick}
        >
            {`${text || ''}`}
            {children}
        </Button>
    );
}
