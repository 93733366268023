import * as yup from 'yup';

/**
 * @description : Schema de validation moyen de retracage création form
 */
export const MoyenRetracageSchema = yup.object().shape({
    selectedType: yup.string().required(),
    selectedElement: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedModeRetracage: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
