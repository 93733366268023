import {
    useGetOrganisationDetail,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CATEGORIE_MARCHAND,
    TYPE_ORGANISATION,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { CreationForm } from './creation-views';
import { fieldsDistributeurMapper, fieldsMapper } from './form-fields';

const defaultFieldsDistributeur = {
    newAppointageDesignation: '',
    newAppointageEmail: '',
    designationMarchand: '',
    selectedTypeMarchand: {
        designation: ''
    },
    selectedMarchand: {
        designation: ''
    },
    selectedTypeDropDown: {},
    numeroAssocie: '',
    typeBusinessWithNumber: [],
    typeBusiness: [],
    selectedTypeBusiness: {},
    selectedTypeBusinessNumero: '',
    selectedUser: {},
    preview: '',
    previewMarchand: '',
    previewTypeMarchand: '',
    selectedZone: {
        designation: ''
    }
};

const defaultFieldsMarchand = {
    newAppointageDesignation: '',
    newAppointageEmail: '',
    newAdresseAdresseUne: '',
    newProprietaireNom: '',
    newProprietairePrenom: '',
    newProprietaireNumeroTel: '',
    newProprietaireEmail: '',
    newProprietaireAdresse: '',
    selectedTypeDropDown: {},
    typeBusinessWithNumber: [],
    selectedTypeDropDownZone: {},
    selectedZoneDropDown: {},
    selectedZone: {
        designation: ''
    },
    selectedTypeMarchand: {
        designation: ''
    },
    selectedMarchand: {
        designation: ''
    },
    zones: []
};

const defaultFieldsMarchandAirtime = {
    numeroAssocie: ''
};

const defaultFieldsMarchandMobileMoney = {
    selectedTypeBusiness: {},
    selectedTypeBusinessNumero: ''
};

const setDefaultProperties = ({ type, categorieMarchand }) => {
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR) {
        return {
            fields: defaultFieldsDistributeur,
            dependencies: { fields: [] },
            elements: ['marchand', 'monnaie', 'users'],
            uiValidator: yup.object().shape({
                newAppointageDesignation: yup.string().required(),
                newAppointageEmail: yup.string().email().required(),
                selectedTypeMarchand: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                designationMarchand: yup.string().required(),
                selectedMarchand: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                typeBusiness: yup.array().when('selectedTypeMarchand', {
                    is: (selectedTypeMarchand) =>
                        selectedTypeMarchand?.code ===
                        CATEGORIE_MARCHAND.MOBILE_MONEY.code,
                    then: yup
                        .array()
                        .of(
                            yup
                                .object({
                                    id: yup.string().required(),
                                    code: yup.string().required(),
                                    numero: yup.string().required()
                                })
                                .shape({})
                        )
                        .required()
                        .min(1, 'Ajouter au moins un élément à la liste'),
                    otherwise: yup.array().of(
                        yup
                            .object({
                                id: yup.string(),
                                code: yup.string(),
                                numero: yup.string()
                            })
                            .shape({})
                    )
                }),
                typeBusinessWithNumber: yup
                    .array()
                    .of(
                        yup
                            .object({
                                code: yup.string().required(),
                                numero: yup.string().required()
                            })
                            .shape({})
                    )
                    .required()
                    .min(1, 'Ajouter au moins un élément à la liste'),
                numeroAssocie: yup.string(),
                selectedTypeBusiness: yup.object().shape({}),
                selectedUser: yup.object().shape({
                    owner: yup.string().required()
                })
            }),
            dynamicFields: fieldsDistributeurMapper
        };
    }
    if (
        type === TYPE_ORGANISATION.MARCHAND &&
        categorieMarchand === CATEGORIE_MARCHAND.AIRTIME.code
    ) {
        return {
            fields: {
                ...defaultFieldsMarchand,
                ...defaultFieldsMarchandAirtime
            },
            dependencies: { fields: [] },
            elements: ['zones'],
            uiValidator: yup.object().shape({
                newAppointageDesignation: yup.string().required(),
                newAppointageEmail: yup.string().required(),
                newAdresseAdresseUne: yup.string().required(),
                newProprietaireNom: yup.string().required(),
                newProprietairePrenom: yup.string().required(),
                newProprietaireNumeroTel: yup.string().required(),
                newProprietaireEmail: yup.string().required(),
                newProprietaireAdresse: yup.string().required(),
                typeBusinessWithNumber: yup
                    .array()
                    .of(
                        yup
                            .object({
                                code: yup.string().required(),
                                numero: yup.string().required()
                            })
                            .shape({})
                    )
                    .required()
                    .min(1, 'Ajouter au moins un élément à la liste'),
                zones: yup
                    .array()
                    .of(
                        yup
                            .object({
                                code: yup.string().required(),
                                designation: yup.string(),
                                niveau: yup.string()
                            })
                            .shape({})
                    )
                    .required()
                    .min(1, 'Ajouter au moins un élément à la liste')
            }),
            dynamicFields: fieldsMapper
        };
    }
    if (
        type === TYPE_ORGANISATION.MARCHAND &&
        categorieMarchand === CATEGORIE_MARCHAND.MOBILE_MONEY.code
    ) {
        return {
            fields: {
                ...defaultFieldsMarchand,
                ...defaultFieldsMarchandMobileMoney
            },
            dependencies: { fields: ['monnaie'] },
            elements: ['zones'],
            uiValidator: yup.object().shape({
                newAppointageDesignation: yup.string().required(),
                newAppointageEmail: yup.string().required(),
                newAdresseAdresseUne: yup.string().required(),
                newProprietaireNom: yup.string().required(),
                newProprietairePrenom: yup.string().required(),
                newProprietaireNumeroTel: yup.string().required(),
                newProprietaireEmail: yup.string().required(),
                newProprietaireAdresse: yup.string().required(),
                zones: yup
                    .array()
                    .of(
                        yup
                            .object({
                                reference: yup.string().required(),
                                designation: yup.string().required(),
                                niveau: yup.string()
                            })
                            .shape({})
                    )
                    .required()
                    .min(1, 'Ajouter au moins un élément à la liste')
            }),
            dynamicFields: fieldsMapper
        };
    }
    return {
        fields: {
            preview: '',
            previewMarchand: '',
            previewTypeMarchand: '',
            selectedZone: {
                designation: ''
            },
            selectedMarchand: {
                designation: ''
            },
            selectedTypeMarchand: {
                designation: ''
            }
        },
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({}),
        dynamicFields: []
    };
};

export function CreationSuperDealerForm() {
    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );

    const { codeCategorieMarchand, designatiMarchand } =
        useGetOrganisationDetail({
            organisation
        });
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const { fields, dependencies, elements, uiValidator, dynamicFields } =
        typeEntite
            ? setDefaultProperties({
                  type: typeEntite,
                  categorieMarchand: codeCategorieMarchand
              })
            : {};

    /**
     * initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });

    const renderContent = () =>
        typeEntite ? (
            <CreationForm
                typeEntite={typeEntite}
                categorieMarchand={codeCategorieMarchand}
                designationMarchand={designatiMarchand}
                idToken={idToken}
                initialState={initialState}
                uiValidator={uiValidator}
                dynamicFields={dynamicFields}
                organisation={organisation}
            />
        ) : null;

    return renderContent();
}
