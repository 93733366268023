export const schema = [
    {
        title: 'Code',
        description: 'code',
        className: 'border-bottom'
    },
    {
        title: 'Désignation',
        description: 'designation',
        className: 'border-bottom'
    },
    {
        title: 'Nationalité',
        description: 'nationalite',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
