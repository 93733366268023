export default [
    {
        id: 'DEFAULT_WEB_USER_SERVICE',
        creator: '',
        dateCreation: 0,
        dateDerniereModification: 0,
        sysService: {
            code: 'DEFAULT_WEB_USER_SERVICE',
            designation: 'default'
        },
        owner: '',
        estActif: true
    }
];
