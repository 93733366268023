import * as yup from 'yup';

/**
 * @description : Schema de Decaissement form
 */

/**
 *  actionEncours: '',
    selectedCoupureBilletage: {},
    nombreCoupureBilletage: 0,
    listBilletage: [],
 */
export const DecaissementDetailFormSchema = yup.object().shape({
    nombreCoupureBilletage: yup.number().min(0),
    actionEncours: yup.string(),
    selectedCoupureBilletage: yup.object().shape({
        code: yup.string()
    }),
    listBilletage: yup
        .array()
        .of(
            yup
                .object({
                    id: yup.string().nullable(),
                    devise: yup
                        .object()
                        .shape({
                            code: yup.string().required()
                        })
                        .nullable(),
                    coupure: yup
                        .object()
                        .shape({
                            code: yup.string().required()
                        })
                        .nullable(),
                    nombre: yup.number().nullable()
                })
                .shape({})
                .nullable()
        )
        .notRequired()
});

export const DecaissementDetailFormRequiredSchema = yup.object().shape({
    nombreCoupureBilletage: yup.number().min(1),
    actionEncours: yup.string(),
    selectedCoupureBilletage: yup.object().shape({
        code: yup.string().required()
    }),
    listBilletage: yup.array().of(
        yup
            .object({
                id: yup.string().required(),
                devise: yup.object().shape({
                    code: yup.string().required()
                }),
                coupure: yup.object().shape({
                    code: yup.string().required()
                }),
                nombre: yup.number().min(1)
            })
            .shape({})
    )
});
