import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetOrganisationDetail,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomRow,
    DynamicFormMounted,
    FormWrapper
} from '../../../components';
import { PointLivraisonSchema as uiValidator } from './validation';
import { mapper as fieldsMapper } from './form-fields';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    adresse: '',
    referenceLivraison: '',
    selectedPays: {},
    selectedAgentLivreur: {}
};

/**
 * @description defaultDeps de notre state
 */
const defaultDependencies = { fields: ['users'] };

export function CreationPointDeLivraisonForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, organisation, users, etatUser } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            organisation: reduxState.organisation,
            users: reduxState.users.map((item) => ({
                ...item,
                designation: `${item?.personne?.prenom || ''} ${
                    item?.personne?.nom || ''
                }`
            })),
            etatUser: reduxState.etat.users.etat
        })
    );
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const { codeMarchand } = useGetOrganisationDetail({
        organisation
    });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * @description récupérer les users marchand
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUser
            }
        ]
    });

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            newAdresse: {
                adresseUne: fields.adresse,
                reference: fields.referenceLivraison,
                pays: fields.selectedPays
            },
            referenceAgentLivreur: fields.selectedAgentLivreur.id,
            codeMarchand,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_POINT_LIVRAISON,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'users',
                    etat: etatUser
                }
            ]}
        >
            <DynamicFormMounted
                formDispatcher={formDispatcher}
                formState={formState}
                uiValidator={uiValidator}
                fieldsMapper={fieldsMapper}
            />
            <CustomRow>
                <CustomDropdown
                    options={users}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner l'agent"
                    label="Agent Livreur*"
                    idProperty="id"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedAgentLivreur"
                    id="selectedAgentLivreurId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer point de livraison"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
