import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    ConfigurationKindistributionCreateRelPath,
    ConfigurationKindistributionViewLayoutRelPath
} from '../../../routes/registration/configuration/config-napp-rel-path';
import { ListConfigurationKindistributionForm } from './list-configuration-kindistribution';

export function ConfigurationKindistributionViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Configuration KinDb</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ConfigurationKindistributionViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE
                                        .CODE_SERVICE_MODULE_ADMINISTRATION
                                        .SERVICE_CONFIGURATION_KINDB
                                        .CREATION_CONFIGURATION_KIN_DISTRIBUTION
                                ]}
                                destination={
                                    ConfigurationKindistributionCreateRelPath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListConfigurationKindistributionForm />
        </div>
    );
}
