import { useGetUserDetail, useFetchAndListening } from '@napp-inc/jnapp-hook';
import { GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST, filterClientBySuperDealerMarchand } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { CustomRow } from '../container';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';

export function RapportActiviteSelector({ formDispatcher, formState }) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        superDealerKinDB,
        etatSuperDealerKinDB,
        clientsOrganisation,
        etatClientsOrganisation
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        superDealerKinDB: reduxState.superDealerKinDB
            .filter((item) => item.estEmoney)
            .map((item) => ({
                ...item,
                code: item.entreprise.code,
                designation: item.entreprise.designation
            })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat,
        clientsOrganisation: reduxState.clientsOrganisation.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeProprietaire?.designation || ''}-${
                item?.numero || ''
            }`
        })),
        etatClientsOrganisation: reduxState.etat.clientsOrganisation.etat
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const { fields, elements } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { estAll: true, typeEntite: nappUser?.typeEntite }
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_NUMERO_CLIENT,
                nodeName: REDUX_NODE_NAME.CLIENT_ORGANISATION,
                etat: etatClientsOrganisation
            }
        ]
    });

    const renderContent = () => {
        if (
            etatSuperDealerKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS &&
            etatClientsOrganisation ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <>
                    <CustomRow>
                        <CustomDropdown
                            options={superDealerKinDB}
                            defaultOption="Selectionner Super Dealer"
                            disabled={
                                !superDealerKinDB?.length ||
                                !elements?.mouvements?.value?.devises
                            }
                            divClassName="col-12 "
                            formDispatcher={formDispatcher}
                            name="selectedSuperDealer"
                            id="selectedSuperDealerRapportActiviteId"
                            formState={formState}
                            resetOnChangeState
                            newState={{
                                ...fields,
                                selectedClient: {},
                                selectedEtat: {}
                            }}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            options={filterClientBySuperDealerMarchand({
                                superDealers: superDealerKinDB,
                                superDealer: fields.selectedSuperDealer,
                                clients: clientsOrganisation
                            })}
                            defaultOption="Selectionner Client"
                            disabled={
                                !filterClientBySuperDealerMarchand({
                                    superDealers: superDealerKinDB,
                                    superDealer: fields.selectedSuperDealer,
                                    clients: clientsOrganisation
                                })?.length ||
                                !elements?.mouvements?.value?.devises
                            }
                            divClassName="col-12 "
                            formDispatcher={formDispatcher}
                            name="selectedClient"
                            id="selectedClientRapportActiviteId"
                            formState={formState}
                            idProperty="id"
                            resetOnChangeState
                            newState={{
                                ...fields,
                                selectedEtat: { code: '', designation: '' }
                            }}
                        />
                    </CustomRow>
                </>
            );
        }
        if (
            etatSuperDealerKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC ||
            etatClientsOrganisation ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des elements" />
            );
        }
        return <CenteredSpinner />;
    };

    return renderContent();
}
