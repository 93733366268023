import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    formInitialState,
    FORM_CONST,
    ONLY_FETCH
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomRow,
    DetailWrapper,
    UserServiceSelectionComponent,
    UserServiceWrapper
} from '../../../components';
import { ProfilUserDedierSchema as uiValidator } from './validation';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, ListTypeOrganisation as data } from '../../../util';

const defaultFields = {
    selectedTypeEntite: {},
    selectedValeurTypeEntite: {},
    selectedTypeOrganisation: {},
    selectedNiveauOrganisation: {},
    selectedProfilUser: {},
    selectedServiceUser: {},
    listServices: [],
    attributedServices: [],
    toRemove: [],
    toAddInputSearch: '',
    toRemoveInputSearch: ''
};

const defaultDependencies = { fields: ['profil', 'services'] };

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['servicesByProfilDedie', 'allProfil'];

export function DetailProfilUserDedier() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, dynamicNode, dynamicEtatNode } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            dynamicNode: reduxState.dynamicNode,
            dynamicEtatNode: reduxState.dynamicEtatNode
        })
    );

    const { id } = useParams();
    const entity = data.find(({ code }) => code === id);

    const { idToken, creator } = useGetUserDetail({ firebaseUser });

    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const { fields, form, elements } = formState;
    const dynamicNodeName = `${REDUX_NODE_NAME.SYS_SERVICE_BY_USERS}${id}`;
    const dynamicNodeNameProfilDedie = `${REDUX_NODE_NAME.SYS_SERVICE_BY_USERS}${id}ProfilDedie`;

    const typeEntite = id.includes('0') ? id.substring(0, id.length - 3) : id;
    const payloadToAdd = {};
    if (entity?.valeurTypeEntite) {
        payloadToAdd.valeurTypeEntite = entity.valeurTypeEntite;
    }
    if (entity?.typeOrganisation) {
        payloadToAdd.typeOrganisation = entity.typeOrganisation;
    }
    if (entity?.niveauOrganisation) {
        payloadToAdd.niveauOrganisation = Number(entity.niveauOrganisation);
    }
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                fetchType: ONLY_FETCH,
                functionName:
                    URL_CONST.GET_LIST_SYS_SERVICES_BY_TYPE_ENTREPRISE,
                dynamicNodeName,
                isDynamicNode: true,
                etat: dynamicEtatNode[dynamicNodeName]?.etat,
                payload: {
                    newSysServicesTypeEnteprise: {
                        estActif: false,
                        typeEntite,
                        ...payloadToAdd
                    }
                }
            },
            {
                fetchType: ONLY_FETCH,
                functionName:
                    URL_CONST.GET_LIST_PROFIL_USER_DEDIE_BY_TYPE_ENTREPRISE,
                dynamicNodeName: dynamicNodeNameProfilDedie,
                isDynamicNode: true,
                etat: dynamicEtatNode[dynamicNodeNameProfilDedie]?.etat,
                payload: {
                    newProfilUserDedie: {
                        estActif: false,
                        typeEntite,
                        ...payloadToAdd
                    }
                }
            }
        ]
    });

    /**
     * @description fonction pour fetcher les services d'un profil dedie
     */
    const onSearchServiceByProfilDedier = () => {
        const { status: etat } = elements.servicesByProfilDedie;
        formDispatcher(
            fetchElement({
                idToken,
                functionName:
                    URL_CONST.GET_LIST_PROFIL_USER_DEDIE_SYS_SERVICE_BY_PROFIL_USER_DEDIE,
                elementPropertyName: 'servicesByProfilDedie',
                etat,
                payload: {
                    idProfilUserDedie: fields.selectedProfilUser.id
                },
                reduxDispatcher
            })
        );
    };
    const formaterListProfil = (listProfil) =>
        listProfil.map((item) => ({
            ...item,
            profilUser: {
                code: item?.code,
                designation: item?.designation,
                reference: item?.id
            }
        }));
    /**
     * Pour fetcher tous les profils
     */
    const onFetchAllProfil = () => {
        const { status: etat } = elements.allProfil;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_ALL_PROFIL_USER,
                elementPropertyName: 'allProfil',
                etat,
                formater: formaterListProfil,
                reduxDispatcher
            })
        );
    };
    const profilToUse = () => {
        const toUse = dynamicNode[dynamicNodeNameProfilDedie]?.slice() || [];
        if (elements?.allProfil?.value?.length) {
            elements.allProfil.value.forEach((item) => {
                if (
                    !toUse.find(
                        (tu) => tu?.profilUser?.code === item?.profilUser?.code
                    )
                ) {
                    toUse.push(item);
                }
            });
        }
        return toUse;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (entity?.code !== 'EndUser' && entity?.code !== 'AgentLivreur') {
            const findProfil = profilToUse()?.find(
                (item) => item.id === fields.selectedProfilUser.id
            );
            payloadToAdd.profilUser = {
                code: findProfil ? findProfil.profilUser.code : undefined
            };
        }
        if (payloadToAdd.profilUser.code === undefined) {
            return;
        }
        const list = fields.listServices;
        const payload = {
            newProfilUserDedie: {
                typeEntite,
                ...payloadToAdd
            },
            list,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_PROFIL_USER_DEDIE,
                payload,
                fields,
                reduxNodeName: DB_NODE.PROFIL_USER_DEDIE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            subtitle="Detail Profil Utilisateur Dédié"
            entity={entity}
            id={id}
            schema={schema}
            listDependencies={[
                {
                    dependency: 'services',
                    etat: dynamicEtatNode[dynamicNodeName]?.etat
                },
                {
                    dependency: 'profil',
                    etat: dynamicEtatNode[dynamicNodeNameProfilDedie]?.etat
                }
            ]}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .PROFIL_USER_DEDIER.CREATION_PROFIL_USER_DEDIER
                ]}
            >
                {entity?.code !== 'EndUser' &&
                entity?.code !== 'AgentLivreur' ? (
                    <CustomRow>
                        <CustomDropdown
                            options={
                                profilToUse()?.map((item) => ({
                                    ...item,
                                    designation: `${
                                        item?.profilUser?.designation ||
                                        item?.designation ||
                                        ''
                                    }`
                                })) || []
                            }
                            label="Profil*"
                            idProperty="id"
                            labelClassName="col-12 col-sm-2 col-md-2"
                            divClassName="col-12 col-sm-6 col-md-6"
                            formDispatcher={formDispatcher}
                            name="selectedProfilUser"
                            id="selectedProfilUserCreationProfilUserDedieId"
                            formState={formState}
                            defaultOption="Sélectionnez le profil"
                            uiValidator={uiValidator}
                        />
                        <CustomButtonLoader
                            className="col-2 col-md-1 h-25"
                            type="button"
                            text="Rechercher"
                            onClick={onSearchServiceByProfilDedier}
                            disabled={
                                elements?.servicesByProfilDedie?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            isActionEncours={
                                elements?.servicesByProfilDedie?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                        />
                        <CustomButtonLoader
                            className="col-2 col-md-2 mx-2 h-25"
                            type="button"
                            text="Add All profil"
                            onClick={onFetchAllProfil}
                            disabled={
                                elements?.allProfil?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            isActionEncours={
                                elements?.allProfil?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                        />
                    </CustomRow>
                ) : null}
                <div className="mt-4 p-2">
                    {elements.servicesByProfilDedie?.value?.length ? (
                        <UserServiceSelectionComponent
                            formDispatcher={formDispatcher}
                            fields={fields}
                            title="liés au profil dédié"
                            buttonType="no button"
                            selectedItemListName="toRemove"
                            dataList={elements.servicesByProfilDedie?.value?.map(
                                (item) => ({
                                    ...item,
                                    designation: item.sysService.designation
                                })
                            )}
                            formState={formState}
                            // onUpdate={updateEtat}
                            inputName="toRemoveInputSearch"
                            // isOrganisation={isOrganisation}
                        />
                    ) : null}
                    {elements.servicesByProfilDedie?.value?.length ? (
                        <br />
                    ) : null}
                    <UserServiceSelectionComponent
                        formDispatcher={formDispatcher}
                        fields={fields}
                        title="de l'organisation (Non attribués)"
                        buttonType="non used"
                        formState={formState}
                        // onUpdate={updateEtat}
                        selectedItemListName="listServices"
                        inputName="toAddInputSearch"
                        // isOrganisation={isOrganisation}
                        dataList={dynamicNode[dynamicNodeName]
                            ?.map((item) => ({
                                ...item,
                                designation: item.designation
                            }))
                            .filter(
                                ({ code }) =>
                                    !elements.servicesByProfilDedie?.value?.find(
                                        (item) => item.sysService.code === code
                                    )
                            )}
                    />
                </div>
                <CustomRow className="row">
                    <CustomCol className="col-3">
                        <CustomButtonLoader
                            className="btn-success"
                            onClick={handleSubmit}
                            text="Sauvegarder"
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
