export const schema = [
    {
        description: 'code',
        title: 'Code',
        className: 'border-bottom'
    },
    {
        description: 'designation',
        title: 'Désignation',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'Création',
        className: 'border-bottom'
    }
];
