import { Add, Mul } from '@napp-inc/jnapp-util';
import { useEffect, useState } from 'react';
/**
 * retourne un table au [{devise, total}] representant le total de toutes les coupures renseignées dans le billetage
 * @param {Object} param0 objet contenant listBilletage
 * @param {Array} param0.listBilletage tableau avec la structure { devise: { code }, coupure: { code }, nombre }
 * @returns {Array}
 */
export function useCalculTotalBilletage({ listBilletage }) {
    const [result, setResult] = useState([]);
    useEffect(() => {
        const tempTable = [];
        if (listBilletage && listBilletage.length) {
            listBilletage.forEach((billets) => {
                const currentDevise = billets?.devise?.code;
                const nombre = billets?.nombre;
                const coupure = billets?.coupure?.code;
                const existing = tempTable.find(
                    (temp) => temp.devise === currentDevise
                );
                const total = Mul(nombre, coupure);
                if (!existing && currentDevise && total) {
                    tempTable.push({
                        devise: currentDevise,
                        total
                    });
                } else if (existing && total && currentDevise) {
                    existing.total = Add(total, existing.total);
                }
            });
        }
        setResult(tempTable);
    }, [listBilletage]);
    return result;
}

export function calculTotalBilletage({ listBilletage }) {
    const result = [];
    if (listBilletage && listBilletage.length) {
        listBilletage.forEach((billets) => {
            const currentDevise = billets?.devise?.code;
            const nombre = billets?.nombre;
            const coupure = billets?.coupure?.code;
            const existing = result.find(
                (temp) => temp.devise === currentDevise
            );
            const total = Mul(nombre, coupure);
            if (!existing && currentDevise && total) {
                result.push({
                    devise: currentDevise,
                    total
                });
            } else if (existing && total && currentDevise) {
                existing.total = Add(total, existing.total);
            }
        });
    }
    return result;
}
