import React from 'react';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { useLocation } from 'react-router-dom';
import { ListGestionRevendeurForm } from './list-gestion-revendeur';
import { ShowNewComponent } from '../../../components';
import { ConfigurationPath } from '../../../routes/registration';

export function GestionRevendeurViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Status Revendeur</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ConfigurationPath.GestionRevendeurViewLayoutPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE
                                        .CODE_SERVICE_MODULE_ADMINISTRATION
                                        .CHANGEMENT_STATUT_REVENDEUR
                                        .INITIER_CHANGEMENT_STATUT_REVENDEUR
                                ]}
                                destination={
                                    ConfigurationPath.GestionRevendeurCreatePath
                                        .path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListGestionRevendeurForm />
        </div>
    );
}
