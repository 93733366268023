import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST, URL_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomInput,
    CustomLabel,
    CustomRow,
    CustomSwitchButton
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { TransfertFondConfigSchema as uiValidator } from '../../../util';

const defaultFields = {
    isTransfertAutomatique: false,
    montantTransfertAutomatique: 0
};

export function TransfertFondConfigForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, deviseReference, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            deviseReference: reduxState.deviseReference,
            organisation: reduxState.organisation
        })
    );
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (event) => {
        const additional = {};
        if (fields.isTransfertAutomatique) {
            additional.montantTransfert = fields.montantTransfertAutomatique;
        }
        const payload = {
            creator: '0oDzddBRCoUnpa2ncVm35AS4gdS2',
            estTransfertAutomatique: fields.isTransfertAutomatique,
            ...additional
        };
        event.preventDefault();
        formDispatcher(
            submitPutAction({
                reduxDispatcher,
                functionName: URL_CONST.PUT_ACCEPTER_TRANSFERT_AUTOMATIQUE,
                reduxNodeName: REDUX_NODE_NAME.ORAGANISATION,
                idToken,
                payload,
                uiValidator
            })
        );
    };
    return (
        <div className="">
            <CustomRow className="">
                <CustomAlert
                    status={
                        form.status === FORM_CONST.MODIFICATION_SUCCESS
                            ? FORM_CONST.MODIFICATION_SUCCESS
                            : ''
                    }
                />
                <CustomCol>
                    <CustomAlert error={form.error} />
                    <CustomSwitchButton
                        id="isTransfertAutomatique"
                        text="Transférer automatiquement"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        value={fields.isTransfertAutomatique}
                        name="isTransfertAutomatique"
                        divClassName="col-md-8"
                    />
                    <CustomCollapse isIn={fields.isTransfertAutomatique}>
                        <CustomRow className="mt-4">
                            <CustomLabel
                                text="Montant du transfert*"
                                className="col-md-3"
                            />
                            <CustomInput
                                isInputGroup={false}
                                divClassName="col-sm-8"
                                name="montantTransfertAutomatique"
                                formDispatcher={formDispatcher}
                                type="number"
                                isFloat
                                formState={formState}
                                uiValidator={uiValidator}
                            />
                        </CustomRow>
                    </CustomCollapse>
                    <div className="w-100" />
                    <fieldset>
                        <legend>
                            <p className="note">
                                En acceptant ce paramètre vous autorisez que des
                                transferts automatiques se fassent
                                <br />
                                entre votre compte JNAPP et votre compte auprès
                                de la banque.
                            </p>
                        </legend>
                    </fieldset>
                    {/* <CustomCol className="offset-md-8 col-md-4"> */}
                    <CustomButtonLoader
                        className="btn-success mt-3"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                    {/* </CustomCol> */}
                </CustomCol>
                <CustomCol className="col-12 mt-1">
                    {organisation?.estTransfertAutomatique ? (
                        <CustomAlert
                            infoMessage={`Le transfert automatique est actuellement de ${
                                organisation?.montantTransfert
                            } ${deviseReference ? deviseReference?.code : ''}`}
                        />
                    ) : (
                        <CustomAlert infoMessage="Le transfert automtique est actuellement désactivé" />
                    )}
                </CustomCol>
            </CustomRow>
        </div>
    );
}
