import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';
import { ConfirmationSoldeCompteFormSchema as uiValidator } from './validation';

const defaultFields = {
    selectedTache: {}
};

const defaultDependencies = { fields: ['taches'] };

export function CreationConfirmationSoldeCompteForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, dynamicNode, dynamicEtatNode } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            dynamicNode: reduxState.dynamicNode,
            dynamicEtatNode: reduxState.dynamicEtatNode
        })
    );
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const dynamicNodeName = `tachePerformances`;
    /**
     * Cloud Functions
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                dynamicNodeName,
                isDynamicNode: true,
                functionName: URL_CONST.GET_LIST_TACHE_PERFORMANCE_AGENT,
                etat: dynamicEtatNode[dynamicNodeName]?.etat,
                payload: {
                    type: 'SoldeCompte'
                }
            }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedTache } = fields;
        const payload = {};
        if (selectedTache.code) payload.codeTache = selectedTache.code;
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CONFIRMATION_SOLDE_COMPTE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.CONFIRMATION_SOLDE_COMPTE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'taches',
                    etat: dynamicEtatNode[dynamicNodeName]?.etat
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={dynamicNode[dynamicNodeName]}
                    defaultOption="Séléctionner Tâche"
                    label="Tâche* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTache"
                    id="selectedTacheCreationConfirmationSolde"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
