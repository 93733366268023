import * as yup from 'yup';

/**
 * @description : Schema de Point de Livraison création form
 */
export const PointLivraisonSchema = yup.object().shape({
    adresse: yup.string().required(),
    referenceLivraison: yup.string().required(),
    selectedPays: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedAgentLivreur: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    })
});
