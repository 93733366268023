import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { ReleveBalanceOrganisme } from '../../../../components';

export function ReleveBalanceKinDistributionForm() {
    return (
        <ReleveBalanceOrganisme
            typeOrganisation={TYPE_ORGANISATION.SUPER_DEALER}
            payload={{
                typeProprietaire: TYPE_ORGANISATION.SUPER_DEALER,
                estOrganisation: true,
                typesEntite: [TYPE_ORGANISATION.NAPP]
            }}
            dynamicNodeName="balanceKinDistribution"
            label="Sélectionner une balance"
        />
    );
}
