import { useDispatch, useSelector } from 'react-redux';
import {
    useFetchAndListening,
    useGetUserDetail,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import { DB_NODE, ProduitSchema } from '@napp-inc/jnapp-util';
import { db } from '../../../firebase';
import { CenteredSpinner, CustomAlert, SimpleTable } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListArticleForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, etatChargementProduits, listProduits } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            etatChargementProduits: reduxState.etat.produits.etat,
            listProduits: reduxState.produits
        })
    );
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        db,
        list: [
            {
                nodeName: DB_NODE.PRODUIT,
                isOnlyFetch: true,
                functionName: URL_CONST.GET_FAKE,
                payload: {
                    referenceMarchand: 'pDknauBK5RNbC8fBmauH'
                },
                etat: etatChargementProduits,
                reduxValidator: ProduitSchema
            }
        ]
    });
    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        etatChargement: etatChargementProduits
    });

    const renderContent = () => {
        if (isLoadFailed) {
            return <CustomAlert error="Une erreur est survenue" />;
        }
        if (!isLoadComplete) {
            return <CenteredSpinner />;
        }
        return (
            <div className="shadow p-3 mt-2">
                <SimpleTable
                    tableClass="table table-hover table-borderless table-responsive-sm"
                    isTwice
                    data={listProduits}
                    mapper={[
                        {
                            displayName: 'Code',
                            field: 'code'
                        },
                        {
                            displayName: 'Désignation',
                            field: 'designation'
                        }
                    ]}
                    isActionActive
                />
            </div>
        );
    };
    return renderContent();
}
