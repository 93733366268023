/**
 * Composant SweetAndSimpleWrapper qui permet d'encadrer un composant avec le style du sweet table ou pas
 * @param {Object} param0 object de paramètre suivant : iSweet, sweetTableTitle, sweetTableType, children
 * @param {Boolean} param0.isSweet s'il faut utiliser le style du sweet table
 * @param {String} param0.sweetTableTitle titre du sweet table
 * @param {String} param0.sweetTableType type du sweet table
 * @returns React.Component
 */

export const SweetAndSimpleWrapper = ({
    isSweet,
    sweetTableTitle = '',
    sweetTableType = 'primary',
    children
}) => {
    const renderContent = () => {
        if (isSweet) {
            return (
                <>
                    <div className="border rounded-top px-2 pt-2 shadow-sm table-responsive">
                        {sweetTableTitle ? (
                            <div className="mb-3">
                                <span className="lead fs-5">
                                    {sweetTableTitle}
                                </span>
                            </div>
                        ) : null}
                        {children}
                    </div>
                    <div
                        className={`border-bottom border-${sweetTableType} rounded-bottom border-5`}
                    />
                </>
            );
        }
        return (
            <div className="py-2 px-1 shadow-sm w-auto fw-light table-responsive">
                {children}
            </div>
        );
    };
    return renderContent();
};
