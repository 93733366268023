import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import paths from '../routes/path';
import { useStateContext } from '../context';
import { useBuildRoutesBasedOnUserService } from './build-routes';

export function BaseLayout({ isLoadedUserService = true }) {
    const { menus, configMenus } = useStateContext();
    const { usersService } = useSelector((reduxState) => ({
        usersService: reduxState.usersService
    }));
    const routesToUse = useBuildRoutesBasedOnUserService(paths, usersService);
    const routeToRender = useRoutes(routesToUse);
    if (!isLoadedUserService) {
        return <div>Loading...</div>;
    }
    return (
        <div className="vw-100 vh-100" menus={{ menus, configMenus }}>
            {routeToRender}
        </div>
    );
}
