import {
    COMPTE_DISTRIBUTEUR,
    COMPTE_PARALLELE_MOBILE_MONEY,
    ONLY_FETCH
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../../components';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import { ViewLayout } from './view-layout';

export function ListCompteMobilMoney() {
    const formater = (tableau = []) =>
        tableau.map((item) => ({
            ...item,
            gestionnaireIdentite: `${item?.gestionnaire?.prenom || ''} ${
                item?.gestionnaire?.nom || ''
            }`,
            type: COMPTE_DISTRIBUTEUR.designation
        }));
    const payload = {
        typeEntite: COMPTE_PARALLELE_MOBILE_MONEY.code
    };
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                formater={formater}
                fetchType={ONLY_FETCH}
                payload={payload}
                reduxPropertyName="comptesMobileMoney"
                functionName={URL_CONST.GET_LIST_COMPTE_DECLARATION_INITIALS}
                nodeName={REDUX_NODE_NAME.COMPTE_MOBILE_MONEY}
                mapper={[
                    {
                        field: 'gestionnaireIdentite',
                        displayName: 'Gestionnaire',
                        position: 2
                    },
                    {
                        field: 'libelle',
                        displayName: 'Libellé',
                        position: 3
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 4
                    },
                    {
                        field: 'numero',
                        displayName: 'Numero de compte',
                        position: 6
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 7
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
