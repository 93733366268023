import { useDispatch } from 'react-redux';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { REDUX_NODE_NAME } from '../../../redux';
import { useGetReportDetail, URL_CONST } from '../../../util';
import { ReduxReportTemplate } from '../../report/template';
import { MarchandPartenaireSelector } from '../../../components';

export function ListActivateurForm() {
    const reduxDispatcher = useDispatch();
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedMarchand: {}
        }
    });
    const { fields } = formState;
    const onSuccess = (data) => {
        const items = data?.element || data?.elements || [];
        // reduxDispatcher(ajouterItems(items, nodeName));
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                items,
                REDUX_NODE_NAME.UPLOAD_TRAITEMENT_PAIEMENT_VENDEUR
            )
        );
    };
    return (
        <ReduxReportTemplate
            isInitialFetch={false}
            listTitle=""
            isLinkToDetail
            onSuccess={onSuccess}
            reduxPropertyName="uploadTraitementPaiementVendeur"
            functionName={
                URL_CONST.GET_LIST_TRAITEMENT_PAIEMENT_VENDEUR_ORGANISATION
            }
            nodeName={REDUX_NODE_NAME.UPLOAD_TRAITEMENT_PAIEMENT_VENDEUR}
            formState={formState}
            formDispatcher={formDispatcher}
            defaultPayloadMapperValues={{
                estOrganisation: true,
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                codeMarchand: fields?.selectedMarchand?.code
            }}
            payload={{
                estOrganisation: true,
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                codeMarchand: fields?.selectedMarchand?.code
            }}
            mapper={[
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand'
                },
                {
                    field: 'nombres.ELIGIBLE.nombre',
                    displayName: 'Paiement Eligible'
                },
                {
                    field: 'nombres.ELIGIBLE.total',
                    displayName: 'Total Eligible'
                },
                {
                    field: 'nombres.NON_ELIGIBLE.nombre',
                    displayName: 'Paiement Non Eligible'
                },
                {
                    field: 'nombres.NON_ELIGIBLE.total',
                    displayName: 'Total Non Eligible'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    field: 'fileName',
                    displayName: 'Fichier'
                },
                {
                    field: 'nombreMois',
                    displayName: 'Nombres Mois'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ]}
            listFormater={(data) => {
                if (data?.length && data?.[0]?.length) return data?.[0];
                if (data?.length && !data?.[0]?.length) return [];
                return data;
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedMarchand',
                    isRequired: false,
                    displayName: 'Marchand',
                    callback: (data) => {
                        if (!data?.code || data?.code === 'all') return {};
                        return { codeMarchand: data?.code };
                    }
                }
            ]}
        >
            <MarchandPartenaireSelector
                formState={formState}
                formDispatcher={formDispatcher}
                isAddAditionalOptions
                idProperty="code"
            />
        </ReduxReportTemplate>
    );
}
