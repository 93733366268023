import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    DB_NODE,
    ETAT,
    TAUX_SYSTEM
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { VirementFormSchema as uiValidator } from './validation';

/**
 * @description defaultFields for our state
 */
const defaultFields = {
    selectedTypeOperation: {},
    selectedCompte: {},
    selectedSuperDealer: {},
    selectedInstitutionFinanciere: {},
    estCommission: false,
    montant: 0,
    commission: 0,
    taux: 0
};

/**
 * @description defaultDependencies for our state
 */

export function CreationVirementForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        nappUser,
        comptes,
        organisation,
        etatCompte,
        beneficiaires,
        etatBeneficiaires,
        deviseReference,
        superDealerKinDB,
        etatSuperDealerKinDB,
        institutionsFinanciere,
        etatInstitutionsFinanciere,
        taux,
        etatTaux
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation,
        comptes: reduxState.comptes,
        etatCompte: reduxState.etat.comptes.etat,
        beneficiaires: reduxState.allPartenaireKinDB
            .filter(
                (item) =>
                    item?.etat === ETAT.ETAT_VALIDER &&
                    item?.typeEntite === 'SuperDealer'
            )
            .map((item) => ({
                ...item,
                designation: `${item?.personne?.prenom || ''} ${
                    item?.personne?.nom || ''
                }`
            })),
        etatBeneficiaires: reduxState.etat.allPartenaireKinDB.etat,
        deviseReference: reduxState.deviseReference,
        superDealerKinDB: reduxState.superDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionsFinanciere: reduxState.etat.institutionsFinanciere.etat,
        taux: reduxState.tauxSuperDealerKindb,
        etatTaux: reduxState.etat.tauxSuperDealerKindb.etat
    }));
    /**
     * Common form processes
     */
    const { idToken, isNapp } = useGetUserDetail({ firebaseUser, nappUser });

    const defaultDependencies = {};
    if (isNapp) {
        defaultDependencies.fields = [
            'beneficiaires',
            'superDealerKinDB',
            'comptes',
            'banques',
            'taux'
        ];
    } else {
        // defaultDependencies.fields = ['devises', 'comptes'];
        defaultDependencies.fields = [
            'beneficiaires',
            'comptes',
            'banques',
            'taux'
        ];
    }

    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const { fields, form } = formState;
    const formater = (tableau = []) =>
        tableau.map((item) => ({
            ...item,
            code: item.id,
            designation: `${item.numero} - ${item.devise}`
        }));

    /**
     * Recupération des données nécessaires
     */
    const payloadFetchCompteOrganistion = {};
    if (isNapp) {
        payloadFetchCompteOrganistion.typeProprietaire = 'SuperDealer';
    }
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PARTENAIRE,
                // payload: { estOrganisation: true },
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KINDB_PARTENAIRE,
                etat: etatBeneficiaires
            },
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.COMPTE,
                functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
                etat: etatCompte,
                formater,
                payload: payloadFetchCompteOrganistion
                // isCondition: !isNapp
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                payload: { estAll: true, typeEntite: nappUser?.typeEntite },
                etat: etatSuperDealerKinDB,
                isCondition: isNapp
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionsFinanciere
            },
            {
                isOnlyFetch: true,
                nodeName: REDUX_NODE_NAME.TAUX_SUPER_DEALER_KINDB,
                functionName: URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION,
                payload: { type: TAUX_SYSTEM },
                etat: etatTaux
            }
        ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = { devises: [] };
        const payloadToAddIn = {};
        if (fields.estCommission) {
            payloadToAddIn.tauxCommission = fields.commission;
        }
        if (fields.selectedSuperDealer?.code) {
            payloadToAdd.codeSuperDealer = fields.selectedSuperDealer?.code;
        }
        if (
            comptes.find((item) => item?.id === fields.selectedCompte?.id)
                ?.devise !== deviseReference?.devise
        ) {
            payloadToAdd.devises.push({
                devise: comptes.find(
                    (item) => item?.id === fields.selectedCompte?.id
                )?.devise,
                valeur: fields.taux
            });
        }
        if (!payloadToAdd.devises?.length) {
            delete payloadToAdd.devises;
        }
        const payload = {
            newOperationVirement: {
                type: fields.selectedTypeOperation?.code,
                partenaire: {
                    code: fields.selectedPartenaire?.code
                },
                compte: { reference: fields.selectedCompte?.id },
                montant: fields.montant,
                estCommission: fields.estCommission,
                ...payloadToAddIn,
                estTauxSollicite: !!payloadToAdd.devises?.length
            },
            ...payloadToAdd
        };
        // console.log(payload);
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_OPERATION_VIREMENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.VIREMENTS,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    /**
     * Return taux système en fonction d'un superdealer
     * @param {Object} sd : fields.selectedSuperDealer
     * @param {Array} txs : taux
     * @returns {Integer}
     */
    const getTauxBySuperDealer = (sd, txs) =>
        txs.find((tx) => tx?.valeurTypeEntite?.code === sd?.code)?.valeur;

    /**
     * Return Compte en fonction d'un superdealer
     * @param {Array} cpt : comptes
     * @param {Object} sd : fields.selectedSuperDealer
     * @param {Object} bq : fields.selectedInstitutionFinanciere
     * @returns {Array}
     */
    const getCompteBySuperDealer = (cpt, sd, bq) =>
        cpt
            .filter((item) => item?.valeurTypeProprietaire?.code === sd?.code)
            .filter(
                (element) => element?.institutionFinanciere?.code === bq?.code
            );

    /**
     * Return true if taille Compte en fonction d'un superdealer est > 0
     * @param {Array} cpt : comptes
     * @param {Object} sd : fields.selectedSuperDealer
     * @param {Object} bq : fields.selectedInstitutionFinanciere
     * @returns {Boolean}
     */
    const isCompteBySuperDealerExiste = (cpt, sd, bq) => {
        const res = getCompteBySuperDealer(cpt, sd, bq);
        return res.length;
    };

    /**
     * Return Array of Partenaire en fonction d'un superdealer
     * @param {Array} ptns : Partenaire
     * @param {Object} sd : fields.selectedSuperDealer
     * @returns {Array}
     */
    const getPartenaireBySuperDealer = (ptns, sd) =>
        ptns.filter((ptn) => ptn?.valeurTypeEntite?.code === sd?.code);

    /**
     * Return Array of Partenaire en fonction d'un superdealer
     * @param {Array} ptns : Partenaire
     * @param {Object} sd : fields.selectedSuperDealer
     * @returns {Array}
     */
    const isPartenaireBySuperDealer = (ptns, sd) =>
        ptns.filter((ptn) => ptn?.valeurTypeEntite?.code === sd?.code)?.length;

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'beneficiaires',
                    etat: etatBeneficiaires
                },
                {
                    dependency: 'banques',
                    etat: etatInstitutionsFinanciere
                },
                {
                    dependency: 'comptes',
                    etat: etatCompte
                    // isCondition: !isNapp
                },
                {
                    dependency: 'superDealerKinDB',
                    etat: etatSuperDealerKinDB,
                    isCondition: isNapp
                },
                {
                    dependency: 'taux',
                    etat: etatTaux
                }
            ]}
        >
            <ConditionalRenderingWrapper isShouldBeRendered={isNapp}>
                <CustomRow>
                    <CustomDropdown
                        options={superDealerKinDB}
                        defaultOption="Sélectionner"
                        label="Super Dealer* : "
                        labelClassName="col-2"
                        divClassName="col-10"
                        formDispatcher={formDispatcher}
                        name="selectedSuperDealer"
                        id="selectedSuperDealerCreationApproCompteEmoney"
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomDropdown
                    idProperty="code"
                    propertyToDisplay="designation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    label="Institution Financière* : "
                    name="selectedInstitutionFinanciere"
                    id="creationRemboursementCautionCompteInstitutFinanciereId"
                    options={institutionsFinanciere}
                    selectionClassName="form-select-md"
                    defaultOption="Sélectionner"
                    divClassName="col-sm-10"
                    labelClassName="col-sm-2"
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={getCompteBySuperDealer(
                        comptes,
                        organisation.entreprise
                            ? organisation.entreprise
                            : fields.selectedSuperDealer,
                        fields.selectedInstitutionFinanciere
                    )}
                    label="Compte*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    defaultOption="Sélectionner"
                    name="selectedCompte"
                    id="selectedCompteCreationVirement"
                    formState={formState}
                    idProperty="id"
                    disabled={
                        !isCompteBySuperDealerExiste(
                            comptes,
                            organisation.entreprise
                                ? organisation.entreprise
                                : fields.selectedSuperDealer,
                            fields.selectedInstitutionFinanciere
                        )
                    }
                />
                {!!isCompteBySuperDealerExiste(
                    comptes,
                    organisation.entreprise
                        ? organisation.entreprise
                        : fields.selectedSuperDealer,
                    fields.selectedInstitutionFinanciere
                ) &&
                !!comptes.find((item) => item?.id === fields.selectedCompte?.id)
                    ?.devise &&
                comptes.find((item) => item?.id === fields.selectedCompte?.id)
                    ?.devise !== deviseReference?.devise ? (
                    <div className="d-flex justify-content-end mt-2">
                        <span className="badge bg-primary">
                            Taux Système:{' '}
                            {getTauxBySuperDealer(
                                organisation.entreprise
                                    ? organisation.entreprise
                                    : fields.selectedSuperDealer,
                                taux
                            )}
                        </span>
                    </div>
                ) : null}
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[{ code: 'Debit' }, { code: 'Credit' }]}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner"
                    label="Type Opération* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedTypeOperation"
                    id="creationVirementSelectedTypeOperationId"
                    propertyToDisplay="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={getPartenaireBySuperDealer(
                        beneficiaires,
                        organisation.entreprise
                            ? organisation.entreprise
                            : fields.selectedSuperDealer
                    )}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner"
                    label="Partenaire* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedPartenaire"
                    id="selectedPartenaireId"
                    disabled={
                        !isPartenaireBySuperDealer(
                            beneficiaires,
                            organisation.entreprise
                                ? organisation.entreprise
                                : fields.selectedSuperDealer
                        )
                    }
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.selectedCompte?.id &&
                    comptes.find(
                        (item) => item?.id === fields.selectedCompte?.id
                    )?.devise !== deviseReference?.devise
                }
            >
                <CustomInput
                    type="number"
                    label="Taux* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Saisir"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    name="taux"
                    id="creationTauxId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Saisir"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    name="montant"
                    id="creationMontantId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="estCommissionId"
                    text="Renseigner un taux de commission?"
                    value={fields.estCommission}
                    name="estCommission"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={fields.estCommission}>
                <CustomInput
                    type="number"
                    label="Taux de Commission*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Commission"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    name="commission"
                    id="creationVirementId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
