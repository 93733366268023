import * as yup from 'yup';

export const ArticleColisSchema = yup.object().shape({
    selectedArticle: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    articles: yup
        .array()
        .of(
            yup
                .object({
                    code: yup.string().required(),
                    designation: yup.string().required()
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins un élément à la liste')
});
