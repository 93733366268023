import { SimpleTable } from '../../../components';
import { ListTypeOrganisation as data } from '../../../util';

export function ListProfilUserDedierForm() {
    return (
        <SimpleTable
            isShowDropButton={false}
            isLinkToDetail
            data={data}
            filter
            dataList={data}
            isExportAllowed
            isRefetchAllowed={false}
            identifier="code"
            tableClass="table border-top table-hover table-striped table-responsive-sm"
            mapper={[
                {
                    field: 'designation',
                    displayName: 'Désignation',
                    position: 2
                },
                {
                    field: 'valeurTypeEntiteDesignation',
                    displayName: 'Type',
                    position: 3
                },
                {
                    field: 'typeOrganisationDesignation',
                    displayName: 'Organisation',
                    position: 4
                },
                {
                    field: 'niveauOrganisation',
                    displayName: 'Niveau',
                    position: 5
                }
            ]}
        />
    );
}
