import { DB_NODE, ONLY_FETCH } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { ViewLayout } from './view-layout';

export function ListNotificationEvolutionRevendeurForm() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                fetchType={ONLY_FETCH}
                reduxPropertyName="notificationsZone"
                functionName={URL_CONST.GET_LIST_NOTIFICATION_ZONE}
                nodeName={DB_NODE.NOTIFICATION_ZONE}
                identifier="id"
                mapper={[
                    {
                        field: 'revendeur.designation',
                        displayName: 'Designation',
                        position: 1
                    },
                    {
                        field: 'revendeur.code',
                        displayName: 'Code',
                        position: 2
                    },
                    {
                        field: 'zone.designation',
                        displayName: 'Zone',
                        position: 3
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 4
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 5
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
