import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    ImputationStockCreationRelPath,
    ImputationStockViewLayoutRelPath
} from '../../../routes/registration/configuration/config-marchand-rel-path';
import { ListImputationStockForm } from './list-imputation';

export function ImputationStockViewLayout() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Imputation stock</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ImputationStockViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.STOCK.IMPUTATION_STOCK
                                        .INITIER_CREATION_IMPUTATION_STOCK
                                ]}
                                destination={
                                    ImputationStockCreationRelPath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListImputationStockForm />
        </>
    );
}
