import { buildYupErrorObject, FORM_CONST } from '@napp-inc/jnapp-util';
import { InputFeedback } from '../input/input-feedback';

/**
 * Datalist remplace le composant Datalist de react-bootstrap et prends les mêmes attributs que le composant Natif
 * @param {Object} param0 object qui contient les props du composant:
 * @param {Array} param0.options liste des options
 * @param {String} param0.htmlFor id de l'élément html
 * @param {String} param0.labelClassName classe CSS du label
 * @param {String} param0.controlClassName classe CSS du control
 * @param {String} param0.divClassName classe CSS de la div
 * @param {String} param0.label label du datalist
 * @param {Function} param0.onChange fonction qui sera appelée lorsque l'utilisateur selectionnera une option
 * @param {String} param0.placeholder placeholder du datalist
 * @param {String} param0.list définit l'id de la datalist
 * @param {String} param0.value valeur du datalist
 * @param {String} param0.name nom de la propriéte name de la datalist
 * @param {String} param0.propertyToDisplay propriété à inclure dans l'objet data
 * @param {Boolean} param0.idProperty propriété à inclure dans l'objet data comme identifiant
 *
 * @returns {React.Component}
 */
export function CustomDropdownEditable({
    options = [],
    id,
    labelClassName,
    controlClassName,
    label,
    placeholder = 'Taper pour rechercher...',
    list = 'datalistOptions',
    name,
    formDispatcher,
    uiValidator,
    formState,
    propertyToDisplay = 'designation',
    idProperty = 'code',
    divClassName,
    onKeyUp
}) {
    const { form, error, fields } = formState;
    const handleChange = (event) => {
        event.preventDefault();
        const { value: currentValue, list: currentList } = event.target;
        const dataOptions = currentList.options;
        let data = { [idProperty]: '', [propertyToDisplay]: currentValue };
        Object.getOwnPropertyNames(dataOptions).forEach((key) => {
            if (
                dataOptions[key].value.toLowerCase().trim() ===
                currentValue.toLowerCase().trim()
            ) {
                data = {
                    [propertyToDisplay]: currentValue,
                    [idProperty]: dataOptions[key].dataset.property
                };
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: name,
                data
            }
        });
        if (uiValidator && form.isSubmited) {
            uiValidator
                .validate({ ...fields, [name]: data }, { abortEarly: false })
                .then(() => {
                    formDispatcher({
                        type: FORM_CONST.VALIDATION_SUCCESS
                    });
                })
                .catch((erreur) => {
                    if (erreur.name === 'ValidationError') {
                        const err = buildYupErrorObject(erreur);
                        formDispatcher({
                            type: FORM_CONST.SET_FIELDS_ERROR,
                            payload: {
                                message: { ...err },
                                status: FORM_CONST.CREATION_ECHEC
                            }
                        });
                    }
                });
        }
    };

    return (
        <>
            {label ? (
                <label htmlFor={id} className={`form-label ${labelClassName}`}>
                    {label}
                </label>
            ) : null}
            <div className={`has-validation ${divClassName}`}>
                <input
                    onKeyUp={onKeyUp}
                    name={name}
                    onChange={handleChange}
                    className={`form-control ${
                        error && error[name] ? `is-invalid` : null
                    } ${controlClassName}`}
                    list={`${id}${list}`}
                    id={id}
                    placeholder={placeholder}
                />
                <datalist id={`${id}${list}`}>
                    {options.map((item) => (
                        <option
                            data-property={item[idProperty]}
                            aria-label={item.designation}
                            key={item[propertyToDisplay]}
                            value={item[propertyToDisplay]}
                        />
                    ))}
                </datalist>
                <InputFeedback>
                    {error && error[name] && error[name][propertyToDisplay]}
                </InputFeedback>
            </div>
        </>
    );
}
