import React from 'react';
import { ListProfilUserDedierForm } from './list-profil-user-dedier';

export function ViewLayout() {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Profils Dédiés</h1>
            </div>
            <ListProfilUserDedierForm />
        </>
    );
}
