import { BiMailSend } from 'react-icons/bi';

/**
 * @desc Regularisation super dealer rel paths
 */
export const RegularisationSuperDealerViewLayoutRelPath = {
    name: 'Regularisation super dealer',
    path: 'regularisation-super-dealer',
    icon: <BiMailSend className="icon-custom" />
};

export const RegularisationSuperDealerCreateRelPath = {
    name: '',
    path: 'create'
};

export const RegularisationSuperDealerDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Regularisation super dealer rel paths
 */
export const RegularisationSpecialeViewLayoutRelPath = {
    name: 'Regularisation Speciale',
    path: 'regul-speciale',
    icon: <BiMailSend className="icon-custom" />
};

export const RegularisationSpecialeCreateRelPath = {
    name: '',
    path: 'create'
};

export const RegularisationSpecialeDetailRelPath = {
    name: '',
    path: ':id'
};
