import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListApproCompteEmoneyForm() {
    return (
        <ListGenericForm
            reduxPropertyName="approvisionnementsMonnaie"
            functionName={URL_CONST.GET_LIST_APPRO_MONNAIE}
            nodeName={DB_NODE.APPROVISIONNEMENT_MONNAIE}
            mapper={[
                {
                    field: 'devise',
                    displayName: 'Devise',
                    position: 1
                },
                {
                    field: 'montant',
                    displayName: 'montant',
                    position: 2
                },
                {
                    field: 'superDealer.tillNumber',
                    displayName: 'till number',
                    position: 3
                },
                {
                    field: 'superDealer.designation',
                    displayName: 'Super Dealer',
                    position: 4
                },
                {
                    field: 'etat',
                    displayName: 'etat',
                    position: 5
                }
            ]}
        />
    );
}
