import { ViewLayout } from './view-layout';

export function ListIngredient() {
    const renderContent = () => (
        <ViewLayout>
            <h2>Liste Ingredient</h2>
            {/* <ListGenericForm
                identifier='id'
                reduxPropertyName="secteurs"
                functionName={URL_CONST.GET_LIST_SECTEUR_CHARGE}
                nodeName={REDUX_NODE_NAME.SECTEUR}
                mapper={[
                    {
                        field: 'id',
                        displayName: 'id',
                        position: 1
                    },
                    {
                        field: 'code',
                        displayName: 'Code',
                        position: 2
                    },
                    {
                        field: 'designation',
                        displayName: 'Designation',
                        position: 3
                    }
                ]}
            /> */}
        </ViewLayout>
    );
    return renderContent();
}
