import {
    CODE_SERVICE,
    DB_NODE,
    FORM_CONST,
    GENERIC_ACTIONS_TYPE
} from '@napp-inc/jnapp-util';
import {
    fetchElement,
    useFetchAndListening,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST, useHasService } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { ConditionalRenderingWrapper, CustomRow } from '../container';
import { CustomButtonLoader } from '../button';

/**
 * Construit une dropdown où l'on peut sélectionner les comptes
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @param {Boolean} param0.isReleve form state du parent
 * @param {String} param0.label Label du dropdown, par défaut "Caisse : "
 * @param {String} param0.propertyName propriété où la valeur de la caisse selectionnée est enregistrée dans le form state, par defaut "selectedCaisse"
 * @param {String} param0.labelClassName className du label
 * @param {String} param0.divClassName className du div
 * @param {String} param0.propertyToDisplay nom de la propriété à afficher dans le dropdown, par défaut : "designation"
 * @param {String} param0.idProperty propriété id à utiliser, par défaut : "id"
 * @returns {React.Component}
 */
export function ReportCompteSelector({
    formDispatcher,
    formState,
    isReleve = false
    // label = 'Compte : ',
    // propertyName = 'selectedCompte',
    // labelClassName = '',
    // divClassName = 'col-12',
    // propertyToDisplay = 'designation',
    // idProperty = 'idLimitCredit'
}) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        // users,
        // etatUser,
        comptes,
        etatCompte,
        superDealerKinDB,
        etatSuperDealerKinDB
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        // users: reduxState.users
        //     .filter((us) => us?.idLimitCredit)
        //     .map((item) => ({
        //         ...item,
        //         designation: `${item?.personne?.nom || ''} ${
        //             item?.personne?.prenom || ''
        //         } USD`
        //     })),
        // etatUser: reduxState.etat.users.etat,
        comptes: reduxState.comptes,
        etatCompte: reduxState.etat.comptes.etat,
        superDealerKinDB: reduxState.allSuperDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.allSuperDealerKinDB.etat
    }));

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
                .LECTURE_ALL_KINDB_COMPTE_BANCAIRE
        ]
    });

    const formater = (tableau = []) =>
        tableau.map((item) => ({
            ...item,
            code: item.id,
            designation: `${item.institutionFinanciere?.designation} - ${item.numero} - ${item.devise}`
        }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            // {
            //     isOnlyFetch: true,
            //     functionName: URL_CONST.GET_LIST_USER,
            //     nodeName: DB_NODE.USER,
            //     etat: etatUser,
            // },
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.COMPTE,
                functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
                etat: etatCompte,
                formater,
                isCondition:
                    !!isReleve &&
                    !hasService[
                        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                            .SERIVCE_CODE_RAPPORT
                            .LECTURE_ALL_KINDB_COMPTE_BANCAIRE?.code
                    ]
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { estAll: true, typeEntite: nappUser?.typeEntite },
                isCondition:
                    !!hasService[
                        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                            .SERIVCE_CODE_RAPPORT
                            .LECTURE_ALL_KINDB_COMPTE_BANCAIRE?.code
                    ]
            }
        ]
    });
    const { elements, fields } = formState;

    const onFetchSuperDealerAccount = () => {
        const { status: etat } = elements.comptes;
        formDispatcher(
            fetchElement({
                idToken,
                functionName:
                    URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION /** @desc : A changer */,
                elementPropertyName: 'comptes',
                etat,
                payload: {
                    typeProprietaire: 'SuperDealer',
                    codeValeurTypeProprietaire: fields.selectedSuperDealer.code
                },
                reduxDispatcher
            })
        );
    };
    const renderContent = () => {
        if (
            etatSuperDealerKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS ||
            etatCompte === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            !!hasService[
                                CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                                    .SERIVCE_CODE_RAPPORT
                                    .LECTURE_ALL_KINDB_COMPTE_BANCAIRE?.code
                            ]
                        }
                    >
                        <CustomRow>
                            <CustomDropdown
                                options={superDealerKinDB}
                                defaultOption="Selectionner SuperDealer"
                                label="Super Dealer: "
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedSuperDealer"
                                id="selectedSuperDealerCreationApproCompteEmoney"
                                // uiValidator={CreationApproCompteEmoneySchema}
                                formState={formState}
                            />
                        </CustomRow>
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                !!isReleve &&
                                !!hasService[
                                    CODE_SERVICE
                                        .CODE_SERVICE_MODULE_ADMINISTRATION
                                        .SERIVCE_CODE_RAPPORT
                                        .LECTURE_ALL_KINDB_COMPTE_BANCAIRE?.code
                                ]
                            }
                        >
                            <CustomButtonLoader
                                text="Voir Compte"
                                className="col-5"
                                type="button"
                                onClick={onFetchSuperDealerAccount}
                                disabled={
                                    elements?.comptes?.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                                isActionEncours={
                                    elements?.comptes?.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                            />
                        </ConditionalRenderingWrapper>
                    </ConditionalRenderingWrapper>
                    {/* <CustomDropdown
                             options={users}
                             formDispatcher={formDispatcher}
                             formState={formState}
                             label={label}
                             labelClassName={labelClassName}
                             divClassName={divClassName}
                             name={propertyName}
                             id={`${propertyName}ReportCompteSelector`}
                             propertyToDisplay={propertyToDisplay}
                             idProperty={idProperty}
                         /> */}
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            !!isReleve &&
                            !hasService[
                                CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                                    .SERIVCE_CODE_RAPPORT
                                    .LECTURE_ALL_KINDB_COMPTE_BANCAIRE?.code
                            ]
                        }
                    >
                        <CustomRow>
                            <CustomDropdown
                                options={comptes}
                                defaultOption="Selectionner Compte"
                                label="Compte"
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedSuperDealerCompte"
                                id="selectedSuperDealerCompteRapportCompteId"
                                formState={formState}
                            />
                        </CustomRow>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            !!isReleve && !!elements?.comptes?.value?.length
                        }
                    >
                        <CustomRow className="mt-3">
                            <CustomDropdown
                                options={formater(
                                    elements?.comptes?.value || []
                                )}
                                defaultOption="Selectionner Compte"
                                label="Compte :"
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedSuperDealerCompte"
                                id="selectedSuperDealerCompteRapportCompteId"
                                formState={formState}
                            />
                        </CustomRow>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper isShouldBeRendered={!isReleve}>
                        <CustomRow>
                            <CustomDropdown
                                isSort={false}
                                options={[
                                    { code: 'ALL', designation: 'Tous' },
                                    {
                                        code: 'CommandeFlash ',
                                        designation: 'Commandes Flash'
                                    },
                                    {
                                        code: 'CommandeEmoney ',
                                        designation: 'Commandes Emoney'
                                    },
                                    {
                                        code: 'ApprovisionnementSim ',
                                        designation: 'Appro Sim'
                                    }
                                ]}
                                defaultOption="Choisir"
                                label="Opération: "
                                labelClassName="col-12"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedTypeOperation"
                                id="selectedTypeOperationCreationApproCompteEmoney"
                                // uiValidator={CreationApproCompteEmoneySchema}
                                formState={formState}
                            />
                        </CustomRow>
                    </ConditionalRenderingWrapper>
                </>
            );
        }
        if (
            etatSuperDealerKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC ||
            etatCompte === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
