import * as yup from 'yup';

/**
 * @description : Schema de validation directions création form
 */
export const CrediterCautionFormValidator = yup.object().shape({
    selectedSuperDealer: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedInstitutionFinanciere: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedCompte: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    montant: yup.number().min(1),
    dateOperation: yup.string().required()
});
