import { DB_NODE, GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';
import { CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner les comptes
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @param {Boolean} param0.isReleve form state du parent
 * @param {String} param0.label Label du dropdown, par défaut "Caisse : "
 * @param {String} param0.propertyName propriété où la valeur de la caisse selectionnée est enregistrée dans le form state, par defaut "selectedCaisse"
 * @param {String} param0.labelClassName className du label
 * @param {String} param0.divClassName className du div
 * @param {String} param0.propertyToDisplay nom de la propriété à afficher dans le dropdown, par défaut : "designation"
 * @param {String} param0.idProperty propriété id à utiliser, par défaut : "id"
 * @returns {React.Component}
 */
export function UserAndDeviseSelector({ formDispatcher, formState }) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        // nappUser,
        users,
        etatUser,
        monnaiesLocales,
        etatMonnaieLocale
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        // nappUser: reduxState.nappUser,
        users: reduxState.users.map((item) => ({
            ...item,
            designation: `${item?.personne?.nom || ''} ${
                item?.personne?.prenom || ''
            }`
        })),
        etatUser: reduxState.etat.users.etat,
        monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            code: item.code || item.devise
        })),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat
    }));

    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUser
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            }
        ]
    });
    const renderContent = () => {
        if (
            etatMonnaieLocale ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS &&
            etatUser === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <>
                    <CustomRow>
                        <CustomDropdown
                            options={users}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            label="Agent"
                            labelClassName="col-12"
                            divClassName="col-12"
                            name="selectedAgent"
                            id="selectedAgentUserDeviseSelectorId"
                            idProperty="uid"
                            defaultOption="Selectionner Agent"
                        />
                    </CustomRow>
                    {/* <CustomDropdown
                             options={users}
                             formDispatcher={formDispatcher}
                             formState={formState}
                             label={label}
                             labelClassName={labelClassName}
                             divClassName={divClassName}
                             name={propertyName}
                             id={`${propertyName}ReportCompteSelector`}
                             propertyToDisplay={propertyToDisplay}
                             idProperty={idProperty}
                         /> */}
                    <CustomRow>
                        <CustomDropdown
                            options={monnaiesLocales}
                            defaultOption="Selectionner Devise"
                            label="Devise"
                            labelClassName="col-12"
                            divClassName="col-12"
                            formDispatcher={formDispatcher}
                            name="selectedDevise"
                            id="selectedDeviseUserDeviseSelectorId"
                            formState={formState}
                            propertyToDisplay="devise"
                            idProperty="devise"
                        />
                    </CustomRow>
                </>
            );
        }
        if (
            etatMonnaieLocale === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC ||
            etatUser === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
