import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    fromStringToTimestamp,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomInputDate,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { CrediterCompteViaInstitutionFin as uiValidator } from './validation';
import { URL_CONST } from '../../../util';

/**
 * @description: le state du formulaire
 */
const defaultFields = {
    selectedInstitutionFinanciere: {},
    numeroCompteExterne: '',
    montant: 0,
    dateOperation: ''
};
/**
 * @description: dependencies
 */
const defaultDependencies = {
    fields: ['banques']
};

export function CreationCrediterCompteViaInstitutFinForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        institutionsFinanciere,
        etatInstitutionsFinanciere,
        organisation
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        organisation: reduxState.organisation,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionsFinanciere: reduxState.etat.institutionsFinanciere.etat
    }));
    /**
     * Common form processes
     */
    const { idToken, oid, creator } = useGetUserDetail({
        firebaseUser,
        organisation
    });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionsFinanciere
            }
        ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            newCrediterCompte: {
                typeEntite: 'InstitutionFinanciere',
                valeurTypeEntite: {
                    code: fields.selectedInstitutionFinanciere.code,
                    designation:
                        fields.selectedInstitutionFinanciere.designation
                },
                numeroCompteExterne: fields.numeroCompteExterne, // Used instead of reference
                typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR,
                valeurTypeProprietaire: {
                    reference: oid
                },
                montant: fields.montant
            },
            dateOperation: fromStringToTimestamp(fields.dateOperation),
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CREDITER_COMPTE,
                payload,
                fields,
                uiValidator,
                reduxNodeName:
                    REDUX_NODE_NAME.CREDITER_COMPTE_VIA_INSTITUT_FINANCIERE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            onSubmit={handleSubmit}
            listDependencies={[
                {
                    dependency: 'banques',
                    etat: etatInstitutionsFinanciere
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    idProperty="code"
                    propertyToDisplay="designation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    label="Banque*"
                    uiValidator={uiValidator}
                    name="selectedInstitutionFinanciere"
                    id="crediterCompteViaInstitutFinanciereSelectId"
                    options={institutionsFinanciere}
                    selectionClassName="form-select-md"
                    defaultOption="Institution Financière"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Numéro Compte*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Numero de compte"
                    formDispatcher={formDispatcher}
                    name="numeroCompteExterne"
                    id="CrediterCompteViaInstitutFinanciereNumeroCompteExterneId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="CrediterCompteViaInstitutFinanciereMontantId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInputDate
                    id="CrediterCompteViaInstitutFinanciereDateId"
                    name="dateOperation"
                    type="date"
                    label="Date Opération*"
                    isInputGroup={false}
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="Date Operation"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
