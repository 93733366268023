/**
 * Composant personnalisé label
 * @param {Object} param0 object qui contient les props du composant:
 * @param {string} param0.text texte du label
 * @param {string} param0.htmlFor id vers lequel pointe le label
 * @param {string} param0.className Classe CSS du label, par défaut form-label
 * @returns {React.Component}
 */

export function CustomLabel({
    text = '',
    className = '',
    htmlFor = '',
    children
}) {
    return (
        <label className={`form-label ${className}`} htmlFor={htmlFor}>
            {text}
            {children}
        </label>
    );
}
