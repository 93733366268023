/* eslint-disable react/no-array-index-key */
// import { Fragment } from 'react';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { useDispatch } from 'react-redux';
import {
    // PROFIL_SYSTEM,
    TIME,
    TODAY
    // TYPE_ORGANISATION
    // resolvePathPropertyOfObject
} from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../util';
import { ReduxReportTemplate } from '../template';
import { REDUX_NODE_NAME } from '../../../redux';
import {
    ConditionalRenderingWrapper,
    CustomAlert,
    CustomRow,
    CustomSwitchButton,
    NumberDisplayer
} from '../../../components';
import { RapportDashboardBalanceFormTranspose } from './rapport-balance-transpose';

export function RapportDashboardBalanceForm(/* { typeOrganisation } */) {
    const customTdStyle = {
        textAlign: 'center',
        verticalAlign: 'middle'
    };
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: { estTranspose: false }
    });
    const { fields } = formState;
    const onSuccess = (data) => {
        const items = data?.element || data?.elements || [];
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.resetTable(
                REDUX_NODE_NAME.RAPPORT_DASHBOARD_BALANCE
            )
        );
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                items,
                REDUX_NODE_NAME.RAPPORT_DASHBOARD_BALANCE
            )
        );
    };

    const COMMANDES_FLASH_LIVREES = 'CommandesFlashLivrees';
    const COMMANDES_FLASH_ANNULEES = 'CommandesFlashAnnulees';
    const COMMANDES_FLASH_ATTENTE_VALIDATION =
        'CommandesFlashAttenteValidation';
    const COMMANDES_FLASH_ENCOURS = 'CommandesFlashEncours';
    const DEPOTS_EMONEY_VALIDEES = 'DepotsEmoneyValidees';
    const DEPOTS_EMONEY_ATTENTE_VALIDATION = 'DepotsEmoneyAttenteValidation';
    const DEPOTS_EMONEY_ANNULEES = 'DepotsEmoneyAnnulees';
    const DEPOTS_EMONEY_ENCOURS = 'DepotsEmoneyEncours';
    const REGULARISATION_ENTREE = 'RegularisationEntree';
    const REGULARISATION_SORTIE = 'RegularisationSortie';
    const TRANSFERT_BALANCE = 'TransfertBalance';
    const TRANSFERT_COMMISSION = 'TransfertCommission';
    const CREDITER_COMPTE_VIA_CASH = 'CrediterCompteViaCash';
    const CREDITER_COMPTE_VIA_EMONEY = 'CrediterCompteViaEmoney';
    const CREDITER_COMPTE_VIA_BANQUE = 'CrediterCompteViaBanque';
    const CREDITER_COMPTE_VIA_COUP_POUCE = 'CrediterCompteViaCoupPouce';
    // const TRANSFERT_EXTERNE = 'TransfertExterne'; // FIXME: Rajouter la bonne opération
    const TRANSFERT_EXTERNE_DEBIT = 'TransfertExterneDebit';
    const TRANSFERT_EXTERNE_CREDIT = 'TransfertExterneCredit';
    const RETRAIT_EMONEY = 'RetraitEmoney';
    const CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR =
        'CrediterCompteViaPaiementVendeur';
    const PAIEMENT_VENDEUR = 'PaiementVendeur';
    const DEMANDE_NUMERO_REFERENCE = 'DemandeNumeroReference';

    const BALANCES_REVENDEUR = 'BalancesRevendeur';
    const BALANCES_CASH_COLLECTEUR = 'BalancesCashCollecteur';
    const BALANCES_SUPER_DEALER = 'BalancesSuperDealer';
    const BALANCES_NAPP = 'BalancesNapp';
    const BALANCES_DISTRIBUTEUR = 'BalancesDistributeur';
    const BALANCES_END_USER = 'BalancesEndUser';

    const USD = 'USD';
    const CDF = 'CDF';

    // const CORRESPONDANCE_BALANCE_ORGANISATION = {
    //     [BALANCES_REVENDEUR]: TYPE_ORGANISATION.REVENDEUR,
    //     [BALANCES_NAPP]: TYPE_ORGANISATION.NAPP,
    //     [BALANCES_SUPER_DEALER]: TYPE_ORGANISATION.SUPER_DEALER,
    //     [BALANCES_CASH_COLLECTEUR]: TYPE_ORGANISATION.CASH_COLLECTEUR,
    //     [BALANCES_DISTRIBUTEUR]: TYPE_ORGANISATION.DISTRIBUTEUR,
    //     [BALANCES_END_USER]: PROFIL_SYSTEM.PROFIL_END_USER.designation
    // };

    const renderSpecificly = ({ data }) => {
        const dataToRender = data || [];
        const mappingDataToCells = ({
            operation,
            devise,
            typeProprietaire,
            level = 'high'
        }) => {
            if (level === 'high') {
                return data?.find(
                    (item) =>
                        item?.typeProprietaire === typeProprietaire &&
                        item?.valeurTypeProprietaire === devise
                )?.[operation];
            }
            return data?.find(
                (item) =>
                    item?.typeProprietaire === typeProprietaire &&
                    item?.valeurTypeProprietaire === devise
            )?.operation?.[operation];
        };
        if (dataToRender?.length > 0 && !Array.isArray(dataToRender?.[0])) {
            return (
                <div className="aio-table-container">
                    <table
                        className="table table-bordered border-top table-hover table-striped table-responsive-sm"
                        border={1}
                    >
                        <thead
                            className="fw-normal aio-table-header"
                            // style={{ position: 'sticky' }}
                        >
                            <tr className="aio-table-tr">
                                <th
                                    id="aio-th-one"
                                    style={customTdStyle}
                                    rowSpan={2}
                                    colSpan={2}
                                    className="border border-dark"
                                >
                                    Operation
                                </th>
                                <th style={customTdStyle} colSpan={3}>
                                    Napp
                                </th>
                                <th style={customTdStyle} colSpan={3}>
                                    SuperDealer
                                </th>
                                <th style={customTdStyle} colSpan={3}>
                                    Revendeur
                                </th>
                                <th style={customTdStyle} colSpan={3}>
                                    Cash Collecteur
                                </th>
                                <th style={customTdStyle} colSpan={3}>
                                    End User
                                </th>
                                <th style={customTdStyle} colSpan={3}>
                                    Distributeur
                                </th>
                            </tr>
                            <tr>
                                <th style={customTdStyle}>USD</th>
                                <th style={customTdStyle}>CDF</th>
                                <th style={customTdStyle}>Total (USD)</th>

                                <th style={customTdStyle}>USD</th>
                                <th style={customTdStyle}>CDF</th>
                                <th style={customTdStyle}>Total (USD)</th>

                                <th style={customTdStyle}>USD</th>
                                <th style={customTdStyle}>CDF</th>
                                <th style={customTdStyle}>Total (USD)</th>

                                <th style={customTdStyle}>USD</th>
                                <th style={customTdStyle}>CDF</th>
                                <th style={customTdStyle}>Total (USD)</th>

                                <th style={customTdStyle}>USD</th>
                                <th style={customTdStyle}>CDF</th>
                                <th style={customTdStyle}>Total (USD)</th>

                                <th style={customTdStyle}>USD</th>
                                <th style={customTdStyle}>CDF</th>
                                <th style={customTdStyle}>Total (USD)</th>
                            </tr>
                        </thead>
                        <tbody className="text-center aio-table-body">
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    colSpan={2}
                                >
                                    Solde Ouverture
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeOuverture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    colSpan={2}
                                >
                                    Solde fermeture
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'soldeFermeture',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    colSpan={2}
                                >
                                    Difference
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: 'difference',
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    rowSpan={4}
                                >
                                    Depot
                                </td>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Encours{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    En attente{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Livré{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_VALIDEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Annulé{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEPOTS_EMONEY_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    rowSpan={4}
                                >
                                    Airtime
                                </td>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Encours{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ENCOURS,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    En attente{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ATTENTE_VALIDATION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Livré{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_LIVREES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Annulé{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    COMMANDES_FLASH_ANNULEES,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    rowSpan={5}
                                >
                                    Crediter Compte
                                </td>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Cash{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_CASH,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Emoney{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Banque{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_BANQUE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Coup de pouce{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_COUP_POUCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Paiement Vendeur{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    colSpan={2}
                                >
                                    Paiements Vendeurs
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: PAIEMENT_VENDEUR,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    colSpan={2}
                                >
                                    Retrait
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: RETRAIT_EMONEY,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    rowSpan={2}
                                >
                                    Régularisation
                                </td>
                                <td id="aio-td-four" style={customTdStyle}>
                                    {' '}
                                    Débit
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_SORTIE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Credit{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    REGULARISATION_ENTREE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    colSpan={2}
                                >
                                    Balance Transféré
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_BALANCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    colSpan={2}
                                >
                                    Commission Transférée
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation: TRANSFERT_COMMISSION,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    rowSpan={2}
                                >
                                    Transfert Externes
                                </td>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Débit{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_DEBIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td id="aio-td-four" style={customTdStyle}>
                                    Credit{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    TRANSFERT_EXTERNE_CREDIT,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                            <tr>
                                <td
                                    id="aio-td-one"
                                    style={customTdStyle}
                                    colSpan={2}
                                >
                                    Demande Numéro Reference
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: USD,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    {' '}
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: CDF,
                                                typeProprietaire: BALANCES_NAPP,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_SUPER_DEALER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_REVENDEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_CASH_COLLECTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_END_USER,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: USD,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}>
                                    <NumberDisplayer
                                        value={
                                            mappingDataToCells({
                                                operation:
                                                    DEMANDE_NUMERO_REFERENCE,
                                                devise: CDF,
                                                typeProprietaire:
                                                    BALANCES_DISTRIBUTEUR,
                                                level: 'inner'
                                            }) || 0
                                        }
                                        nombreApresVirgule={4}
                                    />{' '}
                                </td>
                                <td style={customTdStyle}> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
        if (Array.isArray(data)) {
            return (
                <CustomAlert successMessage="Aucune information disponible !" />
            );
        }
        return null;
    };

    return (
        <>
            <CustomRow>
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="estBeneficiaire"
                    text="Transposer le tableau?"
                    value={fields.estTranspose}
                    name="estTranspose"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!fields.estTranspose}
            >
                <ReduxReportTemplate
                    isInitialFetch={false}
                    listTitle=""
                    isLinkToDetail
                    onSuccess={onSuccess}
                    reduxPropertyName="rapportDashBoardBalance"
                    functionName={URL_CONST.GET_LIST_RAPPORT_BALANCE}
                    nodeName={REDUX_NODE_NAME.RAPPORT_DASHBOARD_BALANCE}
                    formState={formState}
                    formDispatcher={formDispatcher}
                    renderSpecificly={renderSpecificly}
                    payload={{
                        dateDebut: TIME.utc000000(TODAY.at000000()),
                        dateFin: TIME.utc235959(TODAY.at235959()),
                        estRapportBalance: true,
                        estNotDate: true
                    }}
                    defaultPayloadMapperValues={{
                        estRapportBalance: true,
                        estNotDate: true
                    }}
                    listFormater={(data) => {
                        if (data?.length && data?.[0]?.length) return data?.[0];
                        if (data?.length && !data?.[0]?.length) return [];
                        return data;
                    }}
                    payloadMapper={[
                        {
                            name: 'dateDebut',
                            property: 'dateDebut',
                            isRequired: true,
                            displayName: 'Date début'
                        },
                        {
                            name: 'dateFin',
                            property: 'dateFin',
                            isRequired: true,
                            displayName: 'Date fin'
                        }
                    ]}
                />
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={fields.estTranspose}
            >
                <RapportDashboardBalanceFormTranspose />
            </ConditionalRenderingWrapper>
        </>
    );
}
