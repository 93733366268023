import * as yup from 'yup';

/**
 * @description : Schema de declaration ressource form
 */
export const ProduitMaterielSchema = yup.object().shape({
    dateDebut: yup.string().required(),
    dateFin: yup.string().required(),
    selectedSecteur: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedRessource: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    montantNotifier: yup.number().min(1),
    selectedCompte: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    referencePaiement: yup.string().required(),
    marchandMobileMoneyToSelect: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    list: yup.array().of(
        yup
            .object({
                id: yup.string().required(),
                element: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                tauxCroissance: yup.string().required(),
                datePaiement: yup.number().min(1)
            })
            .shape({})
    )
});
