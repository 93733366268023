import { buildYupErrorObject, FORM_CONST } from '@napp-inc/jnapp-util';
import { InputFeedback } from './input-feedback';

/**
 * Composant personnalisé CustomTextInputArea
 * @param {Object} param0 object qui contient les props du composant:
 * @param {boolean} param0.disabled Désactive le composant ou Active le composant
 * @param {string} param0.label Texte affiché dans le label du composant
 * @param {string} param0.id Identifiant unique du composant
 * @param {string} param0.labelClassName Classe CSS du label
 * @param {string} param0.controlClassName Classe CSS du input du composant
 * @param {boolean} param0.readOnly Indique si le composant est en lecture seule ou non
 * @param {string} param0.name Nom du champ du formulaire qui correspond à une propriété du state
 * @param {boolean} param0.required Indique si le composant est obligatoire ou non
 * @param {Function} param0.formDispatcher Fonction qui permet d'envoyer des actions au store du composant
 * @param {Object} param0.formState State du composant
 * @param {Object} param0.uiValidator Schema de validation du composant
 * @param {Number} param0.rows Nombre de lignes du composant
 * @param {string} param0.placeholder Texte affiché dans le composant avant la saisie de données
 * @param {Boolean} param0.isInputGroup vrai si la dispostion doit être en colonne
 * @returns un textarea de type InputGroup
 */
export function CustomTextAreaInput({
    disabled = false,
    label,
    id,
    labelClassName,
    controlClassName,
    readOnly = false,
    name,
    formDispatcher,
    formState = {},
    uiValidator,
    rows = 3,
    isInputGroup = true,
    divClassName = '',
    placeholder = 'Saississez votre texte ici...'
}) {
    const { error, fields, form } = formState;
    const handleChange = async (event) => {
        const { value: currentValue } = event.target;
        formDispatcher({
            type: FORM_CONST.TEXT_FIELD_CHANGE,
            payload: { field: name, data: currentValue }
        });
        if (uiValidator && form.isSubmitted) {
            uiValidator
                .validate(
                    { ...fields, [name]: currentValue },
                    {
                        abortEarly: false
                    }
                )
                .then(() => {
                    formDispatcher({
                        type: FORM_CONST.VALIDATION_SUCCESS
                    });
                })
                .catch((erreur) => {
                    if (erreur.name === 'ValidationError') {
                        const err = buildYupErrorObject(erreur);
                        formDispatcher({
                            type: FORM_CONST.SET_FIELDS_ERROR,
                            payload: {
                                message: { ...err },
                                status: FORM_CONST.CREATION_ECHEC
                            }
                        });
                    }
                });
        }
    };
    return (
        <>
            <label htmlFor={id} className={`form-label ${labelClassName}`}>
                {label}
            </label>
            <div
                className={`${
                    isInputGroup ? 'input-group' : ''
                } has-validation ${divClassName}`}
            >
                <textarea
                    name={name}
                    readOnly={readOnly}
                    className={`form-control ${
                        error && error[name] ? `is-invalid` : null
                    } ${controlClassName}`}
                    placeholder={placeholder}
                    disabled={disabled}
                    id={id}
                    onChange={handleChange}
                    value={fields && fields[name]}
                    aria-describedby={`addon-wrapping-${id}`}
                    rows={rows}
                />
                <InputFeedback>{error && error[name]}</InputFeedback>
            </div>
        </>
    );
}
