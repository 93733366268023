import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    TAUX_SYSTEM
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { DecaissementFormSchema as uiValidator } from './validation';

const defaultFields = {
    montant: 0,
    taux: 0,
    tauxB: 0,
    selectedCaisse: {},
    selectedPartenaire: {},
    selectedDevise: {},
    isTaux: false,
    isTauxB: false,
    typeOperation: 'Decaissement'
};

/**
 * @description defaultDependencies for our state
 */
const defaultDependencies = {
    fields: ['caisses', 'beneficiaires', 'monnaie', 'taux']
};

export function CreationDecaissementForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        caisses,
        etatCaisses,
        beneficiaires,
        etatBeneficiaires,
        deviseReference,
        monnaiesLocales,
        etatMonnaieLocale,
        taux,
        etatTaux
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        caisses: reduxState.caissesActiveUser.map((item) => ({
            ...item,
            designation: `${item?.designation || ''}-${item?.devise || ''}`
        })),
        etatCaisses: reduxState.etat.caissesActiveUser.etat,
        beneficiaires: reduxState.beneficiaires.map((item) => ({
            ...item,
            designation: `${item?.personne?.prenom || ''} ${
                item?.personne?.nom || ''
            }`
        })),
        etatBeneficiaires: reduxState.etat.beneficiaires.etat,
        monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            code: item.code || item.devise
        })),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat,
        deviseReference: reduxState.deviseReference,
        taux: reduxState.taux,
        etatTaux: reduxState.etat.taux.etat
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                payload: { estOrganisation: true },
                nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
                etat: etatCaisses
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PARTENAIRE,
                // payload: { estOrganisation: true },
                nodeName: REDUX_NODE_NAME.BENEFICIAIRE,
                etat: etatBeneficiaires
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            },
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.TAUX,
                functionName: URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION,
                etat: etatTaux
            }
        ]
    });

    const getDeviseCaisse = () => {
        const deviseCaisse = caisses.find(
            (item) => item.id === fields.selectedCaisse.id
        )?.devise;
        return deviseCaisse === deviseReference.devise;
    };
    const isDeviseRef = getDeviseCaisse();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            !fields.selectedCaisse.id ||
            !fields.selectedPartenaire.code ||
            !fields.montant ||
            !fields.selectedDevise.devise
        )
            return;
        const payloadToAdd = { devises: [] };
        if (
            /* isDeviseRef && */ fields.isTaux &&
            fields.selectedDevise.devise !== deviseReference?.devise &&
            !!fields.taux
        ) {
            // payloadToAdd.taux = fields.taux;
            payloadToAdd.devises.push({
                devise: fields.selectedDevise.devise,
                valeur: fields.taux
            });
        }
        if (
            fields.isTauxB &&
            !isDeviseRef &&
            caisses.find((item) => item.id === fields.selectedCaisse?.id)
                ?.devise &&
            !!fields.tauxB
        ) {
            // payloadToAdd.taux = fields.taux;
            payloadToAdd.devises.push({
                devise: caisses.find(
                    (item) => item.id === fields.selectedCaisse?.id
                )?.devise,
                valeur: fields.tauxB
            });
        }
        if (!payloadToAdd.devises.length) {
            delete payloadToAdd.devises;
        }
        const payload = {
            newEncaissementDecaissement: {
                typeProprietaire: fields.typeOperation,
                caisse: {
                    reference: fields.selectedCaisse.id
                },
                partenaire: {
                    code: fields.selectedPartenaire.code
                },
                montant: fields.montant,
                devise: fields.selectedDevise.devise,
                estTauxSollicite: !!payloadToAdd?.devises?.length,
                ...payloadToAdd
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_ENCAISSEMENT_DECAISSEMENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.DECAISSEMENTS,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'caisses',
                    etat: etatCaisses
                },
                {
                    dependency: 'beneficiaires',
                    etat: etatBeneficiaires
                },
                {
                    dependency: 'monnaie',
                    etat: etatMonnaieLocale
                },
                {
                    dependency: 'taux',
                    etat: etatTaux
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={beneficiaires}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner Partenaire"
                    label="Partenaire*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-5"
                    name="selectedPartenaire"
                    id="selectedPartenaireId"
                    uiValidator={uiValidator}
                />
                <CustomDropdown
                    options={monnaiesLocales}
                    divClassName="col-12 col-sm-5"
                    formDispatcher={formDispatcher}
                    name="selectedDevise"
                    id="selectedDeviseCreationCaisse"
                    defaultOption="Sélectionner Devise"
                    formState={formState}
                    propertyToDisplay="devise"
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            {/* Pour le TauxA */}
            <CustomRow
                isShouldBeRendered={
                    !!fields.selectedDevise?.devise &&
                    fields.selectedDevise?.devise !== deviseReference?.devise
                }
            >
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="isTauxId"
                    text="Solliciter un taux?"
                    value={fields.isTaux}
                    name="isTaux"
                    divClassName="col-sm-2"
                    uiValidator={uiValidator}
                />
                {!fields.isTaux &&
                !!fields.selectedDevise?.devise &&
                fields.selectedDevise?.devise !== deviseReference?.devise ? (
                    <span className="badge bg-primary col-sm-2">{`${`Taux Système: 1 ${
                        deviseReference?.devise
                    } = ${
                        taux.find(
                            (rate) =>
                                rate?.devise ===
                                    fields.selectedDevise?.devise &&
                                rate?.type === TAUX_SYSTEM
                        )?.valeur || '***'
                    } ${fields.selectedDevise?.devise}`}`}</span>
                ) : (
                    <CustomInput
                        type="number"
                        isFloat
                        disabled={!fields.isTaux}
                        divClassName="col-12 col-sm-10"
                        isInputGroup={false}
                        placeholder="Taux"
                        formDispatcher={formDispatcher}
                        name="taux"
                        id="creationDecaissementDecaissementTauxId"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                )}
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={caisses}
                    label="Caisse*"
                    defaultOption="Sélectionner caisse"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCaisse"
                    id="creationEncaissementDecaissementSelectedCaisseId"
                    formState={formState}
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="creationencaissementDecaissementId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            {/* Pour le TauxB */}
            <CustomRow
                isShouldBeRendered={!!fields.selectedCaisse.id && !isDeviseRef}
            >
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="isTauxBId"
                    text="Renseigner un taux?"
                    value={fields.isTauxB}
                    name="isTauxB"
                    divClassName="col-sm-2"
                    uiValidator={uiValidator}
                />
                {!fields.isTauxB && !isDeviseRef ? (
                    <span className="badge bg-primary col-sm-2">{`${`Taux Système: 1 ${
                        deviseReference?.devise
                    } = ${
                        taux.find(
                            (rate) =>
                                rate?.devise ===
                                    caisses.find(
                                        (caisse) =>
                                            caisse.id ===
                                            fields.selectedCaisse.id
                                    )?.devise && rate?.type === 'TauxSystem'
                        )?.valeur || '***'
                    } ${
                        caisses.find(
                            (caisse) => caisse.id === fields.selectedCaisse.id
                        )?.devise
                    }`}`}</span>
                ) : (
                    <CustomInput
                        type="number"
                        divClassName="col-12 col-sm-10"
                        isInputGroup={false}
                        placeholder="Taux Caisse"
                        formDispatcher={formDispatcher}
                        name="tauxB"
                        id="creationEncaissementDecaissementTauxBId"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                )}
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
