import { TIME, TODAY } from '@napp-inc/jnapp-util';
import {
    URL_CONST,
    getDesignationStock,
    useGetReportDetail
} from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import {
    StockSuperDealerSelector,
    TypeExpressionValeurSelector
} from '../../../../components';

export function RapportStockKinDBForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedStockSuperDealer: {}
        }
    });
    const listFormater = (donnees) => {
        const result = (donnees?.[0]?.listReturn || []).map((item) => {
            const typeBusiness = getDesignationStock(
                item?.detail?.marchand?.designation,
                item?.detail?.typeBusiness
            );
            return {
                ...item,
                id: `${item?.detail?.marchand?.reference}${typeBusiness}`,
                designation: typeBusiness
            };
        });

        return result;
    };
    const isUnite = formState?.fields?.selectedTypeExpression?.id === 'Unité';
    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                listTitle={`Valeur en ${isUnite ? 'Unité' : 'USD'}`}
                dynamicNodeName={`evolutionsStockSuperDealer-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_STOCK_SUPER_DEALER}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={[
                    {
                        displayName: 'Marchand',
                        field: 'designation'
                    },
                    {
                        displayName: 'Initial',
                        isTotal: true,
                        field: isUnite
                            ? 'soldeOuverture'
                            : 'balance.soldeOuverture'
                    },
                    {
                        displayName: 'Actuel',
                        isTotal: true,
                        field: isUnite
                            ? 'soldeFermeture'
                            : 'balance.soldeFermeture'
                    },
                    {
                        displayName: 'Physique',
                        isTotal: true,
                        field: isUnite
                            ? 'detail.quantitePhysique'
                            : 'detail.balance.montant'
                    },
                    {
                        displayName: 'Livrées',
                        isTotal: true,
                        field: isUnite
                            ? 'operation.CommandesFlashLivrees.montant'
                            : 'operation.CommandesFlashLivrees.balance.montant'
                    },
                    {
                        displayName: 'Facturation',
                        isTotal: true,
                        field: isUnite
                            ? 'operation.Facturation.montant'
                            : 'operation.Facturation.balance.montant'
                    },
                    {
                        displayName: 'En attente',
                        isTotal: true,
                        field: isUnite
                            ? 'operation.CommandesFlashAttenteValidation.montant'
                            : 'operation.CommandesFlashAttenteValidation.balance.montant'
                    },
                    {
                        displayName: 'En cours',
                        isTotal: true,
                        field: isUnite
                            ? 'operation.CommandesFlashEncours.montant'
                            : 'operation.CommandesFlashEncours.balance.montant'
                    },
                    {
                        displayName: 'Régul Entrée',
                        isTotal: true,
                        field: isUnite
                            ? 'operation.RegularisationEntree.montant'
                            : 'operation.RegularisationEntree.balance.montant'
                    },
                    {
                        displayName: 'Régul Sortie',
                        isTotal: true,
                        field: isUnite
                            ? 'operation.RegularisationSortie.montant'
                            : 'operation.RegularisationSortie.balance.montant'
                    }
                ]}
                isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    typeProprietaire: 'SuperDealer',
                    typeRapport: 'EvolutionStock'
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    typeRapport: 'EvolutionStock',
                    typeProprietaire: 'SuperDealer'
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedStockSuperDealer',
                        property: 'codeSuperDealer',
                        isRequired: false,
                        displayName: 'Super dealer',
                        callback: (item) => {
                            if (!item?.code || item?.code === 'all') return {};
                            return { codeSuperDealer: item?.code };
                        }
                    }
                ]}
            >
                <TypeExpressionValeurSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
                <StockSuperDealerSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    idProperty="code"
                    isAddAditionalOptions
                />
            </ReduxReportTemplate>
        </>
    );
}
