import { useCallback } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    Add,
    CODE_SERVICE,
    DB_NODE,
    Div,
    ETAT,
    FORM_CONST,
    Mul,
    Sub,
    percuAndRetournedMontantDevise
} from '@napp-inc/jnapp-util';
// import { useEffect } from 'react';
import {
    ConditionalRenderingWrapper,
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomRow,
    CustomSwitchButton,
    DetailWrapper,
    // StateShowerComponent,
    // SaisiMontantRetourForm,
    // StateShowerComponent,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useDebounce, useGetDetailForm } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { DynamicMonoSelectAndInputSecond } from '../../../components/special-components/dynamic-mono-select-and-input-second';

const defaultFields = {
    tableauDeviseRetour: [],
    actionEncours: '',
    previewValue: 'abc',
    selectedSuperDealer: 'SuperDealer',
    estVersement: false,
    estDeviseSollicite: false,
    selectedClient: {},
    selectedAgent: {},
    deviseRetourSelect: {},
    deviseRetourInput: 0,
    devisePercuSelect: {},
    devisePercuInput: 0,
    montantPercu: [],
    montantRetour: [],
    montant: 0,
    selectedDevise: {},
    estRecouvert: false
};
const defaultDependencies = {
    fields: [
        'monnaiesLocale',
        'taux',
        'clientsOrganisation',
        'userAgentVendeur'
    ]
};

const objectKeysPercu = {
    devise: 'devisePercuSelect.code',
    montant: 'devisePercuInput'
};

const objectKeysRetour = {
    devise: 'deviseRetourSelect.code',
    montant: 'deviseRetourInput'
};

const PERTE_CHANGE = 'PerteChange';
export function DetailRetraitCashForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher,
        reduxProps
    } = useGetDetailForm({
        nodeName: 'retraitsEmoney',
        defaultFields,
        defaultDependencies,
        defaultElements: ['monnaiesOrganisation'],
        listNode: [
            {
                name: 'monnaiesLocale',
                format: (list) =>
                    list.map((item) => ({
                        ...item,
                        designation: item.devise,
                        code: item.devise
                    }))
            },
            { name: 'taux' },
            { name: 'clientsOrganisation' },
            { name: 'userAgentVendeur' },
            { name: 'allCoupures' },
            { name: 'deviseReference' }
        ],
        listFunctions: [
            {
                id: 'monnaiesLocale',
                isOnlyFetch: true,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE
            },
            {
                id: 'taux',
                isOnlyFetch: true,
                nodeName: DB_NODE.TAUX,
                functionName: URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION
            },
            {
                id: 'clientsOrganisation',
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_NUMERO_CLIENT,
                nodeName: REDUX_NODE_NAME.CLIENT_ORGANISATION
            },
            {
                id: 'userAgentVendeur',
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER_A_SELECTIONNER,
                nodeName: REDUX_NODE_NAME.USER_AGENT_VENDEUR
            }
        ]
    });
    const { monnaiesLocale, deviseReference, taux, allCoupures } = reduxProps;
    /**
     * Debounce
     */
    useDebounce({
        name: 'selectedSuperDealer',
        preview: 'previewValue',
        formDispatcher,
        isObject: false,
        formState,
        delay: 1500,
        idToken,
        functionName: URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION_BY_DEVISES,
        elementPropertyName: 'monnaiesOrganisation',
        payloadToAdd: {
            typeEntite: entity?.typePartenariat,
            valeurTypeEntite: entity?.valeurTypePartenariat,
            devise: entity?.devise
        },
        dataLength: 0,
        condition:
            !!entity?.typePartenariat &&
            !!entity?.valeurTypePartenariat &&
            !!entity?.devise
    });
    const { fields, form, elements } = formState;
    const getMontantDeviseByDifference = ({
        tabs = [],
        montant = 0,
        objet
    }) => {
        let difference = 0;
        const findTypeSystem = tabs.find((tab) => tab.type === 'TauxSystem');
        const findType = tabs.find((tab) => tab.type !== 'TauxSystem');
        if (findTypeSystem && findType) {
            if (findType?.type === 'TauxAchat') {
                difference = Math.abs(
                    Sub(findTypeSystem.valeur, findType.valeur)
                );
            }
            if (findType?.type === 'TauxVente') {
                difference = Math.abs(
                    Sub(Div(1, findTypeSystem.valeur), Div(1, findType.valeur))
                );
            }
        }
        const totalMontant =
            objet?.devise === reduxProps.deviseReference?.code && objet?.montant
                ? Mul(findTypeSystem?.valeur, objet?.montant)
                : 0;
        const remise = Mul(difference, montant);
        const totalGeneral = Add(totalMontant || 0, remise || 0);
        return { remise, totalMontant, totalGeneral };
    };

    /**
     * Ordering Monnaie By Perte Change DESC or Gain Change ASC
     */
    const orderDevise = useCallback(({ monnaies, constat, typeRemise }) => {
        if (!monnaies || !constat) return [];
        const types = monnaies.length
            ? Object.keys(monnaies?.find((item) => 'natures' in item)?.natures)
            : [];
        const deviseOrdered = [];
        types.forEach((type) => {
            monnaies.forEach((monnaie) => {
                if ('natures' in monnaie && type === typeRemise) {
                    if (monnaie?.natures?.[type]?.constant === constat)
                        deviseOrdered.push({
                            devise: monnaie?.devise,
                            ...monnaie?.natures?.[type],
                            type
                        });
                }
            });
        });
        return deviseOrdered.sort((a, b) =>
            constat === PERTE_CHANGE
                ? b.difference - a.difference
                : a.difference - b.difference
        );
    }, []);

    const calculRemise = useCallback(() => {
        const tabRetour = percuAndRetournedMontantDevise(
            fields.montantPercu,
            fields.montantRetour
        );
        const valeurAttendue = [];
        const percuTab = tabRetour.filter((item) => item.estPercu);
        percuTab.forEach((percuObject) => {
            const type =
                percuObject.devise === deviseReference.code ? 'Achat' : 'Vente';
            const orderedByPerteChange = orderDevise({
                monnaies: monnaiesLocale,
                constat: PERTE_CHANGE,
                typeRemise: type
            });

            if (
                percuObject.devise === deviseReference.code &&
                !!orderedByPerteChange.length
            ) {
                const coupure = allCoupures
                    ?.find((coupures) => coupures.code === percuObject.devise)
                    ?.coupures?.sort((a, b) => a.valeur - b.valeur)?.[0];
                const [target] = orderedByPerteChange;
                const total = Math.floor(
                    Div(
                        Mul(
                            target?.differenceDevise || 0,
                            percuObject.montant || 0
                        ),
                        coupure.valeur || 1
                    )
                );
                valeurAttendue.push({
                    total,
                    devise: percuObject.devise,
                    monnaieCible: target?.devise,
                    montant: total,
                    estEntree: false
                });
            }
            if (
                percuObject.devise !== deviseReference.code &&
                !!orderedByPerteChange.length
            ) {
                const coupure = allCoupures
                    ?.find((coupures) => coupures.code === deviseReference.code)
                    ?.coupures?.sort((a, b) => a.valeur - b.valeur)?.[0];
                const [target] = orderedByPerteChange.filter(
                    (item) => item.devise === percuObject.devise
                );
                if (target) {
                    const total = Math.floor(
                        Div(
                            Mul(
                                target?.differenceDevise || 0,
                                percuObject.montant || 0
                            ),
                            coupure.valeur || 1
                        )
                    );
                    valeurAttendue.push({
                        total,
                        devise: percuObject.devise,
                        monnaieCible: deviseReference.code,
                        montant: total,
                        estEntree: false
                    });
                }
            }
        });
        return valeurAttendue;
    }, [
        allCoupures,
        deviseReference.code,
        fields.montantPercu,
        fields.montantRetour,
        monnaiesLocale,
        orderDevise
    ]);

    const checkTotalPercuAndMontantWithoutRemise = useCallback(() => {
        let isValid = false;
        let total = 0;
        let tauxMonnaieLocal = 1;
        // let totalWithRemise = 0;
        const tabRetour = percuAndRetournedMontantDevise(
            fields.montantPercu,
            fields.montantRetour
        );
        tabRetour.forEach((item) => {
            if (item?.devise === deviseReference?.code) {
                total = !item?.estPercu
                    ? total + (item?.montant || 0)
                    : total - (item?.montant || 0);
            } else {
                tauxMonnaieLocal = taux.find(
                    (tx) =>
                        tx.devise === item?.devise && tx.type === 'TauxSystem'
                )?.valeur;
                total = !item?.estPercu
                    ? total + Div(item?.montant, tauxMonnaieLocal)
                    : total - Div(item?.montant, tauxMonnaieLocal);
            }
        });
        const equivalent =
            entity?.devise === deviseReference?.code
                ? entity?.montant
                : Div(entity?.montant, tauxMonnaieLocal);
        isValid = total === equivalent;
        return isValid;
    }, [
        deviseReference?.code,
        entity?.devise,
        entity?.montant,
        fields.montantPercu,
        fields.montantRetour,
        taux
    ]);

    function sommeDesMontantsParDevise(tableaux) {
        const sommesParDevise = new Map();
        // Parcourir les tableaux
        // eslint-disable-next-line no-restricted-syntax
        for (const tableau of tableaux) {
            const { devise } = tableau;

            if (sommesParDevise.has(devise)) {
                sommesParDevise.set(
                    devise,
                    Add(sommesParDevise.get(devise), tableau.montant)
                );
            } else {
                sommesParDevise.set(devise, tableau.montant);
            }
        }
        const resultats = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const [devise, montant] of sommesParDevise) {
            const resultat = {
                devise,
                montant,
                estEntree: false
            };
            resultats.push(resultat);
        }
        return resultats;
    }

    const updateEtat = () => {
        const payloadToAdd = {};
        if (fields.estVersement) {
            payloadToAdd.type = 'CrediterCompte';
            payloadToAdd.idCompte = fields.selectedUser.uid;
        } else {
            payloadToAdd.type = 'RetraitCashClient';
            payloadToAdd.idCompte = fields.selectedClient.id;
        }
        if (fields.estDeviseSollicite) {
            payloadToAdd.deviseASolliciter =
                elements?.monnaiesOrganisation?.value?.deviseSollicite;
            payloadToAdd.montantACrediter = getMontantDeviseByDifference({
                tabs: elements?.monnaiesOrganisation?.value?.list || [],
                montant: entity?.montant,
                objet: entity
            })?.totalGeneral;
        } else {
            payloadToAdd.deviseASolliciter = entity.devise;
            payloadToAdd.montantACrediter = entity.montant;
        }
        const montantRetourFromCalculRemise = calculRemise();
        const isBool = checkTotalPercuAndMontantWithoutRemise();
        if (!isBool) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Montant renseigné différent du total',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const newTabSommesMontantParDevise = sommeDesMontantsParDevise(
            fields.montantRetour.concat(
                montantRetourFromCalculRemise.map((item) => ({
                    devise: item.monnaieCible,
                    montant: item.montant,
                    estEntree: item.estEntree
                }))
            )
        );
        const montantsDevise = percuAndRetournedMontantDevise(
            fields.montantPercu,
            // fields.montantRetour
            newTabSommesMontantParDevise
        );
        const payload = {
            creator,
            ...payloadToAdd,
            etat: ETAT.ETAT_ENCOURS,
            idCommandeEmoney: id,
            montantsDevise
        };
        // console.log(payload);
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_COMMANDE_EMONEY,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.COMMANDE_EMONEY_RETRAIT,
                reduxDispatcher
            })
        );
    };

    const filterClientBySuperDealerMarchand = ({
        allClients = reduxProps.clientsOrganisation
    }) =>
        allClients?.filter(
            (item) => item?.marchand?.reference === entity?.marchand?.reference
        );
    const renderContent = () => (
        <>
            {/* <StateShowerComponent state={entity} /> */}
            <DetailWrapper
                functionName={URL_CONST.GET_COMMANDE_EMONEY_BY_ID}
                nodeName={REDUX_NODE_NAME.COMMANDE_EMONEY_RETRAIT}
                formDispatcher={formDispatcher}
                formState={formState}
                reduxDispatcher={reduxDispatcher}
                onSubmit={updateEtat}
                subtitle="Retrait Cash"
                entity={entity}
                listDependencies={[
                    {
                        dependency: 'monnaiesLocale',
                        etat: reduxProps?.etatMonnaiesLocale
                    },
                    {
                        dependency: 'taux',
                        etat: reduxProps?.etatTaux
                    },
                    {
                        dependency: 'clientsOrganisation',
                        etat: reduxProps?.etatClientsOrganisation
                    }
                ]}
                id={id}
                schema={[
                    {
                        title: 'Référence',
                        description: 'numeroReference',
                        className: 'border-bottom'
                    },
                    {
                        title: 'Montant',
                        description: 'montant',
                        className: 'border-bottom'
                    },
                    {
                        title: 'Devise',
                        description: 'devise',
                        className: 'border-bottom'
                    },
                    {
                        title: 'Date',
                        description: 'dateCreation',
                        className: 'border-bottom'
                    },
                    {
                        title: 'Etat',
                        description: 'etat'
                    }
                ]}
                idToken={idToken}
            >
                <UserServiceWrapper
                    services={[
                        { code: 'test' },
                        CODE_SERVICE.SERVICE_EMONEY
                            .LECTURE_RETRAIT_CASH_EMONEY_BY_OWNER,
                        CODE_SERVICE.SERVICE_EMONEY.VALIDER_RETRAIT_CASH_EMONEY
                    ]}
                >
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            entity?.etat === ETAT.ETAT_PRE_ACTIVE
                        }
                    >
                        <div className="card bg-danger">
                            <div className="card-body bg-danger text-white">
                                <IoIosWarning
                                    style={{ width: '24px', height: '24px' }}
                                />{' '}
                                Veuillez contacter votre service client pour
                                valider votre demande de retrait !
                            </div>
                        </div>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            entity?.etat !== ETAT.ETAT_PRE_ACTIVE
                        }
                    >
                        <CustomSwitchButton
                            id="estVersementCreationCommandeFlash"
                            text="Versement"
                            inputTitle="Retrait Pour Versement"
                            formDispatcher={formDispatcher}
                            value={fields.estVersement}
                            name="estVersement"
                            divClassName="col-md-8 my-4"
                            formState={formState}
                            // uiValidator={uiValidator}
                        />
                        {fields.estVersement ? (
                            <CustomRow>
                                <CustomDropdown
                                    options={reduxProps?.userAgentVendeur?.map(
                                        (item) => ({
                                            ...item,
                                            uid: item?.owner,
                                            designation: `${
                                                item?.prenom || ''
                                            } ${item?.nom || ''}`
                                        })
                                    )}
                                    label="Agent vendeur*"
                                    labelClassName="col-12 col-sm-2"
                                    divClassName="col-12 col-sm-10"
                                    formDispatcher={formDispatcher}
                                    name="selectedUser"
                                    id="selectedUserDetailCaisse"
                                    formState={formState}
                                    idProperty="uid"
                                />
                            </CustomRow>
                        ) : (
                            <>
                                <CustomRow>
                                    <CustomDropdown
                                        label="Client: "
                                        labelClassName="col-12 col-sm-2"
                                        divClassName="col-12 col-sm-10"
                                        options={filterClientBySuperDealerMarchand(
                                            {
                                                allClients:
                                                    reduxProps.clientsOrganisation
                                            }
                                        )?.map((item) => ({
                                            ...item,
                                            designation: `${
                                                item?.valeurTypeProprietaire
                                                    ?.designation || ''
                                            }-${item?.numero || ''}`
                                        }))}
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        defaultOption="Selectionner Client"
                                        name="selectedClient"
                                        id="selectedClientCreationClientEmoneyId"
                                        idProperty="id"
                                        disabled={
                                            !filterClientBySuperDealerMarchand({
                                                allClients:
                                                    reduxProps.clientsOrganisation
                                            })?.length
                                        }
                                        // uiValidator={uiValidator}
                                    />
                                </CustomRow>
                                <ConditionalRenderingWrapper
                                    isShouldBeRendered={
                                        !!elements?.monnaiesOrganisation?.value
                                            ?.list?.length &&
                                        !!elements?.monnaiesOrganisation?.value
                                            ?.deviseSollicite
                                    }
                                >
                                    <CustomAlert
                                        successMessage={`Total en dévise Sollicité  : ${
                                            getMontantDeviseByDifference({
                                                tabs:
                                                    elements
                                                        ?.monnaiesOrganisation
                                                        ?.value?.list || [],
                                                montant: entity?.montant,
                                                objet: entity
                                            })?.totalMontant
                                        } + ${
                                            getMontantDeviseByDifference({
                                                tabs:
                                                    elements
                                                        ?.monnaiesOrganisation
                                                        ?.value?.list || [],
                                                montant: entity?.montant,
                                                objet: entity
                                            })?.remise
                                        } ${
                                            elements?.monnaiesOrganisation
                                                ?.value?.deviseSollicite
                                        }`}
                                    />

                                    <CustomSwitchButton
                                        id="estDeviseSolliciteCreationCommandeFlash"
                                        text="Maintenir la devise sollicité ?"
                                        inputTitle="La Devise sollicité est la devise qui mouvementera les comptes ou les balances"
                                        formDispatcher={formDispatcher}
                                        value={fields.estDeviseSollicite}
                                        name="estDeviseSollicite"
                                        divClassName="col-md-8 my-4"
                                        formState={formState}
                                        // uiValidator={uiValidator}
                                    />
                                </ConditionalRenderingWrapper>

                                <CustomRow>
                                    <CustomCol>
                                        <div className="shadow card">
                                            <div className="card-header">
                                                <p className="fw-normal mb-0">
                                                    Cash à encaisser
                                                </p>
                                            </div>
                                            <div className="card-body">
                                                <CustomRow>
                                                    <DynamicMonoSelectAndInputSecond
                                                        options={
                                                            reduxProps.monnaiesLocale
                                                        }
                                                        id="deviseMontantPercu"
                                                        name="devisePercu"
                                                        defaultOption="Selectionner devise"
                                                        formState={formState}
                                                        formDispatcher={
                                                            formDispatcher
                                                        }
                                                        type="number"
                                                        identifier="devise"
                                                        placeholder="montant"
                                                        field="montantPercu"
                                                        objectKeys={
                                                            objectKeysPercu
                                                        }
                                                        // uiValidator={uiValidator}
                                                    />
                                                </CustomRow>
                                            </div>
                                        </div>
                                    </CustomCol>
                                    <CustomCol>
                                        <div className="shadow card">
                                            <div className="card-header">
                                                <p className="fw-normal mb-0">
                                                    Cash à retourner
                                                </p>
                                            </div>
                                            <div className="card-body">
                                                <CustomRow>
                                                    <DynamicMonoSelectAndInputSecond
                                                        options={
                                                            reduxProps.monnaiesLocale
                                                        }
                                                        id="deviseMontantRetour"
                                                        name="deviseRetour"
                                                        defaultOption="Selectionner devise"
                                                        formState={formState}
                                                        formDispatcher={
                                                            formDispatcher
                                                        }
                                                        type="number"
                                                        identifier="devise"
                                                        placeholder="montant"
                                                        field="montantRetour"
                                                        objectKeys={
                                                            objectKeysRetour
                                                        }
                                                        // uiValidator={uiValidator}
                                                    />
                                                </CustomRow>
                                                {calculRemise()?.map((item) => (
                                                    <CustomAlert
                                                        key={item.monnaieCible}
                                                        successMessage={`Remise  : ${item.total} ${item.monnaieCible}`}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </CustomCol>
                                </CustomRow>
                                {/* <SaisiMontantRetourForm
                            montant={entity?.montant}
                            devise={entity?.devise}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            tableauDeviseRetourName="tableauDeviseRetour"
                        /> */}
                            </>
                        )}

                        {/* <StateShowerComponent state={formState} /> */}
                        <CustomRow className="mt-3">
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={updateEtat}
                                    text="Retirer"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                />
                            </CustomCol>
                        </CustomRow>
                    </ConditionalRenderingWrapper>
                </UserServiceWrapper>
            </DetailWrapper>
        </>
    );
    return renderContent();
}
