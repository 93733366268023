import { CustomDropdown, CustomRow } from '../../../../components';

export function CorrespondanceTypeComponent({
    formDispatcher,
    formState,
    id,
    selectedCorrespondanceTypeName,
    uiValidator
}) {
    return (
        <CustomRow>
            <CustomDropdown
                options={[
                    {
                        code: 'idAgent',
                        designation: 'Id Agent'
                    },
                    {
                        code: 'idUser',
                        designation: 'Id User'
                    }
                ]}
                label="Correspondance*"
                labelClassName="col-md-2"
                divClassName="col-md-10"
                formDispatcher={formDispatcher}
                name={selectedCorrespondanceTypeName}
                id={`${id}selectedCorrespondanceTypeComponent`}
                uiValidator={uiValidator}
                formState={formState}
            />
        </CustomRow>
    );
}
