import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { DB_NODE, Sub } from '@napp-inc/jnapp-util';
import { LimiteCreditCard } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

export function HomeLimiteCredit() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, limitCredit, ecouteurLimiteCredit } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            limitCredit: reduxState.limitCredit,
            ecouteurLimiteCredit: reduxState.etat.limitCredit.ecouteur
        })
    );
    /**
     * Common form processes
     */

    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isDocument: true,
                path: DB_NODE.COMPTE,
                id: limitCredit?.id,
                nodeName: REDUX_NODE_NAME.COMPTE_LIMITE_CREDIT,
                ecouteur: ecouteurLimiteCredit
            }
        ]
    });
    const contentToRender = () => (
        <div className="col-12">
            <LimiteCreditCard
                text="Limite Credit"
                type="primary"
                textAlternatif="LIMITE CREDIT INDISPONIBLE !"
                icon={7}
                limit={limitCredit?.limit || 0}
                value={limitCredit?.solde || 0}
                utilise={Sub(limitCredit?.limit || 0, limitCredit?.solde || 0)}
                isCompact
                nombreApresVirgule={2}
            />
        </div>
    );
    return contentToRender();
}
