export const schema = [
    {
        title: 'Designation',
        description: 'designation',
        className: 'border-bottom'
    },
    {
        title: 'Classe',
        description: 'classe',
        className: 'border-bottom'
    },
    {
        title: 'compte imputation',
        description: 'compteImputation.designation',
        className: 'border-bottom'
    },
    {
        title: 'Devise',
        description: 'devise',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation'
    }
];
