import { useDispatch } from 'react-redux';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetReportDetail } from '../../../util';
import { ReduxReportTemplate } from '../../report/template';

export function ListDepensesForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultElements: ['depenses']
    });
    const reduxDispatcher = useDispatch();
    const onSuccess = (data) => {
        const items = data?.element || data?.elements || [];
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.resetTable(REDUX_NODE_NAME.DEPENSES)
        );
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                items,
                REDUX_NODE_NAME.DEPENSES
            )
        );
    };
    return (
        <ReduxReportTemplate
            isInitialFetch={false}
            listTitle=""
            isLinkToDetail
            reduxPropertyName="depenses"
            nodeName={REDUX_NODE_NAME.DEPENSES}
            functionName={URL_CONST.GET_LIST_DEPENSE_INITIALS}
            formState={formState}
            formDispatcher={formDispatcher}
            onSuccess={onSuccess}
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959())
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                }
            ]}
            mapper={[
                {
                    field: 'caisse.designation',
                    displayName: 'Caisse'
                },
                {
                    field: 'devise',
                    displayName: 'Devise'
                },
                {
                    field: 'raison.designation',
                    displayName: 'Raison'
                },
                {
                    field: 'montant',
                    displayName: 'Montant'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
