import * as yup from 'yup';

/**
 * @description : Schema de Appro Stock Prod Materiel création form
 */
export const ApproStockProduitMaterielSchema = yup.object().shape({
    selectedProduit: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedVariete: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    metaInfoVariete: yup.string().when('selectedVariete', {
        is: (selectedVariete) =>
            selectedVariete.code !== '' || selectedVariete.code !== undefined,
        then: yup.string().required(),
        otherwise: yup.string()
    }),
    varietes: yup.array().of(
        yup
            .object({
                id: yup.string().required(),
                typeEntite: yup.string().required(),
                designationVariete: yup.string().required(),
                valeurTypeEntite: yup.string().required(),
                quantite: yup.number().min(1)
            })
            .shape({})
    ),
    // .required()
    // .min(1, 'Ajoutez au moins un élément à la liste'),
    produitWithoutVariety: yup.array().of(
        yup
            .object({
                id: yup.string().required(),
                codeProduit: yup.string().required(),
                quantite: yup.number().min(1)
            })
            .shape({})
    ),
    // .required()
    // .min(1, 'Ajoutez au moins un élément à la liste'),
    produitWithVariety: yup.array().of(
        yup
            .object({
                id: yup.string().required(),
                codeProduit: yup.string().required(),
                designationProduit: yup.string().required(),
                varietes: yup.array()
            })
            .shape({})
    ),
    // .required()
    // .min(1, 'Ajoutez au moins un élément à la liste'),
    quantite: yup.number().min(1)
});
