export const commandeMapper = [
    {
        displayName: 'Marchand',
        field: 'marchand.designation'
    },
    {
        field: 'valeurTypeOrganisation.designation',
        displayName: 'Organisation'
    },
    {
        field: 'agent',
        displayName: 'Agent'
    },
    {
        displayName: 'Client',
        field: 'client.designation'
    },
    {
        field: 'montant',
        displayName: 'Quantité'
    },
    {
        field: 'nombre',
        displayName: 'Nombre exécution'
    },
    {
        field: 'entree',
        displayName: 'Total entrée'
    },
    {
        field: 'sortie',
        displayName: 'Total sortie'
    },
    {
        field: 'dateOperation',
        displayName: 'Création'
    },
    {
        field: 'dateDerniereModification',
        displayName: 'Dernière modification'
    }
];
