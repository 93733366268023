import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail,
    useFetchAndListening
} from '@napp-inc/jnapp-hook';
import { TIME, TODAY, formInitialState } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { URL_CONST, useRelaunchCallback } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ChartWithCarroussel } from './chart-with-carroussel';
import { ChartWithoutCarroussel } from './chart-without-carroussel';
import { CardWithoutCarroussel } from './card-without-carroussel';
import { CardWithCarroussel } from './card-with-carroussel';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TIME.utc000000(TIME.add(TODAY.at000000(), { days: -30 })),
    dateFin: TIME.utc235959(TODAY.at235959()),
    isFetching: false,
    devise: '',
    count: 0
};

export function RapportStockSuperDealerForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser, report, etatReportStock, listRapportDate } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            report: reduxState.reportStocksOrganisation?.todayStatus || [],
            listRapportDate:
                reduxState.reportStocksOrganisation?.rapportsDate || [],
            etatReportStock: reduxState.etat.reportStocksOrganisation.etat
        }));
    const { idToken, isNapp } = useGetUserDetail({ firebaseUser, nappUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const fetchOnSuccess = ({ element }) => {
        const today = TIME.utc000000(TODAY.at000000());
        const rapportsDate = element?.rapportsDate || [];
        const todayStatus = [];
        rapportsDate.forEach((item) => {
            const singleDay = item?.dates?.find((rp) => rp.date === today);
            if (singleDay) {
                todayStatus.push({
                    list: [
                        {
                            designation: 'Ouverture',
                            quantite: singleDay?.soldeOuverture,
                            className: 'bg-secondary'
                        },
                        {
                            designation: 'Entree',
                            quantite: singleDay?.entree,
                            className: 'bg-primary'
                        },
                        {
                            designation: 'Sortie',
                            quantite: singleDay?.sortie,
                            className: 'bg-warning'
                        },
                        {
                            designation: 'Fermeture',
                            quantite: singleDay?.soldeFermeture,
                            className: 'bg-success'
                        }
                    ],
                    detail: item?.detail,
                    typeProprietaire: item?.typeProprietaire
                });
            }
        });
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.setItem(
                {
                    todayStatus,
                    rapportsDate,
                    lastUpdate: Date.now()
                },
                REDUX_NODE_NAME.STOCK_ORGANISATION
            )
        );
    };
    const fetchReport = () => {
        const { dateFin, dateDebut } = fields;
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload: {
                    typeRapport: 'Journalier',
                    dateDebut,
                    dateFin
                },
                functionName: URL_CONST.GET_LIST_STOCK_SUPER_DEALER,
                etat: elements.mouvements.status,
                formDispatcher,
                onSuccess: fetchOnSuccess,
                reduxDispatcher
            })
        );
    };
    useRelaunchCallback({ callback: fetchReport });
    const onSuccessDeFetch = (data) => {
        fetchOnSuccess({ element: data });
    };
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_STOCK_SUPER_DEALER,
                nodeName: REDUX_NODE_NAME.STOCK_ORGANISATION,
                etat: etatReportStock,
                payload: {
                    typeEntite: 'Journalier',
                    dateDebut: fields.dateDebut,
                    dateFin: fields.dateFin
                },
                onSuccess: onSuccessDeFetch
            }
        ]
    });
    const renderContent = () => (
        <>
            {isNapp ? (
                <CardWithCarroussel dataList={report} />
            ) : (
                <CardWithoutCarroussel dataList={report} />
            )}
            <hr />
            {isNapp ? (
                <ChartWithCarroussel dataList={listRapportDate} />
            ) : (
                <ChartWithoutCarroussel dataList={listRapportDate} />
            )}
        </>
    );
    return renderContent();
}
