export const schema = [
    {
        description: 'marchand.designation',
        title: 'Marchand'
    },
    {
        title: 'Actif',
        description: 'estActif',
        isBoolean: true
    },
    {
        description: 'etat',
        title: 'Etat'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
