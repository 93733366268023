import * as yup from 'yup';

/**
 * @description : Schema affiliation agent livreur
 */
export const AffilationAgentLivreurFormSchema = yup.object().shape({
    tillNumber: yup.string().required(),
    carteRose: yup.string().required(),
    permisConduire: yup.string().required()
});
