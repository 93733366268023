import React from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    CONTRATS,
    ETAT,
    FORM_CONST,
    ONLY_FETCH,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    // CustomLabel,
    // CustomRadioButton,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST } from '../../../../util';

export function UpdateFonctionForm({
    idToken,
    typeEntite,
    uiValidator,
    initialState
}) {
    const reduxDispatcher = useDispatch();
    const { fonctions, etatFonction, charges, etatCharge } = useSelector(
        (reduxState) => ({
            fonctions: reduxState.allFonction.filter(
                (item) => item.etat === ETAT.ETAT_ADOPTE
            ),
            etatFonction: reduxState.etat.allFonction.etat,
            charges: reduxState.chargesFixe,
            etatCharge: reduxState.etat.chargesFixe.etat
        })
    );
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                fetchType: ONLY_FETCH,
                functionName: URL_CONST.GET_LIST_ALL_FONCTION,
                nodeName: REDUX_NODE_NAME.ALL_FONCTION,
                etat: etatFonction,
                isCondition: typeEntite === TYPE_ORGANISATION.NAPP
            },
            {
                fetchType: ONLY_FETCH,
                functionName: URL_CONST.GET_LIST_CHARGE_FIXE,
                nodeName: REDUX_NODE_NAME.CHARGE_FIXE,
                etat: etatCharge,
                isCondition: typeEntite === TYPE_ORGANISATION.NAPP
            }
        ]
    });
    const { fields, form } = formState;
    const ajouterCharge = () => {
        const { montant, niveau, selectedCharge, selectedTypeContrat } = fields;
        if (selectedCharge?.code && selectedTypeContrat.code) {
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'codeMixed',
                    field: 'listCharge',
                    data: {
                        ...selectedCharge,
                        contrat: selectedTypeContrat,
                        codeMixed: `${selectedCharge.code}${selectedTypeContrat.code}`,
                        montant,
                        niveau,
                        typeAcquisition: fields.selectedAcquis.code,
                        estAcquis: fields.selectedAcquis.code === 'ACQUIS'
                    }
                }
            });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedFonction, listCharge } = fields;
        const payload = {};
        const chargesToSave = [];
        listCharge.forEach((item) => {
            const existing = chargesToSave.find(
                (exist) => exist.code === item.code
            );
            const payloadOuterLevel = {};
            if (!existing) {
                if (Object.prototype.hasOwnProperty.call(item, 'estAcquis')) {
                    payloadOuterLevel.estAcquis = item?.estAcquis;
                }
                chargesToSave.push({
                    code: item.code,
                    designation: item.designation,
                    niveau: item?.niveau,
                    ...payloadOuterLevel,
                    typesContrat: [
                        {
                            montant: item.montant,
                            typeContrat: item.contrat.code
                        }
                    ]
                });
            } else {
                existing.typesContrat.push({
                    montant: item.montant,
                    typeContrat: item.contrat.code
                });
            }
        });
        if (selectedFonction.code) {
            payload.codeFonction = selectedFonction.code;
        }
        if (chargesToSave.length) {
            payload.charges = chargesToSave;
        }
        // const payload = {
        //     newFonction: {
        //         estProfilUser,
        //         ...toAdd
        //     },
        //     charges: chargesToSave
        // };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_MODIFICATION_FONCTION,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.FONCTION_UPDATE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'fonctions',
                    etat: etatFonction,
                    isCondition: typeEntite === TYPE_ORGANISATION.NAPP
                },
                {
                    dependency: 'charges',
                    etat: etatCharge,
                    isCondition: typeEntite === TYPE_ORGANISATION.NAPP
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={fonctions}
                    label="Sélectionnner fonction*"
                    defaultOption="Fonction"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedFonction"
                    id="selectedFonctionModificationFonction"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomDropdown
                    options={charges}
                    label="Sélectionnner charge*"
                    defaultOption="Charge"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-2"
                    formDispatcher={formDispatcher}
                    name="selectedCharge"
                    id="selectedChargeCreationFonction"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    type="number"
                    isFloat
                    isInputGroup={false}
                    divClassName="col-12 col-md-1"
                    formDispatcher={formDispatcher}
                    placeholder="Niveau"
                    name="niveau"
                    id="niveauCreationFonction"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomDropdown
                    options={[{ code: 'ACQUIS' }, { code: 'NON ACQUIS' }]}
                    defaultOption="Etat"
                    divClassName="col-12 col-sm-2"
                    formDispatcher={formDispatcher}
                    name="selectedAcquis"
                    id="selectedAcquisCreationFonction"
                    formState={formState}
                    uiValidator={uiValidator}
                    propertyToDisplay="code"
                />
                <CustomDropdown
                    options={CONTRATS.TYPES_CONTRATS}
                    divClassName="col-12 col-sm-2"
                    formDispatcher={formDispatcher}
                    defaultOption="Contrat"
                    name="selectedTypeContrat"
                    id="selectedTypeContratCreationFonction"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    type="number"
                    isFloat
                    isInputGroup={false}
                    divClassName="col-12 col-md-1"
                    formDispatcher={formDispatcher}
                    placeholder="Montant"
                    name="montant"
                    id="montantCreationFonction"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomCol className="col-sm-1">
                    <CustomAddButton callback={ajouterCharge} size="25em" />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    <SimpleTable
                        data={fields.listCharge}
                        identifier="codeMixed"
                        listSelectedItemName="listCharge"
                        formDispatcher={formDispatcher}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                displayName: 'charge',
                                field: 'designation'
                            },
                            {
                                displayName: 'niveau',
                                field: 'niveau'
                            },
                            {
                                displayName: 'type',
                                field: 'typeAcquisition'
                            },
                            {
                                displayName: 'montant',
                                field: 'montant'
                            },
                            {
                                displayName: 'contrat',
                                field: 'contrat.designation'
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
