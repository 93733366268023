import {
    submitPostAction,
    submitPutAction,
    useFetchAndListening,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import {
    CATEGORIE_MARCHAND,
    DB_NODE,
    FORM_CONST,
    GENERIC_ACTIONS_TYPE,
    ONLY_FETCH,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { UserServiceSelectionComponent } from './user-service-selection';

export function OctroiRevocationServiceComponent({
    id,
    isOrganisation,
    formState,
    formDispatcher,
    children,
    entity
}) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        dynamicNode,
        dynamicEtatNode,
        servicesByOrganisation,
        etatServicesByOrganisation,
        allServices,
        etatAllServices
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        servicesByOrganisation: reduxState.servicesByOrganisation,
        etatServicesByOrganisation: reduxState.etat.servicesByOrganisation.etat,
        allServices: reduxState.allServices,
        etatAllServices: reduxState.etat.allServices.etat,
        dynamicNode: reduxState.dynamicNode,
        dynamicEtatNode: reduxState.dynamicEtatNode
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const { fields } = formState;
    const dynamicNodeName = `${REDUX_NODE_NAME.SYS_SERVICE_BY_USERS}${id}`;
    const typeEntite = id.includes('0') ? id.substring(0, id.length - 3) : id;
    const payloadToAdd = {};
    if (entity?.valeurTypeEntite) {
        payloadToAdd.valeurTypeEntite = entity.valeurTypeEntite;
    }
    if (entity?.typeOrganisation) {
        payloadToAdd.typeOrganisation = entity.typeOrganisation;
    }
    if (entity?.niveauOrganisation) {
        payloadToAdd.niveauOrganisation = Number(entity.niveauOrganisation);
    }
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                fetchType: ONLY_FETCH,
                functionName: URL_CONST.GET_LIST_SYS_ACTIF_OF_A_USER,
                dynamicNodeName,
                isDynamicNode: true,
                etat: dynamicEtatNode[dynamicNodeName]?.etat,
                payload: {
                    estActif: true,
                    owner: id
                },
                isCondition: !isOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SYS_SERVICES_BY_ORGANISATION,
                nodeName: REDUX_NODE_NAME.SERVICES_BY_ORGANISATION,
                etat: etatServicesByOrganisation,
                isCondition: !isOrganisation
            },
            {
                fetchType: ONLY_FETCH,
                functionName:
                    URL_CONST.GET_LIST_SYS_SERVICES_BY_TYPE_ENTREPRISE,
                dynamicNodeName,
                isDynamicNode: true,
                etat: dynamicEtatNode[dynamicNodeName]?.etat,
                payload: {
                    newSysServicesTypeEnteprise: {
                        estActif: false,
                        typeEntite,
                        ...payloadToAdd
                    }
                },
                isCondition: isOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_ALL_SYS_SERVICE,
                nodeName: DB_NODE.SYST_SERVICE,
                etat: etatAllServices,
                isCondition: isOrganisation
            }
        ]
    });
    const updateEtat = (etat = 'desactiver') => {
        const actionEncours = etat;
        const { toAdd, toRemove } = fields;
        const estActif = etat === 'desactiver';
        const list = estActif
            ? toAdd.map((codeSysService) => ({
                  estActif,
                  codeSysService
              }))
            : toRemove.map((codeSysService) => ({
                  estActif,
                  codeSysService
              }));
        // if (!list.length) {
        //     return;
        // }
        const payload = {
            list
        };
        if (isOrganisation) {
            payload.newSysServicesTypeEnteprise = { typeEntite };
            payload.list = payload.list.map(
                ({ codeSysService }) => codeSysService
            );
        } else {
            payload.owner = id;
            payload.list = payload.list.map((item) => ({
                codeSysService: item.codeSysService,
                estActif: item.estActif
            }));
        }
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        if (!isOrganisation) {
            formDispatcher(
                submitPutAction({
                    idToken,
                    functionName: URL_CONST.PUT_LIST_SYS_SERVICE_USER,
                    payload,
                    onSuccess: ({ element }) => {
                        reduxDispatcher(
                            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItemsDN(
                                element,
                                dynamicNodeName
                            )
                        );
                        if (estActif) {
                            formDispatcher({
                                type: FORM_CONST.FIELD_CHANGE,
                                payload: {
                                    field: 'toAdd',
                                    data: []
                                }
                            });
                        } else {
                            formDispatcher({
                                type: FORM_CONST.FIELD_CHANGE,
                                payload: {
                                    field: 'toRemove',
                                    data: []
                                }
                            });
                        }
                        // TODO: utiliser un meilleur composant pour afficher ce message
                        // eslint-disable-next-line no-alert
                        alert('opération réussie');
                    },
                    onError: (e) => {
                        // TODO: utiliser un meilleur composant pour afficher ce message
                        // eslint-disable-next-line no-alert
                        alert('une erreur est survenue : ', e.message);
                    },
                    reduxDispatcher
                })
            );
        } else {
            /**
             * @description Gestion erreur cas par cas
             */
            if (
                (id.includes(TYPE_ORGANISATION.REVENDEUR) ||
                    id.includes(TYPE_ORGANISATION.SUPER_DEALER)) &&
                !entity?.valeurTypeEntite
            ) {
                return;
            }

            if (
                id.includes(TYPE_ORGANISATION.MARCHAND) &&
                (entity?.valeurTypeEntite ===
                    CATEGORIE_MARCHAND.COMMERCE_GENERAL ||
                    entity?.valeurTypeEntite ===
                        CATEGORIE_MARCHAND.RESTAURANT) &&
                entity?.typeOrganisation !==
                    CATEGORIE_MARCHAND.TYPE_ORGANISATION.PROXIMITE
            ) {
                return;
            }

            if (
                id.includes(TYPE_ORGANISATION.MARCHAND) &&
                (entity?.valeurTypeEntite ===
                    CATEGORIE_MARCHAND.COMMERCE_GENERAL ||
                    entity?.valeurTypeEntite ===
                        CATEGORIE_MARCHAND.RESTAURANT) &&
                entity?.typeOrganisation ===
                    CATEGORIE_MARCHAND.TYPE_ORGANISATION.PROXIMITE &&
                !entity?.niveauOrganisation
            ) {
                return;
            }

            payload.newSysServicesTypeEnteprise = {
                ...payload.newSysServicesTypeEnteprise,
                ...payloadToAdd
            };

            formDispatcher(
                submitPostAction({
                    idToken,
                    functionName: URL_CONST.POST_SYS_SERVICE_TYPE_ENTREPRISE,
                    payload,
                    onSuccess: ({ element }) => {
                        reduxDispatcher(
                            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItemsDN(
                                element,
                                dynamicNodeName
                            )
                        );
                        if (estActif) {
                            formDispatcher({
                                type: FORM_CONST.FIELD_CHANGE,
                                payload: {
                                    field: 'toAdd',
                                    data: []
                                }
                            });
                        } else {
                            formDispatcher({
                                type: FORM_CONST.FIELD_CHANGE,
                                payload: {
                                    field: 'toRemove',
                                    data: []
                                }
                            });
                        }
                        // TODO: utiliser un meilleur composant pour afficher ce message
                        // eslint-disable-next-line no-alert
                        alert('opération réussie');
                    },
                    onError: (e) => {
                        // TODO: utiliser un meilleur composant pour afficher ce message
                        // eslint-disable-next-line no-alert
                        alert('une erreur est survenue : ', e.message);
                    },
                    reduxDispatcher
                })
            );
        }
    };
    const renderContent = () => {
        if (
            ((!isOrganisation &&
                etatServicesByOrganisation ===
                    GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS) ||
                (isOrganisation &&
                    etatAllServices ===
                        GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS)) &&
            dynamicEtatNode[dynamicNodeName]?.etat ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <div className="flex-fill shadow p-2">
                    {children}
                    <div className="mt-4 p-2">
                        <UserServiceSelectionComponent
                            formDispatcher={formDispatcher}
                            fields={fields}
                            buttonType="desactiver"
                            selectedItemListName="toRemove"
                            dataList={dynamicNode[dynamicNodeName]}
                            formState={formState}
                            onUpdate={updateEtat}
                            inputName="toRemoveInputSearch"
                            isOrganisation={isOrganisation}
                        />
                        {isOrganisation && <br />}
                        <UserServiceSelectionComponent
                            formDispatcher={formDispatcher}
                            fields={fields}
                            title="désactivés"
                            buttonType="activer"
                            formState={formState}
                            onUpdate={updateEtat}
                            selectedItemListName="toAdd"
                            inputName="toAddInputSearch"
                            isOrganisation={isOrganisation}
                            dataList={
                                !isOrganisation
                                    ? servicesByOrganisation.filter(
                                          ({ code }) =>
                                              !dynamicNode[
                                                  dynamicNodeName
                                              ]?.find(
                                                  (item) => item.code === code
                                              )
                                      )
                                    : allServices.filter(
                                          ({ code }) =>
                                              !dynamicNode[
                                                  dynamicNodeName
                                              ]?.find(
                                                  (item) => item.code === code
                                              )
                                      )
                            }
                        />
                    </div>
                </div>
            );
        }
        if (
            etatServicesByOrganisation ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC ||
            dynamicEtatNode[dynamicNodeName]?.etat ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC ||
            (isOrganisation &&
                etatAllServices === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC)
        ) {
            return <CustomAlert error="une erreur est survenue" />;
        }
        return <CenteredSpinner />;
    };

    return renderContent();
}
