import { Array, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import {
    URL_CONST,
    traitementMouvement,
    useGetReportDetail
} from '../../../../util';
import { StandardReportTemplate } from '../../template';
import { AgentSuperDealerSelector } from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';

const formater = (data) =>
    Array.tri({
        data: traitementMouvement(data?.[0] || []),
        property: 'dateCreation',
        order: 'desc'
    });
export function ReleveAgentCashCollecteurForm({ typeOrganisation }) {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedAgentCashCollecteur: {}
        }
    });
    return (
        <>
            {/* <StateShowerComponent state={formState?.elements} /> */}
            <StandardReportTemplate
                functionName={URL_CONST.GET_LIST_MOUVEMENT_PLAGE}
                formState={formState}
                formDispatcher={formDispatcher}
                listFormater={formater}
                listTitle={
                    formState?.fields?.selectedAgentCashCollecteur?.designation
                }
                mapper={[
                    {
                        field: 'valeurTypeOrganisation.designation',
                        displayName: 'Super Dealer'
                    },
                    {
                        field: 'typeEntite',
                        displayName: 'Créateur'
                    },
                    {
                        field: 'soldePrecedent',
                        displayName: 'Ouverture',
                        isNumber: true
                    },
                    {
                        field: 'entree',
                        displayName: 'Entrée',
                        isNumber: true
                    },
                    {
                        field: 'sortie',
                        displayName: 'Sortie',
                        isNumber: true
                    },
                    {
                        field: 'soldeActuel',
                        displayName: 'Fermeture',
                        isNumber: true
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Date'
                    }
                ]}
                defaultPayloadMapperValues={{
                    typeProprietaire: 'Compte',
                    typeOrganisation
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedAgentCashCollecteur.id',
                        property: 'valeurTypeProprietaire',
                        isRequired: true,
                        displayName: 'Compte'
                    }
                ]}
            >
                {/* <AgentSelector */}
                <AgentSuperDealerSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    reduxNodeName="agentsCashCollecteur"
                    name="selectedAgentCashCollecteur"
                    payload={{
                        typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR
                    }}
                    nodeName={REDUX_NODE_NAME.AGENT_CASH_COLLECTEUR}
                />
            </StandardReportTemplate>
        </>
    );
}
