import {
    useFormGlobalReducer,
    useGetUserDetail,
    fetchElement,
    useFetchAndListening
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, filterClientBySuperDealerMarchand } from '../../../util';
import { RetraitEmoneyFormSchema as uiValidator } from './validation';

const defaultFields = {
    numeroReference: '',
    tillNumber: '',
    selectedSuperDealer: {},
    selectedClient: {},
    montant: 0
    // numeroReference: '7519384152'
};

const defaultDependencies = { fields: ['superDealer', 'client'] };

export function RechercheRetraitEmoneyByNumeroReferenceForm() {
    const reduxDispatcher = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const {
        firebaseUser,
        nappUser,
        superDealerKinDB,
        etatSuperDealerKinDB,
        clientsOrganisation,
        etatClientsOrganisation
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        superDealerKinDB: reduxState.superDealerEmoneyKinDB
            .filter((item) => item.estEmoney)
            .map((item) => ({
                ...item,
                code: item.entreprise.code,
                designation: item.entreprise.designation
            })),
        etatSuperDealerKinDB: reduxState.etat.superDealerEmoneyKinDB.etat,
        clientsOrganisation: reduxState.clientsOrganisation.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeProprietaire?.designation || ''}-${
                item?.numero || ''
            }`
        })),
        etatClientsOrganisation: reduxState.etat.clientsOrganisation.etat
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['retrait'],
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_EMONEY_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { estEmoney: true, typeEntite: nappUser?.typeEntite }
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_NUMERO_CLIENT,
                nodeName: REDUX_NODE_NAME.CLIENT_ORGANISATION,
                etat: etatClientsOrganisation
            }
        ]
    });
    const onSuccess = ({ element: cmd }) => {
        if (cmd && cmd.id) {
            navigate(`${pathname}/${cmd.id}`);
        }
    };
    const handleRechercheByDispatch = (e) => {
        e.preventDefault();
        const tillNumber = clientsOrganisation.find(
            (item) => item?.id === fields.selectedClient?.id
        )?.numero;
        const { numeroReference, selectedSuperDealer, montant } = fields;
        const marchand = superDealerKinDB.find(
            (item) => item.code === selectedSuperDealer?.code
        )?.marchand;
        formDispatcher(
            fetchElement({
                idToken,
                functionName:
                    URL_CONST.GET_COMMANDE_EMONEY_BY_NUMERO_REFERENCE_AND_TILLNUMBER,
                elementPropertyName: 'retrait',
                etat: elements?.retrait?.status,
                payload: {
                    numeroReference,
                    tillNumber,
                    codeMarchand: marchand?.code,
                    montant
                },
                reduxNodeName: REDUX_NODE_NAME.COMMANDE_EMONEY_RETRAIT,
                reduxDispatcher,
                onSuccess,
                uiValidator,
                fields
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            isRedirect={false}
            onSubmit={handleRechercheByDispatch}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB
                },
                {
                    dependency: 'client',
                    etat: etatClientsOrganisation
                }
            ]}
        >
            <CustomAlert error={elements?.retrait?.error} />
            <CustomRow>
                <CustomDropdown
                    label="SuperDealer*: "
                    labelClassName="col-12 col-sm-12"
                    divClassName="col-12 col-sm-12"
                    options={superDealerKinDB}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner Super Dealer"
                    name="selectedSuperDealer"
                    id="selectedSuperDealerCreationClientEmoneyId"
                    disabled={!superDealerKinDB?.length}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup
                    label="Numéro référence*"
                    labelClassName="col-12"
                    divClassName="col-12"
                    placeholder="Saisissez le numéro de référence"
                    formDispatcher={formDispatcher}
                    name="numeroReference"
                    id="numeroReferenceRecherchRetraitByNumeroReference"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup
                    isFloat
                    type="number"
                    label="Montant: *"
                    labelClassName="col-12"
                    divClassName="col-12"
                    placeholder="Saisissez le montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="montantRecherchRetraitByNumeroReference"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={filterClientBySuperDealerMarchand({
                        superDealers: superDealerKinDB,
                        superDealer: fields.selectedSuperDealer,
                        clients: clientsOrganisation
                    })}
                    defaultOption="Selectionner Client"
                    disabled={
                        !filterClientBySuperDealerMarchand({
                            superDealers: superDealerKinDB,
                            superDealer: fields.selectedSuperDealer,
                            clients: clientsOrganisation
                        })?.length
                    }
                    label="Client*: "
                    labelClassName="col-12 col-sm-12"
                    divClassName="col-12 "
                    formDispatcher={formDispatcher}
                    name="selectedClient"
                    id="selectedClientRetraitCashId"
                    formState={formState}
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-12">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleRechercheByDispatch}
                        text="Rechercher"
                        disabled={
                            elements.retrait.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        isActionEncours={
                            elements.retrait.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
