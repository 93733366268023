import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListUploadFichierForm() {
    return (
        <ListGenericForm
            reduxPropertyName="relevesBancaire"
            functionName={
                URL_CONST.GET_LIST_TRAITEMENT_RELEVE_BANCAIRE_INITIALS
            }
            nodeName={REDUX_NODE_NAME.UPLOAD_RELEVE_BANCAIRE}
            mapper={[
                {
                    field: 'institutionFinanciere.designation',
                    displayName: 'Banque'
                },
                {
                    field: 'fileName',
                    displayName: 'Fichier'
                },
                {
                    field: 'nombreTransaction',
                    displayName: 'Nombre de Transactions'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ]}
        />
    );
}
