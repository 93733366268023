import { useSelector } from 'react-redux';
import { DetailForm } from '../../../components/container/detail-form';
import { ChangePasswordComponent } from './change-password';
import { schema } from './detail-fields/schema-fields';

export function DetailMonCompte() {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: {
            ...reduxState.nappUser,
            email: reduxState.firebaseUser?.email
        }
    }));
    return (
        <>
            <DetailForm
                entity={nappUser}
                schema={schema}
                subtitle="Mon compte"
                isDownloadFile={false}
                isExportable={false}
            />
            <ChangePasswordComponent />
        </>
    );
}
