import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail,
    useFetchFile
} from '@napp-inc/jnapp-hook';
import { FORM_CONST, formInitialState, URL_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { ref, getDownloadURL } from 'firebase/storage';
import {
    CustomButtonLoader,
    CustomRow,
    CustomCol,
    ImageInput,
    FormWrapper
    // StateShowerComponent
} from '../../../components';
import { logoMarchandRef } from '../../../firebase/firebase';
import { getResultFilesToUpload } from '../../../util';

const defaultFields = {
    logo: '',
    logoTraiter: '',
    logoStatus: '',
    showModal: false,
    file: {}
};

export function UpdateLogoForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, organisation } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        organisation: reduxState.organisation
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        files: ['logo']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    useFetchFile({
        formDispatcher,
        getDownloadURL,
        list: [
            {
                fileRef: ref(logoMarchandRef, organisation?.logo),
                propertyName: 'logo',
                etat: formState.files.logo.status
            }
        ]
    });
    const { fields, form } = formState;
    const handleSubmit = async (event) => {
        if (!fields.file) return;
        event.preventDefault();
        formDispatcher({
            type: FORM_CONST.MODIFICATION_EN_COURS
        });
        if (fields.file.name) {
            const element = { file: { ...fields.file } };
            const image = await getResultFilesToUpload({
                tab: [element],
                formDispatcher,
                refStorage: logoMarchandRef,
                fieldData: fields.file
            });
            if (image.length) {
                const marchandLogo = image[0];
                if (marchandLogo.logoUrl) {
                    formDispatcher(
                        submitPutAction({
                            reduxDispatcher,
                            functionName: URL_CONST.PUT_LOGO,
                            fields,
                            idToken,
                            payload: {
                                elementUpdate: {
                                    logoUrl: marchandLogo.logoUrl
                                },
                                logo: marchandLogo.logo
                            }
                        })
                    );
                }
            }
        }
    };

    return (
        <FormWrapper
            formDispatcher={formDispatcher}
            formState={formState}
            onSubmit={handleSubmit}
            isRedirect={false}
        >
            <CustomRow className="">
                <CustomCol>
                    <ImageInput
                        formDispatcher={formDispatcher}
                        formState={formState}
                        idToken={idToken}
                    />
                    <div className="w-100" />
                    <fieldset>
                        <legend>
                            <p className="note">
                                Définissez un logo qui sera visible auprès de
                                tous vos clients.
                            </p>
                        </legend>
                    </fieldset>
                    <CustomButtonLoader
                        className="btn-success mt-2"
                        text="Définir Logo"
                        isActionEncours={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </CustomCol>
                {/* <StateShowerComponent state={formState} /> */}
            </CustomRow>
        </FormWrapper>
    );
}
