import { GestionUser } from '../../../module';
import {
    SignInRelPath,
    ForgotPasswordRelPath,
    SignUpRelPath,
    ConfirmSignUpRelPath
} from './connexion-rel-path';

export const SignInPath = {
    ...SignInRelPath,
    Component: GestionUser.SingIn,
    element: <GestionUser.SingIn />,
    code: [{ code: 'test' }]
};

export const ForgotPasswordPath = {
    ...ForgotPasswordRelPath,
    Component: GestionUser.ForgotPassword,
    element: <GestionUser.ForgotPassword />,
    code: [{ code: 'test' }]
};

export const SignUpPath = {
    ...SignUpRelPath,
    Component: GestionUser.SingUp,
    element: <GestionUser.SingUp />,
    code: [{ code: 'test' }]
};

export const ConfirmSignUpPath = {
    ...ConfirmSignUpRelPath,
    Component: GestionUser.ConfirmSignUp,
    element: <GestionUser.ConfirmSignUp />,
    code: [{ code: 'test' }]
};
