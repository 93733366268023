import { DB_NODE, GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';
import { CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner les revendeurs
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function RevendeurSelector({
    formDispatcher,
    formState,
    idProperty = 'id',
    isAddAditionalOptions = false,
    additionnalOptions = [{ id: 'all', code: 'all', designation: 'Tous' }]
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, revendeurs, etatRevendeur } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            revendeurs: reduxState.revendeurs,
            etatRevendeur: reduxState.etat.revendeurs.etat
        })
    );
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_REVENDEUR,
                nodeName: DB_NODE.REVENDEUR,
                etat: etatRevendeur,
                payload: {
                    estNonCommission: false,
                    estSpecifique: false,
                    limit: 100
                }
            }
        ]
    });

    const renderContent = () => {
        if (etatRevendeur === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS) {
            return (
                <CustomRow>
                    <CustomDropdown
                        options={revendeurs}
                        defaultOption="Selectionner un revendeur"
                        label="Super Dealer: "
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedRevendeur"
                        id="selectedRevendeurRevendeurSelector"
                        formState={formState}
                        idProperty={idProperty}
                        keyProperty="id"
                        additionnalOptions={
                            isAddAditionalOptions ? additionnalOptions : []
                        }
                    />
                </CustomRow>
            );
        }
        if (etatRevendeur === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des revendeurs" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
