import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

const groupementStock = (data) =>
    data.map((item) => {
        let typeBusiness = 'TB, BM';
        if (item?.detail?.typeBusiness?.length < 2) {
            typeBusiness = item?.detail?.typeBusiness?.join(' , ');
            typeBusiness = typeBusiness.replace('Businessmodel', 'BM');
            typeBusiness = typeBusiness.replace('BusinessModel', 'BM');
            typeBusiness = typeBusiness.replace('Traditionnelbusiness', 'TB');
            typeBusiness = typeBusiness.replace('TraditionnelBusiness', 'TB');
        }
        return {
            ...item,
            id: `${item?.detail?.marchand?.reference}${typeBusiness}`,
            designation: `${item?.detail?.marchand?.designation || ''}${
                typeBusiness ? ` - ${typeBusiness}` : ''
            }`
        };
    });
const getConclusion = (item) => {
    const conclusion = item?.detail?.conclusion || 'Pas de conclusion';
    if (conclusion === 'FULL')
        return <span className="badge bg-success">{conclusion}</span>;
    if (conclusion === 'MOYEN')
        return <span className="badge bg-warning text-dark">{conclusion}</span>;
    if (conclusion === 'CRITIQUE')
        return <span className="badge bg-danger">{conclusion}</span>;
    return <span className="badge bg-secondary">Pas de conclusion</span>;
};
export function EvolutionStockSuperDealerForm() {
    return (
        <ListGenericForm
            reduxPropertyName="evolutionsStockSuperDealer"
            functionName={URL_CONST.GET_LIST_STOCK_SUPER_DEALER}
            nodeName={REDUX_NODE_NAME.EVOLUTION_STOCK_SUPER_DEALER}
            payload={{
                typeEntite: 'Journalier',
                typeRapport: 'EvolutionStock',
                estNotDate: true
            }}
            isLinkToDetail={false}
            formater={groupementStock}
            mapper={[
                {
                    displayName: 'Marchand',
                    field: 'designation'
                },
                {
                    displayName: 'Initial',
                    field: 'soldeOuverture'
                },
                {
                    displayName: 'Moyenne',
                    field: 'detail.moyenne'
                },
                {
                    displayName: 'Conclusion',
                    tdExtractor: (item) => getConclusion(item)
                },
                {
                    displayName: 'Physique',
                    field: 'detail.quantitePhysique'
                },
                {
                    displayName: 'Livrées',
                    field: 'operation.CommandesFlashLivrees.montant'
                },
                {
                    displayName: 'En attente',
                    field: 'operation.CommandesFlashAttenteValidation.montant'
                },
                {
                    displayName: 'En cours',
                    field: 'operation.CommandesFlashEncours.montant'
                },
                {
                    displayName: 'Facturation',
                    field: 'operation.Facturation.montant'
                },
                {
                    displayName: 'Régul Entrée',
                    field: 'operation.RegularisationEntree.montant'
                },
                {
                    displayName: 'Régul Sortie',
                    field: 'operation.RegularisationSortie.montant'
                },
                {
                    displayName: 'Régul Trop Percu',
                    field: 'operation.RegularisationTropPercu.montant'
                },
                {
                    displayName: 'Fermeture',
                    field: 'soldeFermeture'
                }
            ]}
        />
    );
}
