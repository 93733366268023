import React from 'react';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import { ListCreationAgenceForm } from './list-creation-agence';
import {
    CreationAgenceCreateRelPath,
    CreationAgenceViewLayoutRelPath
} from '../../../routes/registration/configuration/shared-config-rel-path';

export function CreationAgenceViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Agences</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${CreationAgenceViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.AGENCE_SERVICES
                                        .CREATION_AGENCE_ORGANISATION
                                ]}
                                destination={CreationAgenceCreateRelPath.path}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListCreationAgenceForm />
        </div>
    );
}
