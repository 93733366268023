import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListGestionRevendeurForm() {
    return (
        <ListGenericForm
            reduxPropertyName="statusRevendeur"
            functionName={URL_CONST.GET_LIST_STATUS_REVENDEUR_INITIALS}
            nodeName={REDUX_NODE_NAME.STATUS_REVENDEUR}
            mapper={[
                {
                    field: 'revendeur.designation',
                    displayName: 'Revendeur'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    field: 'estNonCommission',
                    displayName: 'Commission',
                    isBoolean: true
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
