import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Header,
    InternetConnectionStatusBar,
    MainContent,
    Sidebar
} from '../components';
import { StateContext } from '../context/state-context';
import { CheckLogin } from './check-login';

export function DefaultLayout() {
    const theme = useSelector((reduxState) => reduxState.theme);
    const { activeMenu, screenSize, setActiveMenu, setScreenSize } =
        useContext(StateContext);
    const [cssToSet, setCssToSet] = useState('');
    const [mainStyleOn900, setMainStyleOn900] = useState('');
    const mainClass = activeMenu ? `col-md-9 col-lg-10` : `col-md-12 col-lg-12`;

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [screenSize, setScreenSize]);

    useEffect(() => {
        if (screenSize <= 900) {
            setCssToSet(` position-absolute col-6 shadow`);
            setMainStyleOn900(`px-1`);
            setActiveMenu(false);
        } else {
            setCssToSet(``);
            setActiveMenu(true);
        }
    }, [setCssToSet, screenSize, setActiveMenu, setMainStyleOn900]);

    return (
        <CheckLogin>
            <div
                aria-hidden="true"
                className="vh-100 vw-100 overflow-hidden"
                mode-theme={theme?.name}
            >
                <InternetConnectionStatusBar />
                {/* Header */}
                <Header />
                {/* Main SideBar with main content */}
                <div
                    className={`${
                        activeMenu ? 'd-flex position-relative' : ''
                    }`}
                >
                    {activeMenu && (
                        <Sidebar
                            className={`col-md-3 col-lg-2 vh-100 pb-5 overflow-scroll ${cssToSet}`}
                        />
                    )}
                    <div
                        className={`vh-100 overflow-scroll ms-sm-auto px-md-4 pb-5 pt-4 ${mainClass} ${mainStyleOn900}`}
                    >
                        <MainContent />
                    </div>
                </div>
            </div>
        </CheckLogin>
    );
}
