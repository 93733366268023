import { FORM_CONST, resolvePathPropertyOfObject } from '@napp-inc/jnapp-util';

/**
 * Construit le payload à envoyer dans une requete fetch element.
 * @param {Object} param0 Objet avec les propriétés suivantes:
 * @param {Array} param0.payloadMapper Tableau des éléments à mapper,
 * il doit contenir les propriétés suivantes:
 * [{
 * 	name: 'nom du champ dans le fiels du formulaire',
 * property: 'propriété à envoyer dans le payload',
 * isRequired: true,
 * displayName: 'Nom du champ afficher, en cas d'erreur'
 * }]
 * @param {Object} param0.defaultPayloadMapperValues Valeurs par défaut
 * @param {Object} param0.fields Champs du formulaire
 * @param {Function} param0.formDispatcher Dispatcher du formulaire
 * @returns {Object} Objet contenant les valeurs mappées
 */
export const payloadBuilder = ({
    payloadMapper = [],
    defaultPayloadMapperValues = {},
    fields = {},
    formDispatcher
}) => {
    const payload = {
        ...defaultPayloadMapperValues
    };
    for (let i = 0; i < payloadMapper.length; i += 1) {
        const item = payloadMapper[i];
        const value = resolvePathPropertyOfObject(item?.name, fields);
        if (!value && item?.isRequired) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Ce champ est obligatoire : ${
                        item?.displayName || ''
                    }`,
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return false;
        }
        if (item?.callback && typeof item?.callback === 'function') {
            const result = item.callback(value);
            if (item?.isRequired && !result) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `Ce champ est obligatoire : ${
                            item?.displayName || ''
                        }`,
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
                return false;
            }
            Object.assign(payload, result);
        } else if (value) {
            payload[item.property] = value;
        }
    }
    return payload;
};
