import { TYPE_ORGANISATION, URL_CONST } from '@napp-inc/jnapp-util';
import { useGetReportDetail } from '../../util';
import { GenericReleveForm } from '../../module/report/template';
import { CompteInstitutionFinancierSelector } from '../molecules';

export function ReleveInstitutionFinanciereOrganisme({
    typeOrganisation = TYPE_ORGANISATION.SUPER_DEALER
}) {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCompteInstitutionFinanciere: {}
        }
    });
    return (
        <GenericReleveForm
            functionName={URL_CONST.GET_LIST_MOUVEMENT_PLAGE}
            formState={formState}
            formDispatcher={formDispatcher}
            typeProprietaire="Compte"
            name="selectedCompteInstitutionFinanciere"
        >
            <CompteInstitutionFinancierSelector
                formState={formState}
                formDispatcher={formDispatcher}
                typeOrganisation={typeOrganisation}
                propertyName="selectedCompteInstitutionFinanciere"
            />
        </GenericReleveForm>
    );
}
