import * as yup from 'yup';

/**
 * @description : Schema de Depense form
 */

export const DepenseFormSchema = yup.object().shape({
    montant: yup.number().min(1),
    selectedCaisse: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedRaison: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
