import * as yup from 'yup';

/**
 * @description : Schema de taux form
 * const defaultFields = {
    selectedTypeOrganisation: {},
    selectedCashCollecteur: {},
    selectedSuperDealer: {},
    selectedDevise: {},
    selectedTypeTaux: {},
    taux: 0,
    list: [],
    previewValue: '',
    fictifName: {}
};
 */
export const TauxKindistributioFormSchema = yup.object().shape({
    taux: yup.number().min(0),
    selectedTypeTaux: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedTypeOrganisation: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedDevise: yup.object().shape({
        id: yup.string().required(),
        devise: yup.string().required()
    }),
    list: yup
        .array()
        .of(
            yup
                .object({
                    type: yup.string().required(),
                    designation: yup.string().required(),
                    valeur: yup.number()
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins un élément à la liste des Dévises')
});
