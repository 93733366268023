import * as yup from 'yup';

/**
 * @description : Schema de elaboration charge varaible
 */
export const ElaborationChargeVariableFormSchema = yup.object().shape({
    selectedExercice: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedDirection: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedSecteur: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedCharge: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedCaisseSectorielle: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    modePaiement: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    tauxCroissance: yup.number().min(1)
});

/**
 * @description : yup schema for charge fix elaboration
 */
export const ElaborationChargeFixeSchema = yup.object().shape({
    selectedExercice: yup.object().shape({
        id: yup.string(),
        designation: yup.string()
    }),
    list: yup
        .array()
        .of(
            yup
                .object({
                    element: yup.object().shape({
                        code: yup.string().required()
                    }),
                    typeMoyenPaiement: yup.string().required(),
                    idCaisse: yup.string().when('typeMoyenPaiement', {
                        is: (typeMoyenPaiement) =>
                            typeMoyenPaiement === 'Caisse',
                        then: yup.string().required(),
                        otherwise: yup.string()
                    }),
                    prix: yup.number().min(1),
                    details: yup
                        .array()
                        .of(
                            yup
                                .object({
                                    secteur: yup.string().required(),
                                    periodes: yup
                                        .array()
                                        .of(
                                            yup
                                                .object({
                                                    dateDebut: yup
                                                        .number()
                                                        .min(946684800000),
                                                    dateFin: yup
                                                        .number()
                                                        .min(946684800000),
                                                    datePaiement: yup
                                                        .number()
                                                        .min(946684800000),
                                                    beneficiaires: yup
                                                        .array()
                                                        .of(
                                                            yup
                                                                .object({
                                                                    typeEntite:
                                                                        yup
                                                                            .string()
                                                                            .required(),
                                                                    valeurTypeEntite:
                                                                        yup
                                                                            .object()
                                                                            .shape(
                                                                                {
                                                                                    reference:
                                                                                        yup
                                                                                            .string()
                                                                                            .required()
                                                                                }
                                                                            ),
                                                                    quantite:
                                                                        yup
                                                                            .number()
                                                                            .min(
                                                                                0
                                                                            ),
                                                                    montant: yup
                                                                        .number()
                                                                        .min(0)
                                                                })
                                                                .shape({})
                                                        )
                                                        .required()
                                                        .min(
                                                            1,
                                                            'Ajoutez au moins un Bénéficiaire'
                                                        )
                                                })
                                                .shape({})
                                        )
                                        .required()
                                        .min(1, 'Ajoutez au moins une période')
                                })
                                .shape({})
                        )
                        .required()
                        .min(1, 'Ajoutez au moins un élément de détail')
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins un élément à la liste')
});

/**
 * @description : Schema de elaboration charge ressource
 */
export const ElaborationChargeRessourceFormSchema = yup.object().shape({
    selectedExercice: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedDirection: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedSecteur: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedRessource: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    // modePaiement: yup.object().shape({
    //     code: yup.string().required(),
    //     designation: yup.string().required()
    // }),
    // selectedCaisseSectorielle: yup.object().shape({
    //     id: yup.string(),
    //     designation: yup.string()
    // }),
    // datePaiement: yup.string().required(),
    tauxCroissance: yup.number().min(1)
});
