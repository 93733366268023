import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    TYPE_ORGANISATION,
    URL_CONST
} from '@napp-inc/jnapp-util';
import {
    fetchElement,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../../components';
import {
    ApproSimGetByReferenceSchema as uiValidator,
    useWhereToGo
} from '../../../../util';

/**
 * Default Fields for formState
 */
const defaultFields = {
    numeroReference: ''
};

/**
 * Default elements for formState
 */
const defaultElements = ['reference'];

/**
 * Formulaire de recherche d'une opération d'approvisionnement Sim
 * Via le numéro de référence que l'agent d'une organisation Dealer
 * présente à celle d'un super-dealer';'
 * @returns {JSX.Element}
 */
export function CreationVenteByReference() {
    const reduxDispatcher = useDispatch();
    const { pathname } = useLocation();
    const { destination } = useWhereToGo(pathname, 1);
    const { firebaseUser, nappUser, approvisionnementsSim } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            approvisionnementsSim: reduxState.approvisionnementsSim.filter(
                (approSim) =>
                    approSim.typeOrganisationUser ===
                    TYPE_ORGANISATION.REVENDEUR
            )
        })
    );

    const { idToken } = useGetUserDetail({
        firebaseUser,
        nappUser
    });

    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements
    });

    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        { initialState }
    );
    const { fields, elements } = formState;
    /**
     * Function to launch on click event
     * pour rechercher une opération d'approvisionnement Sim
     * @param {Object} event
     */
    const onSearchReference = (e) => {
        e.preventDefault();
        formDispatcher(
            fetchElement({
                idToken,
                functionName:
                    URL_CONST.GET_APPROVISIONNEMENT_SIM_BY_BY_REFERENCE,
                elementPropertyName: 'reference',
                etat: elements.reference.status,
                payload: { numeroReference: fields.numeroReference },
                reduxNodeName: DB_NODE.APPROVISIONNEMENT_SIM,
                reduxDispatcher
            })
        );
    };
    return (
        <div className="shadow card p-0">
            <div className="card-header">
                <span className="text-uppercase">
                    Rechercher l&apos;opération par le numéro de référence
                </span>
            </div>
            <div className="card-body">
                <CustomCol>
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Reference*"
                            labelClassName="col-sm-3"
                            divClassName="col-sm-9"
                            placeholder="Numéro de référence"
                            formDispatcher={formDispatcher}
                            name="numeroReference"
                            id="idReference"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomButtonLoader
                        text="Rechercher"
                        className="btn btn-success"
                        onClick={onSearchReference}
                        disabled={
                            elements.reference.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                    />
                    <CustomCollapse isIn={!!approvisionnementsSim.length}>
                        <SimpleTable
                            identifier="id"
                            pathname={destination}
                            isShowDropButton={false}
                            data={approvisionnementsSim}
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    field: 'numeroReference',
                                    displayName: 'Reference'
                                },
                                {
                                    position: 2,
                                    displayName: 'Revendeur',
                                    field: 'valeurTypeOrganisationUser.code'
                                },
                                {
                                    position: 3,
                                    displayName: 'T-Number',
                                    field: 'valeurTypeOrganisationUser.tillNumber'
                                },
                                {
                                    position: 4,
                                    displayName: 'Quantité',
                                    field: 'quantite'
                                },
                                {
                                    position: 5,
                                    displayName: 'Montant',
                                    field: 'montant'
                                }
                            ]}
                        />
                    </CustomCollapse>
                </CustomCol>
            </div>
        </div>
    );
}
