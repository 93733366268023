import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { PresenceAgentFormSchema as uiValidator } from './validation';

const defaultFields = {
    tillNumber: ''
};

export function CreationPresenceAgentForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const { tillNumber } = fields;
        const payload = {
            tillNumber
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_PRESENCE,
                payload,
                fields,
                reduxNodeName: DB_NODE.PRESENCE_AGENT,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Till number*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    min={0}
                    placeholder="Saisissez le till number"
                    formDispatcher={formDispatcher}
                    name="tillNumber"
                    id="tillNumberPresenceAgent"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
