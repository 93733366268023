export const schema = [
    {
        description: 'designation',
        title: 'Désignation',
        className: 'border-bottom'
    },
    {
        description: 'typeBusiness',
        title: 'Type business',
        className: 'border-bottom'
    },
    {
        description: 'type',
        title: 'Type',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'Etat',
        className: 'border-bottom'
    },
    {
        title: 'Last update',
        description: 'dateDerniereModification',
        className: 'border-bottom'
    }
];
