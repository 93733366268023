import {
    submitPostAction,
    useFormGlobalReducer,
    useGetOrganisationDetail,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    formInitialState,
    MONNAIES,
    URL_CONST,
    TYPE_ORGANISATION,
    DB_NODE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomRow
} from '../../../../components';
import { DynamicMonoSelectAndInputSecond } from '../../../../components/special-components/dynamic-mono-select-and-input-second';
import { ApproSimVenteSchema as uiValidator } from '../../../../util';
import { percuAndRetournedMontantDevise } from '../util';

/**
 * Default Fields for formState
 */
const defaultFields = {
    tillNumberAcheteur: '',
    quantiteSim: 0,
    deviseRetourSelect: {},
    deviseRetourInput: 0,
    devisePercuSelect: {},
    devisePercuInput: 0,
    produitApproSim: {},
    montantPercu: [],
    montantRetour: []
};

/**
 * Object schemes for percu and retour select and Input
 */
// #region MONO_SELECT_AND_INPUT_FORMAT

const objectKeysPercu = {
    devise: 'devisePercuSelect.code',
    montant: 'devisePercuInput'
};

const objectKeysRetour = {
    devise: 'deviseRetourSelect.code',
    montant: 'deviseRetourInput'
};

// #endregion

/**
 * Formulaire de vente espece superDealer-Dealer
 * @returns {JSX.Element}
 */
export function CreationVenteEspece() {
    const reduxDispatcher = useDispatch();

    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );

    const { codeMarchand } = useGetOrganisationDetail({
        organisation
    });

    const { idToken } = useGetUserDetail({
        firebaseUser,
        nappUser
    });

    const initialState = formInitialState({
        fields: defaultFields
    });

    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        { initialState }
    );
    const { fields, form } = formState;
    /**
     * montantsDevise is an array of montantsDevise properties to send in payload
     */
    const montantsDevise = percuAndRetournedMontantDevise(
        fields.montantPercu,
        fields.montantRetour
    );

    /**
     * Function to launch on click event
     * @param {Object} event
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            newApprovisionnementSim: {
                quantite: fields.quantiteSim
            },
            typeOrganisation: TYPE_ORGANISATION.REVENDEUR,
            tillNumber: fields.tillNumberAcheteur,
            codeMarchand,
            montantsDevise
        };
        formDispatcher(
            submitPostAction({
                idToken,
                fields,
                functionName: URL_CONST.POST_APPRO_SIM,
                payload,
                uiValidator,
                reduxNodeName: DB_NODE.APPROVISIONNEMENT_SIM,
                reduxDispatcher
            })
        );
    };

    /**
     * Fonction des vues à rendre
     * @returns {JSX.Element}
     */
    const renderContent = () => (
        <div className="shadow card p-0">
            <div className="card-header">
                <span className="text-uppercase">Vente Sim</span>
            </div>
            <CustomAlert error={formState?.form?.error} />
            <div className="card-body">
                <CustomCol>
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Till Number*"
                            labelClassName="col-sm-3"
                            divClassName="col-sm-9"
                            placeholder="Till Number de l'acheteur"
                            formDispatcher={formDispatcher}
                            name="tillNumberAcheteur"
                            id="idTillNumberAcheteur"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Quantité*"
                            labelClassName="col-sm-3"
                            divClassName="col-sm-9"
                            placeholder="Saisir la quantité"
                            formDispatcher={formDispatcher}
                            name="quantiteSim"
                            type="number"
                            id="idQuantiteSimAcheteur"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomCol>
                            <div className="shadow card">
                                <div className="card-header">
                                    <p className="fw-normal mb-0">
                                        Montant perçu
                                    </p>
                                </div>
                                <div className="card-body">
                                    <CustomRow>
                                        <DynamicMonoSelectAndInputSecond
                                            options={MONNAIES}
                                            id="deviseMontantPercu"
                                            name="devisePercu"
                                            defaultOption="Selectionner devise"
                                            formState={formState}
                                            formDispatcher={formDispatcher}
                                            type="number"
                                            identifier="devise"
                                            placeholder="montant"
                                            field="montantPercu"
                                            objectKeys={objectKeysPercu}
                                            uiValidator={uiValidator}
                                        />
                                    </CustomRow>
                                </div>
                            </div>
                        </CustomCol>
                        <CustomCol>
                            <div className="shadow card">
                                <div className="card-header">
                                    <p className="fw-normal mb-0">
                                        Montant retourné
                                    </p>
                                </div>
                                <div className="card-body">
                                    <CustomRow>
                                        <DynamicMonoSelectAndInputSecond
                                            options={MONNAIES}
                                            id="deviseMontantRetour"
                                            name="deviseRetour"
                                            defaultOption="Selectionner devise"
                                            formState={formState}
                                            formDispatcher={formDispatcher}
                                            type="number"
                                            identifier="devise"
                                            placeholder="montant"
                                            field="montantRetour"
                                            objectKeys={objectKeysRetour}
                                            uiValidator={uiValidator}
                                        />
                                    </CustomRow>
                                </div>
                            </div>
                        </CustomCol>
                    </CustomRow>
                    <CustomButtonLoader
                        text="valider"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </div>
        </div>
    );

    return renderContent();
}
