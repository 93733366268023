// import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
// import { DetailWrapper } from '../../../components';
// import { useGetDetailForm } from '../../../util';
// import { schema } from './detail-fields';

export function DetailDeviseForm() {
    // const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
    //     useGetDetailForm({ nodeName: 'devises' });
    const renderContent = () => (
        <h1>Detail</h1>
        // <DetailWrapper
        //     functionName={URL_CONST.GET_DEVISE_BY_ID }
        //     nodeName={ DB_NODE.DEVISES }
        //     formDispatcher={formDispatcher}
        //     formState={formState}
        //     reduxDispatcher={reduxDispatcher}
        //     subtitle="Detail devise"
        //     entity={entity}
        //     id={id}
        //     schema={schema}
        //     idToken={idToken}
        // />
    );
    return renderContent();
}
