export const mapper = [
    {
        position: 1,
        type: 'text',
        id: 'designationCreationPartenaire',
        name: 'designation',
        placeholder: 'Saisissez le nom du partenaire',
        label: 'Désignation*',
        isInputGroup: false
    },
    {
        position: 2,
        type: 'text',
        id: 'numeroTelEntrepriseCreationPartenaire',
        name: 'numeroTelEntreprise',
        placeholder: 'Saisissez le numéro de contact du partenaire',
        label: 'Numero contact Partenaire*',
        isInputGroup: false
    },
    {
        position: 3,
        type: 'text',
        id: 'adresseEntrepriseCreationPartenaire',
        name: 'adresse',
        placeholder: "Saisissez l'adresse",
        label: 'Adresse*',
        isInputGroup: false
    },
    {
        position: 4,
        type: 'text',
        id: 'nomGestionnaireCreationPartenaire',
        name: 'nom',
        placeholder: 'Nom du gestionnaire',
        label: 'Nom*',
        isInputGroup: false
    },
    {
        position: 5,
        type: 'text',
        id: 'postNomGestionnaireCreationPartenaire',
        name: 'postnom',
        placeholder: 'Postnom du gestionnaire',
        label: 'Postnom*',
        isInputGroup: false
    },
    {
        position: 6,
        type: 'text',
        id: 'prenomGestionnaireCreationPartenaire',
        name: 'prenom',
        placeholder: 'Prenom du gestionnaire',
        label: 'Prenom*',
        isInputGroup: false
    },
    {
        position: 7,
        type: 'text',
        id: 'numeroTelGestionnaireGestionnaireCreationPartenaire',
        name: 'numeroTelGestionnaire',
        placeholder: 'Numéro du gestionnaire',
        label: 'Numero contact Gestionnaire*',
        isInputGroup: false
    },
    {
        position: 8,
        type: 'email',
        id: 'emailGestionnaireCreationPartenaire',
        name: 'email',
        placeholder: 'Email Gestionnaire',
        label: 'Email*',
        isInputGroup: false
    }
];
export const mappePersonneMorale = [
    {
        position: 1,
        type: 'text',
        id: 'designationCreationPartenaire',
        name: 'designation',
        placeholder: 'Saisissez le nom du partenaire',
        label: 'Désignation*',
        isInputGroup: false
    },
    {
        position: 2,
        type: 'text',
        id: 'sigleCreationPartenaire',
        name: 'codeEntreprise',
        placeholder: 'Saisissez le sigle',
        label: 'Sigle*',
        isInputGroup: false
    },
    {
        position: 3,
        type: 'text',
        id: 'numeroTelEntrepriseCreationPartenaire',
        name: 'numeroTelEntreprise',
        placeholder: 'Saisissez le numéro de contact du partenaire',
        label: 'Numero contact Partenaire*',
        isInputGroup: false
    },
    {
        position: 4,
        type: 'text',
        id: 'adresseEntrepriseCreationPartenaire',
        name: 'adresse',
        placeholder: "Saisissez l'adresse",
        label: 'Adresse*',
        isInputGroup: false
    },
    {
        position: 5,
        type: 'text',
        id: 'nomGestionnaireCreationPartenaire',
        name: 'nom',
        placeholder: 'Nom du gestionnaire',
        label: 'Nom*',
        isInputGroup: false
    },
    {
        position: 6,
        type: 'text',
        id: 'postNomGestionnaireCreationPartenaire',
        name: 'postnom',
        placeholder: 'Postnom du gestionnaire',
        label: 'Postnom*',
        isInputGroup: false
    },
    {
        position: 7,
        type: 'text',
        id: 'prenomGestionnaireCreationPartenaire',
        name: 'prenom',
        placeholder: 'Prenom du gestionnaire',
        label: 'Prenom*',
        isInputGroup: false
    },
    {
        position: 8,
        type: 'text',
        id: 'numeroTelGestionnaireGestionnaireCreationPartenaire',
        name: 'numeroTelGestionnaire',
        placeholder: 'Numéro du gestionnaire',
        label: 'Numero contact Gestionnaire*',
        isInputGroup: false
    },
    {
        position: 9,
        type: 'email',
        id: 'emailGestionnaireCreationPartenaire',
        name: 'email',
        placeholder: 'Email Gestionnaire',
        label: 'Email*',
        isInputGroup: false
    }
];
