import React from 'react';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import { ConfigurationPath } from '../../../routes/registration';
import { ListCashCollectionForm } from './list-cash-collection';

export function CashCollectionViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">cash collection organisation</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ConfigurationPath.CashCollectionViewLayoutPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
                                        .INITIER_CREATION_CASH_COLLECTION
                                ]}
                                destination={
                                    ConfigurationPath.CashCollectionCreatePath
                                        .path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListCashCollectionForm />
        </div>
    );
}
