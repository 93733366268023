export const schema = [
    {
        description: 'caisse.designation',
        title: 'Caisse',
        className: 'border-bottom'
    },
    {
        title: 'Agent',
        callback: (item) =>
            `${item?.agent?.prenom || ''} ${item?.agent?.nom || ''}`,
        className: 'border-bottom'
    },
    {
        description: 'devise',
        title: 'devise',
        className: 'border-bottom'
    },
    {
        description: 'montant',
        title: 'montant',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'etat',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'Création',
        className: 'border-bottom'
    }
];
