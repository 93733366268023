import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { MdGpsFixed } from 'react-icons/md';
import { AiOutlineBarChart } from 'react-icons/ai';
import { GrResources } from 'react-icons/gr';
import { CustomPopover, ShowNewComponent } from '../../../../components';
import { ListBudget } from '../common';
import { ConfigurationPath } from '../../../../routes/registration';

export function ElaborationViewLayout() {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Elaboration Budget</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <CustomPopover
                            trigger={['click']}
                            variant="primary"
                            text=" + Créer"
                            direction="bottom"
                        >
                            <CustomPopover
                                isNexted
                                direction="left"
                                text="Additionnel"
                                trigger={['click']}
                                button={
                                    <div
                                        role="button"
                                        className="pb-2 text-center border-bottom"
                                    >
                                        <MdGpsFixed className="me-2" />
                                        <span>Additionnel</span>
                                    </div>
                                }
                            >
                                <ShowNewComponent
                                    className="btn"
                                    services={[
                                        { code: 'test' },
                                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                            .INITIER_ELABORATION_AMENDEMENT
                                    ]}
                                    text="Charge Fixe"
                                    icon={<MdGpsFixed className="me-1" />}
                                    destination={
                                        ConfigurationPath
                                            .ElaborationAdditionnelFixeCreatePath
                                            .path
                                    }
                                />
                                <div className="border-bottom" />
                                <ShowNewComponent
                                    className="btn"
                                    services={[
                                        { code: 'test' },
                                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                            .INITIER_ELABORATION_AMENDEMENT
                                    ]}
                                    text="Charge Variable"
                                    icon={
                                        <AiOutlineBarChart className="me-1" />
                                    }
                                    destination={
                                        ConfigurationPath
                                            .ElaborationAdditionnelVariableCreatePath
                                            .path
                                    }
                                />
                                <div className="border-bottom" />
                                <ShowNewComponent
                                    className="btn"
                                    services={[
                                        { code: 'test' },
                                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                            .INITIER_ELABORATION_AMENDEMENT
                                    ]}
                                    text="Ressource"
                                    icon={<GrResources className="me-1" />}
                                    destination={
                                        ConfigurationPath
                                            .ElaborationAdditionnelRessourceCreatePath
                                            .path
                                    }
                                />
                            </CustomPopover>
                            <CustomPopover
                                isNexted
                                direction="left"
                                text="Prévisionnel"
                                trigger={['click']}
                                button={
                                    <div
                                        role="button"
                                        className="py-2 text-center"
                                    >
                                        <AiOutlineBarChart className="me-2" />
                                        <span>Previsionnel</span>
                                    </div>
                                }
                            >
                                <ShowNewComponent
                                    className="btn"
                                    services={[
                                        { code: 'test' },
                                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                            .INITIER_ELABORATION_AMENDEMENT
                                    ]}
                                    text="Charge Fixe"
                                    icon={<MdGpsFixed className="me-1" />}
                                    destination={
                                        ConfigurationPath
                                            .ElaborationPrevisionnelFixeCreatePath
                                            .path
                                    }
                                />
                                <div className="border-bottom" />
                                <ShowNewComponent
                                    className="btn"
                                    services={[
                                        { code: 'test' },
                                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                            .INITIER_ELABORATION_AMENDEMENT
                                    ]}
                                    text="Charge Variable"
                                    icon={
                                        <AiOutlineBarChart className="me-1" />
                                    }
                                    destination={
                                        ConfigurationPath
                                            .ElaborationPrevisionnelVariableCreatePath
                                            .path
                                    }
                                />
                                <div className="border-bottom" />
                                <ShowNewComponent
                                    className="btn"
                                    services={[
                                        { code: 'test' },
                                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                            .INITIER_ELABORATION_AMENDEMENT
                                    ]}
                                    text="Ressource"
                                    icon={<GrResources className="me-1" />}
                                    destination={
                                        ConfigurationPath
                                            .ElaborationPrevisionnelRessourceCreatePath
                                            .path
                                    }
                                />
                            </CustomPopover>
                        </CustomPopover>
                    </div>
                </div>
            </div>
            <ListBudget />
        </>
    );
}
