import * as yup from 'yup';

/**
 * @description : Schema de validation secteurs
 */
export const ServiceSchema = yup.object().shape({
    code: yup.string(),
    designation: yup.string(),
    list: yup
        .array()
        .of(
            yup
                .object({
                    code: yup.string().required(),
                    designation: yup.string().required()
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins un élément à la liste')
});
