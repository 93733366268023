import { useDispatch, useSelector } from 'react-redux';
import {
    BENEFICIAIRE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY
} from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { REDUX_NODE_NAME } from '../../../redux';
import { CashbookCard } from '../../../components';
import { URL_CONST } from '../../../util';

export function HomeCashBook() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        cashBookUser,
        etatCashBookUser,
        ecouteurCashBookUser
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        cashBookUser: reduxState.cashBookUser,
        etatCashBookUser: reduxState.etat.cashBookUser.etat,
        ecouteurCashBookUser: reduxState.etat.cashBookUser.ecouteur
    }));
    /**
     * Common form processes
     */
    const { idToken, uid } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    // const idLimitCredit = nappUser?.idLimitCredit;
    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                fetchType: FETCH_LISTENING,
                functionName: URL_CONST.GET_LIST_CASH_BOOK_USER,
                nodeName: REDUX_NODE_NAME.CASH_BOOK_USER,
                etat: etatCashBookUser,
                ecouteur: ecouteurCashBookUser,
                path: DB_NODE.CASH_BOOK,
                payload: {
                    dateDebut: TODAY.at000000(),
                    dateFin: TODAY.at235959()
                },
                listenerFilters: [
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'typeEntite',
                        value: BENEFICIAIRE?.USER?.code,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'valeurTypeEntite.reference',
                        value: uid,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'date',
                        value: TODAY.at000000(),
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.ORDER_BY,
                        property: 'dateDerniereModification'
                    },
                    {
                        type: FILTER_TYPE.START_AT,
                        value: TODAY.nowUtc()
                    }
                ]
            }
        ]
    });
    const contentToRender = () =>
        cashBookUser.length ? (
            cashBookUser.map((item) => (
                <div
                    className={`col-${12 / cashBookUser.length} pb-1`}
                    key={item?.devise}
                >
                    <CashbookCard
                        text={`CashBook ${item?.devise}`}
                        type="secondary"
                        value={item?.solde}
                        devise={item?.devise}
                        entree={item?.entree}
                        sortie={item?.sortie}
                        icon={6}
                        isCompact
                        nombreApresVirgule={2}
                    />
                </div>
            ))
        ) : (
            <div className="col-12">
                <CashbookCard
                    text="CashBook"
                    type="success"
                    textAlternatif="CASHBOOK INDISPONIBLE !"
                    icon={6}
                    isCompact
                    nombreApresVirgule={2}
                />
            </div>
        );
    return contentToRender();
}
