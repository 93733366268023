import {
    CODE_SERVICE,
    ETAT,
    FORM_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailTransfertForm() {
    const {
        id,
        creator,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        typeEntite
    } = useGetDetailForm({
        nodeName: 'transfertCaisse',
        defaultFields: {
            actionEncours: '',
            numeroReference: ''
        }
    });

    const { form, fields } = formState;
    const estEmoney =
        typeEntite === TYPE_ORGANISATION.SUPER_DEALER
            ? !!entity?.caisseB?.estEmoney
            : false;
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = {
            etat,
            idTransfert: entity?.id
        };
        if (estEmoney) {
            payload.numeroReference = fields.numeroReference;
        }
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_TRANSFERT_CAISSE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TRANSFERT_CAISSE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TRANSFERT_BY_ID}
            nodeName={REDUX_NODE_NAME.TRANSFERT_CAISSE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail transfert"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .VALIDER_TRANSFERT_CAISSE,
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .APPROUVER_TRANSFERT_CAISSE,
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .CONFIRMER_TRANSFERT_CAISSE
                ]}
            >
                <UserServiceWrapper
                    services={[
                        { code: 'test' },
                        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                            .VALIDER_TRANSFERT_CAISSE
                    ]}
                >
                    <CustomRow isShouldBeRendered={estEmoney}>
                        <CustomInput
                            type="text"
                            label="Numéro Référence* :"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            isInputGroup={false}
                            placeholder="Numéro Référence"
                            formDispatcher={formDispatcher}
                            name="numeroReference"
                            id="creationNumeroReferenceCreationTransfertInterInstitutionnelId"
                            formState={formState}
                            // uiValidator={uiValidator}
                        />
                    </CustomRow>
                </UserServiceWrapper>
                <CustomRow>
                    <CustomCol className="offset-4 offset-md-8 col-3 col-md-2">
                        <CustomButtonLoader
                            type="button"
                            className="btn-danger"
                            onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            text="Annuler"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                        />
                    </CustomCol>
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                                .VALIDER_TRANSFERT_CAISSE
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.userEncaissement === creator &&
                                entity?.etat === ETAT.ETAT_ENCOURS
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_VALIDER)
                                    }
                                    text="valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                    <UserServiceWrapper
                        services={[
                            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                                .CONFIRMER_TRANSFERT_CAISSE
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat === ETAT.ETAT_PRE_ACTIVE
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_PRE_ENCOURS)
                                    }
                                    text="valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_PRE_ENCOURS
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                    <UserServiceWrapper
                        services={[
                            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                                .APPROUVER_TRANSFERT_CAISSE
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat === ETAT.ETAT_PRE_ENCOURS
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_ENCOURS)
                                    }
                                    text="valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ENCOURS
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
