import { ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomInput,
    CustomRow,
    DetailWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = { actionEncours: '', reference: '' };
export function DetailVirementForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'virements',
            defaultFields
        });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payloadToAdd = {};
        if (fields.reference) {
            payloadToAdd.numeroReference = fields.reference;
        }
        const payload = {
            idOperationVirement: entity?.id,
            etat,
            ...payloadToAdd
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_OPERATION_VIREMENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.VIREMENTS,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_OPERATION_VIREMENT_BY_ID}
            nodeName={REDUX_NODE_NAME.VIREMENTS}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail virement"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            {/* ETAT_ATTENTE_VALIDATION changera en ETAT_ATTENTE_CONFIRMATION */}
            <CustomRow
                isShouldBeRendered={
                    entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                }
            >
                <CustomInput
                    isInputGroup={false}
                    label="Reference*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez le numéro de référence"
                    formDispatcher={formDispatcher}
                    name="reference"
                    id="virementReferenceId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                    <CustomConfirmButton
                        type="button"
                        text="Annuler"
                        className="btn-danger"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_ANNULER
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation Annulation"
                    />
                </CustomCol>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Mettre en attente"
                            className="btn-success"
                            confirmOnClick={() =>
                                updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                            }
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours ===
                                ETAT.ETAT_ATTENTE_VALIDATION
                            }
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                    }
                >
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
