import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { URL_CONST } from '../../util';
import { GenericSelector } from './generic-selector';

/**
 * Construit une dropdown où l'on peut sélectionner un agent d'un type organisation donné
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */

export function CompteAgentSelector({
    formDispatcher,
    formState,
    name = 'selectedAgent',
    typeOrganisation = TYPE_ORGANISATION.SUPER_DEALER,
    functionName = URL_CONST.GET_LIST_LIMIT_CREDIT_ORGANISATION,
    payload = {},
    listFormater = (list) =>
        list.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeProprietaire?.prenom || ''} ${
                item?.valeurTypeProprietaire?.nom || ''
            }`
        })),
    dynamicNodeName = 'agentSelector',
    label = 'Sélectionner un agent'
}) {
    const renderContent = () => (
        <GenericSelector
            label={label}
            formDispatcher={formDispatcher}
            formState={formState}
            propertyName={name}
            payload={{
                typeProprietaire: typeOrganisation,
                ...payload
            }}
            functionName={functionName}
            listFormater={listFormater}
            isDynamicNode
            dynamicNodeName={`${dynamicNodeName}-${typeOrganisation}`}
        />
    );
    return renderContent();
}
