import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import { REDUX_NODE_NAME } from '../../../redux';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailLimitCreditForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'limitsCredit' });
    const updateEtat = (etat) => {
        const payload = {
            etat,
            idLimitCredit: entity?.id
        };
        if (etat !== ETAT.ETAT_VALIDER) {
            return;
        }
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_LIMIT_CREDIT,
                payload,
                reduxNodeName: REDUX_NODE_NAME.LIMIT_CREDIT,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_LIMIT_CREDIT_BY_ID}
            nodeName={REDUX_NODE_NAME.LIMIT_CREDIT}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail sur la limite"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_FINANCE
                        .APPROBATION_OCTROI_LIMIT_CREDIT
                ]}
            >
                <CustomRow
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                formState.form.status ===
                                FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                formState.form.status ===
                                FORM_CONST.MODIFICATION_EN_COURS
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
