import { useGetEntity, useIsLoadComplete } from '@napp-inc/jnapp-hook';
import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { CenteredSpinner, CustomAlert, DetailWrapper } from '../../components';
import { useGetDetailForm } from '../../util';

const schema = [
    {
        title: 'Code',
        description: 'code',
        className: 'border-bottom'
    },
    {
        title: 'Designation',
        description: 'designation',
        className: 'border-bottom'
    },
    {
        title: 'Classe',
        description: 'classe',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation'
    }
];

// FIXME: modififier la fonction de get by id
export function DetailPaiementForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'preuvePaiements' });

    const { form, dependencies } = formState;
    useGetEntity({
        entity,
        etat: dependencies.entity.status,
        formDispatcher,
        functionName: URL_CONST.GET_FAKE,
        id,
        idToken,
        nodeName: DB_NODE.PREUVE_PAIEMENT,
        reduxDispatcher
    });
    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        dependencies
    });
    const renderContent = () => {
        if (!entity?.id && !isLoadFailed && !isLoadComplete) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return <CustomAlert error={form?.error} />;
        }
        return (
            <DetailWrapper
                functionName={URL_CONST.GET_FAKE}
                nodeName={DB_NODE.PREUVE_PAIEMENT}
                formDispatcher={formDispatcher}
                formState={formState}
                reduxDispatcher={reduxDispatcher}
                subtitle="Detail Paiement"
                entity={entity}
                id={id}
                schema={schema}
                idToken={idToken}
            />
        );
    };
    return renderContent();
}
