import React from 'react';
import { StandardReportTemplate } from '../../report/template';
import { URL_CONST, useGetReportDetail } from '../../../util';
import { AllOrganisationSelector } from '../../../components';

const formater = (data) => {
    let items = [];
    if (data?.element) items = data?.element;
    if (data?.elements) items = data?.elements;
    if (Array.isArray(data)) items = data?.[0];
    return items;
};

export function HistoriqueTaux() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedAgentSuperDealer: {},
            selectedOrganisation: {},
            estValid: true
        }
    });
    return (
        <>
            {/* <StateShowerComponent state={formState?.elements} /> */}
            <StandardReportTemplate
                functionName={URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION}
                formState={formState}
                formDispatcher={formDispatcher}
                listFormater={formater}
                listTitle={formState?.fields?.selectedOrganisation?.designation}
                mapper={[
                    {
                        field: 'valeurTypeEntite.designation',
                        displayName: 'Organisation'
                    },
                    {
                        field: 'typeEntite',
                        displayName: 'type Organisation'
                    },
                    {
                        field: 'devise',
                        displayName: 'devise'
                    },
                    {
                        field: 'valeur',
                        displayName: 'valeur'
                    },
                    {
                        field: 'type',
                        displayName: 'Type'
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création'
                    },
                    {
                        field: 'dateDerniereModification',
                        displayName: 'Dernière Modification'
                    }
                ]}
                // defaultPayloadMapperValues={{
                //     typeProprietaire: 'Compte'
                // }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'estValid',
                        property: 'estValid',
                        isRequired: true
                        // displayName: 'Date fin'
                    },
                    {
                        name: 'selectedOrganisation',
                        // property: 'estActif',
                        isRequired: false,
                        displayName: 'Organisation',
                        callback: (data) => {
                            if (!data?.code || data?.code === 'Napp') return {};
                            return { codeSuperDealer: data?.code };
                        }
                    }
                ]}
            >
                {/* <AgentSelector */}
                <AllOrganisationSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            </StandardReportTemplate>
        </>
    );
}
