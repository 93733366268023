import {
    fetchElement,
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { ref, uploadBytes } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButton,
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomInputFile,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { documentAgentLivreurRef } from '../../../firebase';
import { AffilationAgentLivreurFormSchema as uiValidator } from './validation';

const defaultFields = {
    tillNumber: '',
    carteRose: '',
    permisConduire: ''
};

export function CreationAffiliationAgentLivreurForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, deviseReference } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        deviseReference: reduxState.deviseReference
    }));
    /**
     * Common form processes
     */
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['agentLivreur']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements, form } = formState;
    const fetchAgentLivreur = () => {
        const { tillNumber } = fields;
        if (
            tillNumber &&
            tillNumber !== elements.agentLivreur?.value?.tillNumber
        ) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'agentLivreur',
                    payload: { tillNumber },
                    functionName: URL_CONST.GET_AGENT_LIVREUR_TILL_NUMBER,
                    etat: elements.agentLivreur.status,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { agentLivreur } = elements;
        if (agentLivreur.status !== FORM_CONST.FETCH_ELEMENT_SUCCESS) return;
        const { carteRose, permisConduire } = fields;
        const carteRoseFileExtention = carteRose.name.split('.').pop();
        const carteRoseFileName = carteRose.name.split('.').shift();
        const permisConduireFileExtention = permisConduire.name
            .split('.')
            .pop();
        const permisConduireFileName = permisConduire.name.split('.').shift();
        const payload = {
            tillNumberAgentLivreur: agentLivreur?.value?.tillNumber,
            carteRose: `${carteRoseFileName}.${carteRoseFileExtention}`,
            permisConduire: `${permisConduireFileName}.${permisConduireFileExtention}`,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_DEMANDE_AFFILIATION_AGENT_LIVREUR,
                payload,
                fields,
                fileUploader: uploadBytes,
                filesToUpload: [
                    {
                        file: permisConduire,
                        fileRef: ref(
                            documentAgentLivreurRef,
                            `${permisConduireFileName}.${permisConduireFileExtention}`
                        )
                    },
                    {
                        file: carteRose,
                        fileRef: ref(
                            documentAgentLivreurRef,
                            `${carteRoseFileName}.${carteRoseFileExtention}`
                        )
                    }
                ],
                reduxNodeName: DB_NODE.AFFILIATION,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Till number*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-10 col-sm-8"
                    isInputGroup={false}
                    placeholder="Saisissez le tillNumber"
                    formDispatcher={formDispatcher}
                    name="tillNumber"
                    id="tillNumberCreationDemandeAffiliation"
                    formState={formState}
                />
                <CustomCol className="col-2">
                    <CustomButton
                        disabled={
                            elements.agentLivreur.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        onClick={fetchAgentLivreur}
                    >
                        Rechercher
                    </CustomButton>
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    {elements.agentLivreur.status ===
                        FORM_CONST.FETCH_ELEMENT_FAILED && (
                        <CustomAlert
                            error={`${elements.agentLivreur?.error || ''}`}
                        />
                    )}
                    {elements.agentLivreur.status ===
                        FORM_CONST.FETCH_ELEMENT_SUCCESS && (
                        <CustomAlert
                            successMessage={`${
                                elements.agentLivreur?.value?.tillNumber || ''
                            } - ${
                                elements.agentLivreur?.value?.personne?.nom ||
                                ''
                            } ${
                                elements.agentLivreur?.value?.personne
                                    ?.prenom || ''
                            }\n - Balance : ${
                                elements.agentLivreur?.value?.caution || 0
                            } ${deviseReference?.code}`}
                        />
                    )}
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomInputFile
                    id="carteRoseCreationAffiliationAgentLivreur"
                    name="carteRose"
                    label="Carte rose*"
                    divClassName="col-sm-10"
                    labelClassName="col-sm-2"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    accept=".jpg, .jpeg, .png"
                />
            </CustomRow>
            <CustomRow>
                <CustomInputFile
                    id="permisConduireCreationAffiliationAgentLivreur"
                    name="permisConduire"
                    label="Permis de conduire*"
                    divClassName="col-sm-10"
                    labelClassName="col-sm-2"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    accept=".jpg, .jpeg, .png"
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
