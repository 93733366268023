import { FORM_CONST, TIME, TODAY } from '@napp-inc/jnapp-util';
import { ConditionalRenderingWrapper, CustomRow } from '../container';
import { CustomDropdown } from '../dropdown';
import { CustomInputDate } from './input-date';

const AUJOURDHUI = {
    code: 'TODAY',
    designation: "Aujourd'hui"
};
const HIER = {
    code: 'YESTERDAY',
    designation: 'Hier'
};
const CE_MOIS = {
    code: 'THIS_MONTH',
    designation: 'Ce mois'
};
const DERNIERS_30_JOURS = {
    code: 'LAST_30_DAYS',
    designation: '30 derniers jours'
};
const DERNIERS_90_JOURS = {
    code: 'LAST_90_DAYS',
    designation: '90 derniers jours'
};
const DERNIERS_12_MOIS = {
    code: 'LAST_12_MONTHS',
    designation: '12 derniers mois'
};
const PLAGE_PERSONNALISEE = {
    code: 'CUSTOM_RANGE',
    designation: 'Personnalisée'
};

const PLAGES_DATE = [
    AUJOURDHUI,
    HIER,
    CE_MOIS,
    DERNIERS_30_JOURS,
    DERNIERS_90_JOURS,
    DERNIERS_12_MOIS,
    PLAGE_PERSONNALISEE
];

/**
 * Construit une dropdown qui permet de séléctionner pré-configurer donnée sinon, de sélectionner une période personnalisée
 * @param {Object} param0 Objet avec les paramètres suivants
 * @param {formState} param0.formState form state du parent
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {String} param0.debutName nom de la propriété début dans le form state du parent, par défaut 'debut'. Cette propriété stocke en chaine de caractère la date début
 * @param {String} param0.finName nom de la propriété début dans le form state du parent, par défaut 'fin'. Cette propriété stocke en chaine de caractère la date fin
 * @param {String} param0.dateDebutName nom de la propriété dateBut dans le form state du parent, par défaut 'dateDebut'. Cette propriété stocke en timestamp la datebut
 * @param {String} param0.dateFinName nom de la propriété dateFin dans le form state du parent, par défaut 'dateFin'. Cette propriété stocke en timestamp la date fin
 * @param {String} param0.selectedDateRangeReportName nom de la propriété selectedDateRangeReport dans le form state du parent, par défaut 'selectedDateRangeReport'. Cette propriété stocke l'élément actuellement selectionné sur la dropdown
 *
 * @returns {React.Component}
 */
export function ReportDateRange({
    formState,
    formDispatcher,
    debutName = 'debut',
    finName = 'fin',
    dateDebutName = 'dateDebut',
    dateFinName = 'dateFin',
    selectedDateRangeReportName = 'selectedDateRangeReport',
    isInRow = false
}) {
    const callback = (data) => {
        let dateDebut = TIME.utc000000(TODAY.at000000());
        let dateFin = TIME.utc235959(TODAY.at000000());
        if (data?.code === HIER.code) {
            dateDebut = TIME.utc000000(
                TIME.add(TODAY.at000000(), { days: -1 })
            );
            dateFin = TIME.utc235959(TIME.add(TODAY.at000000(), { days: -1 }));
        } else if (data?.code === DERNIERS_30_JOURS.code) {
            dateDebut = TIME.utc000000(
                TIME.add(TODAY.at000000(), { days: -30 })
            );
        } else if (data?.code === DERNIERS_90_JOURS.code) {
            dateDebut = TIME.utc000000(
                TIME.add(TODAY.at000000(), { days: -90 })
            );
        } else if (data?.code === DERNIERS_12_MOIS.code) {
            dateDebut = TIME.utc000000(
                TIME.add(TODAY.at000000(), { days: -365 })
            );
        } else if (data?.code === CE_MOIS.code) {
            dateDebut = TIME.utc000000(TIME.getStartOfMonth(TODAY.nowUtc()));
            dateFin = TIME.utc235959(TIME.getEndOfMonth(TODAY.nowUtc()));
        } else if (data?.code === PLAGE_PERSONNALISEE.code) {
            dateDebut = TIME.utc000000(TODAY.at000000());
            dateFin = TIME.utc235959(TODAY.at000000());
            const debut = TIME.getTodayString();
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field: debutName,
                    data: debut
                }
            });
        }
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: dateDebutName,
                data: dateDebut
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: dateFinName,
                data: dateFin
            }
        });
    };
    const customDateCallback = (data, name) => {
        if (name === debutName) {
            if (data) {
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: dateDebutName,
                        data: TIME.utc000000(TIME.get000000(data))
                    }
                });
                if (!formState?.fields?.[finName]) {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: dateFinName,
                            data: TIME.utc235959(TIME.get000000(data))
                        }
                    });
                }
            } else if (!data) {
                if (!formState?.fields?.[finName]) {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: dateFinName,
                            data: TIME.utc235959(TODAY.at000000())
                        }
                    });
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: dateDebutName,
                            data: TIME.utc000000(TODAY.at000000())
                        }
                    });
                } else if (formState?.fields?.[finName]) {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: dateDebutName,
                            data: TIME.utc000000(
                                TIME.get000000(formState?.fields?.[finName])
                            )
                        }
                    });
                }
            }
        } else if (name === finName) {
            if (data) {
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: dateFinName,
                        data: TIME.utc235959(TIME.get000000(data))
                    }
                });
                if (!formState?.fields?.[debutName]) {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: dateDebutName,
                            data: TIME.utc000000(TIME.get000000(data))
                        }
                    });
                }
            } else if (!data) {
                if (!formState?.fields?.[debutName]) {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: dateFinName,
                            data: TIME.utc235959(TODAY.at000000())
                        }
                    });
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: dateDebutName,
                            data: TIME.utc000000(TODAY.at000000())
                        }
                    });
                } else if (formState?.fields?.[debutName]) {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: dateFinName,
                            data: TIME.utc235959(
                                TIME.get000000(formState?.fields?.[debutName])
                            )
                        }
                    });
                }
            }
        }
    };
    return (
        <>
            <CustomRow>
                <CustomDropdown
                    options={PLAGES_DATE}
                    label="Périodes : "
                    labelClassName="col-12"
                    divClassName="col-12"
                    formDispatcher={formDispatcher}
                    name={selectedDateRangeReportName}
                    id="selectedDateRangeReportReport"
                    formState={formState}
                    defaultOption={AUJOURDHUI.designation}
                    callback={callback}
                    isSort={false}
                />
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        formState?.fields?.selectedDateRangeReport?.code ===
                            PLAGE_PERSONNALISEE.code && isInRow
                    }
                >
                    <CustomInputDate
                        formDispatcher={formDispatcher}
                        label="Début : "
                        id="dateDebutListMouvementInputDate"
                        labelClassName="col-12"
                        divClassName="col-12"
                        name={debutName}
                        isInputGroup={false}
                        formState={formState}
                        callback={customDateCallback}
                    />
                    <CustomInputDate
                        labelClassName="col-12"
                        divClassName="col-12"
                        isInputGroup={false}
                        label="Fin : "
                        formDispatcher={formDispatcher}
                        id="dateDebutListMouvementInputDate"
                        name={finName}
                        formState={formState}
                        callback={customDateCallback}
                    />
                </ConditionalRenderingWrapper>
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    formState?.fields?.selectedDateRangeReport?.code ===
                        PLAGE_PERSONNALISEE.code && !isInRow
                }
            >
                <CustomRow>
                    <CustomInputDate
                        formDispatcher={formDispatcher}
                        label="Début : "
                        id="dateDebutListMouvementInputDate"
                        name={debutName}
                        formState={formState}
                        callback={customDateCallback}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInputDate
                        label="Fin : "
                        formDispatcher={formDispatcher}
                        id="dateDebutListMouvementInputDate"
                        name={finName}
                        formState={formState}
                        callback={customDateCallback}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            {/* <hr /> */}
        </>
    );
}
