import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export function CustomPopover({
    direction = 'bottom',
    children,
    text = 'Popover',
    variant = 'primary',
    header,
    trigger = ['focus'],
    isNexted = false,
    button
}) {
    return (
        <OverlayTrigger
            trigger={trigger}
            placement={direction}
            overlay={
                <Popover id={`popover-positioned-${direction}`}>
                    {header ? (
                        <Popover.Header as="h3">{header}</Popover.Header>
                    ) : null}
                    <Popover.Body>{children}</Popover.Body>
                </Popover>
            }
        >
            {isNexted ? button : <Button variant={variant}>{text}</Button>}
        </OverlayTrigger>
    );
}
