import { FORM_CONST } from '@napp-inc/jnapp-util';
import { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';

// FIXME : to continue
export function CustomToast({ error, successMessage, status }) {
    const [show, setShow] = useState(false);
    const [messageToShow, setMessageToShow] = useState('');
    const [variant, setVariant] = useState('');
    useEffect(() => {
        if (status) {
            switch (status) {
                case FORM_CONST.CREATION_SUCCESS:
                    setMessageToShow('Enregistrement effectué');
                    setVariant('success');
                    break;
                case FORM_CONST.MODIFICATION_SUCCESS:
                    setMessageToShow('Modification effectuée');
                    setVariant('success');
                    break;
                default:
                    setMessageToShow('');
                    break;
            }
            if (!messageToShow) return null;
            setShow(true);
        }
        if (successMessage) {
            setShow(true);
            setMessageToShow(successMessage);
            setVariant('success');
        }
        if (error) {
            setShow(true);
            const errorMessage =
                typeof error === 'string' ? error : error?.message;
            setMessageToShow(errorMessage);
            setVariant('danger');
        }
        return () => {
            setShow(false);
        };
    }, [error, successMessage, status, messageToShow]);
    return (
        <div
            className="position-absolute start-50 translate-middle"
            style={{ zIndex: '2000' }}
        >
            <Toast
                bg={variant}
                onClose={() => setShow(false)}
                show={show}
                delay={4000}
                autohide
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Reponse</strong>
                    <small>Il y a un instant</small>
                </Toast.Header>
                <Toast.Body>{messageToShow}</Toast.Body>
            </Toast>
        </div>
    );
}
