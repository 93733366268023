import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { GenericReleveForm } from '../../module/report/template';
import { URL_CONST, useGetReportDetail } from '../../util';
import { CompteAgentSelector } from '../molecules/compte-agent-selector';

/**
 * Composant pour le rapport de limite de crédit d'un organisme : revendeur, super dealer, cash collecteur
 * @param {Object} param0 Object avec les paramètres suivants :
 * @param {String} param0.typeOrganisation Type d'organisation
 *
 * @returns {React.Component}
 */
export function ReleveBalanceOrganisme({
    typeOrganisation = TYPE_ORGANISATION.REVENDEUR,
    payload = {
        estAll: true,
        typesEntite: [TYPE_ORGANISATION.NAPP],
        estOrganisation: true,
        typeProprietaire: TYPE_ORGANISATION.REVENDEUR,
        estGerant: true
    },
    functionName = URL_CONST.GET_LIST_COMPTE_USER,
    listFormater = (list) =>
        list.map((item) => ({
            ...item,
            designation: `${
                item?.valeurTypeOrganisation?.designation ||
                item?.valeurTypeProprietaire?.designation ||
                ''
            } - ${item?.devise || ''}`
        })),
    dynamicNodeName,
    label
}) {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedAgent: {}
        }
    });
    return (
        <GenericReleveForm
            functionName={URL_CONST.GET_LIST_MOUVEMENT_PLAGE}
            formState={formState}
            formDispatcher={formDispatcher}
            typeProprietaire="Compte"
            name="selectedAgent"
        >
            <CompteAgentSelector
                typeOrganisation={typeOrganisation}
                formDispatcher={formDispatcher}
                formState={formState}
                payload={payload}
                functionName={functionName}
                listFormater={listFormater}
                dynamicNodeName={dynamicNodeName}
                label={label}
            />
        </GenericReleveForm>
    );
}
