import { FORM_CONST } from '@napp-inc/jnapp-util';
import { CustomInput } from '../input';
import { CustomDropdown } from '../dropdown';
import { CustomAddButton, CustomDropButton } from '../button';
import { CustomCollapse } from '../container';

/**
 * Select remplace le composant select de react-bootstrap et prends les mêmes attributs que le composant Natif
 * @param {Object} param0 object qui contient les props du composant:
 * @param {Array} param0.options liste des options
 * @param {Array} param0.secondOptions liste des options du deuxieme select
 * @param {String} param0.id id de l'élément html
 * @param {Function} param0.formDispatcher fonction qui sera appelée lorsque l'utilisateur selectionnera une option
 * @param {String} param0.defaultOption valeur par défaut
 * @param {String} param0.name nom de la propriéte name du select
 * @param {String} param0.inputName nom de la propriéte name de l'input
 * @param {Object} param0.uiValidator schema de validation
 * @param {Object} param0.formState state du composant
 * @returns {React.Component}
 */
export function DynamicMonoSelectAndInputSecond({
    options = [],
    defaultOption,
    id,
    name,
    formDispatcher,
    formState,
    uiValidator,
    placeholder,
    identifier,
    field,
    iconClassName,
    type,
    objectKeys
}) {
    const { fields } = formState;
    const tableHeader = Object.keys(objectKeys);
    const genericObjectForMonoSelect = () => {
        const estPercu = name.includes('ercu');
        const result = {};
        Object.keys(objectKeys).forEach((key) => {
            if (objectKeys[key].includes('.')) {
                const [first, second] = objectKeys[key].split('.');
                result[key] = fields[first][second];
                result.estEntree = estPercu;
            } else {
                result[key] = fields[objectKeys[key]];
                result.estEntree = estPercu;
            }
        });

        return result;
    };

    const dataToAddInState = () => {
        const dataToRender = genericObjectForMonoSelect();
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier,
                field,
                data: dataToRender
            }
        });
    };
    return (
        <div className="row mx-2 g-1">
            <div className="col">
                <CustomDropdown
                    name={`${name}Select`}
                    id={`${id}Select`}
                    defaultOption={defaultOption}
                    options={options}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </div>
            <div className="col">
                <CustomInput
                    id={`${id}Input`}
                    name={`${name}Input`}
                    placeholder={placeholder}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    type={type}
                />
            </div>
            <div className="col-sm-2">
                <CustomAddButton
                    disabled={false}
                    list={options}
                    identifier="devise"
                    formDispatcher={formDispatcher}
                    field={field}
                    id={fields[field]?.code}
                    size="25em"
                    iconClassName={iconClassName}
                    callback={dataToAddInState}
                />
            </div>
            <div />
            <CustomCollapse
                isIn={formState.fields[field].length > 0}
                className="col-sm-10"
            >
                <table className="table table-hover table-borderless table-responsive-sm shadow mt-3">
                    <thead>
                        <tr>
                            {tableHeader.map((header, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <th key={index}>{header}</th>
                            ))}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formState.fields[field].map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <tr key={index}>
                                {tableHeader.map((header) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <td key={`${header}${index}`}>
                                        {item[header]}
                                    </td>
                                ))}
                                <td>
                                    <CustomDropButton
                                        id={item[identifier]}
                                        field={field}
                                        formDispatcher={formDispatcher}
                                        identifier={identifier}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CustomCollapse>
        </div>
    );
}
