import { DB_NODE } from '@napp-inc/jnapp-util';
import { DetailWrapper } from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';

export function DetailSystServiceForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'allServices' });
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_SYS_SERVICE_BY_ID}
            nodeName={DB_NODE.SYST_SERVICE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Syst service"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        />
    );
    return renderContent();
}
