import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    TarifRessourceCreationRelPath,
    TarifRessourceViewLayoutRelPath
} from '../../../routes/registration/configuration/config-marchand-rel-path';
import { ListTarifRessource } from './list-tarif-ressource';

export function TarifRessourceViewLayout() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Tarif ressource</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${TarifRessourceViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                        .INITIER_TARIFICATION_RESSOURCE
                                ]}
                                destination={TarifRessourceCreationRelPath.path}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListTarifRessource />
        </>
    );
}
