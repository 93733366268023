import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListApplicationMobileForm() {
    return (
        <ListGenericForm
            isLinkToDetail={false}
            reduxPropertyName="applicationMobile"
            functionName={URL_CONST.GET_ALL_APPLICATION}
            nodeName={REDUX_NODE_NAME.APPLICATION_MOBILE}
            mapper={[
                {
                    field: 'code',
                    displayName: 'code',
                    isKeepFormat: true
                },
                {
                    field: 'designation',
                    displayName: 'designation'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
