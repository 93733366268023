import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST } from '../../../util';
import {
    SimpleCardMouvement,
    RapportActiviteCard,
    RapportActiviteSelector
} from '../../../components';
import { MainReportComponent } from '../releve/generic';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isDisplayChart: false,
    selectedSuperDealer: {},
    selectedClient: {},
    selectedEtat: {},
    isFetching: false,
    aggregated: {},
    devise: '',
    chartTitle: '',
    yAxisTittle: '',
    fetchedDevise: '',
    fetchedCaisse: ''
};

const mapper = [
    {
        field: 'valeurTypeOrganisation.designation',
        displayName: 'Organisation'
    },
    {
        displayName: 'Libelle',
        dataExtractor: (item) =>
            item?.libelle ? item?.libelle || '' : item?.typeEntite || ''
    },
    {
        field: 'soldePrecedent',
        displayName: 'Solde Ouverture'
    },
    {
        field: 'entree',
        displayName: 'Entree'
    },
    {
        field: 'sortie',
        displayName: 'Sortie'
    },
    {
        field: 'soldeActuel',
        displayName: 'Solde Fermeture'
    },
    {
        field: 'dateCreation',
        displayName: 'date création'
    }
];

// const etats = [
//     { code: 'VALIDER', designation: 'VALIDER' },
//     { code: 'ENCOURS', designation: 'EN COURS' },
//     { code: 'ANNULER', designation: 'ANNULER' },
//     {
//         code: 'ATTENTE_VALIDATION',
//         designation: 'ATTENTE VALIDATION'
//     }
// ];

export function RapportActiviteForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        organisation,
        nappUser,
        superDealerKinDB,
        clientsOrganisation
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        organisation: reduxState.organisation,
        nappUser: reduxState.nappUser,
        superDealerKinDB: reduxState.superDealerKinDB
            .filter((item) => item.estEmoney)
            .map((item) => ({
                ...item,
                code: item.entreprise.code,
                designation: item.entreprise.designation
            })),
        clientsOrganisation: reduxState.clientsOrganisation.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeProprietaire?.designation || ''}-${
                item?.numero || ''
            }`
        }))
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;

    const fetchReport = () => {
        const { dateFin, dateDebut } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload: {
                    dateDebut,
                    dateFin
                },
                functionName: URL_CONST.GET_LIST_RAPPORT_EMONEY_USER,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };
    function cardTotaux() {
        const { aggregated, fetchedDevise, fetchedCaisse } = fields;
        if (formState?.elements?.mouvements?.value?.length) {
            return (
                <SimpleCardMouvement
                    entree={aggregated?.entree}
                    sortie={aggregated?.sortie}
                    fermeture={aggregated?.fermeture}
                    ouverture={aggregated?.ouverture}
                    devise={fetchedDevise}
                    textAlternatif={fetchedCaisse}
                    nombreApresVirgule={0}
                />
            );
        }
        return null;
    }
    const getMarchandCode = ({ sd }) =>
        superDealerKinDB.find((item) => item?.code === sd)?.marchand?.code ||
        '';

    const getObjectAndProperties = ({ path, sd, clt }) => {
        let object = {};
        let properties = [];
        if (path && !sd && !clt) {
            object = path?.types || {};
            properties = Object.getOwnPropertyNames(object) || [];
        }
        if (path && sd) {
            const codeM = getMarchandCode({ sd });
            if (codeM && !clt) {
                object = path?.marchands?.[codeM]?.types || {};
                properties = Object.getOwnPropertyNames(object) || [];
            }
            if (codeM && clt) {
                const finded =
                    clientsOrganisation?.find((item) => item?.id === clt)
                        ?.valeurTypeProprietaire?.reference || '';
                object =
                    path?.clients?.[finded]?.marchands?.[codeM]?.types || {};
                properties = Object.getOwnPropertyNames(object) || [];
            }
        }
        return { object, properties };
    };

    const object = elements?.mouvements?.value?.devises || {};
    const properties = Object.getOwnPropertyNames(object) || [];

    return (
        <>
            <MainReportComponent
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={mapper}
                dataList={[]}
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                topOfChart={() => cardTotaux()}
            >
                <RapportActiviteSelector
                    formDispatcher={formDispatcher}
                    formState={formState}
                />
            </MainReportComponent>
            <div className="row">
                {properties.map((item) => (
                    <div
                        key={item}
                        className={`col-12 col-sm-${
                            12 / properties.length
                        } pb-1`}
                    >
                        <RapportActiviteCard
                            element={object}
                            devise={item}
                            marchand={organisation?.marchand?.code}
                            id={nappUser?.uid}
                            text={`Rapport ${item}`}
                            type="secondary"
                            formState={formState}
                            icon={7}
                            isCompact
                            nombreApresVirgule={2}
                            superDealer={
                                fields.selectedSuperDealer?.code
                                    ? fields?.selectedSuperDealer?.designation
                                    : organisation?.entreprise?.designation
                            }
                            client={fields?.selectedClient?.designation}
                            detailToShow={getObjectAndProperties({
                                path: object?.[item],
                                sd: fields.selectedSuperDealer?.code,
                                clt: fields.selectedClient?.id
                            })}
                        />
                    </div>
                ))}
            </div>
        </>
    );
}
