import { buildYupErrorObject, FORM_CONST } from '@napp-inc/jnapp-util';
import { InputFeedback } from '../input/input-feedback';

/**
 * Rend un checkbox
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {String}  param0.label label du composant
 * @param {String}  param0.labelClassName classe CSS du label
 * @param {String}  param0.divClassName classe CSS du div
 * @param {String}  param0.inputClassName classe CSS du input
 * @param {String}  param0.name name du composant
 * @param {Function}  param0.formDispatcher function qui se déclenche à la soumission du formulaire
 * @param {String}  param0.id id du composant
 * @param {Object}  param0.formState state du composant
 * @param {Object}  param0.uiValidator validateur du composant
 * @returns
 */
export function CustomCheckButton({
    labelClassName = '',
    divClassName = '',
    inputClassName = '',
    label,
    name,
    formDispatcher,
    id,
    formState = {},
    uiValidator
}) {
    const { error, form, fields } = formState;
    const handleChange = (e) => {
        const { checked: currentValue } = e.target;
        formDispatcher({
            type: FORM_CONST.TEXT_FIELD_CHANGE,
            payload: { field: name, data: currentValue }
        });
        if (uiValidator && form?.isSubmitted) {
            uiValidator
                .validate(
                    { ...fields, [name]: currentValue },
                    {
                        abortEarly: false
                    }
                )
                .then(() => {
                    formDispatcher({
                        type: FORM_CONST.VALIDATION_SUCCESS
                    });
                })
                .catch((erreur) => {
                    if (erreur.name === 'ValidationError') {
                        const err = buildYupErrorObject(erreur);
                        formDispatcher({
                            type: FORM_CONST.SET_FIELDS_ERROR,
                            payload: {
                                message: { ...err },
                                status: FORM_CONST.CREATION_ECHEC
                            }
                        });
                    }
                });
        }
    };
    return (
        <div className={`form-check ${divClassName} row`}>
            <input
                onChange={handleChange}
                name={name}
                className={`form-check-input ${inputClassName} ${
                    error && error[name] ? `is-invalid` : null
                }`}
                type="checkbox"
                value=""
                id={`invalidCheck${id}`}
                aria-describedby={`invalidCheck3Feedback${id}`}
            />
            <label
                className={`form-check-label ${labelClassName}`}
                htmlFor={`invalidCheck${id}`}
            >
                {label}
            </label>
            <InputFeedback id={`invalidCheck3Feedback${id}`}>
                {error && error[name]}
            </InputFeedback>
        </div>
    );
}
