export const schema = [
    {
        title: 'Code',
        description: 'code',
        className: 'border-bottom'
    },
    {
        title: 'désignation',
        description: 'designation',
        className: 'border-bottom'
    },
    {
        title: 'Creation',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
