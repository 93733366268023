import { Array } from '@napp-inc/jnapp-util';
import { URL_CONST, traitementMouvement } from '../../../util';
import { StandardReportTemplate } from './standard-report-template';

const formater = (data) =>
    Array.tri({
        data: traitementMouvement(data?.[0] || []),
        property: 'dateCreation',
        order: 'desc'
    });

/**
 * Composant générique pour les relevés
 * @param {Object} param0 Object avecs les paramètres suivants :
 * - functionName : nom de la fonction à appeler
 * - mapper : mapper pour le tableau
 * - typeProprietaire : type de propriétaire
 * - payload : payload à envoyer
 * - name : nom du champ
 * - idProperty : propriété id
 * - selectorDisplayName : nom du sélecteur
 * - children : enfants
 * - formState : état du formulaire
 * - formDispatcher : dispatcher du formulaire
 * @param {String} param0.functionName Nom de la fonction à appeler
 * @param {Array} param0.mapper Mapper pour le tableau
 * @param {String} param0.typeProprietaire Type de propriétaire
 * @param {Object} param0.payload Payload à envoyer
 * @param {String} param0.name Nom du champ
 * @param {String} param0.idProperty Propriété id
 * @param {String} param0.selectorDisplayName Nom du sélecteur
 * @param {JSX.Element} param0.children Enfants
 * @param {Object} param0.formState Etat du formulaire
 * @param {Function} param0.formDispatcher Dispatcher du formulaire
 * @returns {JSX.Element}
 */
export function GenericReleveForm({
    functionName = URL_CONST.GET_LIST_MOUVEMENT_PLAGE,
    mapper = [
        {
            field: 'valeurTypeOrganisation.designation',
            displayName: 'Désignation'
        },
        {
            field: 'typeEntite',
            displayName: 'Créateur'
        },
        {
            field: 'soldePrecedent',
            displayName: 'Ouverture'
        },
        {
            field: 'entree',
            displayName: 'Entrée',
            isTotal: true
        },
        {
            field: 'sortie',
            displayName: 'Sortie',
            isTotal: true
        },
        {
            field: 'soldeActuel',
            displayName: 'Fermeture'
        },
        {
            field: 'dateCreation',
            displayName: 'Date'
        }
    ],
    typeProprietaire = 'Compte',
    payload = {},
    name = 'selectedGeneric',
    idProperty = 'id',
    selectorDisplayName = '',
    children,
    formState,
    formDispatcher
}) {
    return (
        <StandardReportTemplate
            listTitle={formState?.fields?.[name]?.designation || ''}
            functionName={functionName}
            formState={formState}
            formDispatcher={formDispatcher}
            listFormater={formater}
            mapper={mapper}
            defaultPayloadMapperValues={{
                typeProprietaire,
                ...payload
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: `${name}.${idProperty}`,
                    property: 'valeurTypeProprietaire',
                    isRequired: true,
                    displayName: selectorDisplayName
                }
            ]}
            isAddTotalTr
        >
            {children}
        </StandardReportTemplate>
    );
}
