import { Button, Offcanvas } from 'react-bootstrap';
import { useState } from 'react';
import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { BiFilterAlt } from 'react-icons/bi';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomRow,
    SimpleTable,
    CustomChart,
    ReportDateRange,
    CustomButtonLoader
} from '../../../../components';

export function MainReportComponent({
    formState,
    formDispatcher,
    fetchReport,
    children,
    customFilter,
    dataList,
    mapper,
    isFetching,
    topOfChart,
    isDataFetched,
    isLinkToDetail = false,
    isSweet = false,
    isExportAllowed = true,
    bottomPagination = false,
    listTitle,
    sweetTableType,
    componentToRenderFromRedux,
    componentToRenderInHeader = null,
    description
}) {
    const offcanvasOptions = {
        name: 'Enable backdrop (default)',
        scroll: true,
        backdrop: true,
        placement: 'end'
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const className = description
        ? 'justify-content-between border-bottom pb-2'
        : 'justify-content-end';
    return (
        <div className="container-fluid">
            <div className={`d-flex flex-row mb-1 ${className}`}>
                {description ? <h4>{description}</h4> : null}
                <Button
                    variant="outline-dark"
                    onClick={toggleShow}
                    className=""
                >
                    Rechercher <BiFilterAlt />
                </Button>
            </div>
            <Offcanvas
                show={show}
                onHide={handleClose}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...offcanvasOptions}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filtres</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="offcanvas-body p-3">
                    <ReportDateRange
                        formState={formState}
                        formDispatcher={formDispatcher}
                    />
                    {children}
                    {customFilter && typeof customFilter === 'function'
                        ? customFilter({
                              formState,
                              formDispatcher
                          })
                        : null}
                    <CustomCol className="col-12 mt-2">
                        <CustomButtonLoader
                            onClick={fetchReport}
                            disabled={isFetching}
                            isActionEncours={isFetching}
                            text="Rechercher"
                        >
                            Rechercher
                        </CustomButtonLoader>
                    </CustomCol>
                </Offcanvas.Body>
            </Offcanvas>
            <div>
                {topOfChart && typeof topOfChart === 'function'
                    ? topOfChart({ formDispatcher, formState })
                    : null}
                <CustomRow>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={formState?.fields?.isDisplayChart}
                    >
                        <CustomChart
                            formDispatcher={formDispatcher}
                            formState={formState}
                            dataList={dataList}
                            title={formState?.fields?.chartTitle}
                            devise={formState?.fields?.devise}
                            yAxisTittle={formState?.fields?.yAxisTittle}
                        />
                    </ConditionalRenderingWrapper>
                </CustomRow>
                {componentToRenderFromRedux &&
                formState?.fields?.dateDebut ===
                    TIME.utc000000(TODAY.at000000()) ? (
                    <div>{componentToRenderFromRedux}</div>
                ) : (
                    <>
                        {componentToRenderInHeader}
                        <SimpleTable
                            isDsiplayEmptyTableNotification={isDataFetched}
                            isDisplaySearch
                            tableClassName=""
                            isShowDropButton={false}
                            data={dataList}
                            identifier="id"
                            mapper={mapper}
                            // tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            isLinkToDetail={isLinkToDetail}
                            isSweet={isSweet}
                            isExportAllowed={isExportAllowed}
                            bottomPagination={bottomPagination}
                            listTitle={listTitle}
                            sweetTableType={sweetTableType}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
