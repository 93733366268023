import {
    submitPutAction,
    useFormGlobalReducer,
    useGetEntity,
    useGetUserDetail,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    formInitialState,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
    CenteredSpinner,
    CustomAlert,
    CustomButtonLoader,
    DetailWrapper,
    ShowNewComponent,
    UserServiceWrapper
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { schema } from '../common/detail-fields';

export function DetailBudgetAmenedementForm() {
    const { id } = useParams();
    const { pathname } = useLocation();
    const reduxDispatcher = useDispatch();
    const { firebaseUser, entity } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        entity: reduxState.elaborations.find((element) => element.id === id)
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: {
            actionEncours: '',
            isShowDetail: false,
            selectedCollection: {}
        },
        dependencies: { entity: true }
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, dependencies } = formState;
    useGetEntity({
        entity,
        etat: dependencies.entity.status,
        formDispatcher,
        functionName: URL_CONST.GET_ELABORATION_AMENDEMENT_BY_ID,
        id,
        idToken,
        nodeName: DB_NODE.ELABORATION_AMENDEMENT,
        reduxDispatcher
    });
    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        dependencies
    });
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = {
            etat,
            creator,
            idElaborationAmendement: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                reduxDispatcher
            })
        );
    };
    const detailCallback = () => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'isShowDetail',
                data: !fields.isShowDetail
            }
        });
    };

    const renderContent = () => {
        if (!entity?.id && !isLoadFailed && !isLoadComplete) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return <CustomAlert error={form?.error} />;
        }
        return (
            <DetailWrapper
                functionName={URL_CONST.GET_ELABORATION_AMENDEMENT_BY_ID}
                nodeName={DB_NODE.ELABORATION_AMENDEMENT}
                formDispatcher={formDispatcher}
                formState={formState}
                reduxDispatcher={reduxDispatcher}
                subtitle="Elaboration Additionnelle Charges et Ressources"
                entity={entity}
                id={id}
                schema={schema}
                idToken={idToken}
                // isShowDetail
                detailCallback={detailCallback}
                actionButtons={
                    <ShowNewComponent
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                .INITIER_ELABORATION_AMENDEMENT
                        ]}
                        destination={`${pathname}/create`}
                        text="Amender"
                        className="btn btn-outline-dark"
                    />
                }
            >
                <UserServiceWrapper
                    services={[
                        { code: 'test' },
                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                            .VALIDER_ELABORATION_AMENDEMENT,
                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                            .ADOPTER_ELABORATION_AMENDEMENT,
                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                            .ANNULER_ELABORATION_AMENDEMENT
                    ]}
                >
                    <div className="d-flex justify-content-end">
                        <CustomButtonLoader
                            type="button"
                            className="btn-danger"
                            onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            text="Annuler"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                        />
                        {entity?.etat === ETAT.ETAT_ENCOURS ? (
                            <CustomButtonLoader
                                type="button"
                                className="btn-success ms-3"
                                onClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                text="Mettre en attente"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        ) : null}
                        {entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION ? (
                            <CustomButtonLoader
                                type="button"
                                className="btn-success ms-3"
                                onClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                                text="Valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        ) : null}
                        {entity?.etat === ETAT.ETAT_VALIDER ? (
                            <CustomButtonLoader
                                type="button"
                                className="btn-success ms-3"
                                onClick={() => updateEtat(ETAT.ETAT_ADOPTE)}
                                text="Adopter"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ADOPTE
                                }
                            />
                        ) : null}
                    </div>
                </UserServiceWrapper>
            </DetailWrapper>
        );
    };
    return renderContent();
}
