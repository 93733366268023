import { GrFormRefresh } from 'react-icons/gr';
/**
 * Ce composant affiche un bouton qui permet d'actualiser ou refetcher sur une liste
 * @param {Object} param0 props
 * @returns {React.Component}
 */
export function RefetchButton({ refetchFunction, disabled }) {
    return disabled ? (
        <div className="spinner-border" role="status" />
    ) : (
        <span title="Actualiser" className="text-primary">
            <GrFormRefresh
                role="button"
                disabled={disabled}
                onClick={refetchFunction}
                className="text-primary h3"
            />
        </span>
    );
}
