import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * Retourne un object de type {[String]: Boolean}
 * @param {String} services tableau de service
 * @returns {Object}
 */
export function useHasService({ services = [] }) {
    /**
     * Recupération des services depuis le store
     */
    const { usersService } = useSelector((reduxState) => ({
        usersService: reduxState.usersService
    }));
    const servicesMemo = useMemo(
        () => ({ services, usersService }),
        [services, usersService]
    );
    const properties = {};
    servicesMemo.services.forEach((element) => {
        const findExisting = servicesMemo.usersService.find(
            (item) => item.sysService.code === element.code
        );
        if (findExisting) {
            properties[findExisting.sysService.code] = true;
        }
    });

    /**
     * Le retour est l'état local du hook
     */
    return {
        hasService: properties
    };
}
