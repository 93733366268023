import { URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { ViewLayout } from './view-layout';

export function ListReevalutionCharges() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                reduxPropertyName="reevaluations"
                functionName={URL_CONST.GET_LIST_REEVALUATION_INITIALS}
                nodeName={REDUX_NODE_NAME.REEVALUATION}
                identifier="id"
                mapper={[
                    {
                        field: 'classe',
                        displayName: 'Classe',
                        position: 1
                    },
                    {
                        field: 'periode.dateDebut',
                        displayName: 'Periode Debut',
                        position: 2,
                        isDate: true
                    },
                    {
                        field: 'periode.dateFin',
                        displayName: 'Periode Fin',
                        position: 3,
                        isDate: true
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 4
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 5
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
