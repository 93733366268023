import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    BanqueCreateRelPath,
    BanqueViewLayoutRelPath
} from '../../../routes/registration/configuration/config-napp-rel-path';
import { ListBanqueForm } from './list-banque';

export function BanqueViewLayout() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Banque</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname === `/${BanqueViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE
                                        .CODE_SERVICE_MODULE_ADMINISTRATION
                                        .SERVICE_BANQUE.CREATION_BANQUE
                                ]}
                                destination={BanqueCreateRelPath.path}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListBanqueForm />
        </>
    );
}
