import * as yup from 'yup';

/**
 * @description : Schema Retrait Emoney
 */
export const RetraitEmoneyFormSchema = yup.object().shape({
    numeroReference: yup.string().required(),
    tillNumber: yup.string(),
    selectedSuperDealer: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedClient: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    montant: yup.number().min(5)
});
