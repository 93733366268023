import { DB_NODE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListCompteBancairesSuperDealersKinDBForm() {
    return (
        <ListGenericForm
            isLinkToDetail={false}
            reduxPropertyName="comptes"
            functionName={URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION}
            nodeName={DB_NODE.COMPTE}
            formater
            payload={{ typeProprietaire: 'SuperDealer' }}
            mapper={[
                {
                    field: 'institutionFinanciere.designation',
                    displayName: 'Banques'
                },
                {
                    field: 'numero',
                    displayName: 'N° Compte'
                },
                {
                    field: 'valeurTypeProprietaire.designation',
                    displayName: 'Super Dealer'
                },
                {
                    field: 'devise',
                    displayName: 'Devise'
                },
                {
                    field: 'solde',
                    displayName: 'Solde'
                }
            ]}
        />
    );
}
