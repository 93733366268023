import {
    // analytics,
    // logEvent,
    ref,
    storage,
    firebaseApp,
    getStorage,
    auth
} from '@napp-inc/jnapp-firebase';

export { auth };

// logEvent(analytics, 'notification_received');

const storageInstruction = getStorage(
    firebaseApp,
    `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_INSTRUCTION}`
);
const releveBancaireStorage = getStorage(
    firebaseApp,
    `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_RELEVE}`
);

export const logoMarchandRef = ref(storage, 'logo');
export const documentAgentLivreurRef = ref(storage, 'agentLivreur');
export const instructionRef = ref(storageInstruction, '');
export const logoProduitRef = ref(storage, 'logo/produits');
export const releveBancaire = ref(releveBancaireStorage, '');
export const releveEmoney = ref(releveBancaireStorage, 'ReleveEmoney');
export const fichierPresence = ref(
    releveBancaireStorage,
    'RepertoireRelevePresence'
);
export const releveVente = ref(releveBancaireStorage, 'ReleveVente');
export const imgPieceIdentite = ref(storage, 'pieceIdentite');
export const relevePaiementActivateur = ref(
    releveBancaireStorage,
    'RepertoireReleveActivateur'
);
