import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { ReleveBalanceOrganisme } from '../../../../components';

export function ReleveLiaisonKinDistributionForm() {
    return (
        <ReleveBalanceOrganisme
            typeOrganisation={TYPE_ORGANISATION.SUPER_DEALER}
            payload={{
                typeProprietaire: TYPE_ORGANISATION.SUPER_DEALER,
                estOrganisation: true,
                type: 'Liaison'
            }}
            dynamicNodeName="compteLiaisonKinDistribution"
            label="Sélectionner un compte"
        />
    );
}
