// import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import { ListRegularisationSpecialeForm } from './list-regularisation-speciale';
import { SuperDealerPath } from '../../../routes/registration';

export function RegularisationSpecialeViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Regularisation Stock</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${SuperDealerPath.RegularisationSpecialeViewLayoutPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' }
                                    // CODE_SERVICE.STOCK.REGULARISATION_STOCK
                                    //     .INITIER_CREATION_REGULARISATION_STOCK
                                ]}
                                destination={
                                    SuperDealerPath
                                        .RegularisationSpecialeCreatePath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListRegularisationSpecialeForm />
        </div>
    );
}
