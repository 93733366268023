import React from 'react';
import { ListDroitAccesOrganisationForm } from './list-droit-acces';

export function DroitAccesOrganisationViewLayout() {
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Droit d&#x27;accès</h1>
            </div>
            <ListDroitAccesOrganisationForm />
        </div>
    );
}
