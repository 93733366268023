import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { UserServiceWrapper } from '../container';

export function ShowNewComponent({
    destination,
    services,
    text = 'Créer',
    className = 'btn btn-primary',
    icon = <FaPlus />,
    title = ''
}) {
    const navigate = useNavigate();
    return (
        <UserServiceWrapper services={services}>
            <span
                title={title}
                role="button"
                tabIndex={0}
                onClick={() =>
                    navigate(destination, {
                        replace: true
                    })
                }
                onKeyDown={() =>
                    navigate(destination, {
                        replace: true
                    })
                }
                className={className}
            >
                {icon} {text}
            </span>
        </UserServiceWrapper>
    );
}
