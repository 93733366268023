import { FORM_CONST } from '@napp-inc/jnapp-util';
import { useCalculTotalBilletage } from '../../util';
import { CustomAddButton } from '../button';
import { CustomCol, CustomRow } from '../container';
import { CustomDropdown } from '../dropdown';
import { CustomInput } from '../input';
import { CustomLabel } from '../label';
import { SimpleTable } from '../table';
/**
 * Ce componsant permet de saisir le billetage des monnaies locale dont les coupures sont disponibles
 * @param {Object} param0 Objet contenant les propriétés suivantes ; formDispatcher, formState
 * listBilletageName, selectedCoupureName, nombreCoupureName, deviseCaisse
 * @param {String} param0.deviseCaisse - la devise de la caisse pour laquelle on fait le billetage
 * @param {Function} param0.formDispatcher fonction de dispatch du formulaire
 * @param {Object} param0.formState State du composant
 * @param {String} param0.listBilletageName - nom de la liste dans le form state
 * @param {String} param0.selectedCoupureName - nom de la propriété coupure dans le form state
 * @param {String} param0.nombreCoupureName - nom de la propriété nombre coupure dans le form state
 * @returns {React.Component}
 */
export function BilletageComponent({
    formDispatcher,
    uiValidator,
    listBilletageName = 'listBilletage',
    selectedCoupureName = 'selectedCoupureBilletage',
    nombreCoupureName = 'nombreCoupureBilletage',
    formState,
    deviseCaisse,
    coupures,
    BilletageLabel = 'Billetage'
}) {
    const ajouterCoupure = () => {
        const devise = { code: deviseCaisse };
        const coupure = formState.fields[selectedCoupureName];
        const nombre = formState.fields[nombreCoupureName];
        if (!nombre || !devise || !devise.code || !coupure || !coupure.code)
            return;
        const data = {
            nombre,
            devise,
            coupure,
            id: `${coupure.code}${devise.code}`
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: { field: listBilletageName, data }
        });
    };
    const resultat = useCalculTotalBilletage({
        listBilletage: formState.fields[listBilletageName]
    });
    const renderContent = () => (
        <>
            <CustomRow>
                <CustomLabel className="col-2">{`${BilletageLabel || ''} ${
                    deviseCaisse || ''
                }`}</CustomLabel>
                <CustomDropdown
                    isSort={false}
                    disabled={!coupures?.length}
                    options={
                        coupures?.length
                            ? coupures.map((item) => ({
                                  code: item?.valeur
                              }))
                            : []
                    }
                    formDispatcher={formDispatcher}
                    name={selectedCoupureName}
                    id="selectedCoupureCreationBilletage"
                    formState={formState}
                    propertyToDisplay="code"
                    divClassName="col-12 col-sm-5"
                    uiValidator={uiValidator}
                />
                <CustomInput
                    type="number"
                    disabled={!coupures?.length}
                    divClassName="col-12 col-sm-4"
                    isInputGroup={false}
                    min={0}
                    placeholder="Nombre coupure"
                    formDispatcher={formDispatcher}
                    name={nombreCoupureName}
                    id="nombreCoupureCreationBilletage"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomAddButton
                    className="col-sm-1"
                    callback={ajouterCoupure}
                />
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-md-2 col-md-10 col-12">
                    <SimpleTable
                        formDispatcher={formDispatcher}
                        listSelectedItemName={listBilletageName}
                        identifier="id"
                        data={formState.fields[listBilletageName]}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                field: 'devise.code',
                                displayName: 'devise',
                                isKeepFormat: true
                            },
                            {
                                field: 'coupure.code',
                                displayName: 'coupure'
                            },
                            {
                                field: 'nombre',
                                displayName: 'nombre'
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
            {!!(resultat && resultat.length) &&
                resultat.map((res) => (
                    <CustomRow key={res.devise}>
                        <CustomCol className="offset-md-2 col-12">
                            <span className="lead">{`Total ${res.devise}: ${res.total}`}</span>
                        </CustomCol>
                    </CustomRow>
                ))}
        </>
    );
    return renderContent();
}
