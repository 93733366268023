import { ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { fetchElement, submitPutAction } from '@napp-inc/jnapp-hook';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    DetailWrapper,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailActivateurForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'uploadTraitementPaiementVendeur',
            defaultElements: ['paiement']
        });
    const { elements, form } = formState;
    const onFetchDetailRetraitById = () => {
        if (!id) return;
        const { status: etat } = elements.paiement;
        formDispatcher(
            fetchElement({
                idToken,
                functionName:
                    URL_CONST.GET_LIST_PAIEMENT_VENDEUR_BY_ORGANISATION,
                elementPropertyName: 'paiement',
                etat,
                payload: {
                    idTraitementPaiementVendeur: id
                },
                reduxDispatcher
            })
        );
    };

    const updateEtat = () => {
        const payload = {
            idTraitementPaiementVendeur: entity?.id
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_DECLENCHEMENT_SMS_PAIEMENT_VENDEUR,
                payload,
                reduxNodeName:
                    REDUX_NODE_NAME.UPLOAD_TRAITEMENT_PAIEMENT_VENDEUR,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TRAITEMENT_PAIEMENT_VENDEUR_BY_ID}
            nodeName={REDUX_NODE_NAME.UPLOAD_TRAITEMENT_PAIEMENT_VENDEUR}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail Paiement Vendeur"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <div>
                <CustomButtonLoader
                    type="button"
                    className="btn-success mt-2 me-5"
                    onClick={onFetchDetailRetraitById}
                    text="Voir Détails"
                    disabled={
                        elements?.paiement?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomButtonLoader
                        type="button"
                        className="btn-primary mt-2"
                        onClick={updateEtat}
                        text="Envoie SMS"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </ConditionalRenderingWrapper>
            </div>
            <div>
                <SimpleTable
                    filter
                    // isAddTotalTr
                    tableClassName=""
                    isShowDropButton={false}
                    data={elements?.paiement?.value || []}
                    identifier="id"
                    mapper={[
                        {
                            displayName: 'Client',
                            field: 'nom'
                        },
                        {
                            displayName: 'Numero',
                            dataExtractor: (item) =>
                                `${item?.numero || item?.client?.numero || ''}`
                        },
                        {
                            field: 'montant',
                            displayName: 'montant',
                            // isTotal: true,
                            isNumber: true
                        },
                        {
                            field: 'devise',
                            displayName: 'Devise'
                        },
                        {
                            field: 'etat',
                            displayName: 'Etat'
                        },
                        {
                            field: 'dateCreation',
                            displayName: 'date création'
                        }
                    ]}
                    // filter={false}
                    isLinkToDetail={false}
                />
            </div>
        </DetailWrapper>
    );
    return renderContent();
}
