import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListCommandeFlashForm() {
    return (
        <ListGenericForm
            reduxPropertyName="commandesFlash"
            functionName={URL_CONST.GET_COMMANDE_FLASH_USER}
            nodeName={DB_NODE.COMMANDE_FLASH}
            mapper={[
                {
                    field: 'quantite',
                    displayName: 'quantite'
                },
                {
                    field: 'prixTotal',
                    displayName: 'prixTotal'
                },
                {
                    field: 'produit.designation',
                    displayName: 'produit'
                },
                {
                    field: 'etat',
                    displayName: 'etat'
                }
            ]}
        />
    );
}
