/**
 * Function extractTXTFileInfo
 * This function is used to extract data from a txt file through the URL provided
 * @param {Object} param0 : the parameter
 * @param {Object} param0.props : file properties (url, name, etc)
 * @param {Object} param0.fileName : the file name
 * @param {Object} param0.extension : the extension (txt)
 * @param {Object} param0.type : the type ('text/plain')
 * @return TXT file containing data
 */

export function extractTXTFileInfo({
    props,
    fileName,
    extension = 'txt',
    type = 'text/plain'
}) {
    let fileData;
    const request = new XMLHttpRequest();
    request.open('GET', props.url);
    request.send(null);
    request.onreadystatechange = () => {
        if (request.readyState === 4 && request.status === 200) {
            const typeText = request.getResponseHeader('Content-Type');
            if (typeText.indexOf('text') !== 1) {
                fileData = request.responseText;
                if (fileData) {
                    const file = new File(
                        [`${fileData}`],
                        `${fileName}.${extension}`,
                        {
                            type
                        }
                    );
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(file);
                    link.href = url;
                    link.download = `${fileName}.${extension}`;
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }
            }
        }
    };
}
