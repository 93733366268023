import * as yup from 'yup';

/**
 * @description : Schema de validation Heure Supplementaire création form
 */
export const HeureSupSchema = yup.object().shape({
    nombreHeure: yup.number().min(1),
    selectedAgent: yup.object().shape({
        uid: yup.string().required(),
        designation: yup.string().required()
    }),
    motif: yup.string().required()
});
