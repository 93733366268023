import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    ServiceGenerateurCreateRelPath,
    ServiceGenerateurViewLayoutRelPath
} from '../../../routes/registration/configuration/config-napp-rel-path';
import { ListServiceGenerateurForm } from './list-service-generateur';

export function ServiceGenerateurViewLayout() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Service Générateur</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ServiceGenerateurViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                        .CREATION_SERVICE_GENERATEUR
                                ]}
                                destination={
                                    ServiceGenerateurCreateRelPath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListServiceGenerateurForm />
        </>
    );
}
