import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST, DB_NODE } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ConfigurationInstuctionSchema as uiValidator } from './validation';

const defaultFields = {
    tempsTemporisation: 0,
    intervalle: 0,
    selectedCompte: {},
    selectedInstitutionFinanciere: {}
};

const defaultDependencies = { fields: ['compte', 'banque'] };

export function CreationConfigurationInstructionForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        institutionsFinanciere,
        etatInstitutionsFinanciere,
        comptes,
        etatCompte
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionsFinanciere: reduxState.etat.institutionsFinanciere.etat,
        comptes: reduxState.compteParallele.map((item) => ({
            ...item,
            code: item.numero,
            designation: `${item.numero} - ${item.devise}`
        })),
        etatCompte: reduxState.etat.compteParallele.etat
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                nodeName: REDUX_NODE_NAME.COMPTE_PARALLELE,
                functionName: URL_CONST.GET_LIST_COMPTE_PARALLELE,
                etat: etatCompte
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionsFinanciere
            }
        ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            newConfigurationInstruction: {
                tempsTemporisation: fields.tempsTemporisation,
                intervalle: fields.intervalle,
                numeroCompteExterne: fields.selectedCompte.code
            }
            // creator: 'qlxrcWKltYhDhrIG90oSyeU0eVN2'
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CONFIGURATION_INSTRUCTION,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.CONFIG_INSTRUCTION,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const formatComptes = (bq, tab = []) => {
        const tabReturned = [];
        tab.forEach((item) => {
            const finder = tabReturned.find((el) => el.numero === item.numero);
            if (!finder) {
                tabReturned.push(item);
            }
        });
        return tabReturned.filter(
            (item) => item.institutionFinanciere.code === bq.code
        );
    };

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'compte',
                    etat: etatCompte
                },
                {
                    dependency: 'banque',
                    etat: etatInstitutionsFinanciere
                }
            ]}
        >
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Intervalle (H)* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Intervalle "
                    formDispatcher={formDispatcher}
                    name="intervalle"
                    id="CreationConfigInstructionIntervalleId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Temporisation (H)* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Temps de temporisation"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    name="tempsTemporisation"
                    id="CreationConfigInstructionTempsTemporisationId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    idProperty="code"
                    propertyToDisplay="designation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    label="Institution Financière* : "
                    name="selectedInstitutionFinanciere"
                    id="creationRemboursementCautionCompteInstitutFinanciereId"
                    options={institutionsFinanciere}
                    selectionClassName="form-select-md"
                    defaultOption="Selectionner Institution Financière"
                    divClassName="col-sm-10"
                    labelClassName="col-sm-2"
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={formatComptes(
                        fields.selectedInstitutionFinanciere,
                        comptes
                    )}
                    defaultOption="Selectionner Compte"
                    label="Compte* : "
                    labelClassName="col-2"
                    divClassName="col-10"
                    formDispatcher={formDispatcher}
                    name="selectedCompte"
                    id="selectedCompteRapportCompteId"
                    uiValidator={uiValidator}
                    formState={formState}
                    disabled={
                        !formatComptes(
                            fields.selectedInstitutionFinanciere,
                            comptes
                        )?.length
                    }
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
