import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListChangeForm() {
    return (
        <ListGenericForm
            reduxPropertyName="operationChange"
            functionName={URL_CONST.GET_LIST_CHANGE_ESPECE_INITIALS}
            nodeName={REDUX_NODE_NAME.OPERATION_CHANGE}
            mapper={[
                {
                    displayName: 'Devise',
                    field: 'devise'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
