import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useGetReportDetail } from '../../../../util';
import { RapportLimiteCreditOrganisme } from '../../../../components';

export function RapportAgentRevendeurForm({ typeOrganisation }) {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedRevendeur: {}
        }
    });
    return (
        <RapportLimiteCreditOrganisme
            typeOrganisation={TYPE_ORGANISATION.REVENDEUR}
            formState={formState}
            formDispatcher={formDispatcher}
            name="selectedRevendeur"
            idProperty="code"
            mapper={[
                {
                    displayName: 'Revendeur',
                    field: 'organisation'
                },
                {
                    displayName: 'Agent',
                    field: 'nomComplet'
                },
                {
                    displayName: 'Autorisé',
                    field: 'limit',
                    isTotal: true
                },
                {
                    displayName: 'Disponible',
                    field: 'disponible',
                    isTotal: true
                },
                {
                    displayName: 'Utilisé',
                    field: 'solde',
                    isTotal: true
                }
            ]}
            payload={{ estKinDistribution: true, typeOrganisation }}
        />
    );
}
