import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    DB_NODE,
    Mul,
    Div
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { BsTag } from 'react-icons/bs';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ChangeGuichetFormSchema as uiValidator } from './validation';

const defaultFields = {
    selectMonnaieEntree: {},
    selectMonnaieSortie: {},
    montant: 0,
    selectedDeviseSollicite: {},
    montantSortie: 0,
    isTauxSollicite: false,
    tauxSollicite: 0,
    list: []
};

/**
 * @description defaultDependencies for our state
 */
const defaultDependencies = { fields: ['monnaieLocale', 'taux'] };

// FIXME: Rajouter la logique selon laquelle le montant de sortie en fonction des coupures il doit etre inférieur ou égal au montant à decaisser en fonction des cas
export function CreationChangeGuichetForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        monnaiesLocales,
        etatMonnaieLocale,
        taux,
        etatTaux,
        deviseReference,
        allCoupures
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            code: item.code || item.devise
        })),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat,
        taux: reduxState.taux,
        etatTaux: reduxState.etat.taux.etat,
        deviseReference: reduxState.deviseReference,
        allCoupures: reduxState.allCoupures
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            },
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.TAUX,
                functionName: URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION,
                etat: etatTaux
            }
        ]
    });

    const [monnaieToUse] =
        allCoupures.filter(
            (item) =>
                item.devise === fields.selectMonnaieSortie.devise ||
                item.code === fields.selectMonnaieSortie.devise
        ) || {};
    const isRetourValide = ({ montantSortie = fields.montantSortie }) => {
        const [firstElement] =
            monnaieToUse?.coupures?.sort(
                (a, b) => Number(a?.valeur || 0) - Number(b?.valeur || 0)
            ) || [];
        return montantSortie % firstElement.valeur === 0;
    };

    const onAddDeviseSollicite = () => {
        if (!fields.selectedDeviseSollicite?.devise || !fields.tauxSollicite)
            return;
        const data = {
            devise: fields.selectedDeviseSollicite.devise,
            valeur: fields.tauxSollicite
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'list',
                data,
                identifier: 'devise'
            }
        });
    };

    const getTauxToUse = () => {
        // if (fields.isTauxSollicite) {
        //     return fields.tauxSollicite ? fields.tauxSollicite : 1;
        // }
        if (fields.selectMonnaieEntree?.devise === deviseReference?.devise) {
            return (
                taux.find(
                    (rate) =>
                        rate.devise === fields.selectMonnaieSortie?.devise &&
                        rate.type === 'TauxAchat'
                )?.valeur || 1
            );
        }
        if (fields.selectMonnaieSortie?.devise === deviseReference?.devise) {
            return (
                taux.find(
                    (rate) =>
                        rate.devise === fields.selectMonnaieEntree?.devise &&
                        rate.type === 'TauxVente'
                )?.valeur || 1
            );
        }
        return 1;
    };

    const getEquivalent = ({ list = [] }) => {
        const listReturn = [];
        if (
            !fields.selectMonnaieEntree?.devise ||
            !fields.selectMonnaieSortie?.devise
        )
            return [];
        if (
            fields.selectMonnaieEntree?.devise === deviseReference?.devise &&
            fields.selectMonnaieSortie?.devise !== deviseReference?.devise
        ) {
            const rateUsed = fields.isTauxSollicite
                ? fields.tauxSollicite
                : taux.find(
                      (rate) =>
                          rate.devise === fields.selectMonnaieSortie?.devise &&
                          rate.type === 'TauxAchat'
                  )?.valeur || 1;
            listReturn.filter(
                (item) => item?.devise !== fields.selectMonnaieSortie?.devise
            );
            listReturn.push({
                devise: fields.selectMonnaieSortie?.devise,
                taux: rateUsed,
                valeur: fields.montant,
                equivalent: Mul(fields.montant, rateUsed)
            });
        }
        if (
            fields.selectMonnaieEntree?.devise !== deviseReference?.devise &&
            fields.selectMonnaieSortie?.devise === deviseReference?.devise
        ) {
            const rateUsed = fields.isTauxSollicite
                ? fields.tauxSollicite
                : taux.find(
                      (rate) =>
                          rate.devise === fields.selectMonnaieEntree?.devise &&
                          rate.type === 'TauxVente'
                  )?.valeur || 1;
            listReturn.filter(
                (item) => item?.devise === fields.selectMonnaieEntree?.devise
            );
            listReturn.push({
                devise: fields.selectMonnaieEntree?.devise,
                taux: rateUsed,
                valeur: fields.montant,
                equivalent: Div(fields.montant, rateUsed)
            });
        }
        if (
            fields.selectMonnaieEntree?.devise !== deviseReference?.devise &&
            fields.selectMonnaieSortie?.devise !== deviseReference?.devise
        ) {
            if (
                fields.selectMonnaieEntree?.devise !== deviseReference?.devise
            ) {
                const findInDevise = list?.find(
                    (element) =>
                        element.devise === fields.selectMonnaieEntree.devise
                );
                if (findInDevise) {
                    listReturn.push({
                        devise:
                            findInDevise?.devise ||
                            fields.selectMonnaieEntree?.devise,
                        taux: findInDevise?.valeur,
                        valeur: fields.montant,
                        equivalent: Div(fields.montant, findInDevise?.valeur)
                    });
                } else if (!findInDevise) {
                    const rateUsed =
                        taux?.find(
                            (rate) =>
                                rate.devise ===
                                    fields.selectMonnaieEntree?.devise &&
                                rate.type === 'TauxVente'
                        )?.valeur || 1;
                    listReturn.push({
                        devise: fields.selectMonnaieEntree?.devise,
                        taux: rateUsed,
                        valeur: fields.montant,
                        equivalent: Div(fields.montant, rateUsed)
                    });
                }
            }
            if (
                fields.selectMonnaieSortie?.devise !== deviseReference?.devise
            ) {
                const [first] = listReturn;
                const findOutDevise = list?.find(
                    (element) =>
                        element.devise === fields.selectMonnaieSortie.devise
                );
                if (findOutDevise) {
                    listReturn.push({
                        devise:
                            findOutDevise?.devise ||
                            fields.selectMonnaieSortie?.devise,
                        taux: findOutDevise?.valeur,
                        valeur: first?.equivalent,
                        equivalent: Mul(
                            first?.equivalent,
                            findOutDevise?.valeur
                        )
                    });
                } else if (!findOutDevise) {
                    const rateUsed =
                        taux.find(
                            (rate) =>
                                rate.devise ===
                                    fields.selectMonnaieSortie?.devise &&
                                rate.type === 'TauxAchat'
                        )?.valeur || 1;
                    listReturn.push({
                        devise: fields.selectMonnaieSortie?.devise,
                        taux: rateUsed,
                        valeur: first?.equivalent,
                        equivalent: Mul(first?.equivalent || 1, rateUsed)
                    });
                }
            }
        }
        return listReturn;
    };

    const getMoneyToCashOut = () =>
        fields.selectMonnaieEntree?.devise === deviseReference?.devise
            ? Mul(fields.montant, getTauxToUse())
            : Div(fields.montant, getTauxToUse());

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            fields.selectMonnaieEntree.devise ===
            fields.selectMonnaieSortie.devise
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Vous ne pouvez pas sélectionner la même devise',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        if (!isRetourValide({ montantSortie: fields.montantSortie })) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        'Vous ne pouvez pas retourner ce montant dans cette devise',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        if (
            fields.selectMonnaieEntree.devise !== deviseReference?.devise &&
            fields.selectMonnaieSortie.devise !== deviseReference?.devise
        ) {
            const second = getEquivalent({ list: fields?.list })?.[1] || {};
            if (
                !isRetourValide({ montantSortie: fields.montantSortie }) ||
                second.equivalent < fields.montantSortie
            ) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message:
                            'Vous ne pouvez pas retourner ce montant dans cette devise',
                        status: FORM_CONST.CREATION_ECHEC
                    }
                });
                return;
            }
        }
        if (
            !fields.isTauxSollicite &&
            getMoneyToCashOut() < fields.montantSortie &&
            (fields.selectMonnaieEntree?.devise === deviseReference?.devise ||
                fields.selectMonnaieSortie?.devise === deviseReference?.devise)
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        'Le montant decaissé ne peut pas être supérieur au monant à décaisser',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        if (fields.isTauxSollicite && !fields.list.length) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Veuillez valider le(s) taux renseigné(s) !',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const payloadToAdd = {};
        if (fields.isTauxSollicite) {
            payloadToAdd.devises = fields?.list;
        }
        const payload = {
            newChangeGuichet: {
                devise: fields.selectMonnaieEntree.devise,
                deviseSollicite: fields.selectMonnaieSortie.devise,
                montant: fields.montant,
                montantSortie: fields.montantSortie,
                estTauxSollicite: fields.isTauxSollicite,
                ...payloadToAdd
            }
        };
        // console.log('payload', payload);
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CHANGE_GUICHET,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.OPERATION_CHANGE_GUICHET,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'monnaieLocale',
                    etat: etatMonnaieLocale
                },
                {
                    dependency: 'taux',
                    etat: etatTaux
                }
            ]}
        >
            {/* <StateShowerComponent state={formState} /> */}
            <CustomRow>
                <CustomDropdown
                    options={monnaiesLocales}
                    label="Monnaie Perçu* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectMonnaieEntree"
                    id="selectMonnaieEntreeId"
                    defaultOption="Selectionner Monnaie Perçu"
                    formState={formState}
                    propertyToDisplay="devise"
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={monnaiesLocales.filter(
                        (monnaie) =>
                            monnaie?.devise !==
                            fields.selectMonnaieEntree?.devise
                    )}
                    label="Monnaie Sollicitée* :"
                    defaultOption="Selectionner Monnaie Sollicitée"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectMonnaieSortie"
                    id="selectMonnaieSortieId"
                    formState={formState}
                    propertyToDisplay="devise"
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomSwitchButton
                formDispatcher={formDispatcher}
                formState={formState}
                text="Solliciter Taux ?"
                name="isTauxSollicite"
                divClassName="col-md-8 mb-3"
                value={fields.isTauxSollicite}
                uiValidator={uiValidator}
            />
            <CustomRow isShouldBeRendered={fields.isTauxSollicite}>
                <CustomLabel text="Taux Sollicité* :" className="col-sm-2" />
                <CustomDropdown
                    formDispatcher={formDispatcher}
                    formState={formState}
                    // uiValidator={uiValidator}
                    name="selectedDeviseSollicite"
                    id="creationGuichetChangeGuichetMontantselectedDeviseSolliciteId"
                    options={monnaiesLocales.filter(
                        (item) => item?.devise !== deviseReference?.devise
                    )}
                    selectionClassName="form-select-md"
                    defaultOption="Selectionner Devise"
                    divClassName="col-sm-4"
                    propertyToDisplay="devise"
                    idProperty="id"
                />
                <CustomInput
                    isFloat
                    type="number"
                    divClassName="col-sm-4"
                    isInputGroup={false}
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="tauxSollicite"
                    id="creationGuichetChangeGuichetMontantTauxSolliciteId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={onAddDeviseSollicite}
                />
            </CustomRow>
            <CustomCollapse
                isIn={fields.isTauxSollicite && !!fields?.list?.length}
                className="mb-3"
            >
                <SimpleTable
                    identifier="devise"
                    data={fields?.list}
                    formDispatcher={formDispatcher}
                    listSelectedItemName="list"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            field: 'devise',
                            displayName: 'Devise'
                        },
                        {
                            position: 2,
                            field: 'valeur',
                            displayName: 'Valeur'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant Perçu* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="creationGuichetChangeGuichetMontantId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant Decaissé* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Montant décaissé"
                    formDispatcher={formDispatcher}
                    name="montantSortie"
                    id="creationGuichetChangeGuichetMontantDecaisserId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            {(fields.selectMonnaieEntree.devise ||
                fields.selectMonnaieSortie.devise) &&
            fields.montant ? (
                <div className="d-flex">
                    {getEquivalent({ list: fields.list }).map((item, index) => (
                        <div
                            className={`card ${index !== 0 ? 'ms-5' : ''}`}
                            key={item.devise}
                        >
                            <div className="card-header d-flex justify-content-between">
                                <div>
                                    Conversion{' '}
                                    <span className="badge bg-danger">
                                        {item.devise}
                                    </span>
                                </div>
                                <div>
                                    <BsTag />
                                </div>
                            </div>
                            <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        {' '}
                                        Montant :{' '}
                                        <span className="badge bg-primary">
                                            {item.valeur}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        Taux Utilisé :{' '}
                                        <span className="badge bg-primary">
                                            1 {deviseReference.devise} ={' '}
                                            {item.taux} {item.devise}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-footer">
                                Equivalent :{' '}
                                <span className="badge bg-primary">
                                    {item.equivalent}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
            <CustomRow className="row mt-5">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
