import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { ImputationStockFormSchema as uiValidator } from './validation';
import { formatCodeDesignation } from '../../../util';

const defaultFields = {
    // code: '',
    designation: '',
    selectedTypeOperation: {},
    selectedTypeStock: {},
    listTypeOperationStock: [],
    selectedParent: {},
    isParent: false
};

const defaultDependencies = { fields: ['typeStock', 'imputation'] };

export function CreationImputationStockForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        typesStock,
        etatTypeStock,
        imputationsStock,
        etatImputationStock
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        typesStock: reduxState.typesStock,
        etatTypeStock: reduxState.etat.typesStock.etat,
        imputationsStock: reduxState.imputationsStock,
        etatImputationStock: reduxState.etat.imputationsStock.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_TYPE_STOCK,
                nodeName: DB_NODE.TYPE_STOCK,
                etat: etatTypeStock
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_IMPUTATION_STOCK,
                nodeName: DB_NODE.IMPUTATION_STOCK,
                etat: etatImputationStock
            }
        ]
    });
    const { fields, form } = formState;
    const addTypeOperationStock = () => {
        const {
            selectedTypeStock,
            selectedTypeOperation,
            listTypeOperationStock
        } = fields;
        if (!selectedTypeStock?.code || !selectedTypeOperation?.code) return;
        if (
            listTypeOperationStock.find(
                (item) => item?.typeStock.code === selectedTypeStock.code
            )
        )
            return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listTypeOperationStock',
                identifier: 'code',
                data: {
                    code: selectedTypeStock.code,
                    typeStock: selectedTypeStock,
                    typeOperation: selectedTypeOperation
                }
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { designation, listTypeOperationStock } = fields;
        const payloadToAdd = {};
        const payloadToAddOut = {};
        if (fields.isParent) {
            payloadToAdd.parent = { code: fields.selectedParent.code };
        }
        if (!fields.isParent) {
            payloadToAddOut.list = listTypeOperationStock.map((item) => ({
                typeOperation: item.typeOperation.code,
                typeStock: { code: item?.typeStock?.code }
            }));
        }
        const payload = {
            newImputation: {
                code: formatCodeDesignation({
                    partToAdd: 'NAPP_IMPUTATION_STOCK_',
                    designation
                }),
                designation,
                ...payloadToAdd
            },
            ...payloadToAddOut,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_IMPUTATION_STOCK,
                payload,
                fields,
                reduxNodeName: DB_NODE.IMPUTATION_STOCK,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'typeStock',
                    etat: etatTypeStock
                },
                {
                    dependency: 'imputation',
                    etat: etatImputationStock
                }
            ]}
        >
            {/* <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Code* : "
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le code"
                    formDispatcher={formDispatcher}
                    name="code"
                    id="codeCreationCharge"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow> */}
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Designation* : "
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le nom"
                    formDispatcher={formDispatcher}
                    name="designation"
                    id="designationCreationCharge"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomSwitchButton
                formDispatcher={formDispatcher}
                formState={formState}
                id="estParentId"
                text="Renseigner une dépendance?"
                value={fields.isParent}
                name="isParent"
                divClassName="ms-2 col-md-8 mb-3"
            />
            <CustomRow isShouldBeRendered={fields.isParent}>
                <CustomDropdown
                    options={imputationsStock}
                    label="Parent* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedParent"
                    defaultOption="Selectionner une imputation"
                    id="selectedParentCreationImputationStock"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!fields.isParent}>
                <CustomDropdown
                    options={typesStock}
                    label="Type stock* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-sm-4"
                    formDispatcher={formDispatcher}
                    name="selectedTypeStock"
                    id="selectedTypeStockCreationImputationStock"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
                <CustomDropdown
                    options={[
                        { code: 'ENTREE', designation: 'Entrée' },
                        { code: 'SORTIE', designation: 'Sortie' }
                    ]}
                    divClassName="col-sm-4"
                    formDispatcher={formDispatcher}
                    name="selectedTypeOperation"
                    id="selectedTypeOperationCreationImputationStock"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
                <CustomCol className="col-sm-2">
                    <CustomAddButton
                        size="25em"
                        callback={addTypeOperationStock}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow isShouldBeRendered={!fields.isParent}>
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    <SimpleTable
                        identifier="code"
                        listSelectedItemName="listTypeOperationStock"
                        data={fields.listTypeOperationStock}
                        formDispatcher={formDispatcher}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 1,
                                field: 'typeStock.designation',
                                displayName: 'Type stock'
                            },
                            {
                                position: 2,
                                field: 'typeOperation.designation',
                                displayName: 'Operation'
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
