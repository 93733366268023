import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    // PRODUIT_ELECTRONIQUE,
    TYPE_BUSINESS,
    BENEFICIAIRE
} from '@napp-inc/jnapp-util';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
    // StateShowerComponent
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST, formatCodeDesignation } from '../../../../util';

const defaultFields = {
    designation: '',
    numero: '',
    nom: '',
    prenom: '',
    selectedProduit: {},
    selectedSuperDealer: {},
    selectedClient: {},
    selectedUser: {},
    list: []
};

export function CreationRattacherNumeroClientForm({ type }) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        organisation,
        superDealerKinDB,
        etatSuperDealerKinDB,
        clientsOrganisation,
        etatClientsOrganisation,
        users,
        etatUser
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation,
        superDealerKinDB: reduxState.superDealerKinDB
            .filter((item) => item.estEmoney)
            .map((item) => ({
                ...item,
                code: item?.entreprise?.code,
                designation: item?.entreprise?.designation
            })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat,
        clientsOrganisation: reduxState.clientsOrganisation.map((item) => ({
            ...item,
            code: item?.valeurTypeProprietaire?.code,
            designation: `${
                item?.valeurTypeProprietaire?.designation || ''
            } - ${item?.valeurTypeProprietaire?.prenom || ''} ${
                item?.valeurTypeProprietaire?.nom || ''
            }`
        })),
        etatClientsOrganisation: reduxState.etat.clientsOrganisation.etat,
        users: reduxState.userAgentVendeur.map((item) => ({
            ...item,
            uid: item?.owner,
            designation: `${item?.prenom || ''} ${item?.nom || ''}`
        })),
        etatUser: reduxState.etat.userAgentVendeur.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const defaultDependencies = {};
    if (organisation.estKinDistribution) {
        defaultDependencies.fields = ['superDealer'];
    }
    if (type === 'rattacher') {
        defaultDependencies.fields = ['superDealer', 'client'];
    }
    if (type === 'rattacherAgent') {
        defaultDependencies.fields = ['superDealer', 'userAgent'];
    }
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { estEmoney: true, typeEntite: nappUser?.typeEntite },
                isCondition: !!organisation?.estKinDistribution
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_NUMERO_CLIENT,
                nodeName: REDUX_NODE_NAME.CLIENT_ORGANISATION,
                etat: etatClientsOrganisation,
                isCondition: type === 'rattacher'
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER_A_SELECTIONNER,
                nodeName: REDUX_NODE_NAME.USER_AGENT_VENDEUR,
                payload: { typeEntite: BENEFICIAIRE.AGENT_VENDEUR.code },
                etat: etatUser,
                isCondition: type === 'rattacherAgent'
            }
        ]
    });

    const onSuccess = (data) => {
        if (data?.element && (data?.element?.client || data?.element?.list)) {
            reduxDispatcher(
                GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                    data.element.list,
                    REDUX_NODE_NAME.CLIENT_ORGANISATION
                )
            );
        }
    };

    /**
     * @description action pour rajouter les numeros
     */
    const addClient = () => {
        if (!fields.selectedSuperDealer.id || !fields.numero) return;
        const marchandGot = superDealerKinDB?.find(
            (sd) => fields?.selectedSuperDealer?.id === sd?.id
        )?.marchand;
        const data = {
            id: fields.selectedSuperDealer.id,
            codeMarchand: marchandGot?.code,
            designation: marchandGot?.designation,
            numero: fields.numero
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'list',
                data
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { designation, selectedClient, selectedUser } = fields;
        const identityClient = clientsOrganisation.find(
            (item) => item?.code === selectedClient?.code
        );
        const payloadToAdd = {};
        if (type === 'new') {
            payloadToAdd.valeurTypeEntite = {
                code: formatCodeDesignation({
                    partToAdd: 'NAPP_CLIENT_SD_',
                    designation
                }),
                designation,
                nom: fields.nom,
                prenom: fields.prenom
            };
        }
        if (type === 'rattacher') {
            payloadToAdd.valeurTypeEntite = {
                code: selectedClient.code,
                designation:
                    identityClient?.valeurTypeProprietaire?.designation,
                nom: identityClient?.valeurTypeProprietaire?.nom,
                prenom: identityClient?.valeurTypeProprietaire?.prenom,
                reference: identityClient?.reference
            };
        }
        if (type === 'rattacherAgent') {
            payloadToAdd.idUser = selectedUser.id;
        }
        const payload = {
            typeBusiness: TYPE_BUSINESS.TRADITIONNEL_BUSINESS,
            creator,
            estClient: type !== 'rattacherAgent',
            ...payloadToAdd,
            list: fields.list.map((item) => ({
                codeMarchand: item.codeMarchand,
                numero: item.numero
            }))
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_LIST_NUMERO_CLIENT,
                payload,
                fields,
                onSuccess
            })
        );
    };
    const removeDuplicate = ({ tab = [] }) => {
        const listeToReturn = [];
        tab.forEach((item) => {
            const finded = listeToReturn.find(
                (element) => item.code === element.code
            );
            if (!finded) listeToReturn.push(item);
        });
        return listeToReturn;
    };

    const renderContent = () => (
        <FormWrapper
            isBackComponent={false}
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB,
                    isCondition: !!organisation?.estKinDistribution
                },
                {
                    dependency: 'client',
                    etat: etatClientsOrganisation,
                    isCondition: type === 'rattacher'
                },
                {
                    dependency: 'userAgent',
                    etat: etatUser,
                    isCondition: type === 'rattacherAgent'
                }
            ]}
        >
            <CustomRow isShouldBeRendered={type === 'rattacherAgent'}>
                <CustomDropdown
                    label="Agent*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    options={users}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner Agent"
                    name="selectedUser"
                    id="selectedUserCreationClientEmoneyId"
                    idProperty="id"
                    disabled={users?.length}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={type === 'rattacher'}>
                <CustomDropdown
                    label="Client*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    options={removeDuplicate({ tab: clientsOrganisation })}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner Client"
                    name="selectedClient"
                    id="selectedClientCreationClientEmoneyId"
                    idProperty="code"
                    disabled={
                        !removeDuplicate({ tab: clientsOrganisation })?.length
                    }
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    type !== 'rattacherAgent' && type !== 'rattacher'
                }
            >
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        labelClassName="col-12 col-md-2"
                        divClassName="col-12 col-md-10"
                        type="text"
                        label="Désignation shop*:"
                        placeholder="Saisissez la désignation du shop"
                        formDispatcher={formDispatcher}
                        name="designation"
                        id="designationCreationClient"
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        labelClassName="col-12 col-md-2"
                        divClassName="col-12 col-md-10"
                        type="text"
                        label="Prenom Client*:"
                        placeholder="Saisissez le Prénom du Client"
                        formDispatcher={formDispatcher}
                        name="prenom"
                        id="designationCreationPrenomClient"
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        labelClassName="col-12 col-md-2"
                        divClassName="col-12 col-md-10"
                        type="text"
                        label="Nom Client*:"
                        placeholder="Saisissez le Nom du Client"
                        formDispatcher={formDispatcher}
                        name="nom"
                        id="designationCreationNomClient"
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!!organisation.estKinDistribution}
            >
                <CustomRow>
                    <CustomLabel
                        text="Numero Client* : "
                        className="col-sm-2"
                    />
                    <CustomDropdown
                        options={superDealerKinDB}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Selectionner SuperDealer"
                        divClassName="col-12 col-sm-4"
                        name="selectedSuperDealer"
                        id="selectedSuperDealerCreationClientEmoneyId"
                        idProperty="id"
                        // uiValidator={uiValidator}
                    />
                    <CustomInput
                        isInputGroup={false}
                        divClassName="col-12 col-sm-4"
                        type="text"
                        placeholder="Saisissez le numéro"
                        formDispatcher={formDispatcher}
                        name="numero"
                        id="numeroCreationClient"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                    <CustomAddRemoveButton
                        className="col-sm-2"
                        callback={addClient}
                    />
                </CustomRow>
                <CustomCollapse isIn={!!fields.list.length}>
                    <SimpleTable
                        identifier="id"
                        formDispatcher={formDispatcher}
                        data={fields.list}
                        isShowDropButton
                        listSelectedItemName="list"
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 2,
                                displayName: 'Marchand',
                                field: 'designation'
                            },
                            {
                                position: 3,
                                displayName: 'Numéro',
                                field: 'numero'
                            }
                        ]}
                    />
                </CustomCollapse>
            </ConditionalRenderingWrapper>

            <ConditionalRenderingWrapper
                isShouldBeRendered={!organisation.estKinDistribution}
            >
                <CustomRow>
                    <CustomInput
                        type="tel"
                        isInputGroup={false}
                        label="Numero*:"
                        labelClassName="col-12 col-md-2"
                        divClassName="col-12 col-md-10"
                        placeholder="Saisissez le numéro"
                        formDispatcher={formDispatcher}
                        name="numero"
                        id="numeroCreationClient"
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            {/* <StateShowerComponent state={fields} /> */}
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        type="button"
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
