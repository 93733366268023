/**
 * @desc Regularisation super dealer
 */

import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Configuration } from '../../../module';
import {
    RegularisationSuperDealerCreateRelPath,
    RegularisationSuperDealerDetailRelPath,
    RegularisationSuperDealerViewLayoutRelPath,
    RegularisationSpecialeCreateRelPath,
    RegularisationSpecialeDetailRelPath,
    RegularisationSpecialeViewLayoutRelPath
} from './regularisation-rel-path';

export const RegularisationSuperDealerViewLayoutPath = {
    ...RegularisationSuperDealerViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.RegularisationSuperDealerViewLayout,
    element: <Configuration.RegularisationSuperDealerViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.REGULARISATION_STOCK
            .ATTENTE_VALIDATION_REGULARISATION_STOCK,
        CODE_SERVICE.STOCK.REGULARISATION_STOCK.ANNULER_REGULARISATION_STOCK,
        CODE_SERVICE.STOCK.REGULARISATION_STOCK.LECTURE_REGULARISATION_STOCK,
        CODE_SERVICE.STOCK.REGULARISATION_STOCK
            .INITIER_CREATION_REGULARISATION_STOCK,
        CODE_SERVICE.STOCK.REGULARISATION_STOCK.VALIDER_REGULARISATION_STOCK
    ]
};

export const RegularisationSuperDealerCreatePath = {
    ...RegularisationSuperDealerCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationRegularisationSuperDealerForm,
    element: <Configuration.CreationRegularisationSuperDealerForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.REGULARISATION_STOCK
            .INITIER_CREATION_REGULARISATION_STOCK
    ]
};

export const RegularisationSuperDealerDetailPath = {
    ...RegularisationSuperDealerDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailRegularisationSuperDealerForm,
    element: <Configuration.DetailRegularisationSuperDealerForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.REGULARISATION_STOCK
            .ATTENTE_VALIDATION_REGULARISATION_STOCK,
        CODE_SERVICE.STOCK.REGULARISATION_STOCK.ANNULER_REGULARISATION_STOCK,
        CODE_SERVICE.STOCK.REGULARISATION_STOCK.LECTURE_REGULARISATION_STOCK,
        CODE_SERVICE.STOCK.REGULARISATION_STOCK
            .INITIER_CREATION_REGULARISATION_STOCK,
        CODE_SERVICE.STOCK.REGULARISATION_STOCK.VALIDER_REGULARISATION_STOCK
    ]
};

/**
 * @desc Regularisation Speciale
 */

export const RegularisationSpecialeViewLayoutPath = {
    ...RegularisationSpecialeViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.RegularisationSpecialeViewLayout,
    element: <Configuration.RegularisationSpecialeViewLayout />,
    code: [
        { code: 'test' }
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK
        //     .ATTENTE_VALIDATION_REGULARISATION_STOCK,
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK.ANNULER_REGULARISATION_STOCK,
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK.LECTURE_REGULARISATION_STOCK,
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK
        //     .INITIER_CREATION_REGULARISATION_STOCK,
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK.VALIDER_REGULARISATION_STOCK
    ]
};

export const RegularisationSpecialeCreatePath = {
    ...RegularisationSpecialeCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationRegularisationSpecialeForm,
    element: <Configuration.CreationRegularisationSpecialeForm />,
    code: [{ code: 'test' }]
};

export const RegularisationSpecialeDetailPath = {
    ...RegularisationSpecialeDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailRegularisationSpecialeForm,
    element: <Configuration.DetailRegularisationSpecialeForm />,
    code: [
        { code: 'test' }
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK
        //     .ATTENTE_VALIDATION_REGULARISATION_STOCK,
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK.ANNULER_REGULARISATION_STOCK,
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK.LECTURE_REGULARISATION_STOCK,
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK
        //     .INITIER_CREATION_REGULARISATION_STOCK,
        // CODE_SERVICE.STOCK.REGULARISATION_STOCK.VALIDER_REGULARISATION_STOCK
    ]
};
