export const schema = [
    {
        description: 'marchand.designation',
        title: 'Marchand',
        className: 'border-bottom'
    },
    {
        description: 'nombres.ELIGIBLE.nombre',
        title: 'Paiement Eligible',
        className: 'border-bottom'
    },
    {
        description: 'nombres.ELIGIBLE.total',
        title: 'Total Eligible',
        className: 'border-bottom'
    },
    {
        description: 'nombres.NON_ELIGIBLE.nombre',
        title: 'Paiement Non Eligible',
        className: 'border-bottom'
    },
    {
        description: 'nombres.NON_ELIGIBLE.total',
        title: 'Total Non Eligible',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'Etat',
        className: 'border-bottom'
    },
    {
        description: 'fileName',
        title: 'Fichier',
        className: 'border-bottom'
    },
    {
        description: 'nombreMois',
        title: 'Nombres Mois',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'Création',
        className: 'border-bottom'
    }
];
