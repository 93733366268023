import { DB_NODE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListAffiliationAgentLivreurForm() {
    return (
        <ListGenericForm
            reduxPropertyName="demandesAffiliation"
            functionName={URL_CONST.GET_LIST_DEMANDE_AFFILIATION}
            nodeName={DB_NODE.DEMANDE_AFFILIATION}
            mapper={[
                {
                    field: 'id',
                    displayName: 'id',
                    position: 1
                },
                {
                    field: 'etat',
                    displayName: 'etat',
                    position: 5
                }
            ]}
        />
    );
}
