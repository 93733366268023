import * as yup from 'yup';

/**
 * @description : Schema imputation Stock
 */
export const ImputationStockFormSchema = yup.object().shape({
    // code: yup.string().required(),
    designation: yup.string().required(),
    selectedParent: yup.object().when('isParent', {
        is: true,
        then: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        otherwise: yup.object().shape({
            code: yup.string(),
            designation: yup.string()
        })
    }),
    selectedTypeOperation: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    isParent: yup.boolean()
});
