import { CustomDropdown, CustomInput, CustomRow } from '../../../components';

export function BooleanTypeConfig({ formState, formDispatcher, uiValidator }) {
    const renderContent = () => (
        <CustomRow>
            <CustomInput
                type="text"
                label="Clé*"
                labelClassName="col-12 col-sm-2"
                divClassName="col-12 col-sm-4"
                isInputGroup={false}
                placeholder="Valeur de la clé"
                formDispatcher={formDispatcher}
                name="key"
                id="keyId"
                formState={formState}
                uiValidator={uiValidator}
            />
            <CustomDropdown
                divClassName="col-sm-4"
                defaultOption="Choisir type Valeur"
                formDispatcher={formDispatcher}
                formState={formState}
                name="booleanValue"
                id="booleanValueId"
                options={[
                    { code: true, designation: 'True' },
                    { code: false, designation: 'False' }
                ]}
            />
        </CustomRow>
    );
    return renderContent();
}
