import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListNiveauCaisseEmoneySuperDealerForm() {
    const groupementCaisse = (data) => {
        const result = [];
        const list = data?.listReturn || [];
        // const devises = {}
        list.forEach((item) => {
            result.push({
                id: item?.valeurTypeProprietaire,
                initial: item?.soldeOuverture,
                entree: item?.entree,
                sortie: item?.sortie,
                solde: item?.soldeFermeture,
                devise: item?.detail?.devise,
                designation: `${item?.detail?.designation} ${item?.detail?.devise}`
            });
        });
        // if (result?.length) {
        //     result.push({
        //         id: 'total',
        //         designation: 'Total',
        //         initial: result.reduce((a, b) => a + b.initial, 0),
        //         entree: result.reduce((a, b) => a + b.entree, 0),
        //         sortie: result.reduce((a, b) => a + b.sortie, 0),
        //         solde: result.reduce((a, b) => a + b.solde, 0)
        //     });
        // }
        return result;
    };
    return (
        <ListGenericForm
            reduxPropertyName="reportsCaisseSuperDealer"
            functionName={URL_CONST.GET_LIST_CAISSE_ACTIVE_USER}
            nodeName={REDUX_NODE_NAME.REPORT_CAISSE_SUPER_DEALER}
            payload={{
                typeEntite: 'Journalier',
                typeProprietaire: 'SuperDealer',
                estOrganisation: true,
                estNotDate: true,
                dateDebut: TIME.utc000000(TODAY.at000000()),
                // dateDebut: TIME.utc000000(
                //     TIME.add(TODAY.at000000(), { days: -900 })
                // ),
                dateFin: TIME.utc235959(TODAY.at235959())
            }}
            isLinkToDetail={false}
            formater={groupementCaisse}
            mapper={[
                {
                    displayName: 'Caisse',
                    field: 'designation'
                },
                {
                    displayName: 'Initial',
                    field: 'initial'
                },
                {
                    displayName: 'Entrée',
                    field: 'entree'
                },
                {
                    displayName: 'Sortie',
                    field: 'sortie'
                },
                {
                    displayName: 'Solde',
                    field: 'solde'
                }
            ]}
        />
    );
}
