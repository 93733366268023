import { submitPutAction } from '@napp-inc/jnapp-hook';
import { ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailCashCollectionForm() {
    const {
        id,
        creator,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher
    } = useGetDetailForm({ nodeName: 'cashCollectionOrganisation' });

    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const actionEncours =
            etat === ETAT.ETAT_ANNULER ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        const payload = {
            etat,
            idCashCollecteur: entity?.id,
            creator
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_CASH_COLLECTEUR,
                payload,
                reduxNodeName: REDUX_NODE_NAME.CASH_COLLECTION_ORGANISATION,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_CASH_COLLECTEUR_BY_ID}
            nodeName={REDUX_NODE_NAME.CASH_COLLECTION_ORGANISATION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Cash collection organisation"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <CustomRow>
                <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                    <CustomConfirmButton
                        type="button"
                        text="Annuler"
                        className="btn-danger"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_ANNULER
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation Annulation"
                    />
                </CustomCol>
                <CustomCol className="col-3 col-md-2">
                    <CustomConfirmButton
                        type="button"
                        text="Valider"
                        className="btn-success"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_VALIDER
                        }
                    />
                </CustomCol>
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
