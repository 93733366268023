import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    URL_CONST,
    agregationMouvement,
    traitementMouvement
} from '../../../util';
import {
    MouvementCardsComponent,
    ReportCreanceSelector
} from '../../../components';
import { MainReportComponent } from '../../report/releve/generic';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isDisplayChart: true,
    selectedCaisse: {},
    selectedCompte: {},
    selectedCompteCreance: {},
    isFetching: false,
    aggregated: {},
    devise: '',
    chartTitle: '',
    yAxisTittle: ''
};

const mapper = [
    {
        field: 'libelle',
        displayName: 'libellé'
    },
    {
        field: 'soldePrecedent',
        displayName: 'solde ouverture'
    },
    {
        field: 'entree',
        displayName: 'entree'
    },
    {
        field: 'sortie',
        displayName: 'sortie'
    },
    {
        field: 'soldeActuel',
        displayName: 'solde fermeture'
    },
    {
        field: 'dateCreation',
        displayName: 'date création'
    }
];

export function ReleveCreanceForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const mouvementsFormater = (listMouvement) =>
        traitementMouvement(listMouvement).sort(
            (a, b) => b.dateCreation - a.dateCreation
        );
    const { fields, elements } = formState;
    const fetchOnSuccess = ({ element }) => {
        const agregees = agregationMouvement(element || []);
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'aggregated',
                data: agregees
            }
        });
    };
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedCaisse } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const devise = selectedCaisse?.designation?.split(' ').reverse()?.[0];
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'devise',
                data: devise
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'chartTitle',
                data: `Caisse : ${selectedCaisse?.designation || ''}`
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'yAxisTittle',
                data: `Montant (${devise || ''})`
            }
        });

        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload: {
                    valeurTypeProprietaire: selectedCaisse?.id,
                    typeProprietaire: DB_NODE.CAISSE,
                    dateDebut,
                    dateFin
                },
                functionName: URL_CONST.GET_LIST_MOUVEMENT_PLAGE,
                etat: elements.mouvements.status,
                formDispatcher,
                formater: mouvementsFormater,
                onSuccess: fetchOnSuccess,
                reduxDispatcher
            })
        );
    };
    return (
        <MainReportComponent
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={mapper}
            dataList={elements.mouvements?.value || []}
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
            topOfChart={MouvementCardsComponent}
        >
            <ReportCreanceSelector
                formState={formState}
                formDispatcher={formDispatcher}
            />
        </MainReportComponent>
    );
}
