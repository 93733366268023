import { RapportBalanceCashCollecteurForm } from './rapport-balance-cash-collecteur';

export function RapportBalanceCashCollecteurViewLayout() {
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Balance Cash Collecteur</h1>
            </div>
            <RapportBalanceCashCollecteurForm />
        </div>
    );
}
