import { FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomAddButton,
    CustomCol,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../../components';

export function RadioTypeComponent({
    formDispatcher,
    formState,
    id,
    optionRadioName = 'optionRadio',
    listOptionsRadioName = 'listOptionsRadio',
    customRadioLabelClassName = 'col-12 col-md-2',
    customRadioDivClassName = 'col-8 col-md-8',
    mapper = [
        {
            displayName: 'Option',
            field: 'value',
            position: 1
        }
    ],
    simpleTableIdentifier = 'id',
    simpleTableColClassName = 'col-12 offset-md-2 col-md-10',
    addButtonColClassName = 'col-2',
    inputPlaceholder = 'Ajouter une option',
    label = 'Options*',
    uiValidator
}) {
    const { fields } = formState;
    const addOption = () => {
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: listOptionsRadioName,
                data: {
                    id: Date.now(),
                    value: formState?.fields?.[optionRadioName]
                }
            }
        });
    };
    return (
        <>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label={label}
                    labelClassName={customRadioLabelClassName}
                    divClassName={customRadioDivClassName}
                    placeholder={inputPlaceholder}
                    formDispatcher={formDispatcher}
                    value={fields?.[optionRadioName]}
                    name={optionRadioName}
                    id={`${id}optionRadioTypeRadioComponent`}
                    uiValidator={uiValidator}
                    formState={formState}
                />
                <CustomCol className={addButtonColClassName}>
                    <CustomAddButton callback={addOption} />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className={simpleTableColClassName}>
                    <SimpleTable
                        formDispatcher={formDispatcher}
                        listSelectedItemName={listOptionsRadioName}
                        data={formState?.fields?.[listOptionsRadioName]}
                        identifier={simpleTableIdentifier}
                        mapper={mapper}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                    />
                </CustomCol>
            </CustomRow>
        </>
    );
}
