import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetDetailForm } from '../../../util';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';

export function DetailConfigurationInstructionForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'configInstruction' });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = {
            etat,
            idConfigurationInstruction: entity?.id
            // creator: 'qlxrcWKltYhDhrIG90oSyeU0eVN2'
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_CONFIGURATION_INSTRUCTION,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.CONFIG_INSTRUCTION,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_CONFIGURATION_INSTRUCTION_BY_ID}
            nodeName={REDUX_NODE_NAME.CONFIG_INSTRUCTION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Détails configuration instruction"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
                        .VALIDER_COMPTE_BANCAIRE_PARALLELE,
                    CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
                        .LECTURE_COMPTE_BANCAIRE_PARALLELE
                ]}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomRow>
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                text="Annuler"
                                type="button"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                        <CustomCol className="col-3 col-md-2">
                            <CustomButtonLoader
                                type="button"
                                className="btn-success"
                                onClick={() => updateEtat(ETAT.ETAT_ADOPTE)}
                                text="Valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ADOPTE
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                </ConditionalRenderingWrapper>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
