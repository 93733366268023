// import { CreationAchatCreditForm } from './creation-achat-credit';
import { CreationAchatCreditFormV2 } from './creation-achat-credit-v2';
import { CreationGroupeAbonneForm } from './creation-groupe-abonne';
import { CreationPolitiqueRemiseForm } from './creation-remise';

export function MarchandAbonnementSetting() {
    return (
        <div className="container-fluid">
            <h1>Création Groupe</h1>
            <CreationGroupeAbonneForm />
            <hr />
            <h1>Politique remise</h1>
            <CreationPolitiqueRemiseForm />
            {/* <h1>Politique achat crédit</h1>
            <CreationAchatCreditForm /> */}
            <h1>Politique achat crédit v2</h1>
            <CreationAchatCreditFormV2 />
        </div>
    );
}
