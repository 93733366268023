import * as yup from 'yup';

export const OctroieLigneCreditSuperDealerSchema = yup.object().shape({
    codeRevendeur: yup.string().required()
});

export const PutOctroieLigneCreditSuperDealerSchema = yup.object().shape({
    limit: yup.number().required(),
    typeReconciliation: yup.string().required(),
    date: yup.string(),
    dateFin: yup.string()
});

export const PutDetailsLigneCreditSuperDealerSchema = yup.object().shape({
    actionEncours: yup.string().required()
});
