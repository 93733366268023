import { Array } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import {
    URL_CONST,
    traitementMouvement,
    useGetReportDetail
} from '../../../util';
import { StandardReportTemplate } from '../template';

const formater = (data) =>
    Array.tri({
        data: traitementMouvement(data?.[0] || []),
        property: 'dateCreation',
        order: 'desc'
    });
export function ReleveLimitCreditAgentForm() {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {}
    });
    return (
        <>
            {/* <StateShowerComponent state={formState?.elements} /> */}
            <StandardReportTemplate
                functionName={URL_CONST.GET_LIST_MOUVEMENT_PLAGE}
                formState={formState}
                formDispatcher={formDispatcher}
                listFormater={formater}
                listTitle={`${nappUser?.personne?.prenom || ''} ${
                    nappUser?.personne?.nom || ''
                }`}
                mapper={[
                    {
                        field: 'valeurTypeOrganisation.designation',
                        displayName: 'Super Dealer'
                    },
                    {
                        field: 'typeEntite',
                        displayName: 'Créateur'
                    },
                    {
                        field: 'libelle',
                        displayName: 'Opération'
                    },
                    {
                        field: 'soldePrecedent',
                        displayName: 'Ouverture',
                        isNumber: true
                    },
                    {
                        field: 'entree',
                        displayName: 'Entrée',
                        isNumber: true
                    },
                    {
                        field: 'sortie',
                        displayName: 'Sortie',
                        isNumber: true
                    },
                    {
                        field: 'soldeActuel',
                        displayName: 'Fermeture',
                        isNumber: true
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Date'
                    }
                ]}
                defaultPayloadMapperValues={{
                    typeProprietaire: 'Compte',
                    valeurTypeProprietaire: nappUser?.idLimitCredit
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
            />
        </>
    );
}
