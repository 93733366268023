import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import { DB_NODE, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper
} from '../../../../../components';
import { URL_CONST } from '../../../../../util';

export function TransfertSim({
    idToken,
    // typeEntite,
    uiValidator,
    initialState
}) {
    const reduxDispatcher = useDispatch();
    const { users, etatUser } = useSelector((reduxState) => ({
        users: reduxState.users.map((item) => ({
            ...item,
            id: item.id,
            idEmploye: item.personne.idEmploye,
            nom: item.personne.nom,
            prenom: item.personne.prenom,
            noms: `${item.personne.nom} ${item.personne.prenom}`
        })),
        etatUser: reduxState.etat.users.etat
    }));
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.USER,
                functionName: URL_CONST.GET_LIST_USER,
                etat: etatUser
            }
        ]
    });

    /**
     * Function to launch on click event
     * @param {Object} event
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            newTransfertSim: {
                beneficiaire: {
                    uid: fields.beneficiaire.uid
                },
                quantite: fields.quantiteSim
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                fields,
                functionName: URL_CONST.POST_TRANSFERT_SIM,
                payload,
                reduxNodeName: DB_NODE.TRANSFERT_SIM,
                reduxDispatcher
            })
        );
    };
    /**
     * Function des vues à rendre
     * @returns {JSX.Element}
     */
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'users',
                    etat: etatUser
                }
            ]}
        >
            <CustomRow>
                <CustomLabel text="Destinataire*" className="col-sm-2" />
                <CustomDropdown
                    idProperty="uid"
                    propertyToDisplay="noms"
                    options={users}
                    divClassName="col-sm-10"
                    defaultOption="Sélectionner le bénéficiaire"
                    formDispatcher={formDispatcher}
                    name="beneficiaire"
                    id="beneficiaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Quantité*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    type="number"
                    placeholder="Saisir la quantité"
                    name="quantiteSim"
                    formDispatcher={formDispatcher}
                    id="idReference"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
