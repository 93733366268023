import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListCompteImputationForm() {
    return (
        <ListGenericForm
            reduxPropertyName="comptesImputation"
            functionName={URL_CONST.GET_LIST_COMPTE_IMPUTATION}
            nodeName={REDUX_NODE_NAME.COMPTE_IMPUTATION}
            mapper={[
                {
                    field: 'valeurTypeProprietaire.code',
                    displayName: 'Code',
                    position: 1
                },
                {
                    field: 'valeurTypeProprietaire.designation',
                    displayName: 'Désignation',
                    position: 2
                }
            ]}
        />
    );
}
