import {
    getBucketFileUrlAction,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    ONLY_FETCH,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { ref, getDownloadURL } from 'firebase/storage';
import { useEffect } from 'react';
import { ListGenericForm } from '../../../components';
import { instructionRef } from '../../../firebase';
import { ViewLayout } from './view-layout';

/**
 * @desc defaultFiles
 */
const defaultFiles = ['instruction'];

export function ListInstructionForm() {
    const initialState = formInitialState({
        fields: {},
        files: defaultFiles
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { files } = formState;
    // const downloadFunction = (entity) => {
    //     formDispatcher(
    //         getBucketFileUrlAction({
    //             getDownloadURL,
    //             fileRef: ref(instructionRef, entity.fileNameText),
    //             propertyName: 'instruction'
    //         })
    //     );
    // };
    const downloadFunction = (entity) => {
        formDispatcher(
            getBucketFileUrlAction({
                getDownloadURL,
                fileRef: ref(instructionRef, entity.fileNameText),
                propertyName: 'instruction'
            })
        );
    };
    const downloadFile = (props) =>
        props?.status === FORM_CONST.FILE_DOWNLOADED
            ? window.open(props.url, '_blank', null)
            : '';
    useEffect(() => {
        downloadFile(files.instruction);
        return () => {};
    }, [files.instruction]);

    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                isUrl
                isColumnClickable
                fetchType={ONLY_FETCH}
                reduxPropertyName="instructions"
                functionName={URL_CONST.GET_LIST_INSTRUCTION_INITIALS}
                nodeName={DB_NODE.INSTRUCTION}
                identifier="id"
                downloadFileFunction={downloadFunction}
                mapper={[
                    {
                        field: 'compte.typeCompteExterne',
                        displayName: 'Compte'
                    },
                    {
                        field: 'compte.numero',
                        displayName: 'Numero Compte'
                    },
                    {
                        dataExtractor: (item) =>
                            item?.fileNameText
                                ? item?.fileNameText
                                : item?.fileName,
                        displayName: 'Nom Fichier'
                    },
                    {
                        field: 'typeOperation',
                        displayName: 'Type'
                    },
                    {
                        field: 'montant',
                        displayName: 'Montant'
                    },
                    {
                        field: 'etat',
                        displayName: 'etat'
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création'
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
