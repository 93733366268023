export const schema = [
    {
        title: 'Email',
        description: 'email',
        className: 'border-bottom'
    },
    {
        title: 'Numero',
        description: 'numeroTel',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
