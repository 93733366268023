import {
    URL_CONST,
    TYPE_ENTITE_TARIF_RESSOURCE,
    TYPE_SERVICE_GENERATUER_CHARGE
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListTarifRessource() {
    const formater = (elements) =>
        elements.map((item) => {
            const ressource = item?.details?.find(
                (itemDetail) =>
                    itemDetail?.typeEntite === TYPE_ENTITE_TARIF_RESSOURCE.code
            );
            const serviceGenerateur = ressource?.serviceGenerateur;
            const typeMarchand = ressource?.typeMarchand;
            const repartition = item?.details
                ?.filter(
                    (itemDetail) =>
                        itemDetail?.typeEntite ===
                        TYPE_SERVICE_GENERATUER_CHARGE.code
                )
                .map(
                    (chg) =>
                        `${chg?.valeurTypeEntite?.designation || ''} : ${
                            chg?.taux
                        } %\t; `
                );
            return {
                ...item,
                ressource,
                repartition,
                serviceGenerateur,
                typeMarchand
            };
        });
    return (
        <ListGenericForm
            reduxPropertyName="tarifsRessource"
            payload={{ typeEntite: TYPE_ENTITE_TARIF_RESSOURCE.code }}
            functionName={URL_CONST.GET_LIST_TARIF_INITIALS}
            nodeName={REDUX_NODE_NAME.TARIF_RESSOURCE}
            formater={formater}
            mapper={[
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand'
                },
                {
                    field: 'typeMarchand.designation',
                    displayName: 'Type marchand'
                },
                {
                    field: 'serviceGenerateur.designation',
                    displayName: 'Service générateur'
                },
                {
                    field: 'ressource.valeurTypeEntite.designation',
                    displayName: 'Ressource'
                },
                {
                    field: 'ressource.taux',
                    displayName: 'Tarif (%)'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                }
            ]}
        />
    );
}
