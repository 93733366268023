export const schema = [
    {
        description: 'institutionFinanciere.designation',
        title: 'Banque',
        className: 'border-bottom'
    },
    {
        description: 'fileName',
        title: 'Fichier',
        className: 'border-bottom'
    },
    {
        description: 'nombreTransaction',
        title: 'Nombre de Transactions',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'Création'
    }
];
