export const fieldsMapper = [
    {
        position: 0,
        type: 'text',
        id: 'creationServiceFormCodeId',
        name: 'code',
        placeholder: 'Code du Service',
        label: 'Code*',
        divClassName: 'col-sm-9',
        labelClassName: 'col-sm-3',
        isInputGroup: false
    },
    {
        position: 1,
        type: 'text',
        id: 'creationServiceFormDesignationId',
        name: 'designation',
        placeholder: 'Désignation du Service',
        label: 'Désignation*',
        divClassName: 'col-sm-9',
        labelClassName: 'col-sm-3',
        isInputGroup: false
    }
];
