import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST, utc000000 } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomInputDate,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ApplicationMobileVersionObsoleteFormSchema as uiValidator } from './validation';

const defaultFields = {
    version: '',
    date: '',
    selectedApplication: {}
};
const defaultDependencies = { fields: ['application'] };
export function CreationVersionApplicationForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, applicationMobile, etatApplicationMobile } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            applicationMobile: reduxState.applicationMobile,
            etatApplicationMobile: reduxState.etat.applicationMobile.etat
        }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                nodeName: REDUX_NODE_NAME.APPLICATION_MOBILE,
                functionName: URL_CONST.GET_ALL_APPLICATION,
                etat: etatApplicationMobile
            }
        ]
    });
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const { version, date, selectedApplication } = fields;
        const payload = {
            newVersionApplication: {
                application: { code: selectedApplication?.code },
                version,
                dateObsolete: utc000000(date)
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_VERSION_APPLICATION,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.VERSION_APPLICATION,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'application',
                    etat: etatApplicationMobile
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    isSort={false}
                    options={applicationMobile}
                    // disabled={!applicationMobile?.length}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner Application"
                    label="Application* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedApplication"
                    id="creationVersionApplicationSelectedApplicationId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Version* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Version"
                    formDispatcher={formDispatcher}
                    name="version"
                    id="creationVersionApplicationVersionId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInputDate
                    id="creationVersionApplicationDatePaiementId"
                    name="date"
                    type="date"
                    label="Date* :"
                    isInputGroup={false}
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="Date"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
