import {
    TIME,
    TODAY,
    TYPE_OPERATION,
    TYPE_OPERATION_DESINGATION,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../util';
import { ReduxReportTemplate } from '../../module/report/template';
import { ConditionalRenderingWrapper } from '../container';
import { SuperDealerSelector } from '../molecules';

const formater = (data = []) => {
    const result = [];
    (data?.[0]?.listReturn || []).forEach((item) => {
        let designationA =
            item?.valeurTypeProprietaireA?.designation ||
            (item?.valeurTypeProprietaireA?.numero
                ? `${item?.valeurTypeProprietaireA?.numero || ''} ${
                      item?.valeurTypeProprietaireA?.institutionFinanciere
                          ?.designation || ''
                  }`
                : '');
        let designationB =
            item?.valeurTypeProprietaireB?.designation ||
            (item?.valeurTypeProprietaireB?.numero
                ? `${item?.valeurTypeProprietaireB?.numero || ''} ${
                      item?.valeurTypeProprietaireB?.institutionFinanciere
                          ?.designation || ''
                  }`
                : '');
        if (item?.libelle === TYPE_OPERATION.CREDITER_COMPTE_VIA_CASH) {
            designationB = 'Balance';
        } else if (
            item?.libelle === TYPE_OPERATION.CREDITER_COMPTE_VIA_COUP_POUCE
        ) {
            designationB = 'Balance dependance';
        } else if (item?.libelle === TYPE_OPERATION.LIAISON_COMPENSATION) {
            designationA = 'Compensation';
            designationB = 'Avoir partenaire';
        } else if (item?.libelle === TYPE_OPERATION.LIAISON_NIVELLEMENT) {
            designationA = 'Compensation (mère)';
            designationB = 'Compensation (fille)';
        }
        if (item?.typeEntite === TYPE_OPERATION.REMBOURSEMENT_CAUTION) {
            designationA = 'Caution (NAPP)';
        } else if (item?.typeEntite === TYPE_OPERATION.TRANSFERT_FONDS) {
            designationA = 'Balance';
        } else if (item?.typeEntite === TYPE_OPERATION.TRANSFERT_KINDB) {
            designationB = 'Balance';
        }
        result.push({
            ...item,
            montantDevise: {
                [item?.devise]: item?.montant
            },
            designationA,
            designationB,
            typeOperation:
                TYPE_OPERATION_DESINGATION?.[item?.typeEntite] ||
                item?.typeEntite
        });
    });
    return result;
};

const dynamicColumnFormater = (dynamicColumn) => {
    const result = [];
    Object.keys(dynamicColumn || {}).forEach((key) => {
        result.push({
            displayName: dynamicColumn[key],
            field: `montantDevise.${key}`,
            isTotal: true
        });
    });
    if (Object.keys(dynamicColumn || {}).length) {
        result.push(
            {
                displayName: 'Etat',
                field: 'etat'
            },
            {
                displayName: 'Création',
                field: 'dateCreation'
            },
            {
                displayName: 'Last update',
                field: 'dateDerniereModification'
            }
        );
    }
    return result;
};
const buildTotalCards = (data = []) => {
    const result = [];
    data.forEach((item) => {
        const index = result.findIndex(
            (element) => element?.devise === item?.devise
        );
        if (index === -1) {
            result.push({
                id: item?.devise,
                devise: item?.devise,
                total: item?.montant
            });
        } else {
            result[index].total += item.montant;
        }
    });
    return result;
};

export function RapportLiaisonForm({
    typeOrganisation = TYPE_ORGANISATION.SUPER_DEALER
}) {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedSuperDealer: {}
        }
    });

    return (
        <ReduxReportTemplate
            dynamicNodeName={`liaison-${typeOrganisation}-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_RAPPORT_LIAISON}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            dynamicColumnFormater={dynamicColumnFormater}
            buildTotalCards={buildTotalCards}
            bottomPagination
            mapper={[
                {
                    displayName: 'Organisation',
                    field: 'valeurTypeOrganisation.designation'
                },
                {
                    displayName: 'Source',
                    field: 'designationA'
                },
                {
                    displayName: 'Destination',
                    field: 'designationB'
                },
                {
                    displayName: 'Type Opération',
                    field: 'typeOperation'
                },
                {
                    displayName: 'Libellé',
                    field: 'libelle'
                }
            ]}
            payload={{
                typeEntite: 'Journalier',
                typeProprietaire: typeOrganisation,
                estGerant: true,
                estDevise: true
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier',
                typeProprietaire: typeOrganisation,
                estGerant: true,
                estDevise: true
            }}
            isAddTotalTr
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedSuperDealer.code',
                    property: 'codeValeurTypeProprietaire',
                    isRequired: false,
                    displayName: 'Super dealer'
                }
            ]}
        >
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    typeOrganisation === TYPE_ORGANISATION.SUPER_DEALER
                }
            >
                <SuperDealerSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    idProperty="code"
                />
            </ConditionalRenderingWrapper>
        </ReduxReportTemplate>
    );
}
