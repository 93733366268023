import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    MODE_LIVRAISON,
    BENEFICIAIRE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { CommanderEnLigneSchema as uiValidator } from './validation';

const defaultFields = {
    selectedModeLivraison: {},
    selectedArticle: {},
    searchInput: '',
    searchInputCode: '',
    beneficiaires: [],
    produits: [],
    estPublic: true,
    estExclu: false,
    selectedUser: {},
    clients: [],
    selectedTypeBeneficiaire: {},
    list: []
};

/**
 * @description list pour les constantes de différents bénéficiaires
 */

const USERS = [BENEFICIAIRE.MARCHAND, BENEFICIAIRE.USER];

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['beneficiaires'];

/**
 * Default dependencies for formState
 */
const defaultDependencies = { fields: ['produits'] };

export function CreationActiverCommanderEnligneForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, produits, etatProduit } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            produits: reduxState.produits,
            etatProduit: reduxState.etat.produits.etat
        })
    );
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    /**
     * @description récupérer les produits marchand
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PRODUIT_MARCHAND,
                nodeName: DB_NODE.PRODUIT,
                etat: etatProduit
            }
        ]
    });

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const { list } = fields;
        if (list.length < 1) return;
        const payload = {
            list,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_PARAMETRE_COMMANDE_EN_LIGNE,
                reduxNodeName: DB_NODE.PARAMETRE_COMMANDE_EN_LIGNE,
                payload,
                fields,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    /**
     * @description fonction pour fetcher les beneficiaires, les produits
     */
    const onSearchBeneficiaire = () => {
        const { status: etat } = elements.beneficiaires;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_PARTENAIRE,
                elementPropertyName: 'beneficiaires',
                etat,
                payload: {
                    typeEntite: fields.selectedTypeBeneficiaire?.code,
                    value: fields.searchInput
                },
                reduxNodeName: REDUX_NODE_NAME.BENEFICIAIRE,
                reduxDispatcher
            })
        );
    };

    // const onSearchProduit = () => {
    //     formDispatcher(
    //         fetchElement({
    //             idToken,
    //             functionName: URL_CONST.GET_LIST_PARTENAIRE,
    //             elementPropertyName: 'beneficiaires',
    //             etat,
    //             payload: {
    //                 typeEntite: fields.selectedTypeBeneficiaire?.code,
    //                 value: fields.searchInput
    //             },
    //             reduxNodeName: REDUX_NODE_NAME.BENEFICIAIRE,
    //             reduxDispatcher
    //         })
    //     );
    // };

    /**
     * @description actions pour ajouter les articles (Produits)
     */

    const addProduits = () => {
        const data = {
            id: fields.selectedArticle.code,
            code: fields.selectedArticle.code,
            designation: fields.selectedArticle.description
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'produits',
                data
            }
        });
    };
    /**
     * Cette function prend en parametre un tablea d'objet et retour
     * un tableau de valeur primite
     * @param {Array} tab
     * @returns {Array}
     */
    const transformObjectToPrimitiveArray = (tab = []) =>
        tab.map((item) => item.code);

    const onAddEntireObjectAction = () => {
        if (!fields.selectedModeLivraison.code || fields.produits.length < 1)
            return;
        const propertyToAdd = {};
        if (!fields.estPublic) {
            propertyToAdd.clients = fields.clients;
        }
        if (fields.estExclu) {
            const listProduits = transformObjectToPrimitiveArray(
                fields.produits
            );
            propertyToAdd.produits = listProduits;
        }
        const data = {
            id: fields.selectedModeLivraison.code,
            modeLivraison: fields.selectedModeLivraison.code,
            estPublic: fields.estPublic,
            estExclu: fields.estExclu,
            ...propertyToAdd
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                data,
                field: 'list'
            }
        });
    };

    /**
     * L'action qui sera déclenchée pour l'ajout des users qui bénéficient de la fonctionnalité
     * @returns
     */
    const onAddUserAction = () => {
        if (
            !fields.selectedTypeBeneficiaire.code ||
            !fields.selectedUser.reference
        )
            return;
        const data = {
            id: fields.selectedUser.reference,
            typeEntite: fields.selectedTypeBeneficiaire.code,
            reference: fields.selectedUser.reference
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'clients',
                data,
                identifier: 'reference'
            }
        });
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'produits',
                    etat: etatProduit
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={MODE_LIVRAISON.LIVRAISON_MARCHAND}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le mode de livraison"
                    label="Type*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedModeLivraison"
                    id="selectedModeLivraisonId"
                    resetOnChangeState
                    newState={{
                        ...initialState.fields
                    }}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Vue" className="col-sm-2" />
                <CustomSwitchButton
                    id="activerCommanderEnLigneSwitchId"
                    text="Visible à tous"
                    formDispatcher={formDispatcher}
                    defaultChecked={fields.estPublic}
                    name="estPublic"
                    divClassName="col-sm-8"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomCollapse isIn={!fields.estPublic}>
                <CustomRow>
                    <CustomDropdown
                        options={USERS}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner le type beneficiaire"
                        label="Type Beneficiaire*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedTypeBeneficiaire"
                        id="selectedTypeBeneficiaireId"
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomLabel text="Till Number*" className="col-sm-2" />
                    <CustomInput
                        isInputGroup={false}
                        divClassName="col-sm-8"
                        placeholder="Saisissez le till Number de l'beneficiaires..."
                        formDispatcher={formDispatcher}
                        name="searchInput"
                        id="beneficiairesId"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                    <CustomButtonLoader
                        className="col-sm-2 h-25"
                        type="button"
                        text="rechercher"
                        onClick={onSearchBeneficiaire}
                        disabled={
                            elements?.beneficiaires?.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        isActionEncours={
                            elements?.beneficiaires?.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                    />
                </CustomRow>
                {fields.beneficiaires.length > 0 ? (
                    <>
                        <CustomRow>
                            <CustomDropdown
                                options={[]}
                                formDispatcher={formDispatcher}
                                formState={formState}
                                defaultOption="Sélectionner beneficiaires"
                                label="Utilisateurs*"
                                labelClassName="col-12 col-sm-2"
                                divClassName="col-12 col-sm-8"
                                name="selectedUser"
                                id="selectedUserId"
                                uiValidator={uiValidator}
                            />
                            <CustomAddRemoveButton
                                className="col-sm-2"
                                callback={onAddUserAction}
                            />
                        </CustomRow>
                        <CustomCollapse isIn={fields.clients.length > 0}>
                            <CustomRow>
                                <SimpleTable
                                    formDispatcher={formDispatcher}
                                    data={fields.clients}
                                    listSelectedItemName="clients"
                                    identifier="id"
                                    mapper={[]} // To be completed
                                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                    filter={false}
                                    bottomPagination={false}
                                    isLinkToDetail={false}
                                />
                            </CustomRow>
                        </CustomCollapse>
                    </>
                ) : null}
            </CustomCollapse>
            <CustomRow>
                <CustomLabel text="Exclure produits" className="col-sm-2" />
                <CustomSwitchButton
                    id="exclureProduitsId"
                    text="Exclure produits"
                    formDispatcher={formDispatcher}
                    defaultChecked={fields.estPublic}
                    name="estExclu"
                    divClassName="col-sm-8"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomCollapse isIn={fields.estExclu}>
                {/* <CustomRow>
                        <CustomLabel
                            text="Code Produit*"
                            className="col-sm-2"
                        />
                        <CustomInput
                            isInputGroup={false}
                            divClassName="col-sm-8"
                            placeholder="Saisissez le code produit..."
                            formDispatcher={formDispatcher}
                            name="searchInputCode"
                            id="searchInputCodeId"
                            formState={formState}
                        // uiValidator={uiValidator}
                        />
                        <CustomButtonLoader
                            className="col-sm-2 h-25"
                            type="button"
                            text="rechercher"
                            onClick={onSearchProduit}
                            disabled={
                                elements?.beneficiaires?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            isActionEncours={
                                elements?.beneficiaires?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                        />
                    </CustomRow> */}
                {/* {
                        fields.beneficiaires.length > 0 ? (
                            <CustomRow>
                                <CustomDropdown
                                    options={produits}
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    defaultOption="Sélectionner beneficiaires"
                                    label="Utilisateurs*"
                                    labelClassName="col-12 col-sm-2"
                                    divClassName="col-12 col-sm-10"
                                    name="selectedUser"
                                    id="selectedUserId"
                                // uiValidator={uiValidator}
                                />
                            </CustomRow>
                        ) : null
                    } */}
                <CustomRow>
                    <CustomDropdown
                        options={produits}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        propertyToDisplay="description"
                        defaultOption="Sélectionner articles"
                        label="Articles concernés*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-8"
                        name="selectedArticle"
                        id="selectedArticleId"
                        uiValidator={uiValidator}
                    />
                    <CustomAddRemoveButton
                        className="col-sm-2"
                        callback={addProduits}
                    />
                </CustomRow>
                <CustomCollapse isIn={!!fields.produits.length > 0}>
                    <SimpleTable
                        listSelectedItemName="produits"
                        formDispatcher={formDispatcher}
                        identifier="id"
                        data={fields.produits}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 1,
                                field: 'code',
                                displayName: 'Code'
                            },
                            {
                                position: 2,
                                field: 'designation',
                                displayName: 'Designation'
                            }
                        ]}
                    />
                </CustomCollapse>
            </CustomCollapse>
            <CustomRow>
                <CustomButtonLoader
                    type="button"
                    className="btn-primary offset-sm-10 col-sm-2"
                    onClick={onAddEntireObjectAction}
                    text="Ajouter..."
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
