import { DetailWrapper } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema, sdMapper } from './detail-fields';

export function DetailConfigurationSenderForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        isNapp
    } = useGetDetailForm({ nodeName: 'zoneAgentsenders' });

    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_ZONE_SENDEUR_BY_ID}
            nodeName={REDUX_NODE_NAME.ZONE_AGENT_SENDERS}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Configuration sender zone"
            entity={entity}
            id={id}
            schema={isNapp ? schema : sdMapper}
            idToken={idToken}
        />
    );
    return renderContent();
}
