import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { AiOutlineFileSearch } from 'react-icons/ai';
// import { CustomCenteredModal, ShowNewComponent } from '../../../components';
import { CustomCenteredModal, UserServiceWrapper } from '../../../components';
// import {
//     CrediterCompteCreateRelPath,
//     CrediterCompteViewLayoutRelPath
// } from '../../../routes/registration/finance/finance-rel-path';
import { ListCrediterCompteForm } from './list-crediter-compte';
import { RechercheCrediterCompteByNumeroTransactionForm } from './recherche-by-num-transaction';

export function CrediterCompteViewLayout() {
    const [show, setShow] = useState(false);
    const onHide = () => setShow(false);
    const onShow = () => setShow(true);
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Crediter compte</h1>
                <div className="btn-toolbar mb-2 mb-md-0" />
                <span
                    role="button"
                    tabIndex={0}
                    title="Vérifier reférence"
                    onClick={onShow}
                    onKeyDown={onShow}
                    className="btn btn-outline-dark"
                >
                    Recherche <AiOutlineFileSearch />
                </span>
            </div>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.FINANCE_ORGANISATION.CREDITER_REVENDEUR
                ]}
            >
                <CustomCenteredModal
                    show={show}
                    onHide={onHide}
                    header="Rechercher Transaction"
                    // size={modalSize}
                    // backdrop={backdrop}
                >
                    <RechercheCrediterCompteByNumeroTransactionForm />
                </CustomCenteredModal>
            </UserServiceWrapper>
            <ListCrediterCompteForm />
        </div>
    );
}
