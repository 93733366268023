import { ADMINISTRATION_CONST } from '@napp-inc/jnapp-util';

export const schema = [
    {
        description: 'valeurTypeOrganisation.designation',
        title: 'Organisation'
    },
    {
        description: 'compteA.type',
        dataExtractor: (item) =>
            item?.compteA?.type === ADMINISTRATION_CONST.NAPP
                ? 'Balance'
                : item?.compteA?.type,
        title: 'Source'
    },
    {
        description: 'compteB.type',
        dataExtractor: (item) =>
            item?.compteB?.type === ADMINISTRATION_CONST.NAPP
                ? 'Balance'
                : item?.compteA?.type,
        title: 'Bénéficiaire'
    },
    {
        title: 'Montant',
        description: 'montant'
    },
    {
        title: 'Devise',
        description: 'devise'
    },
    {
        title: 'Etat',
        description: 'etat'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
