import { fetchElement } from '@napp-inc/jnapp-hook';
import { FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomButtonLoader,
    DetailWrapper,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailUploadFichierReleveEmoneyForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'relevesEmoney',
            defaultElements: ['retrait']
        });
    const { elements } = formState;
    const onFetchDetailRetraitById = () => {
        if (!id) return;
        const { status: etat } = elements.retrait;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_RETRAIT_BY_RELEVE,
                elementPropertyName: 'retrait',
                etat,
                payload: {
                    idTraitementReleveEmoney: id
                },
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TRAITEMENT_RELEVE_EMONEY_BY_ID}
            nodeName={REDUX_NODE_NAME.UPLOAD_RELEVE_EMONEY}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Releve Emoney"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <div>
                <CustomButtonLoader
                    type="button"
                    className="btn-success mt-2"
                    onClick={onFetchDetailRetraitById}
                    text="Voir Détails"
                    disabled={
                        elements?.retrait?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
                <SimpleTable
                    tableClassName=""
                    isShowDropButton={false}
                    data={elements?.retrait?.value || []}
                    identifier="id"
                    mapper={[
                        {
                            displayName: 'Client',
                            dataExtractor: (item) =>
                                `${item?.client?.designation || ''} ${
                                    item?.client?.numero || ''
                                }`
                        },
                        {
                            field: 'montant',
                            displayName: 'montant'
                        },
                        {
                            field: 'devise',
                            displayName: 'Devise'
                        },
                        {
                            field: 'etat',
                            displayName: 'Etat'
                        },
                        {
                            field: 'dateCreation',
                            displayName: 'date création'
                        }
                    ]}
                    filter={false}
                    isLinkToDetail={false}
                />
            </div>
        </DetailWrapper>
    );
    return renderContent();
}
