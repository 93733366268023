import * as yup from 'yup';

/**
 * @description : Schema de validation Relevé création form
 */
export const ReleveBancaireSchema = yup.object().shape({
    selectedBanque: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    file: yup
        .object()
        .shape({
            lastModified: yup.number(),
            name: yup.string().required(),
            webkitRelativePath: yup.string(),
            size: yup.number(),
            type: yup.string()
        })
        .nullable()
});
