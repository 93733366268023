import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    Add,
    DB_NODE,
    Div,
    FILTER_OPERATOR,
    FILTER_TYPE,
    FORM_CONST,
    Mul,
    TODAY,
    formInitialState,
    percuAndRetournedMontantDevise
} from '@napp-inc/jnapp-util';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
    // StateShowerComponent
} from '../../../components';
import {
    URL_CONST,
    filterClientBySuperDealerMarchand,
    useGetCurrentGeoLocation
} from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { DynamicMonoSelectAndInputSecond } from '../../../components/special-components/dynamic-mono-select-and-input-second';

const defaultFields = {
    previewValue: '',
    montant: 0,
    selectedClient: {},
    selectedSuperDealer: {},
    selectedDevise: {},
    estRecouvert: false,
    deviseRetourSelect: {},
    deviseRetourInput: 0,
    devisePercuSelect: {},
    devisePercuInput: 0,
    montantPercu: [],
    montantRetour: []
};

const defaultDependencies = { fields: ['superDealer', 'client', 'devise'] };

const objectKeysPercu = {
    devise: 'devisePercuSelect.code',
    montant: 'devisePercuInput'
};

const objectKeysRetour = {
    devise: 'deviseRetourSelect.code',
    montant: 'deviseRetourInput'
};

const PERTE_CHANGE = 'PerteChange';

export function CreationDepotEmoneyForm() {
    const reduxDispatcher = useDispatch();

    const {
        firebaseUser,
        nappUser,
        organisation,
        superDealerKinDB,
        etatSuperDealerKinDB,
        clientsOrganisation,
        etatClientsOrganisation,
        monnaiesLocale,
        etatMonnaiesLocale,
        deviseReference,
        taux,
        etatTaux,
        allCoupures
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation,
        superDealerKinDB: reduxState.superDealerKinDB
            .filter((item) => item.estEmoney)
            .map((item) => ({
                ...item,
                code: item.entreprise.code,
                designation: item.entreprise.designation
            })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat,
        clientsOrganisation: reduxState.clientsOrganisation.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeProprietaire?.designation || ''}-${
                item?.numero || ''
            }`
        })),
        etatClientsOrganisation: reduxState.etat.clientsOrganisation.etat,
        monnaiesLocale: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            designation: item.devise,
            code: item.devise
        })),
        etatMonnaiesLocale: reduxState.etat.monnaiesLocale.etat,
        deviseReference: reduxState.deviseReference,
        taux: reduxState.taux,
        etatTaux: reduxState.etat.taux.etat,
        allCoupures: reduxState.allCoupures
    }));
    const { idToken, creator, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: ['devises']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { coords, localisationError } = useGetCurrentGeoLocation({
        formDispatcher
    });
    const { fields, form } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { estAll: true, typeEntite }
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_NUMERO_CLIENT,
                nodeName: REDUX_NODE_NAME.CLIENT_ORGANISATION,
                etat: etatClientsOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaiesLocale
            },
            {
                isFetchListening: true,
                functionName: URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION,
                nodeName: DB_NODE.TAUX,
                etat: etatTaux,
                path: DB_NODE.TAUX,
                listenerFilters: [
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'typeEntite',
                        value: nappUser?.typeEntite,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'valeurTypeEntite.code',
                        value: organisation?.entreprise?.code,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'estActif',
                        value: true,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.ORDER_BY,
                        property: 'dateDerniereModification'
                    },
                    {
                        type: FILTER_TYPE.START_AT,
                        value: TODAY.nowUtc()
                    }
                ]
            }
        ]
    });

    /**
     * Ordering Monnaie By Perte Change DESC or Gain Change ASC
     */
    const orderDevise = useCallback(({ monnaies, constat, typeRemise }) => {
        if (!monnaies || !constat) return [];
        const types = monnaies.length
            ? Object.keys(monnaies?.find((item) => 'natures' in item)?.natures)
            : [];
        const deviseOrdered = [];
        types.forEach((type) => {
            monnaies.forEach((monnaie) => {
                if ('natures' in monnaie && type === typeRemise) {
                    if (monnaie?.natures?.[type]?.constant === constat)
                        deviseOrdered.push({
                            devise: monnaie?.devise,
                            ...monnaie?.natures?.[type],
                            type
                        });
                }
            });
        });
        return deviseOrdered.sort((a, b) =>
            constat === PERTE_CHANGE
                ? b.difference - a.difference
                : a.difference - b.difference
        );
    }, []);

    const calculRemise = useCallback(() => {
        const tabRetour = percuAndRetournedMontantDevise(
            fields.montantPercu,
            fields.montantRetour
        );
        const valeurAttendue = [];
        const percuTab = tabRetour.filter((item) => item.estPercu);
        percuTab.forEach((percuObject) => {
            const type =
                percuObject.devise === deviseReference.code ? 'Achat' : 'Vente';
            const orderedByPerteChange = orderDevise({
                monnaies: monnaiesLocale,
                constat: PERTE_CHANGE,
                typeRemise: type
            });
            if (
                percuObject.devise === deviseReference.code &&
                !!orderedByPerteChange.length
            ) {
                const coupure = allCoupures
                    ?.find((coupures) => coupures.code === percuObject.devise)
                    ?.coupures?.sort((a, b) => a.valeur - b.valeur)?.[0];
                const [target] = orderedByPerteChange;
                const total = Math.floor(
                    Div(
                        Mul(
                            target?.differenceDevise || 0,
                            percuObject.montant || 0
                        ),
                        coupure.valeur || 1
                    )
                );
                valeurAttendue.push({
                    total,
                    devise: percuObject.devise,
                    monnaieCible: target?.devise,
                    montant: total,
                    estEntree: false
                });
            }
            if (
                percuObject.devise !== deviseReference.code &&
                !!orderedByPerteChange.length
            ) {
                const coupure = allCoupures
                    ?.find((coupures) => coupures.code === deviseReference.code)
                    ?.coupures?.sort((a, b) => a.valeur - b.valeur)?.[0];
                const [target] = orderedByPerteChange.filter(
                    (item) => item.devise === percuObject.devise
                );
                if (target) {
                    const total = Math.floor(
                        Div(
                            Mul(
                                target?.differenceDevise || 0,
                                percuObject.montant || 0
                            ),
                            coupure.valeur || 1
                        )
                    );
                    valeurAttendue.push({
                        total,
                        devise: percuObject.devise,
                        monnaieCible: deviseReference.code,
                        montant: total,
                        estEntree: false
                    });
                }
            }
        });
        return valeurAttendue.filter((item) => !!item.total);
    }, [
        allCoupures,
        deviseReference.code,
        fields.montantPercu,
        fields.montantRetour,
        monnaiesLocale,
        orderDevise
    ]);

    const checkTotalPercuAndMontantWithoutRemise = useCallback(() => {
        let isValid = false;
        let total = 0;
        let tauxMonnaieLocal = 1;
        // let totalWithRemise = 0;
        const tabRetour = percuAndRetournedMontantDevise(
            fields.montantPercu,
            fields.montantRetour
        );
        tabRetour.forEach((item) => {
            if (item?.devise === deviseReference?.code) {
                total = item?.estPercu
                    ? total + (item?.montant || 0)
                    : total - (item?.montant || 0);
            } else {
                tauxMonnaieLocal = taux.find(
                    (tx) =>
                        tx.devise === item?.devise && tx.type === 'TauxSystem'
                )?.valeur;
                total = item?.estPercu
                    ? total + Div(item?.montant, tauxMonnaieLocal)
                    : total - Div(item?.montant, tauxMonnaieLocal);
            }
        });
        const equivalent =
            fields?.selectedDevise?.devise === deviseReference?.code
                ? fields?.montant
                : Div(fields?.montant, tauxMonnaieLocal);
        isValid = total === equivalent;
        return isValid;
    }, [
        deviseReference?.code,
        fields?.montant,
        fields.montantPercu,
        fields.montantRetour,
        fields?.selectedDevise?.devise,
        taux
    ]);

    function sommeDesMontantsParDevise(tableaux) {
        const sommesParDevise = new Map();
        // Parcourir les tableaux
        // eslint-disable-next-line no-restricted-syntax
        for (const tableau of tableaux) {
            const { devise } = tableau;

            if (sommesParDevise.has(devise)) {
                sommesParDevise.set(
                    devise,
                    Add(sommesParDevise.get(devise), tableau.montant)
                );
            } else {
                sommesParDevise.set(devise, tableau.montant);
            }
        }
        const resultats = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const [devise, montant] of sommesParDevise) {
            const resultat = {
                devise,
                montant,
                estEntree: false
            };
            resultats.push(resultat);
        }
        return resultats;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            !fields.selectedClient.id ||
            !fields.selectedDevise.devise ||
            !fields.selectedSuperDealer.code ||
            !coords.lat ||
            !coords.long
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Veuillez revoir les informations renseignées',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const propertyToAdd = {};
        const montantRetourFromCalculRemise = calculRemise();
        const isBool = checkTotalPercuAndMontantWithoutRemise();
        if (!isBool) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Montant renseigné différent du total',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const newTabSommesMontantParDevise = sommeDesMontantsParDevise(
            fields.montantRetour.concat(
                montantRetourFromCalculRemise.map((item) => ({
                    devise: item.monnaieCible,
                    montant: item.montant,
                    estEntree: item.estEntree
                }))
            )
        );
        if (!fields.estRecouvert) {
            propertyToAdd.montantsDevise = percuAndRetournedMontantDevise(
                fields.montantPercu,
                newTabSommesMontantParDevise
            );
        }
        const marchand = superDealerKinDB.find(
            (item) => item.code === fields.selectedSuperDealer.code
        )?.marchand;
        if (!marchand.code) return;
        if (!fields.estRecouvert && !propertyToAdd?.montantsDevise?.length)
            return;
        const payload = {
            ...propertyToAdd,
            newCommandeEmoney: {
                marchand: {
                    code: marchand?.code
                },
                montant: fields.montant,
                client: {
                    idCompte: fields.selectedClient.id
                },
                devise: fields.selectedDevise.devise,
                estRetrait: false,
                estRecouvert: fields.estRecouvert,
                estCoupPouce: false
            },
            point: { ...coords },
            creator,
            typeBusiness: 'TraditionnelBusiness',
            codeSuperDealer: fields.selectedSuperDealer.code
        };
        // console.log(payload);
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_COMMANDE_EMONEY,
                reduxNodeName: REDUX_NODE_NAME.DEPOT_EMONEY,
                payload,
                fields,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB
                },
                {
                    dependency: 'client',
                    etat: etatClientsOrganisation
                },
                {
                    dependency: 'devise',
                    etat: etatMonnaiesLocale
                }
            ]}
        >
            {/* <StateShowerComponent state={monnaiesLocale} /> */}
            <CustomRow>
                <CustomDropdown
                    label="SuperDealer*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    options={superDealerKinDB}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner Super Dealer"
                    name="selectedSuperDealer"
                    id="selectedSuperDealerCreationClientEmoneyId"
                    disabled={!superDealerKinDB?.length}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    label="Client*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    options={filterClientBySuperDealerMarchand({
                        superDealers: superDealerKinDB,
                        superDealer: fields.selectedSuperDealer,
                        clients: clientsOrganisation
                    })}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner Client"
                    name="selectedClient"
                    id="selectedClientCreationClientEmoneyId"
                    idProperty="id"
                    disabled={
                        !filterClientBySuperDealerMarchand({
                            superDealers: superDealerKinDB,
                            superDealer: fields.selectedSuperDealer,
                            clients: clientsOrganisation
                        })?.length
                    }
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    label="Devise*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    options={monnaiesLocale}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner Devise"
                    name="selectedDevise"
                    id="selectedDeviseCreationClientEmoneyId"
                    propertyToDisplay="devise"
                    disabled={!monnaiesLocale?.length}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    label="Montant*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    type="number"
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="numeroCreationClient"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomSwitchButton
                id="estRecouvertCreationCommandeEmoney"
                text="Mode recouvrement"
                inputTitle="Mode recouvrement"
                formDispatcher={formDispatcher}
                value={fields.estRecouvert}
                name="estRecouvert"
                divClassName="col-md-8"
                formState={formState}
                // uiValidator={uiValidator}
            />
            {fields.estRecouvert ? null : (
                <CustomRow>
                    <CustomCol>
                        <div className="shadow card">
                            <div className="card-header">
                                <p className="fw-normal mb-0">
                                    Cash à encaisser
                                </p>
                            </div>
                            <div className="card-body">
                                <CustomRow>
                                    <DynamicMonoSelectAndInputSecond
                                        options={monnaiesLocale}
                                        id="deviseMontantPercu"
                                        name="devisePercu"
                                        defaultOption="Selectionner devise"
                                        formState={formState}
                                        formDispatcher={formDispatcher}
                                        type="number"
                                        identifier="devise"
                                        placeholder="montant"
                                        field="montantPercu"
                                        objectKeys={objectKeysPercu}
                                        // uiValidator={uiValidator}
                                    />
                                </CustomRow>
                            </div>
                        </div>
                    </CustomCol>
                    <CustomCol>
                        <div className="shadow card">
                            <div className="card-header">
                                <p className="fw-normal mb-0">
                                    Cash à retourner
                                </p>
                            </div>
                            <div className="card-body">
                                <CustomRow>
                                    <DynamicMonoSelectAndInputSecond
                                        options={monnaiesLocale}
                                        id="deviseMontantRetour"
                                        name="deviseRetour"
                                        defaultOption="Selectionner devise"
                                        formState={formState}
                                        formDispatcher={formDispatcher}
                                        type="number"
                                        identifier="devise"
                                        placeholder="montant"
                                        field="montantRetour"
                                        objectKeys={objectKeysRetour}
                                        // uiValidator={uiValidator}
                                    />
                                </CustomRow>
                                {calculRemise()?.map((item) => (
                                    <CustomAlert
                                        key={item.monnaieCible}
                                        successMessage={`Remise  : ${item.total} ${item.monnaieCible}`}
                                    />
                                ))}
                            </div>
                        </div>
                    </CustomCol>
                </CustomRow>
            )}

            {/* <StateShowerComponent state={fields} /> */}
            {localisationError ? null : (
                <CustomRow className="row">
                    <CustomCol className="col-3">
                        <CustomButtonLoader
                            type="button"
                            className="btn-success"
                            onClick={handleSubmit}
                            text="Sauvegarder"
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                        />
                    </CustomCol>
                </CustomRow>
            )}
        </FormWrapper>
    );

    return renderContent();
}
