import * as yup from 'yup';

export const CreationArticleSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required(),
    name: yup.string().required(),
    urlVideo: yup.string().url(),
    rayon: yup
        .object()
        .shape({
            code: yup.string().required(),
            designation: yup.string().required()
        })
        .required(),
    type: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
