import { URL_CONST, TYPE_ENTITE_TARIF_CHARGE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListTarifCharge() {
    return (
        <ListGenericForm
            reduxPropertyName="tarifsCharge"
            functionName={URL_CONST.GET_LIST_TARIF_INITIALS}
            nodeName={REDUX_NODE_NAME.TARIF_CHARGE}
            payload={{ typeEntite: TYPE_ENTITE_TARIF_CHARGE.code }}
            mapper={[
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand',
                    position: 1
                },
                {
                    field: 'dateCreation',
                    displayName: 'Date création',
                    position: 2
                },
                {
                    field: 'etat',
                    displayName: 'Etat',
                    position: 3
                }
            ]}
        />
    );
}
