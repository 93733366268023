import { GENERIC_ACTIONS_TYPE, ONLY_FETCH } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';

/**
 * Composant générique pour les sélecteurs
 * @param {Object} param0 Object avecs les paramètres suivants :
 * @param {Object} param0.formDispatcher Dispatcher du formulaire
 * @param {Object} param0.formState Etat du formulaire
 * @param {String} param0.label Label
 * @param {String} param0.propertyName Nom de la propriété
 * @param {String} param0.labelClassName Classe du label
 * @param {String} param0.divClassName Classe de la div
 * @param {String} param0.propertyToDisplay Propriété à afficher
 * @param {String} param0.idProperty Propriété id
 * @param {Object} param0.payload Payload à envoyer
 * @param {String} param0.nodeName Nom du noeud
 * @param {String} param0.functionName Nom de la fonction à appeler
 * @param {String} param0.reduxPropertyName Nom de la propriété redux
 * @param {Function} param0.listFormater Formateur de la liste
 * @param {Function} param0.formater Formateur des données qui sont fetchées
 * @param {Boolean} param0.isDynamicNode Est-ce un noeud dynamique
 * @param {String} param0.dynamicNodeName Nom du noeud dynamique
 * @param {Function} param0.filterDataList Filtre de la liste dans redux
 * @param {String} param0.fetchType Type de fetch
 * @param {String} param0.path Chemin
 * @param {Object} param0.listenerFilters Filtres du listener
 * @param {Function} param0.onSuccess Callback de succès
 * @param {Function} param0.reduxValidator Validateur redux
 * @returns {React.Component}
 */
export function GenericSelector({
    formDispatcher,
    formState,
    label = '',
    propertyName = 'selectedGeneric',
    labelClassName = '',
    divClassName = 'col-12',
    propertyToDisplay = 'designation',
    idProperty = 'id',
    payload = {},
    nodeName,
    functionName,
    reduxPropertyName,
    listFormater,
    formater,
    isDynamicNode,
    dynamicNodeName,
    filterDataList,
    fetchType = ONLY_FETCH,
    path,
    listenerFilters,
    onSuccess,
    reduxValidator,
    isAddAditionalOptions = false,
    additionnalOptions = [{ id: 'all', code: 'all', designation: 'Tous' }]
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, dataList, etatRedux } = useSelector((reduxState) => {
        let reduxData;
        let etatExtrated;
        if (isDynamicNode) {
            reduxData = reduxState.dynamicNode?.[dynamicNodeName];
            etatExtrated = reduxState?.dynamicEtatNode?.[dynamicNodeName];
        } else {
            reduxData = reduxState[reduxPropertyName];
            etatExtrated = reduxState?.etat[reduxPropertyName];
        }
        if (
            reduxData?.length &&
            listFormater &&
            typeof listFormater === 'function'
        ) {
            return {
                dataList: filterDataList
                    ? listFormater(reduxData).filter(filterDataList)
                    : listFormater(reduxData),
                etatRedux: etatExtrated,
                firebaseUser: reduxState.firebaseUser
            };
        }
        return {
            dataList: filterDataList
                ? reduxData.filter(filterDataList)
                : reduxData,
            etatRedux: etatExtrated,
            firebaseUser: reduxState.firebaseUser
        };
    });
    const { etat: etatDataList, ecouteur: ecouteurDataList } = etatRedux || {};
    const { idToken } = useGetUserDetail({ firebaseUser });
    const propertyToAddToList = {};
    if (!isDynamicNode) {
        propertyToAddToList.nodeName = nodeName;
    }
    const list = [
        {
            ...propertyToAddToList,
            fetchType,
            functionName,
            etat: etatDataList,
            ecouteur: ecouteurDataList,
            path,
            isDynamicNode,
            dynamicNodeName,
            listenerFilters,
            payload,
            formater,
            onSuccess,
            reduxValidator,
            isSet: true
        }
    ];
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list
    });
    const renderContent = () => {
        if (etatDataList === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS) {
            return (
                <CustomDropdown
                    // defaultOption={defaultOption}
                    options={dataList}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    label={label}
                    labelClassName={labelClassName}
                    divClassName={divClassName}
                    name={propertyName}
                    id={`${propertyName}genericSelector`}
                    propertyToDisplay={propertyToDisplay}
                    idProperty={idProperty}
                    additionnalOptions={
                        isAddAditionalOptions ? additionnalOptions : []
                    }
                />
            );
        }
        if (etatDataList === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
