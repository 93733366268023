import { CustomDropdown } from '../dropdown';
import { CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner un type airtime ou emoney
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function TypeSuperDealerSelector({
    formDispatcher,
    formState,
    idProperty = 'id',
    name = 'selectedTypeSuperDealer',
    isAddAditionalOptions = false,
    additionnalOptions = [{ id: 'all', code: 'all', designation: 'Tous' }]
}) {
    return (
        <CustomRow>
            <CustomDropdown
                options={[
                    { code: 'Airtime', designation: 'Airtime' },
                    { code: 'Emoney', designation: 'Emoney' }
                ]}
                defaultOption="Selectionner un type"
                label="Type : "
                labelClassName="col-12"
                divClassName="col-12"
                formDispatcher={formDispatcher}
                name={name}
                id={`${name}-typeSuperDealerSelector`}
                formState={formState}
                idProperty={idProperty}
                additionnalOptions={
                    isAddAditionalOptions ? additionnalOptions : []
                }
            />
        </CustomRow>
    );
}
