import * as yup from 'yup';

/**
 * @description : Schema type Stock
 */
export const TypeStockFormSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required(),
    selectedCategorieMarchand: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
