import { DB_NODE, ONLY_FETCH } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { ViewLayout } from './view-layout';

export function ListDispatchingPaiementExecutionChargeForm() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                fetchType={ONLY_FETCH}
                reduxPropertyName="dispatchingPaiement"
                functionName={
                    URL_CONST.GET_LIST_DISPATCHING_CAISSE_EXECUTION_CHARGE_INITIALS
                }
                nodeName={DB_NODE.MOYEN_PAIEMENT_DATE}
                identifier="id"
                mapper={[
                    {
                        field: 'typeEntite',
                        displayName: 'Type Caisse',
                        position: 1
                    },
                    {
                        field: 'valeurTypeEntite',
                        displayName: 'Caisse Sectorielle',
                        position: 2
                    },
                    {
                        field: 'montant',
                        displayName: 'Montant',
                        position: 3
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 4
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 5
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
