import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    CommandeFlashCreateRelPath,
    CommandeFlashViewLayoutRelPath
} from '../../../routes/registration/vente/vente-rel-path';
import { ListCommandeFlashForm } from './list-commande-flash';

export function CommandeFlashViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Vente flash</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${CommandeFlashViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.VENTE_ORGANISATION
                                        .INITIER_COMMANDE_FLASH
                                ]}
                                destination={CommandeFlashCreateRelPath.path}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListCommandeFlashForm />
        </div>
    );
}
