import { FORM_CONST } from '@napp-inc/jnapp-util';
import { CustomRow, CustomCol } from '../container';

/**
 * Horaires d'un jour
 * @param {Object} param0 object qui contient les props du composant:
 * @param {String} param0.designation designation du composant
 * @param {Function} param0.formDispatcher fonction qui dispatch les actions du formulaire
 * @param {String} param0.switchOuvertName id et htmlFor du switch
 * @param {Boolean} param0.switchOuvertValue valeur de l'état du switch
 * @param {String} param0.switchH24Name id et htmlFor du switch H24
 * @param {Boolean} param0.switchH24Value valeur de l'état du switch H24
 * @param {Date} param0.heureDebutName nom de la propriété name de l'heure de début
 * @param {Date} param0.heureFinName name de la propriété name de l'heure de fin
 * @returns {React.Component}
 */
export function HoraireJourComponent({
    formDispatcher,
    designation,
    switchOuvertName,
    switchOuvertValue,
    switchH24Name,
    switchH24Value,
    heureDebutName,
    heureFinName
}) {
    return (
        <CustomRow>
            <CustomCol className="col-md-2">{designation}</CustomCol>
            <CustomCol className="col-md-2 form-check form-switch">
                <label className="form-check-label" htmlFor={switchOuvertName}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={switchOuvertName}
                        name={switchOuvertName}
                        onChange={(e) =>
                            formDispatcher({
                                type: FORM_CONST.FIELD_CHANGE,
                                payload: {
                                    field: switchOuvertName,
                                    data: e.target.checked
                                }
                            })
                        }
                    />
                    Ouvert
                </label>
            </CustomCol>
            {switchOuvertValue && (
                <CustomCol className="col-md-2 form-check form-switch">
                    <label className="form-check-label" htmlFor={switchH24Name}>
                        <input
                            defaultChecked
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={switchH24Name}
                            name={switchH24Name}
                            onChange={(e) =>
                                formDispatcher({
                                    type: FORM_CONST.FIELD_CHANGE,
                                    payload: {
                                        field: switchH24Name,
                                        data: e.target.checked
                                    }
                                })
                            }
                        />
                        24h/24
                    </label>
                </CustomCol>
            )}
            {switchOuvertValue && !switchH24Value && (
                <>
                    <CustomCol className="col-md-2">
                        <input
                            className="form-control"
                            type="time"
                            name={heureDebutName}
                            onChange={(e) =>
                                formDispatcher({
                                    type: FORM_CONST.FIELD_CHANGE,
                                    payload: {
                                        field: heureDebutName,
                                        data: e.target.value
                                    }
                                })
                            }
                        />
                    </CustomCol>
                    <CustomCol className="col-md-2">
                        <input
                            className="form-control"
                            type="time"
                            name={heureFinName}
                            onChange={(e) =>
                                formDispatcher({
                                    type: FORM_CONST.FIELD_CHANGE,
                                    payload: {
                                        field: heureFinName,
                                        data: e.target.value
                                    }
                                })
                            }
                        />
                    </CustomCol>
                </>
            )}
        </CustomRow>
    );
}
