import { useSelector } from 'react-redux';
import {
    TIME,
    TODAY,
    TYPE_ORGANISATION,
    floatParser
} from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import { ReduxReportTemplate } from '../../template';
import { CashCollecteurSelector } from '../../../../components';

const formater = (data = []) => {
    const result = [];
    (data?.[0]?.listReturn || []).forEach((item) => {
        const existing = result.find(
            (elm) =>
                elm?.superDealer?.code ===
                item?.detail?.valeurTypeProprietaire?.code
        );
        if (existing) {
            if (
                Object.prototype.hasOwnProperty.call(
                    existing?.montant,
                    item?.detail?.devise
                )
            ) {
                existing.montant[item?.detail?.devise] += floatParser(
                    item?.soldeFermeture || 0
                );
            } else {
                existing.montant[item?.detail?.devise] = floatParser(
                    item?.soldeFermeture || 0
                );
            }
        } else {
            result.push({
                id: item?.detail?.valeurTypeProprietaire?.code,
                superDealer: {
                    code: item?.detail?.valeurTypeProprietaire?.code,
                    designation:
                        item?.detail?.valeurTypeProprietaire?.designation
                },
                montant: {
                    [item?.detail?.devise]: floatParser(
                        item?.soldeFermeture || 0
                    )
                }
            });
        }
    });
    return result;
};
const dynamicColumnFormater = (dynamicColumn) =>
    Object.keys(dynamicColumn || {}).map((key) => ({
        displayName: key,
        field: `montant.${key}`,
        isTotal: true
    }));

export function RapportBalanceCashCollecteurForm() {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCashCollecteur: {}
        }
    });
    return (
        <ReduxReportTemplate
            dynamicNodeName={`allBalanceOrganisationcashCollecteur-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_COMPTE_USER}
            nodeName={REDUX_NODE_NAME.ALL_BALANCE_ORGANISATION}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            dynamicColumnFormater={dynamicColumnFormater}
            mapper={[
                {
                    displayName: 'Super dealer',
                    field: 'superDealer.designation'
                }
            ]}
            isAddTotalTr
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                typeEntite: 'Journalier',
                typesEntite: [TYPE_ORGANISATION.NAPP],
                estOrganisation: true,
                typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR,
                estDevise: true,
                typeOrganisation: nappUser?.typeEntite
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier',
                estOrganisation: true,
                typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR,
                typesEntite: [TYPE_ORGANISATION.NAPP],
                estDevise: true,
                typeOrganisation: nappUser?.typeEntite
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedCashCollecteur',
                    isRequired: false,
                    displayName: 'Cash collecteur',
                    callback: (value) => {
                        if (!value?.code || value?.code === 'all') return {};
                        return { codeValeurTypeProprietaire: value?.code };
                    }
                }
            ]}
        >
            <CashCollecteurSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                isAddAditionalOptions
            />
        </ReduxReportTemplate>
    );
}
