import { useCallback } from 'react';
import {
    // fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    BENEFICIAIRE,
    CLASSE_CHARGE_FIXE,
    formInitialState,
    FORM_CONST,
    fromStringToTimestamp,
    MOYEN_PAIEMENT,
    DB_NODE
} from '@napp-inc/jnapp-util';
import { getMonthByDate, utc000000 } from '@napp-inc/jnapp-util/src/time';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomAddRemoveButton,
    // CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomInputDate,
    CustomLabel,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    // InputSearchGroup,
    SimpleTable
    // StateShowerComponent
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST } from '../../../../util';
// import { ReevaluationChargeFixeSchema as uiValidator } from './validation';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedMonth: {},
    selectedDirection: {},
    selectedSecteur: {},
    selectedCharge: {},
    selectedCaisseSectorielle: {},
    prixCharge: 0,
    selectedBeneficiaire: {},
    selectedTypeBeneficiaire: {},
    datePaiement: '',
    quantiteParBeneficiaire: 0,
    montantParBeneficiaire: 0,
    chargeReevaluation: [],
    actionEncours: '',
    beneficiaireAndMontant: [],
    searchInput: '',
    modePaiement: {},
    estBeneficiaire: false
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: [
        'secteurs',
        'charges',
        'directions',
        'caisses',
        'employes',
        'users',
        'partenaires'
    ]
};

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['beneficiaires'];

export function CreationReevaluationChargeFixe() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        chargesAReevaluer,
        etatchargesAReevaluer,
        directions,
        etatDirections,
        secteursNapp,
        secteursKinDB,
        etatSecteurs,
        caisses,
        etatCaisse,
        users,
        etatUsers,
        employesOrganisation,
        etatEmployesOrganisation,
        partenaire,
        etatPartenaire
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        chargesAReevaluer: reduxState.chargesAReevaluer.map((item) => ({
            ...item,
            code: item?.charge?.code,
            designation: `${item?.charge?.designation}-${item?.devise || ''}`
        })),
        etatchargesAReevaluer: reduxState.etat.chargesAReevaluer.etat,
        etatSecteurs: reduxState.etat.secteurs.etat,
        secteursNapp: reduxState.secteurs.filter(
            (item) => item?.direction?.estNapp
        ),
        secteursKinDB: reduxState.secteurs.filter(
            (item) => !item?.direction?.estNapp
        ),
        directions: reduxState.directions,
        etatDirections: reduxState.etat.directions.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item.valeurTypeEntite.designation}-${item.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat,
        users: reduxState.users.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.USER.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatUsers: reduxState.etat.users.etat,
        employesOrganisation: reduxState.employesOrganisation
            .filter((item) => !item?.idUser)
            .map((item) => ({
                ...item,
                typeBeneficiaire: BENEFICIAIRE.EMPLOYE.code,
                designation: `${item?.personne?.prenom || ' '} ${
                    item?.personne?.nom || ' '
                }`
            })),
        etatEmployesOrganisation: reduxState.etat.employesOrganisation.etat,
        partenaire: reduxState.beneficiaires.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.PARTENAIRE.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatPartenaire: reduxState.etat.beneficiaires.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    /**
     * @description verification si les informations concernant la charge sont remplies de manière automatique
     */
    const typeOfCharge = chargesAReevaluer.find(
        (item) => item?.id === fields?.selectedCharge?.id
    ) || { estRempliAutomatique: false };

    const periodes = chargesAReevaluer.length
        ? chargesAReevaluer
              .map((element) => ({
                  ...element,
                  dateDebut: element?.periode?.dateDebut,
                  dateFin: element?.periode?.dateFin,
                  designation: getMonthByDate(element?.periode?.dateDebut)
              }))
              .find((item) => item.periode)
        : [];

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_A_REEVALUER,
                nodeName: REDUX_NODE_NAME.CHARGE_A_REEVALUER,
                etat: etatchargesAReevaluer
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_DIRECTION, // Toutes les directions
                nodeName: REDUX_NODE_NAME.DIRECTION,
                etat: etatDirections
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUsers
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EMPLOYE_ORGANISATION,
                nodeName: DB_NODE.EMPLOYE,
                etat: etatEmployesOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PARTENAIRE,
                nodeName: REDUX_NODE_NAME.BENEFICIAIRE,
                etat: etatPartenaire
            }
        ]
    });

    // /**
    //  * @description fonction pour fetcher les beneficiaires
    //  */
    // const onSearchBeneficiaire = () => {
    //     const { status: etat } = elements.beneficiaires;
    //     formDispatcher(
    //         fetchElement({
    //             idToken,
    //             functionName: URL_CONST.GET_LIST_BENEFICIAIRES,
    //             elementPropertyName: 'beneficiaires',
    //             etat,
    //             payload: {
    //                 typeEntite: fields.selectedTypeBeneficiaire?.code,
    //                 value: fields.searchInput
    //             },
    //             reduxDispatcher
    //         })
    //     );
    // };

    /**
     * @description action pour rajouter le partenaires et le montant à payer
     */
    const addBeneficiaire = () => {
        if (
            !fields.selectedBeneficiaire.id &&
            (!fields.quantiteParBeneficiaire || !fields.montantParBeneficiaire)
        )
            return;

        const propertyToAdd = {};
        if (typeOfCharge.prix)
            propertyToAdd.quantite = fields.quantiteParBeneficiaire;
        if (!typeOfCharge.prix && !typeOfCharge.estRempliAutomatique)
            propertyToAdd.montant = fields.montantParBeneficiaire;
        const data = {
            id: fields.selectedBeneficiaire.id,
            typeEntite: fields.selectedTypeBeneficiaire.code,
            reference: fields.selectedBeneficiaire.id,
            codeSecteur: fields.selectedSecteur.code,
            designation: fields.selectedBeneficiaire.designation,
            ...propertyToAdd
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'beneficiaireAndMontant',
                data
            }
        });
    };

    /**
     * @description action pour rajouter les elements qui partage le même secteur
     */
    const addEntireChargeReevaluation = () => {
        if (!fields?.selectedCharge?.id || !fields.datePaiement) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Vérifiez Selectionner les éléments renseignés`,
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }

        const selectedCharge = chargesAReevaluer.find(
            (item) => item?.id === fields?.selectedCharge?.id
        );

        const newPaiement = utc000000(fields.datePaiement);
        const beneficiaires = fields.beneficiaireAndMontant.map((item) => {
            if (item.montant) {
                return {
                    typeEntite: item.typeEntite,
                    reference: item.reference,
                    codeSecteur: item.codeSecteur,
                    montant: item.montant
                };
            }
            return {
                typeEntite: item.typeEntite,
                reference: item.reference,
                codeSecteur: item.codeSecteur,
                quantite: item.quantite
            };
        });
        const propertyToAdd = {};
        const propertyToAddCaisse = {};
        if (typeOfCharge.prix) propertyToAdd.prix = fields.prixCharge;

        if (fields.modePaiement.code === 'Cash') {
            propertyToAddCaisse.idCaisse = fields.selectedCaisseSectorielle.id;
            // propertyToAddCaisse.typeMoyenPaiement = fields.modePaiement.code;
        }
        let data = {
            code: selectedCharge?.code,
            designationCharge: selectedCharge?.designation,
            devise: selectedCharge?.devise,
            datePaiement: newPaiement.valueOf(),
            typeMoyenPaiement: fields.modePaiement.code
        };
        if (typeOfCharge?.estRempliAutomatique) {
            data = {
                id: `${fields?.selectedCharge?.id}`,
                ...data,
                ...propertyToAddCaisse
            };
        }
        if (beneficiaires.length) {
            propertyToAdd.beneficiaires = beneficiaires;
        }
        if (!typeOfCharge?.estRempliAutomatique) {
            data = {
                id: `${fields?.selectedCharge?.id}_${
                    !typeOfCharge?.estRempliAutomatique
                        ? fields.selectedSecteur.code
                        : 'unknown'
                }_${fields.selectedDirection.code}_${
                    !typeOfCharge?.estRempliAutomatique
                        ? periodes.dateDebut
                        : 'today'
                }_${
                    !typeOfCharge?.estRempliAutomatique
                        ? periodes.dateFin
                        : 'today'
                }_${
                    !typeOfCharge?.estRempliAutomatique
                        ? fromStringToTimestamp(fields.datePaiement)
                        : 'today'
                }`,
                ...propertyToAdd,
                ...data,
                ...propertyToAddCaisse
            };
        }
        // formDispatcher({
        //     type: FORM_CONST.ADD_ITEM,
        //     payload: {
        //         identifier: 'id',
        //         field: 'chargeReevaluation',
        //         data
        //     }
        // });
        /**
         * @description: Je réinitialise un ensemble des propriétés du state
         */
        formDispatcher({
            type: FORM_CONST.REINITIALISER,
            payload: {
                initialState: {
                    ...formState,
                    fields: {
                        ...formState.fields,
                        beneficiaireAndMontant: [],
                        chargeReevaluation: fields.chargeReevaluation
                            .filter((item) => item.id !== data.id)
                            .concat(data)
                    }
                }
            }
        });
    };
    const getBeneficiaire = useCallback(() => {
        if (fields.selectedTypeBeneficiaire.code === BENEFICIAIRE.USER.code)
            return users;
        if (fields.selectedTypeBeneficiaire.code === BENEFICIAIRE.EMPLOYE.code)
            return employesOrganisation;
        if (
            fields.selectedTypeBeneficiaire.code ===
            BENEFICIAIRE.PARTENAIRE.code
        )
            return partenaire;
        return [];
    }, [
        employesOrganisation,
        fields.selectedTypeBeneficiaire.code,
        partenaire,
        users
    ]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const { chargeReevaluation } = fields;
        const list = chargeReevaluation.map((item) => {
            const propertyToAdd = {};
            if (item.prix) {
                propertyToAdd.prix = item.prix;
            }
            if (item.beneficiaires) {
                propertyToAdd.beneficiaires = item.beneficiaires;
            }
            if (item.idCaisse) {
                propertyToAdd.idCaisse = item.idCaisse;
            }
            if (item.typeMoyenPaiement) {
                propertyToAdd.typeMoyenPaiement = item.typeMoyenPaiement;
            }
            if (periodes?.dateDebut) {
                propertyToAdd.periode = {
                    dateDebut: periodes.dateDebut,
                    dateFin: periodes.dateFin
                };
            }
            return {
                ...propertyToAdd,
                code: item.code,
                devise: item?.devise,
                datePaiement: item.datePaiement
            };
        });
        const payload = {
            creator,
            // La période n'est plus envoyé parce qu'elle sera déduit de la
            // même façon que les charges sont déduites par rapport à un mois donné
            // periode: {
            //     dateDebut: periodes.dateDebut,
            //     dateFin: periodes.dateFin
            // },
            classe: CLASSE_CHARGE_FIXE.code,
            list
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_REEVALUATION,
                reduxNodeName: REDUX_NODE_NAME.REEVALUATION,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
        // console.log(payload);
    };

    // const newListBenefinciaires = (listes = []) => {
    //     if (!listes?.length) return [];
    //     return listes.map((item) => ({
    //         ...item,
    //         designation: `${item.personne.prenom} ${item.personne.nom}`
    //     }));
    // };
    // console.log(JSON.stringify(chargesAReevaluer[0]));
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'directions',
                    etat: etatDirections
                },
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'charges',
                    etat: etatchargesAReevaluer
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisse
                },
                {
                    dependency: 'employes',
                    etat: etatEmployesOrganisation
                },
                {
                    dependency: 'users',
                    etat: etatUsers
                },
                {
                    dependency: 'partenaires',
                    etat: etatPartenaire
                }
            ]}
        >
            <ConditionalRenderingWrapper
                isShouldBeRendered={periodes.dateDebut}
            >
                <div className="alert alert-warning" role="alert">
                    Charges fixes !
                    <hr />
                    <div>
                        <span className="">Période (Mois) :</span>{' '}
                        <span>{periodes?.designation?.toUpperCase()}</span>
                    </div>
                </div>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomDropdown
                    // options={chargesAReevaluer}
                    options={[
                        ...chargesAReevaluer,
                        {
                            id: 'amhuN8U6PkSr4s833Slis',
                            dateCreation: 1704703407303,
                            creator: 'qlxrcWKltYhDhrIG90oSyeU0eVN2s',
                            classe: 'Fixe',
                            idBudgetCharge: 'LFdI8VoE618UrWmZVfCzs',
                            charge: {
                                code: 'OKAPI_CHARGE_SALAIRE',
                                designation: 'Salaire'
                            },
                            estRempliAutomatique: false,
                            type: 'Previsionnel',
                            devise: 'USD',
                            typeMoyenPaiement: 'OrdrePaiement',
                            valeurMoyenPaiement: {
                                reference: 'CLbZmKdnCO6F1QomdpH1',
                                numeroCompteBancaire: '00007197604',
                                institutionFinanciere: {
                                    code: 'AccessBank',
                                    designation: 'Access Bank (R.D. Congo) SA'
                                }
                            },
                            periode: {
                                dateDebut: 1714521600000,
                                dateFin: 1717199999000
                            },
                            dateDerniereModification: 1704703407303,
                            previsionnel: 0,
                            estReevalue: false,
                            factuel: 0,
                            compteImputation: {
                                code: 'OKAPI_CMP_PERSONNEL',
                                designation: 'Personnel'
                            },
                            budget: {
                                dateDebut: 1704067200000,
                                idBudget: 'R5i7evKH87Q7B3lNpZvq',
                                dateFin: 1735689599000
                            },
                            code: 'OKAPI_CHARGE_SALAIRES',
                            designation: 'Un autre'
                        }
                    ]}
                    disabled={!chargesAReevaluer.length}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la charge"
                    label="Charge*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedCharge"
                    id="selectedChargeId"
                    idProperty="id"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInputDate
                    id="datePaiementId"
                    name="datePaiement"
                    type="date"
                    label="Date de Paiement*"
                    isInputGroup={false}
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="Date paiement"
                    formDispatcher={formDispatcher}
                    // uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={MOYEN_PAIEMENT.ALL_MOYEN_PAIEMENT}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le mode de paiement"
                    label="Mode de paiement*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="modePaiement"
                    id="modePaiementId"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomCollapse isIn={fields.modePaiement.code === 'Cash'}>
                <CustomRow>
                    <CustomDropdown
                        idProperty="id"
                        options={caisses.filter(
                            (item) =>
                                item.devise ===
                                chargesAReevaluer.find(
                                    (charge) =>
                                        charge?.id ===
                                        fields?.selectedCharge?.id
                                )?.devise
                        )}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner la caisse"
                        label="Caisse Sectorielle*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedCaisseSectorielle"
                        id="selectedCaisseSectorielleId"
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            </CustomCollapse>
            {fields?.selectedCharge?.id &&
            !typeOfCharge?.estRempliAutomatique ? (
                <>
                    {typeOfCharge?.prix ? (
                        <CustomRow>
                            <CustomInput
                                isInputGroup={false}
                                label="Prix Unitaire*"
                                labelClassName="col-12 col-sm-2"
                                divClassName="col-12 col-sm-10"
                                placeholder="Saisissez le montant"
                                formDispatcher={formDispatcher}
                                type="number"
                                name="prixCharge"
                                id="prixChargeId"
                                formState={formState}
                                // uiValidator={uiValidator}
                            />
                        </CustomRow>
                    ) : null}
                    <CustomRow>
                        <CustomSwitchButton
                            formDispatcher={formDispatcher}
                            formState={formState}
                            id="estBeneficiaire"
                            text="Renseigner les bénéficiaires?"
                            value={fields.estBeneficiaire}
                            name="estBeneficiaire"
                            divClassName="ms-2 col-md-8"
                        />
                    </CustomRow>
                    {fields.estBeneficiaire ? (
                        <>
                            <CustomRow>
                                <CustomDropdown
                                    options={directions}
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    defaultOption="Sélectionner la direction"
                                    label="Direction*"
                                    labelClassName="col-12 col-sm-2"
                                    divClassName="col-12 col-sm-10"
                                    name="selectedDirection"
                                    id="selectedDirectionId"
                                    // uiValidator={uiValidator}
                                />
                            </CustomRow>
                            <CustomRow>
                                <CustomDropdown
                                    options={
                                        (fields.selectedDirection.code ===
                                        'OKAPI_DIRECT_NAPP'
                                            ? secteursNapp
                                            : secteursKinDB) || []
                                    }
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    defaultOption="Sélectionner le secteur"
                                    label="Secteur*"
                                    labelClassName="col-12 col-sm-2"
                                    divClassName="col-12 col-sm-10"
                                    name="selectedSecteur"
                                    id="selectedSecteurId"
                                    // uiValidator={uiValidator}
                                />
                            </CustomRow>
                            <CustomRow>
                                <CustomDropdown
                                    options={BENEFICIAIRE.CHARGE_FIXE}
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    defaultOption="Sélectionner le type beneficiaire"
                                    label="Type Beneficiaire*"
                                    labelClassName="col-12 col-sm-2"
                                    divClassName="col-12 col-sm-10"
                                    name="selectedTypeBeneficiaire"
                                    id="selectedTypeBeneficiaireId"
                                    // uiValidator={uiValidator}
                                />
                            </CustomRow>
                            <ConditionalRenderingWrapper
                                isShouldBeRendered={
                                    fields.selectedBeneficiaire.code
                                }
                            >
                                <CustomRow>
                                    <CustomLabel
                                        text="Beneficiaire*"
                                        className="col-sm-2"
                                    />
                                    <CustomDropdown
                                        idProperty="id"
                                        options={getBeneficiaire()}
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        defaultOption="Sélectionner le beneficiaire"
                                        divClassName="col-12 col-sm-4"
                                        name="selectedBeneficiaire"
                                        id="selectedBeneficiaireId"
                                        // uiValidator={uiValidator}
                                    />
                                    {typeOfCharge?.prix ? (
                                        <CustomInput
                                            isInputGroup={false}
                                            divClassName="col-12 col-sm-4"
                                            placeholder="Quantité..."
                                            formDispatcher={formDispatcher}
                                            name="quantiteParBeneficiaire"
                                            type="number"
                                            id="quantiteParBeneficiaireId"
                                            formState={formState}
                                            // uiValidator={uiValidator}
                                        />
                                    ) : (
                                        <CustomInput
                                            isInputGroup={false}
                                            divClassName="col-12 col-sm-4"
                                            placeholder="Montant..."
                                            formDispatcher={formDispatcher}
                                            name="montantParBeneficiaire"
                                            type="number"
                                            id="montantParBeneficiaireId"
                                            formState={formState}
                                            // uiValidator={uiValidator}
                                        />
                                    )}
                                    <CustomAddRemoveButton
                                        className="col-sm-2"
                                        callback={addBeneficiaire}
                                    />
                                </CustomRow>
                                {fields.beneficiaireAndMontant.length &&
                                typeOfCharge.prix ? (
                                    <SimpleTable
                                        identifier="id"
                                        formDispatcher={formDispatcher}
                                        data={fields.beneficiaireAndMontant}
                                        isShowDropButton
                                        listSelectedItemName="beneficiaireAndMontant"
                                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                        filter={false}
                                        bottomPagination={false}
                                        isLinkToDetail={false}
                                        mapper={[
                                            {
                                                position: 2,
                                                displayName: 'Beneficiaire',
                                                field: 'designation'
                                            },
                                            {
                                                position: 3,
                                                displayName: 'Quantité',
                                                field: 'quantite'
                                            }
                                        ]}
                                    />
                                ) : null}
                                {fields.beneficiaireAndMontant.length &&
                                !typeOfCharge.prix ? (
                                    <SimpleTable
                                        identifier="id"
                                        formDispatcher={formDispatcher}
                                        data={fields.beneficiaireAndMontant}
                                        isShowDropButton
                                        listSelectedItemName="beneficiaireAndMontant"
                                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                        filter={false}
                                        bottomPagination={false}
                                        isLinkToDetail={false}
                                        mapper={[
                                            {
                                                position: 2,
                                                displayName: 'Beneficiaire',
                                                field: 'designation'
                                            },
                                            {
                                                position: 3,
                                                displayName: 'Montant',
                                                field: 'montant'
                                            }
                                        ]}
                                    />
                                ) : null}
                            </ConditionalRenderingWrapper>
                        </>
                    ) : null}
                </>
            ) : null}
            <div className="d-flex flex-row-reverse mb-3">
                <CustomButtonLoader
                    type="button"
                    className="btn-primary"
                    onClick={addEntireChargeReevaluation}
                    text="Ajouter Element"
                />
            </div>
            {fields.chargeReevaluation.length ? (
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.chargeReevaluation}
                    isShowDropButton
                    listSelectedItemName="chargeReevaluation"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            displayName: 'Charge',
                            field: 'designationCharge'
                        },
                        {
                            position: 3,
                            displayName: 'Moyen Paiement',
                            field: 'typeMoyenPaiement'
                        },
                        {
                            position: 4,
                            displayName: 'Devise',
                            field: 'devise'
                        },
                        {
                            position: 5,
                            displayName: 'Date Paiement',
                            field: 'datePaiement',
                            isDate: true
                        }
                    ]}
                />
            ) : null}
            {/* <StateShowerComponent state={formState} /> */}
            <CustomRow className="mt-3">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Soumettre réévaluation"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
