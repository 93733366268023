import React from 'react';

/**
 * FormGroup personnalisé
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {String}  param0.className classe CSS
 * @param {React.Component} param0.children le children du composant
 * @returns {React.Component}
 */
export function CustomFormGroup({ children, className }) {
    return <div className={`form-group ${className}`}>{children}</div>;
}
