import { FIELD_TYPE } from '@napp-inc/jnapp-util';
import {
    CustomButton,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../components';
import {
    CheckTypeComponent,
    FileTypeComponent,
    NumberTypeComponent,
    RadioTypeComponent,
    TextTypeComponent,
    CorrespondanceTypeComponent
} from './field-type';

const typeChamps = [
    FIELD_TYPE.TEXTE,
    FIELD_TYPE.NOMBRE,
    FIELD_TYPE.RADIO,
    FIELD_TYPE.CHECKBOX,
    FIELD_TYPE.DATE,
    FIELD_TYPE.HEURE,
    FIELD_TYPE.LISTE,
    FIELD_TYPE.FILE,
    FIELD_TYPE.CORRESPONDANCE
];

export function ConfigFieldsForm({
    id,
    formState,
    formDispatcher,
    handleAjouterChamps,
    isFloatName,
    nombreRangApresVirguleName,
    listSelectedItemName = 'listChamps',
    optionRadioName = 'optionRadio',
    listOptionsRadioName = 'listOptionsRadio',
    listOptionsCheckName = 'listOptionsCheck',
    optionCheckName = 'optionCheck',
    isCheckDefaultCheckedName = 'isCheckDefaultChecked',
    uiValidator
}) {
    const { fields } = formState;
    return (
        <>
            <CustomRow>
                <CustomDropdown
                    options={typeChamps}
                    label="Type*"
                    labelClassName="col-md-2"
                    divClassName="col-md-10"
                    placeholder="Sélectionner un type"
                    formDispatcher={formDispatcher}
                    name="selectedType"
                    id={`${id}selectedTypeCreationConfigDynamiqueForm`}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Code*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="identifiant du champ"
                    formDispatcher={formDispatcher}
                    value={fields.code}
                    name="code"
                    id={`${id}codeCreationConfigSimFlash`}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Désignation*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="titre du champ"
                    formDispatcher={formDispatcher}
                    value={fields.designation}
                    name="designation"
                    id={`${id}designationCreationConfigSimFlash`}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Description"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="description du champ"
                    formDispatcher={formDispatcher}
                    value={fields.description}
                    name="description"
                    id={`${id}descriptionCreationConfigSimFlash`}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomCollapse
                    isIn={fields.selectedType?.code === FIELD_TYPE.TEXTE.code}
                    className="col-12"
                >
                    <TextTypeComponent
                        id={`${id}textTypeCreationConfigSimFlash`}
                        formDispatcher={formDispatcher}
                        formState={formState}
                    />
                </CustomCollapse>
                <CustomCollapse
                    isIn={fields.selectedType?.code === FIELD_TYPE.NOMBRE.code}
                    className="col-12"
                >
                    <NumberTypeComponent
                        id={`${id}textTypeCreationConfigSimFlash`}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        isFloatName={isFloatName}
                        nombreRangApresVirguleName={nombreRangApresVirguleName}
                    />
                </CustomCollapse>
                <CustomCollapse
                    isIn={
                        fields.selectedType?.code === FIELD_TYPE.RADIO.code ||
                        fields.selectedType?.code === FIELD_TYPE.LISTE.code
                    }
                    className="col-12"
                >
                    <RadioTypeComponent
                        id={`${id}radioTypeCreationDynamiqueForm`}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        optionRadioName={optionRadioName}
                        listOptionsRadioName={listOptionsRadioName}
                    />
                </CustomCollapse>
                <CustomCollapse
                    isIn={
                        fields.selectedType?.code === FIELD_TYPE.CHECKBOX.code
                    }
                    className="col-12"
                >
                    <CheckTypeComponent
                        id={`${id}checkTypeCreationDynamiqueForm`}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        optionCheckName={optionCheckName}
                        listOptionsCheckName={listOptionsCheckName}
                        isCheckDefaultCheckedName={isCheckDefaultCheckedName}
                    />
                </CustomCollapse>
                <CustomCollapse
                    isIn={fields.selectedType?.code === FIELD_TYPE.FILE.code}
                    className="col-12"
                >
                    <FileTypeComponent
                        id={`${id}fileTypeCreationDynamiqueForm`}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        // optionRadioName={optionRadioName}
                        // listOptionsRadioName={listOptionsRadioName}
                    />
                </CustomCollapse>
                <CustomCollapse
                    isIn={
                        fields.selectedType?.code ===
                        FIELD_TYPE.CORRESPONDANCE.code
                    }
                    className="col-12"
                >
                    <CorrespondanceTypeComponent
                        id={`${id}correspondanceTypeCreationDynamiqueForm`}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        selectedCorrespondanceTypeName="selectedCorrespondanceType"
                    />
                </CustomCollapse>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-9 col-3 offset-md-10 col-md-2">
                    <CustomButton
                        text="Ajouter"
                        onClick={handleAjouterChamps}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="col-12 offset-md-2 col-md-10">
                    <SimpleTable
                        listSelectedItemName={listSelectedItemName}
                        data={formState?.fields?.listChamps}
                        formDispatcher={formDispatcher}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                field: 'code',
                                displayName: 'Code',
                                position: 1
                            },
                            {
                                field: 'type',
                                displayName: 'Type',
                                position: 2
                            },
                            {
                                field: 'designation',
                                displayName: 'Désignation',
                                position: 3
                            },
                            {
                                field: 'description',
                                displayName: 'Description',
                                position: 4
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
        </>
    );
}
