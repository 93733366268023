import {
    useFormGlobalReducer,
    submitPutAction,
    useGetEntity,
    useGetUserDetail,
    useIsLoadComplete,
    useFetchAndListening,
    useEtatChargementDependencies
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    ETAT,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CenteredSpinner,
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomForm,
    CustomRow,
    EtatDisplayer
} from '../../../components';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    actionEncours: '',
    selectedPointLivraison: '',
    selectedAgentLivreur: {}
};

/**
 * @description defaultDependencies de notre state
 */
const defaultDependencies = {
    fields: ['users'],
    entity: true
};

export function DetailHomologuerPointLivraison() {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    const { firebaseUser, entity, users, etatUser } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            entity: reduxState.pointsLivraison.find(
                (element) => element.id === id
            ),
            users: reduxState.users.map((item) => ({
                ...item,
                designation: `${item?.personne?.prenom || ''} ${
                    item?.personne?.nom || ''
                }`
            })),
            etatUser: reduxState.etat.users.etat
        })
    );
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, dependencies } = formState;
    useGetEntity({
        entity,
        etat: dependencies.entity.status,
        formDispatcher,
        functionName: URL_CONST.GET_POINT_LIVRAISON_BY_ID,
        id,
        idToken,
        nodeName: DB_NODE.POINT_LIVRAISON,
        reduxDispatcher
    });

    /**
     * @description récupérer les users marchand
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUser
            }
        ]
    });

    /**
     * This hook loads dependecies
     */
    useEtatChargementDependencies({
        formDispatcher,
        dependencies,
        list: [
            {
                dependency: 'users',
                etat: etatUser
            }
        ]
    });

    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        dependencies
    });

    const updateEtat = (etat) => {
        const payloadToAdd = {};
        if (entity.agentLivreur) {
            payloadToAdd.referenceAgentLivreur =
                fields?.selectedAgentLivreur?.id;
        }
        const payload = {
            etat,
            creator,
            idPointLivraison: entity?.id,
            ...payloadToAdd
        };
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_POINT_LIVRAISON,
                payload,
                fields,
                reduxNodeName: DB_NODE.POINT_LIVRAISON,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => {
        if (!entity?.id && !isLoadFailed && !isLoadComplete) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return <CustomAlert error={form?.error} />;
        }
        return (
            <>
                <CustomAlert error={form?.error} />
                <CustomForm>
                    <CustomRow>
                        <h1 className="lead">Homologuer Point de livraison</h1>
                    </CustomRow>
                    <CustomRow>date : {entity?.dateCreation}</CustomRow>
                    <CustomRow>
                        <CustomCol className="col-3">
                            <EtatDisplayer etat={entity?.etat} />
                        </CustomCol>
                    </CustomRow>
                    {entity.agentLivreur ? (
                        <CustomRow>
                            <CustomDropdown
                                options={users}
                                formDispatcher={formDispatcher}
                                formState={formState}
                                defaultOption="Sélectionner l'agent"
                                label="Agent Livreur*"
                                idProperty="id"
                                labelClassName="col-12 col-sm-2"
                                divClassName="col-12 col-sm-10"
                                name="selectedAgentLivreur"
                                id="selectedAgentLivreurId"
                                // uiValidator={uiValidator}
                            />
                        </CustomRow>
                    ) : null}
                    <CustomRow>
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomButtonLoader
                                className="btn-danger"
                                onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                                text="Annuler"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                            />
                        </CustomCol>
                        <CustomCol className="col-3 col-md-2">
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                                text="valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                </CustomForm>
            </>
        );
    };
    return renderContent();
}
