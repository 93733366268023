import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetOrganisationDetail,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    formInitialState,
    FORM_CONST,
    TYPE_BUSINESS,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    UserServiceWrapper
} from '../../../components';
import { SaisiMontantForm } from '../../../components/devise/saisi-montant';
import { REDUX_NODE_NAME } from '../../../redux';
import {
    useCalculPrixTotal,
    useCalculTotalInput,
    useComparePercuRetour,
    useGetCurrentGeoLocation
} from '../../../util';

const defaultFields = {
    selectedMonnaie: { devise: 'UNITE', id: 'unite', code: 'unite' },
    selectedClient: {},
    quantite: 0,
    montantsDevise: [],
    montantsDeviseRetour: [],
    selectedProduit: {},
    estModeRecouvrement: false
};

export function CreationCommandeFlashForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        monnaiesLocale,
        etatMonnaieLocale,
        etatClientOrganisation,
        clientsOrganisation,
        produits,
        etatProduit,
        organisation,
        deviseReference,
        etatTaux,
        taux
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        monnaiesLocale: reduxState.monnaiesLocale,
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat,
        clientsOrganisation: reduxState.clientsOrganisation,
        etatClientOrganisation: reduxState.etat.clientsOrganisation.etat,
        produits: reduxState.produits,
        etatProduit: reduxState.etat.produits.etat,
        etatTaux: reduxState.etat.taux.etat,
        taux: reduxState.taux,
        organisation: reduxState.organisation,
        deviseReference: reduxState.deviseReference
    }));
    /**
     * Common form processes
     */
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: {
            fields: [
                'clientsOrganisation',
                'monnaiesLocale',
                'produits',
                'taux'
            ]
        }
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { coords, localisationError } = useGetCurrentGeoLocation({
        formDispatcher
    });
    const { fields, form } = formState;
    const { referenceMarchand } = useGetOrganisationDetail({ organisation });
    /**
     * Custom form functions
     */
    const clientFormater = (listClient = []) =>
        listClient.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeEntite?.designation || ''} - ${
                item.numero
            }`
        }));
    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION,
                nodeName: DB_NODE.TAUX,
                etat: etatTaux
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_NUMERO_CLIENT,
                nodeName: REDUX_NODE_NAME.CLIENT_ORGANISATION,
                etat: etatClientOrganisation,
                formater: clientFormater
                // reduxValidator: ApprovisionnementMonnaieSchema
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PRODUIT_SUPER_DEALER,
                nodeName: DB_NODE.PRODUIT,
                etat: etatProduit,
                payload: { creator }
            }
        ]
    });
    /**
     * Custom hook call
     */
    const { quantiteComputed } = useCalculPrixTotal({
        quantite: fields.quantite,
        prix: fields.selectedProduit?.prixAchat,
        unite: fields.selectedMonnaie?.devise,
        deviseReference: deviseReference?.code,
        taux
    });
    const { totalInput } = useCalculTotalInput({
        taux,
        deviseReference: deviseReference.code,
        listPercuRetour: fields.montantsDevise
    });
    const { totalInput: totalOutput } = useCalculTotalInput({
        taux,
        deviseReference: deviseReference.code,
        listPercuRetour: fields.montantsDeviseRetour
    });
    const { newListPercu, newListRetour } = useComparePercuRetour({
        totalPercu: totalInput,
        totalRetour: totalOutput,
        listPercu: fields.montantsDevise,
        listRetour: fields.montantsDeviseRetour
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedProduit, selectedClient, estModeRecouvrement } = fields;
        const propertyToAdd = {};
        if (!estModeRecouvrement) {
            propertyToAdd.montantsDevise = newListPercu
                .map((item) => ({
                    montant: item.montant,
                    devise: item.devise,
                    estPercu: true
                }))
                .concat(
                    newListRetour.map((itemRet) => ({
                        montant: itemRet.montant,
                        devise: itemRet.devise,
                        estPercu: false
                    }))
                );
        }
        const payload = {
            ...propertyToAdd,
            newCommandeFlash: {
                typeBusiness: TYPE_BUSINESS.TRADITIONNEL_BUSINESS,
                produit: {
                    code: selectedProduit.code,
                    designation: selectedProduit.designation
                },
                prixUnitaire: selectedProduit.prixAchat,
                quantite: quantiteComputed,
                marchand: { reference: referenceMarchand },
                client: {
                    idCompte: selectedClient.id
                },
                estRecouvert: estModeRecouvrement,
                estCoupPouce: false
            },
            point: {
                ...coords
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_COMMANDE_FLASH,
                payload,
                fields,
                reduxNodeName: DB_NODE.COMMANDE_FLASH,
                reduxDispatcher
            })
        );
    };
    useEffect(() => {
        if (produits && produits.length === 1) {
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field: 'selectedProduit',
                    data: produits[0]
                }
            });
        }
    }, [produits, formDispatcher]);
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'clientsOrganisation',
                    etat: etatClientOrganisation
                },
                {
                    dependency: 'monnaiesLocale',
                    etat: etatMonnaieLocale
                },
                {
                    dependency: 'produits',
                    etat: etatProduit
                },
                {
                    dependency: 'taux',
                    etat: etatTaux
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={produits}
                    label="Produit*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedProduit"
                    id="selectedProduitCreationClient"
                    formState={formState}
                    idProperty={produits.length > 1 ? 'id' : 'code'}
                    disabled={produits.length === 1}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={clientsOrganisation}
                    label="Client*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedClient"
                    id="selectedClientCreationCommandeFlash"
                    formState={formState}
                    idProperty="id"
                />
            </CustomRow>
            <CustomRow>
                <CustomCol className="col-4 col-md-2">
                    <CustomLabel>Quantité</CustomLabel>
                </CustomCol>
                <div className="col-sm-5">
                    <CustomInput
                        type="number"
                        isInputGroup={false}
                        min={0}
                        placeholder="Saisissez la quantité"
                        formDispatcher={formDispatcher}
                        name="quantite"
                        id="quantiteCreationCommandeFlash"
                        // uiValidator={CreationGroupeAbonneSchema}
                        formState={formState}
                    />
                </div>
                <CustomDropdown
                    options={[
                        { devise: 'UNITE', id: 'unite', code: 'unite' }
                    ].concat(monnaiesLocale)}
                    divClassName="col-sm-5"
                    formDispatcher={formDispatcher}
                    name="selectedMonnaie"
                    id="selectedMonnaieCreationCommandeFlash"
                    propertyToDisplay="devise"
                    // uiValidator={CreationGroupeAbonneSchema}
                    formState={formState}
                    idProperty="id"
                />
            </CustomRow>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.VENTE_ORGANISATION
                        .INITIER_COMMANDE_FLASH_MODE_RECOUVREMENT
                ]}
            >
                <CustomRow>
                    <CustomSwitchButton
                        id="estModeRecouvrementCreationCommandeFlash"
                        text="Mode recouvrement"
                        inputTitle="Mode recouvrement"
                        formDispatcher={formDispatcher}
                        defaultChecked={fields.estTarifPreferentiel}
                        name="estModeRecouvrement"
                        divClassName="col-md-8"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            </UserServiceWrapper>
            {!fields.estModeRecouvrement && (
                <CustomRow>
                    <CustomCol className="col-6">
                        <SaisiMontantForm
                            formDispatcher={formDispatcher}
                            percuRetourName="montantsDevise"
                            formState={formState}
                        />
                    </CustomCol>
                    <CustomCol className="col-6">
                        <SaisiMontantForm
                            formDispatcher={formDispatcher}
                            percuRetourName="montantsDeviseRetour"
                            formState={formState}
                            isPercu={false}
                        />
                    </CustomCol>
                </CustomRow>
            )}
            {localisationError ? null : (
                <CustomRow className="row">
                    <CustomCol className="col-3">
                        <CustomButtonLoader
                            className="btn-success"
                            onClick={handleSubmit}
                            text="Sauvegarder"
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                        />
                    </CustomCol>
                </CustomRow>
            )}
        </FormWrapper>
    );
    return renderContent();
}
