import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    MOYEN_PAIEMENT,
    BUDGET_AMENDEMENT,
    BUDGET_ELABORATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomLabel,
    CustomCollapse,
    CustomAddRemoveButton,
    SimpleTable,
    FormWrapper,
    CustomRadioButton
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
// import { ElaborationChargeVariableFormSchema as uiValidator } from './validation';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedDirection: {},
    selectedSecteur: {},
    selectedCharge: {},
    selectedCaisseSectorielle: {},
    modePaiement: {},
    tauxCroissance: 0,
    typeOperation: BUDGET_AMENDEMENT,
    list: []
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: ['directions', 'secteurs', 'charges', 'caisses']
};

export function AmendementChargeVariable() {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        chargesVariable,
        etatCharge,
        etatSecteurs,
        etatDirections,
        caisses,
        etatCaisse,
        entity
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        chargesVariable: reduxState.chargesVariable,
        etatCharge: reduxState.etat.chargesVariable.etat,
        etatSecteurs: reduxState.etat.secteurs.etat,
        secteursNapp: reduxState.secteurs.filter(
            (item) => item?.direction?.estNapp
        ),
        secteursKinDB: reduxState.secteurs.filter(
            (item) => !item?.direction?.estNapp
        ),
        entity: reduxState.elaborations.find((element) => element.id === id),
        directions: reduxState.directions,
        etatDirections: reduxState.etat.directions.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item.valeurTypeEntite.designation}-${item.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const chargeVariableTargeted = chargesVariable.find(
        (item) => item.code === fields.selectedCharge.code
    );
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_VARIABLE,
                nodeName: REDUX_NODE_NAME.CHARGE_VARIABLE,
                etat: etatCharge
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_DIRECTION, // Toutes les directions
                nodeName: REDUX_NODE_NAME.DIRECTION,
                etat: etatDirections
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const { list } = fields;
        const payloadToAdd = {};
        if (fields.typeOperation === BUDGET_ELABORATION) {
            payloadToAdd.idElaborationAmendement = id;
        }
        const lastList = list.map((item) => {
            const model = {
                element: {
                    code: item.element.code
                },
                typeMoyenPaiement: item.typeMoyenPaiement
            };
            if (item.tauxCroissance) {
                model.tauxCroissance = item.tauxCroissance;
            }
            if (item.idCaisse) {
                model.idCaisse = item.idCaisse;
            }
            return model;
        });
        const payload = {
            ...payloadToAdd,
            periode: {
                dateDebut: entity?.budget?.dateDebut,
                dateFin: entity?.budget?.dateFin
            },
            newElaborationAmendement: {
                typeEntite:
                    fields.typeOperation === BUDGET_ELABORATION
                        ? BUDGET_ELABORATION
                        : BUDGET_AMENDEMENT,
                typeProprietaire: entity?.typeProprietaire,
                estVariable: entity?.estVariable,
                type: entity?.type
            },
            creator,
            list: lastList
        };
        // console.log(JSON.stringify(payload));
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    /**
     * @description action pour rajouter les taux de croissance par charge
     */
    const addTauxCroissance = () => {
        if (!fields.modePaiement.code || !fields.selectedCharge.code) return;
        const propertyToAdd = {};
        if (fields.modePaiement.code === 'Cash') {
            propertyToAdd.idCaisse = fields.selectedCaisseSectorielle.id;
        }

        if (chargeVariableTargeted?.type === 'Retribution') {
            propertyToAdd.tauxCroissance = 0;
        }
        if (chargeVariableTargeted?.type !== 'Retribution') {
            propertyToAdd.tauxCroissance = fields.tauxCroissance;
        }
        const data = {
            id: fields.selectedCharge.code,
            typeMoyenPaiement: fields.modePaiement.code,
            element: {
                designation: fields.selectedCharge.designation,
                code: fields.selectedCharge.code
            },
            ...propertyToAdd
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'list',
                data
            }
        });
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'directions',
                    etat: etatDirections
                },
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'charges',
                    etat: etatCharge
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisse
                }
            ]}
        >
            <CustomRow>
                <CustomLabel
                    text="Type Opération*"
                    className="col-12 col-md-2"
                />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3 ms-3"
                    label="Amendement"
                    id="typeOperationAmendementID"
                    name="typeOperation"
                    value={BUDGET_AMENDEMENT}
                    formDispatcher={formDispatcher}
                    // uiValidator={uiValidator}
                    formState={formState}
                />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3"
                    label="Compléter Elaboration"
                    id="typeOperationElaborationID"
                    name="typeOperation"
                    value={BUDGET_ELABORATION}
                    formDispatcher={formDispatcher}
                    // uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={MOYEN_PAIEMENT.ALL_MOYEN_PAIEMENT}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le mode de paiement"
                    label="Mode de paiement*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="modePaiement"
                    id="modePaiementId"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomCollapse isIn={fields.modePaiement.code === 'Cash'}>
                <CustomRow>
                    <CustomDropdown
                        idProperty="id"
                        options={caisses}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner la caisse"
                        label="Caisse Sectorielle*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedCaisseSectorielle"
                        id="selectedCaisseSectorielleId"
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            </CustomCollapse>
            <CustomRow>
                <CustomLabel text="Charge*" className="col-sm-2" />
                <CustomDropdown
                    options={chargesVariable}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la charge"
                    divClassName="col-12 col-sm-4"
                    name="selectedCharge"
                    id="selectedChargeId"
                    // uiValidator={uiValidator}
                />
                <CustomInput
                    isInputGroup={false}
                    divClassName="col-12 col-sm-4"
                    placeholder="Saisissez le taux de croissance..."
                    formDispatcher={formDispatcher}
                    name="tauxCroissance"
                    type="number"
                    id="tauxCroissanceId"
                    disabled={chargeVariableTargeted?.type === 'Retribution'}
                    formState={formState}
                    // uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-2"
                    callback={addTauxCroissance}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.list.length}>
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.list}
                    isShowDropButton
                    listSelectedItemName="list"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            displayName: 'ID',
                            field: 'id'
                        },
                        {
                            position: 2,
                            displayName: 'Charge',
                            field: 'element.designation'
                        },
                        {
                            position: 3,
                            displayName: 'Taux de croissance',
                            field: 'tauxCroissance'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text={
                            fields.typeOperation === BUDGET_AMENDEMENT
                                ? 'Amender'
                                : 'Compléter'
                        }
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
