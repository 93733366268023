import * as yup from 'yup';

export const ChoixCategorieSchema = yup.object().shape({
    categorie: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    monnaieDesignation: yup.string(),
    monnaieCode: yup.string(),
    typeRadio: yup.string().required(),
    sousTypeRadio: yup.string(),
    selectedDesive: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    isSim: yup.boolean(),
    isFlash: yup.boolean(),
    simDesignation: yup.string(),
    simCode: yup.string(),
    simTillNumber: yup.string(),
    simDigit: yup.number().min(0),
    flashDesignation: yup.string(),
    flashCode: yup.string()
});
