import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    AchatMarchandToMarchandRelPath,
    MoyenRetracageCreateRelPath
} from '../../../routes/registration/configuration/config-napp-rel-path';

export function ViewLayout({ children }) {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Achat auprès d&apos;un marchand</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${AchatMarchandToMarchandRelPath.path}` && (
                            <ShowNewComponent
                                services={{ code: 'test' }}
                                destination={MoyenRetracageCreateRelPath.path}
                                text="Acheter"
                            />
                        )}
                    </div>
                </div>
            </div>
            {children}
        </>
    );
}
