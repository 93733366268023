import * as yup from 'yup';

/**
 * @description : Schema de Application Mobile form
 */

export const ApplicationMobileFormSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required()
});
