import {
    submitPutAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    ETAT,
    formInitialState,
    FORM_CONST,
    ONLY_FETCH,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
    CustomAddButton,
    CustomAlert,
    CustomCol,
    CustomConfirmButton,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { schema } from './detail-fields';

/**
 * @description defaultFields
 */
const defaultFields = {
    selectedCoupureName: {},
    nombreCoupureName: 0,
    listBilletageName: [],
    actionEncours: ''
};

const defaultDependencies = { fields: ['coupure'] };

export function DetailPaiementCaisseSectorielle() {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    const { firebaseUser, entity, nappUser, dynamicEtatNode, dynamicNode } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            entity: reduxState.paiementSectorielleBeneficiaires.find(
                (element) => element.id === id
            ),
            dynamicNode: reduxState.dynamicNode,
            dynamicEtatNode: reduxState.dynamicEtatNode
        }));
    const { idToken, creator } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    // const { isShouldBeRendered } = useShouldBeRendered({
    //     entity,
    //     list: listEtatFinaux,
    //     etat: etatPath
    // });
    const dynamicNodeName = `${REDUX_NODE_NAME.COUPURE_DEVISE}${id}`;
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                fetchType: ONLY_FETCH,
                functionName: URL_CONST.GET_LIST_COUPURE_BY_DEVISE,
                dynamicNodeName,
                isDynamicNode: true,
                etat: dynamicEtatNode[dynamicNodeName]?.etat,
                payload: {
                    devise: entity?.devise
                },
                isCondition: !!entity?.devise
            }
        ]
    });

    const ajouterCoupure = () => {
        const devise = { code: 'USD' };
        const coupure = formState.fields.selectedCoupureName;
        const nombre = formState.fields.nombreCoupureName;
        if (!nombre || !devise || !devise.code || !coupure || !coupure.code)
            return;
        const data = {
            nombre,
            devise,
            coupure,
            id: `${coupure.code}${devise.code}`
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: { field: 'listBilletageName', data }
        });
    };

    const handleSubmit = () => {
        const payloadToAdd = {};

        if (fields.listBilletageName.length) {
            const coupures = fields.listBilletageName.map((item) => ({
                valeur: Number(item.coupure.code),
                nombre: item.nombre
            }));
            payloadToAdd.coupures = coupures;
        }
        const payload = {
            creator,
            idPaiementCaisseSectorielle: entity?.id,
            ...payloadToAdd
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_CHARGE_BENEFICIAIRE,
                payload,
                reduxNodeName:
                    REDUX_NODE_NAME.PAIEMENT_CAISSE_SECTORIELLE_BENEFICIAIRE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_PAIEMENT_CAISSE_SECTORIELLE_BY_ID}
            nodeName={REDUX_NODE_NAME.PAIEMENT_CAISSE_SECTORIELLE_BENEFICIAIRE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={handleSubmit}
            subtitle="Detail Paiement Charge"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
            listDependencies={[
                {
                    dependency: 'coupure',
                    etat: dynamicEtatNode[dynamicNodeName]?.etat,
                    isCondition: !!entity?.devise
                }
            ]}
        >
            {entity?.devise ? (
                <CustomAlert successMessage={`Devise: ${entity?.devise}`} />
            ) : null}
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .MISE_A_JOUR_CHARGE_BENEFICIAIRE
                ]}
            >
                <CustomRow>
                    <CustomLabel className="col-3">Billetage*</CustomLabel>
                    <CustomCol className="col-4">
                        <CustomDropdown
                            isSort={false}
                            disabled={!dynamicNode[dynamicNodeName]?.length}
                            options={
                                dynamicNode[dynamicNodeName]?.length
                                    ? dynamicNode[dynamicNodeName].map(
                                          (item) => ({
                                              code: item
                                          })
                                      )
                                    : []
                            }
                            formDispatcher={formDispatcher}
                            name="selectedCoupureName"
                            id="selectedCoupureCreationBilletage"
                            formState={formState}
                            propertyToDisplay="code"
                            // uiValidator={uiValidator}
                        />
                    </CustomCol>
                    <CustomCol className="col-4">
                        <CustomInput
                            disabled={!dynamicNode[dynamicNodeName]?.length}
                            type="number"
                            divClassName="col-12 col-sm-10"
                            isInputGroup={false}
                            placeholder="Nombre coupure"
                            formDispatcher={formDispatcher}
                            name="nombreCoupureName"
                            id="nombreCoupureCreationBilletage"
                            formState={formState}
                            // uiValidator={uiValidator}
                        />
                    </CustomCol>
                    <CustomCol className="col-1">
                        <CustomAddButton callback={ajouterCoupure} />
                    </CustomCol>
                </CustomRow>
                {formState?.fields?.listBilletageName.length > 0 && (
                    <CustomRow>
                        <CustomCol className="offset-md-2 col-md-10 col-12">
                            <SimpleTable
                                formDispatcher={formDispatcher}
                                listSelectedItemName="listBilletageName"
                                identifier="id"
                                data={formState.fields.listBilletageName}
                                tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                filter={false}
                                bottomPagination={false}
                                isLinkToDetail={false}
                                mapper={[
                                    {
                                        field: 'devise.code',
                                        displayName: 'devise',
                                        position: 1
                                    },
                                    {
                                        field: 'coupure.code',
                                        displayName: 'coupure',
                                        position: 2
                                    },
                                    {
                                        field: 'nombre',
                                        displayName: 'nombre',
                                        position: 3
                                    }
                                ]}
                            />
                        </CustomCol>
                    </CustomRow>
                )}
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={handleSubmit}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={handleSubmit}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
