export const schema = [
    {
        description: 'montant',
        title: 'Montant',
        className: 'border-bottom'
    },
    {
        description: 'devise',
        title: 'Devise',
        className: 'border-bottom'
    },
    {
        description: 'deviseSollicite',
        title: 'Devise Sollicité',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'Création',
        className: 'border-bottom'
    }
];
