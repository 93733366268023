import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { URL_CONST } from '../../util';
import { GenericSelector } from './generic-selector';

/**
 * Selecteur des comptes des institutions financières
 * @param {Object} param0 Objet avec les paramètres suivants
 * @param {Function} param0.formDispatcher Dispatcher du formulaire
 * @param {Object} param0.formState Etat du formulaire
 *
 * @returns
 */

export function CompteInstitutionFinancierSelector({
    formDispatcher,
    formState,
    typeOrganisation = TYPE_ORGANISATION.SUPER_DEALER,
    propertyName = 'selectedCompteInstitutionFinanciere',
    label = 'Institution financière',
    listFormater = (data) =>
        data?.map((item) => ({
            ...item,
            designation: `${item?.institutionFinanciere?.designation || ''} - ${
                item?.valeurTypeProprietaire?.designation || ''
            } - ${item?.numero || ''} - ${item?.devise || ''}`
        }))
}) {
    return (
        <GenericSelector
            isDynamicNode
            dynamicNodeName={`compteInstitutionFinanciere-${typeOrganisation}`}
            functionName={URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION}
            formDispatcher={formDispatcher}
            formState={formState}
            label={label}
            propertyName={propertyName}
            listFormater={listFormater}
            payload={{
                typeProprietaire: typeOrganisation,
                estOrganisation: true,
                estAll: true
            }}
        />
    );
}
