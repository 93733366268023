import { TIME, TODAY, Add, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { CashCollecteurSelector } from '../../../../components';

const formater = (data) => {
    const result = [];
    (data?.[0]?.listReturn || []).forEach((item) => {
        result.push({
            id: item?.id,
            limit: item?.limit,
            solde: item?.solde,
            disponible: Add(item?.limit, item?.solde),
            nomComplet: `${item?.valeurTypeProprietaire?.prenom || ''} ${
                item?.valeurTypeProprietaire?.nom || ''
            }`,
            organisation: item?.valeurTypeOrganisation?.designation || ''
        });
    });
    return result;
};

export function RapportAgentCashCollecteurForm({ typeOrganisation }) {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCashCollecteur: {}
        }
    });
    return (
        <ReduxReportTemplate
            listTitle="Valeur exprimée en USD"
            dynamicNodeName={`reportsLimitCreditCashCollecteur-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_LIMIT_CREDIT_ORGANISATION}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            mapper={[
                {
                    displayName: 'Super dealer',
                    field: 'organisation'
                },
                {
                    displayName: 'Agent',
                    field: 'nomComplet'
                },
                {
                    displayName: 'Autorisé',
                    field: 'limit',
                    isTotal: true
                },
                {
                    displayName: 'Disponible',
                    field: 'disponible',
                    isTotal: true
                },
                {
                    displayName: 'Utilisé',
                    field: 'solde',
                    isTotal: true
                }
            ]}
            isAddTotalTr
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                estListReturn: true,
                estAll: true,
                typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR,
                typeOrganisation
            }}
            defaultPayloadMapperValues={{
                estListReturn: true,
                estAll: true,
                typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedCashCollecteur',
                    isRequired: false,
                    displayName: 'Cash collecteur',
                    callback: (value) => {
                        if (!value?.code || value?.code === 'all') return {};
                        return { codeValeurTypeProprietaire: value?.code };
                    }
                }
            ]}
        >
            <CashCollecteurSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                isAddAditionalOptions
            />
        </ReduxReportTemplate>
    );
}
