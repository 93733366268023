import { FILE_EXTENSION } from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomLabel,
    CustomRow,
    CustomSwitchButton,
    SelectAddTableComponent
} from '../../../../components';

export function FileTypeComponent({
    formState,
    formDispatcher,
    uiValidator,
    id,
    labelMultipleFilesClassName = 'col-md-2',
    labelRestrictedFilesClassName = 'col-md-2',
    divSwitchMultipleFilesClassName = 'col-md-2',
    divSwitchRestrictedFilesClassName = 'col-md-2',
    isMultipleFilesName = 'isMultipleFiles',
    isRestrictedFilesName = 'isRestrictedFiles',
    selectedTailleMaxName = 'selectedTailleMax',
    selectedFileExtensionName = 'selectedFileExtension',
    listSelectedFileExtensionName = 'listSelectedFileExtension',
    sizeOptions = [
        { code: '1Mo', designation: '1 Mo' },
        { code: '10Mo', designation: '10 Mo' },
        { code: '100Mo', designation: '100 Mo' },
        { code: '1Go', designation: '1 Go' }
    ],
    tailleMaxLabel = 'Taille max*',
    taillMaxLabelClassName = 'col-md-2',
    tailleMaxDivClassName = 'col-md-10',
    multipleFichiersLabel = 'Multiple fichiers',
    restrictedFileLabel = "N'autoriser que",
    divFileExtensionClassName = 'col-12 offset-md-2 col-md-10',
    fileExtensionOptions = [
        FILE_EXTENSION.AUDIO,
        FILE_EXTENSION.VIDEO,
        FILE_EXTENSION.IMAGE,
        FILE_EXTENSION.DOCUMENT,
        FILE_EXTENSION.PDF,
        FILE_EXTENSION.FEUILLE_CALCUL
    ],
    dropdownPropertyToDisplay = 'designation',
    mapper = [
        {
            displayName: 'Type',
            field: 'designation',
            position: 1
        }
    ],
    tableDivTableClassName = 'col-12 col-md-10'
}) {
    const { fields } = formState;
    return (
        <>
            <CustomRow>
                <CustomDropdown
                    options={sizeOptions}
                    label={tailleMaxLabel}
                    labelClassName={taillMaxLabelClassName}
                    divClassName={tailleMaxDivClassName}
                    formDispatcher={formDispatcher}
                    name={selectedTailleMaxName}
                    id={`${id}selectedTailleMaxFileTypeComponent`}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomCol className={labelMultipleFilesClassName}>
                    <CustomLabel
                        htmlFor={`${id}isMultipleFilesFileTypeComponent`}
                    >
                        {multipleFichiersLabel}
                    </CustomLabel>
                </CustomCol>
                <CustomCol className={divSwitchMultipleFilesClassName}>
                    <CustomSwitchButton
                        id={`${id}isMultipleFilesFileTypeComponent`}
                        name={isMultipleFilesName}
                        value={fields?.[isMultipleFilesName]}
                        formDispatcher={formDispatcher}
                        formState={formState}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className={labelRestrictedFilesClassName}>
                    <CustomLabel
                        htmlFor={`${id}isRestrictedFilesFileTypeComponent`}
                    >
                        {restrictedFileLabel}
                    </CustomLabel>
                </CustomCol>
                <CustomCol className={divSwitchRestrictedFilesClassName}>
                    <CustomSwitchButton
                        id={`${id}isRestrictedFilesFileTypeComponent`}
                        name={isRestrictedFilesName}
                        value={fields?.[isRestrictedFilesName]}
                        formDispatcher={formDispatcher}
                        formState={formState}
                    />
                </CustomCol>
            </CustomRow>
            <CustomCollapse
                isIn={formState.fields?.[isRestrictedFilesName]}
                className={divFileExtensionClassName}
            >
                <SelectAddTableComponent
                    options={fileExtensionOptions}
                    dropdownPropertyToDisplay={dropdownPropertyToDisplay}
                    formState={formState}
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    id={`${id}selectAddTableFileTypeComponent`}
                    selectedItemName={selectedFileExtensionName}
                    listSelectedItemName={listSelectedFileExtensionName}
                    mapper={mapper}
                    divTableClassName={tableDivTableClassName}
                />
            </CustomCollapse>
        </>
    );
}
