import React from 'react';
import { ListFormulaireDynamiqueSimFlashForm } from './list-formulaire-dynamique-sim-flash';

export function FormulaireDynamiqueSimFlashViewLayout() {
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Configuration Sim</h1>
                <div />
            </div>
            <ListFormulaireDynamiqueSimFlashForm />
        </div>
    );
}
