import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListConfigurationSenderForm() {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { isNapp } = useGetUserDetail({
        nappUser
    });
    const mapper = isNapp
        ? [
              {
                  field: 'sendeur.prenom',
                  displayName: 'Prenom',
                  position: 1
              },
              {
                  field: 'sendeur.nom',
                  displayName: 'Nom',
                  position: 2
              },
              {
                  field: 'marchand.designation',
                  displayName: 'Marchand',
                  position: 3
              },
              {
                  field: 'marchand.code',
                  displayName: 'Code marchand',
                  position: 4
              },
              {
                  field: 'zone.designation',
                  displayName: 'Zone',
                  position: 5
              },
              {
                  field: 'zone.niveau',
                  displayName: 'Niveau zone',
                  position: 6
              }
          ]
        : [
              {
                  field: 'sendeur.prenom',
                  displayName: 'Prenom',
                  position: 1
              },
              {
                  field: 'sendeur.nom',
                  displayName: 'Nom',
                  position: 2
              }
          ];
    return (
        <ListGenericForm
            reduxPropertyName="zoneAgentsenders"
            functionName={URL_CONST.GET_ALL_ZONE_SENDEUR}
            nodeName={REDUX_NODE_NAME.ZONE_AGENT_SENDERS}
            mapper={mapper}
        />
    );
}
