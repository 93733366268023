import React from 'react';
import { ListBudget } from '../common';

export function AmendementViewLayout() {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Amendement</h1>
            </div>
            <ListBudget />
        </>
    );
}
