import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListTotauxLivreesForm() {
    return (
        <ListGenericForm
            isLinkToDetail={false}
            reduxPropertyName="stocksARegulariser"
            functionName={URL_CONST.GET_LIST_STOCK_A_REGULARISER}
            nodeName={REDUX_NODE_NAME.STOCK_A_REGULARISER}
            formater={(data) =>
                data.map((item) => ({
                    ...item,
                    business: item?.typeBusiness?.join(' - '),
                    id: `${item?.marchand?.code}${item?.typeBusiness?.join(
                        ' - '
                    )}`
                }))
            }
            mapper={[
                {
                    displayName: 'Marchand',
                    field: 'marchand.designation'
                },
                {
                    displayName: 'Numéro',
                    field: 'numero'
                },
                {
                    displayName: 'Type business',
                    field: 'business'
                },
                {
                    displayName: 'Quantité',
                    field: 'quantite'
                },
                {
                    displayName: 'Prix total',
                    field: 'prixTotal'
                }
            ]}
        />
    );
}
