import {
    fetchElement,
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    FormWrapper,
    InputSearchGroup
} from '../../../components';

const defaultFields = {
    tillNumber: ''
};

export function CreationLigneCreditForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['revendeur']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements, form } = formState;
    const fetchRevendeur = () => {
        const { tillNumber } = fields;
        if (
            tillNumber &&
            tillNumber !== elements.revendeur?.value?.tillNumber
        ) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'revendeur',
                    payload: { tillNumber },
                    functionName: URL_CONST.GET_REVENDEUR_BY_TILL_NUMBER,
                    etat: elements.revendeur.status,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { revendeur } = elements;
        if (revendeur.status !== FORM_CONST.FETCH_ELEMENT_SUCCESS) return;
        const payload = {
            codeRevendeur: revendeur?.value?.code,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_LIGNE_CREDIT_DEALER,
                payload,
                fields,
                reduxNodeName: DB_NODE.LIGNE_CREDIT,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper formState={formState} formDispatcher={formDispatcher}>
            <InputSearchGroup
                formDispatcher={formDispatcher}
                formState={formState}
                isInputGroup={false}
                label="Till number*"
                labelClassName="col-12 col-sm-2"
                divClassName="col-10 col-sm-10"
                placeholder="Saisissez le tillNumber du dealer"
                name="tillNumber"
                id="tillNumberCreationLigneCredit"
                callback={fetchRevendeur}
                buttonDisabled={
                    elements.revendeur.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
            />
            <CustomRow>
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    {elements.revendeur.status ===
                        FORM_CONST.FETCH_ELEMENT_FAILED && (
                        <CustomAlert
                            error={`${elements.revendeur?.error || ''}`}
                        />
                    )}
                    {elements.revendeur.status ===
                        FORM_CONST.FETCH_ELEMENT_SUCCESS && (
                        <CustomAlert
                            successMessage={`${
                                elements.revendeur?.value?.tillNumber || ''
                            } - ${
                                elements.revendeur?.value?.designation || ''
                            }`}
                        />
                    )}
                </CustomCol>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
