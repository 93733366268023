import { BiFilterAlt } from 'react-icons/bi';
import { Accordion, Button, Offcanvas } from 'react-bootstrap';
import { useState } from 'react';
import { fromUtcTimestampToLocalString } from '@napp-inc/jnapp-util';
import {
    CustomCol,
    SimpleTable,
    ReportDateRange,
    CustomButtonLoader
} from '../../../../components';
import { formatNumber } from '../../../../util';

export function MainReportComponentLarge({
    formState,
    formDispatcher,
    fetchReport,
    children,
    customFilter,
    dataList,
    dataListDetaille,
    mapper,
    mapperDetaillee,
    isFetching,
    isDetaille = true,
    isDataFetched,
    isCommandeLitigieuse = false,
    isCommandeLitigieuseEmoney = false,
    objectChecker,
    commandes
}) {
    const offcanvasOptions = {
        name: 'Enable backdrop (default)',
        scroll: true,
        backdrop: true,
        placement: 'end'
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    return (
        <div className="container-fluid">
            <div className="d-flex flex-row justify-content-end mb-3">
                <Button
                    variant="outline-dark"
                    onClick={toggleShow}
                    className="me-2"
                >
                    Rechercher <BiFilterAlt />
                </Button>
            </div>
            <Offcanvas
                show={show}
                onHide={handleClose}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...offcanvasOptions}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filtres</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-3">
                    <CustomCol className="col-12">
                        <ReportDateRange
                            formState={formState}
                            formDispatcher={formDispatcher}
                        />
                    </CustomCol>
                    <CustomCol className="col-12">
                        {children}
                        {customFilter && typeof customFilter === 'function'
                            ? customFilter({
                                  formState,
                                  formDispatcher
                              })
                            : null}
                    </CustomCol>
                    <CustomCol className="col-12 mt-2">
                        <CustomButtonLoader
                            onClick={fetchReport}
                            disabled={isFetching}
                            isActionEncours={isFetching}
                            text="Rechercher"
                        >
                            Rechercher
                        </CustomButtonLoader>
                    </CustomCol>
                </Offcanvas.Body>
            </Offcanvas>
            <div>
                {objectChecker &&
                    Object.keys(objectChecker).map((key, index) => {
                        const commande = commandes?.find(
                            (cmd) => cmd.id === key
                        );
                        const nom = `${commande?.client?.prenom || ''} ${
                            commande?.client?.nom || ''
                        }`;
                        const shop = commande?.client?.designation;
                        const numeroContact = commande?.client?.numeroContact;
                        const numeroFlash = `${commande?.numero || '---'}`;
                        const user = `${commande?.user?.prenom || ''} ${
                            commande?.user?.nom || '---'
                        }`;
                        const dateCreation = fromUtcTimestampToLocalString(
                            commande?.dateCreation
                        );
                        const nombre = objectChecker[key]?.list?.length || 0;
                        return (
                            <Accordion defaultActiveKey={0} key={key}>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>
                                        <span className="m-1 text-danger fw-bold">
                                            {`Quantité : ${formatNumber({
                                                value:
                                                    objectChecker[key]
                                                        ?.sortie || 0
                                            })}`}
                                            ,
                                        </span>
                                        <span className="m-1">
                                            {numeroFlash},
                                        </span>
                                        <span className="m-1">
                                            {`(#${nombre} fois)`},
                                        </span>
                                        <span className="m-1">
                                            {dateCreation}
                                        </span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <dl>
                                            <dt>Client</dt>
                                            <dd>{nom || '---'}</dd>
                                            <dt>Shop</dt>
                                            <dd>{shop || ''}</dd>
                                            <dt>Téléphone</dt>
                                            <dd>{numeroContact || ''}</dd>
                                            <dt>Agent</dt>
                                            <dd>{user || ''}</dd>
                                        </dl>
                                        <SimpleTable
                                            isDsiplayEmptyTableNotification={
                                                isDataFetched
                                            }
                                            isDisplaySearch
                                            tableClassName=""
                                            isShowDropButton={false}
                                            data={objectChecker[key]?.list}
                                            identifier="id"
                                            mapper={mapper}
                                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                            filter
                                            bottomPagination={false}
                                            isLinkToDetail={false}
                                            isCommandeLitigieuse={
                                                isCommandeLitigieuse
                                            }
                                            isCommandeLitigieuseEmoney={
                                                isCommandeLitigieuseEmoney
                                            }
                                            formState={formState}
                                            formDispatcher={formDispatcher}
                                            isExportAllowed
                                            isRefetchAllowed={false}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        );
                    })}
            </div>
            {!objectChecker && (
                <div>
                    {isDetaille ? (
                        <SimpleTable
                            isDsiplayEmptyTableNotification={isDataFetched}
                            isDisplaySearch
                            tableClassName=""
                            isShowDropButton={false}
                            data={dataListDetaille}
                            identifier="id"
                            mapper={mapperDetaillee}
                            isExportAllowed
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter
                            bottomPagination={false}
                            isLinkToDetail={false}
                            isCommandeLitigieuse={isCommandeLitigieuse}
                            isCommandeLitigieuseEmoney={
                                isCommandeLitigieuseEmoney
                            }
                            formState={formState}
                            formDispatcher={formDispatcher}
                        />
                    ) : null}
                    {!isDetaille ? (
                        <SimpleTable
                            isDsiplayEmptyTableNotification={isDataFetched}
                            isDisplaySearch
                            tableClassName=""
                            isShowDropButton={false}
                            data={dataList}
                            identifier="id"
                            mapper={mapper}
                            isExportAllowed
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter
                            bottomPagination={false}
                            isLinkToDetail={false}
                            isCommandeLitigieuse={isCommandeLitigieuse}
                            isCommandeLitigieuseEmoney={
                                isCommandeLitigieuseEmoney
                            }
                            formDispatcher={formDispatcher}
                            formState={formState}
                        />
                    ) : null}
                </div>
            )}
        </div>
    );
}
