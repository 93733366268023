import { DB_NODE, ONLY_FETCH, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { ViewLayout } from './view-layout';

export function ListeSalleManger() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                fetchType={ONLY_FETCH}
                reduxPropertyName="salleManger"
                functionName={URL_CONST.GET_LIST_SALLE_MANGER}
                nodeName={DB_NODE.SALLE_MANGER_TABLE}
                mapper={[
                    {
                        field: 'id',
                        displayName: 'id',
                        position: 1
                    },
                    {
                        field: 'etat',
                        displayName: 'Etat',
                        position: 3
                    },
                    {
                        field: 'typeEntite',
                        displayName: 'Type',
                        position: 2
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
