import {
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListTauxActifForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, organisation } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation
    }));
    const filterToConnect = (typeEntite) => {
        let filters = [];
        if (typeEntite !== TYPE_ORGANISATION.NAPP) {
            filters = filters.concat([
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'typeEntite',
                    value: nappUser?.typeEntite,
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'valeurTypeEntite.code',
                    value: organisation?.entreprise?.code,
                    isRequired: true
                }
            ]);
        } else if (typeEntite === TYPE_ORGANISATION.NAPP) {
            filters = filters.concat([
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'typeEntite',
                    value: nappUser?.typeEntite,
                    isRequired: true
                }
            ]);
        }

        if (
            typeEntite !== TYPE_ORGANISATION.NAPP ||
            typeEntite === TYPE_ORGANISATION.NAPP
        ) {
            filters = filters.concat([
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'estActif',
                    value: true,
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.ORDER_BY,
                    property: 'dateDerniereModification'
                },
                {
                    type: FILTER_TYPE.START_AT,
                    value: TODAY.nowUtc()
                }
            ]);
        }
        return filters;
    };
    const mapperToSend = (typeEntite) => {
        let mapperGenerated = [];
        if (typeEntite !== TYPE_ORGANISATION.NAPP) {
            mapperGenerated = mapperGenerated.concat([
                {
                    field: 'devise',
                    displayName: 'devise'
                },
                {
                    field: 'valeur',
                    displayName: 'valeur'
                },
                {
                    field: 'type',
                    displayName: 'Type'
                }
            ]);
        } else if (typeEntite === TYPE_ORGANISATION.NAPP) {
            mapperGenerated = mapperGenerated.concat([
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'Organisation'
                },
                {
                    field: 'typeEntite',
                    displayName: 'type Organisation'
                },
                {
                    field: 'devise',
                    displayName: 'devise'
                },
                {
                    field: 'valeur',
                    displayName: 'valeur'
                },
                {
                    field: 'type',
                    displayName: 'Type'
                }
            ]);
        }
        return mapperGenerated;
    };
    return (
        <ListGenericForm
            isLinkToDetail={false}
            fetchType={FETCH_LISTENING}
            reduxPropertyName="tauxActifs"
            functionName={URL_CONST.GET_LIST_TAUX_ACTIF_ORGANISATION}
            nodeName={REDUX_NODE_NAME.TAUX_ACTIFS}
            path={DB_NODE.TAUX}
            mapper={mapperToSend(nappUser?.typeEntite)}
            listenerFilters={filterToConnect(nappUser?.typeEntite)}
        />
    );
}
