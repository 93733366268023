import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    TYPE_IMPUTATION_RESSOURCE,
    TYPE_IMPUTATION_CHARGE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomLabel,
    CustomRadioButton,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { CompteImputationSchema as uiValidator } from './validation';

const defaultFields = {
    code: '',
    designation: '',
    selectedType: TYPE_IMPUTATION_CHARGE
};

export function CreationCompteImputationForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const { code, designation, selectedType } = fields;
        const payload = {
            newCompteImputation: {
                code,
                designation,
                typeEntite: selectedType
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_COMPTE_IMPUTATION,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.COMPTE_IMPUTATION,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomLabel text="Type*" className="col-12 col-md-2" />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3"
                    label="Charges"
                    id="typeExecutableCreationCharge"
                    name="selectedType"
                    value={TYPE_IMPUTATION_CHARGE}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3"
                    label="Ressources"
                    id="typeNonExecutableCreationCharge"
                    name="selectedType"
                    value={TYPE_IMPUTATION_RESSOURCE}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Code*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le code"
                    formDispatcher={formDispatcher}
                    name="code"
                    id="codeCreationFonction"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Designation*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le nom"
                    formDispatcher={formDispatcher}
                    name="designation"
                    id="designationCreationFonction"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
