import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailVersionApplicationForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'versionApplication',
            defaultFields: {
                actionEncours: ''
            }
        });

    const { fields, form } = formState;
    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payloadToAdd = {};

        if (entity?.etat === ETAT.ETAT_VALIDER) {
            payloadToAdd.estCheckEstActif = true;
            payloadToAdd.estActif = !entity.estActif;
        }
        if (etat && entity?.etat === ETAT.ETAT_ENCOURS) {
            payloadToAdd.etat = etat;
            payloadToAdd.estCheckEstActif = false;
        }
        const payload = {
            idVersionApplication: entity?.id,
            ...payloadToAdd
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_VERSION_APPLICATION,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.VERSION_APPLICATION,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_VERSION_APPLICATION_BY_ID}
            nodeName={REDUX_NODE_NAME.VERSION_APPLICATION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle=""
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .APPLICATION_MOBILE
                        .VALIDER_VERSION_OBSOLETE_APPLICATION_MOBILE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .APPLICATION_MOBILE
                        .MISE_A_JOUR_VERSION_OBSOLETE_APPLICATION_MOBILE
                ]}
            >
                <CustomRow>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                    >
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    <CustomCol className="col-3 col-md-2">
                        {entity?.etat === ETAT.ETAT_ENCOURS ? (
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        ) : null}
                        {entity?.etat === ETAT.ETAT_VALIDER ? (
                            <CustomConfirmButton
                                type="button"
                                text={`${
                                    entity?.estActif ? 'Désactiver' : 'Activer'
                                }`}
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        ) : null}
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
