import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { MdGpsFixed } from 'react-icons/md';
import { AiOutlineBarChart } from 'react-icons/ai';
import { CustomPopover, ShowNewComponent } from '../../../components';
import {
    ReevaluationChargeFixeCreateRelPath,
    ReevaluationChargeVariableCreateRelPath
} from '../../../routes/registration/configuration/config-napp-rel-path';

export function ViewLayout({ children }) {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Réévaluation Charges</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <CustomPopover
                            trigger={['click']}
                            variant="primary"
                            text=" + Créer"
                            direction="bottom"
                        >
                            <ShowNewComponent
                                className="btn"
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                        .INITIER_REEVALUATION_CHARGE_FIXE
                                ]}
                                text="Charge Fixe"
                                icon={<MdGpsFixed className="me-1" />}
                                destination={
                                    ReevaluationChargeFixeCreateRelPath.path
                                }
                            />
                            <div className="border-bottom" />
                            <ShowNewComponent
                                className="btn"
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                        .INITIER_REEVALUATION_CHARGE_VARIABLE
                                ]}
                                text="Charge Variable"
                                icon={<AiOutlineBarChart className="me-1" />}
                                destination={
                                    ReevaluationChargeVariableCreateRelPath.path
                                }
                            />
                        </CustomPopover>
                    </div>
                </div>
            </div>
            {children}
        </>
    );
}
