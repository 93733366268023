import * as yup from 'yup';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    // PROFIL_SYSTEM,
    // CODE_SERVICE,
    TYPE_ORGANISATION,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
// import { useHasService } from '../../../util';
import { useHasService } from '../../../util';
import { RegularisationCaisseForm } from './creation-form';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    selectedImputation: {},
    selectedCaisse: {},
    selectedSuperDealer: {},
    montant: 0,
    numeroReference: '',
    selectedRaison: {}
};

let defaultDependencies = { fields: ['caisses', 'imputations'] };

const setDefaultProperties = ({ type, service }) => {
    const payload = {
        caisseNodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
        caisseReduxProperty: 'caissesActiveUser'
    };
    const conditions = {};
    /** Non caissier */
    if (
        type === TYPE_ORGANISATION.NAPP &&
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_CREATION_REGULARISATION_CAISSE?.code
        ] &&
        !service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT?.code
        ] &&
        !service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DECAISSEMENT?.code
        ]
    ) {
        /** Dependencies */
        defaultDependencies = {
            fields: ['caisses', 'allImputation', 'superDealer']
        };
        /** Caisses */
        payload.caisses = {
            estOrganisation: true,
            estOperation: true,
            typeProprietaire: 'SuperDealer',
            estAll: true
        };
        payload.caisseNodeName = REDUX_NODE_NAME.ALL_CAISSE_SUPER_DEALER;
        payload.caisseReduxProperty = 'allCaissesSuperDealer';

        /** SuperDealers */
        payload.superDealerNodeName = REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB;
        payload.superDealerReduxProperty = 'allSuperDealerKinDB';
        payload.superDealer = {
            typeEntite: type,
            estAll: true
        };

        /** Condition pour savoir si on récupère tous les superDealers */
        conditions.isAll = true;

        /** Imputations */
        payload.imputationDynamicNode = 'allImputation';
        payload.imputation = {
            typeProprietaire: 'SuperDealer',
            estAll: true
        };
    }

    /** Caissier */
    if (
        type === TYPE_ORGANISATION.NAPP &&
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_CREATION_REGULARISATION_CAISSE?.code
        ] &&
        (service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT?.code
        ] ||
            service[
                CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                    .INITIER_DECAISSEMENT?.code
            ])
    ) {
        /** Dependencies */
        defaultDependencies = {
            fields: ['caisses', 'allImputation', 'superDealer']
        };
        /** Caisses */
        payload.caisses = { estOrganisation: false, estOperation: true };
        payload.caisseNodeName = REDUX_NODE_NAME.ALL_CAISSE_ACTIVE_USER;
        payload.caisseReduxProperty = 'allCaissesActiveUser';

        /** SuperDealers */
        payload.superDealerNodeName = REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB;
        payload.superDealerReduxProperty = 'allSuperDealerKinDB';
        payload.superDealer = {
            typeEntite: type,
            estAll: true
        };
        /** Condition pour savoir si on récupère tous les superDealers */
        conditions.isAll = true;

        /** Imputations */
        payload.imputationDynamicNode = 'allImputation';
        payload.imputation = {
            typeProprietaire: 'SuperDealer',
            estAll: true
        };
    }
    const yupValidation = yup.object().shape({
        montant: yup.number(),
        selectedImputation: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedCaisse: yup.object().shape({
            id: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedRaison: yup.object().shape({
            code: yup.string(),
            designation: yup.string()
        }),
        numeroReference: yup.string()
    });
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                montant: yup.number(),
                selectedImputation: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedCaisse: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedRaison: yup.object().shape({
                    code: yup.string(),
                    designation: yup.string()
                }),
                selectedSuperDealer: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                numeroReference: yup.string()
            }),
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yupValidation,
            cfPayload: payload,
            cfCondition: conditions
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yupValidation,
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yupValidation,
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({}),
        cfPayload: {},
        cfCondition: {}
    };
};

export function CreationRegularisationCaisseEmoneyForm() {
    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_CREATION_REGULARISATION_CAISSE,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DECAISSEMENT
        ]
    });
    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser,
        organisation
    });

    const {
        fields,
        dependencies,
        elements,
        uiValidator,
        cfPayload,
        cfCondition
    } = setDefaultProperties({
        type: typeEntite,
        service: hasService
        // profil: profilUser
    });
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });
    const renderContent = () =>
        initialState.dependencies && cfPayload.caisseNodeName ? (
            <RegularisationCaisseForm
                idToken={idToken}
                typeEntite={typeEntite}
                uiValidator={uiValidator}
                initialState={initialState}
                hasService={hasService}
                cfPayload={cfPayload}
                cfCondition={cfCondition}
            />
        ) : null;
    return renderContent();
}
