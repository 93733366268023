export const ListTypeOrganisation = [
    {
        code: `Napp`,
        designation: `Napp`
    },
    {
        code: `CashCollecteur`,
        designation: `Cash collecteur`
    },
    {
        code: `EndUser`,
        designation: `Utilisateur Final`
    },
    {
        code: `AgentLivreur`,
        designation: `Agent Livreur`
    },
    {
        code: `Revendeur001`,
        designation: `Revendeur`,
        valeurTypeEntite: `DEALER`,
        valeurTypeEntiteDesignation: `Dealer`
    },
    {
        code: `Revendeur002`,
        designation: `Revendeur`,
        valeurTypeEntite: `POINT_VENTE`,
        valeurTypeEntiteDesignation: `Point de vente`
    },
    {
        code: `Distributeur001`,
        designation: `Distributeur`,
        valeurTypeEntite: `Distributeur`
    },
    {
        code: `SuperDealer001`,
        designation: `SuperDealer`,
        valeurTypeEntite: `Emoney`,
        valeurTypeEntiteDesignation: `Emoney`
    },
    {
        code: `SuperDealer002`,
        designation: `SuperDealer`,
        valeurTypeEntite: `Electronique`,
        valeurTypeEntiteDesignation: `Airtime`
    },
    {
        code: `Marchand001`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_MOBILE_MONEY`,
        valeurTypeEntiteDesignation: `Emoney`
    },
    {
        code: `Marchand002`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_MARC_AIRTIME`,
        valeurTypeEntiteDesignation: `Airtime`
    },
    {
        code: `Marchand003`,
        designation: `Marchand`,
        valeurTypeEntite: `ModuleAssise`,
        valeurTypeEntiteDesignation: `Module dAssise`
    },
    {
        code: `Marchand004`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_COMMERCE_GENERAL`,
        valeurTypeEntiteDesignation: `Commerce Général`,
        typeOrganisation: `OKAPI_TYP_ORG_PROFESIONNELLE`,
        typeOrganisationDesignation: `Professionnelle`
    },
    {
        code: `Marchand005`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_COMMERCE_GENERAL`,
        valeurTypeEntiteDesignation: `Commerce Général`,
        typeOrganisation: `OKAPI_TYP_ORG_PROXIMITE`,
        typeOrganisationDesignation: `Proximité`,
        niveauOrganisation: 1
    },
    {
        code: `Marchand006`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_COMMERCE_GENERAL`,
        valeurTypeEntiteDesignation: `Commerce Général`,
        typeOrganisation: `OKAPI_TYP_ORG_PROXIMITE`,
        typeOrganisationDesignation: `Proximité`,
        niveauOrganisation: 2
    },
    {
        code: `Marchand007`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_COMMERCE_GENERAL`,
        valeurTypeEntiteDesignation: `Commerce Général`,
        typeOrganisation: `OKAPI_TYP_ORG_PROXIMITE`,
        typeOrganisationDesignation: `Proximité`,
        niveauOrganisation: 3
    },
    {
        code: `Marchand008`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_COMMERCE_GENERAL`,
        valeurTypeEntiteDesignation: `Commerce Général`,
        typeOrganisation: `OKAPI_TYP_ORG_PROXIMITE`,
        typeOrganisationDesignation: `Proximité`,
        niveauOrganisation: 4
    },
    {
        code: `Marchand009`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_RESTAURANT`,
        valeurTypeEntiteDesignation: `Commerce Restaurant`,
        typeOrganisation: `OKAPI_TYP_ORG_PROFESIONNELLE`,
        typeOrganisationDesignation: `Professionnelle`
    },
    {
        code: `Marchand010`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_RESTAURANT`,
        valeurTypeEntiteDesignation: `Commerce Restaurant`,
        typeOrganisation: `OKAPI_TYP_ORG_PROXIMITE`,
        typeOrganisationDesignation: `Proximité`,
        niveauOrganisation: 1
    },
    {
        code: `Marchand011`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_RESTAURANT`,
        valeurTypeEntiteDesignation: `Commerce Restaurant`,
        typeOrganisation: `OKAPI_TYP_ORG_PROXIMITE`,
        typeOrganisationDesignation: `Proximité`,
        niveauOrganisation: 2
    },
    {
        code: `Marchand012`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_RESTAURANT`,
        valeurTypeEntiteDesignation: `Commerce Restaurant`,
        typeOrganisation: `OKAPI_TYP_ORG_PROXIMITE`,
        typeOrganisationDesignation: `Proximité`,
        niveauOrganisation: 3
    },
    {
        code: `Marchand013`,
        designation: `Marchand`,
        valeurTypeEntite: `OKAPI_TYP_RESTAURANT`,
        valeurTypeEntiteDesignation: `Commerce Restaurant`,
        typeOrganisation: `OKAPI_TYP_ORG_PROXIMITE`,
        typeOrganisationDesignation: `Proximité`,
        niveauOrganisation: 4
    }
];
