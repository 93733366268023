import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    DroitAccesCreateRelPath,
    DroitAccesViewLayoutRelPath
} from '../../../routes/registration/administration/admin-rel-path';
import { ListDroitAccesForm } from './list-droit-acces';

export function DroitAccesViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Droit d&#x27;accès</h1>
                <div className="btn-group me-2">
                    {pathname === `/${DroitAccesViewLayoutRelPath.path}` && (
                        <ShowNewComponent
                            services={[
                                { code: 'test' },
                                CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                    .CODE_SERVICE_MODULE_ADMINISTRATION
                                    .INITIER_CREATION_AGENT
                            ]}
                            destination={DroitAccesCreateRelPath.path}
                        />
                    )}
                </div>
            </div>
            <ListDroitAccesForm />
        </div>
    );
}
