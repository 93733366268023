import * as yup from 'yup';

export const CreationGroupeAbonneSchema = yup.object().shape({
    selectedRemise: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    acompte: yup.string().required(),
    echeance: yup.string().required(),
    nombreEcheanceGlobal: yup.number().min(1),
    nombreEcheanceClient: yup.number().min(1),
    dateFinEcheanceGlobal: yup.date(),
    dateFinEcheanceClient: yup.date(),
    montantAVersetEcheanceGlobal: yup.number().min(1),
    montantAVersetEcheanceClient: yup.number().min(1),
    pourcentageAcompteGlobal: yup.number().min(20),
    pourcentageAcompteClient: yup.number().min(1),
    selectedClientAcompte: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedClientEcheance: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectClientLimite: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    montantLimiteClient: yup.number().min(1),
    montantLimiteGlobal: yup.number().min(1),
    tauxInteret: yup.number().min(1),
    penaliteJournaliere: yup.number().min(1)
});
