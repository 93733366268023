import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListProduitForm() {
    return (
        <ListGenericForm
            reduxPropertyName="produits"
            functionName={URL_CONST.GET_LIST_PRODUIT_MARCHAND_TYPE_AIRTIME}
            nodeName={DB_NODE.PRODUIT}
            mapper={[
                {
                    field: 'valeurTypePartenariat.designation',
                    displayName: 'Marchand',
                    position: 1
                },
                {
                    field: 'valeurTypePartenariat.code',
                    displayName: 'Code marchand',
                    position: 2
                },
                {
                    field: 'code',
                    displayName: 'Code',
                    position: 3
                },
                {
                    field: 'designation',
                    displayName: 'Désignation',
                    position: 4
                },
                {
                    field: 'prixVente',
                    displayName: 'Prix de vente',
                    position: 5
                },
                {
                    field: 'prixAchat',
                    displayName: "Prix d'achat",
                    position: 6
                },
                {
                    field: 'prixAchatSuperDealer',
                    displayName: 'Prix super dealer',
                    position: 7
                }
            ]}
        />
    );
}
