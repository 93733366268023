import { DB_NODE, MOYEN_PAIEMENT, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListCrediterCompteForm() {
    return (
        <ListGenericForm
            reduxPropertyName="creditersCompte"
            functionName={URL_CONST.GET_LIST_CREDITER_COMPTE_INITIALS}
            payload={{
                typeEntite: MOYEN_PAIEMENT.CASH,
                estOrganisation: true
            }}
            nodeName={DB_NODE.CREDITER_COMPTE}
            mapper={[
                {
                    field: 'numeroTransaction',
                    displayName: 'Numéro Transaction'
                },
                {
                    field: 'devise',
                    displayName: 'Devise',
                    isKeepFormat: true
                },
                {
                    field: 'deviseSollicite',
                    displayName: 'Devise Sollicité',
                    isKeepFormat: true
                },
                {
                    field: 'montant',
                    displayName: 'Montant'
                },
                {
                    field: 'valeurTypeProprietaire.designation',
                    displayName: 'Revendeur',
                    isKeepFormat: true
                },
                {
                    field: 'valeurTypeProprietaire.tillNumber',
                    displayName: 'Till_Number'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ]}
        />
    );
}
