import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    DeclarationRessourceCreateRelPath,
    DeclarationRessourceRelPath
} from '../../../routes/registration/configuration/config-napp-rel-path';

export function ViewLayout({ children }) {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Declaration Ressource</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${DeclarationRessourceRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                        .INITIER_DECLARATION_RESSOURCE
                                ]}
                                destination={
                                    DeclarationRessourceCreateRelPath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            {children}
        </>
    );
}
