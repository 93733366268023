import {
    BENEFICIAIRE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY
} from '@napp-inc/jnapp-util';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import { REDUX_NODE_NAME } from '../../../redux';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListCashbookForm() {
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    /**
     * Common form processes
     */
    const { uid } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    return (
        <ListGenericForm
            isLinkToDetail={false}
            fetchType={FETCH_LISTENING}
            reduxPropertyName="cashBookUser"
            functionName={URL_CONST.GET_LIST_CASH_BOOK_USER}
            nodeName={REDUX_NODE_NAME.CASH_BOOK_USER}
            path={DB_NODE.CASH_BOOK}
            payload={{
                dateDebut: TODAY.at000000(),
                dateFin: TODAY.at235959()
            }}
            mapper={[
                {
                    field: 'devise',
                    displayName: 'devise'
                },
                {
                    field: 'entree',
                    displayName: 'entree'
                },
                {
                    field: 'sortie',
                    displayName: 'sortie'
                },
                {
                    field: 'solde',
                    displayName: 'solde'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
            listenerFilters={[
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'typeEntite',
                    value: BENEFICIAIRE?.USER?.code,
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'valeurTypeEntite.reference',
                    value: uid,
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'date',
                    value: TODAY.at000000(),
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.ORDER_BY,
                    property: 'dateDerniereModification'
                },
                {
                    type: FILTER_TYPE.START_AT,
                    value: TODAY.nowUtc()
                }
            ]}
        />
    );
}
