import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    GENERIC_ACTIONS_TYPE,
    ONLY_FETCH
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CenteredSpinner,
    CustomAlert,
    CustomCol,
    CustomRow,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';

export const DetailChargeFonctionEmploye = ({ codeFonction, typeContrat }) => {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, dynamicNode, dynamicEtatNode } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            dynamicNode: reduxState.dynamicNode,
            dynamicEtatNode: reduxState.dynamicEtatNode
        })
    );
    const dynamicNodeName = `${DB_NODE.FONCTION}-detail-charge-${codeFonction}-${typeContrat}`;
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                fetchType: ONLY_FETCH,
                functionName: URL_CONST.GET_LIST_DETAIL_FONCTION_TYPE_CONTRAT,
                dynamicNodeName,
                isDynamicNode: true,
                payload: {
                    codeFonction,
                    typeContrat
                },
                etat: dynamicEtatNode[dynamicNodeName]?.etat
            }
        ]
    });
    const renderContent = () => {
        if (
            dynamicEtatNode[dynamicNodeName]?.etat ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <CustomRow>
                    <CustomCol className="offset-md-2 col-md-10 col-12">
                        <SimpleTable
                            isShowDropButton={false}
                            identifier="id"
                            data={dynamicNode[dynamicNodeName]}
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    field: 'typeContrat',
                                    displayName: 'Type contrat',
                                    position: 1
                                },
                                {
                                    field: 'charge.designation',
                                    displayName: 'Charge',
                                    position: 2
                                },
                                {
                                    field: 'montant',
                                    displayName: 'Montant',
                                    position: 3
                                }
                            ]}
                        />
                    </CustomCol>
                </CustomRow>
            );
        }
        if (
            dynamicEtatNode[dynamicNodeName]?.etat ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return <CustomAlert error="une erreur est survenue" />;
        }
        return <CenteredSpinner />;
    };
    return renderContent();
};
