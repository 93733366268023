import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { DetailWrapper } from '../../../components';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailTypeStockForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'typesStock' });
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TYPE_STOCK_BY_ID}
            nodeName={DB_NODE.TYPE_STOCK}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Type Stock"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        />
    );
    return renderContent();
}
