import * as yup from 'yup';

/**
 * @description : Schema de validation pour la creation des comptes parallèles bancaires
 */

export const CompteExterneBancaireSchema = yup.object().shape({
    intituleCompte: yup.string().required(),
    numeroCompte: yup.string().required(),
    selectedType: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedPays: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedBanque: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedDevise: yup.object().shape({
        id: yup.string().required(),
        devise: yup.string().required()
    }),
    nom: yup.string().required(),
    postnom: yup.string(),
    prenom: yup.string().required(),
    email: yup.string().email().required(),
    soldeCritique: yup.number(),
    soldeMedian: yup.number(),
    soldeSecurite: yup.number()
});
