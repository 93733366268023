export const schema = [
    {
        title: 'Type',
        description: 'typeEntite',
        className: 'border-bottom'
    },
    {
        title: 'Valeur',
        description: 'valeurTypeEntite.designation',
        className: 'border-bottom'
    },
    {
        title: 'Mode de Retraçage',
        description: 'modeRetracage',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
