import * as yup from 'yup';

/**
 * @description : Schema de Change Guichet form
 */

/**
 * selectMonnaieEntree: {},
    selectMonnaieSortie: {},
    montant: 0,
    montantSortie: 0,
    isTauxSollicite: false,
    tauxSollicite: 0
 */
export const ChangeGuichetFormSchema = yup.object().shape({
    isTauxSollicite: yup.boolean(),
    tauxSollicite: yup.number().when('isTauxSollicite', {
        is: true,
        then: (schema) => schema.min(1),
        otherwise: (schema) => schema.min(0)
    }),
    montant: yup.number().min(1),
    montantSortie: yup.number().min(0),
    selectMonnaieEntree: yup.object().shape({
        id: yup.string().required(),
        devise: yup.string().required()
    }),
    selectMonnaieSortie: yup.object().shape({
        id: yup.string().required(),
        devise: yup.string().required()
    }),
    list: yup.array().when('isTauxSollicite', {
        is: true,
        then: yup.array().of(
            yup
                .object({
                    devise: yup.string().required(),
                    valeur: yup.number().required()
                })
                .shape({})
        ),
        otherwise: yup.array().of(
            yup
                .object({
                    devise: yup.string(),
                    valeur: yup.number()
                })
                .shape({})
        )
    })
});
