import { fromTimestampToString } from '@napp-inc/jnapp-util';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CustomButton } from '../button';
import logo from '../../assets/images/logo.jpg';
import { CustomRow } from './row';
import { CustomCol } from './col';

export function PrintWrapper({ children }) {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });
    return (
        <>
            <CustomButton
                className="btn-secondary d-none d-sm-block"
                onClick={handlePrint}
            >
                Imprimer
            </CustomButton>
            <div style={{ display: 'none', padding: '4em' }}>
                <div className="p-2" ref={componentRef}>
                    <CustomRow>
                        <CustomCol className="col-2">
                            <div
                                style={{
                                    width: '210px',
                                    height: '210px',
                                    position: 'relative',
                                    overflow: 'hidden'
                                }}
                            >
                                <img
                                    src={logo}
                                    alt="logo j-napps"
                                    className="float-start company-logo"
                                />
                            </div>
                        </CustomCol>
                        {/* <CustomCol className='col-6' /> */}
                        <CustomCol className="offset-sm-6 col-4">
                            J-napps
                            <br />
                            email : contact@j-napps.com
                            <br />
                            Tél : (+243) 82 58 698
                            <br />
                            Date : {fromTimestampToString(Date.now())}
                            <br />
                        </CustomCol>
                    </CustomRow>
                    <br />
                    {children}
                </div>
            </div>
        </>
    );
}
