import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST, utc000000 } from '@napp-inc/jnapp-util';
import { ref, uploadBytes } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomInputDate,
    CustomInputFile,
    CustomRow,
    FormWrapper
} from '../../../components';
import { relevePaiementActivateur } from '../../../firebase';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';
import { PaiementVendorsFormSchema as uiValidator } from './validation';

// releveBancaire

/**
 * @desc defaultFields
 */
const defaultFields = {
    file: '',
    fileStatus: '',
    selectedConfiguration: {},
    nombreMois: 0,
    dateDebut: ''
};

/**
 * @desc defaultFiles
 */
const defaultFiles = ['documentPresentation'];

/**
 *
 * @desc defaultDependencies
 */
const defaultDependencies = { fields: ['configurationPartenaire'] };

export function CreationActivateurForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        configurationPartenaire,
        etatConfigurationPartenaire
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        configurationPartenaire: reduxState.configurationPartenaire.map(
            (item) => ({
                ...item,
                designation: `${item?.marchand?.designation}`
            })
        ),
        etatConfigurationPartenaire:
            reduxState.etat.configurationPartenaire.etat
    }));
    /**
     * Common form processes
     */
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        files: defaultFiles,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_CONFIGURATION_PARTENAIRE_ORGANISATION,
                nodeName: REDUX_NODE_NAME.CONFIGURATION_PARTENAIRE,
                etat: etatConfigurationPartenaire
            }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!fields.selectedConfiguration.id) return;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'fileStatus',
                data: 'UPLOAD_PENDING'
            }
        });
        uploadBytes(
            ref(relevePaiementActivateur, fields.file.name),
            fields.file
        )
            .then((response) => {
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'fileStatus',
                        data: 'UPLOAD_FINISHED'
                    }
                });
                const payload = {
                    fileName: response.metadata.name,
                    nombreMois: fields.nombreMois,
                    idConfigurationPartenaire: fields.selectedConfiguration.id,
                    dateDebut: utc000000(fields.dateDebut),
                    creator
                };
                if (response.metadata.name) {
                    formDispatcher(
                        submitPostAction({
                            idToken,
                            functionName:
                                URL_CONST.POST_TRAITEMENT_PAIEMENT_VENDEUR,
                            payload,
                            fields,
                            reduxNodeName:
                                REDUX_NODE_NAME.UPLOAD_TRAITEMENT_PAIEMENT_VENDEUR,
                            reduxDispatcher,
                            uiValidator
                        })
                    );
                }
            })
            .catch((error) => {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: error,
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
            });
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'configurationPartenaire',
                    etat: etatConfigurationPartenaire
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={configurationPartenaire}
                    propertyToDisplay="designation"
                    idProperty="id"
                    label="Marchand"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="Sélectionner un Marchand"
                    formDispatcher={formDispatcher}
                    name="selectedConfiguration"
                    id="selectedConfigurationCreationActivateurId"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    // isFloat
                    isInputGroup={false}
                    label="Nombre Mois"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    min={1}
                    placeholder="Nombre Mois"
                    formDispatcher={formDispatcher}
                    name="nombreMois"
                    id="nombreMoisCreationActivateurId"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInputFile
                    id="creationUploadFichierCreationActivateurId"
                    name="file"
                    label="Fichier"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    accept=".doc, .docx, .pdf, .txt, .xlsx, .csv"
                />
            </CustomRow>
            <CustomRow>
                <CustomInputDate
                    formDispatcher={formDispatcher}
                    label="Date Début : "
                    id="dateDebutCreationActivateurId"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="dateDebut"
                    isInputGroup={false}
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={
                            (form.status === FORM_CONST.CREATION_EN_COURS ||
                                fields.fileStatus === 'UPLOAD_PENDING') &&
                            fields.fileStatus !== ''
                        }
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
