export const schema = [
    {
        title: 'Banque',
        description: 'institutionFinanciere.designation',
        className: 'border-bottom'
    },
    {
        title: 'Numéro compte',
        description: 'numero',
        className: 'border-bottom'
    },
    {
        title: 'Montant',
        description: 'montant',
        className: 'border-bottom'
    },
    {
        title: 'Devise',
        description: 'devise',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation'
    }
];
