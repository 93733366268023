import { DB_NODE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListPartenaireForm() {
    /**
     * Get data on redux store
     */
    const mapper = [
        {
            dataExtractor: (item) =>
                `${item?.personne?.prenom || ''} ${item?.personne?.nom || ''} ${
                    item?.personne?.postnom || ''
                }`,
            displayName: 'Gestionnaire'
        },
        {
            field: 'designation',
            displayName: 'Désignation'
        },
        {
            field: 'numeroTel',
            displayName: 'Numéro Tel'
        },
        {
            field: 'etat',
            displayName: 'Etat'
        }
    ];
    return (
        <ListGenericForm
            reduxPropertyName="partenaires"
            functionName={URL_CONST.GET_LIST_PARTENAIRE_INITIALS}
            nodeName={DB_NODE.PARTENAIRE}
            mapper={mapper}
        />
    );
}
