export const schema = [
    {
        description: 'personne.prenom',
        title: 'Prénom',
        className: 'border-bottom'
    },
    {
        description: 'personne.nom',
        title: 'Nom',
        className: 'border-bottom'
    },
    {
        description: 'profilUser.designation',
        title: 'Profil',
        className: 'border-bottom'
    },
    {
        description: 'tillNumber',
        title: 'Till number',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Last update',
        description: 'dateDerniereModification',
        className: 'border-bottom'
    }
];
export const employeSchema = [
    {
        description: 'personne.prenom',
        title: 'Prénom',
        className: 'border-bottom'
    },
    {
        description: 'personne.nom',
        title: 'Nom',
        className: 'border-bottom'
    },
    {
        description: 'valeurTypeProprietaire.designation',
        title: 'Secteur administratif',
        className: 'border-bottom'
    },
    {
        description: 'fonction.designation',
        title: 'Fonction',
        className: 'border-bottom'
    },
    {
        description: 'typeContrat',
        title: 'Contrat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Last update',
        description: 'dateDerniereModification',
        className: 'border-bottom'
    }
];

export const listUserMapper = [
    {
        field: 'personne.prenom',
        displayName: 'Prénom'
    },
    {
        field: 'personne.nom',
        displayName: 'Nom'
    },
    {
        field: 'profilUser.designation',
        displayName: 'Profil'
    },
    {
        field: 'valeurTypeProprietaire.designation',
        displayName: 'Agence'
    },
    {
        field: 'tillNumber',
        displayName: 'Till number'
    },
    {
        displayName: 'Création',
        field: 'dateCreation'
    }
];

export const listEmployeMapper = [
    {
        field: 'personne.prenom',
        displayName: 'Prénom',
        position: 1
    },
    {
        field: 'personne.nom',
        displayName: 'Nom',
        position: 2
    },
    {
        field: 'valeurTypeProprietaire.designation',
        displayName: 'Secteur administratif',
        position: 3
    },
    {
        field: 'fonction.designation',
        displayName: 'Fonction',
        position: 4
    },
    {
        field: 'typeContrat',
        displayName: 'Contrat',
        position: 5
    }
];
