import { submitPutAction, useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomInput,
    CustomForm,
    CustomFormGroup,
    CustomRow,
    CustomCol,
    CustomDropdown
} from '../../components';
import {
    SignInRelPath,
    SignUpRelPath
} from '../../routes/registration/gestion-user/connexion-rel-path';
import { URL_CONST } from '../../util';
import { ConfirmRegistrationSchema as uiValidator } from '../../util/validation';

const defaultFields = {
    codeValidation: '',
    email: '',
    estMarchand: true,
    selectedOrganisation: {}
};
export function ConfirmSignUp() {
    const initialState = formInitialState({ fields: defaultFields });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        { initialState }
    );
    const { fields, form } = formState;
    const { error: formError, status: formStatus } = form;
    const payload = {
        codeValidation: fields.codeValidation,
        email: fields.email,
        estMarchand: fields.selectedOrganisation?.code === 'Marchand',
        typeEntite: fields.selectedOrganisation?.code
    };
    if (formStatus === FORM_CONST.MODIFICATION_SUCCESS) {
        return <Navigate to={`/${SignInRelPath.path}`} replace />;
    }
    const headerToDisplay = (status) => {
        if (status === FORM_CONST.MODIFICATION_ECHEC && formError) {
            return <CustomAlert error={formError} />;
        }
        return (
            <p className="fs-3 mb-1 fw-bold text-center">
                Confirmez votre inscription
            </p>
        );
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        formDispatcher(
            submitPutAction({
                idToken: '',
                functionName: URL_CONST.PUT_VALIDATION_ENTREPRISE,
                payload,
                fields,
                uiValidator
            })
        );
    };

    return (
        <div className="container-fluid -flex justify-content-center align-items-center">
            <CustomRow className="p-5 vh-100 vw-100">
                <CustomCol className="col-md-4 col-xl-4 col-lg-4 col-sm-10 offset-sm-1 offset-md-3 col-">
                    <CustomForm className="row g-3" onSubmit={handleSubmit}>
                        {headerToDisplay(formStatus)}
                        <hr />
                        <CustomFormGroup>
                            <CustomInput
                                uiValidator={uiValidator}
                                formDispatcher={formDispatcher}
                                label="Code de confirmation*"
                                labelClassName="col-12 fs-6 fw-bolder"
                                type="text"
                                placeholder="CODE"
                                name="codeValidation"
                                id="societyCodeConfirm"
                                formState={formState}
                            />
                        </CustomFormGroup>
                        <br />
                        <CustomFormGroup>
                            <CustomInput
                                uiValidator={uiValidator}
                                formDispatcher={formDispatcher}
                                label="Adresse mail*"
                                type="email"
                                placeholder="exemple@domaine.com"
                                labelClassName="col-12 fs-6 fw-bolder"
                                name="email"
                                id="societyEmailConfirm"
                                formState={formState}
                            />
                        </CustomFormGroup>
                        <br />
                        <CustomFormGroup className="justify-content-between mt-4">
                            <CustomDropdown
                                options={[
                                    {
                                        code: 'Marchand',
                                        designation: 'Marchand'
                                    },
                                    {
                                        code: 'Distributeur',
                                        designation: 'Distributeur'
                                    }
                                ]}
                                label="Organisation*"
                                labelClassName="col-12 fs-6 fw-bolder"
                                divClassName="col-12"
                                formDispatcher={formDispatcher}
                                name="selectedOrganisation"
                                defaultOption="Selectionner un type organisation"
                                id="selectedOrganisationId"
                                formState={formState}
                                uiValidator={uiValidator}
                            />
                            <p className="mt-4 text-muted text-left">
                                Problème de confirmation !{' '}
                                <Link to={`/${SignUpRelPath.path}`}>
                                    S&apos;inscrire à nouveau?
                                </Link>
                            </p>
                        </CustomFormGroup>
                        <CustomButtonLoader
                            className="w-100 btn btn-lg"
                            text="Valider"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                        />
                    </CustomForm>
                </CustomCol>
            </CustomRow>
        </div>
    );
}
