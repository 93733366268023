import { Modal } from 'react-bootstrap';

/**
 *
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {Boolean} param0.show Affichage du modal
 * @param {Function} param0.onHide Fermeture du modal
 * @param {string} param0.size Taille du modal
 * @param {string} param0.ariaLabelledBy Id du label du modal
 * @param {Boolean} param0.centered Centrer le modal
 * @param {Boolean} param0.fullscreen Set modal to fullscreen mode, by default false
 * @param {string} param0.header Titre du modal
 * @param {React.Component} param0.children Contenu du modal
 * @param {React.Component} param0.footer Pied du modal
 * @returns {React.Component}
 */
export function CustomCenteredModal({
    show,
    onHide,
    size = 'lg',
    ariaLabelledby = 'contained-modal-title-vcenter',
    centered = true,
    header = 'Modal heading',
    backdrop,
    children = (
        <>
            <h4>Centered Modal</h4>
            <p>
                Cras mattis consectetur purus sit amet fermentum. Cras justo
                odio, dapibus ac facilisis in, egestas eget quam. Morbi leo
                risus, porta ac consectetur ac, vestibulum at eros.
            </p>
        </>
    ),
    footer,
    fullscreen = false
}) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size={size}
            aria-labelledby={ariaLabelledby}
            centered={centered}
            fullscreen={fullscreen}
            backdrop={backdrop}
        >
            <Modal.Header closeButton>
                <Modal.Title id={ariaLabelledby}>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            {footer && <Modal.Footer>{footer}</Modal.Footer>}
        </Modal>
    );
}
