import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListUploadFichierReleveFlashForm() {
    return (
        <ListGenericForm
            reduxPropertyName="relevesFlash"
            functionName={URL_CONST.GET_LIST_TRAITEMENT_RELEVE_FLASH}
            nodeName={REDUX_NODE_NAME.UPLOAD_RELEVE_FLASH}
            mapper={[
                {
                    field: 'superDealer.designation',
                    displayName: 'Super Dealer',
                    position: 1
                },
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand',
                    position: 2
                },
                {
                    field: 'fileName',
                    displayName: 'Fichier',
                    position: 3
                },
                {
                    field: 'nombre',
                    displayName: 'Opérations',
                    position: 4
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création',
                    position: 5
                }
            ]}
        />
    );
}
