import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    FORM_CONST,
    TODAY
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';

export function ApproCashForm({
    idToken,
    oid,
    uid,
    initialState,
    uiValidator,
    hasService,
    cfPayload
}) {
    /**
     * @desc: Redux Dispatcher
     */
    const reduxDispatcher = useDispatch();
    /**
     * @desc: Get data on redux store
     */
    const {
        nappUser,
        deviseReference,
        compteLimiteCredit,
        etatCompteLimiteCredit,
        ecouteurCompteLimiteCredit,
        caisses,
        etatCaisse
    } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser,
        deviseReference: reduxState.deviseReference,
        caisses: reduxState.caissesActiveUser
            .filter((item) => !item?.estEmoney)
            .map((item) => ({
                ...item,
                designation: `${item?.designation || ''}-${item.devise || ''}`
            })),
        etatCaisse: reduxState.etat.caissesActiveUser.etat,
        compteLimiteCredit: reduxState.compteLimitCreditOrganisation.map(
            (item) => ({
                ...item,
                uid: item?.valeurTypeProprietaire?.reference || '',
                designation: `${item?.valeurTypeProprietaire?.prenom || ''} ${
                    item?.valeurTypeProprietaire?.nom || ''
                }`
            })
        ),
        etatCompteLimiteCredit:
            reduxState.etat.compteLimitCreditOrganisation.etat,
        ecouteurCompteLimiteCredit:
            reduxState.etat.compteLimitCreditOrganisation.ecouteur
    }));

    /**
     * @desc: form state, form dispatcher
     */
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    /**
     * @desc: FIELDS, FORM, ELEMENTS, DEPENDECIES
     */
    const { fields, form } = formState;

    /**
     * Common form processes
     */

    /**
     * @desc: Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
                etat: etatCaisse,
                payload: cfPayload.caisses
            },
            {
                fetchType: FETCH_LISTENING,
                functionName: URL_CONST.GET_LIST_LIMIT_CREDIT_ORGANISATION,
                nodeName: REDUX_NODE_NAME.COMPTE_LIMITE_CREDIT_ORGANISATION,
                etat: etatCompteLimiteCredit,
                ecouteur: ecouteurCompteLimiteCredit,
                path: DB_NODE.COMPTE,
                payload: cfPayload.users,
                isCondition:
                    !!hasService[
                        CODE_SERVICE.SERVICE_CODE_REVENDEUR
                            .CODE_SERVICE_MODULE_FINANCE
                            .INITIER_APPRO_CASH_POUR_AUTRE?.code
                    ],
                listenerFilters: [
                    // filtre pour l'organisation
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'typeEntite',
                        value: 'LimitCredit',
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'typeOrganisation',
                        value: nappUser?.typeEntite,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'valeurTypeOrganisation.reference',
                        value: oid,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.ORDER_BY,
                        property: 'dateDerniereModification',
                        order: 'asc'
                    },
                    {
                        type: FILTER_TYPE.START_AT,
                        value: TODAY.nowUtc()
                    }
                ]
            }
        ]
    });
    /**
     * @desc: Submit Function
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const { montant, selectedCaisse, selectedUser } = fields;
        const propertyToAdd = {
            innerLevel: {
                agent: { reference: uid }
            },
            outerLevel: {}
        };
        if (selectedUser?.id) {
            propertyToAdd.innerLevel.agent = { reference: selectedUser.uid };
        }
        const payload = {
            newApproCashVersement: {
                ...propertyToAdd.innerLevel,
                montant,
                caisse: { reference: selectedCaisse.id },
                devise:
                    caisses.find(
                        (caisse) => caisse.id === fields.selectedCaisse.id
                    )?.devise || deviseReference?.code,
                typeEntite: 'ApproCash'
            }
        };
        // console.log(payload);
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_APPRO_CASH_VERSEMENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.APPRO_CASH,
                reduxDispatcher
            })
        );
    };
    return (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'caisses',
                    etat: etatCaisse
                },
                {
                    dependency: 'users',
                    etat: etatCompteLimiteCredit,
                    isCondition:
                        !!hasService[
                            CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                .CODE_SERVICE_MODULE_FINANCE
                                .INITIER_APPRO_CASH_POUR_AUTRE?.code
                        ]
                }
            ]}
        >
            {/* Rajouter des condition dans les composants au lieu des vues */}
            <CustomRow>
                <CustomDropdown
                    options={caisses}
                    label="Caisse*"
                    defaultOption={`${
                        caisses.length
                            ? 'Sélectionner une caisse'
                            : 'Aucune caisse disponible'
                    }`}
                    disabled={!caisses.length}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCaisse"
                    id="selectedCaisseCreationVersementAgentVendeur"
                    formState={formState}
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    min={0}
                    placeholder="Saisissez le montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="montantCreationVersementAgentVendeur"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!hasService[
                        CODE_SERVICE.SERVICE_CODE_REVENDEUR
                            .CODE_SERVICE_MODULE_FINANCE
                            .INITIER_APPRO_CASH_POUR_AUTRE?.code
                    ]
                }
            >
                <CustomDropdown
                    options={compteLimiteCredit}
                    label="Agent*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedUser"
                    id="selectedUserDetailCaisse"
                    formState={formState}
                    idProperty="uid"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            {/* <StateShowerComponent state={fields} /> */}
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
}
