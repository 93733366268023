import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListVirementForm() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { isNapp } = useGetUserDetail({ nappUser });
    const mapper = isNapp
        ? [
              {
                  displayName: 'Banque',
                  field: 'institutionFinanciere.designation'
              },
              {
                  displayName: 'SuperDealer',
                  field: 'valeurTypeEntite.designation'
              },
              {
                  field: 'type',
                  displayName: 'Opération'
              },
              {
                  field: 'montant',
                  displayName: 'Montant'
              },
              {
                  field: 'compte.devise',
                  displayName: 'Devise'
              },
              {
                  field: 'tauxCommission',
                  displayName: 'Taux Commission'
              },
              {
                  field: 'etat',
                  displayName: 'Etat'
              },
              {
                  field: 'dateCreation',
                  displayName: 'Création'
              }
          ]
        : [
              {
                  displayName: 'Banque',
                  field: 'institutionFinanciere.designation'
              },
              {
                  field: 'type',
                  displayName: 'Opération'
              },
              {
                  field: 'montant',
                  displayName: 'Montant'
              },
              {
                  field: 'compte.devise',
                  displayName: 'Devise'
              },
              {
                  field: 'tauxCommission',
                  displayName: 'Taux Commission'
              },
              {
                  field: 'etat',
                  displayName: 'Etat'
              },
              {
                  field: 'dateCreation',
                  displayName: 'Création'
              }
          ];
    return (
        <ListGenericForm
            reduxPropertyName="virements"
            functionName={URL_CONST.GET_LIST_OPERATION_VIREMENT_INITIALS}
            nodeName={REDUX_NODE_NAME.VIREMENTS}
            mapper={mapper}
        />
    );
}
