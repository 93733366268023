import React from 'react';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../../components';
import { AdministrationPath } from '../../../../routes/registration';
import { ListNotificationPushForm } from './list-notification-push';

export function NotificationPushViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Notification Push</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${AdministrationPath.NotificationPushViewLayoutPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE
                                        .CODE_SERVICE_MODULE_ADMINISTRATION
                                        .SERVICE_CLIENT
                                        .CREATION_NOTIFICATION_PUSH
                                ]}
                                destination={
                                    AdministrationPath
                                        .NotificationPushCreatePath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListNotificationPushForm />
        </div>
    );
}
