import { useState } from 'react';
import { ConditionalRenderingWrapper, CustomCenteredModal } from '../container';
import { CustomButton } from './button';

/**
 * Bouton avec effet de loader lors de son déclenchement
 * @param {Object} param0 Object contenant les props du composant:
 * @param {string} param0.size Taille du composant basée sur Bootstrap
 * @param {boolean} param0.disabled Désactivé ou non
 * @param {boolean} param0.isActionEncours Affiche le loader en fonction d'une action spécifique
 * @param {string} param0.type Type de composant
 * @param {string} param0.className Classe CSS
 * @param {string} param0.text Texte du composant
 * @param {Function} param0.onClick Fonction qui permet d'envoyer des actions au store du composant ou de déclencher une action du composant
 * @param {string} param0.modalText Message affiché dans le modal
 * @param {string} param0.backdrop Propriété qui peut figer la modal
 * @param {React.Component} param0.icon Icône du composant
 * @param {Boolean} param0.isShouldBeRendered définit si le bouton doit être rendu ou pas, par défaut : "true"
 * @returns {React.Component}
 */
export function CustomConfirmButton({
    disabled,
    text,
    icon,
    modalSize = 'md',
    type = 'submit',
    size = 'md',
    className = '',
    confirmClassName = 'btn-primary',
    cancelClassName = 'btn-danger',
    confirmText = 'Confirmer',
    cancelText = 'Annuler',
    header = 'Confirmation',
    confirmOnClick,
    isActionEncours,
    modalText = 'Confirmez-vous cette action?',
    backdrop,
    isShouldBeRendered = true
}) {
    const [show, setShow] = useState(false);
    const openModal = () => setShow(true);
    const onHide = () => setShow(false);
    const confirmFunction = (e) => {
        confirmOnClick(e);
        onHide();
    };
    const footer = (
        <>
            <CustomButton
                className={`btn btn-sm ${cancelClassName}`}
                size={size}
                type={type}
                onClick={!disabled ? onHide : () => {}}
            >
                <span>{cancelText}</span>
            </CustomButton>
            <CustomButton
                className={`btn btn-sm ${confirmClassName}`}
                size={size}
                type={type}
                onClick={confirmFunction}
                disabled={disabled}
            >
                <span>{confirmText}</span>
            </CustomButton>
        </>
    );

    return (
        <ConditionalRenderingWrapper isShouldBeRendered={isShouldBeRendered}>
            <CustomCenteredModal
                show={show}
                onHide={!disabled ? onHide : () => {}}
                header={header}
                footer={footer}
                size={modalSize}
                backdrop={backdrop}
            >
                <span>{modalText}</span>
            </CustomCenteredModal>
            <CustomButton
                className={className}
                size={size}
                type={type}
                disabled={show || disabled}
                onClick={openModal}
            >
                {disabled && isActionEncours ? (
                    <div className="spinner-border" role="status" />
                ) : (
                    <span>
                        {icon} {text}
                    </span>
                )}
            </CustomButton>
        </ConditionalRenderingWrapper>
    );
}
