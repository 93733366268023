/* eslint-disable no-restricted-syntax */

/**
 * Cette fonction prend en parametre un tableau et le groupe par Charge et secteur
 * @param {Array} data
 * @returns
 */
export const buildingPeriodesByChargeAndSecteur = (data) => {
    const temporaryArray = [];
    let temporaryObject = {};
    for (const object of data) {
        let findSimilarChargeSecteur = temporaryArray.find(
            (item) =>
                item.charge === object.charge && item.secteur === object.secteur
        );
        if (object.secteur && object.charge) {
            findSimilarChargeSecteur = temporaryArray.find(
                (item) =>
                    item.charge === object.charge &&
                    item.secteur === object.secteur
            );
        } else if (!object.secteur && object.charge) {
            findSimilarChargeSecteur = temporaryArray.find(
                (item) => item.charge === object.charge
            );
        }
        const propertyToAdd = {};
        const propertyToAddBeneficiaire = {};
        const propertyToPeriodes = {};
        if (object.beneficiaires)
            propertyToAddBeneficiaire.beneficiaires = object.beneficiaires;
        if (object.idCaisse) propertyToAdd.idCaisse = object.idCaisse;
        if (object.prixCharge) propertyToAdd.prix = object.prixCharge;
        if (object.secteur) {
            propertyToAdd.secteur = object.secteur;
            propertyToPeriodes.periodes = [
                {
                    dateDebut: object.dateDebut,
                    dateFin: object.dateFin,
                    datePaiement: object.datePaiement,
                    ...propertyToAddBeneficiaire
                }
            ];
        }
        if (!findSimilarChargeSecteur) {
            temporaryObject = {
                charge: object.charge,
                ...propertyToAdd,
                typeMoyenPaiement: object.typeMoyenPaiement,
                // secteur: object.secteur,
                ...propertyToPeriodes
            };
            temporaryArray.push(temporaryObject);
        }
        if (findSimilarChargeSecteur) {
            const { periodes } = findSimilarChargeSecteur;
            const asSimilarDate = periodes.find(
                (item) =>
                    item.dateDebut === object.dateDebut &&
                    item.dateFin === object.dateFin &&
                    item.datePaiement === object.datePaiement
            );
            if (!asSimilarDate) {
                findSimilarChargeSecteur.periodes.push({
                    dateDebut: object.dateDebut,
                    dateFin: object.dateFin,
                    datePaiement: object.datePaiement,
                    ...propertyToAddBeneficiaire
                });
            } else if (asSimilarDate.beneficiaires) {
                asSimilarDate.beneficiaires.push(...object.beneficiaires);
            }
        }
    }
    return temporaryArray;
};

/**
 * Cette fonction prend en parametre un tableau et le groupe secteur
 * @param {Array} data
 * @returns
 */
export const buildingObjectBySecteur = (data) => {
    const temporaryArray = [];
    let temporaryObject = {};
    for (const object of data) {
        const findSimilarObjectBySecteur = temporaryArray.find(
            (item) =>
                item.charge === object.charge &&
                item?.details.find(
                    (innerItem) => innerItem?.secteur?.code === object.secteur
                )
        );
        if (!findSimilarObjectBySecteur) {
            const propertyToAdd = {};
            const propertyToAddBySecteur = {};
            if (object.idCaisse) {
                propertyToAdd.idCaisse = object.idCaisse;
            }
            if (object.secteur)
                propertyToAddBySecteur.details = [
                    {
                        secteur: object.secteur,
                        periodes: object.periodes
                    }
                ];
            if (object.prix) propertyToAdd.prix = object.prix;
            temporaryObject = {
                charge: object.charge,
                ...propertyToAdd,
                typeMoyenPaiement: object.typeMoyenPaiement,
                ...propertyToAddBySecteur
            };
            temporaryArray.push(temporaryObject);
        }
        if (findSimilarObjectBySecteur) {
            findSimilarObjectBySecteur.details.push({
                secteur: object.secteur,
                periodes: object.periodes
            });
        }
    }
    return temporaryArray;
};

/**
 * Cette fonction prend en parametre un tableau et le groupe par Charge
 * @param {Array} data
 * @returns
 */
export const buildingOnlyByCharge = (data) => {
    const temporaryArray = [];
    let temporaryObject = {};
    for (const object of data) {
        const findSimilarObjectByCharge = temporaryArray.find(
            (item) => item?.element?.code === object.charge
        );
        if (!findSimilarObjectByCharge) {
            const propertyToAdd = {};
            if (object.idCaisse) {
                propertyToAdd.idCaisse = object.idCaisse;
            }
            if (object.prix) propertyToAdd.prix = object.prix;
            if (object.details) propertyToAdd.details = object.details;
            temporaryObject = {
                element: {
                    code: object.charge
                },
                typeMoyenPaiement: object.typeMoyenPaiement,
                ...propertyToAdd
            };
            temporaryArray.push(temporaryObject);
        }
        if (findSimilarObjectByCharge) {
            findSimilarObjectByCharge.details.push(...object.details);
        }
    }
    return temporaryArray;
};

export const formatRessourceByPeriodes = (tab = []) => {
    const array = [];
    for (const element of tab) {
        const findExisting = array.find(
            (e) => e.element.code === element.ressource
        );
        if (!findExisting) {
            array.push({
                element: {
                    code: element.ressource
                },
                details: [
                    {
                        secteur: element.secteur,
                        periodes: element.periodes
                    }
                ]
            });
        } else {
            const findSameSecteur = findExisting.details.find(
                (e) => e.secteur.code === element.secteur.code
            );
            if (!findSameSecteur) {
                findExisting.details.push({
                    secteur: element.secteur,
                    periodes: element.periodes
                });
            }
        }
    }
    return array;
};

export const formatRessourceByPeriodesRefactored = (tab = []) => {
    const array = [];
    for (const element of tab) {
        // Je trouve les elements exitants deja au plus haut niveau
        const findExisting = array.find(
            (e) => e.element.code === element.ressource
        );
        if (!findExisting) {
            if (element.secteur) {
                array.push({
                    element: {
                        code: element.ressource
                    },
                    details: [
                        {
                            secteur: element.secteur,
                            periodes: element.periodes
                        }
                    ]
                });
            }
            if (element.tauxCroissance) {
                array.push({
                    element: {
                        code: element.ressource
                    },
                    tauxCroissance: element.tauxCroissance
                });
            }
        }

        // S'il existe et qu'il a le secteur
        if (findExisting) {
            // recuperation des elements du même secteur
            const findSameSecteur = findExisting.details.find(
                (e) => e.secteur.code === element.secteur.code
            );

            // if (findSameSecteur) {
            //     // S'il existe vérifier les periodes
            //     const hasSamePeriode =
            //         findSameSecteur.details[0].periodes[0].dateDebut ===
            //             element[0].dateDebut &&
            //         findSameSecteur.details[0].periodes[0].dateFin ===
            //             element[0].dateFin;

            //     if (hasSamePeriode) {
            //         findExisting?.details[0]?.periodes[0]?.devises
            //             .filter(
            //                 (dv) =>
            //                     dv.devise !==
            //                     element.periodes[0].devises[0].devise
            //             )
            //             .push({ ...element.periodes[0].devises[0] });
            //     }
            //     if (!hasSamePeriode) {
            //         findExisting?.details[0]?.periodes.push(element.periodes);
            //     }
            // }
            if (!findSameSecteur) {
                findExisting.details.push({
                    secteur: element.secteur,
                    periodes: element.periodes
                });
            }
        }
    }
    return array;
};
