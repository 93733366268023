import {
    CustomCol,
    CustomCollapse,
    CustomInput,
    CustomLabel,
    CustomRow,
    CustomSwitchButton
} from '../../../../components';

export function NumberTypeComponent({
    formDispatcher,
    formState,
    id,
    isFloatName,
    nombreRangApresVirguleDivClassName = 'col-12 col-md-10',
    nombreRangApresVirguleLabelClassName = 'col-12 col-md-2',
    nombreRangApresVirguleName = 'nombreRangApresVirgule',
    uiValidator,
    valeurDecimaleLabel = 'Valeur décimale',
    valeurDecimaleColClassName = 'col-md-2',
    valeurDecimaleSwitchButtonColClassName = 'col-md-2',
    nombreRangApresVirguleInputLabel = 'Nombre rang après virgule',
    nombreRangApresVirguleInputPlaceholder = 'Nombre de rang après la virgule'
}) {
    const { fields } = formState;
    return (
        <>
            <CustomRow>
                <CustomCol className={valeurDecimaleColClassName}>
                    <CustomLabel htmlFor={`${id}isFloatNumberTypeComponent`}>
                        {valeurDecimaleLabel}
                    </CustomLabel>
                </CustomCol>
                <CustomCol className={valeurDecimaleSwitchButtonColClassName}>
                    <CustomSwitchButton
                        id={`${id}isFloatNumberTypeComponent`}
                        name={isFloatName}
                        value={fields?.[isFloatName]}
                        formDispatcher={formDispatcher}
                    />
                </CustomCol>
            </CustomRow>
            <CustomCollapse isIn={fields?.[isFloatName]}>
                <CustomRow>
                    <CustomInput
                        label={nombreRangApresVirguleInputLabel}
                        labelClassName={nombreRangApresVirguleLabelClassName}
                        divClassName={nombreRangApresVirguleDivClassName}
                        type="number"
                        isInputGroup={false}
                        min={1}
                        placeholder={nombreRangApresVirguleInputPlaceholder}
                        formDispatcher={formDispatcher}
                        name={nombreRangApresVirguleName}
                        id={`${id}nombreRangApresVirguleNumberTypeComponent`}
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
            </CustomCollapse>
        </>
    );
}
