import { ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    BilletageComponent,
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { DepenseDetailFormRequiredSchema as uiValidator } from './validation';

const defaultFields = {
    actionEncours: '',
    selectedCoupureBilletage: {},
    nombreCoupureBilletage: 0,
    listBilletage: []
};

/**
 * @description defaultDependencies for our state
 */
const defaultDependencies = { entity: true };

export function DetailDepensesForm() {
    const {
        id,
        reduxDispatcher,
        reduxProps,
        entity,
        idToken,
        formState,
        formDispatcher
    } = useGetDetailForm({
        nodeName: 'depenses',
        defaultFields,
        defaultDependencies,
        listNode: [
            {
                name: 'allCoupures'
            }
        ]
    });
    const { form, fields } = formState;
    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payloadToAdd = {};
        if (fields.listBilletage.length) {
            payloadToAdd.coupures = fields.listBilletage.map((billet) => ({
                nombre: billet.nombre,
                valeur: parseInt(billet.coupure.code, 10)
            }));
        }
        const payload = {
            idDepense: entity.id,
            etat,
            ...payloadToAdd
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_DEPENSE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.DEPENSES,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_DEPENSE_BY_ID}
            nodeName={REDUX_NODE_NAME.DEPENSES}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail depense"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <CustomRow>
                <BilletageComponent
                    formState={formState}
                    uiValidator={uiValidator}
                    formDispatcher={formDispatcher}
                    selectedDeviseName="selectedDeviseBilletage"
                    selectedCoupureName="selectedCoupureBilletage"
                    nombreCoupureName="nombreCoupureBilletage"
                    deviseCaisse={entity?.devise}
                    coupures={
                        reduxProps?.allCoupures.find(
                            (item) => item.devise === entity?.devise
                        )?.coupures || []
                    }
                />
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                    <CustomConfirmButton
                        type="button"
                        text="Annuler"
                        className="btn-danger"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_ANNULER
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation Annulation"
                    />
                </CustomCol>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
