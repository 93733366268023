import { RapportComptabilisationCompteForm } from './comptabilisation-compte-organisation';

export function RapportComptabilisationCompteOrganisationViewLayout() {
    // const [key, setKey] = useState('releve');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Comptabilisation Compte Organisation</h1>
            </div>
            <RapportComptabilisationCompteForm />
        </div>
    );
}
