import {
    fetchElement,
    submitPutAction,
    useFormGlobalReducer,
    useGetEntity,
    useGetUserDetail,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    formInitialState,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CenteredSpinner,
    CustomAlert,
    CustomButtonLoader,
    CustomCenteredModal,
    CustomCol,
    CustomDropdown,
    CustomRow,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST } from '../../../../util';
import { schema } from '../detail-fields';

/**
 * @description elements du state du formulaire
 */
const defaultElements = [
    'ElaborationAmendementCollection',
    'ElaborationAmendementCollectionPeriode',
    'ElaborationAmendementPeriode',
    'DetailElaborationAmendement'
];

export function DetailElaborationBudgetPrevisionnelForm() {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    const { firebaseUser, entity } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        entity: reduxState.elaborations.find((element) => element.id === id)
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: {
            actionEncours: '',
            isShowDetail: false,
            selectedCollection: {}
        },
        dependencies: { entity: true },
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, dependencies, elements } = formState;
    useGetEntity({
        entity,
        etat: dependencies.entity.status,
        formDispatcher,
        functionName: URL_CONST.GET_ELABORATION_AMENDEMENT_BY_ID,
        id,
        idToken,
        nodeName: DB_NODE.ELABORATION_AMENDEMENT,
        reduxDispatcher
    });
    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        dependencies
    });
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = {
            etat,
            creator,
            idElaborationAmendement: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                reduxDispatcher
            })
        );
    };
    const detailCallback = () => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'isShowDetail',
                data: !fields.isShowDetail
            }
        });
    };

    const handleSubmitDetail = () => {
        /**
         * Dans le payload j'envoie l'information de la collection ElaborationAmendementCollection || ElaborationAmendementCollectionPeriode || ElaborationAmendementPeriode
         * La reference qui est l'id
         * dans le cas des details il y a une info supplémentaire à rajouter
         * Donc la vue n'aura pas de select mais autre chose! à Revoir
         */
        if (!fields.selectedCollection.code) return;

        const payload = {
            reference: id,
            collection: fields.selectedCollection.code
        };

        const { status: etat } = elements[fields.selectedCollection.code];
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_ELEMENT_ELABORATION_AMENDEMENT,
                elementPropertyName: fields.selectedCollection.code,
                etat,
                payload,
                reduxNodeName: REDUX_NODE_NAME.ELABORATION_AMENDEMENT_DETAIL,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => {
        if (!entity?.id && !isLoadFailed && !isLoadComplete) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return <CustomAlert error={form?.error} />;
        }
        return (
            <DetailWrapper
                functionName={URL_CONST.GET_ELABORATION_AMENDEMENT_BY_ID}
                nodeName={DB_NODE.ELABORATION_AMENDEMENT}
                formDispatcher={formDispatcher}
                formState={formState}
                reduxDispatcher={reduxDispatcher}
                subtitle="Elaboration Additionnelle Charges et Ressources"
                entity={entity}
                id={id}
                schema={schema}
                idToken={idToken}
                isShowDetail
                detailCallback={detailCallback}
            >
                <CustomCenteredModal
                    header={`Detail de l'élaboration ${entity?.typeProprietaire}`}
                    show={fields.isShowDetail}
                    onHide={detailCallback}
                >
                    <CustomRow>
                        <CustomDropdown
                            isSort={false}
                            // disabled={!periodes.length}
                            options={[
                                {
                                    code: 'ElaborationAmendementCollection',
                                    designation:
                                        'Detail Elaboration Amendement Collection'
                                },
                                {
                                    code: 'ElaborationAmendementCollectionPeriode',
                                    designation:
                                        'Elaboration Amendement Collection Période'
                                },
                                {
                                    code: 'ElaborationAmendementPeriode',
                                    designation:
                                        'Elaboration Amendement Période'
                                },
                                {
                                    code: 'DetailElaborationAmendement',
                                    designation: 'Détail Elaboration Amendement'
                                }
                            ]}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner la colletion"
                            label="Collection*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedCollection"
                            id="selectedCollectionId"
                        />
                    </CustomRow>
                    {elements?.ElaborationAmendementCollection?.value?.length &&
                    fields.selectedCollection.code ===
                        'ElaborationAmendementCollection' ? (
                        <SimpleTable
                            tableClassName="table-striped-columns"
                            isShowDropButton={false}
                            data={
                                elements.ElaborationAmendementCollection.value
                            }
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    displayName: entity?.typeProprietaire,
                                    field: 'valeurTypeProprietaire.designation'
                                },
                                {
                                    displayName: 'Objectif',
                                    field: 'target'
                                },
                                {
                                    displayName: 'Taux Croissance',
                                    field: 'tauxCroissance'
                                },
                                {
                                    displayName: 'Quantité',
                                    field: 'quantite'
                                },
                                {
                                    displayName: 'Prévisionnel',
                                    field: 'previsionnel'
                                }
                            ]}
                        />
                    ) : null}
                    {elements?.ElaborationAmendementCollectionPeriode?.value
                        ?.length &&
                    fields.selectedCollection.code ===
                        'ElaborationAmendementCollectionPeriode' ? (
                        <SimpleTable
                            isShowDropButton={false}
                            data={
                                elements.ElaborationAmendementCollectionPeriode
                                    .value
                            }
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    displayName: entity?.typeProprietaire,
                                    field: 'valeurTypeProprietaire.designation'
                                },
                                {
                                    displayName: 'Taux Croissance',
                                    field: 'tauxCroissance'
                                },
                                {
                                    displayName: 'Objectif',
                                    field: 'target'
                                },
                                {
                                    displayName: 'Debut',
                                    field: 'periode.dateDebut',
                                    isDate: true
                                },
                                {
                                    displayName: 'Fin',
                                    field: 'periode.dateFin',
                                    isDate: true
                                },
                                {
                                    displayName: 'Prix',
                                    field: 'prix'
                                },
                                {
                                    displayName: 'Quantité',
                                    field: 'quantite'
                                },
                                {
                                    displayName: 'Prévisionnel',
                                    field: 'previsionnel'
                                },
                                {
                                    displayName: 'Moyen Paiement',
                                    field: 'typeMoyenPaiement'
                                }
                            ]}
                        />
                    ) : null}
                    {elements?.ElaborationAmendementPeriode?.value?.length &&
                    fields.selectedCollection.code ===
                        'ElaborationAmendementPeriode' ? (
                        <SimpleTable
                            isShowDropButton={false}
                            data={elements.ElaborationAmendementPeriode.value}
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    displayName: entity?.typeProprietaire,
                                    field: 'valeurTypeProprietaire.designation'
                                },
                                {
                                    displayName: 'Taux Croissance',
                                    field: 'tauxCroissance'
                                },
                                {
                                    displayName: 'Objectif',
                                    field: 'target'
                                },
                                {
                                    displayName: 'Direction',
                                    field: 'direction.designation'
                                },
                                {
                                    displayName: 'Secteur',
                                    field: 'secteur.designation'
                                },
                                {
                                    displayName: 'Imputation',
                                    field: 'compteImputation.designation'
                                },
                                {
                                    displayName: 'Moyen Paiement',
                                    field: 'typeMoyenPaiement'
                                },
                                {
                                    displayName: 'Prix',
                                    field: 'prix'
                                },
                                {
                                    displayName: 'Debut',
                                    field: 'periode.dateDebut',
                                    isDate: true
                                },
                                {
                                    displayName: 'Fin',
                                    field: 'periode.dateFin',
                                    isDate: true
                                },
                                {
                                    displayName: 'Quantite',
                                    field: 'quantite'
                                },
                                {
                                    displayName: 'Prévisionnel',
                                    field: 'previsionnel'
                                }
                            ]}
                        />
                    ) : null}
                    {elements?.DetailElaborationAmendement?.value?.length &&
                    fields.selectedCollection.code ===
                        'DetailElaborationAmendement' ? (
                        <SimpleTable
                            isShowDropButton={false}
                            data={elements.DetailElaborationAmendement.value}
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    displayName: entity?.typeProprietaire,
                                    field: 'valeurTypeProprietaire.designation'
                                },
                                {
                                    displayName: 'Taux Croissance',
                                    field: 'tauxCroissance'
                                },
                                {
                                    displayName: 'Objectif',
                                    field: 'target'
                                },
                                {
                                    displayName: 'Type',
                                    field: 'typeEntite'
                                },
                                {
                                    displayName: 'Prévisionnel',
                                    field: 'previsionnel'
                                }
                            ]}
                        />
                    ) : null}
                    {/* <ChangePassword /> */}
                    <CustomButtonLoader
                        type="button"
                        disabled={
                            elements?.ElaborationAmendementCollection
                                ?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ||
                            elements?.ElaborationAmendementCollectionPeriode
                                ?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ||
                            elements?.ElaborationAmendementPeriode?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ||
                            elements?.DetailElaborationAmendement?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        isActionEncours={
                            elements?.ElaborationAmendementCollection
                                ?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ||
                            elements?.ElaborationAmendementCollectionPeriode
                                ?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ||
                            elements?.ElaborationAmendementPeriode?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ||
                            elements?.DetailElaborationAmendement?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        onClick={
                            handleSubmitDetail
                        } /** @description: this function must be changed */
                        className="btn-primary"
                        text="Voir Détail"
                    />
                    {/* <CustomButton
                        onClick={detailCallback}
                        className="btn-danger"
                    >
                        Fermer
                    </CustomButton> */}
                </CustomCenteredModal>
                <UserServiceWrapper
                    services={[
                        { code: 'test' },
                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                            .VALIDER_ELABORATION_AMENDEMENT,
                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                            .ADOPTER_ELABORATION_AMENDEMENT,
                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                            .ANNULER_ELABORATION_AMENDEMENT
                    ]}
                >
                    <CustomRow>
                        <CustomCol className="offset-4 col-3 col-sm-2">
                            <CustomButtonLoader
                                type="button"
                                className="btn-danger"
                                onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                                text="Annuler"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                            />
                        </CustomCol>
                        <CustomCol className="col-3 col-md-2">
                            {entity?.etat === ETAT.ETAT_ENCOURS ? (
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                    }
                                    text="Mettre en attente"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ATTENTE_VALIDATION
                                    }
                                />
                            ) : null}
                            {entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION ? (
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_VALIDER)
                                    }
                                    text="Valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                />
                            ) : null}
                            {entity?.etat === ETAT.ETAT_VALIDER ? (
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() => updateEtat(ETAT.ETAT_ADOPTE)}
                                    text="Adopter"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ADOPTE
                                    }
                                />
                            ) : null}
                        </CustomCol>
                    </CustomRow>
                </UserServiceWrapper>
            </DetailWrapper>
        );
    };
    return renderContent();
}
