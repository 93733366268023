import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { ReleveInstitutionFinanciereOrganisme } from '../../../../components';

export function ReleveInstitutionFinanciereCashCollecteurForm() {
    return (
        <ReleveInstitutionFinanciereOrganisme
            typeOrganisation={TYPE_ORGANISATION.CASH_COLLECTEUR}
        />
    );
}
