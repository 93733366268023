import { useState } from 'react';
import { CustomSwitchButton } from '../button';
import { CustomRow } from './row';

/**
 * Ce composant permet d'afficher ou masque des détails grâce à une radio button
 * @param {Object} param0 props
 * @param {String} param0.title Label du radion button "Par défaut : Afficher plus"
 * @param {Object} param0.children Propriété react children
 * @param {Boolean} param0.defaultChecked if should be checked by default, default value "false"
 * @returns {React.Component}
 */
export function RadioButtonWrapper({
    title = 'Afficher plus',
    children,
    defaultChecked = false
}) {
    const [isDisplayChildren, setDisplayChildren] = useState(defaultChecked);
    return (
        <>
            <CustomRow>
                <CustomSwitchButton
                    text={title}
                    defaultChecked={isDisplayChildren}
                    name="isDisplayChildren"
                    divClassName="offset-1 col-md-8"
                    callback={setDisplayChildren}
                />
            </CustomRow>
            {isDisplayChildren ? children : ''}
        </>
    );
}
