import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListConfigurationInstructionForm() {
    return (
        <ListGenericForm
            reduxPropertyName="configInstruction"
            functionName={URL_CONST.GET_LIST_CONFIGURATION_INSTRUCTION_INITIALS}
            nodeName={REDUX_NODE_NAME.CONFIG_INSTRUCTION}
            mapper={[
                {
                    field: 'numeroCompteExterne',
                    displayName: 'N° Compte'
                },
                {
                    field: 'tempsTemporisation',
                    displayName: 'Temporisation'
                },
                {
                    field: 'intervalle',
                    displayName: 'Intervalle'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
