import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListRegularisationSpecialeForm() {
    return (
        <ListGenericForm
            reduxPropertyName="regularisationSpeciale"
            functionName={URL_CONST.GET_LIST_REGULARISATION_STOCK_INITIALS}
            nodeName={REDUX_NODE_NAME.REGULARISATION_SPECIALE}
            mapper={[
                {
                    field: 'numero',
                    displayName: 'Numéro',
                    position: 2
                },
                {
                    field: 'quantite',
                    displayName: 'Quantité',
                    position: 3
                },
                {
                    field: 'typeOperation',
                    displayName: 'Type Opération',
                    position: 4
                },
                {
                    field: 'imputationCompte.designation',
                    displayName: 'Imputation',
                    position: 5
                },
                {
                    field: 'libelle',
                    displayName: 'Libelle',
                    position: 6
                },
                {
                    field: 'etat',
                    displayName: 'Etat',
                    position: 7
                }
            ]}
        />
    );
}
