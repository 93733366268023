import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { DepotSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    etalage: '',
    etalages: [],
    codeEtalage: '',
    colonne: '',
    codeColonne: '',
    colonnes: [],
    emplacement: 0
};

export function CreationDepotProduitMateriel() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!fields.etalages.length) return;

        const payload = {
            type: 'Depot',
            list: fields.etalages,
            creator
        };

        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_LIST_RAYON_SHOWROOM,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };

    /**
     * L'action qui sera déclenchée pour l'ajout de nouvelles colonnes
     * @returns
     */
    const onAddColonne = () => {
        if (!fields.colonne) return;
        const data = {
            designation: fields.colonne,
            code: fields.codeColonne,
            nombreEmplacementPhysique: fields.emplacement
        };
        const newState = {
            ...initialState,
            fields: {
                ...initialState.fields,
                colonnes: fields.colonnes,
                etalage: fields.etalage,
                codeEtalage: fields.codeEtalage,
                etalages: fields.etalages
            }
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
            payload: {
                newState,
                field: 'colonnes',
                data,
                identifier: 'code'
            }
        });
    };
    const onAddEtalage = () => {
        if (!fields.etalage || !fields.colonnes.length) return;
        const data = {
            designation: fields.etalage,
            code: fields.codeEtalage,
            details: fields.colonnes
        };
        const newState = {
            ...initialState,
            fields: {
                ...initialState.fields,
                etalages: fields.etalages
            }
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
            payload: {
                newState,
                field: 'etalages',
                data,
                identifier: 'code'
            }
        });
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            {/* Recupérer  */}
            {/* Pour la creation des étalages */}
            <CustomRow>
                <CustomLabel text="Etalage*" className="col-sm-2" />
                <CustomInput
                    id="creationDepotFormEtalageId"
                    name="etalage"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Nommer l'étalage"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    id="creationDepotFormCodeEtalageId"
                    name="codeEtalage"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Saisissez le code de l'étalage"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>

            {/* Pour la creation des colonnes */}
            <CustomRow>
                <CustomLabel text="colonne*" className="col-sm-2" />
                <CustomInput
                    id="creationDepotFormColonneId"
                    name="colonne"
                    isInputGroup={false}
                    divClassName="col-sm-3"
                    placeholder="Nommer la colonne"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    id="creationDepotFormCodeColonneId"
                    name="codeColonne"
                    isInputGroup={false}
                    divClassName="col-sm-3"
                    placeholder="saisissez le code de la colonne"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    id="creationDepotFormEmplacementId"
                    name="emplacement"
                    isInputGroup={false}
                    type="number"
                    divClassName="col-sm-3"
                    placeholder="Saisissez le nombre d'emplacement"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={onAddColonne}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.colonnes.length}>
                <SimpleTable
                    data={fields.colonnes}
                    formDispatcher={formDispatcher}
                    identifier="id"
                    listSelectedItemName="colonnes"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            field: 'designation',
                            displayName: 'Colonne'
                        },
                        {
                            position: 3,
                            field: 'code',
                            displayName: 'Code Colonne'
                        },
                        {
                            position: 4,
                            field: 'nombreEmplacementPhysique',
                            displayName: 'Nbre Emplacement'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-primary"
                        onClick={onAddEtalage}
                        text="Ajouter"
                        type="button"
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer l'étalage"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
