import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomRow,
    FormWrapper
} from '../../../components';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    zone: {},
    pointVente: {}
};

// FIXME: should be updated by adding the logic

export function CreationEvolutionStatutRevendeurForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!fields.zone.code) return;

        const payload = {
            creator
        };

        formDispatcher(
            submitPostAction({
                idToken,
                // functionName: URL_CONST.POST_LIST_RAYON_SHOWROOM,
                payload,
                fields,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomDropdown
                    options={[]}
                    label="Zone*"
                    id="creationEvolutionStatutRevendeurZoneSelectionId"
                    defaultOption="Selectionner une zone"
                    name="zone"
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[]}
                    label="Point de vente*"
                    id="creationEvolutionStatutRevendeurRevendeurSelectionId"
                    defaultOption="Selectionner un Revendeur"
                    name="pointVente"
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            </CustomRow>
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Soumettre la dérogation"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
