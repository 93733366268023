import { DB_NODE, ONLY_FETCH } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { ViewLayout } from './view-layout';

export function ListExerciceFinancierForm() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                fetchType={ONLY_FETCH}
                reduxPropertyName="exerciceFinancier"
                functionName={URL_CONST.GET_LIST_EXERCICE}
                nodeName={DB_NODE.EXERCICE_FINANCIER}
                identifier="id"
                mapper={[
                    {
                        field: 'dateDebut',
                        displayName: 'Debut',
                        position: 1,
                        isDate: true
                    },
                    {
                        field: 'dateFin',
                        displayName: 'Fin',
                        position: 2,
                        isDate: true
                    },
                    {
                        field: 'estCloturer',
                        displayName: 'Cloturer',
                        position: 4
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 5
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
