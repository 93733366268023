import { floatParser } from '@napp-inc/jnapp-util';

/**
 * Format un nombre en utilisant l'API Intl.NumbertFormat
 * @param {Object} param0
 * @param {Number} param0.value la valeur qu'il faut formater
 * @param {Number} param0.nombreApresVirgule Nombre de chiffre après virgule
 * @param {String} param0.lang  la langue d'affichage, par défaut : fr-FR
 * @param {String} param0.devise  la devise au cas où il faut afficher un montant
 * @param {Boolean} param0.isPercent  si la valeur est en pourcentage, par défaut : false
 * @param {Boolean} param0.isCompact  s'il faut utilser la notation compact, par exemple 10 k pour 10.000, par défaut : false
 * @param {Boolean} param0.isLongCompactDisplay  s'il faut utilser la notation compact long, par exemple 10 mille pour 10.000, par défaut : false
 * @param {Boolean} param0.useGrouping  s'il faut grouper ou pas, par exemple 10 000 au lieu de 10000, par défaut : true
 * @param {Boolean} param0.isForceParse s'il faut parser nécessairement la value ou pas, par défaut : false
 */
export const formatNumber = ({
    value = 0,
    lang = 'fr-FR',
    devise,
    nombreApresVirgule = 2,
    isCompact = false,
    isLongCompactDisplay = false,
    isPercent = false,
    useGrouping = true,
    isForceParse = false
}) => {
    const options = { useGrouping };
    if (devise) {
        options.style = 'currency';
        options.currency = devise?.slice(-3);
    }
    if (isPercent) {
        options.style = 'percent';
    }
    if (isCompact) {
        options.notation = 'compact';
        if (isLongCompactDisplay) {
            options.compactDisplay = 'long';
        }
    }
    options.maximumFractionDigits = nombreApresVirgule;
    const resultat = new Intl.NumberFormat(lang, options).format(
        isForceParse ? floatParser(value, 10) : value
    );
    return resultat;
};
