import {
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { ViewLayout } from './view-layout';

export function ListActiverCommanderEnLigne() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                path={DB_NODE.PARAMETRE_COMMANDE_EN_LIGNE}
                fetchType={FETCH_LISTENING}
                reduxPropertyName="commanderEnligne"
                functionName={URL_CONST.GET_LIST_PARAMETRE_COMMANDE_EN_LIGNE}
                nodeName={DB_NODE.PARAMETRE_COMMANDE_EN_LIGNE}
                identifier="id"
                listenerFilters={[
                    {
                        property: 'dateCreation',
                        value: Date.now(),
                        operator: FILTER_OPERATOR.INFERIOR_OR_EQUAL,
                        type: FILTER_TYPE.WHERE
                    }
                ]}
                mapper={[
                    {
                        field: 'classe',
                        displayName: 'Classe',
                        position: 1
                    },
                    {
                        field: 'periode.dateDebut',
                        displayName: 'Periode Debut',
                        position: 2
                    },
                    {
                        field: 'periode.dateFin',
                        displayName: 'Periode Fin',
                        position: 3
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 4
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 5
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
