/** Formater for caisse */
export const formaterCaisses = (caisses) =>
    caisses.map((item) => ({
        ...item,
        designation: item?.designation
            ? `${item.designation}-${item?.devise}`
            : `${item?.typeEntite}-${
                  item?.valeurTypeEntite?.designation || ''
              }-${item?.devise || ''}`
    }));
