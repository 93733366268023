import * as yup from 'yup';

/**
 * @description : Schema de Depense Detail form
 */

export const DepenseDetailFormRequiredSchema = yup.object().shape({
    nombreCoupureBilletage: yup.number().min(1),
    actionEncours: yup.string(),
    selectedCoupureBilletage: yup.object().shape({
        code: yup.string().required()
    }),
    listBilletage: yup.array().of(
        yup
            .object({
                id: yup.string().required(),
                devise: yup.object().shape({
                    code: yup.string().required()
                }),
                coupure: yup.object().shape({
                    code: yup.string().required()
                }),
                nombre: yup.number().min(1)
            })
            .shape({})
    )
});
