import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { FORM_CONST, formInitialState, URL_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    HorairesJoursComponent,
    CustomCenteredModal,
    CustomButtonLoader,
    CustomButton,
    CustomRow,
    CustomCol
} from '../../../components';

const defaultFields = {
    isShowModal: false,
    isLundiOuvert: false,
    isLundiH24: true,
    heureDebutLundi: '',
    heureFinLundi: '',
    isMardiOuvert: false,
    isMardiH24: true,
    heureDebutMardi: '',
    heureFinMardi: '',
    isMercrediOuvert: false,
    isMercrediH24: true,
    heureDebutMercredi: '',
    heureFinMercredi: '',
    isJeudiOuvert: false,
    isJeudiH24: true,
    heureDebutJeudi: '',
    heureFinJeudi: '',
    isVendrediOuvert: false,
    isVendrediH24: true,
    heureDebutVendredi: '',
    heureFinVendredi: '',
    isSamediOuvert: false,
    isSamediH24: true,
    heureDebutSamedi: '',
    heureFinSamedi: '',
    isDimancheOuvert: false,
    isDimancheH24: true,
    heureDebutDimanche: '',
    heureFinDimanche: ''
};

export function HoraireConfigForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (event) => {
        if (!fields.name) return;
        event.preventDefault();
        submitPutAction({
            reduxDispatcher,
            functionName: URL_CONST.POST_FAKE,
            fields,
            idToken,
            payload: { value: fields.isLundiOuvert }
        });
    };
    return (
        <div className="">
            <CustomCenteredModal
                header="Horaire"
                size="lg"
                show={fields.isShowModal}
                onHide={() =>
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: { field: 'isShowModal', data: false }
                    })
                }
                footer={
                    <CustomButton
                        className="btn btn-success"
                        onClick={() =>
                            formDispatcher({
                                type: FORM_CONST.FIELD_CHANGE,
                                payload: {
                                    field: 'isShowModal',
                                    data: false
                                }
                            })
                        }
                    >
                        Valider les changements
                    </CustomButton>
                }
            >
                <HorairesJoursComponent
                    formDispatcher={formDispatcher}
                    isLundiOuvert={fields.isLundiOuvert}
                    isLundiH24={fields.isLundiH24}
                    isMardiOuvert={fields.isMardiOuvert}
                    isMardiH24={fields.isMardiH24}
                    isMercrediOuvert={fields.isMercrediOuvert}
                    isMercrediH24={fields.isMercrediH24}
                    isJeudiOuvert={fields.isJeudiOuvert}
                    isJeudiH24={fields.isJeudiH24}
                    isVendrediOuvert={fields.isVendrediOuvert}
                    isVendrediH24={fields.isVendrediH24}
                    isSamediOuvert={fields.isSamediOuvert}
                    isSamediH24={fields.isSamediH24}
                    isDimancheOuvert={fields.isDimancheOuvert}
                    isDimancheH24={fields.isDimancheH24}
                />
            </CustomCenteredModal>
            <CustomRow>
                <CustomCol>
                    <CustomButton
                        className="btn btn-secondary"
                        onClick={() =>
                            formDispatcher({
                                type: FORM_CONST.FIELD_CHANGE,
                                payload: {
                                    field: 'isShowModal',
                                    data: true
                                }
                            })
                        }
                    >
                        Editer les horaires
                    </CustomButton>
                    <div className="w-100" />
                    <fieldset>
                        <legend>
                            <p className="note">
                                Definissez un horaire pour permettre à votre
                                clientèle de connaitre vos heures
                                d&apos;ouverture et de fermeture.
                            </p>
                        </legend>
                    </fieldset>
                    {/* <CustomCol className="offset-md-4 col-md-4"> */}
                    <div className="w-100" />
                    <CustomButtonLoader
                        className="btn-success mt-3"
                        onClick={handleSubmit}
                        text="Valider horaire"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                    {/* </CustomCol> */}
                </CustomCol>
                {/* <CustomCol>
                    <p>Des</p>
                </CustomCol> */}
            </CustomRow>
        </div>
    );
}
