import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    ETAT,
    FORM_CONST,
    TYPE_ENTITE_TARIF_CHARGE,
    URL_CONST
} from '@napp-inc/jnapp-util';
import {
    CustomButtonLoader,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    RadioButtonWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailTarifRessourceForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({ nodeName: 'tarifsRessource' });

    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const payload = {
            etat,
            creator,
            idTarif: entity?.id
        };
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_TARIF,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TARIF_RESSOURCE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TARIF_BY_ID}
            nodeName={REDUX_NODE_NAME.TARIF_RESSOURCE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Tarif Ressource"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <RadioButtonWrapper defaultChecked>
                {entity && entity?.details?.length && (
                    <CustomRow>
                        <CustomCol className="offset-md-2 col-md-10 col-12">
                            <SimpleTable
                                isShowDropButton={false}
                                identifier="id"
                                data={entity?.details?.filter(
                                    (item) =>
                                        item?.typeEntite ===
                                        TYPE_ENTITE_TARIF_CHARGE.code
                                )}
                                tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                filter={false}
                                bottomPagination={false}
                                isLinkToDetail={false}
                                mapper={[
                                    {
                                        field: 'typeEntite',
                                        displayName: 'Type tarif',
                                        position: 1
                                    },
                                    {
                                        field: 'valeurTypeEntite.designation',
                                        displayName: 'Charge',
                                        position: 2
                                    },
                                    {
                                        field: 'taux',
                                        displayName: 'Taux (%)',
                                        position: 4
                                    }
                                ]}
                            />
                        </CustomCol>
                    </CustomRow>
                )}
            </RadioButtonWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ANNULER_TARIFICATION_RESSOURCE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .LECTURE_TARIFICATION_RESSOURCE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .VALIDER_TARIFICATION_RESSOURCE,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ATTENTE_VALIDATION_TARIFICATION_RESSOURCE
                ]}
            >
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        {entity?.etat === ETAT.ETAT_ENCOURS ? (
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                text="Mettre en attente"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        ) : null}
                        {entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION ? (
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                                text="Valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        ) : null}
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
