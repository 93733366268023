import { TYPE_COMPTE_BANCAIRE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../../components';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import { ViewLayout } from './view-layout';

export function ListCompteBancaire() {
    const formater = (params = []) =>
        params
            .map((item) => ({
                ...item,
                gestionnaireIdentite: `${item?.gestionnaire?.prenom} ${item?.gestionnaire?.nom}`,
                type: item?.typeCompteExterne
            }))
            .filter(
                (item) => item.typeProprietaire === TYPE_COMPTE_BANCAIRE.code
            );
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                formater={formater}
                reduxPropertyName="comptesBancaire"
                functionName={URL_CONST.GET_LIST_COMPTE_PARALLELE_INITIALS}
                nodeName={REDUX_NODE_NAME.COMPTE_BANCAIRE}
                mapper={[
                    {
                        field: 'type',
                        displayName: 'Type',
                        position: 1
                    },
                    {
                        field: 'gestionnaireIdentite',
                        displayName: 'Gestionnaire',
                        position: 2
                    },
                    {
                        field: 'libelle',
                        displayName: 'Libellé',
                        position: 3
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 4
                    },
                    {
                        field: 'institutionFinanciere.designation',
                        displayName: 'Banque',
                        position: 5
                    },
                    {
                        field: 'numero',
                        displayName: 'Numero de compte',
                        position: 6
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 7
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
