import { FORM_CONST } from '@napp-inc/jnapp-util';

/**
 * Retourne un componsant de type switch
 * @param {Object} param0 object with followings props
 * @param {String} param0.name name of the field
 * @param {id} param0.id id of the input used for htmlFor attribute
 * @param {Function} param0.formDispatcher function to dispatch action on form state
 * @param {String} param0.text text used for the label
 * @param {String} param0.offText text to show in case the switch is off
 * @param {Boolean} param0.value the state of the input
 * @param {Object} param0.formState object with the state of the form
 * @param {String} param0.inputClassName additional classname for the input
 * @param {String} param0.labelClassName additional classname for the label
 * @param {String} param0.divClassName additional classname for the div
 * @param {Boolean} param0.disabled if the input should be disabled
 * @param {String} param0.inputTitle Tooltip of the input
 * @param {Function} param0.callback specific function to call when a change occur
 * @returns {React.Component}
 */
export function CustomSwitchButton({
    id,
    name,
    formDispatcher,
    value,
    offText = '',
    text = '',
    inputClassName = '',
    labelClassName = '',
    divClassName = '',
    disabled = false,
    inputTitle = '',
    arrayName,
    formState,
    callback,
    defaultChecked
}) {
    const handleChange = (event) => {
        const { checked } = event.target;
        if (callback && typeof callback === 'function') {
            callback(checked);
            return;
        }
        if (checked && arrayName) {
            formDispatcher({
                type: FORM_CONST.ADD_PRIMITIVE_ITEM,
                payload: { field: arrayName, data: value }
            });
        }
        if (!checked && arrayName) {
            formDispatcher({
                type: FORM_CONST.REMOVE_PRIMITIVE_ITEM,
                payload: { field: arrayName, data: value }
            });
        }
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: { field: name, data: checked }
        });
    };
    return (
        <div className={`form-check form-switch ${divClassName}`}>
            <label
                className={`form-check-label ${labelClassName}`}
                htmlFor={id}
            >
                <input
                    defaultChecked={defaultChecked}
                    checked={formState?.fields?.[name]}
                    className={`form-check-input ${inputClassName}`}
                    type="checkbox"
                    role="switch"
                    id={id}
                    onChange={handleChange}
                    disabled={disabled}
                    title={inputTitle}
                />
                {offText && !false ? offText : text}
            </label>
        </div>
    );
}
