import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddButton,
    CustomAlert,
    CustomButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { TarifChargeRessourceComponent } from './tarif-charge-ressource';
import { TarifRessourceFormSchema as uiValidator } from './validation';

const defaultFields = {
    selectedServiceGenerateur: {},
    selectedRessource: {},
    chargesToUse: [],
    pourcentage: 0,
    listTarif: [],
    selectedType: {},
    selectedCategorie: {},
    ressourcesToUse: [],
    estTarifPreferentiel: false,
    tillNumber: '',
    payload: {}
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: ['ressources', 'typeMarchandRessource', 'categorieMarchand']
};

export function CreationTarifRessourceForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        ressources,
        etatRessource,
        categoriesMarchand,
        etatCategorieMarchand,
        typesMarchand,
        etatTypeMarchandRessource,
        typesMarchandRessource,
        chargesVariable,
        etatChargeVariable
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        ressources: reduxState.ressources,
        etatRessource: reduxState.etat.ressources.etat,
        chargesVariable: reduxState.chargesVariable,
        etatChargeVariable: reduxState.etat.chargesVariable.etat,
        categoriesMarchand: reduxState.categoriesMarchand.filter(
            (item) => item.typeEntite === 'Categorie'
        ),
        typesMarchand: reduxState.categoriesMarchand.filter(
            (item) => item.typeEntite === 'Type'
        ),
        etatCategorieMarchand: reduxState.etat.categoriesMarchand.etat,
        typesMarchandRessource: reduxState.typesMarchandRessource,
        etatTypeMarchandRessource: reduxState.etat.typesMarchandRessource.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: ['marchand']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_RESSOURCE,
                nodeName: DB_NODE.RESSOURCE,
                etat: etatRessource
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_VARIABLE,
                nodeName: REDUX_NODE_NAME.CHARGE_VARIABLE,
                etat: etatChargeVariable
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CATEGORIE_MARCHAND,
                nodeName: DB_NODE.CATEGORIE_MARCHAND,
                etat: etatCategorieMarchand
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_TYPE_MARCHAND_RESSOURCE,
                nodeName: DB_NODE.TYPE_MARCHAND_RESSOURCE,
                etat: etatTypeMarchandRessource
            }
        ]
    });
    useEffect(() => {
        if (fields?.selectedType?.code && typesMarchandRessource.length) {
            const ressourceArray = [];
            typesMarchandRessource.forEach((typeMR) => {
                if (
                    typeMR?.typesMarchand.find(
                        (item) => item?.code === fields.selectedType.code
                    )
                ) {
                    const res = ressources.find(
                        ({ code }) => code === typeMR?.ressource.code
                    );
                    if (res) {
                        ressourceArray.push(res);
                    }
                }
            });
            formDispatcher({
                type: FORM_CONST.ADD_ITEMS,
                payload: {
                    identifier: 'code',
                    field: 'ressourcesToUse',
                    data: ressourceArray
                }
            });
        }
    }, [
        formDispatcher,
        fields.selectedType,
        typesMarchandRessource,
        ressources
    ]);
    // define charges of ressource
    useEffect(() => {
        if (fields.selectedRessource?.code && chargesVariable.length) {
            const chargesAdditional = [];
            const servGenerateur = ressources.find(
                (item) => item.code === fields?.selectedRessource?.code
            )?.serviceGenerateur;
            if (servGenerateur) {
                chargesVariable.forEach((item) => {
                    if (
                        item?.serviceGenerateur?.code === servGenerateur?.code
                    ) {
                        chargesAdditional.push({
                            id: item?.code,
                            code: item?.code,
                            designation: item?.designation,
                            value: 0,
                            typeEntite: item?.typeEntite
                        });
                    }
                });
            }
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field: 'chargesToUse',
                    data: chargesAdditional
                }
            });
        }
    }, [fields.selectedRessource, chargesVariable, formDispatcher, ressources]);
    const ajouterCharge = () => {
        const { pourcentage, selectedRessource, selectedType, chargesToUse } =
            fields;
        if (!selectedRessource?.code || !selectedType?.code || !pourcentage)
            return;
        if (fields.listTarif.length > 0) return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listTarif',
                data: {
                    id: `${selectedType.code}${selectedRessource.code}`,
                    typeMarchand: selectedType,
                    ressource: {
                        ...selectedRessource,
                        taux: pourcentage,
                        charges: chargesToUse.map((chg) => ({
                            code: chg.code,
                            designation: chg.designation,
                            taux: chg.value || 0
                        }))
                    }
                }
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'selectedRessource',
                data: {}
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'chargesToUse',
                data: []
            }
        });
    };
    useEffect(() => {
        if (fields.estTarifPreferentiel && elements.marchand) {
            if (elements.marchand?.value?.categorieMarchand) {
                const type = {
                    code: elements.marchand.value?.categorieMarchand.code,
                    designation:
                        elements.marchand.value?.categorieMarchand.designation
                };
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'selectedType',
                        data: type
                    }
                });
            } else {
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'selectedType',
                        data: {}
                    }
                });
            }
        }
    }, [fields.estTarifPreferentiel, elements.marchand, formDispatcher]);
    const fetchMarchand = () => {
        const { tillNumber } = fields;
        if (tillNumber && tillNumber !== elements.marchand?.value?.tillNumber) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'marchand',
                    payload: { tillNumber },
                    functionName: URL_CONST.GET_MARCHAND_BY_TILL_NUMBER,
                    etat: elements.marchand.status,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { listTarif, estTarifPreferentiel, selectedType } = fields;
        const { marchand } = elements;
        if (!listTarif.length) return;
        if (estTarifPreferentiel && !marchand?.value?.id) return;
        const list = [];
        listTarif.forEach((tarif) => {
            const existing = list.find(
                (item) => tarif.typeMarchand.code === item.codeTypeMarchand
            );
            if (existing) {
                existing.ressources.push({
                    code: tarif.ressource.code,
                    taux: tarif.ressource.taux,
                    charges: tarif.ressource.charges.map((element) => ({
                        code: element.code,
                        taux: element.taux
                    }))
                });
            } else {
                list.push({
                    codeTypeMarchand: tarif.typeMarchand.code,
                    ressources: [
                        {
                            code: tarif.ressource.code,
                            taux: tarif.ressource.taux,
                            charges: tarif.ressource.charges.map((element) => ({
                                code: element.code,
                                taux: element.taux
                            }))
                        }
                    ]
                });
            }
        });
        const payload = {
            creator
        };
        if (!estTarifPreferentiel) {
            payload.list = list;
        } else {
            payload.ressources =
                list.find(
                    (item) => item.codeTypeMarchand === selectedType?.code
                )?.ressources || [];
            payload.idMarchand = marchand?.value?.id;
        }
        const functionName = estTarifPreferentiel
            ? URL_CONST.POST_TARIF_MARCHAND
            : URL_CONST.POST_TARIF_RESSOURCE;
        formDispatcher(
            submitPostAction({
                idToken,
                functionName,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TARIF_RESSOURCE,
                reduxDispatcher
                // uiValidator
            })
        );

        // formDispatcher({
        //     type: FORM_CONST.FIELD_CHANGE,
        //     payload: {
        //         field: 'payload',
        //         data: payload
        //     }
        // });
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'ressources',
                    etat: etatRessource
                },
                {
                    dependency: 'typeMarchandRessource',
                    etat: etatTypeMarchandRessource
                },
                {
                    dependency: 'categorieMarchand',
                    etat: etatCategorieMarchand
                }
            ]}
        >
            <CustomRow>
                <CustomSwitchButton
                    id="estTarifPreferentielCreationTarifRessource"
                    text="Tarif préférentiel"
                    inputTitle="Tarif preferentiel pour un marchand"
                    formDispatcher={formDispatcher}
                    name="estTarifPreferentiel"
                    divClassName="col-md-8"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            {fields.estTarifPreferentiel && (
                <>
                    <CustomRow>
                        <CustomInput
                            type="text"
                            label="Till number*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-10 col-sm-8"
                            isInputGroup={false}
                            placeholder="Saisissez le tillNumber"
                            formDispatcher={formDispatcher}
                            name="tillNumber"
                            id="tillNumberCreationTarifRessource"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                        <CustomCol className="col-2">
                            <CustomButton
                                disabled={
                                    elements.marchand.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                                onClick={fetchMarchand}
                            >
                                Rechercher
                            </CustomButton>
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomCol className="offset-md-2 col-12 col-md-10">
                            {elements.marchand.status ===
                                FORM_CONST.FETCH_ELEMENT_FAILED && (
                                <CustomAlert
                                    error={`${elements.marchand?.error || ''}`}
                                />
                            )}
                            {elements.marchand.status ===
                                FORM_CONST.FETCH_ELEMENT_SUCCESS && (
                                <CustomAlert
                                    successMessage={`${
                                        elements.marchand?.value?.tillNumber ||
                                        ''
                                    } - ${
                                        elements.marchand?.value?.designation ||
                                        ''
                                    }`}
                                />
                            )}
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            options={typesMarchand}
                            label="Type marchand*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            name="selectedType"
                            id="selectedTypeCreationTarifRessource"
                            formState={formState}
                            propertyToDisplay="designation"
                            idProperty="code"
                            disabled
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                </>
            )}
            {!fields.estTarifPreferentiel && (
                <>
                    <CustomRow>
                        <CustomDropdown
                            options={categoriesMarchand}
                            label="Categorie marchand*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            name="selectedCategorie"
                            id="selectedCategorieCreationTarifRessource"
                            formState={formState}
                            propertyToDisplay="designation"
                            idProperty="code"
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            options={typesMarchand.filter(
                                (typeRes) =>
                                    typeRes?.parent?.code ===
                                    fields.selectedCategorie?.code
                            )}
                            label="Type marchand*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            name="selectedType"
                            id="selectedTypeCreationTarifRessource"
                            formState={formState}
                            propertyToDisplay="designation"
                            idProperty="code"
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                </>
            )}
            <CustomRow>
                <CustomDropdown
                    options={fields.ressourcesToUse}
                    label="Ressource*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedRessource"
                    id="selectedCRessourceCreationTarifRessource"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    isFloat
                    isInputGroup={false}
                    label="Pourcentage*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-11 col-md-9"
                    min={0}
                    placeholder="Saisissez le pourcentage"
                    formDispatcher={formDispatcher}
                    name="pourcentage"
                    id="pourcentageCreationTarifRessource"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomCol className="col-1">
                    <CustomAddButton callback={ajouterCharge} />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-2 col-10">
                    <TarifChargeRessourceComponent
                        charges={fields.chargesToUse}
                        nameListChargeRessource="chargesToUse"
                        formDispatcher={formDispatcher}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    <SimpleTable
                        formDispatcher={formDispatcher}
                        listSelectedItemName="listTarif"
                        data={fields.listTarif}
                        identifier="id"
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                field: 'typeMarchand.designation',
                                displayName: 'Type marchand',
                                position: 1
                            },
                            {
                                field: 'ressource.designation',
                                displayName: 'ressource',
                                position: 2
                            },
                            {
                                field: 'ressource.taux',
                                displayName: 'taux',
                                position: 3
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
