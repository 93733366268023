import { ETAT } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { CorrespondanceCollection, URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListTunnelForm() {
    return (
        <ListGenericForm
            reduxPropertyName="tunnels"
            functionName={URL_CONST.GET_LIST_TUNNEL}
            nodeName={REDUX_NODE_NAME.TUNNEL}
            formater={(data) =>
                data.map((item) => ({
                    ...item,
                    etat: item?.estOuvert ? ETAT.ETAT_OUVERT : ETAT.ETAT_FERMER,
                    designation:
                        CorrespondanceCollection[item?.collection] ||
                        item?.collection
                }))
            }
            mapper={[
                {
                    field: 'designation',
                    displayName: 'Désignation'
                },
                {
                    field: 'typeBusiness',
                    displayName: 'Type business'
                },
                {
                    field: 'type',
                    displayName: 'Type'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    field: 'dateDerniereModification',
                    displayName: 'Last update'
                }
            ]}
        />
    );
}
