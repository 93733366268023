import { CustomCol, CustomRow } from '../container';
import { CustomCard } from './custom-card';

/**
 * Affiche un composant avec les cartes entree, sortie, ouverture et fermeture
 * les valeurs de ces éléments sont lus depuis un objet "aggregated" du formState reçu en props
 * Accompagne ces valeurs éventuellement d'une devise lu aussi depuis le formState
 * @param {Object} param0
 * @param {Object} param0.formState
 * @returns {React.Component}
 */
export function MouvementCardsComponent({ formState }) {
    const aggregated = formState?.fields?.aggregated;
    const devise = formState?.fields?.devise;
    const renderContent = () => {
        if (!Object.keys(aggregated).length) return null;
        return (
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomCard
                        text="Ouverture"
                        value={aggregated?.ouverture || 0}
                        className="bg-secondary"
                        devise={devise}
                    />
                </CustomCol>
                <CustomCol className="col-3">
                    <CustomCard
                        text="Entree"
                        value={aggregated?.entree || 0}
                        className="bg-primary"
                        devise={devise}
                    />
                </CustomCol>
                <CustomCol className="col-3">
                    <CustomCard
                        text="Sortie"
                        value={aggregated?.sortie || 0}
                        className="bg-warning"
                        devise={devise}
                    />
                </CustomCol>
                <CustomCol className="col-3">
                    <CustomCard
                        text="Fermeture"
                        value={aggregated?.fermeture || 0}
                        className="bg-success"
                        devise={devise}
                    />
                </CustomCol>
            </CustomRow>
        );
    };
    return renderContent();
}
