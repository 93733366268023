import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    CustomSwitchButton,
    CustomTextAreaInput,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ProduitMaterielSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    codeProduit: '',
    designationProduit: '',
    designationCourteProduit: '',
    selectedUnite: {},
    descriptionProduit: '',
    temperatureProduit: '',
    selectedEtatProduit: {},
    prixVente: 0,
    estInflammable: false,
    estFragile: false,
    delaiConsignation: 0,
    longueur: 0,
    largeur: 0,
    hauteur: 0,
    poids: 0,
    selectedIngredient: {},
    ingredients: [],
    quantite: 0
};

/**
 * @description defaultDeps de notre state
 */
const defaultDependencies = { fields: ['ingredients'] };

export function CreationArticleProduitMaterielForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, ingredients, etatIngredient } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            ingredients: reduxState.ingredients,
            etatIngredient: reduxState.etat.ingredients.etat
        })
    );
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * @description récupérer les produits marchand
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_INGREDIENT_MARCHAND,
                nodeName: REDUX_NODE_NAME.INGREDIENTS,
                etat: etatIngredient
            }
        ]
    });

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            newProduit: {
                code: fields.codeProduit,
                designation: fields.designationProduit,
                designationCourte: fields.designationCourte,
                unite: fields.selectedUnite.code,
                description: fields.descriptionProduit,
                temperature: fields.temperatureProduit,
                etat: fields.selectedEtatProduit.code,
                prixVente: fields.prixVente,
                estInflammable: fields.estInflammable,
                estFragile: fields.estFragile,
                delaiConsignation: fields.delaiConsignation,
                cbm: {
                    longueur: fields.longueur,
                    largeur: fields.largeur,
                    hauteur: fields.largeur,
                    poids: fields.poids
                }
            },
            creator,
            ingredients: fields.ingredients
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_PRODUIT_MATERIEL,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };

    /**
     * L'action qui sera déclenchée pour l'ajout des Ingredients
     * @returns
     */
    const onAddIngredient = () => {
        const data = {
            code: fields.selectedIngredient.code,
            quantite: fields.quantite
        };
        const newState = {
            ...initialState,
            fields: {
                ...initialState.fields,
                codeProduit: fields.codeProduit,
                designationProduit: fields.designationProduit,
                designationCourteProduit: fields.designationCourteProduit,
                selectedUnite: fields.selectedUnite,
                descriptionProduit: fields.descriptionProduit,
                temperatureProduit: fields.temperature,
                selectedEtatProduit: fields.selectedEtatProduit,
                prixVente: fields.prixVente,
                estInflammable: fields.estInflammable,
                estFragile: fields.estFragile,
                delaiConsignation: fields.delaiConsignation,
                longueur: fields.longueur,
                largeur: fields.largeur,
                hauteur: fields.hauteur,
                poids: fields.poids,
                ingredients: fields.ingredients
            }
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
            payload: {
                newState,
                field: 'ingredients',
                data,
                identifier: 'code'
            }
        });
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'ingredients',
                    etat: etatIngredient
                }
            ]}
        >
            <CustomRow>
                <CustomInput
                    label="Code*"
                    labelClassName="col-sm-2"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    placeholder="Code du produit"
                    id="creationArticleProduitMaterielCodeProduitId"
                    name="codeProduit"
                    isInputGroup={false}
                    divClassName="col-sm-10"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    label="Designation*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Designation du produit"
                    name="designationProduit"
                    id="creationArticleProduitMaterielDesignationProduitId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    label="Designation Courte*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Designation courte du produit"
                    name="designationCourteProduit"
                    id="creationArticleProduitMaterielDesignationCourteProduitId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomTextAreaInput
                    label="Description*"
                    rows={5}
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Description du produit"
                    name="descriptionProduit"
                    id="creationArticleProduitMaterielDescriptionProduitId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        { code: 'Liquide', designation: 'Liquide' },
                        { code: 'Solide', designation: 'Solide' },
                        { code: 'Gazeux', designation: 'Gazeux' }
                    ]}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedEtatProduit"
                    id="creationArticleProduitMaterielSelectedEtatProduitId"
                    defaultOption="Selectionner l'unité"
                    label="Etat*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isFloat
                    label="Prix de Vente*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Prix de vente"
                    name="prixVente"
                    type="number"
                    id="creationArticleProduitMaterielPrixVenteId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isFloat
                    label="Temperature*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Temperature"
                    name="temperatureProduit"
                    id="creationArticleProduitMaterielTemperatureProduitId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Est Inflammable*" className="col-sm-2" />
                <CustomSwitchButton
                    id="creationArticleProduitMaterielEstInflammableId"
                    formDispatcher={formDispatcher}
                    name="estInflammable"
                    divClassName="col-sm-10"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Est Fragile*" className="col-sm-2" />
                <CustomSwitchButton
                    id="creationArticleProduitMaterielEstFragileId"
                    formDispatcher={formDispatcher}
                    name="estFragile"
                    divClassName="col-sm-10"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    name="selectedUnite"
                    id="creationIngredientFormUniteId"
                    options={[
                        { code: 'Litre', designation: 'Litre' },
                        { code: 'gramme', designation: 'gramme' }
                    ]}
                    defaultOption="Selectionner l'unite"
                    label="Unité*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    label="Delai Consignation*"
                    type="number"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Delai de consignation..."
                    name="delaiConsignation"
                    id="creationArticleProduitMaterielDelaiConsignationId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isFloat
                    label="Largeur*"
                    type="number"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Largeur"
                    name="largeur"
                    id="creationArticleProduitMaterielLargeurId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isFloat
                    label="Hauteur*"
                    type="number"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Hauteur"
                    name="hauteur"
                    id="creationArticleProduitMaterielHauteurId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isFloat
                    label="Longueur*"
                    type="number"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Longueur"
                    name="longueur"
                    id="creationArticleProduitMaterielLongueurId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isFloat
                    label="Poids*"
                    type="number"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Poids"
                    name="poids"
                    id="creationArticleProduitMaterielPoidsId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Ingredient" className="col-sm-2" />
                <CustomDropdown
                    options={ingredients}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedIngredient"
                    id="creationArticleProduitMaterielSelectedIngredientId"
                    defaultOption="Selectionner l'ingredient"
                    divClassName="col-sm-4"
                    uiValidator={uiValidator}
                />
                <CustomInput
                    divClassName="col-sm-4"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    type="number"
                    placeholder="Quantité"
                    name="quantite"
                    id="creationArticleProduitMaterielQuantiteId"
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-2"
                    callback={onAddIngredient}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.ingredients.length}>
                <SimpleTable
                    formDispatcher={formDispatcher}
                    data={fields.ingredients}
                    listSelectedItemName="ingredients"
                    identifier="code"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            field: 'code',
                            displayName: 'Code'
                        },
                        {
                            position: 2,
                            field: 'quantite',
                            displayName: 'Quantité'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer l'article"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
