import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    URL_CONST,
    agregationMouvement
    // traitementMouvement
} from '../../../util';
import { MainReportComponent } from '../../report/releve/generic';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isDisplayChart: false,
    selectedCompte: {},
    selectedSuperDealer: {},
    selectedSuperDealerCompte: {},
    isFetching: false,
    aggregated: {},
    devise: '',
    chartTitle: '',
    yAxisTittle: ''
};

const mapper = [
    {
        displayName: 'Marchand',
        field: 'designation'
    },
    {
        displayName: 'Initial',
        field: 'soldeOuverture'
    },
    {
        displayName: 'Livrées',
        field: 'operation.CommandesFlashLivrees.montant'
    },
    {
        displayName: 'En attente',
        field: 'operation.CommandesFlashAttenteValidation.montant'
    },
    {
        displayName: 'Facturation',
        field: 'operation.Facturation.montant'
    },
    {
        displayName: 'Régul Entrée',
        field: 'operation.RegularisationEntree.montant'
    },
    {
        displayName: 'Régul Sortie',
        field: 'operation.RegularisationSortie.montant'
    },
    {
        displayName: 'Fermeture',
        field: 'soldeFermeture'
    }
];

export function RapportEvolutionStockByDateForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements', 'comptes']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    // const mouvementsFormater = (listMouvement) =>
    //     traitementMouvement(listMouvement).sort(
    //         (a, b) => b.dateCreation - a.dateCreation
    //     );
    const { fields, elements } = formState;
    const fetchOnSuccess = ({ element }) => {
        const agregees = agregationMouvement(element || []);
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'aggregated',
                data: agregees
            }
        });
    };

    const formatDataOnReturnSuccess = (data) =>
        data.map((item) => {
            let typeBusiness = 'TB, BM';
            if (item?.detail?.typeBusiness?.length < 2) {
                typeBusiness = item?.detail?.typeBusiness?.join(' , ');
                typeBusiness = typeBusiness.replace('Businessmodel', 'BM');
                typeBusiness = typeBusiness.replace('BusinessModel', 'BM');
                typeBusiness = typeBusiness.replace(
                    'Traditionnelbusiness',
                    'TB'
                );
                typeBusiness = typeBusiness.replace(
                    'TraditionnelBusiness',
                    'TB'
                );
            }
            return {
                ...item,
                id: `${item?.detail?.marchand?.reference}${typeBusiness}`,
                designation: `${item?.detail?.marchand?.designation || ''}${
                    typeBusiness ? ` - ${typeBusiness}` : ''
                }`
            };
        });
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedCompte } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const devise = selectedCompte?.designation?.split(' ').reverse()?.[0];
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'devise',
                data: devise
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'chartTitle',
                data: `Compte : ${selectedCompte?.designation || ''}`
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'yAxisTittle',
                data: `Montant (${devise || ''})`
            }
        });

        // FIXME: use idLimiCredit instead of fixed id
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload: {
                    // valeurTypeProprietaire:
                    //     fields?.selectedSuperDealerCompte?.code,
                    // // valeurTypeProprietaire: selectedCompte?.idLimitCredit,
                    // typeProprietaire: DB_NODE.STOCK,
                    dateDebut,
                    dateFin,
                    typeEntite: 'Journalier',
                    typeRapport: 'EvolutionStock',
                    estNotDate: true
                },
                functionName: URL_CONST.GET_LIST_STOCK_SUPER_DEALER,
                etat: elements.mouvements.status,
                formDispatcher,
                formater: formatDataOnReturnSuccess,
                // formater: mouvementsFormater,
                onSuccess: fetchOnSuccess,
                reduxDispatcher
            })
        );
    };
    return (
        <MainReportComponent
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={mapper}
            dataList={
                elements.mouvements?.value ||
                [
                    // {
                    //     creator: '05gXaYB0QHelq0RGGsJOeiv43mP2',
                    //     dateCreation: 1684856165976,
                    //     soldePrecedent: 12,
                    //     typeOperation: 'SORTIE',
                    //     valeurTypeProprietaire: 'hNtD44YawdZRXt3G0R4P',
                    //     soldeActuel: 9,
                    //     montant: 10,
                    //     estRetarder: false,
                    //     dateDerniereModification: 1684856165976,
                    //     typeEntite: 'ApprovisionnementMonnaie',
                    //     estBudget: false,
                    //     typeProprietaire: 'Compte',
                    //     dateDerniereModificationId:
                    //         '1684856165976Ki5uu67bGKR6rk5TxeUc',
                    //     dateCreationId: '1684856165976Ki5uu67bGKR6rk5TxeUc',
                    //     valeurTypeEntite: 'bR3QCaVLh0hjQwhmNdBZ',
                    //     id: 'Ki5uu67bGKR6rk5TxeUc'
                    // }
                ]
            }
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
            // topOfChart={MouvementCardsComponent}
        />
    );
}
