import { GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner les comptes
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @param {Boolean} param0.isReleve form state du parent
 * @param {String} param0.label Label du dropdown, par défaut "Caisse : "
 * @param {String} param0.propertyName propriété où la valeur de la caisse selectionnée est enregistrée dans le form state, par defaut "selectedCaisse"
 * @param {String} param0.labelClassName className du label
 * @param {String} param0.divClassName className du div
 * @param {String} param0.propertyToDisplay nom de la propriété à afficher dans le dropdown, par défaut : "designation"
 * @param {String} param0.idProperty propriété id à utiliser, par défaut : "id"
 * @returns {React.Component}
 */
export function ReportCreanceSelector({ formDispatcher, formState }) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, comptes, etatCompte } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        comptes: reduxState.compteCreance,
        etatCompte: reduxState.etat.compteCreance.etat
    }));

    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                nodeName: REDUX_NODE_NAME.COMPTE_CREANCE,
                functionName: URL_CONST.GET_LIST_COMPTE_CREANCE,
                etat: etatCompte
            }
        ]
    });
    const renderContent = () => {
        if (etatCompte === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS) {
            return (
                <CustomRow>
                    <CustomDropdown
                        options={comptes}
                        defaultOption="Selectionner Compte Créance"
                        label="Créance"
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedCompteCreance"
                        id="selectedCompteCreanceRapportCompteId"
                        formState={formState}
                    />
                </CustomRow>
            );
        }
        if (etatCompte === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
