import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    ADMINISTRATION_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { TransferBalanceForm } from './creation-form';

const defaultFields = {
    selectedSuperDealer: {},
    selectedCompteA: {},
    selectedCompteB: {},
    selectedTypeSource: ADMINISTRATION_CONST.BALANCE,
    montant: 0,
    previewValue: ''
};

const defaultElements = ['comptes'];

const setDefaultProperties = ({ type }) => {
    const payload = {
        compteUser: {
            estOrganisation: true,
            typeProprietaire: 'SuperDealer',
            typesEntite: ['Caution', 'Napp'],
            typeOrganisation: type
        },
        superDealer: { estAll: true, typeEntite: type }
    };

    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: { fields: ['superDealer'] },
            elements: defaultElements,
            uiValidator: yup.object().shape({
                selectedSuperDealer: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedCompteA: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedCompteB: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedTypeSource: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                previewValue: yup.string(),
                montant: yup.number().min(1)
            }),
            cfPayload: payload
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR) {
        return {
            fields: defaultFields,
            dependencies: { fields: ['superDealer'] },
            elements: defaultElements,
            uiValidator: yup.object().shape({
                selectedSuperDealer: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedCompteA: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedCompteB: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedTypeSource: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                previewValue: yup.string(),
                montant: yup.number().min(1)
            }),
            cfPayload: payload
        };
    }
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR) {
        return {
            fields: defaultFields,
            dependencies: { fields: ['comptes'] },
            elements: defaultElements,
            uiValidator: yup.object().shape({
                selectedCompteA: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedCompteB: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedTypeSource: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                previewValue: yup.string(),
                montant: yup.number().min(1)
            }),
            cfPayload: payload
        };
    }
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        return {
            fields: defaultFields,
            dependencies: { fields: ['comptes'] },
            elements: defaultElements,
            uiValidator: yup.object().shape({
                selectedCompteA: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedCompteB: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedTypeSource: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                previewValue: yup.string(),
                montant: yup.number().min(1)
            }),
            cfPayload: payload
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({}),
        cfPayload: {}
    };
};

export function CreationCrediterBalanceCautionForm() {
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));

    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const { fields, dependencies, elements, uiValidator, cfPayload } =
        setDefaultProperties({ type: typeEntite });
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });

    const renderContent = () =>
        initialState.dependencies ? (
            <TransferBalanceForm
                idToken={idToken}
                typeEntite={typeEntite}
                uiValidator={uiValidator}
                initialState={initialState}
                cfPayload={cfPayload}
            />
        ) : null;
    return renderContent();
}
