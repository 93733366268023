import {
    fetchElement,
    submitPostAction,
    useFormGlobalReducer,
    useGetOrganisationDetail,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    BENEFICIAIRE,
    formInitialState,
    FORM_CONST,
    PAYS
} from '@napp-inc/jnapp-util';
import { MARCHAND } from '@napp-inc/jnapp-util/src/constante/db-node';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { HomologuerPointLivraisonSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    selectedTypeBeneficiaire: {},
    searchInput: '',
    adresse: '',
    referenceLivraison: '',
    selectedPays: {}
};

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['clients'];

/**
 * @description list pour les constantes de différents bénéficiaires
 */

const USERS = [BENEFICIAIRE.MARCHAND, BENEFICIAIRE.USER];

export function CreationHomologuerPointDeLivraisonForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, organisation, nappUser, clients } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            organisation: reduxState.organisation,
            nappUser: reduxState.nappUser,
            clients: reduxState.clientsOrganisation
        })
    );
    const { idToken, creator, uid } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const { codeMarchand } = useGetOrganisationDetail({
        organisation
    });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    /**
     * @description fonction pour fetcher les clients, les produits
     */
    const onSearchClientBeneficiaire = () => {
        const { status: etat } = elements.clients;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_PARTENAIRE,
                elementPropertyName: 'clients',
                etat,
                payload: {
                    typeEntite: fields.selectedTypeBeneficiaire?.code,
                    value: fields.searchInput
                },
                reduxNodeName: REDUX_NODE_NAME.CLIENT_ORGANISATION,
                reduxDispatcher
            })
        );
    };

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = {};
        if (nappUser.typeEntite === MARCHAND) {
            payloadToAdd.referenceAgentLivreur = uid;
        }
        const payload = {
            newAdresse: {
                adresseUne: fields.adresse,
                reference: fields.referenceLivraison,
                pays: fields.selectedPays
            },
            ...payloadToAdd,
            typeEntite: nappUser.typeEntite === MARCHAND ? MARCHAND : 'User', // nappUser.typeEntite, // nappUser.typeEntite === Marchan || User
            reference: nappUser.typeEntite === MARCHAND ? organisation.id : uid,
            codeMarchand,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_POINT_LIVRAISON,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomDropdown
                    options={USERS}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le type client"
                    label="Type Client*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedTypeBeneficiaire"
                    id="creationHomologuerSelectedTypeBeneficiaireId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Till Number ou Code*" className="col-sm-2" />
                <CustomInput
                    isInputGroup={false}
                    divClassName="col-sm-8"
                    placeholder="Saisissez le till Number de l'clients..."
                    formDispatcher={formDispatcher}
                    name="searchInput"
                    id="CreationHomologuerPointLivraisonClientsId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomButtonLoader
                    className="col-sm-2 h-25"
                    type="button"
                    text="rechercher"
                    onClick={onSearchClientBeneficiaire}
                    disabled={
                        elements?.clients?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                    isActionEncours={
                        elements?.clients?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
            </CustomRow>
            <CustomCollapse isIn={!!clients.length}>
                <CustomRow>
                    <CustomDropdown
                        options={clients}
                        idProperty="id"
                        label="Client"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        name="selectedClient"
                        id="creationHomologuerPointDeLivraisonSelectedClientId"
                    />
                </CustomRow>
            </CustomCollapse>
            <CustomRow>
                <CustomInput
                    label="Adresse*"
                    labelClassName="col-sm-2"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    placeholder="Adresse de livraison"
                    id="creationHomologuerPointDeLivraisonAdresseId"
                    name="adresse"
                    isInputGroup={false}
                    divClassName="col-sm-10"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    label="Reference*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isInputGroup={false}
                    placeholder="Référence du point de livraison..."
                    name="referenceLivraison"
                    id="creationHomologuerPointDeLivraisonReferencePointLivraisonId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={PAYS}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le pays"
                    label="Pays*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedPays"
                    id="CreationHomologuerPointLivraisonSelectedPaysId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Suggerer point de livraison"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
