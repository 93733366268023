import React from 'react';
import { BiDetail } from 'react-icons/bi';
import { resolvePathPropertyOfObject } from '@napp-inc/jnapp-util';
import { DetailListItem } from '../list';

export function DetailForm({
    entity,
    schema,
    subtitle,
    isDownloadFile = false,
    isExportable = false,
    downloadFileFunction,
    exportDetailFunction,
    isShowDetail = false,
    detailCallback
}) {
    const isEven = (props) => props % 2;
    return (
        <div className="border rounded pt-3 mb-2 shadow-sm">
            <div className="px-2 d-flex justify-content-between">
                <div>
                    <h3>Détail</h3>
                    <p className="text-muted pb-2 mb-4">{subtitle}</p>
                </div>
                {isShowDetail ? (
                    <div>
                        <BiDetail onClick={detailCallback} className="h3" />
                    </div>
                ) : null}
                {isDownloadFile ? (
                    <div>
                        <button
                            onClick={downloadFileFunction}
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                        >
                            Télécharger fichier
                        </button>
                    </div>
                ) : null}
                {isExportable ? (
                    <div>
                        <button
                            onClick={exportDetailFunction}
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                        >
                            Télécharger fichier
                        </button>
                    </div>
                ) : null}
            </div>
            <div>
                <dl className="row dl">
                    {schema.map((item, index) => {
                        let description = resolvePathPropertyOfObject(
                            item.description,
                            entity
                        );
                        if (
                            item.callback &&
                            typeof item.callback === 'function'
                        ) {
                            description = item.callback(entity);
                        }
                        return (
                            <DetailListItem
                                className={
                                    isEven(index)
                                        ? `${item.className}`
                                        : `bg-gray-50 ${item.className}`
                                }
                                key={item.title}
                                title={item.title}
                                field={item.description}
                                dateFormat={item.dateFormat}
                                isEtat={item.isEtat}
                                isBoolean={item.isBoolean}
                                isDate={item.isDate}
                                concat={item.concat}
                                description={description}
                            />
                        );
                    })}
                </dl>
            </div>
        </div>
    );
}
