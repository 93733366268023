import { GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner les superDealers emoney
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function CashCollecteurSelector({
    formDispatcher,
    formState,
    name = 'selectedCashCollecteur',
    idProperty = 'id',
    isAddAditionalOptions = false,
    additionnalOptions = [{ id: 'all', code: 'all', designation: 'Tous' }]
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, allCashCollecteurKinDB, etatAllCashCollecteurKinDB } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            allCashCollecteurKinDB: reduxState.allCashCollecteurKinDB,
            etatAllCashCollecteurKinDB:
                reduxState.etat.allCashCollecteurKinDB.etat
        }));

    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_CASH_COLLECTEUR_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_CASH_COLLECTEUR_KIN_DB,
                etat: etatAllCashCollecteurKinDB
            }
        ]
    });

    const renderContent = () => {
        if (
            etatAllCashCollecteurKinDB ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <CustomRow>
                    <CustomDropdown
                        options={allCashCollecteurKinDB}
                        defaultOption="Selectionner un cash collecteur"
                        label="Cash collecteur: "
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name={name}
                        id="selectedCashCollecteurCashCollectorSelector"
                        formState={formState}
                        idProperty={idProperty}
                        keyProperty="id"
                        additionnalOptions={
                            isAddAditionalOptions ? additionnalOptions : []
                        }
                    />
                </CustomRow>
            );
        }
        if (
            etatAllCashCollecteurKinDB ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des cash collecteurs" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
