import { DB_NODE, GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';

/**
 * Construit une dropdown où l'on peut sélectionner les caisses
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @param {String} param0.label Label du dropdown, par défaut "Caisse : "
 * @param {String} param0.propertyName propriété où la valeur de la caisse selectionnée est enregistrée dans le form state, par defaut "selectedCaisse"
 * @param {String} param0.labelClassName className du label
 * @param {String} param0.divClassName className du div
 * @param {String} param0.propertyToDisplay nom de la propriété à afficher dans le dropdown, par défaut : "designation"
 * @param {String} param0.idProperty propriété id à utiliser, par défaut : "id"
 * @returns {React.Component}
 */
export function ReportCaisseSelector({
    formDispatcher,
    formState,
    label = 'Caisse : ',
    propertyName = 'selectedCaisse',
    labelClassName = '',
    divClassName = 'col-12',
    propertyToDisplay = 'designation',
    idProperty = 'id',
    payload = { estOrganisation: true },
    nodeName = DB_NODE.CAISSE,
    functionName = URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
    reduxNodeName = 'caisses',
    defaultOption = 'Choisir Caisse'
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, caisses, etatCaisse } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        caisses: reduxState[reduxNodeName].map((item) => ({
            ...item,
            designation: `${item?.designation || ''}-${item?.type || ''}-${
                item?.devise || ''
            }`
        })),
        etatCaisse: reduxState.etat[reduxNodeName].etat
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName,
                nodeName,
                etat: etatCaisse,
                payload
            }
        ]
    });
    const renderContent = () => {
        if (etatCaisse === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS) {
            return (
                <CustomDropdown
                    defaultOption={defaultOption}
                    options={caisses}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    label={label}
                    labelClassName={labelClassName}
                    divClassName={divClassName}
                    name={propertyName}
                    id={`${propertyName}ReportCaisseSelector`}
                    propertyToDisplay={propertyToDisplay}
                    idProperty={idProperty}
                />
            );
        }
        if (etatCaisse === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des caisses" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
