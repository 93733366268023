import { DB_NODE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { ViewLayout } from './view-layout';

export function ListDerogation() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                reduxPropertyName="derogations"
                functionName={URL_CONST.GET_LIST_DEROGATION_INITIALS}
                nodeName={DB_NODE.DEROGATION}
                mapper={[
                    {
                        field: 'revendeur.designation',
                        displayName: 'Désignation'
                    },
                    // {
                    //     field: 'secteur.designation',
                    //     displayName: 'Zones'
                    // },
                    {
                        field: 'etat',
                        displayName: 'etat'
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création'
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
