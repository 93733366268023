import {
    fetchElement,
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { FORM_CONST, formInitialState } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomAlert,
    CustomButtonLoader,
    CustomCollapse,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomLabel,
    CustomRadioButton,
    CustomRow,
    CustomSwitchButton,
    SimpleTable
} from '../../../components';
import {
    CreationGroupeAbonneSchema as uiValidator,
    URL_CONST
} from '../../../util';

/**
 * @description defaultFields for our state
 */
const defaultFields = {
    selectedRemise: {},
    tauxInteret: 0,
    penaliteJournaliere: 0,
    configuration: 'Global',
    acompte: 20,
    isExclure: false,
    searchQuery: '',
    nombreJourEcheance: 0,
    montantEcheance: 0,
    montantLimite: 0,
    listEcheance: [],
    excludedList: [],
    includedList: [],
    clientFinded: []
};

/**
 * @description defaultElements for our state
 */
const defaultElements = ['clients'];

export function CreationAchatCreditFormV2() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    const searchClientByTillNumber = () => {
        // function pour rechercher un client par son till number
        const { status: etat } = elements.clients;
        if (fields.searchInput) {
            formDispatcher(
                fetchElement({
                    idToken,
                    // functionName: URL_CONST.GET_LIST_MARCHAND,
                    elementPropertyName: 'clients',
                    etat,
                    payload: {
                        tillNumber: fields.searchInput
                    },
                    // reduxNodeName: DB_NODE.MARCHAND,
                    reduxDispatcher
                })
            );
        }
    };

    const addClientFinded = () => {
        if (!fields.addClientFinded) return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'clientFinded',
                identifier: 'id',
                data: {
                    id: fields.referenceAbonnement, // to complete with the right reference
                    referenceAbonnement: fields.referenceAbonnement, // to complete with the right reference
                    estAutorise: false
                }
            }
        });
    };
    const isPourcentageTotalReached = () => {
        const { listEcheance } = fields;
        const totalEcheanceGlobal = listEcheance.reduce(
            (acc, curr) => acc + curr.pourcentage,
            0
        );
        return totalEcheanceGlobal;
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const totalGlobal = isPourcentageTotalReached();
        if (totalGlobal !== 100) return;
        let payload = {
            idRemise: fields.selectedRemise.id,
            creator
        };
        const list = fields.listEcheance.map(
            ({ nombreJourApres, pourcentage, pourcentagePenalite }) => ({
                nombreJourApres,
                pourcentage,
                pourcentagePenalite
            })
        );
        if (fields.listEcheance.length && fields.configuration === 'Global') {
            payload = {
                ...payload,
                newAchatCredit: {
                    tauxInteret: fields.tauxInteret,
                    limit: fields.montantLimiteGlobal,
                    pourcentageAcompte: fields.pourcentageAcompteGlobal,
                    echeances: list
                }
            };
            if (fields.excludedList.length > 0) {
                const excludedList = fields.excludedList.map(
                    ({ referenceAbonnement, estAutorise }) => ({
                        referenceAbonnement,
                        estAutorise
                    })
                );
                payload = {
                    ...payload,
                    list: excludedList
                };
            }
        }
        if (fields.listEcheance.length && fields.configuration === 'Client') {
            payload = {
                ...payload,
                list: fields.excludedList
            };
        }
        if (fields.neverSubmit) {
            formDispatcher(
                submitPostAction({
                    idToken,
                    functionName: URL_CONST.POST_PARAMETRE_ACHAT_CREDIT,
                    payload,
                    uiValidator,
                    reduxDispatcher,
                    fields
                    // reduxNodeName: DB_NODE.PRODUIT,
                    // reduxValidator: GroupeAbonneSchema
                })
            );
        }
    };

    /**
     * @description function pour ajouter les échéances globals
     */

    const addGlobalEcheances = () => {
        if (!fields.nombreJourEcheance || !fields.montantEcheance) return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listEcheance',
                identifier: 'id',
                data: {
                    id: Date.now(),
                    nombreJourApres: fields.nombreJourEcheance,
                    pourcentage: fields.montantEcheance,
                    pourcentagePenalite: fields.penaliteJournaliere
                }
            }
        });
    };

    return (
        <>
            <CustomAlert error={form.error} />
            <CustomForm onSubmit={handleSubmit} className="py-2">
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { value: 'remise 1', id: 'remise1' },
                            { value: 'remise 2', id: 'remise2' },
                            { value: 'remise 3', id: 'remise3' }
                        ]}
                        idProperty="id"
                        label="Remise*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Sélectionner une politique de remise"
                        formDispatcher={formDispatcher}
                        name="selectedRemise"
                        propertyToDisplay="value"
                        id="selectedRemiseCreationPolitiqueAchatCredit"
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        type="number"
                        isFloat
                        isInputGroup={false}
                        label="Taux d'intérêt (%)"
                        labelClassName="col-12 col-md-2"
                        divClassName="col-12 col-md-10"
                        min={0}
                        placeholder="Saisissez le % du taux d'intérêt"
                        formDispatcher={formDispatcher}
                        name="tauxInteret"
                        id="creationPolitiqueAchatCreditTauxInteretInput"
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomLabel
                        text="Type de configuration*"
                        className="col-12 col-md-2"
                    />
                    <CustomRadioButton
                        divClassName="col-6 col-sm-3 ms-3"
                        label="Global"
                        id="montantGlobalAcompteRadioCreationPolitiqueAchatCredit"
                        name="configuration"
                        value="Global"
                        formDispatcher={formDispatcher}
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                    <CustomRadioButton
                        divClassName="col-6 col-sm-3"
                        label="Client"
                        id="montantParClientAcompteRadioCreationPolitiqueAchatCredit"
                        name="configuration"
                        value="Client"
                        formDispatcher={formDispatcher}
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
                <CustomCollapse isIn={fields.configuration === 'Client'}>
                    <CustomRow>
                        <CustomRow>
                            <CustomInput
                                isInputGroup={false}
                                label="Till Number client"
                                labelClassName="col-12 col-sm-2"
                                divClassName="col-12 col-sm-8"
                                placeholder="Saisissez le tillNumber client"
                                formDispatcher={formDispatcher}
                                name="searchQuery"
                                id="creationPolitiqueAchatCreditTillNumberClientIsExclureInput"
                                uiValidator={uiValidator}
                                formState={formState}
                            />
                            <CustomButtonLoader
                                className="btn-success col-sm-2"
                                onClick={searchClientByTillNumber}
                                text="Rechercher"
                                disabled={
                                    elements?.clients?.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                                isActionEncours={
                                    elements?.clients?.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                            />
                        </CustomRow>
                        <CustomCollapse isIn={false}>
                            <CustomRow>
                                <CustomInput
                                    isInputGroup={false}
                                    label="Abonné"
                                    labelClassName="col-12 col-sm-2 offset-sm-2"
                                    divClassName="col-12 col-sm-6"
                                    placeholder={`${
                                        fields.something || 'ID...'
                                    }`}
                                    formDispatcher={formDispatcher}
                                    name="queryResult"
                                    id="creationPolitiqueAchatCreditTillNumberClientIsExclureInput"
                                    uiValidator={uiValidator}
                                    formState={formState}
                                    disabled
                                />
                                <CustomAddRemoveButton
                                    className="btn-success col-sm-2"
                                    onClick={addClientFinded}
                                />
                            </CustomRow>
                            <CustomCollapse isIn={false}>
                                <SimpleTable
                                    listSelectedItemName="includedList"
                                    data={[]}
                                    identifier="id"
                                    tableClass="offset-sm-2 col-sm-9 table table-hover shadow table-bordered table-responsive-sm"
                                    filter={false}
                                    bottomPagination={false}
                                    isLinkToDetail={false}
                                    mapper={[
                                        {
                                            position: 1,
                                            field: 'name',
                                            displayName: 'Name'
                                        }
                                    ]}
                                />
                            </CustomCollapse>
                        </CustomCollapse>
                    </CustomRow>
                </CustomCollapse>
                <CustomRow>
                    <CustomInput
                        type="number"
                        isFloat
                        isInputGroup={false}
                        label="Acompte (%)"
                        labelClassName="col-12 col-sm-2"
                        divClassName={`col-12 ${
                            fields.configuration === 'Global'
                                ? 'col-sm-7'
                                : 'col-sm-10'
                        }`}
                        min={20}
                        placeholder="Saisissez le % de l'acompte"
                        formDispatcher={formDispatcher}
                        name="acompte"
                        id="creationPolitiqueAchatCreditAcompteInput"
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                    {fields.configuration === 'Global' ? (
                        <CustomSwitchButton
                            inputTitle="Exclure certains abonnées de cette politique d'achat à crédit"
                            id="creationAchatCreditExclureId"
                            name="isExclure"
                            text="Exclure abonnés?"
                            value={fields.isExclure}
                            formState={formState}
                            formDispatcher={formDispatcher}
                            divClassName="col-sm-3 pt-2"
                        />
                    ) : null}
                </CustomRow>
                <CustomCollapse
                    isIn={fields.configuration === 'Global' && fields.isExclure}
                >
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Till Number client"
                            labelClassName="col-12 col-sm-2 offset-sm-2"
                            divClassName="col-12 col-sm-5"
                            placeholder="Saisissez le tillNumber client"
                            formDispatcher={formDispatcher}
                            name="searchQuery"
                            id="creationPolitiqueAchatCreditTillNumberClientIsExclureInput"
                            uiValidator={uiValidator}
                            formState={formState}
                        />
                        <CustomButtonLoader
                            className="btn-success col-sm-2"
                            onClick={searchClientByTillNumber}
                            text="Rechercher"
                            disabled={
                                elements?.clients?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            isActionEncours={
                                elements?.clients?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                        />
                    </CustomRow>
                    <CustomCollapse isIn>
                        <CustomRow>
                            <CustomInput
                                isInputGroup={false}
                                label="Abonné"
                                labelClassName="col-12 col-sm-2 offset-sm-2"
                                divClassName="col-12 col-sm-5"
                                placeholder={`${fields.something || 'ID...'}`}
                                formDispatcher={formDispatcher}
                                name="queryResult"
                                id="creationPolitiqueAchatCreditTillNumberClientIsExclureInput"
                                uiValidator={uiValidator}
                                formState={formState}
                                disabled
                            />
                            <CustomAddRemoveButton
                                className="btn-success col-sm-2"
                                onClick={addClientFinded}
                            />
                        </CustomRow>
                        <CustomCollapse isIn={false}>
                            <SimpleTable
                                listSelectedItemName="excludedList"
                                data={[]}
                                identifier="id"
                                tableClass="offset-sm-2 col-sm-9 table table-hover shadow table-bordered table-responsive-sm"
                                filter={false}
                                bottomPagination={false}
                                isLinkToDetail={false}
                                mapper={[
                                    {
                                        position: 1,
                                        field: 'name',
                                        displayName: 'Name'
                                    }
                                ]}
                            />
                        </CustomCollapse>
                    </CustomCollapse>
                </CustomCollapse>
                <CustomRow>
                    <CustomInput
                        type="number"
                        isFloat
                        isInputGroup={false}
                        label="Penalité journalière (%)"
                        labelClassName="col-12 col-md-2"
                        divClassName="col-12 col-md-10"
                        min={0}
                        placeholder="Saisissez le % de la penalité journalière"
                        formDispatcher={formDispatcher}
                        name="penaliteJournaliere"
                        id="creationPolitiqueAchatCreditPenaliteJournaliereInput"
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomRow>
                        <CustomLabel text="Echéances" className="col-sm-2" />
                        <CustomInput
                            type="number"
                            isFloat
                            isInputGroup={false}
                            min={1}
                            divClassName="col-sm-4"
                            placeholder="Durée échéance (x jours)..."
                            formDispatcher={formDispatcher}
                            name="nombreJourEcheance"
                            id="nombreEcheanceGlobalCreationPolitiqueAchatCreditInput"
                            uiValidator={uiValidator}
                            formState={formState}
                        />
                        <CustomInput
                            type="number"
                            isFloat
                            isInputGroup={false}
                            min={1}
                            divClassName="col-sm-4"
                            placeholder="Montant à verser"
                            formDispatcher={formDispatcher}
                            name="montantEcheance"
                            id="monantAVersetEcheanceGlobalCreationPolitiqueAchatCreditV2Input"
                            uiValidator={uiValidator}
                            formState={formState}
                        />
                        <CustomAddRemoveButton
                            className="col-sm-2"
                            callback={addGlobalEcheances}
                        />
                    </CustomRow>
                    <CustomCollapse isIn={!!fields.listEcheance.length}>
                        <SimpleTable
                            formDispatcher={formDispatcher}
                            identifier="id"
                            isShowDropButton
                            listSelectedItemName="listEcheance"
                            data={fields.listEcheance}
                            tableClass="offset-sm-2 col-sm-9 table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    field: 'nombreJourApres',
                                    displayName: 'Echéance (Jours)'
                                },
                                {
                                    position: 2,
                                    field: 'pourcentage',
                                    displayName: 'Pourcentage'
                                },
                                {
                                    position: 3,
                                    field: 'pourcentagePenalite',
                                    displayName: 'Penalité'
                                }
                            ]}
                        />
                    </CustomCollapse>
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        type="number"
                        isFloat
                        isInputGroup={false}
                        min={0}
                        label="Limite crédit"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Saisissez la limite de crédit"
                        formDispatcher={formDispatcher}
                        name="montantLimite"
                        id="creationPolitiqueAchatCreditV2montantLimiteInput"
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
                {fields.configuration === 'Client' ? (
                    <CustomRow>
                        <CustomButtonLoader
                            className="btn-success col-sm-2 ms-2"
                            onClick={searchClientByTillNumber}
                            text="Valider pour ce client"
                        />
                    </CustomRow>
                ) : null}
                <CustomRow>
                    <CustomButtonLoader
                        className="btn-primary col-sm-2 ms-2"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomRow>
            </CustomForm>
        </>
    );
}
