/**
 *
 * @param {Object} param0 : Objet qui représente l'ensemble des paramètres à mettre
 * @param {Object} param0.element : Objet qui représente la caisse selectionner
 * @param {Array} param0.list : Tableau qui représente la liste de toutes les caisses organisation
 * @returns {Object} : { estEmoney: True | False }
 */

export const checkTypeCaisse = ({ element, list }) => {
    if (list && element) {
        const findedCaisse = list?.find((item) => item?.id === element?.id);
        return { estEmoney: !!findedCaisse?.estEmoney };
    }
    return { estEmoney: false };
};
