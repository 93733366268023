import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST
    // TYPE_BUSINESS
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';
import { RegularisationSuperDealerFormSchema as uiValidator } from './validation';

const defaultFields = {
    quantite: 0,
    libelle: '',
    selectedImputation: {},
    selectedNumero: {},
    selectedSuperDealer: {}
};

/**
 * @description dependencies du state du formulaire
 */
// const defaultDependencies = {
//     fields: ['imputations', 'superDealerKinDB']
// };

const buildTypeBusinessArray = (data) => {
    // const result = [];
    // data.forEach((item) => {
    //     item?.typeBusiness
    //         ?.filter((elm) => elm.code !== TYPE_BUSINESS.BUSINESS_SIM)
    //         .forEach((tb) => {
    //             result.push({
    //                 code: `${item?.entreprise?.designation || ''} ${
    //                     tb?.code || ''
    //                 } - ${tb?.numero || ''}`,
    //                 designation: `${item?.entreprise?.designation || ''} ${
    //                     tb?.code || ''
    //                 } - ${tb?.numero || ''}`,
    //                 codeSuperDealer: item?.entreprise?.code,
    //                 numero: tb?.numero
    //             });
    //         });
    // });
    // return result;
    const sdFinal = [];
    data.filter((item) => !item?.estEmoney).forEach((element) => {
        const typeBusiness = element?.typeBusiness;
        if (typeBusiness) {
            typeBusiness.forEach((tb) => {
                const existes = sdFinal.find(
                    (sdf) => sdf?.numero === tb?.numero
                );
                if (!existes)
                    sdFinal.push({
                        code: `${element?.entreprise?.designation || ''} ${
                            tb?.code || ''
                        } - ${tb?.numero || ''}`,
                        designation: `${tb?.numero} ${tb?.code}`,
                        // designation: `${element?.entreprise?.designation} - ${tb?.numero}`,
                        numero: tb?.numero,
                        codeSuperDealer: element?.entreprise?.code
                    });
            });
        }
    });
    return sdFinal;
};

export function CreationRegularisationSuperDealerForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        organisation,
        imputationStockOrganisation,
        etatImputationStockOrganisation,
        nappUser,
        superDealerKinDB,
        etatSuperDealerKinDB,
        allTypeBusiness
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation,
        allTypeBusiness: buildTypeBusinessArray(reduxState.superDealerKinDB),
        imputationStockOrganisation: reduxState.imputationStockOrganisation,
        etatImputationStockOrganisation:
            reduxState.etat.imputationStockOrganisation.etat,
        superDealerKinDB: reduxState.superDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat
    }));
    /**
     * Common form processes
     */
    const { idToken, creator, typeBusinessNotSim, isNapp } = useGetUserDetail({
        firebaseUser,
        organisation,
        nappUser
    });
    const defaultDependencies = {};
    if (isNapp) {
        defaultDependencies.fields = ['imputations', 'superDealerKinDB'];
    } else {
        defaultDependencies.fields = ['imputations'];
    }
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_IMPUTATION_STOCK_ORGANISATION,
                nodeName: REDUX_NODE_NAME.IMPUTATION_STOCK_ORGANISATION,
                etat: etatImputationStockOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                payload: { typeEntite: nappUser?.typeEntite },
                etat: etatSuperDealerKinDB,
                isCondition: isNapp
            }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const { quantite, libelle, selectedImputation, selectedNumero } =
            fields;
        if (isNapp && !fields.selectedSuperDealer.code) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Vérifiez les informations renseignées',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }

        const dataToAdd = {};
        if (!isNapp) {
            const numero = typeBusinessNotSim.find(
                (item) => item?.code === selectedNumero?.code
            );
            dataToAdd.numero = numero?.numero;
        } else {
            const finderNumber = allTypeBusiness.find(
                (item) =>
                    item?.designation === fields.selectedNumero?.designation
            );
            dataToAdd.numero = finderNumber?.numero;
            dataToAdd.codeSuperDealer = fields.selectedSuperDealer?.code;
        }
        const payload = {
            creator,
            quantite,
            codeImputation: selectedImputation?.code,
            libelle,
            ...dataToAdd
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_REGULARISATION_STOCK_SUPER_DEALER,
                payload,
                fields,
                reduxNodeName: DB_NODE.REGULARISATION_STOCK,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'imputations',
                    etat: etatImputationStockOrganisation
                },
                {
                    dependency: 'superDealerKinDB',
                    etat: etatSuperDealerKinDB,
                    isCondition: isNapp
                }
            ]}
        >
            <ConditionalRenderingWrapper isShouldBeRendered={isNapp}>
                <CustomRow>
                    <CustomDropdown
                        options={superDealerKinDB}
                        defaultOption="Selectionner SuperDealer"
                        label="Super Dealer* : "
                        labelClassName="col-2"
                        divClassName="col-10"
                        formDispatcher={formDispatcher}
                        name="selectedSuperDealer"
                        id="selectedSuperDealerCreationApproCompteEmoney"
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomDropdown
                    label="Imputation*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    defaultOption="Choisir une imputation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedImputation"
                    id="creationRegularisationSuperDealerSelectedImputationId"
                    options={imputationStockOrganisation}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    label="Stock*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    defaultOption="Sélectionner un stock"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    name="selectedNumero"
                    id="creationRegularisationSuperDealerSelectedNumeroId"
                    options={
                        isNapp
                            ? allTypeBusiness.filter(
                                  (item) =>
                                      item?.codeSuperDealer ===
                                      fields.selectedSuperDealer?.code
                              )
                            : typeBusinessNotSim.map((item) => ({
                                  code: item.code,
                                  designation: `${item.code}-${item.numero}`
                              }))
                    }
                    disabled={
                        isNapp
                            ? !allTypeBusiness.filter(
                                  (item) =>
                                      item?.codeSuperDealer ===
                                      fields.selectedSuperDealer?.code
                              )?.length
                            : !typeBusinessNotSim.map((item) => ({
                                  code: item.code,
                                  designation: `${item.code}-${item.numero}`
                              }))?.length
                    }
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Libellé*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Saisir libelle"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    name="libelle"
                    id="creationRegularisationSuperDealerLibelleId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Quantité*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Saisir Quantité"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    name="quantite"
                    id="creationRegularisationSuperDealerQuantiteId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
