import {
    CustomCol,
    CustomLabel,
    CustomRow,
    CustomSwitchButton
} from '../../../../components';

export function TextTypeComponent({
    formState,
    formDispatcher,
    id,
    labelObligatoireClassName = 'col-md-2',
    labelLongTextClassName = 'col-md-2',
    divSwitchObligatoireClassName = 'col-md-2',
    divSwitchLongTextClassName = 'col-md-2',
    isRequiredName = 'isRequired',
    isLongTextName = 'isLongText'
}) {
    const { fields } = formState;
    return (
        <>
            <CustomRow>
                <CustomCol className={labelObligatoireClassName}>
                    <CustomLabel
                        htmlFor={`${id}isRequiredCreationconfigSimFlash`}
                    >
                        Obligatoire
                    </CustomLabel>
                </CustomCol>
                <CustomCol className={divSwitchObligatoireClassName}>
                    <CustomSwitchButton
                        id={`${id}isRequiredCreationconfigSimFlash`}
                        name={isRequiredName}
                        value={fields?.[isRequiredName]}
                        formDispatcher={formDispatcher}
                        formState={formState}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className={labelLongTextClassName}>
                    <CustomLabel
                        htmlFor={`${id}isLongTextCreationconfigSimFlash`}
                    >
                        Texte long
                    </CustomLabel>
                </CustomCol>
                <CustomCol className={divSwitchLongTextClassName}>
                    <CustomSwitchButton
                        id={`${id}isLongTextCreationconfigSimFlash`}
                        name={isLongTextName}
                        value={fields?.[isLongTextName]}
                        formDispatcher={formDispatcher}
                        formState={formState}
                    />
                </CustomCol>
            </CustomRow>
        </>
    );
}
