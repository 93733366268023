export const schema = [
    {
        title: 'Application',
        description: 'application.designation',
        className: 'border-bottom'
    },
    {
        title: 'Version',
        description: 'version',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Date Fin',
        description: 'dateObsolete',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Est Actif',
        description: 'estActif',
        isBoolean: true,
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
