export const schema = [
    {
        title: 'Code',
        description: 'code',
        className: 'border-bottom'
    },
    {
        title: 'Désignation',
        description: 'designation',
        className: 'border-bottom'
    },
    {
        title: 'Entité',
        description: 'typeEntite',
        className: 'border-bottom'
    },
    {
        title: 'Système',
        description: 'estSysteme',
        className: 'border-bottom',
        isBoolean: true
    }
];
