import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    ETAT,
    FORM_CONST,
    URL_CONST,
    COUPURE,
    CODE_SERVICE
} from '@napp-inc/jnapp-util';
import {
    CustomAddButton,
    CustomCol,
    CustomConfirmButton,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { DetailPaiementCompteExecutionSchema as uiValidator } from './validation';

/**
 * @description defaultFields
 */
const defaultFields = {
    actionEncours: '',
    numeroReference: '',
    selectedCoupureName: {},
    nombreCoupureName: 0,
    listBilletageName: []
};

export function DetailPaiementCompteExecution() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({
        nodeName: 'paiementCompteExecution',
        defaultFields
    });
    const { fields, form } = formState;
    const isCaisseExecution = entity?.estCaisseExecution;
    const ajouterCoupure = () => {
        const devise = { code: 'USD' };
        const coupure = formState.fields.selectedCoupureName;
        const nombre = formState.fields.nombreCoupureName;
        if (!nombre || !devise || !devise.code || !coupure || !coupure.code)
            return;
        const data = {
            nombre,
            devise,
            coupure,
            id: `${coupure.code}${devise.code}`
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: { field: 'listBilletageName', data }
        });
    };
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;

        const payloadToAdd = {};
        if (!isCaisseExecution) {
            payloadToAdd.numeroReference = fields.numeroReference;
        }
        if (isCaisseExecution) {
            const coupures = fields.listBilletageName.map((item) => ({
                valeur: Number(item.coupure.code),
                nombre: item.nombre
            }));
            payloadToAdd.coupures = coupures;
        }
        const payload = {
            etat,
            creator,
            idMoyenPaiementDate: entity?.id,
            ...payloadToAdd
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_ACCUSE_RECEPTION_PAIEMENT,
                payload,
                reduxNodeName: REDUX_NODE_NAME.PAIEMENT_COMPTE_EXECUTION,
                reduxDispatcher
                // uiValidator
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_NOTIFICATION_ZONE_BY_ID}
            nodeName={REDUX_NODE_NAME.PAIEMENT_COMPTE_EXECUTION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Paiement compte exécution charge"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_CASH,
                    CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                        .ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_NON_CASH
                ]}
            >
                {isCaisseExecution && (
                    <>
                        <CustomRow>
                            <CustomLabel className="col-3">
                                Billetage*
                            </CustomLabel>
                            <CustomCol className="col-4">
                                <CustomDropdown
                                    options={COUPURE.USD || []}
                                    formDispatcher={formDispatcher}
                                    name="selectedCoupureName"
                                    id="selectedCoupureCreationBilletage"
                                    formState={formState}
                                    propertyToDisplay="code"
                                    uiValidator={uiValidator}
                                />
                            </CustomCol>
                            <CustomCol className="col-4">
                                <CustomInput
                                    type="number"
                                    divClassName="col-12 col-sm-10"
                                    isInputGroup={false}
                                    placeholder="Nombre coupure"
                                    formDispatcher={formDispatcher}
                                    name="nombreCoupureName"
                                    id="nombreCoupureCreationBilletage"
                                    formState={formState}
                                    uiValidator={uiValidator}
                                />
                            </CustomCol>
                            <CustomCol className="col-1">
                                <CustomAddButton callback={ajouterCoupure} />
                            </CustomCol>
                        </CustomRow>
                        {formState?.fields?.listBilletageName.length > 0 && (
                            <CustomRow>
                                <CustomCol className="offset-md-2 col-md-10 col-12">
                                    <SimpleTable
                                        formDispatcher={formDispatcher}
                                        listSelectedItemName="listBilletageName"
                                        identifier="id"
                                        data={
                                            formState.fields.listBilletageName
                                        }
                                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                        filter={false}
                                        bottomPagination={false}
                                        isLinkToDetail={false}
                                        mapper={[
                                            {
                                                field: 'devise.code',
                                                displayName: 'devise',
                                                position: 1
                                            },
                                            {
                                                field: 'coupure.code',
                                                displayName: 'coupure',
                                                position: 2
                                            },
                                            {
                                                field: 'nombre',
                                                displayName: 'nombre',
                                                position: 3
                                            }
                                        ]}
                                    />
                                </CustomCol>
                            </CustomRow>
                        )}
                    </>
                )}
                {!isCaisseExecution && (
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Numéro de référence*"
                            labelClassName="col-12 col-md-2"
                            divClassName="col-12 col-md-10"
                            placeholder="Saisissez le numéro de référence"
                            formDispatcher={formDispatcher}
                            name="numeroReference"
                            id="detailPaiementCompteExecutionNumeroReferenceId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                )}
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
