export const schema = [
    {
        description: 'code',
        title: 'Code',
        className: 'border-bottom'
    },
    {
        description: 'designation',
        title: 'Désignation',
        className: 'border-bottom'
    },
    {
        description: 'valeurTypeEntiteDesignation',
        title: 'Type',
        className: 'border-bottom'
    },
    {
        description: 'selectedTypeOrganisationDesignation',
        title: 'Organisation',
        className: 'border-bottom'
    },
    {
        description: 'selectedNiveauOrganisation',
        title: 'Niveau',
        className: 'border-bottom'
    }
];
