import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { commandeMapper } from './mapper';
import { MainReportComponentLarge } from '../../releve/generic';
import {
    CustomDropdown,
    MouvementCardsComponent
} from '../../../../components';
import { URL_CONST } from '../../../../util';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedTypeProprietaire: {}
};

export function RapportTropPercuCommandeFlashForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedTypeProprietaire } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payload = {
            dateDebut,
            dateFin,
            property: 'dateCreation',
            estNonSendeur: true,
            typesProprietaire: [selectedTypeProprietaire?.code || '']
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_TROP_PERCU_COMMANDE_FLASH,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher,
                formater: (data) =>
                    data.map((item) => ({
                        ...item,
                        agent: `${item?.responsable?.prenom || ''} ${
                            item?.responsable?.nom || ''
                        }`
                    }))
            })
        );
    };
    return (
        <>
            <MainReportComponentLarge
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={commandeMapper}
                dataList={elements.mouvements?.value || []}
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                topOfChart={MouvementCardsComponent}
            >
                <CustomDropdown
                    options={[
                        { code: 'Stock', designation: 'Stock' },
                        { code: 'Caisse', designation: 'Caisse' },
                        { code: 'Compte', designation: 'Compte' },
                        { code: 'CashBook', designation: 'CashBook' }
                    ]}
                    defaultOption="Sélectionne un type"
                    label="Type : "
                    labelClassName="col-12"
                    divClassName="col-12"
                    formDispatcher={formDispatcher}
                    name="selectedTypeProprietaire"
                    id="selectedTypeProprietaireRapportTropPercu"
                    formState={formState}
                />
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={fields} /> */}
        </>
    );
}
