export const schema = [
    {
        description: 'marchand.designation',
        title: 'Marchand',
        className: 'border-bottom'
    },
    {
        description: 'typeMarchand.designation',
        title: 'Type marchand',
        className: 'border-bottom'
    },

    {
        description: 'serviceGenerateur.designation',
        title: 'Service générateur',
        className: 'border-bottom'
    },
    {
        description: 'ressource.valeurTypeEntite.designation',
        title: 'Ressource',
        className: 'border-bottom'
    },
    {
        description: 'ressource.taux',
        title: 'Tarif (%)',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Dernière modification',
        description: 'dateDerniereModification',
        className: 'border-bottom'
    }
];
