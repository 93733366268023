/**
 * Retourne une valeur comprise entre un min et un max, par défaut min = 1 et max = 8
 * @param {Number} value valeur à comparer
 * @param {Number} min valeur minimale
 * @param {Number} max valeur maximale
 * @returns
 */
export const getNumberBetweenMinMax = (value, min = 1, max = 8) => {
    if (value > max) {
        return getNumberBetweenMinMax(value - max);
    }
    if (value < min) {
        return getNumberBetweenMinMax(value + max);
    }
    return value;
};
