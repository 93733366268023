import { FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomAddButton,
    CustomCol,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    SimpleTable
} from '../../../../components';

export function CheckTypeComponent({
    formDispatcher,
    formState,
    id,
    optionCheckName = 'optionCheck',
    listOptionsCheckName = 'listOptionsCheck',
    customCheckLabelClassName = 'col-12 col-md-2',
    customCheckDivClassName = 'col-6 col-md-6',
    switchDivClassName = 'col-4 col-md-2',
    isCheckDefaultCheckedName,
    divAddButtonClassName = 'col-1',
    divTableClassName = 'col-12 offset-md-2 col-md-10',
    inputPlaceholder = 'Ajouter une option',
    label = 'Options*',
    switchLabel = 'Par défaut',
    mapper = [
        {
            displayName: 'Option',
            field: 'value',
            position: 1
        }
    ],
    simpleTableIdentifier = 'id',
    uiValidator
}) {
    const { fields } = formState;
    const addOption = () => {
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: listOptionsCheckName,
                data: {
                    id: Date.now(),
                    value: formState?.fields?.[optionCheckName],
                    isDefaultChecked:
                        formState?.fields?.[isCheckDefaultCheckedName]
                }
            }
        });
    };
    return (
        <>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label={label}
                    labelClassName={customCheckLabelClassName}
                    divClassName={customCheckDivClassName}
                    placeholder={inputPlaceholder}
                    formDispatcher={formDispatcher}
                    value={fields?.[optionCheckName]}
                    name={optionCheckName}
                    id={`${id}optionCheckTypeCheckComponent`}
                    uiValidator={uiValidator}
                    formState={formState}
                />
                <CustomCol className={switchDivClassName}>
                    <CustomSwitchButton
                        id={`${id}isCheckDefaultCheckedTypeCheckComponent`}
                        text={switchLabel}
                        name={isCheckDefaultCheckedName}
                        value={fields?.[isCheckDefaultCheckedName]}
                        formDispatcher={formDispatcher}
                        formState={formState}
                    />
                </CustomCol>
                <CustomCol className={divAddButtonClassName}>
                    <CustomAddButton callback={addOption} />
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className={divTableClassName}>
                    <SimpleTable
                        formDispatcher={formDispatcher}
                        listSelectedItemName={listOptionsCheckName}
                        data={formState?.fields?.[listOptionsCheckName]}
                        identifier={simpleTableIdentifier}
                        mapper={mapper}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                    />
                </CustomCol>
            </CustomRow>
        </>
    );
}
