import { SiCivicrm } from 'react-icons/si';
import { GiMoneyStack } from 'react-icons/gi';
import { IoIosFlash } from 'react-icons/io';

/**
 * @desc Client organisation rel paths
 */
export const ClientViewLayoutRelPath = {
    name: 'Client',
    path: 'client-organisation',
    icon: <SiCivicrm className="icon-custom" />
};

export const ClientCreateRelPath = {
    name: '',
    path: 'create'
};

export const ClientDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Commande flash rel paths
 */
export const CommandeFlashViewLayoutRelPath = {
    name: 'Commande flash',
    path: 'commandes-flash',
    icon: <IoIosFlash className="icon-custom" />
};

export const CommandeFlashCreateRelPath = {
    name: '',
    path: 'create'
};

export const CommandeFlashDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Commande flash sender rel paths
 */
export const CommandeFlashSenderViewLayoutRelPath = {
    name: 'Sender flash',
    path: 'sender-flash',
    icon: <IoIosFlash className="icon-custom" />
};

export const CommandeFlashSenderDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Commande Emoney sender rel paths
 */
export const CommandeEmoneySenderViewLayoutRelPath = {
    name: 'Sender Emoney',
    path: 'sender-emoney',
    icon: <IoIosFlash className="icon-custom" />
};

export const CommandeEmoneySenderDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Retrait cash rel paths
 */
export const RetraitCashViewLayoutRelPath = {
    name: 'Retrait cash',
    path: 'retrait-cash',
    icon: <GiMoneyStack className="icon-custom" />
};

export const RetraitCashDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Depot Emoney rel paths
 */
export const DepotEmoneyViewLayoutRelPath = {
    name: 'Depot Emoney',
    path: 'depot-emoney',
    icon: <GiMoneyStack className="icon-custom" />
};

export const DepotEmoneyCreateRelPath = {
    name: '',
    path: 'create',
    icon: <GiMoneyStack className="icon-custom" />
};

export const DepotEmoneyDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Retrait cash rel paths
 */
export const RecouvrementEmoneyViewLayoutRelPath = {
    name: 'Recouvrement',
    path: 'recouvert-emoney',
    icon: <GiMoneyStack className="icon-custom" />
};

export const RecouvrementEmoneyDetailRelPath = {
    name: '',
    path: ':id'
};
