import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { ShowNewComponent } from '../../../components';
import {
    BudgetAdditionnelAmendementChargeFixeCreateRelPath,
    BudgetAdditionnelAmendementChargeVariableCreateRelPath,
    BudgetAdditionnelAmendementRessourceCreateRelPath
} from '../../../routes/registration/configuration/config-napp-rel-path';

export function ViewLayout({ children }) {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Elaboration Budget Additionnel</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <ShowNewComponent
                            services={[
                                { code: 'test' },
                                CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                    .INITIER_ELABORATION_AMENDEMENT
                            ]}
                            destination={
                                BudgetAdditionnelAmendementChargeFixeCreateRelPath.path
                            }
                            text="Charge Fixe"
                        />
                        <span className="m-1" />
                        <ShowNewComponent
                            services={[
                                { code: 'test' },
                                CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                    .INITIER_ELABORATION_AMENDEMENT
                            ]}
                            destination={
                                BudgetAdditionnelAmendementChargeVariableCreateRelPath.path
                            }
                            text="Charge Variable"
                        />
                        <span className="m-1" />
                        <ShowNewComponent
                            services={[
                                { code: 'test' },
                                CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                                    .INITIER_ELABORATION_AMENDEMENT
                            ]}
                            destination={
                                BudgetAdditionnelAmendementRessourceCreateRelPath.path
                            }
                            text="Charge Ressource"
                        />
                    </div>
                </div>
            </div>
            {children}
        </>
    );
}
