import {
    fetchElement,
    submitPostAction,
    useEtatChargementDependencies,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    formInitialState,
    GroupeAbonneSchema,
    CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CenteredSpinner,
    CustomAddRemoveButton,
    CustomAlert,
    CustomButtonLoader,
    CustomCollapse,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomInputDate,
    CustomLabel,
    CustomRadioButton,
    CustomRow,
    CustomSwitchButton,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import {
    CreationGroupeAbonneSchema as uiValidator,
    URL_CONST
} from '../../../util';

const defaultFields = {
    isPourcentageGlobal: true,
    isPourcentageByProduit: false,
    querySearchProduit: '',
    pourcentageGlobal: 0,
    pourcentageProduit: 0,
    listProduit: [],
    cible: CONST.TEMPS_FIDELITE, // tempsFidelite || VisiteRentable || GroupeAbonne
    groupeAbonneSelected: {},
    dateDebut: '',
    dateFin: '',
    volume: 0
};

/**
 * Default dependencies for formState
 */
const defaultDependencies = { fields: ['abonnes'] };

/**
 * @description defaultElements de notre state
 */
const defaultElements = ['produit'];

export function CreationPolitiqueRemiseForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, groupeAbonnes, produit, etatGroupeAbonne } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            groupeAbonnes: reduxState.groupeAbonnes,
            produit: reduxState.produit,
            etatGroupeAbonne: reduxState.etat.groupeAbonnes.etat
        }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, dependencies, elements } = formState;

    /**
     * @description fonction pour fetcher un produit du marchand en fonction du code
     */
    const onSearchProduitMarchand = () => {
        const { status: etat } = elements.produit;
        if (fields.querySearchProduit) {
            formDispatcher(
                fetchElement({
                    idToken,
                    useSet: true,
                    functionName: URL_CONST.GET_PRODUIT_MARCHAND,
                    elementPropertyName: 'produit',
                    etat,
                    payload: {
                        code: fields.querySearchProduit
                    },
                    reduxNodeName: REDUX_NODE_NAME.PRODUIT_MARCHAND,
                    reduxDispatcher
                })
            );
        }
    };

    /**
     * @description récupérer les produits marchand
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_GROUPE_MARCHAND,
                nodeName: DB_NODE.GROUPE,
                etat: etatGroupeAbonne
            }
        ]
    });

    const addProduit = () => {
        if (produit && produit.code) {
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    field: 'listProduit',
                    identifier: 'code',
                    data: {
                        code: produit.code,
                        designation: produit.designation,
                        pourcentage: fields.pourcentageProduit
                    }
                }
            });
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const { listProduit, cible, pourcentageGlobal, dateDebut, dateFin } =
            fields;
        const newDateDebut = new Date(dateDebut);
        const newDateFin = new Date(dateFin);
        let list = [];
        let payload = {
            creator,
            typeEntite: cible,
            pourcentage: pourcentageGlobal,
            periode: {
                dateDebut: newDateDebut.valueOf(),
                dateFin: newDateFin.valueOf()
            }
        };
        if (cible === CONST.GROUPE) {
            payload = {
                ...payload,
                valeurTypeEntite: {
                    code: fields.groupeAbonneSelected.code
                }
            };
        }
        if (cible !== CONST.GROUPE) {
            payload = {
                ...payload,
                valeurTypeEntite: {
                    volume: fields.volume
                }
            };
        }
        if (listProduit.length) {
            list = listProduit.map(({ code, pourcentage }) => ({
                code,
                pourcentage
            }));
            payload = {
                ...payload,
                list
            };
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_REMISE,
                payload,
                reduxDispatcher,
                fields
                // uiValidator,
                // reduxNodeName: DB_NODE.PRODUIT,
                // reduxValidator: GroupeAbonneSchema
            })
        );
    };
    /**
     * This hook loads dependecies
     */
    useEtatChargementDependencies({
        formDispatcher,
        dependencies,
        list: [
            {
                dependency: 'abonnes',
                etat: etatGroupeAbonne
            }
        ]
    });

    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        dependencies
    });
    const renderContent = () => {
        if (!isLoadFailed && !isLoadComplete) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return <CustomAlert error={form?.error} />;
        }
        return (
            <>
                <CustomAlert error={form.error} />
                <CustomForm onSubmit={handleSubmit} className="py-2">
                    <CustomRow>
                        <div className="offset-sm-2 col-sm-5">
                            <CustomSwitchButton
                                inputTitle="Appliquer le même pourcentge de remise pour tous les produits"
                                id="creationRemisePourcentageSwitch"
                                name="isPourcentageGlobal"
                                text="Definir une remise global?"
                                value={fields.isPourcentageGlobal}
                                formState={formState}
                                formDispatcher={formDispatcher}
                            />
                        </div>
                    </CustomRow>
                    <CustomCollapse isIn={fields.isPourcentageGlobal}>
                        <CustomRow>
                            <CustomLabel
                                text="Pourcentage Remise"
                                className="col-sm-2"
                            />
                            <CustomInput
                                type="number"
                                isFloat
                                isInputGroup={false}
                                divClassName="col-sm-5"
                                placeholder="Saisissez la Remise globale (%)"
                                formDispatcher={formDispatcher}
                                name="pourcentageGlobal"
                                id="creationRemiseInputPourcentageGlobal"
                                uiValidator={GroupeAbonneSchema}
                                formState={formState}
                            />
                            <div className="col-sm-5">
                                <CustomSwitchButton
                                    inputTitle="Définir des remises de façon individuelle"
                                    id="creationRemisePourcentageSwitch"
                                    name="isPourcentageByProduit"
                                    text="Definir remise par produit?"
                                    value={fields.isPourcentageByProduit}
                                    formState={formState}
                                    formDispatcher={formDispatcher}
                                />
                            </div>
                        </CustomRow>
                        <CustomCollapse isIn={fields.isPourcentageByProduit}>
                            <CustomRow>
                                <CustomInput
                                    isInputGroup={false}
                                    divClassName="col-sm-8"
                                    label="Code Produit"
                                    labelClassName="col-sm-2"
                                    placeholder="Saisissez le code du produit"
                                    formDispatcher={formDispatcher}
                                    name="querySearchProduit"
                                    id="creationRemiseInputQuerySearchProduit"
                                    uiValidator={GroupeAbonneSchema}
                                    formState={formState}
                                />
                                <CustomButtonLoader
                                    text="Search"
                                    className="col-sm-2 btn-primary"
                                    onClick={onSearchProduitMarchand}
                                    disabled={
                                        elements.produit?.status ===
                                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                    }
                                    isActionEncours={
                                        elements.produit?.status ===
                                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                    }
                                />
                            </CustomRow>
                            <CustomCollapse
                                isIn={Object.keys(produit).length > 0}
                            >
                                <CustomRow>
                                    <CustomLabel
                                        text="Produit"
                                        className="col-sm-2"
                                    />
                                    <CustomInput
                                        disabled
                                        isInputGroup={false}
                                        divClassName="col-sm-4"
                                        placeholder={produit?.code}
                                        formDispatcher={formDispatcher}
                                        name="somethingElse"
                                        id="creationRemiseInputQueryResultProduit"
                                        uiValidator={GroupeAbonneSchema}
                                        formState={formState}
                                    />
                                    <CustomInput
                                        isInputGroup={false}
                                        divClassName="col-sm-4"
                                        placeholder="(%) de remise du produit..."
                                        formDispatcher={formDispatcher}
                                        name="pourcentageProduit"
                                        id="creationRemiseInputPourcentageProduitProduit"
                                        uiValidator={GroupeAbonneSchema}
                                        formState={formState}
                                    />
                                    <CustomAddRemoveButton
                                        className="col-sm-2"
                                        callback={addProduit}
                                    />
                                </CustomRow>
                                <CustomCollapse
                                    isIn={fields.listProduit.length > 0}
                                >
                                    <CustomRow>
                                        <SimpleTable
                                            data={fields.listProduit}
                                            identifier="code"
                                            formDispatcher={formDispatcher}
                                            listSelectedItemName="listProduit"
                                            tableClass="table table-hover shadow table-bordered table-responsive-sm mt-3"
                                            filter={false}
                                            bottomPagination={false}
                                            isLinkToDetail={false}
                                            mapper={[
                                                {
                                                    position: 1,
                                                    displayName: 'Code',
                                                    field: 'code'
                                                },
                                                {
                                                    position: 2,
                                                    displayName: 'Designation',
                                                    field: 'designation'
                                                },
                                                {
                                                    position: 3,
                                                    displayName: '% remise',
                                                    field: 'pourcentage'
                                                }
                                            ]}
                                        />
                                    </CustomRow>
                                </CustomCollapse>
                            </CustomCollapse>
                        </CustomCollapse>
                    </CustomCollapse>
                    <CustomCollapse isIn={!fields.isPourcentageGlobal}>
                        <CustomRow>
                            <CustomInput
                                isInputGroup={false}
                                divClassName="col-sm-8"
                                label="Code Produit"
                                labelClassName="col-sm-2"
                                placeholder="Saisissez le code du produit"
                                formDispatcher={formDispatcher}
                                name="querySearchProduit"
                                id="creationRemiseInputQuerySearchProduit"
                                uiValidator={GroupeAbonneSchema}
                                formState={formState}
                            />
                            <CustomButtonLoader
                                text="Search"
                                className="col-sm-2 btn-primary"
                                onClick={onSearchProduitMarchand}
                                disabled={
                                    elements.produit?.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                                isActionEncours={
                                    elements.produit?.status ===
                                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                                }
                            />
                        </CustomRow>
                        <CustomCollapse isIn={!!Object.keys(produit).length}>
                            <CustomRow>
                                <CustomLabel
                                    text="Produit"
                                    className="col-sm-2"
                                />
                                <CustomInput
                                    disabled
                                    isInputGroup={false}
                                    divClassName="col-sm-4"
                                    placeholder={produit?.code}
                                    formDispatcher={formDispatcher}
                                    name="querySearchProduit"
                                    id="creationRemiseInputQueryResultProduit"
                                    uiValidator={GroupeAbonneSchema}
                                    formState={formState}
                                />
                                <CustomInput
                                    isInputGroup={false}
                                    divClassName="col-sm-4"
                                    placeholder="(%) de remise du produit..."
                                    formDispatcher={formDispatcher}
                                    name="pourcentageProduit"
                                    id="creationRemiseInputPourcentageProduitProduit"
                                    uiValidator={GroupeAbonneSchema}
                                    formState={formState}
                                />
                                <CustomAddRemoveButton
                                    className="col-sm-2"
                                    callback={addProduit}
                                />
                            </CustomRow>
                            <CustomRow>
                                <SimpleTable
                                    data={fields.listProduit}
                                    identifier="code"
                                    formDispatcher={formDispatcher}
                                    listSelectedItemName="listProduit"
                                    tableClass="table table-hover shadow table-bordered table-responsive-sm mt-3"
                                    filter={false}
                                    bottomPagination={false}
                                    isLinkToDetail={false}
                                    mapper={[
                                        {
                                            position: 1,
                                            displayName: 'Code',
                                            field: 'code'
                                        },
                                        {
                                            position: 2,
                                            displayName: 'Designation',
                                            field: 'designation'
                                        },
                                        {
                                            position: 3,
                                            displayName: '% remise',
                                            field: 'pourcentage'
                                        }
                                    ]}
                                />
                            </CustomRow>
                        </CustomCollapse>
                    </CustomCollapse>
                    <CustomRow>
                        <CustomLabel text="Critère" className="col-sm-2" />
                        <CustomRadioButton
                            divClassName="col-sm-2"
                            label="Temps de fidelité"
                            id="creationPolitiqueRemiseRadioCibleFidelite"
                            name="cible"
                            value={CONST.TEMPS_FIDELITE}
                            formDispatcher={formDispatcher}
                            uiValidator={uiValidator}
                            formState={formState}
                        />
                        <CustomRadioButton
                            divClassName="col-sm-2"
                            label="Visite rentable"
                            id="creationPolitiqueRemiseRadioCibleRentable"
                            name="cible"
                            value={CONST.VISITE_RENTABLE}
                            formDispatcher={formDispatcher}
                            uiValidator={uiValidator}
                            formState={formState}
                        />
                        <CustomRadioButton
                            divClassName="col-sm-2"
                            label="Groupe abonné"
                            id="creationPolitiqueRemiseRadioCibleAbonne"
                            name="cible"
                            value={CONST.GROUPE}
                            formDispatcher={formDispatcher}
                            uiValidator={uiValidator}
                            formState={formState}
                        />
                        <CustomRadioButton
                            divClassName="col-sm-2"
                            label="Volume produit"
                            id="creationPolitiqueRemiseRadioCibleVolumeProduit"
                            name="cible"
                            value={CONST.VOLUME_PRODUIT}
                            formDispatcher={formDispatcher}
                            uiValidator={uiValidator}
                            formState={formState}
                        />
                        <CustomRadioButton
                            divClassName="col-sm-2"
                            label="Total facture"
                            id="creationPolitiqueRemiseRadioCibleTotalFacture"
                            name="cible"
                            value={CONST.VOLUME_COMMANDE}
                            formDispatcher={formDispatcher}
                            uiValidator={uiValidator}
                            formState={formState}
                        />
                    </CustomRow>
                    <CustomRow>
                        {fields.cible !== CONST.GROUPE ? (
                            <CustomInput
                                isFloat
                                label="Volume"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                id="creationRemiseInputTempsFidelite"
                                name="volume"
                                type="number"
                                isInputGroup={false}
                                placeholder="Volume"
                                formDispatcher={formDispatcher}
                                uiValidator={GroupeAbonneSchema}
                                formState={formState}
                            />
                        ) : (
                            <CustomDropdown
                                label="Groupe"
                                labelClassName="col-sm-2"
                                divClassName="col-sm-10"
                                options={groupeAbonnes}
                                propertyToDisplay="designation"
                                idProperty="code"
                                defaultOption="Sélectionner un groupe d'abonné"
                                formDispatcher={formDispatcher}
                                name="groupeAbonneSelected"
                                id="creationRemiseSelectGroupeAbonne"
                                uiValidator={GroupeAbonneSchema}
                                formState={formState}
                            />
                        )}
                    </CustomRow>
                    <CustomRow>
                        <CustomLabel text="Délimitation" className="col-sm-2" />
                        <CustomInputDate
                            id="creationRemiseInputDateDebut"
                            name="dateDebut"
                            type="date"
                            label="debut"
                            isInputGroup={false}
                            labelClassName="col-sm-1"
                            divClassName="col-sm-3"
                            placeholder="Date début"
                            formDispatcher={formDispatcher}
                            uiValidator={GroupeAbonneSchema}
                            formState={formState}
                        />
                        <CustomInputDate
                            id="creationRemiseInputDateFin"
                            name="dateFin"
                            type="date"
                            label="fin"
                            isInputGroup={false}
                            labelClassName="offset-sm-1 col-sm-1"
                            divClassName="col-sm-3"
                            placeholder="Date fin"
                            formDispatcher={formDispatcher}
                            uiValidator={GroupeAbonneSchema}
                            formState={formState}
                        />
                    </CustomRow>
                    <div className="row">
                        <div className="col-3">
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={handleSubmit}
                                text="Sauvegarder"
                                disabled={
                                    form.status === FORM_CONST.CREATION_EN_COURS
                                }
                            />
                        </div>
                    </div>
                </CustomForm>
            </>
        );
    };
    return renderContent();
}
