import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    ADMINISTRATION_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST, useDebounce } from '../../../../util';

export function TransferBalanceForm({
    idToken,
    typeEntite,
    uiValidator,
    initialState,
    cfPayload
}) {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        balances,
        cautions,
        etatComptes,
        superDealerKinDB,
        etatSuperDealerKinDB
    } = useSelector((reduxState) => ({
        balances: reduxState.balancesAndCautionsOrganisation
            ?.filter((item) => item?.typeEntite === TYPE_ORGANISATION.NAPP)
            ?.map((item) => ({
                ...item,
                code: item?.valeurTypeProprietaire?.code,
                designation: `Balance | ${item?.valeurTypeProprietaire?.designation} ${item?.devise}`
            })),
        cautions: reduxState.balancesAndCautionsOrganisation
            ?.filter(
                (item) => item?.typeEntite === ADMINISTRATION_CONST.CAUTION.code
            )
            ?.map((item) => ({
                ...item,
                code: item?.valeurTypeProprietaire?.code,
                designation: `Caution | ${item?.valeurTypeProprietaire?.designation} ${item?.devise}`
            })),
        etatComptes: reduxState.etat.balancesAndCautionsOrganisation.etat,
        superDealerKinDB: reduxState.allSuperDealerKinDB.map((item) => ({
            ...item,
            code: item?.entreprise?.code,
            designation: item?.entreprise?.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.allSuperDealerKinDB.etat
    }));
    /**
     * Common form processes
     */
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    /**
     * Debounce
     */
    useDebounce({
        name: 'selectedSuperDealer',
        preview: 'previewValue',
        propertyInName: 'code',
        isObject: true,
        formDispatcher,
        formState,
        delay: 1500,
        idToken,
        functionName: URL_CONST.GET_LIST_COMPTE_USER,
        elementPropertyName: 'comptes',
        payloadToAdd: {
            estOrganisation: true,
            typeProprietaire: 'SuperDealer',
            type: 'CautionBalance',
            codeValeurTypeProprietaire: fields?.selectedSuperDealer?.code,
            typeOrganisation: typeEntite
        },
        dataLength: 0,
        condition:
            typeEntite === TYPE_ORGANISATION.NAPP ||
            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
    });

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_COMPTE_USER,
                nodeName: REDUX_NODE_NAME.BALANCE_AND_CAUTION_ORGANISATION,
                payload: cfPayload.compteUser,
                etat: etatComptes,
                isCondition:
                    typeEntite !== TYPE_ORGANISATION.NAPP &&
                    typeEntite !== TYPE_ORGANISATION.DISTRIBUTEUR
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: cfPayload.superDealer,
                isCondition:
                    typeEntite === TYPE_ORGANISATION.NAPP ||
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
            }
        ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedCompteA, selectedCompteB, montant } = fields;
        const payloadToAdd = {};
        if (fields?.selectedSuperDealer?.code) {
            payloadToAdd.codeSuperDealer = fields?.selectedSuperDealer?.code;
        }
        const payload = {
            newTransfertBalance: {
                compteA: {
                    reference: selectedCompteA.id,
                    type:
                        fields.selectedTypeSource.code ===
                        ADMINISTRATION_CONST.CAUTION.code
                            ? ADMINISTRATION_CONST.CAUTION.code
                            : TYPE_ORGANISATION.NAPP
                },
                compteB: {
                    reference: selectedCompteB.id,
                    type:
                        fields.selectedTypeSource.code ===
                        ADMINISTRATION_CONST.CAUTION.code
                            ? TYPE_ORGANISATION.NAPP
                            : ADMINISTRATION_CONST.CAUTION.code
                },
                montant
            },
            ...payloadToAdd
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_TRANSFERT_BALANCE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TRANSFERT_BALANCE_CAUTION,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const ternaryCondition = ({ condition, then, otherwise }) =>
        condition ? then : otherwise;
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'comptes',
                    etat: etatComptes,
                    isCondition:
                        typeEntite !== TYPE_ORGANISATION.NAPP &&
                        typeEntite !== TYPE_ORGANISATION.DISTRIBUTEUR
                },
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB,
                    isCondition:
                        typeEntite === TYPE_ORGANISATION.NAPP ||
                        typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                }
            ]}
        >
            {/* <StateShowerComponent state={formState.elements} /> */}
            <CustomRow
                isShouldBeRendered={
                    typeEntite === TYPE_ORGANISATION.NAPP ||
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                }
            >
                <CustomDropdown
                    options={superDealerKinDB}
                    defaultOption="Selectionner SuperDealer"
                    label="Super Dealer: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedSuperDealer"
                    id="selectedSuperDealerCreactionTransfertCautionBalanceId"
                    uiValidator={uiValidator}
                    formState={formState}
                    propertyToDisplay="designation"
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        ADMINISTRATION_CONST.CAUTION,
                        ADMINISTRATION_CONST.BALANCE
                    ]}
                    defaultOption={`${fields.selectedTypeSource.code}`}
                    label="Type Source *:"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeSource"
                    id="selectedSuperDealerCreactionTransfertCautionBalanceId"
                    uiValidator={uiValidator}
                    formState={formState}
                    resetOnChangeState
                    newState={{
                        ...fields,
                        selectedCompteA: {},
                        selectedCompteB: {}
                    }}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    typeEntite === TYPE_ORGANISATION.NAPP ||
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                }
            >
                {elements?.comptes?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                    <div className="placeholder-glow my-3">
                        <span className="placeholder col-12" />
                    </div>
                ) : null}
            </ConditionalRenderingWrapper>
            <CustomRow
                isShouldBeRendered={
                    elements?.comptes?.status ===
                        FORM_CONST.FETCH_ELEMENT_SUCCESS ||
                    typeEntite !== TYPE_ORGANISATION.NAPP ||
                    typeEntite !== TYPE_ORGANISATION.DISTRIBUTEUR
                }
            >
                <CustomDropdown
                    idProperty="id"
                    options={ternaryCondition({
                        condition:
                            fields.selectedTypeSource.code ===
                            ADMINISTRATION_CONST.CAUTION.code,
                        then:
                            typeEntite === TYPE_ORGANISATION.NAPP ||
                            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                                ? elements?.comptes?.value
                                      ?.filter(
                                          (item) =>
                                              item?.typeEntite ===
                                              ADMINISTRATION_CONST.CAUTION.code
                                      )
                                      ?.map((item) => ({
                                          ...item,
                                          code: item?.valeurTypeProprietaire
                                              ?.code,
                                          designation: `Caution | ${item?.valeurTypeProprietaire?.designation} ${item?.devise}`
                                      })) || []
                                : cautions,
                        otherwise:
                            typeEntite === TYPE_ORGANISATION.NAPP ||
                            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                                ? elements?.comptes?.value
                                      ?.filter(
                                          (item) =>
                                              item?.typeEntite ===
                                              TYPE_ORGANISATION.NAPP
                                      )
                                      ?.map((item) => ({
                                          ...item,
                                          code: item?.valeurTypeProprietaire
                                              ?.code,
                                          designation: `Balance | ${item?.valeurTypeProprietaire?.designation} ${item?.devise}`
                                      })) || []
                                : balances
                    })}
                    disabled={ternaryCondition({
                        condition:
                            fields.selectedTypeSource.code ===
                            ADMINISTRATION_CONST.CAUTION.code,
                        then:
                            typeEntite === TYPE_ORGANISATION.NAPP ||
                            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                                ? !elements?.comptes?.value?.filter(
                                      (item) =>
                                          item?.typeEntite ===
                                          ADMINISTRATION_CONST.CAUTION.code
                                  )?.length
                                : !cautions?.length,
                        otherwise:
                            typeEntite === TYPE_ORGANISATION.NAPP ||
                            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                                ? !elements?.comptes?.value?.filter(
                                      (item) =>
                                          item?.typeEntite ===
                                          TYPE_ORGANISATION.NAPP
                                  )?.length
                                : !balances?.length
                    })}
                    defaultOption="Selectionner Source"
                    label="Source* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCompteA"
                    id="sselectedCompteACreactionTransfertCautionBalanceId"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    elements?.comptes?.status ===
                        FORM_CONST.FETCH_ELEMENT_SUCCESS ||
                    typeEntite !== TYPE_ORGANISATION.NAPP ||
                    typeEntite !== TYPE_ORGANISATION.DISTRIBUTEUR
                }
            >
                <CustomDropdown
                    idProperty="id"
                    options={ternaryCondition({
                        condition:
                            fields.selectedTypeSource.code ===
                            ADMINISTRATION_CONST.CAUTION.code,
                        then:
                            typeEntite === TYPE_ORGANISATION.NAPP ||
                            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                                ? elements?.comptes?.value
                                      ?.filter(
                                          (item) =>
                                              item?.typeEntite ===
                                              TYPE_ORGANISATION.NAPP
                                      )
                                      ?.map((item) => ({
                                          ...item,
                                          code: item?.valeurTypeProprietaire
                                              ?.code,
                                          designation: `Balance | ${item?.valeurTypeProprietaire?.designation} ${item?.devise}`
                                      })) || []
                                : balances,
                        otherwise:
                            typeEntite === TYPE_ORGANISATION.NAPP ||
                            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                                ? elements?.comptes?.value
                                      ?.filter(
                                          (item) =>
                                              item?.typeEntite ===
                                              ADMINISTRATION_CONST.CAUTION.code
                                      )
                                      ?.map((item) => ({
                                          ...item,
                                          code: item?.valeurTypeProprietaire
                                              ?.code,
                                          designation: `Caution | ${item?.valeurTypeProprietaire?.designation} ${item?.devise}`
                                      })) || []
                                : cautions
                    })}
                    disabled={ternaryCondition({
                        condition:
                            fields.selectedTypeSource.code ===
                            ADMINISTRATION_CONST.CAUTION.code,
                        then:
                            typeEntite === TYPE_ORGANISATION.NAPP ||
                            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                                ? !elements?.comptes?.value?.filter(
                                      (item) =>
                                          item?.typeEntite ===
                                          TYPE_ORGANISATION.NAPP
                                  )?.length
                                : !balances?.length,
                        otherwise:
                            typeEntite === TYPE_ORGANISATION.NAPP ||
                            typeEntite !== TYPE_ORGANISATION.DISTRIBUTEUR
                                ? !elements?.comptes?.value?.filter(
                                      (item) =>
                                          item?.typeEntite ===
                                          ADMINISTRATION_CONST.CAUTION.code
                                  )?.length
                                : !cautions?.length
                    })}
                    defaultOption="Selectionner Destination"
                    label="Destination*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCompteB"
                    id="sselectedCompteBCreationApproCompteEmoney"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="creactionTransfertCautionBalanceMontantId"
                    formState={formState}
                    uiValidator={uiValidator}
                    min={0}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
