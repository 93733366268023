export const schema = [
    {
        title: 'Prenom',
        description: 'personne.prenom',
        className: 'border-bottom'
    },
    {
        title: 'Nom',
        description: 'personne.nom',
        className: 'border-bottom'
    },
    {
        title: 'Email',
        description: 'email',
        className: 'border-bottom'
    },
    {
        title: 'Profil',
        description: 'profilUser.designation',
        className: 'border-bottom'
    },
    {
        title: 'Till number',
        description: 'tillNumber',
        className: 'border-bottom'
    }
];
