import { DB_NODE, GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { crud, set, reduxNodesBuilder } from '@napp-inc/jnapp-redux';
import * as REDUX_NODE_NAME from './redux-node-name';

const parentsNode = {
    commandes: 'commandes'
};

const listNoeud = [
    {
        name: 'firebaseUser',
        reducer: set,
        nodeName: REDUX_NODE_NAME.FIREBASE_USER,
        isEtat: true
    },
    {
        name: 'nappUser',
        reducer: set,
        nodeName: REDUX_NODE_NAME.NAPP_USER,
        isEtat: true
    },
    {
        name: 'organisation',
        reducer: set,
        nodeName: REDUX_NODE_NAME.ORAGANISATION,
        isEtat: true
    },
    {
        name: 'users',
        reducer: crud,
        nodeName: DB_NODE.USER,
        isEtat: true
    },
    {
        name: 'dynamicNode',
        reducer: set,
        nodeName: REDUX_NODE_NAME.DYNAMIC_NODE
    },
    {
        name: 'dynamicEtatNode',
        reducer: set,
        nodeName: REDUX_NODE_NAME.DYNAMIC_ETAT_NODE
    },
    {
        name: 'nappUser',
        reducer: set,
        nodeName: REDUX_NODE_NAME.NAPP_USER,
        isEtat: true
    },
    {
        name: 'tokenRefresher',
        reducer: set,
        nodeName: GENERIC_ACTIONS_TYPE.DEFAULT_REFRESH_TOKEN_NODE_REDUX
    },
    {
        name: 'commandeFlashAllRdx',
        parent: parentsNode.commandes,
        nodeName: 'COMMANDES_FLASHAllRdx',
        isForm: true
    },
    {
        name: 'commandesFlash',
        nodeName: DB_NODE.COMMANDE_FLASH,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commandesFlashEncours',
        nodeName: REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commandesFlashAttente',
        nodeName: REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ATTENTE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commandesFlashAnnuler',
        nodeName: REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ANNULER,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commandesFlashLivrer',
        nodeName: REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_LIVRER,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'creditersCompte',
        nodeName: DB_NODE.CREDITER_COMPTE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'versements',
        nodeName: REDUX_NODE_NAME.VERSEMENTS,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'approCash',
        nodeName: REDUX_NODE_NAME.APPRO_CASH,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commandesEmoney',
        nodeName: DB_NODE.COMMANDE_EMONEY,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commandesEmoneyEncours',
        nodeName: REDUX_NODE_NAME.COMMANDES_EMONEY_SENDER_ENCOURS,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commandesEmoneyAttente',
        nodeName: REDUX_NODE_NAME.COMMANDES_EMONEY_SENDER_ATTENTE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commandesEmoneyAnnuler',
        nodeName: REDUX_NODE_NAME.COMMANDES_EMONEY_SENDER_ANNULER,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commandesEmoneyLivrer',
        nodeName: REDUX_NODE_NAME.COMMANDES_EMONEY_SENDER_LIVRER,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'retraitsEmoney',
        nodeName: REDUX_NODE_NAME.COMMANDE_EMONEY_RETRAIT,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'caissesEmoney',
        nodeName: REDUX_NODE_NAME.CAISSE_EMONEY,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'allCaissesActiveUser',
        nodeName: REDUX_NODE_NAME.ALL_CAISSE_ACTIVE_USER,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'caissesSuperDealerEmoney',
        nodeName: REDUX_NODE_NAME.CAISSE_SUPER_DEALER_EMONEY,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'allCaissesSuperDealer',
        nodeName: REDUX_NODE_NAME.ALL_CAISSE_SUPER_DEALER,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'allCaissesOrganisation',
        nodeName: REDUX_NODE_NAME.ALL_CAISSE_ORGANISATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'soldeCaissesEmoney',
        nodeName: REDUX_NODE_NAME.SOLDE_CAISSE_EMONEY,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'preuvePaiements',
        nodeName: DB_NODE.PREUVE_PAIEMENT,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'createEntreprise',
        nodeName: 'createEntreprise',
        reducer: set
    },
    {
        name: 'rayonsColonne',
        nodeName: DB_NODE.RAYON_COLONNE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'produits',
        nodeName: DB_NODE.PRODUIT,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'produitsSuperDealer',
        nodeName: DB_NODE.PRODUIT_SUPER_DEALER,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'articlesColi',
        nodeName: DB_NODE.ARTICLE_COLI,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'entitesAdministrative',
        nodeName: DB_NODE.ENTITE_ADMINISTRATIVE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'employesOrganisation',
        nodeName: DB_NODE.EMPLOYE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'approvisionnementsStock',
        nodeName: DB_NODE.APPROVISIONNEMENT_STOCK,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'lignesCredit',
        nodeName: DB_NODE.LIGNE_CREDIT,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'approvisionnementsMonnaie',
        nodeName: DB_NODE.APPROVISIONNEMENT_MONNAIE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'avisApprovisionnementSim',
        nodeName: DB_NODE.AVIS_APPROVISIONNEMENT_SIM,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'monnaies',
        nodeName: DB_NODE.MONNAIE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'approvisionnementsSim',
        nodeName: DB_NODE.APPROVISIONNEMENT_SIM,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'comptes',
        nodeName: DB_NODE.COMPTE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'comptesBancaireSuperDealerKinDB',
        nodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE_ORGANISATION_KINDB,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'comptesBancaireOrganisation',
        nodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE_ORGANISATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'comptesBancaire',
        nodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'comptesDistributeur',
        nodeName: REDUX_NODE_NAME.COMPTE_DISTRIBUTEUR,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'comptesMobileMoney',
        nodeName: REDUX_NODE_NAME.COMPTE_MOBILE_MONEY,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'comptesDeclaration',
        nodeName: REDUX_NODE_NAME.COMPTE_DECLARATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'transfertsSim',
        nodeName: DB_NODE.TRANSFERT_SIM,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'monnaiesLocale',
        nodeName: DB_NODE.MONNAIE_LOCALE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'monnaiesLocaleToApprove',
        nodeName: REDUX_NODE_NAME.MONNAIE_LOCALE_TO_APPROVE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'taux',
        nodeName: DB_NODE.TAUX,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'tauxValidation',
        nodeName: REDUX_NODE_NAME.TAUX_VALIDATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'stocks',
        nodeName: DB_NODE.STOCK,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'clientsOrganisation',
        nodeName: REDUX_NODE_NAME.CLIENT_ORGANISATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'caisses',
        nodeName: DB_NODE.CAISSE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'caissesCashCollecteur',
        nodeName: REDUX_NODE_NAME.CAISSE_CASH_COLLECTEUR,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'caissesRevendeur',
        nodeName: REDUX_NODE_NAME.CAISSE_REVENDEUR,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'tarifsRessource',
        nodeName: REDUX_NODE_NAME.TARIF_RESSOURCE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'tarifsCharge',
        nodeName: REDUX_NODE_NAME.TARIF_CHARGE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'agences',
        nodeName: DB_NODE.AGENCE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'agencesMarchand',
        nodeName: REDUX_NODE_NAME.AGENCES_MARCHAND,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'deviseReference',
        nodeName: REDUX_NODE_NAME.DEVISE_REFERENCE,
        reducer: set
    },
    {
        name: 'supersDealer',
        nodeName: DB_NODE.SUPER_DEALER,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'institutionsFinanciere',
        nodeName: DB_NODE.INSTITUTION_FINANCIERE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'institutionsFinanciereToApprove',
        nodeName: REDUX_NODE_NAME.INSTITUTION_FINANCIERE_TO_APPROVE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'fonctions',
        nodeName: DB_NODE.FONCTION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'charges',
        nodeName: DB_NODE.CHARGE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'chargesVariable',
        nodeName: REDUX_NODE_NAME.CHARGE_VARIABLE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'chargesFixe',
        nodeName: REDUX_NODE_NAME.CHARGE_FIXE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'ressources',
        nodeName: DB_NODE.RESSOURCE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'typesMarchandRessource',
        nodeName: DB_NODE.TYPE_MARCHAND_RESSOURCE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'categoriesMarchand',
        nodeName: DB_NODE.CATEGORIE_MARCHAND,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'partenaires',
        nodeName: DB_NODE.PARTENAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'profilsUserOrganisation',
        nodeName: DB_NODE.PROFIL_USER_ORGANISATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'comptesImputation',
        nodeName: REDUX_NODE_NAME.COMPTE_IMPUTATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'servicesGenerateur',
        nodeName: DB_NODE.SERVICE_GENERATEUR,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'directions',
        nodeName: REDUX_NODE_NAME.DIRECTION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'secteurs',
        nodeName: REDUX_NODE_NAME.SECTEUR,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'demandesAffiliation',
        nodeName: DB_NODE.DEMANDE_AFFILIATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'presencesAgent',
        nodeName: DB_NODE.PRESENCE_AGENT,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'transfertsStockMateriel',
        nodeName: DB_NODE.TRANSFERT_STOCK_MATERIEL,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'colonnesAgence',
        nodeName: REDUX_NODE_NAME.COLONNE_AGENCE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'imputationsStock',
        nodeName: DB_NODE.IMPUTATION_STOCK,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'imputationsCaissse',
        nodeName: REDUX_NODE_NAME.IMPUTATION_CAISSE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'imputations',
        nodeName: DB_NODE.IMPUTATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'typesStock',
        nodeName: DB_NODE.TYPE_STOCK,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'regularisationsStock',
        nodeName: DB_NODE.REGULARISATION_STOCK,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'regularisationsCaisseEmoney',
        nodeName: REDUX_NODE_NAME.REGULARISATION_CAISSE_EMONEY,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'beneficiaires',
        nodeName: REDUX_NODE_NAME.BENEFICIAIRE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'elaborations',
        nodeName: DB_NODE.ELABORATION_AMENDEMENT,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'secteursRessourcesKindb',
        nodeName: REDUX_NODE_NAME.SECTEUR_RESSOURCE_KINDB,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'declarationsRessource',
        nodeName: REDUX_NODE_NAME.DECLARATION_RESSOURCE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'reevaluations',
        nodeName: REDUX_NODE_NAME.REEVALUATION,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'commanderEnligne',
        nodeName: DB_NODE.PARAMETRE_COMMANDE_EN_LIGNE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'detailApprovisionnementsStock',
        nodeName: REDUX_NODE_NAME.DETAIL_APPRO_STOCK_PRODUIT_MATERIEL,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'ingredients',
        nodeName: REDUX_NODE_NAME.INGREDIENTS,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'emplacementsPhysique',
        nodeName: DB_NODE.EMPLACEMENT_PHYSIQUE,
        reducer: crud,
        isEtat: true
    },
    {
        name: 'pointsLivraison',
        nodeName: DB_NODE.POINT_LIVRAISON,
        reducers: true,
        isEtat: true
    },
    {
        name: 'salleManger',
        nodeName: DB_NODE.SALLE_MANGER_TABLE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'marchands',
        nodeName: DB_NODE.MARCHAND,
        reducers: true,
        isEtat: true
    },
    {
        name: 'produitsAgenceMarchand',
        nodeName: REDUX_NODE_NAME.PRODUITS_AGENCE_MARCHAND,
        reducers: true,
        isEtat: true
    },
    {
        name: 'groupeAbonnes',
        nodeName: DB_NODE.GROUPE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'abonnements',
        nodeName: DB_NODE.ABONNEMENT,
        reducers: true,
        isEtat: true
    },
    {
        name: 'produit',
        nodeName: REDUX_NODE_NAME.PRODUIT_MARCHAND,
        reducer: set,
        isEtat: true
    },
    {
        name: 'retracageRessourceCharge',
        nodeName: REDUX_NODE_NAME.RETRACAGE_RESSOURCE_CHARGE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'traitementsModeRetracage',
        nodeName: DB_NODE.TRAITEMENT_MODE_RETRACAGE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'usersService',
        nodeName: REDUX_NODE_NAME.USER_SERVICE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'collections',
        nodeName: REDUX_NODE_NAME.COLLECTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'notificationsZone',
        nodeName: DB_NODE.NOTIFICATION_ZONE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'paiementCaisseSectorielle',
        nodeName: REDUX_NODE_NAME.PAIEMENT_CAISSE_SECTORIELLE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'paiementCompteExecution',
        nodeName: REDUX_NODE_NAME.PAIEMENT_COMPTE_EXECUTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'dispatchingPaiement',
        nodeName: DB_NODE.MOYEN_PAIEMENT_DATE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'instructions',
        nodeName: DB_NODE.INSTRUCTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'exerciceFinancier',
        nodeName: DB_NODE.EXERCICE_FINANCIER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'profilUserDedier',
        nodeName: DB_NODE.PROFIL_USER_DEDIE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'fonctionDisponible',
        nodeName: REDUX_NODE_NAME.FONCTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allProfilUsers',
        nodeName: DB_NODE.PROFIL_USER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allServices',
        nodeName: DB_NODE.SYST_SERVICE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'transfertFonds',
        nodeName: DB_NODE.TRANSFERT_FONDS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'chargesAReevaluer',
        nodeName: REDUX_NODE_NAME.CHARGE_A_REEVALUER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'chargeVariableAReevaluer',
        nodeName: REDUX_NODE_NAME.CHARGE_VARIABLES_A_REEVALUER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'paiementSectorielleBeneficiaires',
        nodeName: REDUX_NODE_NAME.PAIEMENT_CAISSE_SECTORIELLE_BENEFICIAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'crediterCaution',
        nodeName: REDUX_NODE_NAME.CREDITER_CAUTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'remboursementCautionBeneficiaire',
        nodeName: REDUX_NODE_NAME.REMBOURSEMENT_CAUTION_BENEFICIAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'caissesActiveUser',
        nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'userAgentVendeur',
        nodeName: REDUX_NODE_NAME.USER_AGENT_VENDEUR,
        reducers: true,
        isEtat: true
    },
    {
        name: 'logoTemponLogo',
        nodeName: REDUX_NODE_NAME.LOGO_TEMPON_PRODUIT,
        reducer: set,
        isEtat: true
    },
    {
        name: 'derogations',
        nodeName: DB_NODE.DEROGATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'configurationsKindb',
        nodeName: DB_NODE.CONFIGURATION_KINDB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'imputationStockOrganisation',
        nodeName: REDUX_NODE_NAME.IMPUTATION_STOCK_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'servicesByOrganisation',
        nodeName: REDUX_NODE_NAME.SERVICES_BY_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'techConfigs',
        nodeName: DB_NODE.CONFIG,
        reducers: true,
        isEtat: true
    },
    {
        name: 'regularisationsCaisse',
        nodeName: DB_NODE.REGULARISATION_CAISSE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'imputationsCaisseOrganisation',
        nodeName: REDUX_NODE_NAME.IMPUTATION_CAISSE_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'senders',
        nodeName: REDUX_NODE_NAME.AGENT_SENDERS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'zoneAgentsenders',
        nodeName: REDUX_NODE_NAME.ZONE_AGENT_SENDERS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'relevesBancaire',
        nodeName: REDUX_NODE_NAME.UPLOAD_RELEVE_BANCAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'limitsCredit',
        nodeName: REDUX_NODE_NAME.LIMIT_CREDIT,
        reducers: true,
        isEtat: true
    },
    {
        name: 'caissesActiveOrganisation',
        nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'caissesSectorielle',
        nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'devises',
        nodeName: DB_NODE.DEVISE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'monnaieMarchand',
        nodeName: REDUX_NODE_NAME.MONNAIE_MARCHAND,
        reducers: true,
        isEtat: true
    },
    {
        name: 'limitCredit',
        nodeName: REDUX_NODE_NAME.COMPTE_LIMITE_CREDIT,
        reducer: set,
        isEtat: true
    },
    {
        name: 'compteLimitCreditOrganisation',
        nodeName: REDUX_NODE_NAME.COMPTE_LIMITE_CREDIT_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'cashCollectionOrganisation',
        nodeName: REDUX_NODE_NAME.CASH_COLLECTION_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'crediterCompteViaInstitution',
        nodeName: REDUX_NODE_NAME.CREDITER_COMPTE_VIA_INSTITUT_FINANCIERE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'approCashVersement',
        nodeName: DB_NODE.APPRO_CASH_VERSEMENT,
        reducers: true,
        isEtat: true
    },
    {
        name: 'transfertCaisse',
        nodeName: REDUX_NODE_NAME.TRANSFERT_CAISSE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'operationChange',
        nodeName: REDUX_NODE_NAME.OPERATION_CHANGE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'operationChangeGuichet',
        nodeName: REDUX_NODE_NAME.OPERATION_CHANGE_GUICHET,
        reducers: true,
        isEtat: true
    },
    {
        name: 'coupureDevise',
        nodeName: REDUX_NODE_NAME.COUPURE_DEVISE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allCoupures',
        nodeName: REDUX_NODE_NAME.ALL_COUPURE_DEVISE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'encaissementsDecaissements',
        nodeName: DB_NODE.ENCAISSEMENT_DECAISSEMENT,
        reducers: true,
        isEtat: true
    },
    {
        name: 'encaissements',
        nodeName: REDUX_NODE_NAME.ENCAISSEMENTS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'decaissements',
        nodeName: REDUX_NODE_NAME.DECAISSEMENTS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'virements',
        nodeName: REDUX_NODE_NAME.VIREMENTS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'virementsKindb',
        nodeName: REDUX_NODE_NAME.VIREMENTS_KINDB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'comptesBancairePartenaire',
        nodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE_PARTENAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'operationsBancaires',
        nodeName: REDUX_NODE_NAME.OPERATION_BANCAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'usersOrganisation',
        nodeName: REDUX_NODE_NAME.USERS_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'raisons',
        nodeName: REDUX_NODE_NAME.RAISONS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'depenses',
        nodeName: REDUX_NODE_NAME.DEPENSES,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allBalanceOrganisation',
        nodeName: REDUX_NODE_NAME.ALL_BALANCE_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allBalanceOrganisationTransfertFonds',
        nodeName: REDUX_NODE_NAME.ALL_BALANCE_ORGANISATION_TRANSFERT_FONDS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allBalanceOrganisationCompteTransfertFonds',
        nodeName:
            REDUX_NODE_NAME.ALL_BALANCE_ORGANISATION_COMPTE_TRANSFERT_FONDS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allCompteCautionOrganisation',
        nodeName: REDUX_NODE_NAME.ALL_COMPTE_CAUTION_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'organisationsReport',
        nodeName: REDUX_NODE_NAME.ORGANISATION_REPORT,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allBalanceCashCollecteur',
        nodeName: REDUX_NODE_NAME.ALL_BALANCE_CASH_COLLECTEUR,
        reducers: true,
        isEtat: true
    },
    {
        name: 'superDealerKinDB',
        nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'superDealerEmoneyKinDB',
        nodeName: REDUX_NODE_NAME.SUPER_DEALER_EMONEY_KIN_DB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allSuperDealerKinDB',
        nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'revendeurs',
        nodeName: DB_NODE.REVENDEUR,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allCashCollecteurKinDB',
        nodeName: REDUX_NODE_NAME.ALL_CASH_COLLECTEUR_KIN_DB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'reportBalancesOrganisation',
        nodeName: REDUX_NODE_NAME.BALANCE_ORGANISATION,
        reducer: set
    },
    {
        name: 'reportStocksOrganisation',
        nodeName: REDUX_NODE_NAME.STOCK_ORGANISATION,
        reducer: set,
        isEtat: true
    },
    {
        name: 'stocksSuperDealerKinDBIntegrer',
        nodeName: REDUX_NODE_NAME.STOCK_SUPER_DEALER_INTEGRER,
        isEtat: true
    },
    {
        name: 'stocksPhysique',
        nodeName: REDUX_NODE_NAME.STOCK_PHYSIQUE,
        isEtat: true
    },
    {
        name: 'regularisationSpeciale',
        nodeName: REDUX_NODE_NAME.REGULARISATION_SPECIALE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allCoupures',
        nodeName: REDUX_NODE_NAME.ALL_COUPURE_DEVISE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'stocksARegulariser',
        nodeName: REDUX_NODE_NAME.STOCK_A_REGULARISER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'traitementConfigurationMarchandApi',
        nodeName: REDUX_NODE_NAME.TRAITEMENT_CONFIGURATION_MARCHAND_API,
        reducers: true,
        isEtat: true
    },
    {
        name: 'configurationApiPartenaire',
        nodeName: REDUX_NODE_NAME.CONFIGURATION_API_PARTENAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'reportsStockSuperDealer',
        nodeName: REDUX_NODE_NAME.REPORT_STOCK_SUPER_DEALER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'reportsCaisseSuperDealer',
        nodeName: REDUX_NODE_NAME.REPORT_CAISSE_SUPER_DEALER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'evolutionsCaisseSuperDealer',
        nodeName: REDUX_NODE_NAME.EVOLUTION_CAISSE_SUPER_DEALER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'evolutionsStockSuperDealer',
        nodeName: REDUX_NODE_NAME.EVOLUTION_STOCK_SUPER_DEALER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'casLitigieux',
        nodeName: REDUX_NODE_NAME.CAS_LITIGIEUX,
        reducers: true,
        isEtat: true
    },
    {
        name: 'litigieuxEmoney',
        nodeName: REDUX_NODE_NAME.LITIGIEUX_EMONEY,
        reducers: true,
        isEtat: true
    },
    {
        name: 'relevesEmoney',
        nodeName: REDUX_NODE_NAME.UPLOAD_RELEVE_EMONEY,
        reducers: true,
        isEtat: true
    },
    {
        name: 'relevesFlash',
        nodeName: REDUX_NODE_NAME.UPLOAD_RELEVE_FLASH,
        reducers: true,
        isEtat: true
    },
    {
        name: 'marchandKindb',
        nodeName: REDUX_NODE_NAME.MARCHAND_KINDB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allMarchand',
        nodeName: REDUX_NODE_NAME.ALL_MARCHAND,
        reducers: true,
        isEtat: true
    },
    {
        name: 'marchandsElectronique',
        nodeName: REDUX_NODE_NAME.MARCHAND_ELECTRONIQUE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'agentsSuperDealerKinDB',
        nodeName: REDUX_NODE_NAME.AGENT_SUPER_DEALER_KIN_DISTRIBUTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'agentsCashCollecteur',
        nodeName: REDUX_NODE_NAME.AGENT_CASH_COLLECTEUR,
        reducers: true,
        isEtat: true
    },
    {
        name: 'tauxSuperDealerKindb',
        nodeName: REDUX_NODE_NAME.TAUX_SUPER_DEALER_KINDB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allPartenaireKinDB',
        nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KINDB_PARTENAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'institutionAgreeeNonAgreee',
        nodeName: REDUX_NODE_NAME.INSTITUTION_AGREEE_NON_AGREEE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'configInstruction',
        nodeName: REDUX_NODE_NAME.CONFIG_INSTRUCTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'compteParallele',
        nodeName: REDUX_NODE_NAME.COMPTE_PARALLELE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'tunnels',
        nodeName: REDUX_NODE_NAME.TUNNEL,
        reducers: true,
        isEtat: true
    },
    {
        name: 'stocksSuperDealer',
        nodeName: REDUX_NODE_NAME.STOCK_SUPER_DEALER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'depotEmoney',
        nodeName: REDUX_NODE_NAME.DEPOT_EMONEY,
        reducers: true,
        isEtat: true
    },
    {
        name: 'compteCreance',
        nodeName: REDUX_NODE_NAME.COMPTE_CREANCE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'statusRevendeur',
        nodeName: REDUX_NODE_NAME.STATUS_REVENDEUR,
        reducers: true,
        isEtat: true
    },
    {
        name: 'cashBookUser',
        nodeName: REDUX_NODE_NAME.CASH_BOOK_USER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'transfertKinDb',
        nodeName: REDUX_NODE_NAME.TRANSFERT_KINDB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'transfertBalanceCaution',
        nodeName: REDUX_NODE_NAME.TRANSFERT_BALANCE_CAUTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'balancesAndCautionsOrganisation',
        nodeName: REDUX_NODE_NAME.BALANCE_AND_CAUTION_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'versionApplication',
        nodeName: REDUX_NODE_NAME.VERSION_APPLICATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'applicationMobile',
        nodeName: REDUX_NODE_NAME.APPLICATION_MOBILE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'tauxOrganisationKindistribution',
        nodeName: REDUX_NODE_NAME.TAUX_ORGANISATION_KINDISTRIBUTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'tauxActifs',
        nodeName: REDUX_NODE_NAME.TAUX_ACTIFS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'typeCaisseOrganisation',
        nodeName: REDUX_NODE_NAME.TYPE_CAISSE_ORGANISATION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'notificationMobile',
        nodeName: REDUX_NODE_NAME.NOTIFICATION_MOBILE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'produitsMarchandSuperDealer',
        nodeName: REDUX_NODE_NAME.PRODUITS_MARCHAND_SUPER_DEALER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'prixProduitElectronique',
        nodeName: REDUX_NODE_NAME.PRIX_PRODUIT_ELECTRONIQUE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'comptesSuperDealerKinDB',
        nodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE_SUPER_DEALER_KINDB,
        reducers: true,
        isEtat: true
    },
    {
        name: 'notificationPush',
        nodeName: REDUX_NODE_NAME.NOTIFICATION_PUSH,
        reducers: true,
        isEtat: true
    },
    {
        name: 'sujetsDisponible',
        nodeName: REDUX_NODE_NAME.SUJETS_DISPONIBLE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'formulaireDynamiqueSimFlash',
        nodeName: REDUX_NODE_NAME.FORMULAIRE_DYNAMIQUE_SIM_FLASH,
        reducers: true,
        isEtat: true
    },
    {
        name: 'uploadTraitementPaiementVendeur',
        nodeName: REDUX_NODE_NAME.UPLOAD_TRAITEMENT_PAIEMENT_VENDEUR,
        reducers: true,
        isEtat: true
    },
    {
        name: 'configurationPartenaire',
        nodeName: REDUX_NODE_NAME.CONFIGURATION_PARTENAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'rapportDashBoardBalance',
        nodeName: REDUX_NODE_NAME.RAPPORT_DASHBOARD_BALANCE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'rapportDashBoardCaisseEmoney',
        nodeName: REDUX_NODE_NAME.RAPPORT_DASHBOARD_CAISSE_EMONEY,
        reducers: true,
        isEtat: true
    },
    {
        name: 'etatConfigurationSender',
        nodeName: REDUX_NODE_NAME.ETAT_CONFIGURATION_SENDER,
        reducer: set,
        isEtat: true
    },
    {
        name: 'heuresSupplementaire',
        nodeName: DB_NODE.HEURE_SUPPLEMENTAIRE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'tachesPerformanceAgent',
        nodeName: REDUX_NODE_NAME.TACHES_PERFORMANCE_AGENT,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allTachesPerformanceAgent',
        nodeName: REDUX_NODE_NAME.ALL_TACHES_PERFORMANCE_AGENT,
        reducers: true,
        isEtat: true
    },
    {
        name: 'tachesPerformanceProfilUser',
        nodeName: REDUX_NODE_NAME.TACHES_PERFORMANCE_PROFIL_USER,
        reducers: true,
        isEtat: true
    },
    {
        name: 'objectifs',
        nodeName: REDUX_NODE_NAME.OBJECTIFS,
        reducers: true,
        isEtat: true
    },
    {
        name: 'tachesPerformances',
        nodeName: REDUX_NODE_NAME.TACHES_PERFORMANCE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'allFonction',
        nodeName: REDUX_NODE_NAME.ALL_FONCTION,
        reducers: true,
        isEtat: true
    },
    {
        name: 'confirmationSoldeCompte',
        nodeName: REDUX_NODE_NAME.CONFIRMATION_SOLDE_COMPTE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'fichierPresence',
        nodeName: REDUX_NODE_NAME.FICHIER_PRESENCE,
        reducers: true,
        isEtat: true
    },
    {
        name: 'serviceClientForm',
        nodeName: REDUX_NODE_NAME.SERVICE_CLIENT_FORM,
        reducers: true,
        isEtat: true
    },
    {
        name: 'fonctionUpdate',
        nodeName: REDUX_NODE_NAME.FONCTION_UPDATE,
        reducers: true,
        isEtat: true
    }
];

const reducers = reduxNodesBuilder(listNoeud);

export { reducers };
