import { useDispatch, useSelector } from 'react-redux';
import {
    submitPutAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CATEGORIE_MARCHAND,
    DB_NODE,
    FORM_CONST,
    GENERIC_ACTIONS_TYPE,
    formInitialState
} from '@napp-inc/jnapp-util';
import {
    CenteredSpinner,
    CustomAddRemoveButton,
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    isOpened: {},
    isSmsSynchro: {},
    intervalGetCommande: 0,
    intervalPostSms: 0,
    selectedDevise: {},
    devisesRupture: []
};

export function EtatConfigurationSender() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUserMarchandType,
        ecouteurEtatConfigurationSender,
        etatEtatConfigurationSender,
        etatConfigurationSender,
        organisation
        // monnaiesLocales,
        // etatMonnaieLocale
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUserMarchandType: reduxState.nappUser?.marchand?.type,
        ecouteurEtatConfigurationSender:
            reduxState.etat.etatConfigurationSender.ecouteur,
        etatEtatConfigurationSender:
            reduxState.etat.etatConfigurationSender.etat,
        etatConfigurationSender: reduxState.etatConfigurationSender,
        organisation: reduxState.organisation
        // monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
        //     ...item,
        //     code: item.code || item.devise
        // })),
        // etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat
    }));
    const { idToken, uid } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            // {
            //     // isDocument: true,
            //     isFetchListening: true,
            //     path: DB_NODE.CONFIG_SENDER,
            //     // id: uid,
            //     nodeName: REDUX_NODE_NAME.ETAT_CONFIGURATION_SENDER,
            //     ecouteur: ecouteurEtatConfigurationSender,
            //     functionName: URL_CONST.GET_CONFIG_SENDER,
            //     etat: etatEtatConfigurationSender,
            //     listenerFilters: [
            //         {
            //             property: 'sender.reference',
            //             value: uid,
            //             operator: FILTER_OPERATOR.EQUAL,
            //             type: FILTER_TYPE.WHERE,
            //             isRequired: true
            //         },
            //         {
            //             type: FILTER_TYPE.ORDER_BY,
            //             property: 'dateDerniereModification',
            //             value: 'asc'
            //         },
            //         {
            //             type: FILTER_TYPE.START_AT,
            //             value: TODAY.nowUtc(),
            //             isTimestampNowValue: true
            //         }
            //     ]
            // },
            {
                isDocument: true,
                path: DB_NODE.CONFIG_SENDER,
                id: uid,
                nodeName: REDUX_NODE_NAME.ETAT_CONFIGURATION_SENDER,
                ecouteur: ecouteurEtatConfigurationSender
            }
            // {
            //     isOnlyFetch: true,
            //     functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
            //     nodeName: DB_NODE.MONNAIE_LOCALE,
            //     etat: etatMonnaieLocale
            // }
        ]
    });

    const addProduit = () => {
        if (fields.selectedDevise && fields.selectedDevise.code) {
            const target =
                etatConfigurationSender?.devisesRupture?.[
                    fields?.selectedDevise?.code
                ]?.estActif;
            const etat = target === false;
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    field: 'devisesRupture',
                    identifier: 'devise',
                    data: {
                        devise: fields.selectedDevise.code,
                        estActif: etat
                    }
                }
            });
        }
    };

    const handleSubmit = () => {
        const payload = {
            idConfigSender: etatConfigurationSender.id,
            elementUpdate: {}
        };
        if (fields?.isOpened?.code)
            payload.elementUpdate.estOuvert =
                fields?.isOpened?.code === 'OUVRIR';
        if (fields?.isSmsSynchro?.code)
            payload.elementUpdate.estSynchronisationSms =
                fields?.isSmsSynchro?.code === 'ACTIVER';
        if (fields?.intervalGetCommande)
            payload.elementUpdate.intervalleGetCommande =
                fields?.intervalGetCommande;
        if (fields?.intervalPostSms)
            payload.elementUpdate.intervallePostSms = fields?.intervalPostSms;
        if (fields?.devisesRupture?.length) {
            payload.elementUpdate.devisesRupture = fields.devisesRupture;
        }
        formDispatcher(
            submitPutAction({
                reduxDispatcher,
                functionName: URL_CONST.PUT_CONFIG_SENDER,
                fields,
                idToken,
                payload
            })
        );
    };

    const tdExtractor = (item) => {
        if (item?.devisesRupture) {
            const devise = Object.keys(item?.devisesRupture);

            return (
                <td>
                    {devise?.map((dev) => (
                        <div key={`${dev}`}>
                            <span>{dev} :</span>{' '}
                            <span>
                                {item?.devisesRupture?.[dev]?.estActif
                                    ? 'Actif'
                                    : 'Inactif'}
                            </span>
                            {' |'}
                        </div>
                    ))}
                </td>
            );
        }
        return <span>Aucune devise</span>;
    };

    const renderContent = () => {
        if (
            etatEtatConfigurationSender ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS ||
            etatConfigurationSender.id
        ) {
            return (
                <>
                    {form.status === FORM_CONST.MODIFICATION_ECHEC ? (
                        <CustomAlert error="Cette dévise n'a pas encore été configurée!" />
                    ) : null}
                    <CustomRow isShouldBeRendered={etatConfigurationSender.id}>
                        <h4>Etat Actuel des services</h4>
                        <div className="w-100" />
                        <CustomCol className="col-12">
                            <SimpleTable
                                isActionActive={false}
                                isShowDropButton={false}
                                data={[etatConfigurationSender]}
                                tableClass="table table-hover table-bordered table-responsive-sm"
                                filter={false}
                                bottomPagination={false}
                                isLinkToDetail={false}
                                mapper={[
                                    {
                                        field: 'estOuvert',
                                        displayName: 'Service',
                                        isBoolean: true
                                    },
                                    {
                                        field: 'estSynchronisationSms',
                                        displayName: 'Synchronisation',
                                        isBoolean: true
                                    },
                                    {
                                        field: 'intervalleGetCommande',
                                        displayName: 'Interval Commande'
                                    },
                                    {
                                        field: 'intervallePostSms',
                                        displayName: 'Interval SMS'
                                    },
                                    {
                                        field: 'devisesRupture',
                                        displayName: 'Etat Devises',
                                        tdExtractor
                                    }
                                ]}
                            />
                        </CustomCol>
                    </CustomRow>
                    <CustomAlert error={formState?.form?.error} />
                    <CustomRow>
                        <CustomCol>
                            <CustomDropdown
                                name="isOpened"
                                id="isOpenedId"
                                options={[
                                    { code: 'OUVRIR', designation: 'OUVRIR' },
                                    { code: 'FERMER', designation: 'FERMER' }
                                ]}
                                defaultOption="Ouvrir ou Fermer le service"
                                divClassName="col-sm-12"
                                formState={formState}
                                formDispatcher={formDispatcher}
                            />
                            <div className="w-100" />
                            <fieldset>
                                <legend>
                                    <p className="note">
                                        Ceci indique si le service est ouvert ou
                                        fermé.
                                    </p>
                                </legend>
                            </fieldset>
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomCol>
                            <CustomDropdown
                                name="isSmsSynchro"
                                id="isSmsSynchroId"
                                options={[
                                    { code: 'ACTIVER', designation: 'ACTIVER' },
                                    {
                                        code: 'DESACTIVER',
                                        designation: 'DESACTIVER'
                                    }
                                ]}
                                defaultOption="Synchronisation SMS"
                                // label="Unité*"
                                // labelClassName="col-sm-2"
                                divClassName="col-sm-12"
                                formState={formState}
                                formDispatcher={formDispatcher}
                                // uiValidator={uiValidator}
                            />
                            <div className="w-100" />
                            <fieldset>
                                <legend>
                                    <p className="note">
                                        Ceci indique si la synchronisation avec
                                        les SMS est activée ou pas.
                                    </p>
                                </legend>
                            </fieldset>
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomInput
                            type="number"
                            isInputGroup={false}
                            divClassName="col-12 col-md-12"
                            placeholder="Interval commande"
                            formDispatcher={formDispatcher}
                            name="intervalGetCommande"
                            id="intervalGetCommandeDetailConfigId"
                            formState={formState}
                        />
                        <div className="w-100" />
                        <fieldset>
                            <legend>
                                <p className="note">
                                    Permet de définir l&apos;interval entre deux
                                    processus de lecture des Commandes.
                                </p>
                            </legend>
                        </fieldset>
                    </CustomRow>
                    <CustomRow>
                        <CustomInput
                            type="number"
                            isInputGroup={false}
                            divClassName="col-12 col-md-12"
                            placeholder="Interval commande"
                            formDispatcher={formDispatcher}
                            name="intervalPostSms"
                            id="intervalPostSmsDetailConfigId"
                            formState={formState}
                        />
                        <div className="w-100" />
                        <fieldset>
                            <legend>
                                <p className="note">
                                    Permet de définir l&apos;interval entre deux
                                    processus d&apos;écriture des Commandes
                                    (SMS).
                                </p>
                            </legend>
                        </fieldset>
                    </CustomRow>
                    <CustomRow
                        isShouldBeRendered={
                            !!organisation?.estEmoney ||
                            nappUserMarchandType ===
                                CATEGORIE_MARCHAND.MOBILE_MONEY.code
                        }
                    >
                        <CustomRow>
                            <CustomDropdown
                                options={[
                                    { code: 'USD', designation: 'USD' },
                                    { code: 'CDF', designation: 'CDF' }
                                ]}
                                defaultOption="Selectionner Devise"
                                divClassName="col-12 col-sm-10"
                                formDispatcher={formDispatcher}
                                name="selectedDevise"
                                id="selectedDeviseConfigurationSenderId"
                                formState={formState}
                            />
                            <CustomAddRemoveButton
                                className="col-sm-2"
                                callback={addProduit}
                            />
                        </CustomRow>
                        <div className="w-100" />
                        <fieldset>
                            <legend>
                                <p className="note">
                                    La monnaie à activer ou à desactiver.
                                </p>
                            </legend>
                        </fieldset>
                    </CustomRow>
                    <CustomCollapse isIn={fields.devisesRupture.length > 0}>
                        <SimpleTable
                            data={fields.devisesRupture}
                            identifier="devise"
                            formDispatcher={formDispatcher}
                            listSelectedItemName="devisesRupture"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm mt-3"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    displayName: 'Devise',
                                    field: 'devise'
                                },
                                {
                                    position: 2,
                                    displayName: 'Etat à définir',
                                    field: 'estActif',
                                    // tdExtractor: (item) =>
                                    //     !item.estActif ? 'FERMER' : 'ACTIVER',
                                    isBoolean: true
                                }
                            ]}
                        />
                    </CustomCollapse>
                    <CustomButtonLoader
                        onClick={handleSubmit}
                        type="button"
                        className="btn mt-1 w-25"
                        text="Soumettre"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </>
            );
        }
        if (
            etatEtatConfigurationSender ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des cash collecteurs" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
