export const schema = [
    {
        title: 'Code',
        description: 'valeurTypeProprietaire.code',
        className: 'border-bottom'
    },
    {
        title: 'Designation',
        description: 'valeurTypeProprietaire.designation',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation'
    }
];
