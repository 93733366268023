export const schema = [
    {
        title: 'Initiateur',
        description: 'valeurTypeOrganisation.designation'
    },
    {
        title: 'Caisse Source',
        description: 'caisseA.designation'
    },
    {
        title: 'Beneficiaire',
        description: 'valeurTypeOrganisationB.designation'
    },
    {
        title: 'Caisse Bénéficiaire',
        description: 'caisseB.designation'
    },
    {
        title: 'Montant',
        description: 'montant'
    },
    {
        title: 'Devise',
        description: 'devise'
    },
    {
        title: 'Devise Sollicité',
        description: 'deviseSollicite'
    },
    {
        title: 'Etat',
        description: 'etat'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
