import * as yup from 'yup';

export const CreationAgenceSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required(),
    preview: yup.string(),
    selectedZone: yup.object().shape({
        reference: yup.string(),
        designation: yup.string()
    })
});
