import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    TYPE_ORGANISATION,
    LIST_TYPE_TAUX,
    DB_NODE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { TauxKindistributioFormSchema as uiValidator } from './validation';

const defaultFields = {
    selectedTypeOrganisation: {},
    selectedDevise: {},
    selectedTypeTaux: {},
    taux: 0,
    list: []
};

const defaultDependencies = {
    fields: ['monnaie']
};

export function CreationTauxOrganisationKindistributionForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        monnaiesLocales,
        etatMonnaieLocale,
        deviseReference
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            code: item.code || item.devise
        })),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat,
        deviseReference: reduxState.deviseReference
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: []
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            }
        ]
    });

    const { fields, form } = formState;

    const onAddDeviseSollicite = () => {
        if (!fields.selectedTypeTaux?.code || !fields.taux) return;
        const data = {
            designation: fields.selectedTypeTaux?.designation,
            type: fields.selectedTypeTaux?.code,
            valeur: fields.taux
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'list',
                data,
                identifier: 'type'
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            fields?.list?.find((item) => item.type === 'TauxAchat')?.valeur >
            fields?.list?.find((item) => item.type === 'TauxVente')?.valeur
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        "Le taux d'achat doit être inférieur ou égal au taux de vente",
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        if (fields.selectedDevise?.devise === deviseReference?.devise) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Veuillez revoir la dévise selectionnée',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const payload = {
            typeProprietaire: fields.selectedTypeOrganisation?.code,
            devise: fields.selectedDevise?.devise,
            list: fields.list.map((item) => ({
                type: item.type,
                valeur: item.valeur
            }))
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_TAUX_KINDISTRIBUTION,
                payload,
                fields,
                reduxNodeName:
                    REDUX_NODE_NAME.TAUX_ORGANISATION_KINDISTRIBUTION,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                { dependency: 'monnaie', etat: etatMonnaieLocale }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={[
                        {
                            code: TYPE_ORGANISATION.CASH_COLLECTEUR,
                            designation: 'Cash Collecteur'
                        },
                        {
                            code: TYPE_ORGANISATION.SUPER_DEALER,
                            designation: 'Super Dealer'
                        }
                    ]}
                    label="Type Organisation* :"
                    defaultOption="Sélectionner Type Organisation"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeOrganisation"
                    id="selectedTypeOrganisationcreationTauxOrganisationKindistributionId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    formDispatcher={formDispatcher}
                    formState={formState}
                    // // uiValidator={uiValidator}
                    name="selectedDevise"
                    id="creationTauxOrganisationKindistributionMontantselectedDeviseSolliciteId"
                    options={monnaiesLocales?.filter(
                        (item) => item?.devise !== deviseReference?.devise
                    )}
                    disabled={
                        !monnaiesLocales?.filter(
                            (item) => item?.devise !== deviseReference?.devise
                        )?.length
                    }
                    selectionClassName="form-select-md"
                    defaultOption="Selectionner Devise"
                    label="Devise* : "
                    propertyToDisplay="devise"
                    idProperty="id"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Taux* :" className="col-sm-2" />
                <CustomDropdown
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    name="selectedTypeTaux"
                    id="creationTauxOrganisationKindistributionMontantselectedTypeTauxId"
                    options={LIST_TYPE_TAUX}
                    disabled={!LIST_TYPE_TAUX?.length}
                    selectionClassName="form-select-md"
                    defaultOption="Selectionner Type"
                    divClassName="col-sm-4"
                />
                <CustomInput
                    isFloat
                    type="number"
                    divClassName="col-sm-4"
                    isInputGroup={false}
                    placeholder="Taux"
                    formDispatcher={formDispatcher}
                    name="taux"
                    id="creationTauxOrganisationKindistributionMontantTauxSolliciteId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={onAddDeviseSollicite}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields?.list?.length}>
                <SimpleTable
                    identifier="type"
                    data={fields?.list}
                    formDispatcher={formDispatcher}
                    listSelectedItemName="list"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm w-75 offset-3"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            field: 'designation',
                            displayName: 'designation'
                        },
                        {
                            position: 2,
                            field: 'valeur',
                            displayName: 'Valeur'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
