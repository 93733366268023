import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import { ETAT, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
// import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomInputDate,
    CustomLabel,
    CustomRow
} from '../../../components';

const defaultFields = {
    actionEncours: '',
    isUpdateView: false
};

export function DetailProgrammerPause() {
    // const { id } = useParams();
    // const reduxDispatcher = useDispatch();
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: { entity: true }
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    // useGetEntity({
    //     entity,
    //     etat: dependencies.entity.status,
    //     formDispatcher,
    //     functionName: URL_CONST.GET_PARAMETRE_COMMANDE_EN_LIGNE_BY_ID,
    //     id,
    //     idToken,
    //     nodeName: DB_NODE.PARAMETRE_COMMANDE_EN_LIGNE,
    //     reduxDispatcher
    // });

    const onUpdateViewAction = () => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'isUpdateView',
                data: !fields.isUpdateView
            }
        });
    };

    const onUpdateAction = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        // formDispatcher(
        //     submitPutAction({
        //         idToken,
        //         functionName: URL_CONST.PUT_PARAMETRE_COMMANDE_EN_LIGNE,
        //         payload,
        //         fields,
        //         reduxNodeName: DB_NODE.PARAMETRE_COMMANDE_EN_LIGNE,
        //         reduxDispatcher
        //     })
        // );
    };
    const renderContent = () => {
        // if (!entity?.id && !isLoadFailed && !isLoadComplete) {
        //     return <CenteredSpinner />;
        // }
        // if (isLoadFailed) {
        //     return <CustomAlert error={form?.error} />;
        // }
        if (fields.something) return <span>Cool View</span>;
        return (
            <>
                <CustomAlert error={form?.error} />
                <CustomRow>
                    <h1 className="lead">Programmer pause</h1>
                </CustomRow>
                <CustomRow>date Création : 169005124848540</CustomRow>
                {!fields.isUpdateView ? (
                    <CustomRow>
                        <CustomCol className="col-sm-2">
                            <CustomButtonLoader
                                type="button"
                                className="btn-primary"
                                onClick={onUpdateViewAction}
                                text="Mettre à jour..."
                            />
                        </CustomCol>
                    </CustomRow>
                ) : null}
                <CustomCollapse isIn={fields.isUpdateView}>
                    <CustomAlert error={form?.error} />
                    <CustomForm>
                        {/* Recupérer  */}
                        <CustomRow>
                            <CustomLabel
                                text="Intervalle*"
                                className="col-sm-2"
                            />
                            <CustomInputDate
                                disabled
                                id="programmerPauseFormDateDebutId"
                                name="debutPause"
                                type="date"
                                isInputGroup={false}
                                divClassName="col-sm-5"
                                placeholder="Date début"
                                formDispatcher={formDispatcher}
                                formState={formState}
                                // uiValidator={uiValidator}
                            />
                            <CustomInputDate
                                disabled
                                id="programmerPauseFormDateFinId"
                                name="finPause"
                                type="date"
                                isInputGroup={false}
                                divClassName="col-sm-5"
                                placeholder="Date début"
                                formDispatcher={formDispatcher}
                                // uiValidator={uiValidator}
                                formState={formState}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomLabel
                                text="Agent et durée*"
                                className="col-sm-2"
                            />
                            <CustomDropdown
                                disabled
                                options={[]}
                                idProperty="reference"
                                formDispatcher={formDispatcher}
                                formState={formState}
                                defaultOption="Sélectionner l'agent"
                                divClassName="col-12 col-sm-5"
                                name="selectedUser"
                                id="selectedUserId"
                                // uiValidator={uiValidator}
                            />
                            <CustomInput
                                id="programmerPauseFormDureeId"
                                name="duree"
                                type="number"
                                isInputGroup={false}
                                divClassName="col-sm-5"
                                placeholder="Durée"
                                formDispatcher={formDispatcher}
                                formState={formState}
                                // uiValidator={uiValidator}
                            />
                        </CustomRow>
                        <CustomRow className="row">
                            <CustomCol className="offset-6 col-3">
                                <CustomButtonLoader
                                    className="btn-primary"
                                    onClick={onUpdateAction}
                                    text="Mettre à jour"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.CREATION_EN_COURS
                                    }
                                />
                            </CustomCol>
                            <CustomCol className="col-3">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-danger"
                                    onClick={onUpdateViewAction}
                                    text="Annuler la mise à jour..."
                                />
                            </CustomCol>
                        </CustomRow>
                    </CustomForm>
                </CustomCollapse>
            </>
        );
    };
    return renderContent();
}
