import {
    TIME,
    TODAY,
    formInitialState,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { AiOutlineReload } from 'react-icons/ai';
import {
    CustomButtonLoader,
    CustomToast,
    ListGenericForm
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST } from '../../../../util';

export function ListNotificationPushForm() {
    /**
     * Get data on redux store
     */
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: { itemId: '' }
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const resendNotification = (e, item) => {
        e.preventDefault();
        if (!item.id) return;
        const payload = {
            idNotificationPush: item?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'itemId',
                data: item?.id
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.SEND_NOTIFICATION_SUJET,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.NOTIFICATION_PUSH
            })
        );
    };
    const renderContent = (item) => (
        <td key={item?.id}>
            <CustomButtonLoader
                className="btn-success"
                onClick={(e) => resendNotification(e, item)}
                text=""
                icon={<AiOutlineReload />}
                disabled={
                    fields?.itemId === item?.id &&
                    form?.status === FORM_CONST.MODIFICATION_EN_COURS
                }
                title="Renvoyer"
            />
        </td>
    );
    return (
        <>
            {form && form.status === FORM_CONST.MODIFICATION_SUCCESS && (
                <CustomToast
                    successMessage="Notification Envoyée"
                    status={form.status}
                />
            )}
            {form && form.status === FORM_CONST.MODIFICATION_ECHEC && (
                <CustomToast error={form.error} />
            )}
            <ListGenericForm
                isLinkToDetail={false}
                reduxPropertyName="notificationPush"
                functionName={URL_CONST.GET_LIST_NOTIFICATION_PUSH_USER}
                nodeName={REDUX_NODE_NAME.NOTIFICATION_PUSH}
                payload={{
                    dateDebut: 0,
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    estAll: true
                }}
                mapper={[
                    {
                        field: 'title',
                        displayName: 'Notification'
                    },
                    {
                        field: 'message',
                        displayName: 'Message'
                    },
                    {
                        displayName: 'Création',
                        field: 'dateCreation'
                    },
                    {
                        displayName: 'Dernier envoi',
                        field: 'dateDerniereModification'
                    },
                    {
                        displayName: 'Actions',
                        tdExtractor: (item) => renderContent(item)
                    }
                ]}
            />
        </>
    );
}
