import * as yup from 'yup';

export const CreationApproCompteEmoneySchema = yup.object().shape({
    selectedMonnaie: yup
        .object()
        .shape({
            code: yup.string().required(),
            designation: yup.string().required()
        })
        .required('Veuillez sélectionner une monnaie'),
    selectedCompte: yup
        .object()
        .shape({
            code: yup.string().required(),
            designation: yup.string().required()
        })
        .required('Veuillez sélectionner un compte'),
    montant: yup.number().positive()
});
