export const schema = [
    {
        description: 'tache.designation',
        title: 'Tâche'
    },
    {
        description: 'valeurTypeOrganisation.designation',
        title: 'Organisation'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
