import { URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListMonnaieLocaleForm() {
    return (
        <ListGenericForm
            reduxPropertyName="monnaiesLocaleToApprove"
            functionName={URL_CONST.GET_LIST_MONNAIE_LOCALE_INITIALS}
            nodeName={REDUX_NODE_NAME.MONNAIE_LOCALE_TO_APPROVE}
            mapper={[
                {
                    field: 'devise',
                    displayName: 'Devise',
                    position: 2
                },
                {
                    field: 'etat',
                    displayName: 'etat',
                    position: 3
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création',
                    position: 3
                }
            ]}
        />
    );
}
