import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, DB_NODE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailRegularisationCaisseEmoneyForm() {
    const {
        id,
        creator,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher
    } = useGetDetailForm({
        nodeName: 'regularisationsCaisse'
    });
    const { fields, form } = formState;

    const updateEtat = (etat) => {
        if (!etat) return;
        const payload = {
            etat,
            creator,
            idRegularisationCaisse: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_REGULARISATION_CAISSE,
                payload,
                fields,
                reduxNodeName: DB_NODE.REGULARISATION_CAISSE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_REGULARISATION_CAISSE_BY_ID}
            nodeName={DB_NODE.REGULARISATION_CAISSE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Régularisation caisse"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .ANNULER_REGULARISATION_CAISSE,
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .VALIDER_REGULARISATION_CAISSE,
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .ATTENTE_VALIDATION_REGULARISATION_CAISSE
                ]}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat !== ETAT.ETAT_VALIDER}
                >
                    <div className="d-flex flex-row justify-content-end">
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat !== ETAT.ETAT_VALIDER
                            }
                        >
                            <CustomButtonLoader
                                type="button"
                                className="btn-danger ms-5"
                                onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                                text="Annuler"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                            />
                        </ConditionalRenderingWrapper>
                        <UserServiceWrapper
                            services={[
                                CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                                    .ATTENTE_VALIDATION_REGULARISATION_CAISSE
                            ]}
                        >
                            <ConditionalRenderingWrapper
                                isShouldBeRendered={
                                    entity?.etat === ETAT.ETAT_ENCOURS
                                }
                            >
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-warning ms-5"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                    }
                                    text="Attente Validation"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ATTENTE_VALIDATION
                                    }
                                />
                            </ConditionalRenderingWrapper>
                        </UserServiceWrapper>
                        <UserServiceWrapper
                            services={[
                                CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                                    .VALIDER_REGULARISATION_CAISSE
                            ]}
                        >
                            <ConditionalRenderingWrapper
                                isShouldBeRendered={
                                    entity?.etat ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            >
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success ms-5"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_VALIDER)
                                    }
                                    text="Valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                />
                            </ConditionalRenderingWrapper>
                        </UserServiceWrapper>
                    </div>
                </ConditionalRenderingWrapper>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
