import { AiOutlineStock } from 'react-icons/ai';
import { BsDot, BsFillKanbanFill } from 'react-icons/bs';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';
import { GiTakeMyMoney } from 'react-icons/gi';
import { GrMoney } from 'react-icons/gr';
import { getNumberBetweenMinMax } from '../../util';
import { RefetchButton } from '../button';

/**
 * Construit un composant card avec une icone, et un text affiche sur le haut, une value au centre format par la fnction formatNumber et un texte alternatif affiché en bas ) droite
 * @param {Object} param0 Objet avec les paramètres suivants :
 * @param {String} param0.text Texte décrivant la valeur affichée
 * @param {String} param0.textAlternatif Texte alternatif à afficher en bas de la carte
 * @param {Number} param0.value valeur numérique à afficher
 * @param {Number} param0.nombreApresVirgule nombre de rang après la virgule
 * @param {String} param0.devise devise, si jamais la valeur est exprimée en une devise donnée
 * @param {Boolean} param0.isCompact s'il faut afficher la value en notation compact ou pas
 * @param {String} param0.type prend une des valeurs suivantes : ['success', 'warning', 'danger', 'primary', 'secondary', 'white', 'dark', 'muted']
 * @param {Number} param0.icon numéro de l'icone à sélectionner dans l'objet incluants les icones disponibles
 * @param {Number} param0.typeNumber numéro du type à sélectionner dans l'objet incluants les types disponibles
 * @param {Function} param0.refetchFunction function à appeler par l'icone refetch
 * @param {Boolean} param0.isDisabledRefetch détermine si le fetch est en cours ou pas
 * @returns {React.Component}
 */

export function RapportActiviteCard({
    // element,
    devise = '',
    // id = '',
    text = '',
    type, // success, warning, danger, primary, secondary, white, dark, muted
    icon = 0,
    refetchFunction,
    isDisabledRefetch = false,
    typeNumber = 1,
    // marchand,
    client,
    // etat,
    superDealer,
    detailToShow
    // isOther
}) {
    const availableType = {
        1: 'warning',
        2: 'success',
        3: 'danger',
        4: 'primary',
        5: 'secondary',
        6: 'white',
        7: 'dark',
        8: 'muted'
    };
    const typeToUse = type || availableType[getNumberBetweenMinMax(typeNumber)];
    const availableIcons = {
        1: <BsFillKanbanFill className={`icon-custom text-${typeToUse}`} />,
        2: <FaPlusCircle className={`icon-custom text-${typeToUse}`} />,
        3: <FaMinusCircle className={`icon-custom text-${typeToUse}`} />,
        4: <AiOutlineStock className={`icon-custom text-${typeToUse}`} />,
        5: <IoMdRefresh className={`icon-custom text-${typeToUse}`} />,
        6: <GiTakeMyMoney className={`icon-custom text-${typeToUse}`} />,
        7: <GrMoney className={`icon-custom text-${typeToUse}`} />
    };

    const { object, properties } = detailToShow;

    return (
        <>
            <div className="border rounded-top px-2 pt-2 shadow-sm">
                <div className="lead d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div>{availableIcons?.[icon]}</div>
                        <div>
                            {' '}
                            {text ? <span className="me-1">{text}</span> : ''}
                        </div>
                    </div>
                    {typeof refetchFunction === 'function' && (
                        <div>
                            <RefetchButton
                                refetchFunction={refetchFunction}
                                disabled={isDisabledRefetch}
                            />
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="d-flex justify-content-between align-items-center">
                        {properties.map((item) => (
                            <div
                                className={`col-${
                                    12 / properties.length
                                } mb-2 text-center border rounded-top px-5 pt-2 bg-secondary`}
                                key={item}
                            >
                                <div className="col-12 pb-2">
                                    <div>{item}</div>
                                    <div>
                                        {
                                            object?.[item]?.devises?.[devise]
                                                ?.montant
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {superDealer ? (
                    <div
                        className={`fs-9 text-end fw-lighter text-muted text-${typeToUse}`}
                    >
                        <BsDot /> <span className="">{superDealer}</span>
                    </div>
                ) : null}
                {client ? (
                    <div
                        className={`fs-9 text-end fw-lighter text-muted text-${typeToUse}`}
                    >
                        <BsDot />
                        <span className="">{client}</span>
                    </div>
                ) : null}
            </div>
            <div
                className={`border-bottom border-${typeToUse} rounded-bottom border-5`}
            />
        </>
    );
}
