import * as yup from 'yup';

/**
 * @description : Schema de declaration ressource form
 */
export const DerogationSchema = yup.object().shape({
    codeRevendeur: yup.string().required(),
    selectedZone: yup.object().shape({
        reference: yup.string(),
        designation: yup.string()
    }),
    selectedZoneDropDown: yup.object().shape({
        reference: yup.string(),
        designation: yup.string()
    }),
    preview: yup.string(),
    zones: yup.array().of(
        yup
            .object({
                reference: yup.string(),
                designation: yup.string()
            })
            .shape({})
    )
});
