import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    BUDGET_ELABORATION,
    TYPE_BUDGET_PREVISIONNEL,
    RESSOURCE,
    fromTimestampToString,
    getPeriodesOfYear,
    TIME,
    TYPE_BUDGET_ADDITIONNEL
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomLabel,
    CustomAddRemoveButton,
    SimpleTable,
    FormWrapper,
    CustomAlert,
    CustomCollapse,
    ConditionalRenderingWrapper,
    CustomToast
} from '../../../../../components';
import { URL_CONST, useDebounce } from '../../../../../util';
import { REDUX_NODE_NAME } from '../../../../../redux';
import { ElaborationChargeRessourceFormSchema as uiValidator } from '../validation';
import { formatRessourceByPeriodesRefactored } from '../../../budget-previsionnel/util';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedDirection: {},
    selectedSecteur: {},
    selectedRessource: {},
    modePaiement: {},
    tauxCroissance: 0,
    datePaiement: '',
    list: [],
    target: 0,
    listWithTarget: [],
    ressourceElaboration: [],
    targetByDevise: [],
    selectedDevise: {},
    selectedMonth: {},
    previewValue: ''
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: [/* 'directions', */ 'secteurs', 'ressources', 'exercicesFinancier']
};

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['deviseSecteur'];

/**
 * Principale vue des vues pour les Ressources
 * @param {Object} param0 : prends en compte toutes les props possibles
 * @param {String} param0.typeBudget: qui sera du type 'Elaboration | Completion | Ammendement'
 * @param {String} param0.isAdditionnel: qui sera du type true | False, pour savoir si c'est un prévisionnel ou un additionnel
 * @returns
 */
export function BudgetRessources({
    isAdditionnel = false,
    isCompletion = false,
    isAmendement = false,
    idEntity,
    payloadUrl = {}
}) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        ressources,
        etatRessource,
        etatSecteurs,
        exerciceFinancier,
        etatExerciceFinancier,
        secteursKinDB // uniquement les directions commerciales
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        ressources: reduxState.ressources,
        etatRessource: reduxState.etat.ressources.etat,
        etatSecteurs: reduxState.etat.secteursRessourcesKindb.etat,
        secteursKinDB: reduxState.secteursRessourcesKindb,
        exerciceFinancier: reduxState.exerciceFinancier.map((item) => ({
            ...item,
            designation: `${fromTimestampToString(
                item.dateDebut
            )} - ${fromTimestampToString(item.dateFin)}`
        })),
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_RESSOURCE,
                nodeName: DB_NODE.RESSOURCE,
                etat: etatRessource
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_RESSOURCE_KIN_DB,
                nodeName: REDUX_NODE_NAME.SECTEUR_RESSOURCE_KINDB,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
            }
        ]
    });

    const exercices = exerciceFinancier.map((item) => ({
        ...item,
        designation: `${TIME.fromUtcTimestampToLocalString(
            item.dateDebut
        )} - ${TIME.fromUtcTimestampToLocalString(item.dateFin)}`
    }));

    const targetedExercice = exercices.find(
        (item) => item.id === fields?.selectedExercice.id
    );

    const periodes =
        getPeriodesOfYear({
            startOf: targetedExercice?.dateDebut || payloadUrl?.start,
            endOf: targetedExercice?.dateFin || payloadUrl?.end
        }).map((item) => ({
            code: item.dateDebut,
            designation: item.mois,
            ...item
        })) || [];

    const targetedMonth = periodes.length
        ? periodes.find(
              (item) => item.designation === fields?.selectedMonth?.designation
          )
        : {};

    const checkoutTargetRessource = (cible) => {
        const targetedRessources = ressources.find(
            (item) => item.code === cible.code
        );
        if (targetedRessources && targetedRessources.estObjectif) {
            return true;
        }
        return false;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const list = formatRessourceByPeriodesRefactored(
            fields.ressourceElaboration
        );
        const payloadToAddOut = {};
        if (isCompletion || isAmendement) {
            payloadToAddOut.idElaborationAmendement = idEntity;
        }
        const payload = {
            periode: {
                dateDebut: targetedExercice?.dateDebut || payloadUrl?.start,
                dateFin: targetedExercice?.dateFin || payloadUrl?.end
            },
            newElaborationAmendement: {
                typeEntite: BUDGET_ELABORATION,
                typeProprietaire: RESSOURCE.code,
                type: isAdditionnel
                    ? TYPE_BUDGET_ADDITIONNEL.code
                    : TYPE_BUDGET_PREVISIONNEL.code
            },
            creator,
            list,
            ...payloadToAddOut
        };
        // console.log(JSON.stringify(payload));
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    /**
     * @description action pour rajouter le partenaires et le montant à payer
     */
    const addBeneficiaire = () => {
        if (!fields.selectedRessource.code || !fields.tauxCroissance) return;
        const data = {
            id: fields.selectedRessource.code,
            ressource: fields.selectedRessource.code,
            designation: fields.selectedRessource.designation,
            // element: {
            //     designation: fields.selectedRessource.designation,
            //     code: fields.selectedRessource.code
            // },
            tauxCroissance: fields.tauxCroissance
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'ressourceElaboration',
                data
            }
        });
    };

    /**
     * @description action pour rajouter les objectifs par devise
     */
    const addTargetDevise = () => {
        if (!fields.selectedDevise.code || !fields.target) return;
        const data = {
            devise: fields.selectedDevise.code,
            target: fields.target
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'devise',
                field: 'targetByDevise',
                data
            }
        });
    };

    // /**
    //  * @description fonction pour fetcher les beneficiaires
    //  */
    // const onSearchDeviseSecteur = () => {
    //     const { status: etat } = elements.deviseSecteur;
    //     formDispatcher(
    //         fetchElement({
    //             idToken,
    //             functionName:
    //                 URL_CONST.GET_LIST_MONNAIE_LOCALE_MARCHAND /** @desc : A changer */,
    //             elementPropertyName: 'deviseSecteur',
    //             etat,
    //             payload: {
    //                 codeMarchand: fields.selectedSecteur?.code
    //             },
    //             reduxDispatcher
    //         })
    //     );
    // };
    /**
     * Debounce
     */
    useDebounce({
        name: 'selectedSecteur',
        preview: 'previewValue',
        propertyInName: 'code',
        isObject: true,
        formDispatcher,
        formState,
        delay: 1500,
        idToken,
        functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE_MARCHAND,
        elementPropertyName: 'deviseSecteur',
        payloadToAdd: {
            codeMarchand: fields.selectedSecteur?.code
        },
        dataLength: 0
    });

    /**
     * @description action pour rajouter les partenaires et targets dans le cas des Marchand
     */

    const addBeneficiaireAndTarget = () => {
        if (
            !targetedMonth.dateDebut ||
            !targetedMonth.dateFin ||
            !fields.targetByDevise.length
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Vérifiez les informations liées à la période et à l'objectif`,
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const data = {
            id: `${targetedMonth.dateDebut}_${targetedMonth.dateFin}`,
            dateDebut: targetedMonth.dateDebut,
            dateFin: targetedMonth.dateFin,
            mois: targetedMonth.designation,
            devises: fields.targetByDevise
        };

        // formDispatcher({
        //     type: FORM_CONST.ADD_ITEM,
        //     payload: {
        //         identifier: 'id',
        //         field: 'listWithTarget',
        //         data
        //     }
        // });

        formDispatcher({
            type: FORM_CONST.REINITIALISER,
            payload: {
                initialState: {
                    ...formState,
                    fields: {
                        ...formState.fields,
                        targetByDevise: [],
                        listWithTarget: fields.listWithTarget
                            .filter((item) => item.id !== data.id)
                            .concat(data)
                    }
                }
            }
        });
    };

    /**
     * @description action pour rajouter les elements qui partage le même secteur
     */
    const addEntireRessourceElaboration = () => {
        const propertyToAdd = {};
        if (fields.listWithTarget.length) {
            propertyToAdd.periodes = fields.listWithTarget.map(
                ({ dateDebut, dateFin, mois, devises }) => ({
                    dateDebut,
                    dateFin,
                    mois,
                    devises
                })
            );
        }
        const data = {
            id: `${fields.selectedRessource.code}_${
                fields.selectedSecteur.code
            }_${targetedMonth?.dateDebut}_${
                targetedMonth?.dateFin
            }_${JSON.stringify(fields.listWithTarget)}`,
            secteur: {
                code: fields.selectedSecteur.code,
                designation: fields.selectedSecteur.designation
            },
            ressource: fields.selectedRessource.code,
            designation: fields.selectedRessource.designation,
            ...propertyToAdd
        };
        if (
            (checkoutTargetRessource(fields.selectedRessource) &&
                !fields.selectedSecteur.code) ||
            !propertyToAdd.periodes.length
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Vérifiez les informations du formulaire`,
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }

        // formDispatcher({
        //     type: FORM_CONST.ADD_ITEM,
        //     payload: {
        //         identifier: 'id',
        //         field: 'ressourceElaboration',
        //         data
        //     }
        // });

        formDispatcher({
            type: FORM_CONST.REINITIALISER,
            payload: {
                initialState: {
                    ...formState,
                    fields: {
                        ...formState.fields,
                        listWithTarget: [],
                        targetByDevise: [],
                        selectedSecteur: {},
                        selectedCaisseSectorielle: {},
                        modePaiement: {},
                        tauxCroissance: 0,
                        datePaiement: '',
                        target: 0,
                        isPrevisionnel: fields.isPrevisionnel,
                        ressourceElaboration: fields.ressourceElaboration
                            .filter((item) => item.id !== data.id)
                            .concat(data)
                    }
                }
            }
        });
    };

    const renderContent = () => (
        <FormWrapper
            isRedirect={!isCompletion && !isAmendement}
            onSubmit={handleSubmit}
            isStepBack={isCompletion || isAmendement}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'ressources',
                    etat: etatRessource
                },
                {
                    dependency: 'exercicesFinancier',
                    etat: etatExerciceFinancier
                }
            ]}
        >
            <ConditionalRenderingWrapper
                isShouldBeRendered={isCompletion || isAmendement}
            >
                <div className="alert alert-warning" role="alert">
                    Cette opération apportera des ajouts au présent budget!
                    <hr />
                    <div>
                        <span className="">Exercice :</span>{' '}
                        <span>
                            Du {fromTimestampToString(payloadUrl.start)} au{' '}
                            {fromTimestampToString(payloadUrl.end)}
                        </span>
                    </div>
                </div>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!isCompletion && !isAmendement}
            >
                {form && form.status === FORM_CONST.CREATION_SUCCESS && (
                    <CustomToast
                        successMessage="Modification Effectuée"
                        status={form.status}
                    />
                )}
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!isCompletion && !isAmendement}
            >
                <CustomRow>
                    <CustomDropdown
                        options={exerciceFinancier}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner un exercice"
                        label="Exercice*"
                        labelClassName="col-12 col-sm-3"
                        divClassName="col-12 col-sm-9"
                        name="selectedExercice"
                        id="selectedExerciceElaborationRessourceId"
                        idProperty="id"
                        uiValidator={uiValidator}
                        resetOnChangeState
                        newState={{
                            ressourceElaboration: fields.ressourceElaboration,
                            isPrevisionnel: fields.isPrevisionnel,
                            selectedDirection: {},
                            selectedSecteur: {},
                            selectedRessource: {},
                            selectedCaisseSectorielle: {},
                            modePaiement: {},
                            tauxCroissance: 0,
                            datePaiement: '',
                            list: [],
                            target: 0,
                            listWithTarget: [],
                            targetByDevise: [],
                            selectedDevise: {},
                            selectedMonth: {}
                        }}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomLabel text="Ressource*" className="col-sm-3" />
                <CustomDropdown
                    options={ressources}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la ressource"
                    divClassName={`col-12 ${
                        !checkoutTargetRessource(fields.selectedRessource)
                            ? 'col-sm-4'
                            : 'col-sm-9'
                    }`}
                    name="selectedRessource"
                    id="selectedRessourceId"
                    uiValidator={uiValidator}
                    resetOnChangeState
                    newState={{
                        // ...initialState.fields,
                        selectedDirection: {},
                        selectedSecteur: {},
                        selectedCaisseSectorielle: {},
                        modePaiement: {},
                        tauxCroissance: 0,
                        datePaiement: '',
                        list: [],
                        selectedDevise: {},
                        targetByDevise: [],
                        listWithTarget: [],
                        ressourceElaboration: fields.ressourceElaboration,
                        selectedExercice: fields.selectedExercice
                    }}
                />
                {!checkoutTargetRessource(fields.selectedRessource) && (
                    <>
                        <CustomInput
                            isInputGroup={false}
                            divClassName="col-12 col-sm-4"
                            placeholder="Saisissez le taux de croissance..."
                            formDispatcher={formDispatcher}
                            name="tauxCroissance"
                            type="number"
                            id="tauxCroissanceId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                        <CustomAddRemoveButton
                            className="col-sm-1"
                            callback={addBeneficiaire}
                        />
                    </>
                )}
            </CustomRow>
            {checkoutTargetRessource(fields.selectedRessource) && (
                <>
                    <CustomRow>
                        <CustomDropdown
                            options={secteursKinDB}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner le secteur"
                            labelClassName="col-12 col-sm-3"
                            divClassName="col-12 col-sm-9"
                            label="Secteur*"
                            name="selectedSecteur"
                            id="selectedSecteurId"
                            disabled={!secteursKinDB?.length}
                            uiValidator={uiValidator}
                            resetOnChangeState
                            newState={{
                                ...initialState.fields,
                                selectedCaisseSectorielle: {},
                                modePaiement: {},
                                tauxCroissance: 0,
                                datePaiement: '',
                                list: [],
                                selectedDevise: {},
                                targetByDevise: [],
                                listWithTarget: [],
                                selectedMonth: {},
                                isPrevisionnel: fields.isPrevisionnel,
                                selectedDirection: fields.selectedDirection,
                                selectedRessource: fields.selectedRessource,
                                ressourceElaboration:
                                    fields.ressourceElaboration,
                                selectedExercice: fields.selectedExercice
                            }}
                        />
                        {/* <CustomButtonLoader
                            isActionEncours={
                                elements?.deviseSecteur?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            disabled={
                                elements?.deviseSecteur?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            type="button"
                            className="btn-primary col-sm-2"
                            onClick={onSearchDeviseSecteur}
                            text="Recherche Devise"
                        /> */}
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            isSort={false}
                            options={periodes}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            label="Périodes*"
                            defaultOption="Sélectionner le mois"
                            labelClassName="col-12 col-sm-3"
                            divClassName="col-12 col-sm-9"
                            name="selectedMonth"
                            id="selectedMonthId"
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomLabel text="Objectif*" className="col-sm-3" />
                        <CustomInput
                            isInputGroup={false}
                            divClassName="col-12 col-sm-3"
                            placeholder="Target ou Objectif..."
                            formDispatcher={formDispatcher}
                            name="target"
                            type="number"
                            id="targetParSecteurId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />{' '}
                        {elements?.deviseSecteur?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                            <p className="placeholder-lg placeholder-wave col-sm-3 col-12">
                                <span className="placeholder col-12" />
                            </p>
                        ) : null}
                        {elements?.deviseSecteur?.status ===
                            FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                        !elements?.deviseSecteur?.value?.length ? (
                            <CustomAlert successMessage="Ce marchand ne possède aucune devise" />
                        ) : null}
                        {elements?.deviseSecteur?.status ===
                            FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                        !!elements?.deviseSecteur?.value?.length ? (
                            <CustomDropdown
                                options={elements?.deviseSecteur?.value || []}
                                formDispatcher={formDispatcher}
                                formState={formState}
                                defaultOption="Sélectionnez la devise"
                                divClassName="col-12 col-sm-3"
                                name="selectedDevise"
                                id="selectedDeviseId"
                                propertyToDisplay="devise"
                            />
                        ) : null}
                        <CustomAddRemoveButton
                            className="col-sm-1"
                            callback={addTargetDevise}
                        />
                    </CustomRow>
                    <CustomCollapse isIn={!!fields.targetByDevise.length}>
                        <SimpleTable
                            identifier="devise"
                            formDispatcher={formDispatcher}
                            data={fields.targetByDevise}
                            isShowDropButton
                            listSelectedItemName="targetByDevise"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    displayName: 'devise',
                                    field: 'devise'
                                },
                                {
                                    position: 2,
                                    displayName: 'target',
                                    field: 'target'
                                }
                            ]}
                        />
                    </CustomCollapse>
                    <CustomRow>
                        <CustomButtonLoader
                            type="button"
                            className="offset-sm-10 col-sm-2 btn-warning"
                            text="Ajouter période et objectif"
                            onClick={addBeneficiaireAndTarget}
                        />
                    </CustomRow>
                    <CustomCollapse isIn={!!fields.listWithTarget.length}>
                        <SimpleTable
                            identifier="id"
                            formDispatcher={formDispatcher}
                            data={fields.listWithTarget}
                            isShowDropButton
                            listSelectedItemName="listWithTarget"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    displayName: 'Periode',
                                    field: 'mois'
                                },
                                {
                                    displayName: 'debut',
                                    field: 'dateDebut',
                                    isDate: true
                                },
                                {
                                    displayName: 'Fin',
                                    field: 'dateFin',
                                    isDate: true
                                }
                            ]}
                        />
                    </CustomCollapse>
                    <div className="d-flex flex-row-reverse mb-3">
                        <CustomButtonLoader
                            type="button"
                            className="btn-dark"
                            onClick={addEntireRessourceElaboration}
                            text="Valider toutes ces informations?"
                        />
                    </div>
                </>
            )}
            <CustomCollapse isIn={!!fields.ressourceElaboration.length}>
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.ressourceElaboration}
                    isShowDropButton
                    listSelectedItemName="ressourceElaboration"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            displayName: 'Ressource',
                            field: 'designation'
                        },
                        {
                            displayName: 'Secteur',
                            field: 'secteur.designation'
                        },
                        {
                            displayName: 'Taux',
                            field: 'tauxCroissance'
                        }
                    ]}
                />
            </CustomCollapse>
            {/* <StateShowerComponent state={fields} /> */}
            <div className="mt-3">
                <CustomButtonLoader
                    className="btn-success"
                    onClick={handleSubmit}
                    text="Soumettre elaboration"
                    disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                />
            </div>
        </FormWrapper>
    );
    return renderContent();
    // return (
    //     <div>
    //         {isAdditionnel ? 'Additionnel' : null} Ressources {typeBudget}
    //     </div>
    // );
}
