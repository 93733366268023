import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    ADMINISTRATION_CONST,
    CODE_SERVICE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useHasService } from '../../../util';

const setDefaultProperties = ({ type, service, oid }) => {
    // DefaultMapper
    const defaultMapper = [
        {
            field: 'valeurTypeOrganisation.designation',
            displayName: 'Organisation'
        },
        {
            field: 'compteA.type',
            dataExtractor: (item) =>
                item?.compteA?.type === ADMINISTRATION_CONST.NAPP
                    ? 'Balance'
                    : item?.compteA?.type,
            displayName: 'Source'
        },
        {
            field: 'compteB.type',
            dataExtractor: (item) =>
                item?.compteB?.type === ADMINISTRATION_CONST.NAPP
                    ? 'Balance'
                    : item?.compteA?.type,
            displayName: 'Bénéficiaire'
        },
        {
            displayName: 'Montant',
            field: 'montant'
        },
        {
            displayName: 'Devise',
            field: 'devise'
        },
        {
            displayName: 'Etat',
            field: 'etat'
        },
        {
            displayName: 'Création',
            field: 'dateCreation'
        }
    ];

    // DefaultListerners
    let filters = [];
    if (
        (service[
            CODE_SERVICE.FINANCE_ORGANISATION.CREATION_TRANSFERT_BALANCE.code
        ] ||
            service[
                CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_TRANSFERT_BALANCE.code
            ]) &&
        type !== ADMINISTRATION_CONST.NAPP
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'typeOrganisation',
                value: type,
                isRequired: true
            },
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'valeurTypeOrganisation.reference',
                value: oid,
                isRequired: true
            }
        ]);
    } else if (
        (service[
            CODE_SERVICE.FINANCE_ORGANISATION.CREATION_TRANSFERT_BALANCE.code
        ] ||
            service[
                CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_TRANSFERT_BALANCE.code
            ]) &&
        type === ADMINISTRATION_CONST.NAPP
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'estKinDistribution',
                value: true,
                isRequired: true
            }
        ]);
    }

    if (
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.CREATION_TRANSFERT_BALANCE.code
        ] ||
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_TRANSFERT_BALANCE.code
        ]
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.ORDER_BY,
                property: 'dateDerniereModification',
                value: 'asc'
            },
            {
                type: FILTER_TYPE.START_AT,
                value: TODAY.nowUtc(),
                isTimestampNowValue: true
            }
        ]);
    }
    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    return { mapper: [], listerners: [] };
};

export function ListCrediterBalanceCautionForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, organisation } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation
    }));
    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_TRANSFERT_BALANCE,
            CODE_SERVICE.FINANCE_ORGANISATION.CREATION_TRANSFERT_BALANCE,
            CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_TRANSFERT_BALANCE
        ]
    });
    /**
     * Common form processes
     */
    const { oid, typeEntite } = useGetUserDetail({
        organisation,
        nappUser
    });
    const { mapper, listerners } = setDefaultProperties({
        type: typeEntite,
        oid,
        reference: nappUser?.valeurTypeEntite?.reference,
        service: hasService
    });
    return (
        <ListGenericForm
            fetchType={FETCH_LISTENING}
            path={DB_NODE.TRANSFERT_BALANCE}
            reduxPropertyName="transfertBalanceCaution"
            functionName={URL_CONST.GET_LIST_TRANSFERT_BALANCE_INITIALS}
            nodeName={REDUX_NODE_NAME.TRANSFERT_BALANCE_CAUTION}
            mapper={mapper}
            listenerFilters={listerners}
        />
    );
}
