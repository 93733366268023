import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListRegularisationStockForm() {
    return (
        <ListGenericForm
            reduxPropertyName="regularisationsStock"
            functionName={URL_CONST.GET_LIST_REGULARISATION_STOCK_INITIALS}
            nodeName={DB_NODE.REGULARISATION_STOCK}
            mapper={[
                {
                    field: 'id',
                    displayName: 'id',
                    position: 1
                },
                {
                    field: 'produit.designation',
                    displayName: 'Produit',
                    position: 2
                },
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'agence',
                    position: 3
                },
                {
                    field: 'etat',
                    displayName: 'Etat',
                    position: 4
                }
            ]}
        />
    );
}
