import {
    fetchElement,
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

const defaultFields = {
    tillNumber: ''
};

const defaultElements = ['revendeur'];

export function CreationGestionRevendeurForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            newStatusRevendeur: {
                revendeur: { code: elements?.revendeur?.value?.code },
                estNonCommission: !elements?.revendeur?.value?.estNonCommission
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_STATUS_REVENDEUR,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.STATUS_REVENDEUR,
                reduxDispatcher
            })
        );
    };
    const onSearchRevendeur = () => {
        const { status: etat } = elements.revendeur;
        const { tillNumber } = fields;
        if (
            tillNumber &&
            tillNumber !== elements.revendeur?.value?.tillNumber
        ) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'revendeur',
                    payload: { tillNumber },
                    functionName: URL_CONST.GET_REVENDEUR_BY_TILL_NUMBER,
                    etat,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };
    const renderContent = () => (
        <FormWrapper formState={formState} formDispatcher={formDispatcher}>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Till number*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-10 col-sm-8"
                    isInputGroup={false}
                    placeholder="Saisissez le tillNumber"
                    formDispatcher={formDispatcher}
                    name="tillNumber"
                    id="tillNumberCreationGestionRevendeurId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
                <CustomCol className="col-2">
                    <CustomButtonLoader
                        className="col-12"
                        type="button"
                        disabled={
                            elements.revendeur.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        isActionEncours={
                            elements.revendeur.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        onClick={onSearchRevendeur}
                        text="Rechercher"
                    />
                </CustomCol>
            </CustomRow>
            {elements.revendeur.status === FORM_CONST.FETCH_ELEMENT_FAILED && (
                <CustomRow>
                    <CustomCol className="offset-md-2 col-12 col-md-10">
                        <CustomAlert
                            error={`${elements.revendeur?.error || ''}`}
                        />
                    </CustomCol>
                </CustomRow>
            )}
            {elements.revendeur.status === FORM_CONST.FETCH_ELEMENT_SUCCESS && (
                <CustomRow>
                    <CustomCol className="offset-md-2 col-12 col-md-10">
                        <CustomAlert
                            successMessage={`${
                                elements.revendeur?.value?.tillNumber || ''
                            } - ${
                                elements.revendeur?.value?.designation || ''
                            }`}
                        />
                    </CustomCol>
                </CustomRow>
            )}
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text={`${
                            elements?.revendeur?.value?.estNonCommission
                                ? 'Activer Commission'
                                : 'Désactiver Commission'
                        } `}
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
