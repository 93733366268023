import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    ETAT,
    FORM_CONST,
    URL_CONST
    // ApprovisionnementMonnaieSchema
} from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper
} from '../../../components';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailProduitMarchandForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({ nodeName: 'produits' });

    const { fields, form } = formState;

    const { estDesactive } = entity || {};

    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'isActionEncours',
                data: etat
            }
        });
        const payload = {
            etat,
            creator,
            idProduit: entity?.id
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.POST_FAKE,
                payload,
                fields,
                reduxNodeName: DB_NODE.PRODUIT,
                // reduxValidator: ApprovisionnementMonnaieSchema,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_PRODUIT_BY_ID}
            nodeName={DB_NODE.PRODUIT}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Produit Marchand"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <CustomRow>
                {!estDesactive ? (
                    <CustomCol className="offset-9 offset-md-10 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                ) : (
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Desactiver"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                )}
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
