import * as yup from 'yup';

/**
 * @description : Schema de Detail du Point de Livraison création form
 */
export const DetailPointLivraisonSchema = yup.object().shape({
    actionEncours: yup.string().required(),
    selectedPointLivraison: yup.string().required(),
    selectedAgentLivreur: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    })
});
