import { DetailWrapper } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetDetailForm } from '../../../util';

export function DetailClientOrganisationForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'clientsOrganisation'
        });
    return (
        <DetailWrapper
            functionName={URL_CONST.GET_COMPTE_BY_ID}
            nodeName={REDUX_NODE_NAME.CLIENT_ORGANISATION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Client"
            entity={entity}
            id={id}
            schema={[
                {
                    title: 'Client',
                    callback: (item) =>
                        `${item?.valeurTypeProprietaire?.designation || ''}-${
                            item?.valeurTypeProprietaire?.prenom || ''
                        } ${item?.valeurTypeProprietaire?.nom || ''}`,
                    className: 'border-bottom'
                },
                {
                    title: 'Organisation',
                    description: 'valeurTypeOrganisation.designation',
                    className: 'border-bottom'
                },
                {
                    title: 'Numero',
                    description: 'numero',
                    className: 'border-bottom'
                },
                {
                    title: 'Création',
                    description: 'dateCreation',
                    className: 'border-bottom'
                }
            ]}
            idToken={idToken}
        />
    );
}
