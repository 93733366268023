import { useDispatch, useSelector } from 'react-redux';
import {
    TIME,
    TODAY,
    FILTER_TYPE,
    FILTER_OPERATOR
} from '@napp-inc/jnapp-util';
import { useGetUserDetail, useFetchAndListening } from '@napp-inc/jnapp-hook';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetReportDetail } from '../../../util';
import { ReduxReportTemplate } from '../../report/template';
import { MarchandSelector } from '../../../components';

export function ListFormulaireDynamiqueSimFlashForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, ecouteurFormulaireDynamiqueSimFlash } = useSelector(
        (reduxState) => ({
            nappUser: reduxState.nappUser,
            firebaseUser: reduxState.firebaseUser,
            ecouteurFormulaireDynamiqueSimFlash:
                reduxState.etat.formulaireDynamiqueSimFlash.ecouteur
        })
    );
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedMarchand: {}
        }
    });
    const { fields } = formState;
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({
        firebaseUser
    });
    const onSuccess = (data) => {
        let items = [];
        if (data?.element) items = data?.element;
        if (data?.elements) items = data?.elements;
        if (Array.isArray(data)) items = data?.[0];

        // const items = data?.element || data?.elements || [];
        // reduxDispatcher(ajouterItems(items, nodeName));
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.resetTable(
                REDUX_NODE_NAME.FORMULAIRE_DYNAMIQUE_SIM_FLASH
            )
        );
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                items,
                REDUX_NODE_NAME.FORMULAIRE_DYNAMIQUE_SIM_FLASH
            )
        );
    };
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyListening: true,
                path: 'FormulaireDynamique',
                nodeName: REDUX_NODE_NAME.FORMULAIRE_DYNAMIQUE_SIM_FLASH,
                ecouteur: ecouteurFormulaireDynamiqueSimFlash,
                listenerFilters: [
                    {
                        property: 'marchand.code',
                        value: fields?.selectedMarchand?.code,
                        operator: FILTER_OPERATOR.EQUAL,
                        type: FILTER_TYPE.WHERE,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.ORDER_BY,
                        property: 'dateDerniereModification',
                        value: 'asc'
                    },
                    {
                        type: FILTER_TYPE.START_AT,
                        value: TODAY.nowUtc(),
                        isTimestampNowValue: true
                    }
                ]
                // etat: etatFormulaireDynamiqueSimFlash
            }
        ]
    });
    const functionFlattenObject = (datas) => {
        const newTable = [];
        // eslint-disable-next-line array-callback-return
        datas.map((item) => {
            const element = {};
            if (item?.datas?.length) {
                item.datas.forEach((el) => {
                    if (el.property === 'prenom') element.prenom = el.value;
                    if (el.property === 'postnom') element.postnom = el.value;
                    if (el.property === 'nom') element.nom = el.value;
                    if (el.property === 'numero') element.numero = el.value;
                });
            }
            newTable.push({
                ...element,
                secteur: 'LEMBA 2',
                zone: 'Mont-amba'
            });
        });

        return {
            dataList: newTable,
            mapper: [
                {
                    displayName: 'Nom',
                    field: 'nom',
                    Header: 'Nom',
                    accessor: 'nom'
                },
                {
                    displayName: 'Postnom',
                    field: 'postnom',
                    Header: 'Postnom',
                    accessor: 'postnom'
                },
                {
                    displayName: 'Prénom',
                    field: 'prenom',
                    Header: 'Prénom',
                    accessor: 'prenom'
                },
                {
                    displayName: 'Numéro',
                    field: 'numero',
                    Header: 'Numéro',
                    accessor: 'numero'
                },
                {
                    displayName: 'Secteur',
                    field: 'secteur',
                    Header: 'Secteur',
                    accessor: 'secteur'
                },
                {
                    displayName: 'Zone',
                    field: 'zone',
                    Header: 'Zone',
                    accessor: 'zone'
                }
            ]
        };
    };
    return (
        <ReduxReportTemplate
            isInitialFetch={false}
            listTitle=""
            isLinkToDetail
            onSuccess={onSuccess}
            transformExportDataFunction={functionFlattenObject}
            // elementPropertyName="notMnt"
            reduxPropertyName="formulaireDynamiqueSimFlash"
            functionName={URL_CONST.GET_LIST_FORMULAIRE_DYNAMIQUE_INITIALS}
            nodeName={REDUX_NODE_NAME.FORMULAIRE_DYNAMIQUE_SIM_FLASH}
            // dynamicNodeName={`formulaireDynamiqueSimFlash-${TIME.utc000000(
            //     TODAY.at000000()
            // )}-${TIME.utc235959(TODAY.at235959())}`}
            formState={formState}
            formDispatcher={formDispatcher}
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                codeMarchand: fields?.selectedMarchand?.code,
                property: 'dateDerniereModification'
            }}
            mapper={[
                {
                    displayName: 'Categorie',
                    field: 'categorie'
                },
                {
                    displayName: 'Rubrique',
                    field: 'rubrique'
                },
                {
                    displayName: 'Marchand',
                    field: 'marchand.designation'
                },
                {
                    displayName: 'Demandeur',
                    field: 'valeurTypeOrganisation.designation'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Libellé',
                    field: 'libelle'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                },
                {
                    displayName: 'Dernière Modification',
                    field: 'dateDerniereModification'
                }
            ]}
            // listFormater={(data) => {
            //     if (data?.length && data?.[0]?.length) return data?.[0];
            //     if (data?.length && !data?.[0]?.length) return [];
            //     return data;
            // }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedMarchand',
                    isRequired: false,
                    displayName: 'Marchand',
                    callback: (data) => {
                        if (!data?.code || data?.code === 'all') return {};
                        return { codeMarchand: data?.code };
                    }
                }
            ]}
        >
            <MarchandSelector
                formState={formState}
                formDispatcher={formDispatcher}
                isAddAditionalOptions
                estNonEmoney
                idProperty="code"
            />
        </ReduxReportTemplate>
    );
}
