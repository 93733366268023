import {
    submitPutAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';

const defaultFields = {
    selectedProduit: {},
    selectedColonne: {},
    list: []
};
const defaultDependencies = {
    fields: ['produits', 'colonnes']
};

export function ModifierColonneProduitAirtimeForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        produits,
        etatProduit,
        rayonsColonne,
        etatRayonsColonne
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        produits: reduxState.produits.filter(
            (item) => !item.emplacementPhysique
        ),
        etatProduit: reduxState.etat.produits.etat,
        rayonsColonne: reduxState.rayonsColonne,
        etatRayonsColonne: reduxState.etat.rayonsColonne.etat
    }));
    /**
     * Common form processes
     */
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PRODUIT_MARCHAND,
                nodeName: DB_NODE.PRODUIT,
                etat: etatProduit
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_RAYON_COLONNE_MARCHAND_AIRTIME,
                nodeName: DB_NODE.RAYON_COLONNE,
                etat: etatRayonsColonne
            }
        ]
    });
    /**
     * @description action pour rajouter l'emplacement
     */
    const addProduitColonne = () => {
        const data = {
            id: fields.selectedProduit.code,
            produit: fields.selectedProduit.code,
            code: fields.selectedColonne.code
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'produit',
                field: 'list',
                data
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!fields.list || !fields.list.length) return;
        const payload = {
            creator,
            list: fields.list.map((item) => ({
                code: item.code,
                codeProduit: item.produit
            }))
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_LIST_PRODUIT_RAYON,
                payload,
                fields,
                reduxNodeName: DB_NODE.PRODUIT,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'produits',
                    etat: etatProduit
                },
                {
                    dependency: 'colonnes',
                    etat: etatRayonsColonne
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={produits}
                    label="Produit*"
                    defaultOption="Selectionner produit"
                    divClassName="col-12 col-sm-4"
                    formDispatcher={formDispatcher}
                    name="selectedProduit"
                    id="selectedProduitCreationClient"
                    formState={formState}
                />
                <CustomDropdown
                    options={rayonsColonne}
                    divClassName="col-12 col-sm-4"
                    defaultOption="Selectionner Colonne"
                    formDispatcher={formDispatcher}
                    name="selectedColonne"
                    id="selectedColonneId"
                    formState={formState}
                />
                <CustomAddRemoveButton
                    className="col-sm-2"
                    callback={addProduitColonne}
                />
            </CustomRow>
            {fields.list.length ? (
                <CustomRow>
                    <SimpleTable
                        listSelectedItemName="list"
                        identifier="id"
                        formDispatcher={formDispatcher}
                        data={fields.list}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 1,
                                field: 'code',
                                displayName: 'Code colonne'
                            },
                            {
                                position: 2,
                                field: 'produit',
                                displayName: 'Code produit'
                            }
                        ]}
                    />
                </CustomRow>
            ) : null}
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
