import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    formInitialState,
    GroupeAbonneSchema,
    URL_CONST,
    FIELD_TYPE
} from '@napp-inc/jnapp-util';
import { Tab } from 'bootstrap';
import { Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomForm,
    CustomRow
} from '../../../components';
import { CreationGroupeAbonneSchema } from '../../../util';
import { ConfigApiComponent } from './config-api';
import { ConfigFieldsForm } from './config-fields';
// import { TestRootForm } from './test/root';
import { useAvailableForSelection } from './use-available';

const defaultFields = {
    selectedType: {},
    listChamps: [
        {
            id: 1653552908847,
            code: 'nom',
            designation: 'nom',
            description: 'nom du client',
            type: 'texte',
            isLongText: false,
            isRequired: true
        },
        {
            id: 1653552922997,
            code: 'age',
            designation: 'age',
            description: 'age du client',
            type: 'nombre',
            isFloat: false,
            nombreRangApresVirgule: 4
        }
    ],
    code: '',
    designation: '',
    description: '',
    isRequired: false,
    isLongText: false,
    isFloat: false,
    nombreRangApresVirgule: 4,
    optionRadio: '',
    listOptionsRadio: [],
    optionCheck: '',
    listOptionsCheck: [],
    isCheckDefaultChecked: false,
    isMultipleFiles: false,
    isRestrictedFiles: false,
    selectedTailleMax: { code: '10Mo', designation: '10 Mo' },
    selectedFileExtension: '',
    listSelectedFileExtension: [],
    selectedCorrespondanceType: {},
    url: '',
    selectedTypeApi: { code: 'rest', designation: 'REST' },
    selectedApiMethod: { code: 'post', designation: 'POST' },
    selectedApiataFormat: { code: 'json', designation: 'JSON' },
    activeTab: 'form',
    outputFormat: {},
    propertyList: [],
    selectedCurrentField: {},
    outputList: [
        {
            code: 'aa',
            designation: 'dsds'
        },
        {
            code: 'bbb',
            designation: 'dsds'
        },
        {
            code: 'cccc',
            designation: 'dsds'
        }
    ]
};

export function CreationConfigSimFlashForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const { availableForSelection } = useAvailableForSelection({
        listChamps: fields.listChamps,
        outputList: fields.outputList
    });
    const handleAjouterChamps = () => {
        const { selectedType } = fields;
        if (!selectedType || !selectedType.code) return;
        const params = {
            id: Date.now(),
            code: fields.code,
            designation: fields.designation,
            description: fields.description
        };
        if (selectedType?.code === FIELD_TYPE.TEXTE.code) {
            params.type = FIELD_TYPE.TEXTE.code;
            params.isLongText = fields.isLongText;
            params.isRequired = fields.isRequired;
        }
        if (selectedType?.code === FIELD_TYPE.NOMBRE.code) {
            params.type = FIELD_TYPE.NOMBRE.code;
            params.isFloat = fields.isFloat;
            params.nombreRangApresVirgule = fields.nombreRangApresVirgule;
        }
        if (selectedType?.code === FIELD_TYPE.RADIO.code) {
            params.type = FIELD_TYPE.RADIO.code;
            params.listOptionsRadio = fields.listOptionsRadio;
            if (!fields.listOptionsRadio.length) return;
        }
        if (selectedType?.code === FIELD_TYPE.CHECKBOX.code) {
            params.type = FIELD_TYPE.CHECKBOX.code;
            params.listOptionsCheck = fields.listOptionsCheck;
            if (!fields.listOptionsCheck.length) return;
        }
        if (selectedType?.code === FIELD_TYPE.HEURE.code) {
            params.type = FIELD_TYPE.HEURE.code;
        }
        if (selectedType?.code === FIELD_TYPE.DATE.code) {
            params.type = FIELD_TYPE.DATE.code;
        }
        if (selectedType?.code === FIELD_TYPE.FILE.code) {
            params.type = FIELD_TYPE.FILE.code;
            params.isMultipleFiles = fields.isMultipleFiles;
            params.isRestrictedFiles = fields.isRestrictedFiles;
            params.selectedTailleMax = fields.selectedTailleMax;
            if (fields.isRestrictedFiles) {
                params.extensions = fields.listSelectedFileExtension;
            }
        }
        if (selectedType?.code === FIELD_TYPE.LISTE.code) {
            params.type = FIELD_TYPE.LISTE.code;
            params.listOptionsRadio = fields.listOptionsRadio;
            if (!fields.listOptionsRadio.length) return;
        }
        if (selectedType?.code === FIELD_TYPE.CORRESPONDANCE.code) {
            params.type = FIELD_TYPE.CORRESPONDANCE.code;
            params.correspondance = fields.selectedCorrespondanceType;
        }
        if (!params.code || !params.designation) return;
        const newState = {
            ...initialState,
            fields: {
                ...initialState.fields,
                listChamps: fields.listChamps,
                selectedType: fields.selectedType
            }
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
            payload: {
                newState,
                field: 'listChamps',
                data: params,
                identifier: 'code'
            }
        });
    };
    const handleAddOutputField = () => {
        const { selectedCurrentField } = fields;
        if (!selectedCurrentField || !selectedCurrentField.code) return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'outputList',
                identifier: 'code',
                data: selectedCurrentField
            }
        });
    };
    const removeItemOnOutputList = (id) => {
        formDispatcher({
            type: FORM_CONST.REMOVE_ITEM,
            payload: {
                identifier: 'code',
                field: 'outputList',
                data: { code: id }
            }
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            name: fields.designation,
            abonnements: fields.listAbonne
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_FAKE,
                payload,
                uiValidator: CreationGroupeAbonneSchema,
                fields,
                reduxNodeName: DB_NODE.PRODUIT,
                reduxDispatcher,
                reduxValidator: GroupeAbonneSchema
            })
        );
    };
    return (
        <>
            <CustomAlert error={form.error} />
            <CustomForm onSubmit={handleSubmit} className="py-2">
                <Tabs
                    defaultActiveKey="form"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="form" title="Formulaire (1)">
                        <ConfigFieldsForm
                            formState={formState}
                            formDispatcher={formDispatcher}
                            id="creationConfigSimFlash"
                            uiValidator={CreationGroupeAbonneSchema}
                            handleAjouterChamps={handleAjouterChamps}
                            isFloatName="isFloat"
                        />
                    </Tab>
                    {/* <Tab eventKey="test" title="Test (1)">
                        <TestRootForm availableForSelection={availableForSelection} />
                    </Tab> */}
                    <Tab eventKey="api" title="Api (2)">
                        <CustomRow>
                            <CustomCol className="col-12 col-md-6">
                                <ConfigApiComponent
                                    availableForSelection={
                                        availableForSelection
                                    }
                                    listChamps={fields?.listChamps}
                                    formDispatcher={formDispatcher}
                                    uiValidator={CreationGroupeAbonneSchema}
                                    formState={formState}
                                    handleAddOutputField={handleAddOutputField}
                                    removeItemOnOutputList={
                                        removeItemOnOutputList
                                    }
                                />
                            </CustomCol>
                            <CustomCol className="col-12 col-md-6 rounded border border-warning">
                                <pre>
                                    {JSON.stringify(
                                        formState.fields?.listChamps,
                                        null,
                                        4
                                    )}
                                </pre>
                            </CustomCol>
                            <CustomCol className="offset-md-6 col-12 col-md-6 rounded border border-success">
                                <pre>
                                    {JSON.stringify(
                                        formState.fields?.outputList,
                                        null,
                                        4
                                    )}
                                </pre>
                            </CustomCol>
                        </CustomRow>
                    </Tab>
                </Tabs>
                <div className="row">
                    <div className="offset-4 offset-md-10 col-4 col-md-1">
                        <CustomButtonLoader
                            className="btn-success"
                            onClick={handleSubmit}
                            text="Sauvegarder"
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                        />
                    </div>
                </div>
            </CustomForm>
        </>
    );
}
