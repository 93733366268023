import * as yup from 'yup';
import { TYPE_ORGANISATION, formInitialState } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { CreationPartenaire } from './creation-form';

const defaultFields = {
    designation: '',
    numeroTelEntreprise: '',
    nom: '',
    postnom: '',
    prenom: '',
    numeroTelGestionnaire: '',
    listCompte: [],
    numero: '',
    codeEntreprise: '',
    selectedDevise: {},
    selectedTypePartenaire: {},
    selectedInstitutionFinanciere: {},
    partenaireTypeCarteIdentite: {},
    partenaireNumeroCarteIdentite: '',
    partenaireNomImage: '',
    file: {},
    logo: '',
    logoTraiter: '',
    logoStatus: '',
    estBanque: false
};

const defaultDependencies = {
    fields: ['banques', 'devises']
};

const setDefaultProperties = ({ type }) => {
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedTypePartenaire: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                designation: yup.string().required(),
                codeEntreprise: yup.string(),
                numeroTelEntreprise: yup.string().required(),
                nom: yup.string().required(),
                postnom: yup.string(),
                prenom: yup.string().required(),
                numeroTelGestionnaire: yup.string().required(),
                estBanque: yup.boolean(),
                listCompte: yup.array().when('estBanque', {
                    is: true,
                    then: yup
                        .array()
                        .of(
                            yup
                                .object({
                                    id: yup.string(),
                                    numero: yup.string().required(),
                                    institutionFinanciere: yup.object().shape({
                                        code: yup.string().required(),
                                        designation: yup.string().required()
                                    }),
                                    devise: yup.string().required()
                                })
                                .shape({})
                        )
                        .required()
                        .min(1, 'Ajoutez au moins un élément à la liste')
                })
            })
        };
    }
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedTypePartenaire: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                designation: yup.string().required(),
                codeEntreprise: yup.string(),
                numeroTelEntreprise: yup.string().required(),
                nom: yup.string().required(),
                postnom: yup.string(),
                prenom: yup.string().required(),
                numeroTelGestionnaire: yup.string().required(),
                estBanque: yup.boolean(),
                listCompte: yup.array().when('estBanque', {
                    is: true,
                    then: yup
                        .array()
                        .of(
                            yup
                                .object({
                                    id: yup.string(),
                                    numero: yup.string().required(),
                                    institutionFinanciere: yup.object().shape({
                                        code: yup.string().required(),
                                        designation: yup.string().required()
                                    }),
                                    devise: yup.string().required()
                                })
                                .shape({})
                        )
                        .required()
                        .min(1, 'Ajoutez au moins un élément à la liste')
                })
            })
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedTypePartenaire: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                designation: yup.string().required(),
                codeEntreprise: yup.string(),
                numeroTelEntreprise: yup.string().required(),
                nom: yup.string().required(),
                postnom: yup.string(),
                prenom: yup.string().required(),
                numeroTelGestionnaire: yup.string().required(),
                estBanque: yup.boolean(),
                listCompte: yup.array().when('estBanque', {
                    is: true,
                    then: yup
                        .array()
                        .of(
                            yup
                                .object({
                                    id: yup.string(),
                                    numero: yup.string().required(),
                                    institutionFinanciere: yup.object().shape({
                                        code: yup.string().required(),
                                        designation: yup.string().required()
                                    }),
                                    devise: yup.string().required()
                                })
                                .shape({})
                        )
                        .required()
                        .min(1, 'Ajoutez au moins un élément à la liste')
                })
            })
        };
    }
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedTypePartenaire: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                designation: yup.string().required(),
                codeEntreprise: yup.string(),
                numeroTelEntreprise: yup.string().required(),
                nom: yup.string().required(),
                postnom: yup.string(),
                prenom: yup.string().required(),
                numeroTelGestionnaire: yup.string().required(),
                estBanque: yup.boolean(),
                listCompte: yup.array().when('estBanque', {
                    is: true,
                    then: yup
                        .array()
                        .of(
                            yup
                                .object({
                                    id: yup.string(),
                                    numero: yup.string().required(),
                                    institutionFinanciere: yup.object().shape({
                                        code: yup.string().required(),
                                        designation: yup.string().required()
                                    }),
                                    devise: yup.string().required()
                                })
                                .shape({})
                        )
                        .required()
                        .min(1, 'Ajoutez au moins un élément à la liste')
                })
            })
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({})
    };
};

export function CreationPartenaireForm() {
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });

    const { fields, dependencies, elements, uiValidator } = typeEntite
        ? setDefaultProperties({
              type: typeEntite
          })
        : {};

    /**
     * initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });
    const renderContent = () =>
        initialState?.dependencies ? (
            <CreationPartenaire
                idToken={idToken}
                typeEntite={typeEntite}
                uiValidator={uiValidator}
                initialState={initialState}
            />
        ) : null;
    return renderContent();
}
