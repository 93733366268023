import * as yup from 'yup';

/**
 * @description : Schema Crediter Compte Creation
 */
export const crediterCompteSchemaValidator = yup.object().shape({
    isChange: yup.boolean(),
    montant: yup.number().min(1),
    tillNumber: yup.string().required(),
    selectedDevise: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedDeviseSollicite: yup.object().when('isChange', {
        is: true,
        then: (schema) =>
            schema.shape({
                code: yup.string().required(),
                designation: yup.string().required()
            }),
        otherwise: (schema) =>
            schema
                .shape({
                    code: yup.string(),
                    designation: yup.string()
                })
                .nullable()
    })
});

/**
 * @description : Schema Crediter Compte Detail
 * actionEncours: '',
            isChange: false,
            selectedDeviseSollicite: {}
 */
export const crediterCompteDetailSchemaValidator = yup.object().shape({
    actionEncours: yup.string(),
    isChange: yup.boolean(),
    selectedDeviseSollicite: yup.object().when('isChange', {
        is: true,
        then: (schema) =>
            schema.shape({
                code: yup.string().required(),
                devise: yup.string().required()
            }),
        otherwise: (schema) =>
            schema
                .shape({
                    code: yup.string(),
                    devise: yup.string()
                })
                .nullable()
    })
});
