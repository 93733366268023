import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState,
    fromTimestampToString
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../releve/generic';
import { URL_CONST } from '../../../util';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedAgence: {},
    selectedEtat: {}
};

export function RapportPresenceAgentForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const fetchReport = () => {
        const { dateFin, dateDebut } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payload = {
            dateDebut,
            dateFin,
            estOrganisation: true
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_MOUVEMENT_AGENT_ORGANISATION,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };
    const formatDate = ({ item, property }) =>
        fromTimestampToString(item?.processus?.[property])?.split(' ')?.[1] ||
        '-';
    return (
        <MainReportComponentLarge
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            isDetaille={false}
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={[
                {
                    displayName: 'Prenom',
                    field: 'agent.prenom'
                },
                {
                    displayName: 'Nom',
                    field: 'agent.nom'
                },
                {
                    displayName: 'Arrivée',
                    field: 'arrivee'
                },
                { displayName: 'Debut Pause', field: 'debutPause' },
                { displayName: 'Debut Fin', field: 'finPause' },
                {
                    displayName: 'Sortie',
                    field: 'sortie'
                }
            ]}
            dataList={
                elements.mouvements?.value?.map((item) => ({
                    ...item,
                    sortie: formatDate({ item, property: 'Sortie' }),
                    arrivee: formatDate({ item, property: 'Arrivee' }),
                    debutPause: formatDate({
                        item,
                        property: 'SortiePause'
                    }),
                    finPause: formatDate({ item, property: 'RetourPause' })
                })) || []
            }
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
        />
    );
}
