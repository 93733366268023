import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    CODE_SERVICE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST, useHasService } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { DepenseFormSchema as uiValidator } from './validation';

const defaultFields = {
    selectedCaisse: {},
    selectedRaison: {},
    montant: 0
};

/**
 *
 * @Description : DefaultDependencies
 */
const defaultDependencies = { fields: ['caisses', 'raisons'] };

export function CreationDepensesForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        nappUser,
        caisses,
        etatCaisses,
        raisons,
        etatRaisons
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        caisses: reduxState.caissesActiveUser.map((item) => ({
            ...item,
            designation: `${item?.designation || ''}-${item?.devise || ''}`
        })),
        etatCaisses: reduxState.etat.caissesActiveUser.etat,
        raisons: reduxState.raisons,
        etatRaisons: reduxState.etat.raisons.etat
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DEPENSE_POUR_AUTRE,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DEPENSE
        ]
    });

    const payloadCaisses = hasService[
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DEPENSE_POUR_AUTRE?.code
    ]
        ? { estOrganisation: true }
        : {};
    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                payload: payloadCaisses,
                nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
                etat: etatCaisses
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_RAISON,
                nodeName: REDUX_NODE_NAME.RAISONS,
                etat: etatRaisons
            }
        ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedCaisse, selectedRaison, montant } = fields;
        const payload = {
            newDepense: {
                caisse: {
                    reference: selectedCaisse.id
                },
                raison: {
                    code: selectedRaison.code
                },
                montant
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_DEPENSE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.DEPENSES,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'caisses',
                    etat: etatCaisses
                },
                {
                    dependency: 'raisons',
                    etat: etatRaisons
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={raisons}
                    label="Raison*"
                    defaultOption="Sélectionner raison"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedRaison"
                    id="CreationSelectedRaisonDepenseId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={caisses}
                    label="Caisse*"
                    defaultOption="Sélectionner caisse"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCaisse"
                    id="creationSelectedCaisseDepenseId"
                    formState={formState}
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="creationenMontantDepenseId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
