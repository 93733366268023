const formatagePlage = (entity) => {
    if (entity?.plages?.length) {
        let plagesFormatted = '';
        entity.plages.forEach((item) => {
            plagesFormatted += item.initial.debut;
            plagesFormatted += item.debut;
            plagesFormatted += ' - ';
            plagesFormatted += item.initial.fin;
            plagesFormatted += item.fin;
            plagesFormatted += ' ;';
        });
        return plagesFormatted.slice(0, plagesFormatted.length - 1);
    }
    return '';
};

export const schema = [
    {
        title: 'Plages',
        description: 'plages',
        className: 'border-bottom',
        callback: formatagePlage
    },
    {
        title: 'Quantite',
        description: 'quantite',
        className: 'border-bottom'
    },
    {
        title: 'Montant',
        description: 'montant',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    }
];
