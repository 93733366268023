import { GENRE, TYPE_CARTE } from '@napp-inc/jnapp-util';

export const mapper = [
    {
        position: 0,
        type: 'text',
        name: 'newPersonnePrenom',
        id: 'newPersonnePrenom',
        placeholder: 'Prénom',
        label: 'Prénom*',
        isInputGroup: false
    },
    {
        position: 1,
        type: 'text',
        name: 'newPersonneNom',
        id: 'newPersonneNom',
        placeholder: 'Nom',
        label: 'Nom*',
        isInputGroup: false
    },
    {
        position: 2,
        type: 'text',
        name: 'newPersonnePostnom',
        id: 'newPersonnePostnom',
        placeholder: 'Postnom',
        label: 'Postnom*',
        isInputGroup: false
    },
    {
        position: 3,
        type: 'dropdown',
        name: 'newPersonneSexe',
        id: 'newPersonneSexe',
        selectionClassName: 'form-select-md',
        defaultOption: 'Sélectionner le genre',
        options: GENRE.GENRES_PERSONNES,
        label: 'Genre*'
    }
];

export const pieceIdentiteMapper = [
    {
        position: 1,
        type: 'dropdown',
        name: 'newPersonneTypeCarteIdentite',
        id: 'newPersonneTypeCarteIdentite',
        selectionClassName: 'form-select-md',
        defaultOption: 'Type Carte',
        options: TYPE_CARTE,
        label: 'Type de carte*'
    },
    {
        position: 2,
        type: 'text',
        name: 'newPersonneNumeroCarteIdentite',
        id: 'newPersonneNumeroCarteIdentite',
        placeholder: 'numéro de la carte',
        label: 'Numéro de la carte*',
        isInputGroup: false
    },
    {
        position: 3,
        type: 'file',
        name: 'newPersonneNomImage',
        id: 'creationUtilisateurNewPersonneNomImageId',
        label: 'Image',
        accept: '.svg, .png, .jpg, .jpeg, .gif',
        isImage: true
    }
];
