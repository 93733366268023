import { FORM_CONST, intParser } from '@napp-inc/jnapp-util';

export function InputDeviseComponent({
    devise,
    formDispatcher,
    labelClassName = 'col-3 col-md-4',
    inputClassName = 'col-3 col-md-4',
    percuRetourName = 'tableauDeviseRetour',
    montantName = 'montant'
}) {
    const { id } = devise;
    return (
        <div className="input-group mb-1">
            <label htmlFor={id} className={`col-form-label ${labelClassName}`}>
                {devise.id}
            </label>
            <input
                title="Montant"
                onChange={(e) => {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE_ON_TABLE,
                        payload: {
                            id,
                            field: percuRetourName,
                            propertyName: montantName,
                            data: intParser(e.target.value)
                        }
                    });
                }}
                value={devise.montant}
                className={`form-control ${inputClassName}`}
                type="number"
            />
        </div>
    );
}
