import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { ReleveBalanceOrganisme } from '../../../../components';
import { URL_CONST } from '../../../../util';

export function RelevePartenaireKinDistributionForm() {
    return (
        <ReleveBalanceOrganisme
            typeOrganisation={TYPE_ORGANISATION.SUPER_DEALER}
            payload={{
                estOrganisation: true,
                estBalance: true,
                estAll: true
            }}
            functionName={URL_CONST.GET_LIST_COMPTE_BANCAIRE_PARTENAIRE}
            dynamicNodeName="partenairesKinDistribution"
            label="Sélectionner un partenaire"
            listFormater={(list) =>
                list.map((item) => ({
                    ...item,
                    designation: `${
                        item?.valeurTypeProprietaire?.designation || ''
                    } - ${item?.devise || ''}`
                }))
            }
        />
    );
}
