import * as yup from 'yup';

export const CreationRayonSchema = yup.object().shape({
    rayon: yup.string().required('Entrez le nom du rayon'),
    colonnes: yup
        .array()
        .of(
            yup
                .object({
                    name: yup.string().required('Entrez le nom de la colonne'),
                    nombreEmplacement: yup
                        .number()
                        .required("Entrez le nombre d'emplacement")
                        .min(
                            1,
                            "Le nombre d'emplacement doit être supérieur à 0"
                        )
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins une colonne')
});
