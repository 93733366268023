import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListImputationStockForm() {
    return (
        <ListGenericForm
            reduxPropertyName="imputationsStock"
            functionName={URL_CONST.GET_LIST_IMPUTATION_STOCK}
            nodeName={DB_NODE.IMPUTATION_STOCK}
            mapper={[
                {
                    field: 'designation',
                    displayName: 'Désignation'
                },
                {
                    field: 'parent.designation',
                    displayName: 'Parent'
                },
                {
                    field: 'typeOperation',
                    displayName: 'Opération'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ]}
        />
    );
}
