import { useEffect, useState } from 'react';

/**
 * Vérifie si les services associés à une fonctionnalité figurent parmi un ensemble des services disponibles
 * @param {Array} services list des users services disponible pour la session en cours
 * @param {Array} code liste des services associés à une fonctionnalité
 * @returns {Boolean}
 */
const isUserServiceExiste = (services = [], code = []) =>
    code.some((item) =>
        services.find(
            ({ sysService, estActif }) =>
                estActif && sysService?.code === item?.code
        )
    );

/**
 * Retourne la liste des chemins qui peuvent être consultés en fonctions des services utilisateurs
 * @param {Array} paths la liste de tous les chemins disponibles
 * @param {Array} usersService liste des userServices associés à la session en cours
 * @returns {Array}
 * isServiceConstraint : varaible ajouté dans le path au prermier  niveau, pour déterminer si le chargement de l'élément est conditionné aux users services
 * isLayout : éléments ajoutés aux items pour déterminer s'ils sont des layouts
 */
export const useBuildRoutesBasedOnUserService = (
    paths = [],
    usersService = []
) => {
    // cette initialisation est effectuée pour éviter un avertissemnt au cas où un tableau vide serait renvoyé
    const [chemins, setChemins] = useState([
        {
            path: '*',
            element: <div />
        }
    ]);
    useEffect(() => {
        const pathsToReturn = [];
        paths.forEach(({ isServiceConstraint, ...path }) => {
            // particulièrement pour matcher la route (*)
            if (!isServiceConstraint) {
                pathsToReturn.push(path);
            } else {
                const newFirstLevelChildren = [];
                const currentFirstLevelChildren = path?.children || [];
                currentFirstLevelChildren.forEach(
                    ({
                        isLayout,
                        isDropDownMenu,
                        code,
                        children: secondLevelChildren,
                        ...secondLevelRest
                    }) => {
                        if (isDropDownMenu) {
                            secondLevelChildren.forEach(
                                (firstChildrenOfDropDown) => {
                                    if (
                                        isUserServiceExiste(
                                            usersService,
                                            firstChildrenOfDropDown?.code
                                        )
                                    ) {
                                        if (
                                            !firstChildrenOfDropDown?.children
                                        ) {
                                            newFirstLevelChildren.push(
                                                firstChildrenOfDropDown
                                            );
                                        } else {
                                            const newSecondLevelChildren = [];
                                            firstChildrenOfDropDown?.children.forEach(
                                                (
                                                    childOfFirstChildOfDropdwon
                                                ) => {
                                                    if (
                                                        childOfFirstChildOfDropdwon.index
                                                    ) {
                                                        newSecondLevelChildren.push(
                                                            childOfFirstChildOfDropdwon
                                                        );
                                                    } else if (
                                                        !childOfFirstChildOfDropdwon.index &&
                                                        isUserServiceExiste(
                                                            usersService,
                                                            childOfFirstChildOfDropdwon?.code
                                                        )
                                                    ) {
                                                        newSecondLevelChildren.push(
                                                            childOfFirstChildOfDropdwon
                                                        );
                                                    }
                                                }
                                            );
                                            if (newSecondLevelChildren.length) {
                                                newFirstLevelChildren.push({
                                                    ...firstChildrenOfDropDown,
                                                    children:
                                                        newSecondLevelChildren
                                                });
                                            }
                                        }
                                    }
                                }
                            );
                        }
                        if (!isLayout && !secondLevelChildren?.length) {
                            // intercepte les premiers enfants du premier layout qui n'ont pas d'enfants et qui ne sont pas des layouts comme Home
                            if (
                                secondLevelRest.isAlwaysAllow ||
                                isUserServiceExiste(usersService, code)
                            ) {
                                newFirstLevelChildren.push({
                                    code,
                                    ...secondLevelRest
                                });
                            }
                        } else if (!isLayout && secondLevelChildren?.length) {
                            // intercepte les premiers enfants du premier layout qui ont des enfants et qui ne sont pas des layouts comme Home
                            if (isUserServiceExiste(usersService, code)) {
                                const newSecondLevelChildren = [];
                                secondLevelChildren.forEach((slChildren) => {
                                    if (slChildren?.index) {
                                        newSecondLevelChildren.push(slChildren);
                                    } else if (
                                        !slChildren.index &&
                                        isUserServiceExiste(
                                            usersService,
                                            slChildren?.code
                                        )
                                    ) {
                                        newSecondLevelChildren.push(slChildren);
                                    }
                                });
                                newFirstLevelChildren.push({
                                    code,
                                    ...secondLevelRest,
                                    children: newSecondLevelChildren
                                });
                            }
                        } else if (isLayout && secondLevelChildren?.length) {
                            // intercepte les premiers enfants du premier layout qui sont aussi des layouts (comme setting layout qui est un layout se trouvant dans defaultL layout)
                            const newSecondLevelChildren = [];
                            secondLevelChildren.forEach((slChildren) => {
                                if (!slChildren?.isDropDownMenu) {
                                    if (
                                        isUserServiceExiste(
                                            usersService,
                                            slChildren.code
                                        )
                                    ) {
                                        if (!slChildren?.children) {
                                            newSecondLevelChildren.push(
                                                slChildren
                                            );
                                        } else {
                                            const newThirdLevelChildren = [];
                                            slChildren.children.forEach(
                                                (thirdLevelChidrenItem) => {
                                                    if (
                                                        isUserServiceExiste(
                                                            usersService,
                                                            thirdLevelChidrenItem?.code
                                                        )
                                                    ) {
                                                        newThirdLevelChildren.push(
                                                            thirdLevelChidrenItem
                                                        );
                                                    }
                                                }
                                            );
                                            if (newThirdLevelChildren.length) {
                                                newSecondLevelChildren.push({
                                                    ...slChildren,
                                                    children:
                                                        newThirdLevelChildren
                                                });
                                            }
                                        }
                                    }
                                } else if (slChildren?.isDropDownMenu) {
                                    /**
                                     * Update
                                     */
                                    slChildren.children.forEach(
                                        (firstChildrenOfDropDown) => {
                                            if (
                                                isUserServiceExiste(
                                                    usersService,
                                                    firstChildrenOfDropDown?.code
                                                )
                                            ) {
                                                if (
                                                    !firstChildrenOfDropDown?.children
                                                ) {
                                                    newSecondLevelChildren.push(
                                                        firstChildrenOfDropDown
                                                    );
                                                } else {
                                                    const newThirdLevelChildren =
                                                        [];
                                                    firstChildrenOfDropDown?.children.forEach(
                                                        (
                                                            childOfFirstChildOfDropdwon
                                                        ) => {
                                                            if (
                                                                childOfFirstChildOfDropdwon.index
                                                            ) {
                                                                newThirdLevelChildren.push(
                                                                    childOfFirstChildOfDropdwon
                                                                );
                                                            } else if (
                                                                !childOfFirstChildOfDropdwon.index &&
                                                                isUserServiceExiste(
                                                                    usersService,
                                                                    childOfFirstChildOfDropdwon?.code
                                                                )
                                                            ) {
                                                                newThirdLevelChildren.push(
                                                                    childOfFirstChildOfDropdwon
                                                                );
                                                            }
                                                        }
                                                    );
                                                    if (
                                                        newThirdLevelChildren.length
                                                    ) {
                                                        newSecondLevelChildren.push(
                                                            {
                                                                ...firstChildrenOfDropDown,
                                                                children:
                                                                    newThirdLevelChildren
                                                            }
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                    );
                                }
                            });
                            // fin du traitement pour les enfants de layout
                            if (newSecondLevelChildren.length) {
                                newFirstLevelChildren.push({
                                    isLayout,
                                    code,
                                    ...secondLevelRest,
                                    children: newSecondLevelChildren
                                });
                            }
                        }
                    }
                );
                // On rajoute le newFirstLevelChildren dans l'arborescence du menu
                if (newFirstLevelChildren.length) {
                    pathsToReturn.push({
                        isServiceConstraint,
                        ...path,
                        children: newFirstLevelChildren
                    });
                }
            }
        });
        setChemins(pathsToReturn);
    }, [paths, usersService]);
    // console.log(chemins);
    return chemins;
};
