import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListShutDownAndUpApiPartnerForm() {
    return (
        <ListGenericForm
            reduxPropertyName="traitementConfigurationMarchandApi"
            functionName={
                URL_CONST.GET_LIST_TRAITEMENT_CONFIGURATION_MARCHAND_API_INITIALS
            }
            payload={{ creator: 'qlxrcWKltYhDhrIG90oSyeU0eVN2' }}
            nodeName={REDUX_NODE_NAME.TRAITEMENT_CONFIGURATION_MARCHAND_API}
            mapper={[
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand'
                },
                {
                    displayName: 'Actif',
                    field: 'estActif',
                    isBoolean: true
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
