import { useSelector } from 'react-redux';
import {
    Add,
    TIME,
    TODAY,
    TYPE_ORGANISATION,
    floatParser
} from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import { ReduxReportTemplate } from '../../template';
import { RevendeurSelector, StaticSelector } from '../../../../components';

const formater = (data = []) => {
    const result = [];
    (data?.[0]?.listReturn || []).forEach((item) => {
        const existing = result.find(
            (elm) =>
                elm?.superDealer?.code ===
                item?.detail?.valeurTypeProprietaire?.code
        );
        if (existing) {
            if (
                Object.prototype.hasOwnProperty.call(
                    existing?.montant,
                    item?.detail?.devise
                )
            ) {
                existing.montant[item?.detail?.devise] = {
                    soldeFermeture: Add(
                        existing.montant[item?.detail?.devise]?.soldeFermeture,
                        floatParser(item?.soldeFermeture || 0)
                    ),
                    soldeOuverture: Add(
                        existing.montant[item?.detail?.devise]?.soldeOuverture,
                        floatParser(item?.soldeOuverture || 0)
                    ),
                    entree: Add(
                        existing.montant[item?.detail?.devise]?.entree,
                        floatParser(item?.entree || 0)
                    ),
                    sortie: Add(
                        existing.montant[item?.detail?.devise]?.sortie,
                        floatParser(item?.sortie || 0)
                    )
                };
            } else {
                existing.montant[item?.detail?.devise] = {
                    soldeFermeture: floatParser(item?.soldeFermeture || 0),
                    soldeOuverture: floatParser(item?.soldeOuverture || 0),
                    entree: floatParser(item?.entree || 0),
                    sortie: floatParser(item?.sortie || 0)
                };
            }
        } else {
            result.push({
                id: item?.detail?.valeurTypeProprietaire?.code,
                superDealer: {
                    code: item?.detail?.valeurTypeProprietaire?.code,
                    designation:
                        item?.detail?.valeurTypeProprietaire?.designation
                },
                montant: {
                    [item?.detail?.devise]: {
                        soldeFermeture: floatParser(item?.soldeFermeture || 0),
                        soldeOuverture: floatParser(item?.soldeOuverture || 0),
                        entree: floatParser(item?.entree || 0),
                        sortie: floatParser(item?.sortie || 0)
                    }
                },
                tillNumber: item?.detail?.valeurTypeProprietaire?.tillNumber
            });
        }
    });
    // console.log('result', result);
    return result;
};
// const dynamicColumnFormater = (dynamicColumn) => {
//     const result = [];
//     Object.keys(dynamicColumn || {}).forEach((key) => {
//         result.push(
//             {
//                 displayName: `Ouverture ${key}`,
//                 field: `montant.${key}.soldeOuverture`,
//                 isTotal: true
//             },
//             {
//                 displayName: `Entrée ${key}`,
//                 field: `montant.${key}.entree`,
//                 isTotal: true
//             },
//             {
//                 displayName: `Sortie ${key}`,
//                 field: `montant.${key}.sortie`,
//                 isTotal: true
//             },
//             {
//                 displayName: `Fermeture ${key}`,
//                 field: `montant.${key}.soldeFermeture`,
//                 isTotal: true
//             }
//         );
//     });
//     return result;
// };

export function RapportBalanceRevendeurForm() {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedRevendeur: {},
            selectedDevise: { code: 'USD', designation: 'USD' },
            options: []
        }
    });
    const buildTotalCards = (data, totalReturn) => {
        const devise = formState?.fields?.selectedDevise?.code;
        if (totalReturn) {
            return {
                isCustomObject: true,
                customObject: {
                    ouverture: {
                        displayName: 'Ouverture',
                        value: totalReturn?.[devise]?.soldeOuverture,
                        devise
                    },
                    entree: {
                        displayName: 'Entrée',
                        value: totalReturn?.[devise]?.entree,
                        devise
                    },
                    sortie: {
                        displayName: 'Sortie',
                        value: totalReturn?.[devise]?.sortie,
                        devise
                    },
                    fermeture: {
                        displayName: 'Fermeture',
                        value: totalReturn?.[devise]?.soldeFermeture,
                        devise
                    }
                }
            };
        }
        return [];
    };
    return (
        <ReduxReportTemplate
            listTitle={`Balance en ${formState?.fields?.selectedDevise?.code}`}
            dynamicNodeName={`allBalanceOrganisationRevendeur-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_COMPTE_USER}
            nodeName={REDUX_NODE_NAME.ALL_BALANCE_ORGANISATION}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            // dynamicColumnFormater={dynamicColumnFormater}
            mapper={[
                {
                    displayName: 'Revendeur',
                    field: 'superDealer.designation'
                },
                {
                    displayName: 'tillNumber',
                    field: 'tillNumber'
                },
                {
                    displayName: 'Ouverture',
                    field: `montant.${formState?.fields?.selectedDevise?.code}.soldeOuverture`,
                    isTotal: true
                },
                {
                    displayName: 'Entrée',
                    field: `montant.${formState?.fields?.selectedDevise?.code}.entree`,
                    isTotal: true
                },
                {
                    displayName: 'Sortie',
                    field: `montant.${formState?.fields?.selectedDevise?.code}.sortie`,
                    isTotal: true
                },
                {
                    displayName: 'Fermeture',
                    field: `montant.${formState?.fields?.selectedDevise?.code}.soldeFermeture`,
                    isTotal: true
                }
            ]}
            isAddTotalTr
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                typeEntite: 'Journalier',
                typesEntite: [TYPE_ORGANISATION.NAPP, 'BalanceDependance'],
                estOrganisation: true,
                typeProprietaire: TYPE_ORGANISATION.REVENDEUR,
                estDevise: true,
                estGerant: true,
                estTotal: true,
                typeOrganisation: nappUser?.typeEntite
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier',
                estOrganisation: true,
                typeProprietaire: TYPE_ORGANISATION.REVENDEUR,
                typesEntite: [TYPE_ORGANISATION.NAPP, 'BalanceDependance'],
                estDevise: true,
                estGerant: true,
                estTotal: true,
                typeOrganisation: nappUser?.typeEntite
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedCashCollecteur',
                    isRequired: false,
                    displayName: 'Cash collecteur',
                    callback: (value) => {
                        if (!value?.code || value?.code === 'all') return {};
                        return { codeValeurTypeProprietaire: value?.code };
                    }
                },
                {
                    name: 'selectedRevendeur',
                    isRequired: false,
                    displayName: 'Revendeur',
                    callback: (value) => {
                        if (!value?.code || value?.code === 'all') return {};
                        return { codeValeurTypeProprietaire: value?.code };
                    }
                }
            ]}
            bottomPagination
            buildTotalCards={buildTotalCards}
        >
            <RevendeurSelector
                formState={formState}
                formDispatcher={formDispatcher}
                isAddAditionalOptions
                idProperty="code"
            />
            <StaticSelector
                label="Devise :"
                formDispatcher={formDispatcher}
                formState={formState}
                name="selectedDevise"
                idProperty="code"
                options={[
                    { code: 'USD', designation: 'USD' },
                    { code: 'CDF', designation: 'CDF' }
                ]}
                defaultOption="USD"
            />
        </ReduxReportTemplate>
    );
}
