export const schema = [
    {
        description: 'valeurTypeEntite.designation',
        title: 'Organisation',
        className: 'border-bottom'
    },
    {
        description: 'typeProprietaire',
        title: 'Type Organisation',
        className: 'border-bottom'
    },
    {
        description: 'devise',
        title: 'devise',
        className: 'border-bottom'
    },
    {
        description: 'TauxAchat.valeur',
        title: 'Taux Achat',
        className: 'border-bottom'
    },
    {
        description: 'TauxVente.valeur',
        title: 'Taux Vente',
        className: 'border-bottom'
    },
    {
        description: 'TauxSystem.valeur',
        title: 'Taux System',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'etat',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'Création'
    }
];
