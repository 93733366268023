import * as yup from 'yup';

/**
 * @description : Schema de validation de Ressource
 */
export const RessourceSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required(),
    selectedTypeRessource: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedCompteImputation: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedServiceGenerateur: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedTypeMarchand: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    listTypeMarchand: yup
        .array()
        .of(
            yup
                .object({
                    id: yup.string(),
                    code: yup.string(),
                    designation: yup.string()
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins un élément à la liste')
});
