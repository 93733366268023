import * as yup from 'yup';

/**
 * @description : Schema tranfert Stock
 */
export const TransfertStockFormSchema = yup.object().shape({
    tillNumber: yup.string().required(),
    destination: yup.string().required(),
    selectedProduit: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedAgence: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    quantite: yup.number().min(20)
});
