import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListInstitutionsFinanciereSuperDealersKinDBForm() {
    return (
        <ListGenericForm
            isLinkToDetail={false}
            reduxPropertyName="institutionAgreeeNonAgreee"
            // payload={{ typeEntite: 'SuperDealer', codeValeurTypeEntite: 'KINDISTR_KINDB' }}
            payload={{ typeEntite: 'SuperDealer' }}
            functionName={URL_CONST.GET_LIST_BANQUE}
            nodeName={REDUX_NODE_NAME.INSTITUTION_AGREEE_NON_AGREEE}
            formater
            mapper={[
                {
                    field: 'designation',
                    displayName: 'Désignation'
                },
                {
                    field: 'email',
                    displayName: 'Email'
                },
                {
                    dataExtractor: (item) =>
                        item.typeProprietaire === 'Napp'
                            ? 'Agréée'
                            : 'Non Agréée',
                    displayName: 'Classe'
                },
                {
                    field: 'numeroTel',
                    displayName: 'N° Téléphone'
                },
                {
                    field: 'nationalite',
                    displayName: 'Pays'
                }
            ]}
        />
    );
}
