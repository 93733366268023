import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../../components';
import { useGetDetailForm, URL_CONST } from '../../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../../redux';

export function DetailCompteBancaire() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({ nodeName: 'comptesBancaire' });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = {
            etat,
            creator,
            idCompte: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_COMPTE_PARALLELE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_COMPTE_BY_ID}
            nodeName={REDUX_NODE_NAME.COMPTE_BANCAIRE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Compte Bancaire"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
                        .ATTENTE_VALIDATION_COMPTE_BANCAIRE_PARALLELE,
                    CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
                        .VALIDER_COMPTE_BANCAIRE_PARALLELE,
                    CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
                        .LECTURE_COMPTE_BANCAIRE_PARALLELE
                ]}
            >
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        {entity?.etat === ETAT.ETAT_ENCOURS ? (
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        ) : null}
                        {entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION ? (
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        ) : null}
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
