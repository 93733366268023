export const schema = [
    {
        title: 'Opération',
        description: 'typeProprietaire'
    },
    {
        title: 'Caisse',
        description: 'caisse.designation'
    },
    {
        title: 'Partenaire',
        description: 'partenaire.designation'
    },
    {
        title: 'Devise Sollicité',
        description: 'devise'
    },
    {
        title: 'Devise Caisse',
        description: 'caisse.devise'
    },
    {
        title: 'Montant',
        description: 'montant'
    },
    {
        title: 'Etat',
        description: 'etat'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
