import * as yup from 'yup';

/**
 * @description : Schema de validation
 */

// selectedTypeForm: {},
//     description: '',
//     urlImage: '',
//     titre: '',
//     selectedAgent: {},
//     sujet: ''

const TYPES_FORMULAIRE_SERVICE_CLIENT = {
    VIDEO_EXPLICATIVE: `VideoExplicative`,
    URGENCE_COMMUNICATION: `UrgenceCommunication`
};

export const ServiceClientFormulaireFormSchema = yup.object().shape({
    selectedTypeForm: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedAgent: yup.object().when('selectedTypeForm', {
        is: (selectedTypeForm) =>
            selectedTypeForm.code ===
            TYPES_FORMULAIRE_SERVICE_CLIENT.URGENCE_COMMUNICATION,
        then: yup.object().shape({
            reference: yup.string().required()
        }),
        otherwise: yup.object().shape({
            reference: yup.string()
        })
    }),
    description: yup.string().required(),
    urlImage: yup.string().when('selectedTypeForm', {
        is: (selectedTypeForm) =>
            selectedTypeForm.code ===
            TYPES_FORMULAIRE_SERVICE_CLIENT.VIDEO_EXPLICATIVE,
        then: yup.string().required(),
        otherwise: yup.string()
    }),
    titre: yup.string().when('selectedTypeForm', {
        is: (selectedTypeForm) =>
            selectedTypeForm.code ===
            TYPES_FORMULAIRE_SERVICE_CLIENT.VIDEO_EXPLICATIVE,
        then: yup.string().required(),
        otherwise: yup.string()
    }),
    selectedSujet: yup.object().when('selectedTypeForm', {
        is: (selectedTypeForm) =>
            selectedTypeForm.code ===
            TYPES_FORMULAIRE_SERVICE_CLIENT.URGENCE_COMMUNICATION,
        then: yup.object().shape({
            valeurTypeEntite: yup.string().required(),
            title: yup.string()
        }),
        otherwise: yup.object().shape({
            valeurTypeEntite: yup.string(),
            title: yup.string()
        })
    })
});
