import { buildYupErrorObject, FORM_CONST } from '@napp-inc/jnapp-util';
import { InputFeedback } from '../input/input-feedback';

/**
 * Composant personnalisé pour récuperer une date ou une heure
 * @param {Object} param0 object qui contient les props du composant:
 * @param {string} param0.id Identifiant unique du composant
 * @param {string} param0.name Nom du champ du formulaire qui correspond à une propriété du state
 * @param {string} param0.type Type de données saisies dans le composant date ou time, par défaut date
 * @param {string} param0.placeholder Texte affiché dans le composant avant la saisie de données
 * @param {string} param0.label Texte affiché dans le label du composant
 * @param {string} param0.inputTitle Texte affiché dans le tooltip du composant
 * @param {string} param0.labelClassName Classe CSS du label
 * @param {string} param0.inputClassName Classe CSS du input du composant
 * @param {string} param0.divClassName Classe CSS du div du composant
 * @param {string} param0.formDispatcher Fonction qui permet d'envoyer des actions au store du composant
 * @param {Object} param0.formState State du composant
 * @param {Object} param0.uiValidator Schema de validation du composant
 * @param {Boolean} param0.isInputGroup vrai si la dispostion doit être en colonne
 * @param {boolean} param0.readOnly Indique si le composant est en lecture seule ou non
 * @param {boolean} param0.disabled Désactive le composant ou Active le composant
 * @param {Function} param0.callback fonction de callback
 * @returns un input de type InputGroup
 */
export function CustomInputDate({
    id,
    name,
    type = 'date',
    placeholder,
    label,
    inputTitle = '',
    labelClassName,
    inputClassName,
    divClassName = '',
    formDispatcher,
    formState = {},
    uiValidator,
    isInputGroup = true,
    readOnly = false,
    disabled = false,
    callback
}) {
    const { error, fields, form } = formState;
    const handleChange = async (event) => {
        const { value: currentValue } = event.target;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: { field: name, data: currentValue }
        });
        if (callback && typeof callback === 'function') {
            callback(currentValue, name);
        }
        if (uiValidator && form.isSubmitted) {
            uiValidator
                .validate(
                    { ...fields, [name]: currentValue },
                    {
                        abortEarly: false
                    }
                )
                .then(() => {
                    formDispatcher({
                        type: FORM_CONST.VALIDATION_SUCCESS
                    });
                })
                .catch((erreur) => {
                    if (erreur.name === 'ValidationError') {
                        const err = buildYupErrorObject(erreur);
                        formDispatcher({
                            type: FORM_CONST.SET_FIELDS_ERROR,
                            payload: {
                                message: { ...err },
                                status: FORM_CONST.CREATION_ECHEC
                            }
                        });
                    }
                });
        }
    };
    return (
        <>
            {label ? (
                <label
                    htmlFor={id}
                    className={`${
                        isInputGroup ? 'form-label' : 'col-form-label'
                    } ${labelClassName}`}
                >
                    {label}
                </label>
            ) : null}

            <div
                className={`${
                    isInputGroup ? 'input-group' : ''
                } has-validation ${divClassName}`}
            >
                <input
                    id={id}
                    name={name}
                    type={type}
                    title={inputTitle}
                    value={fields && fields[name]}
                    className={`form-control ${
                        error && error[name] ? `is-invalid` : null
                    } ${inputClassName}`}
                    onChange={handleChange}
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={readOnly}
                />
                <InputFeedback>{error && error[name]}</InputFeedback>
            </div>
        </>
    );
}
