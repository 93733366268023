import { CHART_TYPE } from '../../util';
import { BaseChart } from './base-chart';

export function SimpleChart({
    series,
    categories,
    xAxisTickInterval = 1,
    chartType = CHART_TYPE.LINE,
    title = '',
    yAxisTickInterval,
    yAxisTittle
}) {
    return (
        <BaseChart
            options={{
                series,
                xAxis: {
                    categories,
                    tickInterval: xAxisTickInterval
                },
                chart: {
                    renderTo: 'container',
                    type: chartType
                },
                title: {
                    text: title
                },
                accessibility: {
                    enabled: false
                },
                yAxis: {
                    title: {
                        text: yAxisTittle
                    },
                    tickInterval: yAxisTickInterval
                }
            }}
        />
    );
}
