export const schema = [
    {
        title: 'Destinataire',
        description: 'valeurTypeOrganisationUser.reference',
        className: 'border-bottom'
    },
    {
        title: 'Quantite',
        description: 'quantite',
        className: 'border-bottom'
    },
    {
        title: 'Montant',
        description: 'montant',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    }
];
