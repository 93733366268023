import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    RESTITUTION_PARTIEL,
    TYPE_RESTITUTION_CAUTION,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomRow
} from '../../../components';
import { RestitutionFormSchema as uiValidator } from './validation';

const defaultFields = {
    selectedMode: {},
    selectedAgentLivreur: {},
    montant: ''
};

// FIXME: Logique de récupération des agents livreurs
// https requests

export function CreationRestitutionPertesForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_SECTEUR,
                reduxNodeName: DB_NODE.DIRECTION_SECTEUR,
                payload,
                fields,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <>
            <CustomAlert error={form?.error} />
            <CustomForm>
                {/* Recupérer  */}
                <CustomRow>
                    <CustomDropdown
                        options={[]}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner agent livreur..."
                        label="Agent livreur*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedAgentLivreur"
                        id="selectedAgentLivreurId"
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={TYPE_RESTITUTION_CAUTION}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner le mode"
                        label="Mode de Remboursement*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedMode"
                        id="selectedPaysId"
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomCollapse
                    isIn={fields.selectedMode.code === RESTITUTION_PARTIEL.code}
                >
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Montant*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            placeholder="Saisissez le numero de compte"
                            formDispatcher={formDispatcher}
                            name="montant"
                            type="number"
                            id="numeroCompteId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                </CustomCollapse>
                <CustomRow className="row">
                    <CustomCol className="col-3">
                        <CustomButtonLoader
                            className="btn-success"
                            onClick={handleSubmit}
                            text="Valider"
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </CustomForm>
            <hr />
        </>
    );
    return renderContent();
}
