import { Tab, Tabs } from 'react-bootstrap';
import { RapportCommissionRevendeurForm } from './rapport-commission';

export function RapportCommissionRevendeurViewLayout() {
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Commission Revendeur</h1>
            </div>
            <Tabs
                defaultActiveKey="commission"
                id="fill-tab-example"
                className="mb-3"
            >
                <Tab eventKey="commission" title="Par commission">
                    <RapportCommissionRevendeurForm isByRevendeur={false} />
                </Tab>
                <Tab eventKey="revendeur" title="Par revendeur">
                    <RapportCommissionRevendeurForm />
                </Tab>
            </Tabs>
        </div>
    );
}
