import { Array } from '@napp-inc/jnapp-util';
import {
    URL_CONST,
    traitementMouvement,
    useGetReportDetail
} from '../../../../util';
import { StandardReportTemplate } from '../../template';
import { ReportCaisseSelector } from '../../../../components';

const formater = (data) =>
    Array.tri({
        data: traitementMouvement(data?.[0] || []),
        property: 'dateCreation',
        order: 'desc'
    });

export function ReleveCaisseForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCaisse: {}
        }
    });
    return (
        <>
            {/* <StateShowerComponent state={formState?.elements} /> */}
            <StandardReportTemplate
                listTitle={formState?.fields?.selectedCaisse?.designation || ''}
                functionName={URL_CONST.GET_LIST_MOUVEMENT_PLAGE}
                formState={formState}
                formDispatcher={formDispatcher}
                listFormater={formater}
                mapper={[
                    {
                        field: 'valeurTypeOrganisation.designation',
                        displayName: 'Désignation'
                    },
                    {
                        field: 'typeEntite',
                        displayName: 'Créateur'
                    },
                    {
                        field: 'soldePrecedent',
                        displayName: 'Ouverture',
                        isNumber: true
                    },
                    {
                        field: 'entree',
                        displayName: 'Entrée',
                        isNumber: true
                    },
                    {
                        field: 'sortie',
                        displayName: 'Sortie',
                        isNumber: true
                    },
                    {
                        field: 'soldeActuel',
                        displayName: 'Fermeture',
                        isNumber: true
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Date'
                    }
                ]}
                defaultPayloadMapperValues={{
                    typeProprietaire: 'Caisse'
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedCaisse.id',
                        property: 'valeurTypeProprietaire',
                        isRequired: true,
                        displayName: 'Caisse'
                    }
                ]}
            >
                <ReportCaisseSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    payload={{
                        estOrganisation: true,
                        typeProprietaire: 'SuperDealer'
                    }}
                />
            </StandardReportTemplate>
        </>
    );
}
