import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetOrganisationDetail,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FILTER_OPERATOR,
    FILTER_TYPE,
    formInitialState,
    FORM_CONST,
    URL_CONST,
    ApprovisionnementMonnaieSchema
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { CreationApproCompteEmoneySchema } from '../../../util/validation';

const defaultFields = {
    montant: 0,
    selectedMonnaie: {},
    selectedCompte: {}
};

export function ApproCompteEmoneyForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        organisation,
        comptes,
        etatCompte,
        ecouteurApproMonnaie
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        organisation: reduxState.organisation,
        comptes: reduxState.comptes,
        etatCompte: reduxState.etat.comptes.etat,
        ecouteurApproMonnaie: reduxState.etat.approvisionnementsMonnaie.ecouteur
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const { monnaies } = useGetOrganisationDetail({ organisation });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: { fields: ['comptes'] }
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const formater = (tableau = []) =>
        tableau.map((item) => ({
            ...item,
            code: item.id,
            designation: `${item.institutionFinanciere?.designation} - ${item.numero} - ${item.devise}`
        }));

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.COMPTE,
                functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
                etat: etatCompte,
                formater
            },
            {
                isOnlyListening: true,
                path: DB_NODE.APPROVISIONNEMENT_MONNAIE,
                nodeName: DB_NODE.APPROVISIONNEMENT_MONNAIE,
                ecouteur: ecouteurApproMonnaie,
                listenerFilters: [
                    {
                        property: 'dateCreation',
                        value: Date.now(),
                        operator: FILTER_OPERATOR.SUPERIOR_OR_EQUAL,
                        type: FILTER_TYPE.WHERE
                    }
                ],
                reduxValidator: ApprovisionnementMonnaieSchema
            }
        ]
    });
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const { montant, selectedMonnaie, selectedCompte } = fields;
        const devise = monnaies.find(
            (item) => item.code === selectedMonnaie.code
        )?.devise;
        const compte = comptes.find((item) => item.id === selectedCompte?.code);
        const payload = {
            newApprovisionnementMonnaie: {
                devise,
                montant,
                numeroCompteExterne: compte?.numero,
                institutionFinanciere: {
                    code: compte?.institutionFinanciere?.code
                }
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_APPRO_MONNAIE,
                payload,
                uiValidator: CreationApproCompteEmoneySchema,
                reduxValidator: ApprovisionnementMonnaieSchema,
                fields,
                reduxNodeName: DB_NODE.APPROVISIONNEMENT_MONNAIE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'comptes',
                    etat: etatCompte
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={monnaies}
                    label="Monnaie*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedMonnaie"
                    id="selectedMonnaieCreationApproCompteEmoney"
                    uiValidator={CreationApproCompteEmoneySchema}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={comptes}
                    label="Compte*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCompte"
                    id="selectedCompteCreationApproCompteEmoney"
                    uiValidator={CreationApproCompteEmoneySchema}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    isFloat
                    isInputGroup={false}
                    label="Montant*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    min={0}
                    placeholder="Saisissez le montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="montantCreationApproCompteEmoney"
                    uiValidator={CreationApproCompteEmoneySchema}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
