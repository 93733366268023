import * as yup from 'yup';

/**
 * @description : Schema Tarif Charge
 */
export const TarifChargeFormSchema = yup.object().shape({
    selectedServiceGenerateur: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedCharge: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    pourcentage: yup.number().min(0)
});
