import { useSelector } from 'react-redux';
import { Add, TIME, TODAY, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { StandardReportTemplate } from '../../template';
import { RevendeurSelector } from '../../../../components';

const revendeurMapper = [
    {
        displayName: 'Revendeur',
        field: 'revendeur.designation'
    },
    {
        displayName: 'Type',
        field: 'detail.typeEntite'
    },
    {
        displayName: 'Montant',
        field: 'soldeFermeture',
        isTotal: true
    },
    {
        displayName: 'Commission',
        field: 'detail.valeurTypeEntite.designation'
    },
    {
        displayName: 'Début',
        field: 'detail.periode.dateDebut'
    },
    {
        displayName: 'Fin',
        field: 'detail.periode.dateFin'
    }
];
const commissionMapper = [
    {
        displayName: 'Commission',
        field: 'designation'
    },
    {
        displayName: 'Type',
        field: 'type'
    },
    {
        displayName: 'Montant',
        field: 'montant',
        isTotal: true
    }
];

export function RapportCommissionRevendeurForm({ isByRevendeur = true }) {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const listFormater = (donnees) => {
        const result = [];
        (donnees?.[0]?.listReturn || []).forEach((item) => {
            if (isByRevendeur) {
                const revendeur = {
                    ...item,
                    id: `${item?.revendeur?.reference}`,
                    designation: `${item?.revendeur?.designation || ''}`
                };
                item?.commissions.forEach((commission) => {
                    result.push({
                        ...commission,
                        revendeur,
                        id: `${revendeur?.id}-${commission?.detail?.valeurTypeEntite?.code}-${commission?.detail?.periode?.dateDebut}-${commission?.detail?.periode?.dateFin}`
                    });
                });
            } else {
                item?.commissions.forEach((commission) => {
                    const existing = result.find(
                        (elm) =>
                            elm?.id ===
                            commission?.detail?.valeurTypeEntite?.code
                    );
                    if (existing) {
                        existing.montant = Add(
                            existing.montant,
                            commission?.soldeFermeture
                        );
                    } else {
                        result.push({
                            id: commission?.detail?.valeurTypeEntite?.code,
                            type: commission?.detail?.typeEntite,
                            designation:
                                commission?.detail?.valeurTypeEntite
                                    ?.designation,
                            montant: commission?.soldeFermeture
                        });
                    }
                });
            }
        });
        return result;
    };
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedRevendeur: {},
            selectedType: {},
            selectedTypeExpression: {}
        }
    });
    // const ;
    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <StandardReportTemplate
                dynamicNodeName={`rapportRegularisationStockSuperDealer-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_COMPTE_USER}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={isByRevendeur ? revendeurMapper : commissionMapper}
                isAddTotalTr
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    typeProprietaire: TYPE_ORGANISATION.REVENDEUR,
                    estOrganisation: true,
                    type: 'CommissionMensuelle',
                    estGerant: true,
                    typeOrganisation: nappUser?.typeEntite
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedRevendeur',
                        isRequired: false,
                        displayName: 'Revendeur',
                        callback: (value) => {
                            if (!value?.code || value?.code === 'all')
                                return {};
                            return { codeValeurTypeProprietaire: value?.code };
                        }
                    }
                    // {
                    //     name: 'selectedType.id',
                    //     property: 'type',
                    //     isRequired: true,
                    //     displayName: 'Type'
                    // }
                ]}
            >
                {/* <StaticSelector
                    label="Type : "
                    name="selectedType"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    options={[
                        {
                            id: 'CommissionMensuelle',
                            designation: 'Commission Mensuelle'
                        }
                    ]}
                /> */}
                <RevendeurSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    isAddAditionalOptions
                    idProperty="code"
                />
            </StandardReportTemplate>
        </>
    );
}
