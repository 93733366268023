import { REMBOURSEMENT_PARTIEL } from '@napp-inc/jnapp-util';
import * as yup from 'yup';

/**
 * @description : Schema de Restitution
 */
export const RestitutionFormSchema = yup.object().shape({
    selectedMode: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedAgentLivreur: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    montant: yup.number().when('selectedMode', {
        is: (selectedMode) => selectedMode.code === REMBOURSEMENT_PARTIEL.code,
        then: yup.number().min(1),
        otherwise: yup.number()
    })
});
