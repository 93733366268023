import * as yup from 'yup';

export const FacturationSchema = yup.object().shape({
    produit: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    compte: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    quantite: yup.number().min(1),
    montantMonnaie: yup.number().min(1),
    reference: yup.string()
});

export const DetailFacturationchema = yup.object().shape({
    reference: yup.string(),
    actionEncours: yup.string().required(),
    // coupures: yup.array().min(0),
    coupure: yup.object().shape({
        valeur: yup.number(),
        designation: yup.string()
    }),
    nombre: yup.number()
});
