import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailRegularisationSuperDealerForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'regularisationsStock' });

    const { fields, form } = formState;

    const updateEtat = (etat) => {
        if (!etat) return;
        const payload = {
            etat,
            idRegularisationStock: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_REGULARISATION_STOCK,
                payload,
                fields,
                reduxNodeName: DB_NODE.REGULARISATION_STOCK,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_REGULARISATION_STOCK_BY_ID}
            nodeName={DB_NODE.REGULARISATION_STOCK}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Regularisation stock super dealer"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.STOCK.REGULARISATION_STOCK
                        .ATTENTE_VALIDATION_REGULARISATION_STOCK,
                    CODE_SERVICE.STOCK.REGULARISATION_STOCK
                        .ANNULER_REGULARISATION_STOCK,
                    CODE_SERVICE.STOCK.REGULARISATION_STOCK
                        .VALIDER_REGULARISATION_STOCK
                ]}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity?.etat !== ETAT.ETAT_VALIDER}
                >
                    <div className="d-flex flex-row justify-content-end">
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat !== ETAT.ETAT_VALIDER
                            }
                        >
                            <CustomButtonLoader
                                type="button"
                                className="btn-danger"
                                onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                                text="Annuler"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                            />
                        </ConditionalRenderingWrapper>
                        <UserServiceWrapper
                            services={[
                                CODE_SERVICE.STOCK.REGULARISATION_STOCK
                                    .ATTENTE_VALIDATION_REGULARISATION_STOCK
                            ]}
                        >
                            <ConditionalRenderingWrapper
                                isShouldBeRendered={
                                    entity?.etat === ETAT.ETAT_ENCOURS
                                }
                            >
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-warning ms-5"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                    }
                                    text="Attente Validation"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ATTENTE_VALIDATION
                                    }
                                />
                            </ConditionalRenderingWrapper>
                        </UserServiceWrapper>
                        <UserServiceWrapper
                            services={[
                                CODE_SERVICE.STOCK.REGULARISATION_STOCK
                                    .VALIDER_REGULARISATION_STOCK
                            ]}
                        >
                            <ConditionalRenderingWrapper
                                isShouldBeRendered={
                                    entity?.etat ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            >
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success ms-5"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_VALIDER)
                                    }
                                    text="Valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                />
                            </ConditionalRenderingWrapper>
                        </UserServiceWrapper>
                    </div>
                </ConditionalRenderingWrapper>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
