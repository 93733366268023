import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST,
    LIST_TYPE_RESSOURCE,
    TYPE_RESSOURCE_REMUNERATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper,
    SelectAddTableComponent
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { RessourceSchema as uiValidator } from './validation';

const defaultFields = {
    code: '',
    designation: '',
    selectedTypeRessource: {},
    selectedCompteImputation: {},
    selectedServiceGenerateur: {},
    selectedTypeMarchand: {},
    listTypeMarchand: []
};

const defaultDependencies = {
    fields: ['comptes', 'services', 'categories']
};

export function CreationRessourceForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        comptesImputation,
        etatCompteImputation,
        servicesGenerateur,
        etatServiceGenerateur,
        categoriesMarchand,
        etatCategorieMarchand
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        comptesImputation: reduxState.comptesImputation.map((item) => ({
            ...item,
            ...item.valeurTypeProprietaire
        })),
        etatCompteImputation: reduxState.etat.comptesImputation.etat,
        servicesGenerateur: reduxState.servicesGenerateur,
        etatServiceGenerateur: reduxState.etat.servicesGenerateur.etat,
        categoriesMarchand: reduxState.categoriesMarchand.filter(
            (item) => item.typeEntite === 'Type'
        ),
        etatCategorieMarchand: reduxState.etat.categoriesMarchand.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_COMPTE_IMPUTATION,
                nodeName: REDUX_NODE_NAME.COMPTE_IMPUTATION,
                etat: etatCompteImputation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SERVICE_GENERATEUR,
                nodeName: DB_NODE.SERVICE_GENERATEUR,
                etat: etatServiceGenerateur
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CATEGORIE_MARCHAND,
                nodeName: DB_NODE.CATEGORIE_MARCHAND,
                etat: etatCategorieMarchand
            }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const {
            code,
            designation,
            selectedCompteImputation,
            selectedServiceGenerateur,
            selectedTypeRessource,
            listTypeMarchand
        } = fields;
        const payloadToAdd = {};
        const findTypeRessource = LIST_TYPE_RESSOURCE.find(
            (item) => item.code === selectedTypeRessource.code
        );
        if (
            findTypeRessource &&
            findTypeRessource.code === TYPE_RESSOURCE_REMUNERATION.code
        ) {
            payloadToAdd.estObjectif = true;
        }
        const payload = {
            newRessource: {
                code,
                designation,
                type: selectedTypeRessource.code,
                compteImputation: selectedCompteImputation,
                serviceGenerateur: {
                    code: selectedServiceGenerateur.code
                },
                ...payloadToAdd
            },
            typesMarchand: listTypeMarchand.map(
                ({ code: codeTypeMarchand }) => codeTypeMarchand
            ),
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_RESSOURCE,
                payload,
                fields,
                reduxNodeName: DB_NODE.RESSOURCE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'comptes',
                    etat: etatCompteImputation
                },
                {
                    dependency: 'services',
                    etat: etatServiceGenerateur
                },
                {
                    dependency: 'categories',
                    etat: etatCategorieMarchand
                }
            ]}
        >
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Code*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le code"
                    formDispatcher={formDispatcher}
                    name="code"
                    id="codeCreationCharge"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Designation*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le nom"
                    formDispatcher={formDispatcher}
                    name="designation"
                    id="designationCreationCharge"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={comptesImputation}
                    label="Compte imputation*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCompteImputation"
                    id="selectedCompteImputationCreationCharge"
                    formState={formState}
                    defaultOption="Sélectionnez compte Imputation"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={servicesGenerateur}
                    label="Service generateur*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedServiceGenerateur"
                    id="selectedServiceGenerateurCreationCharge"
                    idProperty="code"
                    formState={formState}
                    defaultOption="Sélectionnez service générateur"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={LIST_TYPE_RESSOURCE}
                    label="Type*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeRessource"
                    id="selectedTypeRessourceCreationCharge"
                    formState={formState}
                    defaultOption="Sélectionnez ressource"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <SelectAddTableComponent
                label="Type marchand"
                defaultOption="Sélectionnez type Marchand"
                options={categoriesMarchand}
                dropdownPropertyToDisplay="designation"
                formState={formState}
                formDispatcher={formDispatcher}
                id="selectAddTableCreationRessource"
                selectedItemName="selectedTypeMarchand"
                selecat
                listSelectedItemName="listTypeMarchand"
                uiValidator={uiValidator}
                mapper={[
                    {
                        position: 1,
                        displayName: 'code',
                        field: 'code'
                    },
                    {
                        position: 2,
                        displayName: 'designation',
                        field: 'designation'
                    }
                ]}
                // divTableClassName={tableDivTableClassName}
            />
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
