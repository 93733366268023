export const schema = [
    {
        title: 'Designation',
        description: 'fonction.designation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
