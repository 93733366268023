import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    ETAT,
    formInitialState,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { URL_CONST } from '../../util';
import { CustomToast } from '../alert';
import { CustomConfirmButton } from '../button';
import { UserServiceWrapper } from '../container';

const defaultFields = {
    actionEncours: ''
};
/**
 * Ce composant affiche les actions qu'on peut effectuer sur une commande litigieuse emoney
 * Elle est rajoutée à la liste des commandes emoney pour dans le rapport emoney
 * @param {Object} param0 Objet contenant commande et nodeName
 * @param {Object} param0.commande la commande emoney
 * @returns React.Component
 */
export function CommandeLitigieuseEmoneyActionsComponent({
    commande,
    formState: parentFormState,
    formDispatcher: parentFormDispatcher
}) {
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const onSuccess = () => {
        const isValueATable = parentFormState?.fields?.isValueATable;
        const newTable = (
            isValueATable
                ? parentFormState?.elements?.mouvements?.value?.[0]
                : parentFormState?.elements?.mouvements?.value
        )?.filter((item) => item?.id !== commande?.original?.id);
        parentFormDispatcher({
            type: FORM_CONST.FETCH_ELEMENT_SUCCESS,
            payload: {
                element: 'mouvements',
                data: isValueATable ? [newTable] : newTable
            }
        });
    };
    const updateEtat = (etat) => {
        const actionEncours = etat;
        const payload = {
            etat,
            idCommandeEmoney: commande?.original?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_COMMANDE_EMONEY,
                payload,
                fields,
                onSuccess
            })
        );
    };
    return (
        <UserServiceWrapper
            services={[
                { code: 'test' },
                CODE_SERVICE.CODE_SERVICE_SENDER
                    .MISE_A_JOUR_COMMANDE_LITIGIEUX_EMONEY
            ]}
        >
            {form && form.status === FORM_CONST.MODIFICATION_ECHEC && (
                <CustomToast error={form.error} />
            )}
            {commande?.original?.etat === ETAT.ETAT_ENCOURS && (
                <>
                    <CustomConfirmButton
                        type="button"
                        text="Valider"
                        className="btn-success"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_VALIDER
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation livraison"
                    />
                    {' | '}
                    <CustomConfirmButton
                        type="button"
                        text="Annuler"
                        className="btn-danger"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_ANNULER
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation Annulation"
                    />
                </>
            )}
        </UserServiceWrapper>
    );
}
