import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    ServiceCreateRelPath,
    SystServiceViewLayoutRelPath
} from '../../../routes/registration/administration/admin-rel-path';
import { ListServiceForm } from './list-service';

export function SystServiceViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Syst Service</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${SystServiceViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.SERVICE_CODE_WEB
                                        .CODE_SERVICE_ADMINISTRATION_NAPP
                                        .AJOUTER_SYST_SERVICE_SYSTEM
                                ]}
                                destination={ServiceCreateRelPath.path}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListServiceForm />
        </div>
    );
}
