export const schema = [
    {
        description: 'superDealer.designation',
        title: 'Super Dealer',
        className: 'border-bottom'
    },
    {
        description: 'marchand.designation',
        title: 'Marchand',
        className: 'border-bottom'
    },
    {
        description: 'fileName',
        title: 'Fichier',
        className: 'border-bottom'
    },
    {
        description: 'dateImportation',
        title: 'Date Importation',
        className: 'border-bottom'
    },
    {
        description: 'nombre',
        title: 'Opérations',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'Création',
        className: 'border-bottom'
    }
];
