import { CustomDropdown } from '../dropdown';
import { CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner un type de valeur située dans le tableau otpions
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function StaticSelector({
    formDispatcher,
    formState,
    idProperty = 'id',
    name = 'selectedStatic',
    options = [],
    label = 'Valeur : ',
    defaultOption
}) {
    return (
        <CustomRow>
            <CustomDropdown
                options={options}
                label={label}
                labelClassName="col-12"
                divClassName="col-12"
                formDispatcher={formDispatcher}
                name={name}
                id={`${name}-staticSelector`}
                formState={formState}
                idProperty={idProperty}
                defaultOption={defaultOption}
            />
        </CustomRow>
    );
}
