import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';
import { ListGenericForm } from '../../../components';

export function ListTauxOrganisationKindistributionForm() {
    const listFormater = (data) => {
        if (!data || !data?.length) return [];
        const newDataList = data.map((item) => {
            const objectDevise = {};
            if (item?.details?.length) {
                item.details.forEach((element) => {
                    objectDevise[element?.type] = element;
                });
            }
            return {
                ...item,
                ...objectDevise
            };
        });
        return newDataList;
    };
    return (
        <ListGenericForm
            reduxPropertyName="tauxOrganisationKindistribution"
            functionName={URL_CONST.GET_LIST_TAUX_KINDISTRIBUTION_INITIALS}
            nodeName={REDUX_NODE_NAME.TAUX_ORGANISATION_KINDISTRIBUTION}
            listFormater={listFormater}
            mapper={[
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'Organisation'
                },
                {
                    field: 'typeProprietaire',
                    displayName: 'Type Organisation'
                },
                {
                    field: 'devise',
                    displayName: 'devise'
                },
                {
                    field: 'TauxAchat.valeur',
                    displayName: 'Taux Achat'
                },
                {
                    field: 'TauxVente.valeur',
                    displayName: 'Taux Vente'
                },
                {
                    field: 'TauxSystem.valeur',
                    displayName: 'Taux System'
                },
                {
                    field: 'etat',
                    displayName: 'etat'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ]}
        />
    );
}
