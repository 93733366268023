import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

const defaultFields = {
    selectedPartenaireConfiguration: {}
};

const defaultDependencies = { fields: ['api'] };

export function CreationShutDownAndUpApiPartnerForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        configurationApiPartenaire,
        etatConfigurationApiPartenaire
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        configurationApiPartenaire: reduxState.configurationApiPartenaire.map(
            (item) => ({
                ...item,
                designation: `${item?.marchand?.designation}`
            })
        ),
        etatConfigurationApiPartenaire:
            reduxState.etat.configurationApiPartenaire.etat
    }));
    /**
     * Common form processes
     */
    const { idToken /* , creator */ } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CONFIGURATION_API_PARTENAIRE,
                nodeName: REDUX_NODE_NAME.CONFIGURATION_API_PARTENAIRE,
                etat: etatConfigurationApiPartenaire
                // payload: { creator: 'qlxrcWKltYhDhrIG90oSyeU0eVN2' }
            }
        ]
    });
    const getStateOfConfiguration = () => {
        const { selectedPartenaireConfiguration } = fields;
        return configurationApiPartenaire.find(
            (item) => item.id === selectedPartenaireConfiguration?.id
        )?.estActif;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedPartenaireConfiguration } = fields;
        if (!selectedPartenaireConfiguration.id) return;
        const estActif = getStateOfConfiguration();
        const payload = {
            idConfigurationMarchandApi: selectedPartenaireConfiguration.id,
            // creator,
            estActif: !estActif
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName:
                    URL_CONST.POST_TRAITEMENT_CONFIGURATION_MARCHAND_API,
                payload,
                fields,
                reduxNodeName:
                    REDUX_NODE_NAME.TRAITEMENT_CONFIGURATION_MARCHAND_API,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'api',
                    etat: etatConfigurationApiPartenaire
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    label="Partenaire"
                    options={configurationApiPartenaire}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    defaultOption="Selectionner Configuration"
                    formDispatcher={formDispatcher}
                    name="selectedPartenaireConfiguration"
                    id="facturationSelectedPartenaireConfigurationId"
                    formState={formState}
                    idProperty="id"
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text={`${
                            !getStateOfConfiguration()
                                ? 'Activer'
                                : 'Désactiver'
                        }`}
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
