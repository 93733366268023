import * as yup from 'yup';

/**
 * @description : Schema de fonction
 */

export const CreationLimiteCreditSchema = yup.object().shape({
    selectedUser: yup.object().shape({
        uid: yup.string().required(),
        designation: yup.string().required()
    }),
    limit: yup.number().min(1)
});
