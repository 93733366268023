import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper,
    RadioButtonWrapper
} from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';
import { DetailChargeFonctionUpdate } from './detail-charge-fonction';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailFonctionUpdateForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher,
        isShouldBeRendered
    } = useGetDetailForm({
        nodeName: 'fonctionUpdate',
        listEtatFinaux: [ETAT.ETAT_ANNULER, ETAT.ETAT_ADOPTE]
    });

    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const payload = {
            etat,
            creator,
            idModificationFonction: entity?.id
        };
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_ADOPTE;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_MODIFICATION_FONCTION,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.FONCTION_UPDATE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_MODIFICATION_FONCTION_BY_ID}
            nodeName={REDUX_NODE_NAME.FONCTION_UPDATE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Fonctions"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <RadioButtonWrapper>
                <DetailChargeFonctionUpdate id={id} />
            </RadioButtonWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .SERVICE_FONCTION.METTRE_A_JOUR_FONCTION
                ]}
            >
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            isShouldBeRendered={isShouldBeRendered}
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            isShouldBeRendered={isShouldBeRendered}
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ADOPTE)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ADOPTE
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
