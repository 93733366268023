import { DB_NODE } from '@napp-inc/jnapp-util';
import {
    CustomRow,
    CustomSwitchButton,
    ListGenericForm
} from '../../../components';
import { URL_CONST } from '../../../util';
import { useComponentSwitcher } from '../../../util/hook';
import { listEmployeMapper, listUserMapper } from './detail-fields';

export function ListDroitAccesForm() {
    const { componentSwitcher, setComponentSwitcher } = useComponentSwitcher();
    const callback = (checked) => {
        setComponentSwitcher({
            ...componentSwitcher,
            isAllEmploye: checked
        });
    };
    return (
        <>
            <CustomRow>
                <CustomSwitchButton
                    text="afficher tous les employés"
                    defaultChecked={componentSwitcher?.isAllEmploye}
                    name="isAllEmploye"
                    divClassName="col-md-8"
                    callback={callback}
                />
            </CustomRow>
            <ListGenericForm
                isLinkToDetail
                reduxPropertyName={
                    componentSwitcher?.isAllEmploye
                        ? 'employesOrganisation'
                        : 'users'
                }
                functionName={
                    componentSwitcher?.isAllEmploye
                        ? URL_CONST.GET_LIST_EMPLOYE_ORGANISATION
                        : URL_CONST.GET_LIST_USER
                }
                nodeName={
                    componentSwitcher?.isAllEmploye
                        ? DB_NODE.EMPLOYE
                        : DB_NODE.USER
                }
                mapper={
                    componentSwitcher?.isAllEmploye
                        ? listEmployeMapper
                        : listUserMapper
                }
            />
        </>
    );
}
