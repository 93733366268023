import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { TransfertCaisseKinDBDetailFormSchema as uiValidator } from './validation';

export function DetailTransfertInterInstitutionnelForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        reduxProps,
        uid
        // oid
    } = useGetDetailForm({
        nodeName: 'transfertKinDb',
        defaultFields: {
            actionEncours: '',
            selectedCaisse: {},
            numeroReference: ''
        },
        defaultDependencies: { entity: true, fields: ['caissesActiveUser'] },
        listNode: [
            {
                name: 'caissesActiveUser',
                format: (item) =>
                    item.map((element) => ({
                        ...element,
                        designation: `${element?.designation || ''}-${
                            element?.devise || ''
                        }`
                    }))
            }
        ],
        listFunctions: [
            {
                id: 'caissesActiveUser',
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                payload: { estOrganisation: true },
                nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER
            }
        ]
    });

    const { form, fields } = formState;

    const estEmoney = !!entity?.caisseB?.estEmoney;

    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payloadToAdd = {};
        if (fields.selectedCaisse?.id) {
            payloadToAdd.idCaisse = fields.selectedCaisse?.id;
        }
        if (
            estEmoney &&
            !fields.numeroReference &&
            entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Veuillez saisir le numéro de référence`,
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        if (
            estEmoney &&
            fields.numeroReference &&
            entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
        ) {
            payloadToAdd.numeroReference = fields.numeroReference;
        }
        const payload = {
            etat,
            idTransfert: entity?.id,
            ...payloadToAdd
        };
        const validator = {};
        if (etat === ETAT.ETAT_ATTENTE_VALIDATION) {
            validator.uiValidator = uiValidator;
        }
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_TRANSFERT_CAISSE_KINDB,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TRANSFERT_KINDB,
                reduxDispatcher,
                ...validator
            })
        );
    };

    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TRANSFERT_CAISSE_KINDB_BY_ID}
            nodeName={REDUX_NODE_NAME.TRANSFERT_KINDB}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail transfert"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
            listDependencies={[
                {
                    dependency: 'caissesActiveUser',
                    etat: reduxProps?.etatCaissesActiveUser
                }
            ]}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .INITIER_TRANSFERT_CAISSE_KINDB,
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB,
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .ATTENTE_ENCAISSEMENT_TRANSFERT_CAISSE_ORGANISATION_KINDB,
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .VALIDER_TRANSFERT_CAISSE_KINDB,
                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                        .APPROUVER_TRANSFERT_CAISSE_EXTERNE
                ]}
            >
                <UserServiceWrapper
                    services={[
                        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                            .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB
                    ]}
                >
                    <CustomRow
                        isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                    >
                        <CustomDropdown
                            options={
                                reduxProps?.caissesActiveUser.filter(
                                    (item) => item?.devise === entity?.devise
                                ) || []
                            }
                            label="Caisse*"
                            defaultOption="Sélectionner caisse"
                            disabled={
                                !reduxProps?.caissesActiveUser.filter(
                                    (item) => item?.devise === entity?.devise
                                )?.length
                            }
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            name="selectedCaisse"
                            id="selectedCaisseCreationVersementAgentVendeur"
                            formState={formState}
                            idProperty="id"
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                </UserServiceWrapper>
                <UserServiceWrapper
                    services={[
                        { code: 'test' },
                        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                            .VALIDER_TRANSFERT_CAISSE_KINDB
                    ]}
                >
                    <CustomRow isShouldBeRendered={estEmoney}>
                        <CustomInput
                            type="text"
                            label="Numéro Référence* :"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            isInputGroup={false}
                            placeholder="Numéro Référence"
                            formDispatcher={formDispatcher}
                            name="numeroReference"
                            id="creationNumeroReferenceCreationTransfertInterInstitutionnelId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                </UserServiceWrapper>
                <CustomRow>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            entity?.etat !== ETAT.ETAT_VALIDER &&
                            entity?.etat !== ETAT.ETAT_ANNULER
                        }
                    >
                        <CustomCol className="offset-4 offset-md-8 col-3 col-md-2">
                            <CustomButtonLoader
                                type="button"
                                className="btn-danger"
                                onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                                text="Annuler"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                    >
                        <UserServiceWrapper
                            services={[
                                CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                                    .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB
                            ]}
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                    }
                                    text="valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ATTENTE_VALIDATION
                                    }
                                />
                            </CustomCol>
                        </UserServiceWrapper>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            entity?.userEncaissement === uid &&
                            entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                        }
                    >
                        <UserServiceWrapper
                            services={[
                                CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                                    .INITIER_TRANSFERT_CAISSE_KINDB
                            ]}
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_VALIDER)
                                    }
                                    text="valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                />
                            </CustomCol>
                        </UserServiceWrapper>
                    </ConditionalRenderingWrapper>
                    <UserServiceWrapper
                        services={[
                            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                                .APPROUVER_TRANSFERT_CAISSE_EXTERNE
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat === ETAT.ETAT_PRE_ENCOURS
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_ENCOURS)
                                    }
                                    text="valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ENCOURS
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
