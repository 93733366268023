import { DB_NODE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListServiceForm() {
    return (
        <ListGenericForm
            reduxPropertyName="allServices"
            functionName={URL_CONST.GET_ALL_SYS_SERVICE}
            nodeName={DB_NODE.SYST_SERVICE}
            mapper={[
                {
                    field: 'code',
                    displayName: 'Code',
                    position: 1
                },
                {
                    field: 'designation',
                    displayName: 'Désignation',
                    position: 2
                }
            ]}
        />
    );
}
