import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { RapportRecruteurRevendeurForm } from './rapport-recruteur-revendeur';
import { RapportRecruteurParRevendeurForm } from './rapport-recruteur-par-revendeur';
import { RapportListRevendeurRecruteur } from './list-revendeur';

export function RapportRecruteurRevendeurViewLayout() {
    const [key, setKey] = useState('global');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Rapport Recruteur</h1>
            </div>
            <Tabs
                defaultActiveKey="global"
                id="fill-tab-example"
                className="mb-3"
                onSelect={setKey}
            >
                <Tab eventKey="global" title="Global">
                    {key === 'global' && <RapportRecruteurRevendeurForm />}
                </Tab>
                <Tab eventKey="revendeur" title="Par revendeur">
                    {key === 'revendeur' && (
                        <RapportRecruteurParRevendeurForm />
                    )}
                </Tab>
                <Tab eventKey="list" title="List revendeur">
                    {key === 'list' && <RapportListRevendeurRecruteur />}
                </Tab>
            </Tabs>
        </div>
    );
}
