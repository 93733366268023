import { DB_NODE, ONLY_FETCH, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../../components';

export function ListBudget() {
    const renderContent = () => (
        <ListGenericForm
            fetchType={ONLY_FETCH}
            reduxPropertyName="elaborations"
            functionName={URL_CONST.GET_LIST_ELABORATION_AMENDEMENT_INITIALS}
            nodeName={DB_NODE.ELABORATION_AMENDEMENT}
            mapper={[
                {
                    field: 'type',
                    displayName: 'Budget',
                    position: 1
                },
                {
                    field: 'estPrevisionnel',
                    displayName: 'Prévisionnel Action',
                    position: 2
                },
                {
                    field: 'typeEntite',
                    displayName: 'Type',
                    position: 3
                },
                {
                    field: 'typeProprietaire',
                    displayName: 'Element',
                    position: 4
                },
                {
                    field: 'estVariable',
                    displayName: 'Variable',
                    position: 4
                },
                {
                    field: 'etat',
                    displayName: 'etat',
                    position: 6
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création',
                    position: 7
                },
                {
                    field: 'dateDerniereModification',
                    displayName: 'Modification',
                    position: 8
                }
            ]}
        />
    );
    return renderContent();
}
