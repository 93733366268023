import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { RayonSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    rayon: '',
    rayons: [],
    codeRayon: '',
    colonne: '',
    codeColonne: '',
    colonnes: [],
    emplacement: 0
};

export function CreationRayonShowRoom() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const onSuccess = ({ element }) => {
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                element,
                DB_NODE.RAYON_COLONNE
            )
        );
    };
    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!fields.rayons.length) return;

        const payload = {
            type: 'Rayon',
            list: fields.rayons,
            creator
        };

        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_LIST_RAYON_SHOWROOM,
                payload,
                fields,
                onSuccess,
                reduxDispatcher
            })
        );
    };

    /**
     * L'action qui sera déclenchée pour l'ajout de nouvelles colonnes
     * @returns
     */
    const onAddColonne = () => {
        if (!fields.colonne) return;
        const data = {
            designation: fields.colonne,
            code: fields.codeColonne,
            nombreEmplacementPhysique: fields.emplacement
        };
        const newState = {
            ...initialState,
            fields: {
                ...initialState.fields,
                colonnes: fields.colonnes,
                rayon: fields.rayon,
                codeRayon: fields.codeRayon,
                rayons: fields.rayons
            }
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
            payload: {
                newState,
                field: 'colonnes',
                data,
                identifier: 'code'
            }
        });
    };
    const onAddRayon = () => {
        if (!fields.rayon || !fields.colonnes.length) return;
        const data = {
            designation: fields.rayon,
            code: fields.codeRayon,
            details: fields.colonnes
        };
        const newState = {
            ...initialState,
            fields: {
                ...initialState.fields,
                rayons: fields.rayons
            }
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
            payload: {
                newState,
                field: 'rayons',
                data,
                identifier: 'code'
            }
        });
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            {/* Recupérer  */}
            {/* Pour la creation des Rayons */}
            <CustomRow>
                <CustomLabel text="Rayon*" className="col-sm-2" />
                <CustomInput
                    id="creationRayonFormId"
                    name="rayon"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Nommer le Rayon"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    id="creationRayonFormCodeId"
                    name="codeRayon"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Saisissez le code du rayon"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>

            {/* Pour la creation des colonnes */}
            <CustomRow>
                <CustomLabel text="colonne*" className="col-sm-2" />
                <CustomInput
                    id="creationRayonFormColonneId"
                    name="colonne"
                    isInputGroup={false}
                    divClassName="col-sm-3"
                    placeholder="Nommer la colonne"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    id="creationRayonFormCodeColonneId"
                    name="codeColonne"
                    isInputGroup={false}
                    divClassName="col-sm-3"
                    placeholder="saisissez le code de la colonne"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    id="creationRayonFormEmplacementId"
                    name="emplacement"
                    isInputGroup={false}
                    type="number"
                    divClassName="col-sm-3"
                    placeholder="Saisissez le nombre d'emplacement"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={onAddColonne}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.colonnes.length}>
                <SimpleTable
                    data={fields.colonnes}
                    formDispatcher={formDispatcher}
                    identifier="code"
                    listSelectedItemName="colonnes"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            field: 'designation',
                            displayName: 'Colonne'
                        },
                        {
                            position: 3,
                            field: 'code',
                            displayName: 'Code Colonne'
                        },
                        {
                            position: 4,
                            field: 'nombreEmplacementPhysique',
                            displayName: 'Nbre Emplacement'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-primary"
                        onClick={onAddRayon}
                        text="Ajouter"
                        type="button"
                    />
                </CustomCol>
            </CustomRow>
            <CustomCollapse isIn={!!fields.rayons.length}>
                <SimpleTable
                    data={fields.rayons}
                    formDispatcher={formDispatcher}
                    identifier="code"
                    listSelectedItemName="rayons"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            field: 'designation',
                            displayName: 'Colonne'
                        },
                        {
                            position: 3,
                            field: 'code',
                            displayName: 'Code Colonne'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer ShowRoom"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
