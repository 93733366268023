import * as yup from 'yup';

/**
 * @description : Schema de Application Mobile Version Obsolete form
 */

export const NotificationPushFormSchema = yup.object().shape({
    message: yup.string().min(10).max(300),
    title: yup.string().min(2).max(50),
    url: yup.string(),
    meta: yup.string(),
    metaToParse: yup.string(),
    selectedSujet: yup.object().shape({
        valeurTypeEntite: yup.string().required(),
        title: yup.string().required()
    })
});
