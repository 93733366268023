// import { useCallback } from 'react';
import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    DB_NODE,
    utc235959,
    utc000000,
    MOYEN_PAIEMENT
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomLabel,
    CustomInputDate,
    FormWrapper
} from '../../../components';
import { URL_CONST, formaterCaisses } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ProduitMaterielSchema as uiValidator } from './validation';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    dateDebut: '',
    dateFin: '',
    selectedSecteur: {},
    selectedRessource: {},
    montantNotifier: 0,
    selectedCompte: {},
    selectedMarchandMobileMoney: {},
    selectedMoyenPaiement: {},
    selectedCaisse: {},
    referencePaiement: '',
    marchandMobileMoneyToSelect: {
        code: '',
        designation: ''
    }
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: [
        'secteurs',
        'ressources',
        'comptesDeclaration',
        'caisses',
        'caisse'
    ]
};

/**
 * @description elements
 */

const defaultElements = ['marchandMobileMoney'];

export function CreationDeclarationRessourcesForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        secteursKinDB,
        etatSecteurs,
        ressources,
        etatRessource,
        comptesDeclaration,
        etatCompteDeclaration,
        caisses,
        etatCaisses,
        caisse,
        etatCaisse
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        secteursKinDB: reduxState.secteursRessourcesKindb,
        etatSecteurs: reduxState.etat.secteursRessourcesKindb.etat,
        ressources: reduxState.ressources,
        etatRessource: reduxState.etat.ressources.etat,
        comptesDeclaration: reduxState.comptesDeclaration.map((item) => ({
            ...item,
            designation: `${item?.typeEntite || ''} ${
                item?.valeurTypeProprietaire?.designation || ''
            } ${item?.devise}`
        })),
        etatCompteDeclaration: reduxState.etat.comptesDeclaration.etat,
        caisses: reduxState.caissesActiveOrganisation,
        etatCaisses: reduxState.etat.caissesActiveOrganisation.etat,
        caisse: formaterCaisses(reduxState.caissesEmoney),
        etatCaisse: reduxState.etat.caissesEmoney.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    /**
     * @description fonction pour fetcher les marchands
     */
    const onSearchMarchand = () => {
        const { status: etat } = elements.marchandMobileMoney;
        formDispatcher(
            fetchElement({
                idToken,
                functionName:
                    URL_CONST.GET_LIST_MARCHAND_BY_CATEGORIE_AND_TEXT_DESIGNATION,
                elementPropertyName: 'marchandMobileMoney',
                etat,
                payload: {
                    typeEntite: fields.selectedTypeBeneficiaire?.code,
                    value: fields.searchInput
                },
                reduxDispatcher
            })
        );
    };

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_COMPTE_DECLARATION,
                nodeName: REDUX_NODE_NAME.COMPTE_DECLARATION,
                etat: etatCompteDeclaration
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_RESSOURCE_KIN_DB,
                nodeName: REDUX_NODE_NAME.SECTEUR_RESSOURCE_KINDB,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_RESSOURCE,
                nodeName: DB_NODE.RESSOURCE,
                etat: etatRessource
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_ORGANISATION,
                etat: etatCaisses,
                payload: { estOrganisation: true }
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: REDUX_NODE_NAME.CAISSE_EMONEY,
                // formater: formaterCaisses,
                payload: {
                    estOrganisation: true,
                    estEmoney: true,
                    typeProprietaire: 'SuperDealer'
                },
                etat: etatCaisse
            }
        ]
    });

    // const getCodeSecteur = useCallback(() => {
    //     const codeSecteur = secteursKinDB.find(
    //         (item) => item.id === fields.selectedSecteur.id
    //     )?.code;
    //     return codeSecteur;
    // }, [fields.selectedSecteur.id, secteursKinDB]);

    const typeMoyenPaiement = comptesDeclaration.find(
        (item) => item?.id === fields?.selectedCompte?.id
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        const { dateDebut, dateFin } = fields;
        const secteur = secteursKinDB.find(
            (item) => item?.id === fields?.selectedSecteur?.id
        );
        const payloadToAdd = {};

        if (typeMoyenPaiement?.typeEntite === 'CompteDistributeur') {
            payloadToAdd.typeMoyenPaiement = typeMoyenPaiement.typeEntite;
        }

        const payload = {
            newDeclarationRessource: {
                ressource: {
                    code: fields?.selectedRessource?.code
                },
                secteur: {
                    code: secteur?.code
                },
                valeurMoyenPaiement: {
                    reference: fields.selectedCaisse.id
                },
                montant: fields?.montantNotifier,
                numeroReference: fields?.referencePaiement,
                typeMoyenPaiement: typeMoyenPaiement?.typeEntite,
                valeurTypeMarchand: {
                    code: fields?.selectedMarchandMobileMoney?.code
                }
            },
            periode: {
                dateDebut: utc000000(dateDebut),
                dateFin: utc235959(dateFin, false)
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_DECLARATION_RESSOURCE,
                reduxNodeName: REDUX_NODE_NAME.COMPTE_DECLARATION,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'ressources',
                    etat: etatRessource
                },
                {
                    dependency: 'comptesDeclaration',
                    etat: etatCompteDeclaration
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisses
                },
                {
                    dependency: 'caisse',
                    etat: etatCaisse
                }
            ]}
        >
            <CustomRow>
                <CustomLabel text="Période*" className="col-sm-2" />
                <CustomInputDate
                    id="creationDeclarationRessourceDateDebutId"
                    name="dateDebut"
                    type="date"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Date début"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInputDate
                    id="creationDeclarationRessourceDateFinId"
                    name="dateFin"
                    type="date"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Date début"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={secteursKinDB}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le secteur"
                    label="Secteur*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedSecteur"
                    id="selectedSecteurId"
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Ressources*" className="col-sm-2" />
                <CustomDropdown
                    options={ressources}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la ressource"
                    divClassName="col-12 col-sm-5"
                    name="selectedRessource"
                    id="declartionRessourceSelectedRessourceId"
                    uiValidator={uiValidator}
                />
                <CustomInput
                    isInputGroup={false}
                    divClassName="col-12 col-sm-5"
                    placeholder="Saisissez le montant notifié..."
                    formDispatcher={formDispatcher}
                    name="montantNotifier"
                    type="number"
                    id="declarationRessourceMontantNotifierId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                {/* <CustomAddRemoveButton
                            className='col-sm-1'
                            callback={addBeneficiaire}
                        /> */}
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        MOYEN_PAIEMENT.MOYEN_PAIEMENT_CASH,
                        MOYEN_PAIEMENT.MOYEN_PAIEMENT_EMONEY
                    ]}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner moyen paiement"
                    label="Moyen de Paiement*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedMoyenPaiement"
                    id="declartionRessourceSelectedMoyenPaiementId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={fields.selectedMoyenPaiement.code}>
                <CustomDropdown
                    options={
                        fields.selectedMoyenPaiement.code ===
                        MOYEN_PAIEMENT.CASH
                            ? caisses
                            : caisse
                    }
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner caisse"
                    label="Caisse*"
                    idProperty="id"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedCaisse"
                    id="declartionRessourceSelectedCaisseId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            {/* <CustomRow isShouldBeRendered={fields.selectedMoyenPaiement.code}>
                <CustomDropdown
                    options={
                        fields.selectedMoyenPaiement.code ===
                        MOYEN_PAIEMENT.CASH
                            ? caisses.filter((item) => item.estEmoney === false)
                            : caisses.filter((item) => item.estEmoney === true)
                    }
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner caisse"
                    label="Caisse*"
                    idProperty="id"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedCaisse"
                    id="declartionRessourceSelectedCaisseId"
                    uiValidator={uiValidator}
                />
            </CustomRow> */}
            <CustomRow>
                <CustomDropdown
                    options={comptesDeclaration}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Selectionner le compte bénéficiaire"
                    label="Compte*"
                    idProperty="id"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedCompte"
                    id="declartionRessourceSelectedCompteId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Reference*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez la reférence du paiement"
                    formDispatcher={formDispatcher}
                    name="referencePaiement"
                    id="declarationRessourceReferencePaiementId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            {/* Recupérer le marchandMobileMoney */}
            {typeMoyenPaiement?.typeEntite === 'CompteDistributeur' ? (
                <CustomRow>
                    <CustomLabel
                        text="Rechecher Marchand*"
                        className="col-sm-2"
                    />
                    <CustomInput
                        isInputGroup={false}
                        divClassName="col-12 col-sm-7"
                        placeholder="Marchand"
                        formDispatcher={formDispatcher}
                        name="searchInput"
                        id="creationDeclarationRessourceSearchInputId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                    <CustomButtonLoader
                        className="col-2 h-25"
                        type="button"
                        text="rechercher"
                        onClick={onSearchMarchand}
                        disabled={
                            elements?.marchandMobileMoney?.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        isActionEncours={
                            elements?.marchandMobileMoney?.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                    />
                </CustomRow>
            ) : null}
            {elements.marchandMobileMoney?.value?.length ? (
                <CustomRow>
                    <CustomLabel text="Marchand" className="col-sm-2" />
                    <CustomDropdown
                        options={elements?.marchandMobileMoney?.value}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner le marchand"
                        divClassName="col-12 col-sm-4"
                        name="selectedMarchandMobileMoney"
                        id="selectedMarchandMobileMoneyId"
                        uiValidator={uiValidator}
                    />
                </CustomRow>
            ) : null}
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Soumettre declaration"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
