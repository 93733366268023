import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts';

export function BaseChart({
    divClassName = 'container-fluid',
    style = {},
    options = {}
}) {
    return (
        <div
            className={divClassName}
            style={{ width: '100%', height: '100%', ...style }}
        >
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
}
