import { buildYupErrorObject, FORM_CONST } from '@napp-inc/jnapp-util';
import { InputFeedback } from './input-feedback';

/**
 * Composant personnalisé CustomInput pour sélectionner des fichiers
 * @param {Object} param0 object qui contient les props du composant:
 * @param {string} param0.id Identifiant unique du composant
 * @param {string} param0.name Nom du champ du formulaire qui correspond à une propriété du state
 * @param {boolean} param0.disabled Désactive le composant ou Active le composant
 * @param {string} param0.label Texte affiché dans le label du composant
 * @param {string} param0.formDispatcher Fonction qui permet d'envoyer des actions au store du composant
 * @param {string} param0.labelClassName Classe CSS du label
 * @param {string} param0.inputClassName Classe CSS du input du composant
 * @param {string} param0.divClassName Classe CSS du div du composant
 * @param {boolean} param0.readOnly Indique si le composant est en lecture seule ou non
 * @param {Object} param0.formState State du composant
 * @param {Object} param0.uiValidator Schema de validation du composant
 * * @param {Boolean} param0.multiple Indique si le composant doit gérer plusieurs fichiers
 * @returns un input de type file
 */
export function CustomInputFile({
    id,
    accept = '',
    name,
    disabled = false,
    label,
    formDispatcher,
    labelClassName = 'col-sm-2',
    inputClassName,
    readOnly = false,
    formState = {},
    uiValidator,
    divClassName = 'col-sm-10',
    multiple = false,
    isImage = false
}) {
    const { error, fields, form } = formState;
    const handleChange = async (event) => {
        const { files } = event.target;
        const currentValue = multiple ? files : files[0];
        if (isImage && !multiple) {
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field: 'logo',
                    data: URL.createObjectURL(currentValue)
                }
            });
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field: 'file',
                    data: currentValue
                }
            });
        } else {
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: { field: name, data: currentValue }
            });
        }
        if (uiValidator && form.isSubmitted) {
            uiValidator
                .validate(
                    { ...fields, [name]: currentValue },
                    {
                        abortEarly: false
                    }
                )
                .then(() => {
                    formDispatcher({
                        type: FORM_CONST.VALIDATION_SUCCESS
                    });
                })
                .catch((erreur) => {
                    if (erreur.name === 'ValidationError') {
                        const err = buildYupErrorObject(erreur);
                        formDispatcher({
                            type: FORM_CONST.SET_FIELDS_ERROR,
                            payload: {
                                message: { ...err },
                                status: FORM_CONST.CREATION_ECHEC
                            }
                        });
                    }
                });
        }
    };
    return (
        <>
            <label htmlFor={id} className={`form-label ${labelClassName}`}>
                {label}
            </label>
            <div className={`has-validation ${divClassName}`}>
                <input
                    id={id}
                    name={name}
                    type="file"
                    readOnly={readOnly}
                    className={`form-control ${
                        error && error[name] ? `is-invalid` : null
                    } ${inputClassName}`}
                    accept={accept}
                    onChange={handleChange}
                    disabled={disabled}
                    multiple={multiple}
                />
                <InputFeedback>{error && error[name]}</InputFeedback>
            </div>
        </>
    );
}
