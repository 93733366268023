import * as yup from 'yup';

export const TransfertFondConfigSchema = yup.object().shape({
    isTransfertAutomatique: yup.boolean(),
    montantTransfertAutomatique: yup.number().when('isTransfertAutomatique', {
        is: true,
        then: yup.number().min(100).required(),
        otherwise: yup.number()
    })
});
