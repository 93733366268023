import { fetchElement, submitPutAction } from '@napp-inc/jnapp-hook';
import { DB_NODE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';
import { ListDetailApproStockProduitMateriel } from './list-detail';

const defaultFields = {
    actionEncours: '',
    isUpdateView: false
};

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['stock'];

export function DetailApproStockProduitMateriel() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({
        nodeName: 'regularisationsStock',
        defaultFields,
        defaultElements
    });
    const { fields, form, elements } = formState;

    const onUpdateViewAction = () => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'isUpdateView',
                data: !fields.isUpdateView
            }
        });
        if (
            elements.stock.status === FORM_CONST.STATUT.ETAT_INITIAL ||
            elements.stock.status === FORM_CONST.FETCH_ELEMENT_FAILED
        ) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'stock',
                    payload: { id },
                    functionName:
                        URL_CONST.GET_LIST_DETAIL_APPROVISIONNEMENT_STOCK_PRODUIT_MATERIEL,
                    etat: elements.stock.status,
                    reduxNodeName:
                        REDUX_NODE_NAME.DETAIL_APPRO_STOCK_PRODUIT_MATERIEL,
                    reduxDispatcher
                })
            );
        }
    };

    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;

        const payload = {
            creator,
            etat: actionEncours,
            idApprovisionnementStockProduitMateriel: id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_APPRO_STOCK_PRODUIT_MATERIEL,
                payload,
                fields,
                reduxNodeName: DB_NODE.APPROVISIONNEMENT_STOCK,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <DetailWrapper
            functionName={
                URL_CONST.GET_APPROVISIONNEMENT_STOCK_PRODUIT_MATERIEL_BY_ID
            }
            nodeName={DB_NODE.APPROVISIONNEMENT_STOCK}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Approvisionnement Stock Produit Matériel"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <CustomRow>
                <CustomRow>
                    <CustomCol className="col-sm-2">
                        <CustomButtonLoader
                            type="button"
                            className="btn-primary"
                            onClick={onUpdateViewAction}
                            text="Voir les details..."
                        />
                    </CustomCol>
                </CustomRow>
                <CustomCollapse isIn={fields.isUpdateView}>
                    <ListDetailApproStockProduitMateriel
                        formState={formState}
                    />
                </CustomCollapse>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                    <CustomConfirmButton
                        type="button"
                        text="Annuler"
                        className="btn-danger"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_ANNULER
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation Annulation"
                    />
                </CustomCol>
                <CustomCol className="col-3 col-md-2">
                    <CustomConfirmButton
                        type="button"
                        text="Valider"
                        className="btn-success"
                        confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_VALIDER
                        }
                    />
                </CustomCol>
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
