import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    ETAT,
    FORM_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../../components';

export function CompteBancaireOrganisation({
    idToken,
    // typeEntite,
    uiValidator,
    initialState
}) {
    const reduxDispatcher = useDispatch();
    const {
        institutionsFinanciere,
        etatInstitutionFinanciere,
        monnaiesLocale,
        etatMonnaiesLocale
    } = useSelector((reduxState) => ({
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionFinanciere: reduxState.etat.institutionsFinanciere.etat,
        monnaiesLocale: reduxState.monnaiesLocale.filter(
            (item) =>
                item.etat === ETAT.ETAT_VALIDER || item.estReference === true
        ),
        etatMonnaiesLocale: reduxState.etat.monnaiesLocale.etat
    }));

    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionFinanciere
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaiesLocale
            }
        ]
    });

    const typeProprietaireBanque = useMemo(
        () =>
            institutionsFinanciere.find(
                (banque) => banque?.code === fields?.selectedBanque?.code
            )?.typeProprietaire,
        [fields?.selectedBanque?.code, institutionsFinanciere]
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        const {
            selectedBanque,
            numeroCompte,
            selectedDevise,
            estCompteTransfert
        } = fields;

        const payload = {
            newCompte: {
                institutionFinanciere: {
                    code: selectedBanque?.code,
                    designation: selectedBanque?.designation
                },
                numero: numeroCompte,
                devise: selectedDevise?.devise
            }
        };
        if (typeProprietaireBanque === TYPE_ORGANISATION.NAPP) {
            payload.newCompte.estCompteTransfert = estCompteTransfert;
        }
        if (typeProprietaireBanque !== TYPE_ORGANISATION.NAPP) {
            payload.newCompte.estCompteTransfert = false;
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_COMPTE_BANCAIRE_ORGANISATION,
                reduxNodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE_ORGANISATION,
                payload,
                fields,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                // {
                //     dependency: 'devises',
                //     etat: etatDevise
                // },
                {
                    dependency: 'monnaies',
                    etat: etatMonnaiesLocale
                },
                {
                    dependency: 'banques',
                    etat: etatInstitutionFinanciere
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={institutionsFinanciere}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la banque"
                    label="Banque*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedBanque"
                    id="selectedBanqueId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Numéro de compte*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez le numero de compte"
                    formDispatcher={formDispatcher}
                    name="numeroCompte"
                    id="numeroCompteId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    idProperty="id"
                    propertyToDisplay="devise"
                    options={monnaiesLocale}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la devise"
                    label="Devise*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedDevise"
                    id="selectedDeviseId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    typeProprietaireBanque === TYPE_ORGANISATION.NAPP
                }
            >
                <CustomSwitchButton
                    id="estCompteTransfertCompteBancaireOrganisation"
                    text="Compte de transfert"
                    inputTitle="Compte pour le transfert de fonds"
                    formDispatcher={formDispatcher}
                    name="estCompteTransfert"
                    divClassName="col-md-8"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </ConditionalRenderingWrapper>
            <CustomRow className="row mt-3">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        text="Valider"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
