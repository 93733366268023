import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../context/state-context';
import { HeaderOptions } from './header-options';

export function Header() {
    // const dispatch = useDispatch();
    const { organisation } = useSelector((reduxState) => ({
        organisation: reduxState.organisation
    }));
    // const { activeMenu, setActiveMenu, screenSize, setScreenSize } = useStateContext()
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const { activeMenu, setActiveMenu, screenSize, setScreenSize } =
        useContext(StateContext);
    const navigate = useNavigate();
    // const iconToShow = (mode) => {
    //     if (mode === 'dark')
    //         return (
    //             <BsIcons.BsSunFill
    //                 title="mode clair"
    //                 className="icon-custom header-icon"
    //                 onClick={() => {
    //                     dispatch(
    //                         GENERIC_ITEM_ACTIONS_CREATOR.setItem(
    //                             { name: 'light' },
    //                             REDUX_NODE_NAME.THEME
    //                         )
    //                     );
    //                 }}
    //             />
    //         );
    //     return (
    //         <BsIcons.BsMoonStarsFill
    //             title="mode sombre"
    //             className="icon-custom header-icon"
    //             onClick={() => {
    //                 dispatch(
    //                     GENERIC_ITEM_ACTIONS_CREATOR.setItem(
    //                         { name: 'dark' },
    //                         REDUX_NODE_NAME.THEME
    //                     )
    //                 );
    //             }}
    //         />
    //     );
    // };

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [screenSize, setScreenSize]);

    useEffect(() => {
        if (screenSize <= 900) {
            setActiveMenu(false);
        } else {
            setActiveMenu(true);
        }
    }, [screenSize, setActiveMenu]);

    const handleActiveMenu = () => setActiveMenu(!activeMenu);
    const popover = <HeaderOptions />;

    return (
        <header
            className="vw-100 position-sticky bg-primary shadow p-2 d-flex justify-content-between"
            style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
        >
            <div className="d-flex align-items-center">
                <BsIcons.BsList
                    onClick={handleActiveMenu}
                    className="icon-custom header-icon"
                    type="button"
                />
                <span
                    role="button"
                    tabIndex={0}
                    className="header-brand-span text-white"
                    onKeyDown={() => navigate('/')}
                    onClick={() => navigate('/')}
                >
                    J-Napps
                </span>
                {organisation.estKinDistribution && organisation.entreprise ? (
                    <span className="header-brand-span text-white">
                        {organisation.entreprise.designation}
                    </span>
                ) : null}
            </div>
            <div className="d-flex align-items-center">
                <Popover
                    isOpen={isPopoverOpen}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    positions={['bottom']}
                    content={popover}
                >
                    <div
                        aria-hidden="true"
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                    >
                        <FaIcons.FaUserCircle className="icon-custom header-icon" />
                    </div>
                </Popover>
            </div>
        </header>
    );
}
