import React from 'react';
import { NappSideBar } from '../container';

export function Sidebar({ className }) {
    return (
        <div className={`main-sidebar ${className}`}>
            <div className="position-sticky pt-3">
                <NappSideBar />
            </div>
        </div>
    );
}
