import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { ReleveLimitCreditOrganisme } from '../../../../components';

export function ReleveLimitCreditRevendeurForm({ typeOrganisation }) {
    return (
        <ReleveLimitCreditOrganisme
            typeOrganisation={TYPE_ORGANISATION.REVENDEUR}
            payload={{
                estAll: true,
                typeOrganisation
            }}
        />
    );
}
