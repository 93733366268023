import * as yup from 'yup';

/**
 * @description : Schema de remboursement
 */
export const RegularisationSuperDealerFormSchema = yup.object().shape({
    selectedSuperDealer: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedImputation: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedNumero: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    libelle: yup.string().required(),
    quantite: yup.number().min(1)
});
