import { REDUX_NODE_NAME } from '../../../redux';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListLimitCreditForm() {
    return (
        <ListGenericForm
            reduxPropertyName="limitsCredit"
            functionName={URL_CONST.GET_LIST_LIMIT_CREDIT_INITIALS}
            nodeName={REDUX_NODE_NAME.LIMIT_CREDIT}
            mapper={[
                {
                    displayName: 'Prenom',
                    field: 'valeurTypeProprietaire.prenom'
                },
                {
                    displayName: 'Nom',
                    field: 'valeurTypeProprietaire.nom'
                },
                {
                    displayName: 'Limite',
                    field: 'limit'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
