import {
    useFormGlobalReducer,
    useGetUserDetail,
    fetchElement
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomForm,
    CustomInput,
    CustomRow
} from '../../../components';
import { URL_CONST } from '../../../util';
// import { CreationApproCompteEmoneySchema } from '../../../../util/validation';

const defaultFields = {
    numeroTransaction: '',
    tillNumber: ''
    // numeroTransaction: '7519384152'
};

export function RechercheCrediterCompteByNumeroTransactionForm() {
    const reduxDispatcher = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['transaction']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements, form } = formState;
    const onSuccess = ({ element: cmd }) => {
        if (cmd && cmd.id) {
            navigate(`${pathname}/${cmd.id}`);
        }
    };
    const handleRechercheByDispatch = (e) => {
        e.preventDefault();
        const payload = {
            numeroTransaction: fields.numeroTransaction,
            tillNumber: fields.tillNumber
        };
        formDispatcher(
            fetchElement({
                idToken,
                functionName:
                    URL_CONST.GET_CREDITER_COMPTE_BY_NUMERO_TRANSACTION,
                elementPropertyName: 'transaction',
                etat: elements.transaction.status,
                payload,
                reduxNodeName: DB_NODE.CREDITER_COMPTE,
                reduxDispatcher,
                onSuccess
            })
        );
    };
    const renderContent = () => (
        <>
            <CustomAlert error={form?.error} />
            <CustomAlert error={elements.transaction.error} />
            <CustomForm>
                <CustomRow>
                    <CustomInput
                        isInputGroup
                        label="Till Number* : "
                        labelClassName="col-12"
                        divClassName="col-12"
                        placeholder="Saisissez le TILL_NUMBER"
                        formDispatcher={formDispatcher}
                        name="tillNumber"
                        id="tillNumberRecherchtransactionBynumeroTransaction"
                        // uiValidator={CreationApproCompteEmoneySchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup
                        label="Numéro Transaction* : "
                        labelClassName="col-12"
                        divClassName="col-12"
                        placeholder="Saisissez le numéro de référence"
                        formDispatcher={formDispatcher}
                        name="numeroTransaction"
                        id="numeroTransactionRecherchtransactionBynumeroTransaction"
                        // uiValidator={CreationApproCompteEmoneySchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow className="row">
                    <CustomCol className="col-12">
                        <CustomButtonLoader
                            className="btn-success"
                            onClick={handleRechercheByDispatch}
                            text="Rechercher"
                            disabled={
                                elements.transaction.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            isActionEncours={
                                elements.transaction.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </CustomForm>
        </>
    );
    return renderContent();
}
