import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { RapportActiviteForm } from './list-rapport-activite';
import { ListReleveActiviteDepotRetrait } from './list-releve-activite-depot-retrait';
import { ReleveLimitCreditAgentForm } from './limit-credit-user';

export function RapportActiviteViewLayout() {
    const [key, setKey] = useState('rapportActivite');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Rapport Activité</h1>
            </div>
            <Tabs
                defaultActiveKey="rapportActivite"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="rapportActivite" title="Rapport Activite">
                    {key === 'rapportActivite' && <RapportActiviteForm />}
                </Tab>
                <Tab eventKey="releve" title="Relevé Opération">
                    {key === 'releve' && <ListReleveActiviteDepotRetrait />}
                </Tab>
                <Tab eventKey="limitCredit" title="Limite de Crédit">
                    {key === 'limitCredit' && <ReleveLimitCreditAgentForm />}
                </Tab>
            </Tabs>
            {/* <RapportActiviteForm /> */}
        </div>
    );
}
