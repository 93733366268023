import { formatNumber } from '../../util';

/**
 *
 * @param {Object} param0
 * @param {Number} param0.value la valeur qu'il faut afficher
 * Pour le reste des propriétés, voir @alias formatNumber
 * @returns {React.Component}
 */
export function NumberDisplayer({
    value,
    devise,
    nombreApresVirgule,
    isCompact,
    isLongCompactDisplay,
    isPercent,
    useGrouping,
    isForceParse
}) {
    const text =
        typeof value !== 'number'
            ? value
            : formatNumber({
                  value,
                  devise,
                  nombreApresVirgule,
                  isCompact,
                  isLongCompactDisplay,
                  isPercent,
                  useGrouping,
                  isForceParse
              });
    return <span>{text}</span>;
}
