export const schema = [
    {
        title: 'Type',
        description: 'typeProprietaire',
        className: 'border-bottom'
    },
    {
        title: 'Début Exercice',
        description: 'budget.dateDebut',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Fin Exercice',
        description: 'budget.dateFin',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Date de création',
        description: 'dateCreation',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
