/**
 * Retourne une liste des mouvements traités,
 * les propriétés entree et sortie sont rajoutés
 * ainsi que les propriétés isRed and isBlue, qui permettent d'identifier potentiellement des problèmes d'accès concurentiel
 * @param {Array} data Liste des mouvements
 * @returns {Array}
 */
export const traitementMouvement = (data) =>
    data.map((item, index, collect) => {
        const { soldeActuel, soldePrecedent } = item;
        let entree = 0;
        let sortie = 0;
        if (soldeActuel > soldePrecedent) {
            entree = soldeActuel - soldePrecedent;
        } else if (soldeActuel < soldePrecedent) {
            sortie = soldePrecedent - soldeActuel;
        }
        let isRed = collect.find((collectItem) => {
            if (
                collectItem.id !== item.id &&
                collectItem.soldePrecedent === soldePrecedent
            ) {
                return true;
            }
            return false;
        });
        if (isRed) {
            const inverse = collect.find((collectItem) => {
                if (
                    collectItem.id !== item.id &&
                    collectItem.type_operation !== item.type_operation &&
                    collectItem.montant === item.montant
                ) {
                    return true;
                }
                return false;
            });
            if (inverse) isRed = false;
        }
        const isBlue = !!(
            index !== 0 &&
            collect[index - 1].soldeActuel !== item.soldePrecedent
        );
        const isConcurentAccess =
            !!(
                index !== 0 &&
                collect[index - 1].soldePrecedent === item.soldePrecedent
            ) ||
            !!(
                index !== collect.length - 1 &&
                collect[index + 1].soldePrecedent === item.soldePrecedent
            );
        return { ...item, entree, sortie, isRed, isBlue, isConcurentAccess };
    });
