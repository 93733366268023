import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import { formInitialState } from '@napp-inc/jnapp-util';
import {
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../../components';
import { CreationVenteByReference } from './creation-vente-by-reference';
import { CreationVenteEspece } from './creation-vente-espece';

const defaultFields = {
    isByNumeroReference: false
};

export function CreationVenteSimForm() {
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields } = formState;
    const renderContent = () => (
        <FormWrapper formState={formState} formDispatcher={formDispatcher}>
            <CustomRow>
                <CustomSwitchButton
                    id="estByNumeroReference"
                    text="Numéro référence"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultChecked={fields.isByNumeroReference}
                    name="isByNumeroReference"
                    divClassName="col-md-8"
                />
            </CustomRow>
            {fields.isByNumeroReference && <CreationVenteByReference />}
            {!fields.isByNumeroReference && <CreationVenteEspece />}
        </FormWrapper>
    );
    return renderContent();
}
