import { TYPE_ORGANISATION, URL_CONST } from '@napp-inc/jnapp-util';
import { GenericReleveForm } from '../../module/report/template';
import { useGetReportDetail } from '../../util';
import { CompteAgentSelector } from '../molecules/compte-agent-selector';

/**
 * Composant pour le rapport de limite de crédit d'un organisme : revendeur, super dealer, cash collecteur
 * @param {Object} param0 Object avec les paramètres suivants :
 * @param {String} param0.typeOrganisation Type d'organisation
 *
 * @returns {React.Component}
 */
export function ReleveLimitCreditOrganisme({
    typeOrganisation = TYPE_ORGANISATION.REVENDEUR,
    payload = {
        estAll: true
    }
}) {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedAgent: {}
        }
    });
    return (
        <GenericReleveForm
            functionName={URL_CONST.GET_LIST_MOUVEMENT_PLAGE}
            formState={formState}
            formDispatcher={formDispatcher}
            typeProprietaire="Compte"
            name="selectedAgent"
            mapper={[
                {
                    field: 'valeurTypeOrganisation.designation',
                    displayName: 'Désignation'
                },
                {
                    field: 'typeEntite',
                    displayName: 'Créateur'
                },
                {
                    field: 'libelle',
                    displayName: 'Operation'
                },
                {
                    field: 'soldePrecedent',
                    displayName: 'Ouverture'
                },
                {
                    field: 'entree',
                    displayName: 'Entrée',
                    isTotal: true
                },
                {
                    field: 'sortie',
                    displayName: 'Sortie',
                    isTotal: true
                },
                {
                    field: 'soldeActuel',
                    displayName: 'Fermeture'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Date'
                }
            ]}
        >
            <CompteAgentSelector
                typeOrganisation={typeOrganisation}
                formDispatcher={formDispatcher}
                formState={formState}
                payload={payload}
            />
        </GenericReleveForm>
    );
}
