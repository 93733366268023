export const CorrespondanceCollection = {
    AppelFlashVodacomDev: `Vodacom dev`,
    AppelFlashOrangeDev: `Orange dev`,
    AppelFlashAfricellDev: `Africell dev`,
    AppelFlashAirtelDev: `Airtel dev`,
    AppelFlashAirtelDev1: `Airtel dev 1`,
    AppelAfriMoneyDev: `AfriMoney dev`,
    AppelFlashVodacomProd: `Vodacom prod`,
    AppelFlashOrangeProd: `Orange prod`,
    AppelFlashAfricellProd: `Africell prod`,
    AppelFlashAirtelProd: `Airtel prod`
};
