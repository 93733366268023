import * as yup from 'yup';

/**
 * @description : Schema de Banque
 */

export const CreationBanqueSchema = yup.object().shape({
    designation: yup.string().required(),
    code: yup.string().required(),
    adresse: yup.string().required(),
    nationalite: yup.string().required(),
    email: yup.string().required(),
    numeroTel: yup.string().required(),
    codeBcc: yup.string()
});
