import { useEffect } from 'react';
import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    CODE_SERVICE
} from '@napp-inc/jnapp-util';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomButton } from '../button';
import { CustomCenteredModal, UserServiceWrapper } from '../container';
import { HomeCashBook } from '../../module/finance';
import { HomeLimiteCredit } from '../../module/configuration';
import { RapportDashbaordNappViewLayout } from '../../module/report';

const defaultFields = {
    showModal: false
};
export function HomePage() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields } = formState;

    useEffect(() => {
        if (nappUser && nappUser.estMotPasseDefault) {
            formDispatcher({
                type: FORM_CONST.TEXT_FIELD_CHANGE,
                payload: {
                    field: 'showModal',
                    data: true
                }
            });
        }
    }, [formDispatcher, nappUser]);
    return (
        <div className="">
            <CustomCenteredModal
                header="Avertissement"
                show={fields.showModal}
                onHide={() =>
                    formDispatcher({
                        type: FORM_CONST.TEXT_FIELD_CHANGE,
                        payload: {
                            field: 'showModal',
                            data: false
                        }
                    })
                }
            >
                <p>
                    Le mot de passe que vous utilisez est un mot de passe généré
                    par défaut,{' '}
                    <span>
                        <Link to="/my-account">cliquer ici pour modifier!</Link>
                    </span>
                </p>
                {/* <ChangePassword /> */}
                <CustomButton
                    onClick={() =>
                        formDispatcher({
                            type: FORM_CONST.TEXT_FIELD_CHANGE,
                            payload: {
                                field: 'showModal',
                                data: false
                            }
                        })
                    }
                    className="btn-danger"
                >
                    Fermer
                </CustomButton>
            </CustomCenteredModal>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom w-100">
                <h1 className="h2">Dashboard</h1>
            </div>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .SERIVCE_CODE_RAPPORT.LECTURE_RAPPORT_GERANT
                ]}
            >
                <div className="row p-2 shadow">
                    <RapportDashbaordNappViewLayout />
                </div>
            </UserServiceWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY,
                    CODE_SERVICE.SERVICE_EMONEY
                        .INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT
                    // CODE_SERVICE.SERVICE_CODE_REVENDEUR
                    //     .CODE_SERVICE_MODULE_FINANCE
                    //     .LECTURE_LIST_RAPPORT_ORGANISATION
                ]}
            >
                <div className="row py-1">
                    <HomeCashBook />
                </div>
            </UserServiceWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY,
                    CODE_SERVICE.SERVICE_EMONEY
                        .INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT,
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_FINANCE
                        .LECTURE_LIST_BALANCE_COMPTE_USER
                    // CODE_SERVICE.SERVICE_CODE_REVENDEUR
                    //     .CODE_SERVICE_MODULE_FINANCE
                    //     .LECTURE_LIST_RAPPORT_ORGANISATION
                ]}
            >
                <div className="row py-1">
                    <HomeLimiteCredit />
                </div>
            </UserServiceWrapper>
        </div>
    );
}
