import {
    BROWSER_PERMISSION_QUERY,
    BROWSER_PERMISSION_STATUS,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useEffect, useState } from 'react';

/**
 * Cette fonction retourne la localisation actuelle de l'utilisateur fournit par le navigateur
 * @param {Object} param0 objet recevant le parametre formdDispatcher
 * @param {Function} param0.formdDispatcher function qui permet de renvoyer le message d'erreur dans le state du form
 * @returns {Object} retourne un objet avec une propriété coords { lat, long }
 * en cas d'erreur l'objet retourné contient une propriété localisationError
 */
export const useGetCurrentGeoLocation = ({ formDispatcher }) => {
    const [lat, setLat] = useState();
    const [long, setLlong] = useState();
    const [error, setError] = useState(false);
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                ({ coords: { latitude, longitude } }) => {
                    setLat(latitude);
                    setLlong(longitude);
                },
                () => {
                    navigator.permissions
                        .query({ name: BROWSER_PERMISSION_QUERY.GEOLOCATION })
                        .then(({ state }) => {
                            if (state === BROWSER_PERMISSION_STATUS.DENIED) {
                                const errorMsg =
                                    "Vous devez autoriser l'accès à votre localisation pour utiliser cette fonctionnalité!";
                                if (
                                    formDispatcher &&
                                    typeof formDispatcher === 'function'
                                ) {
                                    formDispatcher({
                                        type: FORM_CONST.SET_ONLY_FORM_ERROR,
                                        payload: {
                                            message: errorMsg
                                        }
                                    });
                                }
                                setError(errorMsg);
                            }
                        });
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 30000,
                    timeout: 27000
                }
            );
        } else {
            const errorMsg =
                'Votre périphérique ne peut donner la position géographique';
            if (formDispatcher && typeof formDispatcher === 'function') {
                formDispatcher({
                    type: FORM_CONST.SET_ONLY_FORM_ERROR,
                    payload: {
                        error: errorMsg
                    }
                });
            }
            setError(errorMsg);
        }
    }, [formDispatcher]);

    return {
        coords: {
            lat,
            long
        },
        localisationError: error
    };
};
