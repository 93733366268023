import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, URL_CONST, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../components';
import { ProfilSchema as uiValidator } from './validation';

/**
 * @description defaultFields
 */
const defaultFields = {
    code: '',
    designation: '',
    isRayon: false,
    isProduit: false
};

export function CreationProfilForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = {};
        if (fields.isProduit) {
            payloadToAdd.estProduit = fields.isProduit;
        }
        if (fields.isRayon) {
            payloadToAdd.estRayon = fields.isRayon;
        }
        const payload = {
            creator,
            newProfilUser: {
                code: fields.code,
                designation: fields.designation,
                ...payloadToAdd
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_PROFIL_USER,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    label="Code*"
                    name="code"
                    id="creationProfilFormCodeId"
                    placeholder="Code Profil"
                    isInputGroup={false}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    label="Designation*"
                    name="designation"
                    id="creationProfilFormDesignationId"
                    placeholder="Désignation Profil"
                    isInputGroup={false}
                />
            </CustomRow>
            {!fields.isRayon ? (
                <CustomRow>
                    <CustomSwitchButton
                        inputTitle="Profil spécialisé produit"
                        id="creationIsProfilSwitchId"
                        name="isProduit"
                        text="Est produit ?"
                        value={fields.isProduit}
                        formState={formState}
                        formDispatcher={formDispatcher}
                        divClassName="col-sm-3 pt-2"
                    />
                </CustomRow>
            ) : null}
            {!fields.isProduit ? (
                <CustomRow>
                    <CustomSwitchButton
                        inputTitle="Profil spécialisé rayon"
                        id="creationIsRayonSwitchId"
                        name="isRayon"
                        text="Est Rayon?"
                        value={fields.isRayon}
                        formState={formState}
                        formDispatcher={formDispatcher}
                        divClassName="col-sm-3 pt-2"
                    />
                </CustomRow>
            ) : null}
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        isActionEncours={
                            form.status === FORM_CONST.CREATION_EN_COURS
                        }
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
