import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    // MOYEN_PAIEMENT,
    // TYPE_CAISSE_SECTORIELLE,
    BUDGET_ELABORATION,
    RESSOURCE,
    fromTimestampToString,
    TYPE_BUDGET_ADDITIONNEL,
    TIME,
    getPeriodesOfYear
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomLabel,
    CustomAddRemoveButton,
    SimpleTable,
    FormWrapper,
    CustomSwitchButton,
    CustomAlert
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import { formatRessourceByPeriodesRefactored } from '../util';
import { ElaborationChargeRessourceFormSchema as uiValidator } from './validation';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedDirection: {},
    selectedSecteur: {},
    selectedRessource: {},
    selectedCaisseSectorielle: {},
    modePaiement: {},
    tauxCroissance: 0,
    datePaiement: '',
    list: [],
    isPrevisionnel: true,
    target: 0,
    listWithTarget: [],
    ressourceElaboration: [],
    targetByDevise: []
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: ['secteurs', 'ressources', 'exercicesFinancier']
};

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['deviseSecteur'];

export function CreationElaborationBudgetAdditionnelRessource() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        ressources,
        etatRessource,
        etatSecteurs,
        exerciceFinancier,
        etatExerciceFinancier,
        secteursKinDB
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        ressources: reduxState.ressources,
        etatRessource: reduxState.etat.ressources.etat,
        etatSecteurs: reduxState.etat.secteursRessourcesKindb.etat,
        secteursKinDB: reduxState.secteursRessourcesKindb,
        exerciceFinancier: reduxState.exerciceFinancier.map((item) => ({
            ...item,
            designation: `${fromTimestampToString(
                item.dateDebut
            )} - ${fromTimestampToString(item.dateFin)}`
        })),
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const { fields, form, elements } = formState;

    const exercices = exerciceFinancier.map((item) => ({
        ...item,
        designation: `${TIME.fromUtcTimestampToLocalString(
            item.dateDebut
        )} - ${TIME.fromUtcTimestampToLocalString(item.dateFin)}`
    }));

    const targetedExercice = exercices.find(
        (item) => item.id === fields?.selectedExercice.id
    );

    const periodes =
        getPeriodesOfYear({
            startOf: targetedExercice?.dateDebut,
            endOf: targetedExercice?.dateFin
        }).map((item) => ({
            code: item.dateDebut,
            designation: item.mois,
            ...item
        })) || [];

    const targetedMonth = periodes.length
        ? periodes.find(
              (item) => item.designation === fields?.selectedMonth?.designation
          )
        : {};

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_RESSOURCE,
                nodeName: DB_NODE.RESSOURCE,
                etat: etatRessource
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_RESSOURCE_KIN_DB, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR_RESSOURCE_KINDB,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
            }
        ]
    });

    const checkoutTargetRessource = (cible) => {
        const targetedRessources = ressources.find(
            (item) => item.code === cible.code
        );
        if (targetedRessources && targetedRessources.estObjectif) {
            return true;
        }
        return false;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // const { list } = fields;
        // const FirstList = formatRessourceByPeriodes(
        //     fields.ressourceElaboration
        // );
        const list = formatRessourceByPeriodesRefactored(
            fields.ressourceElaboration
        );
        // const lastList = list.map((item) => ({
        //     element: {
        //         code: item.element.code
        //     },
        //     tauxCroissance: item.tauxCroissance
        // }));
        // const finalList = FirstList.concat(lastList);
        const payload = {
            periode: {
                dateDebut: targetedExercice.dateDebut,
                dateFin: targetedExercice.dateFin
            },
            newElaborationAmendement: {
                typeEntite: BUDGET_ELABORATION,
                typeProprietaire: RESSOURCE.code,
                type: TYPE_BUDGET_ADDITIONNEL.code,
                estPrevisionnel: fields.isPrevisionnel
            },
            creator,
            list
        };
        // console.log(JSON.stringify(payload));
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    /**
     * @description action pour rajouter le partenaires et le montant à payer
     */
    const addBeneficiaire = () => {
        if (!fields.selectedRessource.code || !fields.tauxCroissance) return;
        const data = {
            id: fields.selectedRessource.code,
            ressource: fields.selectedRessource.code,
            designation: fields.selectedRessource.designation,
            // element: {
            //     designation: fields.selectedRessource.designation,
            //     code: fields.selectedRessource.code
            // },
            tauxCroissance: fields.tauxCroissance
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'ressourceElaboration',
                data
            }
        });
    };

    /**
     * @description action pour rajouter les objectifs par devise
     */
    const addTargetDevise = () => {
        if (!fields.selectedDevise.code || !fields.target) return;
        const data = {
            devise: fields.selectedDevise.code,
            target: fields.target
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'devise',
                field: 'targetByDevise',
                data
            }
        });
    };

    /**
     * @description fonction pour fetcher les beneficiaires
     */
    const onSearchDeviseSecteur = () => {
        const { status: etat } = elements.deviseSecteur;
        formDispatcher(
            fetchElement({
                idToken,
                functionName:
                    URL_CONST.GET_LIST_MONNAIE_LOCALE_MARCHAND /** @desc : A changer */,
                elementPropertyName: 'deviseSecteur',
                etat,
                payload: {
                    codeMarchand: fields.selectedSecteur?.code
                },
                reduxDispatcher
            })
        );
    };

    /**
     * @description action pour rajouter les elements qui partage le même secteur
     */
    const addEntireRessourceElaboration = () => {
        const propertyToAdd = {};
        if (fields.listWithTarget.length) {
            propertyToAdd.periodes = fields.listWithTarget.map(
                ({ dateDebut, dateFin, devises }) => ({
                    dateDebut,
                    dateFin,
                    devises
                })
            );
        }
        const data = {
            id: `${fields.selectedRessource.code}_${fields.selectedSecteur.code}_${targetedMonth.dateDebut}_${targetedMonth.dateFin}`,
            secteur: {
                code: fields.selectedSecteur.code
            },
            ressource: fields.selectedRessource.code,
            designation: fields.selectedRessource.designation,
            ...propertyToAdd
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'ressourceElaboration',
                data
            }
        });
    };

    /**
     * @description action pour rajouter les partenaires et targets dans le cas des Marchand
     */

    const addBeneficiaireAndTarget = () => {
        if (
            // !fields.modePaiement.code ||
            !targetedMonth.dateDebut ||
            !targetedMonth.dateFin ||
            !fields.targetByDevise.length
            // !fields.datePaiement
        )
            return;
        const data = {
            id: `${targetedMonth.dateDebut}_${targetedMonth.dateFin}`,
            dateDebut: targetedMonth.dateDebut,
            dateFin: targetedMonth.dateFin,
            devises: fields.targetByDevise
        };
        // formDispatcher({
        //     type: FORM_CONST.ADD_ITEM,
        //     payload: {
        //         identifier: 'id',
        //         field: 'listWithTarget',
        //         data
        //     }
        // });
        formDispatcher({
            type: FORM_CONST.REINITIALISER,
            payload: {
                initialState: {
                    ...formState,
                    fields: {
                        ...formState.fields,
                        listWithTarget: fields.listWithTarget
                            .filter((item) => item.id !== data.id)
                            .concat(data)
                    }
                }
            }
        });
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'ressources',
                    etat: etatRessource
                },
                {
                    dependency: 'exercicesFinancier',
                    etat: etatExerciceFinancier
                }
            ]}
        >
            <CustomRow>
                <CustomSwitchButton
                    id="selectionElaborationAdditionnelSwitchId"
                    name="isPrevisionnel"
                    text="Agir sur le budget prévisionnel?"
                    value={fields.isPrevisionnel}
                    formState={formState}
                    formDispatcher={formDispatcher}
                    divClassName="col-sm-3 pt-2"
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={exerciceFinancier}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner un exercice"
                    label="Exercice*"
                    labelClassName="col-12 col-sm-3"
                    divClassName="col-12 col-sm-9"
                    name="selectedExercice"
                    id="selectedExerciceElaborationRessourceId"
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Ressource*" className="col-sm-3" />
                <CustomDropdown
                    options={ressources}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la ressource"
                    divClassName={`col-12 ${
                        !checkoutTargetRessource(fields.selectedRessource)
                            ? 'col-sm-4'
                            : 'col-sm-9'
                    }`}
                    name="selectedRessource"
                    id="selectedRessourceId"
                    uiValidator={uiValidator}
                />
                {!checkoutTargetRessource(fields.selectedRessource) && (
                    <>
                        <CustomInput
                            isInputGroup={false}
                            divClassName="col-12 col-sm-4"
                            placeholder="Saisissez le taux de croissance..."
                            formDispatcher={formDispatcher}
                            name="tauxCroissance"
                            type="number"
                            id="tauxCroissanceId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                        <CustomAddRemoveButton
                            className="col-sm-1"
                            callback={addBeneficiaire}
                        />
                    </>
                )}
            </CustomRow>
            {checkoutTargetRessource(fields.selectedRessource) && (
                <>
                    <CustomRow>
                        <CustomDropdown
                            options={secteursKinDB}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner le secteur"
                            labelClassName="col-12 col-sm-3"
                            divClassName="col-12 col-sm-7"
                            label="Secteur*"
                            name="selectedSecteur"
                            id="selectedSecteurId"
                            disabled={!secteursKinDB?.length}
                            uiValidator={uiValidator}
                        />
                        <CustomButtonLoader
                            isActionEncours={
                                elements?.deviseSecteur?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            disabled={
                                elements?.deviseSecteur?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            type="button"
                            className="btn-primary col-sm-2"
                            onClick={onSearchDeviseSecteur}
                            text="Recherche Devise"
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            isSort={false}
                            options={periodes}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            label="Périodes*"
                            defaultOption="Sélectionner le mois"
                            labelClassName="col-12 col-sm-3"
                            divClassName="col-12 col-sm-9"
                            name="selectedMonth"
                            id="selectedMonthId"
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomLabel text="Periodes*" className="col-sm-3" />
                        <CustomInput
                            isInputGroup={false}
                            divClassName="col-12 col-sm-4"
                            placeholder="Target ou Objectif..."
                            formDispatcher={formDispatcher}
                            name="target"
                            type="number"
                            id="targetParSecteurId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />{' '}
                        {elements?.deviseSecteur?.status ===
                            FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                        !elements?.deviseSecteur?.value?.length ? (
                            <CustomAlert successMessage="Ce marchand ne possède aucune devise" />
                        ) : (
                            <CustomDropdown
                                disabled={
                                    !elements?.deviseSecteur?.value?.length
                                }
                                options={elements?.deviseSecteur?.value || []}
                                formDispatcher={formDispatcher}
                                formState={formState}
                                defaultOption="Sélectionnez la devise"
                                divClassName="col-12 col-sm-3"
                                name="selectedDevise"
                                id="selectedDeviseId"
                            />
                        )}
                        <CustomAddRemoveButton
                            className="col-sm-1"
                            callback={addTargetDevise}
                        />
                    </CustomRow>
                    <CustomRow
                        isShouldBeRendered={fields.targetByDevise.length}
                    >
                        <SimpleTable
                            identifier="devise"
                            formDispatcher={formDispatcher}
                            data={fields.targetByDevise}
                            isShowDropButton
                            listSelectedItemName="targetByDevise"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    displayName: 'devise',
                                    field: 'devise'
                                },
                                {
                                    position: 2,
                                    displayName: 'target',
                                    field: 'target'
                                }
                            ]}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomButtonLoader
                            type="button"
                            className="offset-sm-10 col-sm-2"
                            text="Ajouter période et objectif"
                            onClick={addBeneficiaireAndTarget}
                        />
                    </CustomRow>
                    <CustomRow
                        isShouldBeRendered={fields.listWithTarget.length}
                    >
                        <SimpleTable
                            identifier="id"
                            formDispatcher={formDispatcher}
                            data={fields.listWithTarget}
                            isShowDropButton
                            listSelectedItemName="listWithTarget"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    displayName: 'debut',
                                    field: 'dateDebut',
                                    isDate: true
                                },
                                {
                                    displayName: 'Fin',
                                    field: 'dateFin',
                                    isDate: true
                                }
                            ]}
                        />
                    </CustomRow>
                    <CustomRow className="row">
                        <CustomCol className="offset-9 col-3">
                            <CustomButtonLoader
                                type="button"
                                className="btn-primary"
                                onClick={addEntireRessourceElaboration}
                                text="Valider toutes ces informations?"
                            />
                        </CustomCol>
                    </CustomRow>
                </>
            )}
            <CustomRow isShouldBeRendered={fields.ressourceElaboration.length}>
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.ressourceElaboration}
                    isShowDropButton
                    listSelectedItemName="ressourceElaboration"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            displayName: 'Secteur',
                            field: 'secteur.code'
                        },
                        {
                            displayName: 'Ressource',
                            field: 'designation'
                        },
                        {
                            displayName: 'Taux',
                            field: 'tauxCroissance'
                        }
                    ]}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Soumettre elaboration"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
