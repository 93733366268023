import {
    CustomAddButton,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow
} from '../../../components';

export function ConfigApiComponent({
    formDispatcher,
    formState,
    uiValidator,
    urlName = 'url',
    selectedTypeApiName = 'selectedTypeApi',
    selectedApiMethodName = 'selectedApiMethod',
    selectedApiataFormatName = 'selectedApiataFormat',
    selectedCurrentFieldName = 'selectedCurrentField',
    handleAddOutputField,
    availableForSelection
}) {
    return (
        <>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Url*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    formDispatcher={formDispatcher}
                    name={urlName}
                    id="urlCreationConfigApi"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        { code: 'rest', designation: 'rest' },
                        { code: 'soap', designation: 'soap' }
                    ]}
                    label="Type*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    formDispatcher={formDispatcher}
                    name={selectedTypeApiName}
                    id="selectedTypeApiConfigApi"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        { code: 'get', designation: 'GET' },
                        { code: 'post', designation: 'POST' },
                        { code: 'put', designation: 'PUT' },
                        { code: 'delete', designation: 'DELETE' }
                    ]}
                    label="Method*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    formDispatcher={formDispatcher}
                    name={selectedApiMethodName}
                    id="selectedApiMethodConfigApi"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        { code: 'json', designation: 'JSON' },
                        { code: 'xml', designation: 'XML' }
                    ]}
                    label="Format*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    formDispatcher={formDispatcher}
                    name={selectedApiataFormatName}
                    id="selectedApiDataFormatConfigApi"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <hr />
            <CustomRow>
                <CustomLabel className="lead fw-bold">
                    Output format
                </CustomLabel>
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={availableForSelection}
                    label="Sélectionner un champs*"
                    labelClassName="col-12"
                    divClassName="col-10 col-md-10"
                    formDispatcher={formDispatcher}
                    name={selectedCurrentFieldName}
                    id="selectedCurrentFieldApiConfigApi"
                    uiValidator={uiValidator}
                    formState={formState}
                />
                <CustomCol className="col-1">
                    <CustomAddButton callback={handleAddOutputField} />
                </CustomCol>
            </CustomRow>
        </>
    );
}
