import { ViewLayout } from './view-layout';

export function ListAchatMarchand() {
    const renderContent = () => (
        <ViewLayout>
            <h2>Liste Achat Marchand</h2>
            {/* <ListGenericForm
                path={DB_NODE.POINT_LIVRAISON}
                fetchType={FETCH_LISTENING}
                reduxPropertyName="pointsLivraison"
                functionName={URL_CONST.GET_LIST_POINT_LIVRAISON_MARCHAND}
                nodeName={DB_NODE.POINT_LIVRAISON}
                identifier="id"
                listenerFilters={[
                    {
                        property: 'dateCreation',
                        value: Date.now(),
                        operator: FILTER_OPERATOR.INFERIOR_OR_EQUAL,
                        type: FILTER_TYPE.WHERE
                    },
                ]}
                mapper={[
                    {
                        field: 'id',
                        displayName: 'id',
                        position: 1
                    },
                    {
                        field: 'agent',
                        displayName: 'Agent',
                        position: 2
                    },
                    {
                        field: 'adresse.adresseUne',
                        displayName: 'Adresse',
                        position: 3
                    },
                    {
                        field: 'adresse.reference',
                        displayName: 'Reference',
                        position: 4
                    },
                    {
                        field: 'etat',
                        displayName: 'Etat',
                        position: 5
                    }
                ]}
            /> */}
        </ViewLayout>
    );
    return renderContent();
}
