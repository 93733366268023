import * as yup from 'yup';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    TYPE_ORGANISATION,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { useHasService } from '../../../util';
import { VersementForm } from './creation-form';

const defaultFields = {
    selectedCaisse: {},
    selectedUser: {},
    montant: 0
};

const setDefaultProperties = ({ type, service }) => {
    const defaultDependencies = { fields: ['caisses'] };
    const payload = {
        caisses:
            type === TYPE_ORGANISATION.NAPP
                ? { estOrganisation: true, estOperation: true }
                : { estOrganisation: true },
        users: {}
    };
    let yupValidation = yup.object().shape({
        id: yup.string(),
        devise: yup.string()
    });
    if (
        service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_VERSEMENT_POUR_AUTRE.code
        ]
    ) {
        defaultDependencies.fields.concat('users');
        yupValidation = yup.object().shape({
            id: yup.string().required(),
            devise: yup.string().required()
        });
        payload.caisses =
            type === TYPE_ORGANISATION.NAPP ? { estOperation: true } : {};
        payload.users = {
            estOrganisation: true
        };
    }
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedCaisse: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedUser: yupValidation,
                montant: yup.number().min(1)
            }),
            cfPayload: payload
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedCaisse: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedUser: yupValidation,
                montant: yup.number().min(1)
            }),
            cfPayload: payload
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedCaisse: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedUser: yupValidation,
                montant: yup.number().min(1)
            }),
            cfPayload: payload
        };
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR)
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedCaisse: yup.object().shape({
                    id: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedUser: yupValidation,
                montant: yup.number().min(1)
            }),
            cfPayload: payload
        };
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({}),
        cfPayload: {}
    };
};

export function CreationVersementForm() {
    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_VERSEMENT,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_VERSEMENT_POUR_AUTRE
        ]
    });
    /**
     * Common form processes
     */
    const { idToken, typeEntite, oid, uid } = useGetUserDetail({
        firebaseUser,
        nappUser,
        organisation
    });

    const { fields, dependencies, elements, uiValidator, cfPayload } =
        setDefaultProperties({ type: typeEntite, service: hasService });
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });
    const renderContent = () =>
        initialState.dependencies ? (
            <VersementForm
                idToken={idToken}
                typeEntite={typeEntite}
                uiValidator={uiValidator}
                initialState={initialState}
                oid={oid}
                uid={uid}
                hasService={hasService}
                cfPayload={cfPayload}
            />
        ) : null;
    return renderContent();
}
