import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CLASSE_CHARGE_VARIABLE,
    formInitialState,
    FORM_CONST,
    URL_CONST,
    utc000000
} from '@napp-inc/jnapp-util';
import { getMonthByDate } from '@napp-inc/jnapp-util/src/time';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInputDate,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { ReevaluationChargeVariableSchema as uiValidator } from './validation';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedCharge: {},
    datePaiement: '',
    chargeReevaluation: []
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = { fields: ['charges'] };

/**
 * @description elements du state du formulaire
 */
export function CreationReevaluationChargeVariables() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, chargesAReevaluer, etatchargesAReevaluer } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            chargesAReevaluer: reduxState.chargeVariableAReevaluer
                .filter((item) => 'factuel' in item)
                .map((item) => ({
                    ...item,
                    code: item?.charge?.code,
                    designation: item?.charge?.designation
                })),
            etatchargesAReevaluer: reduxState.etat.chargeVariableAReevaluer.etat
        }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const periodes = chargesAReevaluer.length
        ? chargesAReevaluer
              .map((element) => ({
                  ...element,
                  dateDebut: element?.periode?.dateDebut,
                  dateFin: element?.periode?.dateFin,
                  designation: getMonthByDate(element?.periode?.dateDebut)
              }))
              .find((item) => item.periode)
        : [];

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_A_REEVALUER,
                nodeName: REDUX_NODE_NAME.CHARGE_VARIABLES_A_REEVALUER,
                etat: etatchargesAReevaluer,
                payload: { classe: 'Variable' }
            }
        ]
    });
    /**
     * @description action pour rajouter les elements qui partage le même secteur
     */
    const addEntireChargeReevaluation = () => {
        if (!fields?.selectedCharge?.id || !fields.datePaiement) return;
        const deviseCharge = chargesAReevaluer.find(
            (item) => item?.id === fields?.selectedCharge?.id
        )?.devise;
        const charge = chargesAReevaluer.find(
            (item) => item?.id === fields?.selectedCharge?.id
        );
        const data = {
            id: `${fields?.selectedCharge?.id}_${periodes?.dateDebut}_${
                periodes?.dateFin
            }_${utc000000(fields?.datePaiement)}`,
            code: charge?.code,
            designation: charge?.designation,
            datePaiement: utc000000(fields.datePaiement),
            devise: deviseCharge
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'chargeReevaluation',
                data
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { chargeReevaluation } = fields;
        const list = chargeReevaluation.map((item) => ({
            code: item.code,
            datePaiement: item.datePaiement,
            devise: item.devise
        }));

        const payload = {
            classe: CLASSE_CHARGE_VARIABLE.code,
            list
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_REEVALUATION,
                reduxNodeName: REDUX_NODE_NAME.REEVALUATION,
                payload,
                fields,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'charges',
                    etat: etatchargesAReevaluer
                }
            ]}
        >
            <ConditionalRenderingWrapper
                isShouldBeRendered={periodes.dateDebut}
            >
                <div className="alert alert-warning" role="alert">
                    Charges fixes !
                    <hr />
                    <div>
                        <span className="">Période (Mois) :</span>{' '}
                        <span>{periodes?.designation?.toUpperCase()}</span>
                    </div>
                </div>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomLabel text="Charge*" className="col-sm-2" />
                <CustomDropdown
                    options={chargesAReevaluer}
                    disabled={!chargesAReevaluer.length}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la charge"
                    divClassName="col-12 col-sm-4"
                    name="selectedCharge"
                    id="selectedChargeId"
                    uiValidator={uiValidator}
                    idProperty="id"
                />
                <CustomInputDate
                    id="datePaiementId"
                    name="datePaiement"
                    type="date"
                    isInputGroup={false}
                    divClassName="col-sm-4"
                    placeholder="Date paiement"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    formState={formState}
                />
                <CustomAddRemoveButton
                    className="col-sm-2"
                    callback={addEntireChargeReevaluation}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields.chargeReevaluation.length}>
                <SimpleTable
                    identifier="id"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    data={fields.chargeReevaluation}
                    isShowDropButton
                    listSelectedItemName="chargeReevaluation"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            displayName: 'Charge',
                            field: 'designation'
                        },
                        {
                            position: 2,
                            displayName: 'devise',
                            field: 'devise'
                        },
                        {
                            position: 3,
                            displayName: 'Date Paiement',
                            field: 'datePaiement'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Soumettre réévaluation"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
