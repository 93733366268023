import * as yup from 'yup';

export const SignInSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required('Entrez votre adresse : exemple@domaine.com'),
    password: yup
        .string()
        .required()
        .min(4, 'Le mot de passe doit contenir au moins 4 caractères')
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
    // "Doit contenir au moins 8 caractères, Une majuscule, une minuscule, une chiffre et un caractère spécial")
});
