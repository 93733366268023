import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useGetReportDetail } from '../../../../util';
import { GenericReleveForm } from '../../template';
import { ReportCaisseSelector } from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';

export function ReleveCaisseRevendeurForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCaisse: {}
        }
    });
    return (
        <GenericReleveForm
            formState={formState}
            formDispatcher={formDispatcher}
            name="selectedCaisse"
            typeProprietaire="Caisse"
        >
            <ReportCaisseSelector
                formState={formState}
                formDispatcher={formDispatcher}
                nodeName={REDUX_NODE_NAME.CAISSE_REVENDEUR}
                reduxNodeName="caissesRevendeur"
                payload={{
                    estOrganisation: true,
                    estGerant: true,
                    typeProprietaire: TYPE_ORGANISATION.REVENDEUR
                }}
            />
        </GenericReleveForm>
    );
}
