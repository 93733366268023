import { resolvePathPropertyOfObject } from '@napp-inc/jnapp-util';
import { useEffect, useState } from 'react';

/**
 * Ce hook est utilisé pour définir si les actions à effectuer sur le menu de détails doivent s'afficher ou pas
 * @param {Object} param0 Objet contenant les propriétés entity, list, etatPath
 * @param {Object} param0.entity L'objet que l'on manipule dans un état donné
 * @param {Array} param0.list la liste des états à exclure
 * @param {Array} param0.etatPath chemin de la prorpiété etat dans l'objet entity, par défaut ce path est : "etat"
 * @returns {Object} Objet contenant la propriété isShouldBeRendered
 */
export const useShouldBeRendered = ({ entity, list, etatPath = 'etat' }) => {
    const [isShouldBeRendered, setShouldBeRendered] = useState(false);
    useEffect(() => {
        if (!list?.length) {
            setShouldBeRendered(true);
        } else if (
            entity &&
            list.length &&
            !list.some(
                (item) => item === resolvePathPropertyOfObject(etatPath, entity)
            )
        ) {
            setShouldBeRendered(true);
        }
    }, [entity, list, etatPath]);
    return { isShouldBeRendered };
};
