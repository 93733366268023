import { TYPE_ORGANISATION, URL_CONST } from '@napp-inc/jnapp-util';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

const setDefaultProperties = ({ type }) => {
    // fields mapper
    const defaultMapper = [
        {
            field: 'typeEntite',
            displayName: 'Type organisation'
        },
        {
            field: 'valeurTypeEntite.designation',
            displayName: 'Organisation'
        },
        {
            field: 'devise',
            displayName: 'devise'
        },
        {
            field: 'valeur',
            displayName: 'valeur'
        },
        {
            field: 'type',
            displayName: 'Type'
        },
        {
            field: 'etat',
            displayName: 'etat'
        },
        {
            field: 'dateCreation',
            displayName: 'Création'
        }
    ];
    // DefaultListerners
    const filters = [];

    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    return { mapper: [], listerners: [] };
};

export function ListTauxForm() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));

    /**
     * Common form processes
     */
    const { typeEntite } = useGetUserDetail({
        nappUser
    });
    const { mapper } = setDefaultProperties({
        type: typeEntite
    });
    return (
        <ListGenericForm
            reduxPropertyName="tauxValidation"
            functionName={URL_CONST.GET_LIST_TAUX_ORGANISATION_BY_ETAT}
            nodeName={REDUX_NODE_NAME.TAUX_VALIDATION}
            payload={{ typeOrganisation: typeEntite }}
            mapper={mapper}
        />
    );
}
