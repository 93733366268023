import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST, MONNAIES } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    selectedDevise: '',
    estReference: false,
    symbole: '',
    valeur: 0,
    coupures: []
};

export function CreationDeviseForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const ajouterCoupure = () => {
        const { valeur } = fields;
        if (!valeur) return;
        const data = {
            valeur,
            id: `${valeur}`
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: { field: 'coupures', data }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            newDevise: {
                code: fields.selectedDevise.code,
                designation: fields.selectedDevise.designation,
                estReference: fields.estReference,
                symbole: fields.symbole
            },
            coupures: fields.coupures.map((coup) => coup.valeur)
        };
        // console.log(JSON.stringify(payload));
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_DEVISE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.DEVISES,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper formState={formState} formDispatcher={formDispatcher}>
            <CustomRow>
                <CustomDropdown
                    options={MONNAIES}
                    label="Devise*"
                    defaultOption="Sélectionner devise"
                    // disabled={![].length}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedDevise"
                    id="creationDeviseSelectedDeviseId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Symbole*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="symbole"
                    formDispatcher={formDispatcher}
                    name="symbole"
                    id="creationDeviseSymboleId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="isRefenceDevise"
                    text="Devise de référence?"
                    value={fields.estReference}
                    name="estReference"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel className="col-12 col-sm-2">Coupure</CustomLabel>
                <CustomInput
                    type="number"
                    divClassName="col-12 col-sm-8"
                    isInputGroup={false}
                    min={1}
                    placeholder="Nombre coupure"
                    formDispatcher={formDispatcher}
                    name="valeur"
                    id="deviseValeurId"
                    formState={formState}
                />
                <CustomAddButton
                    className="col-sm-1"
                    callback={ajouterCoupure}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!!fields.coupures.length}>
                <CustomCol className="offset-md-2 col-md-10 col-12">
                    <SimpleTable
                        formDispatcher={formDispatcher}
                        listSelectedItemName="coupures"
                        identifier="id"
                        data={fields.coupures}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                field: 'valeur',
                                displayName: 'Valeur'
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
