import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';

// FIXME: define the logic of the table action

/**
 *
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {Boolean} param0.isTwice si True alors on affiche 2 actions
 * @param {Boolean} param0.isSelf si True alors on affiche 1 action
 * @param {Object} param0.rowData Données de la ligne selectionnée
 * @returns
 */
export function TableAction({
    isTwice = false,
    isSelf = false,
    rowData,
    callback,
    dropCallback
}) {
    return (
        <div>
            {isSelf && (
                <FiEdit
                    size="23"
                    className="text-primary"
                    onClick={() => callback({ data: rowData })}
                />
            )}
            {isTwice && (
                <>
                    <FaEye
                        size="23"
                        className="text-primary"
                        onClick={() => callback({ data: rowData })}
                    />
                    <FiTrash2
                        size="23"
                        className="text-danger"
                        onClick={() => dropCallback({ data: rowData })}
                    />
                </>
            )}
        </div>
    );
}
