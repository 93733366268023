export const schema = [
    {
        description: 'revendeur.designation',
        title: 'Revendeur',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'Etat',
        className: 'border-bottom'
    },
    {
        description: 'estNonCommission',
        title: 'Commission',
        isBoolean: true,
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
