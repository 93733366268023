import { fetchElement, submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    NAPP,
    TYPE_CAISSE_ADMINISTRATION,
    TYPE_CAISSE_SUPER_DEALER_AFFECTATION,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomConfirmButton,
    CustomDropdown,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';

const defaultFields = {
    actionEncours: '',
    selectedUser: {},
    selectedTypeCaissier: {}
};

/**
 * @description defaultDeps de notre state
 */
const defaultDependencies = { fields: ['users'] };

const defaultElements = ['caissier'];

export function DetailCaisseForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher,
        typeEntite,
        reduxProps
    } = useGetDetailForm({
        nodeName: 'caisses',
        defaultFields,
        defaultElements,
        defaultDependencies,
        listNode: [
            {
                name: 'users',
                format: (usersList) =>
                    usersList.map((item) => ({
                        ...item,
                        owner: item.id,
                        designation: `${item?.personne?.prenom || ''} ${
                            item?.personne?.nom || ''
                        }`
                    }))
            }
        ],
        listFunctions: [
            {
                id: 'users',
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER
            }
        ]
    });

    const { fields, form, elements } = formState;

    /**
     * @description fonction pour fetcher les caissier
     */
    const onSearchCaissier = () => {
        const { status: etat } = elements.caissier;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_USER_A_SELECTIONNER,
                elementPropertyName: 'caissier',
                etat,
                payload: {
                    typeEntite: fields.selectedTypeCaissier?.code
                },
                reduxDispatcher
            })
        );
    };
    const renderUser = (users = []) => {
        const list = [];
        users.forEach((element) => {
            const findOne = list.find((item) => item.owner === element.owner);
            if (!findOne) {
                list.push(element);
            }
        });
        return list.map((item) => ({
            owner: item.owner,
            designation: `${item?.prenom || ''} ${item?.nom || ''}`
        }));
    };
    const updateEtat = (etat) => {
        const { selectedUser } = fields;
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        const propertyToAdd = {};
        if (actionEncours !== ETAT.ETAT_ANNULER && selectedUser.owner) {
            propertyToAdd.owner = selectedUser.owner;
        }
        const payload = {
            etat,
            creator,
            idCaisse: entity?.id,
            ...propertyToAdd
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_CAISSE,
                payload,
                fields,
                reduxNodeName: DB_NODE.CAISSE,
                reduxDispatcher
            })
        );
    };

    const TYPE_CAISSES =
        typeEntite === NAPP
            ? TYPE_CAISSE_ADMINISTRATION
            : TYPE_CAISSE_SUPER_DEALER_AFFECTATION;
    const mapperToAdd =
        typeEntite === TYPE_ORGANISATION.NAPP
            ? [
                  {
                      description: 'designation',
                      title: 'Designation',
                      className: 'border-bottom'
                  },
                  {
                      title: 'Type Entité',
                      description: 'typeEntite',
                      className: 'border-bottom'
                  },
                  {
                      title: 'Agence',
                      description: 'valeurTypeEntite.designation',
                      className: 'border-bottom'
                  }
              ]
            : [
                  {
                      description: 'designation',
                      title: 'Designation',
                      className: 'border-bottom'
                  }
              ];

    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_CAISSE_BY_ID}
            nodeName={DB_NODE.CAISSE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Caisse"
            entity={entity}
            id={id}
            schema={mapperToAdd.concat(schema)}
            idToken={idToken}
            listDependencies={[
                {
                    dependency: 'users',
                    etat: reduxProps?.etatUsers
                }
            ]}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_ADMINISTRATION
                        .VALIDER_CREATION_CAISSE,
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_ADMINISTRATION
                        .ANNULER_CREATION_CAISSE
                ]}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        entity?.etat !== ETAT.ETAT_VALIDER &&
                        entity?.etat !== ETAT.ETAT_ANNULER
                    }
                >
                    <CustomRow isShouldBeRendered={typeEntite === NAPP}>
                        <CustomDropdown
                            options={TYPE_CAISSES.filter((item) =>
                                entity?.typeEntite.includes(item.code)
                            )}
                            label="Type Caissier*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-8"
                            formDispatcher={formDispatcher}
                            name="selectedTypeCaissier"
                            id="selectedTypeCaissierDetailCaisseId"
                            formState={formState}
                            defaultOption="Selectionner Caissier"
                        />
                        <CustomButtonLoader
                            className="col-2 h-25"
                            type="button"
                            text="rechercher"
                            onClick={onSearchCaissier}
                            disabled={
                                elements?.caissier?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            isActionEncours={
                                elements?.caissier?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                        />
                    </CustomRow>
                    <CustomRow
                        isShouldBeRendered={typeEntite === 'SuperDealer'}
                    >
                        <CustomDropdown
                            options={TYPE_CAISSES}
                            label="Type Caissier*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-8"
                            formDispatcher={formDispatcher}
                            name="selectedTypeCaissier"
                            id="selectedTypeCaissierDetailCaisseId"
                            formState={formState}
                            defaultOption="Selectionner Caissier"
                        />
                        <CustomButtonLoader
                            className="col-2 h-25"
                            type="button"
                            text="rechercher"
                            onClick={onSearchCaissier}
                            disabled={
                                elements?.caissier?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            isActionEncours={
                                elements?.caissier?.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                        />
                    </CustomRow>
                    {elements?.caissier?.value?.length ? (
                        <CustomRow>
                            <CustomDropdown
                                options={renderUser(elements?.caissier?.value)}
                                label="Caissier*"
                                labelClassName="col-12 col-sm-2"
                                divClassName="col-12 col-sm-10"
                                formDispatcher={formDispatcher}
                                name="selectedUser"
                                id="selectedUserDetailCaisse"
                                formState={formState}
                                idProperty="owner"
                                defaultOption="Selectionner Caissier"
                            />
                        </CustomRow>
                    ) : null}
                    <CustomRow
                        isShouldBeRendered={typeEntite === 'CashCollecteur'}
                    >
                        <CustomDropdown
                            options={reduxProps.users}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner l'agent"
                            label="Agent*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedUser"
                            id="selectedUserId"
                            idProperty="owner"
                            // uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                </ConditionalRenderingWrapper>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
