import { ViewLayout } from './view-layout';

export function ListArticleColis() {
    const renderContent = () => (
        <ViewLayout>
            <h2>Liste Article Colis</h2>
            {/* <ListGenericForm
                identifier='id'
                reduxPropertyName="services"
                functionName={URL_CONST.GET_LIST_SALLE_MANGER}
                nodeName={DB_NODE.SALLE_MANGER_TABLE}
                mapper={[
                    {
                        field: 'id',
                        displayName: 'id',
                        position: 1
                    },
                    {
                        field: 'code',
                        displayName: 'Code',
                        position: 2
                    },
                    {
                        field: 'designation',
                        displayName: 'Designation',
                        position: 3
                    }
                ]}
            /> */}
        </ViewLayout>
    );
    return renderContent();
}
