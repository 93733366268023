import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

const groupementStock = (data) => {
    const result = [];
    const list = data?.listReturn || [];
    list.forEach((item) => {
        let typeBusiness = '';
        if (item?.detail?.typeBusiness?.length < 2) {
            typeBusiness = item?.detail?.typeBusiness?.join(' , ');
            typeBusiness = typeBusiness.replace('Businessmodel', 'BM');
            typeBusiness = typeBusiness.replace('BusinessModel', 'BM');
            typeBusiness = typeBusiness.replace('Traditionnelbusiness', 'TB');
        }
        result.push({
            id: item?.valeurTypeProprietaire,
            initial: item?.soldeOuverture,
            entree: item?.entree,
            sortie: item?.sortie,
            solde: item?.soldeFermeture,
            designation: `${item?.detail?.marchand?.designation || ''}${
                typeBusiness ? ` - ${typeBusiness}` : ''
            }`
        });
    });
    if (result?.length) {
        result.push({
            id: 'total',
            designation: 'Total',
            initial: result.reduce((a, b) => a + b.initial, 0),
            entree: result.reduce((a, b) => a + b.entree, 0),
            sortie: result.reduce((a, b) => a + b.sortie, 0),
            solde: result.reduce((a, b) => a + b.solde, 0)
        });
    }
    return result;
};

export function ListStockSuperDealerForm() {
    return (
        <ListGenericForm
            reduxPropertyName="reportsStockSuperDealer"
            functionName={URL_CONST.GET_LIST_STOCK_SUPER_DEALER}
            nodeName={REDUX_NODE_NAME.REPORT_STOCK_SUPER_DEALER}
            payload={{
                typeEntite: 'Journalier',
                dateDebut: TIME.utc000000(TODAY.at000000()),
                // dateDebut: TIME.utc000000(
                //     TIME.add(TODAY.at000000(), { days: -30 })
                // ),
                dateFin: TIME.utc235959(TODAY.at235959())
            }}
            isLinkToDetail={false}
            formater={groupementStock}
            mapper={[
                {
                    displayName: 'Stock',
                    field: 'designation'
                },
                {
                    displayName: 'Initial',
                    field: 'initial'
                },
                {
                    displayName: 'Entrée',
                    field: 'entree'
                },
                {
                    displayName: 'Sortie',
                    field: 'sortie'
                },
                {
                    displayName: 'Solde',
                    field: 'solde'
                }
            ]}
        />
    );
}
