import { fetchElement, useFetchAndListening } from '@napp-inc/jnapp-hook';
import { FORM_CONST, URL_CONST } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import {
    CustomAddButton,
    CustomAlert,
    CustomButton,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

export const DetailProduitTransfertStockMateriel = ({
    entity,
    formDispatcher,
    idToken,
    formState,
    reduxDispatcher,
    isBeneficiaireAgence
}) => {
    const { fields, elements } = formState;
    const { colonnesAgence, etatColonneAgence } = useSelector((reduxState) => ({
        colonnesAgence: reduxState.colonnesAgence,
        etatColonneAgence: reduxState.etat.colonnesAgence.etat
    }));
    const produitsToComplete = (elements?.details?.value || [])
        .filter((item) => item?.estNonStock)
        .map((item) => ({
            ...item,
            designation: item?.produit?.designation || '',
            code: item?.produit?.code || ''
        }));

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_COLONNE_AGENCE,
                nodeName: REDUX_NODE_NAME.COLONNE_AGENCE,
                etat: etatColonneAgence
            }
        ]
    });
    const fetchDetail = () => {
        const formater = (list) =>
            list.map((item) => ({
                ...item,
                emplacement: item.emplacement ? 'défini' : 'non défini'
            }));
        if (
            entity &&
            entity.id &&
            elements.details.status === FORM_CONST.STATUT.ETAT_INITIAL
        ) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'details',
                    payload: { id: entity.id },
                    functionName:
                        URL_CONST.GET_LIST_DETAIL_TRANSFERT_STOCK_MATERIEL,
                    etat: elements.details.status,
                    formDispatcher,
                    formater,
                    reduxDispatcher
                })
            );
        }
    };
    const voirPlusAction = () => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'isVoirPlus',
                data: true
            }
        });
        fetchDetail();
    };
    const voirMoinsAction = () => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'isVoirPlus',
                data: false
            }
        });
    };
    const addColonneProduit = () => {
        const {
            selectedProduitDetail,
            selectedColonneDetail,
            listColonneProduit,
            newCode,
            newDesignation,
            newPrice
        } = fields;
        if (
            isBeneficiaireAgence &&
            (!selectedProduitDetail?.code || !selectedColonneDetail?.code)
        )
            return;
        if (
            !isBeneficiaireAgence &&
            (!selectedProduitDetail?.code ||
                !newCode ||
                !newDesignation ||
                !newPrice)
        )
            return;
        if (
            listColonneProduit.find(
                (item) => item?.produit?.code === selectedProduitDetail.code
            )
        )
            return;

        const dataToAdd = {};
        if (isBeneficiaireAgence) {
            dataToAdd.colonne = selectedColonneDetail;
        } else {
            dataToAdd.newCode = newCode;
            dataToAdd.newDesignation = newDesignation;
            dataToAdd.newPrice = newPrice;
        }
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listColonneProduit',
                identifier: 'code',
                data: {
                    code: selectedProduitDetail.code,
                    produit: selectedProduitDetail,
                    ...dataToAdd
                }
            }
        });
    };
    const renderContent = () => {
        if (!entity) return null;
        if (entity && !fields.isVoirPlus)
            return (
                <CustomButton onClick={voirPlusAction}>
                    Voir plus ...
                </CustomButton>
            );
        return (
            <>
                <CustomAlert error={elements?.details?.error} />
                <CustomRow>
                    <CustomCol className="col-12">
                        <SimpleTable
                            identifier="id"
                            isShowDropButton={false}
                            data={elements.details.value || []}
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    field: 'produit.code',
                                    displayName: 'Code'
                                },
                                {
                                    position: 2,
                                    field: 'produit.designation',
                                    displayName: 'Désignation'
                                },
                                {
                                    position: 3,
                                    field: 'quantite',
                                    displayName: 'quantite'
                                },
                                {
                                    position: 4,
                                    field: 'emplacement',
                                    displayName: 'emplacement'
                                }
                            ]}
                        />
                    </CustomCol>
                </CustomRow>
                {!!produitsToComplete.length && (
                    <>
                        {isBeneficiaireAgence && (
                            <>
                                <CustomRow>
                                    <CustomDropdown
                                        options={produitsToComplete}
                                        label="Choix colonne*"
                                        labelClassName="col-12 col-sm-2"
                                        divClassName="col-sm-4"
                                        formDispatcher={formDispatcher}
                                        name="selectedProduitDetail"
                                        id="selectedProduitDetailUpdateTransfertStockMateriel"
                                        formState={formState}
                                        idProperty="code"
                                    />
                                    <CustomDropdown
                                        options={colonnesAgence}
                                        divClassName="col-sm-4"
                                        formDispatcher={formDispatcher}
                                        name="selectedColonneDetail"
                                        id="selectedColonneDetailUpdateTransfertStockMateriel"
                                        formState={formState}
                                        idProperty="code"
                                    />
                                    <CustomCol className="col-sm-2">
                                        <CustomAddButton
                                            size="25em"
                                            callback={addColonneProduit}
                                        />
                                    </CustomCol>
                                </CustomRow>
                                <CustomRow>
                                    <CustomCol className="offset-md-2 col-12 col-md-10">
                                        <SimpleTable
                                            identifier="code"
                                            isShowDropButton
                                            listSelectedItemName="listColonneProduit"
                                            data={fields.listColonneProduit}
                                            formDispatcher={formDispatcher}
                                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                            filter={false}
                                            bottomPagination={false}
                                            isLinkToDetail={false}
                                            mapper={[
                                                {
                                                    position: 1,
                                                    field: 'produit.designation',
                                                    displayName: 'Produit'
                                                },
                                                {
                                                    position: 2,
                                                    field: 'colonne.designation',
                                                    displayName: 'Colonne'
                                                }
                                            ]}
                                        />
                                    </CustomCol>
                                </CustomRow>
                            </>
                        )}
                        {!isBeneficiaireAgence && (
                            <>
                                <CustomRow>
                                    <CustomDropdown
                                        options={produitsToComplete}
                                        label="Produit*"
                                        labelClassName="col-12 col-sm-2"
                                        divClassName="col-sm-10"
                                        formDispatcher={formDispatcher}
                                        name="selectedProduitDetail"
                                        id="selectedProduitDetailUpdateTransfertStockMateriel"
                                        formState={formState}
                                        idProperty="code"
                                    />
                                </CustomRow>
                                <CustomRow>
                                    <CustomInput
                                        isInputGroup={false}
                                        label="Nouveau code*"
                                        labelClassName="offset-sm-2 col-12 col-md-2"
                                        divClassName="col-12 col-md-8"
                                        placeholder="Saisissez le nouveau code"
                                        formDispatcher={formDispatcher}
                                        name="newCode"
                                        id="newCodeUpdateTransfertStoclMateriel"
                                        formState={formState}
                                    />
                                </CustomRow>
                                <CustomRow>
                                    <CustomInput
                                        isInputGroup={false}
                                        label="Nouvelle désignation*"
                                        labelClassName="offset-sm-2 col-12 col-md-2"
                                        divClassName="col-12 col-md-8"
                                        placeholder="Saisissez la nouvelle désignation"
                                        formDispatcher={formDispatcher}
                                        name="newDesignation"
                                        id="newDesignationUpdateTransfertStoclMateriel"
                                        formState={formState}
                                    />
                                </CustomRow>
                                <CustomRow>
                                    <CustomInput
                                        label="Nouveau prix*"
                                        labelClassName="offset-sm-2 col-12 col-md-2"
                                        divClassName="col-12 col-sm-6"
                                        type="number"
                                        isFloat
                                        isInputGroup={false}
                                        placeholder="Saisissez le nouveau prix"
                                        formDispatcher={formDispatcher}
                                        name="newPrice"
                                        id="newPriceUpdateTransfertStoclMateriel"
                                        formState={formState}
                                    />
                                    <CustomCol className="col-sm-2">
                                        <CustomAddButton
                                            size="25em"
                                            callback={addColonneProduit}
                                        />
                                    </CustomCol>
                                </CustomRow>
                                <CustomRow>
                                    <CustomCol className="offset-md-2 col-12 col-md-10">
                                        <SimpleTable
                                            identifier="code"
                                            isShowDropButton
                                            listSelectedItemName="listColonneProduit"
                                            data={fields.listColonneProduit}
                                            formDispatcher={formDispatcher}
                                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                            filter={false}
                                            bottomPagination={false}
                                            isLinkToDetail={false}
                                            mapper={[
                                                {
                                                    position: 1,
                                                    field: 'produit.designation',
                                                    displayName: 'Produit'
                                                },
                                                {
                                                    position: 2,
                                                    field: 'newCode',
                                                    displayName: 'Nouveau code'
                                                },
                                                {
                                                    position: 3,
                                                    field: 'newDesignation',
                                                    displayName:
                                                        'Nouvelle désignation'
                                                },
                                                {
                                                    position: 4,
                                                    field: 'newPrice',
                                                    displayName: 'Nouveau prix'
                                                }
                                            ]}
                                        />
                                    </CustomCol>
                                </CustomRow>
                            </>
                        )}
                    </>
                )}
                <CustomButton onClick={voirMoinsAction}>
                    Voir moins
                </CustomButton>
            </>
        );
    };
    return renderContent();
};
