import { ONLY_FETCH, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { ViewLayout } from './view-layout';

export function ListPaiementCompteExecutionForm() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                fetchType={ONLY_FETCH}
                reduxPropertyName="paiementCompteExecution"
                functionName={
                    URL_CONST.GET_LIST_ACCUSE_RECEPTION_CAISSE_EXECUTION_CHARGE
                }
                nodeName={REDUX_NODE_NAME.PAIEMENT_COMPTE_EXECUTION}
                identifier="id"
                mapper={[
                    {
                        field: 'estCaisseExecution',
                        displayName: 'Cash',
                        position: 2
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 3
                    },
                    {
                        field: 'montant',
                        displayName: 'Montant',
                        position: 4
                    },
                    {
                        field: 'dateExecution',
                        displayName: 'Date Execution',
                        isDate: true,
                        position: 5
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 6
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
