import { useIsLoadComplete } from '@napp-inc/jnapp-hook';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';

/**
 * Composant affichant un loader en attendant que le chargement arrive à terme.
 * Dans une utilisation directe, il est bien plus adapté aux forms de details qui ne chargent que entity comme depedance
 * @param {Object} param0 objet contenant dependecies et children
 * @param {Object} param0.dependencies objet pouvant contenir entity et/ou fields comme propriété(s)
 * @param {Objet} param0.children propriété children de React
 * @returns {React.Component}
 */
export const LoadCompleteWrapper = ({
    dependencies,
    entity,
    children,
    optionalDependencies
}) => {
    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        dependencies,
        optionalDependencies
    });
    const renderContent = () => {
        if (!entity?.id && !isLoadComplete && !isLoadFailed) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return (
                <CustomAlert
                    error={
                        dependencies?.entity?.error ||
                        'Une erreur est survenue lors du chargement'
                    }
                />
            );
        }
        return children;
    };
    return renderContent();
};
