import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { DB_NODE } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListTransfertFondsForm() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { isNapp } = useGetUserDetail({ nappUser });
    const mapper = isNapp
        ? [
              {
                  displayName: 'Banque',
                  field: 'institutionFinanciere.designation'
              },
              {
                  displayName: 'SuperDealer',
                  field: 'valeurTypeEntite.designation'
              },
              {
                  displayName: 'Numéro compte',
                  field: 'numero'
              },
              {
                  field: 'devise',
                  displayName: 'Devise'
                  //   concat: deviseReference?.code
              },
              {
                  field: 'montant',
                  displayName: 'montant'
              },
              {
                  field: 'etat',
                  displayName: 'Etat'
              },
              {
                  field: 'dateCreation',
                  displayName: 'Date'
              }
          ]
        : [
              {
                  displayName: 'Banque',
                  field: 'institutionFinanciere.designation'
              },
              {
                  displayName: 'Numéro compte',
                  field: 'numero'
              },
              {
                  field: 'devise',
                  displayName: 'Devise'
                  //   concat: deviseReference?.code
              },
              {
                  field: 'montant',
                  displayName: 'montant'
              },
              {
                  field: 'etat',
                  displayName: 'Etat'
              },
              {
                  field: 'dateCreation',
                  displayName: 'Date'
              }
          ];
    return (
        <ListGenericForm
            reduxPropertyName="transfertFonds"
            functionName={URL_CONST.GET_LIST_TRANSFERT_FONDS_INITIALS}
            nodeName={DB_NODE.TRANSFERT_FONDS}
            // payload={{
            //     dateDebut: 0,
            //     dateFin: TIME.utc235959(TODAY.at000000())
            // }}
            mapper={mapper}
        />
    );
}
