import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { ref, uploadBytes } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInputFile,
    CustomRow,
    FormWrapper
} from '../../../components';
import { releveBancaire } from '../../../firebase';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';
import { ReleveBancaireSchema as uiValidator } from './validation';

// releveBancaire

/**
 * @desc defaultFields
 */
const defaultFields = {
    file: {},
    selectedBanque: {}
};

/**
 * @desc defaultFiles
 */
const defaultFiles = ['documentPresentation'];

/**
 *
 * @desc defaultDependencies
 */
const defaultDependencies = { fields: ['banques' /** , comptes */] };

export function CreationUploadFichierForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        institutionsFinanciere,
        etatInstitutionFinanciere /** , comptes, etatCompte */
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionFinanciere: reduxState.etat.institutionsFinanciere.etat
        // comptes: reduxState.comptes,
        // etatCompte: reduxState.etat.comptes.etat
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        files: defaultFiles,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionFinanciere
            }
            // {
            //     isOnlyFetch: true,
            //     nodeName: DB_NODE.COMPTE,
            //     functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
            //     etat: etatCompte,
            //     formater: (tableau = []) =>
            //         tableau.map((item) => ({
            //             ...item,
            //             code: item.id,
            //             designation: `${item.institutionFinanciere?.designation} - ${item.numero} - ${item.devise}`
            //         }))
            // }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!fields.selectedBanque.code) return;
        const timedate = Date.now();
        formDispatcher({
            type: FORM_CONST.CREATION_EN_COURS
        });
        const name = `${timedate}-${fields.file.name}`;
        uploadBytes(ref(releveBancaire, name), fields.file)
            .then((response) => {
                const payload = {
                    newTraitementReleveBancaire: {
                        institutionFinanciere: {
                            code: fields.selectedBanque.code
                        },
                        fileName: response.metadata.name
                    }
                };
                if (response.metadata.name) {
                    formDispatcher(
                        submitPostAction({
                            idToken,
                            functionName:
                                URL_CONST.POST_TRAITEMENT_RELEVE_BANCAIRE,
                            payload,
                            fields,
                            reduxNodeName:
                                REDUX_NODE_NAME.UPLOAD_RELEVE_BANCAIRE,
                            reduxDispatcher,
                            uiValidator
                        })
                    );
                }
            })
            .catch((error) => {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: error,
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
            });
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'banques',
                    etat: etatInstitutionFinanciere
                }
                // {
                //     dependency: 'comptes',
                //     etat: etatCompte
                // }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={institutionsFinanciere}
                    propertyToDisplay="designation"
                    idProperty="code"
                    label="Banque"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="Sélectionner une banque"
                    formDispatcher={formDispatcher}
                    name="selectedBanque"
                    id="creationUploadFichierInstitutionFinanciere"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            {/* <CustomRow>
                <CustomDropdown
                    options={comptes}
                    propertyToDisplay="designation"
                    idProperty="code"
                    label="Banque"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="Sélectionner un compte"
                    formDispatcher={formDispatcher}
                    name="selectedCompte"
                    id="creationUploadFichierSelectedCompteId"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow> */}
            <CustomRow>
                <CustomInputFile
                    id="creationUploadFichierInputFichier"
                    name="file"
                    label="Fichier"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    accept=".doc, .docx, .pdf, .txt, .xlsx"
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={
                            (form.status === FORM_CONST.CREATION_EN_COURS ||
                                fields.fileStatus === 'UPLOAD_PENDING') &&
                            fields.fileStatus !== ''
                        }
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
