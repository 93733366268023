import { DB_NODE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListConfigurationKindistributionForm() {
    return (
        <ListGenericForm
            reduxPropertyName="configurationsKindb"
            functionName={URL_CONST.GET_ALL_CONFIGURATION_KIN_DB}
            nodeName={DB_NODE.CONFIGURATION_KINDB}
            mapper={[
                {
                    displayName: 'Marchand',
                    field: 'marchand.designation',
                    position: 1
                },
                {
                    displayName: 'Numero',
                    field: 'numero',
                    position: 2
                },
                {
                    displayName: 'Est Actif',
                    field: 'estActif',
                    position: 4
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation',
                    position: 5
                }
            ]}
        />
    );
}
