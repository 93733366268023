import { DB_NODE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListImputationCaisseForm() {
    return (
        <ListGenericForm
            reduxPropertyName="imputations"
            functionName={URL_CONST.GET_LIST_IMPUTATION_ORGANISATION}
            nodeName={DB_NODE.IMPUTATION}
            mapper={[
                {
                    field: 'designation',
                    displayName: 'Désignation'
                },
                {
                    field: 'parent.designation',
                    displayName: 'Parent'
                },
                {
                    field: 'typeOperation',
                    displayName: 'Opération'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ]}
        />
    );
}
