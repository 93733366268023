/**
 * Header des autres layouts
 * @param {Object} param0 objet qui contient les props du composant:
 * @param {React.Component} param0.icon Icon du header
 * @param {string} param0.title Titre du header
 * @returns {React.Component}
 */
export default function SettingHeader({ icon, title }) {
    return (
        <div className="d-flex justify-content-between mt-1 mb-1">
            <div className="d-flex justify-content-center align-items-center mb-2 mb-md-0">
                <p>{icon}</p>
                <p className="lead py-1 px-1">{title}</p>
            </div>
        </div>
    );
}
