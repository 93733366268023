export const schema = [
    {
        description: 'typeProprietaire',
        title: 'Opération',
        className: 'border-bottom'
    },
    {
        description: 'caisse.designation',
        title: 'Caisse',
        className: 'border-bottom'
    },
    {
        description: 'compte.numero',
        title: 'Numéro Compte',
        className: 'border-bottom'
    },
    {
        description: 'institutionFinanciere.designation',
        title: 'Banque',
        className: 'border-bottom'
    },
    {
        description: 'montant',
        title: 'Montant',
        className: 'border-bottom'
    },
    {
        description: 'devise',
        title: 'Devise',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'Etat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        className: 'border-bottom',
        description: 'dateCreation'
    }
];
