import { CATEGORIE_MARCHAND } from '@napp-inc/jnapp-util';
import { CustomRadioButton } from '../../../../components';

/**
 * Produit Materiel
 * @param {*} param0 Object containing the following properties:
 * @param {Function} param0.formDispatcher Function to dispatch the form actions
 * @param {Object} param0.formState Object containing the form state
 * @param {Object} param0.uiValidator Object containing the validation schema
 * @returns {JSX.Element}
 */
export function ProduitMateriel({ formState, formDispatcher, uiValidator }) {
    return (
        <>
            <small className="lead fs-5">Type organisation</small>
            <CustomRadioButton
                formDispatcher={formDispatcher}
                formState={formState}
                name="sousTypeRadio"
                divClassName="col-sm-4 mt-2"
                id={CATEGORIE_MARCHAND.TYPE_ORGANISATION.PROXIMITE}
                value={CATEGORIE_MARCHAND.TYPE_ORGANISATION.PROXIMITE}
                labelClassName="blockquote-footer"
                label="Proximité"
                uiValidator={uiValidator}
            />
            <CustomRadioButton
                formDispatcher={formDispatcher}
                formState={formState}
                name="sousTypeRadio"
                divClassName="col-sm-4 mt-2"
                id={CATEGORIE_MARCHAND.TYPE_ORGANISATION.PROFESSIONNEL}
                value={CATEGORIE_MARCHAND.TYPE_ORGANISATION.PROFESSIONNEL}
                labelClassName="blockquote-footer"
                label="Professionnel"
                uiValidator={uiValidator}
            />
        </>
    );
}
