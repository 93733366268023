import { Collapse } from 'react-bootstrap';

/**
 * Collapse
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {any}  param0.children Component qui affiche les données
 * @param {Boolean}  param0.isIn Affiche ou non le composant
 * @returns {React.Component}
 */
export function CustomCollapse({ children, isIn, className }) {
    return (
        <Collapse in={isIn}>
            <div className={className}>{children}</div>
        </Collapse>
    );
}
