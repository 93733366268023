import { MdOutlinePayments } from 'react-icons/md';

export const CheckingPaiementRelPath = {
    name: 'Checking paiement',
    path: 'create'
};

export const ListCheckingPaiementRelPath = {
    name: 'Paiements',
    path: 'lcheckp',
    icon: <MdOutlinePayments className="icon-custom" />
};

export const PaiementDetailRelPath = {
    name: '',
    path: ':id'
};
