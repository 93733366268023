import { useEffect, useState } from 'react';

const isUserServiceExiste = (services = [], code = []) =>
    code.some((item) =>
        services.find(
            ({ sysService, estActif }) =>
                estActif && sysService?.code === item?.code
        )
    );
function menuOfAttachement(dependencies) {
    if (dependencies?.find((item) => item === 'PrincipalMenu'))
        return 'PrincipalMenu';
    if (dependencies?.find((item) => item === 'ConfigMenu'))
        return 'ConfigMenu';
    return 'DefaultMenu';
}

export const useMenuBuilder = (paths, services) => {
    const [menus, setMenus] = useState({
        PrincipalMenu: [],
        ConfigMenu: [],
        DefaultMenu: []
    });
    useEffect(() => {
        const menuToBuild = {
            PrincipalMenu: [],
            ConfigMenu: [],
            DefaultMenu: []
        };
        const childrenOfPrinciplaDefaultLayout = paths?.[0]?.children || [];
        childrenOfPrinciplaDefaultLayout.forEach(
            (childOfPrinciplaDefaultLayout) => {
                if (childOfPrinciplaDefaultLayout?.index);
                else if (
                    !childOfPrinciplaDefaultLayout?.isLayout &&
                    childOfPrinciplaDefaultLayout?.isVisible &&
                    !childOfPrinciplaDefaultLayout?.isDropDownMenu
                ) {
                    // ignorerement de l'index du default layout
                    // traitement des menus drop down du menu principal
                    if (
                        isUserServiceExiste(
                            services,
                            childOfPrinciplaDefaultLayout?.code
                        )
                    ) {
                        const menuKey = menuOfAttachement(
                            childOfPrinciplaDefaultLayout?.dependencies
                        );
                        menuToBuild?.[menuKey]?.push({
                            ...childOfPrinciplaDefaultLayout,
                            children: []
                        });
                    }
                } else if (
                    !childOfPrinciplaDefaultLayout?.isLayout &&
                    childOfPrinciplaDefaultLayout?.isVisible &&
                    childOfPrinciplaDefaultLayout?.isDropDownMenu
                ) {
                    // Traitement des menus drop down du menu principal
                    const currentChildrenOfDropdown =
                        childOfPrinciplaDefaultLayout?.children || [];
                    const newChildrenOfDropdownAfterServiceChecking = [];
                    currentChildrenOfDropdown.forEach((item) => {
                        if (
                            item?.isVisible &&
                            isUserServiceExiste(services, item?.code)
                        ) {
                            newChildrenOfDropdownAfterServiceChecking.push({
                                ...item,
                                children: []
                            });
                        }
                    });
                    if (newChildrenOfDropdownAfterServiceChecking?.length) {
                        const menuKey = menuOfAttachement(
                            childOfPrinciplaDefaultLayout?.dependencies
                        );
                        menuToBuild?.[menuKey]?.push({
                            ...childOfPrinciplaDefaultLayout,
                            children: newChildrenOfDropdownAfterServiceChecking
                        });
                    }
                } else if (childOfPrinciplaDefaultLayout?.isLayout) {
                    const childrenOfLayout =
                        childOfPrinciplaDefaultLayout?.children || [];
                    childrenOfLayout.forEach((childOfLayout) => {
                        if (childOfLayout?.index);
                        else if (
                            !childOfLayout?.isDropDownMenu &&
                            childOfLayout?.isVisible &&
                            isUserServiceExiste(services, childOfLayout?.code)
                        ) {
                            // ingorement de l'index du layout
                            // traitement des menus qui ne sont pas dropdonw
                            const menuKey = menuOfAttachement(
                                childOfLayout?.dependencies
                            );
                            menuToBuild?.[menuKey]?.push({
                                ...childOfLayout,
                                children: []
                            });
                        } else if (childOfLayout?.isDropDownMenu) {
                            const currentChildrenOfLayoutDropdown =
                                childOfLayout?.children || [];
                            const newChildrenOfLayoutDropdown = [];
                            currentChildrenOfLayoutDropdown.forEach((item) => {
                                if (
                                    item?.isVisible &&
                                    isUserServiceExiste(services, item?.code)
                                ) {
                                    newChildrenOfLayoutDropdown.push({
                                        ...item,
                                        children: []
                                    });
                                }
                            });
                            if (newChildrenOfLayoutDropdown?.length) {
                                const menuKey = menuOfAttachement(
                                    childOfLayout?.dependencies
                                );
                                menuToBuild?.[menuKey]?.push({
                                    ...childOfLayout,
                                    children: newChildrenOfLayoutDropdown
                                });
                            }
                        }
                    });
                }
            }
        );
        setMenus(menuToBuild);
    }, [paths, services]);
    return menus;
};
