export const schema = [
    {
        title: 'Classe',
        description: 'classe',
        className: 'border-bottom'
    },
    {
        title: 'Période début',
        description: 'periode.dateDebut',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Période fin',
        description: 'periode.dateFin',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Date de création',
        description: 'dateCreation',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Début Exercice',
        description: 'budget.dateDebut',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
