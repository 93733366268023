/**
 * Formulaire personnalisé
 * @param {Object} param0 object qui contient les props du composant:
 * @param {String}  param0.className classe CSS
 * @param {React.Component} param0.children le children du composant
 * @param {Function} param0.onSubmit function qui se déclenche à la soumission du formulaire
 * @returns {React.Component}
 */
export function CustomForm({ className, children, onSubmit }) {
    return (
        <form onSubmit={onSubmit} className={`needs-validation ${className}`}>
            {children}
        </form>
    );
}
