import { AiOutlineCluster } from 'react-icons/ai';
import { HiOutlineCreditCard } from 'react-icons/hi';

/**
 * @desc Creation agence rel paths
 */
export const CreationAgenceViewLayoutRelPath = {
    name: 'Creation agence',
    path: 'creation-agence',
    icon: <AiOutlineCluster className="icon-custom" />
};

export const CreationAgenceCreateRelPath = {
    name: '',
    path: 'create'
};

export const CreationAgenceDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Crediter compte via institut fin rel paths
 */
export const CrediterCompteViaInstitutFinViewLayoutRelPath = {
    name: 'Crediter compte via institut fin',
    path: 'crediter-compte-via-institut-fin',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const CrediterCompteViaInstitutFinCreateRelPath = {
    name: '',
    path: 'create'
};

export const CrediterCompteViaInstitutFinDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Heure supplementaire rel paths
 */
export const HeureSupplementaireViewLayoutRelPath = {
    name: 'Heure supplementaire',
    path: 'hsup',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const HeureSupplementaireCreateRelPath = {
    name: '',
    path: 'create'
};

export const HeureSupplementaireDetailRelPath = {
    name: '',
    path: ':id'
};
