import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    ETAT,
    FORM_CONST,
    URL_CONST,
    ApprovisionnementMonnaieSchema,
    CODE_SERVICE
} from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailApproCompteEmoneyForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({ nodeName: 'approvisionnementsMonnaie' });

    const { fields, form } = formState;
    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payload = {
            etat,
            creator,
            idApprovisionnementMonnaie: entity?.id
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_APPRO_MONNAIE,
                payload,
                fields,
                reduxNodeName: DB_NODE.APPROVISIONNEMENT_MONNAIE,
                reduxValidator: ApprovisionnementMonnaieSchema,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_APPROVISIONNEMENT_MONNAIE_BY_ID}
            nodeName={DB_NODE.APPROVISIONNEMENT_MONNAIE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Approvisionnement E-money"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.FINANCE_ORGANISATION
                        .METTRE_A_JOUR_APPRO_COMPTE_EMONEY
                ]}
            >
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
