import { CONFIG_TECHNIQUE } from '@napp-inc/jnapp-util';
import {
    CustomAddRemoveButton,
    CustomInput,
    CustomRow
} from '../../../components';

export function NumberTypeConfig({
    formState,
    formDispatcher,
    uiValidator,
    callback
}) {
    const { fields } = formState;
    const renderContent = () => (
        <CustomRow>
            <CustomInput
                type="text"
                label="Clé*"
                labelClassName="col-12 col-sm-2"
                divClassName="col-12 col-sm-4"
                isInputGroup={false}
                placeholder="Valeur de la clé"
                formDispatcher={formDispatcher}
                name="key"
                id="keyId"
                formState={formState}
                uiValidator={uiValidator}
            />
            <CustomInput
                isFloat
                type="number"
                divClassName="col-12 col-sm-4"
                isInputGroup={false}
                placeholder="Valeur de la clé"
                formDispatcher={formDispatcher}
                name="numberValue"
                id="numberValueId"
                formState={formState}
                uiValidator={uiValidator}
            />
            {callback &&
            (fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.ARRAY ||
                fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.OBJECT) ? (
                <CustomAddRemoveButton
                    className="btn-success col-sm-2"
                    onClick={callback}
                />
            ) : null}
        </CustomRow>
    );
    return renderContent();
}
