import { submitPostAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    percuAndRetournedMontantDevise
} from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { DynamicMonoSelectAndInputSecond } from '../../../components/special-components/dynamic-mono-select-and-input-second';
import { schema } from './detail-fields';

const defaultFields = {
    actionEncours: '',
    deviseRetourSelect: {},
    deviseRetourInput: 0,
    devisePercuSelect: {},
    devisePercuInput: 0,
    montantPercu: [],
    montantRetour: []
};

const objectKeysPercu = {
    devise: 'devisePercuSelect.code',
    montant: 'devisePercuInput'
};

const objectKeysRetour = {
    devise: 'deviseRetourSelect.code',
    montant: 'deviseRetourInput'
};

export function DetailRecouvrementForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher,
        reduxProps
    } = useGetDetailForm({
        nodeName: 'compteCreance',
        defaultFields,
        listNode: [{ name: 'monnaiesLocale' }],
        listFunctions: [
            {
                id: 'monnaiesLocale',
                isOnlyFetch: true,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE
            }
        ]
    });
    const { fields, form } = formState;
    const onRecouvrement = () => {
        const payload = {
            creator,
            idCompte: id,
            montantsDevise: percuAndRetournedMontantDevise(
                fields.montantPercu,
                fields.montantRetour
            )
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_REMBOURSEMENT_COMMANDE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.COMPTE_CREANCE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_COMPTE_BY_ID}
            nodeName={REDUX_NODE_NAME.COMPTE_CREANCE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            // listDependencies={[
            //     {
            //         dependency: 'monnaiesLocale',
            //         etat: reduxProps.etatMonnaiesLocale
            //     }
            // ]}
            // onSubmit={onRecouvrement}
            subtitle="Recouvrement"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_EMONEY
                        .INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT
                ]}
            >
                <CustomRow>
                    <CustomCol>
                        <div className="shadow card">
                            <div className="card-header">
                                <p className="fw-normal mb-0">Montant perçu</p>
                            </div>
                            <div className="card-body">
                                <CustomRow>
                                    <DynamicMonoSelectAndInputSecond
                                        options={reduxProps.monnaiesLocale.map(
                                            (item) => ({
                                                ...item,
                                                code: item.devise,
                                                designation: item.devise
                                            })
                                        )}
                                        id="deviseMontantPercu"
                                        name="devisePercu"
                                        defaultOption="Selectionner devise"
                                        formState={formState}
                                        formDispatcher={formDispatcher}
                                        type="number"
                                        identifier="devise"
                                        placeholder="montant"
                                        field="montantPercu"
                                        objectKeys={objectKeysPercu}
                                        // uiValidator={uiValidator}
                                    />
                                </CustomRow>
                            </div>
                        </div>
                    </CustomCol>
                    <CustomCol>
                        <div className="shadow card">
                            <div className="card-header">
                                <p className="fw-normal mb-0">
                                    Montant retourné
                                </p>
                            </div>
                            <div className="card-body">
                                <CustomRow>
                                    <DynamicMonoSelectAndInputSecond
                                        options={reduxProps.monnaiesLocale.map(
                                            (item) => ({
                                                ...item,
                                                code: item.devise,
                                                designation: item.devise
                                            })
                                        )}
                                        id="deviseMontantRetour"
                                        name="deviseRetour"
                                        defaultOption="Selectionner devise"
                                        formState={formState}
                                        formDispatcher={formDispatcher}
                                        type="number"
                                        identifier="devise"
                                        placeholder="montant"
                                        field="montantRetour"
                                        objectKeys={objectKeysRetour}
                                        // uiValidator={uiValidator}
                                    />
                                </CustomRow>
                            </div>
                        </div>
                    </CustomCol>
                </CustomRow>
                <CustomRow>
                    <CustomCol className="col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Recouvrement"
                            className="btn-secondary"
                            confirmOnClick={() => onRecouvrement()}
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                            isActionEncours={
                                form.status === FORM_CONST.CREATION_EN_COURS &&
                                fields.actionEncours !== ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation recouvrement"
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
