import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    URL_CONST,
    TYPE_PROPRIETAIRE_CHARGE,
    TYPE_ENTITE_SECTEUR
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DynamicFormMounted,
    FormWrapper
} from '../../../components';
import { SecteurSchema as uiValidator } from './validation';
import { fieldsMapper } from './form-fields';
import { REDUX_NODE_NAME } from '../../../redux';

/**
 * @desc defaultFields
 */
const defaultFields = {
    code: '',
    designation: '',
    estCommercial: false,
    typeProprietaire: TYPE_PROPRIETAIRE_CHARGE,
    typeEntite: TYPE_ENTITE_SECTEUR
};

export function CreationSecteurForm() {
    const reduxDispatcher = useDispatch();

    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const {
            code,
            designation,
            estCommercial,
            typeEntite,
            typeProprietaire
        } = fields;
        const payload = {
            newSecteur: {
                code,
                designation,
                estCommercial,
                typeEntite,
                typeProprietaire
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_SECTEUR,
                reduxNodeName: REDUX_NODE_NAME.SECTEUR,
                payload,
                fields,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <DynamicFormMounted
                formDispatcher={formDispatcher}
                formState={formState}
                uiValidator={uiValidator}
                fieldsMapper={fieldsMapper}
            />
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomConfirmButton
                        type="button"
                        backdrop="static"
                        text="Valider"
                        className="btn-success"
                        confirmOnClick={handleSubmit}
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                        isActionEncours={
                            form.status === FORM_CONST.CREATION_EN_COURS
                        }
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
