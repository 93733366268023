import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    CONFIG_TECHNIQUE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { BooleanTypeConfig } from './boolean-type-configuration';
import { NumberTypeConfig } from './number-type-configuration';
import { ObjectTypeConfig } from './object-type-configuration';
import { TextTypeConfig } from './text-type-configuration';

const defaultFields = {
    templateField: '',
    selectedTypeValeur: {},
    key: '',
    stringValue: '',
    numberValue: 0,
    booleanValue: {},
    multiValue: [],
    objectValues: [],
    objectKey: '',
    objectValue: ''
};

export function CreationConfigEquipeTechniqueForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const onAddMultiValue = () => {
        if (fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.ARRAY.code) {
            const data = {
                code: fields.stringValue,
                value: fields.stringValue
            };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'code',
                    field: 'multiValue',
                    data
                }
            });
        }
    };
    const onAddObjectValue = () => {
        if (fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.OBJECT.code) {
            const data = { id: fields.objectKey, value: fields.objectValue };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    data,
                    identifier: 'id',
                    field: 'objectValues'
                }
            });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = {};
        if (fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.TEXT.code) {
            payloadToAdd[fields.key] = fields.stringValue;
        }
        if (fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.NUMBER.code) {
            payloadToAdd[fields.key] = fields.numberValue;
        }
        if (fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.BOOLEAN.code) {
            if (fields.booleanValue.code === 'true') {
                payloadToAdd[fields.key] = true;
            }
            if (fields.booleanValue.code === 'false') {
                payloadToAdd[fields.key] = false;
            }
        }
        if (fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.ARRAY.code) {
            const tab = fields.multiValue.map((item) => item.value);
            payloadToAdd[fields.key] = tab;
        }
        if (fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.OBJECT.code) {
            const theObject = fields.objectValues.map((item) => ({
                [item.id]: item.value
            }));
            let mainObject = {};
            theObject.forEach((element) => {
                mainObject = {
                    ...mainObject,
                    ...element
                };
            });
            payloadToAdd[fields.key] = mainObject;
        }
        const payload = {
            creator,
            elemUpdate: payloadToAdd
        };

        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_CONFIG,
                payload,
                fields,
                reduxNodeName: DB_NODE.CONFIG,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper formState={formState} formDispatcher={formDispatcher}>
            <CustomRow>
                <CustomDropdown
                    label="Type Valeur*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    defaultOption="Choisir type Valeur"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedTypeValeur"
                    id="creationRegularisationSuperDealerSelectedTypeValeurId"
                    options={CONFIG_TECHNIQUE.ALL}
                />
            </CustomRow>
            {fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.TEXT.code ? (
                <TextTypeConfig
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            ) : null}
            {fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.NUMBER.code ? (
                <NumberTypeConfig
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            ) : null}
            {fields.selectedTypeValeur.code ===
            CONFIG_TECHNIQUE.BOOLEAN.code ? (
                <BooleanTypeConfig
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            ) : null}
            {fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.ARRAY.code ? (
                <TextTypeConfig
                    formState={formState}
                    formDispatcher={formDispatcher}
                    callback={onAddMultiValue}
                />
            ) : null}
            {fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.OBJECT.code ? (
                <ObjectTypeConfig
                    formState={formState}
                    formDispatcher={formDispatcher}
                    callback={onAddObjectValue}
                />
            ) : null}

            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
