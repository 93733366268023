export const schema = [
    {
        title: 'Designation',
        description: 'designation',
        className: 'border-bottom'
    },
    {
        description: 'parent.designation',
        title: 'Parent',
        className: 'border-bottom'
    },
    {
        description: 'typeOperation',
        title: 'Opération',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation'
    }
];
