import { ONLY_FETCH, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { ViewLayout } from './view-layout';

export function ListPaiementCaisseSectorielleForm() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                fetchType={ONLY_FETCH}
                reduxPropertyName="paiementSectorielleBeneficiaires"
                functionName={URL_CONST.GET_LIST_CHARGE_BENEFICIAIRE_INITIALS}
                nodeName={
                    REDUX_NODE_NAME.PAIEMENT_CAISSE_SECTORIELLE_BENEFICIAIRE
                }
                identifier="id"
                mapper={[
                    {
                        field: 'secteur.designation',
                        displayName: 'Secteur',
                        position: 2
                    },
                    {
                        field: 'etat',
                        displayName: 'etat',
                        position: 3
                    },
                    {
                        field: 'montant',
                        displayName: 'Montant',
                        position: 4
                    },
                    {
                        field: 'numeroReference',
                        displayName: 'Numero de Reference',
                        position: 5
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 6
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
