import {
    submitPostAction,
    useEtatChargementDependencies,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    formInitialState,
    GroupeAbonneSchema
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CenteredSpinner,
    CustomAddRemoveButton,
    CustomAlert,
    CustomButtonLoader,
    CustomCollapse,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../components';
import { CreationGroupeAbonneSchema, URL_CONST } from '../../../util';

const defaultFields = {
    designation: '',
    selectedAbonne: {},
    listAbonne: []
};

/**
 * @description defaultDeps de notre state
 */
const defaultDependencies = { fields: ['abonnements'] };

export function CreationGroupeAbonneForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, abonnements, etatAbonnement } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            abonnements: reduxState.abonnements.map((item) => ({
                id: item.id,
                user: `${
                    item.valeurTypeEntite.code || item.valeurTypeEntite.nom
                } ${
                    item.valeurTypeEntite.designation ||
                    item.valeurTypeEntite.prenom
                }`
            })),
            etatAbonnement: reduxState.etat.abonnements.etat
        })
    );
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, dependencies } = formState;

    /**
     * @description récupérer les abonnements
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_ABONNEMENT,
                nodeName: DB_NODE.ABONNEMENT,
                etat: etatAbonnement
            }
            // {
            //     isOnlyFetch: true,
            //     functionName: URL_CONST.GET_LIST_GROUPE_ABONNE,
            //     nodeName: DB_NODE.GROUPE,
            //     etat: etatGroupeAbonne
            // },
        ]
    });

    /**
     * This hook loads dependecies
     */
    useEtatChargementDependencies({
        formDispatcher,
        dependencies,
        list: [
            {
                dependency: 'abonnements',
                etat: etatAbonnement
            }
        ]
    });

    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        dependencies
    });

    const addAbonnement = () => {
        if (!fields.selectedAbonne.id || !fields.selectedAbonne.user) return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listAbonne',
                data: fields.selectedAbonne,
                identifier: 'id'
            }
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!fields.designation || !fields.listAbonne.length || !creator)
            return;
        const list = fields.listAbonne.map((item) => item.id);
        const payload = {
            newGroupe: {
                code: fields.designation,
                designation: fields.designation
            },
            list,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_GROUPE,
                payload,
                reduxDispatcher,
                fields
                // uiValidator: CreationGroupeAbonneSchema,
                // reduxNodeName: DB_NODE.PRODUIT,
                // reduxValidator: GroupeAbonneSchema,
            })
        );
    };
    const renderContent = () => {
        if (!isLoadComplete && !isLoadFailed) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return <CustomAlert error={form?.error} />;
        }
        return (
            <>
                <CustomAlert error={form.error} />
                <CustomForm onSubmit={handleSubmit} className="py-2">
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Désignation*"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                            placeholder="désignation du groupe"
                            formDispatcher={formDispatcher}
                            value={fields.designation}
                            name="designation"
                            id="designationGroupeAbonne"
                            uiValidator={CreationGroupeAbonneSchema}
                            formState={formState}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            options={abonnements}
                            propertyToDisplay="user"
                            idProperty="id"
                            label="Abonné"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-8"
                            placeholder="Sélectionner un abonné"
                            formDispatcher={formDispatcher}
                            name="selectedAbonne"
                            id="selectedAbonneCreationGroupeAbonne"
                            uiValidator={GroupeAbonneSchema}
                            formState={formState}
                        />
                        <CustomAddRemoveButton
                            className="col-sm-2"
                            callback={addAbonnement}
                        />
                    </CustomRow>
                    <CustomCollapse isIn={!!fields.listAbonne.length}>
                        <CustomRow className="offset-sm-2 col-sm-8">
                            <SimpleTable
                                data={fields.listAbonne}
                                identifier="code"
                                listSelectedItemName="listAbonne"
                                isShowDropButton
                                formDispatcher={formDispatcher}
                                tableClass="table table-hover shadow table-bordered table-responsive-sm"
                                filter={false}
                                bottomPagination={false}
                                isLinkToDetail={false}
                                mapper={[
                                    {
                                        position: 1,
                                        displayName: 'Abonné',
                                        field: 'user'
                                    }
                                ]}
                            />
                        </CustomRow>
                    </CustomCollapse>
                    <div className="row">
                        <div className="col-3">
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={handleSubmit}
                                text="Sauvegarder"
                                disabled={
                                    form.status === FORM_CONST.CREATION_EN_COURS
                                }
                            />
                        </div>
                    </div>
                </CustomForm>
            </>
        );
    };
    return renderContent();
}
