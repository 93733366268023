import { TIME, TODAY, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { CashCollecteurSelector } from '../../../../components';

const formater = (donnees) => {
    const result = [];
    (donnees?.[0]?.listReturn || []).forEach((item) => {
        result.push({
            id: item?.valeurTypeProprietaire,
            institutionFinanciere:
                item?.institutionFinanciere?.designation || '',
            numero: item?.numeroCompteExterne,
            organisation: item?.partenaire?.designation || '',
            initial: item?.soldeOuverture,
            entree: item?.entree,
            sortie: item?.sortie,
            solde: item?.soldeFermeture || 0,
            devise: item?.devise,
            montant: {
                [item?.devise]: item?.soldeFermeture || 0
            },
            designation: `${item?.detail?.designation} ${item?.detail?.devise}`
        });
    });
    return result;
};

const buildTotalCards = (data = []) => {
    const result = [];
    data.forEach((item) => {
        const index = result.findIndex(
            (element) => element?.devise === item?.devise
        );
        if (index === -1) {
            result.push({
                id: item?.devise,
                devise: item?.devise,
                total: item?.solde
            });
        } else {
            result[index].total += item.solde;
        }
    });
    return result;
};

const dynamicColumnFormater = (dynamicColumn) =>
    Object.keys(dynamicColumn || {}).map((key) => ({
        displayName: key,
        field: `montant.${key}`,
        isTotal: true
    }));
export function RapportInstitutionFinanciereCashCollecteurForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCashCollecteur: {}
        }
    });
    return (
        <ReduxReportTemplate
            dynamicNodeName={`reportsInstitutionFinanciereCashCollecteur-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            buildTotalCards={buildTotalCards}
            mapper={[
                {
                    displayName: 'Organisation',
                    field: 'organisation'
                },
                {
                    displayName: 'Inst. financière',
                    field: 'institutionFinanciere'
                },
                {
                    displayName: 'Numéro',
                    field: 'numero'
                }
            ]}
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                typeEntite: 'Journalier',
                typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR,
                estOrganisation: true,
                estAll: true,
                estTotal: true,
                estDevise: true
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier',
                typeProprietaire: TYPE_ORGANISATION.CASH_COLLECTEUR,
                estOrganisation: true,
                estAll: true,
                estTotal: true,
                estDevise: true
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedCashCollecteur',
                    isRequired: false,
                    displayName: 'Cash collecteur',
                    callback: (value) => {
                        if (!value?.code || value?.code === 'all') return {};
                        return { codeValeurTypeProprietaire: value?.code };
                    }
                }
            ]}
            dynamicColumnFormater={dynamicColumnFormater}
            bottomPagination
            isAddTotalTr
        >
            <CashCollecteurSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                isAddAditionalOptions
            />
        </ReduxReportTemplate>
    );
}
