import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListChangeGuichetForm() {
    return (
        <ListGenericForm
            reduxPropertyName="operationChangeGuichet"
            functionName={URL_CONST.GET_LIST_CHANGE_GUICHET_INITIALS}
            nodeName={REDUX_NODE_NAME.OPERATION_CHANGE_GUICHET}
            mapper={[
                {
                    field: 'montant',
                    displayName: 'Montant'
                },
                {
                    field: 'devise',
                    displayName: 'Devise'
                },
                {
                    field: 'deviseSollicite',
                    displayName: 'Devise Sollicité'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
