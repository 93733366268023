import { useSelector } from 'react-redux';

export function SelectedRevendeurShower({ id }) {
    const { revendeur } = useSelector((reduxState) => ({
        revendeur: reduxState.revendeurs.find((item) => item.id === id)
    }));
    const renderContent = () => (
        <div>
            <dl>
                <dt>{revendeur?.designation}</dt>
                <dd>Till number : {revendeur?.tillNumber}</dd>
            </dl>
        </div>
    );
    if (!revendeur) return null;
    return renderContent();
}
