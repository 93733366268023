/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { useDispatch } from 'react-redux';
import { TIME, TODAY, resolvePathPropertyOfObject } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../util';
import { ReduxReportTemplate } from '../template';
import { REDUX_NODE_NAME } from '../../../redux';
import { CustomAlert, NumberDisplayer } from '../../../components';
import { headerColumnsLines, pathMapper } from './utils';

export function RapportDashboardCaisseEmoneyFormTranspose() {
    const customTdStyle = {
        textAlign: 'center',
        verticalAlign: 'middle'
    };
    const ordreDevise = ['CDF', 'USD', 'total'];

    const renderTableFooter = ({ footerKeys, paths, data }) =>
        footerKeys.map((item, index) => (
            <tr
                style={customTdStyle}
                key={item}
                className={`aio-table-footer-tr ${
                    item === 'total' ? 'fw-bold' : ''
                }`}
            >
                {index === 0 ? (
                    <td className="fw-bold" colSpan={2} rowSpan={paths.length}>
                        TOTAUX
                    </td>
                ) : null}
                <td id="aio-td-three">{item?.toUpperCase()}</td>
                {paths.map(({ path }) => {
                    const targetedElement = data[item] || [];
                    const value = targetedElement.reduce(
                        (acc, curr) =>
                            acc +
                            (resolvePathPropertyOfObject(path, curr) || 0),
                        0
                    );
                    return (
                        <td key={path}>
                            <NumberDisplayer
                                value={value}
                                nombreApresVirgule={4}
                            />
                        </td>
                    );
                })}
            </tr>
        ));

    const putIdOnElement = (index, headerIndex) => {
        if (index === 0 && headerIndex === 0) return 'aio-th-one';
        if (index === 1 && headerIndex === 0) return 'aio-th-two';
        if (index === 2 && headerIndex === 0) return 'aio-th-three';
        return '';
    };

    const renderTableBody = ({ row, paths, index }) =>
        row.map((rowItem, rowIndex) => {
            if (rowIndex === 0) {
                return (
                    <tr
                        key={`${rowItem?.superDealer?.designation}-${rowItem?.devise}`}
                        rowSpan={row.length}
                        style={customTdStyle}
                        className={`aio-table-tr ${
                            rowItem.devise === 'total' ? 'fw-bold' : ''
                        }`}
                    >
                        <td rowSpan={row.length}>{index + 1}</td>
                        <td rowSpan={row.length}>
                            {rowItem?.superDealer?.designation}
                        </td>
                        <td id="aio-td-three">{rowItem?.devise}</td>
                        {paths.map(({ path }) => {
                            const value =
                                resolvePathPropertyOfObject(path, rowItem) || 0;
                            return (
                                <td key={path}>
                                    <NumberDisplayer
                                        value={value}
                                        nombreApresVirgule={4}
                                    />
                                </td>
                            );
                        })}
                    </tr>
                );
            }
            return (
                <tr
                    key={`${rowItem?.superDealer?.designation}-${rowItem?.devise}`}
                    rowSpan={row.length}
                    style={customTdStyle}
                    className={`${rowItem.devise === 'total' ? 'fw-bold' : ''}`}
                >
                    <td id="aio-td-three">{rowItem?.devise?.toUpperCase()}</td>
                    {paths.map(({ path }) => {
                        const value =
                            resolvePathPropertyOfObject(path, rowItem) || 0;
                        return (
                            <td key={path}>
                                <NumberDisplayer
                                    value={value}
                                    nombreApresVirgule={4}
                                />
                            </td>
                        );
                    })}
                </tr>
            );
        });

    const renderSpecificly = ({ data }) => {
        const dataToRender = data || [];
        const groupBySuperDealer = data?.length
            ? Object.groupBy(data, (item) => item?.superDealer?.designation)
            : {};
        const groupeByDevise = data?.length
            ? Object.groupBy(data, (item) => item?.devise)
            : {};
        const keys = Object.keys(groupBySuperDealer);
        const headerKeys = Object.keys(headerColumnsLines);
        if (dataToRender?.length > 0 && !Array.isArray(dataToRender?.[0])) {
            return (
                <div className="aio-table-container">
                    <table className="table table-bordered border-top table-hover table-striped table-responsive-sm">
                        <thead className="fw-normal aio-table-header">
                            {headerKeys.map((headerItem, headerIndex) => (
                                <tr style={customTdStyle} key={headerItem}>
                                    {headerColumnsLines[headerItem].map(
                                        (innerHeaderItem, innerHeaderIndex) => (
                                            <th
                                                id={putIdOnElement(
                                                    innerHeaderIndex,
                                                    headerIndex
                                                )}
                                                key={`${innerHeaderItem.name}-${headerItem}-${headerIndex}-${innerHeaderIndex}`}
                                                rowSpan={
                                                    innerHeaderItem.rowSpan
                                                }
                                                colSpan={
                                                    innerHeaderItem.colSpan
                                                }
                                            >
                                                {innerHeaderItem.name}
                                            </th>
                                        )
                                    )}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="text-center">
                            {keys.map((item, index) => {
                                const getEmement = groupBySuperDealer[
                                    item
                                ]?.sort((a, b) => {
                                    const indexA = ordreDevise.indexOf(
                                        a.devise
                                    );
                                    const indexB = ordreDevise.indexOf(
                                        b.devise
                                    );
                                    if (indexA === -1 && indexB === -1) {
                                        return 0;
                                    }
                                    if (indexA === -1) {
                                        return 1;
                                    }
                                    if (indexB === -1) {
                                        return -1;
                                    }
                                    return indexA - indexB;
                                });
                                if (getEmement.length) {
                                    return renderTableBody({
                                        row: getEmement,
                                        paths: pathMapper,
                                        index
                                    });
                                }
                            })}
                        </tbody>
                        <tfoot>
                            {renderTableFooter({
                                footerKeys: ordreDevise,
                                paths: pathMapper,
                                data: groupeByDevise
                            })}
                        </tfoot>
                    </table>
                </div>
            );
        }
        if (Array.isArray(data)) {
            return (
                <CustomAlert successMessage="Aucune information disponible !" />
            );
        }
        return null;
    };
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {}
    });
    const onSuccess = (data) => {
        const items = data?.element || data?.elements || [];
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.resetTable(
                REDUX_NODE_NAME.RAPPORT_DASHBOARD_CAISSE_EMONEY
            )
        );
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                items,
                REDUX_NODE_NAME.RAPPORT_DASHBOARD_CAISSE_EMONEY
            )
        );
    };
    return (
        <ReduxReportTemplate
            isInitialFetch={false}
            listTitle=""
            isLinkToDetail
            onSuccess={onSuccess}
            renderSpecificly={renderSpecificly}
            reduxPropertyName="rapportDashBoardCaisseEmoney"
            functionName={URL_CONST.GET_LIST_RAPPORT_CAISSE_EMONEY}
            nodeName={REDUX_NODE_NAME.RAPPORT_DASHBOARD_CAISSE_EMONEY}
            formState={formState}
            formDispatcher={formDispatcher}
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959())
            }}
            listFormater={(data) => {
                if (data?.length && data?.[0]?.length) return data?.[0];
                if (data?.length && !data?.[0]?.length) return [];
                return data;
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                }
            ]}
        />
    );
}
