import { useState } from 'react';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// import { ResetPasswordView } from './reset-password-view';
import { ResetPinView } from './reset-pin-view';
import { UserServiceWrapper } from '../../../../components';

export function ServiceClientOperationsViewLayout() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    /**
     * Common form processes
     */
    const { typeEntite } = useGetUserDetail({
        nappUser
    });
    const [key, setKey] = useState('resetPin');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Opérations Service Client</h1>
            </div>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .SERVICE_CLIENT.REINITIALISER_PIN_CLIENT
                ]}
            >
                <Tabs
                    defaultActiveKey="resetPin"
                    id="fill-tab-example"
                    className="mb-3"
                    // fill
                    onSelect={setKey}
                >
                    <Tab eventKey="resetPin" title="Réinitialiser Pin">
                        {key === 'resetPin' && (
                            <ResetPinView typeOrganisation={typeEntite} />
                        )}
                    </Tab>
                    {/* <Tab eventKey="password" title="Réinitialiser Mot de passe">
                    {key === 'password' && (
                        <ResetPasswordView typeOrganisation={typeEntite} />
                    )}
                </Tab> */}
                </Tabs>
            </UserServiceWrapper>
        </div>
    );
}
