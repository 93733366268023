export const schema = [
    {
        description: 'type',
        title: 'Opération',
        className: 'border-bottom'
    },
    {
        description: 'montant',
        title: 'Montant',
        className: 'border-bottom'
    },
    {
        description: 'tauxCommission',
        title: 'Taux Commission',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'Etat',
        className: 'border-bottom'
    },
    {
        description: 'type',
        title: 'Opération',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'Création',
        className: 'border-bottom'
    }
];
