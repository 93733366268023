import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    COMPTE_DISTRIBUTEUR,
    formInitialState,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomConfirmButton,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { CompteExterneDistributeurSchema as uiValidator } from './validation';
import { REDUX_NODE_NAME } from '../../../../redux';

const defaultFields = {
    intituleCompte: '',
    numeroCompte: '',
    numeroCompteExterne: '',
    nom: '',
    postnom: '',
    prenom: '',
    email: ''
};

export function CreationCompteDistributeurForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const { nom, postnom, prenom, email, intituleCompte, numeroCompte } =
            fields;
        const payload = {
            newCompte: {
                libelle: intituleCompte,
                numero: numeroCompte,
                typeEntite: COMPTE_DISTRIBUTEUR.code
            },
            gestionnaire: {
                nom,
                prenom,
                postnom,
                email
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_COMPTE_DECLARATION,
                reduxNodeName: REDUX_NODE_NAME.COMPTE_DISTRIBUTEUR,
                payload,
                fields,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Intitulé*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez l'intitulé du compte"
                    formDispatcher={formDispatcher}
                    name="intituleCompte"
                    id="intituleCompteId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Numéro du compte*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Numéro du compte"
                    formDispatcher={formDispatcher}
                    name="numeroCompte"
                    id="numeroCompteId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Nom*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Nom du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="nom"
                    id="nomGestionnaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Postnom*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Postnom du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="postnom"
                    id="postnomGestionnaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Prénom*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Prénom du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="prenom"
                    id="prenomGestionnaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Email*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Email du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="email"
                    type="email"
                    id="emailGestionnaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomConfirmButton
                    type="button"
                    backdrop="static"
                    text="Valider"
                    className="btn-primary btn mt-3 ms-2 w-25"
                    confirmOnClick={handleSubmit}
                    disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    isActionEncours={
                        form.status === FORM_CONST.CREATION_EN_COURS
                    }
                />
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
