/* eslint-disable jsx-a11y/label-has-associated-control */
import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    formInitialState,
    ProduitSchema
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCheckButton,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomRow,
    CustomInputFile,
    CustomCollapse
} from '../../../components';
import { CustomTextAreaInput } from '../../../components/input';
import { CreationArticleSchema, URL_CONST } from '../../../util';

const defaultFields = {
    code: '',
    designation: '',
    rayon: '',
    type: {},
    emplacementPhysique: {},
    prixUnitaire: 1,
    images: [],
    documentPresentation: '',
    description: '',
    urlVideo: '',
    temperature: '',
    etat: '',
    longueur: '',
    largeur: '',
    hauteur: '',
    poids: '',
    estInflammable: false,
    estFragile: false,
    estEmpilable: false,
    longueurSupplementaire: '',
    largeurSupplementaire: '',
    hauteurSupplementaire: '',
    delaiConsignation: '',
    estLivrerDomicile: false,
    positions: []
};

export function CreationArticleForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        files: ['images', 'documentPresentation']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            name: fields.designation,
            code: fields.code
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_FAKE,
                payload,
                uiValidator: CreationArticleSchema,
                fields,
                reduxNodeName: DB_NODE.PRODUIT,
                reduxDispatcher,
                reduxValidator: ProduitSchema
            })
        );
    };
    return (
        <>
            <CustomAlert error="Devra être adaptée à chaque type de marchand" />
            <CustomForm onSubmit={handleSubmit} className="py-2">
                <CustomRow>
                    <CustomInput
                        rows={2}
                        isInputGroup={false}
                        label="Code*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Code du produit"
                        formDispatcher={formDispatcher}
                        name="code"
                        id="codeProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Désignation*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="désignation du produit"
                        formDispatcher={formDispatcher}
                        value={fields.designation}
                        name="designation"
                        id="designationProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { value: 'Rayon 1', code: 'rayon1' },
                            { value: 'Rayon 2', code: 'rayon2' },
                            { value: 'Rayon 3', code: 'rayon3' }
                        ]}
                        propertyToDisplay="value"
                        label="Rayon*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Sélectionner un rayon"
                        formDispatcher={formDispatcher}
                        name="rayon"
                        id="rayonProduit"
                        uiValidator={ProduitSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { value: 'Colonne 1', code: 'colonne1' },
                            { value: 'Colonne 2', code: 'colonne2' },
                            { value: 'Colonne 3', code: 'colonne3' }
                        ]}
                        propertyToDisplay="value"
                        label="Colonne*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Sélectionner une colonne"
                        formDispatcher={formDispatcher}
                        name="type"
                        id="typeProduit"
                        uiValidator={ProduitSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { value: 'Emplacement 1', code: 'EMPLACEMENT1' },
                            { value: 'Emplacement 2', code: 'EMPLACEMENT2' },
                            { value: 'Emplacement 3', code: 'EMPLACEMENT3' }
                        ]}
                        propertyToDisplay="value"
                        label="Emplacement physique*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Sélectionner un emplacement"
                        formDispatcher={formDispatcher}
                        name="emplacementPhysique"
                        id="emplacementPhysiqueProduit"
                        uiValidator={ProduitSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Prix*"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Prix unitaire du produit"
                        formDispatcher={formDispatcher}
                        name="prixUnitaire"
                        type="number"
                        id="prixUnitaireProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Temperature"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Temperature conservation du produit (°C)"
                        formDispatcher={formDispatcher}
                        name="temperature"
                        type="number"
                        id="temperatureProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { value: 'SOLIDE', code: 'SOLIDE' },
                            { value: 'LIQUIDE', code: 'LIQUIDE' },
                            { value: 'GAZEUX', code: 'GAZEUX' }
                        ]}
                        propertyToDisplay="value"
                        label="Etat"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Sélectionner un etat"
                        formDispatcher={formDispatcher}
                        name="etat"
                        id="etatProduit"
                        uiValidator={ProduitSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Longueur"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Longueur du produit (cm)"
                        formDispatcher={formDispatcher}
                        name="longueur"
                        type="number"
                        id="longueurProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Largeur"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Largeur du produit (cm)"
                        formDispatcher={formDispatcher}
                        name="largeur"
                        type="number"
                        id="largeurProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Hauteur"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Hauteur du produit (cm)"
                        formDispatcher={formDispatcher}
                        name="hauteur"
                        type="number"
                        id="hauteurProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Hauteur"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Hauteur du produit (cm)"
                        formDispatcher={formDispatcher}
                        name="hauteur"
                        type="number"
                        id="hauteurProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Poids"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Poids du produit (kg)"
                        formDispatcher={formDispatcher}
                        name="poids"
                        type="number"
                        id="poidsProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Délai consignation"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Délai consignation du produit (jour)"
                        formDispatcher={formDispatcher}
                        name="delaiConsignation"
                        type="number"
                        id="delaiConsignationProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomTextAreaInput
                        isInputGroup={false}
                        label="Description"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Description du produit"
                        formDispatcher={formDispatcher}
                        name="description"
                        id="descriptionProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                        rows={5}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Url vidéo"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        placeholder="Url video présentation produit"
                        formDispatcher={formDispatcher}
                        name="urlVideo"
                        id="urlVideoProduit"
                        uiValidator={CreationArticleSchema}
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInputFile
                        id="documentPresentationProduit"
                        name="documentPresentation"
                        label="Document de presentation"
                        labelClassName="col-sm-2"
                        divClassName="col-sm-10"
                        formState={formState}
                        formDispatcher={formDispatcher}
                        uiValidator={CreationArticleSchema}
                        accept=".doc, .docx, .pdf"
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInputFile
                        id="imagesProduit"
                        name="images"
                        label="Images du produit"
                        divClassName="col-sm-10"
                        labelClassName="col-sm-2"
                        formState={formState}
                        formDispatcher={formDispatcher}
                        uiValidator={CreationArticleSchema}
                        accept=".jpg, .jpeg, .png"
                        multiple
                    />
                </CustomRow>
                <CustomRow>
                    <CustomCheckButton
                        labelClassName="col-sm-2"
                        inputClassName="col-sm-10"
                        label="Est inflammable"
                        name="estInflammable"
                        id="estInflammableProduit"
                        formState={formState}
                        formDispatcher={formDispatcher}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomCheckButton
                        labelClassName="col-sm-2"
                        inputClassName="col-sm-10"
                        label="Est fragile"
                        name="estFragile"
                        id="estFragileProduit"
                        formState={formState}
                        formDispatcher={formDispatcher}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomCheckButton
                        labelClassName="col-sm-2"
                        inputClassName="col-sm-10"
                        label="Livrable à domicile"
                        name="estLivrerDomicile"
                        id="estLivrerDomicileProduit"
                        formState={formState}
                        formDispatcher={formDispatcher}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomCheckButton
                        labelClassName="col-sm-2"
                        inputClassName="col-sm-10"
                        label="Est empilable"
                        name="estEmpilable"
                        id="estEmpilableProduit"
                        formState={formState}
                        formDispatcher={formDispatcher}
                    />
                </CustomRow>
                <CustomCollapse isIn={fields.estEmpilable}>
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Longueur supplémentaire"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                            placeholder="Longueur supplémentaire du produit (cm)"
                            formDispatcher={formDispatcher}
                            name="longueurSupplementaire"
                            type="number"
                            id="longueurSupplementaireProduit"
                            uiValidator={CreationArticleSchema}
                            formState={formState}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Largeur supplémentaire"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                            placeholder="Largeur supplémentaire du produit (cm)"
                            formDispatcher={formDispatcher}
                            name="largeurSupplementaire"
                            type="number"
                            id="largeurSupplementaireProduit"
                            uiValidator={CreationArticleSchema}
                            formState={formState}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Hauteur supplémentaire"
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                            placeholder="Hauteur supplémentaire du produit (cm)"
                            formDispatcher={formDispatcher}
                            name="hauteurSupplementaire"
                            type="number"
                            id="hauteurSupplementaireProduit"
                            uiValidator={CreationArticleSchema}
                            formState={formState}
                        />
                    </CustomRow>
                </CustomCollapse>
                <div className="row">
                    <div className="col-3">
                        <CustomButtonLoader
                            className="btn-success"
                            onClick={handleSubmit}
                            text="Sauvegarder"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                        />
                    </div>
                </div>
            </CustomForm>
        </>
    );
}
