import {
    fromTimestampToString,
    DATE_FORMAT,
    resolvePathPropertyOfObject
} from '@napp-inc/jnapp-util';
/**
 * Construit la liste des catégories demandées par highcart, représente les labels affichés sur l'axe des X
 * @param {Object} param0 Objet avec les parametres suivants
 * @param {Array} param0.dataList Liste contenant les data à afficher le graphique
 * @param {Boolean} param0.isNotDate Définiit si la propriété est une data ou pas pour utiliser un formatage de date sinon un
 * formatage approprié, par défaut false
 * @param {String} param0.dateFormat Format de la date à utiliser
 * @param {String} param0.field chemin de la propriété dans la dataList, par défaut : dateCreation
 * @param {Function} param0.callback function particulière pour récupérer définir le texte de l'axe des X
 * @returns {Array}
 */
export const buildHighchartCategories = ({
    dataList,
    isNotDate = false,
    dateFormat = DATE_FORMAT.DATE_FORMAT_DD_MMM_YYYY,
    field = 'dateCreation',
    callback
}) => {
    const categories = [];
    if (dataList?.length) {
        if (!isNotDate) {
            dataList.forEach((dataListItem) => {
                categories.push(
                    fromTimestampToString(
                        resolvePathPropertyOfObject(field, dataListItem),
                        dateFormat
                    )
                );
            });
        } else if (typeof callback === 'function') {
            dataList.forEach((dataListItem) => {
                categories.push(callback(dataListItem));
            });
        }
    }
    return categories;
};
