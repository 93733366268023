import React from 'react';
import { ShowNewComponent } from '../../../components';
import { SalleMangerCreateRelPath } from '../../../routes/registration/configuration/config-napp-rel-path';

export function ViewLayout({ children }) {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Table à Manger</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <ShowNewComponent
                            services={{ code: 'test' }}
                            destination={SalleMangerCreateRelPath.path}
                        />
                    </div>
                </div>
            </div>
            {children}
        </>
    );
}
