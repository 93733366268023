import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
// import { useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomInputDate,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { ProgrammerPauseSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    debutPause: '',
    finPause: '',
    selectedUser: {},
    users: [],
    duree: 0
};

export function CreationProgrammerPauseForm() {
    // const reduxDispatcher = useDispatch();
    // const { firebaseUser } = useSelector((reduxState) => ({
    //     firebaseUser: reduxState.firebaseUser
    // }));
    // const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * @description récupérer les produits marchand
     */
    // useFetchAndListening({
    //     idToken,
    //     reduxDispatcher,
    //     list: [
    //         {
    //             isOnlyFetch: true,
    //             functionName: URL_CONST.GET_LIST_PRODUIT_MARCHAND,
    //             nodeName: DB_NODE.PRODUIT,
    //             etat: etatProduit
    //         }
    //     ]
    // });

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        // const payload = {};
        // formDispatcher(
        //     submitPostAction({
        //         idToken,
        //         functionName: URL_CONST.POST_PARAMETRE_COMMANDE_EN_LIGNE,
        //         reduxNodeName: DB_NODE.PARAMETRE_COMMANDE_EN_LIGNE,
        //         payload,
        //         fields,
        //         reduxDispatcher
        //         uiValidator
        //     })
        // );
    };

    /**
     * L'action qui sera déclenchée pour l'ajout des users qui bénéficient de la fonctionnalité
     * @returns
     */
    const addUserAndTiming = () => {
        if (!fields.selectedUser.reference) return;
        const data = {
            id: fields.selectedUser.reference,
            typeEntite: 'User',
            reference: fields.selectedUser.reference,
            duree: fields.duree //
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'users',
                data,
                identifier: 'reference'
            }
        });
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomLabel text="Intervalle*" className="col-sm-2" />
                <CustomInputDate
                    id="programmerPauseFormDateDebutId"
                    name="debutPause"
                    type="date"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Date début"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInputDate
                    id="programmerPauseFormDateFinId"
                    name="finPause"
                    type="date"
                    isInputGroup={false}
                    divClassName="col-sm-5"
                    placeholder="Date début"
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Agent et durée*" className="col-sm-2" />
                <CustomDropdown
                    options={[
                        {
                            reference: 'duensd',
                            designation: 'carlo'
                        },
                        {
                            reference: 'ind',
                            designation: 'marx'
                        }
                    ]}
                    idProperty="reference"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner l'agent"
                    divClassName="col-12 col-sm-5"
                    name="selectedUser"
                    id="selectedUserId"
                    resetOnChangeState
                    newState={{
                        ...initialState.fields,
                        debutPause: fields.debutPause,
                        finPause: fields.finPause,
                        users: fields.users
                    }}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    id="programmerPauseFormDureeId"
                    name="duree"
                    type="number"
                    isInputGroup={false}
                    divClassName="col-sm-4"
                    placeholder="Durée"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={addUserAndTiming}
                />
                <CustomCollapse isIn={!!fields.users.length}>
                    <SimpleTable
                        formDispatcher={formDispatcher}
                        identifier="id"
                        listSelectedItemName="users"
                        data={fields.users}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 1,
                                field: 'reference',
                                displayName: 'reference'
                            },
                            {
                                position: 2,
                                field: 'duree',
                                displayName: 'Duree'
                            }
                        ]}
                    />
                </CustomCollapse>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
