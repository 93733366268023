import { CONFIG_TECHNIQUE } from '@napp-inc/jnapp-util';
import {
    CustomAddRemoveButton,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../components';

export function TextTypeConfig({
    formState,
    formDispatcher,
    uiValidator,
    callback
}) {
    const { fields } = formState;
    const renderContent = () => (
        <CustomRow>
            <CustomInput
                type="text"
                label="Clé*"
                labelClassName="col-12 col-sm-2"
                divClassName="col-12 col-sm-4"
                isInputGroup={false}
                placeholder="Valeur de la clé"
                formDispatcher={formDispatcher}
                name="key"
                id="keyId"
                formState={formState}
                uiValidator={uiValidator}
            />
            <CustomInput
                type="text"
                divClassName="col-12 col-sm-4"
                isInputGroup={false}
                placeholder="Valeur de la clé"
                formDispatcher={formDispatcher}
                name="stringValue"
                id="stringValueId"
                formState={formState}
                uiValidator={uiValidator}
            />
            {typeof callback === 'function' &&
            (fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.ARRAY.code ||
                fields.selectedTypeValeur.code ===
                    CONFIG_TECHNIQUE.OBJECT.code) ? (
                <CustomAddRemoveButton
                    className="btn-success col-sm-2"
                    callback={callback}
                />
            ) : null}
            {typeof callback === 'function' &&
            fields.multiValue.length &&
            fields.selectedTypeValeur.code === CONFIG_TECHNIQUE.ARRAY.code ? (
                <CustomRow>
                    <SimpleTable
                        listSelectedItemName="multiValue"
                        identifier="code"
                        formDispatcher={formDispatcher}
                        data={fields.multiValue}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 1,
                                field: 'code',
                                displayName: 'Valeur'
                            }
                        ]}
                    />
                </CustomRow>
            ) : null}
        </CustomRow>
    );
    return renderContent();
}
