import { useEffect, useState } from 'react';

export function useComparePercuRetour({
    totalPercu,
    totalRetour,
    listPercu,
    listRetour
}) {
    const [message, setMessage] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [newListPercu, setNewListPercu] = useState([]);
    const [newListRetour, setNewListRetour] = useState([]);
    useEffect(() => {
        let messageTemp = '';
        let isValidTemp = true;
        if (totalRetour > totalPercu) {
            isValidTemp = false;
            messageTemp =
                'Vous ne pouvez pas faire un retour plus grand que le perçu';
        }
        if (isValidTemp) {
            listPercu
                .filter((per) => per.montant)
                .forEach((itemPercu) => {
                    const retourOfPercu = listRetour.find(
                        (itemRetour) => itemRetour.devise === itemPercu.devise
                    );
                    if (
                        retourOfPercu &&
                        retourOfPercu.montant > itemPercu.montant
                    ) {
                        messageTemp += `Vous ne pouvez pas retourner plus de ${itemPercu.montant} ${itemPercu.devise}\n`;
                        isValidTemp = false;
                    }
                });
            if (!isValidTemp) {
                setIsValid(false);
                setMessage(messageTemp);
            }
        }
        // FIXME: should add another step to consider also coupure devise
        if (isValidTemp) {
            const newPercu = [];
            const newRetour = listRetour.filter((per) => per.montant);
            listPercu
                .filter((per) => per.montant)
                .forEach((itemPercu) => {
                    let currentIndex = -1;
                    const retourOfPercu = newRetour.find(
                        (itemRetour, index) => {
                            currentIndex = index;
                            return itemRetour.devise === itemPercu.devise;
                        }
                    );
                    if (!retourOfPercu) newPercu.push(itemPercu);
                    else if (currentIndex >= 0) {
                        newPercu.push({
                            ...itemPercu,
                            montant: itemPercu.montant - retourOfPercu.montant
                        });
                        newRetour.splice(currentIndex, 1);
                    }
                });
            setNewListPercu(newPercu);
            setNewListRetour(newRetour);
        }
        setIsValid(isValidTemp);
        setMessage(messageTemp);
    }, [totalPercu, totalRetour, listPercu, listRetour]);
    return { message, isValid, newListPercu, newListRetour };
}
