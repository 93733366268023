import { DB_NODE } from '@napp-inc/jnapp-util';
import { DetailWrapper } from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailImputationCaisseForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'imputations' });
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_IMPUTATION_BY_ID}
            nodeName={DB_NODE.IMPUTATION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Imputations"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        />
    );
    return renderContent();
}
