import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { DetailProduitTransfertStockMateriel } from './detail-produit';
import { schema } from './detail-fields';
import { useGetDetailForm } from '../../../util';

const defaultFields = {
    actionEncours: '',
    isVoirPlus: false,
    selectedProduitDetail: {},
    selectedColonneDetail: {},
    listColonneProduit: [],
    newCode: '',
    newDesignation: '',
    newPrice: 0
};

export function DetailTransfertStockForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({
        nodeName: 'transfertsStockMateriel',
        defaultFields,
        defaultElements: ['details']
    });

    const { form, fields } = formState;
    const isBeneficiaireAgence = entity?.typeBeneficiaire === 'Agence';
    const updateEtat = (etat) => {
        const propertyToAdd = {};
        const payload = {
            etat,
            creator,
            idTransfertStockProduitMateriel: entity?.id
        };
        if (isBeneficiaireAgence) {
            propertyToAdd.types = fields.listColonneProduit.map((item) => ({
                codeProduit: item.produit.code,
                code: item.colonne.code
            }));
        } else {
            propertyToAdd.produits = fields.listColonneProduit.map((item) => ({
                codeProduitFournisseur: item.produit.code,
                produit: {
                    code: item.newCode,
                    designation: item.newDesignation
                },
                prixVente: item.newPrice
            }));
        }
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_TRANSFERT_STOCK_MATERIEL,
                payload: { ...payload, ...propertyToAdd },
                fields,
                reduxNodeName: DB_NODE.TRANSFERT_STOCK_MATERIEL,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TRANSFERT_STOCK_MATERIEL_BY_ID}
            nodeName={DB_NODE.TRANSFERT_STOCK_MATERIEL}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Transfert Stock Produit Matériel"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <DetailProduitTransfertStockMateriel
                isBeneficiaireAgence={isBeneficiaireAgence}
                idToken={idToken}
                entity={entity}
                formDispatcher={formDispatcher}
                reduxDispatcher={reduxDispatcher}
                formState={formState}
            />
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.STOCK.TRANSFERT_STOCK
                        .METTRE_A_JOUR_TRANSFERT_STOCK
                ]}
            >
                {fields.isVoirPlus && (
                    <CustomRow>
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                )}
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
