import React, { useContext } from 'react';
import { Menu } from '../menu/menu';
import { StateContext } from '../../context/state-context';

/**
 * Menu principal
 * @returns {React.Component}
 */
export function NappSideBar() {
    const { menus } = useContext(StateContext);
    return (
        <div className="napp-nav-wrapper">
            {menus
                .filter(({ isVisible, index }) => isVisible && !index)
                .map((element) => (
                    <Menu
                        item={element}
                        key={element.name}
                        menuClass="napp-submenu-sidebar-link"
                        menuClassActive="napp-submenu-sidebar-link-active"
                        menuClassText="napp-sidebar-label"
                        submenuClass="napp-link-dropdown"
                        submenuClassActive="napp-link-dropdown-active"
                    />
                ))}
        </div>
    );
}
