import {
    submitPostAction,
    // useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

const defaultFields = {
    quantite: 0,
    raison: '',
    selectedTypeOperation: {},
    selectedTypeRegularisation: {}
};

/**
 * @description dependencies du state du formulaire
 */
// const defaultDependencies = {
//     fields: ['imputations']
// };

export function CreationRegularisationSpecialeForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, organisation } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        organisation: reduxState.organisation
    }));
    /**
     * Common form processes
     */
    const { idToken, creator, typeBusinessNotSim } = useGetUserDetail({
        firebaseUser,
        organisation
    });
    const initialState = formInitialState({
        fields: defaultFields
        // dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    // useFetchAndListening({
    //     idToken,
    //     reduxDispatcher,
    //     list: [
    //         {
    //             isOnlyFetch: true,
    //             functionName: URL_CONST.GET_LIST_IMPUTATION_STOCK_ORGANISATION,
    //             nodeName: REDUX_NODE_NAME.IMPUTATION_STOCK_ORGANISATION,
    //             etat: etatImputationStockOrganisation
    //         }
    //     ]
    // });
    const handleSubmit = (e) => {
        e.preventDefault();
        const { quantite, libelle, selectedImputation, selectedNumero } =
            fields;
        if (!quantite || !libelle || !selectedImputation || !selectedNumero)
            return;
        const numero = typeBusinessNotSim.find(
            (item) => item.code === selectedNumero.code
        );
        if (!numero.numero) return;
        const payload = {
            creator
            // quantite,
            // codeImputation: selectedImputation.code,
            // libelle,
            // numero: numero.numero
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_REGULARISATION_STOCK_SUPER_DEALER,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.REGULARISATION_SPECIALE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            // listDependencies={[
            //     {
            //         dependency: 'imputations',
            //         etat: etatImputationStockOrganisation
            //     }
            // ]}
        >
            <CustomRow>
                <CustomDropdown
                    label="Type*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    defaultOption="Choisir..."
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedTypeRegularisation"
                    id="creationRegularisationSpecialeSelectedTypeRegularisationId"
                    options={[{ code: 'Stock', designation: 'Stock' }]}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    label="Type Opération*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    defaultOption="Choisir..."
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedTypeOperation"
                    id="creationRegularisationSpecialeSelectedTypeOperationId"
                    options={[
                        { code: 'entree', designation: 'ENTREE' },
                        { code: 'sortie', designation: 'SORTIE' }
                    ]}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Raison*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Saisir Raison"
                    formDispatcher={formDispatcher}
                    name="raison"
                    id="creationRegularisationSpecialeRaisonId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Quantité*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Saisir Quantité"
                    formDispatcher={formDispatcher}
                    name="quantite"
                    id="creationRegularisationSpecialeQuantiteId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
