import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    TYPE_ENTITE_DIRECTION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    DynamicFormMounted,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { DirectionSchema as uiValidator } from './validation';
import { mapper as fieldsMapper } from './form-fields';

/**
 * @desc defaultFields for direction
 */
const defaultFields = {
    code: '',
    designation: '',
    typeEntite: TYPE_ENTITE_DIRECTION,
    estNapp: false
};

export function CreationDirectionForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const { fields, form } = formState;

    const handleSubmit = (e) => {
        e.preventDefault();
        const { code, designation, estNapp, typeEntite } = fields;
        const payload = {
            newSecteur: {
                code,
                designation,
                estNapp,
                typeEntite
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_SECTEUR,
                reduxNodeName: DB_NODE.DIRECTION_SECTEUR,
                payload,
                fields,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <DynamicFormMounted
                formDispatcher={formDispatcher}
                formState={formState}
                uiValidator={uiValidator}
                fieldsMapper={fieldsMapper}
            />
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
