import { CustomAlert } from '../alert';
import { BackComponent } from '../button';
import { CustomForm } from '../form';
import { EtatChargementWrapper } from './etat-chargement-wrapper';
import { RedirectionComponent } from './redirection';

/**
 * Composant pour enrober le formulaire de création/détail
 * IL inclut les composants de redirection, de retour à la page précédente,
 * d'affichage de message d'erreur, du formulaire,
 * du composant loader qui traque l'évolution du chargement des dependances
 * @param {Object} param0 Objet avec les propriétés children, formState, fomrDispatcher, listDependencies, entity
 * @param {Object} param0.formState state du formulaire
 * @param {Function} param0.formDispatcher formDispatcher
 * @param {Array} param0.listDependencies tableau contenant des objets { dependency, etatChargement },
 * avec dependecy comme nom de la propriété (depedance) que l'on doit chargé et etatChargement representant son etat de chargement dans le store redux
 *@param {Object} param0.entity objet entity du formulaire détail
 * @param {Objet} param0.children propriété children de React
 * @param {Function} param0.onSubmit function qui se déclenche à la soumission du formulaire
 * @returns {React.Component}
 */
export function FormWrapper({
    children,
    formState,
    formDispatcher,
    listDependencies,
    entity,
    onSubmit,
    isDetail = false,
    schema = [],
    subtitle,
    isDownloadFile,
    isExportable,
    downloadFileFunction,
    exportDetailFunction,
    optionalDependencies,
    isRedirect = true,
    isStepBack = true,
    // isBackComponent = true,
    isShowDetail,
    detailCallback,
    actionButtons
}) {
    return (
        <>
            {isRedirect ? (
                <RedirectionComponent form={formState?.form} />
            ) : null}
            {isStepBack && !actionButtons ? <BackComponent /> : null}
            {isStepBack && actionButtons ? (
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <BackComponent className="btn btn-primary" />
                    </div>
                    <div>{actionButtons}</div>
                </div>
            ) : null}
            {isStepBack ? <hr /> : null}
            {/* {isRedirect && isBackComponent ? <BackComponent /> : null}
            {isRedirect && isBackComponent ? <hr /> : null} */}
            <CustomAlert error={formState?.form?.error} />
            <EtatChargementWrapper
                formDispatcher={formDispatcher}
                dependencies={formState?.dependencies}
                listDependencies={listDependencies}
                entity={entity}
                isDetail={isDetail}
                schema={schema}
                subtitle={subtitle}
                isDownloadFile={isDownloadFile}
                isExportable={isExportable}
                downloadFileFunction={downloadFileFunction}
                exportDetailFunction={exportDetailFunction}
                optionalDependencies={optionalDependencies}
                isShowDetail={isShowDetail}
                detailCallback={detailCallback}
            >
                <CustomForm onSubmit={onSubmit}>{children}</CustomForm>
            </EtatChargementWrapper>
        </>
    );
}
