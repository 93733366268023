import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, formInitialState } from '@napp-inc/jnapp-util';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    CustomRow,
    CustomSwitchButton,
    DetailWrapper,
    UserServiceWrapper,
    OctroiRevocationServiceComponent
} from '../../../components';
import { schema } from './detail-fields';
import { ListTypeOrganisation as data } from '../../../util';

/**
 * @description state du formulaire
 */
const defaultFields = {
    actionEncours: '',
    toAdd: [],
    toRemove: [],
    toAddInputSearch: '',
    toRemoveInputSearch: '',

    selectedTypeEntite: {},
    selectedValeurTypeEntite: {},
    selectedTypeOrganisation: {},
    selectedNiveauOrganisation: {},
    selectedProfilUser: {}
};

export function DetailDroitAccesOrganisationForm() {
    const [isUpdateRightAccess, setUpdateRightAccess] = useState(false);
    const { id } = useParams();
    const entity = data.find(({ code }) => code === id);

    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const renderContent = () => (
        <DetailWrapper
            subtitle="Droit d'accès"
            entity={entity}
            id={id}
            schema={schema}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_WEB
                        .CODE_SERVICE_ADMINISTRATION_NAPP
                        .AJOUTER_SYST_SERVICE_TYPE_ORGANISATION
                ]}
            >
                <CustomRow>
                    <CustomSwitchButton
                        id="isUpdateRightAccess"
                        text="Modifier droit d'accès"
                        defaultChecked={isUpdateRightAccess}
                        name="isUpdateRightAccess"
                        divClassName="offset-1 col-md-8"
                        callback={setUpdateRightAccess}
                    />
                </CustomRow>
                {isUpdateRightAccess && (
                    <OctroiRevocationServiceComponent
                        entity={entity}
                        id={id}
                        isOrganisation
                        formState={formState}
                        formDispatcher={formDispatcher}
                        // uiValidator={uiValidator}
                    />
                )}
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
