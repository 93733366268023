import {
    CODE_SERVICE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, getMapTypeEntite, useHasService } from '../../../util';

const setDefaultProperties = ({ type, service, uid, oid }) => {
    // Default Mapper
    const defaultMapper = [
        {
            field: 'typeProprietaire',
            displayName: 'Opération'
        },
        {
            field: 'caisse.designation',
            displayName: 'Caisse'
        },
        {
            field: 'typeEntite',
            displayName: 'Organisation',
            dataExtractor: (item) => getMapTypeEntite(item?.typeEntite)
        },
        {
            field: 'compte.numero',
            displayName: 'Compte'
        },
        {
            field: 'institutionFinanciere.designation',
            displayName: 'Banque'
        },
        {
            field: 'montant',
            displayName: 'Montant'
        },
        {
            field: 'numeroTransaction',
            displayName: 'Reference'
        },
        {
            field: 'devise',
            displayName: 'Devise'
        },
        {
            field: 'etat',
            displayName: 'Etat'
        },
        {
            displayName: 'Création',
            field: 'dateCreation'
        }
    ];

    // DefaultListerners
    let filters = [];
    if (
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.INITIER_OPERATION_BANCAIRE?.code
        ] ||
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_OPERATION_BANCAIRE?.code
        ]
    ) {
        if (type !== TYPE_ORGANISATION.NAPP) {
            filters = filters.concat([
                {
                    property: 'valeurTypeEntite.reference',
                    value: oid,
                    operator: FILTER_OPERATOR.EQUAL,
                    type: FILTER_TYPE.WHERE
                },
                {
                    property: 'typeEntite',
                    value: type,
                    operator: FILTER_OPERATOR.EQUAL,
                    type: FILTER_TYPE.WHERE
                }
            ]);
        } else {
            filters.push({
                property: 'estKinDistribution',
                value: true,
                operator: FILTER_OPERATOR.EQUAL,
                type: FILTER_TYPE.WHERE
            });
        }

        // eslint-disable-next-line no-else-return
    } else if (
        (service[
            CODE_SERVICE.FINANCE_ORGANISATION
                .ATTENTE_VALIDATION_OPERATION_BANCAIRE?.code
        ] ||
            service[
                CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_OPERATION_BANCAIRE
                    ?.code
            ] ||
            service[
                CODE_SERVICE.FINANCE_ORGANISATION
                    .DECAISSEMENT_OPERATION_BANCAIRE?.code
            ]) &&
        type !== TYPE_ORGANISATION.NAPP
    ) {
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.ARRAY_CONTAINS,
                property: 'users',
                value: uid,
                isRequired: true
            }
        ]);
    }
    if (
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.DECAISSEMENT_OPERATION_BANCAIRE
                ?.code
        ] ||
        service[
            CODE_SERVICE.FINANCE_ORGANISATION
                .ATTENTE_VALIDATION_OPERATION_BANCAIRE?.code
        ] ||
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_OPERATION_BANCAIRE?.code
        ] ||
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_OPERATION_BANCAIRE?.code
        ] ||
        service[
            CODE_SERVICE.FINANCE_ORGANISATION.INITIER_OPERATION_BANCAIRE?.code
        ]
    ) {
        filters = filters.concat([
            {
                property: 'dateDerniereModification',
                value: 'asc',
                type: FILTER_TYPE.ORDER_BY
            },
            {
                type: FILTER_TYPE.START_AT,
                value: TODAY.nowUtc()
            }
        ]);
    }
    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    return { mapper: [], listerners: [] };
};

export function ListOperationBancaireForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, firebaseUser, organisation } = useSelector(
        (reduxState) => ({
            nappUser: reduxState.nappUser,
            firebaseUser: reduxState.firebaseUser,
            organisation: reduxState.organisation
        })
    );
    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.FINANCE_ORGANISATION.DECAISSEMENT_OPERATION_BANCAIRE,
            CODE_SERVICE.FINANCE_ORGANISATION
                .ATTENTE_VALIDATION_OPERATION_BANCAIRE,
            CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_OPERATION_BANCAIRE,
            CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_OPERATION_BANCAIRE,
            CODE_SERVICE.FINANCE_ORGANISATION.INITIER_OPERATION_BANCAIRE
        ]
    });

    /**
     * Common form processes
     */
    const { oid, typeEntite } = useGetUserDetail({
        nappUser,
        firebaseUser,
        organisation
    });

    const { mapper, listerners } = setDefaultProperties({
        type: typeEntite,
        oid,
        reference: nappUser?.valeurTypeEntite?.reference,
        service: hasService
    });

    return (
        <ListGenericForm
            reduxPropertyName="operationsBancaires"
            functionName={URL_CONST.GET_LIST_OPERATION_BANCAIRE_INITIALS}
            nodeName={REDUX_NODE_NAME.OPERATION_BANCAIRE}
            path={DB_NODE.OPERATION_BANCAIRE}
            fetchType={FETCH_LISTENING}
            payload={{ estAll: true }}
            mapper={mapper}
            listenerFilters={listerners}
        />
    );
}
