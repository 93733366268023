import {
    fetchElement,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    URL_CONST,
    getTodayString,
    checkPlageDate,
    fromTimestampToString,
    utc000000,
    utc235959
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButton,
    CustomCol,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomInputDate,
    CustomRow,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { traitementMouvement } from '../../../util';

const defaultFields = {
    dateDebut: getTodayString(),
    dateFin: '',
    valeurTypeProprietaire: '',
    selectedTypeProprietaire: {}
};

export function ListMouvement() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, collections, etatCollection } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            collections: reduxState.collections.map((item) => ({
                ...item,
                designation: item.id,
                code: item.id
            })),
            etatCollection: reduxState.etat.collections.etat
        })
    );
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_COLLECTION,
                nodeName: REDUX_NODE_NAME.COLLECTION,
                etat: etatCollection
            }
        ]
    });
    const mouvementsFormater = (listMouvement) =>
        traitementMouvement(listMouvement)
            .map((item) => ({
                ...item,
                dateToDisplay: fromTimestampToString(
                    item.dateDerniereModification
                )
            }))
            .sort(
                (a, b) =>
                    b.dateDerniereModification - a.dateDerniereModification
            );

    const fetchMouvements = () => {
        const {
            valeurTypeProprietaire,
            selectedTypeProprietaire,
            dateFin,
            dateDebut
        } = fields;

        const { isValid, debut, fin } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }

        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload: {
                    valeurTypeProprietaire,
                    typeProprietaire: selectedTypeProprietaire?.code,
                    dateDebut: utc000000(debut),
                    dateFin: utc235959(fin)
                },
                functionName: URL_CONST.GET_LIST_MOUVEMENT_PLAGE,
                etat: elements.mouvements.status,
                formDispatcher,
                formater: mouvementsFormater,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <div className="container-fluid">
            <CustomAlert error={form?.error} />
            <CustomForm>
                <CustomRow>
                    <CustomInput
                        type="text"
                        divClassName="col-2 col-sm-2"
                        isInputGroup={false}
                        placeholder="valeurTypeProprietaire"
                        formDispatcher={formDispatcher}
                        name="valeurTypeProprietaire"
                        id="valeurTypeProprietaireCreationCrediterCompte"
                        formState={formState}
                    />
                    <CustomDropdown
                        options={collections}
                        divClassName="col-2 col-sm-2"
                        formDispatcher={formDispatcher}
                        name="selectedTypeProprietaire"
                        id="selectedTypeProprietaireListMouvement"
                        formState={formState}
                        propertyToDisplay="designation"
                        idProperty="code"
                    />
                    <CustomCol className="col-2">
                        <CustomInputDate
                            formDispatcher={formDispatcher}
                            id="dateDebutListMouvementInputDate"
                            name="dateDebut"
                            formState={formState}
                        />
                    </CustomCol>
                    <CustomCol className="col-2">
                        <CustomInputDate
                            formDispatcher={formDispatcher}
                            id="dateDebutListMouvementInputDate"
                            name="dateFin"
                            formState={formState}
                        />
                    </CustomCol>
                    <CustomCol className="col-2">
                        <CustomButton
                            disabled={
                                elements.mouvements.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            onClick={fetchMouvements}
                        >
                            Rechercher
                        </CustomButton>
                    </CustomCol>
                </CustomRow>
            </CustomForm>
            <hr />
            <SimpleTable
                isShowDropButton={false}
                data={elements.mouvements?.value || []}
                identifier="id"
                tableClass="table table-hover shadow table-bordered table-responsive-sm"
                filter
                bottomPagination={false}
                isLinkToDetail={false}
                mapper={[
                    {
                        field: 'id',
                        displayName: 'id',
                        isKeepFormat: true,
                        position: 1
                    },
                    {
                        field: 'libelle',
                        displayName: 'libellé',
                        position: 2,
                        dataExtractor: (item) =>
                            item?.libelle || item?.typeEntite
                    },
                    {
                        field: 'soldePrecedent',
                        displayName: 'solde ouverture',
                        position: 3
                    },
                    {
                        field: 'entree',
                        displayName: 'entree',
                        position: 4
                    },
                    {
                        field: 'sortie',
                        displayName: 'sortie',
                        position: 5
                    },
                    {
                        field: 'soldeActuel',
                        displayName: 'solde fermeture',
                        position: 6
                    },
                    // {
                    //     field: 'dateCreation',
                    //     displayName: 'date création',
                    //     position: 7
                    // },
                    {
                        field: 'dateDerniereModification',
                        displayName: 'date création',
                        position: 7
                    }
                ]}
            />
        </div>
    );
    return renderContent();
}
