import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { GenericSelector } from '../../../../components';

const formater = (donnees) => {
    const result = [];
    (donnees?.[0]?.listReturn || []).forEach((item) => {
        const itemToAdd = {};
        itemToAdd.agent = `${item?.agent?.prenom || ''} ${
            item?.agent?.nom || ''
        }`;
        itemToAdd.id = `${item?.agent?.reference || ''}`;
        itemToAdd.nombreRevendeur = item?.list?.nombreRevendeur;
        itemToAdd.prixTotal = item?.list?.prixTotal;
        item?.list?.marchands?.forEach((marchand) => {
            itemToAdd[marchand?.marchand?.code] = marchand?.prixTotal;
        });
        result.push(itemToAdd);
    });
    return result;
};
const dynamicColumnFormater = (dynamicColumn) => {
    const result = [];
    Object.keys(dynamicColumn || {}).forEach((key) => {
        result.push({
            displayName: dynamicColumn[key],
            field: `${key}`,
            isTotal: true
        });
    });
    if (Object.keys(dynamicColumn || {}).length) {
        result.push({
            displayName: 'Total',
            field: 'prixTotal',
            isTotal: true
        });
    }
    return result;
};

export function RapportRecruteurRevendeurForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedRecruteur: {}
        }
    });
    return (
        <ReduxReportTemplate
            dynamicColumnFormater={dynamicColumnFormater}
            dynamicNodeName={`reportsRecruteurRevendeur-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_RAPPORT_VENTE_RECRUTEMENT}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            mapper={[
                {
                    displayName: 'Agent',
                    field: 'agent'
                },
                {
                    displayName: 'Nombre revendeur',
                    field: 'nombreRevendeur'
                }
            ]}
            isAddTotalTr
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                typeEntite: 'Journalier'
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier'
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedRecruteur',
                    isRequired: false,
                    displayName: 'Recruteur',
                    callback: (item) => {
                        if (!item?.id || item?.id === 'all') return {};
                        return { referenceAgentRecrutement: item?.id };
                    }
                }
            ]}
        >
            <GenericSelector
                isDynamicNode
                dynamicNodeName="recruteursRevendeur"
                functionName={URL_CONST.GET_LIST_RECRUTEUR}
                formDispatcher={formDispatcher}
                formState={formState}
                label="Sélectionner un recruteur"
                propertyName="selectedRecruteur"
                isAddAditionalOptions
                listFormater={(list) =>
                    list.map((item) => ({
                        id: item?.reference,
                        code: item?.reference,
                        designation: `${item?.prenom || ''} ${item?.nom || ''}`
                    }))
                }
            />
        </ReduxReportTemplate>
    );
}
