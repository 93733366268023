/**
 * Retourne la désignation du stock en fonction du type de business
 * @param {String} designation Désignation du stock
 * @param {Array} business tableau des types de business
 * @returns
 */
export const getDesignationStock = (designation, business = []) => {
    if (business.length === 0 || business.length > 1) return designation;
    let typeBusiness = '';
    typeBusiness = business?.join(' , ');
    typeBusiness = typeBusiness.replace('Businessmodel', 'BM');
    typeBusiness = typeBusiness.replace('BusinessModel', 'BM');
    typeBusiness = typeBusiness.replace('Traditionnelbusiness', 'TB');
    typeBusiness = typeBusiness.replace('TraditionnelBusiness', 'TB');
    return `${designation} - ${typeBusiness}`;
};
