import { useState, useEffect } from 'react';

export function useAvailableForSelection({ listChamps, outputList }) {
    const [availableForSelection, setAvailableForSelection] = useState([]);
    useEffect(() => {
        setAvailableForSelection(
            [{ code: 'object', designation: 'object' }, ...listChamps].filter(
                (item) => {
                    if (item.code === 'object') return true;
                    if (item.code !== 'object') {
                        const out = outputList.find(
                            (outItem) => outItem.code === item.code
                        );
                        if (out) return false;
                        return true;
                    }
                    return false;
                }
            )
        );
    }, [listChamps, outputList]);
    return {
        availableForSelection
    };
}
