export const fieldsMapper = [
    {
        type: 'text',
        position: 1,
        isInputGroup: false,
        label: 'Code*',
        labelClassName: 'col-12 col-sm-2',
        divClassName: 'col-12 col-sm-10',
        placeholder: 'Saisissez le code',
        name: 'code',
        id: 'codeCreationSecteur'
    },
    {
        type: 'text',
        position: 2,
        isInputGroup: false,
        label: 'Designation*',
        labelClassName: 'col-12 col-sm-2',
        divClassName: 'col-12 col-sm-10',
        placeholder: 'Saisissez la designation',
        name: 'designation',
        id: 'designationCreationSecteur'
    },
    {
        type: 'checkbox',
        position: 3,
        labelClassName: 'col-sm-2',
        inputClassName: 'col-sm-10 ms-1',
        label: 'Est commercial ?',
        name: 'estCommercial',
        id: 'estCommercialCheckButton'
    }
];
