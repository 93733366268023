import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { ViewLayout } from './view-layout';

export function ListRemboursementCaution() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { isNapp } = useGetUserDetail({ nappUser });
    const mapper = isNapp
        ? [
              {
                  displayName: 'SuperDealer',
                  field: 'valeurTypeEntite.designation'
              },
              {
                  field: 'numeroCompteExterne',
                  displayName: 'Compte'
              },
              {
                  field: 'montant',
                  displayName: 'Montant'
              },
              {
                  field: 'dateCreation',
                  displayName: 'Création',
                  isDate: true
              }
          ]
        : [
              {
                  field: 'numeroCompteExterne',
                  displayName: 'Compte'
              },
              {
                  field: 'montant',
                  displayName: 'Montant'
              },
              {
                  field: 'dateCreation',
                  displayName: 'Création',
                  isDate: true
              }
          ];
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                isLinkToDetail={false}
                reduxPropertyName="remboursementCautionBeneficiaire"
                functionName={
                    URL_CONST.GET_LIST_REMBOURSEMENT_CAUTION_BENEFICIAIRE
                }
                nodeName={REDUX_NODE_NAME.REMBOURSEMENT_CAUTION_BENEFICIAIRE}
                mapper={mapper}
            />
        </ViewLayout>
    );
    return renderContent();
}
