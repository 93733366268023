import {
    fetchElement,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { GrFormRefresh } from 'react-icons/gr';
/**
 * Ce composant affiche un bouton qui permet d'actualiser ou refetcher sur une liste
 * @param {Object} param0 props
 * @returns {React.Component}
 */
export function RefetchListData({
    functionName,
    nodeName,
    isDynamicNode,
    dynamicNodeName,
    payload,
    formater,
    onError
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        elements: ['dataToRefetch']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { elements } = formState;
    const onSuccess = (result) => {
        const { element } = result || {};
        const arrayElement =
            formater && typeof formater === 'function'
                ? formater(element)
                : element;
        if (isDynamicNode) {
            reduxDispatcher(
                GENERIC_ITEM_ACTIONS_CREATOR.setItemDN(
                    arrayElement,
                    dynamicNodeName
                )
            );
        } else {
            reduxDispatcher(
                GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                    arrayElement,
                    nodeName
                )
            );
            reduxDispatcher(
                GENERIC_ITEM_ACTIONS_CREATOR.chargementItemSuccess(nodeName)
            );
        }
    };
    const fetchElementDataList = (e) => {
        e.preventDefault();
        formDispatcher(
            fetchElement({
                idToken,
                functionName,
                elementPropertyName: 'dataToRefetch',
                etat: elements.dataToRefetch.status,
                payload,
                onSuccess,
                onError,
                isDynamicNode,
                dynamicNodeName,
                formater,
                reduxDispatcher
            })
        );
    };
    return elements.dataToRefetch.status ===
        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
        <div className="spinner-border" role="status" />
    ) : (
        <span title="Actualiser" className="text-primary">
            <GrFormRefresh
                role="button"
                disabled={
                    elements.dataToRefetch.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                onClick={fetchElementDataList}
                className="text-primary h3"
            />
        </span>
    );
}
