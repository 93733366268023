import * as yup from 'yup';

/**
 * @description : Schema Regularisation Stock
 */
export const RegularisationStockFormSchema = yup.object().shape({
    libelle: yup.string(),
    quantite: yup.number().min(1),
    selectedProduit: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedImputation: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
