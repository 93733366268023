export const schema = [
    {
        description: 'sendeur.prenom',
        title: 'Prenom',
        className: 'border-bottom'
    },
    {
        description: 'sendeur.nom',
        title: 'Nom',
        className: 'border-bottom'
    },
    {
        description: 'marchand.designation',
        title: 'Marchand',
        className: 'border-bottom'
    },
    {
        description: 'marchand.code',
        title: 'Code marchand',
        className: 'border-bottom'
    },
    {
        description: 'zone.designation',
        title: 'Zone',
        className: 'border-bottom'
    },
    {
        description: 'zone.niveau',
        title: 'Niveau zone',
        className: 'border-bottom'
    }
];

export const sdMapper = [
    {
        description: 'sendeur.prenom',
        title: 'Prenom',
        className: 'border-bottom'
    },
    {
        description: 'sendeur.nom',
        title: 'Nom',
        className: 'border-bottom'
    }
];
