import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    ETAT,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleTable } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useGetTotauxFlashSender } from './useGetTotauxFlashSender';

export function ListTotauxSenderFlash() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        commandesFlashEncours,
        commandesFlashAttente,
        commandesFlashAnnuler,
        commandesFlashLivrer,
        node
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        commandesFlashEncours: reduxState.commandesFlashEncours,
        commandesFlashAttente: reduxState.commandesFlashAttente,
        commandesFlashAnnuler: reduxState.commandesFlashAnnuler,
        commandesFlashLivrer: reduxState.commandesFlashLivrer,
        node: {
            [ETAT.ETAT_ENCOURS]: reduxState.etat.commandesFlashEncours,
            [ETAT.ETAT_ATTENTE_VALIDATION]:
                reduxState.etat.commandesFlashAttente,
            [ETAT.ETAT_ANNULER]: reduxState.etat.commandesFlashAnnuler,
            [ETAT.ETAT_LIVRER]: reduxState.etat.commandesFlashLivrer
        }
    }));
    const { idToken, uid } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                fetchType: FETCH_LISTENING,
                functionName: URL_CONST.GET_LIST_COMMANDE_FLASH_BY_ETAT,
                nodeName: REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS,
                etat: node[ETAT.ETAT_ENCOURS].etat,
                ecouteur: node[ETAT.ETAT_ENCOURS].ecouteur,
                path: DB_NODE.COMMANDE_FLASH,
                payload: { etat: ETAT.ETAT_ENCOURS, estMax: true },
                listenerFilters: [
                    {
                        property: 'sendeur.reference',
                        value: uid,
                        operator: FILTER_OPERATOR.EQUAL,
                        type: FILTER_TYPE.WHERE
                    },
                    {
                        property: 'etat',
                        value: ETAT.ETAT_ENCOURS,
                        operator: FILTER_OPERATOR.EQUAL,
                        type: FILTER_TYPE.WHERE
                    },
                    {
                        type: FILTER_TYPE.ORDER_BY,
                        property: 'dateDerniereModification'
                    },
                    {
                        value: TODAY.nowUtc(),
                        type: FILTER_TYPE.START_AT
                    }
                ]
            },
            {
                fetchType: FETCH_LISTENING,
                functionName: URL_CONST.GET_LIST_COMMANDE_FLASH_BY_ETAT,
                nodeName: REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ATTENTE,
                etat: node[ETAT.ETAT_ATTENTE_VALIDATION].etat,
                ecouteur: node[ETAT.ETAT_ATTENTE_VALIDATION].ecouteur,
                path: DB_NODE.COMMANDE_FLASH,
                payload: { etat: ETAT.ETAT_ATTENTE_VALIDATION, estMax: true },
                listenerFilters: [
                    {
                        property: 'sendeur.reference',
                        value: uid,
                        operator: FILTER_OPERATOR.EQUAL,
                        type: FILTER_TYPE.WHERE
                    },
                    {
                        property: 'etat',
                        value: ETAT.ETAT_ATTENTE_VALIDATION,
                        operator: FILTER_OPERATOR.EQUAL,
                        type: FILTER_TYPE.WHERE
                    },
                    {
                        type: FILTER_TYPE.ORDER_BY,
                        property: 'dateDerniereModification'
                    },
                    {
                        value: TODAY.nowUtc(),
                        type: FILTER_TYPE.START_AT
                    }
                ]
            },
            {
                fetchType: FETCH_LISTENING,
                functionName: URL_CONST.GET_LIST_COMMANDE_FLASH_BY_ETAT,
                nodeName: REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ANNULER,
                etat: node[ETAT.ETAT_ANNULER].etat,
                ecouteur: node[ETAT.ETAT_ANNULER].ecouteur,
                path: DB_NODE.COMMANDE_FLASH,
                payload: { etat: ETAT.ETAT_ANNULER, estMax: true },
                listenerFilters: [
                    {
                        property: 'sendeur.reference',
                        value: uid,
                        operator: FILTER_OPERATOR.EQUAL,
                        type: FILTER_TYPE.WHERE
                    },
                    {
                        property: 'etat',
                        value: ETAT.ETAT_ANNULER,
                        operator: FILTER_OPERATOR.EQUAL,
                        type: FILTER_TYPE.WHERE
                    },
                    {
                        type: FILTER_TYPE.ORDER_BY,
                        property: 'dateDerniereModification'
                    },
                    {
                        value: TODAY.nowUtc(),
                        type: FILTER_TYPE.START_AT
                    }
                ]
            },
            {
                fetchType: FETCH_LISTENING,
                functionName: URL_CONST.GET_LIST_COMMANDE_FLASH_BY_ETAT,
                nodeName: REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_LIVRER,
                etat: node[ETAT.ETAT_LIVRER].etat,
                ecouteur: node[ETAT.ETAT_LIVRER].ecouteur,
                path: DB_NODE.COMMANDE_FLASH,
                payload: { etat: ETAT.ETAT_LIVRER, estMax: true },
                listenerFilters: [
                    {
                        property: 'sendeur.reference',
                        value: uid,
                        operator: FILTER_OPERATOR.EQUAL,
                        type: FILTER_TYPE.WHERE
                    },
                    {
                        property: 'etat',
                        value: ETAT.ETAT_LIVRER,
                        operator: FILTER_OPERATOR.EQUAL,
                        type: FILTER_TYPE.WHERE
                    },
                    {
                        type: FILTER_TYPE.ORDER_BY,
                        property: 'dateDerniereModification'
                    },
                    {
                        value: TODAY.nowUtc(),
                        type: FILTER_TYPE.START_AT
                    }
                ]
            }
        ]
    });
    const { totaux } = useGetTotauxFlashSender({
        encours: commandesFlashEncours,
        attente: commandesFlashAttente,
        annulees: commandesFlashAnnuler,
        livrees: commandesFlashLivrer
    });
    return (
        <SimpleTable
            isShowDropButton={false}
            listSelectedItemName="list"
            identifier="etat"
            data={totaux}
            tableClass="table table-hover shadow table-bordered table-responsive-sm"
            filter={false}
            bottomPagination={false}
            isLinkToDetail={false}
            mapper={[
                {
                    position: 1,
                    field: 'etat',
                    displayName: 'etat'
                },
                {
                    position: 2,
                    field: 'quantite',
                    displayName: 'Quantité'
                },
                {
                    position: 3,
                    field: 'montant',
                    displayName: 'Montant (USD)'
                }
            ]}
        />
    );
}
