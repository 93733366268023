import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { TYPE_ORGANISATION, formInitialState } from '@napp-inc/jnapp-util';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { FacturationStock } from './creation-form';

const defaultFields = {
    numero: '',
    montant: 0,
    quantite: 0,
    selectedMonnaie: {},
    selectedCompte: {},
    selectedCaisse: {},
    selectedSuperDealer: {},
    selectedInstitutionFinanciere: {},
    selectedMoyenPaiement: {},
    selectedTypeValeur: {},
    selectedNumeroStock: {},
    selectedTypeStock: {},
    reference: '',
    previewValue: '',
    estTransfertFonds: false
};

const setDefaultProperties = ({ type }) => {
    const payload = {
        caisses: {},
        comptes: {},
        superdealers: {},
        institutionFinanciere: {}
    };

    const CommonUiValidator = yup.object().shape({
        estTransfertFonds: yup.boolean(),
        selectedTypeStock: yup.object().shape({
            code: yup.string().required()
        }),
        selectedSuperDealer: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedMoyenPaiement: yup.object().shape({
            code: yup.string().required()
        }),
        selectedNumeroStock: yup.object().when('selectedTypeStock', {
            is: (selectedTypeStock) => selectedTypeStock.code === 'FLASH',
            then: yup.object().shape({
                numero: yup.string().required(),
                designation: yup.string().required()
            }),
            otherwise: yup.object().shape({
                numero: yup.string(),
                designation: yup.string()
            })
        }),
        selectedInstitutionFinanciere: yup
            .object()
            .when('selectedMoyenPaiement', {
                is: (selectedMoyenPaiement) =>
                    selectedMoyenPaiement.code === 'Compte',
                then: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                otherwise: yup.object().shape({
                    code: yup.string(),
                    designation: yup.string()
                })
            }),
        selectedCaisse: yup.object().when('selectedMoyenPaiement', {
            is: (selectedMoyenPaiement) =>
                selectedMoyenPaiement.code === 'Caisse',
            then: yup.object().shape({
                id: yup.string().required(),
                designation: yup.string().required()
            }),
            otherwise: yup.object().shape({
                id: yup.string(),
                designation: yup.string()
            })
        }),
        selectedCompte: yup.object().when('selectedMoyenPaiement', {
            is: (selectedMoyenPaiement) =>
                selectedMoyenPaiement.code === 'Compte',
            then: yup.object().shape({
                id: yup.string().required(),
                designation: yup.string().required()
            }),
            otherwise: yup.object().shape({
                id: yup.string(),
                designation: yup.string()
            })
        }),
        selectedTypeValeur: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        montant: yup.number().when('selectedTypeValeur', {
            is: (selectedTypeValeur) => selectedTypeValeur.code === 'Montant',
            then: yup.number().min(1),
            otherwise: yup.number()
        }),
        quantite: yup.number().when('selectedTypeValeur', {
            is: (selectedTypeValeur) => selectedTypeValeur.code === 'Unité',
            then: yup.number().min(1),
            otherwise: yup.number()
        })
    });

    if (type === TYPE_ORGANISATION.NAPP) {
        payload.caisses = { estOrganisation: true, estOperation: true };
        payload.comptes = { typeProprietaire: TYPE_ORGANISATION.SUPER_DEALER };
        payload.superdealers = { estAll: true, typeEntite: type };
        return {
            fields: defaultFields,
            dependencies: {
                fields: [
                    'comptesBancaire',
                    'superDealerKinDB',
                    'banques',
                    'caisses',
                    'comptesBancaireSuperDealer'
                ]
            },
            elements: ['caisses'],
            uiValidator: CommonUiValidator,
            cfPayload: payload
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR) {
        payload.caisses = { estOrganisation: true };
        // payload.comptes = { typeProprietaire: TYPE_ORGANISATION.SUPER_DEALER };
        payload.superdealers = { estAll: true, typeEntite: type };
        return {
            fields: defaultFields,
            dependencies: {
                fields: [
                    'comptesBancaire',
                    'superDealerKinDB',
                    'banques',
                    'caisses',
                    'comptesBancaireSuperDealer'
                ]
            },
            elements: ['caisses'],
            uiValidator: CommonUiValidator,
            cfPayload: payload
        };
    }
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        payload.caisses = { estOrganisation: true };
        return {
            fields: defaultFields,
            dependencies: {
                fields: ['comptesBancaire', 'banques', 'caisses']
            },
            elements: [],
            uiValidator: yup.object().shape({
                estTransfertFonds: yup.boolean(),
                selectedTypeStock: yup.object().shape({
                    code: yup.string()
                }),
                selectedSuperDealer: yup.object().shape({
                    code: yup.string(),
                    designation: yup.string()
                }),
                selectedMoyenPaiement: yup.object().shape({
                    code: yup.string().required()
                }),
                selectedNumeroStock: yup.object().when('selectedTypeStock', {
                    is: (selectedTypeStock) =>
                        selectedTypeStock.code === 'FLASH',
                    then: yup.object().shape({
                        numero: yup.string().required(),
                        designation: yup.string().required()
                    }),
                    otherwise: yup.object().shape({
                        numero: yup.string(),
                        designation: yup.string()
                    })
                }),
                selectedInstitutionFinanciere: yup
                    .object()
                    .when('selectedMoyenPaiement', {
                        is: (selectedMoyenPaiement) =>
                            selectedMoyenPaiement.code === 'Compte',
                        then: yup.object().shape({
                            code: yup.string().required(),
                            designation: yup.string().required()
                        }),
                        otherwise: yup.object().shape({
                            code: yup.string(),
                            designation: yup.string()
                        })
                    }),
                selectedCaisse: yup.object().when('selectedMoyenPaiement', {
                    is: (selectedMoyenPaiement) =>
                        selectedMoyenPaiement.code === 'Caisse',
                    then: yup.object().shape({
                        id: yup.string().required(),
                        designation: yup.string().required()
                    }),
                    otherwise: yup.object().shape({
                        id: yup.string(),
                        designation: yup.string()
                    })
                }),
                selectedCompte: yup.object().when('selectedMoyenPaiement', {
                    is: (selectedMoyenPaiement) =>
                        selectedMoyenPaiement.code === 'Compte',
                    then: yup.object().shape({
                        id: yup.string().required(),
                        designation: yup.string().required()
                    }),
                    otherwise: yup.object().shape({
                        id: yup.string(),
                        designation: yup.string()
                    })
                }),
                selectedTypeValeur: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                montant: yup.number().when('selectedTypeValeur', {
                    is: (selectedTypeValeur) =>
                        selectedTypeValeur.code === 'Montant',
                    then: yup.number().min(1),
                    otherwise: yup.number()
                }),
                quantite: yup.number().when('selectedTypeValeur', {
                    is: (selectedTypeValeur) =>
                        selectedTypeValeur.code === 'Unité',
                    then: yup.number().min(1),
                    otherwise: yup.number()
                })
            }),
            cfPayload: payload
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({}),
        cfPayload: {}
    };
};

export function CreateFacturationStockForm() {
    /**
     * Get data on redux store
     */
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const { fields, dependencies, elements, uiValidator, cfPayload } =
        setDefaultProperties({ type: typeEntite });
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });

    /**
     * @describe : Utils functions
     */

    const stockBySuperDealer = ({ choosenSuperDealer, numbers }) => {
        const stock = numbers
            .filter(
                (number) => number?.codeSuperDealer === choosenSuperDealer?.code
            )
            .map((item) => ({
                ...item,
                designation: `${item.numero}-${item.code}`
            }));
        return {
            stock,
            hasLength: !!stock?.length
        };
    };

    const getCompteSuperDealer = ({
        isTrue,
        accounts,
        bank,
        selectedSuperDealer
    }) =>
        isTrue
            ? accounts.filter(
                  (item) =>
                      item?.institutionFinanciere?.code === bank?.code &&
                      item.valeurTypeProprietaire.code ===
                          selectedSuperDealer.code
              )
            : accounts.filter(
                  (item) => item?.institutionFinanciere?.code === bank?.code
              );

    const renderContent = () =>
        initialState?.dependencies ? (
            <FacturationStock
                idToken={idToken}
                typeEntite={typeEntite}
                initialState={initialState}
                uiValidator={uiValidator}
                cfPayload={cfPayload}
                stockBySuperDealerFn={stockBySuperDealer}
                getCompteSuperDealerFn={getCompteSuperDealer}
            />
        ) : null;
    return renderContent();
}
