import { useDispatch } from 'react-redux';
import { submitPostAction, useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import { FORM_CONST, formInitialState, URL_CONST } from '@napp-inc/jnapp-util';
import { Col } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import {
    CustomCheckButton,
    CustomButtonLoader,
    CustomRow,
    CustomFormGroup,
    FormWrapper,
    DynamicFormMounted
} from '../../components';
import {
    AddressPhysiqueMapper,
    DenominationMapper,
    InfoCommercialMapper,
    ProprietaireInfoMapper,
    RegistrationSchema as uiValidator
} from '../../util';
import {
    ConfirmSignUpRelPath,
    ForgotPasswordRelPath,
    SignInRelPath
} from '../../routes/registration/gestion-user/connexion-rel-path';

const defaultFields = {
    designation: '',
    code: '',
    adresseUne: '',
    rccm: '',
    prenom: '',
    nom: '',
    postnom: '',
    email: '',
    emailProprietaire: '',
    numeroTel: '',
    aggree: false,
    entrepriseNumero: '',
    adresseProprietaire: ''
};

export function SingUp() {
    const reduxDispatcher = useDispatch();
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        { initialState }
    );
    const { fields, form } = formState;
    const { status } = form;
    if (status === FORM_CONST.CREATION_SUCCESS) {
        return <Navigate to={`/${ConfirmSignUpRelPath.path}`} replace />;
    }
    const payload = {
        newEntreprise: {
            code: fields.code,
            designation: fields.designation,
            email: fields.email,
            numeroTel: fields.entrepriseNumero,
            rccm: fields.rccm
        },
        newAdresse: {
            adresseUne: fields.adresseUne
        },
        proprietaires: [
            {
                nom: fields.nom,
                prenom: fields.prenom,
                postnom: fields.postnom,
                numeroTel: fields.numeroTel,
                email: fields.emailProprietaire,
                adresse: {
                    adresseUne: fields.adresseUne
                }
            }
        ]
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        formDispatcher(
            submitPostAction({
                idToken: '',
                functionName: URL_CONST.POST_ENTREPRISE,
                payload,
                fields,
                uiValidator,
                reduxNodeName: 'createEntreprise',
                reduxDispatcher
            })
        );
    };
    return (
        // <div className="container-fluid vh-100 vw-100 justify-content-center align-items-center">
        <CustomRow className="vh-100 vw-100 justify-content-md-center">
            <Col className="bg-primary d-flex flex-column justify-content-center align-items-center">
                <p className="fs-3 mb-3 fw-bold text-light">
                    Créez votre entreprise!
                </p>
                <p>
                    <Link
                        className="text-decoration-none text-black"
                        to={`/${SignInRelPath.path}`}
                    >
                        Se connecter!
                    </Link>
                </p>
            </Col>
            <Col className="col-md-8 col-xl-8 col-lg-8 pb-5 mt-5 napp-sign-up">
                <FormWrapper
                    onSubmit={handleSubmit}
                    formState={formState}
                    formDispatcher={formDispatcher}
                    isRedirect={false}
                >
                    <h5 className="h5 mb-2 fw-bolder">Dénomination</h5>
                    <hr className="mb-4" />
                    <DynamicFormMounted
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        fieldsMapper={DenominationMapper}
                    />
                    <h5 className="h5 mt-4 mb-2 fw-bolder">Adresse physique</h5>
                    <hr className="mb-4" />
                    <DynamicFormMounted
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        fieldsMapper={AddressPhysiqueMapper}
                    />
                    <h5 className="h5 mb-2 fw-bolder">
                        Informations Commerciales
                    </h5>
                    <hr className="mb-4" />
                    <DynamicFormMounted
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        fieldsMapper={InfoCommercialMapper}
                    />
                    <h5 className="h5 mb-2 fw-bolder">
                        Informations sur le(s) propriétaire(s)
                    </h5>
                    <hr className="mb-4" />
                    <DynamicFormMounted
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        fieldsMapper={ProprietaireInfoMapper}
                    />
                    <CustomFormGroup className="mb-3">
                        <CustomCheckButton
                            uiValidator={uiValidator}
                            formDispatcher={formDispatcher}
                            name="aggree"
                            id="OwnerAggreement"
                            label="J'ai lu et j'accepte les termes de confidentialité"
                            formState={formState}
                            divClassName="ms-1"
                            labelClassName="col-sm-10 fs-6 text-decoration-underline"
                            inputClassName="col-sm-2"
                        />
                        <div className="mt-3 d-flex justify-content-between">
                            <p className="text-muted">
                                <Link to={`/${ForgotPasswordRelPath.path}`}>
                                    Mot de passe oublié?
                                </Link>
                            </p>
                            <p className="text-muted">
                                <Link to={`/${ConfirmSignUpRelPath.path}`}>
                                    Finalisez la création d&apos;une entreprise
                                    existante?
                                </Link>
                            </p>
                        </div>
                    </CustomFormGroup>
                    <CustomButtonLoader
                        className="px-4 w-25 btn-lg"
                        text="Inscription"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </FormWrapper>
            </Col>
        </CustomRow>
        // </div>
    );
}
