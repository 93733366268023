export const schema = [
    {
        description: 'numeroCompteExterne',
        title: 'N° Compte'
    },
    {
        description: 'tempsTemporisation',
        title: 'Temporisation'
    },
    {
        description: 'intervalle',
        title: 'Intervalle'
    },
    {
        description: 'etat',
        title: 'Etat'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
