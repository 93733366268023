export const schema = [
    {
        title: 'Imputation',
        description: 'imputationCompte.designation',
        className: 'border-bottom'
    },
    {
        title: 'Numéro',
        description: 'numero',
        className: 'border-bottom'
    },
    {
        title: 'Libelle',
        description: 'libelle',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Quantité',
        description: 'quantite',
        className: 'border-bottom'
    },
    {
        title: 'Date Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
