import { useCallback } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import { FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST, formaterCaisses } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../../components';

export function TransfertCaisse({
    idToken,
    // typeEntite,
    uiValidator,
    initialState,
    cfPayload
}) {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        caisses,
        etatCaisses,
        caissesOrganisation,
        etatCaissesOrganisation
    } = useSelector((reduxState) => ({
        caisses: formaterCaisses(
            reduxState?.[cfPayload?.caisseReduxProperty] || []
        ),
        etatCaisses: reduxState?.etat?.[cfPayload?.caisseReduxProperty]?.etat,
        caissesOrganisation: formaterCaisses(
            reduxState?.[cfPayload?.caisseOrganisationReduxProperty] || []
        ),
        etatCaissesOrganisation:
            reduxState?.etat?.[cfPayload?.caisseOrganisationReduxProperty]?.etat
    }));

    /**
     * Common form processes
     */
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const { fields, form } = formState;

    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: cfPayload?.caisseNodeName,
                payload: cfPayload?.caisses,
                etat: etatCaisses,
                isCondition: !!cfPayload.caisseNodeName
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: cfPayload?.caisseOrganisationNodeName,
                payload: cfPayload?.caissesOrganisation,
                etat: etatCaissesOrganisation,
                isCondition: !!cfPayload.caisseOrganisationNodeName
            }
        ]
    });

    const getTypeCaisse = useCallback(
        () =>
            caisses?.find(
                (element) => element?.id === fields?.selectedCaisseSource?.id
            ),
        [caisses, fields.selectedCaisseSource.id]
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        const payloadToOut = {};
        const payload = {
            newTransfert: {
                caisseA: {
                    reference: fields.selectedCaisseSource.id
                },
                caisseB: {
                    reference: fields.selectedCaisseDestination.id
                }
            },
            ...payloadToOut,
            montant: fields.montant
        };
        /** Insertion numeroReference */
        if (getTypeCaisse()?.estEmoney) {
            payload.numeroReference = fields.numeroReference;
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_TRANSFERT_CAISSE,
                payload,
                fields,
                uiValidator,
                reduxNodeName: REDUX_NODE_NAME.TRANSFERT_CAISSE,
                reduxDispatcher
            })
        );
    };

    const getDestinationCaisses = useCallback(() => {
        const caissesDestination = caissesOrganisation.filter(
            (caisse) =>
                fields.selectedCaisseSource.id !== caisse?.id &&
                caissesOrganisation.find(
                    (item) => fields.selectedCaisseSource?.id === item?.id
                )?.devise === caisse?.devise
        );
        return {
            caissesDestination,
            tailleCaisses: caissesDestination.length
        };
    }, [caissesOrganisation, fields.selectedCaisseSource.id]);

    const { caissesDestination, tailleCaisses } = getDestinationCaisses();

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'caisses',
                    etat: etatCaisses
                },
                {
                    dependency: 'caissesOrganisation',
                    etat: etatCaissesOrganisation
                }
            ]}
        >
            {/* <StateShowerComponent state={formState} /> */}
            <CustomRow>
                <CustomDropdown
                    options={caisses}
                    label="Caisse Source*"
                    defaultOption="Sélectionner caisse Source"
                    disabled={!caisses.length}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCaisseSource"
                    id="selectedCaisseSourceCreationVersementAgentVendeur"
                    formState={formState}
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!!getTypeCaisse()?.estEmoney}>
                <CustomInput
                    type="text"
                    label="Numéro Référence* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Référence Emoney"
                    formDispatcher={formDispatcher}
                    name="numeroReference"
                    id="creationNumeroReferenceCreationTransfertInterInstitutionnelId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={caissesDestination}
                    disabled={!tailleCaisses}
                    label="Caisse Destination*"
                    defaultOption="Sélectionner Caisse Destination"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCaisseDestination"
                    id="selectedCaisseDestinationCreationVersementAgentVendeur"
                    formState={formState}
                    uiValidator={uiValidator}
                    idProperty="id"
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    isInputGroup={false}
                    label="Montant*: "
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="montantCreationTransfertCaisseId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Soumettre"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
