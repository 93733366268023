// import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
// import { ListGenericForm } from '../../../components';

export function ListConfigEquipeTechniqueForm() {
    return (
        <div />
        // <ListGenericForm
        //     reduxPropertyName="techConfigs"
        //     functionName={URL_CONST }
        //     nodeName={ DB_NODE.CONFIG }
        //     mapper={[
        //         {
        //             field: 'id',
        //             displayName: 'id',
        //             position: 1
        //         },
        //         {
        //             displayName: 'Création',
        //             field: 'dateCreation',
        //             position: 2
        //         }
        //     ]}
        // />
    );
}
