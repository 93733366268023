export const schema = [
    {
        title: 'Code',
        description: 'code',
        className: 'border-bottom'
    },
    {
        title: 'Designation',
        description: 'designation',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation'
    }
];
