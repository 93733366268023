import * as yup from 'yup';

export const ConfirmCreateUserMarchandSchema = yup.object().shape({
    newPersonneNom: yup.string(),
    newPersonnePostnom: yup.string(),
    newPersonnePrenom: yup.string(),
    newPersonneSexe: yup.object().shape({
        code: yup.string().matches(/M|F/, 'Le sexe doit être M ou F'),
        designation: yup
            .string()
            .matches(/Homme|Femme/, 'Le sexe doit être Masculin ou Feminin')
    }),
    newPersonneTypeCarteIdentite: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    newPersonneNumeroCarteIdentite: yup.string(),
    newPersonneEmailPersonnelle: yup.string().email(),
    newPersonneEmail: yup.string().email(),
    newPersonneNumeroTel: yup.string(),
    // .matches(
    //     /[0-9]/,
    //     'Le numéro de téléphone ne doit comporter que des nombres'
    // ),
    valeurTypeProprietaire: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    profilUserDedier: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    typeContrat: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    codeFonction: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    newAdresseAdresseUne: yup.string()
});
