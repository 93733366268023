export const OwnerMapper = [
    {
        position: 0,
        type: 'text',
        name: 'prenom',
        id: 'cashCollectionOrganisationOwnerFirstNameId',
        placeholder: 'Prénom',
        label: 'Prénom*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 1,
        type: 'text',
        name: 'nom',
        id: 'cashCollectionOrganisationOwnerNameId',
        placeholder: 'Nom',
        label: 'Nom*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 3,
        type: 'email',
        name: 'userEmail',
        id: 'cashCollectionOrganisationEmail',
        placeholder: 'exemple@domaine.com',
        label: 'Email Propriétaire*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 4,
        type: 'text',
        name: 'numeroTel',
        id: 'cashCollectionOrganisationOwnerPhone',
        placeholder: '243 xxx xxx xxx',
        label: 'Téléphone*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 5,
        type: 'text',
        name: 'userAdresseUne',
        id: 'cashCollectionOrganisationAdresseProprietaireId',
        placeholder: '26, ByPass, Ngaliema; Kinshasa/RDC',
        label: 'Adresse*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    }
];

export const CommercialInfoMapper = [
    {
        position: 0,
        type: 'text',
        name: 'rccm',
        id: 'cashCollectionOrganisationRCCMId',
        placeholder: 'ex: ORD-N-182/78/IG',
        label: 'RCCM ou Patente*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    }
];

export const OrganisationMapper = [
    {
        position: 0,
        type: 'text',
        name: 'code',
        id: 'cashCollectionOrganisationCodeId',
        placeholder: 'ex: MON_ORGANISATION',
        label: 'Code*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 1,
        type: 'text',
        name: 'designation',
        id: 'cashCollectionOrganisationDesignationId',
        placeholder: 'ex: Mon Organisation',
        label: 'Désignation*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 2,
        type: 'text',
        name: 'adresseUne',
        id: 'cashCollectionOrganisationAdresseOrganisationId',
        placeholder: '26, ByPass, Ngaliema; Kinshasa/RDC',
        label: 'Adresse*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    }
];

export const AddresseEMailMapper = [
    {
        position: 0,
        type: 'email',
        name: 'email',
        id: 'cashCollectionOrganisationEmailId',
        placeholder: 'example@domaine.com',
        label: 'Email*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    },
    {
        position: 1,
        type: 'text',
        name: 'codeValidation',
        id: 'cashCollectionOrganisationCodeValidationId',
        placeholder: 'xxxx',
        label: 'Code*',
        isInputGroup: false,
        labelClassName: 'col-sm-3',
        divClassName: 'col-sm-9'
    }
];
