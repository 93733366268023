export const schema = [
    {
        callback: (item) =>
            `${item?.personne?.prenom || ''} ${item?.personne?.nom || ''} ${
                item?.personne?.postnom || ''
            }`,
        title: 'Gestionnaire'
    },
    {
        title: 'Designation',
        description: 'designation',
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
