import React from 'react';
import {
    fetchElement,
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TYPE_ORGANISATION,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomAlert,
    CustomButtonLoader,
    CustomInput,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../../components';
import { URL_CONST } from '../../../../util';

const defaultFields = {
    tillNumber: ''
};

export function ResetPinView() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['user']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    const fetchMarchand = () => {
        const { tillNumber } = fields;
        if (tillNumber) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'user',
                    payload: {
                        tillNumber,
                        estDetailEntreprise: true,
                        typeEntite:
                            TYPE_ORGANISATION.ORGANISATION_REVENDEUR.code
                    },
                    functionName: URL_CONST.GET_USER_BY_TILL_NUMBER,
                    etat: elements.user.status,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };

    const { user } = elements;
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!user?.value?.pin) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        "Cette utilisateur n'a pas de PIN configuré! \n\nVeuillez lui demander d'en configurer un!",
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        if (!user?.value?.uid) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: "Veuillez rechercher l'utilisateur à nouveau",
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        const payload = {
            owner: user?.value?.uid
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_RESET_PIN,
                // reduxNodeName: REDUX_NODE_NAME.COMPTE_MOBILE_MONEY,
                payload,
                fields
                // reduxDispatcher
                // uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            isRedirect={false}
            isBackComponent={false}
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            {form.status === FORM_CONST.MODIFICATION_SUCCESS ? (
                <CustomAlert successMessage="Pin Réinitialisé avec succès!" />
            ) : null}
            {elements.user.status === FORM_CONST.FETCH_ELEMENT_FAILED ? (
                <CustomAlert error={`Erreur: ${elements.user.error || ''}`} />
            ) : null}
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Till Number*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-8"
                    placeholder="Saisissez le till number de l'utilisateur"
                    formDispatcher={formDispatcher}
                    name="tillNumber"
                    id="tillNumberId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
                <CustomButtonLoader
                    type="button"
                    className="col-12 col-sm-2 btn-success"
                    onClick={fetchMarchand}
                    text="Rechercher"
                    disabled={
                        user?.status === FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    user?.status === FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
            >
                <span className="fs-6 text-muted">
                    {' '}
                    Informations de l&apos;utilisateur
                </span>
                <SimpleTable
                    listSelectedItemName="includedList"
                    data={user?.value?.id ? [user?.value] : []}
                    // identifier="id"
                    isShowDropButton={false}
                    tableClass="table table-hover shadow table-bordered table-responsive-sm w-100"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            field: 'personne.prenom',
                            displayName: 'Prenom'
                        },
                        {
                            field: 'personne.nom',
                            displayName: 'Nom'
                        },
                        {
                            field: 'profilUser.designation',
                            displayName: 'Profil'
                        },
                        {
                            field: 'tillNumber',
                            displayName: 'T-Number'
                        },
                        {
                            field: 'typeEntite',
                            displayName: 'Organisation'
                        },
                        {
                            field: 'valeurTypeEntite.designation',
                            displayName: 'Entreprise'
                        },
                        {
                            dataExtractor: (item) =>
                                item?.pin ? 'Oui' : 'Non',
                            displayName: 'Pin Configuré?'
                        },
                        {
                            field: 'dateCreation',
                            displayName: 'Date Création'
                        }
                    ]}
                />
                <CustomRow>
                    <CustomButtonLoader
                        className="btn-primary col-sm-2 ms-2"
                        onClick={handleSubmit}
                        text="Réinitialiser PIN"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
        </FormWrapper>
    );
    return renderContent();
}
