import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListDecaissementForm() {
    return (
        <ListGenericForm
            reduxPropertyName="decaissements"
            payload={{ typeProprietaire: 'Decaissement' }}
            functionName={URL_CONST.GET_LIST_ENCAISSEMENT_DECAISSEMENT_INITIALS}
            nodeName={REDUX_NODE_NAME.DECAISSEMENTS}
            mapper={[
                {
                    displayName: 'Opération',
                    field: 'typeProprietaire'
                },
                {
                    displayName: 'Caisse',
                    field: 'caisse.designation'
                },
                {
                    displayName: 'Partenaire',
                    field: 'partenaire.designation'
                },
                {
                    displayName: 'Devise Sollicité',
                    field: 'devise'
                },
                {
                    displayName: 'Devise',
                    field: 'caisse.devise'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
