import * as yup from 'yup';

/**
 * @description : Schema de elaboration charge ressource
 */
export const ElaborationChargeRessourceFormSchema = yup.object().shape({
    selectedExercice: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedDirection: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedSecteur: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedRessource: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    // modePaiement: yup.object().shape({
    //     code: yup.string().required(),
    //     designation: yup.string().required()
    // }),
    // selectedCaisseSectorielle: yup.object().shape({
    //     id: yup.string(),
    //     designation: yup.string()
    // }),
    // datePaiement: yup.string().required(),
    tauxCroissance: yup.number().min(1)
});
