import { URL_CONST, useGetReportDetail } from '../../../../util';
import { StandardReportTemplate } from '../../template';
import { SuperDealerSelector } from '../../../../components';

const formater = (data = []) => {
    const result = [];
    (data?.[0]?.listReturn || []).forEach((item) => {
        result.push({
            numero: item?.client?.numero,
            devise: item?.devise,
            dateCreation: item?.dateCreation,
            etat: item?.etat,
            montant: {
                [item?.devise]: item?.montant
            },
            reference: item?.numeroReference
        });
    });
    return result;
};
const dynamicColumnFormater = (dynamicColumn) => {
    const result = [];
    Object.keys(dynamicColumn || {}).forEach((key) => {
        result.push({
            displayName: dynamicColumn[key],
            field: `montant.${key}`,
            isTotal: true
        });
    });
    if (Object.keys(dynamicColumn || {}).length) {
        result.push(
            {
                field: 'etat',
                displayName: 'Etat'
            },
            {
                field: 'dateCreation',
                displayName: 'Création'
            }
        );
    }
    return result;
};

export function RapportEtatRetraitKinDBForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedSuperDealer: {}
        }
    });
    return (
        <StandardReportTemplate
            functionName={URL_CONST.GET_LIST_RETRAIT_BY_RELEVE}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            dynamicColumnFormater={dynamicColumnFormater}
            mapper={[
                {
                    displayName: 'Numéro',
                    field: 'numero'
                }
            ]}
            isAddTotalTr
            defaultPayloadMapperValues={{}}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedSuperDealer.code',
                    property: 'codeSuperDealer',
                    isRequired: false,
                    displayName: 'Super dealer'
                }
            ]}
        >
            <SuperDealerSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                estNonAirtime
            />
        </StandardReportTemplate>
    );
}
