import * as yup from 'yup';

/**
 * @description : Schema de changement de mot de passe
 */
export const ChangePasswordFormSchema = yup.object().shape({
    currentPassword: yup.string().required(),
    newPassword: yup
        .string()
        .required('Veuillez saisir votre nouveau mot de passe')
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            'Doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial'
        ),
    confirmedPassword: yup
        .string()
        .oneOf(
            [yup.ref('newPassword'), null],
            'Les mots de passe doivent correspondre'
        )
        .required('Veuillez confirmer votre nouveau mot de passe')
});
