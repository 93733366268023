import { Div, intParser, Mul } from '@napp-inc/jnapp-util';
import { useEffect, useState } from 'react';

export function useCalculPrixTotal({
    quantite,
    prix,
    unite = 'UNITE',
    deviseReference,
    taux = []
}) {
    const [prixTotal, setPrixTotal] = useState(0);
    const [quantiteComputed, setQuantiteComputed] = useState(0);
    useEffect(() => {
        if (typeof quantite === 'number' && typeof prix === 'number') {
            let prixTotalTemp = 0;
            let quantiteComputedTemp = 0;
            if (unite === 'UNITE') {
                prixTotalTemp = Mul(quantite, prix);
                quantiteComputedTemp = quantite;
            } else if (unite === deviseReference) {
                prixTotalTemp = quantite;
                quantiteComputedTemp = intParser(Div(quantite, prix));
            } else {
                const tauxToUse = taux.find((item) => item.devise === unite);
                if (tauxToUse && typeof tauxToUse.valeur === 'number') {
                    prixTotalTemp = Div(quantite, tauxToUse.valeur);
                    quantiteComputedTemp = intParser(Div(prixTotalTemp, prix));
                }
            }
            if (!Number.isNaN(prixTotalTemp)) setPrixTotal(prixTotalTemp);
            if (!Number.isNaN(quantiteComputedTemp))
                setQuantiteComputed(quantiteComputedTemp);
        }
    }, [deviseReference, quantite, prix, unite, taux]);

    return { prixTotal, quantiteComputed };
}
