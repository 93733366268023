import { ViewLayout } from './view-layout';

export function ListRestitutionPerte() {
    const renderContent = () => (
        <ViewLayout>
            <h2>Liste Restitution</h2>
        </ViewLayout>
    );
    return renderContent();
}
