import * as yup from 'yup';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { formInitialState, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { TransfertSim } from './creation-form';

/**
 * State for the transfert sim form
 */
const defaultFields = {
    beneficiaire: {},
    tillNumberAcheteur: '',
    quantiteSim: 0
};

const defaultDependencies = { fields: ['users'] };

const setDefaultProperties = ({ type }) => {
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                beneficiaire: yup.object().shape({
                    uid: yup.string().required(),
                    designation: yup.string().required()
                }),
                tillNumberAcheteur: yup.string(),
                quantiteSim: yup.number().min(1)
            })
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                beneficiaire: yup.object().shape({
                    uid: yup.string().required(),
                    designation: yup.string().required()
                }),
                tillNumberAcheteur: yup.string(),
                quantiteSim: yup.number().min(1)
            })
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                beneficiaire: yup.object().shape({
                    uid: yup.string().required(),
                    designation: yup.string().required()
                }),
                tillNumberAcheteur: yup.string(),
                quantiteSim: yup.number().min(1)
            })
        };
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR)
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                beneficiaire: yup.object().shape({
                    uid: yup.string().required(),
                    designation: yup.string().required()
                }),
                tillNumberAcheteur: yup.string(),
                quantiteSim: yup.number().min(1)
            })
        };
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({}),
        cfPayload: {}
    };
};

/**
 * Vue du formulaire de transfert des sims
 * @returns {JSX.Element}
 */
export function CreationTransfertSimForm() {
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));

    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const { fields, dependencies, elements, uiValidator } =
        setDefaultProperties({ type: typeEntite });
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });

    const renderContent = () =>
        initialState?.dependencies ? (
            <TransfertSim
                idToken={idToken}
                typeEntite={typeEntite}
                uiValidator={uiValidator}
                initialState={initialState}
            />
        ) : null;
    return renderContent();
}
