import { submitPutAction } from '@napp-inc/jnapp-hook';
import { ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailRegularisationSpecialeForm() {
    const {
        id,
        creator,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher
    } = useGetDetailForm({ nodeName: 'regularisationSpeciale' });

    const { fields, form } = formState;

    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = {
            etat,
            creator,
            idRegularisationStock: entity?.id
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_REGULARISATION_STOCK,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.REGULARISATION_SPECIALE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_REGULARISATION_STOCK_BY_ID}
            nodeName={REDUX_NODE_NAME.REGULARISATION_SPECIALE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Regularisation Spéciale"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' }
                    // CODE_SERVICE.STOCK.REGULARISATION_STOCK
                    //     .ATTENTE_VALIDATION_REGULARISATION_STOCK,
                    // CODE_SERVICE.STOCK.REGULARISATION_STOCK
                    //     .ANNULER_REGULARISATION_STOCK,
                    // CODE_SERVICE.STOCK.REGULARISATION_STOCK
                    //     .VALIDER_REGULARISATION_STOCK
                ]}
            >
                <CustomRow>
                    <CustomCol className="offset-4 col-3 col-sm-2">
                        <CustomButtonLoader
                            className="btn-danger"
                            onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            text="Annuler"
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        {entity?.etat === ETAT.ETAT_ENCOURS ? (
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                text="Mettre en attente"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        ) : null}
                        {entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION ? (
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                                text="Valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        ) : null}
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
