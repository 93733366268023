export const schema = [
    {
        title: 'Revendeur',
        description: 'revendeur.code',
        className: 'border-bottom'
    },
    {
        title: 'Zone',
        // description: 'secteur.designation',
        callback: (item) => {
            if (item?.zones) {
                const zones = item?.zones?.map(
                    (element) => element?.designation || ''
                );
                return zones.join(' | ');
            }
            return item?.secteur?.designation || '';
        },
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
