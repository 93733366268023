import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../components';
import { crediterCompteSchemaValidator as uiValidator } from './validation';
import { URL_CONST } from '../../../util';

const defaultFields = {
    selectedDevise: {},
    selectedDeviseSollicite: {},
    montant: 0,
    tillNumber: '',
    isChange: false
};

const defaultDependencies = { fields: ['monnaiesLocale'] };
const defaultElements = ['revendeur'];

export function CreationCrediterCompteForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, monnaiesLocale, etatMonnaieLocale } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            monnaiesLocale: reduxState.monnaiesLocale.map((item) => ({
                ...item,
                code: item.code || item.devise
            })),
            etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat
        })
    );
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements, form } = formState;
    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            }
        ]
    });
    const fetchRevendeur = () => {
        const { tillNumber } = fields;
        if (
            tillNumber &&
            tillNumber !== elements.revendeur?.value?.tillNumber
        ) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'revendeur',
                    payload: { tillNumber },
                    functionName: URL_CONST.GET_REVENDEUR_BY_TILL_NUMBER,
                    etat: elements.revendeur.status,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedDevise, montant, tillNumber } = fields;
        const { revendeur } = elements;
        if (
            (tillNumber &&
                revendeur.status !== FORM_CONST.FETCH_ELEMENT_SUCCESS) ||
            !revendeur?.value?.id
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Veuillez rechercher le revendeur',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const payloadToAdd = {};
        if (fields.isChange && fields.selectedDeviseSollicite.code) {
            payloadToAdd.deviseSollicite = fields.selectedDeviseSollicite.code;
        }
        const payload = {
            newCrediterCompte: {
                typeEntite: 'Cash',
                devise: selectedDevise?.code,
                typeProprietaire: TYPE_ORGANISATION.REVENDEUR,
                valeurTypeProprietaire: {
                    reference: revendeur?.value?.id
                },
                montant,
                ...payloadToAdd
            }
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CREDITER_COMPTE,
                payload,
                fields,
                reduxNodeName: DB_NODE.CREDITER_COMPTE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            onSubmit={handleSubmit}
            listDependencies={[
                {
                    dependency: 'monnaiesLocale',
                    etat: etatMonnaieLocale
                }
            ]}
        >
            <CustomRow>
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    text="Activer l'opération de Change"
                    name="isChange"
                    divClassName="col-md-8"
                    value={fields.isChange}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Till number*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-10 col-sm-8"
                    isInputGroup={false}
                    placeholder="Saisissez le tillNumber"
                    formDispatcher={formDispatcher}
                    name="tillNumber"
                    id="tillNumberCreationCrediterCompte"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomCol className="col-2">
                    <CustomButtonLoader
                        className="col-12"
                        type="button"
                        disabled={
                            elements.revendeur.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        isActionEncours={
                            elements.revendeur.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        onClick={fetchRevendeur}
                        text="Rechercher"
                    />
                </CustomCol>
            </CustomRow>
            {elements.revendeur.status === FORM_CONST.FETCH_ELEMENT_FAILED && (
                <CustomRow>
                    <CustomCol className="offset-md-2 col-12 col-md-10">
                        <CustomAlert
                            error={`${elements.revendeur?.error || ''}`}
                        />
                    </CustomCol>
                </CustomRow>
            )}
            {elements.revendeur.status === FORM_CONST.FETCH_ELEMENT_SUCCESS && (
                <CustomRow>
                    <CustomCol className="offset-md-2 col-12 col-md-10">
                        <CustomAlert
                            successMessage={`${
                                elements.revendeur?.value?.tillNumber || ''
                            } - ${
                                elements.revendeur?.value?.designation || ''
                            }`}
                        />
                    </CustomCol>
                </CustomRow>
            )}
            <CustomRow>
                <CustomDropdown
                    options={monnaiesLocale}
                    label="Devise*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedDevise"
                    id="selectedDeviseCreationCrediterCompte"
                    defaultOption="Selectionner Devise"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={fields.isChange}>
                <CustomDropdown
                    options={monnaiesLocale}
                    label="Devise Sollicité*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedDeviseSollicite"
                    id="selectedDeviseSolliciteCreationCrediterCompte"
                    formState={formState}
                    defaultOption="Selectionner Devise Sollicitée"
                    idProperty="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    min={0}
                    placeholder="Saisissez le montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="montantCreationCrediterCompte"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
