export const schema = [
    {
        description: 'valeurTypePartenariat.designation',
        title: 'Marchand',
        className: 'border-bottom'
    },
    {
        description: 'valeurTypePartenariat.code',
        title: 'Code marchand',
        className: 'border-bottom'
    },
    {
        description: 'code',
        title: 'Code',
        className: 'border-bottom'
    },
    {
        description: 'designation',
        title: 'Désignation',
        className: 'border-bottom'
    },
    {
        description: 'prixVente',
        title: 'Prix de vente',
        className: 'border-bottom'
    },
    {
        description: 'prixAchat',
        title: "Prix d'achat",
        className: 'border-bottom'
    },
    {
        description: 'prixAchatSuperDealer',
        title: 'Prix super dealer',
        className: 'border-bottom'
    }
];
