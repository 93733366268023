import {
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

export function ListSuperDealerForm() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    /**
     * Common form processes
     */
    const { typeEntite } = useGetUserDetail({
        nappUser
    });
    const filterToConnect = () => {
        let filters = [];
        if (typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR) {
            filters = filters.concat([
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'typeOrganisation',
                    value: typeEntite,
                    isRequired: true
                },
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL,
                    property: 'valeurTypeOrganisation.reference',
                    value: nappUser?.valeurTypeEntite?.reference,
                    isRequired: true
                }
            ]);
        } else if (typeEntite === TYPE_ORGANISATION.MARCHAND) {
            filters = filters.concat([
                {
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.ARRAY_CONTAINS,
                    property: 'marchand.reference',
                    value: nappUser?.marchand?.reference,
                    isRequired: true
                }
            ]);
        }

        if (
            typeEntite === TYPE_ORGANISATION.MARCHAND ||
            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
        ) {
            filters = filters.concat([
                {
                    type: FILTER_TYPE.ORDER_BY,
                    property: 'dateDerniereModification',
                    value: 'asc'
                },
                {
                    type: FILTER_TYPE.START_AT,
                    value: TODAY.nowUtc(),
                    isTimestampNowValue: true
                }
            ]);
        }
        return filters;
    };

    const mapperBuilder = () => {
        if (typeEntite === TYPE_ORGANISATION.MARCHAND) {
            return [
                {
                    field: 'entreprise.designation',
                    displayName: 'designation'
                },
                {
                    field: 'entreprise.tillNumber',
                    displayName: 'tillnumber'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ];
        }
        if (typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR) {
            return [
                {
                    field: 'entreprise.designation',
                    displayName: 'designation'
                },
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ];
        }
        return [];
    };
    return (
        <ListGenericForm
            fetchType={FETCH_LISTENING}
            reduxPropertyName="supersDealer"
            functionName={URL_CONST.GET_LIST_SUPER_DEALER_MARCHAND_INITIALS}
            nodeName={DB_NODE.SUPER_DEALER}
            path={DB_NODE.SUPER_DEALER}
            listenerFilters={filterToConnect()}
            mapper={mapperBuilder()}
        />
    );
}
