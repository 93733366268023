import { DetailWrapper } from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailChangeGuichetForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'operationChangeGuichet' });
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_CHANGE_ESPECE_BY_ID}
            nodeName={REDUX_NODE_NAME.OPERATION_CHANGE_GUICHET}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Change detail"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        />
    );
    return renderContent();
}
