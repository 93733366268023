import {
    CODE_SERVICE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY
} from '@napp-inc/jnapp-util';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useHasService } from '../../../util';

export function ListVirementKinDBForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, firebaseUser, organisation } = useSelector(
        (reduxState) => ({
            nappUser: reduxState.nappUser,
            firebaseUser: reduxState.firebaseUser,
            organisation: reduxState.organisation
        })
    );
    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.FINANCE_ORGANISATION.ATTENTE_VALIDATION_VIREMENT_KINDB,
            CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_KINDB,
            CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB,
            CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_VIREMENT_KINDB
        ]
    });
    /**
     * Common form processes
     */
    const { isNapp, uid, oid } = useGetUserDetail({
        nappUser,
        firebaseUser,
        organisation
    });

    const filterToConnect = (service) => {
        let filters = [];
        if (
            service[
                CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB?.code
            ] ||
            service[
                CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_VIREMENT_KINDB?.code
            ]
        ) {
            if (!isNapp) {
                filters.push({
                    property: 'organisations',
                    value: [oid],
                    operator: FILTER_OPERATOR.ARRAY_CONTAINS_ANY,
                    type: FILTER_TYPE.WHERE
                });
            }

            // eslint-disable-next-line no-else-return
        } else if (
            (service[
                CODE_SERVICE.FINANCE_ORGANISATION
                    .ATTENTE_VALIDATION_VIREMENT_KINDB?.code
            ] ||
                service[
                    CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_KINDB
                        ?.code
                ]) &&
            !isNapp
        ) {
            filters.push({
                property: 'users',
                value: [uid],
                operator: FILTER_OPERATOR.ARRAY_CONTAINS_ANY,
                type: FILTER_TYPE.WHERE
            });
        }
        if (
            service[
                CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB?.code
            ] ||
            service[
                CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_VIREMENT_KINDB?.code
            ] ||
            service[
                CODE_SERVICE.FINANCE_ORGANISATION
                    .ATTENTE_VALIDATION_VIREMENT_KINDB?.code
            ] ||
            service[
                CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_KINDB?.code
            ]
        ) {
            filters = filters.concat([
                {
                    property: 'dateDerniereModification',
                    value: 'asc',
                    type: FILTER_TYPE.ORDER_BY
                },
                {
                    type: FILTER_TYPE.START_AT,
                    value: TODAY.nowUtc()
                }
            ]);
        }
        return filters;
    };
    return (
        <ListGenericForm
            reduxPropertyName="virementsKindb"
            functionName={URL_CONST.GET_LIST_OPERATION_VIREMENT_KINDB_INITIALS}
            nodeName={REDUX_NODE_NAME.VIREMENTS_KINDB}
            path={DB_NODE.OPERATION_VIREMENT_KINDB}
            fetchType={FETCH_LISTENING}
            listenerFilters={filterToConnect(hasService)}
            mapper={[
                {
                    displayName: 'Banque',
                    field: 'institutionFinanciere.designation'
                },
                {
                    displayName: 'SuperDealer',
                    field: 'valeurTypeEntite.designation'
                },
                {
                    displayName: 'Destinataire',
                    field: 'partenaire.designation'
                },
                {
                    field: 'type',
                    displayName: 'Opération'
                },
                {
                    field: 'montant',
                    displayName: 'Montant'
                },
                {
                    field: 'compte.devise',
                    displayName: 'Devise'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ]}
        />
    );
}
