import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    ETAT,
    FORM_CONST,
    percuAndRetournedMontantDevise
} from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { schema } from './detail-fields';
import { DynamicMonoSelectAndInputSecond } from '../../../components/special-components/dynamic-mono-select-and-input-second';

const defaultFields = {
    actionEncours: '',
    selectedCoupureBilletage: {},
    nombreCoupureBilletage: 0,
    listBilletage: [],
    deviseRetourSelect: {},
    deviseRetourInput: 0,
    devisePercuSelect: {},
    devisePercuInput: 0,
    montantPercu: [],
    montantRetour: []
};

const objectKeysPercu = {
    devise: 'devisePercuSelect.code',
    montant: 'devisePercuInput'
};

const objectKeysRetour = {
    devise: 'deviseRetourSelect.code',
    montant: 'deviseRetourInput'
};

/**
 * @description defaultDeps de notre state
 */
const defaultDependencies = { fields: ['monnaiesLocale'] };

export function DetailDepotEmoneyForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        reduxProps
    } = useGetDetailForm({
        nodeName: 'depotEmoney',
        defaultFields,
        defaultDependencies,
        listNode: [
            {
                name: 'monnaiesLocale',
                format: (list) =>
                    list.map((item) => ({
                        ...item,
                        designation: item.devise,
                        code: item.devise
                    }))
            }
        ],
        listFunctions: [
            {
                id: 'monnaiesLocale',
                isOnlyFetch: true,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE
            }
        ]
    });

    const { fields, form } = formState;

    const demanderAnnulation = () => {
        const payload = {
            typeEntite: 'CommandeEmoney',
            valeurTypeEntite: entity?.id,
            montantsDevise: percuAndRetournedMontantDevise(
                fields.montantPercu,
                fields.montantRetour
            )
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.POST_DEMANDE_ANNULATION,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.DEPOT_EMONEY,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_COMMANDE_EMONEY_BY_ID}
            nodeName={REDUX_NODE_NAME.DEPOT_EMONEY}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Depot Emoney"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <CustomRow>
                <CustomCol>
                    <div className="shadow card">
                        <div className="card-header">
                            <p className="fw-normal mb-0">Montant perçu</p>
                        </div>
                        <div className="card-body">
                            <CustomRow>
                                <DynamicMonoSelectAndInputSecond
                                    options={reduxProps?.monnaiesLocale || []}
                                    id="deviseMontantPercu"
                                    name="devisePercu"
                                    defaultOption="Selectionner devise"
                                    formState={formState}
                                    formDispatcher={formDispatcher}
                                    type="number"
                                    identifier="devise"
                                    placeholder="montant"
                                    field="montantPercu"
                                    objectKeys={objectKeysPercu}
                                    // uiValidator={uiValidator}
                                />
                            </CustomRow>
                        </div>
                    </div>
                </CustomCol>
                <CustomCol>
                    <div className="shadow card">
                        <div className="card-header">
                            <p className="fw-normal mb-0">Montant retourné</p>
                        </div>
                        <div className="card-body">
                            <CustomRow>
                                <DynamicMonoSelectAndInputSecond
                                    options={reduxProps?.monnaiesLocale || []}
                                    id="deviseMontantRetour"
                                    name="deviseRetour"
                                    defaultOption="Selectionner devise"
                                    formState={formState}
                                    formDispatcher={formDispatcher}
                                    type="number"
                                    identifier="devise"
                                    placeholder="montant"
                                    field="montantRetour"
                                    objectKeys={objectKeysRetour}
                                    // uiValidator={uiValidator}
                                />
                            </CustomRow>
                        </div>
                    </div>
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="col-md-2">
                    <CustomConfirmButton
                        type="button"
                        text="Demander annulation"
                        className="btn-secondary"
                        confirmOnClick={() => demanderAnnulation()}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS &&
                            fields.actionEncours !== ETAT.ETAT_ANNULER
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation Annulation"
                    />
                </CustomCol>
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
