import { useState } from 'react';
import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import {
    ConditionalRenderingWrapper,
    CustomButton,
    // CustomButton,
    CustomButtonLoader,
    CustomCenteredModal,
    CustomCol,
    // CustomConfirmButton,
    // CustomInput,
    CustomRow,
    CustomTextAreaInput,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailFormulaireDynamiqueSimFlashForm() {
    const [show, setShow] = useState(false);
    const onHide = () => setShow(false);
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            defaultFields: { libelle: '', actionEncours: '' },
            nodeName: 'formulaireDynamiqueSimFlash'
        });
    const { fields, form } = formState;
    const onSuccess = (data) => {
        const items = data?.element || data?.elements || [];
        // reduxDispatcher(ajouterItems(items, nodeName));
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                items,
                REDUX_NODE_NAME.FORMULAIRE_DYNAMIQUE_SIM_FLASH
            )
        );
    };
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payload = { etat, list: [entity?.id] };

        if (etat === ETAT.ETAT_ANNULER && !fields.libelle) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Raison obligatoire !',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        if (etat === ETAT.ETAT_ANNULER && !!fields.libelle) {
            payload.libelle = fields.libelle;
        }

        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_FORMULAIRE_DYNAMIQUE,
                payload,
                reduxNodeName: REDUX_NODE_NAME.FORMULAIRE_DYNAMIQUE_SIM_FLASH,
                reduxDispatcher,
                onSuccess
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_FORMULAIRE_DYNAMIQUE_BY_ID}
            nodeName={REDUX_NODE_NAME.FORMULAIRE_DYNAMIQUE_SIM_FLASH}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Creation sim flash"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_VENTE
                        .VALIDER_CONFIGURATION_SIM_MARCHAND
                ]}
            >
                <CustomCenteredModal
                    header="Annulation Création Sim"
                    show={show}
                    onHide={onHide}
                >
                    <CustomRow>
                        <CustomTextAreaInput
                            rows={3}
                            required
                            label="Raison* : "
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-10 col-sm-10"
                            isInputGroup={false}
                            placeholder="Raison de l'annulation"
                            formDispatcher={formDispatcher}
                            name="libelle"
                            id="libelleDemandeCreationSimId"
                            formState={formState}
                        />
                    </CustomRow>
                    <CustomButtonLoader
                        type="button"
                        text="Confirmer annulation"
                        className="btn-danger"
                        onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_ANNULER
                        }
                    />
                </CustomCenteredModal>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={!!entity?.datas?.length}
                >
                    <SimpleTable
                        // identifier="id"
                        // formDispatcher={formDispatcher}
                        data={entity?.datas || []}
                        isShowDropButton={false}
                        // listSelectedItemName="list"
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                displayName: 'Label',
                                field: 'displayName'
                            },
                            {
                                displayName: 'Valeur',
                                field: 'value'
                            }
                        ]}
                    />
                </ConditionalRenderingWrapper>
                <CustomRow
                    isShouldBeRendered={entity?.etat !== ETAT.ETAT_VALIDER}
                >
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            onClick={() => setShow(true)}
                            // disabled={
                            //     form.status === FORM_CONST.MODIFICATION_EN_COURS
                            // }
                            // isActionEncours={
                            //     fields.actionEncours === ETAT.ETAT_ANNULER
                            // }
                            // confirmText="Poursuivre"
                            // cancelText="Abandonner"
                            // header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={!!entity?.estEtatIntermediaire}
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                text="Valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            !entity?.estEtatIntermediaire ||
                            (!!entity?.estEtatIntermediaire &&
                                entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION)
                        }
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                                text="Valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
