import { resolvePathPropertyOfObject } from '@napp-inc/jnapp-util';
import { useEffect, useState } from 'react';
import { fuzzySearch } from '../fuzzy-search';

/**
 * Ce hook est utile pour filter les éléments d'une liste, il utilise fuzzy search en filtrant les champs "fields" renseignés dans le mapper d'une generic list
 * @param {Object} param0 Objet
 * @param {Array} param0.dataList list des éléments à filtrer
 * @param {String} param0.search text à rechercher dans la liste
 * @param {Array} param0.mapper mapper de la generic list
 * @param {Array} param0.searchFiter tableau filtrer personnliser au cas où mapper serait insuffisa,t
 * @returns {Array} retourne le tableau des éléments filtrés
 */
export const useSearchFilter = ({
    dataList,
    search,
    searchFilter,
    mapper = []
}) => {
    const [filtered, setFiltered] = useState([]);
    useEffect(() => {
        if (search) {
            const filterToUse = searchFilter || mapper;
            const keys = filterToUse.map((item) => item.field);
            keys.push('fuzzySearchAll');
            const array = dataList.map((item) => {
                let fuzzySearchAll = '';
                keys.forEach((key) => {
                    fuzzySearchAll +=
                        resolvePathPropertyOfObject(key, item) || '';
                });
                return {
                    ...item,
                    fuzzySearchAll
                };
            });
            const temp = fuzzySearch({ array, keys, word: search });
            setFiltered(temp);
        }
    }, [dataList, search, searchFilter, mapper]);
    return search ? filtered : dataList;
};
