import {
    useFormGlobalReducer,
    submitPutAction,
    useGetEntity,
    useGetUserDetail,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import { ETAT, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CenteredSpinner,
    CustomAlert,
    CustomCol,
    CustomConfirmButton,
    CustomForm,
    CustomRow,
    EtatDisplayer
} from '../../../components';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    actionEncours: ''
};

/**
 * @description defaultDependencies de notre state
 */
const defaultDependencies = {
    entity: true
};

export function DetailArticleColis() {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    const { firebaseUser, entity } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        entity: reduxState.articlesColis.find((element) => element.id === id)
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, dependencies } = formState;

    useGetEntity({
        entity,
        etat: dependencies.entity.status,
        formDispatcher,
        // functionName: URL_CONST.GET_LIST_SALLE_MANGER_BY_ID,
        id,
        idToken,
        // nodeName: DB_NODE.SALLE_MANGER_TABLE,
        reduxDispatcher
    });

    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        dependencies
    });

    const updateEtat = (etat) => {
        const payload = {
            etat,
            creator,
            idSalleMangerTable: entity?.id
        };
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                // functionName: URL_CONST.PUT_SALLE_MANGER,
                payload,
                fields,
                // reduxNodeName: DB_NODE.SALLE_MANGER_TABLE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => {
        if (!entity?.id && !isLoadFailed && !isLoadComplete) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return <CustomAlert error={form?.error} />;
        }
        return (
            <>
                <CustomAlert error={form?.error} />
                <CustomForm>
                    <CustomRow>
                        <h1 className="lead">Article Colis</h1>
                    </CustomRow>
                    <CustomRow>date : {entity?.dateCreation}</CustomRow>
                    <CustomRow>
                        <CustomCol className="col-3">
                            <EtatDisplayer etat={entity?.etat} />
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                </CustomForm>
            </>
        );
    };
    return renderContent();
}
