import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    utc000000,
    utc235959
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomInputDate,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { CreationExerciceSchema as uiValidator } from './validation';

/**
 * @description defaultFields de notre state
 */
const defaultFields = {
    dateDebut: '',
    dateFin: ''
};

export function CreationExerciceFinancierForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * Fonction pour poster le parametre d'activer ou de désactiver
     * @param {Event} event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            creator,
            dateDebut: utc000000(fields.dateDebut),
            dateFin: utc235959(fields.dateFin, false)
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_EXERCICE,
                payload,
                fields,
                uiValidator,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInputDate
                    id="creationExerciceFinancierBudgetDebutId"
                    name="dateDebut"
                    type="date"
                    label="Debut Exercice*"
                    isInputGroup={false}
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Debut..."
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomInputDate
                    id="creationExerciceFinancierBudgetFinId"
                    name="dateFin"
                    type="date"
                    isInputGroup={false}
                    label="Fin Exercice*"
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Fin..."
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer exercice"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
