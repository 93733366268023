import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    ETAT,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailTauxValidationForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        creator,
        formState,
        formDispatcher
    } = useGetDetailForm({ nodeName: 'tauxValidation' });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const payload = {
            etat,
            creator,
            idTaux: entity?.id
        };
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_TAUX,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TAUX_VALIDATION,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TAUX_BY_ID}
            nodeName={REDUX_NODE_NAME.TAUX_VALIDATION}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Taux"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_ADMINISTRATION
                        .ANNULER_TAUX_MONNAIE_LOCALE,
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_ADMINISTRATION
                        .VALIDER_TAUX_MONNAIE_LOCALE
                ]}
            >
                <CustomRow>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                    >
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
