import { getDownloadURL } from 'firebase/storage';

export const uploadTaskPromise = async (uploadTask) =>
    new Promise((resolve) => {
        uploadTask.on('state_changed', {
            complete: async () => {
                const downloadURL = await getDownloadURL(
                    uploadTask.snapshot.ref
                );
                resolve(downloadURL);
            }
        });
    });
