import React, { useEffect, useState } from 'react';
import {
    CustomCheckButton,
    CustomRadioButton,
    CustomSwitchButton
} from '../button';
import { CustomInputDate } from '../calendrier';
import { CustomRow } from '../container';
import { CustomDropdown } from '../dropdown';
import { CustomInput, CustomInputFile, CustomTextAreaInput } from '../input';
import { CustomLabel } from '../label';

export function DynamicFormMounted({
    formDispatcher = {},
    formState = {},
    uiValidator = {},
    fieldsMapper = [],
    LoadedFieldsMapper = []
}) {
    const [fields, setFields] = useState([]);
    useEffect(() => {
        const funcArrayFusion = (
            noneLoaded = fieldsMapper,
            loaded = LoadedFieldsMapper
        ) => {
            if (!noneLoaded.length && !loaded.length) return [];
            if (!noneLoaded.length && loaded.length) return loaded;
            if (noneLoaded.length && !loaded.length) return noneLoaded;
            return [...noneLoaded, ...loaded];
        };
        const fieldsArray = funcArrayFusion();
        setFields(fieldsArray);
    }, [LoadedFieldsMapper, fieldsMapper]);
    return (
        <>
            {fields
                .sort((a, b) => a.position - b.position)
                .map((field) => {
                    switch (field.type) {
                        case 'text':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomInput
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                        name={field.name}
                                        id={field.id}
                                        placeholder={field.placeholder}
                                        label={field.label}
                                        type={field.type}
                                        disabled={field.disabled}
                                        readOnly={field.readOnly}
                                        isInputGroup={field.isInputGroup}
                                        min={field.min}
                                        max={field.max}
                                        labelClassName={field.labelClassName}
                                        divClassName={field.divClassName}
                                    />
                                </CustomRow>
                            );
                        case 'email':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomInput
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                        name={field.name}
                                        id={field.id}
                                        placeholder={field.placeholder}
                                        label={field.label}
                                        type={field.type}
                                        disabled={field.disabled}
                                        readOnly={field.readOnly}
                                        isInputGroup={field.isInputGroup}
                                        labelClassName={field.labelClassName}
                                        divClassName={field.divClassName}
                                    />
                                </CustomRow>
                            );
                        case 'textarea':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomTextAreaInput
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                        name={field.name}
                                        id={field.id}
                                        placeholder={field.placeholder}
                                        label={field.label}
                                        type={field.type}
                                        disabled={field.disabled}
                                        readOnly={field.readOnly}
                                        rows={field.rows}
                                        isInputGroup={field.isInputGroup}
                                        labelClassName={field.labelClassName}
                                        divClassName={field.divClassName}
                                    />
                                </CustomRow>
                            );
                        case 'number':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomInput
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                        name={field.name}
                                        id={field.id}
                                        placeholder={field.placeholder}
                                        label={field.label}
                                        type={field.type}
                                        isInputGroup={field.isInputGroup}
                                        isFloat={field.isFloat}
                                        disabled={field.disabled}
                                        readOnly={field.readOnly}
                                        labelClassName={field.labelClassName}
                                        divClassName={field.divClassName}
                                    />
                                </CustomRow>
                            );
                        case 'date':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomInputDate
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                        name={field.name}
                                        id={field.id}
                                        placeholder={field.placeholder}
                                        label={field.label}
                                        type={field.type}
                                        isInputGroup={field.isInputGroup}
                                        disabled={field.disabled}
                                        readOnly={field.readOnly}
                                        divClassName={field.divClassName}
                                        inputClassName={field.inputClassName}
                                        labelClassName={field.labelClassName}
                                    />
                                </CustomRow>
                            );
                        case 'dropdown':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomDropdown
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                        name={field.name}
                                        id={field.id}
                                        defaultOption={field.defaultOption}
                                        options={field.options}
                                        label={field.label}
                                        type={field.type}
                                        idProperty={field.idProperty}
                                        propertyToDisplay={
                                            field.propertyToDisplay
                                        }
                                        resetOnChangeState={
                                            field.resetOnChangeState
                                        }
                                        newState={field.newState}
                                        selectionClassName={
                                            field.selectionClassName
                                        }
                                        labelClassName={field.labelClassName}
                                        divClassName={field.divClassName}
                                    />
                                </CustomRow>
                            );
                        case 'checkbox':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomCheckButton
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                        name={field.name}
                                        id={field.id}
                                        label={field.label}
                                        labelClassName={field.labelClassName}
                                        inputClassName={field.inputClassName}
                                    />
                                </CustomRow>
                            );
                        case 'file':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomInputFile
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                        name={field.name}
                                        id={field.id}
                                        label={field.label}
                                        accepter={field.accept}
                                        multiple={field.multiple}
                                        isImage={field.isImage}
                                        labelClassName={field.labelClassName}
                                        divClassName={field.divClassName}
                                    />
                                </CustomRow>
                            );
                        case 'switch':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomSwitchButton
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                        name={field.name}
                                        id={field.id}
                                        text={field.text}
                                    />
                                </CustomRow>
                            );
                        case 'radio':
                            return (
                                <CustomRow
                                    key={field.id}
                                    isShouldBeRendered={
                                        field.isShouldBeRendered
                                    }
                                >
                                    <CustomLabel
                                        text={field.label}
                                        className={field.labelClassName}
                                    />
                                    {field.radios.map((radio) => (
                                        <CustomRadioButton
                                            key={radio.id}
                                            divClassName={radio.divClassName}
                                            label={radio.label}
                                            id={radio.id}
                                            name={field.name}
                                            value={radio.value}
                                            formState={formState}
                                            formDispatcher={formDispatcher}
                                            uiValidator={uiValidator}
                                        />
                                    ))}
                                </CustomRow>
                            );
                        default:
                            return null;
                    }
                })}
        </>
    );
}
