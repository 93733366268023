export const schema = [
    {
        title: 'Code Revendeur',
        description: 'revendeur.code',
        className: 'border-bottom'
    },
    {
        title: 'Designation Revendeur',
        description: 'revendeur.designation',
        className: 'border-bottom'
    },
    {
        title: 'Zone',
        description: 'zone.designation',
        className: 'border-bottom'
    },
    {
        title: 'Date Execution',
        description: 'dateExecution',
        className: 'border-bottom',
        isDate: true
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
