import {
    fetchElement,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    URL_CONST,
    fromTimestampToString
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButton,
    CustomCol,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    valeurTypeEntite: '',
    selectedTypeEntite: {}
};

export function ListMouvementOperation() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, collections, etatCollection } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            collections: reduxState.collections.map((item) => ({
                ...item,
                designation: item.id,
                code: item.id
            })),
            etatCollection: reduxState.etat.collections.etat
        })
    );
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_COLLECTION,
                nodeName: REDUX_NODE_NAME.COLLECTION,
                etat: etatCollection
            }
        ]
    });
    const mouvementsFormater = (listMouvement) =>
        listMouvement
            .map((item) => ({
                ...item,
                dateToDisplay: fromTimestampToString(item.dateCreation)
            }))
            .sort((a, b) => b.dateCreation - a.dateCreation);

    const fetchMouvements = () => {
        const { valeurTypeEntite, selectedTypeEntite } = fields;

        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload: {
                    valeurTypeEntite,
                    typeEntite: selectedTypeEntite?.code
                },
                functionName: URL_CONST.GET_LIST_MOUVEMENT_OPERATION,
                etat: elements.mouvements.status,
                formDispatcher,
                formater: mouvementsFormater,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <div className="container-fluid">
            <CustomAlert error={form?.error} />
            <CustomForm>
                <CustomRow>
                    <CustomInput
                        type="text"
                        divClassName="col-2 col-sm-2"
                        isInputGroup={false}
                        placeholder="valeurTypeEntite"
                        formDispatcher={formDispatcher}
                        name="valeurTypeEntite"
                        id="valeurTypeEntiteCreationCrediterCompte"
                        formState={formState}
                    />
                    <CustomDropdown
                        options={collections}
                        divClassName="col-2 col-sm-2"
                        formDispatcher={formDispatcher}
                        name="selectedTypeEntite"
                        id="selectedTypeEntiteListMouvement"
                        formState={formState}
                        propertyToDisplay="designation"
                        idProperty="code"
                    />
                    <CustomCol className="col-2">
                        <CustomButton
                            disabled={
                                elements.mouvements.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            onClick={fetchMouvements}
                        >
                            Rechercher
                        </CustomButton>
                    </CustomCol>
                </CustomRow>
            </CustomForm>
            <hr />
            <SimpleTable
                isShowDropButton={false}
                data={elements.mouvements?.value || []}
                identifier="id"
                tableClass="table table-hover shadow table-bordered table-responsive-sm"
                filter={false}
                bottomPagination={false}
                isLinkToDetail={false}
                mapper={[
                    {
                        field: 'id',
                        displayName: 'id',
                        isKeepFormat: true,
                        position: 1
                    },
                    {
                        field: 'libelle',
                        displayName: 'libellé',
                        position: 2,
                        dataExtractor: (item) =>
                            item?.libelle || item?.typeEntite
                    },
                    {
                        field: 'soldePrecedent',
                        displayName: 'solde ouverture',
                        position: 3
                    },
                    {
                        field: 'montant',
                        displayName: 'montant',
                        position: 4
                    },
                    {
                        field: 'soldeActuel',
                        displayName: 'solde fermeture',
                        position: 5
                    },
                    {
                        field: 'typeOperation',
                        displayName: 'type opération',
                        position: 6
                    },
                    {
                        field: 'typeProprietaire',
                        displayName: 'type proprietaire',
                        isKeepFormat: true,
                        position: 7
                    },
                    {
                        field: 'valeurTypeProprietaire',
                        displayName: 'valeur type proprietaire',
                        isKeepFormat: true,
                        position: 8
                    },
                    {
                        field: 'dateToDisplay',
                        displayName: 'date création',
                        position: 9
                    }
                ]}
            />
        </div>
    );
    return renderContent();
}
