import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import { ConfigurationPath } from '../../../routes/registration';
import { ListLimitCreditForm } from './list-limit-credit';

export function LimitCreditViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Limite de crédit</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ConfigurationPath.LimitCreditViewLayoutPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                        .CODE_SERVICE_MODULE_FINANCE
                                        .INITIER_OCTROI_LIMIT_CREDIT
                                ]}
                                destination={
                                    ConfigurationPath.LimitCreditCreatePath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListLimitCreditForm />
        </div>
    );
}
