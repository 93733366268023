import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../../components';
import {
    ApprovisionnementSimCreateRelPath,
    ApprovisionnementSimViewLayoutRelPath
} from '../../../../routes/registration/stock/stock-rel-path';
import { ListApprovisionnementSimForm } from './list-approvisionnement-sim';

export function ApprovisionnementSimViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Approvisionnement Sim</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ApprovisionnementSimViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                        .CODE_SERVICE_MODULE_VENTE
                                        .INITIER_APPRO_SIM_ACHAT
                                ]}
                                destination={
                                    ApprovisionnementSimCreateRelPath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListApprovisionnementSimForm />
        </div>
    );
}
