import {
    useFormGlobalReducer,
    useGetUserDetail,
    submitPutAction,
    useGetEntity,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    URL_CONST,
    ETAT,
    formInitialState,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CenteredSpinner,
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomForm,
    CustomRow,
    EtatDisplayer
} from '../../../components';
import { ViewLayout } from './view-layout';

export function DetailMouvement() {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    const { firebaseUser, entity } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        entity: reduxState.traitementsModeRetracage.find(
            (element) => element.id === id
        )
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: { actionEncours: '' },
        dependencies: { entity: true }
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, dependencies } = formState;
    useGetEntity({
        entity,
        etat: dependencies.entity.status,
        formDispatcher,
        functionName: URL_CONST.GET_TRAITEMENT_MODE_RETRACAGE_BY_ID,
        id,
        idToken,
        nodeName: DB_NODE.TRAITEMENT_MODE_RETRACAGE,
        reduxDispatcher
    });
    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        dependencies
    });
    const updateEtat = (etat) => {
        const payload = {
            etat,
            creator,
            idTraitementModeRetracage: entity?.id
        };
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_TRAITEMENT_MODE_RETRACAGE,
                payload,
                fields,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => {
        if (!entity?.id && !isLoadFailed && !isLoadComplete) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return <CustomAlert error={form?.error} />;
        }
        return (
            <ViewLayout>
                <CustomAlert error={form?.error} />
                <CustomForm>
                    <CustomRow>
                        <h1 className="lead">Traitement Mode Retracage</h1>
                    </CustomRow>
                    <CustomRow>date : {entity?.dateCreation}</CustomRow>
                    <CustomRow>Type : {entity?.typeEntite}</CustomRow>
                    <CustomRow>
                        Valeur : {entity?.valeurTypeEntite?.designation}
                    </CustomRow>
                    <CustomRow>
                        Moyen de Retracage : {entity?.modeRetracage}
                    </CustomRow>
                    <CustomRow>
                        <CustomCol className="col-3">
                            <EtatDisplayer etat={entity?.etat} />
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomButtonLoader
                                className="btn-danger"
                                onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                                text="Annuler"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                            />
                        </CustomCol>
                        <CustomCol className="col-3 col-md-2">
                            <CustomButtonLoader
                                className="btn-success"
                                onClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                                text="valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                </CustomForm>
            </ViewLayout>
        );
    };
    return renderContent();
}
