export const schema = [
    {
        description: 'designationAgent',
        title: 'Agent',
        className: 'border-bottom'
    },
    {
        description: 'designationType',
        title: 'Type',
        className: 'border-bottom'
    },
    {
        description: 'urlImage',
        title: 'Url',
        className: 'border-bottom'
    },
    {
        description: 'titre',
        title: 'Titre',
        className: 'border-bottom'
    },
    {
        description: 'description',
        title: 'Description',
        className: 'border-bottom'
    },
    {
        description: 'texteTraite',
        title: 'Correction',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'Etat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
