import {
    fetchElement,
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    COMPTE_PARALLELE_MOBILE_MONEY,
    formInitialState,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAlert,
    CustomButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
// import { CompteExterneMobileMoneySchema as uiValidator } from './validation';

const defaultFields = {
    intituleCompte: '',
    numeroCompte: '',
    numeroComptePaiement: '',
    selectedPays: {},
    selectedTypeEmoney: {},
    selectedBanque: {},
    nom: '',
    postnom: '',
    prenom: '',
    email: '',
    numeroTel: '',
    tillNumber: '',
    estType: false
};

// FIXME: le schema de validation
export function CreationCompteMobilMoneyForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['marchand']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    const fetchMarchand = () => {
        const { tillNumber } = fields;
        if (tillNumber && tillNumber !== elements.marchand?.value?.tillNumber) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'marchand',
                    payload: { tillNumber },
                    functionName:
                        URL_CONST.GET_MARCHAND_BY_TILLNUMBER_AND_CODE_CATEGORIE_MARCHAND,
                    etat: elements.marchand.status,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };

    const { marchand } = elements;
    const handleSubmit = (e) => {
        e.preventDefault();
        const {
            nom,
            postnom,
            prenom,
            email,
            intituleCompte,
            numeroCompte,
            numeroComptePaiement
        } = fields;

        const payloadToAdd = {};
        if (fields.selectedTypeEmoney.code) {
            payloadToAdd.typeEntite = fields.selectedTypeEmoney.code;
        }
        const payload = {
            newCompte: {
                libelle: intituleCompte,
                numero: numeroCompte,
                typeEntite: COMPTE_PARALLELE_MOBILE_MONEY.code,
                valeurTypeMarchand: {
                    code: marchand.value.code
                },
                numeroCompteExterne: numeroComptePaiement,
                ...payloadToAdd
                /**
                 * @description à compléter
                 */
                // typeEmoney : '2034 || 5184', This property will be available only if Marchand estTypeEmoney === true
            },
            gestionnaire: {
                nom,
                prenom,
                postnom,
                email
            },
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_COMPTE_DECLARATION,
                reduxNodeName: REDUX_NODE_NAME.COMPTE_MOBILE_MONEY,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Intitulé*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez l'intitulé du compte"
                    formDispatcher={formDispatcher}
                    name="intituleCompte"
                    id="intituleCompteId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Numéro du compte*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Numéro à la base de la ressource"
                    formDispatcher={formDispatcher}
                    name="numeroCompte"
                    id="numeroCompteId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            {/* Recupérer le marchandMobileMoney */}
            <CustomRow>
                <CustomInput
                    type="text"
                    label="Marchand Emoney*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-10 col-sm-8"
                    isInputGroup={false}
                    placeholder="Saisissez le tillNumber Marchand emoney"
                    formDispatcher={formDispatcher}
                    name="tillNumber"
                    id="tillNumberCreationTarifRessource"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
                <CustomCol className="col-2">
                    <CustomButton
                        disabled={
                            elements.marchand.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        onClick={fetchMarchand}
                    >
                        Rechercher
                    </CustomButton>
                </CustomCol>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    {elements.marchand.status ===
                        FORM_CONST.FETCH_ELEMENT_FAILED && (
                        <CustomAlert
                            error={`${elements.marchand?.error || ''}`}
                        />
                    )}
                    {elements.marchand.status ===
                        FORM_CONST.FETCH_ELEMENT_SUCCESS && (
                        <CustomAlert
                            successMessage={`${
                                elements.marchand?.value?.tillNumber || ''
                            } - ${
                                elements.marchand?.value?.designation ||
                                'Aucun marchand'
                            }`}
                        />
                    )}
                </CustomCol>
            </CustomRow>
            {marchand?.value?.estTypeEmoney ? (
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { code: '2034', designation: '2034' },
                            { code: '5184', designation: '5184' }
                        ]}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        label="Type Emoney"
                        defaultOption="Sélectionner le type Money"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedTypeEmoney"
                        id="selectedTypeEmoneyId"
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            ) : null}
            <CustomRow>
                <CustomInput
                    type="text"
                    isInputGroup={false}
                    label="Numéro Mobile Money*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Numéro par lequel le paiement sera effectué"
                    formDispatcher={formDispatcher}
                    name="numeroComptePaiement"
                    id="numeroComptePaiementId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="text"
                    isInputGroup={false}
                    label="Nom*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Nom du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="nom"
                    id="nomGestionnaireId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Postnom*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Postnom du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="postnom"
                    id="postnomGestionnaireId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Prénom*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Prénom du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="prenom"
                    id="prenomGestionnaireId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Email*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Email du gestionnaire"
                    formDispatcher={formDispatcher}
                    name="email"
                    type="email"
                    id="emailGestionnaireId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Tel*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="(+243) 82 865 99 45"
                    formDispatcher={formDispatcher}
                    name="numeroTel"
                    id="numeroTelGestionnaireId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
