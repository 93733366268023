import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    fromStringToTimestamp
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    CustomSwitchButton,
    DynamicFormMounted,
    FormWrapper,
    SimpleTable
} from '../../../../components';
import {
    URL_CONST,
    formatCodeDesignation,
    getAvatarInitials
} from '../../../../util';
import { mappePersonneMorale, mapper as fieldsMapper } from '../form-fields';

export function CreationPartenaire({
    idToken,
    // typeEntite,
    uiValidator,
    initialState
}) {
    const reduxDispatcher = useDispatch();
    const {
        institutionsFinanciere,
        etatInstitutionFinanciere,
        etatMonnaieLocale,
        monnaiesLocale
    } = useSelector((reduxState) => ({
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionFinanciere: reduxState.etat.institutionsFinanciere.etat,
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat,
        monnaiesLocale: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            code: item.code || item.devise
        }))
    }));

    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionFinanciere
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            }
        ]
    });
    const addCompte = () => {
        const { selectedInstitutionFinanciere, selectedDevise, numero } =
            fields;
        if (
            !numero ||
            !selectedInstitutionFinanciere.code ||
            !selectedDevise.code
        )
            return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listCompte',
                identifier: 'id',
                data: {
                    id: `${selectedDevise.code}${selectedInstitutionFinanciere.code}${numero}`,
                    numero,
                    institutionFinanciere: selectedInstitutionFinanciere,
                    devise: selectedDevise.code
                }
            }
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {
            designation,
            numeroTelEntreprise,
            nom,
            postnom,
            prenom,
            numeroTelGestionnaire,
            listCompte
        } = fields;

        const payload = {};
        if (fields.estBanque && !listCompte.length) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        'Vérifiez les informations des institutions financières',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        if (fields.selectedTypePartenaire?.code === 'personnePhysique') {
            payload.newPartenaire = {
                code: formatCodeDesignation({
                    partToAdd: `NAPP_PARTENAIRE_`,
                    designation: `${prenom}${nom}`
                }),
                sigle: getAvatarInitials({
                    firstName: prenom,
                    lastName: nom
                }),
                designation: `${prenom} ${nom}`,
                nom,
                postnom,
                prenom,
                numeroTel: numeroTelEntreprise,
                email: fields.email,
                adresse: fields.adresse
            };
        } else {
            payload.newPartenaire = {
                code: formatCodeDesignation({
                    partToAdd: `NAPP_CAISSE_${fromStringToTimestamp(
                        new Date()
                    )}_`,
                    designation
                }),
                sigle: fields.codeEntreprise,
                designation,
                numeroTel: numeroTelEntreprise,
                adresse: fields.adresse
            };
        }
        if (listCompte.length && !!fields.estBanque) {
            payload.comptes = listCompte.map((item) => ({
                institutionFinanciere: item.institutionFinanciere,
                numero: item.numero,
                devise: item.devise
            }));
        }
        payload.newPersonne = {
            nom,
            prenom,
            postnom,
            numeroTel: numeroTelGestionnaire,
            email: fields.email,
            adresse: fields.adresse
        };

        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_PARTENAIRE,
                payload,
                fields,
                reduxNodeName: DB_NODE.PARTENAIRE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'devises',
                    etat: etatMonnaieLocale
                },
                {
                    dependency: 'banques',
                    etat: etatInstitutionFinanciere
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={[
                        {
                            code: 'personnePhysique',
                            designation: 'Personne Physique'
                        },
                        {
                            code: 'personneMorale',
                            designation: 'Personne Morale'
                        }
                    ]}
                    label="Type Partenaire"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    defaultOption="Sélectionnez Type Partenaire"
                    name="selectedTypePartenaire"
                    id="selectedTypePartenaireCreationPartenaireId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <DynamicFormMounted
                formDispatcher={formDispatcher}
                formState={formState}
                uiValidator={uiValidator}
                fieldsMapper={
                    fields?.selectedTypePartenaire?.code === 'personneMorale'
                        ? mappePersonneMorale
                        : fieldsMapper
                }
            />
            <CustomRow>
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="estBanqueId"
                    text="Renseigner les informations bancaires?"
                    value={fields.estBanque}
                    name="estBanque"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!!fields.estBanque}>
                <CustomCol className="col-2">
                    <CustomLabel>Info Bancaire*</CustomLabel>
                </CustomCol>
                <CustomCol className="col-10">
                    <CustomRow>
                        <CustomDropdown
                            options={institutionsFinanciere}
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            defaultOption="Sélectionnez Institut financière"
                            name="selectedInstitutionFinanciere"
                            id="selectedInstitutionFinanciereCreationPartenaire"
                            formState={formState}
                            uiValidator={uiValidator}
                            propertyToDisplay="designation"
                            idProperty="code"
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            options={monnaiesLocale}
                            divClassName="col-12 col-sm-10"
                            formDispatcher={formDispatcher}
                            name="selectedDevise"
                            id="selectedDeviseCreationPartenaire"
                            defaultOption="Sélectionnez Devise"
                            formState={formState}
                            uiValidator={uiValidator}
                            propertyToDisplay="code"
                            idProperty="id"
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomInput
                            type="text"
                            isInputGroup={false}
                            divClassName="col-10 col-md-8"
                            placeholder="Saisissez le numéro de compte"
                            uiValidator={uiValidator}
                            formDispatcher={formDispatcher}
                            name="numero"
                            id="numeroCompteCreationPartenaire"
                            formState={formState}
                        />
                        <CustomCol className="col-2">
                            <CustomAddRemoveButton callback={addCompte} />
                        </CustomCol>
                    </CustomRow>
                </CustomCol>
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.estBanque && fields.listCompte.length
                }
            >
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    <SimpleTable
                        data={fields.listCompte}
                        listSelectedItemName="listCompte"
                        identifier="id"
                        formDispatcher={formDispatcher}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                displayName: 'Instituion',
                                field: 'institutionFinanciere.designation',
                                position: 1
                            },
                            {
                                displayName: 'Devise',
                                field: 'devise',
                                position: 2
                            },
                            {
                                displayName: 'Numero compte',
                                field: 'numero',
                                position: 3
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={
                            (fields.file?.name &&
                                !!fields.logoStatus &&
                                form.status ===
                                    FORM_CONST.STATUT.ETAT_INITIAL) ||
                            (form.status === FORM_CONST.CREATION_EN_COURS &&
                                fields.file?.name &&
                                !!fields.logoStatus) ||
                            (form.status === FORM_CONST.CREATION_EN_COURS &&
                                !fields.file?.name &&
                                !fields.logoStatus)
                        }
                        isActionEncours={
                            (fields.file?.name &&
                                !!fields.logoStatus &&
                                form.status ===
                                    FORM_CONST.STATUT.ETAT_INITIAL) ||
                            (form.status === FORM_CONST.CREATION_EN_COURS &&
                                fields.file?.name &&
                                !!fields.logoStatus) ||
                            (form.status === FORM_CONST.CREATION_EN_COURS &&
                                !fields.file?.name &&
                                !fields.logoStatus)
                        }
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
