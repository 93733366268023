import { PAYS } from '@napp-inc/jnapp-util';

export const mapper = [
    {
        position: 0,
        type: 'text',
        id: 'creationPointDeLivraisonAdresseId',
        name: 'adresse',
        placeholder: 'Adresse de livraison',
        label: 'Adresse*',
        isInputGroup: false
    },
    {
        position: 1,
        type: 'text',
        id: 'creationPointDeLivraisonReferencePointLivraisonId',
        name: 'referenceLivraison',
        placeholder: 'Référence du point de livraison...',
        label: 'Reference*',
        isInputGroup: false
    },
    {
        position: 2,
        type: 'dropdown',
        id: 'selectedPaysId',
        name: 'selectedPays',
        defaultOption: 'Sélectionner le pays',
        label: 'Pays*',
        options: PAYS,
        isInputGroup: false
    }
];
