import * as yup from 'yup';

/**
 * @description : Schema de validation directions création form
 */
export const IngredientSchema = yup.object().shape({
    ingredient: yup.string().required(),
    codeIngredient: yup.string().required(),
    unite: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedEmplacement: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    emplacements: yup
        .array()
        .of(
            yup
                .object({
                    code: yup.string().required(),
                    designation: yup.string().required()
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins un élément à la liste')
});
