import { Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';
import { RapportFondRoulementCashCollecteurForm } from './rapport-fonds-roulement';
import { RapportFondRoulementChargeRessourceCashCollecteurForm } from './rapport-fonds-roulement-charge-ressource';

export function FondsRoulementCashCollecteurViewLayout() {
    const [key, setKey] = useState('fonds');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Fonds de Roulement</h1>
            </div>

            <Tabs
                defaultActiveKey="fonds"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="fonds" title="Fonds de roulement">
                    {key === 'fonds' && (
                        <RapportFondRoulementCashCollecteurForm />
                    )}
                </Tab>
                <Tab eventKey="chgress" title="Ressource & charge">
                    {key === 'chgress' && (
                        <RapportFondRoulementChargeRessourceCashCollecteurForm />
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}
