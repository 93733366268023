export const schema = [
    {
        title: 'Devise',
        description: 'devise'
    },
    {
        title: 'Montant',
        description: 'montant'
    },
    {
        title: 'Etat',
        description: 'etat'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
