/**
 * Fonction qui retourne une liste de clients en fonction du marchand d'un superdealer
 * @param {Object} param0
 * @param {Array} param0.superDealers : Tableau des super dealers
 * @param {Object} param0.superDealer : Objet representant un super dealer
 * @param {Array} param0.superDealers : Tableau des clients
 * @returns {Array}
 */

export const filterClientBySuperDealerMarchand = ({
    superDealers = [],
    superDealer = {},
    clients = []
}) => {
    const findedMarchandFromSd = superDealers?.find(
        (item) => item.entreprise?.code === superDealer?.code
    )?.marchand;
    return clients?.filter(
        (item) => item?.marchand?.reference === findedMarchandFromSd?.reference
    );
};
