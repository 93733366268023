import { useGetOrganisationDetail } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { UserServiceWrapper } from '../../../components';
import { AccepterReferenceForm } from './accepter-reference';
import { ChoixCategorieMarchand } from './choix-categorie-marchand';
import { ComplementariteStock } from './complementarite-stock';
import { HoraireConfigForm } from './horaire';
import { TransfertFondConfigForm } from './transfert-fond';
import { UpdateLogoForm } from './update-logo';
import { ViderCompteFermetureForm } from './vider-compte';

export function MarchandGeneralSetting() {
    const { organisation } = useSelector((state) => ({
        organisation: state.organisation
    }));
    const { isMarchand } = useGetOrganisationDetail({
        organisation
    });
    return (
        <div className="container-fluid">
            <div className="Subhead mt-3 mb-3">
                <h2 className="Subhead-heading">Paramètres Généraux</h2>
            </div>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
                        .DEFINIR_LOGO_ORGANISATION
                ]}
            >
                <div className="Subhead-card mb-4">
                    <h5 className="Subhead-heading-label">Logo</h5>
                    <UpdateLogoForm />
                </div>
            </UserServiceWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
                        .PARAMETRER_ACCEPTER_NUMERO_REFERENCE_PAIEMENT
                ]}
            >
                <div className="Subhead-card mb-4">
                    <h5 className="Subhead-heading-label">
                        Numéro référence paiement
                    </h5>
                    <AccepterReferenceForm />
                </div>
            </UserServiceWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
                        .PARAMETRER_TRANSFERT_FONDS_AUTOMATIQUE
                ]}
            >
                <div className="Subhead-card mb-4">
                    <h5 className="Subhead-heading-label">
                        Transfert de fonds
                    </h5>
                    <TransfertFondConfigForm />
                </div>
            </UserServiceWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
                        .PARAMETRER_VIDER_COMPTE_FERMETURE
                ]}
            >
                <div className="Subhead-card mb-4">
                    <h5 className="Subhead-heading-label">
                        Vider compte à la fermeture
                    </h5>
                    <ViderCompteFermetureForm />
                </div>
            </UserServiceWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
                        .DEFINIR_HORAIRE
                ]}
            >
                <div className="Subhead-card mb-4">
                    <h5 className="Subhead-heading-label">Horaire</h5>
                    <HoraireConfigForm />
                </div>
            </UserServiceWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.MARCHAND.CHOIX_CATEGORIE
                        .MISE_A_JOUR_CATEGORIE_MARCHAND
                ]}
            >
                {isMarchand && (
                    <div className="Subhead-card mb-4">
                        <h5 className="Subhead-heading-label">
                            Choix de la catégorie
                        </h5>
                        <ChoixCategorieMarchand />
                    </div>
                )}
            </UserServiceWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.STOCK.TYPE_COMPLEMENTARITE
                        .METTRE_A_JOUR_TYPE_COMPLEMENTARITE
                ]}
            >
                <div className="Subhead-card mb-4">
                    <h5 className="Subhead-heading-label">
                        Complémentarité de Stock
                    </h5>
                    <ComplementariteStock />
                </div>
            </UserServiceWrapper>
        </div>
    );
}
