import { FORM_CONST } from '@napp-inc/jnapp-util';
import { useEffect, useState } from 'react';

/**
 * Retourne une valeur booléenne pour déterminer en fonction du statut du formulaire si on peut faire ou pas une redirection
 * à noter que pour faire la redirection il faut que le status soit un statut de succès
 * @param {String} formStatus etat du status du form
 * @returns {Boolean}
 */
export function useShouldRedirectOnState(formStatus) {
    const [isShouldRedirect, setIsShouldRedirect] = useState(false);
    useEffect(() => {
        if (
            formStatus === FORM_CONST.CREATION_SUCCESS ||
            formStatus === FORM_CONST.MODIFICATION_SUCCESS
        ) {
            setIsShouldRedirect(true);
        }
    }, [formStatus]);
    return isShouldRedirect;
}
