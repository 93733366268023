// import { ListGenericForm } from '../../../components';
// import { REDUX_NODE_NAME } from '../../../redux';
// import { URL_CONST } from '../../../util';

export function ListDeviseForm() {
    return (
        <h1>Liste Devises</h1>
        // <ListGenericForm
        //     reduxPropertyName="devises"
        //     functionName={URL_CONST.GET_LIST_DEVISES}
        //     nodeName={REDUX_NODE_NAME.DEVISES}
        //     mapper={[
        //         {
        //             field: 'id',
        //             displayName: 'id',
        //             position: 1
        //         },
        //         {
        //             displayName: 'Création',
        //             field: 'dateCreation',
        //             position: 2
        //         }
        //     ]}
        // />
    );
}
