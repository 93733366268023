import { OPERATION_RAPPORT, TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import { ReduxReportTemplate } from '../../template';
import { CashCollecteurSelector } from '../../../../components';

export function RapportFondRoulementChargeRessourceCashCollecteurForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCashCollecteur: {}
        }
    });
    const formater = (data) => {
        const result = [];
        const operation = data?.[0]?.listReturn?.[0]?.operation || undefined;
        if (operation) {
            Object.getOwnPropertyNames(operation).forEach((property) => {
                const designation = OPERATION_RAPPORT?.[property] || property;
                result.push({
                    ...operation[property],
                    id: property,
                    designation
                });
            });
        }
        return result;
    };
    const chartDataListFormater = (data) =>
        data?.[0]?.rapportsDate?.[0]?.dates || [];
    return (
        <>
            {/* <StateShowerComponent state={formState} /> */}
            <ReduxReportTemplate
                listTitle=""
                dynamicNodeName={`organisationsReportRessourceChargeCashCollecteur-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                functionName={URL_CONST.GET_LIST_RAPPORT_ORGANISATION_V2}
                nodeName={REDUX_NODE_NAME.ORGANISATION_REPORT}
                listFormater={formater}
                formState={formState}
                formDispatcher={formDispatcher}
                mapper={[
                    {
                        displayName: 'Désignation',
                        field: 'designation',
                        fieldClassname: 'text-secondary fw-bold fs-6'
                    },
                    {
                        displayName: 'Débit',
                        field: 'sortie',
                        isTotal: true
                    },
                    {
                        displayName: 'Crédit',
                        field: 'entree',
                        isTotal: true
                    }
                ]}
                isAddTotalTr
                seriesMapper={[
                    {
                        displayName: 'Gain change',
                        field: 'operation.GainChange.soldeFermeture'
                    },
                    {
                        displayName: 'Perte change',
                        field: 'operation.PerteChange.soldeFermeture'
                    },
                    {
                        displayName: 'Regularisation entrée',
                        field: 'operation.RegularisationEntree.soldeFermeture'
                    },
                    {
                        displayName: 'Regularisation sortie',
                        field: 'operation.RegularisationSortie.soldeFermeture'
                    },
                    {
                        displayName: 'Dépense',
                        field: 'operation.Depenses.soldeFermeture'
                    }
                ]}
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    typeProprietaire: 'CashCollecteur',
                    type: 'RessourceCharge',
                    rapport: {
                        dateDebut: TIME.add(TODAY.at000000(), { days: -15 }),
                        dateFin: TIME.utc235959(TODAY.at235959())
                    }
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    typeProprietaire: 'CashCollecteur',
                    type: 'RessourceCharge'
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedCashCollecteur',
                        isRequired: false,
                        displayName: 'Cash collecteur',
                        callback: (value) => {
                            if (!value?.code || value?.code === 'all')
                                return {};
                            return { codeValeurTypeProprietaire: value?.code };
                        }
                    }
                ]}
                chartDataListFormater={chartDataListFormater}
                chartTitle="Fonds de roulement"
                yAxisChartTitle="Fermerture ($)"
                isDisplayChart
            >
                <CashCollecteurSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    idProperty="code"
                    isAddAditionalOptions
                />
            </ReduxReportTemplate>
        </>
    );
}
