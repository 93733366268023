import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { ViewLayout } from './view-layout';

export function ListRayonForm() {
    return (
        <ViewLayout>
            <ListGenericForm
                reduxPropertyName="rayonsColonne"
                functionName={URL_CONST.GET_LIST_RAYON_COLONNE_MARCHAND_AIRTIME}
                nodeName={DB_NODE.RAYON_COLONNE}
                mapper={[
                    {
                        field: 'agence.designation',
                        displayName: 'Agence',
                        position: 1
                    },
                    {
                        field: 'parent.code',
                        displayName: 'Code rayon',
                        position: 2
                    },
                    {
                        field: 'parent.designation',
                        displayName: 'Désignation rayon',
                        position: 3
                    },
                    {
                        field: 'code',
                        displayName: 'Code colonne',
                        position: 4
                    },
                    {
                        field: 'designation',
                        displayName: 'Désignation colonne',
                        position: 5
                    }
                ]}
            />
        </ViewLayout>
    );
}
