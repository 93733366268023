import { URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { ViewLayout } from './view-layout';

export function ListSecteur() {
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                identifier="id"
                reduxPropertyName="secteurs"
                functionName={URL_CONST.GET_LIST_SECTEUR_CHARGE}
                nodeName={REDUX_NODE_NAME.SECTEUR}
                isLinkToDetail={false}
                mapper={[
                    {
                        field: 'code',
                        displayName: 'Code',
                        position: 1
                    },
                    {
                        field: 'designation',
                        displayName: 'Designation',
                        position: 2
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
