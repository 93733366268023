import * as yup from 'yup';

/**
 * @description : Schema Creation cash Collecteur
 */
export const CreationCashCollecteurFormSchema = yup.object().shape({
    email: yup.string().required(),
    numeroTel: yup.string().required(),
    preview: yup.string(),
    searchInput: yup.string(),
    selectedZone: yup.object().shape({
        reference: yup.string(),
        designation: yup.string()
    }),
    selectedZoneDropDown: yup.object().shape({
        reference: yup.string().required(),
        designation: yup.string().required()
    }),
    zones: yup.array().of(
        yup
            .object({
                reference: yup.string().required(),
                niveau: yup.number(),
                designation: yup.string().required()
            })
            .shape({})
    )
});

/**
 * @description : Schema Creation cash Collecteur
 */
export const ValidationCashCollecteurFormSchema = yup.object().shape({
    codeValidation: yup.string().required(),
    email: yup.string().required(),
    code: yup.string().required(),
    numeroTel: yup.string().required(),
    designation: yup.string().required(),
    rccm: yup.string().required(),
    adresseUne: yup.string().required(),
    nom: yup.string().required(),
    prenom: yup.string().required(),
    userEmail: yup.string().required(),
    userAdresseUne: yup.string().required(),
    aggree: yup
        .boolean()
        .oneOf([true], 'Vous devez accepter les termes et les conditions')
});
