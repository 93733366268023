import { Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';
import { RapportPartenaireKinDBForm } from './rapport-partenaire-kindb';
import { RelevePartenaireKinDistributionForm } from './releve';

export function RapportPartenaireKinDBViewLayout() {
    const [key, setKey] = useState('report');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Partenaire Kin distribution</h1>
            </div>
            <Tabs
                defaultActiveKey="report"
                id="fill-tab-example"
                className="mb-3"
                onSelect={setKey}
            >
                <Tab eventKey="report" title="Rapport">
                    {key === 'report' && <RapportPartenaireKinDBForm />}
                </Tab>
                <Tab eventKey="releve" title="Relevé">
                    {key === 'releve' && (
                        <RelevePartenaireKinDistributionForm />
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}
