import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST, URL_CONST } from '@napp-inc/jnapp-util';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    reauthenticateWithCredential,
    EmailAuthProvider,
    signOut
} from 'firebase/auth';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomFormGroup,
    CustomInput,
    CustomLabel,
    CustomRow
} from '../../../components';
import { ChangePasswordFormSchema as uiValidator } from './validation';
import { auth } from '../../../firebase/firebase';

const defaultFields = {
    currentPassword: '',
    newPassword: '',
    confirmedPassword: ''
};

export function ChangePasswordComponent() {
    const reduxDispatcher = useDispatch();
    const navigate = useNavigate();
    const path = useLocation();
    const smoothScroll = () => {
        const element = document.getElementById('smoothScroll');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken, creator, uid } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const reAuthenticate = () => {
        const { newPassword } = fields;
        const { email } = firebaseUser;
        reauthenticateWithCredential(
            firebaseUser,
            EmailAuthProvider.credential(email, newPassword)
        ).catch(() => {
            signOut(auth).then(() => {
                reduxDispatcher(GENERIC_ITEM_ACTIONS_CREATOR.resetApp());
                navigate('/sign-in', { replace: true });
            });
        });
    };
    const updateEtat = () => {
        const { currentPassword, newPassword } = fields;
        const payload = {
            password: newPassword,
            currentPassword,
            creator,
            idUser: uid
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_PASSWORD,
                payload,
                fields,
                onSuccess: reAuthenticate,
                uiValidator,
                reduxDispatcher
            })
        );
    };
    useEffect(() => {
        if (
            path &&
            path.hash === '#change' &&
            path.pathname === '/my-account'
        ) {
            smoothScroll();
        }
    }, [path]);

    return (
        <div className="p-5 border border-warning" id="smoothScroll">
            <h4>Changer mot de passe</h4>
            <CustomRow>
                <div className="col-12 col-md-4">
                    <CustomLabel
                        text="Mot de passe actuel*"
                        htmlFor="currentPasswordChangePassword"
                        className="col-12"
                    />
                </div>
                <div className="col-12 col-md-8">
                    <CustomFormGroup>
                        <CustomInput
                            uiValidator={uiValidator}
                            type="password"
                            placeholder="Saisissez le mot de passe actuel"
                            id="currentPasswordChangePassword"
                            name="currentPassword"
                            divClassName="col-12"
                            controlClassName="form-control"
                            formDispatcher={formDispatcher}
                            formState={formState}
                        />
                    </CustomFormGroup>
                </div>
            </CustomRow>
            <CustomRow>
                <div className="col-12 col-md-4">
                    <CustomLabel
                        text="Nouveau mot de passe*"
                        htmlFor="newPasswordChangePassword"
                        className="col-12"
                    />
                </div>
                <div className="col-12 col-md-8">
                    <CustomFormGroup>
                        <CustomInput
                            uiValidator={uiValidator}
                            type="password"
                            placeholder="Saisissez le nouveau mot de passe"
                            id="newPasswordChangePassword"
                            name="newPassword"
                            divClassName="col-12"
                            controlClassName="form-control"
                            formDispatcher={formDispatcher}
                            formState={formState}
                        />
                    </CustomFormGroup>
                </div>
            </CustomRow>
            <CustomRow>
                <div className="col-12 col-md-4">
                    <CustomLabel
                        text="Confirmer mot de passe*"
                        htmlFor="confirmedPasswordChangePassword"
                        className="col-12"
                    />
                </div>
                <div className="col-12 col-md-8">
                    <CustomFormGroup>
                        <CustomInput
                            uiValidator={uiValidator}
                            type="password"
                            placeholder="Confirmez le nouveau mot de passe"
                            id="confirmedPasswordChangePassword"
                            name="confirmedPassword"
                            divClassName="col-12"
                            controlClassName="form-control"
                            formDispatcher={formDispatcher}
                            formState={formState}
                        />
                    </CustomFormGroup>
                </div>
            </CustomRow>
            <CustomRow>
                <CustomCol className="col-3 col-md-2">
                    <CustomButtonLoader
                        className="btn-warning"
                        onClick={() => updateEtat()}
                        text="Changer mot de passe"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </CustomCol>
            </CustomRow>
        </div>
    );
}
