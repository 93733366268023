import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../../components';
import { useGetDetailForm } from '../../../../util';
import { schema } from './detail-fields';

export function DetailVenteSimForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'approvisionnementsSim' });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payload = {
            etat,
            idApprovisionnement: entity?.id
        };
        formDispatcher(
            submitPutAction({
                idToken,
                reduxNodeName: DB_NODE.APPROVISIONNEMENT_SIM,
                functionName: URL_CONST.PUT_APPRO_SIM,
                payload,
                fields,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_APPRO_SIM_BY_ID}
            nodeName={DB_NODE.APPROVISIONNEMENT_SIM}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Vente sim"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_VENTE.VALIDER_APPRO_SIM_VENTE,
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_VENTE.ANNULER_APPRO_SIM_VENTE
                ]}
            >
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() =>
                                updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                            }
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours ===
                                ETAT.ETAT_ATTENTE_VALIDATION
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
