import * as yup from 'yup';

/**
 * @description : Schema de commander en ligne
 */
export const CommanderEnLigneSchema = yup.object().shape({
    selectedModeLivraison: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedArticle: yup.object().shape({
        code: yup.string().required(),
        description: yup.string().required()
    }),
    searchInput: yup.string().required(),
    searchInputCode: yup.string().required(),
    produits: yup.array().of(
        yup
            .object({
                id: yup.string().required(),
                code: yup.string().required(),
                designation: yup.string().required()
            })
            .shape({})
    ),
    estPublic: yup.boolean(),
    estExclu: yup.boolean(),
    selectedUser: yup.object().shape({
        code: yup.string().required(),
        description: yup.string().required()
    }),
    selectedTypeBeneficiaire: yup.object().shape({
        code: yup.string().required(),
        description: yup.string().required()
    }),
    clients: yup.array().of(
        yup.object({
            id: yup.string().required(),
            typeEntite: yup.string().required(),
            reference: yup.string().required()
        })
    ),
    list: yup.array().of(
        yup
            .object({
                id: yup.string().required(),
                modeLivraison: yup.string().required(),
                estPublic: yup.boolean(),
                estExclu: yup.boolean(),
                produits: yup.array().of(
                    yup.object({
                        id: yup.string().required(),
                        code: yup.string().required(),
                        designation: yup.string().required()
                    })
                )
            })
            .shape({})
    )
    // .required()
    // .min(1, 'Ajoutez au moins un élément à la liste'),
});
