import { Add } from '@napp-inc/jnapp-util';
import { traitementMouvement } from './traitement-mouvement';

/**
 * Cette fonction agrege une liste des mouvements pour en produire le solde d'ouverture et de fermeture ainsi que le total des entrées et des sorties
 * @param {Array} data liste des mouvements
 * @param {Boolean} isReverseByDate permet de choisir l'ouverture & la fermeture tout en bas ou tout en haut et in
 * @returns {Object} objet contenant les propriétés { entree, sortie, ouverture, fermeture }
 */
export function agregationMouvement(data, isReverseByDate = false) {
    let entree = 0;
    let sortie = 0;
    let ouverture = 0;
    let fermeture = 0;
    const mouvement = traitementMouvement(data);
    if (mouvement?.length) {
        mouvement.forEach((item) => {
            entree = Add(entree, item.entree);
            sortie = Add(sortie, item.sortie);
        });
        if (isReverseByDate) {
            ouverture = mouvement[mouvement.length - 1]?.soldePrecedent;
            fermeture = mouvement[0]?.soldeActuel;
        } else {
            ouverture = mouvement[0]?.soldePrecedent;
            fermeture = mouvement[mouvement.length - 1]?.soldeActuel;
        }
    }
    return {
        entree,
        sortie,
        ouverture,
        fermeture
    };
}
