export const formatType = (type) => {
    let message = '';
    switch (type) {
        case 'UrgenceCommunication':
            message = 'Urgence Communication';
            break;
        case 'VideoExplicative':
            message = 'Video Explicative';
            break;
        default:
            message = '';
            break;
    }
    return message;
};

export const formater = (item) => ({
    ...item,
    designationAgent: `${item?.agent?.prenom} ${item?.agent?.nom}` || '',
    designationType: formatType(item?.type)
});
