import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { MarchandSelector } from '../../../../components';
import { SelectedRevendeurShower } from './selected-revendeur-shower';

export function RapportVenteTopRevendeurForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedMarchand: {}
        }
    });
    const listFormater = (donnees) =>
        donnees?.[0]?.listReturn?.map((item) => ({
            ...item,
            id: item?.revendeur?.reference
        })) || [];

    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <SelectedRevendeurShower
                id={formState?.fields?.selectedRevendeur?.id}
            />
            <ReduxReportTemplate
                listTitle="Rapport de vente par revendeur"
                bottomPagination
                dynamicNodeName={`rapportVenteTopRevendeur-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_RAPPORT_VENTE_USER}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={[
                    {
                        displayName: 'Till number',
                        field: 'revendeur.tillNumber'
                    },
                    {
                        displayName: 'Nom',
                        field: 'revendeur.designation'
                    },
                    {
                        displayName: 'Statut',
                        field: 'revendeur.statut'
                    },
                    {
                        displayName: 'Montant',
                        field: 'prixTotal',
                        isTotal: true
                    }
                ]}
                isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    estOrganisation: true,
                    estAllRevendeur: true
                }}
                defaultPayloadMapperValues={{
                    estOrganisation: true,
                    estAllRevendeur: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedMarchand',
                        isRequired: false,
                        displayName: 'Marchand',
                        callback: (data) => {
                            if (!data?.code || data?.code === 'all') return {};
                            return { codeMarchand: data?.code };
                        }
                    }
                ]}
            >
                <MarchandSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    isAddAditionalOptions
                    estNonEmoney
                    idProperty="code"
                />
            </ReduxReportTemplate>
        </>
    );
}
