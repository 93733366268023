import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail,
    fetchElement
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    MOYEN_PAIEMENT,
    BUDGET_ELABORATION,
    CHARGE,
    TYPE_BUDGET_PREVISIONNEL,
    BENEFICIAIRE,
    fromStringToTimestamp,
    getPeriodesOfYear,
    TIME
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomInputDate,
    CustomRow,
    CustomLabel,
    CustomCollapse,
    SimpleTable,
    FormWrapper,
    CustomAddRemoveButton,
    InputSearchGroup,
    CustomAlert
    // StateShowerComponent
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import {
    buildingObjectBySecteur,
    buildingOnlyByCharge,
    buildingPeriodesByChargeAndSecteur
} from '../util';
import { ElaborationChargeFixeFormSchema as uiValidator } from './validation';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedDirection: {},
    selectedMonth: {},
    selectedSecteur: {},
    selectedCharge: {},
    selectedCaisseSectorielle: {},
    prixCharge: 0,
    beneficiaireAndMontant: [],
    selectedBeneficiaire: {},
    selectedTypeBeneficiaire: {},
    datePaiement: '',
    modePaiement: {},
    searchInput: '',
    quantiteParBeneficiaire: 0,
    montantParBeneficiaire: 0,
    chargeElaboration: []
};

/**
 * @description dependencies du state du formulaire
 */
const defaultDependencies = {
    fields: [
        'directions',
        'secteurs',
        'charges',
        'caisses',
        'exercicesFinancier'
    ]
};

/**
 * @description elements du state du formulaire
 */
const defaultElements = ['beneficiaires'];

// FIXME: Séparer la logique du prévisionnel simple et de l'additionnel du prévisionnel
export function CreationElaborationChargeFixe() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        chargesFixe,
        etatCharge,
        secteursNapp,
        secteursKinDB,
        etatSecteurs,
        directions,
        etatDirections,
        caisses,
        etatCaisse,
        exerciceFinancier,
        etatExerciceFinancier
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        chargesFixe: reduxState.chargesFixe,
        etatCharge: reduxState.etat.chargesFixe.etat,
        etatSecteurs: reduxState.etat.secteurs.etat,
        secteursNapp: reduxState.secteurs.filter(
            (item) => item?.direction?.estNapp
        ),
        secteursKinDB: reduxState.secteurs.filter(
            (item) => !item?.direction?.estNapp
        ),
        directions: reduxState.directions,
        etatDirections: reduxState.etat.directions.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeEntite?.designation}-${item?.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat,
        exerciceFinancier: reduxState.exerciceFinancier,
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: defaultElements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    const typeOfCharge = chargesFixe.find(
        (item) => item?.code === fields?.selectedCharge?.code
    ) || { estRempliAutomatique: false };

    const exercices = exerciceFinancier.map((item) => ({
        ...item,
        designation: `${TIME.fromUtcTimestampToLocalString(
            item.dateDebut
        )} - ${TIME.fromUtcTimestampToLocalString(item.dateFin)}`
    }));

    const targetedExercice = exercices.find(
        (item) => item.id === fields?.selectedExercice.id
    );

    const periodes =
        getPeriodesOfYear({
            startOf: targetedExercice?.dateDebut,
            endOf: targetedExercice?.dateFin
        }).map((item) => ({
            code: item.dateDebut,
            designation: item.mois,
            ...item
        })) || [];

    const targetedMonth = periodes.length
        ? periodes.find(
              (item) => item.designation === fields.selectedMonth.designation
          )
        : {};
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_FIXE,
                nodeName: REDUX_NODE_NAME.CHARGE_FIXE,
                etat: etatCharge
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_DIRECTION, // Toutes les directions
                nodeName: REDUX_NODE_NAME.DIRECTION,
                etat: etatDirections
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            }
        ]
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const { chargeElaboration } = fields;
        /**
         * @desc return the list in the format whished
         */
        const list = buildingOnlyByCharge(
            buildingObjectBySecteur(
                buildingPeriodesByChargeAndSecteur(chargeElaboration)
            )
        );
        /**
         * @desc the payload to send
         */
        const payload = {
            periode: {
                dateDebut: targetedExercice.dateDebut,
                dateFin: targetedExercice.dateFin
            },
            newElaborationAmendement: {
                typeEntite: BUDGET_ELABORATION,
                typeProprietaire: CHARGE.code,
                estVariable: false,
                type: TYPE_BUDGET_PREVISIONNEL.code
            },
            creator,
            list
        };

        /**
         * @desc Post CF
         * @toFix : uiValidator must be defined
         */
        // console.log(JSON.stringify(payload));
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    /**
     * @description fonction pour fetcher les beneficiaires
     */
    const onSearchBeneficiaire = () => {
        const { status: etat } = elements.beneficiaires;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_BENEFICIAIRES,
                elementPropertyName: 'beneficiaires',
                etat,
                payload: {
                    typeEntite: fields.selectedTypeBeneficiaire?.code,
                    value: fields.searchInput
                },
                reduxDispatcher
            })
        );
    };

    /**
     * @description action pour rajouter le partenaires et le montant à payer
     */
    const addBeneficiaire = () => {
        if (
            !fields.selectedBeneficiaire.id &&
            (!fields.quantiteParBeneficiaire || !fields.montantParBeneficiaire)
        ) {
            return;
        }
        const propertyToAdd = {};
        if (typeOfCharge.estQuantite)
            propertyToAdd.quantite = fields.quantiteParBeneficiaire;
        if (!typeOfCharge.estQuantite)
            propertyToAdd.montant = fields.montantParBeneficiaire;

        const data = {
            id: fields.selectedBeneficiaire.id,
            typeEntite: fields.selectedTypeBeneficiaire.code,
            valeurTypeEntite: {
                designation: fields.selectedBeneficiaire.designation,
                reference: fields.selectedBeneficiaire.id
            },
            ...propertyToAdd
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'beneficiaireAndMontant',
                data
            }
        });
    };

    /**
     * @description action pour rajouter les elements qui partage le même secteur
     */
    const addEntireChargeElaboration = () => {
        // if (!fields.selectedBeneficiaire.id || !fields.quantiteParBeneficiaire)
        //     return;
        if (
            typeOfCharge.estRempliAutomatique &&
            (!fields.selectedExercice.id ||
                !fields.selectedCharge.code ||
                !fields.modePaiement.code)
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Vérifiez les informations du formulaire`,
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }

        if (
            !typeOfCharge.estRempliAutomatique &&
            (!fields.selectedExercice.id ||
                !fields.selectedDirection.code ||
                !fields.selectedCharge.code ||
                !fields.selectedSecteur.code ||
                !fields.prixCharge ||
                !fields.selectedMonth.code ||
                !fields.beneficiaireAndMontant.length ||
                !fields.selectedTypeBeneficiaire.code ||
                !fields.datePaiement ||
                !fields.modePaiement.code ||
                !fields.selectedTypeBeneficiaire.code ||
                !fields.searchInput)
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Vérifiez les informations du formulaire`,
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }

        const propertyToAdd = {};
        const montantByBeneficiaire = fields.beneficiaireAndMontant.map(
            (item) => ({
                ...item,
                valeurTypeEntite: { reference: item.valeurTypeEntite.reference }
            })
        );
        if (fields.modePaiement.code === 'Cash')
            propertyToAdd.idCaisse = fields.selectedCaisseSectorielle.id;
        if (!typeOfCharge?.estRempliAutomatique) {
            propertyToAdd.beneficiaires = montantByBeneficiaire;
            propertyToAdd.secteur = { code: fields.selectedSecteur.code };
        }
        if (typeOfCharge.estQuantite)
            propertyToAdd.prixCharge = fields.prixCharge;
        const data = {
            id: `${fields.selectedCharge.code}_${
                !typeOfCharge?.estRempliAutomatique
                    ? fields.selectedSecteur.code
                    : 'unknown'
            }_${
                !typeOfCharge?.estRempliAutomatique
                    ? fields.selectedDirection.code
                    : 'unknown'
            }_${
                !typeOfCharge?.estRempliAutomatique
                    ? targetedMonth.dateDebut
                    : 'today'
            }_${
                !typeOfCharge?.estRempliAutomatique
                    ? targetedMonth.dateFin
                    : 'today'
            }_${
                !typeOfCharge?.estRempliAutomatique
                    ? fromStringToTimestamp(fields.datePaiement)
                    : 'today'
            }`,
            charge: fields.selectedCharge.code,
            typeMoyenPaiement: fields.modePaiement.code,
            dateDebut: !typeOfCharge?.estRempliAutomatique
                ? targetedMonth.dateDebut
                : 1666688885558,
            dateFin: !typeOfCharge?.estRempliAutomatique
                ? targetedMonth.dateFin
                : 1666688885558,
            datePaiement: !typeOfCharge?.estRempliAutomatique
                ? fromStringToTimestamp(fields.datePaiement)
                : 1666688885558,
            // secteur: fields.selectedSecteur.code,
            ...propertyToAdd
        };

        // /**
        //  * @description: On rajoute l'entièrté des éléments
        //  */
        // formDispatcher({
        //     type: FORM_CONST.ADD_ITEM,
        //     payload: {
        //         identifier: 'id',
        //         field: 'chargeElaboration',
        //         data
        //     }
        // });

        /**
         * @description: Je réinitialise un ensemble des propriétés du state
         */
        formDispatcher({
            type: FORM_CONST.REINITIALISER,
            payload: {
                initialState: {
                    ...formState,
                    fields: {
                        ...formState.fields,
                        beneficiaireAndMontant: [],
                        chargeElaboration: fields.chargeElaboration
                            .filter((item) => item.id !== data.id)
                            .concat(data)
                    }
                }
            }
        });
        // const newState = {
        //     ...initialState,
        //     fields: {
        //         ...initialState.fields,
        //         chargeElaboration: fields.chargeElaboration,
        //         selectedExercice: fields.selectedExercice,
        //         selectedDirection: fields.selectedDirection
        //     }
        // };
        // formDispatcher({
        //     type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
        //     payload: {
        //         newState,
        //         field: 'chargeElaboration',
        //         data,
        //         identifier: 'id'
        //     }
        // });
    };

    const newListBenefinciaires = (listes = []) => {
        if (!listes?.length) return [];
        return listes.map((item) => ({
            ...item,
            designation: `${item.personne.prenom} ${item.personne.nom}`
        }));
    };

    // const onChangeAfterSelect = (data, obj) => {
    //     const payload = {
    //         field: 'selectedExercice',
    //         data,
    //         newState: {
    //             ...formState,
    //             fields: {
    //                 ...initialState.fields,
    //                 ...obj
    //             }
    //         }
    //     };
    //     formDispatcher({
    //         type: 'CHANGE_FIELD_ON_NEW_STATE',
    //         payload
    //     });
    // };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'directions',
                    etat: etatDirections
                },
                {
                    dependency: 'exercicesFinancier',
                    etat: etatExerciceFinancier
                },
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'charges',
                    etat: etatCharge
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisse
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={exercices}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner un exercice"
                    label="Exercice*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedExercice"
                    id="selectedExerciceId"
                    idProperty="id"
                    uiValidator={uiValidator}
                    // callback={onChangeAfterSelect}
                    // resetOnChangeState
                    // fieldsReseted={{
                    //     ...initialState.fields,
                    //     selectedExercice: fields.selectedExercice,
                    //     chargeElaboration: fields.chargeElaboration
                    // }}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={chargesFixe}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la charge"
                    label="Charge*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedCharge"
                    id="selectedChargeId"
                    uiValidator={uiValidator}
                    // resetOnChangeState
                    // newState={{
                    //     ...initialState.fields,
                    //     selectedExercice: fields.selectedExercice,
                    //     selectedDirection: fields.selectedDirection,
                    //     selectedCharge: fields.selectedCharge
                    // }}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!!typeOfCharge.estQuantite}>
                <CustomInput
                    isInputGroup={false}
                    label="Prix Unitaire*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez le montant"
                    formDispatcher={formDispatcher}
                    type="number"
                    name="prixCharge"
                    id="prixChargeId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!typeOfCharge.estRempliAutomatique}>
                <CustomRow>
                    <CustomDropdown
                        // refValue={directionRef}
                        options={directions}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner la direction"
                        label="Direction*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedDirection"
                        id="selectedDirectionId"
                        uiValidator={uiValidator}
                        // resetOnChangeState
                        // newState={{
                        //     ...initialState.fields,
                        //     selectedExercice: fields.selectedExercice,
                        //     selectedDirection: fields.selectedDirection
                        // }}
                    />
                </CustomRow>
                <CustomDropdown
                    options={
                        (fields.selectedDirection.code === 'OKAPI_DIRECT_NAPP'
                            ? secteursNapp
                            : secteursKinDB) || []
                    }
                    disabled={
                        fields.selectedDirection?.code === 'OKAPI_DIRECT_NAPP'
                            ? !secteursNapp.length
                            : !secteursKinDB.length
                    }
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le secteur"
                    label="Secteur*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedSecteur"
                    id="selectedSecteurId"
                    uiValidator={uiValidator}
                    // resetOnChangeState
                    // newState={{
                    //     ...initialState.fields,
                    //     selectedExercice: fields.selectedExercice,
                    //     selectedDirection: fields.selectedDirection,
                    //     selectedCharge: fields.selectedCharge,
                    //     selectedSecteur: fields.selectedSecteur
                    // }}
                />
            </CustomRow>
            {!typeOfCharge?.estRempliAutomatique ? (
                <>
                    <CustomRow>
                        <CustomDropdown
                            isSort={false}
                            disabled={!periodes.length}
                            options={periodes}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner le mois"
                            label="Mois*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedMonth"
                            id="selectedMonthId"
                            uiValidator={uiValidator}
                            // resetOnChangeState
                            // newState={{
                            //     ...initialState.fields,
                            //     selectedExercice: fields.selectedExercice,
                            //     selectedDirection: fields.selectedDirection,
                            //     selectedCharge: fields.selectedCharge,
                            //     selectedSecteur: fields.selectedSecteur,
                            //     prixCharge: fields.prixCharge,
                            //     selectedMonth: fields.selectedMonth
                            // }}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomInputDate
                            id="datePaiementId"
                            name="datePaiement"
                            type="date"
                            label="Date de Paiement*"
                            isInputGroup={false}
                            labelClassName="col-sm-2"
                            divClassName="col-sm-10"
                            placeholder="Date paiement"
                            formDispatcher={formDispatcher}
                            uiValidator={uiValidator}
                            formState={formState}
                        />
                    </CustomRow>
                </>
            ) : null}
            <CustomRow>
                <CustomDropdown
                    options={MOYEN_PAIEMENT.MOYEN_PAIEMENT_CHARGE_FIXE}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le mode de paiement"
                    label="Mode de paiement*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="modePaiement"
                    id="modePaiementId"
                    uiValidator={uiValidator}
                    // resetOnChangeState
                    // newState={{
                    //     ...initialState.fields,
                    //     selectedExercice: fields.selectedExercice,
                    //     selectedDirection: fields.selectedDirection,
                    //     selectedCharge: fields.selectedCharge,
                    //     selectedSecteur: fields.selectedSecteur,
                    //     prixCharge: fields.prixCharge,
                    //     selectedMonth: fields.selectedMonth,
                    //     datePaiement: fields.datePaiement,
                    //     modePaiement: fields.modePaiement
                    // }}
                />
            </CustomRow>
            <CustomCollapse isIn={fields.modePaiement.code === 'Cash'}>
                <CustomRow>
                    <CustomDropdown
                        idProperty="id"
                        options={caisses.filter(
                            (item) =>
                                item.devise ===
                                chargesFixe.find(
                                    (charge) =>
                                        charge.code ===
                                        fields.selectedCharge.code
                                )?.devise
                        )}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner la caisse"
                        label="Caisse Sectorielle*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedCaisseSectorielle"
                        id="selectedCaisseSectorielleId"
                        uiValidator={uiValidator}
                        // resetOnChangeState
                        // newState={{
                        //     ...initialState.fields,
                        //     selectedExercice: fields.selectedExercice,
                        //     selectedDirection: fields.selectedDirection,
                        //     selectedCharge: fields.selectedCharge,
                        //     selectedSecteur: fields.selectedSecteur,
                        //     prixCharge: fields.prixCharge,
                        //     selectedMonth: fields.selectedMonth,
                        //     datePaiement: fields.datePaiement,
                        //     modePaiement: fields.modePaiement,
                        //     selectedCaisseSectorielle:
                        //         fields.selectedCaisseSectorielle
                        // }}
                    />
                </CustomRow>
            </CustomCollapse>
            {!typeOfCharge?.estRempliAutomatique ? (
                <>
                    <CustomRow>
                        <CustomDropdown
                            options={BENEFICIAIRE.CHARGE_FIXE}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner le type beneficiaire"
                            label="Type Beneficiaire*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedTypeBeneficiaire"
                            id="selectedTypeBeneficiaireId"
                            uiValidator={uiValidator}
                            // resetOnChangeState
                            // newState={{
                            //     ...initialState.fields,
                            //     selectedExercice: fields.selectedExercice,
                            //     selectedDirection: fields.selectedDirection,
                            //     selectedCharge: fields.selectedCharge,
                            //     selectedSecteur: fields.selectedSecteur,
                            //     prixCharge: fields.prixCharge,
                            //     selectedMonth: fields.selectedMonth,
                            //     datePaiement: fields.datePaiement,
                            //     modePaiement: fields.modePaiement,
                            //     selectedCaisseSectorielle:
                            //         fields.selectedCaisseSectorielle,
                            //     selectedTypeBeneficiaire:
                            //         fields.selectedTypeBeneficiaire
                            // }}
                        />
                    </CustomRow>
                    <InputSearchGroup
                        placeholder="Till Number ou Identité"
                        formDispatcher={formDispatcher}
                        name="searchInput"
                        id="creationElaborationSearchInputId"
                        formState={formState}
                        isInputGroup={false}
                        label="Bénéficiaire*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-10 col-sm-10"
                        callback={onSearchBeneficiaire}
                        buttonDisabled={
                            elements?.beneficiaires?.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        uiValidator={uiValidator}
                    />
                    {elements?.beneficiaires?.status ===
                        FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                    !elements.beneficiaires?.value?.length ? (
                        <CustomAlert successMessage="Aucun resultat pour cette recherche" />
                    ) : null}
                    <CustomCollapse
                        isIn={!!elements.beneficiaires?.value?.length}
                    >
                        <CustomRow>
                            <CustomLabel
                                text="Beneficiaire*"
                                className="col-sm-2"
                            />
                            <CustomDropdown
                                idProperty="id"
                                options={newListBenefinciaires(
                                    elements.beneficiaires.value
                                )}
                                formDispatcher={formDispatcher}
                                formState={formState}
                                defaultOption="Sélectionner le beneficiaire"
                                divClassName="col-12 col-sm-4"
                                name="selectedBeneficiaire"
                                id="selectedBeneficiaireId"
                                uiValidator={uiValidator}
                                // resetOnChangeState
                                // newState={{
                                //     ...initialState.fields,
                                //     selectedExercice: fields.selectedExercice,
                                //     selectedDirection: fields.selectedDirection,
                                //     selectedCharge: fields.selectedCharge,
                                //     selectedSecteur: fields.selectedSecteur,
                                //     prixCharge: fields.prixCharge,
                                //     selectedMonth: fields.selectedMonth,
                                //     datePaiement: fields.datePaiement,
                                //     modePaiement: fields.modePaiement,
                                //     selectedCaisseSectorielle:
                                //         fields.selectedCaisseSectorielle,
                                //     selectedTypeBeneficiaire:
                                //         fields.selectedTypeBeneficiaire,
                                //     selectedBeneficiaire:
                                //         fields.selectedBeneficiaire,
                                //     searchInput: fields.searchInput,
                                //     quantiteParBeneficiaire:
                                //         fields.quantiteParBeneficiaire,
                                //     montantParBeneficiaire:
                                //         fields.montantParBeneficiaire,
                                //     beneficiaireAndMontant:
                                //         fields.beneficiaireAndMontant
                                // }}
                            />
                            {typeOfCharge?.estQuantite ? (
                                <CustomInput
                                    isInputGroup={false}
                                    divClassName="col-12 col-sm-4"
                                    placeholder="Quantité..."
                                    formDispatcher={formDispatcher}
                                    name="quantiteParBeneficiaire"
                                    type="number"
                                    id="quantiteParBeneficiaireId"
                                    formState={formState}
                                    uiValidator={uiValidator}
                                />
                            ) : (
                                <CustomInput
                                    isInputGroup={false}
                                    divClassName="col-12 col-sm-4"
                                    placeholder="Montant..."
                                    formDispatcher={formDispatcher}
                                    name="montantParBeneficiaire"
                                    type="number"
                                    id="montantParBeneficiaireId"
                                    formState={formState}
                                    uiValidator={uiValidator}
                                />
                            )}
                            <CustomAddRemoveButton
                                className="col-sm-2"
                                callback={addBeneficiaire}
                            />
                        </CustomRow>
                    </CustomCollapse>
                </>
            ) : null}
            <CustomRow className="row">
                <CustomCol className="offset-9 col-3">
                    <CustomButtonLoader
                        type="button"
                        className="btn-primary"
                        onClick={addEntireChargeElaboration}
                        text="Ajouter Element"
                    />
                </CustomCol>
            </CustomRow>
            {fields.beneficiaireAndMontant.length &&
            typeOfCharge.estQuantite ? (
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.beneficiaireAndMontant}
                    isShowDropButton
                    listSelectedItemName="beneficiaireAndMontant"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            displayName: 'Beneficiaire',
                            field: 'valeurTypeEntite.designation'
                        },
                        {
                            position: 3,
                            displayName: 'Quantité',
                            field: 'quantite'
                        }
                    ]}
                />
            ) : null}
            {fields.beneficiaireAndMontant.length &&
            !typeOfCharge.estQuantite ? (
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.beneficiaireAndMontant}
                    isShowDropButton
                    listSelectedItemName="beneficiaireAndMontant"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            displayName: 'Beneficiaire',
                            field: 'valeurTypeEntite.designation'
                        },
                        {
                            position: 3,
                            displayName: 'Montant',
                            field: 'montant'
                        }
                    ]}
                />
            ) : null}

            {fields.chargeElaboration.length ? (
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.chargeElaboration}
                    isShowDropButton
                    listSelectedItemName="chargeElaboration"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            displayName: 'Charge',
                            field: 'charge'
                        },
                        {
                            position: 3,
                            displayName: 'Moyen Paiement',
                            field: 'typeMoyenPaiement'
                        },
                        {
                            position: 4,
                            displayName: 'Secteur',
                            field: 'secteur.code'
                        }
                    ]}
                />
            ) : null}
            {/* <StateShowerComponent state={formState.fields} /> */}
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Soumettre elaboration"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
