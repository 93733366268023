import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Administration } from '../../../module';
import {
    DroitAccesDetailRelPath,
    DroitAccesViewLayoutRelPath,
    SystServiceViewLayoutRelPath,
    ServiceCreateRelPath,
    SystServiceDetailRelPath,
    DroitAccesOrganisationViewLayoutRelPath,
    DroitAccesOrganisationDetailRelPath,
    DroitAccesCreateRelPath,
    VersionApplicationDetailRelPath,
    VersionApplicationCreateRelPath,
    VersionApplicationViewLayoutRelPath,
    ApplicationMobileCreateRelPath,
    ApplicationMobileViewLayoutRelPath,
    NotificationPushViewLayoutRelPath,
    NotificationPushCreateRelPath,
    OperationServiceClientViewLayoutRelPath,
    ServiceClientFormulaireViewLayoutRelPath,
    ServiceClientFormulaireCreateRelPath,
    ServiceClientFormulaireDetailRelPath
} from './admin-rel-path';

export const SystServiceViewLayoutPath = {
    ...SystServiceViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Administration.SystServiceViewLayout,
    element: <Administration.SystServiceViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .LECTURE_SYST_SERVICE,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .AJOUTER_SYST_SERVICE_SYSTEM
    ]
};

export const SystServiceDetailPath = {
    ...SystServiceDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Administration.DetailSystServiceForm,
    element: <Administration.DetailSystServiceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .LECTURE_SYST_SERVICE,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .AJOUTER_SYST_SERVICE_SYSTEM
    ]
};

export const ServiceCreatePath = {
    ...ServiceCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Administration.CreationServiceForm,
    element: <Administration.CreationServiceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .AJOUTER_SYST_SERVICE_SYSTEM
    ]
};
/**
 * @desc Droit acces
 */

export const DroitAccesViewLayoutPath = {
    ...DroitAccesViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Administration.DroitAccesViewLayout,
    element: <Administration.DroitAccesViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_SYST_SERVICE_USER,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .MODIFIER_SYST_SERVICE_USER,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_AGENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.UPDATE_USER
            .DESACTIVER_USER
    ]
};

export const DroitAccesDetailPath = {
    ...DroitAccesDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Administration.DetailDroitAccesForm,
    element: <Administration.DetailDroitAccesForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_SYST_SERVICE_USER,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .MODIFIER_SYST_SERVICE_USER,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.UPDATE_USER
            .DESACTIVER_USER
    ]
};

export const DroitAccesCreatePath = {
    ...DroitAccesCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Administration.DroitAccessCreationUtilisateur,
    element: <Administration.DroitAccessCreationUtilisateur />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_AGENT
    ]
};

/**
 * @desc Droit acces
 */

export const DroitAccesOrganisationViewLayoutPath = {
    ...DroitAccesOrganisationViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Administration.DroitAccesOrganisationViewLayout,
    element: <Administration.DroitAccesOrganisationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .AJOUTER_SYST_SERVICE_TYPE_ORGANISATION
    ]
};

export const DroitAccesOrganisationDetailPath = {
    ...DroitAccesOrganisationDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Administration.DetailDroitAccesOrganisationForm,
    element: <Administration.DetailDroitAccesOrganisationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .AJOUTER_SYST_SERVICE_TYPE_ORGANISATION
    ]
};

/**
 * @desc Version application
 */

export const VersionApplicationViewLayoutPath = {
    ...VersionApplicationViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Administration.VersionApplicationViewLayout,
    element: <Administration.VersionApplicationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.APPLICATION_MOBILE
            .AJOUTER_VERSION_OBSOLETE_APPLICATION_MOBILE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.APPLICATION_MOBILE
            .MISE_A_JOUR_VERSION_OBSOLETE_APPLICATION_MOBILE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.APPLICATION_MOBILE
            .VALIDER_VERSION_OBSOLETE_APPLICATION_MOBILE
    ]
};

export const VersionApplicationCreatePath = {
    ...VersionApplicationCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Administration.CreationVersionApplicationForm,
    element: <Administration.CreationVersionApplicationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.APPLICATION_MOBILE
            .AJOUTER_VERSION_OBSOLETE_APPLICATION_MOBILE
    ]
};

export const VersionApplicationDetailPath = {
    ...VersionApplicationDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Administration.DetailVersionApplicationForm,
    element: <Administration.DetailVersionApplicationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.APPLICATION_MOBILE
            .AJOUTER_VERSION_OBSOLETE_APPLICATION_MOBILE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.APPLICATION_MOBILE
            .MISE_A_JOUR_VERSION_OBSOLETE_APPLICATION_MOBILE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.APPLICATION_MOBILE
            .VALIDER_VERSION_OBSOLETE_APPLICATION_MOBILE
    ]
};

/**
 * @desc Application mobile
 */

export const ApplicationMobileViewLayoutPath = {
    ...ApplicationMobileViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Administration.ApplicationMobileViewLayout,
    element: <Administration.ApplicationMobileViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.APPLICATION_MOBILE
            .CREATION_APPLICATION_MOBILE
    ]
};

export const ApplicationMobileCreatePath = {
    ...ApplicationMobileCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Administration.CreationApplicationMobileForm,
    element: <Administration.CreationApplicationMobileForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.APPLICATION_MOBILE
            .CREATION_APPLICATION_MOBILE
    ]
};

/**
 * @desc Notification Push
 */

export const NotificationPushViewLayoutPath = {
    ...NotificationPushViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Administration.NotificationPushViewLayout,
    element: <Administration.NotificationPushViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .CREATION_NOTIFICATION_PUSH,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .LECTURE_NOTIFICATION_PUSH
    ]
};

export const NotificationPushCreatePath = {
    ...NotificationPushCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Administration.CreationNotificationPushForm,
    element: <Administration.CreationNotificationPushForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .CREATION_NOTIFICATION_PUSH
    ]
};

/**
 * @desc Operation service client
 */

export const OperationServiceClientViewLayoutPath = {
    ...OperationServiceClientViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Administration.ServiceClientOperationsViewLayout,
    element: <Administration.ServiceClientOperationsViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .REINITIALISER_PIN_CLIENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .REINITIALISER_PASSWORD_CLIENT
    ]
};

/**
 * @desc Service client form
 */

export const ServiceClientFormulaireViewLayoutPath = {
    ...ServiceClientFormulaireViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Administration.ServiceClientFormulaireViewLayout,
    element: <Administration.ServiceClientFormulaireViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .CREATION_FORMULAIRE_SERVICE_CLIENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .VALIDER_FORMULAIRE_SERVICE_CLIENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .LECTURE_FORMULAIRE_SERVICE_CLIENT
    ]
};

export const ServiceClientFormulaireCreatePath = {
    ...ServiceClientFormulaireCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Administration.CreationServiceClientFormulaireForm,
    element: <Administration.CreationServiceClientFormulaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .CREATION_FORMULAIRE_SERVICE_CLIENT
    ]
};

export const ServiceClientFormDetailPath = {
    ...ServiceClientFormulaireDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Administration.DetailServiceClientFormulaireForm,
    element: <Administration.DetailServiceClientFormulaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .CREATION_FORMULAIRE_SERVICE_CLIENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .VALIDER_FORMULAIRE_SERVICE_CLIENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
            .LECTURE_FORMULAIRE_SERVICE_CLIENT
    ]
};
