import {
    useFormGlobalReducer,
    useGetEntity,
    useGetUserDetail,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CenteredSpinner, CustomAlert } from '../../../components';
import { URL_CONST } from '../../../util';

const defaultDependencies = {
    entity: true
};

export function DetailArticleForm() {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    const { firebaseUser, entity } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        entity: reduxState.produits.find(
            ({ id: idProduit }) => idProduit === id
        )
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    useGetEntity({
        idToken,
        id,
        entity,
        etat: formState.dependencies.entity.status,
        nodeName: DB_NODE.PRODUIT,
        functionName: URL_CONST.GET_FAKE,
        reduxDispatcher,
        formDispatcher
    });
    const { dependencies } = formState;
    const { isLoadFailed, isLoadComplete } = useIsLoadComplete({
        dependencies
    });
    const renderContent = () => {
        if (isLoadFailed) {
            return (
                <>
                    <div>Detail produit</div>
                    <hr />
                    <dl>
                        <dt>Rayon</dt>
                        <dd>Nom rayon</dd>
                    </dl>
                </>
            );
        }
        if (!isLoadFailed) {
            return <CustomAlert error={dependencies?.entity?.error} />;
        }
        if (!isLoadComplete) {
            return <CenteredSpinner />;
        }
        return <div>Detail colonne rayon 10</div>;
    };
    return renderContent();
}
