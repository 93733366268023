export const schema = [
    {
        description: 'type',
        title: 'Elaboration',
        className: 'border-bottom'
    },
    {
        title: 'Type',
        description: 'typeProprietaire',
        className: 'border-bottom'
    },
    {
        description: 'estPrevisionnel',
        title: 'Prévisionnel',
        isBoolean: true,
        className: 'border-bottom'
    },
    {
        description: 'estVariable',
        title: 'Variable',
        isBoolean: true,
        className: 'border-bottom'
    },
    {
        title: 'Début Exercice',
        description: 'budget.dateDebut',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Fin Exercice',
        description: 'budget.dateFin',
        isDate: true,
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Date de création',
        description: 'dateCreation',
        isDate: true,
        className: 'border-bottom'
    },
    {
        description: 'dateDerniereModification',
        title: 'Modification',
        isDate: true
    }
];
