import * as yup from 'yup';

export const RegistrationSchema = yup.object().shape({
    designation: yup.string().required().min(4),
    code: yup
        .string()
        .required()
        .min(3)
        .matches(/[A-Z]/, 'Le code doit être en Majuscule'),
    adresseUne: yup.string().required().min(4),
    rccm: yup.string().required().min(4),
    prenom: yup.string().required().min(4),
    nom: yup.string().required().min(4),
    postnom: yup.string().required().min(4),
    email: yup.string().email().required(),
    emailProprietaire: yup.string().email().required(),
    adresseProprietaire: yup.string().required().min(4),
    numeroTel: yup
        .string()
        .required()
        .min(10)
        .matches(/[0-9]/, 'Entrez le numéro au format 243 xxx xxx xxx'),
    entrepriseNumero: yup
        .string()
        .required()
        .min(10)
        .matches(/[0-9]/, 'Entrez le numéro au format 243 xxx xxx xxx'),
    aggree: yup
        .boolean()
        .oneOf([true], 'Vous devez accepter les termes et les conditions')
});
