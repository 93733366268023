import { GENERIC_ACTIONS_TYPE, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner les superDealers emoney
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function AllOrganisationSelector({
    formDispatcher,
    formState,
    idProperty = 'code',
    isAddAditionalOptions = false,
    additionnalOptions = [{ id: 'all', code: 'all', designation: 'Tous' }]
}) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        superDealerKinDB,
        etatSuperDealerKinDB,
        allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        superDealerKinDB: reduxState.allSuperDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.allSuperDealerKinDB.etat,
        allCashCollecteurKinDB: reduxState.allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB: reduxState.etat.allCashCollecteurKinDB.etat
    }));

    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { estAll: true, typeEntite: nappUser?.typeEntite }
            },
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_CASH_COLLECTEUR_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_CASH_COLLECTEUR_KIN_DB,
                etat: etatAllCashCollecteurKinDB
            }
        ]
    });

    const renderContent = () => {
        if (
            etatSuperDealerKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS &&
            etatAllCashCollecteurKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <CustomRow>
                    <CustomDropdown
                        options={[
                            {
                                code: TYPE_ORGANISATION.NAPP,
                                designation: TYPE_ORGANISATION.NAPP,
                                id: TYPE_ORGANISATION.NAPP
                            },
                            ...superDealerKinDB,
                            ...allCashCollecteurKinDB
                        ]}
                        defaultOption="Selectionner Organisation"
                        label="Organisation: "
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedOrganisation"
                        id="selectedOrganisationCreationApproCompteEmoney"
                        // uiValidator={CreationApproCompteEmoneySchema}
                        formState={formState}
                        idProperty={idProperty}
                        keyProperty="id"
                        additionnalOptions={
                            isAddAditionalOptions ? additionnalOptions : []
                        }
                    />
                </CustomRow>
            );
        }
        if (
            etatSuperDealerKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC ||
            etatAllCashCollecteurKinDB ===
                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
