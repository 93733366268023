import { FORM_CONST } from '@napp-inc/jnapp-util';
import { Alert } from 'react-bootstrap';
import { RefetchListData } from '../special-components/refetch-list-data';

/**
 * Composant personnalisé des alertes
 * @param {Object} param0 object qui contient les props du composant:
 * @param {Object || String} param0.error Texte de message ou Object qui contient les erreurs du composant dans la propriété message
 * @param {String} param0.successMessage Texte de message de succès
 * @param {String} param0.status Status du composant parent
 * @returns {React.Component}
 */
export function CustomAlert({
    error,
    successMessage,
    status,
    infoMessage,
    variant = 'info',
    children,
    isRefetchAllowed = false,
    refetchFunctionName,
    functionName,
    nodeName,
    isDynamicNode,
    dynamicNodeName,
    payload,
    formater,
    onError
}) {
    const classNameToSend = isRefetchAllowed
        ? 'd-flex justify-content-between'
        : '';
    if (status) {
        let messageToShow = '';
        switch (status) {
            case FORM_CONST.CREATION_SUCCESS:
                messageToShow = 'Enregistrement effectué';
                break;
            case FORM_CONST.MODIFICATION_SUCCESS:
                messageToShow = 'Modification effectuée';
                break;
            default:
                messageToShow = '';
                break;
        }
        if (!messageToShow) return null;
        return <Alert variant="success">{messageToShow}</Alert>;
    }
    if (successMessage) {
        return (
            <Alert variant="success" className={classNameToSend}>
                <div>{successMessage}</div>
                {isRefetchAllowed ? (
                    <div>
                        <RefetchListData
                            functionName={refetchFunctionName || functionName}
                            nodeName={nodeName}
                            isDynamicNode={isDynamicNode}
                            dynamicNodeName={dynamicNodeName}
                            payload={payload}
                            formater={formater}
                            onError={onError}
                        />
                    </div>
                ) : null}
            </Alert>
        );
    }
    if (error) {
        const errorMessage = typeof error === 'string' ? error : error?.message;
        return (
            <Alert variant="danger" className={classNameToSend}>
                <div>{errorMessage}</div>
                {isRefetchAllowed ? (
                    <div>
                        <RefetchListData
                            functionName={refetchFunctionName || functionName}
                            nodeName={nodeName}
                            isDynamicNode={isDynamicNode}
                            dynamicNodeName={dynamicNodeName}
                            payload={payload}
                            formater={formater}
                            onError={onError}
                        />
                    </div>
                ) : null}
            </Alert>
        );
    }
    if (infoMessage) {
        return (
            <Alert variant="info" className={classNameToSend}>
                <div>{infoMessage}</div>
                {isRefetchAllowed ? (
                    <div>
                        <RefetchListData
                            functionName={refetchFunctionName || functionName}
                            nodeName={nodeName}
                            isDynamicNode={isDynamicNode}
                            dynamicNodeName={dynamicNodeName}
                            payload={payload}
                            formater={formater}
                            onError={onError}
                        />
                    </div>
                ) : null}
            </Alert>
        );
    }
    if (children) {
        return (
            <Alert variant={variant} className={classNameToSend}>
                <div>{children}</div>
                {isRefetchAllowed ? (
                    <div>
                        <RefetchListData
                            functionName={refetchFunctionName || functionName}
                            nodeName={nodeName}
                            isDynamicNode={isDynamicNode}
                            dynamicNodeName={dynamicNodeName}
                            payload={payload}
                            formater={formater}
                            onError={onError}
                        />
                    </div>
                ) : null}
            </Alert>
        );
    }
    // if (isRefetchAllowed) {
    //     return (
    //         <Alert variant={variant} className={classNameToSend}>
    //             <div>{children}</div>
    //             <div>
    //                 <RefetchListData
    //                     functionName={refetchFunctionName || functionName}
    //                     nodeName={nodeName}
    //                     isDynamicNode={isDynamicNode}
    //                     dynamicNodeName={dynamicNodeName}
    //                     payload={payload}
    //                     formater={formater}
    //                     onError={onError}
    //                 />
    //             </div>
    //         </Alert>
    //     );
    // }
    return null;
}
