import { DB_NODE, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';

export function ListTypeStockForm() {
    return (
        <ListGenericForm
            reduxPropertyName="typesStock"
            functionName={URL_CONST.GET_LIST_TYPE_STOCK}
            nodeName={DB_NODE.TYPE_STOCK}
            formater={(data) =>
                data.map((item) => ({
                    ...item,
                    categories: item?.categoriesMarchand?.join(' - ')
                }))
            }
            mapper={[
                {
                    field: 'code',
                    displayName: 'code'
                },
                {
                    field: 'designation',
                    displayName: 'designation'
                },
                {
                    field: 'categories',
                    displayName: 'Categorie'
                }
            ]}
        />
    );
}
