export const SignInRelPath = {
    name: '',
    path: 'sign-in'
};

export const SignUpRelPath = {
    name: '',
    path: 'sign-up'
};

export const ForgotPasswordRelPath = {
    name: '',
    path: 'forgot-password'
};

export const ConfirmSignUpRelPath = {
    name: '',
    path: 'confirm-registration'
};
