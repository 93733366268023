import { useFormGlobalReducer, useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    FORM_CONST,
    TODAY,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

const defaultFields = {
    removeFilters: []
};

export function ListCommandeFlashSenderForm({
    etat,
    filterDataList,
    isSenderFlash = true,
    functionName = URL_CONST.GET_LIST_COMMANDE_FLASH_BY_ETAT,
    reduxPropertyName,
    nodeName,
    fetchType = FETCH_LISTENING,
    isConnectRemoveFilter = false,
    isRowSelector = false,
    viewSender
}) {
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { uid } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields } = formState;
    const removeFilterBuilder = (elements, meta) => {
        if (!isConnectRemoveFilter) return;
        if (!meta?.debut || !meta?.fin) return;
        const { debut, fin } = meta;
        const removeFilters = [
            {
                property: 'sendeur.reference',
                value: uid,
                operator: FILTER_OPERATOR.EQUAL,
                type: FILTER_TYPE.WHERE,
                isRequired: true
            },
            {
                property: 'ancienEtat',
                value: etat,
                operator: FILTER_OPERATOR.EQUAL,
                type: FILTER_TYPE.WHERE
            },
            {
                property:
                    'datePrecedenteDerniereModificationDateDerniereModification',
                type: FILTER_TYPE.ORDER_BY,
                value: 'asc'
            },
            {
                value: `${debut}${TODAY.nowUtc()}`,
                type: FILTER_TYPE.START_AT
            },
            {
                type: FILTER_TYPE.END_AT,
                value: `${fin}~`
            }
        ];
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'removeFilters',
                data: removeFilters
            }
        });
    };
    return (
        <ListGenericForm
            viewSender={viewSender}
            isRowSelector={isRowSelector}
            onSuccess={removeFilterBuilder}
            filterDataList={filterDataList}
            isLinkToDetail={false}
            isSenderFlash={isSenderFlash}
            path={DB_NODE.COMMANDE_FLASH}
            fetchType={fetchType}
            reduxPropertyName={reduxPropertyName}
            functionName={functionName}
            payload={{ etat, estMax: true }}
            nodeName={nodeName}
            listenerFilters={[
                {
                    property: 'sendeur.reference',
                    value: uid,
                    operator: FILTER_OPERATOR.EQUAL,
                    type: FILTER_TYPE.WHERE,
                    isRequired: true
                },
                {
                    property: 'etat',
                    value: etat,
                    operator: FILTER_OPERATOR.EQUAL,
                    type: FILTER_TYPE.WHERE
                },
                {
                    type: FILTER_TYPE.ORDER_BY,
                    property: 'dateDerniereModification'
                },
                {
                    value: TODAY.nowUtc(),
                    type: FILTER_TYPE.START_AT
                }
            ]}
            removeFilters={fields.removeFilters}
            mapper={[
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'Source',
                    position: 1
                },
                {
                    field: 'client.designation',
                    displayName: 'Client',
                    position: 2
                },
                {
                    field: 'quantite',
                    displayName: 'quantite',
                    position: 3
                },
                {
                    field: 'produit.designation',
                    displayName: 'produit',
                    position: 4
                },
                {
                    field: 'etat',
                    displayName: 'etat',
                    position: 5
                },
                {
                    field: 'numero',
                    displayName: 'Numéro',
                    position: 6
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création',
                    position: 7
                },
                {
                    field: 'dateDerniereModification',
                    displayName: 'Dernière modification',
                    position: 8
                },
                {
                    field: 'dateAttenteValidation',
                    displayName: 'Date Attente Validation',
                    isDate: true
                }
            ]}
        />
    );
}
