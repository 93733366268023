import { CustomButton } from './button';

/**
 * Bouton avec effet de loader lors de son déclenchement
 * @param {Object} param0 Object contenant les props du composant:
 * @param {string} param0.size Taille du composant basée sur Bootstrap
 * @param {boolean} param0.disabled Désactivé ou non
 * @param {boolean} param0.isActionEncours Affiche le loader en fonction d'une action spécifique
 * @param {string} param0.type Type de composant
 * @param {string} param0.className Classe CSS
 * @param {string} param0.text Texte du composant
 * @param {Function} param0.onClick Fonction qui permet d'envoyer des actions au store du composant ou de déclencher une action du composant
 * @param {React.Component} param0.icon Icône du composant
 * @returns {React.Component}
 */
export function CustomButtonLoader({
    disabled,
    text,
    icon,
    type = 'submit',
    size = 'md',
    className = '',
    onClick,
    isActionEncours = true,
    title
}) {
    return (
        <CustomButton
            className={className}
            size={size}
            type={type}
            disabled={disabled}
            onClick={onClick}
        >
            {disabled && isActionEncours ? (
                <div className="spinner-border" role="status" />
            ) : (
                <span title={title}>
                    {icon} {text}
                </span>
            )}
        </CustomButton>
    );
}
