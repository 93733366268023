import { Button } from 'react-bootstrap';
import { BiFilterAlt } from 'react-icons/bi';
import React, { useState } from 'react';
import { fetchElement, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { payloadBuilder } from '../../../util';
import { CustomOffCanvas } from '../../../components';

const defaultDatePayloadMapper = [
    {
        name: 'dateDebut',
        property: 'dateDebut',
        isRequired: true,
        displayName: 'Date début'
    },
    {
        name: 'dateFin',
        property: 'dateFin',
        isRequired: true,
        displayName: 'Date fin'
    }
];

/**
 * Composant de base pour les formulaires de rapport
 * @param {Object} param0 Objet passé en paramètre avec les propriétés suivantes:
 * @param {Object} param0.contents Contenu à afficher
 * @param {Object} param0.formState Etat du formulaire
 * @param {Function} param0.formDispatcher Dispatcher du formulaire
 * @param {Boolean} param0.isFetching Indique si le formulaire est en cours de chargement
 * @param {String} param0.divContentClassName Classe css du div contenant le contenu
 * @param {React.Component} param0.children Composant enfant
 * @param {Array} param0.defaultPayloadMapperValues Valeurs par défaut
 * @param {Array} param0.payloadMapper Tableau des éléments à mapper du payload,
 * il doit contenir les propriétés suivantes:
 * [{
 *     name: 'nom du champ dans le fiels du formulaire',
 *    property: 'propriété à envoyer dans le payload',
 *   isRequired: true,
 *  displayName: 'Nom du champ afficher, en cas d'erreur'
 * }]
 * @param {String} param0.functionName Nom de la fonction à appeler
 * @param {Function} param0.formater Fonction pour formatter les données
 * @param {Boolean} param0.isAddDateOnPayloadMapper Indique s'il faut ajouter les dates dans le payload mapper
 * @param {String} param0.elementPropertyName Nom de la propriété de l'élément
 * @param {String} param0.CustomOffCanvasButtonText Texte du bouton pour afficher le offcanvas
 * @param {Boolean} param0.isShouldShowDateRange @alias CustomOffCanvas
 * @param {String} param0.debutName @alias CustomOffCanvas
 * @param {String} param0.finName @alias CustomOffCanvas
 * @param {String} param0.dateDebutName @alias CustomOffCanvas
 * @param {String} param0.dateFinName @alias CustomOffCanvas
 * @param {String} param0.selectedDateRangeReportName @alias CustomOffCanvas
 * @param {String} param0.searchButtonText @alias CustomOffCanvas
 * @param {String} param0.offCanvasTitle @alias CustomOffCanvas
 * @returns React.Component
 */
export function BaseReportTemplateForm({
    contents,
    formState,
    formDispatcher,
    isFetching,
    divContentClassName,
    children,
    defaultPayloadMapperValues,
    payloadMapper,
    functionName,
    // formater,
    isAddDateOnPayloadMapper = true,
    elementPropertyName = 'mouvements',
    CustomOffCanvasButtonText = 'Filtres',
    isShouldShowDateRange,
    debutName,
    finName,
    dateDebutName,
    dateFinName,
    selectedDateRangeReportName,
    searchButtonText,
    offCanvasTitle,
    onSuccess
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({
        firebaseUser
    });
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow((s) => !s);
    const handleClose = () => setShow(false);
    const renderContent = () => {
        if (contents) {
            return Object.keys(contents).map((key) => (
                <React.Fragment key={key}>{contents[key]()}</React.Fragment>
            ));
        }
        return null;
    };
    const { fields, elements } = formState;
    const fetchReport = () => {
        const payload = payloadBuilder({
            fields,
            defaultPayloadMapperValues,
            payloadMapper: isAddDateOnPayloadMapper
                ? payloadMapper.concat(defaultDatePayloadMapper)
                : payloadMapper,
            formDispatcher
        });
        if (!payload) return;
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName,
                payload,
                functionName,
                etat: elements[elementPropertyName].status,
                formDispatcher,
                reduxDispatcher,
                formater: (data) => [data],
                onSuccess
            })
        );
    };
    return (
        <>
            <div className="d-flex flex-row justify-content-end mb-3">
                <Button
                    variant="outline-dark"
                    onClick={toggleShow}
                    className="me-2"
                >
                    {CustomOffCanvasButtonText} <BiFilterAlt />
                </Button>
                <CustomOffCanvas
                    elementPropertyName={elementPropertyName}
                    formState={formState}
                    formDispatcher={formDispatcher}
                    fetchReport={fetchReport}
                    isFetching={isFetching}
                    show={show}
                    handleClose={handleClose}
                    isShouldShowDateRange={isShouldShowDateRange}
                    debutName={debutName}
                    finName={finName}
                    dateDebutName={dateDebutName}
                    dateFinName={dateFinName}
                    selectedDateRangeReportName={selectedDateRangeReportName}
                    searchButtonText={searchButtonText}
                    offCanvasTitle={offCanvasTitle}
                >
                    {children}
                </CustomOffCanvas>
            </div>
            <div className={divContentClassName}>{renderContent()}</div>
        </>
    );
}
