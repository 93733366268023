import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../components';
import { ImputationStockFormSchema as uiValidator } from './validation';
import { URL_CONST, formatCodeDesignation } from '../../../util';

const defaultFields = {
    designation: '',
    selectedTypeOperation: {},
    selectedParent: {},
    isParent: false
};

const defaultDependencies = { fields: ['imputation'] };

export function CreationImputationCaisseForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, organisation, imputations, etatImputation } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            organisation: reduxState.organisation,
            imputations: reduxState.imputations,
            etatImputation: reduxState.etat.imputations.etat
        }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const organization = organisation?.entreprise
        ? organisation?.entreprise?.code
        : organisation?.code;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_IMPUTATION_ORGANISATION,
                nodeName: DB_NODE.IMPUTATION,
                etat: etatImputation
            }
        ]
    });
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const { designation, selectedTypeOperation } = fields;
        const payloadToAdd = {};
        if (fields.isParent) {
            payloadToAdd.parent = { code: fields.selectedParent.code };
        }
        const payload = {
            newImputation: {
                code: formatCodeDesignation({
                    partToAdd: 'NAPP_IMPUTATION_CAISSE_',
                    designation
                }),
                designation,
                typeOperation: selectedTypeOperation.code,
                ...payloadToAdd
            },
            creator
        };
        if (organization === TYPE_ORGANISATION.NAPP.toLocaleUpperCase()) {
            payload.newImputation.estNapp =
                organization === TYPE_ORGANISATION.NAPP.toLocaleUpperCase();
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_IMPUTATION,
                payload,
                fields,
                reduxNodeName: DB_NODE.IMPUTATION,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'imputation',
                    etat: etatImputation
                }
            ]}
        >
            <CustomRow>
                <CustomInput
                    isInputGroup={false}
                    label="Designation* : "
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le nom"
                    formDispatcher={formDispatcher}
                    name="designation"
                    id="designationCreationCharge"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    label="Type Opération* : "
                    options={[
                        { code: 'ENTREE', designation: 'Entrée' },
                        { code: 'SORTIE', designation: 'Sortie' }
                    ]}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeOperation"
                    id="selectedTypeOperationCreationImputationStock"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomSwitchButton
                formDispatcher={formDispatcher}
                formState={formState}
                id="estParentId"
                text="Renseigner une dépendance?"
                value={fields.isParent}
                name="isParent"
                divClassName="ms-2 col-md-8 mb-3"
            />
            <CustomRow isShouldBeRendered={fields.isParent}>
                <CustomDropdown
                    options={imputations}
                    label="Parent* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedParent"
                    defaultOption="Selectionner une imputation"
                    id="selectedParentCreationImputationStock"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
