import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ListCaisseEmoneyAllSuperDealerForm } from './list-caisse-emoney';
import { ReleveCaisseForm } from './releve-caisse';
import { ListNiveauCaisseEmoneySuperDealerForm } from './list-niveau-caisse';
import { EvolutionCaisseSuperDealerForm } from './evolution-caisse';

export function ReleveCaisseEmoneyViewLayout() {
    const [key, setKey] = useState('caisse');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Caisse Organisation</h1>
            </div>
            <Tabs
                defaultActiveKey="caisse"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="caisse" title="Solde">
                    {key === 'caisse' && <ListCaisseEmoneyAllSuperDealerForm />}
                </Tab>
                <Tab eventKey="niveau" title="Niveau Caisse">
                    {key === 'niveau' && (
                        <ListNiveauCaisseEmoneySuperDealerForm />
                    )}
                </Tab>
                <Tab eventKey="evolution" title="Evolution Caisse">
                    {key === 'evolution' && <EvolutionCaisseSuperDealerForm />}
                </Tab>
                <Tab eventKey="releve" title="Relevé">
                    {key === 'releve' && <ReleveCaisseForm />}
                </Tab>
            </Tabs>
        </div>
    );
}
