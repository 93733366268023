import React, { useEffect } from 'react';
import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    COMPLEMENTARITE,
    FORM_CONST,
    formInitialState,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomRow,
    CustomRadioButton,
    CustomLabel,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse
} from '../../../../components';

const defaultFields = {
    complementarite: 'Figée'
};
export function ComplementariteStock() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );
    const { idToken, creator } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!fields.complementarite) return;
        const payload = {
            creator,
            typeComplementarite: fields.complementarite
        };
        formDispatcher(
            submitPutAction({
                idToken,
                fields,
                functionName: URL_CONST.PUT_TYPE_COMPLEMENTARITE,
                payload,
                reduxDispatcher
                // uiValidator,
            })
        );
    };

    useEffect(() => {
        if (organisation?.typeComplementarite) {
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field: 'complementarite',
                    data: organisation.typeComplementarite
                }
            });
        }
    }, [formDispatcher, organisation.typeComplementarite]);

    return (
        <>
            <CustomRow>
                <CustomLabel
                    text="Type Complémentarité*"
                    className="col-md-4"
                />
                <CustomRadioButton
                    divClassName="offset-md-1 col-md-3"
                    label="Figée"
                    name="complementarite"
                    id="isIdCompFiege"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    value={COMPLEMENTARITE.FIGEE}
                />
                <CustomRadioButton
                    divClassName="col-md-3"
                    label="Rotative"
                    name="complementarite"
                    id="isIdCompRotative"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    value={COMPLEMENTARITE.ROTATIVE}
                />
            </CustomRow>
            <CustomCollapse
                isIn={fields.complementarite === COMPLEMENTARITE.ROTATIVE}
            >
                <fieldset>
                    <legend>
                        <p className="note">
                            Une liste des comptes fairont partie de cette
                            Complémentarité
                        </p>
                    </legend>
                </fieldset>
            </CustomCollapse>
            <CustomCollapse
                isIn={fields.complementarite === COMPLEMENTARITE.FIGEE}
            >
                <fieldset>
                    <legend>
                        <p className="note">
                            Un seul compte sera utilisé pour cette
                            Complémentarité
                        </p>
                    </legend>
                </fieldset>
            </CustomCollapse>
            <CustomRow>
                <CustomCol>
                    <CustomButtonLoader
                        className="btn-success mt-3"
                        onClick={handleSubmit}
                        text="Mettre à jour"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </CustomCol>
            </CustomRow>
        </>
    );
}
