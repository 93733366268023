import { Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';
import { RapportDashboardCaisseEmoneyFormTranspose } from './rapport-caisse-emoney-transpose';

export function RapportCaisseEmoneyOrganisationViewLayout() {
    const [key, setKey] = useState('report');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Rapport Caisse Emoney</h1>
            </div>

            <Tabs
                defaultActiveKey="report"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="report" title="Rapport Caisse Emoney">
                    {key === 'report' && (
                        <RapportDashboardCaisseEmoneyFormTranspose />
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}
