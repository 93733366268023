import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, DB_NODE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { useState } from 'react';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    CustomSwitchButton,
    DetailWrapper,
    OctroiRevocationServiceComponent,
    RadioButtonWrapper,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { useComponentSwitcher } from '../../../util/hook';
import { DetailChargeFonctionEmploye } from './detail-charge-fonction';
import { employeSchema, schema } from './detail-fields';

const defaultFields = {
    actionEncours: '',
    toAdd: [],
    toRemove: [],
    toAddInputSearch: '',
    toRemoveInputSearch: ''
};

export function DetailDroitAccesForm() {
    const { componentSwitcher } = useComponentSwitcher();
    const [isUpdateRightAccess, setUpdateRightAccess] = useState(false);
    const nodeNameValue = componentSwitcher?.isAllEmploye
        ? 'employesOrganisation'
        : 'users';
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: nodeNameValue, defaultFields });
    const { fields, form } = formState;
    const updateEtat = (etat, estDesactive) => {
        const payload = {
            uidUserADesactiver: entity?.id,
            estDesactive
        };
        const actionEncours = etat;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_DESACTIVE_USER,
                payload,
                fields,
                reduxNodeName: DB_NODE.USER,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={
                componentSwitcher?.isAllEmploye
                    ? URL_CONST.GET_EMPLOYE_BY_ID
                    : URL_CONST.GET_USER_BY_ID
            }
            nodeName={
                componentSwitcher?.isAllEmploye ? DB_NODE.EMPLOYE : DB_NODE.USER
            }
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Droit d'accès"
            entity={entity}
            id={id}
            schema={componentSwitcher?.isAllEmploye ? employeSchema : schema}
            idToken={idToken}
        >
            {!componentSwitcher?.isAllEmploye && (
                <UserServiceWrapper
                    services={[
                        { code: 'test' },
                        CODE_SERVICE.SERVICE_CODE_REVENDEUR
                            .CODE_SERVICE_MODULE_ADMINISTRATION
                            .MODIFIER_SYST_SERVICE_USER
                    ]}
                >
                    <CustomRow>
                        {entity?.estDesactive ? (
                            <CustomCol className="col-3 col-md-2">
                                <CustomConfirmButton
                                    type="button"
                                    backdrop="static"
                                    text="Ré-activer utilisateur"
                                    className="btn-success"
                                    confirmOnClick={() =>
                                        updateEtat(ETAT.ETAT_ADOPTE, false)
                                    }
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                />
                            </CustomCol>
                        ) : (
                            <CustomCol className="col-3 col-md-2">
                                <CustomConfirmButton
                                    type="button"
                                    backdrop="static"
                                    text="Désactiver utilisateur"
                                    className="btn-danger"
                                    confirmOnClick={() =>
                                        updateEtat(ETAT.ETAT_ANNULER, true)
                                    }
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ANNULER
                                    }
                                    confirmText="Poursuivre"
                                    cancelText="Abandonner"
                                    header="Confirmation Annulation"
                                />
                            </CustomCol>
                        )}
                    </CustomRow>
                    <CustomRow>
                        <CustomSwitchButton
                            id="isUpdateRightAccess"
                            text="Modifier droit d'accès"
                            defaultChecked={isUpdateRightAccess}
                            name="isUpdateRightAccess"
                            divClassName="offset-1 col-md-8"
                            callback={setUpdateRightAccess}
                        />
                    </CustomRow>
                    {isUpdateRightAccess && (
                        <OctroiRevocationServiceComponent
                            id={id}
                            formState={formState}
                            formDispatcher={formDispatcher}
                            isOrganisation={false}
                        />
                    )}
                </UserServiceWrapper>
            )}
            {componentSwitcher?.isAllEmploye && (
                <RadioButtonWrapper title="Détail charge">
                    <DetailChargeFonctionEmploye
                        typeContrat={entity?.typeContrat}
                        codeFonction={entity?.fonction?.code}
                    />
                </RadioButtonWrapper>
            )}
        </DetailWrapper>
    );
    return renderContent();
}
