import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ReleveCaisseForm } from './releve-caisse';
import { RapportCaisseForm } from './rapport-caisse';

export function ReleveCaisseViewLayout() {
    const [key, setKey] = useState('caisse');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Rapport de caisse</h1>
            </div>
            <Tabs
                defaultActiveKey="caisse"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="caisse" title="Caisse">
                    {key === 'caisse' && <RapportCaisseForm />}
                </Tab>
                <Tab eventKey="releve" title="Relevé">
                    {key === 'releve' && <ReleveCaisseForm />}
                </Tab>
            </Tabs>
        </div>
    );
}
