import React, { useContext } from 'react';
import { StateContext } from '../../context/state-context';
import { Menu } from '../menu/menu';

/**
 * Menu des autres layouts
 * @param {Object} param0 objet qui contient les props du composant:
 * @param {String} param0.className Classe CSS
 * @returns {React.Component}
 */
export function SettingMenu({ className }) {
    const { configMenus } = useContext(StateContext);
    return (
        <div className={`napp-nav-wrapper ${className}`}>
            {configMenus
                .filter(({ isVisible, index }) => isVisible && !index)
                .map((element) => (
                    <Menu
                        item={element}
                        key={element.name}
                        menuClass="ActionList-item"
                        menuClassActive="ActionList-item-active"
                        menuClassText="ActionList-item-label"
                        submenuClass="ActionList-item-submenu"
                        submenuClassActive="ActionList-item-submenu-active"
                    />
                ))}
        </div>
    );
}
