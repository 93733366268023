import { fetchElement } from '@napp-inc/jnapp-hook';
import { FORM_CONST } from '@napp-inc/jnapp-util';
import { useEffect } from 'react';

/**
 * Ce hook effectue un appel fonction sur base d'une condition et en un certain temps
 * @param {Object} param0
 * @param {String} param0.name : Le nom de la propriété du champ de saisie
 * @param {String} param0.preview : Le nom de la propriété du state qui sauvegardera la valeur précédente de {param0.name}
 * @param {Boolean} param0.isObject : Indique si le champ de saisie est un Input ou une Dropdown
 * @param {Function} param0.formDispatcher : Fonction de dispatch liée au formulaire
 * @param {Object} param0.formState : state global du formulaire
 * @param {Number} param0.delay : temps de delais avant de lancer la fonction
 * @param {String} param0.idToken : token
 * @param {String} param0.functionName : fonction cloud
 * @param {String} param0.elementPropertyName : nom de la propriété cible dans formState.element
 * @param {String} param0.creator : uid de l'utilisateur @deprecated
 * @param {String} param0.reduxNodeName : Noeud redux
 * @param {String} param0.reduxDispatcher : Dispatcher Redux
 */

// FIXME: Remove creator
export function useDebounce({
    name,
    preview = 'preview',
    isObject = true,
    formDispatcher,
    formState,
    delay = 1500,
    idToken,
    functionName,
    elementPropertyName,
    creator,
    reduxNodeName,
    reduxDispatcher,
    propertyInName = 'designation',
    payloadToAdd,
    dataLength = 2,
    condition = true,
    onSuccess
}) {
    const { fields, elements } = formState;
    const value = isObject
        ? fields[name][propertyInName] || ''
        : fields[name] || '';
    const previewValue = fields[preview];
    useEffect(() => {
        const timer = setTimeout(() => {
            if (
                previewValue !== value &&
                value.length > dataLength &&
                condition
            ) {
                const { status: etat } = elements[elementPropertyName];
                formDispatcher({
                    type: FORM_CONST.TEXT_FIELD_CHANGE,
                    payload: { field: preview, data: value }
                });
                const payload = payloadToAdd || {
                    creator,
                    textDesignation: value
                };
                formDispatcher(
                    fetchElement({
                        idToken,
                        functionName,
                        elementPropertyName,
                        etat,
                        payload,
                        reduxNodeName,
                        reduxDispatcher,
                        onSuccess
                    })
                );
            }
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [
        value,
        delay,
        previewValue,
        formDispatcher,
        preview,
        idToken,
        functionName,
        elementPropertyName,
        creator,
        reduxNodeName,
        reduxDispatcher,
        elements,
        dataLength,
        payloadToAdd,
        condition,
        onSuccess
    ]);
}
