import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    DB_NODE,
    fromStringToTimestamp
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomInputDate,
    CustomRow,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { CrediterCautionFormValidator as uiValidator } from './validation';
// import { mapper as fieldsMapper } from './form-fields';
import { REDUX_NODE_NAME } from '../../../redux';

/**
 * @desc defaultFields for direction
 */
const defaultFields = {
    dateOperation: '',
    montant: 0,
    numeroCompteExterne: '',
    selectedDevise: {},
    selectedSuperDealer: {},
    selectedInstitutionFinanciere: {},
    selectedMode: {},
    selectedTypeOperation: { code: 'Créditer' },
    selectedCompte: {},
    isCompte: false
};

export function CreationCrediterCautionForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        organisation,
        comptes,
        etatCompte,
        // monnaiesLocales,
        // etatMonnaieLocale,
        superDealerKinDB,
        etatSuperDealerKinDB,
        institutionsFinanciere,
        etatInstitutionsFinanciere
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation,
        comptes: reduxState.comptes,
        etatCompte: reduxState.etat.comptes.etat,
        // monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
        //     ...item,
        //     code: item.code || item.devise
        // })),
        // etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat,
        superDealerKinDB: reduxState.superDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionsFinanciere: reduxState.etat.institutionsFinanciere.etat
    }));
    const { idToken, isNapp } = useGetUserDetail({ firebaseUser, nappUser });

    const defaultDependencies = {};
    if (isNapp) {
        defaultDependencies.fields = ['banques', 'superDealerKinDB', 'comptes'];
    } else {
        defaultDependencies.fields = ['banques', 'comptes'];
    }

    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const { fields, form } = formState;
    const formater = (tableau = []) =>
        tableau.map((item) => ({
            ...item,
            code: item.id,
            designation: `${item.numero} - ${item.devise}`
        }));

    /**
     * Recupération des données nécessaires
     */
    const payloadFetchCompteOrganistion = {};
    if (isNapp) {
        payloadFetchCompteOrganistion.typeProprietaire = 'SuperDealer';
    }

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            // {
            //     isOnlyFetch: true,
            //     functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
            //     nodeName: DB_NODE.MONNAIE_LOCALE,
            //     etat: etatMonnaieLocale
            // },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionsFinanciere
            },
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.COMPTE,
                functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
                etat: etatCompte,
                formater,
                payload: payloadFetchCompteOrganistion
                // isCondition: !isNapp
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                payload: { estAll: true, typeEntite: nappUser?.typeEntite },
                etat: etatSuperDealerKinDB,
                isCondition: isNapp
            }
        ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const institutionFinanciereNumero = comptes?.find(
            (item) => item?.id === fields.selectedCompte?.id
        )?.numero;
        const institutionFinanciere = {};
        if (fields.selectedInstitutionFinanciere.code) {
            institutionFinanciere.institutionFinanciere = {
                code: fields.selectedInstitutionFinanciere.code
            };
            institutionFinanciere.numeroCompteExterne =
                institutionFinanciereNumero;
        }
        const generalPayloadToAdd = {};
        if (isNapp) {
            generalPayloadToAdd.codeSuperDealer =
                fields.selectedSuperDealer.code;
        }
        if (fields.selectedTypeOperation.code === 'Créditer') {
            const payload = {
                newCrediterCaution: {
                    montant: fields.montant,
                    ...institutionFinanciere
                },
                dateOperation: fromStringToTimestamp(fields.dateOperation),
                ...generalPayloadToAdd
                // creator
            };
            formDispatcher(
                submitPostAction({
                    idToken,
                    functionName: URL_CONST.POST_CREDITER_CAUTION,
                    reduxNodeName: REDUX_NODE_NAME.CREDITER_CAUTION,
                    payload,
                    fields,
                    reduxDispatcher,
                    uiValidator
                })
            );
        }
    };
    const getCompteBySuperDealer = (cpt, sd, bq) =>
        cpt
            .filter((item) => item?.valeurTypeProprietaire?.code === sd?.code)
            .filter(
                (element) => element?.institutionFinanciere?.code === bq?.code
            );
    const isCompteBySuperDealerExiste = (cpt, sd, bq) => {
        const res = getCompteBySuperDealer(cpt, sd, bq);
        return res.length;
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'banques',
                    etat: etatInstitutionsFinanciere
                },
                {
                    dependency: 'comptes',
                    etat: etatCompte
                },
                {
                    dependency: 'superDealerKinDB',
                    etat: etatSuperDealerKinDB,
                    isCondition: isNapp
                }
            ]}
        >
            <ConditionalRenderingWrapper isShouldBeRendered={isNapp}>
                <CustomRow>
                    <CustomDropdown
                        options={superDealerKinDB}
                        defaultOption="Selectionner SuperDealer"
                        label="Super Dealer* : "
                        labelClassName="col-2"
                        divClassName="col-10"
                        formDispatcher={formDispatcher}
                        name="selectedSuperDealer"
                        id="selectedSuperDealerCreationCrediterCautionId"
                        uiValidator={uiValidator}
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomDropdown
                    idProperty="code"
                    propertyToDisplay="designation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    label="Institution Financière* : "
                    name="selectedInstitutionFinanciere"
                    id="creationRemboursementCautionCompteInstitutFinanciereId"
                    options={institutionsFinanciere}
                    selectionClassName="form-select-md"
                    defaultOption="Selectionner Institution Financière"
                    divClassName="col-sm-10"
                    labelClassName="col-sm-2"
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={getCompteBySuperDealer(
                        comptes,
                        organisation.entreprise
                            ? organisation.entreprise
                            : fields.selectedSuperDealer,
                        fields.selectedInstitutionFinanciere
                    )}
                    defaultOption="Selectionner Compte"
                    label="Compte* : "
                    labelClassName="col-2"
                    divClassName="col-10"
                    formDispatcher={formDispatcher}
                    name="selectedCompte"
                    id="selectedCompteCrediterCautionId"
                    idProperty="id"
                    formState={formState}
                    uiValidator={uiValidator}
                    disabled={
                        !isCompteBySuperDealerExiste(
                            comptes,
                            organisation.entreprise
                                ? organisation.entreprise
                                : fields.selectedSuperDealer,
                            fields.selectedInstitutionFinanciere
                        )
                    }
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedTypeOperation.code === 'Créditer'
                }
            >
                <CustomRow>
                    <CustomInput
                        isFloat
                        type="number"
                        label="Montant* : "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-10 col-sm-10"
                        isInputGroup={false}
                        placeholder="Montant Opération"
                        formDispatcher={formDispatcher}
                        uiValidator={uiValidator}
                        name="montant"
                        id="creationMontantIdCrediterCaution"
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInputDate
                        isInputGroup={false}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        name="dateOperation"
                        id="creationDateIdCrediterCaution"
                        label="Date Opération* : "
                        divClassName="col-sm-10"
                        labelClassName="col-sm-2"
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
