export const schema = [
    {
        title: 'Designation',
        description: 'libelle',
        isLast: false,
        className: 'border-bottom'
    },
    {
        title: 'Date',
        description: 'dateCreation',
        isLast: false,
        className: 'border-bottom'
    },
    {
        title: 'Devise',
        description: 'devise',
        isLast: false,
        className: 'border-bottom'
    },
    {
        title: 'Numéro',
        description: 'numero',
        isLast: false,
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        isLast: false
    }
];
