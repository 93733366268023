import { Add, ETAT } from '@napp-inc/jnapp-util';
import { useState, useEffect } from 'react';

/**
 *
 * @param {Array} list list des commandes d'un etat donné
 * @param {string} etat code etat dont on fait le calcul
 * @returns  {Object}
 */
const calcultTotaux = (list, etat) => {
    const objectToReturn = {
        etat,
        quantite: 0,
        montant: 0
    };
    list.forEach((item) => {
        objectToReturn.quantite = Add(objectToReturn.quantite + item.quantite);
        objectToReturn.montant = Add(objectToReturn.montant + item.prixTotal);
    });
    return objectToReturn;
};

/**
 * Ce hook calcul le total des commandes d'un état, utilisé pour la vue totaux du sender flash
 * @param {Object} param0 objet contenant
 * @param {Array} param0.encours liste des commandes encours
 * @param {Array} param0.livrées liste des commandes livrées
 * @param {Array} param0.annulées liste des commandes annulées
 * @param {Array} param0.attente liste des commandes en attente de validatop,
 *
 * @returns {Object} objet contenant la propriété totaux qui est un tableau
 */
export const useGetTotauxFlashSender = ({
    encours = [],
    attente = [],
    annulees = [],
    livrees = []
}) => {
    const [totaux, setTotaux] = useState([]);
    useEffect(() => {
        const encoursResult = calcultTotaux(encours, ETAT.ETAT_ENCOURS);
        const attenteResult = calcultTotaux(
            attente,
            ETAT.ETAT_ATTENTE_VALIDATION
        );
        const annuleesResult = calcultTotaux(annulees, ETAT.ETAT_ANNULER);
        const livreesResult = calcultTotaux(livrees, ETAT.ETAT_LIVRER);
        setTotaux([
            encoursResult,
            attenteResult,
            annuleesResult,
            livreesResult
        ]);
    }, [encours, attente, annulees, livrees]);
    return { totaux };
};
