import * as yup from 'yup';

export const TransfertSimSchema = yup.object().shape({
    tillNumberAcheteur: yup.string().required(),
    quantiteSim: yup.number().min(10)
});

export const DetailTransfertSimSchema = yup.object().shape({
    isActionEncours: yup.string().required()
});
