import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    // formInitialState,
    FORM_CONST,
    TYPE_CAISSE_EXECUTION_CHARGE,
    TYPE_CAISSE_SECTORIELLE,
    TYPE_CAISSE_GUICHET,
    TYPE_ORGANISATION,
    fromStringToTimestamp
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomConfirmButton,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../../components';
import { URL_CONST, formatCodeDesignation } from '../../../../util';
// import { CreationCaisseFormSchema as uiValidator } from '../validation';
import { REDUX_NODE_NAME } from '../../../../redux';

export function CreationCaisse({
    idToken,
    typeEntite,
    isKinDistribution = false,
    uiValidator,
    initialState
    // formDispatcher
}) {
    /**
     * Redux Dispatcher
     */
    const reduxDispatcher = useDispatch();
    /**
     * Redux Selectors
     */
    const {
        organisation,
        superDealerKinDB,
        etatSuperDealerKinDB,
        monnaiesLocales,
        etatMonnaieLocale,
        agences,
        etatAgence,
        users,
        etatUser,
        typeCaisseOrganisation,
        etatTypeCaisseOrganisation
    } = useSelector((reduxState) => ({
        organisation: reduxState.organisation,
        monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            code: item.code || item.devise
        })),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat,
        agences: reduxState.agences,
        etatAgence: reduxState.etat.agences.etat,
        superDealerKinDB: reduxState.superDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat,
        users: reduxState.users.map((item) => ({
            ...item,
            designation: `${item?.personne?.prenom || ''} ${
                item?.personne?.nom || ''
            }`
        })),
        etatUser: reduxState.etat.users.etat,
        typeCaisseOrganisation: reduxState.typeCaisseOrganisation,
        etatTypeCaisseOrganisation: reduxState.etat.typeCaisseOrganisation.etat
    }));

    /**
     * form state, form dispatcher
     */
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    /**
     * FIELDS, FORM, ELEMENTS, DEPENDECIES
     */
    const { fields, form } = formState;
    // console.log(initialState)
    /**
     * Cloud Functions
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_AGENCE,
                nodeName: DB_NODE.AGENCE,
                etat: etatAgence,
                isCondition: typeEntite === TYPE_ORGANISATION.NAPP
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: {
                    estAll: true,
                    estExclu: true,
                    typeEntite
                },
                isCondition: isKinDistribution
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUser
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_TYPE_CAISSE_ORGANISATION,
                nodeName: REDUX_NODE_NAME.TYPE_CAISSE_ORGANISATION,
                etat: etatTypeCaisseOrganisation,
                isCondition:
                    typeEntite !== TYPE_ORGANISATION.NAPP && !isKinDistribution
            }
        ]
    });

    /**
     * submit function
     * @param {Event} event
     * @returns {CF Event}
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const { selectedDevise, selectedTypeEntite, selectedAgence } = fields;
        const payload = {
            newCaisse: {
                devise: selectedDevise?.devise
            }
        };
        const propertyToAdd = {
            innerLevel: {},
            outerLevel: {}
        };
        if (typeEntite === TYPE_ORGANISATION.NAPP) {
            if (
                selectedTypeEntite?.code === TYPE_CAISSE_SECTORIELLE.code ||
                selectedTypeEntite?.code === TYPE_CAISSE_GUICHET.code
            ) {
                propertyToAdd.innerLevel = {
                    code: selectedAgence?.code,
                    designation: selectedAgence?.designation
                };
                payload.codeAgence = selectedAgence?.code;
            }
            if (fields.selectedAgent?.uid) {
                payload.owner = fields.selectedAgent?.uid;
            }

            payload.typeEntite = selectedTypeEntite?.code;
        }
        if (
            typeEntite === TYPE_ORGANISATION.CASH_COLLECTEUR ||
            typeEntite === TYPE_ORGANISATION.REVENDEUR ||
            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
        ) {
            propertyToAdd.innerLevel = {
                code: formatCodeDesignation({
                    partToAdd: `NAPP_CAISSE_${fromStringToTimestamp(
                        new Date()
                    )}_`,
                    designation: fields.designation
                }),
                designation: fields.designation,
                type: fields.selectedTypeCaisse?.code
            };
            if (fields.selectedAgent?.uid) {
                payload.owner = fields.selectedAgent?.uid;
            }
        }
        if (typeEntite === TYPE_ORGANISATION.SUPER_DEALER) {
            if (!!isKinDistribution && !!fields.estEmoney) {
                propertyToAdd.innerLevel.code =
                    fields.selectedSuperDealer?.code;
                propertyToAdd.innerLevel.designation =
                    fields.selectedSuperDealer?.designation;
            } else {
                propertyToAdd.innerLevel.code = formatCodeDesignation({
                    partToAdd: `NAPP_CAISSE_${fromStringToTimestamp(
                        new Date()
                    )}_`,
                    designation: fields.designation
                });
                propertyToAdd.innerLevel.designation = fields.designation;
            }
            if (!isKinDistribution) {
                propertyToAdd.innerLevel.type = fields.selectedTypeCaisse?.code;
            }
            if (fields.selectedAgent?.uid) {
                payload.owner = fields.selectedAgent?.uid;
            }
        }
        payload.newCaisse = {
            ...payload.newCaisse,
            ...propertyToAdd.innerLevel
        };
        // console.log('Clicked', payload);
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CAISSE,
                payload,
                fields,
                reduxNodeName: DB_NODE.CAISSE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    // console.log('initialstate', formState);

    return (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                { dependency: 'monnaie', etat: etatMonnaieLocale },
                {
                    dependency: 'agences',
                    etat: etatAgence,
                    isCondition: typeEntite === TYPE_ORGANISATION.NAPP
                },
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB,
                    isCondition: !!isKinDistribution
                },
                {
                    dependency: 'users',
                    etat: etatUser
                },
                {
                    dependency: 'typeCaisse',
                    etat: etatTypeCaisseOrganisation,
                    isCondition:
                        typeEntite !== TYPE_ORGANISATION.NAPP &&
                        !isKinDistribution
                }
            ]}
        >
            <CustomRow
                isShouldBeRendered={typeEntite === TYPE_ORGANISATION.NAPP}
            >
                <CustomDropdown
                    options={[
                        TYPE_CAISSE_EXECUTION_CHARGE,
                        TYPE_CAISSE_SECTORIELLE,
                        TYPE_CAISSE_GUICHET
                    ]}
                    label="Type Caisse*: "
                    defaultOption="Selectionner Type Caisse"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeEntite"
                    id="selectedTypeEntiteCreationCaisse"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.selectedTypeEntite?.code ===
                        TYPE_CAISSE_SECTORIELLE?.code ||
                    fields.selectedTypeEntite?.code ===
                        TYPE_CAISSE_GUICHET?.code
                }
            >
                <CustomDropdown
                    options={agences}
                    label="Agence*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    defaultOption="Sélectionner l'agence"
                    name="selectedAgence"
                    id="selectedAgenceCreationCaisse"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!!organisation?.estKinDistribution}>
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="estEmoneyId"
                    text="Caisse Emoney?"
                    uiValidator={uiValidator}
                    // value={fields.estEmoney}
                    name="estEmoney"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    typeEntite === TYPE_ORGANISATION.CASH_COLLECTEUR ||
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR ||
                    (typeEntite === TYPE_ORGANISATION.SUPER_DEALER &&
                        !isKinDistribution) ||
                    (!!isKinDistribution && !fields.estEmoney)
                }
            >
                <CustomInput
                    isInputGroup={false}
                    label="Designation*: "
                    labelClassName="col-12 col-md-2"
                    divClassName="col-12 col-md-10"
                    placeholder="Saisissez le nom"
                    formDispatcher={formDispatcher}
                    name="designation"
                    id="designationCreationCaisse"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={!!isKinDistribution && !!fields.estEmoney}
            >
                <CustomDropdown
                    options={superDealerKinDB}
                    defaultOption="Selectionner SuperDealer"
                    label="Super Dealer*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedSuperDealer"
                    id="selectedSuperDealerCreationApproCompteEmoney"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    typeEntite !== TYPE_ORGANISATION.NAPP && !isKinDistribution
                }
            >
                <CustomDropdown
                    options={typeCaisseOrganisation}
                    defaultOption="Selectionner Type Caisse"
                    label="Type Caisse*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeCaisse"
                    id="selectedTypeCaisseCreationApproCompteEmoney"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={monnaiesLocales}
                    label="Devise*"
                    defaultOption="Selectionner Devise"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedDevise"
                    id="selectedDeviseCreationCaisse"
                    formState={formState}
                    propertyToDisplay="devise"
                    idProperty="id"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={users}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner l'agent"
                    label="Responsable Caisse* :"
                    idProperty="uid"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedAgent"
                    id="selectedAgentId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomConfirmButton
                    type="button"
                    backdrop="static"
                    text="Valider"
                    className="btn-primary btn mt-3 ms-2 w-25"
                    confirmOnClick={handleSubmit}
                    disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    isActionEncours={
                        form.status === FORM_CONST.CREATION_EN_COURS
                    }
                />
            </CustomRow>
        </FormWrapper>
    );
}
