import { useEffect, useState } from 'react';

/**
 *
 * @param {Array} usersService
 * @param {Array} featureService
 * @returns Boolean
 */
export function useIsFeatureAllowed(usersService = [], featureService = []) {
    const [isAllowed, setIsAllowed] = useState(false);
    useEffect(() => {
        let test = false;
        if (featureService?.length) {
            test = featureService.some(
                (ftService) =>
                    !!usersService.find(
                        (us) =>
                            us.estActif &&
                            ftService?.code === us?.sysService?.code
                    )
            );
        } else {
            test = !!usersService.find(
                (item) =>
                    item.estActif &&
                    item?.sysService?.code === featureService?.code
            );
        }
        setIsAllowed(test);
    }, [usersService, featureService]);
    return isAllowed;
}
