import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { CreationRattacherNumeroClientForm } from './type';
import { BackComponent } from '../../../components';

export function CreationClientForm() {
    const [key, setKey] = useState('new');
    const renderContent = () => (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <BackComponent />
            </div>
            <Tabs
                defaultActiveKey="new"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="new" title="Nouveau Client">
                    {key === 'new' && (
                        <CreationRattacherNumeroClientForm type={key} />
                    )}
                </Tab>
                <Tab eventKey="rattacher" title="Rattacher Client">
                    {key === 'rattacher' && (
                        <CreationRattacherNumeroClientForm type={key} />
                    )}
                </Tab>
                <Tab eventKey="rattacherAgent" title="Rattacher Agent">
                    {key === 'rattacherAgent' && (
                        <CreationRattacherNumeroClientForm type={key} />
                    )}
                </Tab>
            </Tabs>
        </div>
    );
    return renderContent();
}
