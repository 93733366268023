import { memo } from 'react';
import {
    DB_NODE,
    FETCH_LISTENING,
    FILTER_TYPE,
    TODAY
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

const RenderTypeFonction = memo(({ element }) =>
    !element.estProfilUser ? (
        <span className="badge bg-danger">Non Utilisateur</span>
    ) : (
        <span className="badge bg-secondary">Utilisateur</span>
    )
);

export function ListFonctionUpdateForm() {
    const typeFormater = (item) => <RenderTypeFonction element={item} />;
    return (
        <ListGenericForm
            path={DB_NODE.MODIFICATION_FONCTION}
            fetchType={FETCH_LISTENING}
            listenerFilters={[
                {
                    type: FILTER_TYPE.ORDER_BY,
                    property: 'dateDerniereModification',
                    value: 'asc'
                },
                {
                    type: FILTER_TYPE.START_AT,
                    value: TODAY.nowUtc(),
                    isTimestampNowValue: true
                }
            ]}
            reduxPropertyName="fonctionUpdate"
            functionName={URL_CONST.GET_LIST_MODIFICATION_FONCTION_INITIALS}
            nodeName={REDUX_NODE_NAME.FONCTION_UPDATE}
            mapper={[
                {
                    displayName: 'Designation',
                    field: 'fonction.designation'
                },
                {
                    tdExtractor: (item) => typeFormater(item),
                    displayName: 'Type'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ]}
        />
    );
}
