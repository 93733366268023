import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    AffiliationAgentLivreurCreationRelPath,
    AffiliationAgentLivreurViewLayoutRelPath
} from '../../../routes/registration/configuration/config-marchand-rel-path';
import { ListAffiliationAgentLivreurForm } from './list-affiliation';

export function AffiliationAgentLivreurViewLayout() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Affiliation agent livreur</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${AffiliationAgentLivreurViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.SERVICE_CODE_WEB
                                        .MARCHAND_PRODUIT_MATERIEL
                                        .INITIER_AFFILIATION_AGENT_LIVREUR
                                ]}
                                destination={
                                    AffiliationAgentLivreurCreationRelPath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListAffiliationAgentLivreurForm />
        </>
    );
}
