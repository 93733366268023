import { Outlet } from 'react-router-dom';
import { TokenRefresherComponent } from '../../layout/token-refresher';

/**
 * Contenu principal du site
 * @returns {React.Component}
 */
export function MainContent() {
    return (
        <div className="container-fluid justify-content-center">
            <TokenRefresherComponent />
            <Outlet />
        </div>
    );
}
