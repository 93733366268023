import { FORM_CONST } from '@napp-inc/jnapp-util';
import { IoIosAddCircleOutline } from 'react-icons/io';

/**
 * Button avec une icone de plus,
 * permet de rajouter un élément dans une liste d'un formulaire
 * @param {Object} param0 object with the following properties:
 * @param {String} param0.id id of the item to be add
 * @param {String} param0.field nom de la liste dans les champps fields du state du form
 * @param {Array} param0.list liste des éléments dans laquelle doit figurer l'élément à ajouter
 * @param {Function} param0.formDispatcher fonction de dispatch du formulaire
 * @param {Function} param0.callback function to overlaod the default of the onClick event
 * @param {String} param0.identifier propriété utilisée pour filtrer dans la liste, par défaut c'est l'id
 * @param {Object} param0.style style du bouton
 * @param {String} param0.className classe du bouton
 * @param {Boolean} param0.disabled si le bouton est clicable ou non
 * @param {String} param0.iconClassName classe de l'icone
 * @returns {React.ReactElement}
 */
export function CustomAddButton({
    id,
    list,
    field,
    style = { border: 'none', background: 'none' },
    formDispatcher,
    identifier = 'id',
    className = '',
    callback,
    disabled = false,
    iconClassName = 'text-primary'
}) {
    const handleAdd = (itemId) => {
        if (callback && typeof callback === 'function') {
            callback();
            return;
        }
        const itemToAdd = list.find((item) => item[identifier] === itemId);
        if (itemToAdd) {
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier,
                    field,
                    data: itemToAdd
                }
            });
        }
    };
    return (
        <button
            disabled={disabled}
            className={className}
            type="button"
            style={style}
            onClick={() => handleAdd(id)}
            size="md"
        >
            <span hidden={false}>
                <IoIosAddCircleOutline
                    className={`icon-custom ${iconClassName}`}
                />
            </span>
        </button>
    );
}
