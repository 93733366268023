import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import {
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import {
    useGetDetailForm,
    URL_CONST,
    CorrespondanceCollection
} from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailTunnelForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'tunnels' });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const payload = {
            estOuvert: etat === ETAT.ETAT_OUVERT,
            list: [entity?.id]
        };
        const actionEncours = etat;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_TUNNELS,
                payload,
                fields,
                onSuccess: (item) => {
                    const itemsToAdd = item?.element.map((elm) => ({
                        ...elm,
                        designation:
                            CorrespondanceCollection[elm?.collection] ||
                            elm?.collection,
                        etat: elm?.estOuvert
                            ? ETAT.ETAT_OUVERT
                            : ETAT.ETAT_FERMER
                    }));
                    reduxDispatcher(
                        GENERIC_ITEM_ACTIONS_CREATOR.ajouterItem(
                            itemsToAdd,
                            REDUX_NODE_NAME.TUNNEL
                        )
                    );
                }
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TUNNEL_BY_ID}
            nodeName={REDUX_NODE_NAME.TUNNEL}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Tunnel"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
            formater={(element) => ({
                ...element,
                designation:
                    CorrespondanceCollection[element?.collection] ||
                    element?.collection,
                etat: element?.estOuvert ? ETAT.ETAT_OUVERT : ETAT.ETAT_FERMER
            })}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
                        .ACTIVER_DESACTIVER_API_PARTENAIRE
                ]}
            >
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Fermé"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_FERMER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_FERMER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Ouvrir"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_OUVERT)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_OUVERT
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
