export const schema = [
    {
        title: 'Designation',
        description: 'entreprise.designation',
        className: 'border-bottom'
    },
    {
        title: 'Till Number',
        description: 'entreprise.tillNumber',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom',
        isEtat: true
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];

export const schemaDistributeur = [
    {
        title: 'Designation',
        description: 'entreprise.designation',
        className: 'border-bottom'
    },
    {
        title: 'Marchand',
        description: 'marchand.designation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom',
        isEtat: true
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
