import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { useLocation } from 'react-router-dom';
import { ListVersementForm } from './list-versement';
import { ShowNewComponent } from '../../../components';
import {
    MarchandVersementCreationRelPath,
    MarchandVersementListRelPath
} from '../../../routes/registration/configuration/config-marchand-rel-path';

export function MarchandVersementSetting() {
    const { pathname } = useLocation();
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Versement</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${MarchandVersementListRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                        .CODE_SERVICE_MODULE_FINANCE
                                        .INITIER_VERSEMENT,
                                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                        .CODE_SERVICE_MODULE_FINANCE
                                        .INITIER_VERSEMENT_POUR_AUTRE
                                ]}
                                destination={
                                    MarchandVersementCreationRelPath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListVersementForm />
        </>
    );
}
