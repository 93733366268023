/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { useDispatch } from 'react-redux';
import {
    PROFIL_SYSTEM,
    TIME,
    TODAY,
    TYPE_ORGANISATION,
    resolvePathPropertyOfObject
} from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../util';
import { ReduxReportTemplate } from '../template';
import { REDUX_NODE_NAME } from '../../../redux';
import { CustomAlert, NumberDisplayer } from '../../../components';

export function RapportDashboardBalanceFormTranspose(/* { typeOrganisation } */) {
    const customTdStyle = {
        textAlign: 'center',
        verticalAlign: 'middle'
    };
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {}
    });
    const onSuccess = (data) => {
        const items = data?.element || data?.elements || [];
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.resetTable(
                REDUX_NODE_NAME.RAPPORT_DASHBOARD_BALANCE
            )
        );
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItems(
                items,
                REDUX_NODE_NAME.RAPPORT_DASHBOARD_BALANCE
            )
        );
    };

    const renderTotal = ({ list, devise, path }) => {
        let total = 0;
        const newList = list?.filter(
            (item) => item?.valeurTypeProprietaire === devise
        );
        newList.forEach((element) => {
            total += resolvePathPropertyOfObject(path, element?.operation) || 0;
        });
        return total;
    };

    const COMMANDES_FLASH_LIVREES = 'CommandesFlashLivrees';
    const COMMANDES_FLASH_ANNULEES = 'CommandesFlashAnnulees';
    const COMMANDES_FLASH_ATTENTE_VALIDATION =
        'CommandesFlashAttenteValidation';
    const COMMANDES_FLASH_ENCOURS = 'CommandesFlashEncours';
    const DEPOTS_EMONEY_VALIDEES = 'DepotsEmoneyValidees';
    const DEPOTS_EMONEY_ATTENTE_VALIDATION = 'DepotsEmoneyAttenteValidation';
    const DEPOTS_EMONEY_ANNULEES = 'DepotsEmoneyAnnulees';
    const DEPOTS_EMONEY_ENCOURS = 'DepotsEmoneyEncours';
    const REGULARISATION_ENTREE = 'RegularisationEntree';
    const REGULARISATION_SORTIE = 'RegularisationSortie';
    const TRANSFERT_BALANCE = 'TransfertBalance';
    const TRANSFERT_COMMISSION = 'TransfertCommission';
    const CREDITER_COMPTE_VIA_CASH = 'CrediterCompteViaCash';
    const CREDITER_COMPTE_VIA_EMONEY = 'CrediterCompteViaEmoney';
    const CREDITER_COMPTE_VIA_BANQUE = 'CrediterCompteViaBanque';
    const CREDITER_COMPTE_VIA_COUP_POUCE = 'CrediterCompteViaCoupPouce';
    // const TRANSFERT_EXTERNE = 'TransfertExterne'; // FIXME: Rajouter la bonne opération
    const TRANSFERT_EXTERNE_DEBIT = 'TransfertExterneDebit';
    const TRANSFERT_EXTERNE_CREDIT = 'TransfertExterneCredit';
    const RETRAIT_EMONEY = 'RetraitEmoney';
    const CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR =
        'CrediterCompteViaPaiementVendeur';
    const PAIEMENT_VENDEUR = 'PaiementVendeur';
    const DEMANDE_NUMERO_REFERENCE = 'DemandeNumeroReference';

    const BALANCES_REVENDEUR = 'BalancesRevendeur';
    const BALANCES_CASH_COLLECTEUR = 'BalancesCashCollecteur';
    const BALANCES_SUPER_DEALER = 'BalancesSuperDealer';
    const BALANCES_NAPP = 'BalancesNapp';
    const BALANCES_DISTRIBUTEUR = 'BalancesDistributeur';
    const BALANCES_END_USER = 'BalancesEndUser';

    const CORRESPONDANCE_BALANCE_ORGANISATION = {
        [BALANCES_REVENDEUR]: TYPE_ORGANISATION.REVENDEUR,
        [BALANCES_NAPP]: TYPE_ORGANISATION.NAPP,
        [BALANCES_SUPER_DEALER]: TYPE_ORGANISATION.SUPER_DEALER,
        [BALANCES_CASH_COLLECTEUR]: TYPE_ORGANISATION.CASH_COLLECTEUR,
        [BALANCES_DISTRIBUTEUR]: TYPE_ORGANISATION.DISTRIBUTEUR,
        [BALANCES_END_USER]: PROFIL_SYSTEM.PROFIL_END_USER.designation
    };

    const mappingBalanceOrganisation = ({ objectMapping, value }) =>
        objectMapping?.[value];

    const formater = ({ data }) => {
        const renderedTable = [];
        data?.forEach((item) => {
            const findedElement = renderedTable?.find(
                (element) =>
                    element?.typeProprietaire === item?.typeProprietaire
            );
            if (!findedElement) {
                const newElement = {
                    typeProprietaire: item?.typeProprietaire,
                    devises: [
                        {
                            valeurTypeProprietaire:
                                item?.valeurTypeProprietaire,
                            operation: {
                                ...item?.operation
                            },
                            extraValues: {
                                difference: item?.difference,
                                entree: item?.entree,
                                montantDifference: item?.montantDifference,
                                soldeFermeture: item?.soldeFermeture,
                                soldeOuverture: item?.soldeOuverture,
                                sortie: item?.sortie,
                                total: item?.total
                            }
                        }
                    ]
                };
                renderedTable.push(newElement);
            }
            if (findedElement) {
                findedElement.devises.push(
                    {
                        valeurTypeProprietaire: item?.valeurTypeProprietaire,
                        operation: {
                            ...item?.operation
                        },
                        extraValues: {
                            difference: item?.difference,
                            entree: item?.entree,
                            montantDifference: item?.montantDifference,
                            soldeFermeture: item?.soldeFermeture,
                            soldeOuverture: item?.soldeOuverture,
                            sortie: item?.sortie,
                            total: item?.total
                        }
                    },
                    {
                        valeurTypeProprietaire: `Total (USD)`,
                        operation: {
                            ...item?.operation
                        },
                        extraValues: {
                            difference: item?.difference,
                            entree: item?.entree,
                            montantDifference: item?.montantDifference,
                            soldeFermeture: item?.soldeFermeture,
                            soldeOuverture: item?.soldeOuverture,
                            sortie: item?.sortie,
                            total: item?.total
                        }
                    }
                );
            }
        });
        return renderedTable;
    };

    const renderSpecificly = ({ data }) => {
        const dataToRender = data || [];
        const dataToRenderFormated =
            data?.length > 0 && !Array.isArray(data?.[0])
                ? formater({ data })
                : [];
        // console.log(data);
        if (dataToRender?.length > 0 && !Array.isArray(dataToRender?.[0])) {
            return (
                <div className="aio-table-container">
                    <table
                        className="table table-bordered border-top table-hover table-striped table-responsive-sm"
                        border={1}
                    >
                        <thead className="fw-normal aio-table-header">
                            <tr style={customTdStyle}>
                                {/* <th rowSpan={2}>#</th> */}
                                {/* <th colSpan={2} rowSpan={2}> */}
                                <th id="aio-th-one" rowSpan={2} colSpan={2}>
                                    Organisation
                                </th>
                                <th rowSpan={2}>Solde Ouverture</th>
                                <th rowSpan={2}>Solde Fermeture</th>
                                <th rowSpan={2}>Difference</th>
                                <th colSpan={4}>Depot</th>
                                <th colSpan={4}>Airtime</th>
                                <th colSpan={5}>Crediter Compte</th>
                                <th rowSpan={2}>Paiements Vendeurs</th>
                                <th rowSpan={2}>Retrait</th>
                                <th colSpan={2}>Régularisation</th>
                                <th colSpan={2}>Balance Transféré</th>
                                <th rowSpan={2}>Commission Transférée</th>
                                <th colSpan={2}>Transfert Externes</th>
                                <th rowSpan={2}>Demande Numéro Reference</th>
                            </tr>
                            <tr style={customTdStyle}>
                                <th>Encours</th>
                                <th>En attente</th>
                                <th>Livré</th>
                                <th>Annulé</th>
                                <th>Encours</th>
                                <th>En attente</th>
                                <th>Livré</th>
                                <th>Annulé</th>
                                <th>Cash</th>
                                <th>Emoney</th>
                                <th>Banque</th>
                                <th>Coup de pouce</th>
                                <th>Paiement Vendeur</th>
                                <th>Débit</th>
                                <th>Credit</th>
                                <th>Débit</th>
                                <th>Credit</th>
                                <th>Débit</th>
                                <th>Credit</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {dataToRenderFormated.map((outer, outerIndex) => (
                                <Fragment key={outer?.typeProprietaire}>
                                    {outer?.devises?.map(
                                        (inner, innerIndex) => (
                                            <tr
                                                // className="aio-table-tr"
                                                style={customTdStyle}
                                                key={`${outerIndex}-${innerIndex}`}
                                            >
                                                {innerIndex === 0 && (
                                                    <td
                                                        id="aio-td-one"
                                                        rowSpan={
                                                            outer?.devises
                                                                ?.length
                                                        }
                                                    >
                                                        {mappingBalanceOrganisation(
                                                            {
                                                                value: outer?.typeProprietaire,
                                                                objectMapping:
                                                                    CORRESPONDANCE_BALANCE_ORGANISATION
                                                            }
                                                        )}
                                                    </td>
                                                )}
                                                <td id="aio-td-three">
                                                    {
                                                        inner?.valeurTypeProprietaire
                                                    }
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.extraValues
                                                                ?.soldeOuverture ||
                                                            0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.extraValues
                                                                ?.soldeFermeture ||
                                                            0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.extraValues
                                                                ?.difference ||
                                                            0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Depot */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                DEPOTS_EMONEY_ENCOURS
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                DEPOTS_EMONEY_ATTENTE_VALIDATION
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                DEPOTS_EMONEY_VALIDEES
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                DEPOTS_EMONEY_ANNULEES
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Airtime */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                COMMANDES_FLASH_ENCOURS
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                COMMANDES_FLASH_ATTENTE_VALIDATION
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                COMMANDES_FLASH_LIVREES
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                COMMANDES_FLASH_ANNULEES
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Crediter Compte */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                CREDITER_COMPTE_VIA_CASH
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                CREDITER_COMPTE_VIA_EMONEY
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                CREDITER_COMPTE_VIA_BANQUE
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                CREDITER_COMPTE_VIA_COUP_POUCE
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Paiement Vendeur FIXME: operations */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                PAIEMENT_VENDEUR
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Retrait FIXME: operations */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                RETRAIT_EMONEY
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Régularisation Débit - Crédit */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                REGULARISATION_SORTIE
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                REGULARISATION_ENTREE
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Balance Transféré */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                TRANSFERT_BALANCE
                                                            ]?.Debit || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                TRANSFERT_BALANCE
                                                            ]?.Credit || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Commission Transférée */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                TRANSFERT_COMMISSION
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Transfert Externes */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                TRANSFERT_EXTERNE_DEBIT
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                TRANSFERT_EXTERNE_CREDIT
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                                {/* Demande Numero Reference */}
                                                <td>
                                                    <NumberDisplayer
                                                        value={
                                                            inner?.operation?.[
                                                                DEMANDE_NUMERO_REFERENCE
                                                            ] || 0
                                                        }
                                                        nombreApresVirgule={4}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </Fragment>
                            ))}
                            <tr style={customTdStyle}>
                                <td id="aio-tf-one" colSpan={4} rowSpan={2}>
                                    Total
                                </td>
                                <td id="aio-tf-two"> USD </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: DEPOTS_EMONEY_ENCOURS
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: DEPOTS_EMONEY_ATTENTE_VALIDATION
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: DEPOTS_EMONEY_VALIDEES
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: DEPOTS_EMONEY_ANNULEES
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: COMMANDES_FLASH_ENCOURS
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: COMMANDES_FLASH_ATTENTE_VALIDATION
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: COMMANDES_FLASH_LIVREES
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: COMMANDES_FLASH_ANNULEES
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: CREDITER_COMPTE_VIA_CASH
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: CREDITER_COMPTE_VIA_EMONEY
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: CREDITER_COMPTE_VIA_BANQUE
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: CREDITER_COMPTE_VIA_COUP_POUCE
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: PAIEMENT_VENDEUR
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: RETRAIT_EMONEY
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: REGULARISATION_SORTIE
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: REGULARISATION_ENTREE
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: `${TRANSFERT_BALANCE}.Debit`
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: `${TRANSFERT_BALANCE}.Credit`
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: TRANSFERT_COMMISSION
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: TRANSFERT_EXTERNE_DEBIT
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: TRANSFERT_EXTERNE_CREDIT
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'USD',
                                        path: DEMANDE_NUMERO_REFERENCE
                                    })}{' '}
                                </td>
                            </tr>
                            <tr>
                                <td id="aio-tf-two"> CDF </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: DEPOTS_EMONEY_ENCOURS
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: DEPOTS_EMONEY_ATTENTE_VALIDATION
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: DEPOTS_EMONEY_VALIDEES
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: DEPOTS_EMONEY_ANNULEES
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: COMMANDES_FLASH_ENCOURS
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: COMMANDES_FLASH_ATTENTE_VALIDATION
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: COMMANDES_FLASH_LIVREES
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: COMMANDES_FLASH_ANNULEES
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: CREDITER_COMPTE_VIA_CASH
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: CREDITER_COMPTE_VIA_EMONEY
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: CREDITER_COMPTE_VIA_BANQUE
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: CREDITER_COMPTE_VIA_COUP_POUCE
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: CREDITER_COMPTE_VIA_PAIEMENT_VENDEUR
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: PAIEMENT_VENDEUR
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: RETRAIT_EMONEY
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: REGULARISATION_SORTIE
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: REGULARISATION_ENTREE
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: `${TRANSFERT_BALANCE}.Debit`
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: `${TRANSFERT_BALANCE}.Credit`
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: TRANSFERT_COMMISSION
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: TRANSFERT_EXTERNE_DEBIT
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: TRANSFERT_EXTERNE_CREDIT
                                    })}{' '}
                                </td>
                                <td>
                                    {' '}
                                    {renderTotal({
                                        list: dataToRender,
                                        devise: 'CDF',
                                        path: DEMANDE_NUMERO_REFERENCE
                                    })}{' '}
                                </td>
                            </tr>
                            {/* <tr>
                                <td colSpan={5}>Total Général</td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            );
        }
        if (Array.isArray(data)) {
            return (
                <CustomAlert successMessage="Aucune information disponible !" />
            );
        }
        return null;
    };

    return (
        <ReduxReportTemplate
            isInitialFetch={false}
            listTitle=""
            isLinkToDetail
            onSuccess={onSuccess}
            reduxPropertyName="rapportDashBoardBalance"
            functionName={URL_CONST.GET_LIST_RAPPORT_BALANCE}
            nodeName={REDUX_NODE_NAME.RAPPORT_DASHBOARD_BALANCE}
            formState={formState}
            formDispatcher={formDispatcher}
            renderSpecificly={renderSpecificly}
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                estRapportBalance: true,
                estNotDate: true
            }}
            defaultPayloadMapperValues={{
                estRapportBalance: true,
                estNotDate: true
            }}
            listFormater={(data) => {
                if (data?.length && data?.[0]?.length) return data?.[0];
                if (data?.length && !data?.[0]?.length) return [];
                return data;
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                }
            ]}
        />
    );
}
