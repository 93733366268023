import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { DB_NODE, formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomTextAreaInput,
    FormWrapper
} from '../../../components';
import { URL_CONST } from '../../../util';
import { HeureSupSchema as uiValidator } from './validation';

const defaultFields = {
    nombreHeure: 0,
    selectedAgent: {},
    motif: ''
};

const defaultDependencies = { fields: ['users'] };

export function CreationHeureSupplementaireForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, users, etatUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        users: reduxState.users.map((item) => ({
            ...item,
            designation: `${item?.personne?.prenom || ''} ${
                item?.personne?.nom || ''
            }`
        })),
        etatUser: reduxState.etat.users.etat
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * Cloud Functions
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUser
            }
        ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const { nombreHeure, motif } = fields;
        const payload = {
            nombreHeure,
            referenceBeneficiaire: fields.selectedAgent.uid,
            motif
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_HEURE_SUPPLEMENTAIRE,
                payload,
                fields,
                reduxNodeName: DB_NODE.HEURE_SUPPLEMENTAIRE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            onSubmit={handleSubmit}
            listDependencies={[
                {
                    dependency: 'users',
                    etat: etatUser
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={users}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner l'agent"
                    label="Agent* :"
                    idProperty="uid"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedAgent"
                    id="selectedAgentId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Durée (H): *"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Durée"
                    formDispatcher={formDispatcher}
                    name="nombreHeure"
                    id="nombreHeureHeureSupId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomTextAreaInput
                    isInputGroup={false}
                    label="Motif*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Message à envoyer"
                    formDispatcher={formDispatcher}
                    name="motif"
                    id="motifHeureSupId"
                    uiValidator={uiValidator}
                    formState={formState}
                    rows={5}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
