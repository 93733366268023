import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ViewLayout } from './view-layout';

export function ListCrediterCautionForm() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { isNapp } = useGetUserDetail({ nappUser });
    const mapper = isNapp
        ? [
              {
                  field: 'institutionFinanciere.designation',
                  displayName: 'Banque'
              },
              {
                  displayName: 'SuperDealer',
                  field: 'valeurTypeEntite.designation'
              },
              {
                  field: 'numeroCompteExterne',
                  displayName: 'Compte Externe'
              },
              {
                  field: 'montant',
                  displayName: 'Montant Opération'
              },
              {
                  field: 'devise',
                  displayName: 'Devise'
              },
              {
                  field: 'dateCreation',
                  displayName: 'Création'
              }
          ]
        : [
              {
                  field: 'institutionFinanciere.designation',
                  displayName: 'Banque'
              },
              {
                  field: 'numeroCompteExterne',
                  displayName: 'Compte Externe'
              },
              {
                  field: 'montant',
                  displayName: 'Montant Opération'
              },
              {
                  field: 'devise',
                  displayName: 'Devise'
              },
              {
                  field: 'dateCreation',
                  displayName: 'Création'
              }
          ];
    return (
        <ViewLayout>
            <ListGenericForm
                // isLinkToDetail={false}
                identifier="id"
                reduxPropertyName="crediterCaution"
                functionName={URL_CONST.GET_LIST_CREDITER_CAUTION_BENEFICIAIRE}
                nodeName={REDUX_NODE_NAME.CREDITER_CAUTION}
                mapper={mapper}
            />
        </ViewLayout>
    );
}
