import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    URL_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddButton,
    CustomAlert,
    CustomButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRadioButton,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { TransfertStockFormSchema as uiValidator } from './validation';

const defaultFields = {
    tillNumber: '',
    destination: 'interne',
    selectedProduit: {},
    selectedAgence: {},
    quantite: 0,
    listProduit: []
};

export function CreationTransfertStockForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, agences, etatAgence, produits, etatProduit } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            agences: reduxState.agences,
            etatAgence: reduxState.etat.agences.etat,
            produits: reduxState.produits,
            etatProduit: reduxState.etat.produits.etat
        }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['marchand']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_AGENCE,
                nodeName: DB_NODE.AGENCE,
                etat: etatAgence
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PRODUIT_MARCHAND_MATERIEL,
                nodeName: DB_NODE.PRODUIT,
                etat: etatProduit
            }
        ]
    });
    const fetchMarchand = () => {
        const { tillNumber } = fields;
        if (tillNumber && tillNumber !== elements.marchand?.value?.tillNumber) {
            formDispatcher(
                fetchElement({
                    idToken,
                    elementPropertyName: 'marchand',
                    payload: { tillNumber },
                    functionName: URL_CONST.GET_MARCHAND_BY_TILL_NUMBER,
                    etat: elements.marchand.status,
                    formDispatcher,
                    reduxDispatcher
                })
            );
        }
    };
    const addProduit = () => {
        const { selectedProduit, quantite, listProduit } = fields;
        if (!selectedProduit || !quantite) return;
        if (
            listProduit.find(
                (item) => item?.produit.code === selectedProduit.code
            )
        )
            return;
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'listProduit',
                identifier: 'code',
                data: {
                    code: selectedProduit.code,
                    produit: selectedProduit,
                    quantite
                }
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { listProduit, destination, selectedAgence } = fields;
        const paramToAdd = {};
        if (destination === 'interne') {
            paramToAdd.codeAgence = selectedAgence?.code;
        } else if (destination === 'externe') {
            paramToAdd.tillNumberMarchand =
                elements.marchand?.value?.tillNumber;
        }
        const payload = {
            ...paramToAdd,
            list: listProduit.map((item) => ({
                codeProduit: item.code,
                quantite: item.quantite
            })),
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_TRANSFERT_STOCK_MATERIEL,
                payload,
                fields,
                reduxNodeName: DB_NODE.TRANSFERT_STOCK_MATERIEL,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            <CustomRow>
                <CustomLabel text="Destination*" className="col-12 col-md-2" />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3"
                    label="Interne"
                    id="interneRadioCreationTransfertStockMateriel"
                    name="destination"
                    value="interne"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3"
                    label="Externe"
                    id="externeRadioCreationTransfertStockMateriel"
                    name="destination"
                    value="externe"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomCollapse
                isIn={fields.destination === 'interne'}
                className=""
            >
                <CustomRow>
                    <CustomDropdown
                        options={agences}
                        label="Agence*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        formDispatcher={formDispatcher}
                        name="selectedAgence"
                        id="selectedAgenceCreationTransfertStockMateriel"
                        formState={formState}
                        idProperty="code"
                        uiValidator={uiValidator}
                    />
                </CustomRow>
            </CustomCollapse>
            <CustomCollapse
                isIn={fields.destination === 'externe'}
                className=""
            >
                <CustomRow>
                    <CustomInput
                        type="text"
                        label="Till number marchand*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-10 col-sm-8"
                        isInputGroup={false}
                        placeholder="Saisissez le tillNumber"
                        formDispatcher={formDispatcher}
                        name="tillNumber"
                        id="tillNumberCreationDemandeAffiliation"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                    <CustomCol className="col-2">
                        <CustomButton
                            disabled={
                                elements.marchand.status ===
                                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                            }
                            onClick={fetchMarchand}
                        >
                            Rechercher
                        </CustomButton>
                    </CustomCol>
                    <CustomRow>
                        <CustomCol className="offset-md-2 col-12 col-md-10">
                            {elements.marchand.status ===
                                FORM_CONST.FETCH_ELEMENT_FAILED && (
                                <CustomAlert
                                    error={`${elements.marchand?.error || ''}`}
                                />
                            )}
                            {elements.marchand.status ===
                                FORM_CONST.FETCH_ELEMENT_SUCCESS && (
                                <CustomAlert
                                    successMessage={`${
                                        elements.marchand?.value?.tillNumber ||
                                        ''
                                    } - ${
                                        elements.marchand?.value?.designation ||
                                        ''
                                    }`}
                                />
                            )}
                        </CustomCol>
                    </CustomRow>
                </CustomRow>
            </CustomCollapse>
            <CustomRow>
                <CustomDropdown
                    options={produits}
                    label="Produit*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-sm-5"
                    formDispatcher={formDispatcher}
                    name="selectedProduit"
                    id="selectedProduitCreationTransfertStockMateriel"
                    formState={formState}
                    idProperty="code"
                    uiValidator={uiValidator}
                />
                <div className="col-sm-3">
                    <CustomInput
                        type="number"
                        isFloat
                        isInputGroup={false}
                        min={20}
                        max={99}
                        placeholder="Saisissez la quantité"
                        formDispatcher={formDispatcher}
                        name="quantite"
                        id="quantiteCreationTransfertStockMateriel"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </div>
                <div className="col-sm-2">
                    <CustomAddButton size="25em" callback={addProduit} />
                </div>
            </CustomRow>
            <CustomRow>
                <CustomCol className="offset-md-2 col-12 col-md-10">
                    <SimpleTable
                        identifier="code"
                        isShowDropButton
                        listSelectedItemName="listProduit"
                        data={fields.listProduit}
                        formDispatcher={formDispatcher}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                position: 1,
                                field: 'produit.code',
                                displayName: 'Code'
                            },
                            {
                                position: 2,
                                field: 'produit.designation',
                                displayName: 'Designation'
                            },
                            {
                                position: 3,
                                field: 'quantite',
                                displayName: 'quantite'
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
