import * as yup from 'yup';

/**
 * @description : Schema de validation service générateur
 */
export const ServiceGenerateurSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required(),
    selectedType: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});
