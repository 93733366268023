import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    Add,
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    Sub,
    URL_CONST
} from '@napp-inc/jnapp-util';
import {
    CustomCol,
    CustomCollapse,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../../components';
import { useGetDetailForm } from '../../../../util';
import { schema } from './detail-fields';

export function DetailTransfertSimForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'transfertsSim' });
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payload = {
            etat,
            idTransfertSim: entity?.id
        };
        formDispatcher(
            submitPutAction({
                idToken,
                reduxNodeName: DB_NODE.TRANSFERT_SIM,
                functionName: URL_CONST.PUT_TRANSFERT_SIM,
                payload,
                fields,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TRANSFERT_SIM_BY_ID}
            nodeName={DB_NODE.TRANSFERT_SIM}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Transfert sim"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_VENTE.VALIDER_DISPATCHING_SIM,
                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                        .CODE_SERVICE_MODULE_VENTE.ANNULER_DISPATCHING_SIM
                ]}
            >
                <CustomCollapse
                    isIn={!!entity?.plages?.length}
                    className="offset-sm-2 col-sm-10"
                >
                    <SimpleTable
                        isShowDropButton={entity?.etat === ETAT.ETAT_ENCOURS}
                        identifier="id"
                        data={entity?.plages?.map(
                            ({ debut, fin, initial }) => ({
                                debut: `${initial.debut}${debut}`,
                                fin: `${initial.fin}${fin}`,
                                quantite: Add(
                                    Sub(parseInt(fin, 10), parseInt(debut, 10)),
                                    1
                                )
                            })
                        )}
                        listSelectedItemName="plages"
                        formDispatcher={formDispatcher}
                        tableClass="table table-hover shadow table-borderless table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                field: 'debut',
                                displayName: 'Début',
                                position: 1
                            },
                            {
                                field: 'fin',
                                displayName: 'Fin',
                                position: 2
                            },
                            {
                                field: 'quantite',
                                displayName: 'Quantité',
                                position: 3
                            }
                        ]}
                    />
                </CustomCollapse>
                <CustomRow>
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_VALIDER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
