import * as yup from 'yup';

/**
 * @description : Schema de validation moyen de retracage création form
 */
export const MoyenRetracageSchema = yup.object().shape({
    selectedType: yup.string().required(),
    selectedElement: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});

/**
 * @description : Schema de validation directions création form
 */
export const DirectionSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required(),
    typeEntite: yup.string().required(),
    estNapp: yup.boolean()
});

/**
 * @description : Schema de validation secteurs
 */
export const SecteurSchema = yup.object().shape({
    code: yup.string().required(),
    designation: yup.string().required(),
    estCommercial: yup.boolean(),
    typeEntite: yup.string().required(),
    typeProprietaire: yup.string().required(),
    selectedDirection: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    })
});

/**
 * @description : Schema de validation pour la creation des comptes parallèles bancaires
 */
export const CompteExterneBancaireSchema = yup.object().shape({
    intituleCompte: yup.string().required(),
    numeroCompte: yup.string().required(),
    selectedType: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedPays: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedBanque: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedDevise: yup.object().shape({
        id: yup.string().required(),
        devise: yup.string().required()
    }),
    nom: yup.string(),
    postnom: yup.string(),
    prenom: yup.string().required(),
    email: yup.string().email().required()
});

/**
 * @description : Schema de validation pour la creation des comptes distributeurs
 */
export const CompteExterneDistributeurSchema = yup.object().shape({
    intituleCompte: yup.string().required(),
    numeroCompte: yup.string(),
    selectedSecteur: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    nom: yup.string(),
    postnom: yup.string(),
    prenom: yup.string(),
    email: yup.string().email(),
    numeroTel: yup.string()
});

/**
 * @description : Schema de validation pour la creation des comptes mobile money
 */
export const CompteExterneMobileMoneySchema = yup.object().shape({
    intituleCompte: yup.string().required(),
    numeroCompte: yup.string().required(),
    nom: yup.string(),
    postnom: yup.string(),
    prenom: yup.string().required(),
    email: yup.string().email().required(),
    numeroTel: yup.string().required()
});
