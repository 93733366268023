export const percuAndRetournedMontantDevise = (percu, retour) => {
    const concatenatedArray = percu.concat(retour);
    const result = [];
    concatenatedArray.forEach((item1) => {
        const elementsExisting = concatenatedArray.filter(
            (element) => element.devise === item1.devise
        );
        if (elementsExisting.length === 1) {
            result.push({
                devise: item1.devise,
                estPercu: item1.estEntree,
                montant: item1.montant
            });
        }
        if (
            elementsExisting.length === 2 &&
            !result.find((element) => element.devise === item1.devise)
        ) {
            if (
                elementsExisting[0].montant > elementsExisting[1].montant &&
                elementsExisting[0].estEntree
            ) {
                result.push({
                    devise: item1.devise,
                    estPercu: true,
                    montant: elementsExisting[0].estEntree
                        ? elementsExisting[0].montant -
                          elementsExisting[1].montant
                        : elementsExisting[1].montant -
                          elementsExisting[0].montant
                });
            }
            if (
                elementsExisting[0].montant < elementsExisting[1].montant &&
                elementsExisting[0].estEntree
            ) {
                result.push({
                    devise: item1.devise,
                    estPercu: false,
                    montant: elementsExisting[0].estEntree
                        ? elementsExisting[1].montant -
                          elementsExisting[0].montant
                        : elementsExisting[0].montant -
                          elementsExisting[1].montant
                });
            }
        }
    });
    return result;
};
