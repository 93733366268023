import * as yup from 'yup';

/**
 * @description : Schema de Decaissement form
 */

/**
 *  montant: 0,
typeOperation: 'Decaissement'
    selectedCaisse: {},
    selectedDevise: {},
    selectedPartenaire: {},
    taux: 0,
    tauxB: 0,
    isTaux: false,
    isTauxB: false,
    isTauxSollicite: false,
 */
export const DecaissementFormSchema = yup.object().shape({
    montant: yup.number().min(1),
    typeOperation: yup.string().required(),
    selectedCaisse: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedDevise: yup.object().shape({
        id: yup.string().required(),
        devise: yup.string().required()
    }),
    selectedPartenaire: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    isTaux: yup.boolean(),
    isTauxB: yup.boolean(),
    taux: yup.number().when('isTaux', {
        is: true,
        then: (schema) => schema.min(1),
        otherwise: (schema) => schema.min(0)
    }),
    tauxB: yup.number().when('isTauxB', {
        is: true,
        then: (schema) => schema.min(1),
        otherwise: (schema) => schema.min(0)
    })
});
