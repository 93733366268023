import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import * as yup from 'yup';

/**
 * @description : Schema de Transfert KinDB form
 */
export const TransfertCaisseKinDBFormSchema = yup.object().shape({
    estDeviseSollicite: yup.boolean(),
    selectedCaisseSource: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    selectMonnaieSortie: yup.object().when('estDeviseSollicite', {
        is: true,
        then: yup.object().shape({
            id: yup.string().required(),
            devise: yup.string().required()
        }),
        otherwise: yup.object().shape({
            id: yup.string(),
            devise: yup.string()
        })
    }),
    selectedTypeOrganisation: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedSuperDealer: yup.object().when('selectedTypeOrganisation', {
        is: (selectedTypeOrganisation) =>
            selectedTypeOrganisation.code === TYPE_ORGANISATION.SUPER_DEALER,
        then: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        otherwise: yup.object().shape({
            code: yup.string(),
            designation: yup.string()
        })
    }),
    selectedCashCollecteur: yup.object().when('selectedTypeOrganisation', {
        is: (selectedTypeOrganisation) =>
            selectedTypeOrganisation.code === TYPE_ORGANISATION.CASH_COLLECTEUR,
        then: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        otherwise: yup.object().shape({
            code: yup.string(),
            designation: yup.string()
        })
    }),
    montant: yup.number().min(1)
});
/**
 * @description : Schema de Transfert KinDB form
 */
export const TransfertCaisseKinDBDetailFormSchema = yup.object().shape({
    selectedCaisse: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    })
});
