import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    CATEGORIE_MARCHAND,
    DB_NODE,
    FORM_CONST,
    TYPE_BUSINESS,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import {
    RDC_PREFIX,
    URL_CONST,
    formatCodeDesignation,
    useDebounce
} from '../../../../util';
import {
    ConditionalRenderingWrapper,
    CustomAddRemoveButton,
    CustomAlert,
    CustomCollapse,
    CustomConfirmButton,
    CustomDropdown,
    CustomFormGroup,
    CustomInput,
    CustomRow,
    DynamicFormMounted,
    FormWrapper,
    SelectZone,
    SimpleTable
} from '../../../../components';
import { SelectProduitTypeBusiness } from '../form-completion';
import { REDUX_NODE_NAME } from '../../../../redux';

export function CreationForm({
    typeEntite,
    idToken,
    categorieMarchand,
    uiValidator,
    initialState,
    designationMarchand,
    dynamicFields,
    organisation
}) {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { entitesAdministrative, monnaieMarchand, etatMonnaieMarchand } =
        useSelector((reduxState) => ({
            entitesAdministrative: reduxState.entitesAdministrative,
            monnaieMarchand: reduxState.monnaieMarchand,
            etatMonnaieMarchand: reduxState.etat.monnaieMarchand.etat
        }));

    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const { fields, form, elements } = formState;

    /**
     * use fetch
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_MONNAIE_MARCHAND,
                nodeName: REDUX_NODE_NAME.MONNAIE_MARCHAND,
                etat: etatMonnaieMarchand,
                isCondition:
                    typeEntite === TYPE_ORGANISATION.MARCHAND &&
                    categorieMarchand === CATEGORIE_MARCHAND.MOBILE_MONEY.code
            }
        ]
    });

    /**
     * Use Debounce
     * @param {} event
     */
    // Marchand
    /** Start Section */
    useDebounce({
        name: 'selectedZone',
        preview: 'preview',
        isObject: true,
        formDispatcher,
        formState,
        delay: 2000,
        idToken,
        functionName: URL_CONST.GET_LIST_ENTITE_ADMINISTRATIVE_BY_ZONES,
        elementPropertyName: 'zones',
        // creator,
        reduxNodeName: DB_NODE.ENTITE_ADMINISTRATIVE,
        reduxDispatcher,
        condition: typeEntite === TYPE_ORGANISATION.MARCHAND
    });
    /** End Section */

    // Distributeur
    /** Start Section */
    useDebounce({
        name: 'designationMarchand',
        preview: 'preview',
        isObject: false,
        formDispatcher,
        formState,
        delay: 1000,
        idToken,
        functionName:
            URL_CONST.GET_LIST_MARCHAND_BY_CATEGORIE_AND_TEXT_DESIGNATION,
        elementPropertyName: 'marchand',
        payloadToAdd: {
            codeCategorie: fields?.selectedTypeMarchand?.code,
            textDesignation: fields?.designationMarchand
        },
        // creator,
        reduxDispatcher,
        condition: typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
    });

    useDebounce({
        name: 'selectedMarchand',
        preview: 'previewMarchand',
        isObject: true,
        formDispatcher,
        formState,
        delay: 1000,
        idToken,
        functionName: URL_CONST.GET_ENTREPRISE_AND_LIST_PRODUIT_MARCHAND,
        elementPropertyName: 'monnaie',
        payloadToAdd: {
            code: fields?.selectedMarchand?.code,
            estClient: false
        },
        // creator,
        reduxDispatcher,
        condition:
            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR &&
            fields?.selectedTypeMarchand?.code ===
                CATEGORIE_MARCHAND.MOBILE_MONEY.code &&
            !!fields?.selectedMarchand?.code
    });

    useDebounce({
        name: 'selectedTypeMarchand',
        preview: 'previewTypeMarchand',
        isObject: true,
        formDispatcher,
        formState,
        delay: 1000,
        idToken,
        functionName: URL_CONST.GET_LIST_USER,
        elementPropertyName: 'users',
        // creator,
        reduxDispatcher,
        condition:
            typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR &&
            fields?.selectedTypeMarchand?.code ===
                CATEGORIE_MARCHAND.MOBILE_MONEY.code &&
            !!fields?.selectedMarchand?.code
    });
    /** End Section */

    /**
     * Action to add type business
     * @param {*} event
     */
    /** Start Section */
    const addTypeBusiness = () => {
        if (
            !fields?.selectedTypeDropDown?.code ||
            !fields?.selectedTypeDropDown?.designation
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Vérifiez les valeurs renseignées',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        let numero = fields?.numeroAssocie;
        if (fields?.numeroAssocie.startsWith('00')) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Format invalide',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        RDC_PREFIX.forEach((prefix) => {
            if (fields?.numeroAssocie.startsWith(prefix)) {
                numero = fields?.numeroAssocie.substring(
                    prefix.length,
                    fields?.numeroAssocie.length
                );
                return true;
            }
            return false;
        });
        const data = {
            code: fields?.selectedTypeDropDown?.code,
            numero: numero || 'Pas de numéro Associé'
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'code',
                field: 'typeBusinessWithNumber',
                data
            }
        });
    };
    /** End Section */

    /**
     * Constitution Zones
     */
    /** Start Section */
    const zoneFound = [];
    entitesAdministrative.forEach(({ designation, reference }) => {
        if (
            designation
                .toLowerCase()
                .includes(fields?.selectedZone?.designation?.toLowerCase())
        ) {
            zoneFound.push({
                designation,
                reference
            });
        }
    });
    /** End Section */

    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            newAppointage: {
                designation: fields?.newAppointageDesignation,
                email: fields?.newAppointageEmail
            }
        };
        const propertyToAdd = {
            innerLevel: {},
            outerLevel: {}
        };
        if (typeEntite === TYPE_ORGANISATION.MARCHAND) {
            /**
             * InnerLevel
             */
            propertyToAdd.innerLevel.code = formatCodeDesignation({
                partToAdd: `${designationMarchand}_`,
                designation: fields?.newAppointageDesignation
            });
            /**
             * OuterLevel
             */
            payload.zones = fields?.zones;
            payload.proprietaires = [
                {
                    nom: fields?.newProprietaireNom,
                    prenom: fields?.newProprietairePrenom,
                    numeroTel: fields?.newProprietaireNumeroTel,
                    adresse: {
                        adresseUne: fields?.newProprietaireAdresse
                    },
                    email: fields?.newProprietaireEmail
                }
            ];
            payload.newAdresse = {
                adresseUne: fields?.newAdresseAdresseUne
            };
            if (categorieMarchand === CATEGORIE_MARCHAND.AIRTIME.code) {
                payload.typeBusiness = fields?.typeBusinessWithNumber;
            }
            if (categorieMarchand === CATEGORIE_MARCHAND.MOBILE_MONEY.code) {
                payload.typeBusiness = fields?.typeBusinessWithNumber.map(
                    (item) => item?.code
                );
                payload.devises = fields?.typeBusiness.map((item) => ({
                    code: item?.code,
                    numero: item?.numero
                }));
            }
        }
        if (typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR) {
            /**
             * InnerLevel
             */
            propertyToAdd.innerLevel.code = formatCodeDesignation({
                partToAdd: `${fields?.selectedMarchand?.code}_`,
                designation: fields?.newAppointageDesignation
            });
            /**
             * OuterLevel
             */
            if (
                fields?.selectedTypeMarchand?.code ===
                CATEGORIE_MARCHAND.AIRTIME.code
            ) {
                payload.typeBusiness = fields?.typeBusinessWithNumber;
            }
            if (
                fields?.selectedTypeMarchand?.code ===
                CATEGORIE_MARCHAND.MOBILE_MONEY.code
            ) {
                payload.typeBusiness = fields?.typeBusinessWithNumber?.map(
                    (item) => item.code
                );
                payload.devises = fields?.typeBusiness?.map((item) => ({
                    code: item?.code,
                    numero: item?.numero
                }));
                payload.owner = fields?.selectedUser?.owner;
            }
        }
        /**
         * final payload
         */
        payload.newAppointage.code = propertyToAdd?.innerLevel?.code;
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_APPOINTAGE_SUPER_DEALER,
                payload,
                fields,
                reduxNodeName: DB_NODE.SUPER_DEALER,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'monnaie',
                    etat: etatMonnaieMarchand,
                    isCondition:
                        typeEntite === TYPE_ORGANISATION.MARCHAND &&
                        categorieMarchand ===
                            CATEGORIE_MARCHAND.MOBILE_MONEY.code
                }
            ]}
        >
            <DynamicFormMounted
                formDispatcher={formDispatcher}
                formState={formState}
                uiValidator={uiValidator}
                fieldsMapper={dynamicFields}
            />
            <ConditionalRenderingWrapper
                isShouldBeRendered={typeEntite === TYPE_ORGANISATION.MARCHAND}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        categorieMarchand === CATEGORIE_MARCHAND.AIRTIME.code
                    }
                >
                    <CustomRow>
                        <CustomDropdown
                            options={TYPE_BUSINESS.TYPE_BUSINESS}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner Type Business"
                            label="Business*"
                            labelClassName="col-sm-2"
                            divClassName="col-12 col-sm-4"
                            name="selectedTypeDropDown"
                            id="selectedTypeDropDownId"
                            idProperty="code"
                            propertyToDisplay="designation"
                            uiValidator={uiValidator}
                        />
                        <CustomInput
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            id="numeroAssocieId"
                            divClassName="col-sm-4"
                            isInputGroup={false}
                            name="numeroAssocie"
                            placeholder="[prefix] xx xx xxx (ex: 822435689) "
                        />
                        <CustomAddRemoveButton
                            className="col-sm-2"
                            callback={addTypeBusiness}
                        />
                    </CustomRow>
                    <CustomCollapse isIn={!!fields?.typeBusinessWithNumber}>
                        <SimpleTable
                            identifier="code"
                            data={fields?.typeBusinessWithNumber}
                            listSelectedItemName="typeBusinessWithNumber"
                            formDispatcher={formDispatcher}
                            // className="w-50"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    displayName: 'Code',
                                    field: 'code'
                                },
                                {
                                    position: 2,
                                    displayName: 'Numero',
                                    field: 'numero'
                                }
                            ]}
                        />
                    </CustomCollapse>
                </ConditionalRenderingWrapper>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        categorieMarchand ===
                        CATEGORIE_MARCHAND.MOBILE_MONEY.code
                    }
                >
                    <CustomRow>
                        <CustomDropdown
                            options={[
                                {
                                    code: TYPE_BUSINESS.BUSINESS_MODEL,
                                    designation: TYPE_BUSINESS.BUSINESS_MODEL
                                },
                                {
                                    code: TYPE_BUSINESS.TRADITIONNEL_BUSINESS,
                                    designation:
                                        TYPE_BUSINESS.TRADITIONNEL_BUSINESS
                                }
                            ]}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner Type Business"
                            label="Business*"
                            labelClassName="col-sm-2"
                            divClassName="col-11 col-sm-9"
                            name="selectedTypeDropDown"
                            id="selectedTypeDropDownId"
                            idProperty="code"
                            propertyToDisplay="designation"
                            uiValidator={uiValidator}
                        />
                        <CustomAddRemoveButton
                            className="col-1 col-sm-1"
                            callback={addTypeBusiness}
                        />
                    </CustomRow>
                    <CustomCollapse isIn={!!fields?.typeBusinessWithNumber}>
                        <SimpleTable
                            identifier="code"
                            data={fields?.typeBusinessWithNumber}
                            listSelectedItemName="typeBusinessWithNumber"
                            formDispatcher={formDispatcher}
                            // className="w-50"
                            tableClass="table table-hover table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    displayName: 'Code',
                                    field: 'code'
                                }
                            ]}
                        />
                    </CustomCollapse>
                    <CustomRow>
                        <CustomFormGroup className="col-md-12">
                            <SelectProduitTypeBusiness
                                codeCategorieMarchand={categorieMarchand}
                                formDispatcher={formDispatcher}
                                formState={formState}
                                uiValidator={uiValidator}
                                organisation={organisation}
                                monnaies={monnaieMarchand[0]?.devises || []}
                            />
                        </CustomFormGroup>
                    </CustomRow>
                </ConditionalRenderingWrapper>
                <CustomRow>
                    <CustomFormGroup className="col-md-12">
                        <SelectZone
                            stateProperty="selectedTypeDropDownZone"
                            zoneFound={entitesAdministrative}
                            entitesAdministrative={entitesAdministrative}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomFormGroup>
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR
                }
            >
                <CustomRow>
                    <CustomDropdown
                        options={[
                            CATEGORIE_MARCHAND.AIRTIME,
                            CATEGORIE_MARCHAND.MOBILE_MONEY
                        ]}
                        label="Catégorie Marchand*:"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedTypeMarchand"
                        id="selectedTypeMarchandCreationAppointageID"
                        defaultOption="Selectionnez Type Marchand"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Marchand* :"
                        labelClassName="col-12 col-md-2"
                        divClassName="col-12 col-md-10"
                        placeholder="Recherche marchand..."
                        formDispatcher={formDispatcher}
                        name="designationMarchand"
                        id="designationMarchandCreationAppointageId"
                        formState={formState}
                        uiValidator={uiValidator}
                        disabled={!fields?.selectedTypeMarchand?.code}
                    />
                </CustomRow>
                {elements?.marchand?.status ===
                    FORM_CONST.FETCH_ELEMENT_FAILED && (
                    <CustomAlert error={`${elements?.marchand?.error || ''}`} />
                )}
                {elements?.marchand?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                    <div className="placeholder-glow">
                        <span className="placeholder col-5" />
                        <span className="placeholder col-12" />
                    </div>
                ) : null}
                {elements?.marchand?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                !elements?.marchand?.value?.length ? (
                    <CustomAlert successMessage="Aucun marchand trouvé" />
                ) : null}
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        elements?.marchand?.status ===
                            FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                        elements?.marchand?.value?.length
                    }
                >
                    <CustomRow>
                        <CustomDropdown
                            options={elements?.marchand?.value || []}
                            label=" "
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedMarchand"
                            id="selectedMarchandCreationAppointageID"
                            defaultOption="Selectionnez Marchand"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            // uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow
                        isShouldBeRendered={
                            fields?.selectedTypeMarchand?.code ===
                            CATEGORIE_MARCHAND.AIRTIME.code
                        }
                    >
                        <CustomDropdown
                            options={TYPE_BUSINESS.TYPE_BUSINESS}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner Type Business"
                            label="Business*"
                            labelClassName="col-sm-2"
                            divClassName="col-12 col-sm-4"
                            name="selectedTypeDropDown"
                            id="selectedTypeDropDownId"
                            idProperty="code"
                            propertyToDisplay="designation"
                            uiValidator={uiValidator}
                        />
                        <CustomInput
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            id="numeroAssocieId"
                            divClassName="col-sm-4"
                            isInputGroup={false}
                            name="numeroAssocie"
                            placeholder="[prefix] xx xx xxx (ex: 822435689) "
                        />
                        <CustomAddRemoveButton
                            className="col-sm-2"
                            callback={addTypeBusiness}
                        />
                    </CustomRow>
                    <CustomRow
                        isShouldBeRendered={
                            fields?.selectedTypeMarchand?.code ===
                            CATEGORIE_MARCHAND.MOBILE_MONEY.code
                        }
                    >
                        <CustomDropdown
                            options={[
                                {
                                    code: TYPE_BUSINESS.BUSINESS_MODEL,
                                    designation: TYPE_BUSINESS.BUSINESS_MODEL
                                },
                                {
                                    code: TYPE_BUSINESS.TRADITIONNEL_BUSINESS,
                                    designation:
                                        TYPE_BUSINESS.TRADITIONNEL_BUSINESS
                                }
                            ]}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner Type Business"
                            label="Business*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-9 col-sm-9"
                            name="selectedTypeDropDown"
                            id="selectedTypeDropDownId"
                            idProperty="code"
                            propertyToDisplay="designation"
                            uiValidator={uiValidator}
                        />
                        <CustomAddRemoveButton
                            className="col-1 col-sm-1"
                            callback={addTypeBusiness}
                        />
                    </CustomRow>

                    <CustomCollapse isIn={!!fields?.typeBusinessWithNumber}>
                        <SimpleTable
                            identifier="code"
                            data={fields?.typeBusinessWithNumber}
                            listSelectedItemName="typeBusinessWithNumber"
                            formDispatcher={formDispatcher}
                            // className="w-50"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={
                                fields?.selectedTypeMarchand.code ===
                                CATEGORIE_MARCHAND.AIRTIME.code
                                    ? [
                                          {
                                              position: 1,
                                              displayName: 'Code',
                                              field: 'code'
                                          },
                                          {
                                              position: 2,
                                              displayName: 'Numero',
                                              field: 'numero'
                                          }
                                      ]
                                    : [
                                          {
                                              position: 1,
                                              displayName: 'Code',
                                              field: 'code'
                                          }
                                      ]
                            }
                        />
                    </CustomCollapse>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            fields?.selectedTypeMarchand.code ===
                            CATEGORIE_MARCHAND.MOBILE_MONEY.code
                        }
                    >
                        {elements?.monnaie?.status ===
                            FORM_CONST.FETCH_ELEMENT_FAILED && (
                            <CustomAlert
                                error={`${elements?.monnaie?.error || ''}`}
                            />
                        )}
                        {elements?.monnaie?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                            <div className="placeholder-glow">
                                <span className="placeholder col-5" />
                                <span className="placeholder col-12" />
                            </div>
                        ) : null}
                        {elements?.monnaie?.status ===
                            FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                        // !!elements?.monnaie?.value?.[0]?.list?.length &&
                        !elements?.monnaie?.value?.[0]?.list?.[0]?.devises
                            ?.length ? (
                            <CustomAlert successMessage="Aucune devise trouvée" />
                        ) : null}
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            elements?.monnaie?.status ===
                                FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                            !!elements?.monnaie?.value?.[0]?.list?.length &&
                            !!elements?.monnaie?.value?.[0]?.list?.[0]?.devises
                                ?.length
                        }
                    >
                        <CustomRow>
                            <CustomFormGroup className="col-md-12">
                                <SelectProduitTypeBusiness
                                    codeCategorieMarchand={
                                        fields?.selectedTypeMarchand.code
                                    }
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    uiValidator={uiValidator}
                                    organisation={organisation}
                                    monnaies={
                                        elements?.monnaie?.value?.[0]?.list?.[0]
                                            ?.devises || []
                                    }
                                />
                            </CustomFormGroup>
                        </CustomRow>
                    </ConditionalRenderingWrapper>
                </ConditionalRenderingWrapper>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        elements?.users?.status ===
                            FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                        !!elements?.marchand?.value?.length
                    }
                >
                    <CustomRow>
                        <CustomDropdown
                            options={elements?.users?.value?.map((item) => ({
                                ...item,
                                owner: item.id,
                                designation: `${item?.personne?.prenom || ''} ${
                                    item?.personne?.nom || ''
                                }`
                            }))}
                            label="Gestionnaire*:"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedUser"
                            id="selectedUserCreationAppointageID"
                            defaultOption="Selectionnez Gestionnaire"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            idProperty="owner"
                            // uiValidator={uiValidator}
                        />
                    </CustomRow>
                </ConditionalRenderingWrapper>
            </ConditionalRenderingWrapper>
            <CustomRow className="row">
                <CustomConfirmButton
                    type="button"
                    backdrop="static"
                    text="Appointer"
                    className="btn-primary btn mt-3 ms-2 w-25"
                    confirmOnClick={handleSubmit}
                    disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    isActionEncours={
                        form.status === FORM_CONST.CREATION_EN_COURS
                    }
                />
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
