import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import {
    TauxCreateRelPath,
    TauxViewLayoutRelPath
} from '../../../routes/registration/finance/finance-rel-path';
import { ListTauxActifForm } from './list-taux-actif';
import { HistoriqueTaux } from './historique-taux';

export function TauxActifsViewLayout() {
    const { pathname } = useLocation();
    const [key, setKey] = useState('main');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Taux Actifs</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname === `/${TauxViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.SERVICE_CODE_REVENDEUR
                                        .CODE_SERVICE_MODULE_ADMINISTRATION
                                        .LECTURE_LIST_TAUX_ACTIF_MONNAIE_LOCALE
                                ]}
                                destination={TauxCreateRelPath.path}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Tabs
                defaultActiveKey="main"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="main" title="Taux actifs">
                    {key === 'main' && <ListTauxActifForm />}
                </Tab>
                <Tab eventKey="historique" title="Historique Taux">
                    {key === 'historique' && <HistoriqueTaux />}
                </Tab>
            </Tabs>
        </div>
    );
}
