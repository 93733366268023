import { forwardRef, useEffect, useRef } from 'react';

export const TableRowCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <input type="checkbox" ref={resolvedRef} {...rest} />;
    }
);
