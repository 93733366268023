import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListConfirmationSoldeCompteForm() {
    return (
        <ListGenericForm
            reduxPropertyName="confirmationSoldeCompte"
            functionName={
                URL_CONST.GET_LIST_CONFIRMATION_SOLDE_COMPTE_ORGANISATION
            }
            nodeName={REDUX_NODE_NAME.CONFIRMATION_SOLDE_COMPTE}
            mapper={[
                {
                    field: 'tache.designation',
                    displayName: 'Tâche'
                },
                {
                    field: 'valeurTypeOrganisation.designation',
                    displayName: 'Organisation'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                },
                {
                    displayName: 'Date',
                    field: 'date'
                }
            ]}
        />
    );
}
