import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = { actionEncours: '', reference: '' };
export function DetailVirementKinDBForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        // oid,
        uid
    } = useGetDetailForm({
        nodeName: 'virementsKindb',
        defaultFields
    });
    // const isValidator = entity?.partenaire?.reference === oid;
    const isDecaisseur = entity?.userDecaissement === uid;
    const isEncaisseur = entity?.userEncaissement === uid;
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payload = {
            idOperationVirement: entity?.id,
            etat
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_OPERATION_VIREMENT_KINDB,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.VIREMENTS_KINDB,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_OPERATION_VIREMENT_KINDB_BY_ID}
            nodeName={REDUX_NODE_NAME.VIREMENTS_KINDB}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail virement"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB,
                    CODE_SERVICE.FINANCE_ORGANISATION
                        .ATTENTE_VALIDATION_VIREMENT_KINDB,
                    CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_KINDB
                ]}
            >
                <CustomRow>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat !== ETAT.ETAT_VALIDER}
                    >
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            entity?.etat === ETAT.ETAT_ENCOURS && isDecaisseur
                        }
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Mettre en attente"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION &&
                            isEncaisseur
                        }
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
