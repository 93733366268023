import {
    CATEGORIE_MARCHAND,
    FORM_CONST,
    TYPE_BUSINESS
} from '@napp-inc/jnapp-util';
import {
    CustomCollapse,
    CustomRow,
    DynamicMonoSelectAndInput
} from '../../../../components';

// FIXME : A faire, fetcher user pour appliquer des modifications telles que l'activation et la désativation

/**
 * Select Produit
 * @param {Object} param0 Objet contenant les props suivantes:
 * @param {Object} param0.formState représente le state du composant
 * @param {Function} param0.formDispatcher fonction qui déclenche une action sur le state du composant
 * @param {Object} param0.uiValidator Objet contenant les schémas de validation
 * @param {Array} param0.produits tableau représentant la liste des produits du marchand
 * @param {Object} param0.organisation objet reprénnant toutes les informations lié à l'organisation du marchand
 * @param {Object} param0.monnaies Les monnaies du marchand
 * @returns {JSX.Element}
 */
export function SelectProduitTypeBusiness({
    formState,
    formDispatcher,
    uiValidator,
    codeCategorieMarchand,
    monnaies = []
}) {
    const { fields } = formState;
    const callbackFunction = () => {
        const dataToRender = {};
        if (
            fields?.selectedTypeBusinessNumero &&
            fields?.selectedTypeBusiness.code &&
            fields?.selectedTypeBusiness.designation &&
            codeCategorieMarchand === CATEGORIE_MARCHAND.MOBILE_MONEY.code
        ) {
            dataToRender.id = fields?.selectedTypeBusiness.code;
            dataToRender.numero = fields?.selectedTypeBusinessNumero;
            dataToRender.code = fields?.selectedTypeBusiness?.code;
        }
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'code',
                field: 'typeBusiness',
                data: dataToRender
            }
        });
    };

    return (
        <CustomCollapse
            isIn={
                codeCategorieMarchand === CATEGORIE_MARCHAND.MOBILE_MONEY.code
            }
        >
            <CustomRow>
                <DynamicMonoSelectAndInput
                    callback={callbackFunction}
                    code={codeCategorieMarchand}
                    options={TYPE_BUSINESS.TYPE_BUSINESS}
                    optionsEmoney={monnaies}
                    id="typeBusinessAndInput"
                    name="selectedTypeBusiness"
                    inputName="selectedTypeBusinessNumero"
                    defaultOption="Selectionner la devise"
                    formState={formState}
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                />
            </CustomRow>
        </CustomCollapse>
    );
}
