export const schema = [
    {
        title: 'Caisse Source',
        description: 'caisseA.designation',
        className: 'border-bottom'
    },
    {
        title: 'Caisse Destination',
        description: 'caisseB.designation',
        className: 'border-bottom'
    },
    {
        title: 'Montant',
        description: 'montant',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Creation',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
