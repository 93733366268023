import { FORM_CONST } from '@napp-inc/jnapp-util';
import { CustomInput, CustomRow } from '../../../components';

const TYPE_CHARGE_RETRIBUTION = [
    { code: 'NAPP_RECRUTEMENT_MARCHAND', designation: 'Marchand' },
    { code: 'NAPP_AFFILIATION', designation: 'Affiliation' },
    { code: 'NAPP_RECRUTEMENT_END_USER', designation: 'Recrutement End user' },
    { code: 'NAPP_RECHARGE_END_USER', designation: 'Recharge End user' },
    { code: 'NAPP_DEFAULT', designation: '' }
];

export function TarifChargeRessourceComponent({
    charges,
    nameListChargeRessource,
    formDispatcher
}) {
    const stateUpdater = (name, value) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE_ON_TABLE,
            payload: {
                field: nameListChargeRessource,
                id: name,
                propertyName: 'value',
                data: value
            }
        });
    };
    return charges.map((charge) => {
        const chargeFind = TYPE_CHARGE_RETRIBUTION.find(
            (item) => item.code === charge?.typeEntite
        );
        const label = chargeFind
            ? `${charge?.designation} (${chargeFind.designation})`
            : charge?.designation;
        return (
            <CustomRow key={charge.code}>
                <CustomInput
                    isInputGroup={false}
                    label={label}
                    labelClassName="col-12 col-md-4"
                    divClassName="col-12 col-md-8"
                    type="number"
                    isFloat
                    min={0}
                    placeholder="Saisissez le taux"
                    name={charge?.code || ''}
                    id={`${charge?.code || ''}tarifChargeRessource`}
                    callback={stateUpdater}
                />
            </CustomRow>
        );
    });
}
