import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { ReleveBalanceOrganisme } from '../../../../components';

export function ReleveNonDeclareKinDistributionForm() {
    return (
        <ReleveBalanceOrganisme
            typeOrganisation={TYPE_ORGANISATION.SUPER_DEALER}
            payload={{
                typeProprietaire: TYPE_ORGANISATION.SUPER_DEALER,
                estOrganisation: true,
                typesEntite: ['NonDeclarerEmoney']
            }}
            dynamicNodeName="compteRetraitNonDeclareKinDistribution"
            label="Sélectionner un compte"
        />
    );
}
