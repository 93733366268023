import { URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListClientForm() {
    return (
        <ListGenericForm
            reduxPropertyName="clientsOrganisation"
            functionName={URL_CONST.GET_LIST_NUMERO_CLIENT}
            nodeName={REDUX_NODE_NAME.CLIENT_ORGANISATION}
            mapper={[
                {
                    displayName: 'Client',
                    dataExtractor: (item) =>
                        `${item?.valeurTypeProprietaire?.designation || ''} - ${
                            item?.valeurTypeProprietaire?.prenom || ''
                        } ${item?.valeurTypeProprietaire?.nom || ''}`
                },
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'Organisation'
                },
                {
                    field: 'numero',
                    displayName: 'numeros'
                    // dataExtractor: (client) => {
                    //     if (client?.list?.length) {
                    //         let numero = '';
                    //         client.list.forEach((element, index) => {
                    //             numero = `${numero}${index !== 0 ? '|' : ''}${
                    //                 element.numero
                    //             }`;
                    //         });
                    //         return numero;
                    //     }
                    //     return '';
                    // }
                },
                {
                    field: 'dateCreation',
                    displayName: 'dateCreation'
                }
            ]}
        />
    );
}
