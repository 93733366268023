import { JOURS_SEMAINE } from '@napp-inc/jnapp-util';
import { HoraireJourComponent } from './horaire-jour';

/**
 * Horaires de la semaine
 * @param {Object} param0 object qui contient les props du composant:
 * @param {String} param0.formDispatcher fonction qui dispatch les actions du formulaire
 * @param {Boolean} param0.isLundiOuvert valeur de l'état du switch du lundi
 * @param {Boolean} param0.isLundiH24 valeur de l'état du switch H24 du lundi
 * @param {Boolean} param0.isMardiOuvert valeur de l'état du switch du mardi
 * @param {Boolean} param0.isMardiH24 valeur de l'état du switch H24 du mardi
 * @param {Boolean} param0.isMercrediOuvert valeur de l'état du switch du mercredi
 * @param {Boolean} param0.isMercrediH24 valeur de l'état du switch H24 du mercredi
 * @param {Boolean} param0.isJeudiOuvert valeur de l'état du switch du jeudi
 * @param {Boolean} param0.isJeudiH24 valeur de l'état du switch H24 du jeudi
 * @param {Boolean} param0.isVendrediOuvert valeur de l'état du switch du vendredi
 * @param {Boolean} param0.isVendrediH24 valeur de l'état du switch H24 du vendredi
 * @param {Boolean} param0.isSamediOuvert valeur de l'état du switch du samedi
 * @param {Boolean} param0.isSamediH24 valeur de l'état du switch H24 du samedi
 * @param {Boolean} param0.isDimancheOuvert valeur de l'état du switch du dimanche
 * @param {Boolean} param0.isDimancheH24 valeur de l'état du switch H24 du dimanche
 * @returns {React.Component}
 */
export function HorairesJoursComponent({
    formDispatcher,
    isLundiOuvert,
    isLundiH24,
    isMardiOuvert,
    isMardiH24,
    isMercrediOuvert,
    isMercrediH24,
    isJeudiOuvert,
    isJeudiH24,
    isVendrediOuvert,
    isVendrediH24,
    isSamediOuvert,
    isSamediH24,
    isDimancheOuvert,
    isDimancheH24
}) {
    return (
        <>
            <HoraireJourComponent
                formDispatcher={formDispatcher}
                designation={JOURS_SEMAINE.LUNDI.designation}
                switchOuvertName="isLundiOuvert"
                switchOuvertValue={isLundiOuvert}
                switchH24Name="isLundiH24"
                switchH24Value={isLundiH24}
                heureDebutName="heureDebutLundi"
                heureFinName="heureFinLundi"
            />
            <HoraireJourComponent
                formDispatcher={formDispatcher}
                designation={JOURS_SEMAINE.MARDI.designation}
                switchOuvertName="isMardiOuvert"
                switchOuvertValue={isMardiOuvert}
                switchH24Name="isMardiH24"
                switchH24Value={isMardiH24}
                heureDebutName="heureDebutMardi"
                heureFinName="heureFinMardi"
            />
            <HoraireJourComponent
                formDispatcher={formDispatcher}
                designation={JOURS_SEMAINE.MERCREDI.designation}
                switchOuvertName="isMercrediOuvert"
                switchOuvertValue={isMercrediOuvert}
                switchH24Name="isMercrediH24"
                switchH24Value={isMercrediH24}
                heureDebutName="heureDebutMercredi"
                heureFinName="heureFinMercredi"
            />
            <HoraireJourComponent
                formDispatcher={formDispatcher}
                designation={JOURS_SEMAINE.JEUDI.designation}
                switchOuvertName="isJeudiOuvert"
                switchOuvertValue={isJeudiOuvert}
                switchH24Name="isJeudiH24"
                switchH24Value={isJeudiH24}
                heureDebutName="heureDebutJeudi"
                heureFinName="heureFinJeudi"
            />
            <HoraireJourComponent
                formDispatcher={formDispatcher}
                designation={JOURS_SEMAINE.VENDREDI.designation}
                switchOuvertName="isVendrediOuvert"
                switchOuvertValue={isVendrediOuvert}
                switchH24Name="isVendrediH24"
                switchH24Value={isVendrediH24}
                heureDebutName="heureDebutVendredi"
                heureFinName="heureFinVendredi"
            />
            <HoraireJourComponent
                formDispatcher={formDispatcher}
                designation={JOURS_SEMAINE.SAMEDI.designation}
                switchOuvertName="isSamediOuvert"
                switchOuvertValue={isSamediOuvert}
                switchH24Name="isSamediH24"
                switchH24Value={isSamediH24}
                heureDebutName="heureDebutSamedi"
                heureFinName="heureFinSamedi"
            />
            <HoraireJourComponent
                formDispatcher={formDispatcher}
                designation={JOURS_SEMAINE.DIMANCHE.designation}
                switchOuvertName="isDimancheOuvert"
                switchOuvertValue={isDimancheOuvert}
                switchH24Name="isDimancheH24"
                switchH24Value={isDimancheH24}
                heureDebutName="heureDebutDimanche"
                heureFinName="heureFinDimanche"
            />
        </>
    );
}
