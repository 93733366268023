import * as yup from 'yup';

export const TableMangerSchema = yup.object().shape({
    table: yup.string(),
    selectedPointLivraison: yup.object().shape({
        id: yup.string(),
        designation: yup.string()
    }),
    list: yup
        .array()
        .of(
            yup
                .object({
                    code: yup.string().required(),
                    designation: yup.string()
                })
                .shape({})
        )
        .required()
        .min(1, 'Ajoutez au moins un élément')
});
